import { Grid, Stack, Typography } from "@mui/material";
import Page from "Layout/Page";
import { unsafeFromUuid } from "Lib/Id";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Enrollment,
  PatientDetailsQuery,
  usePatientDetailsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { PatientActionsCard } from "./PatientActionsCard";
import PatientDetailsCard from "./PatientDetailsCard";
import { DigUnpacked } from "type-utils";
import PatientInfoCard from "./PatientInfoCard";
import RelatedPeopleCard from "../../Patients/PatientDetails/RelatedPeopleCard";
import SurveyHistoryCard from "./SurveyHistoryCard";
import TasksCard from "./TasksCard";
import SurveyManagement from "./DetailTabs/SurveyManagementTab/SurveyManagement";
import { useIsMobile } from "Shared/Responsive";
import { CaseConsultCard } from "./CaseConsultCard";
import { PreviousEnrollmentsCard } from "./PreviousEnrollmentsCard";
import EnrollmentMonthsCard from "./EnrollmentMonthsCard";
import TimelineCard from "./TimelineCard";
import { TIMELINE_FLAG, WithFrontendFlag } from "Contexts/FrontendFlagContext";
import { WithFeature } from "Contexts/CurrentInstituteContext";
import ActiveEncounterCard from "./ActiveEncounterCard";

export type PatientDetails = DigUnpacked<PatientDetailsQuery, ["patient"]>;

// PatientDetails is separated into two views, one mobile and one desktop.
// We do this because we're also going to position: sticky the patient details card.
// This sticky element needs to have a single ancestor that it can scroll through.
export function PatientDetails(): ReactElement | null {
  const { t } = useTranslation(["common"]);
  const params = useParams<{ patientId: string }>();
  if (!params.patientId) {
    return <PatientNotFound />;
  }
  const patientId = unsafeFromUuid<"Patient">(params.patientId);
  const { remoteData } = apolloQueryHookWrapper(
    usePatientDetailsQuery({ variables: { patientId: patientId } })
  );

  return remoteData.caseOf({
    Failure: () => <Typography>{t("common:remoteData.failure")}</Typography>,
    NotAsked: () => <Typography>{t("common:remoteData.notAsked")}</Typography>,
    Loading: () => <Typography>{t("common:remoteData.loading")}</Typography>,
    Success: (data) => {
      return (
        <PatientDetailsElement
          patient={data.patient}
          enrollment={data.patient?.activeCollaborativeCareCareEpisode?.collaborativeCareEnrollment || null}
        />
      );
    },
  });
}

function PatientNotFound() {
  const { t } = useTranslation(["common"]);
  return <Typography>{t("common:remoteData.failure")}</Typography>;
}

type PatientDetailsElementProps = {
  patient: PatientDetails | null;
  enrollment: Pick<Enrollment, "id"> | null;
};
function PatientDetailsElement(props: PatientDetailsElementProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const mobile = useIsMobile();

  let content = <PatientNotFound />;
  if (props.patient !== null) {
    if (mobile) {
      content = <MobilePatientDetails patient={props.patient} enrollment={props.enrollment} />;
    } else {
      content = <DesktopPatientDetails patient={props.patient} enrollment={props.enrollment} />;
    }
  }
  return <Page browserTitle={t("collaborativeCare:patientDetails.title")}>{content}</Page>;
}

type DesktopPatientDetailsProps = {
  patient: PatientDetails;
  enrollment: Pick<Enrollment, "id"> | null;
};
function DesktopPatientDetails(props: DesktopPatientDetailsProps): ReactElement {
  return (
    <Grid container columns={12} spacing={1}>
      <Grid item lg={4} xs={12}>
        <Stack direction="column" minHeight={"100%"} spacing={1} flexGrow={1} flexDirection={"column"}>
          <LeftPanel patient={props.patient} enrollment={props.enrollment} />
        </Stack>
      </Grid>
      <Grid item lg={8} xs={12}>
        <Stack direction="column" spacing={1}>
          <WithFeature feature="enableCocmEncounters">
            <ActiveEncounterCard enrollment={props.enrollment} />
          </WithFeature>
          <RightPanel patient={props.patient} enrollment={props.enrollment} />
        </Stack>
      </Grid>
    </Grid>
  );
}

type PanelProps = {
  patient: PatientDetails;
  enrollment: Pick<Enrollment, "id"> | null;
};

function LeftPanel(props: PanelProps): ReactElement {
  return (
    <>
      <PatientDetailsCard patient={props.patient} disableLink />
      <PatientActionsCard patient={props.patient} />
      <PatientInfoCard patient={props.patient} />
      <PreviousEnrollmentsCard patient={props.patient} />
    </>
  );
}

function RightPanel(props: PanelProps): ReactElement {
  return (
    <>
      <WithFrontendFlag flagName={TIMELINE_FLAG}>
        <TimelineCard patient={props.patient} />
      </WithFrontendFlag>
      <SurveyManagement patient={props.patient} mobile={useIsMobile()} />
      <SurveyHistoryCard patient={props.patient} />
      <TasksCard patient={props.patient} />
      <EnrollmentMonthsCard enrollment={props.enrollment} />
      <RelatedPeopleCard patient={props.patient} />
      <CaseConsultCard patient={props.patient} />
    </>
  );
}

type MobilePatientDetailsProps = {
  patient: PatientDetails;
  enrollment: Pick<Enrollment, "id"> | null;
};
function MobilePatientDetails(props: MobilePatientDetailsProps): ReactElement {
  return (
    <Stack direction="column" spacing={1}>
      <WithFeature feature="enableCocmEncounters">
        <ActiveEncounterCard enrollment={props.enrollment} />
      </WithFeature>
      <LeftPanel patient={props.patient} enrollment={props.enrollment} />
      <RightPanel patient={props.patient} enrollment={props.enrollment} />
    </Stack>
  );
}
