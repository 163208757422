import { Button } from "@mui/material";
import { useIsInternalUser } from "AppSession/AuthenticatedProviderUser";
import { getImpersonatingUserId } from "AppSession/ImpersonatingUser";
import { useInstituteHasGroups } from "Contexts/CurrentInstituteContext";
import { PiiLevel, usePiiLevelIsInsufficient } from "Contexts/PiiLevelContext";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type ContextDisabledButtonProps = {
  disabledText?: string;
  children: ReactNode;
  minPiiLevel?: PiiLevel;
};

// This function will automatically sub a button out for a disabled button if:
//  - The PII mode is not full_access, OR
//  - You are impersonating a user, OR
//  - This is a write operation and you are in read only mode due to being part of an Institute Group
export function ReadOnlyOrImpersonatingDisabledButton(props: ContextDisabledButtonProps) {
  const isImpersonating = !!getImpersonatingUserId().getOrElse(null);
  const groupsEnabled = useInstituteHasGroups();
  const piiReadOnly = usePiiLevelIsInsufficient(props.minPiiLevel || "full_access");
  const { t } = useTranslation(["settings"]);

  if (isImpersonating || piiReadOnly || groupsEnabled) {
    const disabledText = props.disabledText
      ? props.disabledText
      : groupsEnabled
      ? t("settings:instituteGroupUser.disabledButton")
      : isImpersonating
      ? t("settings:impersonatingUser.disabledButton")
      : t("settings:piiLevel.disabledButton");

    return (
      <Button disabled variant="contained" color="primary">
        {disabledText}
      </Button>
    );
  } else {
    return props.children;
  }
}

export function ImpersonatingDisabledButton(props: ContextDisabledButtonProps) {
  const isImpersonating = !!getImpersonatingUserId().getOrElse(null);
  const { t } = useTranslation(["settings"]);

  if (isImpersonating) {
    const disabledText = props.disabledText
      ? props.disabledText
      : t("settings:impersonatingUser.disabledButton");

    return (
      <Button disabled variant="contained" color="primary">
        {disabledText}
      </Button>
    );
  } else {
    return props.children;
  }
}

// This function will automatically sub a button out for a disabled button if:
//  - You are an internal user
export function InternalUserDisabledButton(props: ContextDisabledButtonProps) {
  const isInternalUser = useIsInternalUser();
  const { t } = useTranslation(["settings"]);

  if (isInternalUser) {
    const disabledText = props.disabledText ? props.disabledText : t("settings:internalUser.disabledButton");

    return (
      <Button disabled variant="contained" color="primary">
        {disabledText}
      </Button>
    );
  } else {
    return props.children;
  }
}
