import Page from "Layout/Page";
import { useTranslation } from "react-i18next";
import ImplementationTargetForm from "./ImplementationTargetForm";
import {
  ImplementationTargetStatus,
  useImplementationTargetDetailsQuery,
  useUpdateImplementationTargetMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { useNavigate } from "react-router-dom";
import { ImplementationTargetId } from "Lib/Ids";
import Spinner from "Shared/Spinner";
import ErrorMessage from "Shared/ErrorMessage";
import NotFound from "Shared/NotFound";
import Link from "MDS/Link";
import { Typography } from "@mui/material";

export default function EditImplementationTarget(props: { implementationTargetId: ImplementationTargetId }) {
  const { t } = useTranslation(["implementation", "common"]);
  const [updateTarget, { remoteData: updateRemoteData }] = apolloMutationHookWrapper(
    (result) => result.implementationUpdateImplementationTarget,
    useUpdateImplementationTargetMutation()
  );
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate(`..`);
  };

  const { remoteData: queryRemoteData } = apolloQueryHookWrapper(
    useImplementationTargetDetailsQuery({
      variables: { implementationTargetId: props.implementationTargetId },
    })
  );

  const body = queryRemoteData.caseOf({
    Success: (result) => {
      const target = result.implementationTarget;
      if (!target) {
        return <NotFound />;
      }

      const defaults = {
        name: target.name,
        description: target.description,
        startDate: target.startDate,
        targetType: target.targetType,
        target: target.target,
        mirahInternal: target.mirahInternal,
      };

      return (
        <ImplementationTargetForm
          defaults={defaults}
          mode="edit"
          mutation={(form) => {
            updateTarget({
              variables: {
                input: {
                  id: props.implementationTargetId,
                  name: form.name,
                  description: form.description,
                  startDate: form.startDate,
                  status: ImplementationTargetStatus.ACTIVE,
                  target: form.target,
                  entityTreeNode: form.entityTreeNodeParams,
                  targetType: form.targetType,
                  softLaunchDate: form.softLaunchDate,
                  initialCredits: form.initialCredits,
                  mirahInternal: form.mirahInternal,
                },
              },
            });
          }}
          remoteData={updateRemoteData}
          onSuccess={onSuccess}
        />
      );
    },
    Loading: () => <Spinner />,
    NotAsked: () => <Spinner />,
    Failure: (error) => <ErrorMessage message={error.message} />,
  });

  const breadcrumbs = queryRemoteData.caseOf({
    Success: (response) => {
      if (response.implementationTarget) {
        return [
          <Link key="implementation-breadcrumb" to="/app/cocm/implementation">
            {t("implementation:dashboard.title")}
          </Link>,
          <Link key="target-breadcrumb" to={`/app/cocm/implementation/${response.implementationTarget.id}`}>
            {response.implementationTarget.name}
          </Link>,
          <Typography key="edit-breadcrumb">{t("common:actions.edit")}</Typography>,
        ];
      } else {
        return [];
      }
    },
    _: () => [],
  });

  return (
    <Page browserTitle={t("implementation:edit.title")} breadcrumbs={breadcrumbs}>
      {body}
    </Page>
  );
}
