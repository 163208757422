import { Button, Card, CardContent, CardHeader, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { WithFeature } from "Contexts/CurrentInstituteContext";
import {
  CareEpisode,
  CareEpisodeProvider,
  Organization,
  Provider,
  TreatmentService,
  useCareEpisodeDetailsEditQuery,
  useCareUnitSummaryQuery,
  useEditCareEpisodeMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import * as Id from "Lib/Id";
import { PatientId, OrganizationId, TreatmentServiceId } from "Lib/Ids";
import { remotePair } from "Lib/RemoteData";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ErrorMessage from "Shared/ErrorMessage";
import { useRedirectOnSuccess } from "Shared/Form";
import Spinner from "Shared/Spinner";
import { careEpisodeEditBreadcrumbs } from "./CareEpisodeBreadcrumbs";
import { CareEpisodeLoading } from "./CareEpisodeLoading";
import CareEpisodeOrganizationSection from "./CareEpisodeOrganizationSection";
import CareEpisodeProviderSection from "./CareEpisodeProviderSection";
import CareEpisodeTreatmentServiceSection from "./CareEpisodeTreatmentServiceSection";

function CareEpisodeEditHookWrapper(): ReactElement {
  const { t } = useTranslation(["common", "careEpisodes"]);
  const params = useParams<{
    patientId?: string;
    careEpisodeId?: string;
  }>();
  const careEpisodeId = Id.fromNullableString<"CareEpisode">(params.careEpisodeId).getOrElse(null);
  const patientId = Id.fromNullableString<"Patient">(params.patientId).getOrElse(null);

  if (!careEpisodeId || !patientId) {
    return (
      <Page browserTitle="Mirah">
        <ErrorMessage message="Invalid URL" />
      </Page>
    );
  }

  // TODO: If we want to avoid querying stuff we don't need on CareEpisodeDetails we can split the institute-level parts
  // of this query out into a second one. Not sure offhand if an extra query or extra data is worse.

  const { remoteData: careEpisodeResponse } = apolloQueryHookWrapper(
    useCareEpisodeDetailsEditQuery({
      variables: {
        careEpisodeId: careEpisodeId,
      },
    })
  );

  const { remoteData: careUnitResponse } = apolloQueryHookWrapper(
    useCareUnitSummaryQuery({
      variables: {
        patientId: patientId,
      },
    })
  );

  const breadcrumbs = remotePair(careEpisodeResponse, careUnitResponse).caseOf({
    Success: ([careEpisode, careUnit]) => {
      if (!careEpisode.careEpisode || !careUnit.patient) {
        return null;
      }

      return careEpisodeEditBreadcrumbs({
        careEpisode: careEpisode.careEpisode,
        patient: careUnit.patient,
        t: t,
      });
    },
    _: () => null,
  });

  const content = remotePair(careEpisodeResponse, careUnitResponse).caseOf({
    NotAsked: () => <CareEpisodeLoading />,
    Loading: () => <CareEpisodeLoading />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: ([careEpisode, careUnit]) => {
      if (!careEpisode.careEpisode || !careUnit.patient) {
        return <ErrorMessage message="Unable to load data" />;
      }

      return (
        <CareEpisodeEdit
          careEpisode={careEpisode.careEpisode}
          patientId={careUnit.patient.id}
          allOrganizations={careEpisode.careEpisode.institute.organizations.nodes}
          allProviders={careEpisode.careEpisode.institute.providers}
        />
      );
    },
  });

  return (
    <Page browserTitle="Mirah" breadcrumbs={breadcrumbs}>
      {content}
    </Page>
  );
}

type ProviderSummary = Pick<Provider, "__typename" | "id" | "name">;
type CareEpisodeProviderSummary = Pick<CareEpisodeProvider, "__typename" | "id" | "relationship"> & {
  provider: ProviderSummary;
};
type OrganizationSummary = Pick<Organization, "__typename" | "id" | "name" | "parentId"> & {
  providers: { nodes: ReadonlyArray<ProviderSummary> };
};
type OrgWithId = Pick<Organization, "__typename" | "id">;
type TreatmentServiceWithId = Pick<TreatmentService, "__typename" | "id">;
type EditableCareEpisode = Pick<CareEpisode, "__typename" | "id" | "periodStart"> & {
  organization: OrgWithId;
  treatmentService: TreatmentServiceWithId | null; // TAC-15 We need to migrate this to be required once treatment services are set everywhere
  careEpisodeProviders: ReadonlyArray<CareEpisodeProviderSummary>;
};
type CareEpisodeEditProps = {
  careEpisode: EditableCareEpisode;
  patientId: PatientId;
  allOrganizations: ReadonlyArray<OrganizationSummary>;
  allProviders: ReadonlyArray<ProviderSummary>;
};

export function CareEpisodeEdit(props: CareEpisodeEditProps): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);

  const [selectedOrg, setSelectedOrg] = React.useState<OrganizationId>(props.careEpisode.organization.id);
  const [selectedTreatmentService, setSelectedTreatmentService] = React.useState<TreatmentServiceId | null>(
    props.careEpisode.treatmentService?.id || null
  );

  const [careTeam, setCareTeam] = React.useState(
    props.careEpisode.careEpisodeProviders.map((cep) => ({
      providerId: cep.provider.id,
      relationship: cep.relationship,
    }))
  );

  const [periodStart, setPeriodStart] = React.useState(props.careEpisode.periodStart);

  const [editCareEpisode, { remoteData }] = apolloMutationHookWrapper(
    (data) => data.editCareEpisode,
    useEditCareEpisodeMutation({
      variables: {
        careEpisode: {
          careEpisodeId: props.careEpisode.id,
          organizationId: selectedOrg,
          treatmentServiceId: selectedTreatmentService,
          periodStart: periodStart,
          careTeam: careTeam,
        },
      },
    })
  );

  function onSave() {
    editCareEpisode();
  }

  const isLoading = remoteData.caseOf({
    Loading: () => true,
    _: () => false,
  });
  const error = remoteData.caseOf({
    Failure: (err) =>
      err.caseOf({
        apolloError: (aerr) => aerr.message,
        // This is quite bad, but in order to actually ship this page ever I'm declaring
        // better error handling patterns out of scope. We have to make time for them soon though.
        userError: (uerr) => uerr.map((e) => e.message).join(", "),
      }),
    _: () => "",
  });

  useRedirectOnSuccess(
    remoteData,
    `/app/patients/${props.patientId}/care-episodes/${props.careEpisode.id}`,
    useNavigate()
  );

  // Given that treatment service is optional, you can't unselect an org, there is no way this form can be invalid;

  return (
    <Stack spacing={1}>
      <WithFeature feature={"enableTreatmentServices"}>
        <CareEpisodeTreatmentServiceSection
          selectedTreatmentService={selectedTreatmentService}
          setSelectedTreatmentService={setSelectedTreatmentService}
        />
      </WithFeature>
      <CareEpisodeOrganizationSection selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} />
      <CareEpisodeProviderSection setCareTeam={setCareTeam} selectedOrg={selectedOrg} careTeam={careTeam} />
      <Card>
        <CardHeader
          title={t("careEpisodes:sections.dates")}
          subheader={t("careEpisodes:sections.datesSubtitle")}
        />
        <CardContent>
          {/* TODO: Make a ResponsiveDatePicker component that wraps this an MobileDatePicker and uses our theme
              breakpoints to pick which one to use. */}
          <DatePicker
            label={t("careEpisodes:fields.start")}
            format="MM/dd/yyyy"
            value={periodStart}
            onChange={setPeriodStart}
          />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Stack direction="row" spacing={1}>
            <Button variant="contained" color="primary" onClick={onSave} disabled={isLoading}>
              {isLoading ? <Spinner /> : t("careEpisodes:actions.save")}
            </Button>
            <ErrorMessage message={error} />
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

export { CareEpisodeEditHookWrapper as CareEpisodeEditPage, CareEpisodeEdit as Component };
