import { colors, lighten } from "@mui/material";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// PALETTE OVERRIDE DESCRIPTION
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// We're using MUI's palette overrides. We have to physically mention any new sections
// or colors we want present in our theme in both the Palette and PaletteOptions.
// This section does not bind colors, it simply declares what can be bound.

declare module "@mui/material/styles/createPalette" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Palette {
    report: ReportColors;
    dividerChip?: string;
    dividerLight?: string;
    textInput: {
      borderColor: string;
    };
    filterHighlight: string;
    qualityIssues: {
      neverOpenedMeasurement: string;
      badContactInfo: string;
      noCaregiver: string;
      contactForbidden: string;
      wrongAge: string;
      noMeasurementsTaken: string;
      snoozedBorder: string;
    };
    collaborativeCare: {
      patientBillableMinutes: string;
      patientBillableMinutesOnTarget: string;
      patientBillableMinutesUnderTarget: string;
      patientBillableMinutesOverTarget: string;
      panels: {
        main: string;
        text: string;
      };
    };
    billing: {
      currentBillable: string;
      nextBillable: string;
    };
    timeline: {
      event: string;
      eventNotBillable: string;
      eventText: string;
      eventNotBillableText: string;
      dayBorder: string;
      eventBorder: string;
      eventNotBillableBorder: string;
    };
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface PaletteOptions {
    report: Partial<ReportColors>;
    dividerChip?: string;
    dividerLight?: string;
    textInput: {
      borderColor: string;
    };
    filterHighlight: string;
    qualityIssues: {
      neverOpenedMeasurement: string;
      badContactInfo: string;
      noCaregiver: string;
      contactForbidden: string;
      wrongAge: string;
      noMeasurementsTaken: string;
      snoozedBorder: string;
    };
    collaborativeCare: {
      patientBillableMinutes: string;
      patientBillableMinutesOnTarget: string;
      patientBillableMinutesUnderTarget: string;
      patientBillableMinutesOverTarget: string;
      panels: {
        main: string;
        text: string;
      };
    };
    billing: {
      currentBillable: string;
      nextBillable: string;
    };
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// EFR PALETTE DESCRIPTION
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// This section is just specific to the EFR, and slots into the Palette Override Description above.

type ReportColors = {
  alerts: { alert: AlertColors; noAlert: AlertColors };
  text: {
    fresh: string;
  };
  acuteChange: { improving: AcuteChangeColors; declining: AcuteChangeColors };
  trend: {
    improving: AcuteChangeColors;
    declining: AcuteChangeColors;
    stable: TrendColors;
  };
  itemCoding: {
    unknown: ItemCodingColors;
    severe: ItemCodingColors;
    moderate: ItemCodingColors;
    mild: ItemCodingColors;
  };
  scaleThreshold: {
    Unknown: ScoreCategoryColors;
    Unanswered: ScoreCategoryColors;
    Continuous: ScoreCategoryColors;
    None: ScoreCategoryColors;
    Low: ScoreCategoryColors;
    Mild: ScoreCategoryColors;
    Moderate: ScoreCategoryColors;
    ModerateToSevere: ScoreCategoryColors;
    Severe: ScoreCategoryColors;
  };
  completion: ScoreCategoryColors;
  blank: ScoreCategoryColors;
  shading: {
    background: string;
    focus: string;
  };
};

export type AlertColors = {
  primary: string;
  offset: string;
};

type AcuteChangeColors = {
  primary: string;
  offset: string;
};

type ItemCodingColors = {
  primary: string;
};

export type ScoreCategoryColors = {
  primary: string;
  offset: string;
  secondary: string;
  secondaryOffset: string;
  text: string;
  heatmapBackgroundImage?: string;
};

type TrendColors = AcuteChangeColors;

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BRAND COLORS
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// From https://drive.google.com/drive/folders/19Jtxa5OL5idBOTeHhiKq2yLjBVtvPsRG
// GDrive > Mirah General > Mirah Brand Guidelines > Brand Guides > Mirah-Brand-Guide-Reference.pdf
// Draw from these first when you need new colors for things. I've tried to use these as variables everywhere in this
// file that they appear, _except_ in the severity color series. (Moderate is the same as golden hour.) I figure those
// are semantically different and should be allowed to float idependently even if they happen to overlap.
export const brand = {
  garnet: "#431F0F",
  rosewood: "#632E16",
  darkHarbor: "#183B44",
  kelp: "#2C2C22",
  goldenHour: "#BE8445",
  pier: "#AC9474",
  driftwood: "#CDB296",
  wetSand: "#DCD8D1",
  seaSalt: "#EEEEEE",
  sail: "#F7F3F0",
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ADDITIONAL COLORS
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// These are in-use colors throughout the app that existed prior to us bringing in our brand colors.

const iconColor = colors.blueGrey[600];
const black = "#000000";
const white = "#FFFFFF";

const goodMain = "#BEDCD5";
const goodOffset = brand.darkHarbor;

const badMain = "#FA3D34";

const background = brand.sail;
const staleBackground = "#EFE9E6";

const greyThreshold = {
  primary: "#EFE9E6",
  offset: goodOffset,
  secondary: staleBackground,
  secondaryOffset: goodOffset,
  text: goodOffset,
};

const darkOffset = background;
const lightOffset = "#203a44";

// This doesn't really have any semantic meaning on its own, but its a shade
// of blue that shows up in several places so it gets a variable.
const slateBlue = "#4E8484";

const transparent = "rgba(0, 0, 0, 0.0)";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ACTUAL PALETTE
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// This is where we actually bind colors to our palette.

export const palette = {
  background: {
    default: background,
    paper: white,
  },
  common: {
    black: black,
    white: white,
  },
  primary: { main: brand.darkHarbor, offset: darkOffset, light: slateBlue },
  secondary: { main: brand.goldenHour, offset: darkOffset, light: brand.driftwood },
  // Generated by starting with brand.rosewood and increasing overall brightness and redness
  error: { main: "#C34431" },
  text: {
    primary: brand.darkHarbor,
    secondary: "#7A8587",
  },
  textInput: {
    borderColor: "#C4C4C4",
    borderRadius: "4px",
    borderWidth: "1px",
    borderStyle: "solid",
  },
  divider: brand.seaSalt,
  dividerChip: "#cccccc",
  dividerLight: brand.driftwood,
  filterHighlight: brand.pier,
  report: {
    alerts: {
      alert: { primary: badMain, offset: "#fff" },
      noAlert: { primary: goodMain, offset: goodOffset },
    },
    text: {
      fresh: "#0073FF",
    },
    itemCoding: {
      unknown: {
        primary: "transparent",
      },
      severe: {
        primary: badMain,
      },
      moderate: {
        primary: "transparent",
      },
      mild: {
        primary: "transparent",
      },
    },
    scaleThreshold: {
      Unknown: greyThreshold,
      Unanswered: {
        primary: "#8a7252",
        offset: "white",
        secondary: staleBackground,
        secondaryOffset: "#624A2A",
        text: "#624A2A",
        heatmapBackgroundImage: "repeating-linear-gradient(-45deg,transparent 0 4px, white 4px 10px)",
      },
      Continuous: greyThreshold,
      None: {
        primary: "#c3e1da",
        offset: goodOffset,
        secondary: staleBackground,
        secondaryOffset: slateBlue,
        text: "#7cb5b9",
      },
      Low: {
        primary: "#97c9c9",
        offset: lightOffset,
        secondary: staleBackground,
        secondaryOffset: goodOffset,
        text: slateBlue,
      },
      Mild: {
        primary: "#f2df91",
        offset: lightOffset,
        secondary: staleBackground,
        secondaryOffset: "#77651C",
        text: "#e2a052",
      },
      Moderate: {
        primary: "#F2A436",
        offset: lightOffset,
        secondary: staleBackground,
        secondaryOffset: "#BE8445",
        text: "#BE8445",
      },
      ModerateToSevere: {
        primary: "#dd5817",
        offset: darkOffset,
        secondary: staleBackground,
        secondaryOffset: "#EF6000",
        text: "#dd5817",
      },
      Severe: {
        primary: badMain,
        offset: darkOffset,
        secondary: staleBackground,
        secondaryOffset: "#CF0A05",
        text: "#af2a24",
      },
    },
    completion: {
      primary: "#2E7384",
      offset: darkOffset,
      secondary: staleBackground,
      secondaryOffset: "#2E7384",
      text: "#555",
    },
    blank: {
      primary: lighten(greyThreshold.primary, 0.5), // LET THERE BE LIGHTEN
      offset: goodOffset,
      secondary: staleBackground,
      secondaryOffset: goodOffset,
      text: goodOffset,
    },
    acuteChange: {
      improving: {
        primary: "#FFCD00",
        offset: darkOffset,
      },
      declining: {
        primary: "#91C0F7",
        offset: darkOffset,
      },
    },
    trend: {
      improving: {
        primary: goodMain,
        offset: goodOffset,
      },
      declining: {
        primary: "#af2a24",
        offset: darkOffset,
      },
      stable: {
        primary: "#aaa",
        offset: "#aaa",
      },
    },
    shading: {
      background: "#fafafa",
      focus: "#f3f3f3",
    },
  },
  // These are existing colors in our palette with transparency. Hex color codes don't have any transparency info, so
  // there isn't a great way to explicitly share these values throughout the palette.
  qualityIssues: {
    // "slateBlue"
    neverOpenedMeasurement: "rgba(78, 132, 132, 0.2)",
    // moderate
    badContactInfo: "rgba(262, 164, 54, 0.4)",
    // mod. severe
    noCaregiver: "rgba(221, 88, 23, 0.2)",
    // severe
    contactForbidden: "rgba(250, 61, 52, 0.3)",
    // low
    wrongAge: "rgba(151, 201, 201, 0.5)",
    // grey
    noMeasurementsTaken: "rgba(239, 233, 230, 0.8)",
    snoozedBorder: slateBlue,
  },
  helpScout: {
    background: brand.goldenHour,
  },
  info: {
    // Default icon color (main) and background (light) of Mui info-severity alert boxes, which for some reason isn't
    // the default value for this in the theme? What even are themes. Our branding doesn't have any light colors that
    // aren't shades of yellow, so I'm sticking with this instead of using something from the brand.
    main: "rgb(3, 169, 244)",
    light: "rgb(229, 246, 253)",
  },
  collaborativeCare: {
    patientBillableMinutes: brand.wetSand,
    patientBillableMinutesOnTarget: goodOffset,
    patientBillableMinutesUnderTarget: "#CF0A05",
    patientBillableMinutesOverTarget: "#e2a052",
    panels: {
      main: brand.rosewood,
      text: brand.sail,
    },
  },
  billing: {
    // These are the result of taking the low (current) and mild (next) primary colors from report scale, setting the
    // opacity to 0.2, putting them on a white background, and then finding the opaque color that you get. This lets
    // us have a thing that looks like a highlight but doesn't get messed up by a color underneath the data grid column
    // headers I can't get rid of.
    currentBillable: "#F3F9F8",
    nextBillable: "#FCF9E9",
  },
  timeline: {
    event: brand.goldenHour,
    eventNotBillable: badMain,
    eventText: brand.sail,
    eventNotBillableText: brand.sail,
    dayBorder: brand.wetSand,
    eventBorder: transparent,
    eventNotBillableBorder: transparent,
  },
};

export { iconColor, white };
export default palette;
