import React, { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import { FeedbackReportRoute } from "../FeedbackReport/FeedbackReportRoute";
import CareUnitListProgram from "Pipbhc/CareUnitList/Program";
import ReportRedirectRoute from "FeedbackReport/ReportRedirectRoute";

const CareUnitDetailsProgram = React.lazy(() => import("../Pipbhc/CareUnitDetails/Program"));

function CareUnits(): ReactElement {
  return (
    <Routes>
      <Route element={<CareUnitListProgram />} path="/" />
      <Route
        element={<FeedbackReportRoute />}
        path=":patientId/care-episodes/:careEpisodeId/feedback-report/*"
      />
      <Route element={<ReportRedirectRoute />} path=":patientId/report" />
      <Route element={<CareUnitDetailsProgram />} path=":careGroupId/*" />
    </Routes>
  );
}

export { CareUnits };
