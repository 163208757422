import {
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  useManuallyCancelInvitationMutation,
  useDownloadInvitationPdfLazyQuery,
  useResendInvitationNotificationsMutation,
  useSendInvitationNotificationsMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId, InvitationId, PatientId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { downloadPdf } from "Shared/PdfDownload";
import React, { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";

export function SendReminderButton(props: {
  disabled: boolean;
  invitationId: InvitationId;
  careEpisodeId: CareEpisodeId;
  mobile: boolean;
}) {
  const { t } = useTranslation(["collaborativeCare"]);

  const [sendInvitationNotifications] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareSendInvitationNotifications,
    useSendInvitationNotificationsMutation({
      refetchQueries: refetchQueries("sessions"),
    })
  );
  const [disabled, setDisabled] = React.useState(props.disabled);
  if (props.mobile) {
    const style = { textAlign: "center", width: "100%" };
    return (
      <Button
        sx={style}
        variant="contained"
        color="secondary"
        disabled={disabled}
        onClick={function () {
          setDisabled(true);
          sendInvitationNotifications({
            variables: {
              input: {
                invitationId: props.invitationId,
              },
            },
          });
        }}
      >
        {t("collaborativeCare:surveyManagement.actions.sendReminder")}
      </Button>
    );
  } else {
    return (
      <MenuItem disabled={disabled}>
        <div
          onClick={function () {
            setDisabled(true);
            sendInvitationNotifications({
              variables: {
                input: {
                  invitationId: props.invitationId,
                },
              },
            });
          }}
        >
          {t("collaborativeCare:surveyManagement.actions.sendReminder")}
        </div>
      </MenuItem>
    );
  }
}

export function CancelInvitationButton(props: {
  disabled: boolean;
  invitationId: InvitationId;
  careEpisodeId: CareEpisodeId;
  mobile: boolean;
}) {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const [showConfirmButton, setShowConfirmButton] = React.useState<boolean>(false);

  const [manuallyCancelInvitation] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareManuallyCancelInvitation,
    useManuallyCancelInvitationMutation({
      refetchQueries: refetchQueries("sessions"),
    })
  );

  let cancelButton = <></>;
  const confirmButton = (
    <ResponsiveDialog
      open={showConfirmButton}
      title={t("collaborativeCare:surveyManagement.actions.cancelMeasurement")}
      keepMounted={false}
      dialogWidth="50%"
      hideClose={true}
    >
      <DialogContent>
        <DialogContent>
          <Typography>
            {t("collaborativeCare:surveyManagement.actions.cancelMeasurementDescription")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={function () {
              setShowConfirmButton(false);
            }}
          >
            {t("common:actions.back")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={function () {
              manuallyCancelInvitation({
                variables: {
                  input: {
                    invitationId: props.invitationId,
                  },
                },
              });
              setShowConfirmButton(false);
            }}
          >
            {t("common:actions.confirm")}
          </Button>
        </DialogActions>
      </DialogContent>
    </ResponsiveDialog>
  );
  if (props.mobile) {
    const style = { textAlign: "center", width: "100%" };
    cancelButton = (
      <Button
        sx={style}
        variant="contained"
        color="secondary"
        onClick={function () {
          setShowConfirmButton(true);
        }}
        disabled={props.disabled}
      >
        {t("collaborativeCare:surveyManagement.actions.cancelMeasurement")}
      </Button>
    );
  } else {
    cancelButton = (
      <MenuItem disabled={props.disabled}>
        <div
          onClick={function () {
            setShowConfirmButton(true);
          }}
        >
          {t("collaborativeCare:surveyManagement.actions.cancelMeasurement")}
        </div>
      </MenuItem>
    );
  }
  return (
    <>
      {cancelButton} {confirmButton}
    </>
  );
}

export function ResendAllNotificationsButton(props: {
  patientId: PatientId;
  careEpisodeId: CareEpisodeId;
  count?: number;
  disabled?: boolean;
}) {
  const { t } = useTranslation(["collaborativeCare"]);

  const [resendAllNotifications] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareResendAllInvitationNotifications,
    useResendInvitationNotificationsMutation({
      refetchQueries: refetchQueries("sessions"),
    })
  );
  const disabled = props.count == 0 || props.disabled || false;
  return (
    <Tooltip title={t("collaborativeCare:surveyManagement.actions.resendAllNotificationsTooltip")}>
      <Button
        variant="contained"
        color="secondary"
        onClick={function () {
          resendAllNotifications({
            variables: {
              input: {
                patientId: props.patientId,
              },
            },
          });
        }}
        disabled={disabled}
      >
        {t("collaborativeCare:surveyManagement.actions.resendAllNotifications", {
          count: disabled ? undefined : props.count,
        })}
      </Button>
    </Tooltip>
  );
}

export function DownloadPdfButton(props: { disabled: boolean; invitationId: InvitationId; mobile: boolean }) {
  const { t } = useTranslation(["collaborativeCare"]);

  const [fetchPdfData, fetchPdfResult] = useDownloadInvitationPdfLazyQuery({
    variables: { invitationId: props.invitationId },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.assessmentInvitation?.pdfContentBase64) {
        downloadPdf(
          data.assessmentInvitation.pdfContentBase64,
          t("collaborativeCare:surveyManagement.actions.downloadPdfTitle")
        );
      }
    },
  });

  const disabled = props.disabled || fetchPdfResult.loading;

  const title = t(
    fetchPdfResult.loading
      ? "collaborativeCare:surveyManagement.actions.downloadPdfGenerating"
      : "collaborativeCare:surveyManagement.actions.downloadPdf"
  );

  if (props.mobile) {
    const style = { textAlign: "center", width: "100%" };
    return (
      <Button
        sx={style}
        variant="contained"
        color="secondary"
        disabled={disabled}
        onClick={function () {
          fetchPdfData();
        }}
      >
        {title}
      </Button>
    );
  } else {
    return (
      <MenuItem disabled={disabled}>
        <div
          onClick={function () {
            fetchPdfData();
          }}
        >
          {title}
        </div>
      </MenuItem>
    );
  }
}

export function SurveyManagementTableLoading(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  return (
    <Card>
      <CardHeader title={t("collaborativeCare:surveyManagement.title")} />
      <CardContent>{t("collaborativeCare:surveyManagement.loading")}</CardContent>
    </Card>
  );
}

type SurveyManagementTableFailedProps = {
  action: ReactNode;
};

export function SurveyManagementTableFailed(props: SurveyManagementTableFailedProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  return (
    <Card>
      <CardHeader title={t("collaborativeCare:surveyManagement.title")} action={props.action} />
      <CardContent>{t("collaborativeCare:surveyManagement.failure")}</CardContent>
    </Card>
  );
}
