import { Checklist, MoreTime } from "@mui/icons-material";
import { Button, ButtonProps, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useChangeCurrentTask } from "Contexts/CurrentTaskContext";
import { useCurrentTimeTracking } from "Contexts/TimeTracking/CurrentTimeTrackingContext";
import { useModifyTimeEntryForm } from "Contexts/TimeTracking/ModifyTimeEntryFormContext";
import { useEndTimeEntryLogMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { refetchQueries } from "Lib/RefetchQueries";
import React, { ReactElement } from "react";

type TimerStopButtonProps = ButtonProps & {
  buttonLabel: string;
  completeTask: boolean;
};

// TimerStopButton renders the Save Progress or Complete Task buttons
// that end the active timer, save the time entry to the server
// and open the modal to modify time entry or to add a note
// we might want this to take a disabled flag instead of hiding the button entirely
export function TimerStopButton(props: TimerStopButtonProps): ReactElement | null {
  // Get the Timer state from the context. you can only render this component
  // under the timetracking context
  const timeTrackingContext = useCurrentTimeTracking();
  const { buttonLabel, completeTask, ...passthroughProps } = props;
  const endTimeAndShowForm = useEndTimeAndShowForm(completeTask);

  // If we aren't actively tracking, there is no button to display
  // We'll ditch early in this case, but after hooks.
  if (timeTrackingContext.status !== "tracking") {
    return <></>;
  }

  return (
    <Button
      onClick={() => endTimeAndShowForm()}
      {...passthroughProps}
      startIcon={<TimerStopIcon completeTask={completeTask} />}
    >
      {buttonLabel}
    </Button>
  );
}

type TimerStopMenuItemProps = {
  label: string;
  completeTask: boolean;
  onClick?: () => void;
};

export function TimerStopMenuItem(props: TimerStopMenuItemProps): ReactElement {
  const endTimeAndShowForm = useEndTimeAndShowForm(props.completeTask);

  const handleClick = () => {
    endTimeAndShowForm();
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon>
        <TimerStopIcon completeTask={props.completeTask} />
      </ListItemIcon>
      <ListItemText>{props.label}</ListItemText>
    </MenuItem>
  );
}

function TimerStopIcon(props: { completeTask: boolean }): ReactElement {
  if (props.completeTask) {
    return <Checklist />;
  } else {
    return <MoreTime />;
  }
}

function useEndTimeAndShowForm(completeTask: boolean) {
  const timeTracking = useCurrentTimeTracking();
  const changeTask = useChangeCurrentTask();
  const modifyTimeEntryFormContext = useModifyTimeEntryForm();
  const [endTimeEntryLogMutation] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareEndTimeEntryLog,
    useEndTimeEntryLogMutation({ refetchQueries: refetchQueries("timeEntries") })
  );

  if (timeTracking.status === "tracking") {
    return () => {
      endTimeEntryLogMutation({
        variables: {
          input: {
            timeEntryLogId: timeTracking.timeEntryLogId,
            clientEndTime: new Date(),
            completeTask: completeTask,
          },
        },
        onCompleted: (data) => {
          if (data.collaborativeCareEndTimeEntryLog?.result?.timeEntryLog) {
            // There are 2 mutations in this order
            // 1 stop timer & save time entry (immediately when you click the button)
            // 2 update time entry & add a note (submitted on form)
            changeTask(data.collaborativeCareEndTimeEntryLog.result.timeEntryLog.workFor);
            modifyTimeEntryFormContext.useAdjustFinishedTimerForm({
              task: data.collaborativeCareEndTimeEntryLog.result.timeEntryLog.workFor,
              timeEntryLog: data.collaborativeCareEndTimeEntryLog.result.timeEntryLog,
              patient: data.collaborativeCareEndTimeEntryLog.result.timeEntryLog.workFor.patient,
            });
          }
        },
      });
    };
  } else {
    return () => {
      // No-op because we aren't tracking anything so there's no form to show.
    };
  }
}
