import { CareEpisodeId, InvitationId } from "Lib/Ids";
import React, { ReactElement } from "react";
import { useEnrollmentState } from "./EnrollmentState";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import {
  Alert,
  Box,
  Button,
  DialogContent,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { ScaleDescription, ScalePickerMultiple } from "Shared/ScalePicker";
import { apolloMutationHookWrapper, MutationRemoteDataResult } from "Api/GraphQL";
import {
  InvitationFormat,
  SendImmediateSurveyMutation,
  useBeginTimeEntryLogMutation,
  useCreateTaskMutation,
  useSendImmediateSurveyMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { Form, FormOverlay, useForm, useListField, useWrappedField } from "Shared/Form";
import { Close } from "@mui/icons-material";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { useTranslation } from "react-i18next";
import { refetchQueries } from "Lib/RefetchQueries";
import { PatientDetails } from "./PatientDetails";
import { DigUnpacked } from "type-utils";
import { ShadedTable } from "MDS/ShadedTable";
import { useCurrentProviderId } from "AppSession/AppSession";
import { useNavigate } from "react-router-dom";

type SendImmediateSurveyResultsType = DigUnpacked<
  SendImmediateSurveyMutation,
  ["collaborativeCareSendImmediateSurvey", "result"]
> | null;

type SendImmediateSurveyButtonProps = {
  patient: PatientDetails;
  buttonMinWidth?: string;
};
export function SendImmediateSurveyButton(props: SendImmediateSurveyButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const enrollmentState = useEnrollmentState(props.patient.id);
  const enable = enrollmentState.status === "enrolled";
  const [open, setOpen] = React.useState(false);
  const [showSurveyChoiceModal, setShowSurveyChoiceModal] = React.useState(false);
  const [remoteData, setRemoteData] =
    React.useState<MutationRemoteDataResult<SendImmediateSurveyResultsType> | null>();
  let modalContent = enable ? (
    <SendImmediateSurveyForm
      patient={props.patient}
      careEpisodeId={enrollmentState.careEpisodeId}
      onSuccess={() => setTimeout(() => setOpen(false), 300)}
      onOpenFollowUp={(results) => {
        setShowSurveyChoiceModal(true);
        setRemoteData(results);
      }}
    />
  ) : null;
  if (showSurveyChoiceModal) {
    modalContent =
      enable && remoteData ? <SentSurveysDisplay remoteData={remoteData} patient={props.patient} /> : null;
  }

  const dialog = enable ? (
    <ResponsiveDialog
      open={open}
      title={t("collaborativeCare:patientDetails.immediateSurveyForm.titleWithPatientName", {
        patient: props.patient.name,
      })}
      onClose={() => {
        setOpen(false);
        setShowSurveyChoiceModal(false);
        setRemoteData(null);
      }}
    >
      <DialogContent>{modalContent}</DialogContent>
    </ResponsiveDialog>
  ) : null;

  return (
    <Box minWidth={props.buttonMinWidth}>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={() => setOpen(true)}
        disabled={!enable}
      >
        {t("collaborativeCare:patientDetails.immediateSurveyForm.title")}
      </Button>
      {dialog}
    </Box>
  );
}

type ScaleResult = DigUnpacked<
  SendImmediateSurveyMutation,
  [
    "collaborativeCareSendImmediateSurvey",
    "result",
    "patientSession",
    "assessmentReport",
    "invitations",
    "reportScales",
    "scale"
  ]
>;

type InvitationResult = DigUnpacked<
  SendImmediateSurveyMutation,
  ["collaborativeCareSendImmediateSurvey", "result", "patientSession", "assessmentReport", "invitations"]
>;

type SentSurveysSummary = {
  scales: Array<ScaleResult>;
  invitationId: InvitationId;
  takingUserNames: string;
};

type SentSurveysDisplayProps = {
  remoteData: MutationRemoteDataResult<SendImmediateSurveyResultsType> | null;
  patient: PatientDetails;
};

function SentSurveysDisplay(props: SentSurveysDisplayProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const currentProviderId = useCurrentProviderId();
  if (props.remoteData && currentProviderId) {
    return props.remoteData.caseOf({
      NotAsked: () => <Alert severity="info">{t("collaborativeCare:surveyManagement.loading")}</Alert>,
      Loading: () => <Alert severity="info">{t("collaborativeCare:surveyManagement.loading")}</Alert>,
      Failure: () => <Alert severity="error">{t("collaborativeCare:surveyManagement.failure")}</Alert>,
      Success: (response) => {
        if (!response) {
          return <Alert severity="error">{t("collaborativeCare:surveyManagement.failure")}</Alert>;
        }
        //this is the element we'll dynamically insert rows into to form a table of what we've sent out
        let scalesContent = <></>;

        const sentSurveys: Array<SentSurveysSummary> = [];

        //I don't think it's actually ever happening that "reportScaleParticipants" has more than one participant
        //but it seems like the data model supports it, so I wrote the code to support it to, even if it doesn't really happen
        response.patientSession.assessmentReport?.invitations.forEach(function (i: InvitationResult) {
          const summary: SentSurveysSummary = {
            scales: [],
            invitationId: i.id,
            takingUserNames: i.reportScaleParticipants
              .map((p) => {
                return p.user.name;
              })
              .filter(function (value, index, self) {
                return self.indexOf(value) === index;
              })
              .join(", "),
          };
          i.reportScales.forEach(function (rs) {
            summary.scales.push(rs.scale);
          });
          sentSurveys.push(summary);
        });

        //for each unique participant group...
        const uniqueNames: Array<string> = sentSurveys
          .map((s) => s.takingUserNames)
          .filter(function (value, index, self) {
            return self.indexOf(value) === index;
          });

        uniqueNames.forEach((uniqueNameGroup) => {
          //get only the surveys that belong to this group
          const summariesForGroup = sentSurveys.filter((s) => s.takingUserNames == uniqueNameGroup);

          //get all the survey names we're going to administer for this group
          const scaleNamesForGroup = summariesForGroup
            .map((summary) => {
              return summary.scales;
            })
            .flat()
            .map((scale: ScaleResult) => {
              return scale.shortname;
            });

          //just take any from the group and use that information to make a row
          if (summariesForGroup[0]) {
            scalesContent = (
              <>
                <>{scalesContent}</>
                <SentSurveyRow
                  takingUserNames={summariesForGroup[0].takingUserNames}
                  scaleNamesArray={scaleNamesForGroup}
                  invitationId={summariesForGroup[0].invitationId}
                  patient={props.patient}
                />
              </>
            );
          }
        });

        return (
          <>
            <Typography>
              {t("collaborativeCare:patientDetails.immediateSurveyForm.administerInterviewHelp")}
            </Typography>
            <ShadedTable>
              <TableBody>{scalesContent}</TableBody>
            </ShadedTable>
          </>
        );
      },
    });
  }
  return <>{t("collaborativeCare:patientDetails.immediateSurveyForm.administerInterviewProviderWarning")}</>;
}

type SentSurveyRowProps = {
  takingUserNames: string;
  scaleNamesArray: Array<string | null>;
  invitationId: InvitationId;
  patient: PatientDetails;
};

function SentSurveyRow(props: SentSurveyRowProps): ReactElement {
  const currentProviderId = useCurrentProviderId();
  const { t } = useTranslation(["collaborativeCare"]);
  const navigate = useNavigate();

  if (!currentProviderId) {
    return (
      <>{t("collaborativeCare:patientDetails.immediateSurveyForm.administerInterviewProviderWarning")}</>
    );
  }
  const [createTask] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareCreateTask,
    useCreateTaskMutation({
      refetchQueries: refetchQueries("tasks"),
    })
  );
  const [beginTimeEntryLog] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareStartTimeEntryLogNow,
    useBeginTimeEntryLogMutation()
  );

  const task = {
    title: t("collaborativeCare:patientDetails.immediateSurveyForm.taskTitle", {
      scaleNames: props.scaleNamesArray.join(","),
      recipients: props.takingUserNames,
    }),
    body: t("collaborativeCare:patientDetails.immediateSurveyForm.taskBody", {
      recipients: props.takingUserNames,
      scaleNames: props.scaleNamesArray.join(","),
      invitationId: props.invitationId,
    }),
    dueAt: new Date(),
    patientId: props.patient.id,
    assignedToId: currentProviderId,
  };
  const onClick = () => {
    createTask({
      variables: {
        input: {
          title: task.title,
          body: task.body,
          dueAt: task.dueAt,
          patientId: task.patientId,
          assignedToId: task.assignedToId,
          addToDefaultList: true,
        },
      },
    }).then((response) => {
      const taskId = response.data?.collaborativeCareCreateTask?.result?.task.id;
      if (taskId) {
        beginTimeEntryLog({
          refetchQueries: refetchQueries("timeEntries"),
          variables: {
            input: {
              taskId: taskId,
              providerId: task.assignedToId,
              clientStartTime: new Date(),
              forceStart: true,
            },
          },
        }).then(() => {
          navigate(`/provider/assessment/invitation/${props.invitationId}/edit`);
        });
      }
    });
  };
  return (
    <TableRow>
      <TableCell>{props.takingUserNames}</TableCell>
      <TableCell>{props.scaleNamesArray.join(", ")} </TableCell>
      <TableCell>
        <Button variant="contained" color="secondary" onClick={onClick}>
          {t("collaborativeCare:enrollment.actions.administerSurvey", {
            count: props.scaleNamesArray.length,
          })}
        </Button>
      </TableCell>
    </TableRow>
  );
}

type SendImmediateSurveyFormProps = {
  patient: PatientDetails;
  careEpisodeId: CareEpisodeId;
  onSuccess?: () => void;
  onOpenFollowUp?: (remoteData: MutationRemoteDataResult<SendImmediateSurveyResultsType> | null) => void;
};

function SendImmediateSurveyForm(props: SendImmediateSurveyFormProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const [selectedAdministrationChoice, setSelectedAdministrationChoice] = React.useState(
    InvitationFormat.SELF_REPORT
  );

  const [sendSurvey, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareSendImmediateSurvey,
    useSendImmediateSurveyMutation({
      // Refetching so the new survey shows up in the list of scheduled surveys. Not refetching the constructs data
      // because nothing actually shows up in there until they fill out the survey.
      refetchQueries: refetchQueries("sessions"),
    })
  );

  const fields = {
    scales: useListField<ScaleDescription>({
      default: [],
      required: true,
      validate: (scales) => {
        if (scales.length === 0) {
          return t("collaborativeCare:patientDetails.immediateSurveyForm.noScales");
        }
        return null;
      },
    }),
    administrationChoice: useWrappedField<InvitationFormat>({
      default: InvitationFormat.SELF_REPORT,
      required: true,
    }),
  };

  const form = useForm({
    fields: fields,
    submit: () => {
      sendSurvey({
        variables: {
          input: {
            careEpisodeId: props.careEpisodeId,
            scaleIds: fields.scales.value.map((scale) => scale.id),
            administrationChoice: selectedAdministrationChoice,
          },
        },
      });
    },
    remoteData: remoteData,
    onSuccess: () => {
      // if the user has opted to send notifications, close the modal immediately, as there's nothing left to do
      if (props.onSuccess && selectedAdministrationChoice == InvitationFormat.SELF_REPORT) {
        props.onSuccess();
        // otherwise, if the user opted to administer the scales via interview, take them to the screen that lets them do that
      } else if (props.onOpenFollowUp && selectedAdministrationChoice == InvitationFormat.INTERVIEW) {
        props.onOpenFollowUp(remoteData);
      }
    },
  });

  const content = (
    <Form onSubmit={form.onSubmit}>
      <FormOverlay
        response={remoteData}
        errorMessage={t("collaborativeCare:patientDetails.immediateSurveyForm.genericFormError")}
      />
      <Stack direction="column" spacing={1}>
        <ScalePickerMultiple
          value={fields.scales.value}
          onChange={fields.scales.onChange}
          error={fields.scales.error}
          helperText={fields.scales.helperText}
          // Hide the inline chips because we're going to show the scale names below the picker.
          autocompleteProps={{ renderTags: () => null }}
        />
        {/* This box is here to give the first couple measures they pick room to drop in without reflowing the
            rest of the form. If they pick a bunch it'll grow but I expect the median case is like 2-3. */}
        <Box minHeight="15rem">
          {fields.scales.value.map((scale, i) => {
            return (
              <Stack direction="row" spacing={1} key={i} alignItems="center">
                <Typography flexGrow={1}>{scale.name}</Typography>
                <IconButton onClick={() => fields.scales.removeNthValue(i)}>
                  <Close fontSize="small" />
                </IconButton>
              </Stack>
            );
          })}
        </Box>

        <Grid direction={"row-reverse"} container columns={4}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="SELF_REPORT"
            name="radio-buttons-group"
          >
            <Typography align="right">
              {t("collaborativeCare:enrollment.sendNotification")}
              <Radio
                value="SELF_REPORT"
                onClick={() => {
                  setSelectedAdministrationChoice(InvitationFormat.SELF_REPORT);
                }}
              ></Radio>
            </Typography>
            <Typography align="right">
              {t("collaborativeCare:enrollment.administerManually")}
              <Radio
                value="INTERVIEW"
                onClick={() => {
                  setSelectedAdministrationChoice(InvitationFormat.INTERVIEW);
                }}
              ></Radio>
            </Typography>
          </RadioGroup>
        </Grid>
        <Stack direction="row-reverse">
          <ButtonWithSpinner
            variant="contained"
            color="secondary"
            type="submit"
            showSpinner={form.showSpinner}
            disabled={form.disableSubmit}
          >
            {t("collaborativeCare:patientDetails.immediateSurveyForm.sendAction")}
          </ButtonWithSpinner>
        </Stack>
      </Stack>
    </Form>
  );

  return content;
}
