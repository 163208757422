import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  useTheme,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import { Axis, Grid, Tooltip, XYChart, BarStack, BarSeries } from "@visx/xychart";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  ChartData,
  ScaleThresholdClass,
  useEnrollmentAcuityStatsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React from "react";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { useTranslation } from "react-i18next";
import { ProviderId, InstituteId } from "Lib/Ids";
import { freshStyles } from "Shared/Scale/SeverityChip";

const dateAccessor = (d: ChartData) => d.date;
const valueAccessor = (d: ChartData) => d.value;

const yAccessor = (d: ChartData) => {
  return percentage(d);
};

const percentage = (d: ChartData): string => {
  if (!d.label) return "0";
  return d.label.toFixed(2);
};

const percentageString = (d: ChartData): string => {
  return percentage(d) + "%";
};

export default function EnrollmentAcuityChart(props: {
  startDate: Date | null;
  endDate: Date | null;
  showNone: boolean;
  showLow: boolean;
  showMild: boolean;
  showModerate: boolean;
  showModSevere: boolean;
  showSevere: boolean;
  careManagerIds: ReadonlyArray<ProviderId>;
  instituteId: InstituteId | null;
}) {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const theme = useTheme();

  const [showNone, setShowNone] = React.useState<boolean>(props.showNone);
  const [showLow, setShowLow] = React.useState<boolean>(props.showLow);
  const [showMild, setShowMild] = React.useState<boolean>(props.showMild);
  const [showModerate, setShowModerate] = React.useState<boolean>(props.showModerate);
  const [showModSevere, setShowModSevere] = React.useState<boolean>(props.showModSevere);
  const [showSevere, setShowSevere] = React.useState<boolean>(props.showSevere);

  const dateFormat = (date: Date): string => {
    return t("common:date.monthYear", { date });
  };

  const { remoteData } = apolloQueryHookWrapper(
    useEnrollmentAcuityStatsQuery({
      variables: {
        careManagerIds: props.careManagerIds,
        startDate: props.startDate,
        endDate: props.endDate,
        instituteId: props.instituteId,
      },
    })
  );
  const bars: Array<ReactJSXElement> = [];
  let content = <>Loading</>;

  const colorAccessor = (d: ChartData) => {
    switch (d.baselineAcuity) {
      case "none":
        return freshStyles(ScaleThresholdClass.NONE, theme).backgroundColor;
      case "low":
        return freshStyles(ScaleThresholdClass.LOW, theme).backgroundColor;
      case "mild":
        return freshStyles(ScaleThresholdClass.MILD, theme).backgroundColor;
      case "moderate":
        return freshStyles(ScaleThresholdClass.MODERATE, theme).backgroundColor;
      case "modsevere":
        return freshStyles(ScaleThresholdClass.MOD_SEVERE, theme).backgroundColor;
      case "severe":
        return freshStyles(ScaleThresholdClass.SEVERE, theme).backgroundColor;
      default:
        return undefined;
    }
  };

  remoteData.caseOf({
    Loading: () => {},
    NotAsked: () => null,
    Failure: () => {
      content = <>Failed to load chart</>;
    },
    Success: (result) => {
      if (showNone) {
        bars.push(
          <BarSeries
            key="none"
            dataKey={t("collaborativeCare:performanceCharts.acuity.minimal")}
            data={
              result.collaborativeCareEnrollmentAcuityStats?.filter(
                (datum) => datum.baselineAcuity === "none"
              ) || []
            }
            xAccessor={dateAccessor}
            yAccessor={yAccessor}
            aria-label={t("collaborativeCare:performanceCharts.acuity.minimal")}
            colorAccessor={colorAccessor}
          />
        );
      }
      if (showLow) {
        bars.push(
          <BarSeries
            key="low"
            dataKey={t("collaborativeCare:performanceCharts.acuity.low")}
            data={
              result.collaborativeCareEnrollmentAcuityStats?.filter(
                (datum) => datum.baselineAcuity === "low"
              ) || []
            }
            xAccessor={dateAccessor}
            yAccessor={yAccessor}
            aria-label={t("collaborativeCare:performanceCharts.acuity.low")}
            colorAccessor={colorAccessor}
          />
        );
      }
      if (showMild) {
        bars.push(
          <BarSeries
            key="mild"
            dataKey={t("collaborativeCare:performanceCharts.acuity.mild")}
            data={
              result.collaborativeCareEnrollmentAcuityStats?.filter(
                (datum) => datum.baselineAcuity === "mild"
              ) || []
            }
            xAccessor={dateAccessor}
            yAccessor={yAccessor}
            aria-label={t("collaborativeCare:performanceCharts.acuity.mild")}
            colorAccessor={colorAccessor}
          />
        );
      }
      if (showModerate) {
        bars.push(
          <BarSeries
            key="moderate"
            dataKey={t("collaborativeCare:performanceCharts.acuity.moderate")}
            data={
              result.collaborativeCareEnrollmentAcuityStats?.filter(
                (datum) => datum.baselineAcuity === "moderate"
              ) || []
            }
            xAccessor={dateAccessor}
            yAccessor={yAccessor}
            aria-label={t("collaborativeCare:performanceCharts.acuity.moderate")}
            colorAccessor={colorAccessor}
          />
        );
      }
      if (showModSevere) {
        bars.push(
          <BarSeries
            key="modSevere"
            dataKey={t("collaborativeCare:performanceCharts.acuity.modSevere")}
            data={
              result.collaborativeCareEnrollmentAcuityStats?.filter(
                (datum) => datum.baselineAcuity === "modsevere"
              ) || []
            }
            xAccessor={dateAccessor}
            yAccessor={yAccessor}
            aria-label={t("collaborativeCare:performanceCharts.acuity.modSevere")}
            colorAccessor={colorAccessor}
          />
        );
      }
      if (showSevere) {
        bars.push(
          <BarSeries
            key="severe"
            dataKey={t("collaborativeCare:performanceCharts.acuity.severe")}
            data={
              result.collaborativeCareEnrollmentAcuityStats?.filter(
                (datum) => datum.baselineAcuity === "severe"
              ) || []
            }
            xAccessor={dateAccessor}
            yAccessor={yAccessor}
            aria-label={t("collaborativeCare:performanceCharts.acuity.severe")}
            colorAccessor={colorAccessor}
          />
        );
      }
      content = (
        <Stack direction="column" spacing={0}>
          <XYChart
            height={300}
            xScale={{ type: "band", paddingInner: 0.6, paddingOuter: 0.1 }}
            yScale={{ type: "linear", domain: [0, 100] }}
            margin={{ top: 20, right: 20, bottom: 40, left: 40 }}
          >
            <Axis
              orientation="left"
              numTicks={6}
              hideAxisLine
              hideTicks
              tickFormat={function (t) {
                return t + "%";
              }}
            />
            <Axis
              orientation="bottom"
              hideAxisLine
              hideTicks
              rangePadding={50}
              labelOffset={200}
              tickFormat={dateFormat}
            />
            <Grid columns={false} numTicks={6} strokeDasharray="3 3" />
            <BarStack>{bars}</BarStack>
            <Tooltip
              snapTooltipToDatumX
              snapTooltipToDatumY
              showVerticalCrosshair
              renderTooltip={({ tooltipData, colorScale }) => {
                if (!tooltipData || !colorScale) {
                  return <></>;
                }
                let tooltipElement = <></>;
                let dateAdded = false;
                ["Severe", "ModSevere", "Moderate", "Mild", "Low", "Minimal"].forEach(function (acuityKey) {
                  const datum = tooltipData.datumByKey[acuityKey];
                  if (!datum) {
                    return;
                  }
                  if (!dateAdded) {
                    tooltipElement = (
                      <>{t("common:date.monthYear", { date: dateAccessor(datum.datum as ChartData) })}</>
                    );
                    dateAdded = true;
                  }
                  tooltipElement = (
                    <>
                      {tooltipElement}
                      <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <span style={{ color: colorAccessor(datum.datum as ChartData) }}>{acuityKey}</span>
                        {": "}
                        {valueAccessor(datum.datum as ChartData)} (
                        {percentageString(datum.datum as ChartData)})
                      </div>
                    </>
                  );
                });

                return <>{tooltipElement}</>;
              }}
            />
          </XYChart>
          <FormGroup row={true}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showNone}
                  onChange={function (e, val) {
                    setShowNone(val);
                  }}
                />
              }
              label={t("collaborativeCare:performanceCharts.acuity.minimal")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showLow}
                  onChange={function (e, val) {
                    setShowLow(val);
                  }}
                />
              }
              label={t("collaborativeCare:performanceCharts.acuity.low")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showMild}
                  onChange={function (e, val) {
                    setShowMild(val);
                  }}
                />
              }
              label={t("collaborativeCare:performanceCharts.acuity.mild")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showModerate}
                  onChange={function (e, val) {
                    setShowModerate(val);
                  }}
                />
              }
              label={t("collaborativeCare:performanceCharts.acuity.moderate")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showModSevere}
                  onChange={function (e, val) {
                    setShowModSevere(val);
                  }}
                />
              }
              label={t("collaborativeCare:performanceCharts.acuity.modSevere")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showSevere}
                  onChange={function (e, val) {
                    setShowSevere(val);
                  }}
                />
              }
              label={t("collaborativeCare:performanceCharts.acuity.severe")}
            />
          </FormGroup>
        </Stack>
      );
    },
  });
  return (
    <Card>
      <CardHeader
        title={t("collaborativeCare:performanceCharts.acuity.title")}
        subheader={t("collaborativeCare:performanceCharts.acuity.description")}
      />
      <CardContent>{content}</CardContent>
    </Card>
  );

  return <>{content}</>;
}
