import React, { ReactElement } from "react";
import { TaskDetails, TimeEntryDetails } from "./TaskCard";
import { Box, Divider, Grid, Stack, TextField, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { TimeEntryLog, useAddNoteToTaskMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { Form, FormOverlay, useForm, useTextField } from "Shared/Form";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { TaskId } from "Lib/Ids";
import { TimeEntryEditIconButton } from "CollaborativeCare/TimeEntry/TimeEntryEditIconButton";
import { PickTypename } from "type-utils";
import { TimeEntryLogDeleteIconButton } from "CollaborativeCare/TimeEntry/TimeEntryDeleteIconButton";
import { refetchQueries } from "Lib/RefetchQueries";
import { useIsMobile } from "Shared/Responsive";
import EditableTaskBody from "./EditableTaskBody";
import { EditableTaskCardNote } from "./EditableTaskCardNote";

export function TaskCardBody({ task }: { task: TaskDetails }): ReactElement {
  let bodyContent = <EditableTaskBody task={task} />;
  if (task.isPlaceholder) {
    bodyContent = <></>;
  }
  return (
    <Stack direction="column" spacing={1}>
      {bodyContent}
      <AddNoteForm taskId={task.id} />
      <TaskLogs
        notes={task.notes}
        timeEntries={task.timeEntries}
        id={task.id}
        title={task.title}
        patient={task.patient}
        isPlaceholder={task.isPlaceholder}
      />
    </Stack>
  );
}

function AddNoteForm(props: { taskId: TaskId }): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [addNote, { remoteData, reset }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareAddNoteToTask,
    useAddNoteToTaskMutation({
      refetchQueries: refetchQueries("tasks"),
    })
  );

  const fields = {
    text: useTextField({ required: true }),
  };

  const form = useForm({
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      addNote({
        variables: {
          input: {
            // Safe cast because validation has passed when we get here
            text: fields.text.value as string,
            taskId: props.taskId,
          },
        },
      });
    },
  });

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        // Let the success overlay linger for a bit so they get the feedback it worked (the note will also show up
        // inline), then clear the form.
        setTimeout(() => {
          reset();
          form.reset();
        }, 500);
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  const columns = useIsMobile() ? 6 : 12;
  const flexBox = useIsMobile() ? null : <Box sx={{ flexGrow: 1 }} />;

  return (
    <Form onSubmit={form.onSubmit}>
      <FormOverlay response={remoteData} errorMessage={t("collaborativeCare:tasks.genericNoteFormError")} />
      <Grid container columns={columns} spacing={1}>
        <Grid item xs={9}>
          <TextField
            label={t("collaborativeCare:tasks.newNote")}
            multiline
            minRows={1}
            value={fields.text.value}
            onChange={fields.text.onChange}
            error={fields.text.error}
            helperText={fields.text.helperText}
            sx={{ flexGrow: 1 }}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" alignItems="center">
            {flexBox}
            <ButtonWithSpinner
              variant="contained"
              type="submit"
              color="secondary"
              showSpinner={form.showSpinner}
              disabled={form.disableSubmit}
              sx={{ marginTop: "0.6em" }}
            >
              {t("collaborativeCare:tasks.actions.addNote")}
            </ButtonWithSpinner>
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
}

function TaskLogs(
  props: Pick<TaskDetails, "notes" | "timeEntries" | "id" | "title" | "patient" | "isPlaceholder">
): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const theme = useTheme();

  const nonEmptyTimeEntries = props.timeEntries.nodes.filter(
    (entry) => entry.durationSeconds !== null || entry.endTime !== null
  );

  const notesAndTimeEntries = [...props.notes.nodes, ...nonEmptyTimeEntries];
  notesAndTimeEntries.sort((left, right) => {
    // Notes are considered to have "happened" when they were written, time entries when they were started. This is a
    // log of what happened when, not a log when we recorded what happened.
    let leftTime = null;
    if (left.__typename === "Note") {
      if (left.timeEntry) {
        leftTime = left.timeEntry.startTime;
      } else {
        leftTime = left.createdAt;
      }
    } else {
      leftTime = left.startTime;
    }
    let rightTime = null;
    if (right.__typename === "Note") {
      if (right.timeEntry) {
        rightTime = right.timeEntry.startTime;
      } else {
        rightTime = right.createdAt;
      }
    } else {
      rightTime = right.startTime;
    }

    // in the case of a tie between a time entry and a note, put the time entry above the note
    if (left.__typename != right.__typename && rightTime.toString() == leftTime.toString()) {
      if (left.__typename == "TimeEntryLog") {
        return -1;
      } else {
        return 1;
      }
    }

    // Reverse chronological order, like a blog.
    return leftTime < rightTime ? 1 : -1;
  });

  const noLogs = <Typography fontStyle="italic">{t("collaborativeCare:tasks.noLogs")}</Typography>;

  return (
    <Stack direction="column" spacing={1} paddingTop={1}>
      <Typography variant="h2" borderBottom={`1px solid ${theme.palette.dividerLight}`}>
        {t("collaborativeCare:tasks.logsTitle")}
      </Typography>
      <Stack
        direction="column"
        spacing={1}
        divider={<Divider sx={{ borderColor: theme.palette.dividerChip }} />}
      >
        {notesAndTimeEntries.length === 0 ? noLogs : null}
        {notesAndTimeEntries.map((noteOrEntry) => {
          if (noteOrEntry.__typename === "Note") {
            return <EditableTaskCardNote note={noteOrEntry} key={noteOrEntry.id.toString()} />;
          } else {
            return (
              <TaskCardTimeEntry
                timeEntryLog={noteOrEntry}
                key={noteOrEntry.id.toString()}
                title={props.title}
                id={props.id}
                patient={props.patient}
                isPlaceholder={props.isPlaceholder}
              />
            );
          }
        })}
      </Stack>
    </Stack>
  );
}

function TaskCardTimeEntry(
  props: { timeEntryLog: TimeEntryDetails } & Pick<TaskDetails, "id" | "title" | "patient" | "isPlaceholder">
): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const minutes = props.timeEntryLog.durationSeconds ? props.timeEntryLog.durationSeconds / 60 : 0;
  const endTimeContent = props.timeEntryLog.endTime ? (
    <Typography fontWeight="bold">{t("common:date.time", { date: props.timeEntryLog.endTime })}</Typography>
  ) : null;

  const updatedTimeEntryLog: PickTypename<TimeEntryLog, "id" | "clientStartTime" | "durationSeconds"> = {
    __typename: "TimeEntryLog",
    id: props.timeEntryLog.id,
    clientStartTime: props.timeEntryLog.startTime,
    durationSeconds: props.timeEntryLog.durationSeconds,
  };

  const timeEntryLogContent = (
    <>
      <Typography>
        {props.timeEntryLog.provider.name}, {t("common:date.long", { date: props.timeEntryLog.startTime })}
      </Typography>

      <Stack direction="row" spacing={0}>
        <Typography fontWeight="bold">
          {t("collaborativeCare:tasks.timeEntryLogMinutes", {
            count: Math.round(minutes),
          })}
        </Typography>
        <Typography fontWeight="bold">
          &nbsp;{t("common:date.time", { date: props.timeEntryLog.startTime })}
        </Typography>
        <Typography>&nbsp;-&nbsp;</Typography>
        {endTimeContent}
      </Stack>
    </>
  );

  const columns = useIsMobile() ? 6 : 12;

  // There's some fuckery with all the layers of stuff above this that causes these buttons
  // to break out of their box pretty easily and it looks really bad. We're going to stack them
  // vertically purely via grid when on desktop, but use the old row stacking on mobile.
  const timeEntryEditButton = (
    <Box>
      <TimeEntryEditIconButton
        timeEntryLog={updatedTimeEntryLog}
        task={{ id: props.id, title: props.title, isPlaceholder: props.isPlaceholder }}
        patient={props.patient}
      />
    </Box>
  );
  const timeEntryDeleteButton = (
    <Box marginTop={"1em"}>
      <TimeEntryLogDeleteIconButton timeEntryLog={updatedTimeEntryLog} patient={props.patient}>
        {timeEntryLogContent}
      </TimeEntryLogDeleteIconButton>
    </Box>
  );
  let timeEntryEditButtons = (
    <Box marginLeft={"0.5em"}>
      {timeEntryEditButton}
      {timeEntryDeleteButton}
    </Box>
  );
  if (useIsMobile()) {
    timeEntryEditButtons = (
      <Stack direction="row" spacing={1} marginTop="1em">
        {timeEntryEditButton}
        {timeEntryDeleteButton}
      </Stack>
    );
  }

  // Note that putting spacing on the grid container seems to really break the model for unknown
  // reasons. Keep it at zero unless you are up for reworking this.
  return (
    <Grid container columns={columns} spacing={0}>
      <Grid item xs={10}>
        {timeEntryLogContent}
      </Grid>
      <Grid item xs={2}>
        {timeEntryEditButtons}
      </Grid>
    </Grid>
  );
}
