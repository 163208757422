import { ButtonProps, Tooltip } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";
import { useCurrentTimeTracking } from "Contexts/TimeTracking/CurrentTimeTrackingContext";
import { useTranslation } from "react-i18next";
import { Task, TaskStatus, useBeginTimeEntryLogMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { PatientId, TaskId } from "Lib/Ids";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import React, { ReactElement } from "react";
import { PickTypename } from "type-utils";
import { refetchQueries } from "Lib/RefetchQueries";
import { taskStatusT } from "GeneratedGraphQL/EnumTranslations";

export enum activatedTaskTimerStates {
  CURRENT_TASK_TIMER_ACTIVE = "CURRENT_TASK_TIMER_ACTIVE",
  ANOTHER_TASK_TIMER_ACTIVE = "ANOTHER_TASK_TIMER_ACTIVE",
  NO_TIMER_ACTIVE = "NO_TIMER_ACTIVE",
  UNKNOWN = "UNKNOWN",
}

export function useCheckForActiveTaskTimer(taskId: TaskId): activatedTaskTimerStates {
  const myActiveTimer = useCurrentTimeTracking();
  if (myActiveTimer.status === "tracking") {
    if (myActiveTimer.task.id === taskId) {
      return activatedTaskTimerStates["CURRENT_TASK_TIMER_ACTIVE"];
    }
    return activatedTaskTimerStates["ANOTHER_TASK_TIMER_ACTIVE"];
  } else if (myActiveTimer.status === "not tracking") {
    return activatedTaskTimerStates["NO_TIMER_ACTIVE"];
  } else {
    // myActiveTimer.status === "unknown"
    return activatedTaskTimerStates["UNKNOWN"];
  }
}

type TimerButtonProps = ButtonProps & {
  task: PickTypename<Task, "id" | "status">;
  patientId: PatientId | undefined;
  buttonLabel: string;
  buttonCompleteLabel: string;
  onSuccess?: () => void;
};

export function TimerBeginButton(props: TimerButtonProps): ReactElement | null {
  const { t } = useTranslation(["collaborativeCare", "enums"]);
  let clientStartTime = new Date();

  // I could be convinced that provider ID should be passed in, but for now
  // i'm putting the logic here since it's more likely to be calculated the same way
  // everytime.
  const currentProvider = React.useContext(AuthenticatedProviderUserContext);
  if (!currentProvider) {
    return null;
  }

  const currentProviderId = currentProvider.providerId.getOrElse(null);
  if (!currentProviderId) {
    return null;
  }

  const [beginTimer, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareStartTimeEntryLogNow,
    useBeginTimeEntryLogMutation({
      refetchQueries: refetchQueries("timeEntries"),
      variables: {
        input: {
          taskId: props.task.id,
          providerId: currentProviderId,
          clientStartTime: clientStartTime,
        },
      },
    })
  );

  const timerState = useCheckForActiveTaskTimer(props.task.id);

  const isRecording =
    remoteData.kind === "Loading" || timerState == activatedTaskTimerStates["CURRENT_TASK_TIMER_ACTIVE"];
  const isDisabled =
    remoteData.kind === "Loading" ||
    timerState === activatedTaskTimerStates["ANOTHER_TASK_TIMER_ACTIVE"] ||
    timerState == activatedTaskTimerStates["CURRENT_TASK_TIMER_ACTIVE"] ||
    timerState == activatedTaskTimerStates["UNKNOWN"] ||
    props.task.status != TaskStatus.ACTIVE;

  const label =
    props.task.status != TaskStatus.ACTIVE
      ? taskStatusT(props.task.status as TaskStatus, t)
      : props.buttonLabel;

  const onClick = () => {
    clientStartTime = new Date();
    beginTimer();
  };

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        if (props.onSuccess) {
          props.onSuccess();
        }
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  // We need to prune some extraneous stuff from our button props or we'll get spammed
  // with warnings.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { patientId, buttonCompleteLabel, buttonLabel, ...buttonProps } = props;

  if (!isDisabled) {
    return (
      <ButtonWithSpinner showSpinner={isRecording} disabled={isDisabled} onClick={onClick} {...buttonProps}>
        {label}
      </ButtonWithSpinner>
    );
  } else {
    const tooltip =
      timerState === activatedTaskTimerStates["CURRENT_TASK_TIMER_ACTIVE"]
        ? t("collaborativeCare:tasks.actions.tooltips.disabledTaskAlreadyStarted")
        : props.task.status != TaskStatus.ACTIVE
        ? t("collaborativeCare:tasks.actions.tooltips.disabledAlreadyComplete")
        : t("collaborativeCare:tasks.actions.tooltips.disabledStartBlockedByTracking");

    return (
      <Tooltip title={tooltip} arrow placement="right-end">
        <div>
          <ButtonWithSpinner
            showSpinner={isRecording}
            disabled={isDisabled}
            onClick={onClick}
            {...buttonProps}
          >
            {label}
          </ButtonWithSpinner>
        </div>
      </Tooltip>
    );
  }
}
