import { Link as RouterLink, useParams } from "react-router-dom";
import {
  InstituteCareEpisodeQuery,
  useInstituteCareEpisodeQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { fromNullableString } from "Lib/Id";
import { default as React, ReactElement } from "react";
import ErrorMessage from "Shared/ErrorMessage";
import DetailsLayout from "../DetailsLayout";
import { Dig } from "type-utils";
import { Link } from "@mui/material";
import CareEpisodeDecisionBadge from "./CareEpisodeDecisionBadge";
import { InternalBreadcrumbPath } from "../InstituteDetailsRoute";

function InstituteCareEpisode(props: {
  setSubCrumbs: (subCrumbs: Array<InternalBreadcrumbPath>) => void;
}): ReactElement {
  const params = useParams<{ careEpisodeId?: string }>();

  return fromNullableString<"CareEpisode">(params.careEpisodeId).caseOf({
    Ok: (careEpisodeId) => (
      <DetailsLayout
        queryResult={useInstituteCareEpisodeQuery({
          variables: {
            careEpisodeId: careEpisodeId,
          },
        })}
        setSubCrumbs={props.setSubCrumbs}
        subCrumbs={(x) => [
          { text: "Patients", path: "/patients" },
          { text: x.patient.name, path: `/patients/${x.patient.id}` },
          { text: "Care Episodes", path: `/patients/${x.patient.id}` },
          { text: x.id.toString(), path: `/careEpisodes/${x.id}` },
        ]}
        headerContents={(x) => <CareEpisodeDecisionBadge careEpisode={x} />}
        browserTitle={() => "CareEpisode"}
        titleAction={(x) => <div>({x.id.toString()})</div>}
        resultFetcher={(x: InstituteCareEpisodeQuery) => x.careEpisode}
        title={(_x) => "CareEpisode"}
        overviewFields={(x) => overviewFields(x)}
        tabs={() => []}
      />
    ),

    Err: () => <ErrorMessage message="Invalid URL" />,
  });
}

function overviewFields(dat: NonNullable<Dig<InstituteCareEpisodeQuery, ["careEpisode"]>>) {
  return [
    { name: "id", value: dat.id.toString() },
    {
      name: "Patient",
      value: (
        <Link component={RouterLink} to={`../../patients/${dat.patient.id}`}>
          {dat.patient.name}
        </Link>
      ),
    },
    { name: "Status", value: dat.status },
    { name: "Measurement Allowed", value: dat.measurementAllowed },
    { name: "periodStart", value: dat.periodStart?.toLocaleString() },
    { name: "periodEnd", value: dat.periodEnd?.toLocaleString() },
    { name: "name", value: dat.name },
    { name: "shortname", value: dat.shortname },
  ];
}

export default InstituteCareEpisode;
