import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  gridDateComparator,
  GridRenderCellParams,
  GridSortCellParams,
  GridSortDirection,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { CreateTaskTrigger } from "CollaborativeCare/CreateOrEditTaskForm";
import { TaskDetails } from "CollaborativeCare/Tasks/TaskCard/TaskCard";
import { TaskCell } from "CollaborativeCare/Tasks/TaskList/DesktopTaskList";
import { TimerBeginButton } from "CollaborativeCare/TimeEntry/TimerBeginButton";
import { taskStatusT } from "GeneratedGraphQL/EnumTranslations";
import {
  Patient,
  SortDirection,
  Task,
  TaskSortParameter,
  TaskStatus,
  useTasksCardQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { EnrollmentId, PatientId, TaskId } from "Lib/Ids";
import EnumSelect from "Shared/EnumSelect";
import { subYears } from "date-fns";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { CopyMonthlySummaryButton } from "./CopyMonthlySummaryButton";
import { TaskCardBack } from "CollaborativeCare/Tasks/TaskCard/TaskCardBack";
import { useCurrentTask } from "Contexts/CurrentTaskContext";
import { ReadOnlyOrImpersonatingDisabledButton } from "Shared/ContextDisabledButtons";
import { PatientDetailsDataGridContainer } from "./PatientDetailsDataGridContainer";

export type TasksCardListFilters = {
  date: Date; // We're going to keep the filter as a date for convenience with a date picker.
  status: TaskStatus | null;
  enrollmentId: EnrollmentId | null;
  billableMinutesGreaterThanZero?: boolean;
};

type TasksCardProps = {
  patient: PickTypename<Patient, "id" | "name">;
  forPreviousEnrollment?: boolean;
  enrollmentId?: EnrollmentId;
};
export default function TasksCard(props: TasksCardProps): ReactElement {
  const [openDetails, setOpenDetails] = React.useState(false);
  const task = useCurrentTask();
  const { t } = useTranslation(["collaborativeCare"]);

  // These are the filters that we'll use to control what tasks we actually display, and
  // how we decide what minutes count for a given month.
  const defaultStatusFilter = props.forPreviousEnrollment ? TaskStatus.COMPLETE : TaskStatus.ACTIVE;

  const [filters, setFilters] = useState<TasksCardListFilters>({
    date: new Date(),
    status: defaultStatusFilter,
    enrollmentId: props.enrollmentId || null,
    billableMinutesGreaterThanZero: false,
  });

  // This just controls whether or not we'll show the filter options.
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const onShowFilterToggle = () => {
    setShowFilters(!showFilters);
  };
  // This is the actual filters we may or may not show.
  const filtersElement = showFilters ? (
    <TasksCardFiltersInputs filters={filters} onChange={setFilters} />
  ) : null;

  const createAction = props.forPreviousEnrollment ? (
    <></>
  ) : (
    <ReadOnlyOrImpersonatingDisabledButton minPiiLevel="limited_pii">
      <CreateTaskTrigger
        patient={props.patient}
        component={
          <Button fullWidth variant="contained" color="secondary">
            {t("collaborativeCare:tasks.createHeader")}
          </Button>
        }
      />
    </ReadOnlyOrImpersonatingDisabledButton>
  );

  // What do we want to show in the upper right hand corner?
  const cardAction = (
    <Stack direction="row" spacing={0.5} marginRight="0.3em">
      <Button variant="contained" color="secondary" onClick={onShowFilterToggle}>
        {t("collaborativeCare:patientDetails.tasks.actions.filters")}
      </Button>
      <Box>{createAction}</Box>
    </Stack>
  );

  let sharedCardBack = <></>;
  if (task) {
    sharedCardBack = (
      <TaskCardBack
        task={task}
        onClose={() => {
          setOpenDetails(false);
        }}
        openDetails={setOpenDetails}
        open={openDetails}
      />
    );
  }

  return (
    <>
      <Card>
        <CardHeader title={t("collaborativeCare:patientDetails.cards.tasks")} action={cardAction} />
        <CardContent>
          <Stack direction="column" spacing={1}>
            {filtersElement}
            <TasksCardList patientId={props.patient.id} filters={filters} openDetails={setOpenDetails} />
          </Stack>
        </CardContent>
      </Card>
      {sharedCardBack}
    </>
  );
}

type TasksCardListFilterInputsProps = {
  filters: TasksCardListFilters;
  onChange: (value: TasksCardListFilters) => void;
};

function TasksCardFiltersInputs(props: TasksCardListFilterInputsProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const now = new Date();
  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={1}>
        <Box maxWidth="20em">
          <DatePicker
            views={["year", "month"]}
            openTo={"month"}
            label={t("collaborativeCare:patientDetails.tasks.filters.yearAndMonth")}
            minDate={subYears(now, 5)}
            maxDate={now}
            value={props.filters.date}
            onChange={(value) => {
              props.onChange({ ...props.filters, date: value || new Date() });
            }}
          />
        </Box>
        <Typography variant="caption">
          {t("collaborativeCare:patientDetails.tasks.filters.yearAndMonthHelp")}
        </Typography>
      </Stack>
      <Box maxWidth="20em">
        <EnumSelect
          optionsEnum={TaskStatus}
          title={t("collaborativeCare:patientDetails.tasks.filters.status")}
          enumTrans={taskStatusT}
          value={props.filters.status}
          onChange={(value) => {
            props.onChange({ ...props.filters, status: value });
          }}
        />
      </Box>
      <Box maxWidth="20em">
        <FormControlLabel
          control={
            <Checkbox
              checked={props.filters.billableMinutesGreaterThanZero}
              onChange={(_value, checked) => {
                props.onChange({ ...props.filters, billableMinutesGreaterThanZero: checked });
              }}
            />
          }
          label={
            t("collaborativeCare:filters.billableMinutes") +
            " (" +
            t("common:date.monthYear", {
              date: props.filters.date,
            }) +
            ")"
          }
        />
      </Box>
    </Stack>
  );
}

function TasksCardListSkeleton(): ReactElement {
  return <Skeleton variant="rectangular" height="10em" />;
}

function TasksCardListError(): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  return <Typography>{t("collaborativeCare:patientDetails.tasks.failure")}</Typography>;
}

type TasksCardListProps = {
  patientId: PatientId;
  filters: TasksCardListFilters;
  openDetails: (val: boolean) => void;
  displayedTask?: TaskDetails;
};
function TasksCardList(props: TasksCardListProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useTasksCardQuery({
      variables: {
        patientId: props.patientId,
        sortBy: TaskSortParameter.DUE_AT,
        sortDirection: SortDirection.DESC,
        status: props.filters.status,
        year: props.filters.date.getFullYear(),
        month: props.filters.date.getMonth() + 1, // JS vs Ruby
        enrollmentId: props.filters.enrollmentId,
      },
    })
  );
  return remoteData.caseOf({
    NotAsked: () => {
      return <TasksCardListSkeleton />;
    },
    Loading: () => {
      return <TasksCardListSkeleton />;
    },
    Failure: () => {
      return <TasksCardListError />;
    },
    Success: (result) => {
      if (!result.collaborativeCareTasks) {
        return <TasksCardListError />;
      }
      // It's a little awkward to have to put the button here too, but they both need the full tasks
      // data so here we are.
      return (
        <Stack direction="column" spacing={2}>
          <TasksCardListElement
            tasks={result.collaborativeCareTasks.nodes}
            filters={props.filters}
            openDetails={props.openDetails}
          />
          <CopyMonthlySummarySection
            patientId={props.patientId}
            tasks={result.collaborativeCareTasks.nodes}
            filters={props.filters}
          />
        </Stack>
      );
    },
  });
}

type TasksCardListElementProps = {
  tasks: ReadonlyArray<TaskDetails & Pick<Task, "totalMinutesForMonth" | "createdAt">>;
  filters: TasksCardListFilters;
  openDetails: (val: boolean) => void;
};
function TasksCardListElement(props: TasksCardListElementProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare", "enums", "patients"]);

  type RowDataType = {
    id: string | TaskId;
    task?: TaskDetails;
    patientId?: PatientId | undefined;
    totalMinutesForMonth: number;
    title: string;
    status: string;
    dueAt: Date | null;
  };

  const infoRowToTheBottomSortFunction = function (sortDirection: GridSortDirection) {
    const modifier = sortDirection === "desc" ? -1 : 1;
    return (
      value1: number | string,
      value2: number | string,
      cellParams1: GridSortCellParams,
      cellParams2: GridSortCellParams
    ) => {
      if (cellParams1.id === "info") {
        return 1;
      }
      if (cellParams2.id === "info") {
        return -1;
      }
      return modifier * gridStringOrNumberComparator(value1, value2, cellParams1, cellParams2);
    };
  };

  const infoRowToTheBottomDateSortFunction = function (sortDirection: GridSortDirection) {
    const modifier = sortDirection === "desc" ? -1 : 1;
    return (value1: Date, value2: Date, cellParams1: GridSortCellParams, cellParams2: GridSortCellParams) => {
      if (cellParams1.id === "info") {
        return 1;
      }
      if (cellParams2.id === "info") {
        return -1;
      }
      return modifier * gridDateComparator(value1, value2, cellParams1, cellParams2);
    };
  };

  const columns: Array<GridColDef<RowDataType>> = [
    {
      field: "expand",
      headerName: "",
      sortable: false,
      minWidth: 50,
      width: 50,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row["task"] && params.row["id"] != "info") {
          return <TaskCell task={params.row["task"]} openDetails={props.openDetails} />;
        } else {
          return <></>;
        }
      },
      getSortComparator: infoRowToTheBottomSortFunction,
    },
    {
      field: "begin",
      headerName: "",
      sortable: false,
      minWidth: 130,
      width: 130,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row["task"] && params.row["id"] != "info") {
          return (
            <TimerBeginButton
              task={params.row["task"]}
              patientId={params.row["patientId"]}
              buttonLabel={t("collaborativeCare:tasks.actions.begin")}
              buttonCompleteLabel={t("collaborativeCare:tasks.actions.alreadyComplete")}
              variant="contained"
              color="secondary"
            />
          );
        } else {
          return <></>;
        }
      },
      getSortComparator: infoRowToTheBottomSortFunction,
    },
    {
      field: "title",
      headerName: t("collaborativeCare:fields.title.label"),
      sortable: true,
      flex: 1,
      minWidth: 250,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row["task"] && params.row["id"] != "info") {
          return <>{params.row["title"]}</>;
        } else {
          return (
            <Typography width="100%" textAlign="right">
              {t("collaborativeCare:tasks.minutesTotal")}
            </Typography>
          );
        }
      },
      getSortComparator: infoRowToTheBottomSortFunction,
    },
    {
      field: "totalMinutesForMonth",
      headerName: `${t("collaborativeCare:fields.minutes.label")} (${t("common:date.monthYear", {
        date: props.filters.date,
      })})`,
      sortable: true,
      minWidth: 160,
      width: 160,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row["task"] && params.row["id"] != "info") {
          return <>{params.row["totalMinutesForMonth"]}</>;
        } else {
          return <Typography fontWeight={"bold"}>{params.row["totalMinutesForMonth"]}</Typography>;
        }
      },
      getSortComparator: infoRowToTheBottomSortFunction,
    },
    {
      field: "status",
      headerName: t("collaborativeCare:fields.status.label"),
      minWidth: 90,
      width: 90,
      getSortComparator: infoRowToTheBottomSortFunction,
    },
    {
      field: "dueAt",
      headerName: t("collaborativeCare:fields.dueAt.label"),
      minWidth: 150,
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row["task"] && params.row["id"] != "info") {
          return <>{t("collaborativeCare:tasks.dueAtSubheadShort", { date: params.row["dueAt"] })}</>;
        } else {
          return <></>;
        }
      },
      getSortComparator: infoRowToTheBottomDateSortFunction,
    },
  ];

  let rows: Array<RowDataType> = props.tasks.map((task) => {
    return {
      id: task.id, // Datagrid seems to require an id.
      task: task,
      patientId: task.patient?.id,
      totalMinutesForMonth: task.totalMinutesForMonth,
      title: task.title,
      status: taskStatusT(task.status, t),
      dueAt: task.dueAt,
    };
  });

  if (props.filters.billableMinutesGreaterThanZero) {
    rows = rows.filter((task) => {
      return task.totalMinutesForMonth > 0;
    });
  }

  const totalMinutes = rows.reduce((acc, row) => {
    return acc + row.totalMinutesForMonth;
  }, 0);

  if (rows.length > 0) {
    rows.push({
      id: "info",
      totalMinutesForMonth: totalMinutes,
      title: "",
      status: "",
      dueAt: null,
    });
  }

  return (
    <PatientDetailsDataGridContainer>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        disableRowSelectionOnClick={true}
        hideFooter={true}
        sx={{
          "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          overflowX: "scroll",
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "dueAt", sort: "desc" }],
          },
        }}
      />
    </PatientDetailsDataGridContainer>
  );
}

type CopyMonthlySummarySectionProps = {
  patientId: PatientId;
  tasks: ReadonlyArray<TaskDetails & Pick<Task, "totalMinutesForMonth" | "createdAt">>;
  filters: TasksCardListFilters;
};
function CopyMonthlySummarySection(props: CopyMonthlySummarySectionProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
      <Typography variant="caption" textAlign="right">
        {t("collaborativeCare:patientDetails.tasks.actions.copyMonthlyTasksSummaryHelp")}
      </Typography>
      <CopyMonthlySummaryButton patientId={props.patientId} filters={props.filters} />
    </Stack>
  );
}
