import React, { ReactElement, ReactNode, useState } from "react";
import NavBar, { UserMenuItem } from "./NavBar/NavBar";

import { InstituteContext } from "../../AppSession/AppContext";
import { SvgPatterns } from "../../Shared/SvgPatterns";
import TopBar from "./TopBar";
import { styled } from "@mui/material/styles";
import { MOBILE_BREAKPOINT } from "Shared/Responsive";

export type AppLayoutUserMenuItem = AppLayoutNavigationItem & {
  icon?: ReactElement;
  additionalElement?: ReactElement;
};
export type AppLayoutNavigationItem = {
  label?: string;
  location?: string;
  onClick?: () => void;
  // A list of other route prefixes that the nav item should consider itself active for.
  activeFor?: Array<string>;
};
export type AppLayoutNavData = {
  primaryNavigation: AppLayoutNavigationItem;
  additionalNavigation: Array<AppLayoutNavigationItem>;
};
const StyledAppRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "visible",
  width: "100%",
}));

const StyledWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "visible",
  [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
    paddingTop: theme.spacing(3),
  },
}));

const StyledContentContainer = styled("div")(() => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "visible",
}));

const StyledContent = styled("div")(() => ({
  flex: "1 1 auto",
  height: "100%",
  overflow: "visible",
}));

type Props = {
  children: ReactNode;
  user: {
    name: string;
    lastSignIn: Date | null;
  };
  institute: InstituteContext;

  navItems: AppLayoutNavData;
  instituteNavItems: Array<AppLayoutNavigationItem>;
  userMenuItems: Array<UserMenuItem>;
  userBadge?: { count: number };
  dialogs?: ReactElement;
};

function AppLayout({
  children,
  user,
  institute,
  instituteNavItems,
  navItems,
  userMenuItems,
  userBadge,
  dialogs,
}: Props): ReactElement {
  const [navDrawerOpen, setNavDrawerOpen] = useState<boolean>(false);
  const openNavDrawer: () => void = () => setNavDrawerOpen(true);
  const closeNavDrawer: () => void = () => setNavDrawerOpen(false);
  return (
    <StyledAppRoot>
      <NavBar
        instituteNavItems={instituteNavItems}
        user={user}
        navItems={navItems}
        institute={institute}
        onNavDrawerClose={closeNavDrawer}
        navDrawerOpen={navDrawerOpen}
        userMenuItems={userMenuItems}
        userBadge={userBadge}
        dialogs={dialogs}
      />
      <StyledWrapper>
        <StyledContentContainer>
          <StyledContent>
            <TopBar
              userBadge={userBadge}
              user={user}
              toggleNavDrawer={navDrawerOpen ? closeNavDrawer : openNavDrawer}
              userMenuItems={userMenuItems}
            />
            {children}
          </StyledContent>
        </StyledContentContainer>
      </StyledWrapper>
      <SvgPatterns />
    </StyledAppRoot>
  );
}

export default AppLayout;
