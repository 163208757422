import React, { ReactElement } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Avatar, TableBody, TableRow, TableCell, Link, Box, Skeleton } from "@mui/material";
import { Group as GroupIcon } from "@mui/icons-material";
import { arrOfN } from "Lib/Utils";
import * as Id from "Lib/Id";
import { styled } from "@mui/material/styles";
import { PickTypename } from "type-utils";
import { CareGroup, Patient } from "GeneratedGraphQL/SchemaAndOperations";

type RowProps = {
  careUnit: PickTypename<CareGroup, "id" | "name"> | PickTypename<Patient, "id" | "name">;
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
}));

function CareUnitRow(props: RowProps): ReactElement {
  const { careUnit } = props;
  const id = careUnit.id;

  return (
    <TableRow>
      <TableCell>
        <Link component={RouterLink} to={Id.toString(id)}>
          <Grid container direction="row" alignItems="center">
            <StyledAvatar>
              <GroupIcon />
            </StyledAvatar>
            <Box sx={{ ml: 1 }}>{careUnit.name}</Box>
          </Grid>
        </Link>
      </TableCell>
    </TableRow>
  );
}

function CareUnitRowsLoading(): ReactElement {
  const rows = arrOfN(10).map((i) => {
    return (
      <TableRow key={i} data-testid="loading-row">
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    );
  });

  return <TableBody>{rows}</TableBody>;
}

export { CareUnitRow, CareUnitRowsLoading };
