import {
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { endOfMonth, startOfMonth, sub } from "date-fns";
import { useCurrentRootNode } from "Contexts/CurrentInstituteIdContext";
import {
  EntityTreeNodeParams,
  Metric,
  MetricParams,
  MetricScaleScorerConfigurationPayload,
  MetricStatus,
  ScaleScorerConfigurationQuery,
  useCreateOutcomesMetricMutation,
  useOutcomesMetricTemplateDetailsQuery,
  useScaleScorersConfigurationQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { allowedEntityTypes } from "Outcomes";
import { useQueryStringIdParameter } from "Shared/QueryStringParameter";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OutcomesTransientMetricComputedValueTable from "./OutcomesTransientMetricComputedValuesTable";
import PreviewMetricStats from "./PreviewMetricStats";
import CreateMetricForm from "./CreateMetricForm";
import { assertNonNull } from "Lib/Utils";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { Form, useForm, useTextField } from "Shared/Form";
import {
  defaultsToParams,
  ensureMetricParams,
  scaleScorerConfigToParams,
  scaleScorerDetailsToSupportedOptions,
  scaleScorerIdsFromParams,
  scaleScorerParamsToRollupType,
} from "./OutcomesFormHelpers";
import { Failure, RemoteData, Success } from "seidr";
import { ApolloError } from "@apollo/client";
import { ChooseMetricTemplate } from "./FormElements/ChooseMetricTemplate";
import OutcomesTransientMetricBreakdown from "./OutcomesTransientMetricBreakdown";
import MetricRollupAndScalesForm from "./MetricRollupAndScalesForm";
import OutcomesTransientMetricMultiScaleBreakdown from "./OutcomesTransientMetricMultiScaleBreakdown";
import {
  ResetAndStickyFilterButtonGroup,
  useStickyEntityTreeNodeParameter,
  useStickyMonthParameter,
} from "Shared/StickyParameter";
import DatePicker from "Shared/DatePickers";
import { STICKY_PARAMETER_NAMES, STICKY_PARAMETER_FILTER_SETS } from "Shared/Storage";
import EntityTreeNodeSelect from "Shared/Filters/EntityTreeNodeSelect";
import Link from "MDS/Link";

export type ScaleScorerConfiguration = ScaleScorerConfigurationQuery["assessmentScaleScorer"];

function FormNoScaleScorer() {
  const { t } = useTranslation(["outcomes"]);
  return (
    <Card>
      <CardHeader title={t("outcomes:create.configureMetricTitle")} />
      <CardContent>
        <Typography style={{ textAlign: "center" }}>{t("outcomes:create.chooseScorerHelp")}</Typography>
      </CardContent>
    </Card>
  );
}

type SaveDialogProps = {
  metricParams: MetricParams | null | undefined;
  entityTreeNode: EntityTreeNodeParams;
  open: boolean;
  onClose: () => void;
  defaultName?: string;
};

type SaveDialogInnerProps = SaveDialogProps & { metricParams: MetricParams };

function SaveDialogInner(props: SaveDialogInnerProps) {
  const { t } = useTranslation(["outcomes", "common"]);
  const navigate = useNavigate();
  const [createOutcomesMetric, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.outcomesCreateMetric,
    useCreateOutcomesMetricMutation({
      refetchQueries: ["OutcomesMetrics"],
    })
  );

  const onSuccess = (response: { metric: Pick<Metric, "id"> }) => {
    navigate("../" + response.metric.id.toString());
  };

  const fields = {
    name: useTextField({
      required: true,
      default: props.defaultName,
    }),
  };

  const form = useForm({
    id: "save-metric-form",
    submit: () => {
      createOutcomesMetric({
        variables: {
          input: {
            payload: ensureMetricParams(props.metricParams),
            name: assertNonNull(fields.name.value),
            status: MetricStatus.ACTIVE,
            entityTreeNode: props.entityTreeNode,
          },
        },
      });
    },
    remoteData: remoteData,
    onSuccess,
    fields: fields,
  });

  return (
    <>
      <Form id={form.id} onSubmit={form.onSubmit}>
        <DialogContent style={{ marginTop: "0.5em", overflow: "visible" }}>
          <Stack direction="column" spacing={1}>
            <Typography>{t("outcomes:create.saveDialog.explanation")}</Typography>
            <TextField
              onChange={fields.name.onChange}
              value={fields.name.value}
              placeholder={t("outcomes:create.saveDialog.namePlaceholder")}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <ButtonWithSpinner
            variant="contained"
            color="secondary"
            type="submit"
            showSpinner={form.showSpinner}
            disabled={form.disableSubmit}
            form={form.id}
          >
            {t("common:actions.save")}
          </ButtonWithSpinner>
        </DialogActions>
      </Form>
    </>
  );
}

function SaveDialog(props: SaveDialogProps) {
  const { t } = useTranslation(["outcomes"]);

  if (props.open) {
    let content = <Typography>{t("outcomes:create.saveDialog.invalid")}</Typography>;
    if (props.metricParams) {
      content = <SaveDialogInner {...props} metricParams={props.metricParams} />;
    }

    return (
      <ResponsiveDialog
        open={props.open}
        title={t("outcomes:create.saveDialog.title")}
        keepMounted={false}
        dialogWidth="50%"
        onClose={props.onClose}
      >
        {content}
      </ResponsiveDialog>
    );
  }
  return null;
}

export default function CreateOutcomesMetric() {
  const { t } = useTranslation(["common", "outcomes"]);
  const [now, _] = React.useState(new Date());
  const nineMonthsAgo = startOfMonth(sub(now, { months: 9 }));
  const endOfThisMonth = endOfMonth(now);
  const [startDate, setStartDate] = useStickyMonthParameter(
    STICKY_PARAMETER_NAMES.START_DATE,
    STICKY_PARAMETER_FILTER_SETS.OUTCOMES,
    nineMonthsAgo,
    "start",
    true
  );
  const [endDate, setEndDate] = useStickyMonthParameter(
    STICKY_PARAMETER_NAMES.END_DATE,
    STICKY_PARAMETER_FILTER_SETS.OUTCOMES,
    endOfThisMonth,
    "end",
    true
  );
  const [entityRoot] = useCurrentRootNode();
  const [entityTreeNodeParams, setEntityTreeNodeParams] = useStickyEntityTreeNodeParameter(
    STICKY_PARAMETER_NAMES.ENTITY_TREE_NODE,
    STICKY_PARAMETER_FILTER_SETS.OUTCOMES,
    entityRoot,
    true
  );
  const [currentValidParams, setCurrentValidParams] = useState<MetricParams | null>();
  const [lastValidParams, setLastValidParams] = useState<MetricParams | null>();
  const [templateId, setTemplateId] = useQueryStringIdParameter<"Metric">("templateId", true);
  const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
  const [defaultName, setDefaultName] = useState<string | undefined>(undefined);
  const [scaleScorerConfig, setScaleScorerConfig] = useState<MetricScaleScorerConfigurationPayload | null>(
    null
  );

  const { remoteData: scaleScorerConfigData } = apolloQueryHookWrapper(
    useScaleScorersConfigurationQuery({
      // Apollo will only invoke this query if the scale scorer is present, so we can do the coercion even though it's not strictly held.
      skip: !scaleScorerConfig,
      variables: {
        ids: assertNonNull(scaleScorerConfig ? scaleScorerIdsFromParams(scaleScorerConfig) : null),
      },
    })
  );

  const { remoteData: defaultRemoteData } = apolloQueryHookWrapper(
    useOutcomesMetricTemplateDetailsQuery({
      // Apollo will only invoke this query if the scale scorer is present, so we can do the coercion even though it's not strictly held.
      skip: !templateId,
      variables: { id: assertNonNull(templateId) },
      onCompleted: (result) => {
        if (result.outcomesMetricTemplate) {
          setScaleScorerConfig(
            scaleScorerConfigToParams(result.outcomesMetricTemplate.configuration.scorerConfig)
          );
          setCurrentValidParams({
            ...defaultsToParams(result.outcomesMetricTemplate.configuration),
          });
          setDefaultName(result.outcomesMetricTemplate.name);
        }
      },
    })
  );

  // When setting the defaults we require the following behavior:
  //  - If there is a lastValidParams set that means we've changed something but want to try to keep as much
  //    the same as possible, so reconcile that with our current state (e.g. has the scale changed so that some severities are not available)
  //  - If not, use the template if there is one
  //  - Else no defaults
  const unwrappedDefaultRemoteData: RemoteData<ApolloError, MetricParams> = lastValidParams
    ? Success(lastValidParams)
    : defaultRemoteData.flatMap((data) => {
        // If we have a new metric template, use that.
        if (data.outcomesMetricTemplate) {
          return Success(defaultsToParams(data.outcomesMetricTemplate.configuration));
        } else {
          return Failure(
            new ApolloError({
              errorMessage: "No data returned",
            })
          );
        }
      });

  const handleChangeStartDate = (newValue: Date) => {
    setStartDate(newValue);
  };
  const handleChangeEndDate = (newValue: Date) => {
    setEndDate(newValue);
  };

  const form = scaleScorerConfigData.caseOf({
    Success: (config) => {
      const data = config.assessmentScaleScorers?.nodes;
      // TODO: filter default data by what's allowed
      const scorerDetails = data ? scaleScorerDetailsToSupportedOptions(data) : null;
      if (data && scorerDetails) {
        return (
          <CreateMetricForm
            scaleScorerSupportedOptions={scorerDetails}
            onSave={() => setShowSaveDialog(true)}
            defaults={unwrappedDefaultRemoteData} // When you have an existing metric you can put defaults in here
            errorMessage={t("common:failedToSave")}
            submitButtonText={t("outcomes:create.actions.createAndTrack")}
            onDataChange={(metricData) => {
              if (metricData && scaleScorerConfig) {
                setCurrentValidParams({ ...metricData, scaleScorerConfig });
                setLastValidParams({ ...metricData, scaleScorerConfig });
              } else {
                setCurrentValidParams(null);
              }
            }}
          />
        );
      } else {
        return <FormNoScaleScorer />;
      }
    },
    _: () => {
      return <FormNoScaleScorer />;
    },
  });

  const scaleBreakdown = currentValidParams?.scaleScorerConfig.multiScale ? (
    <Grid item xs={2}>
      <OutcomesTransientMetricMultiScaleBreakdown
        scaleScorerConfigData={scaleScorerConfigData}
        metricParams={currentValidParams}
        entityTreeNode={entityTreeNodeParams}
        startDate={startDate}
        endDate={endDate}
      />
    </Grid>
  ) : null;

  return (
    <Page
      breadcrumbs={[
        <Link to="/app/outcomes" key="outcomes-breadcrumb">
          {t("outcomes:dashboard.title")}
        </Link>,
        <Typography key="create-breadcrumb">{t("outcomes:create.title")}</Typography>,
      ]}
      browserTitle={t("outcomes:create.title")}
    >
      <SaveDialog
        open={showSaveDialog}
        entityTreeNode={entityTreeNodeParams}
        metricParams={currentValidParams}
        onClose={() => setShowSaveDialog(false)}
        defaultName={defaultName}
      />
      <Grid container columns={2} spacing={1}>
        <Grid item lg={1} xs={1}>
          <Stack direction={"column"} spacing={1}>
            <ChooseMetricTemplate
              onTemplateIdChange={(value) => {
                // Once you set a new template, you're explictly nuking all the prior configuration you have.
                setLastValidParams(null);
                setTemplateId(value);
              }}
              templateId={templateId}
            />
            <MetricRollupAndScalesForm
              defaults={unwrappedDefaultRemoteData.map((d) =>
                scaleScorerParamsToRollupType(d.scaleScorerConfig)
              )}
              scaleScorerConfigParams={scaleScorerConfig}
              scaleScorerConfigData={scaleScorerConfigData}
              startDate={startDate}
              endDate={endDate}
              entityTreeNode={entityTreeNodeParams}
              onChange={(value) => {
                // Keep a copy of what we used to have when we change a scale so that we can reconstruct whatever
                // is valid after the scale change.
                setLastValidParams(currentValidParams);
                if (!value) {
                  // Reset all the stats if you unset the scale scorer
                  setCurrentValidParams(null);
                }
                setScaleScorerConfig(value);
              }}
            />
            {form}
          </Stack>
        </Grid>
        <Grid item lg={1} xs={1} flexGrow={1} flexDirection="column">
          <Stack direction={"column"} spacing={1} flexGrow={1} flexDirection="column" height={"100%"}>
            <Card>
              <CardHeader title={t("outcomes:create.dates")} />
              <CardContent>
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <DatePicker
                      label={t("outcomes:create.startMonth")}
                      views={["month", "year"]}
                      openTo={"month"}
                      value={startDate}
                      defaultValue={nineMonthsAgo}
                      onChange={(newValue: Date) => handleChangeStartDate(newValue)}
                    />
                    <DatePicker
                      label={t("outcomes:create.endMonth")}
                      views={["month", "year"]}
                      openTo={"month"}
                      value={endDate}
                      defaultValue={endOfThisMonth}
                      onChange={(newValue: Date) => handleChangeEndDate(newValue)}
                    />
                  </Stack>

                  <EntityTreeNodeSelect
                    setValue={setEntityTreeNodeParams}
                    entityTypes={allowedEntityTypes}
                    value={entityTreeNodeParams}
                    defaultValue={entityRoot}
                  />
                  <ResetAndStickyFilterButtonGroup
                    onReset={() => {
                      setStartDate(nineMonthsAgo);
                      setEndDate(endOfThisMonth);
                      setEntityTreeNodeParams(entityRoot);
                    }}
                  />
                </Stack>
              </CardContent>
            </Card>
            <PreviewMetricStats
              endDate={endDate}
              startDate={startDate}
              metricParams={currentValidParams || null}
              entityTreeNodeParams={entityTreeNodeParams}
            />
          </Stack>
        </Grid>
        {scaleBreakdown}
        <Grid item xs={2}>
          <OutcomesTransientMetricBreakdown
            metricParams={currentValidParams || null}
            entityTreeNodeParams={entityTreeNodeParams}
            setEntityTreeNodeParams={setEntityTreeNodeParams}
            startDate={startDate}
            endDate={endDate}
          />
        </Grid>
        <Grid item xs={2}>
          <OutcomesTransientMetricComputedValueTable
            endDate={endDate}
            entityTreeNodeParams={entityTreeNodeParams}
            metricParams={currentValidParams || null}
            startDate={startDate}
          />
        </Grid>
      </Grid>
    </Page>
  );
}
