const implementation = {
  hideProviderInformation: "Hide Provider Information",
  showInternalTargets: "Show Internal",
  targetType: "Type of Target",
  incompleteReportWarning:
    "This report is for a period that finishes in the future. Data may change between now and the end of the period.",
  dashboard: {
    title: "Implementation Targets",
    weekEnding: "Week Ending",
    createTarget: "Create Target",
    queryType: "Show implementation targets that are:",
  },
  noTargets: "You currently do not have any implementation targets configured.",
  card: {
    moreInsights: "More Insights",
    errorLoadingChart: "An error occurred loading the report",
    noData: "This target has no data yet",
  },
  details: {
    title: "Implementation Target",
    editTarget: "Edit Target",
    missingReportHeader: "There is no data for this date",
    missingReportBody:
      "The target you are viewing was not active for this date. Please choose a date after $t(common:date:tiny) to see data.",
    onlyNodesWithDataShown: "Only entities with at least one result during the target period are shown.",
    breakdown: "Shows a breakdown of the target by {{collection}}",
    noBreakdownData:
      "There is no data for this breakdown. At least one success must be registered to see a breakdown",
    configuration: {
      title: "Target Details",
    },
    share: {
      title: "Share This Report",
      sendEmail: "Send Email",
      helpTextBreakdowns:
        "Press the button below to receive an email which contains this report. It will include the provider-specific breakdowns above. If you would like a report without them, please uncheck the box at the top of the page",
      helpTextNoBreakdowns:
        "Press the button below to receive an email which contains this report. It will NOT include any provider-specific breakdowns. If you would like to include these breakdowns, please uncheck the box at the top of the page",
      success: "An email has been sent to {{emails}}",
      additionalEmailAddress: "Additional Email Address",
    },
  },
  retireImplementationTarget: {
    form: {
      title: "Retire {{ target }}?",
      confirm: "Stop tracking this target",
      abort: "Keep tracking this target",
      description:
        'Retiring "{{ target }}" will mean that it will not be shown on the dashboard. You will not receive future notifications or updates about it.',
    },
    buttonTitle: "Retire target",
  },
  form: {
    create: "Create New Target",
    edit: "Edit Target",
    chooseTargetType: "Choose a target type to finish configuration",
    targetType: {
      title: "Select a target type",
      helpText:
        "Select the target for what you would like to measure. Different targets will use different metrics and surface different insights",
    },
    coreDetails: {
      title: "Set core details of the target",
      helpText: "Configure the name and description of the target, and who it applies to",
    },
    configureTarget: {
      title: "Configure the target",
      helpText: "Configure details of exactly the target to hit",
    },
    name: {
      label: "Name",
      placeholder: "The name that will be displayed whenever this target is used.",
    },
    description: {
      label: "Description",
      placeholder: "A full description of what you are trying to accomplish with this target",
    },
    startDate: {
      label: "Start Date",
      placeholder: "The date from which the target will be evaluated.",
      helperText: "Must be a Monday as reports are calculated Monday-Sunday every week.",
      format: "MM/dd/yyyy",
    },
    softLaunchDate: {
      label: "Soft Launch Date",
      helperText:
        "Soft launching allows you to start tracking progress against the metric without it counting as part of your elapsed time. Any actions taken after the soft launch date but before the start date will be counted in the first week's report",
      placeholder: "The date from which data will be gathered. This can be ",
      format: "MM/dd/yyyy",
      notPresent: "Not configured, data collection begins at start date",
    },
    target: {
      label: "Target",
      placeholder: "The final target to hit",
      notPresent: "No formal target",
    },
    initialCredits: {
      label: "Initial Credits",
      placeholder: "How many credits you start with",
      helperText:
        "At launch, how far towards the target you start. These will be counted as happening before the first week's report",
      notPresent: "Not configured, target starts at 0",
    },
    mirahInternal: {
      label: "Mirah Internal",
      helperText: "If checked, is not visible to the customer and only viewable by internal users",
    },
  },
  create: {
    title: "Create an Implementation Target",
  },
  edit: {
    title: "Edit Implementation Target",
  },
  explainTargets:
    "Implementation targets let you set goals for your organization and track progress towards those goals. You will get regular summary reports of how you are tracking towards those goals, and highlighted data including wins and things to celebrate, and things you may need to take action on.",
  targets: {
    COCM_NEW_ENROLLMENTS: {
      graphLegend: "Number of new enrollments",
      description: "This target measures how many new enrollments have been made since the start period.",
      targetDescription: "How many enrollments you want to hit in the first 90 days",
    },
    COCM_MONTHLY_BILLED_MINUTES: {
      graphLegend: "Number of billed minutes in month",
      description: "This target measures how many minutes have been billed in every month",
      targetDescription:
        "How many minutes you expect to bill for every workday in the month, combined across participants. For example, 240 represents 4 hours = 240 minutes per workday.",
    },
    COCM_MONTHLY_EXPECTED_BILLING: {
      graphLegend: "Expected monthly billing",
      description: "This target measures the expected amount billable throughout the month",
      targetDescription:
        "How many dollars you expect to bill in total every month, based on an average reimbursement across the entire population",
    },
    COCM_MONTHLY_EXPECTED_RVUS: {
      graphLegend: "Expected monthly RVUs",
      description: "This target measures the expected number of RVUs  throughout the month",
      targetDescription: "How many RVUS you expect to bill in total every month.",
    },
    COCM_MONTHLY_EXPECTED_VALUE_UNITS: {
      graphLegend: "Expected monthly care units",
      description: "This target measures the expected number of care units throughout the month",
      targetDescription: "How many value units you expect to bill in total every month.",
    },
    COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES: {
      graphLegend: "Expected billable code minutes",
      description: "This target measures the expected number of time code minutes billed",
      targetDescription: "How many time code minutes you expect to bill in total every month.",
    },
    COCM_MONTHLY_BILLING_EFFICIENCY: {
      graphLegend: "Expected % efficiency",
      description:
        "This target measures the efficiency of billing, from 0% meaning nothing was billable, to 200% meaning that you billed optimally at half + 1 minute to the time code",
      targetDescription: "What % efficiency you want to end the month at",
    },
  },
  statPeriod: {
    LAST_7_DAYS: "Week ending $t(common:date.short)",
  },
  widget: {
    noActive: {
      title: "No Active Implementation Target",
      description: "You do not currently have an active implementation target",
    },
  },
};

export default implementation;
