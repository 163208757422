import { TaskDetails } from "CollaborativeCare/Tasks/TaskCard/TaskCard";
import { useCollaborativeCareTaskDetailsLazyQuery } from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement, ReactNode, useContext, useEffect } from "react";
import { Maybe } from "seidr";

type TaskContextType = {
  maybeTask: Maybe<TaskDetails>;
  changeTask: (task: TaskDetails) => void;
};

export const CurrentTaskContext: React.Context<TaskContextType> = React.createContext({
  maybeTask: Maybe.fromNullable<TaskDetails>(null),
  changeTask: function (_task: TaskDetails) {},
});

export function useCurrentTask(): TaskDetails | null {
  const { maybeTask } = useContext(CurrentTaskContext);
  const task = maybeTask.caseOf({
    Nothing: () => {
      return null;
    },
    Just: (task) => task,
  });
  return task;
}
export function useChangeCurrentTask(): (task: TaskDetails) => void {
  const { changeTask } = useContext(CurrentTaskContext);
  return changeTask;
}

export function CurrentTaskProvider({ children }: { children: ReactNode }): ReactElement {
  const [task, changeTask] = React.useState<TaskDetails>();
  const [getDetails, { loading, error, data }] = useCollaborativeCareTaskDetailsLazyQuery();

  // whether or not you define `loading` and `error`, the component gets re-rendered for each of those three query states
  // so we absolutely need to make sure we don't start another query while we're loading or in an error state,
  // or else we'll be in an infinite loop
  if (!loading && !error) {
    //the first time we render this component with an actual task, get the details
    if (task && !data) {
      //by calling getDetails, changes to the object in the cache will trigger our hook below (JUST for `task.id`)
      getDetails({ variables: { id: task.id } });
    } else if (task && data?.collaborativeCareTask && data.collaborativeCareTask.id != task.id) {
      //otherwise, if task.id is a different task than the one we're hooked into, we need to call getDetails again to make the hook work for the new task
      getDetails({ variables: { id: task.id } });
    }
  }

  //whenever the data changes (i.e. because we refetched), update the task
  useEffect(() => {
    if (data?.collaborativeCareTask) changeTask(data.collaborativeCareTask);
  }, [data]);

  return (
    <CurrentTaskContext.Provider
      value={{ maybeTask: Maybe.fromNullable<TaskDetails>(task), changeTask: changeTask }}
    >
      {children}
    </CurrentTaskContext.Provider>
  );
}

export default CurrentTaskContext;
