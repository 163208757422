import { Pause, PlayArrow } from "@mui/icons-material";
import { Button, Collapse, FormHelperText, Stack } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useCurrentProviderId } from "AppSession/AppSession";
import { useCurrentTimeTracking } from "Contexts/TimeTracking/CurrentTimeTrackingContext";
import {
  useBeginTimeEntryLogMutation,
  useEndTimeEntryLogMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { PatientId, ProviderId, TaskId, TimeEntryLogId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { ExpandingIconButton } from "MDS/ExpandingIconButton";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type MaybeStartStopTimeTrackingButtonProps = {
  taskId: TaskId;
  patientId: PatientId | undefined;
};

export function MaybeStartStopTimeTrackingButton(props: MaybeStartStopTimeTrackingButtonProps) {
  const currentProviderId = useCurrentProviderId();
  if (currentProviderId) {
    return (
      <StartStopTimeTrackingButton
        taskId={props.taskId}
        patientId={props.patientId}
        currentProviderId={currentProviderId}
      />
    );
  } else {
    return <DisabledStartStopTimeTrackingButton />;
  }
}

type StartStopTaskButtonProps = {
  taskId: TaskId;
  patientId: PatientId | undefined;
  currentProviderId: ProviderId;
};

export function StartStopTimeTrackingButton(props: StartStopTaskButtonProps): ReactElement {
  // When we click a button it gets unmounted and the other get mounted, so in order to avoid a flicker of the button
  // closing and then opening again, we need to flip it to default to starting open.
  const [defaultOpen, setDefaultOpen] = React.useState(false);

  const timeTracking = useCurrentTimeTracking();
  const currentlyTrackingThisTask =
    timeTracking.status === "tracking" && timeTracking.task.id === props.taskId;

  if (currentlyTrackingThisTask) {
    return (
      <StopTimeTrackingButton
        patientId={props.patientId}
        timeEntryLogId={timeTracking.timeEntryLogId}
        defaultOpen={defaultOpen}
        onClick={() => setDefaultOpen(true)}
      />
    );
  } else {
    return (
      <StartTimeTrackingButton
        taskId={props.taskId}
        patientId={props.patientId}
        currentProviderId={props.currentProviderId}
        defaultOpen={defaultOpen}
        onClick={() => setDefaultOpen(true)}
      />
    );
  }
}

type StartTimeTrackingButtonProps = {
  taskId: TaskId;
  patientId: PatientId | undefined;
  currentProviderId: ProviderId;
  defaultOpen: boolean;
  onClick: () => void;
};

function StartTimeTrackingButton(props: StartTimeTrackingButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [startTimeTracking, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareStartTimeEntryLogNow,
    useBeginTimeEntryLogMutation({
      refetchQueries: refetchQueries("timeEntries"),
      variables: {
        input: {
          taskId: props.taskId,
          providerId: props.currentProviderId,
          clientStartTime: new Date(),
          forceStart: true,
        },
      },
    })
  );

  const showError = remoteData.kind === "Failure";

  return (
    <Stack direction="row" spacing={0.5}>
      <Collapse orientation="horizontal" in={showError}>
        <FormHelperText error sx={{ whiteSpace: "nowrap" }}>
          {t("collaborativeCare:tasks.errors.failedStartTracking")}
        </FormHelperText>
      </Collapse>
      <ExpandingIconButton
        defaultOpen={props.defaultOpen}
        variant="outlined"
        color="secondary"
        icon={<PlayArrow />}
        onClick={(event) => {
          // Explicitly stop the event bubbling up, otherwise it'll also hit the click event on the whole card that opens
          // the task dialog.
          event.stopPropagation();
          startTimeTracking();
          props.onClick();
        }}
      >
        {t("collaborativeCare:tasks.actions.begin")}
      </ExpandingIconButton>
    </Stack>
  );
}

type StopTimeTrackingButtonProps = {
  patientId: PatientId | undefined;
  timeEntryLogId: TimeEntryLogId;
  defaultOpen: boolean;
  onClick: () => void;
};

function StopTimeTrackingButton(props: StopTimeTrackingButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [stopTimeTracking, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareEndTimeEntryLog,
    useEndTimeEntryLogMutation({
      refetchQueries: refetchQueries("timeEntries"),
      variables: {
        input: {
          timeEntryLogId: props.timeEntryLogId,
        },
      },
    })
  );

  const showError = remoteData.kind === "Failure";

  return (
    <Stack direction="row" spacing={0.5}>
      <Collapse orientation="horizontal" in={showError}>
        <FormHelperText error sx={{ whiteSpace: "nowrap" }}>
          {t("collaborativeCare:tasks.errors.failedStopTracking")}
        </FormHelperText>
      </Collapse>
      <ExpandingIconButton
        defaultOpen={props.defaultOpen}
        variant="outlined"
        color="secondary"
        icon={<Pause />}
        onClick={(event) => {
          // Explicitly stop the event bubbling up, otherwise it'll also hit the click event on the whole card that opens
          // the task dialog.
          event.stopPropagation();
          stopTimeTracking();
          props.onClick();
        }}
      >
        {t("collaborativeCare:tasks.actions.save")}
      </ExpandingIconButton>
    </Stack>
  );
}

function DisabledStartStopTimeTrackingButton(): ReactElement {
  return <Button variant="outlined" color="secondary" endIcon={<PlayArrow />} disabled />;
}
