import React, { ReactElement } from "react";
import {
  Provider,
  ProviderSettingsQuery,
  useProviderSettingsQuery,
  useProviderUpdateMeasurementAllowedMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { ProviderId } from "Lib/Ids";
import * as Id from "Lib/Id";
import { Maybe, RemoteData } from "seidr";
import { ApolloError } from "@apollo/client";
import DefaultLoading from "Settings/DefaultLoading";
import DefaultError from "Settings/DefaultError";
import Page from "Layout/Page";
import Grid from "@mui/material/Grid";
import ShadowSettingsLink from "Settings/ShadowSettingsLink";
import UnderMeasurementChip from "Ops/Institutes/InstituteDetails/UnderMeasurementChip";
import { useTranslation } from "react-i18next";
import ImmediateSettingsSwitch from "Shared/ImmediateSettingSwitch";
import { useParams } from "react-router-dom";
import { refetchQueries } from "Lib/RefetchQueries";

type ProviderSettingsProps = { id: ProviderId };

function ProviderSettingsRoute(): ReactElement {
  const { providerId } = useParams<{ providerId?: string }>();

  return Id.fromNullableString<"Provider">(providerId).caseOf({
    Err: () => <DefaultError error={"The parameters to this page are invalid"} />,
    Ok: (id) => <ProviderSettingsHookWrapper id={id} />,
  });
}

function ProviderSettingsHookWrapper(props: ProviderSettingsProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useProviderSettingsQuery({
      variables: {
        id: props.id,
      },
    })
  );

  return ProviderSettings({ remoteData });
}

type ProviderSettingsDataProps = {
  remoteData: RemoteData<ApolloError, ProviderSettingsQuery>;
};

function ProviderSettings(props: ProviderSettingsDataProps): ReactElement {
  return props.remoteData.caseOf({
    Loading: () => <DefaultLoading />,
    NotAsked: () => <DefaultLoading />,
    Failure: (error) => <DefaultError error={error.message} />,
    Success: (result) => {
      return Maybe.fromNullable(result.provider).caseOf({
        Nothing: () => <DefaultError error="This provider was not found!" />,
        Just: (provider: Provider) => <ProviderSettingsElement {...provider} />,
      });
    },
  });
}

function ProviderMeasurementAllowedSwitch(props: ProviderSettingsElementProps): ReactElement {
  const { t } = useTranslation(["settings"]);
  const [updateMeasurementAllowed, { remoteData }] = apolloMutationHookWrapper(
    (data) => data.settingsProviderUpdateMeasurementAllowed,
    useProviderUpdateMeasurementAllowedMutation({
      refetchQueries: refetchQueries("providerSettings"),
    })
  );
  const partialUpdateMeasurementAllowed = (allowed: boolean) => {
    updateMeasurementAllowed({
      variables: {
        input: {
          providerId: props.id,
          measurementAllowed: allowed,
        },
      },
    });
  };
  return (
    <ImmediateSettingsSwitch
      value={props.measurementAllowed}
      label={t("settings:measurementAllowedSwitch.label")}
      description={t("settings:measurementAllowedSwitch.providerTooltip")}
      remoteData={remoteData}
      query={partialUpdateMeasurementAllowed}
    />
  );
}

type ProviderSettingsElementProps = {
  id: ProviderId;
  measurementAllowed: boolean;
};

// This page is actually meant for providers but we call them users
// in the current ember routes. We should consider changing that.
function ProviderSettingsElement(props: ProviderSettingsElementProps): ReactElement {
  const title = "Provider Settings";
  return (
    <Page browserTitle={title}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <div>
            <h2>
              {title}{" "}
              <ShadowSettingsLink shadowSettingUrl={window.location.href}>
                {props.id.toString()}
              </ShadowSettingsLink>
            </h2>
          </div>
        </Grid>
        <Grid item xs={3}>
          <UnderMeasurementChip measurementAllowed={props.measurementAllowed} />
        </Grid>
      </Grid>
      <ProviderMeasurementAllowedSwitch id={props.id} measurementAllowed={props.measurementAllowed} />
    </Page>
  );
}

export default ProviderSettingsRoute;
export { ProviderSettingsHookWrapper as HookWrapper, ProviderSettings as Component };
