/** Storage keys. When you add a new piece of data to local storage, define the key here so that we can make sure
 *  we aren't creating any unintended collisions. Keep in mind that some of the keys are generated dynamically based on
 * things like flag and parameter names. Note that some of these keys are used in localStorage and some are used in
 * sessionStorage - since these are two different storage domains we can technically let those keys collide with each
 * other without causing an error, but for our own sanity and flexibility in changing how we store data we should avoid
 * that if at all possible.
 */

export const EMBER_AUTH_LOCAL_STORAGE_KEY = "ember_simple_auth-session";
export const COCM_PATIENT_LIST_TABLE_STORAGE_KEY = "cocmPatientList";
export const COCM_TASK_LIST_TABLE_STORAGE_KEY = "cocmTaskList";
export const MBC_PATIENT_LIST_TABLE_STORAGE_KEY = "mbcPatientList";
export const IMPERSONATION_STORAGE_KEY = "impersonatingUserId";
export const INSTITUTE_GROUP_STORAGE_KEY = "activeInstituteGruop";
export const PII_LEVEL_STORAGE_KEY = "piiLevel";
export const COCM_SELECTED_PANELS_STORAGE_KEY = "cocmSelectedPanels";
export const PRIVACY_MODE_STORAGE_KEY = "privacyMode";
export const TEST_PATIENT_STORAGE_KEY = "testPatientViewability";
export const COCM_BILLING_ALGORITHM_TABLE_STORAGE_KEY = "cocmBillingAlgorithm";
export const COCM_BILLING_PREDICTION_STORAGE_KEY = "cocmBillingPrediction";

export function stickyParameterKey(parameterName: string, filterSet: string) {
  return `${filterSet}:${parameterName}`;
}

// See `stickyParameteKey` above for how these are used.
export const STICKY_PARAMETER_FILTER_SETS = {
  OUTCOMES: "outcomes",
  COCM_CASE_CONSULT: "cocmConsults",
  COCM_INSTITUTE_PERFORMANCE: "cocmInstitutePerformance",
  COCM_MY_PERFORMANCE: "cocmMyPerformance",
  COCM_PATIENT_SEARCH: "cocmPatientSearch",
  COCM_TASKS: "cocmTasks",
  MBC_PATIENT_SEARCH: "patientListProvider",
  IMPLEMENTATION: "implementation",
  BILLING: "billing",
};

// See `stickyParameterKey` for how these are used. This is the intersection of all the parameter names everywhere we use
// a sticky parameter.
export const STICKY_PARAMETER_NAMES = {
  START_DATE: "startDate",
  END_DATE: "endDate",
  ENTITY_ID: "entityId",
  ENTITY_TREE_NODE: "entityPath",
  PCP: "pcpId",
  CARE_MANAGER: "cmId",
  PROVIDER: "providerId",
  ENROLLMENT_STATUS: "enrollmentStatus",
  TASK_STATUS: "taskStatus",
  ASSIGNED_TO: "assignedTo",
  SORT_BY: "sortBy",
  SORT_DIRECTION: "sortDirection",
};

export function frontendFlagStorageKey(name: string) {
  return `mirah-frontend-flags-${name}`;
}

/**
 * Event handlers. These will get called at the appropriate point in the application lifecycle.
 */

// We want to remove everything in session storage because, well, logging out makes you start a new session. We want to
// keep everything in localstorage _except_ for the authentication data, which of course we have to remove in order to
// actually be logged out.
export function storageLogout() {
  sessionStorage.clear();
  localStorage.removeItem(EMBER_AUTH_LOCAL_STORAGE_KEY);
}
