import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import {
  Institute,
  InstituteType,
  useAddInstituteToInstituteGroupMutation,
  useInstituteGroupDetailsQuery,
  useRemoveInstituteFromInstituteGroupMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { fromNullableString } from "Lib/Id";
import ErrorMessage from "Shared/ErrorMessage";
import { InstituteGroupId, InstituteId } from "Lib/Ids";
import { remoteDataGetOrElse } from "Lib/RemoteData";
import {
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Spinner from "Shared/Spinner";
import { LabelAndInfo } from "Shared/InfoSidebarCard";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import InstituteSelect from "Shared/Filters/InstituteSelect";
import { assertNonNull } from "Lib/Utils";

function MembershipRow(props: {
  member: Pick<Institute, "id" | "name">;
  instituteGroupId: InstituteGroupId;
}) {
  const { t } = useTranslation(["instituteGroups", "common"]);
  const { member, instituteGroupId } = props;

  const [removeMember, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.removeInstituteFromInstituteGroup,
    useRemoveInstituteFromInstituteGroupMutation({
      refetchQueries: refetchQueries("instituteGroups"),
      variables: { input: { instituteGroupId, memberId: member.id } },
    })
  );

  return (
    <TableRow>
      <TableCell>{member.name}</TableCell>
      <TableCell>
        <ButtonWithSpinner
          variant="contained"
          color="secondary"
          showSpinner={remoteData.kind === "Loading"}
          disabled={remoteData.kind === "Loading"}
          onClick={() => removeMember()}
        >
          {t("instituteGroups:members.remove")}
        </ButtonWithSpinner>
      </TableCell>
    </TableRow>
  );
}

function InstituteGroupMembership(props: {
  instituteGroupId: InstituteGroupId;
  members: ReadonlyArray<Pick<Institute, "id" | "name">>;
}) {
  const { t } = useTranslation(["instituteGroups", "common"]);

  const [addMemberId, setAddMemberId] = React.useState<InstituteId | null>(null);

  const [addMember, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.addInstituteToInstituteGroup,
    useAddInstituteToInstituteGroupMutation({
      refetchQueries: refetchQueries("instituteGroups"),
      variables: {
        // We can't hit submit until it's not null, so assert that here.
        input: { instituteGroupId: props.instituteGroupId, memberId: assertNonNull(addMemberId) },
      },
    })
  );
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography fontWeight="bold">{t("instituteGroups:table.columns.members")}</Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.members.map((member) => (
            <MembershipRow
              instituteGroupId={props.instituteGroupId}
              member={member}
              key={member.id.toString()}
            />
          ))}
          <TableRow>
            <TableCell>
              <InstituteSelect
                setValue={setAddMemberId}
                value={addMemberId}
                types={[InstituteType.PROVIDER]}
              />
            </TableCell>
            <TableCell>
              <ButtonWithSpinner
                variant="contained"
                color="error"
                showSpinner={remoteData.kind === "Loading"}
                disabled={!addMemberId || remoteData.kind === "Loading"}
                onClick={() => addMember()}
              >
                {t("instituteGroups:members.add")}
              </ButtonWithSpinner>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography>{t("instituteGroups:members.warning")}</Typography>
    </>
  );
}

function InstituteGroupDetailsInner(props: { instituteGroupId: InstituteGroupId }): ReactElement {
  const { t } = useTranslation(["instituteGroups", "common"]);

  const navigate = useNavigate();

  const { remoteData } = apolloQueryHookWrapper(
    useInstituteGroupDetailsQuery({ variables: { id: props.instituteGroupId } })
  );

  const title = remoteDataGetOrElse(
    remoteData.map((result) => result.instituteGroup?.name),
    t("common:remoteData.loading")
  );

  const content = remoteData.caseOf({
    Success: (data) => {
      const group = data.instituteGroup;

      if (group) {
        return (
          <Grid container spacing={0.5} rowGap={0.5} paddingLeft="0.5em">
            <LabelAndInfo label={t("instituteGroups:form.name")} data={group.name} />
            <LabelAndInfo label={t("instituteGroups:form.leader")} data={group.leader.name} />
            <InstituteGroupMembership instituteGroupId={group.id} members={group.members} />
          </Grid>
        );
      } else {
        return <ErrorMessage message={t("common:notFound")} />;
      }
    },
    Failure: (e) => <ErrorMessage message={e.message} />,
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
  });

  return (
    <ResponsiveDialog open={true} onClose={() => navigate("..")} title={title} stopBackdropClose={false}>
      <DialogContent>{content}</DialogContent>
    </ResponsiveDialog>
  );
}

export function InstituteGroupDetails(): ReactElement {
  const params = useParams<{ instituteGroupId?: string }>();

  const instituteGroupId = fromNullableString<"InstituteGroup">(params.instituteGroupId);

  return instituteGroupId.caseOf({
    Err: (error) => <ErrorMessage message={error.message} />,
    Ok: (id) => <InstituteGroupDetailsInner instituteGroupId={id} />,
  });
}
