import { Box, Stack, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { TimeEntryLog, useEditTimeEntryLogMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { PatientId, TaskId } from "Lib/Ids";
import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { Form, FormOverlay, useForm, useNumberField, useWrappedField, useTextField } from "Shared/Form";
import { addMinutes } from "date-fns";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteTimeEntryButton } from "./DeleteTimeEntryButton";
import { refetchQueries } from "Lib/RefetchQueries";
import { useEnrollmentState } from "CollaborativeCare/PatientDetails/EnrollmentState";
import PlaceholderOptions from "CollaborativeCare/Tasks/PlaceholderOptions";
import { useCurrentTask } from "Contexts/CurrentTaskContext";

// TODO: add option to hide note?
export type ModifyTimerFormProps = {
  taskId: TaskId;
  isPlaceholder?: boolean;
  patientId?: PatientId;
  timeEntryLog: Pick<TimeEntryLog, "id" | "clientStartTime" | "durationSeconds">;
  onSuccess?: () => void;
};

export function ModifyTimerForm(props: ModifyTimerFormProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const [showTimeEntryForm, setShowTimeEntryForm] = useState<boolean>(true);

  const [editTimeEntry, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareEditTimeEntryLog,
    useEditTimeEntryLogMutation({
      refetchQueries: refetchQueries("timeEntries"),
    })
  );

  const defaultDurationMinutes =
    props.timeEntryLog.durationSeconds === null
      ? undefined
      : Math.round(props.timeEntryLog.durationSeconds / 60);

  const fields = {
    // Error matchers on these two fields are for fields on TimeEntryLog
    clientStartTime: useWrappedField({
      required: true,
      default: props.timeEntryLog.clientStartTime,
      errorPaths: ["start_time", "client_start_time"],
    }),
    durationMinutes: useNumberField({
      required: false,
      default: defaultDurationMinutes,
      errorPaths: ["duration"],
    }),
    // Error matcher on this field is for Note. Fortunately the attributes on these two models are disjoint.
    noteText: useTextField({ required: false, errorPaths: ["text"] }),
    // There's no end time field because we calculate it from start+duration. End time errors will end up in the
    // global error display, which I think is correct since you can't edit that field directly.
  };

  let outsideEnrollmentNotif = <></>;
  if (props.patientId) {
    const enrollment = useEnrollmentState(props.patientId);
    if (enrollment.status == "enrolled" && (fields.clientStartTime.value as Date) < enrollment.consentDate) {
      outsideEnrollmentNotif = (
        <Typography color={"red"}>
          {t("collaborativeCare:tasks.beforeConsentWarning", { date: enrollment.consentDate })}
        </Typography>
      );
    }
  }

  let seconds: number | undefined = undefined;
  if (fields.durationMinutes.value !== undefined) {
    if (typeof fields.durationMinutes.value === "string") {
      seconds = Number.parseInt(fields.durationMinutes.value) * 60;
    } else if (typeof fields.durationMinutes.value === "number") {
      seconds = fields.durationMinutes.value * 60;
    }
  }

  // cannot show a form if you're not logged in as a provider
  const currentProvider = React.useContext(AuthenticatedProviderUserContext);
  if (!currentProvider) {
    return <></>;
  }

  const currentProviderId = currentProvider.providerId.getOrElse(null);
  if (!currentProviderId) {
    return <></>;
  }

  const maybeTask = useCurrentTask();
  const displayedTaskId = maybeTask?.id || props.taskId;
  let placeholder = props.isPlaceholder;
  if (maybeTask) {
    placeholder = maybeTask.isPlaceholder;
  }

  let placeholderOptionsContent = <></>;
  if (placeholder && props.patientId) {
    placeholderOptionsContent = (
      <PlaceholderOptions
        patientId={props.patientId}
        taskId={displayedTaskId}
        setShowSurroundingForms={setShowTimeEntryForm}
      />
    );
  }

  const form = useForm({
    fields: fields,
    submit: () => {
      editTimeEntry({
        variables: {
          input: {
            providerId: currentProviderId,
            timeEntryLogId: props.timeEntryLog.id,
            clientStartTime: fields.clientStartTime.value as Date,
            durationSeconds: seconds as number,
            noteText: fields.noteText.value as string,
          },
        },
      });
    },
    remoteData: remoteData,
    onSuccess: props.onSuccess,
  });

  const startDate = fields.clientStartTime.value
    ? fields.clientStartTime.value
    : props.timeEntryLog.clientStartTime;
  const minutes = seconds ? seconds / 60 : 0;
  const endTime = addMinutes(startDate, minutes);

  let formContent = <></>;
  if (showTimeEntryForm) {
    formContent = (
      <>
        <DateTimePicker
          label={t("collaborativeCare:fields.manualStartTime.label")}
          format={t("collaborativeCare:tasks.timeEntryLog.dateFormat")}
          value={fields.clientStartTime.value}
          onChange={fields.clientStartTime.onChange}
          slotProps={{
            textField: {
              error: fields.clientStartTime.error,
              helperText: fields.clientStartTime.helperText,
            },
          }}
        />
        <TextField
          title={t("collaborativeCare:fields.duration.label")}
          label={t("collaborativeCare:fields.duration.label")}
          autoFocus
          value={minutes.toFixed(0)}
          onChange={fields.durationMinutes.onChange}
          error={fields.durationMinutes.error}
          helperText={fields.durationMinutes.helperText}
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
        />
        <DateTimePicker
          label="End Time"
          format={t("collaborativeCare:tasks.timeEntryLog.dateFormat")}
          value={endTime}
          disabled
        />
        <TextField
          multiline
          minRows={5}
          title={t("collaborativeCare:tasks.timeEntryLog.noteText.label")}
          label={t("collaborativeCare:tasks.timeEntryLog.noteText.label")}
          autoFocus
          value={fields.noteText.value}
          onChange={fields.noteText.onChange}
          error={fields.noteText.error}
          helperText={fields.noteText.helperText}
        />
        <Stack direction="row-reverse" spacing={1}>
          <ButtonWithSpinner
            variant="contained"
            color="secondary"
            type="submit"
            showSpinner={form.showSpinner}
            disabled={form.disableSubmit}
          >
            {t("collaborativeCare:tasks.timeEntryLog.modifyTimeSave")}
          </ButtonWithSpinner>
          <DeleteTimeEntryButton
            variant="outlined"
            color="secondary"
            onSuccess={props.onSuccess}
            timeEntryLogId={props.timeEntryLog.id}
            buttonLabel={t("collaborativeCare:tasks.timeEntryLog.deleteTimeEntryButtonLabel")}
          />
        </Stack>
      </>
    );
  }

  return (
    <>
      {placeholderOptionsContent}
      <Form onSubmit={form.onSubmit}>
        <FormOverlay
          response={remoteData}
          errorMessage={form.globalError || t("collaborativeCare:tasks.genericFormError")}
        />
        <Stack direction="column" spacing={1}>
          <Box marginBottom={"0.5em"} fontSize={"small"}>
            {outsideEnrollmentNotif}
          </Box>
          {formContent}
        </Stack>
      </Form>
    </>
  );
}
