import { Card, CardHeader, CardContent, TextField } from "@mui/material";
import React, { ReactElement } from "react";
import { maybeFromEmpty } from "../../Lib/Utils";

type Props = {
  search: string | null;
  onChange: (search: string | null) => void;
};

function SearchAndFilters(props: Props): ReactElement {
  const { search, onChange } = props;

  const onSearchChange = (ev: React.ChangeEvent<{ value: string }>) => {
    // TODO: Debounce
    onChange(maybeFromEmpty(ev.target.value).getOrElse(null));
  };

  return (
    <Card>
      <CardHeader title="Search & Filter" />
      <CardContent>
        <TextField
          label="Search name"
          variant="outlined"
          fullWidth
          defaultValue={search || ""}
          onChange={onSearchChange}
          inputProps={{ "data-testid": "search" }}
          size="small"
        />
      </CardContent>
    </Card>
  );
}

export default SearchAndFilters;
