import {
  Badge,
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Note,
  Patient,
  PatientSession,
  Provider,
  Task,
  TaskCreationLog,
  TaskStatus,
  TimeEntryLog,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { taskStatusT } from "GeneratedGraphQL/EnumTranslations";
import { TaskCardBody } from "./TaskCardBody";
import { TaskActions } from "./TaskActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Link } from "react-router-dom";
import { patientUrl } from "CollaborativeCare/Utils/patient";
import EditableTaskTitle from "./EditableTaskTitle";

type ProviderName = PickTypename<Provider, "id" | "name">;
export type PatientName = PickTypename<Patient, "id" | "name" | "isTest">;

export type TimeEntryDetails = PickTypename<
  TimeEntryLog,
  "id" | "startTime" | "endTime" | "durationSeconds" | "createdAt"
> & {
  provider: ProviderName;
};
export type NoteDetails = PickTypename<Note, "id" | "text" | "createdAt"> & {
  author: ProviderName;
  timeEntry?: PickTypename<TimeEntryLog, "startTime"> | null;
};

type TaskCreationLogDetails = PickTypename<TaskCreationLog, "id" | "creationType"> & {
  patientSession:
    | (PickTypename<PatientSession, "id" | "targetDate"> & {
        patient: PatientName;
      })
    | null;
  requestedBy: ProviderName | null;
  assignedBy: ProviderName | null;
};

export type TaskDetails = PickTypename<
  Task,
  "id" | "title" | "body" | "dueAt" | "status" | "isPlaceholder"
> & {
  patient: PatientName | null;
  assignedTo: ProviderName;
  notes: { nodes: ReadonlyArray<NoteDetails> };
  timeEntries: { nodes: ReadonlyArray<TimeEntryDetails> };
  creationLog: TaskCreationLogDetails | null;
};

export function TaskCard(props: { task: TaskDetails; inPatientContext: boolean }): ReactElement {
  const theme = useTheme();

  const [expanded, setExpanded] = React.useState(false);

  // When the card is collapsed we want it to have the "i'm clickable" styling from CardActionArea. But we don't want
  // clicking the card area while its expanded to collapse it (too easy to do by accident while scrolling), so remove
  // the action area.
  const header = expanded ? (
    <TaskCardHeader
      task={props.task}
      expanded={expanded}
      setExpanded={setExpanded}
      inPatientContext={props.inPatientContext}
    />
  ) : (
    // Have to specifically use a div component here or else CardActionArea gets rendered as <button>, and then when
    // we try to put other actions inside it they're also <button>, and html doesn't let you put <button> in <button>.
    <CardActionArea component="div">
      <TaskCardHeader
        task={props.task}
        expanded={expanded}
        setExpanded={setExpanded}
        inPatientContext={props.inPatientContext}
      />
    </CardActionArea>
  );

  return (
    <Card sx={{ border: "1px solid", borderColor: theme.palette.divider }}>
      {header}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ paddingTop: "0em", paddingBottom: "0em" }}>
          <Stack direction="column" spacing={2}>
            <TaskCardBody task={props.task} />
          </Stack>
        </CardContent>
        <CardActions>
          {/* This just uses the flexbox created by CardActions to push one set of buttons to the left and the
              other to the right. */}
          <Box sx={{ flexGrow: 1 }} />
          <TaskActions task={props.task} expand={setExpanded} />
        </CardActions>
      </Collapse>
    </Card>
  );
}

type TaskCardHeaderProps = {
  task: TaskDetails;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  inPatientContext: boolean;
};

function TaskCardHeader(props: TaskCardHeaderProps): ReactElement {
  const onCardHeaderClick = () => {
    if (!props.expanded) {
      // This is actually an event propagation issue between the header click
      // and the icon click but checking expanded seemed reasonable enough.
      props.setExpanded(true);
    }
  };
  const onExpandLessClick = () => {
    props.setExpanded(false);
  };

  const action = props.expanded ? (
    <IconButton onClick={onExpandLessClick}>
      <ExpandLessIcon fontSize="large" />
    </IconButton>
  ) : (
    <IconButton>
      <ExpandMoreIcon fontSize="large" />
    </IconButton>
  );

  return (
    <CardHeader
      onClick={onCardHeaderClick}
      title={<TaskCardTitle task={props.task} expanded={props.expanded} />}
      subheader={<TaskCardSubheader task={props.task} inPatientContext={props.inPatientContext} />}
      action={action}
    />
  );
}

function TaskCardSubheader({
  task,
  inPatientContext,
}: {
  task: PickTypename<Task, "id" | "dueAt"> & {
    patient: PickTypename<Patient, "id" | "name" | "isTest"> | null;
    assignedTo: ProviderName;
  };
  inPatientContext: boolean;
}) {
  const { t } = useTranslation(["collaborativeCare", "patients"]);
  const patientInfo = inPatientContext ? null : (
    <Typography>
      {task.patient === null ? (
        t("collaborativeCare:tasks.noPatient")
      ) : (
        <Badge
          badgeContent={t("patients:referenceHeader.testPatient")}
          invisible={!task.patient.isTest}
          color="success"
        >
          <Link to={patientUrl(task.patient)}>{task.patient.name}</Link>
        </Badge>
      )}
    </Typography>
  );

  const dueAtString =
    task.dueAt === null ? "" : t("collaborativeCare:tasks.dueAtSubhead", { date: task.dueAt });

  return (
    <Stack>
      {patientInfo}
      <Stack direction="row" spacing={1}>
        <Typography>
          {t("collaborativeCare:tasks.assignedSubhead", { provider: task.assignedTo.name })}
        </Typography>
        <Typography>{dueAtString}</Typography>
      </Stack>
    </Stack>
  );
}

function TaskCardTitle(props: { task: TaskDetails; expanded: boolean }): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "enums"]);

  // TODO: This is the doneness indicator, it literally will append - Done to the card.
  // We actually want to do something different where the entire card gets a change of view.
  // However, we also strip these cards out pretty fast so we need to rethink the flow here.
  const statusIndicator =
    props.task.status === TaskStatus.ACTIVE ? "" : ` - ${taskStatusT(props.task.status, t)}`;
  return (
    <>
      <EditableTaskTitle task={props.task} small={true} allowInteraction={props.expanded} />
      <Typography>{statusIndicator}</Typography>
    </>
  );
}
