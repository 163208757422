import Page from "Layout/Page";
import React from "react";
import { Box, Card, CardContent, CardHeader, Grid, Paper, Stack, Typography } from "@mui/material";
import {
  EntityTreeNodeParams,
  Metric,
  MetricInclusionCriterion,
  MetricRollupType,
  MetricSuccessCriterion,
  useOutcomesMetricDetailsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import Spinner from "Shared/Spinner";
import ErrorMessage from "Shared/ErrorMessage";
import { endOfMonth, startOfMonth, sub } from "date-fns";
import { MetricId } from "Lib/Ids";
import {
  InclusionCriterionText,
  successCriterionText,
  timeBasedTimeSummary,
  triggerText,
} from "./OutcomesMetricHelpers";
import EntityPath, { EntityTreeNodeDetails, entityTreeNodeToParams } from "Entities/EntityPath";
import { scaleMediumName } from "Shared/Scale/Scale";
import { allowedEntityTypes } from "Outcomes";
import { useTranslation } from "react-i18next";
import {
  BaseMetricDetails,
  TimeBasedMetricConfiguration,
  TriggerBasedMetricConfiguration,
} from "./OutcomesMetricCard";
import Link, { LinkButton } from "MDS/Link";
import { AbandonMetricButton } from "./AbandonMetricButton";
import { useLocation, useNavigate } from "react-router-dom";
import OutcomesMetricBreakdown from "./OutcomesMetricBreakdown";
import OutcomesMetricComputedValueTable from "./OutcomesMetricComputedValuesTable";
import OutcomesMetricMultiScaleBreakdown from "./OutcomesMetricMultiScaleBreakdown";
import PreviewMetricStats from "./PreviewMetricStats";
import { defaultsToParams } from "./OutcomesFormHelpers";
import { ResetAndStickyFilterButtonGroup, useStickyMonthParameter } from "Shared/StickyParameter";
import DatePicker from "Shared/DatePickers";
import { STICKY_PARAMETER_NAMES, STICKY_PARAMETER_FILTER_SETS } from "Shared/Storage";
import EntityTreeNodeSelect from "Shared/Filters/EntityTreeNodeSelect";
import { useQueryStringEntityTreeNodeParameter } from "Shared/QueryStringParameter";

type OutcomesMetricsDetailsProps = {
  metric: Pick<Metric, "id" | "name"> & {
    configuration: TimeBasedMetricConfiguration | TriggerBasedMetricConfiguration;
    entityTreeNode: EntityTreeNodeDetails;
  };
};

type DetailsProps = {
  metric: BaseMetricDetails;
  entityTreeNodeParams: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
  configuration: TimeBasedMetricConfiguration | TriggerBasedMetricConfiguration;
};

function MetricDetailsSection(props: DetailsProps) {
  const { t } = useTranslation(["outcomes"]);

  const details =
    props.configuration.__typename === "TimeBasedMetric" ? (
      <TimeBasedMetricSummary config={props.configuration} />
    ) : (
      <TriggerBasedMetricSummary config={props.configuration} />
    );

  const allScorers =
    props.configuration.scorerConfig.__typename === "MetricMultiScaleScorerConfiguration"
      ? props.configuration.scorerConfig.scaleScorers
      : [props.configuration.scorerConfig.scaleScorer];
  return (
    <Card>
      <CardHeader title={props.metric.name} sx={{ paddingBottom: 0 }} />
      <CardContent>
        <Grid container spacing={0.5} alignItems="center">
          <Grid item xs={4}>
            <Typography variant="h3">{t("outcomes:metricDetails.target")}</Typography>
          </Grid>
          <Grid item xs={8}>
            <EntityPath includeInstitute={"always"} entityTreeNode={props.metric.entityTreeNode} />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h3">{t("outcomes:metricDetails.scale")}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body1">
              {allScorers.map((scorer) => scaleMediumName(scorer.scale)).join(", ")}
            </Typography>
          </Grid>
          {details}
        </Grid>
      </CardContent>
    </Card>
  );
}

function TimeBasedMetricSummary(props: { config: TimeBasedMetricConfiguration }) {
  const { t } = useTranslation(["outcomes"]);
  return (
    <>
      <Grid item xs={4}>
        <Typography variant="h3">{t("outcomes:metricDetails.measurementPoint")}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body1">
          {timeBasedTimeSummary(props.config.timePeriodType, props.config.timePeriodValue, t)}
        </Typography>
      </Grid>
      <SuccessCriteriaSummary successCriteria={props.config.metricSuccessCriteria} />
      <InclusionCriteriaSummary inclusionCriteria={props.config.metricInclusionCriteria} />
    </>
  );
}

function TriggerBasedMetricSummary(props: { config: TriggerBasedMetricConfiguration }) {
  const { t } = useTranslation(["outcomes", "enums"]);
  const { t: tEnum } = useTranslation(["enums"]);
  const triggers = props.config.metricTriggers.map((trigger, idx, arr) => {
    const text = triggerText(trigger.triggerType, trigger.trendValue, trigger.severityValue, t, tEnum);

    return (
      <React.Fragment key={idx}>
        {text}
        {idx < arr.length - 1 ? <br /> : null}
      </React.Fragment>
    );
  });
  return (
    <>
      <Grid item xs={4}>
        <Typography variant="h3">{t("outcomes:metricDetails.successTriggers")}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body1">{triggers}</Typography>
      </Grid>
      <InclusionCriteriaSummary inclusionCriteria={props.config.metricInclusionCriteria} />
    </>
  );
}

function InclusionCriteriaSummary(props: {
  inclusionCriteria: ReadonlyArray<
    Pick<
      MetricInclusionCriterion,
      | "criterionType"
      | "numberValue"
      | "operator"
      | "severityValues"
      | "excludeResults"
      | "integerArrayValue"
      | "stringArrayValue"
    >
  >;
}) {
  const { t } = useTranslation(["outcomes"]);
  const criteria = props.inclusionCriteria.map((criterion, idx, arr) => {
    return (
      <React.Fragment key={idx}>
        <InclusionCriterionText
          criterionType={criterion.criterionType}
          numberValue={criterion.numberValue}
          operator={criterion.operator}
          severityValues={criterion.severityValues}
          excludeResults={criterion.excludeResults}
          integerArrayValue={criterion.integerArrayValue}
          stringArrayValue={criterion.stringArrayValue}
        />
        {idx < arr.length - 1 ? <br /> : null}
      </React.Fragment>
    );
  });
  return (
    <>
      <Grid item xs={4}>
        <Typography variant="h3">{t("outcomes:metricDetails.meetingInclusionCriteria")}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body1">{criteria}</Typography>
      </Grid>
    </>
  );
}

function SuccessCriteriaSummary(props: {
  successCriteria: ReadonlyArray<
    Pick<
      MetricSuccessCriterion,
      "criterionType" | "numberValue" | "operator" | "severityValues" | "trendValue"
    >
  >;
}) {
  const { t } = useTranslation(["outcomes"]);
  const criteria = props.successCriteria.map((criterion, idx, arr) => {
    return (
      <React.Fragment key={idx}>
        {successCriterionText(
          criterion.criterionType,
          criterion.numberValue,
          criterion.operator,
          criterion.severityValues,
          criterion.trendValue,
          t
        )}
        {idx < arr.length - 1 ? <br /> : null}
      </React.Fragment>
    );
  });
  return (
    <>
      <Grid item xs={4}>
        <Typography variant="h3">{t("outcomes:metricDetails.meetingSuccessCriteria")}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body1">{criteria}</Typography>
      </Grid>
    </>
  );
}

export function OutcomesMetricDetails(props: OutcomesMetricsDetailsProps) {
  const { t } = useTranslation(["outcomes", "common"]);
  const navigate = useNavigate();
  const { metric } = props;
  const [now, _] = React.useState(new Date());
  const nineMonthsAgo = startOfMonth(sub(now, { months: 9 }));
  const endOfThisMonth = endOfMonth(now);
  const [startDate, setStartDate] = useStickyMonthParameter(
    STICKY_PARAMETER_NAMES.START_DATE,
    STICKY_PARAMETER_FILTER_SETS.OUTCOMES,
    nineMonthsAgo,
    "start",
    true
  );
  const [endDate, setEndDate] = useStickyMonthParameter(
    STICKY_PARAMETER_NAMES.END_DATE,
    STICKY_PARAMETER_FILTER_SETS.OUTCOMES,
    endOfThisMonth,
    "end",
    true
  );
  // We explicitly don't want this to be sticky for outcomes metrics.
  // We want it to reset to the entity tree node of the metric in question, each time
  const [entityTreeNodeParams, setEntityTreeNodeParams] = useQueryStringEntityTreeNodeParameter(
    "metricPath",
    entityTreeNodeToParams(props.metric.entityTreeNode),
    true
  );
  const { search } = useLocation();

  const defaultParams = entityTreeNodeToParams(props.metric.entityTreeNode);

  const handleChangeStartDate = (newValue: Date) => {
    setStartDate(startOfMonth(newValue));
  };
  const handleChangeEndDate = (newValue: Date) => {
    setEndDate(endOfMonth(newValue));
  };

  const scaleBreakdown =
    metric.configuration.rollup === MetricRollupType.MULTI_SCALE ? (
      <Grid item xs={2}>
        <OutcomesMetricMultiScaleBreakdown
          scaleScorerConfigData={
            metric.configuration.scorerConfig.__typename === "MetricMultiScaleScorerConfiguration"
              ? metric.configuration.scorerConfig.scaleScorers
              : []
          }
          metricId={metric.id}
          metricConfiguration={metric.configuration}
          entityTreeNodeParams={entityTreeNodeParams}
          startDate={startDate}
          endDate={endDate}
        />
      </Grid>
    ) : null;

  return (
    <Page
      breadcrumbs={[
        <Link to="/app/outcomes" key="outcomes-breadcrumb">
          {t("outcomes:dashboard.title")}
        </Link>,
        <Typography key="outcomes-details-breadcrumb">{props.metric.name}</Typography>,
      ]}
      browserTitle={t("outcomes:metricDetails.title")}
    >
      <Grid container columns={2} spacing={1}>
        <Grid item lg={2} xs={2}>
          <Stack direction="row" spacing={1} paddingBottom={"0.5em"}>
            <Box width="15em">
              <DatePicker
                label={t("outcomes:metricDetails.startMonth")}
                views={["month", "year"]}
                openTo={"month"}
                defaultValue={nineMonthsAgo}
                onChange={(newValue: Date) => handleChangeStartDate(newValue)}
                value={startDate}
              />
            </Box>
            <Box width="15em">
              <DatePicker
                label={t("outcomes:metricDetails.endMonth")}
                views={["month", "year"]}
                openTo={"month"}
                value={endDate}
                defaultValue={endOfThisMonth}
                onChange={(newValue: Date) => handleChangeEndDate(newValue)}
              />
            </Box>
            <Box width="25em">
              <EntityTreeNodeSelect
                setValue={setEntityTreeNodeParams}
                entityTypes={allowedEntityTypes}
                value={entityTreeNodeParams}
                defaultValue={defaultParams}
              />
            </Box>
            <Box>
              <ResetAndStickyFilterButtonGroup
                onReset={() => {
                  setStartDate(nineMonthsAgo);
                  setEndDate(endOfThisMonth);
                  setEntityTreeNodeParams(defaultParams);
                }}
              />
            </Box>
            <Box flexGrow={1} />
            <AbandonMetricButton metric={metric} onSuccess={() => navigate("..")} />
            <LinkButton variant="contained" to={`edit${search}`} color="secondary">
              {t("outcomes:metricDetails.edit")}
            </LinkButton>
          </Stack>
        </Grid>
        <Grid item md={1} xs={2}>
          <Paper sx={{ height: "100%" }}>
            <MetricDetailsSection
              metric={metric}
              configuration={metric.configuration}
              entityTreeNodeParams={entityTreeNodeParams}
              startDate={startDate}
              endDate={endDate}
            />
          </Paper>
        </Grid>
        <Grid item md={1} xs={2}>
          <Paper>
            <PreviewMetricStats
              endDate={endDate}
              startDate={startDate}
              metricParams={defaultsToParams(metric.configuration)}
              entityTreeNodeParams={entityTreeNodeParams}
            />
          </Paper>
        </Grid>
        {scaleBreakdown}
        <Grid item xs={2}>
          <OutcomesMetricBreakdown
            metricConfiguration={metric.configuration}
            metricId={metric.id}
            entityTreeNodeParams={entityTreeNodeParams}
            setEntityTreeNodeParams={setEntityTreeNodeParams}
            startDate={startDate}
            endDate={endDate}
          />
        </Grid>
        <Grid item xs={2}>
          <OutcomesMetricComputedValueTable
            rollup={metric.configuration.rollup}
            metricId={metric.id}
            metricType={metric.configuration.__typename}
            entityTreeNodeParams={entityTreeNodeParams}
            startDate={startDate}
            endDate={endDate}
          />
        </Grid>
      </Grid>
    </Page>
  );
}

export default function OutcomesMetricsDetailsWrapper(props: { id: MetricId }) {
  const { t } = useTranslation(["common"]);
  const { remoteData } = apolloQueryHookWrapper(
    useOutcomesMetricDetailsQuery({
      variables: {
        id: props.id,
      },
    })
  );

  return remoteData.caseOf({
    Success: (metricResult) => {
      const metric = metricResult.outcomesMetric;
      if (metric) {
        return <OutcomesMetricDetails metric={metric} />;
      } else {
        return <ErrorMessage message={t("common:unexpectedError")} />;
      }
    },
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <ErrorMessage message={err.message} />,
  });
}
