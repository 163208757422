import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  useTheme,
} from "@mui/material";
import { ParseKeys, TFunction } from "i18next";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ClearableSelect } from "./ClearableSelect";
import { getEnumItems, EnumType, EnumValueOf, enumFromStringValue } from "./Enum";

type EnumSelectParams<T> = {
  optionsEnum: EnumType & T;
  title: ParseKeys;
  enumTrans: (value: EnumValueOf<T>, t: TFunction<["enums"]>) => string;
  sortFn?: (a: EnumValueOf<T>, b: EnumValueOf<T>) => number;
  onChange: (newValue: EnumValueOf<T> | null) => void;
  value: EnumValueOf<T> | null;
  omitOptions?: ReadonlyArray<EnumValueOf<T>>;
  defaultValue?: EnumValueOf<T> | null;
  helperText?: string | null;
} & Omit<SelectProps, "onChange" | "value">;

export default function EnumSelect<T extends EnumType>(props: EnumSelectParams<T>): ReactElement {
  const {
    sortFn,
    optionsEnum,
    title,
    enumTrans,
    value,
    omitOptions,
    helperText,
    defaultValue,
    sx,
    ...passthrough
  } = props;
  const { t } = useTranslation(["enums"]);
  const highlightColor = useTheme().palette.filterHighlight;

  const highlight = typeof defaultValue !== "undefined" && defaultValue !== value;

  const mergedSx = highlight
    ? [
        ...(Array.isArray(sx) ? sx : [sx]),
        {
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "4px",
            borderColor: highlightColor,
          },
        },
      ]
    : sx;

  const compare =
    sortFn ||
    function (_a: string, _b: string) {
      return 0;
    };

  const optionsToSkip = omitOptions || [];

  const items = getEnumItems(optionsEnum)
    .sort(compare)
    .filter((option) => !optionsToSkip.includes(option))
    .map((option) => {
      return (
        <MenuItem value={option.toString()} key={option.toString()}>
          {enumTrans(option, t)}
        </MenuItem>
      );
    });

  // Passing null into a controlled component doesn't work well, use empty string instead.
  const valueOrBlank = value === null ? "" : value;

  return (
    <FormControl error={props.error} fullWidth>
      <ClearableSelect
        fullWidth
        label={title}
        value={valueOrBlank}
        {...passthrough}
        sx={mergedSx}
        onChange={props.onChange}
        error={props.error}
      >
        {items}
      </ClearableSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

type EnumSelectMultipleParams<T> = {
  optionsEnum: EnumType & T;
  enumTrans: (value: EnumValueOf<T>, t: TFunction<["enums"]>) => string;
  sortFn?: (a: EnumValueOf<T>, b: EnumValueOf<T>) => number;
  onChange: (newValue: ReadonlyArray<EnumValueOf<T>> | null) => void;
  value: ReadonlyArray<EnumValueOf<T>> | null;
  omitOptions?: ReadonlyArray<EnumValueOf<T>>;
} & Omit<SelectProps, "onChange" | "value">;

export function EnumSelectMultiple<T extends EnumType>(props: EnumSelectMultipleParams<T>): ReactElement {
  const { sortFn, optionsEnum, enumTrans, value, omitOptions, onChange, ...passthrough } = props;
  const { t } = useTranslation(["enums"]);

  const compare =
    sortFn ||
    function (_a: string, _b: string) {
      return 0;
    };

  const optionsToSkip = omitOptions || [];

  const items = getEnumItems(optionsEnum)
    .sort(compare)
    .filter((option) => !optionsToSkip.includes(option))
    .map((option) => {
      return (
        <MenuItem value={option.toString()} key={option.toString()}>
          {enumTrans(option, t)}
        </MenuItem>
      );
    });

  const handleOnChange = (event: SelectChangeEvent<ReadonlyArray<EnumValueOf<T>>>) => {
    const finalArray: Array<EnumValueOf<T>> = [];
    Array.isArray(event.target.value)
      ? event.target.value.forEach((value) => {
          const parsed = enumFromStringValue<EnumValueOf<T>>(optionsEnum, value);

          if (parsed) {
            finalArray.push(parsed);
          }
        })
      : null;

    onChange(finalArray);
  };

  // Passing null into a controlled component doesn't work well, use empty string instead.
  const valueOrEmpty = value === null ? [] : value;

  return (
    <Select multiple sx={{ height: "2em" }} value={valueOrEmpty} onChange={handleOnChange} {...passthrough}>
      {items}
    </Select>
  );
}
