import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  EntityTreeNodeParams,
  ImplementationTargetType,
  useImplementationTargetWidgetQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React from "react";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import {
  ImplementationTargetHeadlineGraph,
  ImplementationTargetHeadlineStatBox,
  ImplementationTargetOnTrackBadge,
} from "./ImplementationTargetElements";
import { Card, CardHeader, CardContent, Grid, Stack, Typography } from "@mui/material";
import { LinkButton } from "MDS/Link";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EntityPath from "Entities/EntityPath";
import { useIsInternalUser } from "AppSession/AuthenticatedProviderUser";

export function ImplementationTargetWidgetWithMissing(props: {
  implementationTargetType: ImplementationTargetType | null;
  date: Date;
  entityTreeNode: EntityTreeNodeParams;
}) {
  const { implementationTargetType } = props;
  const { t } = useTranslation(["implementation"]);
  if (implementationTargetType) {
    return <ImplementationTargetWidget {...props} implementationTargetType={implementationTargetType} />;
  } else {
    return (
      <Card>
        <CardHeader title={t("implementation:widget.noActive.title")} />
        <CardContent>
          <Typography>{t("implementation:widget.noActive.description")}</Typography>
        </CardContent>
      </Card>
    );
  }
}

export function ImplementationTargetWidget(props: {
  implementationTargetType: ImplementationTargetType;
  date: Date;
  entityTreeNode: EntityTreeNodeParams;
}) {
  const { implementationTargetType, date, entityTreeNode } = props;
  const allowMirahInternal = useIsInternalUser() ?? false;
  const { t } = useTranslation(["implementation"]);
  const { remoteData } = apolloQueryHookWrapper(
    useImplementationTargetWidgetQuery({
      variables: {
        implementationTargetType,
        date,
        entityTreeNode,
        allowMirahInternal,
      },
    })
  );

  const { search } = useLocation();

  const content = remoteData.caseOf({
    Success: (result) => {
      const report = result.implementationTargetBestReport;
      if (!report) {
        return (
          <Card>
            <CardHeader title={t("implementation:widget.noActive.title")} />
            <CardContent>
              <Typography>{t("implementation:widget.noActive.description")}</Typography>
            </CardContent>
          </Card>
        );
      }

      const statBoxes = report.headlineStats.map((stat, index) => {
        return (
          <ImplementationTargetHeadlineStatBox
            endDate={report.reportDate.endDate}
            stat={stat}
            period={stat.period}
            key={index}
          />
        );
      });

      return (
        <Card>
          <CardHeader
            title={report.implementationTarget.name}
            subheader={
              <EntityPath
                includeInstitute={"when_solo"}
                entityTreeNode={report.implementationTarget.entityTreeNode}
              />
            }
          />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <ImplementationTargetHeadlineGraph
                  series={report.graphData}
                  unitClass={report.unitClass}
                  maxValue={report.graphMaxValue}
                  targetType={report.implementationTarget.targetType}
                />
              </Grid>
              <Grid item xs={3}>
                <Stack direction={"column"} spacing={1}>
                  {statBoxes}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <ImplementationTargetOnTrackBadge onTrack={report.onTrack} />
              </Grid>
              <Grid item xs={12}>
                <LinkButton
                  variant="contained"
                  color="secondary"
                  fullWidth
                  to={`/app/implementation/${report.implementationTarget.id.toString()}${search}`}
                >
                  {t("implementation:card.moreInsights")}
                </LinkButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    },
    Failure: (error) => <ErrorMessage message={error.message} />,
    Loading: () => <Spinner />,
    NotAsked: () => <Spinner />,
  });

  return content;
}
