import { Button, DialogActions, DialogContent } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  Enrollment,
  Panel,
  PanelTeamMember,
  Provider,
  useUpdateEnrollmentMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { Form, FormOverlay, useForm, useWrappedField } from "Shared/Form";
import { PanelCareTeamSelect } from "CollaborativeCare/Panels/PanelCareTeamSelector";

type TeamMemberSummary = PickTypename<PanelTeamMember, "id"> & {
  provider: PickTypename<Provider, "id" | "name">;
};

type EnrollmentWithCareTeam = PickTypename<Enrollment, "id"> & {
  panel: PickTypename<Panel, "id" | "name"> | null;
  primaryCareProviderTeamMember: TeamMemberSummary | null;
  careManagerTeamMember: TeamMemberSummary | null;
};

type EditEnrollmentCareTeamButtonProps = {
  enrollment: EnrollmentWithCareTeam;
};

type MaybeEditEnrollmentCareTeamButtonProps = {
  enrollment: EnrollmentWithCareTeam | undefined | null;
};

export function MaybeEditEnrollmentCareTeamButton(
  props: MaybeEditEnrollmentCareTeamButtonProps
): ReactElement | null {
  return props.enrollment ? <EditEnrollmentCareTeamButton enrollment={props.enrollment} /> : null;
}

export function EditEnrollmentCareTeamButton(props: EditEnrollmentCareTeamButtonProps): ReactElement {
  const { t } = useTranslation(["common"]);

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button variant="text" onClick={() => setOpen(true)}>
        {t("common:actions.edit")}
      </Button>
      <ResponsiveDialog open={open} onClose={() => setOpen(false)}>
        <EditEnrollmentCareTeamForm
          enrollment={props.enrollment}
          onSuccess={() => {
            setTimeout(() => setOpen(false), 500);
          }}
        />
      </ResponsiveDialog>
    </>
  );
}

type EditEnrollmentCareTeamFormProps = {
  enrollment: EnrollmentWithCareTeam;
  onSuccess?: () => void;
};

function EditEnrollmentCareTeamForm(props: EditEnrollmentCareTeamFormProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare", "patients"]);
  const [updateEnrollment, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareUpdateEnrollment,
    useUpdateEnrollmentMutation()
  );

  const fields = {
    panel: useWrappedField({ default: props.enrollment.panel?.id, required: true, errorPaths: ["panel_id"] }),
    pcp: useWrappedField({
      default: props.enrollment.primaryCareProviderTeamMember?.id,
      required: true,
      errorPaths: ["primary_care_provider_id"],
    }),
    careManager: useWrappedField({
      default: props.enrollment.careManagerTeamMember?.id,
      required: true,
      errorPaths: ["care_manager_id"],
    }),
  };

  const form = useForm({
    id: "update-enrollment-care-team-form",
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      updateEnrollment({
        variables: {
          input: {
            enrollmentId: props.enrollment.id,
            panelId: fields.panel.value,
            primaryCareProviderId: fields.pcp.value,
            careManagerId: fields.careManager.value,
          },
        },
      });
    },
    onSuccess: props.onSuccess,
  });

  return (
    <>
      <DialogContent>
        <Form id={form.id} onSubmit={form.onSubmit}>
          <FormOverlay
            response={remoteData}
            errorMessage={
              form.globalError || t("collaborativeCare:enrollment.updateConsentDateDialog.errorSaving")
            }
          />
          <PanelCareTeamSelect
            panel={fields.panel}
            primaryCareProvider={fields.pcp}
            careManager={fields.careManager}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button type="submit" variant="contained" color="secondary" form={form.id}>
          {t("common:actions.save")}
        </Button>
      </DialogActions>
    </>
  );
}
