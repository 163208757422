import { Card, CardContent, CardHeader, Checkbox, Collapse, FormControlLabel, Grid } from "@mui/material";
import { CocmBillingAlgorithmRuleId } from "Lib/Ids";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BillingRuleNameDetails, billingRuleName } from "./BillingRuleHelpers";
import { ExpandMore } from "@mui/icons-material";

// Note that we will translate 12 to be 12+ in the back end
const allowedMonthsOfEnrollment = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export type BillingDashboardFilters = {
  monthsOfEnrollment: ReadonlyArray<number>;
  setMonthsOfEnrollment: (values: ReadonlyArray<number>) => void;
  selectedRuleIds: ReadonlyArray<CocmBillingAlgorithmRuleId>;
  setSelectedRuleIds: (values: ReadonlyArray<CocmBillingAlgorithmRuleId>) => void;
  // This contains info on the currently active rules to reference by id
  selectedRuleDetails: ReadonlyArray<BillingRuleNameDetails>;
};
export function useBillingDashboardFilters(
  ruleData: ReadonlyArray<BillingRuleNameDetails>
): BillingDashboardFilters {
  // TODO: consider make these parameters sticky
  const [monthsOfEnrollment, setMonthsOfEnrollment] =
    React.useState<ReadonlyArray<number>>(allowedMonthsOfEnrollment);

  const [selectedRuleIds, setSelectedRuleIds] = React.useState<ReadonlyArray<CocmBillingAlgorithmRuleId>>(
    ruleData.map((r) => r.id)
  );

  const selectedRuleDetails = React.useMemo(() => {
    return ruleData.filter((r) => selectedRuleIds.includes(r.id));
  }, [selectedRuleIds]);

  return {
    monthsOfEnrollment,
    setMonthsOfEnrollment,
    selectedRuleIds,
    setSelectedRuleIds,
    selectedRuleDetails,
  };
}

function CheckableRule(props: {
  name: string;
  checked: boolean;
  onChange: (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}) {
  return (
    <FormControlLabel
      control={<Checkbox checked={props.checked} onChange={props.onChange} />}
      label={props.name}
    />
  );
}

export function BillingDashboardFilters(props: {
  filters: BillingDashboardFilters;
  allRuleDetails: ReadonlyArray<BillingRuleNameDetails>;
}) {
  const { t } = useTranslation(["billing"]);
  const { filters, allRuleDetails } = props;

  const [open, setOpen] = useState<boolean>(false);

  const handleCheckboxRuleChange = (ruleId: CocmBillingAlgorithmRuleId) => {
    return (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const without = filters.selectedRuleIds.filter((s) => s !== ruleId);

      if (checked) {
        filters.setSelectedRuleIds([...without, ruleId]);
      } else {
        filters.setSelectedRuleIds([...without]);
      }
    };
  };

  const handleMonthChange = (month: number) => {
    return (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const without = filters.monthsOfEnrollment.filter((s) => s !== month);

      if (checked) {
        filters.setMonthsOfEnrollment([...without, month]);
      } else {
        filters.setMonthsOfEnrollment([...without]);
      }
    };
  };

  const rules = allRuleDetails.map((rule) => {
    return (
      <CheckableRule
        key={rule.id.toString()}
        name={billingRuleName(rule)}
        checked={filters.selectedRuleIds.includes(rule.id)}
        onChange={handleCheckboxRuleChange(rule.id)}
      />
    );
  });

  const months = allowedMonthsOfEnrollment.map((month) => {
    return (
      <CheckableRule
        key={month}
        name={t("billing:dashboard.filters.month", { month })}
        checked={filters.monthsOfEnrollment.includes(month)}
        onChange={handleMonthChange(month)}
      />
    );
  });

  return (
    <Card>
      <CardHeader
        title={t("billing:dashboard.filters.title")}
        subheader={
          open ? t("billing:dashboard.filters.explanation") : t("billing:dashboard.filters.explanationClosed")
        }
        onClick={() => setOpen(!open)}
        action={<ExpandMore />}
      />
      <CardContent>
        <Collapse in={open}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              {rules}
            </Grid>
            <Grid item xs={12} sm={6}>
              {months}
            </Grid>
          </Grid>
        </Collapse>
      </CardContent>
    </Card>
  );
}
