import { Box, Card, CardContent, Grid, Stack } from "@mui/material";
import { useCurrentRootNode } from "Contexts/CurrentInstituteIdContext";
import {
  CocmBillingAlgorithmRuleCheckValue,
  EntityTreeNodeParams,
  ImplementationTargetType,
  MonthParams,
  useCocmBillingDashboardRulesQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { allowedEntityTypes } from "Implementation";
import { ImplementationTargetWidgetWithMissing } from "Implementation/ImplementationTargetWidget";
import Page from "Layout/Page";
import DatePicker from "Shared/DatePickers";
import {
  useStickyEntityTreeNodeParameter,
  useStickyEnumParameter,
  useStickyMonthParameterV2,
} from "Shared/StickyParameter";
import { STICKY_PARAMETER_FILTER_SETS, STICKY_PARAMETER_NAMES } from "Shared/Storage";
import React from "react";
import { useTranslation } from "react-i18next";
import { BillingCumulativeEffort } from "./BillingCumulativeEffort";
import { dateToMonth, endOfMonth, monthToDate, monthToday } from "Shared/Month";
import Link, { LinkButton } from "MDS/Link";
import EnumSelect from "Shared/EnumSelect";
import { cocmBillingAlgorithmRuleCheckValueT } from "GeneratedGraphQL/EnumTranslations";
import { ALL_VALUE_TYPES, BillingPredictionTable } from "./BillingPrediction";
import { BillingPredictionFilters } from "./BillingPredictionFilters";
import { useLocation } from "react-router-dom";
import { useQueryStringIdParameter } from "Shared/QueryStringParameter";
import BillingWinnerDetailsModal from "./BillingWinnerDetailsModal";
import { BillingSummaryStats } from "./BillingSummaryStats";
import { BillingDashboardFilters, useBillingDashboardFilters } from "./BillingDashboardFilters";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import Spinner from "Shared/Spinner";
import ErrorMessage from "Shared/ErrorMessage";
import { BillingRuleNameDetails } from "./BillingRuleHelpers";
import { sortBy } from "ramda";
import EntityTreeNodeSelect from "Shared/Filters/EntityTreeNodeSelect";

function valueTypeToImplementationTargetType(
  valueType: CocmBillingAlgorithmRuleCheckValue
): ImplementationTargetType | null {
  switch (valueType) {
    case CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE:
      return ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLING;
    case CocmBillingAlgorithmRuleCheckValue.RVUS:
      return ImplementationTargetType.COCM_MONTHLY_EXPECTED_RVUS;
    case CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS:
      return ImplementationTargetType.COCM_MONTHLY_EXPECTED_VALUE_UNITS;
    default:
      return null;
  }
}

function BillingDashboardInner(props: {
  month: MonthParams;
  entityTreeNodeParams: EntityTreeNodeParams;
  valueType: CocmBillingAlgorithmRuleCheckValue;
  ruleData: ReadonlyArray<BillingRuleNameDetails>;
}) {
  const { month, entityTreeNodeParams, valueType, ruleData } = props;
  const [activeWinnerId, setActiveWinnerId] =
    useQueryStringIdParameter<"EnrollmentMonthBillingRuleResultWinner">("winnerId", true);

  const filters = useBillingDashboardFilters(props.ruleData);
  const { t } = useTranslation(["billing"]);
  const { search } = useLocation();

  const predictionFilters: BillingPredictionFilters = {
    month,
    // You can't set the month
    setMonth: (_value) => {},
    // We don't use search here but it can be plugged into the table.
    search: null,
    setSearch: (_value: string) => {},
    valueType,
    // Can't set value type from here
    setValueType: (_value) => {},
    entityTreeNodeParams,
    setEntityTreeNodeParams: (_value) => {},
    reset: () => {},
  };

  const details = activeWinnerId ? (
    <BillingWinnerDetailsModal winnerId={activeWinnerId} onClose={() => setActiveWinnerId(null)} />
  ) : null;

  return (
    <>
      <Grid item xs={1}>
        <ImplementationTargetWidgetWithMissing
          implementationTargetType={valueTypeToImplementationTargetType(valueType)}
          entityTreeNode={entityTreeNodeParams}
          date={endOfMonth(month)}
        />
      </Grid>
      <Grid item xs={1}>
        <BillingCumulativeEffort
          month={month}
          entityTreeNode={entityTreeNodeParams}
          valueType={valueType}
          setActiveWinnerId={setActiveWinnerId}
          monthOfEnrollment={filters.monthsOfEnrollment}
          ruleIds={filters.selectedRuleIds}
        />
      </Grid>
      <Grid item xs={2}>
        <BillingDashboardFilters filters={filters} allRuleDetails={ruleData} />
      </Grid>
      <Grid item xs={2}>
        <BillingSummaryStats
          rules={filters.selectedRuleDetails}
          entityTreeNode={entityTreeNodeParams}
          month={month}
          valueType={valueType}
          monthOfEnrollment={filters.monthsOfEnrollment}
        />
      </Grid>
      <Grid item xs={2}>
        <BillingPredictionTable
          allowedValueTypes={ALL_VALUE_TYPES}
          viewContext="dashboard"
          filters={predictionFilters}
          onRowClick={setActiveWinnerId}
          monthsofTreatment={filters.monthsOfEnrollment}
          winningRuleIds={filters.selectedRuleIds}
        />
        <LinkButton to={`prediction?${search}`} variant="contained" color="secondary" fullWidth>
          Show Full Predictions
        </LinkButton>
      </Grid>
      <Grid item xs={2}>
        <Link to="configuration">{t("billing:dashboard.viewBillingAlgorithm")}</Link>
      </Grid>
      {details}
    </>
  );
}

export default function BillingDashboard() {
  const { t } = useTranslation(["billing"]);

  const [currentRootNode] = useCurrentRootNode();
  const [entityTreeNodeParams, setEntityTreeNodeParams] = useStickyEntityTreeNodeParameter(
    STICKY_PARAMETER_NAMES.ENTITY_TREE_NODE,
    STICKY_PARAMETER_FILTER_SETS.BILLING,
    currentRootNode,
    true
  );

  const [month, setMonth] = useStickyMonthParameterV2(
    "month",
    STICKY_PARAMETER_FILTER_SETS.BILLING,
    monthToday(),
    true
  );

  const [valueType, setValueType] = useStickyEnumParameter(
    "valueType",
    STICKY_PARAMETER_FILTER_SETS.BILLING,
    CocmBillingAlgorithmRuleCheckValue,
    CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE,
    true
  );
  const { remoteData: availableRulesData } = apolloQueryHookWrapper(
    useCocmBillingDashboardRulesQuery({
      variables: {
        entityTreeNode: entityTreeNodeParams,
      },
    })
  );

  const inner = availableRulesData.caseOf({
    Success: (result) => {
      const firstAlgo = result.billingCocmBillingAlgorithms?.nodes[0];
      if (firstAlgo) {
        const sortedRules = sortBy((item) => -item.priority, firstAlgo.rules);
        // TODO: limitation for now: we only take the rules from the first active algorithm. If you have multiple active
        // Algorithms tthis will need to be extended to support that.
        return (
          <BillingDashboardInner
            entityTreeNodeParams={entityTreeNodeParams}
            month={month}
            valueType={valueType}
            ruleData={sortedRules}
          />
        );
      } else {
        return (
          <Card>
            <CardContent>{t("billing:dashboard.noAlgo")}</CardContent>
          </Card>
        );
      }
    },
    Failure: (error) => <ErrorMessage message={error.message} />,
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
  });

  return (
    <Page browserTitle={t("billing:dashboard.title")}>
      <Grid container columns={2} spacing={1}>
        <Grid item lg={2} xs={2}>
          <Stack direction="row" spacing={1}>
            <Box>
              <DatePicker
                views={["year", "month"]}
                label={t("billing:prediction.fields.yearAndMonth")}
                minDate={new Date("2019-01-01")} // This should be the oldest enrollment for the institute.
                maxDate={new Date()}
                openTo={"month"}
                value={monthToDate(month)}
                onChange={(newValue) => {
                  if (newValue) {
                    setMonth(dateToMonth(newValue));
                  }
                }}
              />
            </Box>

            <Box minWidth="25em">
              <EntityTreeNodeSelect
                setValue={setEntityTreeNodeParams}
                entityTypes={allowedEntityTypes}
                value={entityTreeNodeParams}
                defaultValue={currentRootNode}
              />
            </Box>
            <Box minWidth="20em">
              <EnumSelect
                value={valueType}
                onChange={setValueType}
                optionsEnum={CocmBillingAlgorithmRuleCheckValue}
                enumTrans={cocmBillingAlgorithmRuleCheckValueT}
                title={t("billing:prediction.showValueBy")}
                defaultValue={CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE}
              />
            </Box>
          </Stack>
        </Grid>
        {inner}
      </Grid>
    </Page>
  );
}
