import React, { ReactElement } from "react";
import { Link } from "MDS/Link";
import { Box, Drawer, List, Typography, drawerClasses } from "@mui/material";
import NavItem from "./NavItem";
import { InstituteContext } from "AppSession/AppContext";
import { styled, useTheme } from "@mui/material/styles";
import { NavInstituteMenu } from "./NavInstituteMenu";
import { NavUserMenu } from "./NavUserMenu";
import { OnDesktop, OnMobile } from "Shared/Responsive";
import { LogoLink } from "MDS/Logo";
import { AppLayoutNavData, AppLayoutNavigationItem } from "../AppLayout";
import { LinkProps, useLocation } from "react-router-dom";
import { FrontendFlagsMenu } from "Contexts/FrontendFlagContext";
import { GlobalPanelIndicatorBadge } from "CollaborativeCare/Panels/GlobalPanelIndicator";
import { DebugModal } from "Debug/DebugModal";
import { NotInEmbeddedPatientOrEncounter } from "Contexts/LaunchContext";
import { GlobalInstituteGroupIndicatorBadge } from "AppSession/GlobalInstituteGroupIndicator";

// This is the type that is used for each menu item appearing in the top nav under
// individual users.
export type UserMenuItem = {
  label?: string;
  location?: string;
  icon?: ReactElement;
  additionalElement?: ReactElement;
  onClick?: () => void;
};

type Props = {
  institute: InstituteContext;
  user: {
    name: string;
    lastSignIn: Date | null;
  };
  navItems: AppLayoutNavData;
  userMenuItems: Array<UserMenuItem>;
  instituteNavItems: Array<AppLayoutNavigationItem>;
  userBadge?: { count: number };
  dialogs?: ReactElement;
};

function UserContext({ institute, user, instituteNavItems }: Props): ReactElement {
  const inst = institute.caseOf({
    ProviderInstituteContext: (inst) => inst,
    MirahInternalInstituteContext: (inst) => inst,
    GroupLeaderInstituteContext: (inst) => inst,
  });

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Typography variant="h2">{user.name}</Typography>
        <NavInstituteMenu instituteName={inst.name} instituteNavItems={instituteNavItems} />
      </Box>
    </>
  );
}

function NavBarContent({
  institute,
  user,
  navItems,
  userMenuItems,
  instituteNavItems,
  userBadge,
}: Props): ReactElement {
  const allNavItems = [navItems.primaryNavigation, ...navItems.additionalNavigation];
  const navLinks = (
    <List disablePadding>
      {allNavItems.map((navItem, index) => (
        <NavItem
          key={`${index}-${navItem.location}-${navItem.label}`}
          href={navItem.location || ""}
          title={navItem.label || ""}
        />
      ))}
    </List>
  );

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <LogoLink to={navItems.primaryNavigation.location || ""} />
      <UserContext
        navItems={navItems}
        user={user}
        institute={institute}
        userMenuItems={userMenuItems}
        instituteNavItems={instituteNavItems}
        userBadge={userBadge}
      />
      <Box sx={{ mt: 5, mb: 5 }}>{navLinks}</Box>
      <FrontendFlagsMenu />
    </Box>
  );
}

const StyledMobileDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${drawerClasses.paper}`]: {
    width: theme.spacing(170 / 16),
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    borderRight: "solid white 1.5px",
  },
}));
const StyledDesktopDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${drawerClasses.paper}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    border: 0,
    borderBottom: "solid white 1.5px",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const NavLink = styled(Link)(({ theme }) => ({
  color: "inherit",
  textTransform: "capitalize",
  margin: `0 ${theme.spacing(1)}`,
  display: "inline-block",
  flexGrow: 0,
  textDecoration: "none",
}));

type DesktopNavLinkProps = LinkProps & {
  // A list of other route prefixes that the nav item should consider itself active for.
  activeFor?: Array<string>;
};

function DesktopNavLink(props: DesktopNavLinkProps): ReactElement {
  const theme = useTheme();
  const location = useLocation();
  const prefixes = [...(props.activeFor || []), props.to.toString()];
  // Look for a prefix instead of an exact match so that we can tell a nav link to be active for `/app/cocm/patient` and
  // it'll count for all patient pages no matter the id.
  const active = prefixes.some((prefix) => location.pathname.startsWith(prefix));

  // We set the box containing the link and indicator to relative positioning so that it's the "positioned parent" of
  // the indicator, so all the indicator coordinates are in reference to that box. THen we stretch this to the width
  // of the link (left: 0, right: 0) and put it _below_ the link (bottom: spacing(-1)). This negative bottom lines it
  // up with the bottom of the top drawer.
  const indicator = active ? (
    <Box
      sx={{
        position: "absolute",
        // This is a very magic number that just happens to make everything work out. Because the links are just
        // centered in the drawer's box this height is the padding of the drawer plus the amount of space a link
        // happens to take up vertically.
        bottom: theme.spacing(-1.5),
        left: 0,
        right: 0,
        // 2px matches the size of the indicators on Mui's tab navs
        height: "2px",
        backgroundColor: theme.palette.dividerLight,
      }}
    />
  ) : null;

  // This is just pulling out activeFor from props so we don't throw warnings at ourselves.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { activeFor, ...linkProps } = props;

  return (
    <Box
      sx={{
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        position: "relative",
      }}
    >
      <NavLink {...linkProps} />
      {indicator}
    </Box>
  );
}

export const NavUserItem = styled(Typography)(({ theme }) => ({
  margin: `0 ${theme.spacing(1)}`,
  flexGrow: 0,
}));

const NavSpacing = styled(Box)(() => ({
  flexGrow: 1,
}));

type NavDrawerItems = {
  onNavDrawerClose: () => void;
  navDrawerOpen: boolean;
};

function NavBar(props: Props & NavDrawerItems): ReactElement {
  const { institute } = props;
  const inst = institute.caseOf({
    ProviderInstituteContext: (inst) => inst,
    MirahInternalInstituteContext: (inst) => inst,
    GroupLeaderInstituteContext: (inst) => inst,
  });

  return (
    <NotInEmbeddedPatientOrEncounter>
      {props.dialogs}
      <OnDesktop>
        <StyledDesktopDrawer anchor="top" open variant="persistent">
          <LogoLink to={props.navItems.primaryNavigation.location || ""} />
          <Box sx={{ width: "1rem" }}>&nbsp;</Box>
          <DesktopNavLink
            to={props.navItems.primaryNavigation.location || ""}
            activeFor={props.navItems.primaryNavigation.activeFor}
          >
            <Typography variant="h2">{props.navItems.primaryNavigation.label}</Typography>
          </DesktopNavLink>
          {props.navItems.additionalNavigation.map((navItem, index) => (
            <DesktopNavLink
              key={`${index}-${navItem.location}-${navItem.label}`}
              to={navItem.location || ""}
              activeFor={navItem.activeFor}
            >
              <Typography variant="h2">{navItem.label}</Typography>
            </DesktopNavLink>
          ))}
          <NavSpacing />
          <DebugModal />
          <GlobalInstituteGroupIndicatorBadge />
          <FrontendFlagsMenu />
          <Box marginLeft="1rem" marginRight="1rem">
            <GlobalPanelIndicatorBadge />
          </Box>
          <NavUserMenu
            isMobile={false}
            fontVariant="h2"
            arrowSize={24}
            user={props.user}
            userMenuItems={props.userMenuItems}
            userBadge={props.userBadge}
          />
          <NavInstituteMenu instituteName={inst.name} instituteNavItems={props.instituteNavItems} />
        </StyledDesktopDrawer>
      </OnDesktop>
      <OnMobile>
        <StyledMobileDrawer anchor="left" onClose={props.onNavDrawerClose} open={props.navDrawerOpen}>
          <NavBarContent {...props} />
        </StyledMobileDrawer>
      </OnMobile>
    </NotInEmbeddedPatientOrEncounter>
  );
}

export default NavBar;
