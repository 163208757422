import { Face as FaceIcon } from "@mui/icons-material";
import { Patient, useSearchPatientsLazyQuery } from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { QueryAutocompleteMultiple } from "Shared/QueryAutocomplete";

type Props = {
  valueUpdated: (values: Array<Pick<Patient, "__typename" | "name" | "id">>) => void;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  value: Array<Pick<Patient, "__typename" | "name" | "id">>;
};

function PatientsAutocomplete(props: Props): ReactElement {
  return (
    <QueryAutocompleteMultiple
      value={props.value}
      query={useSearchPatientsLazyQuery}
      queryVariables={{}}
      valueUpdated={props.valueUpdated}
      unwrapResponse={(response) =>
        // this is ugly as sin and we should fix it
        response.patients?.nodes
      }
      valueEqual={(a, b) => a.id === b.id}
      error={props.error}
      required={props.required}
      helperText={props.helperText}
      label="Patients"
      skipPreload
      autocompleteProps={{
        filterSelectedOptions: true,
        noOptionsText: "No Matching Patients",
        getOptionLabel: (option) => option.name,
        ChipProps: { icon: <FaceIcon /> },
      }}
      data-testid="patients-autocomplete"
    />
  );
}

export default PatientsAutocomplete;
