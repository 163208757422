import {
  IntakeStatus,
  Patient,
  useBeginTimeEntryLogMutation,
  useEndTimeEntryLogMutation,
  useEnrollmentTaskQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { EnrollmentId, CareEpisodeId } from "Lib/Ids";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { IntakeFlow } from "./IntakeScalesFlow";
import { useCurrentTimeTracking } from "Contexts/TimeTracking/CurrentTimeTrackingContext";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";

type IntakeDialogProps = {
  open: boolean;
  patient: PickTypename<Patient, "id" | "name">;
  enrollmentId: EnrollmentId;
  careEpisodeId: CareEpisodeId;
  intakeStatus: IntakeStatus;
  onClose: () => void;
};

export function IntakeDialog(props: IntakeDialogProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  useStartTrackingEnrollmentTask(props.enrollmentId, props.open);

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      title={t("collaborativeCare:enrollment.intakeTitle")}
      keepMounted={false}
      dialogWidth="50%"
      stopBackdropClose={true}
    >
      <IntakeFlow
        patient={props.patient}
        enrollmentId={props.enrollmentId}
        careEpisodeId={props.careEpisodeId}
        intakeStatus={props.intakeStatus}
        onClose={props.onClose}
      />
    </ResponsiveDialog>
  );
}

// I had the idea that since we have all the pieces to do this it would be better to use the pieces rather than making
// a bespoke mutation just for this one thing. Maybe not though? This turned out worse than I expected.
function useStartTrackingEnrollmentTask(enrollmentId: EnrollmentId, dialogOpen: boolean) {
  const [endTimeEntryLog, { remoteData: endLogRemoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareEndTimeEntryLog,
    useEndTimeEntryLogMutation()
  );

  const [startTimeEntryLog, { remoteData: startLogRemoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareStartTimeEntryLogNow,
    useBeginTimeEntryLogMutation({})
  );

  const { remoteData: enrollmentRemoteData } = apolloQueryHookWrapper(
    useEnrollmentTaskQuery({ variables: { enrollmentId: enrollmentId } })
  );

  const timeTracking = useCurrentTimeTracking();

  const currentProvider = React.useContext(AuthenticatedProviderUserContext);

  useEffectSimpleCompare(() => {
    if (!dialogOpen) {
      return;
    }

    const enrollmentTaskId = enrollmentRemoteData.caseOf({
      Success: (response) => response.collaborativeCareEnrollment?.enrollmentTask?.id,
      _: () => undefined,
    });

    // If there wasn't an enrollment task created during the original enrollment flow we're in a weird state, don't
    // create one now.
    if (enrollmentTaskId === undefined) {
      return;
    }

    if (!currentProvider) {
      return;
    }

    const currentProviderId = currentProvider.providerId.getOrElse(null);
    if (!currentProviderId) {
      return;
    }

    if (timeTracking.status === "tracking") {
      if (timeTracking.task.id !== enrollmentTaskId && endLogRemoteData.kind === "NotAsked") {
        endTimeEntryLog({ variables: { input: { timeEntryLogId: timeTracking.timeEntryLogId } } });
      }
    } else if (timeTracking.status === "not tracking" && startLogRemoteData.kind === "NotAsked") {
      startTimeEntryLog({
        variables: {
          input: { clientStartTime: new Date(), providerId: currentProviderId, taskId: enrollmentTaskId },
        },
      });
    }
  }, [
    dialogOpen,
    enrollmentRemoteData.kind,
    timeTracking.status,
    endLogRemoteData.kind,
    startLogRemoteData.kind,
  ]);
}
