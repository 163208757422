const organizations = {
  title: "Organizations",
  loadError: "Failed to load organizations",
  saveError: "Failed to save organization",
  notSelected: "Select an organization from the tree to view more information",
  ehrMessage:
    "This organizations list is controlled by data imported from your EHR. To create or edit these organizations, make changes in your system and they will be reflected in Mirah shortly.",
  fields: {
    name: "Name",
    shortname: "Short Name",
    inTrialPeriod: "In Trial Period",
    measurementAllowed: "Measurement Allowed",
    parent: "Parent Organization (optional)",
    timezone: "Timezone",
  },
  actions: {
    create: "Create Organization",
    edit: "Edit Organization",
  },
  info: {
    inTrialPeriod:
      "Organizations in a trial period will automatically create care episodes when Mirah gets new appointments from your EHR, but those care episodes will not be participating in MBC, and will not send measures.",
    shortname: "An organization's shortname is shown in Mirah when text space is limited",
    timezone:
      "If this particular organization has a timezone specified it will appear here. Organizations with no timezone will inherit their timezone from the top level institute.",
  },
};

export default organizations;
