import React, { ReactElement, useState } from "react";
import CareUnitTable from "./CareUnitTable";
import Page from "../../Layout/Page";
import PageHeader from "../../Layout/PageHeader";
import SearchAndFilters from "./SearchAndFilters";
import { Button, Grid } from "@mui/material";
import { GroupAdd } from "@mui/icons-material";
import AddCareGroupDialog from "../AddUpdateCareGroupDialog/AddUpdateCareGroupDialog";
import * as Paginator from "Shared/Paginator";

const PAGE_SIZE = 20;

// -- VIEW --------------------------------------------------------------------

export default function CareUnitList(): ReactElement {
  const [showDialog, updateShowDialog] = useState<boolean>(false);

  const [perspective, updatePerspective] = useState<{
    paging: Paginator.QueryPage;
    search: string | null;
  }>({
    paging: Paginator.initPage(PAGE_SIZE),
    search: null,
  });

  const dialog = showDialog ? (
    <AddCareGroupDialog close={() => updateShowDialog(false)} done={(x) => console.log(x.name)} />
  ) : null;

  return (
    <Page browserTitle="Care Units">
      <PageHeader
        title="Care Units"
        titleAction={
          <Button
            startIcon={<GroupAdd />}
            color="primary"
            size="small"
            onClick={() => updateShowDialog(true)}
            data-testid="add-care-unit-button"
          >
            Add Care Unit
          </Button>
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={9}>
          <CareUnitTable
            perspective={perspective}
            onPaginationChange={(p) => updatePerspective({ ...perspective, paging: p })}
          />
        </Grid>
        <Grid item xs={3}>
          <SearchAndFilters
            onChange={(sf) =>
              updatePerspective({ ...perspective, search: sf, paging: Paginator.initPage(PAGE_SIZE) })
            }
            search={perspective.search}
          />
        </Grid>
      </Grid>

      {dialog}
    </Page>
  );
}
