import { Grid } from "@mui/material";
import Page from "Layout/Page";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "Shared/Responsive";
import { Findings } from "./Findings";
import { DashboardTaskList } from "./BetterTaskList/DashboardTaskList";

export function CareManagerDashboard(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const columns = useIsMobile() ? 6 : 12;

  return (
    <Page browserTitle={t("collaborativeCare:careManagerDashboard.title")} supportsPanels>
      <Grid container columns={columns} spacing={2}>
        <Grid item xs={6}>
          <DashboardTaskList />
        </Grid>
        <Grid item xs={6}>
          <Findings />
        </Grid>
      </Grid>
    </Page>
  );
}
