import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { TreatmentService } from "GeneratedGraphQL/SchemaAndOperations";
import * as Id from "Lib/Id";
import { TreatmentServiceId } from "Lib/Ids";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

// We're opting to require the user to pass these values, but this box could probably
// just do the lookup itself too.
type TreatmentServicesSelectBoxProps = {
  startingParentId?: TreatmentServiceId;
  treatmentServices: ReadonlyArray<Pick<TreatmentService, "__typename" | "id" | "name">>;
  onChangeHandler: (newParentId: TreatmentServiceId | null) => void;
};
export function TreatmentServiceSelectBox(props: TreatmentServicesSelectBoxProps): ReactElement {
  const { t } = useTranslation(["treatmentServices"]);

  // We are going to work in strings here, because select works better that way.
  // We'll convert out to an actual TreatmentServiceId only when we call back to our
  // parent component's handler.
  const [parentId, setParentId] = useState(props.startingParentId ? props.startingParentId.toString() : null);
  const onParentIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setParentId(event.target.value);
    const parsedId = Id.fromString<"TreatmentService">(event.target.value).getOrElse(null);
    props.onChangeHandler(parsedId);
  };
  let parentSelectItems = [...props.treatmentServices]
    .sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    })
    .map((treatmentService) => {
      return (
        <MenuItem key={treatmentService.id.toString()} value={treatmentService.id.toString()}>
          {treatmentService.name}
        </MenuItem>
      );
    });
  const nullItem = (
    <MenuItem key="" value={""}>
      {t("treatmentServices:select.noneMenuItem")}
    </MenuItem>
  );
  parentSelectItems = [nullItem, ...parentSelectItems];

  return (
    <FormControl fullWidth>
      <InputLabel id="treatment-services-select-label">
        {t("treatmentServices:select.parentIdLabel")}
      </InputLabel>
      <Select
        labelId="treatment-services-select-label"
        label={t("treatmentServices:select.parentIdLabel")}
        value={parentId ? parentId.toString() : ""}
        onChange={onParentIdChange}
      >
        {parentSelectItems}
      </Select>
    </FormControl>
  );
}
