import { ApolloError } from "@apollo/client";
import { Link, Skeleton, Stack, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  PatientQualityIssue,
  QualityIssueDetailQuery,
  useQualityIssueDetailQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { PatientId, PatientQualityIssueId } from "Lib/Ids";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { RemoteData } from "seidr";
import ErrorMessage from "Shared/ErrorMessage";
import {
  PatientQualityIssueSuperStatus,
  qualityIssueSortString,
  statusToSuperStatus,
} from "./PatientQualityIssue";
import QualityIssueDetailCard from "./QualityIssueDetailCards";

export type QualityIssueDetailProps = {
  patientId: PatientId;
  activeSuperStatus: PatientQualityIssueSuperStatus | null;
};

function QualityIssueDetailHookWrapper(props: QualityIssueDetailProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useQualityIssueDetailQuery({
      variables: {
        id: props.patientId,
      },
    })
  );

  return QualityIssueDetail({ remoteData, activeSuperStatus: props.activeSuperStatus });
}

type QualityIssueDetailDataProps = {
  remoteData: RemoteData<ApolloError, QualityIssueDetailQuery>;
  activeSuperStatus: PatientQualityIssueSuperStatus | null;
};

function QualityIssueDetail(props: QualityIssueDetailDataProps): ReactElement {
  return props.remoteData.caseOf({
    Loading: () => <LoadingIndicator />,
    NotAsked: () => <LoadingIndicator />,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (result) => {
      if (!result.patient) {
        return <ErrorMessage message="Not Found" />;
      }
      return <QualityIssueDetailPane patient={result.patient} activeSuperStatus={props.activeSuperStatus} />;
    },
  });
}

const LoadingIndicator = () => {
  return (
    <Stack direction="column" spacing={1} data-testid="loading-indicator">
      <Skeleton height={50} width={300} />
      <Skeleton height={50} width={300} />
    </Stack>
  );
};

function QualityIssueDetailPane(props: {
  activeSuperStatus: PatientQualityIssueSuperStatus | null;
  patient: NonNullable<QualityIssueDetailQuery["patient"]>;
}): ReactElement {
  const { patient, activeSuperStatus } = props;
  if (props.patient.patientQualityIssues.length === 0) {
    return <Typography>All issues done</Typography>;
  }

  const { t } = useTranslation(["qualityIssues"]);

  // Start with all other issues filtered out. Expand if clicked
  const [showAll, setShowAll] = useState(false);

  // We also don't want cards that have been edited to just disappear either. So these need
  // to be exempt from the filter until refresh.
  const [editedIssues, setEditedIssues] = useState<ReadonlyArray<PatientQualityIssueId>>([]);

  // Although we want to sort the issues at the start, we don't want to automatically re-sort them
  // as we take actions. So, come up with an original sort order, store it by id, and then use it until we change context.
  const [originalSortMap] = useState<Record<string, string>>(() => {
    return patient.patientQualityIssues.reduce<Record<string, string>>((result, issue) => {
      result[issue.id.toString()] = qualityIssueSortString(issue);
      return result;
    }, {});
  });

  // Filter the issues by what is visible, then attempt to use the stable sort to order them.
  const visibleIssues = patient.patientQualityIssues
    .filter((issue) => {
      return (
        editedIssues.includes(issue.id) || // We've edited it this pass
        showAll || // We're showing all issues after a click
        activeSuperStatus === null || // We're not filtering at all
        statusToSuperStatus(issue.status) === activeSuperStatus // It matches
      );
    })
    .sort((a, b) =>
      (originalSortMap[a.id.toString()] || qualityIssueSortString(a)).localeCompare(
        originalSortMap[b.id.toString()] || qualityIssueSortString(b)
      )
    );
  let visibleIssueLink = null;

  if (visibleIssues.length !== patient.patientQualityIssues.length) {
    visibleIssueLink = (
      <Link href={"#"} onClick={() => setShowAll(!showAll)}>
        {t(showAll ? "qualityIssues:hideFiltered" : "qualityIssues:showAll")}
      </Link>
    );
  }

  const onEdit = (issue: Pick<PatientQualityIssue, "id">) => {
    setEditedIssues([...editedIssues, issue.id]);
  };

  return (
    <Stack direction="column" spacing={1}>
      {visibleIssues.map((issue) => (
        <QualityIssueDetailCard
          issue={issue}
          onEdit={onEdit}
          patientInfo={patient}
          key={issue.id.toString()}
        />
      ))}

      {visibleIssueLink}
    </Stack>
  );
}

export default QualityIssueDetailHookWrapper;
export { QualityIssueDetailHookWrapper as HookWrapper, QualityIssueDetail as Component };
