import { Card, CardContent, Stack, Typography } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Page from "Layout/Page";
import { Link } from "MDS/Link";
import React, { ReactElement } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { GridOn, Search } from "@mui/icons-material";
import CategoryIcon from "@mui/icons-material/Category";

export function OpsToolsList(): ReactElement {
  return (
    <Page browserTitle="Mirah Internal Ops Tools">
      <Card>
        <CardContent>
          <Stack direction="column" spacing={1}>
            <Typography variant="h1">Internal Ops Tools</Typography>
            <Link to="institutes">
              <Stack direction="row" spacing={1}>
                <AccountBalanceIcon />
                <Typography>Institute List</Typography>
              </Stack>
            </Link>
            <Link to="institute-groups">
              <Stack direction="row" spacing={1}>
                <CategoryIcon />
                <Typography>Institute Groups</Typography>
              </Stack>
            </Link>
            <Link to="/app/ops/institutes/reporting/integrations">
              <Stack direction="row" spacing={1}>
                <SsidChartIcon />
                <Typography>Integration Status</Typography>
              </Stack>
            </Link>
            <Link to="/internal/institutes">
              <Stack direction="row" spacing={1}>
                <FavoriteBorderIcon />
                <Typography>Mirah Internal</Typography>
              </Stack>
            </Link>
            <Link to="task-runner">
              <Stack direction="row" spacing={1}>
                <DirectionsRunIcon />
                <Typography>Task Runner</Typography>
              </Stack>
            </Link>
            <Link to="feature-matrix">
              <Stack direction="row" spacing={1}>
                <GridOn />
                <Typography>Feature Matrix</Typography>
              </Stack>
            </Link>
            <Link to="inspector">
              <Stack direction="row" spacing={1}>
                <Search />
                <Typography>Inspector</Typography>
              </Stack>
            </Link>
          </Stack>
        </CardContent>
      </Card>
    </Page>
  );
}
