import React, { ReactElement, ReactNode, useContext, useState } from "react";
import { Route } from "react-router-dom";
import AppLayout, { AppLayoutNavigationItem, AppLayoutUserMenuItem } from "Layout/AppLayout/AppLayout";
import { AppRouteProps } from "App";
import RouterWithNotFound from "Shared/RouterWithNotFound";
import { CareUnits } from "CareUnits";
import SettingsRoutes from "Settings/SettingsRoutes";
import { PatientRoutes } from "Patients";
import { FailedLoginsRoutes } from "FailedLogins/FailedLoginsRoutes";
import { DashboardRoutes } from "Providers";
import { DecisionSupportRoutes } from "./DecisionSupport/DecisionSupportRoutes";
import Login from "Login/Login";
import { InstituteRoutes } from "Institute/InstituteRoutes";
import CurrentInstituteContext, {
  featureIsEnabled,
  useConfigurationEnabled,
  useWithFeatureEnabled,
} from "Contexts/CurrentInstituteContext";
import { useTranslation } from "react-i18next";
import { Assignment, Inbox } from "@mui/icons-material";
import {
  ActiveDecisionsChip,
  useActiveProviderDecisionCount,
} from "./DecisionSupport/ActiveDecisionsIndicator";
import {
  AuthenticatedProviderUser,
  AuthenticatedProviderUserContext,
  providerUserHasAnyRole,
  providerUserHasPermission,
  useIsInternalUser,
} from "AppSession/AuthenticatedProviderUser";
import { ModifyTimeEntryFormProvider } from "Contexts/TimeTracking/ModifyTimeEntryFormContext";
import { CurrentTimeTrackingProvider } from "Contexts/TimeTracking/CurrentTimeTrackingContext";
import { CollaborativeCareAppBar } from "CollaborativeCare/CollaborativeCareAppBar";
import { useCollaborativeCareNavigationItems } from "CollaborativeCare/CollaborativeCareApp";
import { OutcomesRoutes } from "Outcomes";
import { TestPatientViewabilityToggleUserItemDefinition } from "CollaborativeCare/CareManagerDashboard/TestPatientViewabilityToggle";
import { MBC_REDESIGN_FLAG, useIsFrontendFlagEnabled } from "Contexts/FrontendFlagContext";
import { IntegrationRoutes } from "Integrations";
import { PiiLevelDialog, PiiLevelMenuItems } from "Settings/PiiLevel";
import { ImpersonatingUserMenuItem, ImpersonatingUserRoute } from "Settings/ImpersonatingUser";
import { ImplementationRoutes } from "Implementation";
import { SelectedInstituteGroupsProvider } from "Contexts/ActiveInstituteGroupContext";

type Dialogs = "pii" | null;

export function MeasurementBasedCareApp(props: AppRouteProps): ReactElement {
  const allowBulkExport = useConfigurationEnabled("allowBulkExport");
  const enableImplementationTargets = useWithFeatureEnabled("enableImplementationTargets");
  const [activeDialog, setActiveDialog] = useState<Dialogs>(null);

  const mbcNavigationItems = useMeasurementBasedCareNavigationItems({ user: props.user, setActiveDialog });
  const cocmNavigationItems = useCollaborativeCareNavigationItems(
    props.user,
    enableImplementationTargets,
    {
      allowBulkExport,
    },
    setActiveDialog
  );

  // For now, we want to keep the exact same set of navigation items
  // whenever we are in a CoCM institute.
  // We can deal with MBC inside a CoCM institute at a later date.
  // For example, we could simply smartly merge the items in a sane way.
  let navigationItems = mbcNavigationItems;
  if (useWithFeatureEnabled("enableCollaborativeCare")) {
    navigationItems = cocmNavigationItems;
  }

  return (
    <SelectedInstituteGroupsProvider>
      <AppLayout
        userBadge={navigationItems.userBadge}
        userMenuItems={navigationItems.userMenuItems}
        navItems={navigationItems.navItems}
        instituteNavItems={navigationItems.instituteNavItems}
        dialogs={
          <>
            <PiiLevelDialog open={activeDialog === "pii"} onClose={() => setActiveDialog(null)} />
          </>
        }
        {...props}
      >
        <CocmAppBar>
          <RouterWithNotFound>
            <Route element={<ImpersonatingUserRoute />} path="impersonation" />
            <Route element={<CareUnits />} path="care-units/*" />
            <Route element={<SettingsRoutes />} path="settings/*" />
            <Route element={<PatientRoutes />} path="patients/*" />
            <Route element={<FailedLoginsRoutes />} path="failed-logins/*" />
            <Route element={<DashboardRoutes />} path="providers/dashboard/*" />
            <Route element={<DecisionSupportRoutes />} path="providers/decision-support/*" />
            <Route element={<Login />} path="login/*" />
            <Route element={<InstituteRoutes />} path="institute/*" />
            <Route element={<OutcomesRoutes />} path="outcomes/*" />
            <Route element={<ImplementationRoutes />} path="implementation/*" />
            <Route element={<IntegrationRoutes />} path="integrations/*" />
          </RouterWithNotFound>
        </CocmAppBar>
      </AppLayout>
    </SelectedInstituteGroupsProvider>
  );
}

type UseMeasurementBasedCareNavigationItemsProps = {
  user: AuthenticatedProviderUser;
  setActiveDialog: (dialog: Dialogs) => void;
};
function useMeasurementBasedCareNavigationItems(props: UseMeasurementBasedCareNavigationItemsProps) {
  const { t } = useTranslation(["general"]);

  const mbcRedesignEnabled = useIsFrontendFlagEnabled(MBC_REDESIGN_FLAG);
  const userIsInternal = useIsInternalUser() || false;

  const allowBulkExport = useConfigurationEnabled("allowBulkExport");
  const enabledFeatures = {
    enableMeasurementPlans: useWithFeatureEnabled("enableMeasurementPlans"),
    enableBilling: useWithFeatureEnabled("enableBilling"),
    enableTreatmentServices: useWithFeatureEnabled("enableTreatmentServices"),
    enablePayors: useWithFeatureEnabled("enablePayors"),
    enableExperiments: useWithFeatureEnabled("enableExperiments"),
    enableDashboardSchedule: useWithFeatureEnabled("enableDashboardSchedule"),
    enableDecisionSupport: useWithFeatureEnabled("enableDecisionSupport"),
    enableQualityIssues: useWithFeatureEnabled("enableQualityIssues"),
  };

  const navigationItems = navItems({
    dashboardScheduleEnabled: enabledFeatures.enableDashboardSchedule,
    mbcRedesignEnabled,
    user: props.user,
    t: (k) => t(k),
  });

  const instituteNavItems = instituteMenuItems({
    configuration: { allowBulkExport: allowBulkExport },
    user: props.user,
    features: enabledFeatures,
    t: (k) => t(k),
  });

  const userMenuItems = userMenu({
    decisionSupportEnabled: enabledFeatures.enableDecisionSupport,
    mbcRedesignEnabled,
    userIsInternal,
    setActiveDialog: props.setActiveDialog,
    t: (k) => t(k),
  });

  const decisionCount: number = useActiveProviderDecisionCount();

  const userBadge = enabledFeatures.enableDecisionSupport ? { count: decisionCount } : undefined;

  return {
    navItems: navigationItems,
    instituteNavItems: instituteNavItems,
    userMenuItems: userMenuItems,
    userBadge: userBadge,
  };
}

const OUTCOMES_ENABLED_ROLES = ["superuser", "Sub Unit Supervisor", "Supervisor", "executive"];

function navItems({
  dashboardScheduleEnabled,
  mbcRedesignEnabled,
  user,
  t,
}: {
  dashboardScheduleEnabled: boolean;
  mbcRedesignEnabled: boolean;
  user: AuthenticatedProviderUser;
  t: (
    key:
      | "general:navigation.main.patients"
      | "general:navigation.main.analytics"
      | "general:navigation.main.dashboard"
      | "general:navigation.main.schedule"
      | "general:navigation.main.outcomes"
  ) => string;
}): { primaryNavigation: AppLayoutNavigationItem; additionalNavigation: Array<AppLayoutNavigationItem> } {
  const primaryNav = dashboardScheduleEnabled
    ? { location: "/app/providers/dashboard/schedule", label: t("general:navigation.main.schedule") }
    : { label: t("general:navigation.main.dashboard"), location: "/" };

  const additionNavigationFixedElements = [
    {
      label: t("general:navigation.main.patients"),
      location: mbcRedesignEnabled ? "/app/patients" : "/provider/patients",
    },
    { label: t("general:navigation.main.analytics"), location: "/provider/reports" },
  ];

  const outcomesElements = providerUserHasAnyRole(user, OUTCOMES_ENABLED_ROLES)
    ? [{ label: t("general:navigation.main.outcomes"), location: "/app/outcomes" }]
    : [];

  return {
    primaryNavigation: primaryNav,
    additionalNavigation: [...additionNavigationFixedElements, ...outcomesElements],
  };
}

function userMenu({
  t,
  decisionSupportEnabled,
  mbcRedesignEnabled,
  userIsInternal,
  setActiveDialog,
}: {
  decisionSupportEnabled: boolean;
  mbcRedesignEnabled: boolean;
  userIsInternal: boolean;
  setActiveDialog: (dialog: Dialogs) => void;
  t: (
    key:
      | "general:navigation.user.previewSchedule"
      | "general:navigation.user.previewMyTasks"
      | "general:navigation.user.inbox"
  ) => string;
}): Array<AppLayoutUserMenuItem> {
  const userMenuPreviewItems = [
    {
      label: t("general:navigation.user.previewSchedule"),
      location: "/app/providers/dashboard/schedule",
      icon: <Assignment />,
    },
    {
      label: t("general:navigation.user.previewMyTasks"),
      location: "/app/providers/dashboard/my-tasks",
      icon: <Assignment />,
    },
  ];

  const userMenuDecisionSupportItems = decisionSupportEnabled
    ? [
        {
          label: t("general:navigation.user.inbox"),
          location: "/app/providers/decision-support/inbox",
          icon: <Inbox />,
          additionalElement: <ActiveDecisionsChip size="small" />,
        },
      ]
    : [];

  // We explicitly want to call this every time, whether or not it's enabled, otherwise we get a hook
  // ordering error when you flip it.
  let testPatientItem = [TestPatientViewabilityToggleUserItemDefinition()];

  if (!mbcRedesignEnabled) {
    testPatientItem = [];
  }

  const impersonationItem = userIsInternal ? [ImpersonatingUserMenuItem()] : [];

  const piiLevelItem = userIsInternal ? PiiLevelMenuItems(() => setActiveDialog("pii")) : [];

  return [
    ...userMenuDecisionSupportItems,
    ...userMenuPreviewItems,
    ...testPatientItem,
    ...impersonationItem,
    ...piiLevelItem,
  ];
}

function instituteMenuItems({
  t,
  configuration,
  user,
  features,
}: {
  configuration: { allowBulkExport: boolean };
  user: AuthenticatedProviderUser;
  features: {
    enableMeasurementPlans: boolean;
    enableBilling: boolean;
    enableTreatmentServices: boolean;
    enablePayors: boolean;
    enableExperiments: boolean;
    enableQualityIssues: boolean;
    enableDecisionSupport: boolean;
  };
  t: (
    key:
      | "general:navigation.institute.qualityIssues"
      | "general:navigation.institute.experiments"
      | "general:navigation.institute.failedLogins"
      | "general:navigation.institute.manageUsers"
      | "general:navigation.institute.measureLibrary"
      | "general:navigation.institute.measurementPlans"
      | "general:navigation.institute.payerSetup"
      | "general:navigation.institute.reimbursement"
      | "general:navigation.institute.treatmentServices"
      | "general:navigation.institute.treatmentTracks"
      | "general:navigation.institute.uploadClients"
      | "general:navigation.institute.bulkDataExport"
      | "general:navigation.institute.configurationManager"
      | "general:navigation.institute.decisionSupportConfiguration"
      | "general:navigation.institute.organizations"
  ) => string;
}): Array<AppLayoutNavigationItem> {
  const instMenu: Array<AppLayoutNavigationItem> = [];

  if (configuration.allowBulkExport && providerUserHasPermission(user, "bulkExport")) {
    instMenu.push({
      label: t("general:navigation.institute.bulkDataExport"),
      location: "/app/settings/provider/admin/exports",
    });
  }

  if (providerUserHasPermission(user, "editConfigurationValues")) {
    instMenu.push({
      label: t("general:navigation.institute.configurationManager"),
      location: "/provider/admin/configuration",
    });
  }

  if (features.enableQualityIssues && providerUserHasPermission(user, "viewQualityIssues")) {
    instMenu.push({
      label: t("general:navigation.institute.qualityIssues"),
      location: "/app/patients/quality",
    });
  }

  if (features.enableDecisionSupport) {
    instMenu.push({
      label: t("general:navigation.institute.decisionSupportConfiguration"),
      location: "/app/providers/decision-support/algorithms",
    });
  }

  if (features.enableExperiments && providerUserHasPermission(user, "editExperiments")) {
    instMenu.push({
      label: t("general:navigation.institute.experiments"),
      location: "/provider/experiments",
    });
  }

  //TODO: does this need more permissions?
  instMenu.push({
    label: t("general:navigation.institute.failedLogins"),
    location: "/app/failed-logins/",
  });

  if (providerUserHasPermission(user, "editProviders")) {
    instMenu.push({
      label: t("general:navigation.institute.manageUsers"),
      location: "/provider/admin/users",
    });
  }

  instMenu.push({
    label: t("general:navigation.institute.measureLibrary"),
    location: "/provider/help",
  });

  //TODO: does this need more permissions?
  if (features.enableMeasurementPlans) {
    instMenu.push({
      label: t("general:navigation.institute.measurementPlans"),
      location: "/provider/plan/entities",
    });
  }

  if (features.enablePayors && providerUserHasPermission(user, "editInstitutePayors")) {
    instMenu.push({
      label: t("general:navigation.institute.payerSetup"),
      location: "/provider/admin/payors",
    });
  }

  if (features.enableBilling && providerUserHasPermission(user, "viewPatientBilling")) {
    instMenu.push({
      label: t("general:navigation.institute.reimbursement"),
      location: "/provider/billing/charges",
    });
  }

  if (features.enableTreatmentServices && providerUserHasPermission(user, "editTreatmentServices")) {
    instMenu.push({
      label: t("general:navigation.institute.treatmentServices"),
      location: "/app/institute/treatment-services",
    });
  }
  // TODO: Create ticket, this likely wants more permissions and feature gates
  //       <MenuItem to="/app/settings/provider/admin/configuration/treatmenttrack/" component={Link}>
  //         {t("general:navigation.institute.treatmentTracks")}
  //       </MenuItem>
  instMenu.push({
    label: t("general:navigation.institute.treatmentTracks"),
    location: "/app/settings/provider/admin/configuration/treatmenttrack/",
  });

  if (providerUserHasPermission(user, "bulkUpload")) {
    instMenu.push({
      label: t("general:navigation.institute.uploadClients"),
      location: "/provider/admin/upload/patients",
    });
  }

  if (providerUserHasPermission(user, "viewOrganizations")) {
    instMenu.push({
      label: t("general:navigation.institute.organizations"),
      location: "/app/institute/organizations",
    });
  }

  return instMenu;
}

// This just loads in the app bar from the cocm if the current
// user actually has the correct feature and permissions.
// This lets the app bar appear on non-cocm specific content, like
// the feedback report.
function CocmAppBar(props: { children: ReactNode }): ReactElement {
  // For logical simplicity we'll directly check features & permissions instead of nesting children.
  // The nested approach looks gross and is hard to reason about.
  const enabledCocm = featureIsEnabled(useContext(CurrentInstituteContext), "enableCollaborativeCare");
  const user = React.useContext(AuthenticatedProviderUserContext);
  const viewCocm = user !== undefined && providerUserHasPermission(user, "viewCollaborativeCare");
  const content =
    enabledCocm && viewCocm ? (
      <ModifyTimeEntryFormProvider>
        <CurrentTimeTrackingProvider>
          {props.children}
          <CollaborativeCareAppBar />
        </CurrentTimeTrackingProvider>
      </ModifyTimeEntryFormProvider>
    ) : (
      <>{props.children}</>
    );

  return content;
}
