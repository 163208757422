import React, { ReactElement, ReactNode } from "react";

import { Paper, Link } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { CareEpisodeMeasurementAllowedDetailsFragment } from "GeneratedGraphQL/SchemaAndOperations";
import { ArrayElement, Dig } from "type-utils";
import CheckIcon from "@mui/icons-material/Check";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import UnderMeasurementChip from "../UnderMeasurementChip";
import { GoodTextTypography, BadTextTypography } from "MDS/MDSTypography";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function DecisionRow({
  children,
  measurementAllowed,
}: {
  children: ReactNode;
  measurementAllowed: boolean;
}): ReactElement {
  return (
    <TableRow>
      <TableCell component="th" scope="row" align={"left"}>
        {children}
      </TableCell>
      <DecisionIconCell measurementAllowed={measurementAllowed} />
    </TableRow>
  );
}

function ProviderRow({
  provider,
}: {
  provider: ArrayElement<Dig<CareEpisodeMeasurementAllowedDetailsFragment, ["providers"]>>;
}): ReactElement {
  return (
    <TableRow>
      <TableCell component="th" scope="row" align={"right"}>
        <Link component={RouterLink} to={`../../providers/${provider.id}`}>
          {provider.name}
        </Link>
      </TableCell>
      <DecisionIconCell measurementAllowed={provider.measurementAllowed} />
    </TableRow>
  );
}

function DecisionIconCell({ measurementAllowed }: { measurementAllowed: boolean }): ReactElement {
  return (
    <TableCell align="right">
      {measurementAllowed ? (
        <GoodTextTypography component="span">
          <CheckIcon />
        </GoodTextTypography>
      ) : (
        <BadTextTypography component="span">
          <DoNotDisturbIcon />
        </BadTextTypography>
      )}
    </TableCell>
  );
}

function CareEpisodeDecisionTreeToolTip({
  careEpisode,
  children,
}: {
  careEpisode: CareEpisodeMeasurementAllowedDetailsFragment;
  children: ReactElement;
}): ReactElement {
  const { t } = useTranslation(["common", "settings"]);
  const tooltip = (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small" sx={{ maxWidth: 350 }}>
          <TableBody>
            <TableRow key="description">
              <TableCell colSpan={2}>{t("settings:measurementAllowedBadge.careEpisodeTitle")}</TableCell>
            </TableRow>
            <DecisionRow measurementAllowed={careEpisode.institute.measurementAllowed}>
              {t("entities.institute")} - {careEpisode.institute.name}
            </DecisionRow>
            <DecisionRow measurementAllowed={careEpisode.organization.measurementAllowed}>
              {t("entities.organization")} - {careEpisode.organization.name}
            </DecisionRow>
            <DecisionRow measurementAllowed={careEpisode.measurementAllowed}>
              {t("entities.careEpisode", { count: 1 })}
            </DecisionRow>
            <TableRow>
              <TableCell component="th" scope="row" align={"left"} colSpan={2}>
                {t("settings:measurementAllowedBadge.careEpisodeProviderDescription")}
              </TableCell>
            </TableRow>
            {careEpisode.providers.map((provider) => (
              <ProviderRow key={provider.id.toString()} provider={provider} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
  return (
    <Tooltip title={tooltip} placement="bottom">
      {children}
    </Tooltip>
  );
}

function measurementAllowed({
  institute,
  measurementAllowed,
  organization,
  providers,
}: CareEpisodeMeasurementAllowedDetailsFragment): boolean {
  return (
    institute.measurementAllowed &&
    measurementAllowed &&
    organization.measurementAllowed &&
    providers.reduce<boolean>((acc, provider) => acc || provider.measurementAllowed, false)
  );
}

function CareEpisodeDecisionBadge({
  careEpisode,
}: {
  careEpisode: CareEpisodeMeasurementAllowedDetailsFragment;
}): ReactElement {
  return (
    <UnderMeasurementChip
      measurementAllowed={measurementAllowed(careEpisode)}
      chipTooltip={(child) => (
        <CareEpisodeDecisionTreeToolTip careEpisode={careEpisode}>{child}</CareEpisodeDecisionTreeToolTip>
      )}
    />
  );
}

export default CareEpisodeDecisionBadge;
