const general = {
  navigation: {
    main: {
      patients: '$t(common:patient, {"count": 100 })',
      dashboard: "dashboard",
      schedule: "schedule",
      analytics: "analytics",
      collaborativeCare: {
        dashboard: "Dashboard",
        patients: "Patient Search",
        tasks: "All Tasks",
        caseConsult: "Case Consult",
      },
      manageUsers: "Manage Users",
      editConfiguration: "Configuration Manager",
      outcomes: "Outcomes",
    },
    institute: {
      uploadClients: "Upload Clients",
      bulkDataExport: "Bulk Data Export",
      manageUsers: "Manage Users",
      measurementPlans: "Measurement Plans",
      configurationManager: "Configuration Manager",
      experiments: "Experiments",
      payerSetup: "Payer Setup",
      reimbursement: "Reimbursement",
      treatmentServices: "Treatment Services",
      treatmentTracks: "Treatment Tracks",
      failedLogins: "Failed Logins",
      qualityIssues: "Data Quality Issues",
      collaborativeCare: {
        taskTemplates: "Quick Tasks",
        monthlyBillingReport: "Monthly Billing Report",
        quickText: "Case Consult Smart Phrases",
        minutesTargets: "Minutes Targets",
      },
      decisionSupportConfiguration: "Decision Support Configuration",
      measureLibrary: "Measure Library",
      organizations: "Organizations",
      institutePerformance: "Institute Performance",
      implementation: "Implementation Targets",
      cocmBilling: "Billing Insights",
    },
    user: {
      previewSchedule: "Preview Schedule",
      previewMyTasks: "Preview My Tasks",
      profile: "Profile",
      myTasks: "My Tasks",
      logout: "Logout",
      lastSignIn: "Last sign in:",
      inbox: "Inbox",
      myPerformance: "My Performance",
    },
  },
  notFound: {
    title: "The page you are looking for cannot be found",
    subtitle: "The page may not exist or has been moved",
    goHome: "Back to home",
  },
  csv: {
    export: "Export CSV",
    noData: "No data matching these filters",
  },
};

export default general;
