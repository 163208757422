import { apolloMutationHookWrapper } from "Api/GraphQL";
import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";
import {
  AllTreatmentTargetsQuery,
  Patient,
  ShortTaskDescription,
  useEnrollPatientMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { PanelId, PanelTeamMemberId } from "Lib/Ids";
import { useForm, useListField, useWrappedField } from "Shared/Form";
import React, { ReactElement } from "react";
import { DigUnpacked, PickTypename } from "type-utils";
import { EnrollmentTargetsAndTasks } from "./EnrollmentTargetsAndTasks";
import { EnrollmentSuccess } from "./EnrollmentSuccess";
import { Typography, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { refetchQueries } from "Lib/RefetchQueries";
import { assertNonNull } from "Lib/Utils";
import { EnrollmentPanelConsent } from "./EnrollmentPanelConsent";

type EnrollmentScreen = "consent" | "targets-and-tasks" | "enrollment-success";

type EnrollmentFlowProps = {
  patient: PickTypename<Patient, "id" | "name" | "dob">;
  onStartIntake: () => void;
  onClose: () => void;
};

export function EnrollmentFlow(props: EnrollmentFlowProps): ReactElement | null {
  const [screen, setScreen] = React.useState<EnrollmentScreen>("consent");
  const { t } = useTranslation(["collaborativeCare"]);
  const notACareManagerError = (
    <>
      <DialogContent>
        <Typography>{t("collaborativeCare:enrollment.careManagerRequired")}</Typography>
      </DialogContent>
    </>
  );
  const currentProvider = React.useContext(AuthenticatedProviderUserContext);
  if (!currentProvider) {
    return notACareManagerError;
  }

  const currentProviderId = currentProvider.providerId.getOrElse(null);
  if (!currentProviderId) {
    return notACareManagerError;
  }

  const [enrollPatient, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareEnrollPatient,
    useEnrollPatientMutation({
      refetchQueries: refetchQueries("enroll"),
    })
  );

  // Note that we aren't even trying to match errors to individual fields here. This form is particularly poorly
  // suited to that, since there are so many entities involved and the form controls themselves are split over two
  // screens, which would potentially hide some errors.
  const fields = {
    targets: useListField<
      DigUnpacked<AllTreatmentTargetsQuery, ["collaborativeCareTreatmentTargets", "nodes"]>
    >({ required: true, default: [] }),
    tasks: useListField<ShortTaskDescription>({ required: true, default: [] }),
    enrollmentDate: useWrappedField({ default: new Date(), required: true }),
    consentDate: useWrappedField({ default: new Date(), required: true }),
    pcpTeamId: useWrappedField<PanelTeamMemberId>({ required: true }),
    cmTeamId: useWrappedField<PanelTeamMemberId>({ required: true }),
    panelId: useWrappedField<PanelId>({ required: true }),
  };

  const careTeamInput = {
    panelTeamIds: {
      pcpTeamMemberId: assertNonNull(fields.pcpTeamId.value),
      // This field is required so the cast is safe
      cmTeamMemberId: assertNonNull(fields.cmTeamId.value),
    },
  };

  const form = useForm({
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      enrollPatient({
        variables: {
          input: {
            patientId: props.patient.id,
            careTeamIds: careTeamInput,
            treatmentTargetIds: fields.targets.value.map((target) => target.id),
            taskDescriptions: fields.tasks.value,
            startEnrollmentTimeTracking: true,
            enrollmentDate: fields.enrollmentDate.value || new Date(),
            consentDate: assertNonNull(fields.consentDate.value),
            panelId: assertNonNull(fields.panelId.value),
          },
        },
      });
    },
    // Automatically transition to enrollment successful on the form saving.
    onSuccess: () => {
      setTimeout(() => {
        setScreen("enrollment-success");
      }, 300);
    },
  });

  const savedTasks = remoteData.caseOf({
    Success: (response) => {
      return response?.tasks || [];
    },
    _: () => [],
  });

  switch (screen) {
    case "consent":
      return (
        <EnrollmentPanelConsent
          enrollmentDate={fields.enrollmentDate.value}
          setEnrollmentDate={fields.enrollmentDate.onChange}
          consentDate={fields.consentDate.value}
          setConsentDate={fields.consentDate.onChange}
          patient={props.patient}
          pcp={fields.pcpTeamId}
          cm={fields.cmTeamId}
          panel={fields.panelId}
          onConfirm={() => setScreen("targets-and-tasks")}
        />
      );
    case "targets-and-tasks":
      return (
        <EnrollmentTargetsAndTasks
          patient={props.patient}
          targets={fields.targets.value}
          tasks={fields.tasks.value}
          remoteData={remoteData}
          errorMessage={form.globalError}
          setTargets={fields.targets.onChange}
          setTasks={fields.tasks.onChange}
          onBack={() => setScreen("consent")}
          onComplete={form.onFakeSubmit}
        />
      );
    case "enrollment-success":
      return (
        <EnrollmentSuccess
          patient={props.patient}
          tasks={savedTasks}
          onContinue={() => props.onStartIntake()}
          onFinish={props.onClose}
        />
      );
  }
}
