import { Close } from "@mui/icons-material";
import { Dialog, DialogProps, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { useIsMobile } from "Shared/Responsive";
import React, { MouseEventHandler, ReactElement } from "react";

// This is a little funky, but we are basically going to have a desktop modal-like experience,
// and a mobile full screen experience.
// These two SX constants define the default behavior, which is customized directly via props.
const DESKTOP_PAPER_PROPS_SX = {
  position: "absolute",
  right: 0,
  top: 0,
  bottom: 0,
  maxWidth: "50%",
  minWidth: "25%",
};

const MOBILE_PAPER_PROPS_SX = {};

type ResponsiveDialogProps = Omit<DialogProps, "scroll" | "fullScreen"> & {
  dialogWidth?: string; // Desktop only.
  dialogMaxWidth?: string; // Desktop only. Some dialogs require more than setting the dialogWidth.
  dialogPadding?: string; // Desktop only. This controls the outer dialog padding.
  dialogBackgroundColor?: string; // Desktop only. This controls the dialog background, default white.
  hideClose?: boolean;
  stopBackdropClose?: boolean;
  hideTitle?: boolean;
  subtitle?: string;
};

export function ResponsiveDialog(props: ResponsiveDialogProps): ReactElement {
  // A note on title: this is actually part of the DialogProps. If it gets
  // set on the modal, one effect it has it will make a tooltip pop up with the title
  // across the entire modal. We don't want that.
  const {
    dialogWidth,
    dialogMaxWidth,
    dialogPadding,
    dialogBackgroundColor,
    title,
    stopBackdropClose,
    hideTitle,
    ...dialogProps
  } = props;

  const fullScreen = useIsMobile();

  const onButtonClose: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (props.onClose) {
      // Mui requires a "reason" argument here. backdropClick is a lie but it's closer than the other allowed options.
      props.onClose(event, "backdropClick");
    }
  };

  // We're going to do a thing where we modify paperPropsSx depending on options are being passed,
  // and then apply this wholesale to the component.
  // All of the sub-modifications only apply to desktop.
  let paperPropsSx = fullScreen ? MOBILE_PAPER_PROPS_SX : DESKTOP_PAPER_PROPS_SX;

  // This controls the actual full size of the dialog.
  if (dialogMaxWidth && !fullScreen) {
    paperPropsSx = { ...paperPropsSx, maxWidth: dialogMaxWidth };
  }

  // This needs to be set of actually get the dialog to grow correctly for reasons that I am unsure about.
  if (dialogWidth && !fullScreen) {
    paperPropsSx = { ...paperPropsSx, width: dialogWidth };
  }

  // This controls the dialog background color. If we're putting white cards over it,
  // we want it to be a nice grey probably, but by default it's white.
  if (dialogBackgroundColor && !fullScreen) {
    paperPropsSx = { ...paperPropsSx, backgroundColor: dialogBackgroundColor };
  }

  const closeButton = props.hideClose ? null : (
    <IconButton onClick={onButtonClose}>
      <Close />
    </IconButton>
  );

  const onDialogClose: DialogProps["onClose"] = (event, reason) => {
    if (props.onClose) {
      // We intentionally want to block closing the dialog when you click the backdrop. There doesn't seem to be a
      // builtin prop to do that so we're hooking into the close handler here. You can still close the dialog with ESC
      // or the button we add to the title.
      // We do have an override that allows backdrop closes for specific dialogs where it makes sense.
      if (reason !== "backdropClick" || !stopBackdropClose) {
        props.onClose(event, reason);
      }
    }
  };

  const subtitle = props.subtitle ? (
    <Typography variant="subtitle2" component="span">
      {props.subtitle}
    </Typography>
  ) : (
    <></>
  );

  const titleElement = hideTitle ? null : (
    <DialogTitle>
      <Stack direction="row" alignItems="center">
        <Typography sx={{ flexGrow: 1 }}>{title}</Typography>
        {closeButton}
      </Stack>
      {subtitle}
    </DialogTitle>
  );

  // Setting this on paper props doesn't seem to do the trick, this is the only
  // method I found that let us customize the padding correctly.
  let dialogSx = {};
  if (dialogPadding && !fullScreen) {
    dialogSx = {
      ".MuiDialogContent-root": {
        padding: dialogPadding,
      },
    };
  }

  return (
    <Dialog
      {...dialogProps}
      fullScreen={fullScreen}
      scroll="paper"
      PaperProps={{ sx: paperPropsSx }}
      onClose={onDialogClose}
      sx={dialogSx}
    >
      {titleElement}
      {props.children}
    </Dialog>
  );
}
