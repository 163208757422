const care = {
  scheduleSummary: {
    initialBoth:
      "starting at the later of measurement {{onsetSession}} and {{onsetTime}} after {measureOnsetText}",
    initialTime: "starting {{onsetTime}} after {measureOnsetText}",
    initialSession: "starting at measurement {{onsetSession}}",
    onceOnly: "Once only",
    repeatBoth:
      "repeating the longer of every {{repeatSessions}} measurements or every {{maxRepeatInterval}}",
    repeatSession: "repeating every {{repeatSessions}} measurements",
    everySession: "Every measurement",
    repeatTime: "repeating every {{maxRepeatInterval}}",
    endingBoth:
      "ending at the later of measurement {{discontinueSession}} and {{discontinueTime}} after care episode start",
    endingTime: "ending {{discontinueTime}} after care episode start",
    endingSession: "ending at measurement {{discontinueSession}}",
  },
  duration: {
    unknown: "Unknown Duration",
    oneWeek: "1 week",
    weeks: "{{week}} weeks",
  },
  entityMeasurementPlans: {
    columns: {
      name: "Name",
      appliesTo: "Applies To",
    },
    noMatching: "There are no matching measurement plans",
  },
  filters: {
    maxAge: "up to {{maxAge}} years old",
    minAge: "{{minAge}}+ years old",
    age: "{{minAge}} - {{maxAge}} years old",
  },
  measureOnsetFrom: {
    careEpisodePeriodStart: "care episode start",
    nextCalendarQuarter: "start of quarter after care episode start",
  },
};
export default care;
