import { DialogContent } from "@mui/material";
import { ModifyTimerForm } from "CollaborativeCare/TimeEntry/ModifyTimerForm";
import { Patient, Task, TimeEntryLog } from "GeneratedGraphQL/SchemaAndOperations";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { ReactElement, ReactNode, useContext } from "react";
import { PickTypename } from "type-utils";

export type ModifyTimeEntryFormProps = {
  task: PickTypename<Task, "id" | "title" | "isPlaceholder">;
  timeEntryLog: PickTypename<TimeEntryLog, "id" | "clientStartTime" | "durationSeconds">;
  patient: PickTypename<Patient, "id" | "name"> | null;
};
import { useTranslation } from "react-i18next";
import { useCurrentTask } from "Contexts/CurrentTaskContext";

type ModifyTimeEntryForm =
  | {
      showForm: true;
      task: PickTypename<Task, "id" | "title" | "isPlaceholder">;
      timeEntryLog: PickTypename<TimeEntryLog, "id" | "clientStartTime" | "durationSeconds">;
      patient: PickTypename<Patient, "id" | "name"> | null;
    }
  | {
      showForm: false;
    };

type ModifyTimeEntryFormContextType = {
  showModifyForm: ModifyTimeEntryForm;
  useAdjustFinishedTimerForm: (props: ModifyTimeEntryFormProps) => void;
};
const initModifyForm: ModifyTimeEntryForm = {
  showForm: false,
};
const initModifyFormContextState = {
  showModifyForm: initModifyForm,
  useAdjustFinishedTimerForm: () => {
    return;
  },
};

const ModifyTimeEntryFormContext =
  React.createContext<ModifyTimeEntryFormContextType>(initModifyFormContextState);

export function ModifyTimeEntryFormProvider({ children }: { children: ReactNode }): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const hideForm: ModifyTimeEntryForm = {
    showForm: false,
  };
  const [showModifyForm, setShowModifyForm] = React.useState<ModifyTimeEntryForm>(hideForm);

  const useAdjustFinishedTimerForm = (props: ModifyTimeEntryFormProps) => {
    setShowModifyForm({
      showForm: true,
      task: props.task,
      timeEntryLog: props.timeEntryLog,
      patient: props.patient,
    });
    return;
  };
  const maybeTask = useCurrentTask();

  const title = maybeTask?.title || (showModifyForm.showForm ? showModifyForm.task.title : "");
  let patientName = "";
  if (showModifyForm.showForm && showModifyForm.patient) {
    patientName = " (" + showModifyForm.patient.name + ")";
  }
  const modifyForm = showModifyForm.showForm ? (
    // Pausing for a couple hundred millis here so that users have a chance to see the checkmark before the
    // form goes away. If you hit end timer, then timeTrackingContext won't have the values for Task id or
    // TimeEntryLog anymore.
    <ModifyTimerForm
      taskId={maybeTask?.id || showModifyForm.task.id}
      patientId={showModifyForm.patient?.id}
      timeEntryLog={showModifyForm.timeEntryLog}
      isPlaceholder={maybeTask?.isPlaceholder || showModifyForm.task.isPlaceholder}
      onSuccess={() => setTimeout(() => setShowModifyForm(hideForm), 300)}
    />
  ) : (
    <> </>
  );

  return (
    <>
      <ModifyTimeEntryFormContext.Provider value={{ showModifyForm, useAdjustFinishedTimerForm }}>
        {children}
      </ModifyTimeEntryFormContext.Provider>
      <ResponsiveDialog
        open={showModifyForm.showForm}
        onClose={() => {
          setShowModifyForm(hideForm);
        }}
        title={t("collaborativeCare:tasks.timeEntryLog.modifyTimeHeader") + ": " + title + patientName}
        dialogWidth="50%"
        hideClose={true}
      >
        <DialogContent>{modifyForm}</DialogContent>
      </ResponsiveDialog>
    </>
  );
}

export const useModifyTimeEntryForm = () => useContext(ModifyTimeEntryFormContext);
