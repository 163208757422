import { ButtonProps, DialogContent } from "@mui/material";
import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";
import { Patient, Task, TimeEntryLog } from "GeneratedGraphQL/SchemaAndOperations";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ModifyTimerForm } from "./ModifyTimerForm";
import { PickTypename } from "type-utils";
import { Edit } from "@mui/icons-material";
import { ExpandingIconButton } from "MDS/ExpandingIconButton";
import { useCurrentTask } from "Contexts/CurrentTaskContext";
import { omit } from "ramda";

type TimeEntryEditIconButtonProps = ButtonProps & { task: Pick<Task, "id" | "title" | "isPlaceholder"> } & {
  timeEntryLog: PickTypename<TimeEntryLog, "id" | "clientStartTime" | "durationSeconds">;
  patient: PickTypename<Patient, "id"> | null;
};

export function TimeEntryEditIconButton(props: TimeEntryEditIconButtonProps): ReactElement | null {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const currentProvider = React.useContext(AuthenticatedProviderUserContext);
  if (!currentProvider) {
    return null;
  }

  const currentProviderId = currentProvider.providerId.getOrElse(null);
  if (!currentProviderId) {
    return null;
  }

  const [showForm, setShowForm] = React.useState(false);
  const maybeTask = useCurrentTask();
  const displayedTask = maybeTask || props.task;

  const buttonProps = omit(["timeEntryLog"], props);
  return (
    <>
      <ExpandingIconButton
        variant="contained"
        color="secondary"
        icon={<Edit />}
        onClick={() => setShowForm(true)}
        {...buttonProps}
      >
        {t("common:actions.edit")}
      </ExpandingIconButton>
      <ResponsiveDialog
        open={showForm}
        onClose={() => setShowForm(false)}
        title={t("collaborativeCare:tasks.timeEntryLog.modifyTimeHeader") + ": " + props.task.title}
        dialogWidth="50%"
      >
        <DialogContent>
          <ModifyTimerForm
            taskId={displayedTask.id}
            isPlaceholder={displayedTask.isPlaceholder}
            patientId={props.patient?.id}
            timeEntryLog={props.timeEntryLog}
            onSuccess={() => setTimeout(() => setShowForm(false), 300)}
          />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}
