import {
  Button,
  ButtonProps,
  Checkbox,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";
import { useEnrollmentState } from "CollaborativeCare/PatientDetails/EnrollmentState";
import PlaceholderOptions from "CollaborativeCare/Tasks/PlaceholderOptions";
import { taskStatusT } from "GeneratedGraphQL/EnumTranslations";
import { Task, TaskStatus, useCreateTimeEntryLogMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { PatientId, ProviderId, TaskId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import {
  Form,
  FormOverlay,
  useBooleanField,
  useForm,
  useNumberField,
  useTextField,
  useWrappedField,
} from "Shared/Form";
import { startOfMinute } from "date-fns";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

type CreateTimeEntryFormProps = {
  taskId: TaskId;
  providerId: ProviderId;
  patientId?: PatientId | undefined;
  isPlaceholder?: boolean;
  onSuccess?: () => void;
};

export function CreateTimeEntryLogForm(props: CreateTimeEntryFormProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common", "enums"]);

  const [createTimeEntry, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareCreateTimeEntryLog,
    useCreateTimeEntryLogMutation({
      refetchQueries: refetchQueries("timeEntries"),
    })
  );

  const [showForm, setShowForm] = useState<boolean>(true);

  // i had to strip off the seconds in order to get
  // the test input on a date to match what DatePicker actually
  // returns for a mock mutation. we shouldn't actually have to do this
  const stripSeconds = (date: Date) => {
    return startOfMinute(date);
  };

  const fields = {
    clientStartTime: useWrappedField<Date>({ required: true }),
    durationMinutes: useNumberField({ required: false, default: 1 }),
    completeTask: useBooleanField({ required: false, default: false }),
    noteText: useTextField({ required: false }),
  };

  let outsideEnrollmentNotif = <></>;
  if (props.patientId) {
    const enrollment = useEnrollmentState(props.patientId);
    if (
      enrollment.status == "enrolled" &&
      stripSeconds(fields.clientStartTime.value as Date) < enrollment.consentDate
    ) {
      outsideEnrollmentNotif = (
        <Typography color={"red"}>
          {t("collaborativeCare:tasks.beforeConsentWarning", { date: enrollment.consentDate })}
        </Typography>
      );
    }
  }

  // fields.durationSeconds.value as number | undefined
  let seconds: number | undefined = undefined;
  if (fields.durationMinutes.value !== undefined) {
    if (typeof fields.durationMinutes.value === "string") {
      seconds = Number.parseInt(fields.durationMinutes.value) * 60;
    } else if (typeof fields.durationMinutes.value === "number") {
      seconds = fields.durationMinutes.value * 60;
    }
  }

  const form = useForm({
    fields: fields,
    submit: () => {
      createTimeEntry({
        variables: {
          input: {
            clientStartTime: stripSeconds(fields.clientStartTime.value as Date),
            durationSeconds: seconds as number,
            noteText: fields.noteText.value as string,
            completeTask: fields.completeTask.value,
            // these two inputs are not mutable in the form
            // and are required as arguments to be able to execute this mutation
            providerId: props.providerId,
            taskId: props.taskId,
          },
        },
      });
    },
    remoteData: remoteData,
    onSuccess: props.onSuccess,
  });

  let placeholderOptionsContent = <></>;
  if (props.isPlaceholder && props.patientId) {
    placeholderOptionsContent = (
      <PlaceholderOptions
        patientId={props.patientId}
        taskId={props.taskId}
        setShowSurroundingForms={setShowForm}
      />
    );
  }

  const startTimeHelperText =
    fields.clientStartTime.helperText ||
    (fields.clientStartTime.value === undefined ? t("collaborativeCare:fields.manualStartTime.missing") : "");

  let formContent = <></>;
  if (showForm) {
    formContent = (
      <>
        <Box marginBottom={"0.5em"} fontSize={"small"}>
          {outsideEnrollmentNotif}
        </Box>
        <DateTimePicker
          label={t("collaborativeCare:fields.manualStartTime.label")}
          format={t("collaborativeCare:tasks.timeEntryLog.dateFormat")}
          value={fields.clientStartTime.value}
          onChange={fields.clientStartTime.onChange}
          slotProps={{
            textField: {
              error: fields.clientStartTime.error,
              helperText: startTimeHelperText,
            },
          }}
        />
        <TextField
          title={t("collaborativeCare:fields.duration.label")}
          label={t("collaborativeCare:fields.duration.label")}
          autoFocus
          value={fields.durationMinutes.value}
          onChange={fields.durationMinutes.onChange}
          error={fields.durationMinutes.error}
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
        />
        <TextField
          multiline
          minRows={5}
          title={t("collaborativeCare:tasks.timeEntryLog.noteText.label")}
          label={t("collaborativeCare:tasks.timeEntryLog.noteText.label")}
          autoFocus
          value={fields.noteText.value}
          onChange={fields.noteText.onChange}
          error={fields.noteText.error}
        />
        <FormControl>
          <FormControlLabel
            control={<Checkbox />}
            label={t("collaborativeCare:tasks.timeEntryLog.TaskCompleted")}
            checked={fields.completeTask.value}
            onChange={fields.completeTask.onChange}
          />
          <FormHelperText>
            {t("collaborativeCare:tasks.timeEntryLog.MarkTaskCompleteHelp", {
              status: taskStatusT(TaskStatus.COMPLETE, t),
            })}
          </FormHelperText>
        </FormControl>
        <Stack direction="row-reverse" spacing={1}>
          <ButtonWithSpinner
            variant="contained"
            color="secondary"
            type="submit"
            showSpinner={form.showSpinner}
            disabled={form.disableSubmit}
          >
            {t("common:actions.save")}
          </ButtonWithSpinner>
        </Stack>
      </>
    );
  }

  return (
    <>
      {placeholderOptionsContent}
      <Form onSubmit={form.onSubmit}>
        <FormOverlay response={remoteData} errorMessage={t("collaborativeCare:tasks.genericFormError")} />
        <Stack direction="column" spacing={1}>
          {formContent}
        </Stack>
      </Form>
    </>
  );
}

type TimeEntryLogButtonProps = ButtonProps & {
  task: Pick<Task, "id" | "title" | "isPlaceholder">;
  patientId?: PatientId;
  buttonLabel: string;
  isDisabled?: boolean;
};

export function TimeEntryLogButton(props: TimeEntryLogButtonProps): ReactElement | null {
  const { t } = useTranslation(["collaborativeCare"]);

  const currentProvider = React.useContext(AuthenticatedProviderUserContext);
  if (!currentProvider) {
    return null;
  }

  const currentProviderId = currentProvider.providerId.getOrElse(null);
  if (!currentProviderId) {
    return null;
  }

  const [showForm, setShowForm] = React.useState(false);
  const variant = props.variant || "outlined";
  const color = props.color || "secondary";
  const button = props.isDisabled ? (
    <Tooltip
      title={t("collaborativeCare:tasks.actions.tooltips.disabledLogTimeBlockedByTracking")}
      arrow
      placement="right-end"
    >
      <div>
        <Button onClick={() => setShowForm(true)} disabled={props.isDisabled} variant={variant} color={color}>
          {props.buttonLabel}
        </Button>
      </div>
    </Tooltip>
  ) : (
    <Button onClick={() => setShowForm(true)} disabled={props.isDisabled} variant={variant} color={color}>
      {props.buttonLabel}
    </Button>
  );
  return (
    <>
      {button}
      <ResponsiveDialog
        open={showForm}
        onClose={() => setShowForm(false)}
        title={t("collaborativeCare:tasks.timeEntryLog.logTimeHeader") + ": " + props.task.title}
        dialogWidth="50%"
      >
        <DialogContent>
          {/* Pausing for a couple hundred millis here so that users have a chance to see the checkmark before the
            form goes away */}
          <CreateTimeEntryLogForm
            providerId={currentProviderId}
            taskId={props.task.id}
            isPlaceholder={props.task.isPlaceholder}
            patientId={props.patientId}
            onSuccess={() => setTimeout(() => setShowForm(false), 300)}
          />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}
