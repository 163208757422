import React from "react";
import { createRoot } from "react-dom/client";
import "./i18n";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import Style from "./Style";
import * as Sentry from "@sentry/react";
import { objOfOr } from "./Lib/Utils";
import { ApolloProvider } from "@apollo/client";
import { client } from "Api/GraphQL";
import { BrowserRouter } from "react-router-dom";
import captureException from "Lib/ErrorHandling";
import { LaunchContextProvider } from "Contexts/LaunchContext";

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    environment: string | undefined;
    appVersion: string | undefined;
  }
}

// In development mode nothing gets expanded.
const sentryEnvironment = window.environment === "%ENVIRONMENT%" ? "development" : window.environment;
const release = window.appVersion === "%CURRENT_GIT_SHA%" ? null : window.appVersion;

Sentry.init({
  beforeSend(event, _) {
    if (sentryEnvironment == "development") {
      // Returning null here effectively deletes the event. This is what keeps us
      // from polluting sentry with local development errors.
      return null;
    }
    return event;
  },
  dsn: "https://4195b602a2f1494e97258b09b2a3c6d7@o376047.ingest.sentry.io/5257288",
  ...objOfOr("environment", sentryEnvironment),
  ...objOfOr("release", release),

  // Explicitly set this to an empty array so no one is tempted to use it by accident (see comment on integrations)
  ignoreErrors: [],
  // For reasons I cannot properly suss out, the InboundFilters integration is dropping many of our errors that
  // originate in react components. The default behavior _which we are not changing_ is to drop ScriptError instances,
  // which are often spurious. However, we've observed it also dropping other errors, despite not being able to find
  // a mechanism that would make it do that. Hence, disable the entire integration. Note that this also disables the
  // functionality of the ignoreErrors option in init, since InboundFilters is what implements the error ignoring
  // feature. If we end up getting spammed with too many bad errors from turning this off we may need to implement
  // our own filtering or something.
  integrations: (allIntegrations) => {
    const integrations = allIntegrations.filter((i) => i.name !== "InboundFilters");

    integrations.push(Sentry.browserTracingIntegration());

    return integrations;
  },

  // We currently want distributed tracing set up to cross reference errors on the backend and frontend, but do want to
  // cross reference when we get
  tracesSampleRate: 0,

  // Enable debug logging. Only turn on when trying to debug an issue with Sentry, shouldn't normally be enabled in
  // production environments.
  debug: false,
});

// New React 18 mounting incantation:
// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  // If you are developing single sign on flows, the double render caused by StrictMode can invalidate the token
  // you generate. Comment it out when developing.
  root.render(
    <React.StrictMode>
      <Style>
        <LaunchContextProvider>
          <BrowserRouter>
            <ApolloProvider client={client}>
              <App />
            </ApolloProvider>
          </BrowserRouter>
        </LaunchContextProvider>
      </Style>
    </React.StrictMode>
  );
} else {
  // Something has gone catastrophically wrong and we can't mount the app. What do?
  captureException(new Error("Unable to mount React app, no #root element."), "index");
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
