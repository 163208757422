import { DialogContent, Grid, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { useEnrollmentState } from "CollaborativeCare/PatientDetails/EnrollmentState";
import { BaseContext, alertRoute, scaleScoreRoute } from "FeedbackReport/FeedbackReportRouting";
import { scaleThresholdClassT } from "GeneratedGraphQL/EnumTranslations";
import {
  CareEpisodeClinicalAlert,
  CareEpisodeClinicalAlertHistory,
  CareEpisodeComputedValue,
  CategoricalScaleScorer,
  ClinicalAlertType,
  ClinicalAlertValue,
  NumericalScaleScorer,
  Scale,
  ScaleThreshold,
  ScaleThresholdClass,
  UnscoredScaleScorer,
  User,
  useEnrolledStatusIndicatorQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId, EnrollmentId, PatientId } from "Lib/Ids";
import Link from "MDS/Link";
import { alertColor } from "Shared/Scale/CareEpisodeClinicalAlert";
import { freshStyles } from "Shared/Scale/SeverityChip";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import * as Id from "Lib/Id";
import { useIsDesktop } from "Shared/Responsive";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";

type PatientHeaderStatusProps = {
  patientId: PatientId;
  patientName: string;
};

export function PatientHeaderStatus(props: PatientHeaderStatusProps): ReactElement {
  const enrollmentState = useEnrollmentState(props.patientId);
  switch (enrollmentState.status) {
    case "unknown":
      return <LoadingStatus />;
    case "not-enrolled":
      return <UnenrolledStatus />;
    case "enrolled":
      return (
        <EnrolledStatus
          patientId={props.patientId}
          careEpisodeId={enrollmentState.careEpisodeId}
          patientName={props.patientName}
          enrollmentId={enrollmentState.enrollmentId}
        />
      );
  }
}

const LoadingBubble = styled(Skeleton)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  borderRadius: "50%",
  // Default skeleton style is a weird transform that shrinks them vertically? why???
  transform: "none",
}));

export function LoadingStatus(): ReactElement {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <LoadingBubble />
      <LoadingBubble />
      <LoadingBubble />
    </Stack>
  );
}

const UnenrolledBar = styled("div")(({ theme }) => ({
  height: theme.spacing(2),
  // 3 bubbles @ 2 each + 2 spacers @ 0.5 each.
  width: theme.spacing(7),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.divider,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

function UnenrolledStatus(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  return <UnenrolledBar>{t("collaborativeCare:enrollment.unenrolled")}</UnenrolledBar>;
}

function ErrorStatus(): ReactElement {
  // Is it really useful to tell the user that there was an error here? They can't do anything about it. Maybe if I had
  // more design chops I could come up with something to put here that didn't look like an alert indicator?
  return <></>;
}

type EnrolledStatusProps = {
  patientId: PatientId;
  careEpisodeId: CareEpisodeId;
  patientName: string;
  enrollmentId: EnrollmentId;
};

function EnrolledStatus(props: EnrolledStatusProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useEnrolledStatusIndicatorQuery({ variables: { enrollmentId: props.enrollmentId } })
  );
  return remoteData.caseOf({
    NotAsked: () => <LoadingStatus />,
    Loading: () => <LoadingStatus />,
    Failure: () => <ErrorStatus />,
    Success: (response) => {
      if (response.collaborativeCareSeverityBadge) {
        return (
          <ThreePartStatus
            patientId={props.patientId}
            careEpisodeId={props.careEpisodeId}
            patientName={props.patientName}
            alert={response.collaborativeCareSeverityBadge.alert}
            target={response.collaborativeCareSeverityBadge.target}
            generalFunctioning={response.collaborativeCareSeverityBadge.generalFunctioning}
          />
        );
      } else {
        return <ErrorStatus />;
      }
    },
  });
}

type NamedScale = { scale: Pick<Scale, "id" | "shortname"> };
type MaybeNamedScale = { scale: Pick<Scale, "id" | "shortname"> | null };
type RespondentSummary = { user: Pick<User, "id" | "name"> | null };
type ScorerWithThresholds = {
  scorer:
    | PickTypename<CategoricalScaleScorer, "id" | "scoreType">
    | PickTypename<UnscoredScaleScorer, "id" | "scoreType">
    | (PickTypename<NumericalScaleScorer, "id" | "scoreType"> & {
        thresholdData: ReadonlyArray<PickTypename<ScaleThreshold, "thresholdClass">> | null;
      });
};
type CareEpisodeClinicalAlertHistorySummary = PickTypename<
  CareEpisodeClinicalAlertHistory,
  "id" | "alertType"
> & { latest: PickTypename<CareEpisodeClinicalAlert, "id" | "status"> | null } & MaybeNamedScale;
type CareEpisodeComputedValueSummary = PickTypename<
  CareEpisodeComputedValue,
  "id" | "thresholdClass" | "thresholdMnemonic"
> &
  NamedScale &
  RespondentSummary &
  ScorerWithThresholds;

type ThreePartStatusProps = {
  patientId: PatientId;
  careEpisodeId: CareEpisodeId;
  patientName: string;
  alert: CareEpisodeClinicalAlertHistorySummary | null;
  target: CareEpisodeComputedValueSummary | null;
  generalFunctioning: CareEpisodeComputedValueSummary | null;
};

function ThreePartStatus(props: ThreePartStatusProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const [mobileDialogOpen, setMobileDialogOpen] = React.useState(false);
  const badge = (
    <Stack
      direction="row"
      spacing={0}
      alignItems="center"
      divider={<IndicatorGroupSeparator direction="row" />}
      onClick={() => setMobileDialogOpen(true)}
    >
      <AlertIndicatorBubble alert={props.alert} />
      <ComputedValueIndicatorBubble computedValue={props.target} />
      <ComputedValueIndicatorBubble computedValue={props.generalFunctioning} />
    </Stack>
  );

  if (useIsDesktop()) {
    return <Tooltip title={<StatusIndicatorTooltip {...props} />}>{badge}</Tooltip>;
  } else {
    return (
      <>
        {badge}
        <ResponsiveDialog
          title={t("collaborativeCare:patientDetails.severityBadge.title", { name: props.patientName })}
          open={mobileDialogOpen}
          onClose={() => setMobileDialogOpen(false)}
        >
          <DialogContent>
            <StatusIndicatorTooltip {...props} />
          </DialogContent>
        </ResponsiveDialog>
      </>
    );
  }
}

type AlertIndicatorBubbleProps = {
  alert: CareEpisodeClinicalAlertHistorySummary | null;
};

function AlertIndicatorBubble(props: AlertIndicatorBubbleProps): ReactElement {
  const theme = useTheme();
  // The alert bubble has three states - alert, no alert and not measured. There's only two ClinicalAlertValue options,
  // so we have to fall back to unknown threshold color for not measured.
  const color = props.alert?.latest?.status
    ? alertColor(props.alert.latest.status, theme).primary
    : freshStyles(ScaleThresholdClass.UNKNOWN, theme).backgroundColor;

  return <IndicatorBubble background={color} />;
}

type ComputedValueIndicatorBubbleProps = {
  computedValue: CareEpisodeComputedValueSummary | null;
};

function ComputedValueIndicatorBubble(props: ComputedValueIndicatorBubbleProps): ReactElement {
  const theme = useTheme();
  const color = freshStyles(props.computedValue?.thresholdClass || ScaleThresholdClass.UNKNOWN, theme);

  return <IndicatorBubble background={color.backgroundColor} />;
}

const IndicatorBubble = styled("div", { shouldForwardProp: (_) => false })<{
  background: string;
  fade?: boolean;
}>(({ theme, background, fade }) => ({
  borderRadius: "50%",
  border: `${theme.spacing(0.125)} solid ${theme.palette.primary.main}`,
  width: theme.spacing(2),
  height: theme.spacing(2),
  position: "relative",
  backgroundColor: background,
  opacity: fade ? "30%" : "100%",
}));

const IndicatorGroupSeparator = styled("div", { shouldForwardProp: (_) => false })<{
  direction: "row" | "column";
  fade?: boolean;
}>(({ theme, direction, fade }) => ({
  width: direction == "row" ? theme.spacing(0.5) : theme.spacing(0.125),
  height: direction == "row" ? theme.spacing(0.125) : theme.spacing(0.5),
  backgroundColor: theme.palette.primary.main,
  opacity: fade ? "30%" : "100%",
}));

function AlertIndicatorAllBubbles(props: AlertIndicatorBubbleProps): ReactElement {
  const theme = useTheme();
  const status = props.alert?.latest?.status;

  if (status === undefined) {
    return <IndicatorBubble background={freshStyles(ScaleThresholdClass.UNKNOWN, theme).backgroundColor} />;
  }

  return (
    <Stack
      direction="column"
      spacing={0}
      alignItems="center"
      divider={<IndicatorGroupSeparator direction="column" fade />}
    >
      <IndicatorBubble
        background={alertColor(ClinicalAlertValue.ALERT, theme).primary}
        fade={status !== ClinicalAlertValue.ALERT}
      />
      <IndicatorBubble
        background={alertColor(ClinicalAlertValue.NO_ALERT, theme).primary}
        fade={status !== ClinicalAlertValue.NO_ALERT}
      />
    </Stack>
  );
}

function ComputedValueIndicatorAllBubbles(props: ComputedValueIndicatorBubbleProps): ReactElement {
  const theme = useTheme();
  const activeThreshold = props.computedValue?.thresholdClass;

  if (
    props.computedValue?.scorer.__typename === "NumericalScaleScorer" &&
    props.computedValue.scorer.thresholdData
  ) {
    const thresholds = props.computedValue.scorer.thresholdData.map((data) => data.thresholdClass);
    // It just so happens that the thresholds always come out of the API from least to most severe, so we can rely on
    // that here. In theory we might have to start sorting them or something eventually.
    thresholds.reverse();

    return (
      <Stack
        direction="column"
        spacing={0}
        alignItems="center"
        divider={<IndicatorGroupSeparator direction="column" fade />}
      >
        {thresholds.map((threshold) => {
          return (
            <IndicatorBubble
              background={freshStyles(threshold, theme).backgroundColor}
              fade={threshold !== activeThreshold}
              key={threshold.toString()}
            />
          );
        })}
      </Stack>
    );
  } else {
    // If this isn't a numerical scorer there's no threshold stack to draw, so just fall back to the same indicator
    // bubble that we show in the inline badge.
    return <ComputedValueIndicatorBubble computedValue={props.computedValue} />;
  }
}

function StatusIndicatorTooltip(props: ThreePartStatusProps) {
  // Rather than making a query to load the full feedback report context, this is just enough to make routes.
  const feedbackReportContext = { patientId: props.patientId, careEpisodeId: props.careEpisodeId };

  return (
    <Grid container columns={3} columnSpacing={2} rowSpacing={2} sx={{ textAlign: "center" }}>
      <Grid item xs={1} display="flex" flexDirection="column" alignItems="center">
        <AlertIndicatorAllBubbles alert={props.alert} />
      </Grid>
      <Grid item xs={1} display="flex" flexDirection="column" alignItems="center">
        <ComputedValueIndicatorAllBubbles computedValue={props.target} />
      </Grid>
      <Grid item xs={1} display="flex" flexDirection="column" alignItems="center">
        <ComputedValueIndicatorAllBubbles computedValue={props.generalFunctioning} />
      </Grid>
      <Grid item xs={1}>
        <AlertExplanation
          alert={props.alert}
          patientName={props.patientName}
          feedbackReportContext={feedbackReportContext}
        />
      </Grid>
      <Grid item xs={1}>
        <TargetExplanation
          computedValue={props.target}
          patientName={props.patientName}
          feedbackReportContext={feedbackReportContext}
        />
      </Grid>
      <Grid item xs={1}>
        <GeneralFunctioningExplanation
          computedValue={props.generalFunctioning}
          patientName={props.patientName}
          feedbackReportContext={feedbackReportContext}
        />
      </Grid>
    </Grid>
  );
}

type AlertExplanationProps = {
  alert: CareEpisodeClinicalAlertHistorySummary | null;
  patientName: string;
  feedbackReportContext: BaseContext;
};

function AlertExplanation(props: AlertExplanationProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  let text = t("collaborativeCare:patientDetails.severityBadge.explanations.alert.notMeasured", {
    name: props.patientName,
  });
  if (props.alert?.latest?.status === ClinicalAlertValue.ALERT) {
    switch (props.alert.alertType) {
      case ClinicalAlertType.ALLIANCE:
        text = t("collaborativeCare:patientDetails.severityBadge.explanations.alert.ALLIANCE.ALERT", {
          name: props.patientName,
          scale: props.alert.scale?.shortname,
        });
        break;
      case ClinicalAlertType.MEDICATIONS:
        text = t("collaborativeCare:patientDetails.severityBadge.explanations.alert.MEDICATIONS.ALERT", {
          name: props.patientName,
          scale: props.alert.scale?.shortname,
        });
        break;
      case ClinicalAlertType.SUBSTANCE_USE:
        text = t("collaborativeCare:patientDetails.severityBadge.explanations.alert.SUBSTANCE_USE.ALERT", {
          name: props.patientName,
          scale: props.alert.scale?.shortname,
        });
        break;
      case ClinicalAlertType.SUICIDALITY:
        text = t("collaborativeCare:patientDetails.severityBadge.explanations.alert.SUICIDALITY.ALERT", {
          name: props.patientName,
          scale: props.alert.scale?.shortname,
        });
        break;
    }
  } else if (props.alert?.latest?.status === ClinicalAlertValue.NO_ALERT) {
    switch (props.alert.alertType) {
      case ClinicalAlertType.ALLIANCE:
        text = t("collaborativeCare:patientDetails.severityBadge.explanations.alert.ALLIANCE.NO_ALERT", {
          name: props.patientName,
          scale: props.alert.scale?.shortname,
        });
        break;
      case ClinicalAlertType.MEDICATIONS:
        text = t("collaborativeCare:patientDetails.severityBadge.explanations.alert.MEDICATIONS.NO_ALERT", {
          name: props.patientName,
          scale: props.alert.scale?.shortname,
        });
        break;
      case ClinicalAlertType.SUBSTANCE_USE:
        text = t("collaborativeCare:patientDetails.severityBadge.explanations.alert.SUBSTANCE_USE.NO_ALERT", {
          name: props.patientName,
          scale: props.alert.scale?.shortname,
        });
        break;
      case ClinicalAlertType.SUICIDALITY:
        text = t("collaborativeCare:patientDetails.severityBadge.explanations.alert.SUICIDALITY.NO_ALERT", {
          name: props.patientName,
          scale: props.alert.scale?.shortname,
        });
        break;
    }
  }

  if (props.alert && props.alert.latest) {
    const url = alertRoute(
      props.feedbackReportContext,
      props.alert.id,
      // For some reason this isn't typed correctly in the API. The feedback report does the same thing as this in the
      // guts of its response mangling code, and I don't want to risk making that angry by changing the API type so
      // I guess we live with this for now.
      Id.unsafeFromUuid(props.alert.latest.id)
    );
    return <Link to={url}>{text}</Link>;
  } else {
    return <Typography>{text}</Typography>;
  }
}

type ComputedValueExplanationProps = {
  computedValue: CareEpisodeComputedValueSummary | null;
  patientName: string;
  feedbackReportContext: BaseContext;
};

function TargetExplanation(props: ComputedValueExplanationProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "enums"]);

  // Use the name of the actual respondent if its present on the computed value, otherwise fall back to the patient.
  const name = props.computedValue?.user?.name || props.patientName;

  if (props.computedValue) {
    const thresholdLabel =
      props.computedValue.thresholdMnemonic ||
      scaleThresholdClassT(props.computedValue.thresholdClass || ScaleThresholdClass.UNKNOWN, t);
    const text = t("collaborativeCare:patientDetails.severityBadge.explanations.target.value", {
      name: name,
      threshold: thresholdLabel,
      scale: props.computedValue.scale.shortname,
    });
    const url = scaleScoreRoute(
      props.feedbackReportContext,
      props.computedValue.scale.id,
      props.computedValue.id
    );
    return <Link to={url}>{text}</Link>;
  } else {
    const text = t("collaborativeCare:patientDetails.severityBadge.explanations.target.noValue", {
      name: name,
    });
    return <Typography>{text}</Typography>;
  }
}

function GeneralFunctioningExplanation(props: ComputedValueExplanationProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "enums"]);

  // Use the name of the actual respondent if its present on the computed value, otherwise fall back to the patient.
  const name = props.computedValue?.user?.name || props.patientName;

  if (props.computedValue) {
    const thresholdLabel =
      props.computedValue.thresholdMnemonic ||
      scaleThresholdClassT(props.computedValue.thresholdClass || ScaleThresholdClass.UNKNOWN, t);
    const text = t("collaborativeCare:patientDetails.severityBadge.explanations.generalFunctioning.value", {
      name: name,
      threshold: thresholdLabel,
      scale: props.computedValue.scale.shortname,
    });
    const url = scaleScoreRoute(
      props.feedbackReportContext,
      props.computedValue.scale.id,
      props.computedValue.id
    );
    return <Link to={url}>{text}</Link>;
  } else {
    const text = t("collaborativeCare:patientDetails.severityBadge.explanations.generalFunctioning.noValue", {
      name: name,
    });
    return <Typography>{text}</Typography>;
  }
}
