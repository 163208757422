import { Badge, Box, Stack, Typography, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { useTestPatientViewability } from "Contexts/TestPatientViewabilityContext";
import { usePatientLinkUrl } from "FeedbackReport/Demographics/DemographicInfo";
import {
  CareEpisode,
  CareEpisodeProvider,
  CareEpisodeProviderRelationship,
  Enrollment,
  EnrollmentMonth,
  Patient,
  Provider,
  usePatientMonthlyBillingReportQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { EnrollmentId, EnrollmentMonthId, PatientId } from "Lib/Ids";
import { CsvExportableColDefs, CsvExportableTable } from "MDS/Csv";
import Link from "MDS/Link";
import ErrorMessage from "Shared/ErrorMessage";
import { dateToMonth } from "Shared/Month";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { DownloadEnrollmentMonthSummaryPdfButton } from "./DownloadEnrollmentMonthSummaryButton";

export function MonthlyBillingReport(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <Page browserTitle={t("collaborativeCare:monthlyBillingReport.title")}>
      <Stack direction="column" spacing={1}>
        <Stack direction="row">
          <Typography component="h1" variant="h1" sx={{ fontSize: "2em" }} flexGrow={1}>
            {t("collaborativeCare:monthlyBillingReport.header")}
          </Typography>
        </Stack>
      </Stack>
      <MonthlyBillingReportList />
    </Page>
  );
}

function MonthlyBillingReportList(): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const theme = useTheme();

  const [date, setDate] = React.useState(new Date());

  const testPatientViewAbility = useTestPatientViewability();

  const { remoteData } = apolloQueryHookWrapper(
    usePatientMonthlyBillingReportQuery({
      variables: {
        monthAndYear: dateToMonth(date),
        testPatient: testPatientViewAbility,
        minimumBillableMinutes: 1,
      },
    })
  );

  return remoteData.caseOf({
    NotAsked: () => <Typography>{t("common:remoteData.notAsked")}</Typography>,
    Loading: () => <Typography>{t("collaborativeCare:monthlyBillingReport.loading")}</Typography>,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (response) => {
      if (!response.collaborativeCareEnrollmentMonths) {
        return <ErrorMessage message={t("collaborativeCare:monthlyBillingReport.genericQueryError")} />;
      }
      return (
        <Stack direction="column" spacing={1} marginTop={1}>
          <Box width={theme.spacing(15)}>
            <DatePicker
              views={["year", "month"]}
              label={t("collaborativeCare:monthlyBillingReport.fields.yearAndMonth")}
              minDate={new Date("2012-03-01")} // This should be the oldest enrollment for the institute.
              maxDate={new Date()}
              openTo={"month"}
              value={date}
              onChange={(newValue) => {
                if (newValue) {
                  setDate(newValue);
                }
              }}
              sx={{ backgroundColor: theme.palette.background.paper }}
            />
          </Box>
          <MonthyBillingReportTable
            billingMonths={response.collaborativeCareEnrollmentMonths.nodes}
            date={date}
          />
        </Stack>
      );
    },
  });
}

type RowDataType = {
  id: EnrollmentId;
  enrollmentMonthId: EnrollmentMonthId;
  patientId: PatientId;
  patientName: string;
  mrn: string | null;
  dob: string;
  billableMinutes: number;
  providerName: string | undefined;
  providerNpi: string | undefined | null;
  isTest: boolean;
  assessmentCompleted: boolean;
  careManagerName: string | undefined;
  enrolledAt: string;
  monthsEnrolled: number;
  billingMonth: string;
  // This key exists just to make the type system happy as all columns need to be
  // in the type right now.
  download: null;
};

type MonthlyBillingReportDataGridProps = {
  billingMonths: ReadonlyArray<
    PickTypename<
      EnrollmentMonth,
      "id" | "billableMinutes" | "beginningOfMonth" | "validatedMeasureCompleted"
    > & {
      enrollment: PickTypename<Enrollment, "id" | "enrolledAt"> & {
        careEpisode: PickTypename<CareEpisode, "id"> & {
          careEpisodeProviders: ReadonlyArray<
            PickTypename<CareEpisodeProvider, "id" | "relationship"> & {
              provider: PickTypename<Provider, "id" | "name" | "npiNumber">;
            }
          >;
          patient: PickTypename<
            Patient,
            "id" | "name" | "legalFirstName" | "legalLastName" | "dob" | "mrn" | "isTest"
          >;
        };
      };
    }
  >;
  date: Date;
};

function MonthyBillingReportTable(props: MonthlyBillingReportDataGridProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common", "patients"]);

  const rows: Array<RowDataType> = props.billingMonths.map((billingMonth) => {
    const patient = billingMonth.enrollment.careEpisode.patient;

    const provider = billingMonth.enrollment.careEpisode.careEpisodeProviders.find((provider) => {
      // Basically, we consider any provider that is not specifically a care manager to be a valid PCP.
      // Since we're doing this client side and there's lots of opportunities for sorting to be a problem,
      // we just need to be aware that this is being done here.
      return provider.relationship != CareEpisodeProviderRelationship.CARE_MANAGER;
    });

    const careManager = billingMonth.enrollment.careEpisode.careEpisodeProviders.find((provider) => {
      return provider.relationship == CareEpisodeProviderRelationship.CARE_MANAGER;
    });

    const year = billingMonth.beginningOfMonth.getFullYear();
    // We're adding a single month here, because it's 0-indexed, and because it fixes the
    // months enrolled math where the first calendar month is also the 1st and not the 0th.
    // .getMonth() returns an integer between 0-11 for months
    const month = billingMonth.beginningOfMonth.getMonth() + 1;
    let monthsEnrolled = year * 12 - billingMonth.enrollment.enrolledAt.getFullYear() * 12;
    monthsEnrolled -= billingMonth.enrollment.enrolledAt.getMonth();
    monthsEnrolled += month;

    const calculateLegalName = (patient.legalLastName || "")
      .concat(", ")
      .concat(patient.legalFirstName || "");

    return {
      id: billingMonth.enrollment.id, // Datagrid seems to require an id.
      enrollmentMonthId: billingMonth.id,
      patientId: patient.id,
      patientName: calculateLegalName,
      mrn: patient.mrn,
      dob: patient.dob ? t("common:date.tiny", { date: patient.dob }) : "",
      billableMinutes: billingMonth.billableMinutes,
      providerName: provider?.provider.name,
      providerNpi: provider?.provider.npiNumber,
      careManagerName: careManager?.provider.name,
      enrolledAt: t("common:date.tiny", { date: billingMonth.enrollment.enrolledAt }),
      monthsEnrolled: monthsEnrolled,
      isTest: patient.isTest,
      assessmentCompleted: billingMonth.validatedMeasureCompleted,
      billingMonth: `${month}/01/${year}`, // We're dealing with zero indexed before this.
      download: null,
    };
  });

  const columns: CsvExportableColDefs<RowDataType> = React.useMemo(() => {
    return [
      {
        headerName: "#",
        rowNumber: true,
      },
      {
        field: "patientName",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.patientName"),
        renderCell: (row) => {
          const url = usePatientLinkUrl(row.patientId);
          return (
            <Badge
              badgeContent={t("patients:referenceHeader.testPatient")}
              invisible={!row.isTest}
              color="success"
            >
              <Link to={url}>{row.patientName}</Link>
            </Badge>
          );
        },
        minWidth: 175,
        sortable: true,
        defaultSort: true,
      },
      {
        field: "mrn",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.mrn"),
        sortable: true,
      },
      {
        field: "dob",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.dob"),
        sortable: true,
      },
      {
        field: "billableMinutes",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.billableMinutes"),
        sortable: true,
      },
      {
        field: "providerName",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.providerName"),
        minWidth: 175,
        sortable: true,
      },
      {
        field: "providerNpi",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.providerNpi"),
        sortable: true,
      },
      {
        field: "careManagerName",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.careManagerName"),
        minWidth: 175,
        sortable: true,
      },
      {
        field: "enrolledAt",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.enrolledAt"),
        sortable: true,
      },
      {
        field: "monthsEnrolled",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.monthsEnrolled"),
        sortable: true,
      },
      {
        field: "assessmentCompleted",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.assessmentCompleted"),
        minWidth: 150,
        sortable: true,
      },
      {
        field: "billingMonth",
        headerName: t("collaborativeCare:monthlyBillingReport.fields.billingMonth"),
        minWidth: 100,
        sortable: true,
      },
      {
        field: "download",
        disableExport: true,
        headerName: t("collaborativeCare:monthlyBillingReport.fields.pdf"),
        sortable: false,
        renderCell: (row) => {
          return (
            <DownloadEnrollmentMonthSummaryPdfButton
              enrollmentMonthId={row.enrollmentMonthId}
              disabled={false}
            />
          );
        },
      },
    ];
  }, []);

  const csvFileName = `${t("collaborativeCare:monthlyBillingReport.title")} - ${
    props.date.getMonth() + 1
  }-${props.date.getFullYear()}.csv`;

  return <CsvExportableTable rows={rows} columns={columns} filename={csvFileName} />;
}
