// What in the ever-loving fuck is this?
// This binds a "category" of related queries together into an easy bundle that Apollo's refresh query system
// understands. Apollo supports sending arbitrary graphql documents with arbitrary variables as part of the refetch,
// but we've found that in practice, it's better to stick to "named refetches", where you simply specify the name(s) of
// the document(s) you want to refetch, and Apollo will run each query with the most recent set of variables that it
// was executed with (or do nothing if the query has never been executed this session). This avoids issues with
// parameter drift over time and missing parameters causing things like loading an entire data set instead of just one
// page of it.
//
// Generally speaking, to add a new set:
// * Add an entry to CATEGORY_TO_REFETCH_QUERY_MAP.
//    * You'll need one item in the list for each query document. The list items should be the names of queries, so if
//      you have a query like
//
//          query PatientSearch($search: String) {
//            ...
//          }
//
//      you'll want a refetch list like `patients: ["PatientSearch"]`
// * Go forth and conquer.
//
// When using refetchQueries, it's as simple as calling: `refetchQueries: refetchQueries("tasks")` in the Apollo
// useQuery hook.

// This is mapping between some arbitrary category names and one or more query documents
// for our refetch queries. This helps with consistency, because we only need add another
// query document here and things which may impact it should all now know to fetch that query
// too.
//
// This may eventually cause undesired performance issues and is only meant to be a stopgap.
const CATEGORY_TO_REFETCH_QUERY_MAP = {
  careEpisodes: ["CareEpisodeDetails"],
  goals: ["OverviewGoalsQuery"],
  instituteSettings: ["InstituteSettings"],
  organizationSettings: ["OrganizationSettings", "OrganizationTree"],
  organizationList: ["OrganizationTree"],
  providerSettings: ["ProviderSettings"],
  relatedPeople: ["PatientRelatedPeople"],
  sessions: ["SurveyManagementIndex"],
  tasks: [
    "TaskSearch",
    "DefaultTaskList",
    "CollaborativeCareTaskDetails",
    // NWM: I am commenting this out, because it fixes an immediate problem, but it might have other
    // impacts because this refetch is widely used and I don't want to forget that we used to refetch this.
    // What I'd actually like to do is figure out under what circumstances it's dangerous to do this
    // refetch and make it just skip this and make these safe instead, because we'll never see
    // these ahead of time.
    // "TasksCard",
  ],
  discharge: [
    "PatientDetails",
    "TasksCard",
    "TaskSearch",
    "CollaborativeCarePatientCount",
    "SurveyManagementIndex",
  ],
  enroll: ["PatientActiveCollaborativeCareEpisode", "CollaborativeCarePatientCount", "SurveyManagementIndex"],
  taskTemplates: ["TaskTemplates"],
  timeEntries: ["CurrentTimeTracking", "DefaultTaskList", "PatientBillableMinutes", "TasksCard"],
  treatmentServices: ["TreatmentServices"],
  treatmentTracks: ["TreatmentTracksByCareEpisodeId", "CareEpisodeDetails"],
  consultRequests: ["CaseConsultList", "ConsultMeetingList", "ConsultMeetingSummary"],
  quickTexts: ["QuickTextList"],
  findings: ["Findings"],
  treatmentTargets: ["InstituteMeasurementBundles"],
  panels: ["PanelsList"],
  instituteGroups: ["InstituteGroupList", "InstituteGroupDetails"],
};

/**
 * Construct a list of refetchable query documents that should be updated because of the effects of a mutation you're
 * running.
 * @param category A named category of refetch documents, see the large map in RefetchQueries.ts
 * @param variables An optional hash of variables to pass to the query. Note that for a query to refetch correctly the
 *                  variables must match _exactly_.
 * @returns
 */
export function refetchQueries(category: keyof typeof CATEGORY_TO_REFETCH_QUERY_MAP): Array<string> {
  return [...CATEGORY_TO_REFETCH_QUERY_MAP[category]];
}
