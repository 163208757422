import React, { ReactElement, useEffect, useState } from "react";
import { Maybe } from "seidr";

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { CheckCircle } from "@mui/icons-material";
import Spinner from "../../Shared/Spinner";
import Overlay from "../../Shared/Overlay";

import * as NEL from "Lib/NonEmptyList";
import { PatientId } from "Lib/Ids";
import {
  CareGroup,
  Patient,
  useCreateCareGroupMutation,
  useFamilyCareUnitTypeQuery,
  useUpdateCareGroupMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper, apolloMutationHookWrapper, MutationRemoteDataResult } from "Api/GraphQL";
import SumType from "sums-up";
import { PickTypename } from "type-utils";
import PatientsAutocomplete from "Pipbhc/PatientsAutocomplete/PatientsAutocomplete";

// -- MODEL -------------------------------------------------------------------

type Form = {
  name?: string;
  patients?: Array<Pick<Patient, "__typename" | "id" | "name">>;
};

type ValidForm = {
  name: string;
  patientIds: NEL.NonEmptyList<PatientId>;
};

type FormValidationErrors = {
  name?: string;
  patients?: string;
};

class FormState extends SumType<{
  Valid: [ValidForm];
  Invalid: [FormValidationErrors];
}> {
  public static Valid = (validForm: ValidForm) => new FormState("Valid", validForm);
  public static Invalid = (formValidationErrors: FormValidationErrors) =>
    new FormState("Invalid", formValidationErrors);
}

const StyledSuccessIcon = styled(CheckCircle)(({ theme }) => ({
  fontSize: 50,
  color: theme.palette.success.main,
}));

function validateForm(formState: Form): FormState {
  return Maybe.fromNullable(formState.name).caseOf({
    Just: (name) =>
      NEL.fromArray(formState.patients || []).caseOf({
        Nothing: () =>
          FormState.Invalid({
            patients: "There must be at least one patient",
            name: undefined,
          }),
        Just: (patients) =>
          FormState.Valid({
            name: name,
            patientIds: patients.map((p) => p.id),
          }),
      }),
    Nothing: () =>
      FormState.Invalid({
        name: "Name is required",
        patients: NEL.fromArray(formState.patients || []).caseOf({
          Nothing: () => "There must be at least one patient",
          Just: () => undefined,
        }),
      }),
  });
}

function AddUpdateCareGroupDialogForm({
  close,
  save,
  initialFormState,
  saveRemoteData,
}: {
  initialFormState?: Form;
  close: () => void;
  save: (validForm: ValidForm) => void;
  saveRemoteData: MutationRemoteDataResult<{
    readonly careGroup: {
      readonly __typename: "CareGroup";
    } & PickTypename<CareGroup, "id" | "name">;
  } | null>;
}): ReactElement {
  const [formState, updateFormState] = useState<Form | undefined>(initialFormState);

  const [validationErrors, updateValidationErrors] = useState<FormValidationErrors>();

  // validate the form
  useEffect(() => {
    if (formState) {
      validateForm(formState).caseOf({
        Valid: () => updateValidationErrors(undefined),
        Invalid: (errors) => updateValidationErrors(errors),
      });
    } else if (validationErrors) {
      updateValidationErrors(undefined);
    }
  }, [formState]);

  const saveResult = saveRemoteData.caseOf({
    NotAsked: () => null,
    Loading: () => (
      <Overlay>
        <Spinner />
      </Overlay>
    ),
    Failure: (_e) => <Alert severity="error">"{formState?.name || "Untitled"}" could not be saved</Alert>,
    Success: () => (
      <Overlay>
        <StyledSuccessIcon data-testid="success-icon" />
      </Overlay>
    ),
  });

  return (
    <>
      <DialogTitle id="add-care-unit-dialog-title">
        {Maybe.fromNullable(initialFormState?.name).caseOf({
          Just: () => "Edit",
          Nothing: () => "Add",
        })}{" "}
        Care Unit
      </DialogTitle>
      {saveResult}
      <DialogContent dividers>
        <form>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <TextField
                label="Name"
                variant="outlined"
                value={formState?.name || ""}
                onChange={(ev) => updateFormState({ ...(formState || {}), name: ev.target.value })}
                size="small"
                inputProps={{ "data-testid": "name-field" }}
                required
                fullWidth
                autoFocus
                error={!!validationErrors?.name}
                helperText={validationErrors?.name}
              />
            </Grid>
            <Grid item>
              <PatientsAutocomplete
                value={formState?.patients || []}
                required
                error={!!validationErrors?.patients}
                helperText={validationErrors?.patients}
                valueUpdated={(values) => updateFormState({ ...(formState || {}), patients: values })}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (formState) {
              validateForm(formState).caseOf({
                Valid: (validForm) => save(validForm),
                Invalid: () => undefined,
              });
            }
          }}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
}

type ExistingCareGroup = Pick<CareGroup, "__typename" | "id" | "name"> & {
  patients: Array<PickTypename<Patient, "id" | "name">>;
};

type Props = {
  close: () => void;
  done: (cg: Pick<CareGroup, "__typename" | "id" | "name">) => void;
  existingCareGroup?: ExistingCareGroup;
};

function UpdateCareGroupDialog({
  close,
  done,
  existingCareGroup,
}: Props & { existingCareGroup: ExistingCareGroup }): ReactElement {
  const [updateCareGroup, { remoteData }] = apolloMutationHookWrapper(
    (data) => data.updateCareGroup,
    useUpdateCareGroupMutation()
  );

  const saveIt = ({ name, patientIds }: ValidForm) => {
    updateCareGroup({
      variables: {
        careGroup: {
          name: name,
          patientIds: patientIds.toArray(),
          careGroupId: existingCareGroup.id,
        },
      },
      onCompleted: (data) => {
        if (data.updateCareGroup?.result?.careGroup) {
          done(data.updateCareGroup.result.careGroup);
        }
      },
    });
  };

  return (
    <AddUpdateCareGroupDialogForm
      save={saveIt}
      saveRemoteData={remoteData}
      close={close}
      initialFormState={existingCareGroup}
    />
  );
}

function AddCareGroupDialog({ close, done }: Pick<Props, "close" | "done">): ReactElement {
  const remoteCareUnitType = apolloQueryHookWrapper(useFamilyCareUnitTypeQuery());

  const loading = (
    <Overlay>
      <Spinner />
    </Overlay>
  );

  const [createCareGroup, { remoteData }] = apolloMutationHookWrapper(
    (data) => data.createCareGroup,
    useCreateCareGroupMutation()
  );

  return remoteCareUnitType.remoteData.caseOf({
    Loading: () => loading,
    NotAsked: () => loading,
    Success: (data) => {
      const careUnitType = data.careUnitTypes?.nodes[0];
      if (careUnitType) {
        const saveIt = ({ name, patientIds }: ValidForm) => {
          createCareGroup({
            variables: {
              careGroup: {
                name: name,
                patientIds: patientIds.toArray(),
                careUnitTypeId: careUnitType.id,
              },
            },
            onCompleted: (data) => {
              if (data.createCareGroup?.result?.careGroup) {
                done(data.createCareGroup.result.careGroup);
              }
            },
          });
        };
        return <AddUpdateCareGroupDialogForm save={saveIt} saveRemoteData={remoteData} close={close} />;
      }
      return <div>Could not load the CareUnitTypes</div>;
    },
    Failure: (_e) => <div>Could not load the CareUnitTypes</div>,
  });
}

function AddUpdateCareGroupDialog(props: Props): ReactElement {
  const content = props.existingCareGroup ? (
    <UpdateCareGroupDialog
      close={props.close}
      done={props.done}
      existingCareGroup={props.existingCareGroup}
    />
  ) : (
    <AddCareGroupDialog close={props.close} done={props.done} />
  );

  return (
    <Dialog
      open={true}
      maxWidth="xs"
      fullWidth
      aria-labelledby="add-care-unit-dialog-title"
      data-testid="add-care-unit-dialog"
      onClose={props.close}
    >
      {content}
    </Dialog>
  );
}

export default AddUpdateCareGroupDialog;
