import { Link as RouterLink, useParams } from "react-router-dom";
import {
  InstituteOrganizationQuery,
  useInstituteOrganizationQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { fromNullableString } from "Lib/Id";
import { default as React, ReactElement } from "react";
import ErrorMessage from "Shared/ErrorMessage";
import DetailsLayout from "../DetailsLayout";
import { Dig } from "type-utils";
import { Maybe } from "seidr";
import { Box, Link } from "@mui/material";
import UnderMeasurementChip from "../UnderMeasurementChip";
import { InternalBreadcrumbPath } from "../InstituteDetailsRoute";
import InspectorTab from "Ops/Inspector/InspectorTab";

function InstituteOrganization(props: {
  setSubCrumbs: (subCrumbs: Array<InternalBreadcrumbPath>) => void;
}): ReactElement {
  const params = useParams<{ organizationId?: string }>();

  return fromNullableString<"Organization">(params.organizationId).caseOf({
    Ok: (organizationId) => (
      <DetailsLayout
        queryResult={useInstituteOrganizationQuery({
          variables: {
            organizationId: organizationId,
          },
        })}
        setSubCrumbs={props.setSubCrumbs}
        subCrumbs={(x) => [
          { text: "Organizations", path: "/organizations" },
          { text: x.name, path: `/organizations/${x.id}` },
        ]}
        headerContents={(x) => (
          <Box sx={{ display: "none" }}>
            <UnderMeasurementChip measurementAllowed={x.measurementAllowed} />
          </Box>
        )}
        browserTitle={() => "Organization"}
        titleAction={(x) => <div>({x.id.toString()})</div>}
        resultFetcher={(x: InstituteOrganizationQuery) => x.organization}
        title={(p) => p.name}
        overviewFields={(x) => overviewFields(x)}
        integrationsDataSourceRecords={(p) => p.integrationsDataSourceRecords}
        tabs={(_x) => [
          {
            component: <InspectorTab className={"Organization"} id={organizationId.toString()} />,
            tabLabel: "Inspector",
            path: "inspector",
          },
        ]}
      />
    ),

    Err: () => <ErrorMessage message="Invalid URL" />,
  });
}

function overviewFields(dat: NonNullable<Dig<InstituteOrganizationQuery, ["organization"]>>) {
  return [
    { name: "id", value: dat.id.toString() },
    { name: "name", value: dat.name },

    { name: "shortname", value: dat.shortname },
    {
      name: "parent",
      value: Maybe.fromNullable(dat.parent)
        .map((o) => (
          <Link component={RouterLink} to={`../${o.id}`}>
            {o.name}({o.shortname})
          </Link>
        ))
        .getOrElse(null),
    },
    { name: "Measurement Allowed", value: dat.measurementAllowed },
    { name: "In Trial Period", value: dat.inTrialPeriod },
  ];
}

export default InstituteOrganization;
