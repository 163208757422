import Page from "Layout/Page";
import { useTranslation } from "react-i18next";
import ImplementationTargetForm from "./ImplementationTargetForm";
import {
  CreateImplementationTargetResult,
  ImplementationTargetStatus,
  ImplementationTargetType,
  useCreateImplementationTargetMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useMemo, useState } from "react";
import { startOfWeek } from "date-fns";
import { useNavigate } from "react-router-dom";
import Link from "MDS/Link";
import { Typography } from "@mui/material";

export default function CreateImplementationTarget() {
  const { t } = useTranslation(["common", "implementation"]);
  const [createTarget, { remoteData }] = apolloMutationHookWrapper(
    (result) => result.implementationCreateImplementationTarget,
    useCreateImplementationTargetMutation()
  );
  const [thisWeekStart] = useState<Date>(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const navigate = useNavigate();

  const defaults = useMemo(() => {
    return {
      startDate: thisWeekStart,
      targetType: ImplementationTargetType.COCM_NEW_ENROLLMENTS,
    };
  }, []);

  const onSuccess = (result: CreateImplementationTargetResult) => {
    const id = result.implementationTarget.id;
    navigate(`../${id.toString()}`);
  };

  const breadcrumbs = [
    <Link key="implementation-breadcrumb" to="/app/cocm/implementation">
      {t("implementation:dashboard.title")}
    </Link>,
    <Typography key="create-breadcrumb">{t("implementation:dashboard.createTarget")}</Typography>,
  ];

  return (
    <Page browserTitle={t("implementation:create.title")} breadcrumbs={breadcrumbs}>
      <ImplementationTargetForm
        defaults={defaults}
        mode="create"
        mutation={(form) => {
          createTarget({
            variables: {
              input: {
                name: form.name,
                description: form.description,
                startDate: form.startDate,
                targetType: form.targetType,
                entityTreeNode: form.entityTreeNodeParams,
                status: ImplementationTargetStatus.ACTIVE,
                target: form.target,
                softLaunchDate: form.softLaunchDate,
                initialCredits: form.initialCredits,
                mirahInternal: form.mirahInternal,
              },
            },
          });
        }}
        remoteData={remoteData}
        onSuccess={onSuccess}
      />
    </Page>
  );
}
