import { Box, Divider, Grid, Paper, TextField, styled } from "@mui/material";
import { GlobalPanelIndicatorBadge } from "CollaborativeCare/Panels/GlobalPanelIndicator";
import { taskStatusT } from "GeneratedGraphQL/EnumTranslations";
import {
  ProviderFilter,
  SortDirection,
  TaskSortParameter,
  TaskStatus,
} from "GeneratedGraphQL/SchemaAndOperations";
import EnumSelect from "Shared/EnumSelect";
import ProviderSelect from "Shared/Filters/ProviderSelect";
import { useQueryStringOptionalEncryptedParameter } from "Shared/QueryStringParameter";
import { useIsMobile } from "Shared/Responsive";
import { SortFilters } from "Shared/Sorting";
import {
  ResetAndStickyFilterButtonGroup,
  useStickyOptionalEnumParameter,
  useStickyProviderFilterParameter,
} from "Shared/StickyParameter";
import { STICKY_PARAMETER_FILTER_SETS, STICKY_PARAMETER_NAMES } from "Shared/Storage";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export type SearchFilters = {
  search: string | null;
  setSearch: (value: string | null) => void;
  assignedTo: ProviderFilter | null;
  setAssignedTo: (value: ProviderFilter | null) => void;
  status: TaskStatus | null;
  setStatus: (value: TaskStatus | null) => void;
} & SortFilters<TaskSortParameter>;

const DEFAULTS = {
  search: null,
  status: TaskStatus.ACTIVE,
  assignedTo: null,
};

export function useTaskSearchFilters(): SearchFilters {
  const [search, setSearch] = useQueryStringOptionalEncryptedParameter("search", null, true);
  const [assignedTo, setAssignedTo] = useStickyProviderFilterParameter(
    STICKY_PARAMETER_NAMES.ASSIGNED_TO,
    STICKY_PARAMETER_FILTER_SETS.COCM_TASKS,
    DEFAULTS.assignedTo,
    true
  );
  const [status, setStatus] = useStickyOptionalEnumParameter<TaskStatus>(
    STICKY_PARAMETER_NAMES.TASK_STATUS,
    STICKY_PARAMETER_FILTER_SETS.COCM_TASKS,
    TaskStatus,
    DEFAULTS.status,
    true
  );

  const [sortBy, setSortBy] = useStickyOptionalEnumParameter<TaskSortParameter>(
    STICKY_PARAMETER_NAMES.SORT_BY,
    STICKY_PARAMETER_FILTER_SETS.COCM_TASKS,
    TaskSortParameter,
    null,
    true
  );
  const [sortDirection, setSortDirection] = useStickyOptionalEnumParameter<SortDirection>(
    STICKY_PARAMETER_NAMES.SORT_DIRECTION,
    STICKY_PARAMETER_FILTER_SETS.COCM_TASKS,
    SortDirection,
    null,
    true
  );

  return {
    search,
    setSearch,
    assignedTo,
    setAssignedTo,
    status,
    setStatus,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  };
}

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderColor: theme.palette.divider,
  borderStyle: "solid",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.25),
}));

type TaskSearchFilterProps = {
  filters: SearchFilters;
};

export default function TaskSearchFilters(props: TaskSearchFilterProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  const { filters } = props;
  const rowSpacing = useIsMobile() ? 1 : 0;

  return (
    <StyledPaper>
      <StyledBox>
        <Grid container rowSpacing={rowSpacing} spacing={1}>
          <Grid item xs="auto">
            <TextField
              id="outlined-required"
              label={t("common:actions.search")}
              value={filters.search || ""}
              onChange={(event) => props.filters.setSearch(event.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs="auto">
            <ProviderSelect
              label={t("collaborativeCare:fields.assignedTo.labelPastTense")}
              value={filters.assignedTo || null}
              setValue={filters.setAssignedTo}
              defaultValue={DEFAULTS.assignedTo}
            />
          </Grid>
          <Grid item xs="auto">
            <Box width="10rem">
              <EnumSelect
                title={t("collaborativeCare:fields.status.label")}
                value={filters.status}
                optionsEnum={TaskStatus}
                enumTrans={taskStatusT}
                onChange={filters.setStatus}
                defaultValue={DEFAULTS.status}
              />
            </Box>
          </Grid>
          <Grid item xs="auto" display="flex" alignItems="center">
            <GlobalPanelIndicatorBadge />
          </Grid>
          <Grid item xs="auto" display="flex" alignItems="center" justifyContent="center">
            <Divider orientation="vertical" flexItem />
            <ResetAndStickyFilterButtonGroup
              onReset={() => {
                filters.setSearch(DEFAULTS.search);
                filters.setAssignedTo(DEFAULTS.assignedTo);
                filters.setStatus(DEFAULTS.status);
                filters.setSortDirection(null);
                filters.setSortBy(null);
              }}
            />
          </Grid>
        </Grid>
      </StyledBox>
    </StyledPaper>
  );
}
