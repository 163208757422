import { Box, Grid, Paper, TextField, styled, useTheme } from "@mui/material";
import { GlobalPanelIndicatorBadge } from "CollaborativeCare/Panels/GlobalPanelIndicator";
import { useCurrentRootNode } from "Contexts/CurrentInstituteIdContext";
import { cocmBillingAlgorithmRuleCheckValueT } from "GeneratedGraphQL/EnumTranslations";
import {
  CocmBillingAlgorithmRuleCheckValue,
  EntityTreeNodeParams,
  EntityType,
  MonthParams,
} from "GeneratedGraphQL/SchemaAndOperations";
import DatePicker from "Shared/DatePickers";
import EnumSelect from "Shared/EnumSelect";
import EntityTreeNodeSelect from "Shared/Filters/EntityTreeNodeSelect";
import { dateToMonth, monthToDate, monthToday } from "Shared/Month";
import { useIsMobile } from "Shared/Responsive";
import {
  ResetAndStickyFilterButtonGroup,
  useStickyEntityTreeNodeParameter,
  useStickyMonthParameterV2,
} from "Shared/StickyParameter";
import { STICKY_PARAMETER_FILTER_SETS, STICKY_PARAMETER_NAMES } from "Shared/Storage";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderColor: theme.palette.divider,
  borderStyle: "solid",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.25),
}));

/**
 * Only allow certain entity types in the outcomes system. No patients/care episodes etc
 */
const allowedEntityTypes = [
  EntityType.INSTITUTE_GROUP,
  EntityType.INSTITUTE,
  EntityType.ORGANIZATION,
  EntityType.PROVIDER,
  EntityType.TREATMENT_SERVICE,
  EntityType.PANEL,
  EntityType.TREATMENT_TRACK,
  EntityType.PRIMARY_CARE_PHYSICIAN,
  EntityType.CARE_MANAGER,
];

export type BillingPredictionFilters = {
  month: MonthParams;
  setMonth: (newMonth: MonthParams) => void;
  valueType: CocmBillingAlgorithmRuleCheckValue;
  setValueType: (valueType: CocmBillingAlgorithmRuleCheckValue) => void;
  search: string | null;
  setSearch: (str: string | null) => void;
  entityTreeNodeParams: EntityTreeNodeParams;
  setEntityTreeNodeParams: (params: EntityTreeNodeParams) => void;
  reset: () => void;
};

export function useBillingPredictionFilters(): BillingPredictionFilters {
  const [search, setSearch] = useState<string | null>(null);
  const [month, setMonth] = useStickyMonthParameterV2(
    "month",
    STICKY_PARAMETER_FILTER_SETS.BILLING,
    monthToday()
  );
  const [valueType, setValueType] = React.useState<CocmBillingAlgorithmRuleCheckValue>(
    CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE
  );
  const [currentRootNode] = useCurrentRootNode();

  const [entityTreeNodeParams, setEntityTreeNodeParams] = useStickyEntityTreeNodeParameter(
    STICKY_PARAMETER_NAMES.ENTITY_TREE_NODE,
    STICKY_PARAMETER_FILTER_SETS.BILLING,
    currentRootNode,
    true
  );

  return {
    month,
    setMonth,
    valueType,
    setValueType,
    search,
    setSearch,
    entityTreeNodeParams,
    setEntityTreeNodeParams,
    reset: () => {
      setEntityTreeNodeParams(currentRootNode);
    },
  };
}

type BillingPredictionFiltersProps = {
  filters: BillingPredictionFilters;
};
export default function BillingPredictionFilters(props: BillingPredictionFiltersProps): ReactElement {
  const { t } = useTranslation(["billing", "common"]);
  const theme = useTheme();
  const { filters } = props;
  const [currentRootNode] = useCurrentRootNode();

  const rowSpacing = useIsMobile() ? 1 : 0;

  return (
    <StyledPaper>
      <Grid container rowSpacing={rowSpacing} spacing={1}>
        <StyledBox>
          <Grid container rowSpacing={rowSpacing} spacing={1}>
            <Grid item xs="auto">
              <TextField
                id="outlined-required"
                label={t("billing:prediction.fields.searchClientsLabel")}
                value={props.filters.search || ""}
                onChange={(event) =>
                  props.filters.setSearch(event.target.value === "" ? null : event.target.value)
                }
                variant="outlined"
              />
            </Grid>
            <Grid item width={theme.spacing(15)}>
              <DatePicker
                views={["year", "month"]}
                label={t("billing:prediction.fields.yearAndMonth")}
                minDate={new Date("2019-01-01")} // This should be the oldest enrollment for the institute.
                maxDate={new Date()}
                openTo={"month"}
                value={monthToDate(filters.month)}
                onChange={(newValue) => {
                  if (newValue) {
                    filters.setMonth(dateToMonth(newValue));
                  }
                }}
                sx={{ backgroundColor: theme.palette.background.paper }}
              />
            </Grid>
            <Grid item width={theme.spacing(15)}>
              <EnumSelect
                value={props.filters.valueType}
                onChange={props.filters.setValueType}
                optionsEnum={CocmBillingAlgorithmRuleCheckValue}
                enumTrans={cocmBillingAlgorithmRuleCheckValueT}
                title={t("billing:prediction.showValueBy")}
                defaultValue={CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE}
              />
            </Grid>
            <Grid item xs="auto" minWidth={"15em"}>
              <EntityTreeNodeSelect
                setValue={filters.setEntityTreeNodeParams}
                entityTypes={allowedEntityTypes}
                value={filters.entityTreeNodeParams}
                defaultValue={currentRootNode}
              />
            </Grid>
            <Grid item xs="auto" display="flex" alignItems="center" justifyContent="center">
              <GlobalPanelIndicatorBadge />
            </Grid>
            <Grid item>
              <ResetAndStickyFilterButtonGroup
                onReset={() => {
                  filters.reset();
                }}
              />
            </Grid>
          </Grid>
        </StyledBox>
      </Grid>
    </StyledPaper>
  );
}
