import React, { ReactElement, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { CareManagerDashboard } from "./CareManagerDashboard/CareManagerDashboard";
import { WithPermission, WithoutPermission } from "Shared/WithPermission";
import NotFound from "Shared/NotFound";
import { PatientList } from "./PatientList/PatientList";
import { TaskTemplates } from "./TaskTemplates";
import AppLayout, { AppLayoutUserMenuItem } from "Layout/AppLayout/AppLayout";
import { AppRouteProps } from "App";
import { useTranslation } from "react-i18next";
import { CurrentTimeTrackingProvider } from "Contexts/TimeTracking/CurrentTimeTrackingContext";
import { PatientDetails } from "./PatientDetails/PatientDetails";
import {
  WithFeature,
  WithOutFeature,
  useConfigurationEnabled,
  useWithFeatureEnabled,
} from "Contexts/CurrentInstituteContext";
import { MonthlyBillingReport } from "./MonthlyBillingReport";
import { CollaborativeCareAppBar } from "./CollaborativeCareAppBar";
import { ModifyTimeEntryFormProvider } from "Contexts/TimeTracking/ModifyTimeEntryFormContext";
import TaskListPage from "./Tasks/TaskListPage";
import {
  AuthenticatedProviderUser,
  providerUserHasPermission,
  providerUserHasRole,
} from "AppSession/AuthenticatedProviderUser";
import { CaseConsults } from "./CaseConsult/CaseConsults";
import { TestPatientViewabilityToggleUserItemDefinition } from "./CareManagerDashboard/TestPatientViewabilityToggle";
import { ConsultMeetingPage } from "./CaseConsult/ConsultMeeting";
import { CaseConsultReview } from "./CaseConsult/CaseConsultReview";
import { QuickTextAdminPage } from "./CaseConsult/QuickTextAdmin";
import { OutcomesRoutes } from "Outcomes";
import MyPerformancePage from "./MyPerformancePage";
import InstitutePerformancePage from "./InstitutePerformancePage";
import { OrganizationsPage } from "Institute/Organizations/OrganizationsPage";
import { BarChart } from "@mui/icons-material";
import { CurrentTaskProvider } from "Contexts/CurrentTaskContext";
import { ImpersonatingUserRoute } from "Settings/ImpersonatingUser";
import { PiiLevelDialog, PiiLevelMenuItems } from "Settings/PiiLevel";
import { PanelsPage } from "./Panels/PanelsPage";
import { SelectedPanelsProvider } from "Contexts/SelectedPanelContext";
import { ImplementationRoutes } from "Implementation";
import { EnrollmentDetails } from "./PatientDetails/EnrollmentDetails";
import { BillingRoutes } from "Billing";
import { MinutesTargetsPage } from "./MinutesTargets";
import { SelectedInstituteGroupsProvider } from "Contexts/ActiveInstituteGroupContext";

type Dialogs = "pii" | null;

export function CollaborativeCareApp(props: AppRouteProps): ReactElement {
  const allowBulkExport = useConfigurationEnabled("allowBulkExport");
  const enableImplementationTargets = useWithFeatureEnabled("enableImplementationTargets");

  const [activeDialog, setActiveDialog] = useState<Dialogs>(null);
  const navigationItems = useCollaborativeCareNavigationItems(
    props.user,
    enableImplementationTargets,
    {
      allowBulkExport,
    },
    setActiveDialog
  );

  return (
    <CurrentTaskProvider>
      <ModifyTimeEntryFormProvider>
        <CurrentTimeTrackingProvider>
          <SelectedInstituteGroupsProvider>
            <SelectedPanelsProvider>
              <AppLayout
                instituteNavItems={navigationItems.instituteNavItems}
                userMenuItems={navigationItems.userMenuItems}
                navItems={navigationItems.navItems}
                dialogs={
                  <>
                    <PiiLevelDialog open={activeDialog === "pii"} onClose={() => setActiveDialog(null)} />
                  </>
                }
                {...props}
              >
                <CollaborativeCareRoutes />
                <CollaborativeCareAppBar />
              </AppLayout>
            </SelectedPanelsProvider>
          </SelectedInstituteGroupsProvider>
        </CurrentTimeTrackingProvider>
      </ModifyTimeEntryFormProvider>
    </CurrentTaskProvider>
  );
}

export function useCollaborativeCareNavigationItems(
  currentUser: AuthenticatedProviderUser,
  enableImplementationTargets: boolean,
  configuration: { allowBulkExport: boolean },
  setActiveDialog: (mode: Dialogs) => void
) {
  const { t } = useTranslation(["general"]);

  const navItems = {
    primaryNavigation: {
      label: t("general:navigation.main.collaborativeCare.dashboard"),
      location: "/app/cocm/dashboard",
    },
    additionalNavigation: [
      {
        label: t("general:navigation.main.collaborativeCare.patients"),
        location: "/app/cocm/patients",
        activeFor: ["/app/cocm/patient"],
      },

      { label: t("general:navigation.main.collaborativeCare.tasks"), location: "/app/cocm/tasks" },

      {
        label: t("general:navigation.main.collaborativeCare.caseConsult"),
        location: "/app/cocm/case-consult",
        activeFor: ["/app/cocm/case-consult"],
      },
    ],
  };

  const instituteNavItems = [
    {
      label: t("general:navigation.institute.collaborativeCare.quickText"),
      location: "/app/cocm/smart-phrases",
    },
    {
      label: t("general:navigation.institute.measureLibrary"),
      location: "/provider/help",
    },
    {
      label: t("general:navigation.institute.collaborativeCare.monthlyBillingReport"),
      location: "/app/cocm/monthly-billing-report",
    },
    {
      label: t("general:navigation.institute.collaborativeCare.taskTemplates"),
      location: "/app/cocm/task-templates",
    },
    {
      label: t("general:navigation.institute.collaborativeCare.minutesTargets"),
      location: "/app/cocm/minutes-targets",
    },
    {
      label: "Panels",
      location: "/app/cocm/panels",
    },
  ];

  if (enableImplementationTargets && providerUserHasPermission(currentUser, "viewImplementationTargets")) {
    instituteNavItems.push({
      label: t("general:navigation.institute.implementation"),
      location: "/app/cocm/implementation",
    });
  }

  if (providerUserHasRole(currentUser, "Collaborative Care Superuser")) {
    instituteNavItems.push({
      label: t("general:navigation.institute.cocmBilling"),
      location: "/app/cocm/billing",
    });
  }

  if (providerUserHasRole(currentUser, "Collaborative Care Superuser")) {
    instituteNavItems.push({
      label: t("general:navigation.main.outcomes"),
      location: "/app/outcomes",
    });
  }

  if (configuration.allowBulkExport && providerUserHasPermission(currentUser, "bulkExport")) {
    instituteNavItems.push({
      label: t("general:navigation.institute.bulkDataExport"),
      location: "/app/settings/provider/admin/exports",
    });
  }

  if (providerUserHasPermission(currentUser, "editProviders")) {
    instituteNavItems.push({
      label: t("general:navigation.main.manageUsers"),
      location: "/provider/admin/users",
    });
  }

  if (providerUserHasPermission(currentUser, "viewCollaborativeCareInstitutePerformance")) {
    instituteNavItems.push({
      label: t("general:navigation.institute.institutePerformance"),
      location: "/app/cocm/institute-performance",
    });
  }

  if (
    useWithFeatureEnabled("enableTreatmentServices") &&
    providerUserHasPermission(currentUser, "editTreatmentServices")
  ) {
    instituteNavItems.push({
      label: t("general:navigation.institute.treatmentServices"),
      location: "/app/institute/treatment-services",
    });
  }

  if (providerUserHasPermission(currentUser, "editConfigurationValues")) {
    instituteNavItems.push({
      label: t("general:navigation.main.editConfiguration"),
      location: "/provider/admin/configuration",
    });
  }

  if (providerUserHasPermission(currentUser, "viewOrganizations")) {
    instituteNavItems.push({
      label: t("general:navigation.institute.organizations"),
      location: "/app/institute/organizations",
    });
  }

  const userMenuItems: Array<AppLayoutUserMenuItem> = [
    TestPatientViewabilityToggleUserItemDefinition(),
    {
      label: t("general:navigation.user.myPerformance"),
      location: "my-performance",
      icon: <BarChart></BarChart>,
    },
    ...PiiLevelMenuItems(() => setActiveDialog("pii")),
  ];

  return {
    navItems: navItems,
    instituteNavItems: instituteNavItems,
    userMenuItems: userMenuItems,
    userBadge: undefined,
  };
}

function CollaborativeCareRoutes(): ReactElement {
  return (
    <>
      <WithFeature feature="enableCollaborativeCare">
        <WithPermission permission="viewCollaborativeCare">
          <Routes>
            <Route element={<ImpersonatingUserRoute />} path="impersonation" />
            <Route element={<CareManagerDashboard />} path="dashboard/*" />
            <Route element={<PatientDetails />} path="patient/:patientId" />
            <Route element={<EnrollmentDetails />} path="patient/:patientId/enrollment/:enrollmentId" />
            <Route element={<PatientList />} path="patients/*" />
            <Route element={<TaskListPage />} path="tasks/*" />
            <Route element={<TaskTemplates />} path="task-templates" />
            <Route element={<QuickTextAdminPage />} path="smart-phrases" />
            <Route element={<MonthlyBillingReport />} path="monthly-billing-report" />
            <Route element={<ConsultMeetingPage />} path="case-consult/meeting/:meetingId" />
            <Route element={<OutcomesRoutes />} path="outcomes/*" />
            <Route element={<ImplementationRoutes />} path="implementation/*" />
            <Route element={<BillingRoutes />} path="billing/*" />
            <Route element={<MyPerformancePage />} path="my-performance" />
            <Route
              element={<CaseConsultReview />}
              path="case-consult/meeting/:meetingId/case/:caseConsultId"
            />
            <Route element={<CaseConsults />} path="case-consult/*" />
            {/* Intentionally not including the entire <InstituteRoutes /> element under cocm because as of now we
                don't want to include the treatment services page. Keeping the institutes/ prefix on the url though
                so that we can swap back to using the whole subsection if we want. */}
            <Route element={<OrganizationsPage />} path="/institute/organizations/*" />
            <Route element={<PanelsPage />} path="panels/" />
            <Route element={<MinutesTargetsPage />} path="minutes-targets" />
          </Routes>
        </WithPermission>
        <WithPermission permission="viewCollaborativeCareInstitutePerformance">
          <Routes>
            <Route element={<InstitutePerformancePage />} path="institute-performance" />
          </Routes>
        </WithPermission>
      </WithFeature>
      <WithoutPermission permission="viewCollaborativeCare">
        <Routes>
          <Route element={<NotFound />} path="*" />
        </Routes>
      </WithoutPermission>
      <WithOutFeature feature="enableCollaborativeCare">
        <Routes>
          <Route element={<NotFound />} path="*" />
        </Routes>
      </WithOutFeature>
    </>
  );
}
