import React, { ReactElement } from "react";
import { Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { CareUnitRow, CareUnitRowsLoading } from "./CareUnitRow";

import Paginator, { QueryPage } from "../../Shared/Paginator";
import { useCareGroupListQueryQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";

type Props = {
  perspective: {
    paging: QueryPage;
    search: string | null;
  };
  onPaginationChange: (paging: QueryPage) => void;
};

function CareUnitTable(props: Props): ReactElement {
  const { onPaginationChange } = props;

  const { remoteData } = apolloQueryHookWrapper(
    useCareGroupListQueryQuery({
      variables: {
        ...props.perspective.paging,
        search: props.perspective.search,
        careUnitTypeMnemonic: "family",
      },
    })
  );

  const renderBody = (l: ReadonlyArray<React.ComponentProps<typeof CareUnitRow>["careUnit"]>) => {
    return (
      <TableBody>
        {l.map((careUnit) => (
          <CareUnitRow key={careUnit.id.toString()} careUnit={careUnit} />
        ))}
      </TableBody>
    );
  };

  const [paginator, body] = remoteData.caseOf<[ReactElement | null, ReactElement]>({
    NotAsked: () => [null, <CareUnitRowsLoading />],
    Loading: () => [null, <CareUnitRowsLoading />],
    Success: (data) => {
      if (data.careGroups) {
        const paginator = (
          <Paginator
            pagination={props.perspective.paging}
            pageInfo={data.careGroups.pageInfo}
            onChange={onPaginationChange}
          />
        );

        return [paginator, renderBody(data.careGroups.nodes)];
      }
      return [null, <TableBody>Error</TableBody>];
    },
    Failure: (_e) => [null, <TableBody>Error</TableBody>],
  });

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>

        {body}
      </Table>

      {paginator}
    </TableContainer>
  );
}

export default CareUnitTable;
