import { Stack, Typography, Box, Grid, TextField } from "@mui/material";
import { RichText } from "MDS/RichText";
import React, { BaseSyntheticEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { NoteDetails } from "./TaskCard";
import { useIsMobile } from "Shared/Responsive";
import { ExpandingIconButton } from "MDS/ExpandingIconButton";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import CreateIcon from "@mui/icons-material/Create";
import { refetchQueries } from "Lib/RefetchQueries";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useDeleteTaskNoteMutation, useEditTaskNoteMutation } from "GeneratedGraphQL/SchemaAndOperations";
import AddCommentIcon from "@mui/icons-material/AddComment";

export function EditableTaskCardNote(props: { note: NoteDetails }): ReactElement {
  const [editingNote, setEditingNote] = useState<boolean>(false);
  const columns = useIsMobile() ? 6 : 12;
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const originalText = props.note.text;
  const [newText, setNewText] = useState<string>(originalText);

  const [updateNote, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareEditNote,
    useEditTaskNoteMutation({
      variables: { input: { noteId: props.note.id, text: newText } },
      refetchQueries: refetchQueries("tasks"),
    })
  );

  const [deleteNote] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareDeleteNote,
    useDeleteTaskNoteMutation({
      variables: { input: { noteId: props.note.id } },
      refetchQueries: refetchQueries("tasks"),
    })
  );

  remoteData.caseOf({
    NotAsked: () => [],
    Loading: () => [],
    Failure: () => {
      return [];
    },
    Success: (response) => {
      if (response?.note.text && !editingNote && newText != response.note.text) {
        setNewText(response.note.text);
      }
      return [];
    },
  });

  const toDelete = newText.length == 0;
  const updateInProgress = remoteData.kind === "Loading";

  const exitEditingAndSaveHandler = function () {
    if (toDelete) {
      deleteNote();
    } else if (newText != originalText) {
      updateNote();
    }
    setEditingNote(false);
  };

  const exitEditingWithoutSavingHandler = function () {
    setEditingNote(false);
    setNewText(originalText);
  };

  const textChangeHandler = function (e: BaseSyntheticEvent) {
    setNewText(e.target.value);
  };

  let noteBlock = (
    <>
      <Grid item xs={8}>
        <Box border={`1px solid`} borderRadius={0.5} padding={1} overflow="auto">
          <RichText>{newText}</RichText>
        </Box>
      </Grid>
    </>
  );
  let actionBlock = (
    <Grid item xs={4}>
      <Stack direction="row" spacing={1}>
        <ExpandingIconButton
          color="primary"
          variant="outlined"
          icon={<CreateIcon />}
          onClick={() => {
            setEditingNote(true);
          }}
        >
          {t("common:actions.edit")}
        </ExpandingIconButton>
      </Stack>
    </Grid>
  );
  if (editingNote) {
    noteBlock = (
      <Grid item xs={8}>
        <TextField
          fullWidth={true}
          autoFocus={true}
          multiline={true}
          onChange={textChangeHandler}
          disabled={updateInProgress}
          value={newText}
          helperText={toDelete ? t("collaborativeCare:tasks.noteWillBeDeleted") : null}
        >
          {props.note.text}
        </TextField>
      </Grid>
    );
    actionBlock = (
      <Grid item xs={4}>
        <Stack direction="row" spacing={1}>
          <ExpandingIconButton
            color="primary"
            variant="outlined"
            icon={<CheckIcon />}
            onClick={exitEditingAndSaveHandler}
          >
            {t("common:actions.save")}
          </ExpandingIconButton>
          <ExpandingIconButton
            color="primary"
            variant="outlined"
            icon={<CancelIcon />}
            onClick={exitEditingWithoutSavingHandler}
          >
            {t("common:actions.cancel")}
          </ExpandingIconButton>
        </Stack>
      </Grid>
    );
  }
  const forTimeEntry = props.note.timeEntry ? <>{t("collaborativeCare:tasks.details.forTime")} </> : <></>;
  const icon = props.note.timeEntry ? <AddCommentIcon /> : <></>;
  return (
    <Grid container columns={columns} spacing={1}>
      {icon}
      <Typography>
        {props.note.author.name}, {forTimeEntry}
        {t("common:date.long", { date: props.note.timeEntry?.startTime || props.note.createdAt })}
      </Typography>
      {noteBlock}
      {actionBlock}
    </Grid>
  );
}
