import { EntitySummary, EntityTreeNodeDetails } from "Entities/EntityPath";
import { EntityTreeNodeParams, EntityType } from "GeneratedGraphQL/SchemaAndOperations";
import { EntityId, InstituteId } from "Lib/Ids";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Maybe } from "seidr";
import { usePiiLevel } from "./PiiLevelContext";
import CurrentInstituteContext from "./CurrentInstituteContext";
import { unsafeFromUuid } from "Lib/Id";

const ROOT_UUID = "00000000-0000-0000-0000-000000000001";

// This is a special entity that doesn't really exist but just represents 'all data'
const ROOT_ENTITY: EntitySummary = {
  entityType: EntityType.ROOT,
  // For expedience don't translate this for now as we only need to support english
  name: "All Data",
  shortname: "All Data",
  id: unsafeFromUuid(ROOT_UUID),
};

export const CurrentInstituteIdContext: React.Context<Maybe<{ id: InstituteId; name: string }>> =
  React.createContext(Maybe.fromNullable<{ id: InstituteId; name: string }>(null));

export function useCurrentInstituteId(): InstituteId {
  const maybeInstituteId = useContext(CurrentInstituteIdContext);
  const instituteId = maybeInstituteId.caseOf({
    Nothing: () => {
      throw new Error("No current institute!");
    },
    Just: (institute) => institute.id,
  });
  return instituteId;
}

/**
 * Get the current institute id
 * @returns the current institute id as an entity id for use in e.g. path selection
 * @throws an error if you somehow have not even bootstrapped the application
 */
export function useCurrentInstituteEntity(): EntitySummary {
  const { t } = useTranslation(["settings"]);
  const maybeInstituteId = useContext(CurrentInstituteIdContext);
  const isAnonymous = usePiiLevel() === "full_anon";
  const instituteId = maybeInstituteId.caseOf({
    Nothing: () => {
      throw new Error("No current institute!");
    },
    Just: (institute) => {
      return {
        id: institute.id as unknown as EntityId,
        name: isAnonymous ? t("settings:piiLevel.anonInstitute") : institute.name,
        shortname: isAnonymous ? t("settings:piiLevel.anonInstitute") : institute.name,
        entityType: EntityType.INSTITUTE,
      };
    },
  });
  return instituteId;
}

export function useCurrentRootNode(): [EntityTreeNodeParams, EntityTreeNodeDetails] {
  const maybeInstitute = useContext(CurrentInstituteContext);
  const instituteEntity = useCurrentInstituteEntity();

  return maybeInstitute.caseOf({
    Nothing: () => {
      throw new Error("No current institute!");
    },
    Just: (institute) => {
      if (institute.supportsInstituteGroups) {
        return [{ root: true }, { entity: ROOT_ENTITY, segments: [], path: "/", minAge: null, maxAge: null }];
      } else {
        return [
          { path: `institute/${institute.id.toString()}` },
          {
            entity: instituteEntity,
            segments: [],
            path: "institute/" + institute.id,
            minAge: null,
            maxAge: null,
          },
        ];
      }
    },
  });
}

export default CurrentInstituteIdContext;
