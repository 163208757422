import {
  AuthenticatedProviderUserContext,
  providerUserHasAnyPermission,
  providerUserHasAnyRole,
  providerUserHasPermission,
} from "AppSession/AuthenticatedProviderUser";
import React, { ReactElement } from "react";

export function useProviderUserHasPermission(permission: string): boolean {
  const user = React.useContext(AuthenticatedProviderUserContext);

  if (user === undefined) {
    return false;
  }

  return providerUserHasPermission(user, permission);
}

export function useProviderUserHasAnyPermission(permissions: ReadonlyArray<string>): boolean {
  const user = React.useContext(AuthenticatedProviderUserContext);

  if (user === undefined) {
    return false;
  }

  return providerUserHasAnyPermission(user, permissions);
}

// Returns its children if the current user has the listed permission (regardless of scope), or if the current user
// is internal. Basically a component version of the `hasPermission` function on ember's session-user.
export function WithPermission(props: React.PropsWithChildren<{ permission: string }>): ReactElement | null {
  const user = React.useContext(AuthenticatedProviderUserContext);

  if (user === undefined) {
    return null;
  }

  if (providerUserHasPermission(user, props.permission)) {
    return <>{props.children}</>;
  }

  return null;
}

export function WithoutPermission(
  props: React.PropsWithChildren<{ permission: string }>
): ReactElement | null {
  const user = React.useContext(AuthenticatedProviderUserContext);

  if (user === undefined) {
    return <>{props.children}</>;
  }

  if (providerUserHasPermission(user, props.permission)) {
    return null;
  }

  return <>{props.children}</>;
}

// Returns its children if the current user has the listed permission (regardless of scope), or if the current user
// is internal. Basically a component version of the `hasPermission` function on ember's session-user.
export function WithAnyPermission(
  props: React.PropsWithChildren<{ permissions: ReadonlyArray<string> }>
): ReactElement | null {
  const user = React.useContext(AuthenticatedProviderUserContext);

  if (user === undefined) {
    return null;
  }

  if (providerUserHasAnyPermission(user, props.permissions) || user.userType === "internal") {
    return <>{props.children}</>;
  }

  return null;
}

export function WithoutAnyPermission(
  props: React.PropsWithChildren<{ permissions: ReadonlyArray<string> }>
): ReactElement | null {
  const user = React.useContext(AuthenticatedProviderUserContext);

  if (user === undefined) {
    return <>{props.children}</>;
  }

  if (providerUserHasAnyPermission(user, props.permissions) || user.userType === "internal") {
    return null;
  }

  return <>{props.children}</>;
}

/**
 * Run the given block if the user has a given role OR the user is a internal user
 */
export function WithRole(props: React.PropsWithChildren<{ role: string }>): ReactElement | null {
  const user = React.useContext(AuthenticatedProviderUserContext);

  if (user === undefined) {
    return null;
  }

  if (user.roles.includes(props.role) || user.userType === "internal") {
    return <>{props.children}</>;
  }

  return null;
}

/**
 * Run the given block if the user has a given role OR the user is a internal user
 */
export function WithAnyRole(
  props: React.PropsWithChildren<{ roles: ReadonlyArray<string> }>
): ReactElement | null {
  const user = React.useContext(AuthenticatedProviderUserContext);

  if (user === undefined) {
    return null;
  }

  if (providerUserHasAnyRole(user, props.roles)) {
    return <>{props.children}</>;
  }

  return null;
}

/**
 * Run the given block if the user does not have a given role and is not an internal user
 */
export function WithoutRole(props: React.PropsWithChildren<{ role: string }>): ReactElement | null {
  const user = React.useContext(AuthenticatedProviderUserContext);

  if (user === undefined) {
    return null;
  }

  if (user.roles.includes(props.role) || user.userType === "internal") {
    return null;
  }

  return <>{props.children}</>;
}

/**
 * Run the given block if the user does not have a given role and is not an internal user
 */
export function WithoutAnyRole(
  props: React.PropsWithChildren<{ roles: ReadonlyArray<string> }>
): ReactElement | null {
  const user = React.useContext(AuthenticatedProviderUserContext);

  if (user === undefined) {
    return null;
  }

  if (providerUserHasAnyRole(user, props.roles)) {
    return null;
  }

  return <>{props.children}</>;
}
