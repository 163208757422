import React, { ChangeEvent, ReactElement } from "react";
import {
  OrganizationSettingsQuery,
  Timezone,
  useOrganizationSettingsQuery,
  useUpdateOrganizationSettingsMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { OrganizationId } from "Lib/Ids";
import { Loading, RemoteData } from "seidr";
import { ApolloError } from "@apollo/client";
import DefaultLoading from "Settings/DefaultLoading";
import DefaultError from "Settings/DefaultError";
import Page from "Layout/Page";
import UnderMeasurementChip from "Ops/Institutes/InstituteDetails/UnderMeasurementChip";
import OrganizationMeasurementAllowedSwitch from "./Settings/OrganizationMeasurementAllowedSwitch";
import OrganizationTrialPeriodSwitch from "./Settings/OrganizationTrialPeriodSwitch";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useParams } from "react-router-dom";
import * as Id from "Lib/Id";
import { useTranslation } from "react-i18next";
import { refetchQueries } from "Lib/RefetchQueries";

type OrganizationSettingsProps = { id: OrganizationId };

function OrganizationSettingsRoute(): ReactElement {
  const { t } = useTranslation(["settings"]);
  const { organizationId } = useParams<{ organizationId?: string }>();

  return Id.fromNullableString<"Organization">(organizationId).caseOf({
    Err: () => <DefaultError error={t("settings:organization.error")} />,
    Ok: (id) => <OrganizationSettingsHookWrapper id={id} />,
  });
}

function OrganizationSettingsHookWrapper(props: OrganizationSettingsProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useOrganizationSettingsQuery({
      variables: {
        id: props.id,
      },
    })
  );

  return OrganizationSettings({ remoteData });
}

type OrganizationSettingsDataProps = {
  remoteData: RemoteData<ApolloError, OrganizationSettingsQuery>;
};

function OrganizationSettings(props: OrganizationSettingsDataProps): ReactElement {
  const { t } = useTranslation(["settings"]);
  return props.remoteData.caseOf({
    Loading: () => <DefaultLoading />,
    NotAsked: () => <DefaultLoading />,
    Failure: (error) => <DefaultError error={error.message} />,
    Success: (result) => {
      if (result.organization && result.timezones) {
        return <OrganizationSettingsElement {...result.organization} timezones={result.timezones} />;
      }
      return <DefaultError error={t("settings:organization.error")} />;
    },
  });
}

type OrganizationSettingsElementProps = {
  id: OrganizationId;
  measurementAllowed: boolean;
  inTrialPeriod: boolean;
  name: string;
  timezone: string | null;
  timezones: ReadonlyArray<Pick<Timezone, "timezone">>;
};

// This page doesn't actually exist in the ember world, so there's nothing to actually link
// back to.
function OrganizationSettingsElement(props: OrganizationSettingsElementProps): ReactElement {
  const { t } = useTranslation(["settings"]);
  const title = t("settings:organization.title");

  // We can't use null for values here, which mean inherit on the server, so we'll do some sleight of hand.
  const [timezone, setTimezone] = React.useState<string>(props.timezone ? props.timezone : "inherit");
  const handleTimezoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTimezone(event.target.value);
  };
  const timezones = [
    <MenuItem key="inherit" value={"inherit"}>
      {t("settings:organization.timezone.inherit")}
    </MenuItem>,
  ].concat(
    props.timezones.map((tz) => {
      return (
        <MenuItem key={tz.timezone} value={tz.timezone}>
          {tz.timezone}
        </MenuItem>
      );
    })
  );

  const [updateOrganizationSettings, { remoteData }] = apolloMutationHookWrapper(
    (data) => data.settingsOrganizationUpdateOrganizationSettings,
    useUpdateOrganizationSettingsMutation({
      refetchQueries: refetchQueries("organizationSettings"),
      variables: {
        input: {
          organizationId: props.id,
          timezone: timezone == "inherit" ? null : timezone,
        },
      },
    })
  );

  const handleSubmit = () => {
    updateOrganizationSettings();
  };

  return (
    <Page browserTitle={title}>
      <Stack direction="column" spacing={1}>
        <Card>
          <CardHeader
            title={props.name}
            subheader={props.id.toString()}
            action={<UnderMeasurementChip measurementAllowed={props.measurementAllowed} />}
          ></CardHeader>
        </Card>
        <Card>
          <CardHeader
            title={t("settings:organization.switchesLabel")}
            subheader={t("settings:organization.switchesSublabel")}
          ></CardHeader>
          <CardContent>
            <Stack spacing={1} alignItems="start">
              <OrganizationMeasurementAllowedSwitch
                id={props.id}
                measurementAllowed={props.measurementAllowed}
              />
              <OrganizationTrialPeriodSwitch id={props.id} inTrialPeriod={props.inTrialPeriod} />
            </Stack>
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            title={t("settings:organization.settingsLabel")}
            subheader={t("settings:organization.settingsSublabel")}
          ></CardHeader>
          <CardContent>
            <Stack spacing={1} alignItems="start" marginBottom={"1em"}>
              <Box width={300}>
                <FormControl fullWidth>
                  <InputLabel id="timezone-select-label">
                    {t("settings:organization.timezone.timezone")}
                  </InputLabel>
                  <Select
                    labelId="timezone-select-label"
                    id="timezone-select"
                    value={timezone}
                    label={t("settings:organization.timezone.timezone")}
                    onChange={handleTimezoneChange}
                  >
                    {timezones}
                  </Select>
                </FormControl>
              </Box>
            </Stack>
            <CardActions sx={{ paddingLeft: 0 }}>
              <Button
                size="medium"
                variant="contained"
                onClick={handleSubmit}
                disabled={remoteData.equals(Loading())}
              >
                {t("settings:organization.settingsSubmit")}
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </Stack>
    </Page>
  );
}

export default OrganizationSettingsRoute;
export { OrganizationSettingsHookWrapper as HookWrapper, OrganizationSettings as Component };
