import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { Link } from "MDS/Link";
import React, { ReactElement } from "react";
import { AppLayoutNavigationItem } from "../AppLayout";
import { Box, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { usePiiLevel } from "Contexts/PiiLevelContext";

type NavInstituteMenuProps = {
  instituteName: string;
  instituteNavItems: Array<AppLayoutNavigationItem>;
};

const NavUserItem = styled(Box)(({ theme }) => ({
  margin: `0 ${theme.spacing(1)}`,
  flexGrow: 0,
}));

export function NavInstituteMenu(props: NavInstituteMenuProps): ReactElement {
  const { t } = useTranslation(["settings"]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const instituteName =
    usePiiLevel() === "full_anon" ? t("settings:piiLevel.anonInstitute") : props.instituteName;

  return (
    <>
      <NavUserItem onClick={handleClick}>
        <Stack direction="row">
          <Typography variant="h2">{instituteName}</Typography>
          <ArrowDropDownIcon />
        </Stack>
      </NavUserItem>

      <Menu
        transitionDuration={0}
        elevation={2}
        variant="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {props.instituteNavItems.map((navItem, index) => (
          <Link
            key={`${index}-${navItem.location}-${navItem.label}`}
            to={navItem.location || ""}
            sx={{ textDecoration: "none" }}
            onClick={handleClose}
          >
            <MenuItem>{navItem.label}</MenuItem>
          </Link>
        ))}
      </Menu>
    </>
  );
}
