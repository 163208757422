import { Add, Cancel, Check, Menu as MenuIcon, PersonAdd } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { useCurrentProviderId } from "AppSession/AppSession";
import { Section } from "CollaborativeCare/CaseConsult/Section";
import { panelTeamMemberFunctionT } from "GeneratedGraphQL/EnumTranslations";
import {
  Panel,
  PanelStatus,
  PanelTeamMember,
  PanelTeamMemberFunction,
  Provider,
  useCreatePanelMutation,
  useCreatePanelTeamMemberMutation,
  useEditPanelMutation,
  useEditPanelTeamMemberMutation,
  usePanelsListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { ProviderId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { LinkButton } from "MDS/Link";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import EnumSelect from "Shared/EnumSelect";
import ErrorMessage from "Shared/ErrorMessage";
import ProviderSelectSingle from "Shared/Filters/ProviderSelectSingle";
import { Form, FormOverlay, useForm, useTextField, useWrappedField } from "Shared/Form";
import { OnDesktop, OnMobile } from "Shared/Responsive";
import Spinner from "Shared/Spinner";
import { WithPermission } from "Shared/WithPermission";
import React, { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { panelContainsProvider } from "./Panel";

export function PanelsPage() {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const { remoteData } = apolloQueryHookWrapper(usePanelsListQuery());

  const currentProviderId = useCurrentProviderId();

  const [myPanels, activePanels, retiredPanels, providersWarning] = remoteData.caseOf<
    [ReactNode, ReactNode, ReactNode, ReactNode]
  >({
    NotAsked: () => [<PanelCardsLoading />, <PanelCardsLoading />, <PanelCardsLoading />, null],
    Loading: () => [<PanelCardsLoading />, <PanelCardsLoading />, <PanelCardsLoading />, null],
    // Only showing the error message in the top-most section, hopefully it'll be clear that it applies to all of them.
    // Maybe we could have a fourth return value for a top level error slot.
    Failure: (err) => [<ErrorMessage message={err.message} />, null, null, null],
    Success: (response) => {
      if (response.collaborativeCarePanels && response.providers) {
        const allPanels = response.collaborativeCarePanels.nodes;
        const retiredPanels = allPanels.filter((panel) => panel.status === PanelStatus.RETIRED);
        const allActivePanels = allPanels.filter((panel) => panel.status === PanelStatus.ACTIVE);
        const myActivePanels = allActivePanels.filter((panel) =>
          panelContainsProvider(panel, currentProviderId)
        );
        const otherActivePanels = allActivePanels.filter(
          (panel) => !panelContainsProvider(panel, currentProviderId)
        );

        const allEmpaneledProviders = allPanels
          .flatMap((panel) => panel.teamMembers)
          .map((teamMember) => teamMember.provider.id);
        const nonEmpaneledProviders = response.providers.nodes.filter(
          (provider) => !allEmpaneledProviders.includes(provider.id)
        );

        return [
          <PanelCards
            panels={myActivePanels}
            noPanelsMessage={t("collaborativeCare:panels.myPanels.noPanels")}
          />,
          <PanelCards
            panels={otherActivePanels}
            noPanelsMessage={t("collaborativeCare:panels.activePanels.noPanels")}
          />,
          <PanelCards
            panels={retiredPanels}
            noPanelsMessage={t("collaborativeCare:panels.retiredPanels.noPanels")}
          />,
          <NonEmpaneledProviders providers={nonEmpaneledProviders} />,
        ];
      } else {
        return [<ErrorMessage message={t("collaborativeCare:panels.genericLoadError")} />, null, null, null];
      }
    },
  });

  return (
    <Page browserTitle={t("collaborativeCare:panels.title")}>
      <Stack direction="column" spacing={1}>
        {providersWarning}
        <Stack direction="row" spacing={3}>
          <CreatePanelForm />
          <WithPermission permission="editProviders">
            <LinkButton
              variant="outlined"
              color="secondary"
              startIcon={<PersonAdd />}
              to="/provider/admin/users/create"
            >
              {t("common:providers.create")}
            </LinkButton>
          </WithPermission>
        </Stack>
        <Section title={t("collaborativeCare:panels.myPanels.title")}>{myPanels}</Section>
        <Section title={t("collaborativeCare:panels.activePanels.title")}>{activePanels}</Section>
        <Section title={t("collaborativeCare:panels.retiredPanels.title")}>{retiredPanels}</Section>
      </Stack>
    </Page>
  );
}

function PanelCardsLoading(): ReactElement {
  return (
    <Stack direction="column" alignItems="center" marginTop={3}>
      <Spinner />
    </Stack>
  );
}

function CreatePanelForm(): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const theme = useTheme();

  const [createPanel, { remoteData, reset: resetMutation }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareCreatePanel,
    useCreatePanelMutation({
      refetchQueries: refetchQueries("panels"),
    })
  );

  const fields = {
    name: useTextField({ default: undefined, required: false, errorPaths: ["name"] }),
  };

  const form = useForm({
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      createPanel({
        variables: {
          input: {
            name: fields.name.value,
          },
        },
      });
    },
    onSuccess: () => {
      setTimeout(fullResetForm, 500);
    },
  });

  const [showForm, setShowForm] = React.useState(false);

  const fullResetForm = () => {
    setShowForm(false);
    resetMutation();
    form.reset();
  };

  if (showForm) {
    return (
      <Form onSubmit={form.onSubmit} sx={{ maxWidth: theme.spacing(25) }}>
        <Typography variant="h2">{t("collaborativeCare:panels.createPanel")}</Typography>
        <FormOverlay
          response={remoteData}
          errorMessage={form.globalError || t("collaborativeCare:panels.genericSaveError")}
        />
        <Stack direction="column" spacing={1}>
          <TextField
            value={fields.name.value}
            onChange={fields.name.onChange}
            error={fields.name.error}
            helperText={fields.name.helperText}
            label={t("collaborativeCare:panels.fields.panelName")}
            sx={{ backgroundColor: theme.palette.background.paper }}
          />
          <Stack direction="row" spacing={1}>
            <Button color="secondary" variant="contained" type="submit" startIcon={<Check />}>
              {t("common:actions.save")}
            </Button>
            <Button color="secondary" variant="outlined" onClick={fullResetForm} startIcon={<Cancel />}>
              {t("common:actions.cancel")}
            </Button>
          </Stack>
        </Stack>
      </Form>
    );
  } else {
    return (
      <Stack direction="row">
        <Button color="secondary" variant="outlined" startIcon={<Add />} onClick={() => setShowForm(true)}>
          {t("collaborativeCare:panels.createPanel")}
        </Button>
      </Stack>
    );
  }
}

type NamedProvider = PickTypename<Provider, "id" | "name">;
type TeamMemberDetails = PickTypename<PanelTeamMember, "id" | "function" | "startDate" | "endDate"> & {
  provider: NamedProvider;
};
type PanelDetails = PickTypename<Panel, "id" | "name" | "status"> & {
  teamMembers: ReadonlyArray<TeamMemberDetails>;
};

type PanelCardsProps = {
  panels: ReadonlyArray<PanelDetails>;
  noPanelsMessage: string;
};

function PanelCards(props: PanelCardsProps): ReactElement {
  // Keep the panels in a stable position no matter what the database decides to do.
  const sortedPanels = [...props.panels];
  sortedPanels.sort((left, right) => left.name.localeCompare(right.name));

  const noPanels = (
    <Stack direction="column" alignItems="center">
      <Typography variant="h3">{props.noPanelsMessage}</Typography>
    </Stack>
  );

  return (
    <Stack direction="column" spacing={1} marginTop={1}>
      {props.panels.map((panel) => (
        <PanelCard panel={panel} key={panel.id.toString()} />
      ))}
      {props.panels.length === 0 ? noPanels : null}
    </Stack>
  );
}

type PanelCardProps = {
  panel: PanelDetails;
};

function PanelCard(props: PanelCardProps): ReactElement {
  const [editingName, setEditingName] = React.useState(false);

  // At the time of writing this table is only three columns and seems to be small enough to shrink down to a phone
  // screen acceptably, so I'm not going to make a dedicated mobile view for this. Revisit this decision if we add
  // more information/functionality here.

  return (
    <Card>
      <CardHeader
        title={<EditablePanelName panel={props.panel} editing={editingName} setEditing={setEditingName} />}
        action={<PanelCardMenu panel={props.panel} onEditName={() => setEditingName(true)} />}
      />
      <CardContent>
        <Stack direction="column" spacing={3}>
          <TeamMembersTable teamMembers={props.panel.teamMembers} />
          {/* Extra stack so the button doesn't stretch to full width */}
          <Stack direction="row">
            <AddTeamMemberForm panel={props.panel} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

type TeamMembersTableProps = {
  teamMembers: ReadonlyArray<TeamMemberDetails>;
};

function TeamMembersTable(props: TeamMembersTableProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common", "enums"]);

  const sortedTeamMembers = [...props.teamMembers];
  // Sort first by name and then by start date, so if the same person has multiple jobs or multiple engagements with
  // the same panel they get sorted chronologically.
  sortedTeamMembers.sort((left, right) => {
    const nameCompare = left.provider.name.localeCompare(right.provider.name);
    if (nameCompare !== 0) {
      return nameCompare;
    }

    return left.startDate < right.startDate ? -1 : 1;
  });

  const noTeamMembers = (
    <TableRow>
      <TableCell colSpan={3}>
        <Typography textAlign="center" display="block">
          {t("collaborativeCare:panels.teamMembers.noMembers")}
        </Typography>
      </TableCell>
    </TableRow>
  );

  return (
    <Table>
      <TeamMemberTableHeader />
      <TableBody>
        {sortedTeamMembers.map((teamMember) => (
          <TeamMemberTableRow teamMember={teamMember} key={teamMember.id.toString()} />
        ))}
        {sortedTeamMembers.length === 0 ? noTeamMembers : null}
      </TableBody>
    </Table>
  );
}

function TeamMemberTableHeader() {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <TableHead>
      <OnDesktop>
        <TableRow>
          <TableCell>
            <Typography fontWeight="bold">{t("collaborativeCare:panels.teamMembers.name")}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight="bold">{t("collaborativeCare:panels.teamMembers.function")}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight="bold">{t("collaborativeCare:panels.teamMembers.dates")}</Typography>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </OnDesktop>
    </TableHead>
  );
}

type TeamMemberRowProps = {
  teamMember: TeamMemberDetails;
};

function TeamMemberTableRow(props: TeamMemberRowProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common", "enums"]);

  const endDate = props.teamMember.endDate
    ? t("common:date.medium", { date: props.teamMember.endDate })
    : "present";

  return (
    <TableRow>
      <OnDesktop>
        <TableCell>{props.teamMember.provider.name}</TableCell>
        <TableCell>{panelTeamMemberFunctionT(props.teamMember.function, t)}</TableCell>
        <TableCell>
          {t("common:date.medium", { date: props.teamMember.startDate })} - {endDate}
        </TableCell>
        <TableCell>
          <EditTeamMemberForm teamMember={props.teamMember} />
        </TableCell>
      </OnDesktop>
      <OnMobile>
        <TableCell>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography fontWeight="bold">{t("collaborativeCare:panels.teamMembers.name")}</Typography>
            </Grid>
            <Grid item xs={7}>
              {props.teamMember.provider.name}
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold">{t("collaborativeCare:panels.teamMembers.function")}</Typography>
            </Grid>
            <Grid item xs={7}>
              {panelTeamMemberFunctionT(props.teamMember.function, t)}
            </Grid>
            <Grid item xs={5}>
              <Typography fontWeight="bold">{t("collaborativeCare:panels.teamMembers.dates")}</Typography>
            </Grid>
            <Grid item xs={7}>
              {t("common:date.medium", { date: props.teamMember.startDate })} - {endDate}
            </Grid>
            <Grid item xs={12}>
              <EditTeamMemberForm teamMember={props.teamMember} />
            </Grid>
          </Grid>
        </TableCell>
      </OnMobile>
    </TableRow>
  );
}

type EditTeamMemberFormProps = {
  teamMember: TeamMemberDetails;
};

function EditTeamMemberForm(props: EditTeamMemberFormProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common", "enums"]);
  const [showForm, setShowForm] = React.useState(false);

  const [editTeamMember, { remoteData, reset: resetMutation }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareEditPanelTeamMember,
    useEditPanelTeamMemberMutation()
  );

  const fields = {
    endDate: useWrappedField<Date>({
      default: props.teamMember.endDate || undefined,
      required: false,
      errorPaths: ["end_date"],
    }),
    startDate: useWrappedField<Date>({
      default: props.teamMember.startDate,
      required: true,
      errorPaths: ["start_date"],
    }),
  };

  const form = useForm({
    id: "deactivate-panel-team-member-form",
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      editTeamMember({
        variables: {
          input: {
            teamMemberId: props.teamMember.id,
            endDate: fields.endDate.value,
            // Really we should never get to the right hand side of this, the start date field is required so the form
            // won't submit without it.
            startDate: fields.startDate.value || props.teamMember.startDate,
          },
        },
      });
    },
    onSuccess: () => {
      setTimeout(fullReset, 500);
    },
  });

  const fullReset = () => {
    setShowForm(false);
    resetMutation();
    form.reset();
  };

  const endDateField = (
    <DatePicker
      value={fields.endDate.value}
      onChange={fields.endDate.onChange}
      slotProps={{
        textField: {
          error: fields.endDate.error,
          helperText: fields.endDate.helperText,
        },
      }}
      label={t("collaborativeCare:panels.teamMembers.endDate")}
    />
  );

  const startDateField = (
    <DatePicker
      value={fields.startDate.value}
      onChange={fields.startDate.onChange}
      slotProps={{
        textField: {
          error: fields.startDate.error,
          helperText: fields.startDate.helperText,
        },
      }}
      label={t("collaborativeCare:panels.teamMembers.startDate")}
    />
  );

  if (showForm) {
    return (
      <>
        <OnDesktop>
          <Form onSubmit={form.onSubmit}>
            <FormOverlay
              response={remoteData}
              errorMessage={form.globalError || t("collaborativeCare:panels.teamMembers.genericSaveError")}
            />
            <Stack direction="row" spacing={1} alignItems="center">
              {startDateField}
              {endDateField}
              <Button variant="contained" color="secondary" startIcon={<Check />} type="submit">
                {t("common:actions.save")}
              </Button>
              <Button variant="outlined" color="secondary" startIcon={<Cancel />} onClick={fullReset}>
                {t("common:actions.cancel")}
              </Button>
            </Stack>
          </Form>
        </OnDesktop>
        <OnMobile>
          <ResponsiveDialog
            open={showForm}
            title={t("collaborativeCare:panels.teamMembers.actions.deactivatePerson", {
              name: props.teamMember.provider.name,
            })}
            onClose={fullReset}
          >
            <DialogContent>
              <Form id={form.id} onSubmit={form.onSubmit}>
                <Stack direction="column" spacing={1}>
                  <Typography>{props.teamMember.provider.name}</Typography>
                  <Typography>{panelTeamMemberFunctionT(props.teamMember.function, t)}</Typography>
                  <Typography>
                    {t("collaborativeCare:panels.teamMembers.startDate")}{" "}
                    {t("common:date.medium", { date: props.teamMember.startDate })}
                  </Typography>
                  {startDateField}
                  {endDateField}
                </Stack>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<Check />}
                type="submit"
                form={form.id}
              >
                {t("common:actions.save")}
              </Button>
              <Button variant="outlined" color="secondary" startIcon={<Cancel />} onClick={fullReset}>
                {t("common:actions.cancel")}
              </Button>
            </DialogActions>
          </ResponsiveDialog>
        </OnMobile>
      </>
    );
  } else {
    return (
      <Button variant="outlined" color="secondary" onClick={() => setShowForm(true)}>
        {t("common:actions.edit")}
      </Button>
    );
  }
}

type PanelCardMenuProps = {
  panel: PickTypename<Panel, "id" | "name" | "status">;
  onEditName: () => void;
};

function PanelCardMenu(props: PanelCardMenuProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // I'm not thrilled with throwing away the response here and dropping any error messages on the ground, but we don't
  // a UI pattern for showing errors when you aren't in a form, so I'm not sure what else to do here.
  const [editPanel, _] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareEditPanel,
    useEditPanelMutation()
  );

  const retirePanel = (
    <MenuItem
      onClick={() => {
        editPanel({
          variables: {
            input: {
              panelId: props.panel.id,
              name: props.panel.name,
              status: PanelStatus.RETIRED,
            },
          },
        });
      }}
    >
      {t("collaborativeCare:panels.actions.retire")}
    </MenuItem>
  );
  const activatePanel = (
    <MenuItem
      onClick={() => {
        editPanel({
          variables: {
            input: {
              panelId: props.panel.id,
              name: props.panel.name,
              status: PanelStatus.ACTIVE,
            },
          },
        });
      }}
    >
      {t("collaborativeCare:panels.actions.unretire")}
    </MenuItem>
  );

  return (
    <>
      <IconButton onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            props.onEditName();
            handleClose();
          }}
        >
          {t("collaborativeCare:panels.actions.editName")}
        </MenuItem>
        {props.panel.status === PanelStatus.ACTIVE ? retirePanel : activatePanel}
      </Menu>
    </>
  );
}

type EditablePanelNameProps = {
  panel: PickTypename<Panel, "id" | "name" | "status">;
  editing: boolean;
  setEditing: (editing: boolean) => void;
};

function EditablePanelName(props: EditablePanelNameProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const [editPanel, { remoteData, reset: resetMutation }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareEditPanel,
    useEditPanelMutation()
  );

  const fields = {
    name: useTextField({ default: props.panel.name, required: false, errorPaths: ["name"] }),
  };

  const form = useForm({
    id: "edit-panel-name-form",
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      editPanel({
        variables: {
          input: {
            panelId: props.panel.id,
            name: fields.name.value,
            status: props.panel.status,
          },
        },
      });
    },
    onSuccess: () => {
      setTimeout(closeForm, 500);
    },
  });

  const closeForm = () => {
    props.setEditing(false);
    resetMutation();
    form.reset();
  };

  if (props.editing) {
    return (
      <>
        <OnMobile>
          <ResponsiveDialog
            open={props.editing}
            onClose={() => props.setEditing(false)}
            title={t("collaborativeCare:panels.createPanel")}
          >
            <DialogContent>
              <Form onSubmit={form.onSubmit} id={form.id}>
                <FormOverlay
                  response={remoteData}
                  errorMessage={form.globalError || t("collaborativeCare:panels.genericSaveError")}
                />
                <TextField
                  label={t("collaborativeCare:panels.fields.panelName")}
                  value={fields.name.value}
                  onChange={fields.name.onChange}
                  error={fields.name.error}
                  helperText={fields.name.helperText}
                  fullWidth
                />
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                variant="contained"
                startIcon={<Check />}
                type="submit"
                form={form.id}
              >
                {t("common:actions.save")}
              </Button>
              <Button color="secondary" variant="outlined" startIcon={<Cancel />} onClick={closeForm}>
                {t("common:actions.cancel")}
              </Button>
            </DialogActions>
          </ResponsiveDialog>
        </OnMobile>
        <OnDesktop>
          <Form onSubmit={form.onSubmit}>
            <FormOverlay
              response={remoteData}
              errorMessage={form.globalError || t("collaborativeCare:panels.genericSaveError")}
            />
            <Stack direction="row" spacing={1} alignItems="center">
              <TextField
                label={t("collaborativeCare:panels.fields.panelName")}
                value={fields.name.value}
                onChange={fields.name.onChange}
                error={fields.name.error}
                helperText={fields.name.helperText}
                sx={{ width: "20rem" }}
              />
              <Button color="secondary" variant="contained" startIcon={<Check />} type="submit">
                {t("common:actions.save")}
              </Button>
              <Button color="secondary" variant="outlined" startIcon={<Cancel />} onClick={closeForm}>
                {t("common:actions.cancel")}
              </Button>
            </Stack>
          </Form>
        </OnDesktop>
      </>
    );
  } else {
    return <span>{props.panel.name}</span>;
  }
}

type AddTeamMemberFormProps = {
  panel: PickTypename<Panel, "id">;
};

function AddTeamMemberForm(props: AddTeamMemberFormProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const [showForm, setShowForm] = React.useState(false);

  const [createTeamMember, { remoteData, reset: resetMutation }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareCreatePanelTeamMember,
    useCreatePanelTeamMemberMutation()
  );

  // Memoize now so it doesn't change every time we render the component
  const now = React.useMemo(() => new Date(), []);

  const fields = {
    provider: useWrappedField<ProviderId | null>({
      default: undefined,
      required: true,
      errorPaths: ["provider_id", "provider"],
    }),
    function: useWrappedField<PanelTeamMemberFunction>({
      default: undefined,
      required: true,
      errorPaths: ["function"],
    }),
    startDate: useWrappedField<Date>({
      default: now,
      required: true,
      errorPaths: ["start_date"],
    }),
    endDate: useWrappedField<Date>({
      default: undefined,
      required: false,
      errorPaths: ["end_date"],
    }),
  };

  const form = useForm({
    id: "new-team-member-form",
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      createTeamMember({
        variables: {
          input: {
            panelId: props.panel.id,
            // Casts are safe because the fields are marked required
            providerId: fields.provider.value as ProviderId,
            function: fields.function.value as PanelTeamMemberFunction,
            startDate: fields.startDate.value,
            endDate: fields.endDate.value,
          },
        },
      });
    },
    onSuccess: () => {
      setTimeout(fullReset, 500);
    },
  });

  const fullReset = () => {
    setShowForm(false);
    form.reset();
    resetMutation();
  };

  const providerField = (
    <ProviderSelectSingle
      value={fields.provider.value || null}
      setValue={fields.provider.onChange}
      error={fields.provider.error}
      helperText={fields.provider.helperText}
      label={t("collaborativeCare:panels.teamMembers.name")}
    />
  );
  const functionField = (
    <EnumSelect
      value={fields.function.value || null}
      onChange={fields.function.onChange}
      error={fields.function.error}
      helperText={fields.function.helperText}
      optionsEnum={PanelTeamMemberFunction}
      enumTrans={panelTeamMemberFunctionT}
      title={t("collaborativeCare:panels.teamMembers.function")}
    />
  );
  const startField = (
    <DatePicker
      value={fields.startDate.value}
      onChange={fields.startDate.onChange}
      slotProps={{
        textField: {
          error: fields.startDate.error,
          helperText: fields.startDate.helperText,
        },
      }}
      label={t("collaborativeCare:panels.teamMembers.startDate")}
    />
  );
  const endField = (
    <DatePicker
      value={fields.endDate.value}
      onChange={fields.endDate.onChange}
      slotProps={{
        textField: {
          error: fields.endDate.error,
          helperText: fields.endDate.helperText,
        },
      }}
      label={t("collaborativeCare:panels.teamMembers.endDate")}
    />
  );

  if (showForm) {
    return (
      <>
        <OnDesktop>
          <Form onSubmit={form.onSubmit} sx={{ width: "100%" }}>
            <FormOverlay
              response={remoteData}
              errorMessage={form.globalError || t("collaborativeCare:panels.teamMembers.genericSaveError")}
            />
            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={1}>
                <Box flexBasis="17rem" flexGrow={1}>
                  {providerField}
                </Box>
                <Box flexBasis="17rem" flexGrow={1} flexShrink={0}>
                  {functionField}
                </Box>
                <Stack direction="row" spacing={1} flexBasis="30rem" flexGrow={1} flexShrink={0}>
                  {startField}
                  {endField}
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Button variant="contained" color="secondary" startIcon={<Check />} type="submit">
                  {t("common:actions.save")}
                </Button>
                <Button variant="outlined" color="secondary" startIcon={<Cancel />} onClick={fullReset}>
                  {t("common:actions.cancel")}
                </Button>
              </Stack>
            </Stack>
          </Form>
        </OnDesktop>
        <OnMobile>
          <ResponsiveDialog
            open={showForm}
            title={t("collaborativeCare:panels.teamMembers.actions.create")}
            onClose={fullReset}
          >
            <DialogContent>
              <Form onSubmit={form.onSubmit} id={form.id}>
                <Stack direction="column" spacing={1}>
                  <FormOverlay
                    response={remoteData}
                    errorMessage={
                      form.globalError || t("collaborativeCare:panels.teamMembers.genericSaveError")
                    }
                  />
                  {providerField}
                  {functionField}
                  {startField}
                  {endField}
                </Stack>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<Check />}
                type="submit"
                form={form.id}
              >
                {t("common:actions.save")}
              </Button>
              <Button variant="outlined" color="secondary" startIcon={<Cancel />} onClick={fullReset}>
                {t("common:actions.cancel")}
              </Button>
            </DialogActions>
          </ResponsiveDialog>
        </OnMobile>
      </>
    );
  } else {
    return (
      <Button variant="outlined" color="secondary" startIcon={<Add />} onClick={() => setShowForm(true)}>
        {t("collaborativeCare:panels.teamMembers.actions.create")}
      </Button>
    );
  }
}

type NonEmpaneledProvidersProps = {
  providers: ReadonlyArray<PickTypename<Provider, "id" | "name">>;
};

function NonEmpaneledProviders(props: NonEmpaneledProvidersProps): ReactElement | null {
  const { t } = useTranslation(["collaborativeCare"]);

  if (props.providers.length === 0) {
    return null;
  }

  return (
    <Alert severity="info">
      {t("collaborativeCare:panels.unusedProviders", {
        names: props.providers.map((provider) => provider.name).join(", "),
      })}
    </Alert>
  );
}
