import React, { ReactElement } from "react";
import {
  OverridableAutocompleteProps,
  QueryAutocompleteMultiple,
  QueryAutocompleteSingle,
} from "./QueryAutocomplete";
import {
  AdministrableScale,
  ScaleLocale,
  SearchAdministrableScalesQueryVariables,
  SearchAllScalesQueryVariables,
  Subscale,
  useSearchAdministrableScalesLazyQuery,
  useSearchAllScalesLazyQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { PickTypename } from "type-utils";
import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { genderT, scaleLocaleT } from "GeneratedGraphQL/EnumTranslations";
import { TFunction } from "i18next";

// The existing of ScalePickerMultiple obviously implies the existing of a ScalePickerSingle, but we haven't needed
// that so we haven't built it. It should be reasonably obvious how to adapt this.

export type ScaleDescription = PickTypename<
  AdministrableScale,
  "id" | "name" | "shortname" | "gender" | "minAge" | "maxAge" | "locale" | "estimatedTime"
>;

type ScalePickerMultipleProps = {
  value: Array<ScaleDescription>;
  onChange: (newValue: Array<ScaleDescription>) => void;
  error?: boolean;
  required?: boolean;
  helperText?: string | null;
  autocompleteProps?: OverridableAutocompleteProps<ScaleDescription, true>;
};

export function ScalePickerMultiple(props: ScalePickerMultipleProps): ReactElement {
  const { t } = useTranslation();

  const queryVars: Omit<SearchAdministrableScalesQueryVariables, "search"> = {
    // Large enough to get all variants on the RCADS but small enough to not
    // blow up the server.
    first: 100,
  };

  return (
    <QueryAutocompleteMultiple
      value={props.value}
      valueUpdated={props.onChange}
      error={props.error}
      required={props.required}
      helperText={props.helperText}
      queryVariables={queryVars}
      query={useSearchAdministrableScalesLazyQuery}
      unwrapResponse={(response) => response.assessmentAdministrableScales?.nodes}
      valueEqual={(left, right) => left.id === right.id}
      label={t("scales.title")}
      autocompleteProps={{
        noOptionsText: t("scales.noMatching"),
        getOptionLabel: (scale) => scale.shortname || scale.name,
        renderOption: (props, option, _state) => {
          return (
            <li {...props} key={option.id.toString()} style={{ display: "block" }}>
              <ScaleAutocompleteOption option={option} />
            </li>
          );
        },
        ...props.autocompleteProps,
      }}
    />
  );
}

type ScalePickerSingleProps = {
  value: ScaleDescription | undefined;
  onChange: (newValue: ScaleDescription | undefined) => void;
  error?: boolean;
  required?: boolean;
  helperText?: string | null;
  autocompleteProps?: OverridableAutocompleteProps<ScaleDescription, false>;
};

export function ScalePickerSingle(props: ScalePickerSingleProps): ReactElement {
  const { t } = useTranslation();

  const queryVars: Omit<SearchAdministrableScalesQueryVariables, "search"> = {
    first: 100,
  };

  return (
    <QueryAutocompleteSingle
      value={props.value}
      valueUpdated={props.onChange}
      error={props.error}
      required={props.required}
      helperText={props.helperText}
      queryVariables={queryVars}
      query={useSearchAdministrableScalesLazyQuery}
      unwrapResponse={(response) => response.assessmentAdministrableScales?.nodes}
      valueEqual={(left, right) => left?.id === right?.id}
      label={t("scales.title")}
      autocompleteProps={{
        noOptionsText: t("scales.noMatching"),
        getOptionLabel: (scale) => scale?.shortname || scale?.name || "",
        renderOption: (props, option, _state) => {
          if (!option) {
            return null;
          }

          return (
            <li {...props} key={option.id.toString()} style={{ display: "block" }}>
              <ScaleAutocompleteOption option={option} />
            </li>
          );
        },
      }}
    />
  );
}

export type ScaleOrSubscaleDescription =
  | PickTypename<
      AdministrableScale,
      "id" | "name" | "shortname" | "gender" | "minAge" | "maxAge" | "locale" | "estimatedTime"
    >
  | PickTypename<Subscale, "id" | "name" | "shortname">;

type ScaleOrSubscalePickerSingleProps = {
  value: ScaleOrSubscaleDescription | undefined;
  onChange: (newValue: ScaleOrSubscaleDescription | undefined) => void;
  error?: boolean;
  required?: boolean;
  helperText?: string | null;
  autocompleteProps?: OverridableAutocompleteProps<ScaleOrSubscaleDescription, false>;
};

export function ScaleOrSubscalePickerSingle(props: ScaleOrSubscalePickerSingleProps): ReactElement {
  const { t } = useTranslation();

  const queryVars: Omit<SearchAllScalesQueryVariables, "search"> = {
    first: 100,
  };

  return (
    <QueryAutocompleteSingle
      value={props.value}
      valueUpdated={props.onChange}
      error={props.error}
      required={props.required}
      helperText={props.helperText}
      queryVariables={queryVars}
      query={useSearchAllScalesLazyQuery}
      unwrapResponse={(response) => response.assessmentScales?.nodes}
      valueEqual={(left, right) => left?.id === right?.id}
      label={t("scales.title")}
      autocompleteProps={{
        noOptionsText: t("scales.noMatching"),
        getOptionLabel: (scale) => scale?.shortname || scale?.name || "",
        renderOption: (props, option, _state) => {
          if (!option) {
            return null;
          }

          return (
            <li {...props} key={option.id.toString()} style={{ display: "block" }}>
              <ScaleAutocompleteOption option={option} />
            </li>
          );
        },
      }}
    />
  );
}

function ScaleAutocompleteOption(props: { option: ScaleOrSubscaleDescription }): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation(["common", "enums"]);

  let administrationInfo = null;
  if (props.option.__typename === "AdministrableScale") {
    administrationInfo = (
      <Stack direction="row" spacing={1} width="100%">
        <Typography flexBasis="25%" variant="caption">
          {languageSupport(props.option.locale, t)}
        </Typography>
        <Typography flexBasis="25%" variant="caption">
          {props.option.gender ? genderT(props.option.gender, t) : ""}
        </Typography>
        <Typography flexBasis="25%" variant="caption">
          {ageRange(props.option.minAge, props.option.maxAge, t)}
        </Typography>
        <Typography flexBasis="25%" variant="caption">
          {secondsToRoundedMinutesStr(props.option.estimatedTime, t)}
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack direction="column" spacing={0.5} sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
      <Typography variant="body1">{props.option.name}</Typography>
      {administrationInfo}
    </Stack>
  );
}

// Right now our supported languages are just english and spanish, with an assumption that everyone supports english,
// so we can get all the information we need by showing users when a scale supports spanish.
function languageSupport(
  locales: ReadonlyArray<ScaleLocale> | null,
  t: TFunction<["common", "enums"]>
): string {
  const en = scaleLocaleT(ScaleLocale.EN, t);
  if (locales && locales.includes(ScaleLocale.ES)) {
    return [scaleLocaleT(ScaleLocale.ES, t), en].join(", ");
  } else {
    return en;
  }
}

function ageRange(minAge: number | null, maxAge: number | null, t: TFunction): string {
  if (minAge === null && maxAge === null) {
    return t("scales.ageRange.none");
  } else if (minAge === null) {
    return t("scales.ageRange.lessThan", { maxAge: maxAge });
  } else if (maxAge === null) {
    return t("scales.ageRange.greaterThan", { minAge: minAge });
  } else {
    return t("scales.ageRange.bounded", { minAge: minAge, maxAge: maxAge });
  }
}

function secondsToRoundedMinutesStr(seconds: number | null, t: TFunction) {
  if (seconds === null) {
    return t("scales.estimatedTime.none");
  } else {
    const minutes = Math.round(seconds / 60);
    if (minutes < 1) {
      return t("scales.estimatedTime.subMinute");
    } else {
      // I have no idea why typescript insists I need default value here, but it makes it happy.
      return t("scales.estimatedTime.minutes", { minutes: minutes });
    }
  }
}
