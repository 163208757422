import { DialogContent, Link, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useWithFeatureEnabled } from "Contexts/CurrentInstituteContext";
import { dataDumpResourceT } from "GeneratedGraphQL/EnumTranslations";
import { DataDumpResource, useCreateExportMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import EnumCheckboxes from "Shared/EnumCheckboxes";
import { Form, FormOverlay, useForm, useListField, useTextField, useWrappedField } from "Shared/Form";
import { format } from "date-fns";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

type CreateExportFormProps = {
  open: boolean;
  onClose: () => void;
};

const alwaysIgnoreOptions = [DataDumpResource.RESEARCH_EXPERIMENT];

const cocmExportOptions = [
  DataDumpResource.COLLABORATIVE_CARE_CASE_CONSULT,
  DataDumpResource.COLLABORATIVE_CARE_CONSULT_MEETING,
  DataDumpResource.COLLABORATIVE_CARE_CONSULT_MEETING_ATTENDEE,
  DataDumpResource.COLLABORATIVE_CARE_CONSULT_REQUEST,
  DataDumpResource.COLLABORATIVE_CARE_ENROLLMENTS,
  DataDumpResource.COLLABORATIVE_CARE_TASK,
  DataDumpResource.COLLABORATIVE_CARE_TIME_ENTRY_LOG,
];

function CreateExportForm(props: CreateExportFormProps): ReactElement {
  const { t } = useTranslation(["settings"]);
  const [createDataDump, { remoteData, reset }] = apolloMutationHookWrapper(
    (response) => response.exportCreateDataDump,
    useCreateExportMutation({
      refetchQueries: ["ExportList"],
    })
  );

  const omitOptions = alwaysIgnoreOptions.concat(
    useWithFeatureEnabled("enableCollaborativeCare") ? [] : cocmExportOptions
  );

  const [showResources, setShowResources] = useState(false);

  const fields = {
    name: useTextField({ required: true, default: format(new Date(), "MMM dd, yyyy - h:mma") + " export" }),
    startDate: useWrappedField<Date | null>({ required: false, default: null }),
    endDate: useWrappedField<Date | null>({ required: false, default: null }),
    resources: useListField({ required: true, default: [DataDumpResource.PATIENT] }),
  };

  const form = useForm({
    fields: fields,
    submit: () => {
      createDataDump({
        variables: {
          input: {
            // Casts here are safe because this will only get called if all the fields are valid, including required
            // fields having values.
            name: fields.name.value as string,
            startDate: fields.startDate.value,
            endDate: fields.endDate.value,
            resources: fields.resources.value,
          },
        },
      });
    },
    remoteData: remoteData,
    onSuccess: () => {
      // Show the check mark for a second, then a second later clear the form.
      setTimeout(() => {
        props.onClose();
        setTimeout(() => {
          reset(), form.reset();
        }, 500);
      }, 500);
    },
  });

  let resourceSection = (
    <>
      <Typography>
        {t("settings:exports.create.resourceExplanation")}{" "}
        <Link onClick={() => setShowResources(true)}>
          {t("settings:exports.create.showAvailableResources")}
        </Link>
      </Typography>
    </>
  );

  if (showResources) {
    resourceSection = (
      <EnumCheckboxes
        enumTrans={dataDumpResourceT}
        onChange={fields.resources.onChange}
        optionsEnum={DataDumpResource}
        value={fields.resources.value}
        omitOptions={omitOptions}
      />
    );
  }

  return (
    <>
      <ResponsiveDialog
        open={props.open}
        onClose={props.onClose}
        title={t("settings:exports.createExport")}
        keepMounted={false}
        dialogWidth="50%"
      >
        <DialogContent>
          <Form onSubmit={form.onSubmit}>
            <FormOverlay response={remoteData} errorMessage={t("settings:exports.error")} />
            <Stack direction="column" spacing={1}>
              <TextField
                title={t("settings:exports.create.name.label")}
                label={t("settings:exports.create.name.label")}
                name="name"
                autoFocus
                value={fields.name.value}
                onChange={fields.name.onChange}
                error={fields.name.error}
                helperText={fields.name.helperText}
              />
              <DatePicker
                label={t("settings:exports.create.startDate.label")}
                format={t("settings:exports.create.startDate.format")}
                value={fields.startDate.value}
                onChange={fields.startDate.onChange}
              />
              <DatePicker
                label={t("settings:exports.create.endDate.label")}
                format={t("settings:exports.create.endDate.format")}
                value={fields.endDate.value}
                onChange={fields.endDate.onChange}
              />
              {resourceSection}
              <Stack direction="row-reverse" spacing={1}>
                <ButtonWithSpinner
                  variant="contained"
                  color="secondary"
                  type="submit"
                  showSpinner={form.showSpinner}
                  disabled={form.disableSubmit}
                >
                  {t("settings:exports.create.beginExport")}
                </ButtonWithSpinner>
              </Stack>
            </Stack>
          </Form>
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}

export default CreateExportForm;
