import React, { ReactElement } from "react";

import { Institutes } from "./Institutes";
import { Redirect } from "./Redirect";
import RouterWithNotFound from "../Shared/RouterWithNotFound";
import InspectorRoute from "./Inspector/Inspector";
import { Route } from "react-router-dom";
import { OpsToolsList } from "./OpsToolsList";
import { TaskRunnerRoute } from "./TaskRunner";
import { FeatureMatrixPage } from "./Institutes/FeatureMatrix";
import { InspectorEntry } from "./Inspector/InspectorEntry";
import { InstituteGroups } from "./InstituteGroups";

function Ops(): ReactElement {
  return (
    <RouterWithNotFound>
      <Route element={<OpsToolsList />} path="/" />
      <Route element={<InspectorEntry />} path="/inspector" />
      <Route element={<InspectorRoute />} path="inspector/class/:className/id/:id" />
      <Route element={<Institutes />} path="institutes/*" />
      <Route element={<InstituteGroups />} path="institute-groups/*" />
      <Route element={<TaskRunnerRoute />} path="task-runner/*" />
      <Route element={<Redirect />} path="redirect" />
      <Route element={<FeatureMatrixPage />} path="feature-matrix" />
    </RouterWithNotFound>
  );
}

export default Ops;
