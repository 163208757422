import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TreeView } from "@mui/lab";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { MutationRemoteDataResult, apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import {
  DataSourceResourceType,
  Organization,
  useCreateOrganizationMutation,
  useEditOrganizationMutation,
  useOrganizationTreeQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { OrganizationId } from "Lib/Ids";
import { ancestry, treeify } from "Shared/Ancestry";
import ErrorMessage from "Shared/ErrorMessage";
import { OnlyExpandOnIconClickTreeItem } from "Shared/OnlyExpandOnIconClickTreeItem";
import Spinner from "Shared/Spinner";
import React, { ReactElement } from "react";
import { PickTypename } from "type-utils";
import * as Id from "Lib/Id";
import { PropertyTable } from "MDS/PropertyTable";
import OrganizationMeasurementAllowedSwitch from "Settings/Provider/Admin/Configuration/Organizations/Settings/OrganizationMeasurementAllowedSwitch";
import { useTranslation } from "react-i18next";
import CurrentInstituteContext, { hasEnabledDataSourceResource } from "Contexts/CurrentInstituteContext";
import {
  AuthenticatedProviderUserContext,
  providerUserHasPermission,
} from "AppSession/AuthenticatedProviderUser";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { Form, FormOverlay, useBooleanField, useForm, useTextField, useWrappedField } from "Shared/Form";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ItemWithInfoTooltip } from "MDS/Tooltip/InfoTooltip";

export function OrganizationsPage(): ReactElement {
  const { t } = useTranslation(["organizations"]);
  const { remoteData } = apolloQueryHookWrapper(useOrganizationTreeQuery());

  const content = remoteData.caseOf({
    NotAsked: () => <OrganizationsPageLoading />,
    Loading: () => <OrganizationsPageLoading />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (response.organizations) {
        return <OrganizationsPageContent organizations={response.organizations.nodes} />;
      } else {
        return <ErrorMessage message={t("organizations:loadError")} />;
      }
    },
  });

  return <Page browserTitle={t("organizations:title")}>{content}</Page>;
}

function OrganizationsPageLoading(): ReactElement {
  const { t } = useTranslation(["organizations"]);

  return (
    <Card>
      <CardHeader title={t("organizations:title")} />
      <CardContent>
        <Spinner />
      </CardContent>
    </Card>
  );
}

type OrganizationSummary = PickTypename<
  Organization,
  "id" | "name" | "parentId" | "shortname" | "measurementAllowed" | "inTrialPeriod" | "timezone"
>;

function useEditingEnabled() {
  const currentInstitute = React.useContext(CurrentInstituteContext);
  const ehrManagedOrganizations = hasEnabledDataSourceResource(
    currentInstitute,
    DataSourceResourceType.ORGANIZATION
  );
  const currentProvider = React.useContext(AuthenticatedProviderUserContext);
  const editOrganizationsPermission = currentProvider
    ? providerUserHasPermission(currentProvider, "editOrganizations")
    : false;

  return !ehrManagedOrganizations && editOrganizationsPermission;
}

type OrganizationContentProps = {
  organizations: ReadonlyArray<OrganizationSummary>;
};

function OrganizationsPageContent(props: OrganizationContentProps): ReactElement {
  const { t } = useTranslation(["organizations"]);
  const [selectedId, setSelectedId] = React.useState<OrganizationId | undefined>(undefined);
  const selectedOrg = props.organizations.find((org) => org.id === selectedId);

  return (
    <Grid container columns={12} spacing={1}>
      <Grid item xs={12}>
        <EhrBanner />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Card>
          <CardHeader
            title={t("organizations:title")}
            action={<MaybeCreateOrganizationButton organizations={props.organizations} />}
          />
          <CardContent>
            <OrganizationTreeSelect
              organizations={props.organizations}
              value={selectedId}
              onChange={setSelectedId}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={8}>
        <MaybeOrganizationDetails organization={selectedOrg} organizations={props.organizations} />
      </Grid>
    </Grid>
  );
}

function EhrBanner(): ReactElement {
  const { t } = useTranslation(["organizations"]);

  const ehrManagedOrganizations = hasEnabledDataSourceResource(
    React.useContext(CurrentInstituteContext),
    DataSourceResourceType.ORGANIZATION
  );

  if (ehrManagedOrganizations) {
    return <Alert severity="info">{t("organizations:ehrMessage")}</Alert>;
  } else {
    return <></>;
  }
}

type OrganizationTreeSelectProps = {
  organizations: ReadonlyArray<OrganizationSummary>;
  value: OrganizationId | undefined;
  onChange: (newValue: OrganizationId) => void;
};
type OrganizationTree = OrganizationSummary & {
  children: ReadonlyArray<OrganizationTree>;
};

function OrganizationTreeSelect(props: OrganizationTreeSelectProps): ReactElement {
  const { t } = useTranslation(["organizations"]);
  const tree = treeify(props.organizations);
  const ancestors = ancestry(props.organizations, props.value || null);
  const [expanded, setExpanded] = React.useState(ancestors);

  return (
    <TreeView
      aria-label={t("organizations:title")}
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      // We have to convert undefined to empty string here or we get console spam about switching the control from
      // an uncontrolled state to a controlled state.
      selected={props.value?.toString() || ""}
      onNodeSelect={(_event: React.SyntheticEvent, id: string) => props.onChange(Id.unsafeFromUuid(id))}
      multiSelect={false}
      expanded={expanded}
      onNodeToggle={(_event: React.SyntheticEvent, ids: Array<string>) => setExpanded(ids)}
    >
      {tree.map((o, i) => (
        <OrganizationTreeItem {...o} key={i} />
      ))}
    </TreeView>
  );
}

function OrganizationTreeItem(props: OrganizationTree): ReactElement {
  return (
    <OnlyExpandOnIconClickTreeItem nodeId={props.id.toString()} label={props.name}>
      {props.children.map((o, i) => (
        <OrganizationTreeItem {...o} key={i} />
      ))}
    </OnlyExpandOnIconClickTreeItem>
  );
}

type MaybeOrganizationDetailsProps = {
  organization: OrganizationSummary | undefined;
  organizations: ReadonlyArray<OrganizationSummary>;
};

function MaybeOrganizationDetails(props: MaybeOrganizationDetailsProps): ReactElement {
  const { t } = useTranslation(["organizations"]);

  if (props.organization === undefined) {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        <Typography variant="h2">{t("organizations:notSelected")}</Typography>
      </Stack>
    );
  } else {
    return <OrganizationDetails organization={props.organization} organizations={props.organizations} />;
  }
}

type OrganizationsDetailsProps = {
  organization: OrganizationSummary;
  organizations: ReadonlyArray<OrganizationSummary>;
};

function OrganizationDetails(props: OrganizationsDetailsProps): ReactElement {
  const { t } = useTranslation(["organizations"]);

  return (
    <Card>
      <CardHeader
        title={props.organization.name}
        action={
          <MaybeEditOrganizationButton
            organization={props.organization}
            organizations={props.organizations}
          />
        }
      />
      <CardContent>
        <PropertyTable>
          <TableBody>
            <TableRow>
              <TableCell>{t("organizations:fields.name")}</TableCell>
              <TableCell>{props.organization.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <ItemWithInfoTooltip
                  item={t("organizations:fields.shortname")}
                  tooltip={t("organizations:info.shortname")}
                />
              </TableCell>
              <TableCell>{props.organization.shortname}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <ItemWithInfoTooltip
                  item={t("organizations:fields.inTrialPeriod")}
                  tooltip={t("organizations:info.inTrialPeriod")}
                />
              </TableCell>
              <TableCell>{props.organization.inTrialPeriod ? "true" : "false"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t("organizations:fields.measurementAllowed")}</TableCell>
              <TableCell>
                <OrganizationMeasurementAllowedSwitch
                  key={props.organization.id.toString()}
                  id={props.organization.id}
                  measurementAllowed={props.organization.measurementAllowed}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <ItemWithInfoTooltip
                  item={t("organizations:fields.timezone")}
                  tooltip={t("organizations:info.timezone")}
                />
              </TableCell>
              <TableCell>{props.organization.timezone}</TableCell>
            </TableRow>
          </TableBody>
        </PropertyTable>
      </CardContent>
    </Card>
  );
}

type CreateOrganizationButtonProps = {
  organizations: ReadonlyArray<OrganizationSummary>;
};

function MaybeCreateOrganizationButton(props: CreateOrganizationButtonProps): ReactElement {
  const { t } = useTranslation(["organizations"]);
  const [createOrganization, { remoteData, reset }] = apolloMutationHookWrapper(
    (response) => response.createOrganization,
    useCreateOrganizationMutation({
      refetchQueries: refetchQueries("organizationList"),
    })
  );

  return (
    <MaybeCreateOrEditOrganizationButton
      organizations={props.organizations}
      defaults={{
        name: "",
        shortname: "",
        parentId: null,
        inTrialPeriod: false,
        measurementAllowed: false,
        timezone: null,
      }}
      label={t("organizations:actions.create")}
      remoteData={remoteData}
      mutation={(fields) => {
        createOrganization({
          variables: {
            input: {
              ...fields,
            },
          },
        });
      }}
      resetMutation={reset}
    />
  );
}

type EditOrganizationButtonProps = {
  organization: OrganizationSummary;
  organizations: ReadonlyArray<OrganizationSummary>;
};

function MaybeEditOrganizationButton(props: EditOrganizationButtonProps): ReactElement {
  const { t } = useTranslation(["organizations"]);
  const [editOrganization, { remoteData, reset }] = apolloMutationHookWrapper(
    (response) => response.editOrganization,
    useEditOrganizationMutation({
      refetchQueries: refetchQueries("organizationList"),
    })
  );

  return (
    <MaybeCreateOrEditOrganizationButton
      organizations={props.organizations}
      defaults={props.organization}
      label={t("organizations:actions.edit")}
      remoteData={remoteData}
      mutation={(fields) => {
        editOrganization({
          variables: {
            input: {
              organizationId: props.organization.id,
              ...fields,
            },
          },
        });
      }}
      resetMutation={reset}
    />
  );
}

type CreateOrEditOrganizationButtonProps = {
  organizations: ReadonlyArray<OrganizationSummary>;
  defaults: Omit<OrganizationSummary, "id" | "__typename">;
  label: string;
  remoteData: MutationRemoteDataResult<unknown>;
  mutation: (fields: Omit<OrganizationSummary, "id" | "__typename">) => void;
  resetMutation: () => void;
};

function MaybeCreateOrEditOrganizationButton(props: CreateOrEditOrganizationButtonProps): ReactElement {
  const showButton = useEditingEnabled();
  if (showButton) {
    return <CreateOrEditOrganizationButton {...props} />;
  } else {
    return <></>;
  }
}

function CreateOrEditOrganizationButton(props: CreateOrEditOrganizationButtonProps): ReactElement {
  const [open, setOpen] = React.useState(false);
  const dialog = open ? (
    <CreateOrEditOrganizationDialog
      defaults={props.defaults}
      organizations={props.organizations}
      title={props.label}
      remoteData={props.remoteData}
      mutation={props.mutation}
      onClose={() => setOpen(false)}
      onSuccess={() => {
        setTimeout(() => {
          setOpen(false);
          props.resetMutation();
        }, 500);
      }}
    />
  ) : null;

  return (
    <>
      <Button color="secondary" variant="contained" onClick={() => setOpen(true)}>
        {props.label}
      </Button>
      {dialog}
    </>
  );
}

type CreateOrEditOrganizationDialogProps = {
  organizations: ReadonlyArray<OrganizationSummary>;
  defaults: Omit<OrganizationSummary, "id" | "__typename">;
  title: string;
  remoteData: MutationRemoteDataResult<unknown>;
  mutation: (fields: Omit<OrganizationSummary, "id" | "__typename">) => void;
  onClose: () => void;
  onSuccess?: () => void;
};

function CreateOrEditOrganizationDialog(props: CreateOrEditOrganizationDialogProps): ReactElement {
  const { t } = useTranslation(["organizations", "common"]);

  const fields = {
    name: useTextField({ required: true, default: props.defaults.name }),
    shortname: useTextField({ required: true, default: props.defaults.shortname }),
    parentId: useWrappedField<OrganizationId>({
      required: false,
      default: props.defaults.parentId || undefined,
    }),
    inTrialPeriod: useBooleanField({ required: true, default: props.defaults.inTrialPeriod }),
    measurementAllowed: useBooleanField({ required: true, default: props.defaults.measurementAllowed }),
    timezone: useTextField({
      default: props.defaults.timezone || undefined,
      required: false,
    }),
  };

  const form = useForm({
    id: "create-organization-form",
    fields: fields,
    remoteData: props.remoteData,
    submit: () => {
      props.mutation({
        name: fields.name.value as string,
        shortname: fields.shortname.value as string,
        parentId: fields.parentId.value || null,
        inTrialPeriod: fields.inTrialPeriod.value as boolean,
        measurementAllowed: fields.measurementAllowed.value as boolean,
        timezone: fields.timezone.value === "" ? null : (fields.timezone.value as string | null),
      });
    },
    onSuccess: props.onSuccess,
  });

  return (
    <ResponsiveDialog open={true} onClose={props.onClose} title={props.title}>
      <DialogContent>
        <Form id={form.id} onSubmit={form.onSubmit}>
          <FormOverlay response={props.remoteData} errorMessage={t("organizations:saveError")} />
          <Stack direction="column" spacing={1}>
            <FormControl error={fields.parentId.error}>
              <FormControlLabel
                label={t("organizations:fields.parent")}
                control={<></>}
                // These labels have a default negative margin for reasons I don't really understand, but if we don't
                // cancel it out here it pushes the label outside the bounds of the form.
                sx={{ marginLeft: 0 }}
              />
              <OrganizationTreeSelect
                organizations={props.organizations}
                value={fields.parentId.value}
                onChange={fields.parentId.onChange}
              />
              <FormHelperText>{fields.parentId.helperText}</FormHelperText>
            </FormControl>
            <TextField
              label={t("organizations:fields.name")}
              value={fields.name.value}
              onChange={fields.name.onChange}
              error={fields.name.error}
              helperText={fields.name.helperText}
            />
            <TextField
              label={
                <ItemWithInfoTooltip
                  item={t("organizations:fields.shortname")}
                  tooltip={t("organizations:info.shortname")}
                />
              }
              value={fields.shortname.value}
              onChange={fields.shortname.onChange}
              error={fields.shortname.error}
              helperText={fields.shortname.helperText}
            />
            <FormControl error={fields.inTrialPeriod.error}>
              <FormControlLabel
                control={<Checkbox />}
                label={
                  <ItemWithInfoTooltip
                    item={t("organizations:fields.inTrialPeriod")}
                    tooltip={t("organizations:info.inTrialPeriod")}
                  />
                }
                checked={fields.inTrialPeriod.value}
                onChange={fields.inTrialPeriod.onChange}
              />
              <FormHelperText>{fields.inTrialPeriod.helperText}</FormHelperText>
            </FormControl>
            <FormControl error={fields.measurementAllowed.error}>
              <FormControlLabel
                control={<Checkbox />}
                label={t("organizations:fields.measurementAllowed")}
                checked={fields.measurementAllowed.value}
                onChange={fields.measurementAllowed.onChange}
              />
              <FormHelperText>{fields.measurementAllowed.helperText}</FormHelperText>
            </FormControl>
            <TextField
              label={
                <ItemWithInfoTooltip
                  item={t("organizations:fields.timezone")}
                  tooltip={t("organizations:info.timezone")}
                />
              }
              value={fields.timezone.value}
              onChange={fields.timezone.onChange}
              error={fields.timezone.error}
              helperText={fields.timezone.helperText}
            />
          </Stack>
        </Form>
      </DialogContent>
      <DialogActions>
        <ButtonWithSpinner
          variant="contained"
          color="secondary"
          type="submit"
          form={form.id}
          showSpinner={form.showSpinner}
          disabled={form.disableSubmit}
        >
          {t("common:actions.save")}
        </ButtonWithSpinner>
      </DialogActions>
    </ResponsiveDialog>
  );
}
