import * as Ids from "../Lib/Ids";
import * as GqlScalars from "../Lib/GqlScalars";
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Represents untyped JSON */
  AnalyticsEventData: { input: GqlScalars.AnalyticsEventData; output: GqlScalars.AnalyticsEventData };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  AnalyticsEventId: { input: Ids.AnalyticsEventId; output: Ids.AnalyticsEventId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  AnswerId: { input: Ids.AnswerId; output: Ids.AnswerId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  AppointmentId: { input: Ids.AppointmentId; output: Ids.AppointmentId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  AthenaAppointmentEncounterId: {
    input: Ids.AthenaAppointmentEncounterId;
    output: Ids.AthenaAppointmentEncounterId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  AthenaScreeningQuestionnaireId: {
    input: Ids.AthenaScreeningQuestionnaireId;
    output: Ids.AthenaScreeningQuestionnaireId;
  };
  /** Represents untyped JSON */
  BusinessEventData: { input: GqlScalars.BusinessEventData; output: GqlScalars.BusinessEventData };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  BusinessEventId: { input: Ids.BusinessEventId; output: Ids.BusinessEventId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CareEpisodeComputedValueId: {
    input: Ids.CareEpisodeComputedValueId;
    output: Ids.CareEpisodeComputedValueId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CareEpisodeConstructId: { input: Ids.CareEpisodeConstructId; output: Ids.CareEpisodeConstructId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CareEpisodeId: { input: Ids.CareEpisodeId; output: Ids.CareEpisodeId };
  /** Represents untyped JSON */
  CareEpisodeMessageData: {
    input: GqlScalars.CareEpisodeMessageData;
    output: GqlScalars.CareEpisodeMessageData;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CareEpisodeMessageId: { input: Ids.CareEpisodeMessageId; output: Ids.CareEpisodeMessageId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CareEpisodeProviderId: { input: Ids.CareEpisodeProviderId; output: Ids.CareEpisodeProviderId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CareEpisodeScaleId: { input: Ids.CareEpisodeScaleId; output: Ids.CareEpisodeScaleId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CareEpisodeScaleScorerSummaryId: {
    input: Ids.CareEpisodeScaleScorerSummaryId;
    output: Ids.CareEpisodeScaleScorerSummaryId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CareEpisodeTreatmentTargetId: {
    input: Ids.CareEpisodeTreatmentTargetId;
    output: Ids.CareEpisodeTreatmentTargetId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CareEpisodeTreatmentTrackId: {
    input: Ids.CareEpisodeTreatmentTrackId;
    output: Ids.CareEpisodeTreatmentTrackId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CareGroupId: { input: Ids.CareGroupId; output: Ids.CareGroupId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CareUnitMembershipId: { input: Ids.CareUnitMembershipId; output: Ids.CareUnitMembershipId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CareUnitTypeId: { input: Ids.CareUnitTypeId; output: Ids.CareUnitTypeId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CaseConsultId: { input: Ids.CaseConsultId; output: Ids.CaseConsultId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CocmBillingAlgorithmCodeId: {
    input: Ids.CocmBillingAlgorithmCodeId;
    output: Ids.CocmBillingAlgorithmCodeId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CocmBillingAlgorithmId: { input: Ids.CocmBillingAlgorithmId; output: Ids.CocmBillingAlgorithmId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CocmBillingAlgorithmRuleCodeId: {
    input: Ids.CocmBillingAlgorithmRuleCodeId;
    output: Ids.CocmBillingAlgorithmRuleCodeId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CocmBillingAlgorithmRuleId: {
    input: Ids.CocmBillingAlgorithmRuleId;
    output: Ids.CocmBillingAlgorithmRuleId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ComputedValueId: { input: Ids.ComputedValueId; output: Ids.ComputedValueId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ConditionId: { input: Ids.ConditionId; output: Ids.ConditionId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ConsentAgreementId: { input: Ids.ConsentAgreementId; output: Ids.ConsentAgreementId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ConsentId: { input: Ids.ConsentId; output: Ids.ConsentId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ConstructId: { input: Ids.ConstructId; output: Ids.ConstructId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ConsultMeetingAttendeeId: { input: Ids.ConsultMeetingAttendeeId; output: Ids.ConsultMeetingAttendeeId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ConsultMeetingId: { input: Ids.ConsultMeetingId; output: Ids.ConsultMeetingId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ConsultRequestId: { input: Ids.ConsultRequestId; output: Ids.ConsultRequestId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  CoverageId: { input: Ids.CoverageId; output: Ids.CoverageId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  DataDumpId: { input: Ids.DataDumpId; output: Ids.DataDumpId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  DataFetchRequestId: { input: Ids.DataFetchRequestId; output: Ids.DataFetchRequestId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  DataSourceId: { input: Ids.DataSourceId; output: Ids.DataSourceId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  DataSourceRecordAlgorithmLogId: {
    input: Ids.DataSourceRecordAlgorithmLogId;
    output: Ids.DataSourceRecordAlgorithmLogId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  DataSourceRecordId: { input: Ids.DataSourceRecordId; output: Ids.DataSourceRecordId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  DataSourceRecordLogId: { input: Ids.DataSourceRecordLogId; output: Ids.DataSourceRecordLogId };
  /** Represents untyped JSON */
  DataSourceRecordLogRawData: {
    input: GqlScalars.DataSourceRecordLogRawData;
    output: GqlScalars.DataSourceRecordLogRawData;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  DataSourceResourceConfigId: {
    input: Ids.DataSourceResourceConfigId;
    output: Ids.DataSourceResourceConfigId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  DataSourceResourceExtractorId: {
    input: Ids.DataSourceResourceExtractorId;
    output: Ids.DataSourceResourceExtractorId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  DataSourceResourceId: { input: Ids.DataSourceResourceId; output: Ids.DataSourceResourceId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  DecisionAlgorithmId: { input: Ids.DecisionAlgorithmId; output: Ids.DecisionAlgorithmId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  DomainId: { input: Ids.DomainId; output: Ids.DomainId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  EhrLinkId: { input: Ids.EhrLinkId; output: Ids.EhrLinkId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  EhrLinkLogId: { input: Ids.EhrLinkLogId; output: Ids.EhrLinkLogId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  EncounterId: { input: Ids.EncounterId; output: Ids.EncounterId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  EnrollmentId: { input: Ids.EnrollmentId; output: Ids.EnrollmentId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  EnrollmentMonthBillingRuleResultId: {
    input: Ids.EnrollmentMonthBillingRuleResultId;
    output: Ids.EnrollmentMonthBillingRuleResultId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  EnrollmentMonthBillingRuleResultWinnerId: {
    input: Ids.EnrollmentMonthBillingRuleResultWinnerId;
    output: Ids.EnrollmentMonthBillingRuleResultWinnerId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  EnrollmentMonthId: { input: Ids.EnrollmentMonthId; output: Ids.EnrollmentMonthId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  EntityDecisionAlgorithmId: { input: Ids.EntityDecisionAlgorithmId; output: Ids.EntityDecisionAlgorithmId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  EntityId: { input: Ids.EntityId; output: Ids.EntityId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  EntityMeasurementPlanId: { input: Ids.EntityMeasurementPlanId; output: Ids.EntityMeasurementPlanId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ExternalEventId: { input: Ids.ExternalEventId; output: Ids.ExternalEventId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ExternalEventTypeId: { input: Ids.ExternalEventTypeId; output: Ids.ExternalEventTypeId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ExternalObservationId: { input: Ids.ExternalObservationId; output: Ids.ExternalObservationId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  FindingId: { input: Ids.FindingId; output: Ids.FindingId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  FlagId: { input: Ids.FlagId; output: Ids.FlagId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  GoalAnswerId: { input: Ids.GoalAnswerId; output: Ids.GoalAnswerId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  GoalId: { input: Ids.GoalId; output: Ids.GoalId };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: Date; output: Date };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: Date; output: Date };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ImplementationTargetId: { input: Ids.ImplementationTargetId; output: Ids.ImplementationTargetId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ImportHistoryId: { input: Ids.ImportHistoryId; output: Ids.ImportHistoryId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  InboxMessageId: { input: Ids.InboxMessageId; output: Ids.InboxMessageId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  InstituteConditionId: { input: Ids.InstituteConditionId; output: Ids.InstituteConditionId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  InstituteGroupId: { input: Ids.InstituteGroupId; output: Ids.InstituteGroupId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  InstituteId: { input: Ids.InstituteId; output: Ids.InstituteId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  InstitutePayorId: { input: Ids.InstitutePayorId; output: Ids.InstitutePayorId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  InstituteScaleId: { input: Ids.InstituteScaleId; output: Ids.InstituteScaleId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  IntakeSessionScaleId: { input: Ids.IntakeSessionScaleId; output: Ids.IntakeSessionScaleId };
  /** A postgres interval string */
  Interval: { input: GqlScalars.Interval; output: GqlScalars.Interval };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  InvitationId: { input: Ids.InvitationId; output: Ids.InvitationId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  InvitationNotificationId: { input: Ids.InvitationNotificationId; output: Ids.InvitationNotificationId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  InviteId: { input: Ids.InviteId; output: Ids.InviteId };
  /** Represents untyped JSON */
  JobArgumentData: { input: GqlScalars.JobArgumentData; output: GqlScalars.JobArgumentData };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  KeyPairId: { input: Ids.KeyPairId; output: Ids.KeyPairId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  LocationId: { input: Ids.LocationId; output: Ids.LocationId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  MeasurementPlanId: { input: Ids.MeasurementPlanId; output: Ids.MeasurementPlanId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  MeasurementPlanScaleId: { input: Ids.MeasurementPlanScaleId; output: Ids.MeasurementPlanScaleId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  MetricDataExportId: { input: Ids.MetricDataExportId; output: Ids.MetricDataExportId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  MetricId: { input: Ids.MetricId; output: Ids.MetricId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  MetricInclusionCriterionId: {
    input: Ids.MetricInclusionCriterionId;
    output: Ids.MetricInclusionCriterionId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  MetricSuccessCriterionId: { input: Ids.MetricSuccessCriterionId; output: Ids.MetricSuccessCriterionId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  MetricTriggerId: { input: Ids.MetricTriggerId; output: Ids.MetricTriggerId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ModuleId: { input: Ids.ModuleId; output: Ids.ModuleId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  MonitoredJobId: { input: Ids.MonitoredJobId; output: Ids.MonitoredJobId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  NormId: { input: Ids.NormId; output: Ids.NormId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  NoteId: { input: Ids.NoteId; output: Ids.NoteId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  OrganizationId: { input: Ids.OrganizationId; output: Ids.OrganizationId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  PanelId: { input: Ids.PanelId; output: Ids.PanelId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  PanelTeamMemberId: { input: Ids.PanelTeamMemberId; output: Ids.PanelTeamMemberId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ParticipantId: { input: Ids.ParticipantId; output: Ids.ParticipantId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  PatientConditionId: { input: Ids.PatientConditionId; output: Ids.PatientConditionId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  PatientFlagId: { input: Ids.PatientFlagId; output: Ids.PatientFlagId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  PatientId: { input: Ids.PatientId; output: Ids.PatientId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  PatientMinutesTargetId: { input: Ids.PatientMinutesTargetId; output: Ids.PatientMinutesTargetId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  PatientQualityIssueId: { input: Ids.PatientQualityIssueId; output: Ids.PatientQualityIssueId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  PatientSessionId: { input: Ids.PatientSessionId; output: Ids.PatientSessionId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  PipbhcExternalAppointmentId: {
    input: Ids.PipbhcExternalAppointmentId;
    output: Ids.PipbhcExternalAppointmentId;
  };
  /** Represents untyped JSON */
  PipbhcExternalEventData: {
    input: GqlScalars.PipbhcExternalEventData;
    output: GqlScalars.PipbhcExternalEventData;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  PipbhcPatientConditionId: { input: Ids.PipbhcPatientConditionId; output: Ids.PipbhcPatientConditionId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  PipbhcTreatmentGoalAnswerId: {
    input: Ids.PipbhcTreatmentGoalAnswerId;
    output: Ids.PipbhcTreatmentGoalAnswerId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  PipbhcTreatmentGoalId: { input: Ids.PipbhcTreatmentGoalId; output: Ids.PipbhcTreatmentGoalId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  PipbhcTreatmentGoalReportId: {
    input: Ids.PipbhcTreatmentGoalReportId;
    output: Ids.PipbhcTreatmentGoalReportId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  PopulationId: { input: Ids.PopulationId; output: Ids.PopulationId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ProviderId: { input: Ids.ProviderId; output: Ids.ProviderId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ProviderNotificationId: { input: Ids.ProviderNotificationId; output: Ids.ProviderNotificationId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ProviderOrganizationId: { input: Ids.ProviderOrganizationId; output: Ids.ProviderOrganizationId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  QuestionConstructId: { input: Ids.QuestionConstructId; output: Ids.QuestionConstructId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  QuestionId: { input: Ids.QuestionId; output: Ids.QuestionId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  QuestionOptionId: { input: Ids.QuestionOptionId; output: Ids.QuestionOptionId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  QuickTextId: { input: Ids.QuickTextId; output: Ids.QuickTextId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  RecurrenceId: { input: Ids.RecurrenceId; output: Ids.RecurrenceId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  RelatedPersonId: { input: Ids.RelatedPersonId; output: Ids.RelatedPersonId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ReportId: { input: Ids.ReportId; output: Ids.ReportId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ReportScaleId: { input: Ids.ReportScaleId; output: Ids.ReportScaleId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ReportScaleParticipantId: { input: Ids.ReportScaleParticipantId; output: Ids.ReportScaleParticipantId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ReportTextId: { input: Ids.ReportTextId; output: Ids.ReportTextId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ResponseId: { input: Ids.ResponseId; output: Ids.ResponseId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ResponseItemId: { input: Ids.ResponseItemId; output: Ids.ResponseItemId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ScaleGroupId: { input: Ids.ScaleGroupId; output: Ids.ScaleGroupId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ScaleId: { input: Ids.ScaleId; output: Ids.ScaleId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  ScaleScorerId: { input: Ids.ScaleScorerId; output: Ids.ScaleScorerId };
  /** Represents untyped JSON */
  SideEffectParams: { input: GqlScalars.SideEffectParams; output: GqlScalars.SideEffectParams };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  SupportTaskHistoryId: { input: Ids.SupportTaskHistoryId; output: Ids.SupportTaskHistoryId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  TaskCreationLogId: { input: Ids.TaskCreationLogId; output: Ids.TaskCreationLogId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  TaskId: { input: Ids.TaskId; output: Ids.TaskId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  TaskListId: { input: Ids.TaskListId; output: Ids.TaskListId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  TaskListItemId: { input: Ids.TaskListItemId; output: Ids.TaskListItemId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  TaskTemplateId: { input: Ids.TaskTemplateId; output: Ids.TaskTemplateId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  TimeEntryLogId: { input: Ids.TimeEntryLogId; output: Ids.TimeEntryLogId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  TreatmentServiceId: { input: Ids.TreatmentServiceId; output: Ids.TreatmentServiceId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  TreatmentTargetId: { input: Ids.TreatmentTargetId; output: Ids.TreatmentTargetId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  TreatmentTrackId: { input: Ids.TreatmentTrackId; output: Ids.TreatmentTrackId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  TriggeredDecisionAlgorithmId: {
    input: Ids.TriggeredDecisionAlgorithmId;
    output: Ids.TriggeredDecisionAlgorithmId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  TriggeredWorkflowActionId: { input: Ids.TriggeredWorkflowActionId; output: Ids.TriggeredWorkflowActionId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  TriggeredWorkflowActionLogId: {
    input: Ids.TriggeredWorkflowActionLogId;
    output: Ids.TriggeredWorkflowActionLogId;
  };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  TriggeredWorkflowId: { input: Ids.TriggeredWorkflowId; output: Ids.TriggeredWorkflowId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  UserConsentId: { input: Ids.UserConsentId; output: Ids.UserConsentId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  UserId: { input: Ids.UserId; output: Ids.UserId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  WorkflowActionId: { input: Ids.WorkflowActionId; output: Ids.WorkflowActionId };
  /**
   * Represents textual data as UTF-8 character sequences. This type is most often
   * used by GraphQL to represent free-form human-readable text.
   */
  WorkflowId: { input: Ids.WorkflowId; output: Ids.WorkflowId };
};

/** Autogenerated input type of AcceptFinding */
export type AcceptFindingInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly findingId: Scalars["FindingId"]["input"];
};

/** Autogenerated return type of AcceptFinding. */
export type AcceptFindingPayload = {
  readonly __typename: "AcceptFindingPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<AcceptFindingResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type AcceptFindingResult = {
  readonly __typename: "AcceptFindingResult";
  readonly finding: Finding;
};

export type ActOnInboxMessageChoice =
  | {
      readonly accept: InboxMessageIdArgs;
      readonly dismiss?: never;
      readonly invalidate?: never;
      readonly snooze?: never;
    }
  | {
      readonly accept?: never;
      readonly dismiss: InboxMessageIdArgs;
      readonly invalidate?: never;
      readonly snooze?: never;
    }
  | {
      readonly accept?: never;
      readonly dismiss?: never;
      readonly invalidate: InboxMessageIdArgs;
      readonly snooze?: never;
    }
  | {
      readonly accept?: never;
      readonly dismiss?: never;
      readonly invalidate?: never;
      readonly snooze: InboxMessageAndDateArgs;
    };

/** Autogenerated input type of ActOnInboxMessage */
export type ActOnInboxMessageInput = {
  readonly action: ActOnInboxMessageChoice;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of ActOnInboxMessage. */
export type ActOnInboxMessagePayload = {
  readonly __typename: "ActOnInboxMessagePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<ActOnInboxMessageResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type ActOnInboxMessageResult = {
  readonly __typename: "ActOnInboxMessageResult";
  readonly inboxMessage: InboxMessage;
};

/** The change between this data point and the last */
export enum AcuteChangeCategory {
  /** There was a clinically significant worsening */
  DECLINING = "DECLINING",
  /** There was a clinically significant improvement */
  IMPROVING = "IMPROVING",
  /** The result was not valid and could not be used */
  INVALID = "INVALID",
  /** There is not enough data or the algorithm chose not to evaluate */
  NOT_SPECIFIED = "NOT_SPECIFIED",
  /** Acute change is not supported */
  NOT_SUPPORTED = "NOT_SUPPORTED",
  /** There was no clinically significant change */
  STABLE = "STABLE",
}

/** The algorithms available for calculating acute change */
export enum AcuteChangeType {
  /** Any change in categorical result will yield a trend */
  CATEGORICAL = "CATEGORICAL",
  /** Use Reliable Change Index to calculate trend */
  RCI = "RCI",
}

/** Autogenerated input type of AddCareEpisodeProvider */
export type AddCareEpisodeProviderInput = {
  readonly careEpisodeId: Scalars["CareEpisodeId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly providerId: Scalars["ProviderId"]["input"];
  readonly relationship: CareEpisodeProviderRelationship;
};

/** Autogenerated return type of AddCareEpisodeProvider. */
export type AddCareEpisodeProviderPayload = {
  readonly __typename: "AddCareEpisodeProviderPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<AddCareEpisodeProviderResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type AddCareEpisodeProviderResult = {
  readonly __typename: "AddCareEpisodeProviderResult";
  readonly careEpisode: CareEpisode;
  readonly careEpisodeProvider: CareEpisodeProvider;
};

/** Autogenerated input type of AddInstituteToInstituteGroup */
export type AddInstituteToInstituteGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly instituteGroupId: Scalars["InstituteGroupId"]["input"];
  readonly memberId: Scalars["InstituteId"]["input"];
};

/** Autogenerated return type of AddInstituteToInstituteGroup. */
export type AddInstituteToInstituteGroupPayload = {
  readonly __typename: "AddInstituteToInstituteGroupPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<AddInstituteToInstituteGroupResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type AddInstituteToInstituteGroupResult = {
  readonly __typename: "AddInstituteToInstituteGroupResult";
  readonly instituteGroup: InstituteGroup;
};

/** Autogenerated input type of AddNoteToTask */
export type AddNoteToTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly taskId: Scalars["TaskId"]["input"];
  readonly text: Scalars["String"]["input"];
};

/** Autogenerated return type of AddNoteToTask. */
export type AddNoteToTaskPayload = {
  readonly __typename: "AddNoteToTaskPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<AddNoteToTaskResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type AddNoteToTaskResult = {
  readonly __typename: "AddNoteToTaskResult";
  readonly note: Note;
};

/** Autogenerated input type of AddTaskToDefaultTaskListAtPosition */
export type AddTaskToDefaultTaskListAtPositionInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly position: Scalars["Int"]["input"];
  readonly taskId: Scalars["TaskId"]["input"];
};

/** Autogenerated return type of AddTaskToDefaultTaskListAtPosition. */
export type AddTaskToDefaultTaskListAtPositionPayload = {
  readonly __typename: "AddTaskToDefaultTaskListAtPositionPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<AddTaskToDefaultTaskListAtPositionResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type AddTaskToDefaultTaskListAtPositionResult = {
  readonly __typename: "AddTaskToDefaultTaskListAtPositionResult";
  readonly taskListItem: TaskListItem;
};

/** Autogenerated input type of AddTaskToDefaultTaskList */
export type AddTaskToDefaultTaskListInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly taskId: Scalars["TaskId"]["input"];
};

/** Autogenerated return type of AddTaskToDefaultTaskList. */
export type AddTaskToDefaultTaskListPayload = {
  readonly __typename: "AddTaskToDefaultTaskListPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<AddTaskToDefaultTaskListResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type AddTaskToDefaultTaskListResult = {
  readonly __typename: "AddTaskToDefaultTaskListResult";
  readonly taskListItem: TaskListItem;
};

/** Autogenerated input type of AddTreatmentTarget */
export type AddTreatmentTargetInput = {
  readonly careEpisodeId: Scalars["CareEpisodeId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly treatmentTargetId: Scalars["TreatmentTargetId"]["input"];
};

/** Autogenerated return type of AddTreatmentTarget. */
export type AddTreatmentTargetPayload = {
  readonly __typename: "AddTreatmentTargetPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<AddTreatmentTargetResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type AddTreatmentTargetResult = {
  readonly __typename: "AddTreatmentTargetResult";
  readonly careEpisodeTreatmentTarget: CareEpisodeTreatmentTarget;
};

export type AdministrableScale = Scale & {
  readonly __typename: "AdministrableScale";
  readonly acuteChangeType: Maybe<AcuteChangeType>;
  /** Recommended maximum frequency for administration */
  readonly adminInterval: Maybe<Scalars["String"]["output"]>;
  readonly allowedParticipants: Maybe<ReadonlyArray<ParticipantRelationship>>;
  readonly clinicalCategoryType: Maybe<ClinicalCategoryType>;
  readonly construct: Maybe<Construct>;
  readonly defaultParticipants: Maybe<ReadonlyArray<ParticipantRelationship>>;
  readonly description: Maybe<Scalars["String"]["output"]>;
  /** If this scale is allowed to be administered */
  readonly disabled: Scalars["Boolean"]["output"];
  readonly displayData: Maybe<ScaleDisplayData>;
  /** Number of seconds estimated for completion */
  readonly estimatedTime: Maybe<Scalars["Int"]["output"]>;
  readonly friendlyName: Maybe<Scalars["String"]["output"]>;
  /** If this scale is only suitable for a given gender */
  readonly gender: Maybe<Gender>;
  readonly globalId: Scalars["ID"]["output"];
  /** A link to a downloadable guide about the scale */
  readonly guideUrl: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["ScaleId"]["output"];
  /** Can this scale be directly administered? */
  readonly independent: Scalars["Boolean"]["output"];
  /** Whether this measure a positive aspect or a symptom */
  readonly isBeneficial: Maybe<Scalars["Boolean"]["output"]>;
  /** The locales this scale is available for. English if not present */
  readonly locale: Maybe<ReadonlyArray<ScaleLocale>>;
  /** Max recommended age for this scale */
  readonly maxAge: Maybe<Scalars["Int"]["output"]>;
  readonly measureType: ScaleMeasureType;
  /** Minimum recommended age for this scale */
  readonly minAge: Maybe<Scalars["Int"]["output"]>;
  readonly mnemonic: Scalars["String"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly nanoname: Maybe<Scalars["String"]["output"]>;
  readonly norms: ReadonlyArray<Norm>;
  readonly parent: Maybe<Scale>;
  readonly parentId: Maybe<Scalars["ScaleId"]["output"]>;
  readonly questions: ReadonlyArray<Question>;
  /** Short description for use inside clinical report */
  readonly reportShortDescription: Maybe<Scalars["String"]["output"]>;
  readonly role: Maybe<ScaleRole>;
  readonly scaleGroup: Maybe<ScaleGroup>;
  readonly scaleScorers: ReadonlyArray<ScaleScorer>;
  /** Additional scoring explanation */
  readonly scoringText: Maybe<Scalars["String"]["output"]>;
  readonly severityCategoryType: Maybe<SeverityCategoryType>;
  readonly shortname: Maybe<Scalars["String"]["output"]>;
  /** Additional tags about the scale */
  readonly tags: Maybe<ReadonlyArray<Scalars["String"]["output"]>>;
  readonly trendType: Maybe<TrendType>;
};

/** The connection type for AdministrableScale. */
export type AdministrableScaleConnection = {
  readonly __typename: "AdministrableScaleConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<AdministrableScaleEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<AdministrableScale>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type AdministrableScaleEdge = {
  readonly __typename: "AdministrableScaleEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: AdministrableScale;
};

export enum AdministrableScaleSortParameter {
  /** Estimated completion time */
  ESTIMATED_TIME = "ESTIMATED_TIME",
  /** Id */
  ID = "ID",
  /** Name */
  NAME = "NAME",
}

export type Answer = {
  readonly __typename: "Answer";
  readonly computedValues: ReadonlyArray<ComputedValue>;
  readonly endorsed: Maybe<Scalars["Boolean"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["AnswerId"]["output"];
  readonly invitation: Maybe<Invitation>;
  readonly itemCoding: Maybe<ItemCoding>;
  readonly normalizedScore: Maybe<NormalizedScore>;
  readonly option: Maybe<QuestionOption>;
  readonly optionId: Maybe<Scalars["QuestionOptionId"]["output"]>;
  readonly question: Question;
  readonly questionId: Scalars["QuestionId"]["output"];
  readonly report: Report;
  /** Taking time information */
  readonly timeTracking: Maybe<ReadonlyArray<AnswerTimeTracking>>;
  readonly user: User;
  readonly value: Maybe<Scalars["String"]["output"]>;
};

/** Time tracking of a particular answer, i.e. when it was taken and how long it was looked at for. */
export type AnswerTimeTracking = {
  readonly __typename: "AnswerTimeTracking";
  /** Timestamp in ms since epoch when the item was closed */
  readonly close: Scalars["Int"]["output"];
  /** Timestamp in ms since epoch when the item was opened */
  readonly open: Scalars["Int"]["output"];
  /** Duration in ms of how long it was open for */
  readonly span: Scalars["Int"]["output"];
};

export type Appointment = {
  readonly __typename: "Appointment";
  readonly athenaAppointmentEncounter: Maybe<AthenaAppointmentEncounter>;
  readonly athenaScreeningQuestionnaires: ReadonlyArray<AthenaScreeningQuestionnaire>;
  readonly careEpisode: Maybe<CareEpisode>;
  /** Whether the care episode was set manually, algorithmically, or by the EHR */
  readonly careEpisodeSource: FieldSourceEnum;
  readonly comment: Maybe<Scalars["String"]["output"]>;
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly endDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["AppointmentId"]["output"];
  readonly identifiers: Maybe<ReadonlyArray<Identifier>>;
  /** Calculates how the care episode inferred. This is expensive so do not use on list queries */
  readonly inferredCareEpisodeReason: Maybe<CareEpisodeInferenceReason>;
  /** Calculates how the treatment service was inferred. This is expensive so do not use on list queries */
  readonly inferredTreatmentServiceReason: Maybe<TreatmentServiceInferenceReason>;
  readonly integrationsDataSourceRecords: ReadonlyArray<DataSourceRecord>;
  readonly lastActiveCareEpisodeCreationAlgorithm: Maybe<DataSourceRecordAlgorithmLog>;
  readonly lastActivePatientSessionCreationAlgorithm: Maybe<DataSourceRecordAlgorithmLog>;
  readonly lastCareEpisodeCreationAlgorithm: Maybe<DataSourceRecordAlgorithmLog>;
  readonly lastImportDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly lastPatientSessionCreationAlgorithm: Maybe<DataSourceRecordAlgorithmLog>;
  readonly measurementCategory: AppointmentMeasurementCategory;
  readonly measurementStatus: AppointmentMeasurementStatus;
  readonly minutesDuration: Maybe<Scalars["Int"]["output"]>;
  readonly organization: Maybe<Organization>;
  /** Whether the organization was set manually, algorithmically, or by the EHR */
  readonly organizationSource: FieldSourceEnum;
  readonly patient: Patient;
  readonly patientId: Scalars["PatientId"]["output"];
  readonly patientSession: Maybe<PatientSession>;
  readonly provider: Maybe<Provider>;
  readonly serviceType: Maybe<Scalars["String"]["output"]>;
  readonly source: Maybe<SourceEnum>;
  readonly startDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly status: AppointmentStatus;
  /** Whether the status was set manually, algorithmically, or by the EHR */
  readonly statusSource: FieldSourceEnum;
  readonly treatmentService: Maybe<TreatmentService>;
  /** Whether the treatment service was set manually, algorithmically, or by the EHR */
  readonly treatmentServiceSource: FieldSourceEnum;
};

/** The connection type for Appointment. */
export type AppointmentConnection = {
  readonly __typename: "AppointmentConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<AppointmentEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Appointment>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type AppointmentEdge = {
  readonly __typename: "AppointmentEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Appointment;
};

export enum AppointmentMeasurementCategory {
  /** Awaiting Interview */
  AWAITING_INTERVIEW = "AWAITING_INTERVIEW",
  /** Complete */
  COMPLETE = "COMPLETE",
  /** Error */
  ERROR = "ERROR",
  /** Failed */
  FAILED = "FAILED",
  /** Future */
  FUTURE = "FUTURE",
  /** In Progress */
  IN_PROGRESS = "IN_PROGRESS",
  /** Measurement Canceled */
  MEASUREMENT_CANCELED = "MEASUREMENT_CANCELED",
  /** Notifications Blocked */
  NOTIFICATIONS_BLOCKED = "NOTIFICATIONS_BLOCKED",
  /** Not Measured */
  NOT_MEASURED = "NOT_MEASURED",
  /** Not Participating */
  NOT_PARTICIPATING = "NOT_PARTICIPATING",
  /** Not Started */
  NOT_STARTED = "NOT_STARTED",
}

export enum AppointmentMeasurementStatus {
  /** Aborted - there was a measurement associated but due to a change in the appointment it was canceled */
  ABORTED = "ABORTED",
  /** The measurement is waiting on a provider interview */
  AWAITING_INTERVIEW = "AWAITING_INTERVIEW",
  /** Complete - feedback completed and viewed */
  COMPLETE = "COMPLETE",
  /** Failed - no feedback was given and the measurement has closed */
  FAILED = "FAILED",
  /** Feedback available - some but not all feedback is available and has not been viewed */
  FEEDBACK_AVAILABLE = "FEEDBACK_AVAILABLE",
  /** Feedback complete but not yet viewed */
  FEEDBACK_COMPLETE = "FEEDBACK_COMPLETE",
  /** Future - there is a measurement but it is not active yet */
  FUTURE = "FUTURE",
  /** There is a data quality issue stopping notifications going out for this measurement */
  NOTIFICATIONS_BLOCKED = "NOTIFICATIONS_BLOCKED",
  /** Not measured - this appointment has no measurement associated */
  NOT_MEASURED = "NOT_MEASURED",
  /** Not participating in MBC */
  NOT_PARTICIPATING = "NOT_PARTICIPATING",
  /** Not started - everything is on track but no invitations have been started */
  NOT_STARTED = "NOT_STARTED",
  /** Report viewed - some but not all feedback available but all available feedback viewed */
  REPORT_VIEWED = "REPORT_VIEWED",
  /** Started - at least one invitation has started but no data has been collected yet */
  STARTED = "STARTED",
  /** Unknown - the state of measurement is unknown */
  UNKNOWN = "UNKNOWN",
}

export type AppointmentSchedule = {
  readonly __typename: "AppointmentSchedule";
  readonly currentStats: Maybe<CurrentStats>;
  readonly endDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["AppointmentId"]["output"];
  readonly measurementCategory: AppointmentMeasurementCategory;
  readonly measurementStatus: AppointmentMeasurementStatus;
  readonly minutesDuration: Maybe<Scalars["Int"]["output"]>;
  readonly patientFlags: ReadonlyArray<PatientFlag>;
  readonly patientId: Scalars["PatientId"]["output"];
  readonly patientName: Maybe<Scalars["String"]["output"]>;
  readonly patientSession: Maybe<PatientSession>;
  readonly providerId: Maybe<Scalars["ProviderId"]["output"]>;
  readonly providerName: Maybe<Scalars["String"]["output"]>;
  readonly startDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly status: AppointmentStatus;
};

/** The connection type for AppointmentSchedule. */
export type AppointmentScheduleConnection = {
  readonly __typename: "AppointmentScheduleConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<AppointmentScheduleEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<AppointmentSchedule>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type AppointmentScheduleEdge = {
  readonly __typename: "AppointmentScheduleEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: AppointmentSchedule;
};

export enum AppointmentScheduleSortParameter {
  /** Id */
  ID = "ID",
  /** The start date of the appointment */
  STARTDATE = "STARTDATE",
}

export enum AppointmentSortParameter {
  /** Id */
  ID = "ID",
  /** The start date of the appointment */
  STARTDATE = "STARTDATE",
}

export enum AppointmentStatus {
  /** Arrived */
  ARRIVED = "ARRIVED",
  /** Booked */
  BOOKED = "BOOKED",
  /** Canceled */
  CANCELED = "CANCELED",
  /** Checked in */
  CHECKED_IN = "CHECKED_IN",
  /** Entered in error */
  ENTERED_IN_ERROR = "ENTERED_IN_ERROR",
  /** Fulfilled */
  FULFILLED = "FULFILLED",
  /** No-show */
  NOSHOW = "NOSHOW",
  /** Pending */
  PENDING = "PENDING",
  /** Proposed */
  PROPOSED = "PROPOSED",
  /** Wait list */
  WAIT_LIST = "WAIT_LIST",
}

/** Autogenerated input type of AssignDocumentationEncounter */
export type AssignDocumentationEncounterInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly encounterId: Scalars["EncounterId"]["input"];
  readonly enrollmentMonthId: Scalars["EnrollmentMonthId"]["input"];
  /** If true, overwrites existing encounters. If false, only items which have not had an encounter set will be updated */
  readonly overwrite?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Autogenerated return type of AssignDocumentationEncounter. */
export type AssignDocumentationEncounterPayload = {
  readonly __typename: "AssignDocumentationEncounterPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<AssignDocumentationEncounterResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type AssignDocumentationEncounterResult = {
  readonly __typename: "AssignDocumentationEncounterResult";
  readonly enrollmentMonth: EnrollmentMonth;
};

export type AthenaAppointmentEncounter = {
  readonly __typename: "AthenaAppointmentEncounter";
  readonly appointment: Appointment;
  readonly appointmentId: Scalars["AppointmentId"]["output"];
  readonly athenaEncounterId: Scalars["String"]["output"];
  readonly athenaPatientId: Scalars["String"]["output"];
  readonly athenaScreeningQuestionnaires: ReadonlyArray<AthenaScreeningQuestionnaire>;
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly departmentId: Scalars["String"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["AthenaAppointmentEncounterId"]["output"];
  readonly updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type AthenaScreeningQuestionnaire = {
  readonly __typename: "AthenaScreeningQuestionnaire";
  readonly appointmentEncounter: Maybe<AthenaAppointmentEncounter>;
  readonly appointmentEncounterId: Maybe<Scalars["AthenaAppointmentEncounterId"]["output"]>;
  readonly athenaDocumentId: Maybe<Scalars["String"]["output"]>;
  readonly athenaQuestionaireId: Maybe<Scalars["String"]["output"]>;
  readonly computedValueId: Scalars["ComputedValueId"]["output"];
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["AthenaScreeningQuestionnaireId"]["output"];
  readonly pdfS3Location: Maybe<Scalars["String"]["output"]>;
  readonly scale: Scale;
  readonly schedulingAppointment: Appointment;
  readonly schedulingAppointmentId: Scalars["AppointmentId"]["output"];
  readonly status: AthenaScreeningQuestionnaireStatus;
  readonly updatedAt: Scalars["ISO8601DateTime"]["output"];
};

/** The status for an Athena Screening Questionaire */
export enum AthenaScreeningQuestionnaireStatus {
  /** This send has errored */
  ERROR = "ERROR",
  /** This has some data, but is not yet ready to send. */
  PARTIAL = "PARTIAL",
  /** The data is ready to be sent */
  READY = "READY",
  /** The data has been sucessfully sent */
  SEND_COMPLETE = "SEND_COMPLETE",
  /** Data is currently sending */
  SEND_IN_PROGRESS = "SEND_IN_PROGRESS",
}

/** Mechanisms for authentication to our platform */
export enum AuthenticationMechanism {
  /** Athena SAML */
  ATHENA = "ATHENA",
  /** Epic OAuth */
  EPIC = "EPIC",
  /** Password authentication */
  PASSWORD = "PASSWORD",
  /** Generic SAML */
  SAML = "SAML",
}

export type BusinessEvent = {
  readonly __typename: "BusinessEvent";
  readonly analyticsEventId: Maybe<Scalars["AnalyticsEventId"]["output"]>;
  readonly assessmentInvitation: Maybe<Invitation>;
  readonly assessmentReport: Maybe<Report>;
  readonly data: Maybe<Scalars["BusinessEventData"]["output"]>;
  readonly eventDate: Scalars["ISO8601DateTime"]["output"];
  readonly eventName: Scalars["String"]["output"];
  readonly eventUuid: Maybe<Scalars["String"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["BusinessEventId"]["output"];
  readonly institute: Institute;
  readonly isTest: Scalars["Boolean"]["output"];
  readonly key: Maybe<Scalars["String"]["output"]>;
  readonly patient: Maybe<Patient>;
  readonly patientSession: Maybe<PatientSession>;
  readonly provider: Maybe<Provider>;
  readonly relatedPerson: Maybe<RelatedPerson>;
  readonly schedulingAppointmentId: Maybe<Appointment>;
  readonly user: Maybe<User>;
};

/** The connection type for BusinessEvent. */
export type BusinessEventConnection = {
  readonly __typename: "BusinessEventConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<BusinessEventEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<BusinessEvent>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type BusinessEventEdge = {
  readonly __typename: "BusinessEventEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: BusinessEvent;
};

export enum BusinessEventSortParameter {
  /** Event Date */
  EVENT_DATE = "EVENT_DATE",
  /** Id */
  ID = "ID",
}

/** Whether something was calculated by Mirah or an external system. */
export enum CalculationMechanism {
  /** This data was calculating externally and supplied to Mirah */
  EXTERNAL = "EXTERNAL",
  /** This data was calculated by Mirah */
  MIRAH = "MIRAH",
}

/** Autogenerated input type of CancelAppointment */
export type CancelAppointmentInput = {
  readonly appointmentId: Scalars["AppointmentId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of CancelAppointment. */
export type CancelAppointmentPayload = {
  readonly __typename: "CancelAppointmentPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CancelAppointmentResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CancelAppointmentResult = {
  readonly __typename: "CancelAppointmentResult";
  readonly appointment: Appointment;
};

/** Autogenerated input type of CancelCaseConsult */
export type CancelCaseConsultInput = {
  readonly caseConsultId: Scalars["CaseConsultId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of CancelCaseConsult. */
export type CancelCaseConsultPayload = {
  readonly __typename: "CancelCaseConsultPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CancelCaseConsultResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CancelCaseConsultResult = {
  readonly __typename: "CancelCaseConsultResult";
  readonly caseConsult: CaseConsult;
};

export type CanvasEhrLinkDetails = {
  readonly __typename: "CanvasEhrLinkDetails";
  readonly baseUrl: Maybe<Scalars["String"]["output"]>;
  readonly emrBaseUrl: Maybe<Scalars["String"]["output"]>;
  readonly oauthApplicationClientId: Maybe<Scalars["String"]["output"]>;
  readonly oauthApplicationClientSecret: Maybe<Scalars["String"]["output"]>;
};

export type CareEpisode = {
  readonly __typename: "CareEpisode";
  readonly assessmentCareEpisodeScaleScorerSummaries: ReadonlyArray<CareEpisodeScaleScorerSummary>;
  readonly assessmentCareEpisodeScales: ReadonlyArray<CareEpisodeScaleScorerSummary>;
  readonly assessmentGoals: ReadonlyArray<Goal>;
  readonly careEpisodeMessages: ReadonlyArray<CareEpisodeScaleScorerSummary>;
  readonly careEpisodeProviders: ReadonlyArray<CareEpisodeProvider>;
  readonly careEpisodeTreatmentTracks: ReadonlyArray<CareEpisodeTreatmentTrack>;
  readonly collaborativeCareCareEpisodeTreatmentTargets: ReadonlyArray<CareEpisodeTreatmentTarget>;
  readonly collaborativeCareEnrollment: Maybe<Enrollment>;
  readonly dischargeDueToInactivityDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly entityTreeNode: EntityTreeNode;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CareEpisodeId"]["output"];
  readonly identifiers: Maybe<ReadonlyArray<Identifier>>;
  readonly institute: Institute;
  readonly integrationsDataSourceRecords: ReadonlyArray<DataSourceRecord>;
  readonly measurementAllowed: Scalars["Boolean"]["output"];
  /** A name field that includes the patient name */
  readonly name: Scalars["String"]["output"];
  readonly organization: Organization;
  readonly participants: ReadonlyArray<Participant>;
  readonly patient: Patient;
  readonly patientConditions: ReadonlyArray<PatientCondition>;
  readonly patientId: Scalars["PatientId"]["output"];
  readonly patientSessions: PatientSessionConnection;
  readonly periodEnd: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly periodStart: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly providers: ReadonlyArray<Provider>;
  readonly schedulingAppointments: AppointmentConnection;
  /** A name field that does not include the patient name */
  readonly shortname: Scalars["String"]["output"];
  readonly status: CareEpisodeStatus;
  readonly treatmentService: Maybe<TreatmentService>;
};

export type CareEpisodeassessmentCareEpisodeScaleScorerSummariesArgs = {
  headline: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CareEpisodeassessmentCareEpisodeScalesArgs = {
  headline: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CareEpisodecareEpisodeMessagesArgs = {
  headline: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CareEpisodecareEpisodeProvidersArgs = {
  careEpisodeStatus: InputMaybe<ReadonlyArray<CareEpisodeStatus>>;
  relationship: InputMaybe<ReadonlyArray<CareEpisodeProviderRelationship>>;
};

export type CareEpisodecareEpisodeTreatmentTracksArgs = {
  careEpisodeId: InputMaybe<ReadonlyArray<Scalars["CareEpisodeId"]["input"]>>;
  status: InputMaybe<ReadonlyArray<CareEpisodeTreatmentTrackStatus>>;
};

export type CareEpisodecollaborativeCareCareEpisodeTreatmentTargetsArgs = {
  status: InputMaybe<CareEpisodeTreatmentTargetStatus>;
};

export type CareEpisodepatientConditionsArgs = {
  instituteConditionId: InputMaybe<ReadonlyArray<Scalars["InstituteConditionId"]["input"]>>;
  patientId: InputMaybe<Scalars["PatientId"]["input"]>;
  status: InputMaybe<ReadonlyArray<PatientConditionStatus>>;
};

export type CareEpisodepatientSessionsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<PatientSessionSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<PatientSessionStatus>>;
  targetDate: InputMaybe<DateRangeInput>;
};

export type CareEpisodeprovidersArgs = {
  active: InputMaybe<Scalars["Boolean"]["input"]>;
  defaultRelationship: InputMaybe<ReadonlyArray<CareEpisodeProviderRelationship>>;
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  measurementAllowed: InputMaybe<Scalars["Boolean"]["input"]>;
  practicing: InputMaybe<Scalars["Boolean"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
};

export type CareEpisodeschedulingAppointmentsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careEpisodeId: InputMaybe<Scalars["CareEpisodeId"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  flags: InputMaybe<ReadonlyArray<Scalars["FlagId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  measurementStatus: InputMaybe<ReadonlyArray<AppointmentMeasurementStatus>>;
  patientFlagId: InputMaybe<ReadonlyArray<Scalars["PatientFlagId"]["input"]>>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  provider: InputMaybe<ProviderFilter>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<AppointmentSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  startDateAfter: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  startDateBefore: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  status: InputMaybe<ReadonlyArray<AppointmentStatus>>;
  testClient: InputMaybe<Scalars["Boolean"]["input"]>;
  unit: InputMaybe<OrganizationFilter>;
};

export type CareEpisodeClinicalAlert = {
  readonly __typename: "CareEpisodeClinicalAlert";
  readonly answers: ReadonlyArray<Answer>;
  /** Measurement effective date */
  readonly effectiveDate: Scalars["ISO8601DateTime"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly invitations: ReadonlyArray<Invitation>;
  /** The report id, used to know whether you can directly compare multi-respondent values */
  readonly reportId: Scalars["ReportId"]["output"];
  /** Session number this was taken as part of */
  readonly sessionNumber: Scalars["Int"]["output"];
  readonly status: ClinicalAlertValue;
  /** Measurement target date */
  readonly targetDate: Scalars["ISO8601DateTime"]["output"];
};

export type CareEpisodeClinicalAlertHistory = {
  readonly __typename: "CareEpisodeClinicalAlertHistory";
  readonly alertType: ClinicalAlertType;
  readonly globalId: Scalars["ID"]["output"];
  readonly history: ReadonlyArray<CareEpisodeClinicalAlert>;
  readonly id: Scalars["CareEpisodeScaleId"]["output"];
  readonly latest: Maybe<CareEpisodeClinicalAlert>;
  readonly scale: Maybe<AdministrableScale>;
};

export type CareEpisodeComputedValue = {
  readonly __typename: "CareEpisodeComputedValue";
  readonly acuteChangeCategory: AcuteChangeCategory;
  readonly answers: ReadonlyArray<Answer>;
  readonly clinicalCategory: ClinicalCategory;
  /**
   * Date used for objects that want a date field. Invitation completed or begun
   * date if available, otherwise session target date
   */
  readonly date: Scalars["ISO8601DateTime"]["output"];
  readonly declinedQuestions: Maybe<Scalars["Int"]["output"]>;
  /** Measurement effective date */
  readonly effectiveDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly externalObservation: Maybe<ExternalObservation>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CareEpisodeComputedValueId"]["output"];
  /** Is this value in the current report */
  readonly inCurrentReport: Scalars["Boolean"]["output"];
  readonly interpretationMechanism: CalculationMechanism;
  readonly mostRelevantInvitation: Maybe<Invitation>;
  readonly patient: Patient;
  readonly patientId: Scalars["PatientId"]["output"];
  readonly population: Maybe<Population>;
  /** The recency of this value compared to the current report, even if the report is out of the specified window. */
  readonly recency: Scalars["Int"]["output"];
  readonly report: Maybe<Report>;
  /** The report id, used to know whether you can directly compare multi-respondent values */
  readonly reportId: Scalars["ReportId"]["output"];
  readonly responsesAvailable: ResponsesAvailable;
  readonly scale: Scale;
  readonly scaleId: Scalars["ScaleId"]["output"];
  readonly scorer: ScaleScorer;
  readonly scorerId: Scalars["ScaleScorerId"]["output"];
  readonly scoringMechanism: CalculationMechanism;
  /** Session number this was taken as part of */
  readonly sessionNumber: Scalars["Int"]["output"];
  readonly severityCategory: SeverityCategory;
  /** Measurement target date */
  readonly targetDate: Scalars["ISO8601DateTime"]["output"];
  readonly thresholdClass: Maybe<ScaleThresholdClass>;
  readonly thresholdMnemonic: Maybe<Scalars["String"]["output"]>;
  readonly totalQuestions: Maybe<Scalars["Int"]["output"]>;
  readonly trend: Trend;
  readonly unansweredQuestions: Maybe<Scalars["Int"]["output"]>;
  readonly user: Maybe<User>;
  readonly validity: ComputedValueValidity;
  readonly value: Maybe<Scalars["Float"]["output"]>;
};

/** The connection type for CareEpisodeComputedValue. */
export type CareEpisodeComputedValueConnection = {
  readonly __typename: "CareEpisodeComputedValueConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<CareEpisodeComputedValueEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<CareEpisodeComputedValue>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type CareEpisodeComputedValueEdge = {
  readonly __typename: "CareEpisodeComputedValueEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: CareEpisodeComputedValue;
};

export enum CareEpisodeComputedValueSortParameter {
  /** Id */
  ID = "ID",
}

/** The connection type for CareEpisode. */
export type CareEpisodeConnection = {
  readonly __typename: "CareEpisodeConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<CareEpisodeEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<CareEpisode>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

export type CareEpisodeConstruct = {
  readonly __typename: "CareEpisodeConstruct";
  readonly construct: Maybe<Construct>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CareEpisodeConstructId"]["output"];
  readonly scaleSections: ReadonlyArray<CareEpisodeScaleSection>;
};

/** An edge in a connection. */
export type CareEpisodeEdge = {
  readonly __typename: "CareEpisodeEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: CareEpisode;
};

/** How and why a care episode was inferred */
export enum CareEpisodeInferenceReason {
  /** Appointments require a treatment service to be matched and this appointment has no treatment service */
  APPOINTMENT_NO_TREATMENT_SERVICE = "APPOINTMENT_NO_TREATMENT_SERVICE",
  /** Mirah is receiving episodes of care from the EHR and assumes that these should be chosen */
  CARE_EPISODES_MANAGED_EXTERNALLY = "CARE_EPISODES_MANAGED_EXTERNALLY",
  /** There is exactly one episode of care in the same organizations as the provider */
  EXACT_MATCH_ORGANIZATION = "EXACT_MATCH_ORGANIZATION",
  /** An active episode of care with a matching treatment service was found */
  MATCHED_TREATMENT_SERVICE = "MATCHED_TREATMENT_SERVICE",
  /**
   * There are multiple episodes of care in the same organization as the provider,
   * the one with the most recent measurement has been chosen.
   */
  MOST_RECENT_MATCH_ORGANIZATION = "MOST_RECENT_MATCH_ORGANIZATION",
  /** No episodes of care matched the exact criteria, so the most recent active episode of care was chosen */
  NO_MATCH_DEFAULT = "NO_MATCH_DEFAULT",
  /** There are no episodes of care suitable to be used */
  NO_MATCH_FOUND = "NO_MATCH_FOUND",
  /** The provider is on the care team given */
  PROVIDER_ON_CARE_TEAM = "PROVIDER_ON_CARE_TEAM",
  /** The system is configured to only allow one episode of care at a time, and that episode of care will always be chosen */
  SINGLE_EPISODE_OF_CARE_ALLOWED = "SINGLE_EPISODE_OF_CARE_ALLOWED",
}

export type CareEpisodeMessage = {
  readonly __typename: "CareEpisodeMessage";
  readonly careEpisode: Maybe<CareEpisode>;
  readonly completedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly content: Maybe<Scalars["CareEpisodeMessageData"]["output"]>;
  readonly deliveredAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CareEpisodeMessageId"]["output"];
  readonly response: Maybe<Scalars["String"]["output"]>;
  readonly status: CareEpisodeMessageStatus;
  readonly user: User;
};

/** The connection type for CareEpisodeMessage. */
export type CareEpisodeMessageConnection = {
  readonly __typename: "CareEpisodeMessageConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<CareEpisodeMessageEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<CareEpisodeMessage>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type CareEpisodeMessageEdge = {
  readonly __typename: "CareEpisodeMessageEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: CareEpisodeMessage;
};

export enum CareEpisodeMessageSortParameter {
  /** Id */
  ID = "ID",
}

/** Statuses for a care episode message, e.g. has it been delivered */
export enum CareEpisodeMessageStatus {
  /** The message has not been delivered or sent */
  ACTIVE = "ACTIVE",
  /** The message has been canceled and will not be delivered */
  CANCELED = "CANCELED",
  /** The message has been viewed and is deemed complete */
  COMPLETE = "COMPLETE",
}

export type CareEpisodeProvider = {
  readonly __typename: "CareEpisodeProvider";
  readonly careEpisode: CareEpisode;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CareEpisodeProviderId"]["output"];
  readonly patient: Patient;
  readonly patientId: Scalars["PatientId"]["output"];
  readonly provider: Provider;
  readonly relationship: CareEpisodeProviderRelationship;
};

/** The connection type for CareEpisodeProvider. */
export type CareEpisodeProviderConnection = {
  readonly __typename: "CareEpisodeProviderConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<CareEpisodeProviderEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<CareEpisodeProvider>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type CareEpisodeProviderEdge = {
  readonly __typename: "CareEpisodeProviderEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: CareEpisodeProvider;
};

/** A provider and the relationship they have to this care episode */
export type CareEpisodeProviderInput = {
  readonly providerId: Scalars["ProviderId"]["input"];
  readonly relationship: CareEpisodeProviderRelationship;
};

export enum CareEpisodeProviderRelationship {
  /** Administrator */
  ADMINISTRATOR = "ADMINISTRATOR",
  /** Care manager */
  CARE_MANAGER = "CARE_MANAGER",
  /** Case manager */
  CASE_MANAGER = "CASE_MANAGER",
  /** Clinician */
  CLINICIAN = "CLINICIAN",
  /** Counselor */
  COUNSELOR = "COUNSELOR",
  /** Director */
  DIRECTOR = "DIRECTOR",
  /** Primary care physician */
  PRIMARY_CARE_PHYSICIAN = "PRIMARY_CARE_PHYSICIAN",
  /** Psychiatrist */
  PSYCHIATRIST = "PSYCHIATRIST",
  /** Psychologist */
  PSYCHOLOGIST = "PSYCHOLOGIST",
  /** Research assistant */
  RESEARCH_ASSISTANT = "RESEARCH_ASSISTANT",
  /** Social_worker */
  SOCIAL_WORKER = "SOCIAL_WORKER",
  /** Therapist */
  THERAPIST = "THERAPIST",
}

export enum CareEpisodeProviderSortParameter {
  /** Id */
  ID = "ID",
}

export type CareEpisodeScale = {
  readonly __typename: "CareEpisodeScale";
  readonly blinding: ScaleBlinding;
  readonly careEpisodeScaleScorerSummaries: ReadonlyArray<CareEpisodeScaleScorerSummary>;
  readonly careMeasurementPlanScales: ReadonlyArray<MeasurementPlanScale>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CareEpisodeScaleId"]["output"];
  readonly scale: AdministrableScale;
  readonly scaleId: Scalars["ScaleId"]["output"];
  readonly targeting: ScaleTargeting;
};

export type CareEpisodeScalecareEpisodeScaleScorerSummariesArgs = {
  headline: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CareEpisodeScaleByScorer = {
  readonly __typename: "CareEpisodeScaleByScorer";
  readonly byUser: ReadonlyArray<CareEpisodeScaleByUserByScorer>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly scale: AdministrableScale;
  readonly scorer: ScaleScorer;
};

export type CareEpisodeScaleByUser = {
  readonly __typename: "CareEpisodeScaleByUser";
  readonly acuteChangeCategory: Maybe<AcuteChangeCategory>;
  readonly byScorer: ReadonlyArray<CareEpisodeScaleByUserByScorer>;
  readonly clinicalCategory: Maybe<ClinicalCategory>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly resultValid: Maybe<Scalars["Boolean"]["output"]>;
  readonly scale: AdministrableScale;
  readonly severityCategory: Maybe<SeverityCategory>;
  readonly trend: Maybe<Trend>;
  readonly user: Maybe<User>;
};

export type CareEpisodeScaleByUserByScorer = {
  readonly __typename: "CareEpisodeScaleByUserByScorer";
  readonly globalId: Scalars["ID"]["output"];
  readonly history: ReadonlyArray<CareEpisodeComputedValue>;
  readonly id: Scalars["ID"]["output"];
  readonly latest: CareEpisodeComputedValue;
  readonly scale: AdministrableScale;
  readonly scorer: ScaleScorer;
  readonly user: Maybe<User>;
};

/** The connection type for CareEpisodeScale. */
export type CareEpisodeScaleConnection = {
  readonly __typename: "CareEpisodeScaleConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<CareEpisodeScaleEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<CareEpisodeScale>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

export type CareEpisodeScaleDetail = {
  readonly __typename: "CareEpisodeScaleDetail";
  readonly careEpisode: CareEpisode;
  readonly careEpisodeScaleResult: CareEpisodeScaleResult;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly scale: AdministrableScale;
};

/** An edge in a connection. */
export type CareEpisodeScaleEdge = {
  readonly __typename: "CareEpisodeScaleEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: CareEpisodeScale;
};

export type CareEpisodeScaleGroupDetail = {
  readonly __typename: "CareEpisodeScaleGroupDetail";
  readonly careEpisode: CareEpisode;
  readonly careEpisodeScaleGroupResult: CareEpisodeScaleGroupResult;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly scaleGroup: ScaleGroup;
};

export type CareEpisodeScaleGroupReport = {
  readonly __typename: "CareEpisodeScaleGroupReport";
  readonly careEpisode: CareEpisode;
  readonly careEpisodeScaleGroupResult: CareEpisodeScaleGroupResult;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly report: Report;
  readonly scaleGroup: ScaleGroup;
};

export type CareEpisodeScaleGroupResult = {
  readonly __typename: "CareEpisodeScaleGroupResult";
  readonly byScale: ReadonlyArray<CareEpisodeScaleResult>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly scaleGroup: ScaleGroup;
};

export type CareEpisodeScaleReport = {
  readonly __typename: "CareEpisodeScaleReport";
  readonly careEpisode: CareEpisode;
  readonly careEpisodeScaleResult: CareEpisodeScaleResult;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly report: Report;
  readonly scale: Scale;
};

export type CareEpisodeScaleResult = {
  readonly __typename: "CareEpisodeScaleResult";
  readonly byScorer: ReadonlyArray<CareEpisodeScaleByScorer>;
  readonly byUser: ReadonlyArray<CareEpisodeScaleByUser>;
  readonly careEpisodeScale: CareEpisodeScale;
  readonly careMeasurementPlanScales: ReadonlyArray<MeasurementPlanScale>;
  readonly construct: Maybe<Construct>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ID"]["output"];
  readonly scale: AdministrableScale;
  readonly targeting: ScaleTargeting;
};

export type CareEpisodeScaleScorerSummary = {
  readonly __typename: "CareEpisodeScaleScorerSummary";
  readonly careEpisodeScale: CareEpisodeScale;
  readonly firstClinicalCategory: Maybe<ClinicalCategory>;
  readonly firstComputedValue: Maybe<ComputedValue>;
  readonly firstDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly firstSessionNumber: Maybe<Scalars["Int"]["output"]>;
  readonly firstSeverityCategory: Maybe<SeverityCategory>;
  readonly firstThresholdClass: Maybe<ScaleThresholdClass>;
  readonly firstThresholdMnemonic: Maybe<Scalars["String"]["output"]>;
  readonly firstValue: Maybe<Scalars["Float"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly history: ReadonlyArray<CareEpisodeScaleScorerSummaryHistoryItem>;
  readonly id: Scalars["CareEpisodeScaleScorerSummaryId"]["output"];
  readonly lastAcuteChangeCategory: Maybe<AcuteChangeCategory>;
  readonly lastClinicalCategory: Maybe<ClinicalCategory>;
  readonly lastComputedValue: Maybe<ComputedValue>;
  readonly lastDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly lastSessionNumber: Maybe<Scalars["Int"]["output"]>;
  readonly lastSeverityCategory: Maybe<SeverityCategory>;
  readonly lastThresholdClass: Maybe<ScaleThresholdClass>;
  readonly lastThresholdMnemonic: Maybe<Scalars["String"]["output"]>;
  readonly lastTrend: Maybe<Trend>;
  readonly lastValue: Maybe<Scalars["Float"]["output"]>;
  readonly scaleScorer: ScaleScorer;
  readonly user: Maybe<ComputedValue>;
};

/** The connection type for CareEpisodeScaleScorerSummary. */
export type CareEpisodeScaleScorerSummaryConnection = {
  readonly __typename: "CareEpisodeScaleScorerSummaryConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<CareEpisodeScaleScorerSummaryEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<CareEpisodeScaleScorerSummary>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type CareEpisodeScaleScorerSummaryEdge = {
  readonly __typename: "CareEpisodeScaleScorerSummaryEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: CareEpisodeScaleScorerSummary;
};

export type CareEpisodeScaleScorerSummaryHistoryItem = {
  readonly __typename: "CareEpisodeScaleScorerSummaryHistoryItem";
  readonly globalId: Scalars["ID"]["output"];
  readonly severityCategory: Maybe<SeverityCategory>;
  readonly targetDate: Scalars["ISO8601DateTime"]["output"];
  readonly thresholdClass: Maybe<ScaleThresholdClass>;
  readonly thresholdMnemonic: Maybe<Scalars["String"]["output"]>;
  readonly value: Maybe<Scalars["Float"]["output"]>;
};

export enum CareEpisodeScaleScorerSummarySortParameter {
  /** Id */
  ID = "ID",
}

/** A section for display in the report. May be an individual scale or a scale group of collected scales */
export type CareEpisodeScaleSection = CareEpisodeScaleGroupResult | CareEpisodeScaleResult;

export enum CareEpisodeScaleSortParameter {
  /** Id */
  ID = "ID",
}

export type CareEpisodeScaleSummary = {
  readonly __typename: "CareEpisodeScaleSummary";
  readonly activeInvitations: ReadonlyArray<Invitation>;
  readonly administrationDates: ReadonlyArray<Scalars["ISO8601DateTime"]["output"]>;
  readonly alerts: ReadonlyArray<CareEpisodeClinicalAlertHistory>;
  readonly careEpisode: CareEpisode;
  readonly careEpisodeScaleResults: ReadonlyArray<CareEpisodeScaleResult>;
  readonly careEpisodeScaleSections: ReadonlyArray<CareEpisodeScaleSection>;
  readonly constructs: ReadonlyArray<CareEpisodeConstruct>;
  readonly globalId: Scalars["ID"]["output"];
  readonly goals: ReadonlyArray<Goal>;
  readonly id: Scalars["CareEpisodeId"]["output"];
  readonly invitations: ReadonlyArray<Invitation>;
  readonly specialSections: ReadonlyArray<CareEpisodeSpecialSection>;
  readonly targetSections: ReadonlyArray<CareEpisodeScaleSection>;
};

export type CareEpisodeScaleSummaryconstructsArgs = {
  includeTargetScales: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum CareEpisodeSortParameter {
  /** Id */
  ID = "ID",
  /** Measurement Allowed */
  MEASUREMENT_ALLOWED = "MEASUREMENT_ALLOWED",
  /** When the care episode ended */
  PERIODEND = "PERIODEND",
  /** When the care episode started */
  PERIODSTART = "PERIODSTART",
  /** Status */
  STATUS = "STATUS",
}

export type CareEpisodeSpecialSection = {
  readonly __typename: "CareEpisodeSpecialSection";
  readonly globalId: Scalars["ID"]["output"];
  readonly scaleSections: ReadonlyArray<CareEpisodeScaleSection>;
  readonly sectionType: CareEpisodeSpecialSectionType;
};

/** The different special display sections that are available */
export enum CareEpisodeSpecialSectionType {
  /** Special  */
  NORSE = "NORSE",
}

export enum CareEpisodeStatus {
  /** Active */
  ACTIVE = "ACTIVE",
  /** Canceled - currently unused */
  CANCELED = "CANCELED",
  /** Finished */
  FINISHED = "FINISHED",
  /** On hold - currently unused */
  ON_HOLD = "ON_HOLD",
  /** Planned - currently unused */
  PLANNED = "PLANNED",
  /** Waitlist - currently unused */
  WAITLIST = "WAITLIST",
}

export type CareEpisodeTreatmentTarget = {
  readonly __typename: "CareEpisodeTreatmentTarget";
  readonly careEpisode: CareEpisode;
  readonly endedDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly entityMeasurementPlan: EntityMeasurementPlan;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CareEpisodeTreatmentTargetId"]["output"];
  readonly startedDate: Scalars["ISO8601DateTime"]["output"];
  readonly status: CareEpisodeTreatmentTargetStatus;
  readonly treatmentTarget: TreatmentTarget;
};

/** The connection type for CareEpisodeTreatmentTarget. */
export type CareEpisodeTreatmentTargetConnection = {
  readonly __typename: "CareEpisodeTreatmentTargetConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<CareEpisodeTreatmentTargetEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<CareEpisodeTreatmentTarget>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type CareEpisodeTreatmentTargetEdge = {
  readonly __typename: "CareEpisodeTreatmentTargetEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: CareEpisodeTreatmentTarget;
};

export enum CareEpisodeTreatmentTargetSortParameter {
  /** Id */
  ID = "ID",
}

/** The status of a treatment target applied to a care episode */
export enum CareEpisodeTreatmentTargetStatus {
  /** Active */
  ACTIVE = "ACTIVE",
  /** Complete */
  COMPLETE = "COMPLETE",
}

export type CareEpisodeTreatmentTrack = {
  readonly __typename: "CareEpisodeTreatmentTrack";
  readonly careEpisode: CareEpisode;
  readonly completionDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly endSessionNumber: Maybe<Scalars["Int"]["output"]>;
  readonly enrollmentDate: Scalars["ISO8601DateTime"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CareEpisodeTreatmentTrackId"]["output"];
  readonly startSessionNumber: Scalars["Int"]["output"];
  readonly status: CareEpisodeTreatmentTrackStatus;
  readonly treatmentTrack: TreatmentTrack;
};

/** The connection type for CareEpisodeTreatmentTrack. */
export type CareEpisodeTreatmentTrackConnection = {
  readonly __typename: "CareEpisodeTreatmentTrackConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<CareEpisodeTreatmentTrackEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<CareEpisodeTreatmentTrack>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type CareEpisodeTreatmentTrackEdge = {
  readonly __typename: "CareEpisodeTreatmentTrackEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: CareEpisodeTreatmentTrack;
};

export enum CareEpisodeTreatmentTrackSortParameter {
  /** Enrollment Date */
  ENROLLMENT_DATE = "ENROLLMENT_DATE",
  /** Id */
  ID = "ID",
}

/** The available statuses for a care episode treatment track */
export enum CareEpisodeTreatmentTrackStatus {
  /** Treatment track is active for this care episode */
  ACTIVE = "ACTIVE",
  /** Treatment track is complete for this episode and no longer active */
  COMPLETE = "COMPLETE",
  /** Treatment track was enrolled in error */
  ENTERED_IN_ERROR = "ENTERED_IN_ERROR",
}

/** Autogenerated input type of CareEpisodeUpdateMeasurementAllowed */
export type CareEpisodeUpdateMeasurementAllowedInput = {
  readonly careEpisodeId: Scalars["CareEpisodeId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly measurementAllowed: Scalars["Boolean"]["input"];
};

/** Autogenerated return type of CareEpisodeUpdateMeasurementAllowed. */
export type CareEpisodeUpdateMeasurementAllowedPayload = {
  readonly __typename: "CareEpisodeUpdateMeasurementAllowedPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CareEpisodeUpdateMeasurementAllowedResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CareEpisodeUpdateMeasurementAllowedResult = {
  readonly __typename: "CareEpisodeUpdateMeasurementAllowedResult";
  readonly careEpisodeId: Scalars["CareEpisodeId"]["output"];
  readonly measurementAllowed: Scalars["Boolean"]["output"];
};

export type CareGroup = {
  readonly __typename: "CareGroup";
  readonly careUnitParentMemberships: ReadonlyArray<CareUnitMembership>;
  readonly careUnitType: Maybe<CareUnitType>;
  readonly currentCareUnitParentMemberships: ReadonlyArray<CareUnitMembership>;
  readonly globalId: Scalars["ID"]["output"];
  readonly groupCareEpisodeProviders: CareEpisodeProviderConnection;
  readonly groupCareEpisodes: CareEpisodeConnection;
  readonly groupExternalEvents: ExternalEventConnection;
  readonly groupSchedulingAppointments: AppointmentConnection;
  readonly id: Scalars["CareGroupId"]["output"];
  readonly identifiers: Maybe<ReadonlyArray<Identifier>>;
  readonly name: Scalars["String"]["output"];
  readonly pipbhcAllGroupExternalAppointments: ReadonlyArray<PipbhcExternalAppointment>;
  readonly pipbhcAllGroupSchedulingAppointments: ReadonlyArray<Appointment>;
  readonly pipbhcTreatmentGoalReports: ReadonlyArray<PipbhcTreatmentGoalReport>;
  readonly pipbhcTreatmentGoals: ReadonlyArray<PipbhcTreatmentGoal>;
};

export type CareGroupgroupCareEpisodeProvidersArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careEpisodeStatus: InputMaybe<ReadonlyArray<CareEpisodeStatus>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  relationship: InputMaybe<ReadonlyArray<CareEpisodeProviderRelationship>>;
  sortBy: InputMaybe<CareEpisodeProviderSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

export type CareGroupgroupCareEpisodesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  flagIds: InputMaybe<ReadonlyArray<Scalars["FlagId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  measurementAllowed: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationId: InputMaybe<ReadonlyArray<Scalars["OrganizationId"]["input"]>>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  periodEnd: InputMaybe<DateRangeInput>;
  periodStart: InputMaybe<DateRangeInput>;
  sortBy: InputMaybe<CareEpisodeSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<CareEpisodeStatus>>;
};

export type CareGroupgroupExternalEventsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  authorId: InputMaybe<ReadonlyArray<Scalars["UserId"]["input"]>>;
  before: InputMaybe<Scalars["String"]["input"]>;
  effectiveDate: InputMaybe<DateRangeInput>;
  externalEventTypeId: InputMaybe<ReadonlyArray<Scalars["ExternalEventTypeId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<ExternalEventSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

export type CareGroupgroupSchedulingAppointmentsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careEpisodeId: InputMaybe<Scalars["CareEpisodeId"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  flags: InputMaybe<ReadonlyArray<Scalars["FlagId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  measurementStatus: InputMaybe<ReadonlyArray<AppointmentMeasurementStatus>>;
  patientFlagId: InputMaybe<ReadonlyArray<Scalars["PatientFlagId"]["input"]>>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  provider: InputMaybe<ProviderFilter>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<AppointmentSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  startDateAfter: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  startDateBefore: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  status: InputMaybe<ReadonlyArray<AppointmentStatus>>;
  testClient: InputMaybe<Scalars["Boolean"]["input"]>;
  unit: InputMaybe<OrganizationFilter>;
};

/** The connection type for CareGroup. */
export type CareGroupConnection = {
  readonly __typename: "CareGroupConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<CareGroupEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<CareGroup>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type CareGroupEdge = {
  readonly __typename: "CareGroupEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: CareGroup;
};

export enum CareGroupSortParameter {
  /** Id */
  ID = "ID",
  LASTNAME = "LASTNAME",
  NAME = "NAME",
}

export type CareUnitMembership = {
  readonly __typename: "CareUnitMembership";
  readonly child: Patient;
  readonly endDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CareUnitMembershipId"]["output"];
  readonly parent: CareGroup;
  readonly startDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
};

export type CareUnitType = {
  readonly __typename: "CareUnitType";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CareUnitTypeId"]["output"];
  readonly isUser: Scalars["Boolean"]["output"];
  readonly mnemonic: Scalars["String"]["output"];
  readonly name: Scalars["String"]["output"];
};

/** The connection type for CareUnitType. */
export type CareUnitTypeConnection = {
  readonly __typename: "CareUnitTypeConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<CareUnitTypeEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<CareUnitType>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type CareUnitTypeEdge = {
  readonly __typename: "CareUnitTypeEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: CareUnitType;
};

export enum CareUnitTypeSortParameter {
  /** Id */
  ID = "ID",
}

export type CaseConsult = {
  readonly __typename: "CaseConsult";
  readonly caseSummary: Maybe<Scalars["String"]["output"]>;
  readonly caseSummaryTask: Maybe<Task>;
  readonly consultMeeting: Maybe<ConsultMeeting>;
  readonly consultMeetingTask: Maybe<Task>;
  readonly consultRequests: ConsultRequestConnection;
  readonly consultationDocumentation: Maybe<Scalars["String"]["output"]>;
  readonly consultationNotes: Maybe<Scalars["String"]["output"]>;
  readonly documentationTask: Maybe<Task>;
  readonly enrollment: Enrollment;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CaseConsultId"]["output"];
  readonly patient: Patient;
  readonly presentationOrder: Scalars["Int"]["output"];
  readonly status: CaseConsultStatus;
  readonly summaryUpdatedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
};

export type CaseConsultconsultRequestsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<ConsultRequestSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The connection type for CaseConsult. */
export type CaseConsultConnection = {
  readonly __typename: "CaseConsultConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<CaseConsultEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<CaseConsult>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type CaseConsultEdge = {
  readonly __typename: "CaseConsultEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: CaseConsult;
};

export enum CaseConsultSortParameter {
  /** Id */
  ID = "ID",
  /** Presentation Order */
  PRESENTATION_ORDER = "PRESENTATION_ORDER",
}

/** Which part of its lifecyle a case consult is in */
export enum CaseConsultStatus {
  /** The case is ready to be reviewed */
  AWAITING_CONSULT = "AWAITING_CONSULT",
  /** The case has been reviewed and needs to be documented in the EHR */
  AWAITING_DOCUMENTATION = "AWAITING_DOCUMENTATION",
  /** The consultation was canceled because no review was needed */
  CANCELED = "CANCELED",
  /** The case has been reviewed and all needed documentation has been created */
  COMPLETE = "COMPLETE",
  /** The case needs pre-meeting data collection */
  NEEDS_SUMMARY = "NEEDS_SUMMARY",
  /** The case has not been reviewed or updated for a long time */
  STALE = "STALE",
}

export type CategoricalScaleScorer = ScaleScorer & {
  readonly __typename: "CategoricalScaleScorer";
  readonly domain: ScaleScorerDomain;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ScaleScorerId"]["output"];
  /** Which other values are used to generate this score */
  readonly inputDomains: Maybe<ReadonlyArray<Scalars["String"]["output"]>>;
  readonly name: Scalars["String"]["output"];
  readonly scale: Scale;
  readonly scoreType: ScaleScorerType;
  readonly supportedSeverityCategories: ReadonlyArray<SeverityCategory>;
  readonly supportedTrends: ReadonlyArray<Trend>;
};

export type CernerEhrLinkDetails = {
  readonly __typename: "CernerEhrLinkDetails";
  readonly backendSecret: Maybe<Scalars["String"]["output"]>;
  readonly providerSecret: Maybe<Scalars["String"]["output"]>;
  readonly tenantId: Maybe<Scalars["String"]["output"]>;
  readonly url: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated input type of ChangeImplementationTargetDisplayOrder */
export type ChangeImplementationTargetDisplayOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly moveBefore: Scalars["Boolean"]["input"];
  readonly targetIdToChange: Scalars["ImplementationTargetId"]["input"];
  readonly targetIdToMoveNextTo: Scalars["ImplementationTargetId"]["input"];
};

/** Autogenerated return type of ChangeImplementationTargetDisplayOrder. */
export type ChangeImplementationTargetDisplayOrderPayload = {
  readonly __typename: "ChangeImplementationTargetDisplayOrderPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<ChangeImplementationTargetDisplayOrderResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type ChangeImplementationTargetDisplayOrderResult = {
  readonly __typename: "ChangeImplementationTargetDisplayOrderResult";
  readonly target: ImplementationTarget;
};

/** Autogenerated input type of ChangeMetricDisplayOrder */
export type ChangeMetricDisplayOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly metricIdToChange: Scalars["MetricId"]["input"];
  readonly metricIdToMoveNextTo: Scalars["MetricId"]["input"];
  readonly moveBefore: Scalars["Boolean"]["input"];
};

/** Autogenerated return type of ChangeMetricDisplayOrder. */
export type ChangeMetricDisplayOrderPayload = {
  readonly __typename: "ChangeMetricDisplayOrderPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<ChangeMetricDisplayOrderResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type ChangeMetricDisplayOrderResult = {
  readonly __typename: "ChangeMetricDisplayOrderResult";
  readonly metric: Metric;
};

export type ChartData = {
  readonly __typename: "ChartData";
  readonly baselineAcuity: Maybe<Scalars["String"]["output"]>;
  readonly date: Scalars["ISO8601Date"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly label: Maybe<Scalars["Float"]["output"]>;
  readonly value: Scalars["Float"]["output"];
};

/** The alert type of the scale */
export enum ClinicalAlertType {
  /** Therapeutic alliance risk */
  ALLIANCE = "ALLIANCE",
  /** Medication concerns */
  MEDICATIONS = "MEDICATIONS",
  /** Substance Use risk */
  SUBSTANCE_USE = "SUBSTANCE_USE",
  /** Suicidality risk */
  SUICIDALITY = "SUICIDALITY",
}

/** Whether this alert is active or not */
export enum ClinicalAlertValue {
  /** Alert is active */
  ALERT = "ALERT",
  /** This alert is not active */
  NO_ALERT = "NO_ALERT",
}

/** Whether this score is in a clinical range or not */
export enum ClinicalCategory {
  /** This score is in the clinical range */
  CLINICAL = "CLINICAL",
  /** The result was not valid and could not be used */
  INVALID = "INVALID",
  /** This score is not in the clinical range */
  NON_CLINICAL = "NON_CLINICAL",
  /** There is not enough data or the algorithm chose not to evaluate */
  NOT_SPECIFIED = "NOT_SPECIFIED",
  /** Acute change is not supported */
  NOT_SUPPORTED = "NOT_SUPPORTED",
}

/** The algorithms available for scoring whether a result is clinical or not */
export enum ClinicalCategoryType {
  /** Automatically calculated based on specified clinical thresholds. There may be some ambiguity if some scales  */
  THRESHOLD = "THRESHOLD",
}

export type CocmBillingAlgorithm = {
  readonly __typename: "CocmBillingAlgorithm";
  readonly codes: ReadonlyArray<CocmBillingAlgorithmCode>;
  readonly endDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly entityTreeNode: EntityTreeNode;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CocmBillingAlgorithmId"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly rules: ReadonlyArray<CocmBillingAlgorithmRule>;
  readonly startDate: Scalars["ISO8601DateTime"]["output"];
  readonly status: CocmBillingAlgorithmStatus;
};

/** What the date of service for a rule should be calculated as */
export enum CocmBillingAlgorithmBillingDate {
  /** Billing date will be the end of the calendar month */
  END_OF_MONTH = "END_OF_MONTH",
  /** billing date will be the first date the criteria were met */
  FIRST_DAY_MET = "FIRST_DAY_MET",
}

export type CocmBillingAlgorithmCode = {
  readonly __typename: "CocmBillingAlgorithmCode";
  readonly avgRatePerUnit: Money;
  readonly cocmBillingAlgorithm: CocmBillingAlgorithm;
  readonly code: Scalars["String"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CocmBillingAlgorithmCodeId"]["output"];
  readonly initialEncounter: Scalars["Boolean"]["output"];
  readonly rvusPerUnit: Scalars["Float"]["output"];
  readonly valueUnits: Scalars["Float"]["output"];
};

/** The connection type for CocmBillingAlgorithmCode. */
export type CocmBillingAlgorithmCodeConnection = {
  readonly __typename: "CocmBillingAlgorithmCodeConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<CocmBillingAlgorithmCodeEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<CocmBillingAlgorithmCode>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type CocmBillingAlgorithmCodeEdge = {
  readonly __typename: "CocmBillingAlgorithmCodeEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: CocmBillingAlgorithmCode;
};

export type CocmBillingAlgorithmCodeParams = {
  readonly avgRatePerUnit?: InputMaybe<MoneyParams>;
  readonly code?: InputMaybe<Scalars["String"]["input"]>;
  readonly id?: InputMaybe<Scalars["CocmBillingAlgorithmCodeId"]["input"]>;
  readonly rvusPerUnit?: InputMaybe<Scalars["Float"]["input"]>;
  readonly valueUnits?: InputMaybe<Scalars["Float"]["input"]>;
};

export enum CocmBillingAlgorithmCodeSortParameter {
  /** Code */
  CODE = "CODE",
  /** Id */
  ID = "ID",
}

/** The connection type for CocmBillingAlgorithm. */
export type CocmBillingAlgorithmConnection = {
  readonly __typename: "CocmBillingAlgorithmConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<CocmBillingAlgorithmEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<CocmBillingAlgorithm>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type CocmBillingAlgorithmEdge = {
  readonly __typename: "CocmBillingAlgorithmEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: CocmBillingAlgorithm;
};

/** The impact strength of an activity - low, med, or high */
export enum CocmBillingAlgorithmPowerStrength {
  /** This is a high impact activity */
  HIGH = "HIGH",
  /** This is a lower value activity */
  LOW = "LOW",
  /** This is a medium impact activity */
  MEDIUM = "MEDIUM",
}

export type CocmBillingAlgorithmRule = {
  readonly __typename: "CocmBillingAlgorithmRule";
  readonly anyAssessmentCompleted: Scalars["Boolean"]["output"];
  readonly billableMinutes: Maybe<Scalars["Int"]["output"]>;
  readonly billingDate: CocmBillingAlgorithmBillingDate;
  readonly cocmBillingAlgorithm: CocmBillingAlgorithm;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CocmBillingAlgorithmRuleId"]["output"];
  readonly minimumMinutes: Maybe<Scalars["Int"]["output"]>;
  readonly mustBeInitialEncounter: Scalars["Boolean"]["output"];
  readonly mustBeSubsequentEncounter: Scalars["Boolean"]["output"];
  readonly notBilled99492Or99493Already: Scalars["Boolean"]["output"];
  readonly priority: Scalars["Int"]["output"];
  readonly ruleCodes: ReadonlyArray<CocmBillingAlgorithmRuleCode>;
};

/** A check applied to a billing algorithm, e.g. were sufficient minutes met. */
export enum CocmBillingAlgorithmRuleCheck {
  /** Whether an assessment has been completed */
  ASSESSMENT_COMPLETED = "ASSESSMENT_COMPLETED",
  /** Whether the minimum required minutes have been met */
  MINIMUM_MINUTES = "MINIMUM_MINUTES",
  /** If true, this code can only be billed if no other encounters have been billed */
  MUST_BE_INITIAL_ENCOUNTER = "MUST_BE_INITIAL_ENCOUNTER",
  /** If true, this code can only be billed if another encounter marked as initial has been billed */
  MUST_BE_SUBSEQUENT_ENCOUNTER = "MUST_BE_SUBSEQUENT_ENCOUNTER",
  /** Whether there have already been 99492 and 99493 codes billed */
  NOT_BILLED_99492_OR_99493_ALREADY = "NOT_BILLED_99492_OR_99493_ALREADY",
}

/** The different ways we can measure the value of billing - currency, RVUs, etc. */
export enum CocmBillingAlgorithmRuleCheckValue {
  /** Measure billable minutes */
  BILLABLE_MINUTES = "BILLABLE_MINUTES",
  /** Measure billing success on estimated dollars received */
  ESTIMATED_RATE = "ESTIMATED_RATE",
  /** Measure billing success on RVUs */
  RVUS = "RVUS",
  /** Measure billing success on Care Units */
  VALUE_UNITS = "VALUE_UNITS",
}

export type CocmBillingAlgorithmRuleCode = {
  readonly __typename: "CocmBillingAlgorithmRuleCode";
  readonly code: CocmBillingAlgorithmCode;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CocmBillingAlgorithmRuleCodeId"]["output"];
  readonly rule: CocmBillingAlgorithmRule;
  readonly units: Scalars["Int"]["output"];
};

export type CocmBillingAlgorithmRuleCodeParams = {
  readonly codeString: Scalars["String"]["input"];
  readonly id?: InputMaybe<Scalars["CocmBillingAlgorithmRuleCodeId"]["input"]>;
  readonly units: Scalars["Int"]["input"];
};

/** The connection type for CocmBillingAlgorithmRule. */
export type CocmBillingAlgorithmRuleConnection = {
  readonly __typename: "CocmBillingAlgorithmRuleConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<CocmBillingAlgorithmRuleEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<CocmBillingAlgorithmRule>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type CocmBillingAlgorithmRuleEdge = {
  readonly __typename: "CocmBillingAlgorithmRuleEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: CocmBillingAlgorithmRule;
};

export type CocmBillingAlgorithmRuleParams = {
  readonly anyAssessmentCompleted: Scalars["Boolean"]["input"];
  readonly billableMinutes: Scalars["Int"]["input"];
  readonly billingDate: CocmBillingAlgorithmBillingDate;
  readonly id?: InputMaybe<Scalars["CocmBillingAlgorithmRuleId"]["input"]>;
  readonly minimumMinutes: Scalars["Int"]["input"];
  readonly mustBeInitialEncounter: Scalars["Boolean"]["input"];
  readonly mustBeSubsequentEncounter: Scalars["Boolean"]["input"];
  readonly notBilled99492Or99493Already?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly priority: Scalars["Int"]["input"];
  readonly ruleCodes: ReadonlyArray<CocmBillingAlgorithmRuleCodeParams>;
};

export enum CocmBillingAlgorithmRuleSortParameter {
  /** Id */
  ID = "ID",
  /** Priority */
  PRIORITY = "PRIORITY",
}

export enum CocmBillingAlgorithmSortParameter {
  /** End Date */
  END_DATE = "END_DATE",
  /** Id */
  ID = "ID",
  /** Name */
  NAME = "NAME",
  /** Start Date */
  START_DATE = "START_DATE",
  /** Status */
  STATUS = "STATUS",
}

/** Tracks the status of a billing algorithm */
export enum CocmBillingAlgorithmStatus {
  /** Algorithm is active. Charges will be generated */
  ACTIVE = "ACTIVE",
  /** Algorithm is retired and will not be used */
  RETIRED = "RETIRED",
}

export type CocmEstimatedBillingStatsSummary = {
  readonly __typename: "CocmEstimatedBillingStatsSummary";
  readonly currentMonthData: ReadonlyArray<CocmEstimatedBillingStatsSummaryStat>;
  readonly entityTreeNode: EntityTreeNode;
  readonly historicalAverageData: ReadonlyArray<CocmEstimatedBillingStatsSummaryStat>;
  readonly maxBillableMinutes: Scalars["Float"]["output"];
  readonly maxRateCents: Scalars["Float"]["output"];
  readonly maxRvus: Scalars["Float"]["output"];
  readonly maxValueUnits: Scalars["Float"]["output"];
  readonly previousMonthData: ReadonlyArray<CocmEstimatedBillingStatsSummaryStat>;
};

export type CocmEstimatedBillingStatsSummaryStat = {
  readonly __typename: "CocmEstimatedBillingStatsSummaryStat";
  readonly avgBillableMinutes: Scalars["Float"]["output"];
  readonly avgEnrollmentMonths: Scalars["Float"]["output"];
  readonly avgRateCents: Scalars["Float"]["output"];
  readonly avgRvus: Scalars["Float"]["output"];
  readonly avgValueUnits: Scalars["Float"]["output"];
  readonly rule: CocmBillingAlgorithmRule;
};

export type ComputedValue = {
  readonly __typename: "ComputedValue";
  readonly acuteChangeCategory: AcuteChangeCategory;
  readonly answers: ReadonlyArray<Answer>;
  readonly clinicalCategory: ClinicalCategory;
  readonly declinedQuestions: Maybe<Scalars["Int"]["output"]>;
  readonly externalObservation: Maybe<ExternalObservation>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ComputedValueId"]["output"];
  readonly interpretationMechanism: CalculationMechanism;
  readonly patient: Patient;
  readonly patientId: Scalars["PatientId"]["output"];
  readonly population: Maybe<Population>;
  readonly report: Maybe<Report>;
  readonly responsesAvailable: ResponsesAvailable;
  readonly scale: Scale;
  readonly scaleId: Scalars["ScaleId"]["output"];
  readonly scorer: ScaleScorer;
  readonly scorerId: Scalars["ScaleScorerId"]["output"];
  readonly scoringMechanism: CalculationMechanism;
  readonly severityCategory: SeverityCategory;
  readonly thresholdClass: Maybe<ScaleThresholdClass>;
  readonly thresholdMnemonic: Maybe<Scalars["String"]["output"]>;
  readonly totalQuestions: Maybe<Scalars["Int"]["output"]>;
  readonly trend: Trend;
  readonly unansweredQuestions: Maybe<Scalars["Int"]["output"]>;
  readonly user: Maybe<User>;
  readonly validity: ComputedValueValidity;
  readonly value: Maybe<Scalars["Float"]["output"]>;
};

/** The connection type for ComputedValue. */
export type ComputedValueConnection = {
  readonly __typename: "ComputedValueConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ComputedValueEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<ComputedValue>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ComputedValueEdge = {
  readonly __typename: "ComputedValueEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: ComputedValue;
};

export enum ComputedValueSortParameter {
  /** Id */
  ID = "ID",
}

/** The validity of the value, i.e. whether enough question answered etc. */
export enum ComputedValueValidity {
  /** Result was invalid, e.g. not enough questions answered */
  INVALID = "INVALID",
  /** The scale was never administered, e.g. as part of a computer adaptive scale */
  NOT_ADMINISTERED = "NOT_ADMINISTERED",
  /** The score was not applicable, e.g. they did not meet requirements to be scored */
  NOT_APPLICABLE = "NOT_APPLICABLE",
  /** Result was valid */
  VALID = "VALID",
}

/** An identifier from an external system */
export type Condition = {
  readonly __typename: "Condition";
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["ConditionId"]["output"];
  readonly identifiers: Maybe<ReadonlyArray<Identifier>>;
  readonly mnemonic: Scalars["String"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly shortname: Scalars["String"]["output"];
};

export type Consent = {
  readonly __typename: "Consent";
  readonly assentAgreeText: Maybe<TranslatedText>;
  readonly assentDeclineText: Maybe<TranslatedText>;
  readonly assentDeferText: Maybe<TranslatedText>;
  readonly assentInfoButtonText: Maybe<TranslatedText>;
  readonly assentInfoText: Maybe<TranslatedText>;
  readonly assentPreambleNoText: Maybe<TranslatedText>;
  readonly assentPreambleText: Maybe<TranslatedText>;
  readonly assentPreambleYesText: Maybe<TranslatedText>;
  readonly assentText: Maybe<TranslatedText>;
  readonly consentAgreeText: Scalars["String"]["output"];
  readonly consentDeclineText: Scalars["String"]["output"];
  readonly consentDeferText: Maybe<TranslatedText>;
  readonly consentInfoButtonText: Maybe<TranslatedText>;
  readonly consentInfoText: Maybe<TranslatedText>;
  readonly consentPreambleNoText: Maybe<TranslatedText>;
  readonly consentPreambleText: Maybe<TranslatedText>;
  readonly consentPreambleYesText: Maybe<TranslatedText>;
  readonly consentText: TranslatedText;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ConsentId"]["output"];
  readonly maxDeferrals: Maybe<Scalars["Int"]["output"]>;
  readonly mode: ConsentMode;
  readonly name: Scalars["String"]["output"];
};

export type ConsentAgreement = {
  readonly __typename: "ConsentAgreement";
  readonly consent: Consent;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ConsentAgreementId"]["output"];
  readonly patient: Patient;
  readonly patientId: Scalars["PatientId"]["output"];
  readonly status: ConsentAgreementStatus;
};

/** The connection type for ConsentAgreement. */
export type ConsentAgreementConnection = {
  readonly __typename: "ConsentAgreementConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ConsentAgreementEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<ConsentAgreement>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ConsentAgreementEdge = {
  readonly __typename: "ConsentAgreementEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: ConsentAgreement;
};

export enum ConsentAgreementSortParameter {
  /** Id */
  ID = "ID",
}

/**
 * What is the status of this consent agreement - has it been accepted, rejected, or still
 * waiting for more input.
 */
export enum ConsentAgreementStatus {
  /** Accepted and is active */
  ACTIVE = "ACTIVE",
  /** Inactive - currently unused */
  INACTIVE = "INACTIVE",
  /** Still gathering consents */
  PROPOSED = "PROPOSED",
  /** Rejected */
  REJECTED = "REJECTED",
}

/** The connection type for Consent. */
export type ConsentConnection = {
  readonly __typename: "ConsentConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ConsentEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Consent>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ConsentEdge = {
  readonly __typename: "ConsentEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Consent;
};

export enum ConsentMode {
  /** Guardian only */
  GUARDIAN = "GUARDIAN",
  /** Patient only */
  PATIENT = "PATIENT",
  /** Always administer to both patient and guardian */
  PATIENT_AND_GUARDIAN = "PATIENT_AND_GUARDIAN",
  /** If a child, administer to both patient and guardian, else just patient */
  PATIENT_AND_GUARDIAN_IF_CHILD = "PATIENT_AND_GUARDIAN_IF_CHILD",
}

export enum ConsentSortParameter {
  /** Id */
  ID = "ID",
}

export type Construct = {
  readonly __typename: "Construct";
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly displayOrder: Scalars["Int"]["output"];
  readonly domain: Domain;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ConstructId"]["output"];
  readonly mnemonic: Scalars["String"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly shortname: Scalars["String"]["output"];
};

/** The connection type for Construct. */
export type ConstructConnection = {
  readonly __typename: "ConstructConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ConstructEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Construct>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ConstructEdge = {
  readonly __typename: "ConstructEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Construct;
};

export type ConsultMeeting = {
  readonly __typename: "ConsultMeeting";
  readonly attendees: Maybe<ConsultMeetingAttendeeConnection>;
  readonly cases: Maybe<CaseConsultConnection>;
  readonly endTime: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ConsultMeetingId"]["output"];
  readonly startTime: Scalars["ISO8601DateTime"]["output"];
  readonly status: ConsultMeetingStatus;
};

export type ConsultMeetingattendeesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<ConsultMeetingAttendeeSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

export type ConsultMeetingcasesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careManagerId: InputMaybe<Scalars["ProviderId"]["input"]>;
  consultMeetingId: InputMaybe<Scalars["ConsultMeetingId"]["input"]>;
  enrollmentId: InputMaybe<Scalars["EnrollmentId"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patient: InputMaybe<PatientFilter>;
  primaryCareProviderId: InputMaybe<Scalars["ProviderId"]["input"]>;
  sortBy: InputMaybe<CaseConsultSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<CaseConsultStatus>>;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ConsultMeetingAttendee = {
  readonly __typename: "ConsultMeetingAttendee";
  readonly consultMeeting: ConsultMeeting;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ConsultMeetingAttendeeId"]["output"];
  readonly provider: Provider;
  readonly role: ConsultMeetingAttendeeRole;
};

/** The connection type for ConsultMeetingAttendee. */
export type ConsultMeetingAttendeeConnection = {
  readonly __typename: "ConsultMeetingAttendeeConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ConsultMeetingAttendeeEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<ConsultMeetingAttendee>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ConsultMeetingAttendeeEdge = {
  readonly __typename: "ConsultMeetingAttendeeEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: ConsultMeetingAttendee;
};

/** What purpose each attendee serves in the meeting */
export enum ConsultMeetingAttendeeRole {
  /** The person running the meeting */
  PRESENTER = "PRESENTER",
  /** Someone attending the meeting but not controlling it */
  VIEWER = "VIEWER",
}

export enum ConsultMeetingAttendeeSortParameter {
  /** Id */
  ID = "ID",
}

/** The connection type for ConsultMeeting. */
export type ConsultMeetingConnection = {
  readonly __typename: "ConsultMeetingConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ConsultMeetingEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<ConsultMeeting>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ConsultMeetingEdge = {
  readonly __typename: "ConsultMeetingEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: ConsultMeeting;
};

export enum ConsultMeetingSortParameter {
  /** Id */
  ID = "ID",
  /** Start Time */
  START_TIME = "START_TIME",
}

/** Whether a consult meeting is running or not */
export enum ConsultMeetingStatus {
  /** THis meeting has ended */
  ENDED = "ENDED",
  /** This meeting is currently happening */
  ONGOING = "ONGOING",
}

export type ConsultRequest = {
  readonly __typename: "ConsultRequest";
  readonly caseConsult: CaseConsult;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ConsultRequestId"]["output"];
  readonly provider: Provider;
  readonly requestDate: Scalars["ISO8601DateTime"]["output"];
  readonly requestReason: ConsultRequestReason;
  readonly requestText: Maybe<Scalars["String"]["output"]>;
};

/** The connection type for ConsultRequest. */
export type ConsultRequestConnection = {
  readonly __typename: "ConsultRequestConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ConsultRequestEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<ConsultRequest>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ConsultRequestEdge = {
  readonly __typename: "ConsultRequestEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: ConsultRequest;
};

/** The reason a provider requested a case consult for this patient */
export enum ConsultRequestReason {
  /** Patient has changed to a new course of treatment */
  CHANGE_OF_TREATMENT_PLAN = "CHANGE_OF_TREATMENT_PLAN",
  /** Providers need help assessing a case */
  DIAGNOSTIC_CLARITY = "DIAGNOSTIC_CLARITY",
  /** Patient may be ready for discharge */
  DISCHARGE = "DISCHARGE",
  /** Severity of patient's measures has increased recently */
  INCREASED_MEASURE_SEVERITY = "INCREASED_MEASURE_SEVERITY",
  /** Patient not showing improvement after treatment */
  LACK_OF_CLINICAL_IMPROVEMENT = "LACK_OF_CLINICAL_IMPROVEMENT",
  /** Patient is experiencing medical complications */
  MEDICAL_COMPLICATIONS = "MEDICAL_COMPLICATIONS",
  /** Providers need help managing medication for this patient */
  MEDICATION_MANAGEMENT_QUESTION = "MEDICATION_MANAGEMENT_QUESTION",
  /** Patient is experiencing a side effect from medication */
  MEDICATION_SIDE_EFFECT = "MEDICATION_SIDE_EFFECT",
  /** New information about a patient may change diagnosis or treatment */
  NEW_INFORMATION = "NEW_INFORMATION",
  /** Reasons not captured in other options */
  OTHER = "OTHER",
  /** Good news about a patient for team morale */
  QUICK_WIN = "QUICK_WIN",
  /** SDOH factors my change a patient's diagnosis or treatment */
  SDOH = "SDOH",
}

export enum ConsultRequestSortParameter {
  /** Id */
  ID = "ID",
}

export type ConsultReviewNote =
  | { readonly discussion: Scalars["Boolean"]["input"]; readonly notes?: never; readonly summary?: never }
  | { readonly discussion?: never; readonly notes: Scalars["Boolean"]["input"]; readonly summary?: never }
  | { readonly discussion?: never; readonly notes?: never; readonly summary: Scalars["Boolean"]["input"] };

export type Coverage = {
  readonly __typename: "Coverage";
  readonly endDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["CoverageId"]["output"];
  readonly institutePayor: InstitutePayor;
  readonly isPrimary: Maybe<Scalars["Boolean"]["output"]>;
  readonly patient: Patient;
  readonly relationship: Maybe<CoverageRelationship>;
  readonly startDate: Scalars["ISO8601DateTime"]["output"];
  readonly subscriberNumber: Maybe<Scalars["String"]["output"]>;
};

/** The connection type for Coverage. */
export type CoverageConnection = {
  readonly __typename: "CoverageConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<CoverageEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Coverage>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type CoverageEdge = {
  readonly __typename: "CoverageEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Coverage;
};

export enum CoverageRelationship {
  /** The Beneficiary is a child of the Subscriber */
  CHILD = "CHILD",
  /** The Beneficiary is a common law spouse of the Subscriber */
  COMMON = "COMMON",
  /** The Beneficiary is covered under insurance of the Subscriber due to an injury. */
  INJURY = "INJURY",
  /** The Beneficiary has no relationship to the Subscriber */
  NONE = "NONE",
  /** The Beneficiary has some other relationship to the Subscriber */
  OTHER = "OTHER",
  /** The Beneficiary is a parent of the Subscriber */
  PARENT = "PARENT",
  /** The Beneficiary is the Subscriber */
  SELF = "SELF",
  /** The Beneficiary is the spouse of the Subscriber */
  SPOUSE = "SPOUSE",
}

export enum CoverageSortParameter {
  /** Id */
  ID = "ID",
}

/** Autogenerated input type of CreateAnalyticsEvent */
export type CreateAnalyticsEventInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly data?: InputMaybe<Scalars["AnalyticsEventData"]["input"]>;
  readonly eventDate: Scalars["ISO8601DateTime"]["input"];
  readonly eventName: Scalars["String"]["input"];
  readonly eventUuid: Scalars["String"]["input"];
  readonly instituteId: Scalars["InstituteId"]["input"];
  readonly patientId?: InputMaybe<Scalars["PatientId"]["input"]>;
  readonly providerId?: InputMaybe<Scalars["ProviderId"]["input"]>;
  readonly relatedPersonId?: InputMaybe<Scalars["RelatedPersonId"]["input"]>;
  readonly route?: InputMaybe<Scalars["String"]["input"]>;
  readonly sessionId?: InputMaybe<Scalars["ID"]["input"]>;
  readonly url?: InputMaybe<Scalars["String"]["input"]>;
  readonly userId?: InputMaybe<Scalars["UserId"]["input"]>;
};

/** Autogenerated return type of CreateAnalyticsEvent. */
export type CreateAnalyticsEventPayload = {
  readonly __typename: "CreateAnalyticsEventPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: Maybe<ReadonlyArray<Scalars["String"]["output"]>>;
  readonly ok: Scalars["Boolean"]["output"];
  readonly result: Maybe<Scalars["String"]["output"]>;
  readonly success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of CreateAppointment */
export type CreateAppointmentInput = {
  readonly careEpisodeId: Scalars["CareEpisodeId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly minutesDuration?: InputMaybe<Scalars["Int"]["input"]>;
  readonly providerId: Scalars["ProviderId"]["input"];
  readonly recurrenceEndDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  readonly recurrenceFrequency?: InputMaybe<RecurrenceFrequency>;
  readonly startDate: Scalars["ISO8601DateTime"]["input"];
};

/** Autogenerated return type of CreateAppointment. */
export type CreateAppointmentPayload = {
  readonly __typename: "CreateAppointmentPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateAppointmentResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateAppointmentResult = {
  readonly __typename: "CreateAppointmentResult";
  readonly appointment: Appointment;
  readonly futureAppointments: Maybe<ReadonlyArray<Appointment>>;
};

/** Autogenerated input type of CreateCareEpisode */
export type CreateCareEpisodeInput = {
  readonly careTeam?: InputMaybe<ReadonlyArray<CareEpisodeProviderInput>>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly organizationId?: InputMaybe<Scalars["OrganizationId"]["input"]>;
  readonly patientId: Scalars["PatientId"]["input"];
  readonly periodStart?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  readonly treatmentServiceId?: InputMaybe<Scalars["TreatmentServiceId"]["input"]>;
};

/** Autogenerated return type of CreateCareEpisode. */
export type CreateCareEpisodePayload = {
  readonly __typename: "CreateCareEpisodePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateCareEpisodeResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateCareEpisodeResult = {
  readonly __typename: "CreateCareEpisodeResult";
  readonly careEpisode: CareEpisode;
};

/** Autogenerated input type of CreateCareEpisodeTreatmentTrack */
export type CreateCareEpisodeTreatmentTrackInput = {
  readonly careEpisodeId: Scalars["CareEpisodeId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly enrollmentDate: Scalars["ISO8601DateTime"]["input"];
  readonly status: CareEpisodeTreatmentTrackStatus;
  readonly treatmentTrackId: Scalars["TreatmentTrackId"]["input"];
};

/** Autogenerated return type of CreateCareEpisodeTreatmentTrack. */
export type CreateCareEpisodeTreatmentTrackPayload = {
  readonly __typename: "CreateCareEpisodeTreatmentTrackPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateCareEpisodeTreatmentTrackResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateCareEpisodeTreatmentTrackResult = {
  readonly __typename: "CreateCareEpisodeTreatmentTrackResult";
  readonly careEpisodeTreatmentTrack: CareEpisodeTreatmentTrack;
};

/** Autogenerated input type of CreateCareGroup */
export type CreateCareGroupInput = {
  readonly careUnitTypeId: Scalars["CareUnitTypeId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly patientIds: ReadonlyArray<Scalars["PatientId"]["input"]>;
};

/** Autogenerated return type of CreateCareGroup. */
export type CreateCareGroupPayload = {
  readonly __typename: "CreateCareGroupPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateCareGroupResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateCareGroupResult = {
  readonly __typename: "CreateCareGroupResult";
  readonly careGroup: CareGroup;
};

/** Autogenerated input type of CreateCocmBillingAlgorithm */
export type CreateCocmBillingAlgorithmInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly codes: ReadonlyArray<CocmBillingAlgorithmCodeParams>;
  readonly endDate?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  readonly entityTreeNode: EntityTreeNodeParams;
  readonly name: Scalars["String"]["input"];
  readonly rules: ReadonlyArray<CocmBillingAlgorithmRuleParams>;
  readonly startDate: Scalars["ISO8601DateTime"]["input"];
  readonly status: CocmBillingAlgorithmStatus;
};

/** Autogenerated return type of CreateCocmBillingAlgorithm. */
export type CreateCocmBillingAlgorithmPayload = {
  readonly __typename: "CreateCocmBillingAlgorithmPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateCocmBillingAlgorithmResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateCocmBillingAlgorithmResult = {
  readonly __typename: "CreateCocmBillingAlgorithmResult";
  readonly cocmBillingAlgorithm: CocmBillingAlgorithm;
};

/** Autogenerated input type of CreateConsultMeetingAttendee */
export type CreateConsultMeetingAttendeeInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly consultMeetingId: Scalars["ConsultMeetingId"]["input"];
  readonly providerId: Scalars["ProviderId"]["input"];
};

/** Autogenerated return type of CreateConsultMeetingAttendee. */
export type CreateConsultMeetingAttendeePayload = {
  readonly __typename: "CreateConsultMeetingAttendeePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateConsultMeetingAttendeeResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateConsultMeetingAttendeeResult = {
  readonly __typename: "CreateConsultMeetingAttendeeResult";
  readonly consultMeetingAttendee: ConsultMeetingAttendee;
};

/** Autogenerated input type of CreateDataDumpDownloadUrl */
export type CreateDataDumpDownloadUrlInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly dataDumpId: Scalars["DataDumpId"]["input"];
};

/** Autogenerated return type of CreateDataDumpDownloadUrl. */
export type CreateDataDumpDownloadUrlPayload = {
  readonly __typename: "CreateDataDumpDownloadUrlPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateDataDumpDownloadUrlResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateDataDumpDownloadUrlResult = {
  readonly __typename: "CreateDataDumpDownloadUrlResult";
  readonly dataDumpId: Scalars["DataDumpId"]["output"];
  readonly downloadUrl: Scalars["String"]["output"];
};

/** Autogenerated input type of CreateDataDump */
export type CreateDataDumpInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly endDate?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly resources: ReadonlyArray<DataDumpResource>;
  readonly startDate?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
};

/** Autogenerated return type of CreateDataDump. */
export type CreateDataDumpPayload = {
  readonly __typename: "CreateDataDumpPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateDataDumpResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateDataDumpResult = {
  readonly __typename: "CreateDataDumpResult";
  readonly dataDump: DataDump;
};

/** Autogenerated input type of CreateExternalEvent */
export type CreateExternalEventInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly data?: InputMaybe<Scalars["PipbhcExternalEventData"]["input"]>;
  readonly details?: InputMaybe<Scalars["String"]["input"]>;
  readonly effectiveDate: Scalars["ISO8601DateTime"]["input"];
  readonly name: Scalars["String"]["input"];
  readonly patientId: Scalars["PatientId"]["input"];
};

/** Autogenerated return type of CreateExternalEvent. */
export type CreateExternalEventPayload = {
  readonly __typename: "CreateExternalEventPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateExternalEventResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateExternalEventResult = {
  readonly __typename: "CreateExternalEventResult";
  readonly externalEvent: ExternalEvent;
};

/** Autogenerated input type of CreateGoal */
export type CreateGoalInput = {
  readonly careEpisodeId: Scalars["ID"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly goalType: GoalType;
  readonly patientText: Scalars["String"]["input"];
  readonly status: GoalStatus;
  readonly userId: Scalars["ID"]["input"];
};

/** Autogenerated return type of CreateGoal. */
export type CreateGoalPayload = {
  readonly __typename: "CreateGoalPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateGoalResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateGoalResult = {
  readonly __typename: "CreateGoalResult";
  readonly goal: Goal;
};

/** Autogenerated input type of CreateImplementationTarget */
export type CreateImplementationTargetInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly description?: InputMaybe<Scalars["String"]["input"]>;
  readonly endDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  readonly entityTreeNode: EntityTreeNodeParams;
  readonly initialCredits?: InputMaybe<Scalars["Float"]["input"]>;
  readonly mirahInternal?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly softLaunchDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  readonly startDate: Scalars["ISO8601Date"]["input"];
  readonly status: ImplementationTargetStatus;
  readonly target: Scalars["Float"]["input"];
  readonly targetType: ImplementationTargetType;
};

/** Autogenerated return type of CreateImplementationTarget. */
export type CreateImplementationTargetPayload = {
  readonly __typename: "CreateImplementationTargetPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateImplementationTargetResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateImplementationTargetResult = {
  readonly __typename: "CreateImplementationTargetResult";
  readonly implementationTarget: ImplementationTarget;
};

/** Autogenerated input type of CreateInstituteGroup */
export type CreateInstituteGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly leaderId: Scalars["InstituteId"]["input"];
  readonly name: Scalars["String"]["input"];
};

/** Autogenerated return type of CreateInstituteGroup. */
export type CreateInstituteGroupPayload = {
  readonly __typename: "CreateInstituteGroupPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateInstituteGroupResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateInstituteGroupResult = {
  readonly __typename: "CreateInstituteGroupResult";
  readonly instituteGroup: InstituteGroup;
};

/** Autogenerated input type of CreateMetricAndExport */
export type CreateMetricAndExportInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The range of dates required */
  readonly dateRange: DateRangeInput;
  readonly entityTreeNode: EntityTreeNodeParams;
  readonly name: Scalars["String"]["input"];
  readonly payload: MetricParams;
  readonly status: MetricStatus;
};

/** Autogenerated return type of CreateMetricAndExport. */
export type CreateMetricAndExportPayload = {
  readonly __typename: "CreateMetricAndExportPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateMetricAndExportResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateMetricAndExportResult = {
  readonly __typename: "CreateMetricAndExportResult";
  readonly metric: Metric;
  readonly metricDataExport: MetricDataExport;
};

/** Autogenerated input type of CreateMetricDataExportDownloadUrl */
export type CreateMetricDataExportDownloadUrlInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly metricDataExportId: Scalars["MetricDataExportId"]["input"];
};

/** Autogenerated return type of CreateMetricDataExportDownloadUrl. */
export type CreateMetricDataExportDownloadUrlPayload = {
  readonly __typename: "CreateMetricDataExportDownloadUrlPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateMetricDataExportDownloadUrlResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateMetricDataExportDownloadUrlResult = {
  readonly __typename: "CreateMetricDataExportDownloadUrlResult";
  readonly downloadUrl: Scalars["String"]["output"];
  readonly metricDataExportId: Scalars["MetricDataExportId"]["output"];
};

/** Autogenerated input type of CreateMetricDataExport */
export type CreateMetricDataExportInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The range of dates required */
  readonly dateRange: DateRangeInput;
  readonly entityTreeNode: EntityTreeNodeParams;
  readonly metricId: Scalars["MetricId"]["input"];
};

/** Autogenerated return type of CreateMetricDataExport. */
export type CreateMetricDataExportPayload = {
  readonly __typename: "CreateMetricDataExportPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateMetricDataExportResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateMetricDataExportResult = {
  readonly __typename: "CreateMetricDataExportResult";
  readonly metricDataExport: MetricDataExport;
};

/** Autogenerated input type of CreateMetric */
export type CreateMetricInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly entityTreeNode: EntityTreeNodeParams;
  readonly name: Scalars["String"]["input"];
  readonly payload: MetricParams;
  readonly status: MetricStatus;
};

/** Autogenerated return type of CreateMetric. */
export type CreateMetricPayload = {
  readonly __typename: "CreateMetricPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateMetricResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateMetricResult = {
  readonly __typename: "CreateMetricResult";
  readonly metric: Metric;
};

/** Autogenerated input type of CreateOrEditTreatmentTargetScale */
export type CreateOrEditTreatmentTargetScaleInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly interval?: InputMaybe<Scalars["String"]["input"]>;
  readonly scaleId: Scalars["ScaleId"]["input"];
  readonly skipIntake?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly targeting: ScaleTargeting;
  readonly treatmentTargetId: Scalars["TreatmentTargetId"]["input"];
};

/** Autogenerated return type of CreateOrEditTreatmentTargetScale. */
export type CreateOrEditTreatmentTargetScalePayload = {
  readonly __typename: "CreateOrEditTreatmentTargetScalePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EditTreatmentTargetScaleResult>;
  readonly success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of CreateOrganization */
export type CreateOrganizationInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly inTrialPeriod: Scalars["Boolean"]["input"];
  readonly measurementAllowed: Scalars["Boolean"]["input"];
  readonly name: Scalars["String"]["input"];
  readonly parentId?: InputMaybe<Scalars["OrganizationId"]["input"]>;
  readonly shortname: Scalars["String"]["input"];
  readonly timezone?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of CreateOrganization. */
export type CreateOrganizationPayload = {
  readonly __typename: "CreateOrganizationPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateOrganizationResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateOrganizationResult = {
  readonly __typename: "CreateOrganizationResult";
  readonly organization: Organization;
};

/** Autogenerated input type of CreatePanel */
export type CreatePanelInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly name?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of CreatePanel. */
export type CreatePanelPayload = {
  readonly __typename: "CreatePanelPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreatePanelResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreatePanelResult = {
  readonly __typename: "CreatePanelResult";
  readonly panel: Panel;
};

/** Autogenerated input type of CreatePanelTeamMember */
export type CreatePanelTeamMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly endDate?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  readonly function: PanelTeamMemberFunction;
  readonly panelId: Scalars["PanelId"]["input"];
  readonly providerId: Scalars["ProviderId"]["input"];
  readonly startDate?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
};

/** Autogenerated return type of CreatePanelTeamMember. */
export type CreatePanelTeamMemberPayload = {
  readonly __typename: "CreatePanelTeamMemberPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreatePanelTeamMemberResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreatePanelTeamMemberResult = {
  readonly __typename: "CreatePanelTeamMemberResult";
  readonly panelTeamMember: PanelTeamMember;
};

/** Autogenerated input type of CreatePatient */
export type CreatePatientInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly dob?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  readonly email?: InputMaybe<Scalars["String"]["input"]>;
  readonly ethnicity?: InputMaybe<Scalars["String"]["input"]>;
  readonly gender?: InputMaybe<Gender>;
  readonly genderIdentity?: InputMaybe<Scalars["String"]["input"]>;
  readonly genderIdentityFhir?: InputMaybe<GenderIdentity>;
  readonly isTest?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly legalFirstName?: InputMaybe<Scalars["String"]["input"]>;
  readonly legalLastName?: InputMaybe<Scalars["String"]["input"]>;
  readonly mrn?: InputMaybe<Scalars["String"]["input"]>;
  readonly notificationPreference?: InputMaybe<NotificationPreference>;
  readonly phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  readonly preferredFirstName?: InputMaybe<Scalars["String"]["input"]>;
  readonly preferredLastName?: InputMaybe<Scalars["String"]["input"]>;
  readonly preferredPronouns?: InputMaybe<Scalars["String"]["input"]>;
  readonly primaryLanguage?: InputMaybe<Scalars["String"]["input"]>;
  readonly race?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of CreatePatient. */
export type CreatePatientPayload = {
  readonly __typename: "CreatePatientPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreatePatientResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreatePatientResult = {
  readonly __typename: "CreatePatientResult";
  readonly patient: Patient;
};

/** Autogenerated input type of CreatePipbhcTreatmentGoal */
export type CreatePipbhcTreatmentGoalInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly goalText: Scalars["String"]["input"];
  readonly note?: InputMaybe<Scalars["String"]["input"]>;
  readonly patientId: Scalars["PatientId"]["input"];
};

/** Autogenerated return type of CreatePipbhcTreatmentGoal. */
export type CreatePipbhcTreatmentGoalPayload = {
  readonly __typename: "CreatePipbhcTreatmentGoalPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreatePipbhcTreatmentGoalResult>;
  readonly success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of CreatePipbhcTreatmentGoalReport */
export type CreatePipbhcTreatmentGoalReportInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly note?: InputMaybe<Scalars["String"]["input"]>;
  readonly pipbhcTreatmentGoalAnswers: ReadonlyArray<NewPipbhcTreatmentGoalAnswer>;
};

/** Autogenerated return type of CreatePipbhcTreatmentGoalReport. */
export type CreatePipbhcTreatmentGoalReportPayload = {
  readonly __typename: "CreatePipbhcTreatmentGoalReportPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreatePipbhcTreatmentGoalReportResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreatePipbhcTreatmentGoalReportResult = {
  readonly __typename: "CreatePipbhcTreatmentGoalReportResult";
  readonly pipbhcTreatmentGoalReport: PipbhcTreatmentGoalReport;
};

export type CreatePipbhcTreatmentGoalResult = {
  readonly __typename: "CreatePipbhcTreatmentGoalResult";
  readonly treatmentGoal: PipbhcTreatmentGoal;
};

/** Autogenerated input type of CreateQuickText */
export type CreateQuickTextInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly order: Scalars["Int"]["input"];
  readonly text?: InputMaybe<Scalars["String"]["input"]>;
  readonly title: Scalars["String"]["input"];
};

/** Autogenerated return type of CreateQuickText. */
export type CreateQuickTextPayload = {
  readonly __typename: "CreateQuickTextPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateQuickTextResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateQuickTextResult = {
  readonly __typename: "CreateQuickTextResult";
  readonly quickText: QuickText;
};

/** Autogenerated input type of CreateRelatedPerson */
export type CreateRelatedPersonInput = {
  readonly active?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly email?: InputMaybe<Scalars["String"]["input"]>;
  readonly firstName: Scalars["String"]["input"];
  readonly lastName: Scalars["String"]["input"];
  readonly notificationPreference?: InputMaybe<NotificationPreference>;
  readonly patientId: Scalars["PatientId"]["input"];
  readonly phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  readonly relatedPersonRelationship: RelatedPersonRelationship;
};

/** Autogenerated return type of CreateRelatedPerson. */
export type CreateRelatedPersonPayload = {
  readonly __typename: "CreateRelatedPersonPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateRelatedPersonResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateRelatedPersonResult = {
  readonly __typename: "CreateRelatedPersonResult";
  readonly relatedPerson: RelatedPerson;
};

/** Autogenerated input type of CreateTask */
export type CreateTaskInput = {
  readonly addToDefaultList?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly assignedToId: Scalars["ProviderId"]["input"];
  readonly body?: InputMaybe<Scalars["String"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly dueAt?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  readonly patientId?: InputMaybe<Scalars["PatientId"]["input"]>;
  readonly title: Scalars["String"]["input"];
};

/** Autogenerated return type of CreateTask. */
export type CreateTaskPayload = {
  readonly __typename: "CreateTaskPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateTaskResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateTaskResult = {
  readonly __typename: "CreateTaskResult";
  readonly task: Task;
};

/** Autogenerated input type of CreateTaskTemplate */
export type CreateTaskTemplateInput = {
  readonly body?: InputMaybe<Scalars["String"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly title: Scalars["String"]["input"];
};

/** Autogenerated return type of CreateTaskTemplate. */
export type CreateTaskTemplatePayload = {
  readonly __typename: "CreateTaskTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateTaskTemplateResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateTaskTemplateResult = {
  readonly __typename: "CreateTaskTemplateResult";
  readonly taskTemplate: TaskTemplate;
};

/** Autogenerated input type of CreateTimeEntryLog */
export type CreateTimeEntryLogInput = {
  readonly clientEndTime?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly clientStartTime: Scalars["ISO8601DateTime"]["input"];
  readonly completeTask?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly durationSeconds: Scalars["Int"]["input"];
  readonly noteText?: InputMaybe<Scalars["String"]["input"]>;
  readonly providerId: Scalars["ProviderId"]["input"];
  readonly taskId: Scalars["TaskId"]["input"];
};

/** Autogenerated return type of CreateTimeEntryLog. */
export type CreateTimeEntryLogPayload = {
  readonly __typename: "CreateTimeEntryLogPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateTimeEntryLogResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateTimeEntryLogResult = {
  readonly __typename: "CreateTimeEntryLogResult";
  readonly myActiveTimeEntryLog: MyActiveTimeEntryLog;
  readonly timeEntryLog: TimeEntryLog;
};

/** Autogenerated input type of CreateTreatmentService */
export type CreateTreatmentServiceInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly measurementAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly parentId?: InputMaybe<Scalars["TreatmentServiceId"]["input"]>;
  readonly serviceTypes?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
};

/** Autogenerated return type of CreateTreatmentService. */
export type CreateTreatmentServicePayload = {
  readonly __typename: "CreateTreatmentServicePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateTreatmentServiceResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateTreatmentServiceResult = {
  readonly __typename: "CreateTreatmentServiceResult";
  readonly treatmentService: Maybe<TreatmentService>;
};

/** Autogenerated input type of CreateTreatmentTarget */
export type CreateTreatmentTargetInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly instituteId: Scalars["InstituteId"]["input"];
  readonly name: Scalars["String"]["input"];
};

/** Autogenerated return type of CreateTreatmentTarget. */
export type CreateTreatmentTargetPayload = {
  readonly __typename: "CreateTreatmentTargetPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateTreatmentTargetResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateTreatmentTargetResult = {
  readonly __typename: "CreateTreatmentTargetResult";
  readonly treatmentTarget: TreatmentTarget;
};

/** Autogenerated input type of CreateTreatmentTrack */
export type CreateTreatmentTrackInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly status: TreatmentTrackStatus;
};

/** Autogenerated return type of CreateTreatmentTrack. */
export type CreateTreatmentTrackPayload = {
  readonly __typename: "CreateTreatmentTrackPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<CreateTreatmentTrackResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type CreateTreatmentTrackResult = {
  readonly __typename: "CreateTreatmentTrackResult";
  readonly treatmentTrack: TreatmentTrack;
};

export type CredibleEhrLinkDetails = {
  readonly __typename: "CredibleEhrLinkDetails";
  readonly visitStatusesArray: ReadonlyArray<CredibleVisitStatus>;
  readonly visitTypesArray: ReadonlyArray<CredibleVisitType>;
};

export type CredibleVisitStatus = {
  readonly __typename: "CredibleVisitStatus";
  readonly appointmentStatus: Maybe<AppointmentStatus>;
  readonly credibleStatus: Scalars["String"]["output"];
};

export type CredibleVisitType = {
  readonly __typename: "CredibleVisitType";
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["String"]["output"];
  readonly label: Maybe<Scalars["String"]["output"]>;
  readonly visittype: Maybe<Scalars["String"]["output"]>;
};

export type CurrentInstitute = {
  readonly __typename: "CurrentInstitute";
  readonly activeDataSourceResources: ReadonlyArray<DataSourceResourceType>;
  readonly configuration: InstituteConfiguration;
  readonly enabled: Scalars["Boolean"]["output"];
  readonly featureSet: InstituteFeatureSet;
  readonly globalId: Scalars["ID"]["output"];
  readonly helpscoutBeaconKey: Scalars["String"]["output"];
  readonly id: Scalars["InstituteId"]["output"];
  readonly inactivityTimeoutSeconds: Maybe<Scalars["Int"]["output"]>;
  readonly name: Scalars["String"]["output"];
  readonly shortcode: Scalars["String"]["output"];
  readonly supportsInstituteGroups: Scalars["Boolean"]["output"];
  readonly timezone: Maybe<Scalars["String"]["output"]>;
};

export type CurrentStats = {
  readonly __typename: "CurrentStats";
  readonly complexity: Maybe<Scalars["Float"]["output"]>;
  readonly severity: Maybe<Scalars["Float"]["output"]>;
  readonly severityTrend: Maybe<Scalars["Float"]["output"]>;
};

export type DataDump = {
  readonly __typename: "DataDump";
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly endDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly expiresAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["DataDumpId"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly password: Maybe<Scalars["String"]["output"]>;
  readonly resources: ReadonlyArray<DataDumpResource>;
  readonly startDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly status: DataDumpStatus;
  readonly updatedAt: Scalars["ISO8601DateTime"]["output"];
  readonly user: Maybe<User>;
};

/** The connection type for DataDump. */
export type DataDumpConnection = {
  readonly __typename: "DataDumpConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<DataDumpEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<DataDump>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type DataDumpEdge = {
  readonly __typename: "DataDumpEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: DataDump;
};

/** A source which the data dump is downloading */
export enum DataDumpResource {
  /** Exports anon research set */
  ANON_RESEARCH_SET = "ANON_RESEARCH_SET",
  /** Exports answer */
  ANSWER = "ANSWER",
  /** Exports appointment */
  APPOINTMENT = "APPOINTMENT",
  /** Collaborative care case consultation */
  COLLABORATIVE_CARE_CASE_CONSULT = "COLLABORATIVE_CARE_CASE_CONSULT",
  /** Collaborative care case consultation meeting */
  COLLABORATIVE_CARE_CONSULT_MEETING = "COLLABORATIVE_CARE_CONSULT_MEETING",
  /** Collaborative care case consultation meeting attendee */
  COLLABORATIVE_CARE_CONSULT_MEETING_ATTENDEE = "COLLABORATIVE_CARE_CONSULT_MEETING_ATTENDEE",
  /** Collaborative care case consultation request */
  COLLABORATIVE_CARE_CONSULT_REQUEST = "COLLABORATIVE_CARE_CONSULT_REQUEST",
  /** Collaborative care enrollments */
  COLLABORATIVE_CARE_ENROLLMENTS = "COLLABORATIVE_CARE_ENROLLMENTS",
  /** Collaborative care task note */
  COLLABORATIVE_CARE_NOTE = "COLLABORATIVE_CARE_NOTE",
  /** Collaborative care panel */
  COLLABORATIVE_CARE_PANEL = "COLLABORATIVE_CARE_PANEL",
  /** Collaborative care panel team member */
  COLLABORATIVE_CARE_PANEL_TEAM_MEMBER = "COLLABORATIVE_CARE_PANEL_TEAM_MEMBER",
  /** Collaborative care tasks */
  COLLABORATIVE_CARE_TASK = "COLLABORATIVE_CARE_TASK",
  /** Collaborative care time entry log */
  COLLABORATIVE_CARE_TIME_ENTRY_LOG = "COLLABORATIVE_CARE_TIME_ENTRY_LOG",
  /** Exports contact details quality */
  CONTACT_DETAILS_QUALITY = "CONTACT_DETAILS_QUALITY",
  /** Exports coverage */
  COVERAGE = "COVERAGE",
  /** Decision Support Algorithms */
  DECISION_SUPPORT_ENTITY_DECISION_ALGORITHM = "DECISION_SUPPORT_ENTITY_DECISION_ALGORITHM",
  /** Decision Support Provider Notifications */
  DECISION_SUPPORT_PROVIDER_NOTIFICATION = "DECISION_SUPPORT_PROVIDER_NOTIFICATION",
  /** Decision Support Triggered Algorithms */
  DECISION_SUPPORT_TRIGGERED_DECISION_ALGORITHM = "DECISION_SUPPORT_TRIGGERED_DECISION_ALGORITHM",
  /** Decision Support Triggered Workflows */
  DECISION_SUPPORT_TRIGGERED_WORKFLOW = "DECISION_SUPPORT_TRIGGERED_WORKFLOW",
  /** Decision Support Triggered Actions */
  DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION = "DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION",
  /** Decision Support Triggered Action Logs */
  DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION_LOG = "DECISION_SUPPORT_TRIGGERED_WORKFLOW_ACTION_LOG",
  /** Decision Support Workflows */
  DECISION_SUPPORT_WORKFLOW = "DECISION_SUPPORT_WORKFLOW",
  /** Decision Support Actions */
  DECISION_SUPPORT_WORKFLOW_ACTION = "DECISION_SUPPORT_WORKFLOW_ACTION",
  /** Exports diagnostic report */
  DIAGNOSTIC_REPORT = "DIAGNOSTIC_REPORT",
  /** Exports encounter */
  ENCOUNTER = "ENCOUNTER",
  /** Exports episode of care */
  EPISODE_OF_CARE = "EPISODE_OF_CARE",
  /** Exports observation */
  OBSERVATION = "OBSERVATION",
  /** Exports organization */
  ORGANIZATION = "ORGANIZATION",
  /** Exports a specific outcomes format */
  OUTCOMES = "OUTCOMES",
  /** Exports patient */
  PATIENT = "PATIENT",
  /** Exports practitioner */
  PRACTITIONER = "PRACTITIONER",
  /** Exports question */
  QUESTION = "QUESTION",
  /** Exports questionnaire */
  QUESTIONNAIRE = "QUESTIONNAIRE",
  /** Exports questionnaire */
  QUESTIONNAIRE_RESPONSE = "QUESTIONNAIRE_RESPONSE",
  /** Exports reimbursement list */
  REIMBURSEMENT_LIST = "REIMBURSEMENT_LIST",
  /** Exports related person */
  RELATED_PERSON = "RELATED_PERSON",
  /** Exporters provider report views */
  REPORT_VIEW = "REPORT_VIEW",
  /** Research Experiment Exporter */
  RESEARCH_EXPERIMENT = "RESEARCH_EXPERIMENT",
  /** Exports research patient set */
  RESEARCH_PATIENT_SET = "RESEARCH_PATIENT_SET",
  /** Exports research set */
  RESEARCH_SET = "RESEARCH_SET",
  /** Exports user consent */
  USER_CONSENT = "USER_CONSENT",
}

export enum DataDumpSortParameter {
  /** When the dump was created */
  CREATED_AT = "CREATED_AT",
  /** Id */
  ID = "ID",
}

/** The status of the data dump */
export enum DataDumpStatus {
  /** The data export had an error during processing */
  ERROR = "ERROR",
  /** The data export has not yet started processing */
  NEW = "NEW",
  /** The data export is currently being processed */
  PROCESSING = "PROCESSING",
  /** The data export is ready for download */
  READY = "READY",
}

export type DataFetchRequest = {
  readonly __typename: "DataFetchRequest";
  readonly dataSourceRecord: Maybe<DataSourceRecord>;
  readonly dataSourceRecordLogs: DataSourceRecordLogConnection;
  readonly dataSourceResource: Maybe<DataSourceResource>;
  readonly ehrConnection: EhrLink;
  readonly endDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly fetchType: DataFetchRequestFetchType;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["DataFetchRequestId"]["output"];
  readonly startDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly status: DataFetchRequestStatus;
};

export type DataFetchRequestdataSourceRecordLogsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  dataSourceRecordId: InputMaybe<ReadonlyArray<Scalars["DataSourceRecordId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  importHistoryId: InputMaybe<ReadonlyArray<Scalars["ImportHistoryId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<DataSourceRecordLogSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<DataSourceRecordLogStatus>>;
};

/** The connection type for DataFetchRequest. */
export type DataFetchRequestConnection = {
  readonly __typename: "DataFetchRequestConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<DataFetchRequestEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<DataFetchRequest>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type DataFetchRequestEdge = {
  readonly __typename: "DataFetchRequestEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: DataFetchRequest;
};

/** The type of a fetch request */
export enum DataFetchRequestFetchType {
  /** Fetch the details of the object in question */
  DETAILS = "DETAILS",
  /** Setup everything necessary to make a new provider */
  FIRST_TIME_PROVIDER_SETUP = "FIRST_TIME_PROVIDER_SETUP",
  /** Fetch all patient conditions for a patient */
  PATIENT_CONDITION_QUERY = "PATIENT_CONDITION_QUERY",
  /** Fetch all recent appointments for a patient */
  PATIENT_RECENT_APPOINTMENTS_QUERY = "PATIENT_RECENT_APPOINTMENTS_QUERY",
  /** Fetch all recent appointments for a provider */
  PROVIDER_RECENT_APPOINTMENTS_QUERY = "PROVIDER_RECENT_APPOINTMENTS_QUERY",
  /** Query the resource for all available records */
  QUERY = "QUERY",
  /** Fetch a summary of the object in question */
  SUMMARY = "SUMMARY",
}

export enum DataFetchRequestSortParameter {
  /** End Date */
  END_DATE = "END_DATE",
  /** Id */
  ID = "ID",
  /** Start Date */
  START_DATE = "START_DATE",
}

/** The status of a data fetch request */
export enum DataFetchRequestStatus {
  /** The fetch is blocked by another fetch which must complete first */
  BLOCKED = "BLOCKED",
  /** The fetch is complete */
  COMPLETE = "COMPLETE",
  /** The fetch this is dependent on failed */
  DEPENDENCY_ERROR = "DEPENDENCY_ERROR",
  /** The object to be fetched had errors */
  FETCH_ERROR = "FETCH_ERROR",
  /** The fetch is currently working */
  IN_PROGRESS = "IN_PROGRESS",
  /** The fetch has not started yet */
  PENDING = "PENDING",
  /** A system error occurred while fetching */
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export type DataSource = {
  readonly __typename: "DataSource";
  readonly connectionInformation: Maybe<Scalars["String"]["output"]>;
  readonly dataSourceResources: ReadonlyArray<DataSourceResource>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["DataSourceId"]["output"];
  readonly institute: Institute;
  readonly name: Scalars["String"]["output"];
};

/** The connection type for DataSource. */
export type DataSourceConnection = {
  readonly __typename: "DataSourceConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<DataSourceEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<DataSource>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type DataSourceEdge = {
  readonly __typename: "DataSourceEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: DataSource;
};

export type DataSourceRecord = {
  readonly __typename: "DataSourceRecord";
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly dataSourceResource: DataSourceResource;
  readonly effectiveSince: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["DataSourceRecordId"]["output"];
  readonly remoteId: Scalars["String"]["output"];
  readonly resourceType: DataSourceResourceType;
  readonly target: Maybe<Entity>;
  readonly updatedAt: Scalars["ISO8601DateTime"]["output"];
};

/** Algorithms and business logic which can be called upon import of data */
export enum DataSourceRecordAlgorithm {
  /** Creates a new care episode for an appointment */
  APPOINTMENT_CARE_EPISODE_CREATION = "APPOINTMENT_CARE_EPISODE_CREATION",
  /** Adds a provider to an episode of care */
  APPOINTMENT_CARE_EPISODE_PROVIDER = "APPOINTMENT_CARE_EPISODE_PROVIDER",
  /** Sets the default provider to that of this appointment */
  APPOINTMENT_CREATE_ATHENA_ENCOUNTER = "APPOINTMENT_CREATE_ATHENA_ENCOUNTER",
  /** Sets the default provider to that of this appointment */
  APPOINTMENT_DEFAULT_PROVIDER = "APPOINTMENT_DEFAULT_PROVIDER",
  /** Creates a new patient session for this appointment */
  APPOINTMENT_PATIENT_SESSION_CREATION = "APPOINTMENT_PATIENT_SESSION_CREATION",
  /** Imports an external observation into the Mirah measurement system */
  EXTERNAL_OBSERVATION_IMPORT = "EXTERNAL_OBSERVATION_IMPORT",
  /** Extracts coverage information from patient details */
  PATIENT_COVERAGE = "PATIENT_COVERAGE",
}

export type DataSourceRecordAlgorithmLog = {
  readonly __typename: "DataSourceRecordAlgorithmLog";
  readonly algorithm: DataSourceRecordAlgorithm;
  readonly algorithmCode: Scalars["String"]["output"];
  readonly dataSourceRecordLog: DataSourceRecordLog;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["DataSourceRecordAlgorithmLogId"]["output"];
  readonly statusDescription: Scalars["String"]["output"];
  readonly statusName: Scalars["String"]["output"];
};

/** The connection type for DataSourceRecord. */
export type DataSourceRecordConnection = {
  readonly __typename: "DataSourceRecordConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<DataSourceRecordEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<DataSourceRecord>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type DataSourceRecordEdge = {
  readonly __typename: "DataSourceRecordEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: DataSourceRecord;
};

export type DataSourceRecordLog = {
  readonly __typename: "DataSourceRecordLog";
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly dataSourceRecord: Maybe<DataSourceRecord>;
  readonly dataSourceRecordAlgorithmLogs: ReadonlyArray<DataSourceRecordAlgorithmLog>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["DataSourceRecordLogId"]["output"];
  readonly idError: Maybe<DataSourceRecordLogError>;
  readonly matchStatus: Maybe<DataSourceRecordLogMatchStatus>;
  readonly raw: Scalars["DataSourceRecordLogRawData"]["output"];
  readonly status: DataSourceRecordLogStatus;
  readonly transformErrors: Maybe<ReadonlyArray<DataSourceRecordLogTransformError>>;
  readonly updatedAt: Scalars["ISO8601DateTime"]["output"];
};

/** The connection type for DataSourceRecordLog. */
export type DataSourceRecordLogConnection = {
  readonly __typename: "DataSourceRecordLogConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<DataSourceRecordLogEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<DataSourceRecordLog>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type DataSourceRecordLogEdge = {
  readonly __typename: "DataSourceRecordLogEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: DataSourceRecordLog;
};

/** Errors that can occur during an import */
export enum DataSourceRecordLogError {
  /** No record for the association was found */
  ASSOCIATION_MISSING = "ASSOCIATION_MISSING",
  /** We have not imported the record for the association */
  ASSOCIATION_NOT_IMPORTED = "ASSOCIATION_NOT_IMPORTED",
  /** A blank value for this field is not permitted */
  BLANK_NOT_ALLOWED = "BLANK_NOT_ALLOWED",
  /** The column given is not part of our system */
  INVALID_COLUMN = "INVALID_COLUMN",
  /** The value was in an invalid format */
  INVALID_FORMAT = "INVALID_FORMAT",
  /** The column data has not be typed correctly (ie a date field marked as a string) */
  INVALID_TYPE = "INVALID_TYPE",
  /** The column requested was not found in the source document */
  MISSING_COLUMN = "MISSING_COLUMN",
  /** The value was required but no value was found */
  NOT_PRESENT = "NOT_PRESENT",
  /** An unknown error occurred */
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  /** A value was supplied that has not been mapped to a Mirah value */
  UNMAPPED = "UNMAPPED",
}

/** The result of a matching exercise for existing records during record creation */
export enum DataSourceRecordLogMatchStatus {
  /** A match was not attempted because not enough data was supplied */
  INSUFFICIENT_DATA = "INSUFFICIENT_DATA",
  /** An existing record was matched */
  MATCHED = "MATCHED",
  /** No existing record was matched */
  UNMATCHED = "UNMATCHED",
}

export enum DataSourceRecordLogSortParameter {
  /** Created At */
  CREATED_AT = "CREATED_AT",
  /** Id */
  ID = "ID",
}

/** The result of importing this record into the system */
export enum DataSourceRecordLogStatus {
  /** Record is awaiting processing */
  AWAITING_PROCESSING = "AWAITING_PROCESSING",
  /** A record was created */
  CREATED = "CREATED",
  /** The record's id could not be understood */
  ID_ERROR = "ID_ERROR",
  /** The data was invalid and could not be saved */
  INVALID = "INVALID",
  /** An error occurred while loading the data */
  LOAD_ERROR = "LOAD_ERROR",
  /** Record is currently processing */
  PROCESSING = "PROCESSING",
  /** This Record was skipped */
  SKIPPED = "SKIPPED",
  /** The row is stale and was ignored */
  STALE = "STALE",
  /** A system error occurred during processing */
  SYSTEM_ERROR = "SYSTEM_ERROR",
  /** A record was left unchanged */
  UNCHANGED = "UNCHANGED",
  /** An unknown error occurred */
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  /** A record was updated */
  UPDATED = "UPDATED",
}

export type DataSourceRecordLogTransformError = {
  readonly __typename: "DataSourceRecordLogTransformError";
  readonly errors: ReadonlyArray<DataSourceRecordLogError>;
  readonly field: Scalars["String"]["output"];
};

export enum DataSourceRecordSortParameter {
  /** Id */
  ID = "ID",
  /** The remote id of the record */
  REMOTE_ID = "REMOTE_ID",
}

export type DataSourceResource = {
  readonly __typename: "DataSourceResource";
  readonly dataSource: DataSource;
  readonly dataSourceRecords: DataSourceConnection;
  readonly dataSourceResourceConfigs: ReadonlyArray<DataSourceResourceConfig>;
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["DataSourceResourceId"]["output"];
  readonly resourceType: Maybe<DataSourceResourceType>;
};

export type DataSourceResourcedataSourceRecordsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<DataSourceSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

export type DataSourceResourceConfig = {
  readonly __typename: "DataSourceResourceConfig";
  readonly dataSourceResource: DataSourceResource;
  readonly dataSourceResourceExtractors: ReadonlyArray<DataSourceResourceExtractor>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["DataSourceResourceConfigId"]["output"];
};

/** The connection type for DataSourceResourceConfig. */
export type DataSourceResourceConfigConnection = {
  readonly __typename: "DataSourceResourceConfigConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<DataSourceResourceConfigEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<DataSourceResourceConfig>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type DataSourceResourceConfigEdge = {
  readonly __typename: "DataSourceResourceConfigEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: DataSourceResourceConfig;
};

export enum DataSourceResourceConfigSortParameter {
  /** Id */
  ID = "ID",
}

/** The connection type for DataSourceResource. */
export type DataSourceResourceConnection = {
  readonly __typename: "DataSourceResourceConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<DataSourceResourceEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<DataSourceResource>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type DataSourceResourceEdge = {
  readonly __typename: "DataSourceResourceEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: DataSourceResource;
};

export type DataSourceResourceExtractor = {
  readonly __typename: "DataSourceResourceExtractor";
  readonly dataSourceResourceConfig: DataSourceResourceConfig;
  readonly ehrConnection: EhrLink;
  readonly extractorType: ExtractorType;
  readonly filePath: Maybe<Scalars["String"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["DataSourceResourceExtractorId"]["output"];
};

export enum DataSourceResourceSortParameter {
  /** Id */
  ID = "ID",
}

/** What type of external resource this item represents, e.g. patients, appointments, etc. */
export enum DataSourceResourceType {
  /** Appointments */
  APPOINTMENT = "APPOINTMENT",
  /** Care Episode data */
  CARE_EPISODE = "CARE_EPISODE",
  /** Billing Charges */
  CHARGE = "CHARGE",
  /** Condition/diagnoses data */
  CONDITION = "CONDITION",
  /** Insurance Coverage info */
  COVERAGE = "COVERAGE",
  /** Encounters */
  ENCOUNTER = "ENCOUNTER",
  /** Enrollment */
  ENROLLMENT = "ENROLLMENT",
  /** Location */
  LOCATION = "LOCATION",
  /** Observations */
  OBSERVATION = "OBSERVATION",
  /** Organization data */
  ORGANIZATION = "ORGANIZATION",
  /** Patient data */
  PATIENT = "PATIENT",
  /** Patient diagnoses */
  PATIENT_CONDITION = "PATIENT_CONDITION",
  /** Insurance Companies */
  PAYOR = "PAYOR",
  /** Provider/practitioner data */
  PROVIDER = "PROVIDER",
  /** Provider Role */
  PROVIDER_ROLE = "PROVIDER_ROLE",
  /** Related person data */
  RELATED_PERSON = "RELATED_PERSON",
  /** Treatment service */
  TREATMENT_SERVICE = "TREATMENT_SERVICE",
}

export enum DataSourceSortParameter {
  /** Id */
  ID = "ID",
  /** Data source name */
  NAME = "NAME",
}

/** Range of dates */
export type DateRangeInput = {
  /** Maximum value of the range */
  readonly max?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  /** Minimum value of the range */
  readonly min?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
};

export type DecisionAlgorithm = {
  readonly __typename: "DecisionAlgorithm";
  readonly cancelable: DecisionAlgorithmCancelable;
  readonly fullDescription: Scalars["String"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly hardcodedAlgorithmClass: Scalars["String"]["output"];
  readonly id: Scalars["DecisionAlgorithmId"]["output"];
  readonly mnemonic: Scalars["String"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly recurrence: DecisionAlgorithmRecurrence;
  readonly shortDescription: Scalars["String"]["output"];
};

/** How the decision algorithm should respond when it was triggered and then returns nil */
export enum DecisionAlgorithmCancelable {
  /** This algorithm should be automatically canceled */
  CANCEL_ON_NIL = "CANCEL_ON_NIL",
  /** This algorithm should not be automatically canceled */
  NEVER_CANCEL = "NEVER_CANCEL",
}

/** The connection type for DecisionAlgorithm. */
export type DecisionAlgorithmConnection = {
  readonly __typename: "DecisionAlgorithmConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<DecisionAlgorithmEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<DecisionAlgorithm>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type DecisionAlgorithmEdge = {
  readonly __typename: "DecisionAlgorithmEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: DecisionAlgorithm;
};

/** How the algorithm should respond when it gets triggered more than once over time */
export enum DecisionAlgorithmRecurrence {
  /** This algorithm should only trigger once per care episode */
  NEVER = "NEVER",
  /** This algorithm may trigger multiple workflows with different priorities, use the highest one */
  REPLACE_HIGHER_PRIORITY = "REPLACE_HIGHER_PRIORITY",
}

export enum DecisionAlgorithmSortParameter {
  /** Id */
  ID = "ID",
}

/** Autogenerated input type of DeleteAppointment */
export type DeleteAppointmentInput = {
  readonly appointmentId: Scalars["AppointmentId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly deleteSeries?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Autogenerated return type of DeleteAppointment. */
export type DeleteAppointmentPayload = {
  readonly __typename: "DeleteAppointmentPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<DeleteAppointmentResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type DeleteAppointmentResult = {
  readonly __typename: "DeleteAppointmentResult";
  readonly deletedAppointmentId: Scalars["AppointmentId"]["output"];
};

/** Autogenerated input type of DeleteCareEpisodeProvider */
export type DeleteCareEpisodeProviderInput = {
  readonly careEpisodeProviderId: Scalars["CareEpisodeProviderId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of DeleteCareEpisodeProvider. */
export type DeleteCareEpisodeProviderPayload = {
  readonly __typename: "DeleteCareEpisodeProviderPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<DeleteCareEpisodeProviderResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type DeleteCareEpisodeProviderResult = {
  readonly __typename: "DeleteCareEpisodeProviderResult";
  readonly careEpisode: CareEpisode;
};

/** Autogenerated input type of DeleteNote */
export type DeleteNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly noteId: Scalars["NoteId"]["input"];
};

/** Autogenerated return type of DeleteNote. */
export type DeleteNotePayload = {
  readonly __typename: "DeleteNotePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<DeleteNoteResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type DeleteNoteResult = {
  readonly __typename: "DeleteNoteResult";
  readonly institute: Institute;
};

/** Autogenerated input type of DeleteQuickText */
export type DeleteQuickTextInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly quickTextId: Scalars["QuickTextId"]["input"];
};

/** Autogenerated return type of DeleteQuickText. */
export type DeleteQuickTextPayload = {
  readonly __typename: "DeleteQuickTextPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<DeleteQuickTextResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type DeleteQuickTextResult = {
  readonly __typename: "DeleteQuickTextResult";
  readonly quickText: QuickText;
};

/** Autogenerated input type of DeleteTaskTemplate */
export type DeleteTaskTemplateInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly taskTemplateId: Scalars["TaskTemplateId"]["input"];
};

/** Autogenerated return type of DeleteTaskTemplate. */
export type DeleteTaskTemplatePayload = {
  readonly __typename: "DeleteTaskTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<DeleteTaskTemplateResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type DeleteTaskTemplateResult = {
  readonly __typename: "DeleteTaskTemplateResult";
  readonly success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of DeleteTimeEntryLog */
export type DeleteTimeEntryLogInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly timeEntryLogId: Scalars["TimeEntryLogId"]["input"];
};

/** Autogenerated return type of DeleteTimeEntryLog. */
export type DeleteTimeEntryLogPayload = {
  readonly __typename: "DeleteTimeEntryLogPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<DeleteTimeEntryLogResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type DeleteTimeEntryLogResult = {
  readonly __typename: "DeleteTimeEntryLogResult";
  readonly myActiveTimeEntryLog: MyActiveTimeEntryLog;
  readonly success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of DeleteTreatmentTarget */
export type DeleteTreatmentTargetInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly treatmentTargetId: Scalars["TreatmentTargetId"]["input"];
};

/** Autogenerated return type of DeleteTreatmentTarget. */
export type DeleteTreatmentTargetPayload = {
  readonly __typename: "DeleteTreatmentTargetPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<DeleteTreatmentTargetResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type DeleteTreatmentTargetResult = {
  readonly __typename: "DeleteTreatmentTargetResult";
  readonly treatmentTarget: TreatmentTarget;
};

/** Autogenerated input type of DeleteTreatmentTargetScale */
export type DeleteTreatmentTargetScaleInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly scaleId: Scalars["ScaleId"]["input"];
  readonly treatmentTargetId: Scalars["TreatmentTargetId"]["input"];
};

/** Autogenerated return type of DeleteTreatmentTargetScale. */
export type DeleteTreatmentTargetScalePayload = {
  readonly __typename: "DeleteTreatmentTargetScalePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<DeleteTreatmentTargetScaleResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type DeleteTreatmentTargetScaleResult = {
  readonly __typename: "DeleteTreatmentTargetScaleResult";
  readonly treatmentTarget: TreatmentTarget;
};

/** Autogenerated input type of DischargeCareEpisode */
export type DischargeCareEpisodeInput = {
  readonly careEpisodeId: Scalars["CareEpisodeId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of DischargeCareEpisode. */
export type DischargeCareEpisodePayload = {
  readonly __typename: "DischargeCareEpisodePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<DischargeCareEpisodeResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type DischargeCareEpisodeResult = {
  readonly __typename: "DischargeCareEpisodeResult";
  readonly success: Maybe<Scalars["Boolean"]["output"]>;
};

/** Autogenerated input type of DischargeEnrollment */
export type DischargeEnrollmentInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly dischargeReason?: InputMaybe<DischargeReason>;
  readonly dischargeText?: InputMaybe<Scalars["String"]["input"]>;
  readonly enrollmentId: Scalars["EnrollmentId"]["input"];
};

/** Autogenerated return type of DischargeEnrollment. */
export type DischargeEnrollmentPayload = {
  readonly __typename: "DischargeEnrollmentPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<DischargeEnrollmentResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type DischargeEnrollmentResult = {
  readonly __typename: "DischargeEnrollmentResult";
  readonly enrollment: Enrollment;
};

/** Tracks the reason for discharge, set by the care manager */
export enum DischargeReason {
  /** The patient has changed their care provider */
  CHANGED_PROVIDER = "CHANGED_PROVIDER",
  /** The patient successfully completed the program */
  COMPLETED = "COMPLETED",
  /** The patient dropped out or otherwise declined to participate */
  DROPPED_OUT = "DROPPED_OUT",
  /** The patient was in contact for a time before losing contact */
  LOST_CONTACT = "LOST_CONTACT",
  /** The patient was never able to be contacted */
  NO_CONTACT = "NO_CONTACT",
  /** Other */
  OTHER = "OTHER",
}

/** Autogenerated input type of DismissFinding */
export type DismissFindingInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly findingId: Scalars["FindingId"]["input"];
};

/** Autogenerated return type of DismissFinding. */
export type DismissFindingPayload = {
  readonly __typename: "DismissFindingPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<DismissFindingResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type DismissFindingResult = {
  readonly __typename: "DismissFindingResult";
  readonly finding: Finding;
};

export type Domain = {
  readonly __typename: "Domain";
  readonly constructs: ReadonlyArray<Construct>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["DomainId"]["output"];
  readonly mnemonic: Scalars["String"]["output"];
  readonly name: Scalars["String"]["output"];
};

export type DrChronoEhrLinkDetails = {
  readonly __typename: "DrChronoEhrLinkDetails";
  readonly refreshToken: Maybe<Scalars["String"]["output"]>;
  readonly token: Maybe<Scalars["String"]["output"]>;
  readonly tokenExpiresAt: Maybe<Scalars["String"]["output"]>;
};

/** Parameters for how to break down the graph data for a metric request into buckets of time */
export enum DrilldownMetricGraphInterval {
  /** Show a graph point every day */
  DAY = "DAY",
  /** Show a graph point every hour */
  HOUR = "HOUR",
  /** Show a graph point every month */
  MONTH = "MONTH",
  /** Show a graph point every week */
  WEEK = "WEEK",
  /** Show a graph point every year */
  YEAR = "YEAR",
}

/** Parameters for evoking the drilldown metric system. */
export type DrilldownMetricParams = {
  /** The entity tree node of the data */
  readonly entityTreeNode: EntityTreeNodeOrCollectionParams;
  /** How to break down  */
  readonly graphInterval?: InputMaybe<DrilldownMetricGraphInterval>;
  /** Include graph data in the response */
  readonly includeGraph: Scalars["Boolean"]["input"];
  /** How many results tod return for collections */
  readonly limit?: InputMaybe<Scalars["Int"]["input"]>;
  /** Which period to view results for */
  readonly period: DrilldownMetricTimeParams;
  /** Which report to view */
  readonly shortcode: DrilldownMetricShortcode;
  readonly sort?: InputMaybe<Scalars["String"]["input"]>;
  readonly sortDirection?: InputMaybe<SortDirection>;
};

/** Presets of dates available for drilldown reports */
export enum DrilldownMetricPreset {
  /** All time */
  ALL_TIME = "ALL_TIME",
  /** Last 7 days in the timezone of the institute */
  LAST_7_DAYS = "LAST_7_DAYS",
  /** The last 30 days in the timezone of the institute */
  LAST_30_DAYS = "LAST_30_DAYS",
  /** Last 90 days */
  LAST_90_DAYS = "LAST_90_DAYS",
  /** The previous monththe timezone of the institute */
  LAST_MONTH = "LAST_MONTH",
  /** The previous quarter */
  LAST_QUARTER = "LAST_QUARTER",
  /** The previous week in the timezone of the institute, starting on Monday */
  LAST_WEEK = "LAST_WEEK",
  /** Last year */
  LAST_YEAR = "LAST_YEAR",
  /** The current month in the timezone of the institute */
  THIS_MONTH = "THIS_MONTH",
  /** The current quarter */
  THIS_QUARTER = "THIS_QUARTER",
  /** The current week in the timezone of the institute, starting on Monday */
  THIS_WEEK = "THIS_WEEK",
  /** THis year */
  THIS_YEAR = "THIS_YEAR",
  /** Today in the timezone of the institute */
  TODAY = "TODAY",
  /** Yesterday in the timezone of the institute */
  YESTERDAY = "YESTERDAY",
}

/** Available reports to use in the drilldown system */
export enum DrilldownMetricShortcode {
  /** Insurance billing data */
  BILLABLE_CHARGE_DRILLDOWN = "BILLABLE_CHARGE_DRILLDOWN",
  /** Per care episodes outcomes data */
  CARE_EPISODE_OUTCOME_DRILLDOWN = "CARE_EPISODE_OUTCOME_DRILLDOWN",
  /** Efficacy of billing data */
  CHARGE_EFFICIENCY_DRILLDOWN = "CHARGE_EFFICIENCY_DRILLDOWN",
  /** Stats about clients assessment completion */
  CLIENT_ASSESSMENT_COMPLETION_DRILLDOWN = "CLIENT_ASSESSMENT_COMPLETION_DRILLDOWN",
  /** Insurance coverage */
  COVERAGE_DRILLDOWN = "COVERAGE_DRILLDOWN",
  /** Which decision support actions have been taken */
  DECISION_SUPPORT_ACTIONS_DRILLDOWN = "DECISION_SUPPORT_ACTIONS_DRILLDOWN",
  /** Which decision support workflows have been used */
  DECISION_SUPPORT_WORKFLOW_DRILLDOWN = "DECISION_SUPPORT_WORKFLOW_DRILLDOWN",
  /** Which patients have started care */
  ENROLLMENT_DRILLDOWN = "ENROLLMENT_DRILLDOWN",
  /** History of no-show data */
  NO_SHOW_HISTORY_DRILLDOWN = "NO_SHOW_HISTORY_DRILLDOWN",
  /** Outcomes data */
  OUTCOMES_METRIC_DRILLDOWN = "OUTCOMES_METRIC_DRILLDOWN",
  /** Number of patient data quality issues */
  PATIENT_QUALITY_ISSUE_DRILLDOWN = "PATIENT_QUALITY_ISSUE_DRILLDOWN",
  /** Patient usage data */
  PATIENT_USAGE_DRILLDOWN = "PATIENT_USAGE_DRILLDOWN",
  /** Stats about providers assessment completion */
  PROVIDER_ASSESSMENT_COMPLETION_DRILLDOWN = "PROVIDER_ASSESSMENT_COMPLETION_DRILLDOWN",
  /** Stats about provider usage */
  PROVIDER_USAGE_DRILLDOWN = "PROVIDER_USAGE_DRILLDOWN",
  /** Which data quality issues have been addressed */
  QUALITY_ISSUE_WORKFLOW_DRILLDOWN = "QUALITY_ISSUE_WORKFLOW_DRILLDOWN",
  /** Per session outcomes data */
  SESSION_OUTCOME_DRILLDOWN = "SESSION_OUTCOME_DRILLDOWN",
  /** How long assessments take to complete */
  TAKING_TIME_DRILLDOWN = "TAKING_TIME_DRILLDOWN",
  /** Which treatment tracks are currently active */
  TREATMENT_TRACK_ACTIVE_DRILLDOWN = "TREATMENT_TRACK_ACTIVE_DRILLDOWN",
  /** Treatment track enrollment data */
  TREATMENT_TRACK_ENROLLMENT_DRILLDOWN = "TREATMENT_TRACK_ENROLLMENT_DRILLDOWN",
}

export type DrilldownMetricTimeParams =
  /** The range of dates required */
  | {
      readonly dateRange: DateRangeInput;
      readonly preset?: never;
    } /** How many results to return for collections */
  | { readonly dateRange?: never; readonly preset: DrilldownMetricPreset };

export type Edge = {
  readonly __typename: "Edge";
  readonly id: Scalars["String"]["output"];
  readonly summary: ReadonlyArray<Field>;
};

export type Edges = {
  readonly __typename: "Edges";
  readonly className: Scalars["String"]["output"];
  readonly edges: ReadonlyArray<Edge>;
};

/** Autogenerated input type of EditAppointment */
export type EditAppointmentInput = {
  readonly appointmentId: Scalars["AppointmentId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly minutesDuration?: InputMaybe<Scalars["Int"]["input"]>;
  readonly providerId: Scalars["ProviderId"]["input"];
  readonly recurrenceEndDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  readonly recurrenceFrequency?: InputMaybe<RecurrenceFrequency>;
  readonly startDate: Scalars["ISO8601DateTime"]["input"];
};

/** Autogenerated return type of EditAppointment. */
export type EditAppointmentPayload = {
  readonly __typename: "EditAppointmentPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EditAppointmentResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type EditAppointmentResult = {
  readonly __typename: "EditAppointmentResult";
  readonly appointment: Appointment;
};

/** Autogenerated input type of EditCareEpisode */
export type EditCareEpisodeInput = {
  readonly careEpisodeId: Scalars["CareEpisodeId"]["input"];
  readonly careTeam?: InputMaybe<ReadonlyArray<CareEpisodeProviderInput>>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly organizationId: Scalars["OrganizationId"]["input"];
  readonly periodStart?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  readonly treatmentServiceId?: InputMaybe<Scalars["TreatmentServiceId"]["input"]>;
};

/** Autogenerated return type of EditCareEpisode. */
export type EditCareEpisodePayload = {
  readonly __typename: "EditCareEpisodePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EditCareEpisodeResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type EditCareEpisodeResult = {
  readonly __typename: "EditCareEpisodeResult";
  readonly careEpisode: CareEpisode;
};

/** Autogenerated input type of EditExternalEvent */
export type EditExternalEventInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly data?: InputMaybe<Scalars["PipbhcExternalEventData"]["input"]>;
  readonly details?: InputMaybe<Scalars["String"]["input"]>;
  readonly effectiveDate: Scalars["ISO8601DateTime"]["input"];
  readonly externalEventId: Scalars["ExternalEventId"]["input"];
  readonly name: Scalars["String"]["input"];
  readonly patientId: Scalars["PatientId"]["input"];
};

/** Autogenerated return type of EditExternalEvent. */
export type EditExternalEventPayload = {
  readonly __typename: "EditExternalEventPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EditExternalEventResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type EditExternalEventResult = {
  readonly __typename: "EditExternalEventResult";
  readonly externalEvent: ExternalEvent;
};

/** Autogenerated input type of EditNote */
export type EditNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly noteId: Scalars["NoteId"]["input"];
  readonly text: Scalars["String"]["input"];
};

/** Autogenerated return type of EditNote. */
export type EditNotePayload = {
  readonly __typename: "EditNotePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EditNoteResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type EditNoteResult = {
  readonly __typename: "EditNoteResult";
  readonly note: Note;
};

/** Autogenerated input type of EditOrganization */
export type EditOrganizationInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly inTrialPeriod: Scalars["Boolean"]["input"];
  readonly measurementAllowed: Scalars["Boolean"]["input"];
  readonly name: Scalars["String"]["input"];
  readonly organizationId: Scalars["OrganizationId"]["input"];
  readonly parentId?: InputMaybe<Scalars["OrganizationId"]["input"]>;
  readonly shortname: Scalars["String"]["input"];
  readonly timezone?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of EditOrganization. */
export type EditOrganizationPayload = {
  readonly __typename: "EditOrganizationPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EditOrganizationResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type EditOrganizationResult = {
  readonly __typename: "EditOrganizationResult";
  readonly organization: Organization;
};

/** Autogenerated input type of EditPanel */
export type EditPanelInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly name?: InputMaybe<Scalars["String"]["input"]>;
  readonly panelId: Scalars["PanelId"]["input"];
  readonly status: PanelStatus;
};

/** Autogenerated return type of EditPanel. */
export type EditPanelPayload = {
  readonly __typename: "EditPanelPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EditPanelResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type EditPanelResult = {
  readonly __typename: "EditPanelResult";
  readonly panel: Panel;
};

/** Autogenerated input type of EditPanelTeamMember */
export type EditPanelTeamMemberInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly endDate?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  readonly startDate: Scalars["ISO8601DateTime"]["input"];
  readonly teamMemberId: Scalars["PanelTeamMemberId"]["input"];
};

/** Autogenerated return type of EditPanelTeamMember. */
export type EditPanelTeamMemberPayload = {
  readonly __typename: "EditPanelTeamMemberPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EditPanelTeamMemberResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type EditPanelTeamMemberResult = {
  readonly __typename: "EditPanelTeamMemberResult";
  readonly panelTeamMember: PanelTeamMember;
};

/** Autogenerated input type of EditPatientDemographic */
export type EditPatientDemographicInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly dob?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  readonly email?: InputMaybe<Scalars["String"]["input"]>;
  readonly ethnicity?: InputMaybe<Scalars["String"]["input"]>;
  readonly gender?: InputMaybe<Gender>;
  readonly genderIdentity?: InputMaybe<Scalars["String"]["input"]>;
  readonly genderIdentityFhir?: InputMaybe<GenderIdentity>;
  readonly isTest?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly legalFirstName?: InputMaybe<Scalars["String"]["input"]>;
  readonly legalLastName?: InputMaybe<Scalars["String"]["input"]>;
  readonly mrn?: InputMaybe<Scalars["String"]["input"]>;
  readonly notificationPreference?: InputMaybe<NotificationPreference>;
  readonly patientId: Scalars["PatientId"]["input"];
  readonly phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  readonly preferredFirstName?: InputMaybe<Scalars["String"]["input"]>;
  readonly preferredLastName?: InputMaybe<Scalars["String"]["input"]>;
  readonly preferredPronouns?: InputMaybe<Scalars["String"]["input"]>;
  readonly primaryLanguage?: InputMaybe<Scalars["String"]["input"]>;
  readonly race?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of EditPatientDemographic. */
export type EditPatientDemographicPayload = {
  readonly __typename: "EditPatientDemographicPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EditPatientDemographicResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type EditPatientDemographicResult = {
  readonly __typename: "EditPatientDemographicResult";
  readonly patient: Patient;
};

/** Autogenerated input type of EditTimeEntryLog */
export type EditTimeEntryLogInput = {
  readonly clientEndTime?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly clientStartTime?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  readonly durationSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  readonly noteText?: InputMaybe<Scalars["String"]["input"]>;
  readonly providerId: Scalars["ProviderId"]["input"];
  readonly timeEntryLogId: Scalars["TimeEntryLogId"]["input"];
};

/** Autogenerated return type of EditTimeEntryLog. */
export type EditTimeEntryLogPayload = {
  readonly __typename: "EditTimeEntryLogPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EditTimeEntryLogResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type EditTimeEntryLogResult = {
  readonly __typename: "EditTimeEntryLogResult";
  readonly myActiveTimeEntryLog: MyActiveTimeEntryLog;
  readonly timeEntryLog: TimeEntryLog;
};

/** Autogenerated input type of EditTreatmentTarget */
export type EditTreatmentTargetInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly treatmentTargetId: Scalars["TreatmentTargetId"]["input"];
};

/** Autogenerated return type of EditTreatmentTarget. */
export type EditTreatmentTargetPayload = {
  readonly __typename: "EditTreatmentTargetPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EditTreatmentTargetResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type EditTreatmentTargetResult = {
  readonly __typename: "EditTreatmentTargetResult";
  readonly treatmentTarget: TreatmentTarget;
};

export type EditTreatmentTargetScaleResult = {
  readonly __typename: "EditTreatmentTargetScaleResult";
  readonly treatmentTarget: TreatmentTarget;
};

export type EhrLink = {
  readonly __typename: "EhrLink";
  readonly connectionType: Maybe<EhrLinkType>;
  readonly connectionTypeClass: Maybe<EhrLinkTypeClass>;
  readonly dataSource: Maybe<DataSource>;
  readonly dataSourceResourceExtractors: ReadonlyArray<DataSourceResourceExtractor>;
  readonly details: Maybe<EhrLinkDetails>;
  readonly ehrLinkLogs: EhrLinkLogConnection;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["EhrLinkId"]["output"];
  readonly lastLog: Maybe<EhrLinkLog>;
  readonly notes: Maybe<Scalars["String"]["output"]>;
  readonly schedule: Maybe<Scalars["String"]["output"]>;
};

export type EhrLinkehrLinkLogsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  ehrLinkId: InputMaybe<ReadonlyArray<Scalars["EhrLinkId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<EhrLinkLogSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<EhrLinkLogStatus>>;
};

/** The connection type for EhrLink. */
export type EhrLinkConnection = {
  readonly __typename: "EhrLinkConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<EhrLinkEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<EhrLink>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

export type EhrLinkDetails =
  | CanvasEhrLinkDetails
  | CernerEhrLinkDetails
  | CredibleEhrLinkDetails
  | DrChronoEhrLinkDetails
  | EpicEhrLinkDetails
  | HealthieEhrLinkDetails
  | TherapyNotesEhrLinkDetails
  | ValentEhrLinkDetails;

/** An edge in a connection. */
export type EhrLinkEdge = {
  readonly __typename: "EhrLinkEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: EhrLink;
};

export type EhrLinkLog = {
  readonly __typename: "EhrLinkLog";
  readonly endDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["EhrLinkLogId"]["output"];
  readonly log: Maybe<Scalars["String"]["output"]>;
  readonly startDate: Scalars["ISO8601DateTime"]["output"];
  readonly status: EhrLinkLogStatus;
};

/** The connection type for EhrLinkLog. */
export type EhrLinkLogConnection = {
  readonly __typename: "EhrLinkLogConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<EhrLinkLogEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<EhrLinkLog>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type EhrLinkLogEdge = {
  readonly __typename: "EhrLinkLogEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: EhrLinkLog;
};

export enum EhrLinkLogSortParameter {
  /** End date */
  ENDDATE = "ENDDATE",
  /** Id */
  ID = "ID",
  /** Start date */
  STARTDATE = "STARTDATE",
}

/** Displays the status of the attempt to run the integration */
export enum EhrLinkLogStatus {
  /** The run is complete sucessfully */
  COMPLETE = "COMPLETE",
  /** There was an error when processing */
  ERROR = "ERROR",
  /** The run is in progress */
  INPROGRESS = "INPROGRESS",
  /** The run is currently enqueued */
  QUEUED = "QUEUED",
  /** This run is currently scheduled */
  SCHEDULED = "SCHEDULED",
  /** This run was skipped */
  SKIPPED = "SKIPPED",
}

export enum EhrLinkSortParameter {
  /** Id */
  ID = "ID",
}

/** Different systems we support EHR integrations for */
export enum EhrLinkType {
  /** Accumedic */
  ACCUMEDIC = "ACCUMEDIC",
  /** Advanced MD */
  ADVANCED_MD = "ADVANCED_MD",
  /** Depricated -- Athenahealth API */
  ATHENA = "ATHENA",
  /** Athena Marketplace */
  ATHENA_MARKETPLACE = "ATHENA_MARKETPLACE",
  /** Canvas */
  CANVAS = "CANVAS",
  /** Cerner - Backend */
  CERNER_BACKEND = "CERNER_BACKEND",
  /** Cerner - Provider */
  CERNER_PROVIDER = "CERNER_PROVIDER",
  /** Credible */
  CREDIBLE = "CREDIBLE",
  /** Dr Chrono */
  DR_CHRONO = "DR_CHRONO",
  /** Dummy */
  DUMMY = "DUMMY",
  /** Elation */
  ELATION = "ELATION",
  /** Epic - Backend */
  EPIC_BACKEND = "EPIC_BACKEND",
  /** Epic - Provider */
  EPIC_PROVIDER = "EPIC_PROVIDER",
  /** Secure SFTP transfer of flat files */
  FLAT_FILE = "FLAT_FILE",
  /** Healthie */
  HEALTHIE = "HEALTHIE",
  /** HL7 */
  HL7 = "HL7",
  /** IntakeQ */
  INTAKEQ = "INTAKEQ",
  /** Our integration API */
  INTEGRATION_API = "INTEGRATION_API",
  /** Pimsy */
  PIMSY = "PIMSY",
  /** Simple Practice */
  SIMPLE_PRACTICE = "SIMPLE_PRACTICE",
  /** Social solutions */
  SOCIAL_SOLUTIONS = "SOCIAL_SOLUTIONS",
  /** Therapy notes scraper */
  THERAPY_NOTES = "THERAPY_NOTES",
  /** Valant scraper */
  VALANT = "VALANT",
}

/** The overarching class of the type of connection, IE, a scraper */
export enum EhrLinkTypeClass {
  /** Uses an actual API for integrations */
  API = "API",
  /** Uses flat files for integrations */
  FLAT_FILE = "FLAT_FILE",
  /** Uses HL7 */
  HL7 = "HL7",
  /** Uses a scraper for integrations */
  SCRAPER = "SCRAPER",
  /** Unknown integration method */
  UNKNOWN = "UNKNOWN",
}

/** Autogenerated input type of EmailImplementationTargetReport */
export type EmailImplementationTargetReportInput = {
  readonly additionalEmailAddresses?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** What date and period this report is for */
  readonly dateAndPeriod: ImplementationTargetReportInput;
  /** The entity tree node for data */
  readonly entityTreeNode?: InputMaybe<EntityTreeNodeParams>;
  readonly hideProviderInformation?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly implementationTargetId: Scalars["ImplementationTargetId"]["input"];
};

/** Autogenerated return type of EmailImplementationTargetReport. */
export type EmailImplementationTargetReportPayload = {
  readonly __typename: "EmailImplementationTargetReportPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EmailImplementationTargetReportResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type EmailImplementationTargetReportResult = {
  readonly __typename: "EmailImplementationTargetReportResult";
  readonly sentToEmails: ReadonlyArray<Scalars["String"]["output"]>;
};

export type Encounter = {
  readonly __typename: "Encounter";
  readonly actualPeriodEnd: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly actualPeriodStart: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly appointment: Maybe<Appointment>;
  readonly careEpisode: Maybe<CareEpisode>;
  readonly encounterLocations: ReadonlyArray<EncounterLocation>;
  readonly encounterProviders: ReadonlyArray<EncounterProvider>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["EncounterId"]["output"];
  readonly organization: Maybe<Organization>;
  readonly patient: Patient;
  readonly serviceType: Maybe<Scalars["String"]["output"]>;
  readonly status: EncounterStatus;
};

/** The connection type for Encounter. */
export type EncounterConnection = {
  readonly __typename: "EncounterConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<EncounterEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Encounter>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type EncounterEdge = {
  readonly __typename: "EncounterEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Encounter;
};

export type EncounterLocation = {
  readonly __typename: "EncounterLocation";
  readonly location: Location;
  readonly periodEnd: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly periodStart: Maybe<Scalars["ISO8601DateTime"]["output"]>;
};

/** Role of a provider during an encounter */
export enum EncounterParticipantType {
  /** The practitioner who is responsible for admitting a patient to a patient encounter. */
  ADMITTER = "ADMITTER",
  /** The practitioner that has responsibility for overseeing a patient's care during a patient encounter. */
  ATTENDER = "ATTENDER",
  /** A person or organization who should be contacted for follow-up questions about the act in place of the author. */
  CALLBACK_CONTACT = "CALLBACK_CONTACT",
  /** An advisor participating in the service by performing evaluations and making recommendations. */
  CONSULTANT = "CONSULTANT",
  /** The practitioner who is responsible for the discharge of a patient from a patient encounter. */
  DISCHARGER = "DISCHARGER",
  /** A person to be contacted in case of an emergency during the encounter. */
  EMERGENCY = "EMERGENCY",
  /** Only with Transportation services. A person who escorts the patient. */
  ESCORT = "ESCORT",
  /**
   * A person having referred the subject of the service to the performer
   * (referring physician). Typically, a referring physician will receive a report.
   */
  REFERRER = "REFERRER",
  /** A translator who is facilitating communication with the patient during the encounter. */
  TRANSLATOR = "TRANSLATOR",
}

export type EncounterProvider = {
  readonly __typename: "EncounterProvider";
  readonly participantType: Maybe<EncounterParticipantType>;
  readonly periodEnd: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly periodStart: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly provider: Provider;
};

export enum EncounterSortParameter {
  /** Actual Period End */
  ACTUAL_PERIOD_END = "ACTUAL_PERIOD_END",
  /** Actual Period Start */
  ACTUAL_PERIOD_START = "ACTUAL_PERIOD_START",
  /** Id */
  ID = "ID",
}

/** Tracks the status of an encounter */
export enum EncounterStatus {
  /** The Encounter has ended before it has begun. */
  CANCELLED = "CANCELLED",
  /** The Encounter has ended */
  COMPLETED = "COMPLETED",
  /**
   * The Encounter has been clinically completed, the patient has been discharged
   * from the facility or the visit has ended, and the patient may have departed
   */
  DISCHARGED = "DISCHARGED",
  /** The Encounter has started, but was not able to be completed */
  DISCONTINUED = "DISCONTINUED",
  /** This instance should not have been part of this patient's medical record */
  ENTERED_IN_ERROR = "ENTERED_IN_ERROR",
  /** The Encounter has begun and the patient is present / the practitioner and the patient are meeting */
  IN_PROGRESS = "IN_PROGRESS",
  /** The Encounter has begun, but is currently on hold, e.g. because the patient is temporarily on leave */
  ON_HOLD = "ON_HOLD",
  /** The Encounter has not yet started */
  PLANNED = "PLANNED",
  /** The encounter status is unknown */
  UNKNOWN = "UNKNOWN",
}

/** Autogenerated input type of EndCaseConsultReview */
export type EndCaseConsultReviewInput = {
  readonly caseConsultId: Scalars["CaseConsultId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly createDocumentationTask?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly status?: InputMaybe<CaseConsultStatus>;
};

/** Autogenerated return type of EndCaseConsultReview. */
export type EndCaseConsultReviewPayload = {
  readonly __typename: "EndCaseConsultReviewPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EndCaseConsultReviewResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type EndCaseConsultReviewResult = {
  readonly __typename: "EndCaseConsultReviewResult";
  readonly caseConsult: CaseConsult;
  readonly myActiveTimeEntryLog: MyActiveTimeEntryLog;
};

/** Autogenerated input type of EndTimeEntryLog */
export type EndTimeEntryLogInput = {
  readonly clientEndTime?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly completeTask?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly durationSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  readonly timeEntryLogId: Scalars["TimeEntryLogId"]["input"];
};

/** Autogenerated return type of EndTimeEntryLog. */
export type EndTimeEntryLogPayload = {
  readonly __typename: "EndTimeEntryLogPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EndTimeEntryLogResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type EndTimeEntryLogResult = {
  readonly __typename: "EndTimeEntryLogResult";
  readonly myActiveTimeEntryLog: MyActiveTimeEntryLog;
  readonly timeEntryLog: TimeEntryLog;
};

/** Autogenerated input type of EnrollPatient */
export type EnrollPatientInput = {
  readonly careTeamIds: EnrollmentRequiredProvider;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly consentDate?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  readonly enrollmentDate?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  readonly panelId: Scalars["PanelId"]["input"];
  readonly patientId: Scalars["PatientId"]["input"];
  readonly startEnrollmentTimeTracking?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly taskDescriptions: ReadonlyArray<ShortTaskDescription>;
  readonly treatmentTargetIds: ReadonlyArray<Scalars["TreatmentTargetId"]["input"]>;
};

/** Autogenerated return type of EnrollPatient. */
export type EnrollPatientPayload = {
  readonly __typename: "EnrollPatientPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EnrollPatientResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type EnrollPatientResult = {
  readonly __typename: "EnrollPatientResult";
  readonly enrollment: Maybe<Enrollment>;
  readonly myActiveTimeEntryLog: MyActiveTimeEntryLog;
  readonly tasks: Maybe<ReadonlyArray<Task>>;
};

export type Enrollment = {
  readonly __typename: "Enrollment";
  readonly billableMinutesForCurrentMonth: Scalars["Int"]["output"];
  readonly canReactivate: Scalars["Boolean"]["output"];
  readonly careEpisode: CareEpisode;
  readonly careManagerTeamMember: Maybe<PanelTeamMember>;
  readonly consentDate: Scalars["ISO8601DateTime"]["output"];
  readonly dischargeReason: Maybe<DischargeReason>;
  readonly dischargeText: Maybe<Scalars["String"]["output"]>;
  readonly dischargedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly enrolledAt: Scalars["ISO8601DateTime"]["output"];
  readonly enrollmentTask: Maybe<Task>;
  readonly firstValidatedMeasureCompletedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["EnrollmentId"]["output"];
  readonly intakeSession: Maybe<PatientSession>;
  readonly intakeSessionScales: ReadonlyArray<IntakeSessionScale>;
  readonly intakeStatus: IntakeStatus;
  readonly panel: Maybe<Panel>;
  readonly primaryCareProviderTeamMember: Maybe<PanelTeamMember>;
  readonly status: EnrollmentStatus;
};

export type EnrollmentBillingMonth = {
  readonly __typename: "EnrollmentBillingMonth";
  readonly assessmentCompleted: Scalars["Boolean"]["output"];
  readonly billableMinutes: Scalars["Int"]["output"];
  readonly enrollment: Enrollment;
  readonly globalId: Scalars["ID"]["output"];
  readonly month: Scalars["Int"]["output"];
  readonly year: Scalars["Int"]["output"];
};

/** Autogenerated input type of EnrollmentCheckIntakeScalesComplete */
export type EnrollmentCheckIntakeScalesCompleteInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly enrollmentId: Scalars["EnrollmentId"]["input"];
};

/** Autogenerated return type of EnrollmentCheckIntakeScalesComplete. */
export type EnrollmentCheckIntakeScalesCompletePayload = {
  readonly __typename: "EnrollmentCheckIntakeScalesCompletePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<EnrollmentCheckIntakeScalesCompleteResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type EnrollmentCheckIntakeScalesCompleteResult = {
  readonly __typename: "EnrollmentCheckIntakeScalesCompleteResult";
  readonly enrollment: Enrollment;
};

/** The connection type for Enrollment. */
export type EnrollmentConnection = {
  readonly __typename: "EnrollmentConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<EnrollmentEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Enrollment>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type EnrollmentEdge = {
  readonly __typename: "EnrollmentEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Enrollment;
};

export type EnrollmentMonth = {
  readonly __typename: "EnrollmentMonth";
  readonly beginningOfMonth: Scalars["ISO8601Date"]["output"];
  readonly billableMinutes: Scalars["Int"]["output"];
  readonly billingEnrollmentMonthBillingRuleResultWinner: Maybe<EnrollmentMonthBillingRuleResultWinner>;
  readonly billingEnrollmentMonthBillingRuleResults: ReadonlyArray<EnrollmentMonthBillingRuleResult>;
  readonly careManager: Maybe<Provider>;
  readonly encounter: Maybe<Encounter>;
  readonly enrollment: Enrollment;
  readonly firstValidatedMeasureCompletedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["EnrollmentMonthId"]["output"];
  readonly lastValidatedMeasureCompletedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly minutesTargetCeiling: Scalars["Int"]["output"];
  readonly minutesTargetFloor: Scalars["Int"]["output"];
  readonly minutesTargetStatus: EnrollmentMonthMinutesTargetStatus;
  readonly month: Scalars["Int"]["output"];
  readonly monthlySummaryPdfContentBase64: Maybe<Scalars["String"]["output"]>;
  readonly pcp: Maybe<Provider>;
  readonly validatedMeasureCompleted: Scalars["Boolean"]["output"];
};

export type EnrollmentMonthBillingRuleResult = {
  readonly __typename: "EnrollmentMonthBillingRuleResult";
  readonly billableMinutes: Maybe<Scalars["Int"]["output"]>;
  readonly billableMinutesDiffVsCurrentWinner: Maybe<Scalars["Float"]["output"]>;
  readonly billableMinutesDiffVsNextPriority: Maybe<Scalars["Float"]["output"]>;
  readonly billableMinutesPowerStrength: Maybe<CocmBillingAlgorithmPowerStrength>;
  readonly billableMinutesPowerVsCurrentWinner: Maybe<Scalars["Float"]["output"]>;
  readonly billableMinutesPowerVsNextPriority: Maybe<Scalars["Float"]["output"]>;
  readonly billingEfficiency: Maybe<Scalars["Float"]["output"]>;
  readonly dateOfService: Maybe<Scalars["ISO8601Date"]["output"]>;
  readonly disqualifyReasonCodes: Maybe<ReadonlyArray<CocmBillingAlgorithmRuleCheck>>;
  readonly enrollmentMonth: EnrollmentMonth;
  readonly expectedRate: Money;
  readonly failReasonCodes: Maybe<ReadonlyArray<CocmBillingAlgorithmRuleCheck>>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["EnrollmentMonthBillingRuleResultId"]["output"];
  readonly minutesDiffVsCurrentWinner: Maybe<Scalars["Float"]["output"]>;
  readonly minutesDiffVsNextPriority: Maybe<Scalars["Float"]["output"]>;
  readonly minutesShort: Maybe<Scalars["Int"]["output"]>;
  readonly priority: Scalars["Int"]["output"];
  readonly rateDiffVsCurrentWinner: Maybe<Money>;
  readonly rateDiffVsNextPriority: Maybe<Money>;
  readonly ratePowerStrength: Maybe<CocmBillingAlgorithmPowerStrength>;
  readonly ratePowerVsCurrentWinner: Maybe<Scalars["Float"]["output"]>;
  readonly ratePowerVsNextPriority: Maybe<Scalars["Float"]["output"]>;
  readonly rule: CocmBillingAlgorithmRule;
  readonly rvuDiffVsCurrentWinner: Maybe<Scalars["Float"]["output"]>;
  readonly rvuDiffVsNextPriority: Maybe<Scalars["Float"]["output"]>;
  readonly rvuPowerStrength: Maybe<CocmBillingAlgorithmPowerStrength>;
  readonly rvuPowerVsCurrentWinner: Maybe<Scalars["Float"]["output"]>;
  readonly rvuPowerVsNextPriority: Maybe<Scalars["Float"]["output"]>;
  readonly rvus: Maybe<Scalars["Float"]["output"]>;
  readonly status: EnrollmentMonthBillingRuleResultStatus;
  readonly triggerDate: Maybe<Scalars["ISO8601Date"]["output"]>;
  readonly valueUnitDiffVsCurrentWinner: Maybe<Scalars["Float"]["output"]>;
  readonly valueUnitDiffVsNextPriority: Maybe<Scalars["Float"]["output"]>;
  readonly valueUnitPowerStrength: Maybe<CocmBillingAlgorithmPowerStrength>;
  readonly valueUnitPowerVsCurrentWinner: Maybe<Scalars["Float"]["output"]>;
  readonly valueUnitPowerVsNextPriority: Maybe<Scalars["Float"]["output"]>;
  readonly valueUnits: Maybe<Scalars["Float"]["output"]>;
  readonly winner: Maybe<EnrollmentMonthBillingRuleResultWinner>;
};

/** The connection type for EnrollmentMonthBillingRuleResult. */
export type EnrollmentMonthBillingRuleResultConnection = {
  readonly __typename: "EnrollmentMonthBillingRuleResultConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<EnrollmentMonthBillingRuleResultEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<EnrollmentMonthBillingRuleResult>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type EnrollmentMonthBillingRuleResultEdge = {
  readonly __typename: "EnrollmentMonthBillingRuleResultEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: EnrollmentMonthBillingRuleResult;
};

export enum EnrollmentMonthBillingRuleResultSortParameter {
  /** Id */
  ID = "ID",
  /** Priority */
  PRIORITY = "PRIORITY",
}

/** Tracks the status of billing recommendation */
export enum EnrollmentMonthBillingRuleResultStatus {
  /** This rule has met requirements and is billable */
  BILLABLE = "BILLABLE",
  /** The rule has failed prerequisites, and cannot be billed */
  DISQUALIFIED = "DISQUALIFIED",
  /** This rule has not yet met requirements but might in the future */
  REQUIREMENTS_NOT_MET = "REQUIREMENTS_NOT_MET",
}

export type EnrollmentMonthBillingRuleResultWinner = {
  readonly __typename: "EnrollmentMonthBillingRuleResultWinner";
  readonly algorithm: CocmBillingAlgorithm;
  readonly enrollmentMonth: EnrollmentMonth;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["EnrollmentMonthBillingRuleResultWinnerId"]["output"];
  readonly nextResult: Maybe<EnrollmentMonthBillingRuleResult>;
  readonly results: ReadonlyArray<EnrollmentMonthBillingRuleResult>;
  readonly winningResult: Maybe<EnrollmentMonthBillingRuleResult>;
};

/** The connection type for EnrollmentMonthBillingRuleResultWinner. */
export type EnrollmentMonthBillingRuleResultWinnerConnection = {
  readonly __typename: "EnrollmentMonthBillingRuleResultWinnerConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<EnrollmentMonthBillingRuleResultWinnerEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<EnrollmentMonthBillingRuleResultWinner>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type EnrollmentMonthBillingRuleResultWinnerEdge = {
  readonly __typename: "EnrollmentMonthBillingRuleResultWinnerEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: EnrollmentMonthBillingRuleResultWinner;
};

export enum EnrollmentMonthBillingRuleResultWinnerSortParameter {
  /** Billable minutes power */
  BILLABLE_MINUTES_POWER_VS_CURRENT_WINNER = "BILLABLE_MINUTES_POWER_VS_CURRENT_WINNER",
  CARE_MANAGER_NAME = "CARE_MANAGER_NAME",
  /** Billable Minutes */
  CURRENT_MINUTES = "CURRENT_MINUTES",
  /** Current winner billable minutes */
  CURRENT_WINNER_BILLABLE_MINUTES = "CURRENT_WINNER_BILLABLE_MINUTES",
  /** Expected rate cents */
  CURRENT_WINNER_EXPECTED_RATE_CENTS = "CURRENT_WINNER_EXPECTED_RATE_CENTS",
  /** Current winner RVUs */
  CURRENT_WINNER_RVUS = "CURRENT_WINNER_RVUS",
  /** Current winner value units */
  CURRENT_WINNER_VALUE_UNITS = "CURRENT_WINNER_VALUE_UNITS",
  /** Id */
  ID = "ID",
  /** minutes to threshold */
  MINUTES_SHORT = "MINUTES_SHORT",
  /** Month of treatment */
  MONTH_OF_TREATMENT = "MONTH_OF_TREATMENT",
  /** next winner billable minutes */
  NEXT_WINNER_BILLABLE_MINUTES = "NEXT_WINNER_BILLABLE_MINUTES",
  /** next winner billable minutes */
  NEXT_WINNER_BILLABLE_MINUTES_DIFF_VS_CURRENT_WINNER = "NEXT_WINNER_BILLABLE_MINUTES_DIFF_VS_CURRENT_WINNER",
  /** Next expected rate cents */
  NEXT_WINNER_EXPECTED_RATE_CENTS = "NEXT_WINNER_EXPECTED_RATE_CENTS",
  /** Diff vs current winner cents */
  NEXT_WINNER_RATE_DIFF_VS_CURRENT_WINNER = "NEXT_WINNER_RATE_DIFF_VS_CURRENT_WINNER",
  /** Next winner RVUs */
  NEXT_WINNER_RVUS = "NEXT_WINNER_RVUS",
  /** RVU diff between this and current winner */
  NEXT_WINNER_RVU_DIFF_VS_CURRENT_WINNER = "NEXT_WINNER_RVU_DIFF_VS_CURRENT_WINNER",
  /** next winner value units */
  NEXT_WINNER_VALUE_UNITS = "NEXT_WINNER_VALUE_UNITS",
  /** next winner value units */
  NEXT_WINNER_VALUE_UNIT_DIFF_VS_CURRENT_WINNER = "NEXT_WINNER_VALUE_UNIT_DIFF_VS_CURRENT_WINNER",
  PATIENT_DOB = "PATIENT_DOB",
  PATIENT_MRN = "PATIENT_MRN",
  PATIENT_NAME = "PATIENT_NAME",
  PCP_NAME = "PCP_NAME",
  /** Rate power */
  RATE_POWER_VS_CURRENT_WINNER = "RATE_POWER_VS_CURRENT_WINNER",
  /** RVU power */
  RVU_POWER_VS_CURRENT_WINNER = "RVU_POWER_VS_CURRENT_WINNER",
  /** Value unit power */
  VALUE_UNIT_POWER_VS_CURRENT_WINNER = "VALUE_UNIT_POWER_VS_CURRENT_WINNER",
}

/** The connection type for EnrollmentMonth. */
export type EnrollmentMonthConnection = {
  readonly __typename: "EnrollmentMonthConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<EnrollmentMonthEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<EnrollmentMonth>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type EnrollmentMonthEdge = {
  readonly __typename: "EnrollmentMonthEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: EnrollmentMonth;
};

/** Whether the month is under-target, on-target, or over-target for minutes */
export enum EnrollmentMonthMinutesTargetStatus {
  /** The month is on target for minutes for the month */
  ON_TARGET = "ON_TARGET",
  /** The month is over target minutes for the month */
  OVER_TARGET = "OVER_TARGET",
  /** The month is under target minutes for the month */
  UNDER_TARGET = "UNDER_TARGET",
}

export enum EnrollmentMonthSortParameter {
  /** Number of billable minutes */
  BILLABLE_MINUTES = "BILLABLE_MINUTES",
  /** Id */
  ID = "ID",
  /** Month of Enrollment */
  MONTH = "MONTH",
}

/** Required PCP & CM provider pair */
export type EnrollmentRequiredProvider = { readonly panelTeamIds: RequiredPanelTeamMemberIds };

export enum EnrollmentSortParameter {
  /** Id */
  ID = "ID",
}

/** Tracks the status of a collaborative care enrollment */
export enum EnrollmentStatus {
  /** This care episode's collaborative care is finished */
  DISCHARGED = "DISCHARGED",
  /** This care episode is enrolled in collaborative care */
  ENROLLED = "ENROLLED",
  /** This care episode is not currently enrolled in collaborative care */
  UNENROLLED = "UNENROLLED",
  /** This care episode's collaborative care was never started */
  UNENROLLED_NEVER_ENROLLED = "UNENROLLED_NEVER_ENROLLED",
}

export type Entity = {
  readonly __typename: "Entity";
  readonly entityType: EntityType;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["EntityId"]["output"];
  readonly integrationsDataSourceRecordLogs: ReadonlyArray<DataSourceRecordLog>;
  readonly integrationsDataSourceRecords: ReadonlyArray<DataSourceRecord>;
  readonly lastImportDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly name: Scalars["String"]["output"];
  readonly shortname: Scalars["String"]["output"];
  readonly source: Maybe<SourceEnum>;
  readonly updatedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
};

/** The connection type for Entity. */
export type EntityConnection = {
  readonly __typename: "EntityConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<EntityEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Entity>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

export type EntityDecisionAlgorithm = {
  readonly __typename: "EntityDecisionAlgorithm";
  readonly decisionAlgorithm: DecisionAlgorithm;
  readonly entityTreeNode: EntityTreeNode;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["EntityDecisionAlgorithmId"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly notes: Maybe<Scalars["String"]["output"]>;
  readonly sendNotifications: Scalars["Boolean"]["output"];
  readonly startDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly status: EntityDecisionAlgorithmStatus;
  readonly workflowNote: Maybe<Scalars["String"]["output"]>;
};

/** The connection type for EntityDecisionAlgorithm. */
export type EntityDecisionAlgorithmConnection = {
  readonly __typename: "EntityDecisionAlgorithmConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<EntityDecisionAlgorithmEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<EntityDecisionAlgorithm>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type EntityDecisionAlgorithmEdge = {
  readonly __typename: "EntityDecisionAlgorithmEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: EntityDecisionAlgorithm;
};

export enum EntityDecisionAlgorithmSortParameter {
  /** Id */
  ID = "ID",
}

/** Tracks the status of a decision algorithm */
export enum EntityDecisionAlgorithmStatus {
  /** Algorithm is active. Alerts will be created and displayed */
  ACTIVE = "ACTIVE",
  /** Algorithm will not trigger, and alerts are hidden */
  DISABLED = "DISABLED",
  /** Algorithm continues to trigger,  */
  HIDDEN = "HIDDEN",
  /** Algorithm will not trigger, but alerts for existing triggers will be seen */
  PAUSED = "PAUSED",
}

/** An edge in a connection. */
export type EntityEdge = {
  readonly __typename: "EntityEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Entity;
};

export type EntityMeasurementPlan = {
  readonly __typename: "EntityMeasurementPlan";
  readonly active: Scalars["Boolean"]["output"];
  readonly endDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly entityTreeNode: EntityTreeNode;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["EntityMeasurementPlanId"]["output"];
  readonly measurementPlan: MeasurementPlan;
  readonly needsReplanning: Scalars["Boolean"]["output"];
  readonly planType: MeasurementPlanType;
  readonly precedence: Scalars["Int"]["output"];
  readonly startDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
};

/** The connection type for EntityMeasurementPlan. */
export type EntityMeasurementPlanConnection = {
  readonly __typename: "EntityMeasurementPlanConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<EntityMeasurementPlanEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<EntityMeasurementPlan>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type EntityMeasurementPlanEdge = {
  readonly __typename: "EntityMeasurementPlanEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: EntityMeasurementPlan;
};

export enum EntityMeasurementPlanSortParameter {
  /** Id */
  ID = "ID",
}

export enum EntitySortParameter {
  /** Id */
  ID = "ID",
  /** Name */
  NAME = "NAME",
}

/** A set of available child collections for an entity tree node */
export type EntityTreeCollection = {
  readonly __typename: "EntityTreeCollection";
  readonly collection: EntityType;
  readonly entityTreeNode: EntityTreeNode;
  readonly path: Scalars["String"]["output"];
};

/** Parameters for invoking collection endpoints for the entity system */
export type EntityTreeCollectionParams = {
  /** The collection type */
  readonly collection: EntityType;
  /** The entity id for the base of the collection */
  readonly node: EntityTreeNodeParams;
};

/** Entity Tree Node represent a set of filter criteria that can be used to filter and segment data */
export type EntityTreeNode = {
  readonly __typename: "EntityTreeNode";
  readonly collections: ReadonlyArray<EntityTreeCollection>;
  readonly entity: Entity;
  readonly flagIds: Maybe<ReadonlyArray<Scalars["FlagId"]["output"]>>;
  readonly gender: Maybe<Gender>;
  readonly maxAge: Maybe<Scalars["Int"]["output"]>;
  readonly minAge: Maybe<Scalars["Int"]["output"]>;
  readonly name: Scalars["String"]["output"];
  readonly parent: Maybe<EntityTreeNode>;
  readonly path: Scalars["String"]["output"];
  readonly segments: ReadonlyArray<EntityTreeSegment>;
};

/** Parameters for invoking entity tree node system */
export type EntityTreeNodeOrCollectionParams =
  /** A collection endpoint */
  | {
      readonly collection: EntityTreeCollectionParams;
      readonly node?: never;
    } /** An individual node endpoint */
  | { readonly collection?: never; readonly node: EntityTreeNodeParams };

/** Parameters for invoking entity tree node system */
export type EntityTreeNodeParams =
  /** The entity id */
  | {
      readonly entityId: Scalars["EntityId"]["input"];
      readonly path?: never;
      readonly root?: never;
    } /** The entity tree node path of the data */
  | {
      readonly entityId?: never;
      readonly path: Scalars["String"]["input"];
      readonly root?: never;
    } /** Include all data */
  | { readonly entityId?: never; readonly path?: never; readonly root: Scalars["Boolean"]["input"] };

/** Parameter for querying the entitysystem */
export type EntityTreeNodeQueryParams = {
  readonly entityTreeNodeParams: EntityTreeNodeParams;
  readonly exact?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly lessSpecific?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly moreSpecific?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A segment for an entity tree node */
export type EntityTreeSegment = {
  readonly __typename: "EntityTreeSegment";
  readonly entityType: EntityType;
  readonly id: Scalars["EntityId"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly path: Scalars["String"]["output"];
  readonly rootPath: Scalars["String"]["output"];
  readonly shortname: Scalars["String"]["output"];
};

/** The available types for an entity in the system. */
export enum EntityType {
  /** An episode of care */
  CARE_EPISODE = "CARE_EPISODE",
  /** A CoCM care manager */
  CARE_MANAGER = "CARE_MANAGER",
  /** The type of patient */
  CARE_UNIT_TYPE = "CARE_UNIT_TYPE",
  /** An encounter */
  ENCOUNTER = "ENCOUNTER",
  /** An instance of a decision support algorithm */
  ENTITY_DECISION_ALGORITHM = "ENTITY_DECISION_ALGORITHM",
  /** An experiment */
  EXPERIMENT = "EXPERIMENT",
  /** The variant of an experiment */
  EXPERIMENT_VARIANT = "EXPERIMENT_VARIANT",
  /** A top level institute */
  INSTITUTE = "INSTITUTE",
  /** A diagnosis as specified by the institute EHR */
  INSTITUTE_CONDITION = "INSTITUTE_CONDITION",
  /** A group of institutes */
  INSTITUTE_GROUP = "INSTITUTE_GROUP",
  /** An insurance company */
  INSTITUTE_PAYOR = "INSTITUTE_PAYOR",
  /** A physical location */
  LOCATION = "LOCATION",
  /** An organization within an institute */
  ORGANIZATION = "ORGANIZATION",
  /** A collaborative care panel */
  PANEL = "PANEL",
  /** A patient */
  PATIENT = "PATIENT",
  /** A measurement of a patient */
  PATIENT_SESSION = "PATIENT_SESSION",
  /** A primary care physician */
  PRIMARY_CARE_PHYSICIAN = "PRIMARY_CARE_PHYSICIAN",
  /** A clinician/provider */
  PROVIDER = "PROVIDER",
  /** A provider in a given treatment role */
  PROVIDER_RELATIONSHIP = "PROVIDER_RELATIONSHIP",
  /** Top level root entity */
  ROOT = "ROOT",
  /** A treatment service */
  TREATMENT_SERVICE = "TREATMENT_SERVICE",
  /** A treatment track */
  TREATMENT_TRACK = "TREATMENT_TRACK",
  /** A user of the system. Could be a patient, provider, related person */
  USER = "USER",
  /** A workflow as part of a decision support algorithm */
  WORKFLOW = "WORKFLOW",
  /** An action taken as part of a decision support algorithm */
  WORKFLOW_ACTION = "WORKFLOW_ACTION",
}

export type EpicEhrLinkDetails = {
  readonly __typename: "EpicEhrLinkDetails";
  readonly url: Maybe<Scalars["String"]["output"]>;
};

export type EstimatedBillingCumulativeEffort = {
  readonly __typename: "EstimatedBillingCumulativeEffort";
  readonly dataPoints: ReadonlyArray<EstimatedBillingCumulativeEffortDatum>;
  readonly target: Maybe<Scalars["Float"]["output"]>;
};

export type EstimatedBillingCumulativeEffortDatum = {
  readonly __typename: "EstimatedBillingCumulativeEffortDatum";
  readonly additionalPercentOfTarget: Maybe<Scalars["Float"]["output"]>;
  readonly cumulativeBillableMinutes: Scalars["Int"]["output"];
  readonly cumulativeMinutes: Scalars["Int"]["output"];
  readonly cumulativeRateCents: Scalars["Int"]["output"];
  readonly cumulativeRvus: Scalars["Float"]["output"];
  readonly cumulativeValueUnits: Scalars["Float"]["output"];
  readonly cumulativeValueWhenCompleted: Maybe<Scalars["Float"]["output"]>;
  readonly resultId: Maybe<Scalars["EnrollmentMonthBillingRuleResultId"]["output"]>;
  readonly totalPercentOfTarget: Maybe<Scalars["Float"]["output"]>;
  readonly winnerId: Maybe<Scalars["EnrollmentMonthBillingRuleResultWinnerId"]["output"]>;
};

export type EstimatedPatientSessionMeasurement = {
  readonly __typename: "EstimatedPatientSessionMeasurement";
  readonly planningDetails: ReadonlyArray<PatientSessionPlanningDetail>;
  readonly scaleIds: ReadonlyArray<Scalars["ScaleId"]["output"]>;
  readonly sessionId: Scalars["PatientSessionId"]["output"];
  readonly sessionNumber: Scalars["Int"]["output"];
};

export type ExternalEvent = {
  readonly __typename: "ExternalEvent";
  readonly author: User;
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly data: Maybe<Scalars["PipbhcExternalEventData"]["output"]>;
  readonly details: Maybe<Scalars["String"]["output"]>;
  readonly effectiveDate: Scalars["ISO8601DateTime"]["output"];
  readonly externalEventType: ExternalEventType;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ExternalEventId"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly patient: Patient;
  readonly patientId: Scalars["PatientId"]["output"];
  readonly updatedAt: Scalars["ISO8601DateTime"]["output"];
};

/** The connection type for ExternalEvent. */
export type ExternalEventConnection = {
  readonly __typename: "ExternalEventConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ExternalEventEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<ExternalEvent>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ExternalEventEdge = {
  readonly __typename: "ExternalEventEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: ExternalEvent;
};

export enum ExternalEventSortParameter {
  /** Care unit */
  CARE_UNIT_ID = "CARE_UNIT_ID",
  /** Effective date */
  EFFECTIVE_DATE = "EFFECTIVE_DATE",
  /** Id */
  ID = "ID",
}

export type ExternalEventType = {
  readonly __typename: "ExternalEventType";
  readonly category: ExternalEventTypeCategory;
  readonly description: Scalars["String"]["output"];
  readonly externalEvents: ExternalEventConnection;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ExternalEventTypeId"]["output"];
  readonly mnemonic: Scalars["String"]["output"];
  readonly name: Scalars["String"]["output"];
};

export type ExternalEventTypeexternalEventsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  authorId: InputMaybe<ReadonlyArray<Scalars["UserId"]["input"]>>;
  before: InputMaybe<Scalars["String"]["input"]>;
  effectiveDate: InputMaybe<DateRangeInput>;
  externalEventTypeId: InputMaybe<ReadonlyArray<Scalars["ExternalEventTypeId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<ExternalEventSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

export enum ExternalEventTypeCategory {
  /** Referral */
  REFERRAL = "REFERRAL",
}

/** The connection type for ExternalEventType. */
export type ExternalEventTypeConnection = {
  readonly __typename: "ExternalEventTypeConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ExternalEventTypeEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<ExternalEventType>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ExternalEventTypeEdge = {
  readonly __typename: "ExternalEventTypeEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: ExternalEventType;
};

export enum ExternalEventTypeSortParameter {
  /** Id */
  ID = "ID",
  /** Name */
  NAME = "NAME",
}

export type ExternalObservation = {
  readonly __typename: "ExternalObservation";
  readonly effectiveDate: Scalars["ISO8601DateTime"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ExternalObservationId"]["output"];
  readonly interpretation: Maybe<Scalars["String"]["output"]>;
  readonly patient: Patient;
  readonly report: Maybe<Report>;
  readonly scale: Scale;
  readonly user: User;
  readonly valueFloat: Maybe<Scalars["Float"]["output"]>;
  readonly valueInt: Maybe<Scalars["Int"]["output"]>;
  readonly valueString: Maybe<Scalars["Int"]["output"]>;
};

/** The connection type for ExternalObservation. */
export type ExternalObservationConnection = {
  readonly __typename: "ExternalObservationConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ExternalObservationEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<ExternalObservation>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ExternalObservationEdge = {
  readonly __typename: "ExternalObservationEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: ExternalObservation;
};

export enum ExternalObservationSortParameter {
  /** Effective Date */
  EFFECTIVE_DATE = "EFFECTIVE_DATE",
  /** Id */
  ID = "ID",
}

/** Different mechanisms for EHR data extraction */
export enum ExtractorType {
  /** CSV file */
  CSV = "CSV",
  /** JSON file */
  JSON = "JSON",
}

export type Field = {
  readonly __typename: "Field";
  readonly name: Scalars["String"]["output"];
  readonly value: Maybe<Scalars["String"]["output"]>;
};

/** How a particular field was set, for example manually by a user or by the EHR. */
export enum FieldSourceEnum {
  /** This field was inferred by algorithm from other data received */
  ALGORITHM = "ALGORITHM",
  /** This field was directly set by data from the EHR */
  EHR = "EHR",
  /** This field was set by a human user of the system */
  MANUAL = "MANUAL",
  /** The source of this field is not known */
  UNKNOWN = "UNKNOWN",
}

export type Finding = {
  readonly __typename: "Finding";
  readonly acceptedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly dismissedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly flavor: FindingFlavor;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["FindingId"]["output"];
  readonly invalidatedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly invitation: Maybe<Invitation>;
  readonly patient: Maybe<Patient>;
  readonly snoozeUntil: Maybe<Scalars["ISO8601Date"]["output"]>;
};

/** The connection type for Finding. */
export type FindingConnection = {
  readonly __typename: "FindingConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<FindingEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Finding>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type FindingEdge = {
  readonly __typename: "FindingEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Finding;
};

/** The possible flavors for a finding */
export enum FindingFlavor {
  /** A patient has an assessment that needs to be reviewed */
  ASSESSMENT_NEEDS_REVIEW = "ASSESSMENT_NEEDS_REVIEW",
  /** A patient still needs to complete an assessment this month */
  MONTHLY_ASSESSMENT_NOT_COMPLETED = "MONTHLY_ASSESSMENT_NOT_COMPLETED",
  /** An assessment has not been completed X days after being sent */
  TIME_ELAPSED_SINCE_INVITATION = "TIME_ELAPSED_SINCE_INVITATION",
}

export enum FindingSortParameter {
  /** Id */
  ID = "ID",
}

export type Flag = {
  readonly __typename: "Flag";
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly editable: Scalars["Boolean"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly icon: Scalars["String"]["output"];
  readonly id: Scalars["FlagId"]["output"];
  readonly manual: Scalars["Boolean"]["output"];
  readonly name: Scalars["String"]["output"];
};

/** The connection type for Flag. */
export type FlagConnection = {
  readonly __typename: "FlagConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<FlagEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Flag>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type FlagEdge = {
  readonly __typename: "FlagEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Flag;
};

export enum FlagSortParameter {
  /** Id */
  ID = "ID",
  /** Name */
  NAME = "NAME",
}

export type FutureSessionMeasurementEstimate = {
  readonly __typename: "FutureSessionMeasurementEstimate";
  readonly careEpisode: CareEpisode;
  readonly results: ReadonlyArray<EstimatedPatientSessionMeasurement>;
};

/** Administrative sex as defined by https://www.hl7.org/fhir/valueset-administrative-gender.html */
export enum Gender {
  /** Female */
  FEMALE = "FEMALE",
  /** Male */
  MALE = "MALE",
  /** Other */
  OTHER = "OTHER",
  /** Unknown */
  UNKNOWN = "UNKNOWN",
}

/** Gender Identity as defined by http://hl7.org/fhir/R4/valueset-gender-identity.html */
export enum GenderIdentity {
  /** The patient identifies as female */
  FEMALE = "FEMALE",
  /** The patient identifies as male */
  MALE = "MALE",
  /** The patient identifies with neither/both female and male */
  NON_BINARY = "NON_BINARY",
  /** The patient does not wish to disclose his gender identity */
  NON_DISCLOSE = "NON_DISCLOSE",
  /** Other gender identity */
  OTHER = "OTHER",
  /** The patient identifies as transgender male-to-female */
  TRANSGENDER_FEMALE = "TRANSGENDER_FEMALE",
  /** The patient identifies as transgender female-to-male */
  TRANSGENDER_MALE = "TRANSGENDER_MALE",
}

/** Autogenerated input type of GenerateUserLoginCode */
export type GenerateUserLoginCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly patientSessionId: Scalars["PatientSessionId"]["input"];
};

/** Autogenerated return type of GenerateUserLoginCode. */
export type GenerateUserLoginCodePayload = {
  readonly __typename: "GenerateUserLoginCodePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<GenerateUserLoginCodeResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type GenerateUserLoginCodeResult = {
  readonly __typename: "GenerateUserLoginCodeResult";
  readonly loginCodes: ReadonlyArray<UserLoginCode>;
  readonly patientSession: PatientSession;
};

export type Goal = {
  readonly __typename: "Goal";
  readonly careEpisode: CareEpisode;
  readonly endDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  /** Answers for this goal */
  readonly goalAnswers: ReadonlyArray<GoalAnswer>;
  readonly goalType: GoalType;
  readonly id: Scalars["GoalId"]["output"];
  readonly isBeneficial: Scalars["Boolean"]["output"];
  readonly patient: Patient;
  readonly patientId: Scalars["PatientId"]["output"];
  readonly patientText: Scalars["String"]["output"];
  readonly startDate: Scalars["ISO8601DateTime"]["output"];
  readonly status: GoalStatus;
  readonly thresholdData: ReadonlyArray<ScaleThreshold>;
  readonly user: User;
};

export type GoalAnswer = {
  readonly __typename: "GoalAnswer";
  readonly answerDate: Scalars["ISO8601DateTime"]["output"];
  /** Measurement effective date */
  readonly effectiveDate: Scalars["ISO8601DateTime"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly goal: Goal;
  readonly id: Scalars["GoalAnswerId"]["output"];
  readonly report: Report;
  /** Measurement target date */
  readonly targetDate: Scalars["ISO8601DateTime"]["output"];
  readonly user: User;
  readonly value: Maybe<Scalars["Int"]["output"]>;
};

/** The connection type for Goal. */
export type GoalConnection = {
  readonly __typename: "GoalConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<GoalEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Goal>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type GoalEdge = {
  readonly __typename: "GoalEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Goal;
};

/** Events possible to be triggered on goals */
export enum GoalEvent {
  /** Cancel this goal */
  CANCEL = "CANCEL",
  /** Complete this goal */
  COMPLETE = "COMPLETE",
  /** Pause */
  PAUSE = "PAUSE",
  /** Resume */
  RESUME = "RESUME",
}

export enum GoalSortParameter {
  /** Id */
  ID = "ID",
}

/** The status of goal */
export enum GoalStatus {
  /** Active */
  ACTIVE = "ACTIVE",
  /** Completed */
  COMPLETED = "COMPLETED",
  /** Entered in error, no data entered */
  ENTERED_IN_ERROR = "ENTERED_IN_ERROR",
  /** Created but never answered */
  NEW = "NEW",
  /** Paused */
  PAUSED = "PAUSED",
}

/** The type of goal */
export enum GoalType {
  /** Custom */
  CUSTOM = "CUSTOM",
  /** Attainment scaling */
  GOAL_ATTAINMENT_SCALING = "GOAL_ATTAINMENT_SCALING",
  /** Likert-style goal */
  GOAL_LIKERT = "GOAL_LIKERT",
  /** Top problem */
  TOP_PROBLEM = "TOP_PROBLEM",
}

export type HealthieEhrLinkDetails = {
  readonly __typename: "HealthieEhrLinkDetails";
  readonly apiKey: Maybe<Scalars["String"]["output"]>;
};

/** An identifier from an external system */
export type Identifier = {
  readonly __typename: "Identifier";
  /** The system of record that assigned this value */
  readonly assigner: Scalars["String"]["output"];
  /** The value assigned by the system of record */
  readonly value: Scalars["String"]["output"];
};

export type ImplementationTarget = {
  readonly __typename: "ImplementationTarget";
  readonly availableReportDates: ReadonlyArray<ImplementationTargetReportDate>;
  readonly closestReportDateTo: Maybe<ImplementationTargetReportDate>;
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly displayOrder: Maybe<Scalars["Int"]["output"]>;
  readonly endDate: Maybe<Scalars["ISO8601Date"]["output"]>;
  readonly entityTreeNode: EntityTreeNode;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ImplementationTargetId"]["output"];
  readonly initialCredits: Maybe<Scalars["Float"]["output"]>;
  readonly latestReport: Maybe<ImplementationTargetReport>;
  readonly mirahInternal: Scalars["Boolean"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly softLaunchDate: Maybe<Scalars["ISO8601Date"]["output"]>;
  readonly startDate: Scalars["ISO8601Date"]["output"];
  readonly status: ImplementationTargetStatus;
  readonly target: Maybe<Scalars["Float"]["output"]>;
  readonly targetPhase: ImplementationTargetPhase;
  readonly targetType: ImplementationTargetType;
};

export type ImplementationTargetclosestReportDateToArgs = {
  date: Scalars["ISO8601Date"]["input"];
};

export type ImplementationTargetBreakdownStat = {
  readonly __typename: "ImplementationTargetBreakdownStat";
  readonly displayValue: Scalars["String"]["output"];
  readonly series: ImplementationTargetStatPeriod;
  readonly value: Scalars["Float"]["output"];
};

/** The connection type for ImplementationTarget. */
export type ImplementationTargetConnection = {
  readonly __typename: "ImplementationTargetConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ImplementationTargetEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<ImplementationTarget>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ImplementationTargetEdge = {
  readonly __typename: "ImplementationTargetEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: ImplementationTarget;
};

export type ImplementationTargetGraphData = {
  readonly __typename: "ImplementationTargetGraphData";
  readonly name: Scalars["String"]["output"];
  readonly points: ReadonlyArray<ChartData>;
  readonly seriesType: ImplementationTargetReportGraphDataSeries;
};

export type ImplementationTargetHeadlineStat = {
  readonly __typename: "ImplementationTargetHeadlineStat";
  /** If this stat should be displayed as a percentage this will be used */
  readonly denominator: Maybe<Scalars["Float"]["output"]>;
  readonly period: ImplementationTargetStatPeriod;
  readonly unitClass: ImplementationTargetReportGraphDataUnit;
  readonly units: Maybe<Scalars["String"]["output"]>;
  /** Value of the stat. Null implies not present, e.g. not yet available */
  readonly value: Maybe<Scalars["Float"]["output"]>;
};

/** Whether a particular target is on track or off track. */
export enum ImplementationTargetOnTrack {
  /** The target is on track */
  OFF_TRACK = "OFF_TRACK",
  /** The target is on track */
  ON_TRACK = "ON_TRACK",
}

/** The phase of implementation this target applies to */
export enum ImplementationTargetPhase {
  /** The target is a launch metric */
  LAUNCH = "LAUNCH",
  /** The target is an ongoing metric */
  ONGOING = "ONGOING",
}

export type ImplementationTargetReport = {
  readonly __typename: "ImplementationTargetReport";
  readonly breakdownMaxValue: Scalars["Float"]["output"];
  readonly breakdowns: ReadonlyArray<ImplementationTargetReportBreakdown>;
  readonly entityTreeNode: EntityTreeNode;
  readonly globalId: Scalars["ID"]["output"];
  readonly graphData: ReadonlyArray<ImplementationTargetGraphData>;
  readonly graphMaxValue: Scalars["Float"]["output"];
  readonly headlineStats: ReadonlyArray<ImplementationTargetHeadlineStat>;
  readonly id: Scalars["String"]["output"];
  readonly implementationTarget: ImplementationTarget;
  readonly insights: ReadonlyArray<ImplementationTargetReportInsight>;
  readonly onTrack: ImplementationTargetOnTrack;
  readonly reportDate: ImplementationTargetReportDate;
  readonly unitClass: ImplementationTargetReportGraphDataUnit;
};

export type ImplementationTargetReportBreakdown = {
  readonly __typename: "ImplementationTargetReportBreakdown";
  readonly collection: EntityTreeCollection;
  readonly nodes: ReadonlyArray<ImplementationTargetReportBreakdownNode>;
};

export type ImplementationTargetReportBreakdownNode = {
  readonly __typename: "ImplementationTargetReportBreakdownNode";
  readonly node: EntityTreeNode;
  readonly values: ReadonlyArray<ImplementationTargetBreakdownStat>;
};

export type ImplementationTargetReportDate = {
  readonly __typename: "ImplementationTargetReportDate";
  readonly endDate: Scalars["ISO8601Date"]["output"];
  readonly period: ImplementationTargetReportPeriod;
  /** Whether this report contains provisional data or is final */
  readonly provisional: Scalars["Boolean"]["output"];
  readonly startDate: Scalars["ISO8601Date"]["output"];
};

/** What the series for this line represents - e.g. is it real data, a target line, etc. */
export enum ImplementationTargetReportGraphDataSeries {
  /** This series is the actual data */
  DATA = "DATA",
  /** This series is the target line */
  TARGET = "TARGET",
}

/** What format is this data? */
export enum ImplementationTargetReportGraphDataUnit {
  /** Represent as a float */
  FLOAT = "FLOAT",
  /** Represent as an integer */
  INTEGER = "INTEGER",
  /** Represent as money, the value is in cents */
  MONEY_CENTS = "MONEY_CENTS",
  /** Represent as a %, with 1 = 100% */
  PERCENT = "PERCENT",
}

/** Implementation  */
export type ImplementationTargetReportInput = {
  /** Last date of report */
  readonly endDate: Scalars["ISO8601Date"]["input"];
  /** Period of the report */
  readonly period: ImplementationTargetReportPeriod;
};

/** Insights are facts that help provide context for implementation - who is doing well, who is doing poorly etc */
export type ImplementationTargetReportInsight = {
  readonly __typename: "ImplementationTargetReportInsight";
  readonly insightType: ImplementationTargetReportInsightType;
  readonly summary: Scalars["String"]["output"];
};

/** A type of insight - for example, a success, or something that requires action */
export enum ImplementationTargetReportInsightType {
  /** A concerning fact that may require action */
  ACTION = "ACTION",
  /** A win - something good to report or celebrate */
  WIN = "WIN",
}

/** The period a report applies to - e.g. a particular week or month. */
export enum ImplementationTargetReportPeriod {
  /** The report applies to a calendar month */
  MONTH = "MONTH",
  /** The report applies to a 7-day week */
  WEEK = "WEEK",
}

export enum ImplementationTargetSortParameter {
  /** Display order */
  DISPLAY_ORDER = "DISPLAY_ORDER",
  /** Id */
  ID = "ID",
}

/** The period that a statistic applies to - e.g. 'last 7 days' */
export enum ImplementationTargetStatPeriod {
  /** The stat applies from the start of the goal but does not include the current value */
  BEFORE_CURRENT_REPORT = "BEFORE_CURRENT_REPORT",
  /** The stat applies to the last 7 days */
  LAST_7_DAYS = "LAST_7_DAYS",
  /** The stat applies to the last 30 days */
  LAST_30_DAYS = "LAST_30_DAYS",
  /** The stat applies to the previous month */
  LAST_MONTH = "LAST_MONTH",
  /** The stat applies since the start of the goal */
  SINCE_GOAL_START = "SINCE_GOAL_START",
  /** The stat applies to the current month */
  THIS_MONTH = "THIS_MONTH",
}

/** Statuses for an implementation target, e.g. is it active */
export enum ImplementationTargetStatus {
  /** This target is active */
  ACTIVE = "ACTIVE",
  /** This target is no longer active and has been retired */
  RETIRED = "RETIRED",
}

/** Type for an implementation target, e.g. new enrollments */
export enum ImplementationTargetType {
  /** This target concerns how many minutes are being billed per month */
  COCM_MONTHLY_BILLED_MINUTES = "COCM_MONTHLY_BILLED_MINUTES",
  /** This target the efficiency of billing, defined as minutes required to hit targets / minutes spent */
  COCM_MONTHLY_BILLING_EFFICIENCY = "COCM_MONTHLY_BILLING_EFFICIENCY",
  /** This target concerns the sum of time code minutes */
  COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES = "COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES",
  /** This target concerns how much revenue is being billed per month */
  COCM_MONTHLY_EXPECTED_BILLING = "COCM_MONTHLY_EXPECTED_BILLING",
  /** This target concerns how many RVUs are being billed per month */
  COCM_MONTHLY_EXPECTED_RVUS = "COCM_MONTHLY_EXPECTED_RVUS",
  /** This target concerns how many value units are being billed per month */
  COCM_MONTHLY_EXPECTED_VALUE_UNITS = "COCM_MONTHLY_EXPECTED_VALUE_UNITS",
  /** This target concerns new CoCM enrollments */
  COCM_NEW_ENROLLMENTS = "COCM_NEW_ENROLLMENTS",
}

export type ImportHistory = {
  readonly __typename: "ImportHistory";
  readonly createdBy: Maybe<User>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ImportHistoryId"]["output"];
  readonly integrationsDataSourceRecordLogs: DataSourceRecordLogConnection;
  readonly integrationsDataSourceRecords: ReadonlyArray<DataSourceRecord>;
  readonly lastImportDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly lastUpdatedBy: Maybe<User>;
  readonly name: Scalars["String"]["output"];
  readonly resourceType: DataSourceResourceType;
  readonly source: Maybe<SourceEnum>;
  readonly updatedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
};

export type ImportHistoryintegrationsDataSourceRecordLogsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  dataSourceRecordId: InputMaybe<ReadonlyArray<Scalars["DataSourceRecordId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  importHistoryId: InputMaybe<ReadonlyArray<Scalars["ImportHistoryId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<DataSourceRecordLogSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<DataSourceRecordLogStatus>>;
};

export type InboxMessage = {
  readonly __typename: "InboxMessage";
  readonly acceptedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly dismissedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["InboxMessageId"]["output"];
  readonly invalidatedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly snoozeUntil: Maybe<Scalars["ISO8601Date"]["output"]>;
};

export type InboxMessageAndDateArgs = {
  readonly date: Scalars["ISO8601Date"]["input"];
  readonly inboxMessageId: Scalars["InboxMessageId"]["input"];
};

export type InboxMessageIdArgs = {
  readonly inboxMessageId: Scalars["InboxMessageId"]["input"];
};

export type InboxMessagesFinding = {
  readonly __typename: "InboxMessagesFinding";
  readonly flavor: FindingFlavor;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["FindingId"]["output"];
  readonly inboxMessage: InboxMessage;
  readonly invitation: Maybe<Invitation>;
  readonly patient: Maybe<Patient>;
};

/** The connection type for InboxMessagesFinding. */
export type InboxMessagesFindingConnection = {
  readonly __typename: "InboxMessagesFindingConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<InboxMessagesFindingEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<InboxMessagesFinding>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type InboxMessagesFindingEdge = {
  readonly __typename: "InboxMessagesFindingEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: InboxMessagesFinding;
};

export enum InboxMessagesFindingSortParameter {
  /** Id */
  ID = "ID",
}

export type InspectorNode = {
  readonly __typename: "InspectorNode";
  /** All valid edges from this model */
  readonly associations: ReadonlyArray<Edges>;
  readonly globalId: Scalars["ID"]["output"];
  /** The current underlying model */
  readonly model: Model;
  /** All previous versions of the underlying model */
  readonly versions: ReadonlyArray<Version>;
};

export type Institute = {
  readonly __typename: "Institute";
  readonly allOrganizations: ReadonlyArray<Organization>;
  readonly alternateShortcode: Maybe<Scalars["String"]["output"]>;
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly customerSupportManager: Maybe<Scalars["String"]["output"]>;
  readonly defaultAuthenticationMechanism: Maybe<AuthenticationMechanism>;
  readonly defaultNotificationPreference: Scalars["String"]["output"];
  readonly ehrLinks: EhrLinkConnection;
  readonly enabled: Maybe<Scalars["Boolean"]["output"]>;
  readonly featureSet: InstituteFeatureSet;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["InstituteId"]["output"];
  readonly instituteType: InstituteType;
  readonly isTest: Maybe<Scalars["Boolean"]["output"]>;
  readonly logoDataBase64: Maybe<Scalars["String"]["output"]>;
  readonly logoUrl: Maybe<Scalars["String"]["output"]>;
  readonly longName: Scalars["String"]["output"];
  readonly measurementAllowed: Scalars["Boolean"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly organizations: OrganizationConnection;
  readonly providers: ReadonlyArray<Provider>;
  readonly shortcode: Scalars["String"]["output"];
  readonly ssoSignonUrl: Scalars["String"]["output"];
  readonly timezone: Scalars["String"]["output"];
  readonly updatedAt: Scalars["ISO8601DateTime"]["output"];
};

export type InstituteehrLinksArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  connectionType: InputMaybe<ReadonlyArray<EhrLinkType>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<EhrLinkSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

export type InstituteorganizationsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  measurementAllowed: InputMaybe<Scalars["Boolean"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<OrganizationSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

export type InstituteCondition = {
  readonly __typename: "InstituteCondition";
  readonly code: Scalars["String"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["InstituteConditionId"]["output"];
  readonly name: Scalars["String"]["output"];
};

/** The connection type for InstituteCondition. */
export type InstituteConditionConnection = {
  readonly __typename: "InstituteConditionConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<InstituteConditionEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<InstituteCondition>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type InstituteConditionEdge = {
  readonly __typename: "InstituteConditionEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: InstituteCondition;
};

export enum InstituteConditionSortParameter {
  /** Id */
  ID = "ID",
}

export type InstituteConfiguration = {
  readonly __typename: "InstituteConfiguration";
  /** Allow Bulk download (Genoa only) -> If true, users with appropriate permissions may use the bulk export functions */
  readonly allowBulkExport: Scalars["Boolean"]["output"];
  /** EHR Integration includes appointments -> whether this client receives scheduled appointments from an EHR */
  readonly appointmentIntegrationExists: Scalars["Boolean"]["output"];
  /**
   * Ignore appointments for billing -> Automatically mark manual appointments as
   * fulfilled afterwards. This is important for things like reimbursements where
   * you need an appointment to occur
   */
  readonly billingChargeIgnoreAppointments: Scalars["Boolean"]["output"];
  /** Charge on survey begin -> If true, will begin charges when the patient begins rather than completes a survey */
  readonly billingChargeOnSurveyBegin: Scalars["Boolean"]["output"];
  /** Charge for provider scale -> If true, will charge when a survey has been completed by a provider */
  readonly billingChargeProviderScale: Scalars["Boolean"]["output"];
  /** Charge when no appointment -> If true, will charge when a survey has been completed but there is no appointment backing it */
  readonly billingChargeWhenNoAppointment: Scalars["Boolean"]["output"];
  /**
   * Charge when appointment canceled -> If true, will charge when a survey has
   * been completed but the appointment backing it never took place
   */
  readonly billingChargeWhenSessionCanceled: Scalars["Boolean"]["output"];
  /** Show extra fields designed just for Cambridge Public Schools */
  readonly cambridgeSchoolsMode: Scalars["Boolean"]["output"];
  /**
   * Disable Manual Care Episode Creation -> Do not allow users to manually create
   * Episodes of Care. They will be created only by the EHR
   */
  readonly careEpisodeDisableManualCreate: Scalars["Boolean"]["output"];
  /**
   * EHR Integration includes episodes of care -> whether we import care episodes
   * from the ehr. Changes the behavior of the appointment processor as it will be
   * less aggressive creating new care episodes, assuming that the EMR has already
   * told them about it.
   */
  readonly careEpisodeIntegrationEnabled: Scalars["Boolean"]["output"];
  /**
   * Do not show the consent screen to survey users. Should only be used when
   * another means of consent has been considered, e.g. via a school system
   */
  readonly disableSurveyConsent: Scalars["Boolean"]["output"];
  /** Disables the automatic display of the screen to let users update their contact details and notification preferences */
  readonly disableSurveyContactDetails: Scalars["Boolean"]["output"];
  /**
   * Enable Provider Consent Administration [Leave mostly alone] -> If true,
   * providers can administer patient consents from the patient details page, as
   * well as having the patients take them as part of an assessment
   */
  readonly enableProviderConsentAdministration: Scalars["Boolean"]["output"];
  /** Enable scale blinding -> If true, you can set scales to be blinded as part of research */
  readonly enableScaleBlinding: Scalars["Boolean"]["output"];
  /** Measures -> Whether to show the severity indicator in the dashboard and appointment pages */
  readonly enableSeveritySummary: Scalars["Boolean"]["output"];
  /** Enable webhooks -> If true, webhooks can be sent to other servers */
  readonly enableWebhooks: Scalars["Boolean"]["output"];
  /**
   * For manual sessions that are not completed or canceled by the user, assume
   * they have been missed for adherence purposes. If you set this to true, any
   * non-completed session will count as a miss. If you set this to false, you get
   * 100% adherence at all times.
   */
  readonly failAllMissedSessions: Scalars["Boolean"]["output"];
  /** Hide MRN -> hide MRN from all forms. Used when the MRNS are not real keys but instead integration composites */
  readonly hideMrn: Scalars["Boolean"]["output"];
  /**
   * Appointment Deduplication Window -> The window at which to not create new
   * measurements from integrated appointments. 0 disables this.
   */
  readonly integrationAppointmentDeduplicationWindowMinutes: Scalars["Int"]["output"];
  /** EHR Integration -> Automatically Activate New Providers */
  readonly integrationAutoActivateProvider: Scalars["Boolean"]["output"];
  /**
   * EHR Integration Auto Create Care Episodes -> Automatically create care
   * episodes if none can be found. If false, when no care episode can be found, no
   * session will be created.
   */
  readonly integrationAutoCreateCareEpisodes: Scalars["Boolean"]["output"];
  /**
   * EHR Integration Auto Create Session -> whether the default is to create
   * sessions from appointments when integration is enabled. Overridden by
   * per-patient and per-organization settings.
   */
  readonly integrationAutoCreateSessions: Scalars["Boolean"]["output"];
  /**
   * EHR Integration Auto Create Session For Provider -> whether the default is to
   * create sessions from appointments when integration is enabled per provider. If
   * false, individual providers need to be opted in.
   */
  readonly integrationAutoCreateSessionsForProvider: Scalars["Boolean"]["output"];
  /** EHR Integration -> Only Create Care Episodes When Treatment Service Matches */
  readonly integrationCareEpisodeRequiresTreatmentService: Scalars["Boolean"]["output"];
  /**
   * EHR Integration Enable Writeback -> Allow the institute to write back EMR
   * data. This is the top level switch and will not do anything by itself. You
   * will also have to enable writeback capabilities on the connection
   */
  readonly integrationEnableWriteback: Scalars["Boolean"]["output"];
  /** Enable EHR Integration -> whether uploaded files should be processed */
  readonly integrationEnabled: Scalars["Boolean"]["output"];
  /** New Provider Role -> The role which new providers in the system created through the EMR connection will be given. */
  readonly integrationNewProviderDefaultRole: Scalars["String"]["output"];
  /** New Provider Measurement Allowed -> Whether newly created providers will start with measurement allowed on */
  readonly integrationNewProviderMeasurementAllowed: Scalars["Boolean"]["output"];
  /**
   * Measure Primary Clinician Only -> If true, only measure sessions from the
   * primary provider specified. Upon patient creation, the provider will
   * automatically be set to the first provider with an appointment.
   */
  readonly integrationOnlyMeasurePrimaryProvider: Scalars["Boolean"]["output"];
  /**
   * EHR Integration Schedule -> whether we should expect regular delivery of
   * integration files. If false, assume files will be uploaded manually at some
   * undefined interval
   */
  readonly integrationScheduled: Scalars["Boolean"]["output"];
  /**
   * EHR Integration Update Primary Provider -> If true, the patient's primary
   * provider will be automatically set to the last provider for which we processed
   * an appointment.
   */
  readonly integrationUpdatePrimaryProvider: Scalars["Boolean"]["output"];
  /** Login Code Validity Period (minutes) -> How long instant login codes are valid for (in minutes) */
  readonly loginCodeValidityMinutes: Scalars["Int"]["output"];
  /**
   * Legacy Configuration for Mclean -> A set of configuration used by Mclean
   * research that is for research use and not well suited for other customers.
   */
  readonly mcleanLegacyMode: Scalars["Boolean"]["output"];
  /**
   * Optum Pilot Mode -> If true, enable extra features and configuration specific
   * to Optum including GINA consent administration
   */
  readonly optumPilotMode: Scalars["Boolean"]["output"];
  /**
   * Legacy Configuration for HCA/PACE -> A set of configuration used by HCA/PACE
   * research that is for research use and not well suited for other customers.
   */
  readonly paceLegacyMode: Scalars["Boolean"]["output"];
  /**
   * Enable SMS Messaging (Leave enabled) -> Send SMS reminders if a phone number
   * is present and a reminder is configured to be sent. LEAVE THIS ON. It should
   * be removed once final customers without it on are confirmed
   */
  readonly sendSmsReminders: Scalars["Boolean"]["output"];
  /**
   * Show Care Episode Selector -> Show the care episode selector when creating
   * sessions. Useful when patients have multiple ongoing treatments
   */
  readonly showCareEpisodeSelector: Scalars["Boolean"]["output"];
  /**
   * Legal -> Institute has appropriate consent according to COPPA to administer
   * self report questionnaires to children under 13
   */
  readonly supportsCoppaForChildren: Scalars["Boolean"]["output"];
  /**
   * Legacy Configuration for Uminn/Navigate -> A set of configuration used by
   * Uminn/navigate that is for research use and not well suited for other customers.
   */
  readonly uminnLegacyMode: Scalars["Boolean"]["output"];
};

/** The connection type for Institute. */
export type InstituteConnection = {
  readonly __typename: "InstituteConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<InstituteEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Institute>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type InstituteEdge = {
  readonly __typename: "InstituteEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Institute;
};

export type InstituteFeatureSet = {
  readonly __typename: "InstituteFeatureSet";
  /** Enable Assessment Incomplete Findings -> Create findings 7 days after an invitation is sent and has not been not completed */
  readonly assessmentIncompleteFindings: Scalars["Boolean"]["output"];
  /** Enable Athena Writeback -> Enable writing survey data back to Athena */
  readonly enableAthenaWriteback: Scalars["Boolean"]["output"];
  /** Billing Functionality -> Allows use of the reimbursement engine. */
  readonly enableBilling: Scalars["Boolean"]["output"];
  /** Care Units/Group Therapy/Family -> Allows non-patient units of treatment, e.g. group therapy and family therapy. */
  readonly enableCareUnits: Scalars["Boolean"]["output"];
  /** Enable CoCM Encounters -> Allows encounter level interactions for CoCM */
  readonly enableCocmEncounters: Scalars["Boolean"]["output"];
  /** Enable Collaborative Care -> This institute is a collaborative care institute, _not_ an MBC institute */
  readonly enableCollaborativeCare: Scalars["Boolean"]["output"];
  /**
   * Conditions/Diagnoses -> Allows providers to set list of conditions/diagnoses
   * for a patient and do reporting on them. Still experiment, not ready for roll out yet.
   */
  readonly enableConditions: Scalars["Boolean"]["output"];
  /** Enable Roll out of Dashboard Schedule -> Enable the react schedule page to show on login instead of ember dashboard */
  readonly enableDashboardSchedule: Scalars["Boolean"]["output"];
  /** Enable Decision Support -> Enable creating custom triggers to send providers notifications */
  readonly enableDecisionSupport: Scalars["Boolean"]["output"];
  /** Demo Quick Add -> Enables the 'quick add patient' button suitable for demos. Don't enable in production, it won't work. */
  readonly enableDemoQuickAdd: Scalars["Boolean"]["output"];
  /** Experiments Functionality -> Allows RCTS and other randomized experiments. */
  readonly enableExperiments: Scalars["Boolean"]["output"];
  /**
   * Enable Healthie Child Patient Swap -> Whether or not we'll swap contact
   * information for an under 18 patient with at least one caregiver from a single
   * child notification contact. Only works with the healthie integration
   */
  readonly enableHealthieChildPatientSwap: Scalars["Boolean"]["output"];
  /** Enable Implementation Targets -> Show implementation targets mode */
  readonly enableImplementationTargets: Scalars["Boolean"]["output"];
  /** Enable Locations -> Enable locations */
  readonly enableLocations: Scalars["Boolean"]["output"];
  /** Enable MBC Redesign -> Enables the MBC redesign */
  readonly enableMbcRedesign: Scalars["Boolean"]["output"];
  /** Measurement Dimensions -> Turns on dimensional measurement including the new feedback report. */
  readonly enableMeasurementDimensions: Scalars["Boolean"]["output"];
  /** Measurement Plans -> Enable full measurement planning for the organization. */
  readonly enableMeasurementPlans: Scalars["Boolean"]["output"];
  /** Optum -> Enables metric reports for optum sites */
  readonly enableOptumMetrics: Scalars["Boolean"]["output"];
  /** Payor Functionality -> Allows the collection of insurance information and shows analytics by insurer. */
  readonly enablePayors: Scalars["Boolean"]["output"];
  /** Enable Quality Issues -> Enables all quality check workflows for patient quality issues */
  readonly enableQualityIssues: Scalars["Boolean"]["output"];
  /** Enable Treatment Services -> Enables all treatment services */
  readonly enableTreatmentServices: Scalars["Boolean"]["output"];
  /**
   * Enable Monthly Assessment Incomplete Findings -> Create findings when a
   * patient has no measures completed 7 days before the end of the month
   */
  readonly monthlyAssessmentMissingFindings: Scalars["Boolean"]["output"];
};

export type InstituteGroup = {
  readonly __typename: "InstituteGroup";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["InstituteGroupId"]["output"];
  readonly leader: CurrentInstitute;
  readonly members: ReadonlyArray<CurrentInstitute>;
  readonly name: Scalars["String"]["output"];
};

/** The connection type for InstituteGroup. */
export type InstituteGroupConnection = {
  readonly __typename: "InstituteGroupConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<InstituteGroupEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<InstituteGroup>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type InstituteGroupEdge = {
  readonly __typename: "InstituteGroupEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: InstituteGroup;
};

export enum InstituteGroupSortParameter {
  /** Id */
  ID = "ID",
  /** Name */
  NAME = "NAME",
}

export type InstitutePayor = {
  readonly __typename: "InstitutePayor";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["InstitutePayorId"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly primaryId: Maybe<Scalars["String"]["output"]>;
};

/** The connection type for InstitutePayor. */
export type InstitutePayorConnection = {
  readonly __typename: "InstitutePayorConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<InstitutePayorEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<InstitutePayor>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type InstitutePayorEdge = {
  readonly __typename: "InstitutePayorEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: InstitutePayor;
};

export enum InstitutePayorSortParameter {
  /** Id */
  ID = "ID",
}

export type InstituteScale = {
  readonly __typename: "InstituteScale";
  readonly default: Maybe<Scalars["Boolean"]["output"]>;
  readonly disableAdministration: Scalars["Boolean"]["output"];
  readonly format: Maybe<InvitationFormat>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["InstituteScaleId"]["output"];
  readonly scale: Scale;
};

/** The connection type for InstituteScale. */
export type InstituteScaleConnection = {
  readonly __typename: "InstituteScaleConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<InstituteScaleEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<InstituteScale>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type InstituteScaleEdge = {
  readonly __typename: "InstituteScaleEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: InstituteScale;
};

export enum InstituteScaleSortParameter {
  /** Id */
  ID = "ID",
}

export enum InstituteSortParameter {
  /** Created At */
  CREATED_AT = "CREATED_AT",
  /** Id */
  ID = "ID",
  /** Measurement Allowed */
  MEASUREMENT_ALLOWED = "MEASUREMENT_ALLOWED",
  /** Name */
  NAME = "NAME",
  /** Updated At */
  UPDATED_AT = "UPDATED_AT",
}

export enum InstituteType {
  /** Institute Group Leader */
  GROUP_LEADER = "GROUP_LEADER",
  /** Mirah Internal */
  INTERNAL = "INTERNAL",
  /** Provider */
  PROVIDER = "PROVIDER",
}

/** Autogenerated input type of InstituteUpdateMeasurementAllowed */
export type InstituteUpdateMeasurementAllowedInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly instituteId: Scalars["InstituteId"]["input"];
  readonly measurementAllowed: Scalars["Boolean"]["input"];
};

/** Autogenerated return type of InstituteUpdateMeasurementAllowed. */
export type InstituteUpdateMeasurementAllowedPayload = {
  readonly __typename: "InstituteUpdateMeasurementAllowedPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<InstituteUpdateMeasurementAllowedResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type InstituteUpdateMeasurementAllowedResult = {
  readonly __typename: "InstituteUpdateMeasurementAllowedResult";
  readonly instituteId: Scalars["String"]["output"];
  readonly measurementAllowed: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of InstituteUploadLogo */
export type InstituteUploadLogoInput = {
  readonly base64EncodedImage: Scalars["String"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly instituteId: Scalars["InstituteId"]["input"];
};

/** Autogenerated return type of InstituteUploadLogo. */
export type InstituteUploadLogoPayload = {
  readonly __typename: "InstituteUploadLogoPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<InstituteUploadLogoResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type InstituteUploadLogoResult = {
  readonly __typename: "InstituteUploadLogoResult";
  readonly institute: Institute;
};

export type InstituteWorkflow = {
  readonly __typename: "InstituteWorkflow";
  readonly callToAction: Scalars["String"]["output"];
  readonly emailBody: Scalars["String"]["output"];
  readonly emailSubject: Scalars["String"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly institute: Institute;
  readonly instituteId: Scalars["String"]["output"];
  readonly instructions: Maybe<Scalars["String"]["output"]>;
  readonly title: Scalars["String"]["output"];
  readonly workflow: Workflow;
  readonly workflowId: Scalars["String"]["output"];
};

/** The connection type for InstituteWorkflow. */
export type InstituteWorkflowConnection = {
  readonly __typename: "InstituteWorkflowConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<InstituteWorkflowEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<InstituteWorkflow>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type InstituteWorkflowEdge = {
  readonly __typename: "InstituteWorkflowEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: InstituteWorkflow;
};

export type IntakeScaleAdministrationChoice = {
  readonly administrationChoice: IntakeSessionAdministrationChoice;
  readonly intakeSessionScaleId: Scalars["IntakeSessionScaleId"]["input"];
};

/** Tracks the choice the care manager made for how to administer each scale on intake */
export enum IntakeSessionAdministrationChoice {
  /** Administer the scale in person using the mirah provider app */
  ADMINISTER = "ADMINISTER",
  /** Send a text or email notification to the patient to take the survey */
  NOTIFY = "NOTIFY",
  /** The user has not selected an administration strategy */
  NOT_SELECTED = "NOT_SELECTED",
  /** Copy the administration from another session */
  TRANSFER = "TRANSFER",
}

export type IntakeSessionScale = {
  readonly __typename: "IntakeSessionScale";
  readonly administrationChoice: IntakeSessionAdministrationChoice;
  readonly enrollment: Enrollment;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["IntakeSessionScaleId"]["output"];
  readonly scale: AdministrableScale;
  readonly status: IntakeSessionScaleStatus;
};

/** The connection type for IntakeSessionScale. */
export type IntakeSessionScaleConnection = {
  readonly __typename: "IntakeSessionScaleConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<IntakeSessionScaleEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<IntakeSessionScale>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type IntakeSessionScaleEdge = {
  readonly __typename: "IntakeSessionScaleEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: IntakeSessionScale;
};

export enum IntakeSessionScaleSortParameter {
  /** Id */
  ID = "ID",
}

/** Tracks whether a scale has been sent on intake or not */
export enum IntakeSessionScaleStatus {
  /** This intake scale has been copied, sent or administered */
  COMPLETE = "COMPLETE",
  /** This intake scale has not been copied, sent or administered */
  NOT_COMPLETE = "NOT_COMPLETE",
}

/** Tracks the progress of the intake workflow, a subset of overall enrollment */
export enum IntakeStatus {
  /** All the intake scales have been copied, had notifications sent, or manually administered */
  COMPLETE = "COMPLETE",
  /** The care manager has made no administration selections for the intake scales */
  NOT_STARTED = "NOT_STARTED",
  /** The care manager has made administration selections for all of the intake scales */
  PARTIAL = "PARTIAL",
}

export type IntegrationStatus = {
  readonly __typename: "IntegrationStatus";
  readonly dates: ReadonlyArray<IntegrationStatusDate>;
  readonly ehrConnection: EhrLink;
  readonly globalId: Scalars["ID"]["output"];
  readonly institute: Institute;
};

export type IntegrationStatusDate = {
  readonly __typename: "IntegrationStatusDate";
  readonly date: Scalars["ISO8601DateTime"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly success: Scalars["Int"]["output"];
  readonly total: Scalars["Int"]["output"];
};

/** Autogenerated input type of InvalidateFindings */
export type InvalidateFindingsInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly flavor: FindingFlavor;
  readonly patientId?: InputMaybe<Scalars["PatientId"]["input"]>;
};

/** Autogenerated return type of InvalidateFindings. */
export type InvalidateFindingsPayload = {
  readonly __typename: "InvalidateFindingsPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<InvalidateFindingsResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type InvalidateFindingsResult = {
  readonly __typename: "InvalidateFindingsResult";
  readonly findings: ReadonlyArray<Finding>;
};

export type Invitation = {
  readonly __typename: "Invitation";
  readonly canResendNotifications: Scalars["Boolean"]["output"];
  readonly canceledAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly canceledByUser: Maybe<User>;
  /** When this invitation closes for feedback. No responses will be allowed after this date. */
  readonly closesAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  /** When this invite was first created */
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  /** When this invitation was started */
  readonly dateBegun: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  /** When this invitation was completed */
  readonly dateCompleted: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly downloadAvailable: Scalars["Boolean"]["output"];
  /** How this measurement may be administered */
  readonly format: InvitationFormat;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["InvitationId"]["output"];
  readonly invitationNotifications: ReadonlyArray<InvitationNotification>;
  readonly loginAttemptsRemaining: Maybe<Scalars["Int"]["output"]>;
  readonly managedBy: ManagedBy;
  readonly manuallyAdministerable: Scalars["Boolean"]["output"];
  readonly manuallyCancelable: Scalars["Boolean"]["output"];
  /** When this invitation opens for feedback. No responses will be allowed before this date. */
  readonly opensAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly patient: Patient;
  readonly pdfContentBase64: Maybe<Scalars["String"]["output"]>;
  /** The phase of measurement this invitation is part of */
  readonly phase: InvitationPhase;
  readonly referrer: Maybe<InvitationLoginMethod>;
  readonly report: Report;
  readonly reportScaleParticipants: ReadonlyArray<ReportScaleParticipant>;
  readonly reportScales: ReadonlyArray<ReportScale>;
  /** The status of this invitation */
  readonly status: InvitationStatus;
  /** The last time the status was updated. */
  readonly statusUpdatedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly takingLanguage: Maybe<Scalars["String"]["output"]>;
  readonly takingUser: Maybe<User>;
  readonly user: User;
};

/** The connection type for Invitation. */
export type InvitationConnection = {
  readonly __typename: "InvitationConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<InvitationEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Invitation>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type InvitationEdge = {
  readonly __typename: "InvitationEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Invitation;
};

/** The format of an invitation, i.e. self report, interview, etc. */
export enum InvitationFormat {
  /** Interview, i.e. questionnaire is asked by provider */
  INTERVIEW = "INTERVIEW",
  /** Self report, i.e. completed by the participant themselves */
  SELF_REPORT = "SELF_REPORT",
}

/** The method used to log in to take an assessment */
export enum InvitationLoginMethod {
  /** Respondent used an email link */
  EMAIL = "EMAIL",
  /** Respondent took via kiosk */
  KIOSK = "KIOSK",
  /** Respondent logged in from the feedback report */
  PROVIDER_REPORT = "PROVIDER_REPORT",
  /** Respondent logged in from provider mode */
  PROVIDER_SESSION = "PROVIDER_SESSION",
  /** Respondent used provider_session_bulk */
  PROVIDER_SESSION_BULK = "PROVIDER_SESSION_BULK",
  /** Respondent used a text message link */
  TEXT = "TEXT",
}

export type InvitationNotification = {
  readonly __typename: "InvitationNotification";
  /** When this invitation was emailed to the user. */
  readonly dateEmailed: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  /** When this invitation was texted to the user. */
  readonly dateTexted: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly emailAllowed: Scalars["Boolean"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["InvitationNotificationId"]["output"];
  /** When the user will be notified. */
  readonly sendAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly status: InvitationNotificationStatus;
  readonly textAllowed: Scalars["Boolean"]["output"];
};

/** The status of an assessment notification */
export enum InvitationNotificationStatus {
  /** The notification is too close to another notification and will not be sent */
  DUPLICATE = "DUPLICATE",
  /** The user has opted out of communication so this notification cannot be sent */
  FORBIDDEN = "FORBIDDEN",
  /** The notification is missing contact information and cannot be sent */
  MISSING_DETAILS = "MISSING_DETAILS",
  /** The notification was not needed because the assessment was completed or canceled */
  NOT_NEEDED = "NOT_NEEDED",
  /** The notification is scheduled but has not yet been delivered */
  SCHEDULED = "SCHEDULED",
  /** The notification has been delivered */
  SENT = "SENT",
}

/** The phase of a measurement, i.e. before, during or after the target date. */
export enum InvitationPhase {
  /** After the target date */
  AFTER = "AFTER",
  /** Before the target date */
  BEFORE = "BEFORE",
  /** During the target date */
  DURING = "DURING",
}

export enum InvitationSortParameter {
  /** Id */
  ID = "ID",
}

/** The status of an invitation to measurement. */
export enum InvitationStatus {
  /** The invitation has had at least one question answered by the participant */
  BEGUN = "BEGUN",
  /** The invitation was canceled */
  CANCELED = "CANCELED",
  /** The invitation has been completed by the participant */
  COMPLETE = "COMPLETE",
  /** The invitation was not started and has expired */
  EXPIRED = "EXPIRED",
  /** The invitation is for the future and cannot be used yet */
  FUTURE = "FUTURE",
  /** The invitation has been locked due to failed login attempts. */
  LOCKED_OUT = "LOCKED_OUT",
  /** The invitation was canceled manually by a care manager */
  MANUALLY_CANCELED = "MANUALLY_CANCELED",
  /** The invitation is open but is not configured to send any notifications */
  NEW = "NEW",
  /** The invitation was not required */
  NOT_REQUIRED = "NOT_REQUIRED",
  /** The invitation has been opened by the participant */
  OPENED = "OPENED",
  /** The invitation was started but not completed and has now expired */
  PARTIAL = "PARTIAL",
  /** The invitation is open for feedback and notification have been sent */
  SENT = "SENT",
}

/**
 * The status event currently happening on an invitation. This transistions its status on Invitation's state machine.
 * If you pass nil to this field, the mutation handling the event will ignore it and assume there is no state change.
 */
export enum InvitationStatusEvent {
  /** The participant is starting the assessment on the invitation */
  ASSESSMENT_BEGUN = "ASSESSMENT_BEGUN",
}

export type Invite = {
  readonly __typename: "Invite";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["InviteId"]["output"];
  readonly moduleIds: ReadonlyArray<Scalars["ID"]["output"]>;
  readonly preloadedModules: ReadonlyArray<Module>;
  readonly response: Maybe<Response>;
};

/** The severity of a particular question/item */
export enum ItemCoding {
  /** Mild */
  MILD = "MILD",
  /** Moderate */
  MODERATE = "MODERATE",
  /** Sever */
  SEVERE = "SEVERE",
}

export type KeyPair = {
  readonly __typename: "KeyPair";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["KeyPairId"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly privateKey: Maybe<Scalars["String"]["output"]>;
  readonly publicKey: Maybe<Scalars["String"]["output"]>;
  readonly revocationDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly revocationReason: Maybe<Scalars["String"]["output"]>;
  readonly source: Maybe<Scalars["String"]["output"]>;
};

/** The connection type for KeyPair. */
export type KeyPairConnection = {
  readonly __typename: "KeyPairConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<KeyPairEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<KeyPair>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type KeyPairEdge = {
  readonly __typename: "KeyPairEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: KeyPair;
};

export enum KeyPairSortParameter {
  /** Id */
  ID = "ID",
}

export type Location = {
  readonly __typename: "Location";
  readonly children: ReadonlyArray<Location>;
  readonly form: Maybe<LocationForm>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["LocationId"]["output"];
  readonly managingOrganization: Maybe<Organization>;
  readonly name: Scalars["String"]["output"];
  readonly parent: Maybe<Location>;
  readonly parentId: Maybe<Scalars["LocationId"]["output"]>;
  readonly subtree: Maybe<ReadonlyArray<Location>>;
};

/** The connection type for Location. */
export type LocationConnection = {
  readonly __typename: "LocationConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<LocationEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Location>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type LocationEdge = {
  readonly __typename: "LocationEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Location;
};

/** Form for a location, see https://www.hl7.org/fhir/valueset-location-form.html */
export enum LocationForm {
  /** A defined physical boundary of something, such as a flood risk zone, region, postcode */
  AREA = "AREA",
  /**
   * A space that is allocated for sleeping/laying on. This is not the physical
   * bed/trolley that may be moved about, but the space it may occupy.
   */
  BED = "BED",
  /**
   * Any Building or structure. This may contain rooms, corridors, wings, etc. It
   * might not have walls, or a roof, but is considered a defined/allocated space.
   */
  BUILDING = "BUILDING",
  /** A container that can store goods, equipment, medications or other items. */
  CABINET = "CABINET",
  /** Any corridor within a Building, that may connect rooms. */
  CORRIDOR = "CORRIDOR",
  /** A residential dwelling. Usually used to reference a location that a person/patient may reside. */
  HOUSE = "HOUSE",
  /**
   * A wide scope that covers a conceptual domain, such as a Nation (Country wide
   * community or Federal Government - e.g. Ministry of Health), Province or State
   * (community or Government), Business (throughout the enterprise), Nation with a
   * business scope of an agency (e.g. CDC, FDA etc.) or a Business segment (UK
   * Pharmacy), not just an physical boundary
   */
  JURISDICTION = "JURISDICTION",
  /** A Level in a multi-level Building/Structure. */
  LEVEL = "LEVEL",
  /** A defined path to travel between 2 points that has a known name. */
  ROAD = "ROAD",
  /** A space that is allocated as a room, it may have walls/roof etc., but does not require these. */
  ROOM = "ROOM",
  /** A collection of buildings or other locations such as a site or a campus. */
  SITE = "SITE",
  /** A means of transportation. */
  VEHICLE = "VEHICLE",
  /** A location that is virtual in nature, such as a conference call or virtual meeting space */
  VIRTUAL = "VIRTUAL",
  /** A Ward is a section of a medical facility that may contain rooms and other types of location. */
  WARD = "WARD",
  /** A Wing within a Building, this often contains levels, rooms and corridors. */
  WING = "WING",
}

export enum LocationSortParameter {
  /** Id */
  ID = "ID",
}

/** Whether a particular record is managed by Mirah or an external syste, */
export enum ManagedBy {
  /** This was created externally and should be considered a copy of original data */
  EXTERNAL = "EXTERNAL",
  /** This session was created by Mirah */
  MIRAH = "MIRAH",
}

/** Autogenerated input type of ManuallyCancelInvitation */
export type ManuallyCancelInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly invitationId: Scalars["InvitationId"]["input"];
};

/** Autogenerated return type of ManuallyCancelInvitation. */
export type ManuallyCancelInvitationPayload = {
  readonly __typename: "ManuallyCancelInvitationPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<ManuallyCancelInvitationResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type ManuallyCancelInvitationResult = {
  readonly __typename: "ManuallyCancelInvitationResult";
  readonly invitation: Invitation;
};

/** Autogenerated input type of MarkCaseConsultComplete */
export type MarkCaseConsultCompleteInput = {
  readonly caseConsultId: Scalars["CaseConsultId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of MarkCaseConsultComplete. */
export type MarkCaseConsultCompletePayload = {
  readonly __typename: "MarkCaseConsultCompletePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<MarkCaseConsultCompleteResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type MarkCaseConsultCompleteResult = {
  readonly __typename: "MarkCaseConsultCompleteResult";
  readonly caseConsult: CaseConsult;
};

/** Autogenerated input type of MarkConsultMeetingComplete */
export type MarkConsultMeetingCompleteInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly consultMeetingId: Scalars["ConsultMeetingId"]["input"];
};

/** Autogenerated return type of MarkConsultMeetingComplete. */
export type MarkConsultMeetingCompletePayload = {
  readonly __typename: "MarkConsultMeetingCompletePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<MarkConsultMeetingCompleteResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type MarkConsultMeetingCompleteResult = {
  readonly __typename: "MarkConsultMeetingCompleteResult";
  readonly consultMeeting: ConsultMeeting;
};

export type MeasurementPlan = {
  readonly __typename: "MeasurementPlan";
  readonly author: Maybe<User>;
  readonly autogenerated: Scalars["Boolean"]["output"];
  readonly defaultPlanType: MeasurementPlanType;
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly global: Scalars["Boolean"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["MeasurementPlanId"]["output"];
  readonly measurementPlanScales: ReadonlyArray<MeasurementPlanScale>;
  readonly name: Scalars["String"]["output"];
  readonly shared: Scalars["Boolean"]["output"];
};

/** The connection type for MeasurementPlan. */
export type MeasurementPlanConnection = {
  readonly __typename: "MeasurementPlanConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<MeasurementPlanEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<MeasurementPlan>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type MeasurementPlanEdge = {
  readonly __typename: "MeasurementPlanEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: MeasurementPlan;
};

/** When onset is measured from for measurement planning */
export enum MeasurementPlanMeasureOnsetFrom {
  /** Onset period starts at care episode start */
  CARE_EPISODE_PERIOD_START = "CARE_EPISODE_PERIOD_START",
  /** Onset period starts at next calendar quarter */
  NEXT_CALENDAR_QUARTER = "NEXT_CALENDAR_QUARTER",
}

export type MeasurementPlanScale = {
  readonly __typename: "MeasurementPlanScale";
  readonly alternationGroup: Maybe<Scalars["String"]["output"]>;
  readonly alternationPriority: Maybe<Scalars["Int"]["output"]>;
  readonly blinding: ScaleBlinding;
  readonly discontinueSession: Maybe<Scalars["Int"]["output"]>;
  readonly discontinueTime: Maybe<Scalars["Interval"]["output"]>;
  readonly format: Maybe<InvitationFormat>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["MeasurementPlanScaleId"]["output"];
  readonly maxRepeatInterval: Maybe<Scalars["Interval"]["output"]>;
  readonly measureOnsetFrom: Maybe<MeasurementPlanMeasureOnsetFrom>;
  readonly measurementPlan: MeasurementPlan;
  readonly onsetSession: Maybe<Scalars["Int"]["output"]>;
  readonly onsetTime: Maybe<Scalars["Interval"]["output"]>;
  readonly phase: Maybe<InvitationPhase>;
  readonly repeatSessions: Maybe<Scalars["Int"]["output"]>;
  readonly scale: Scale;
  readonly targeting: Maybe<ScaleTargeting>;
};

export enum MeasurementPlanSortParameter {
  /** Id */
  ID = "ID",
}

/** The type of the measurement plan */
export enum MeasurementPlanType {
  /** Mandatory compliance plan */
  COMPLIANCE = "COMPLIANCE",
  /** Core plan - only one per session */
  CORE = "CORE",
  /** Additional supplemental plan */
  SUPPLEMENTAL = "SUPPLEMENTAL",
}

export enum MeasurementPlanningPhase {
  /** Invitations have been created for this measurement, but no scales */
  INVITATIONS_CREATED = "INVITATIONS_CREATED",
  /** Measurement plans have been calculated for this measurement, but no invitations or scales */
  PLANS_CALCULATED = "PLANS_CALCULATED",
  /** All planning is complete and scales have been calculated */
  SCALES_CALCULATED = "SCALES_CALCULATED",
}

/** Autogenerated input type of MeasurementRequest */
export type MeasurementRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of MeasurementRequest. */
export type MeasurementRequestPayload = {
  readonly __typename: "MeasurementRequestPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<MeasurementRequestResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type MeasurementRequestResult = {
  readonly __typename: "MeasurementRequestResult";
  readonly invitationId: Maybe<Scalars["ID"]["output"]>;
};

/** Autogenerated input type of MergePlaceholderTaskWithExistingTask */
export type MergePlaceholderTaskWithExistingTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly taskId: Scalars["TaskId"]["input"];
  readonly taskIdToMergeInto: Scalars["TaskId"]["input"];
};

/** Autogenerated return type of MergePlaceholderTaskWithExistingTask. */
export type MergePlaceholderTaskWithExistingTaskPayload = {
  readonly __typename: "MergePlaceholderTaskWithExistingTaskPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<MergePlaceholderTaskWithExistingTaskResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type MergePlaceholderTaskWithExistingTaskResult = {
  readonly __typename: "MergePlaceholderTaskWithExistingTaskResult";
  readonly task: Task;
};

export type Metric = {
  readonly __typename: "Metric";
  readonly configuration: MetricConfiguration;
  readonly displayOrder: Maybe<Scalars["Int"]["output"]>;
  readonly entityTreeNode: EntityTreeNode;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["MetricId"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly status: MetricStatus;
};

export type MetricComputedValue = {
  readonly __typename: "MetricComputedValue";
  readonly ageAtMeasurement: Maybe<Scalars["Int"]["output"]>;
  readonly ageAtTreatmentStart: Maybe<Scalars["Int"]["output"]>;
  readonly clinicalCategory: ClinicalCategory;
  readonly daysBetweenTreatmentStartAndBaseline: Maybe<Scalars["Int"]["output"]>;
  readonly daysSinceTreatmentStart: Scalars["Int"]["output"];
  readonly delta: Maybe<Scalars["Float"]["output"]>;
  readonly firstClinicalCategory: ClinicalCategory;
  readonly firstDate: Scalars["ISO8601DateTime"]["output"];
  readonly firstSeverityCategory: SeverityCategory;
  readonly firstThresholdClass: Maybe<ScaleThresholdClass>;
  readonly firstThresholdMnemonic: Maybe<Scalars["String"]["output"]>;
  readonly firstValue: Maybe<Scalars["Float"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["String"]["output"];
  readonly metInclusionCriteria: Scalars["Boolean"]["output"];
  readonly metSuccessCriteria: Scalars["Boolean"]["output"];
  readonly metTrigger: Scalars["Boolean"]["output"];
  readonly metric: Metric;
  readonly monthsSinceTreatmentStart: Scalars["Int"]["output"];
  readonly organization: Organization;
  readonly patient: Patient;
  readonly scale: Scale;
  readonly sessionTargetDate: Scalars["ISO8601DateTime"]["output"];
  readonly severityCategory: SeverityCategory;
  readonly thresholdClass: Maybe<ScaleThresholdClass>;
  readonly thresholdMnemonic: Maybe<Scalars["String"]["output"]>;
  readonly treatmentStartDate: Scalars["ISO8601DateTime"]["output"];
  readonly trend: Trend;
  readonly validity: ComputedValueValidity;
  readonly value: Maybe<Scalars["Float"]["output"]>;
  readonly weeksSinceTreatmentStart: Scalars["Int"]["output"];
};

/** The connection type for MetricComputedValue. */
export type MetricComputedValueConnection = {
  readonly __typename: "MetricComputedValueConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<MetricComputedValueEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<MetricComputedValue>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type MetricComputedValueEdge = {
  readonly __typename: "MetricComputedValueEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: MetricComputedValue;
};

export enum MetricComputedValueSortParameter {
  /** Delta/Change since baseline */
  DELTA = "DELTA",
  /** First/Baseline Value */
  FIRST_VALUE = "FIRST_VALUE",
  /** Met Inclusion Criteria */
  MET_INCLUSION_CRITERIA = "MET_INCLUSION_CRITERIA",
  /** Met Success Criteria */
  MET_SUCCESS_CRITERIA = "MET_SUCCESS_CRITERIA",
  /** Met Trigger */
  MET_TRIGGER = "MET_TRIGGER",
  PATIENT_NAME = "PATIENT_NAME",
  /** Treatment Start Date */
  TREATMENT_START_DATE = "TREATMENT_START_DATE",
  /** Current Value */
  VALUE = "VALUE",
}

export type MetricConfiguration = {
  readonly globalId: Scalars["ID"]["output"];
  readonly metricInclusionCriteria: ReadonlyArray<MetricInclusionCriterion>;
  readonly population: Maybe<Population>;
  readonly rollup: MetricRollupType;
  readonly scorerConfig: MetricScorerConfiguration;
};

/** The connection type for Metric. */
export type MetricConnection = {
  readonly __typename: "MetricConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<MetricEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Metric>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

export type MetricDataExport = {
  readonly __typename: "MetricDataExport";
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly filename: Scalars["String"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["MetricDataExportId"]["output"];
  readonly metric: Metric;
  readonly status: MetricDataExportStatus;
  readonly updatedAt: Scalars["ISO8601DateTime"]["output"];
  readonly user: Maybe<User>;
};

/** The connection type for MetricDataExport. */
export type MetricDataExportConnection = {
  readonly __typename: "MetricDataExportConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<MetricDataExportEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<MetricDataExport>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type MetricDataExportEdge = {
  readonly __typename: "MetricDataExportEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: MetricDataExport;
};

export enum MetricDataExportSortParameter {
  /** Created At */
  CREATED_AT = "CREATED_AT",
  /** Id */
  ID = "ID",
}

/** Statuses for the export of a metric */
export enum MetricDataExportStatus {
  /** There was an error producing the export */
  ERROR = "ERROR",
  /** The export has not been processed yet */
  NEW = "NEW",
  /** The export is being processed */
  PROCESSING = "PROCESSING",
  /** The export is ready for download */
  READY = "READY",
}

/** An edge in a connection. */
export type MetricEdge = {
  readonly __typename: "MetricEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Metric;
};

export type MetricInclusionCriterion = {
  readonly __typename: "MetricInclusionCriterion";
  readonly criterionType: MetricInclusionCriterionType;
  readonly excludeResults: Maybe<Scalars["Boolean"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["MetricInclusionCriterionId"]["output"];
  readonly integerArrayValue: Maybe<ReadonlyArray<Maybe<Scalars["Int"]["output"]>>>;
  readonly integerValue: Maybe<Scalars["Int"]["output"]>;
  readonly metric: Metric;
  readonly numberValue: Maybe<Scalars["Float"]["output"]>;
  readonly operator: Maybe<Operator>;
  readonly severityValues: Maybe<ReadonlyArray<SeverityCategory>>;
  readonly stringArrayValue: Maybe<ReadonlyArray<Maybe<Scalars["String"]["output"]>>>;
  readonly stringValue: Maybe<Scalars["String"]["output"]>;
  readonly trendValue: Maybe<Trend>;
};

export type MetricInclusionCriterionParams = {
  readonly criterionType: MetricInclusionCriterionType;
  readonly excludeResults?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly id?: InputMaybe<Scalars["MetricInclusionCriterionId"]["input"]>;
  readonly integerArrayValue?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["Int"]["input"]>>>;
  readonly numberValue?: InputMaybe<Scalars["Float"]["input"]>;
  readonly operator?: InputMaybe<Operator>;
  readonly severityValues?: InputMaybe<ReadonlyArray<SeverityCategory>>;
  readonly stringArrayValue?: InputMaybe<ReadonlyArray<InputMaybe<Scalars["String"]["input"]>>>;
  readonly stringValue?: InputMaybe<Scalars["String"]["input"]>;
  readonly trendValue?: InputMaybe<Trend>;
};

/** Which types of inclusion criteria are available */
export enum MetricInclusionCriterionType {
  /** Only include results where the patient was the given age at treatment start */
  AGE_AT_TREATMENT_START = "AGE_AT_TREATMENT_START",
  /** Only include results with a matching baseline score */
  BASELINE_SCORE = "BASELINE_SCORE",
  /** Only include results with the given baseline severity */
  BASELINE_SEVERITY = "BASELINE_SEVERITY",
  /** Only include results where the scale is a target scale for CoCM */
  COCM_TARGET_SCALE = "COCM_TARGET_SCALE",
  /** Days between treatment start and baseline for this scale */
  DAYS_BETWEEN_TREATMENT_START_AND_BASELINE = "DAYS_BETWEEN_TREATMENT_START_AND_BASELINE",
  /** Days between treatment start and current measurement */
  DAYS_SINCE_TREATMENT_START = "DAYS_SINCE_TREATMENT_START",
  /** Only include results for this gender */
  GENDER = "GENDER",
  /** Only include results for this race */
  RACE = "RACE",
}

export type MetricMultiScaleScorerConfiguration = MetricScorerConfiguration & {
  readonly __typename: "MetricMultiScaleScorerConfiguration";
  readonly globalId: Scalars["ID"]["output"];
  readonly scaleScorers: ReadonlyArray<ScaleScorer>;
};

export type MetricParams = {
  readonly inclusionCriteria: ReadonlyArray<MetricInclusionCriterionParams>;
  readonly payload: MetricParamsPayload;
  readonly populationId?: InputMaybe<Scalars["PopulationId"]["input"]>;
  readonly scaleScorerConfig: MetricScaleScorerConfigurationPayload;
};

export type MetricParamsPayload =
  | { readonly timeBased: TimeBasedMetricParams; readonly triggerBased?: never }
  | { readonly timeBased?: never; readonly triggerBased: TriggerBasedMetricParams };

/** Is this metric a single scale metric or a multi-scale rollup */
export enum MetricRollupType {
  /** This is a multi-scale rollup metric */
  MULTI_SCALE = "MULTI_SCALE",
  /** This metric has only a single scale */
  SINGLE_SCALE = "SINGLE_SCALE",
}

export type MetricScaleScorerConfigurationPayload =
  | { readonly multiScale: ReadonlyArray<Scalars["ScaleScorerId"]["input"]>; readonly singleScale?: never }
  | { readonly multiScale?: never; readonly singleScale: Scalars["ScaleScorerId"]["input"] };

export type MetricScorerConfiguration = {
  readonly globalId: Scalars["ID"]["output"];
};

export type MetricSingleScaleScorerConfiguration = MetricScorerConfiguration & {
  readonly __typename: "MetricSingleScaleScorerConfiguration";
  readonly globalId: Scalars["ID"]["output"];
  readonly scaleScorer: ScaleScorer;
};

export enum MetricSortParameter {
  /** Entity depth */
  DEPTH = "DEPTH",
  /** Display order */
  DISPLAY_ORDER = "DISPLAY_ORDER",
  /** Id */
  ID = "ID",
  /** Name */
  NAME = "NAME",
}

/** Statuses for a metric, e.g. is it active */
export enum MetricStatus {
  /** This metric is active */
  ACTIVE = "ACTIVE",
  /** This metric was used to generate an excel export only */
  EXPORT_ONLY = "EXPORT_ONLY",
  /** This metric is no longer active and has been retired */
  RETIRED = "RETIRED",
}

export type MetricSuccessCriterion = {
  readonly __typename: "MetricSuccessCriterion";
  readonly criterionType: MetricSuccessCriterionType;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["MetricSuccessCriterionId"]["output"];
  readonly integerValue: Maybe<Scalars["Int"]["output"]>;
  readonly metric: Metric;
  readonly numberValue: Maybe<Scalars["Float"]["output"]>;
  readonly operator: Maybe<Operator>;
  readonly severityValues: Maybe<ReadonlyArray<SeverityCategory>>;
  readonly stringValue: Maybe<Scalars["String"]["output"]>;
  readonly trendValue: Maybe<Trend>;
};

export type MetricSuccessCriterionParams = {
  readonly criterionType: MetricSuccessCriterionType;
  readonly id?: InputMaybe<Scalars["MetricSuccessCriterionId"]["input"]>;
  readonly integerValue?: InputMaybe<Scalars["Int"]["input"]>;
  readonly numberValue?: InputMaybe<Scalars["Float"]["input"]>;
  readonly operator?: InputMaybe<Operator>;
  readonly severityValues?: InputMaybe<ReadonlyArray<SeverityCategory>>;
  readonly stringArrayValue?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
  readonly stringValue?: InputMaybe<Scalars["String"]["input"]>;
  readonly trendValue?: InputMaybe<Trend>;
};

/** Which types of success criteria are available */
export enum MetricSuccessCriterionType {
  /** Delta between baseline and this measure */
  DELTA = "DELTA",
  /** Absolute final value of the result */
  FINAL_VALUE = "FINAL_VALUE",
  /** Final value as a percent of the initial value */
  FINAL_VALUE_PERCENT = "FINAL_VALUE_PERCENT",
  /** Whether they have improved in severity category since baseline */
  IMPROVED_SEVERITY = "IMPROVED_SEVERITY",
  /** The severity of the final result */
  SEVERITY = "SEVERITY",
  /** Whether symptoms fully remitted */
  TREATMENT_REMISSION = "TREATMENT_REMISSION",
  /** Whether there has been a meaningful response to treatment */
  TREATMENT_RESPONSE = "TREATMENT_RESPONSE",
  /** The trend as given by the psychometrics of the scale */
  TREND = "TREND",
}

export type MetricSummaryData = {
  readonly __typename: "MetricSummaryData";
  readonly eligibleRows: Scalars["Int"]["output"];
  readonly metric: Metric;
  readonly reportEntityTreeNodes: ReadonlyArray<ReportEntityTreeNode>;
  readonly rowsInTimePeriod: Scalars["Int"]["output"];
  readonly successfulRows: Scalars["Int"]["output"];
  readonly totalRows: Scalars["Int"]["output"];
  readonly triggerRows: Scalars["Int"]["output"];
  readonly value: Maybe<Scalars["Float"]["output"]>;
};

export type MetricTemplate = {
  readonly __typename: "MetricTemplate";
  readonly configuration: MetricConfiguration;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["MetricId"]["output"];
  readonly name: Scalars["String"]["output"];
};

/** The connection type for MetricTemplate. */
export type MetricTemplateConnection = {
  readonly __typename: "MetricTemplateConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<MetricTemplateEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<MetricTemplate>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type MetricTemplateEdge = {
  readonly __typename: "MetricTemplateEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: MetricTemplate;
};

export enum MetricTemplateSortParameter {
  /** Id */
  ID = "ID",
  /** Name */
  NAME = "NAME",
}

export type MetricTrigger = {
  readonly __typename: "MetricTrigger";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["MetricTriggerId"]["output"];
  readonly integerValue: Maybe<Scalars["Int"]["output"]>;
  readonly metric: Metric;
  readonly severityValue: Maybe<SeverityCategory>;
  readonly stringArrayValue: Maybe<ReadonlyArray<Scalars["String"]["output"]>>;
  readonly stringValue: Maybe<Scalars["String"]["output"]>;
  readonly trendValue: Maybe<Trend>;
  readonly triggerType: MetricTriggerType;
};

export type MetricTriggerParams = {
  readonly id?: InputMaybe<Scalars["MetricTriggerId"]["input"]>;
  readonly integerValue?: InputMaybe<Scalars["Int"]["input"]>;
  readonly severityValue?: InputMaybe<SeverityCategory>;
  readonly stringArrayValue?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
  readonly stringValue?: InputMaybe<Scalars["String"]["input"]>;
  readonly trendValue?: InputMaybe<Trend>;
  readonly triggerType: MetricTriggerType;
};

/** Which types of trigger are available for a trigger-based metric */
export enum MetricTriggerType {
  /** The first time the patient has improved severity */
  FIRST_SESSION_OF_IMPROVED_SEVERITY = "FIRST_SESSION_OF_IMPROVED_SEVERITY",
  /** The first time the patient got to the specified severity */
  FIRST_SESSION_OF_SPECIFIED_SEVERITY = "FIRST_SESSION_OF_SPECIFIED_SEVERITY",
  /** The first time the patient saw treatment remission */
  FIRST_SESSION_OF_TREATMENT_REMISSION = "FIRST_SESSION_OF_TREATMENT_REMISSION",
  /** The first time the patient saw treatment response */
  FIRST_SESSION_OF_TREATMENT_RESPONSE = "FIRST_SESSION_OF_TREATMENT_RESPONSE",
  /** The first time a particular trend is seen for this patient */
  FIRST_SESSION_OF_TREND = "FIRST_SESSION_OF_TREND",
}

export type Model = {
  readonly __typename: "Model";
  readonly className: Scalars["String"]["output"];
  readonly fields: ReadonlyArray<Field>;
  readonly id: Scalars["String"]["output"];
};

export type Module = {
  readonly __typename: "Module";
  readonly description: Scalars["String"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ModuleId"]["output"];
  readonly mnemonic: Scalars["String"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly urlCode: Scalars["String"]["output"];
};

/** An amount of currency */
export type Money = {
  readonly __typename: "Money";
  /** Number of cents */
  readonly cents: Scalars["Int"]["output"];
  /** The currency */
  readonly currency: Scalars["String"]["output"];
};

/** Inputs a currency amount */
export type MoneyParams = {
  /** Cents of the money */
  readonly cents: Scalars["Int"]["input"];
  /** Currency code */
  readonly currency: Scalars["String"]["input"];
};

export type MonitoredJob = {
  readonly __typename: "MonitoredJob";
  readonly arguments: Scalars["JobArgumentData"]["output"];
  readonly className: Scalars["String"]["output"];
  readonly completion: Maybe<Scalars["Float"]["output"]>;
  readonly endedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["MonitoredJobId"]["output"];
  readonly logOutput: Scalars["String"]["output"];
  readonly startedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly status: MonitoredJobStatus;
};

/** What state a monitored job is in */
export enum MonitoredJobStatus {
  /** The underlying task raised an exception */
  FAILED = "FAILED",
  /** The underlying task has not been queued */
  NOT_YET_STARTED = "NOT_YET_STARTED",
  /** The underlying task has been queued, but not started */
  QUEUED = "QUEUED",
  /** The underlying task is running */
  RUNNING = "RUNNING",
  /** The underlying task exited without raising an exception */
  SUCCEEDED = "SUCCEEDED",
}

/** A calendar month */
export type MonthParams = {
  /** The calendar month, 1 indexed so January = 1 */
  readonly month: Scalars["Int"]["input"];
  /** The year */
  readonly year: Scalars["Int"]["input"];
};

/** Autogenerated input type of MoveTaskListItemToPositionInDefaultTaskList */
export type MoveTaskListItemToPositionInDefaultTaskListInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly position: Scalars["Int"]["input"];
  readonly taskListItemId: Scalars["TaskListItemId"]["input"];
};

/** Autogenerated return type of MoveTaskListItemToPositionInDefaultTaskList. */
export type MoveTaskListItemToPositionInDefaultTaskListPayload = {
  readonly __typename: "MoveTaskListItemToPositionInDefaultTaskListPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<MoveTaskListItemToPositionInDefaultTaskListResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type MoveTaskListItemToPositionInDefaultTaskListResult = {
  readonly __typename: "MoveTaskListItemToPositionInDefaultTaskListResult";
  readonly taskList: TaskList;
  readonly taskListItem: TaskListItem;
};

/** Autogenerated input type of MoveTaskToPositionInDefaultTaskList */
export type MoveTaskToPositionInDefaultTaskListInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly position: Scalars["Int"]["input"];
  readonly taskId: Scalars["TaskId"]["input"];
};

/** Autogenerated return type of MoveTaskToPositionInDefaultTaskList. */
export type MoveTaskToPositionInDefaultTaskListPayload = {
  readonly __typename: "MoveTaskToPositionInDefaultTaskListPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<MoveTaskToPositionInDefaultTaskListResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type MoveTaskToPositionInDefaultTaskListResult = {
  readonly __typename: "MoveTaskToPositionInDefaultTaskListResult";
  readonly taskList: Maybe<TaskList>;
  readonly taskListItem: Maybe<TaskListItem>;
};

export type Mutation = {
  readonly __typename: "Mutation";
  readonly actOnInboxMessage: Maybe<ActOnInboxMessagePayload>;
  readonly addCareEpisodeProvider: Maybe<AddCareEpisodeProviderPayload>;
  readonly addInstituteToInstituteGroup: Maybe<AddInstituteToInstituteGroupPayload>;
  readonly assessmentCreateGoal: Maybe<CreateGoalPayload>;
  readonly assessmentMeasurementRequest: Maybe<MeasurementRequestPayload>;
  readonly assessmentResendInvitationNotifications: Maybe<ResendInvitationNotificationsPayload>;
  readonly assessmentUpdateGoal: Maybe<UpdateGoalPayload>;
  readonly assessmentUpdateInvitation: Maybe<UpdateInvitationPayload>;
  readonly billingCreateCocmBillingAlgorithm: Maybe<CreateCocmBillingAlgorithmPayload>;
  readonly billingUpdateCocmBillingAlgorithm: Maybe<UpdateCocmBillingAlgorithmPayload>;
  readonly careCreateCareEpisodeTreatmentTrack: Maybe<CreateCareEpisodeTreatmentTrackPayload>;
  readonly careCreateTreatmentTrack: Maybe<CreateTreatmentTrackPayload>;
  readonly careDischargeCareEpisode: Maybe<DischargeCareEpisodePayload>;
  readonly careUpdateCareEpisodeTreatmentTrack: Maybe<UpdateCareEpisodeTreatmentTrackPayload>;
  readonly careUpdateTreatmentTrack: Maybe<UpdateTreatmentTrackPayload>;
  readonly collaborativeCareAcceptFinding: Maybe<AcceptFindingPayload>;
  readonly collaborativeCareAddNoteToTask: Maybe<AddNoteToTaskPayload>;
  readonly collaborativeCareAddTaskToDefaultTaskList: Maybe<AddTaskToDefaultTaskListPayload>;
  readonly collaborativeCareAddTaskToDefaultTaskListAtPosition: Maybe<AddTaskToDefaultTaskListAtPositionPayload>;
  readonly collaborativeCareAddTreatmentTarget: Maybe<AddTreatmentTargetPayload>;
  readonly collaborativeCareAssignDocumentationEncounter: Maybe<AssignDocumentationEncounterPayload>;
  readonly collaborativeCareCancelCaseConsult: Maybe<CancelCaseConsultPayload>;
  readonly collaborativeCareCreateConsultMeetingAttendee: Maybe<CreateConsultMeetingAttendeePayload>;
  readonly collaborativeCareCreateOrEditTreatmentTargetScale: Maybe<CreateOrEditTreatmentTargetScalePayload>;
  readonly collaborativeCareCreatePanel: Maybe<CreatePanelPayload>;
  readonly collaborativeCareCreatePanelTeamMember: Maybe<CreatePanelTeamMemberPayload>;
  readonly collaborativeCareCreateQuickText: Maybe<CreateQuickTextPayload>;
  readonly collaborativeCareCreateTask: Maybe<CreateTaskPayload>;
  readonly collaborativeCareCreateTaskTemplate: Maybe<CreateTaskTemplatePayload>;
  readonly collaborativeCareCreateTimeEntryLog: Maybe<CreateTimeEntryLogPayload>;
  readonly collaborativeCareCreateTreatmentTarget: Maybe<CreateTreatmentTargetPayload>;
  readonly collaborativeCareDeleteNote: Maybe<DeleteNotePayload>;
  readonly collaborativeCareDeleteQuickText: Maybe<DeleteQuickTextPayload>;
  readonly collaborativeCareDeleteTaskTemplate: Maybe<DeleteTaskTemplatePayload>;
  readonly collaborativeCareDeleteTimeEntryLog: Maybe<DeleteTimeEntryLogPayload>;
  readonly collaborativeCareDeleteTreatmentTarget: Maybe<DeleteTreatmentTargetPayload>;
  readonly collaborativeCareDeleteTreatmentTargetScale: Maybe<DeleteTreatmentTargetScalePayload>;
  readonly collaborativeCareDischargeEnrollment: Maybe<DischargeEnrollmentPayload>;
  readonly collaborativeCareDismissFinding: Maybe<DismissFindingPayload>;
  readonly collaborativeCareEditNote: Maybe<EditNotePayload>;
  readonly collaborativeCareEditPanel: Maybe<EditPanelPayload>;
  readonly collaborativeCareEditPanelTeamMember: Maybe<EditPanelTeamMemberPayload>;
  readonly collaborativeCareEditTimeEntryLog: Maybe<EditTimeEntryLogPayload>;
  readonly collaborativeCareEditTreatmentTarget: Maybe<EditTreatmentTargetPayload>;
  readonly collaborativeCareEndCaseConsultReview: Maybe<EndCaseConsultReviewPayload>;
  readonly collaborativeCareEndTimeEntryLog: Maybe<EndTimeEntryLogPayload>;
  readonly collaborativeCareEnrollPatient: Maybe<EnrollPatientPayload>;
  readonly collaborativeCareEnrollmentCheckIntakeScalesComplete: Maybe<EnrollmentCheckIntakeScalesCompletePayload>;
  readonly collaborativeCareInvalidateFindings: Maybe<InvalidateFindingsPayload>;
  readonly collaborativeCareManuallyCancelInvitation: Maybe<ManuallyCancelInvitationPayload>;
  readonly collaborativeCareMarkCaseConsultComplete: Maybe<MarkCaseConsultCompletePayload>;
  readonly collaborativeCareMarkConsultMeetingComplete: Maybe<MarkConsultMeetingCompletePayload>;
  readonly collaborativeCareMergePlaceholderTaskWithExistingTask: Maybe<MergePlaceholderTaskWithExistingTaskPayload>;
  readonly collaborativeCareMoveTaskListItemToPositionInDefaultTaskList: Maybe<MoveTaskListItemToPositionInDefaultTaskListPayload>;
  readonly collaborativeCareMoveTaskToPositionInDefaultTaskList: Maybe<MoveTaskToPositionInDefaultTaskListPayload>;
  readonly collaborativeCareQuickTrackUnknownTask: Maybe<QuickTrackUnknownTaskPayload>;
  readonly collaborativeCareReactivatePreviousEnrollment: Maybe<ReactivatePreviousEnrollmentPayload>;
  readonly collaborativeCareRemoveConsultMeetingAttendee: Maybe<RemoveConsultMeetingAttendeePayload>;
  readonly collaborativeCareRemoveTaskListItemFromDefaultTaskList: Maybe<RemoveTaskListItemFromDefaultTaskListPayload>;
  readonly collaborativeCareRemoveTreatmentTarget: Maybe<RemoveTreatmentTargetPayload>;
  readonly collaborativeCareReplanAllActiveEnrollments: Maybe<ReplanAllActiveEnrollmentPayload>;
  readonly collaborativeCareRequestCaseConsult: Maybe<RequestCaseConsultPayload>;
  readonly collaborativeCareResendAllInvitationNotifications: Maybe<ResendAllInvitationNotificationsPayload>;
  readonly collaborativeCareSaveConsultReviewNotes: Maybe<SaveConsultReviewNotePayload>;
  readonly collaborativeCareSendImmediateSurvey: Maybe<SendImmediateSurveyPayload>;
  readonly collaborativeCareSendInvitationNotifications: Maybe<SendInvitationNotificationsPayload>;
  readonly collaborativeCareSetIntakeScaleAdministrationChoice: Maybe<SetIntakeScaleAdministrationChoicePayload>;
  readonly collaborativeCareSetTaskStatus: Maybe<SetTaskStatusPayload>;
  readonly collaborativeCareSnoozeFinding: Maybe<SnoozeFindingPayload>;
  readonly collaborativeCareStartCaseConsultReview: Maybe<StartCaseConsultReviewPayload>;
  readonly collaborativeCareStartCaseSummaryTimeEntry: Maybe<StartCaseSummaryTimeEntryPayload>;
  readonly collaborativeCareStartConsultMeeting: Maybe<StartConsultMeetingPayload>;
  readonly collaborativeCareStartTimeEntryLogNow: Maybe<StartTimeEntryLogNowPayload>;
  readonly collaborativeCareUpdateEnrollment: Maybe<UpdateEnrollmentPayload>;
  readonly collaborativeCareUpdatePatientNote: Maybe<UpdatePatientNotePayload>;
  readonly collaborativeCareUpdateQuickText: Maybe<UpdateQuickTextPayload>;
  /** Updates a task title or body by id */
  readonly collaborativeCareUpdateTask: Maybe<UpdateTaskPayload>;
  readonly collaborativeCareUpdateTaskTemplate: Maybe<UpdateTaskTemplatePayload>;
  readonly createAnalyticsEvent: Maybe<CreateAnalyticsEventPayload>;
  readonly createCareEpisode: Maybe<CreateCareEpisodePayload>;
  readonly createCareGroup: Maybe<CreateCareGroupPayload>;
  readonly createExternalEvent: Maybe<CreateExternalEventPayload>;
  readonly createInstituteGroup: Maybe<CreateInstituteGroupPayload>;
  readonly createOrganization: Maybe<CreateOrganizationPayload>;
  readonly createPatient: Maybe<CreatePatientPayload>;
  readonly createPipbhcTreatmentGoal: Maybe<CreatePipbhcTreatmentGoalPayload>;
  readonly createPipbhcTreatmentGoalReport: Maybe<CreatePipbhcTreatmentGoalReportPayload>;
  readonly createRelatedPerson: Maybe<CreateRelatedPersonPayload>;
  readonly createTreatmentService: Maybe<CreateTreatmentServicePayload>;
  readonly decisionSupportRunWorkflowAction: Maybe<RunWorkflowActionPayload>;
  readonly deleteCareEpisodeProvider: Maybe<DeleteCareEpisodeProviderPayload>;
  readonly editCareEpisode: Maybe<EditCareEpisodePayload>;
  readonly editExternalEvent: Maybe<EditExternalEventPayload>;
  readonly editOrganization: Maybe<EditOrganizationPayload>;
  readonly editPatientDemographics: Maybe<EditPatientDemographicPayload>;
  readonly exportCreateDataDump: Maybe<CreateDataDumpPayload>;
  readonly exportCreateDataDumpDownloadUrl: Maybe<CreateDataDumpDownloadUrlPayload>;
  readonly generateUserLoginCode: Maybe<GenerateUserLoginCodePayload>;
  readonly implementationChangeImplementationTargetDisplayOrder: Maybe<ChangeImplementationTargetDisplayOrderPayload>;
  readonly implementationCreateImplementationTarget: Maybe<CreateImplementationTargetPayload>;
  readonly implementationEmailImplementationTargetReport: Maybe<EmailImplementationTargetReportPayload>;
  readonly implementationUpdateImplementationTarget: Maybe<UpdateImplementationTargetPayload>;
  readonly implementationUpdateImplementationTargetStatus: Maybe<UpdateImplementationTargetStatusPayload>;
  readonly instituteUploadLogo: Maybe<InstituteUploadLogoPayload>;
  readonly outcomesChangeMetricDisplayOrder: Maybe<ChangeMetricDisplayOrderPayload>;
  readonly outcomesCreateMetric: Maybe<CreateMetricPayload>;
  readonly outcomesCreateMetricAndExport: Maybe<CreateMetricAndExportPayload>;
  readonly outcomesCreateMetricDataExport: Maybe<CreateMetricDataExportPayload>;
  readonly outcomesCreateMetricDataExportDownloadUrl: Maybe<CreateMetricDataExportDownloadUrlPayload>;
  readonly outcomesUpdateMetric: Maybe<UpdateMetricPayload>;
  readonly outcomesUpdateMetricStatus: Maybe<UpdateMetricStatusPayload>;
  readonly removeInstituteFromInstituteGroup: Maybe<RemoveInstituteFromInstituteGroupPayload>;
  readonly schedulingCancelAppointment: Maybe<CancelAppointmentPayload>;
  readonly schedulingCreateAppointment: Maybe<CreateAppointmentPayload>;
  readonly schedulingDeleteAppointment: Maybe<DeleteAppointmentPayload>;
  readonly schedulingEditAppointment: Maybe<EditAppointmentPayload>;
  readonly setPatientFlag: Maybe<SetPatientFlagPayload>;
  readonly settingsCareEpisodeUpdateMeasurementAllowed: Maybe<CareEpisodeUpdateMeasurementAllowedPayload>;
  readonly settingsInstituteUpdateMeasurementAllowed: Maybe<InstituteUpdateMeasurementAllowedPayload>;
  readonly settingsOrganizationUpdateMeasurementAllowed: Maybe<OrganizationUpdateMeasurementAllowedPayload>;
  readonly settingsOrganizationUpdateOrganizationSettings: Maybe<UpdateOrganizationSettingsPayload>;
  readonly settingsOrganizationUpdateTrialPeriod: Maybe<UpdateTrialPeriodPayload>;
  readonly settingsProviderUpdateMeasurementAllowed: Maybe<ProviderUpdateMeasurementAllowedPayload>;
  readonly supportRunTask: Maybe<RunSupportTaskPayload>;
  readonly surveyTakeSurvey: Maybe<TakeSurveyPayload>;
  readonly updateCareGroup: Maybe<UpdateCareGroupPayload>;
  readonly updateEhrLinkNotes: Maybe<UpdateEhrLinkNotesPayload>;
  readonly updatePatientFlag: Maybe<UpdatePatientFlagPayload>;
  readonly updatePatientNotificationPreference: Maybe<UpdatePatientNotificationPreferencePayload>;
  readonly updatePatientQualityIssue: Maybe<UpdatePatientQualityIssuePayload>;
  readonly updatePipbhcGpraId: Maybe<UpdateGpraIdPayload>;
  readonly updatePipbhcTreatmentGoal: Maybe<UpdateTreatmentGoalPayload>;
  readonly updateRelatedPerson: Maybe<UpdateRelatedPersonPayload>;
  readonly updateTreatmentService: Maybe<UpdateTreatmentServicePayload>;
  readonly updateTreatmentServiceInstituteConditions: Maybe<UpdateTreatmentServiceInstituteConditionsPayload>;
  readonly updateTreatmentServiceOrganizations: Maybe<UpdateTreatmentServiceOrganizationsPayload>;
  readonly updateTreatmentServiceProviders: Maybe<UpdateTreatmentServiceProvidersPayload>;
};

export type MutationactOnInboxMessageArgs = {
  input: ActOnInboxMessageInput;
};

export type MutationaddCareEpisodeProviderArgs = {
  input: AddCareEpisodeProviderInput;
};

export type MutationaddInstituteToInstituteGroupArgs = {
  input: AddInstituteToInstituteGroupInput;
};

export type MutationassessmentCreateGoalArgs = {
  input: CreateGoalInput;
};

export type MutationassessmentMeasurementRequestArgs = {
  input: MeasurementRequestInput;
};

export type MutationassessmentResendInvitationNotificationsArgs = {
  input: ResendInvitationNotificationsInput;
};

export type MutationassessmentUpdateGoalArgs = {
  input: UpdateGoalInput;
};

export type MutationassessmentUpdateInvitationArgs = {
  input: UpdateInvitationInput;
};

export type MutationbillingCreateCocmBillingAlgorithmArgs = {
  input: CreateCocmBillingAlgorithmInput;
};

export type MutationbillingUpdateCocmBillingAlgorithmArgs = {
  input: UpdateCocmBillingAlgorithmInput;
};

export type MutationcareCreateCareEpisodeTreatmentTrackArgs = {
  input: CreateCareEpisodeTreatmentTrackInput;
};

export type MutationcareCreateTreatmentTrackArgs = {
  input: CreateTreatmentTrackInput;
};

export type MutationcareDischargeCareEpisodeArgs = {
  input: DischargeCareEpisodeInput;
};

export type MutationcareUpdateCareEpisodeTreatmentTrackArgs = {
  input: UpdateCareEpisodeTreatmentTrackInput;
};

export type MutationcareUpdateTreatmentTrackArgs = {
  input: UpdateTreatmentTrackInput;
};

export type MutationcollaborativeCareAcceptFindingArgs = {
  input: AcceptFindingInput;
};

export type MutationcollaborativeCareAddNoteToTaskArgs = {
  input: AddNoteToTaskInput;
};

export type MutationcollaborativeCareAddTaskToDefaultTaskListArgs = {
  input: AddTaskToDefaultTaskListInput;
};

export type MutationcollaborativeCareAddTaskToDefaultTaskListAtPositionArgs = {
  input: AddTaskToDefaultTaskListAtPositionInput;
};

export type MutationcollaborativeCareAddTreatmentTargetArgs = {
  input: AddTreatmentTargetInput;
};

export type MutationcollaborativeCareAssignDocumentationEncounterArgs = {
  input: AssignDocumentationEncounterInput;
};

export type MutationcollaborativeCareCancelCaseConsultArgs = {
  input: CancelCaseConsultInput;
};

export type MutationcollaborativeCareCreateConsultMeetingAttendeeArgs = {
  input: CreateConsultMeetingAttendeeInput;
};

export type MutationcollaborativeCareCreateOrEditTreatmentTargetScaleArgs = {
  input: CreateOrEditTreatmentTargetScaleInput;
};

export type MutationcollaborativeCareCreatePanelArgs = {
  input: CreatePanelInput;
};

export type MutationcollaborativeCareCreatePanelTeamMemberArgs = {
  input: CreatePanelTeamMemberInput;
};

export type MutationcollaborativeCareCreateQuickTextArgs = {
  input: CreateQuickTextInput;
};

export type MutationcollaborativeCareCreateTaskArgs = {
  input: CreateTaskInput;
};

export type MutationcollaborativeCareCreateTaskTemplateArgs = {
  input: CreateTaskTemplateInput;
};

export type MutationcollaborativeCareCreateTimeEntryLogArgs = {
  input: CreateTimeEntryLogInput;
};

export type MutationcollaborativeCareCreateTreatmentTargetArgs = {
  input: CreateTreatmentTargetInput;
};

export type MutationcollaborativeCareDeleteNoteArgs = {
  input: DeleteNoteInput;
};

export type MutationcollaborativeCareDeleteQuickTextArgs = {
  input: DeleteQuickTextInput;
};

export type MutationcollaborativeCareDeleteTaskTemplateArgs = {
  input: DeleteTaskTemplateInput;
};

export type MutationcollaborativeCareDeleteTimeEntryLogArgs = {
  input: DeleteTimeEntryLogInput;
};

export type MutationcollaborativeCareDeleteTreatmentTargetArgs = {
  input: DeleteTreatmentTargetInput;
};

export type MutationcollaborativeCareDeleteTreatmentTargetScaleArgs = {
  input: DeleteTreatmentTargetScaleInput;
};

export type MutationcollaborativeCareDischargeEnrollmentArgs = {
  input: DischargeEnrollmentInput;
};

export type MutationcollaborativeCareDismissFindingArgs = {
  input: DismissFindingInput;
};

export type MutationcollaborativeCareEditNoteArgs = {
  input: EditNoteInput;
};

export type MutationcollaborativeCareEditPanelArgs = {
  input: EditPanelInput;
};

export type MutationcollaborativeCareEditPanelTeamMemberArgs = {
  input: EditPanelTeamMemberInput;
};

export type MutationcollaborativeCareEditTimeEntryLogArgs = {
  input: EditTimeEntryLogInput;
};

export type MutationcollaborativeCareEditTreatmentTargetArgs = {
  input: EditTreatmentTargetInput;
};

export type MutationcollaborativeCareEndCaseConsultReviewArgs = {
  input: EndCaseConsultReviewInput;
};

export type MutationcollaborativeCareEndTimeEntryLogArgs = {
  input: EndTimeEntryLogInput;
};

export type MutationcollaborativeCareEnrollPatientArgs = {
  input: EnrollPatientInput;
};

export type MutationcollaborativeCareEnrollmentCheckIntakeScalesCompleteArgs = {
  input: EnrollmentCheckIntakeScalesCompleteInput;
};

export type MutationcollaborativeCareInvalidateFindingsArgs = {
  input: InvalidateFindingsInput;
};

export type MutationcollaborativeCareManuallyCancelInvitationArgs = {
  input: ManuallyCancelInvitationInput;
};

export type MutationcollaborativeCareMarkCaseConsultCompleteArgs = {
  input: MarkCaseConsultCompleteInput;
};

export type MutationcollaborativeCareMarkConsultMeetingCompleteArgs = {
  input: MarkConsultMeetingCompleteInput;
};

export type MutationcollaborativeCareMergePlaceholderTaskWithExistingTaskArgs = {
  input: MergePlaceholderTaskWithExistingTaskInput;
};

export type MutationcollaborativeCareMoveTaskListItemToPositionInDefaultTaskListArgs = {
  input: MoveTaskListItemToPositionInDefaultTaskListInput;
};

export type MutationcollaborativeCareMoveTaskToPositionInDefaultTaskListArgs = {
  input: MoveTaskToPositionInDefaultTaskListInput;
};

export type MutationcollaborativeCareQuickTrackUnknownTaskArgs = {
  input: QuickTrackUnknownTaskInput;
};

export type MutationcollaborativeCareReactivatePreviousEnrollmentArgs = {
  input: ReactivatePreviousEnrollmentInput;
};

export type MutationcollaborativeCareRemoveConsultMeetingAttendeeArgs = {
  input: RemoveConsultMeetingAttendeeInput;
};

export type MutationcollaborativeCareRemoveTaskListItemFromDefaultTaskListArgs = {
  input: RemoveTaskListItemFromDefaultTaskListInput;
};

export type MutationcollaborativeCareRemoveTreatmentTargetArgs = {
  input: RemoveTreatmentTargetInput;
};

export type MutationcollaborativeCareReplanAllActiveEnrollmentsArgs = {
  input: ReplanAllActiveEnrollmentInput;
};

export type MutationcollaborativeCareRequestCaseConsultArgs = {
  input: RequestCaseConsultInput;
};

export type MutationcollaborativeCareResendAllInvitationNotificationsArgs = {
  input: ResendAllInvitationNotificationsInput;
};

export type MutationcollaborativeCareSaveConsultReviewNotesArgs = {
  input: SaveConsultReviewNoteInput;
};

export type MutationcollaborativeCareSendImmediateSurveyArgs = {
  input: SendImmediateSurveyInput;
};

export type MutationcollaborativeCareSendInvitationNotificationsArgs = {
  input: SendInvitationNotificationsInput;
};

export type MutationcollaborativeCareSetIntakeScaleAdministrationChoiceArgs = {
  input: SetIntakeScaleAdministrationChoiceInput;
};

export type MutationcollaborativeCareSetTaskStatusArgs = {
  input: SetTaskStatusInput;
};

export type MutationcollaborativeCareSnoozeFindingArgs = {
  input: SnoozeFindingInput;
};

export type MutationcollaborativeCareStartCaseConsultReviewArgs = {
  input: StartCaseConsultReviewInput;
};

export type MutationcollaborativeCareStartCaseSummaryTimeEntryArgs = {
  input: StartCaseSummaryTimeEntryInput;
};

export type MutationcollaborativeCareStartConsultMeetingArgs = {
  input: StartConsultMeetingInput;
};

export type MutationcollaborativeCareStartTimeEntryLogNowArgs = {
  input: StartTimeEntryLogNowInput;
};

export type MutationcollaborativeCareUpdateEnrollmentArgs = {
  input: UpdateEnrollmentInput;
};

export type MutationcollaborativeCareUpdatePatientNoteArgs = {
  input: UpdatePatientNoteInput;
};

export type MutationcollaborativeCareUpdateQuickTextArgs = {
  input: UpdateQuickTextInput;
};

export type MutationcollaborativeCareUpdateTaskArgs = {
  input: UpdateTaskInput;
};

export type MutationcollaborativeCareUpdateTaskTemplateArgs = {
  input: UpdateTaskTemplateInput;
};

export type MutationcreateAnalyticsEventArgs = {
  input: CreateAnalyticsEventInput;
};

export type MutationcreateCareEpisodeArgs = {
  input: CreateCareEpisodeInput;
};

export type MutationcreateCareGroupArgs = {
  input: CreateCareGroupInput;
};

export type MutationcreateExternalEventArgs = {
  input: CreateExternalEventInput;
};

export type MutationcreateInstituteGroupArgs = {
  input: CreateInstituteGroupInput;
};

export type MutationcreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

export type MutationcreatePatientArgs = {
  input: CreatePatientInput;
};

export type MutationcreatePipbhcTreatmentGoalArgs = {
  input: CreatePipbhcTreatmentGoalInput;
};

export type MutationcreatePipbhcTreatmentGoalReportArgs = {
  input: CreatePipbhcTreatmentGoalReportInput;
};

export type MutationcreateRelatedPersonArgs = {
  input: CreateRelatedPersonInput;
};

export type MutationcreateTreatmentServiceArgs = {
  input: CreateTreatmentServiceInput;
};

export type MutationdecisionSupportRunWorkflowActionArgs = {
  input: RunWorkflowActionInput;
};

export type MutationdeleteCareEpisodeProviderArgs = {
  input: DeleteCareEpisodeProviderInput;
};

export type MutationeditCareEpisodeArgs = {
  input: EditCareEpisodeInput;
};

export type MutationeditExternalEventArgs = {
  input: EditExternalEventInput;
};

export type MutationeditOrganizationArgs = {
  input: EditOrganizationInput;
};

export type MutationeditPatientDemographicsArgs = {
  input: EditPatientDemographicInput;
};

export type MutationexportCreateDataDumpArgs = {
  input: CreateDataDumpInput;
};

export type MutationexportCreateDataDumpDownloadUrlArgs = {
  input: CreateDataDumpDownloadUrlInput;
};

export type MutationgenerateUserLoginCodeArgs = {
  input: GenerateUserLoginCodeInput;
};

export type MutationimplementationChangeImplementationTargetDisplayOrderArgs = {
  input: ChangeImplementationTargetDisplayOrderInput;
};

export type MutationimplementationCreateImplementationTargetArgs = {
  input: CreateImplementationTargetInput;
};

export type MutationimplementationEmailImplementationTargetReportArgs = {
  input: EmailImplementationTargetReportInput;
};

export type MutationimplementationUpdateImplementationTargetArgs = {
  input: UpdateImplementationTargetInput;
};

export type MutationimplementationUpdateImplementationTargetStatusArgs = {
  input: UpdateImplementationTargetStatusInput;
};

export type MutationinstituteUploadLogoArgs = {
  input: InstituteUploadLogoInput;
};

export type MutationoutcomesChangeMetricDisplayOrderArgs = {
  input: ChangeMetricDisplayOrderInput;
};

export type MutationoutcomesCreateMetricArgs = {
  input: CreateMetricInput;
};

export type MutationoutcomesCreateMetricAndExportArgs = {
  input: CreateMetricAndExportInput;
};

export type MutationoutcomesCreateMetricDataExportArgs = {
  input: CreateMetricDataExportInput;
};

export type MutationoutcomesCreateMetricDataExportDownloadUrlArgs = {
  input: CreateMetricDataExportDownloadUrlInput;
};

export type MutationoutcomesUpdateMetricArgs = {
  input: UpdateMetricInput;
};

export type MutationoutcomesUpdateMetricStatusArgs = {
  input: UpdateMetricStatusInput;
};

export type MutationremoveInstituteFromInstituteGroupArgs = {
  input: RemoveInstituteFromInstituteGroupInput;
};

export type MutationschedulingCancelAppointmentArgs = {
  input: CancelAppointmentInput;
};

export type MutationschedulingCreateAppointmentArgs = {
  input: CreateAppointmentInput;
};

export type MutationschedulingDeleteAppointmentArgs = {
  input: DeleteAppointmentInput;
};

export type MutationschedulingEditAppointmentArgs = {
  input: EditAppointmentInput;
};

export type MutationsetPatientFlagArgs = {
  input: SetPatientFlagInput;
};

export type MutationsettingsCareEpisodeUpdateMeasurementAllowedArgs = {
  input: CareEpisodeUpdateMeasurementAllowedInput;
};

export type MutationsettingsInstituteUpdateMeasurementAllowedArgs = {
  input: InstituteUpdateMeasurementAllowedInput;
};

export type MutationsettingsOrganizationUpdateMeasurementAllowedArgs = {
  input: OrganizationUpdateMeasurementAllowedInput;
};

export type MutationsettingsOrganizationUpdateOrganizationSettingsArgs = {
  input: UpdateOrganizationSettingsInput;
};

export type MutationsettingsOrganizationUpdateTrialPeriodArgs = {
  input: UpdateTrialPeriodInput;
};

export type MutationsettingsProviderUpdateMeasurementAllowedArgs = {
  input: ProviderUpdateMeasurementAllowedInput;
};

export type MutationsupportRunTaskArgs = {
  input: RunSupportTaskInput;
};

export type MutationsurveyTakeSurveyArgs = {
  input: TakeSurveyInput;
};

export type MutationupdateCareGroupArgs = {
  input: UpdateCareGroupInput;
};

export type MutationupdateEhrLinkNotesArgs = {
  input: UpdateEhrLinkNotesInput;
};

export type MutationupdatePatientFlagArgs = {
  input: UpdatePatientFlagInput;
};

export type MutationupdatePatientNotificationPreferenceArgs = {
  input: UpdatePatientNotificationPreferenceInput;
};

export type MutationupdatePatientQualityIssueArgs = {
  input: UpdatePatientQualityIssueInput;
};

export type MutationupdatePipbhcGpraIdArgs = {
  input: UpdateGpraIdInput;
};

export type MutationupdatePipbhcTreatmentGoalArgs = {
  input: UpdateTreatmentGoalInput;
};

export type MutationupdateRelatedPersonArgs = {
  input: UpdateRelatedPersonInput;
};

export type MutationupdateTreatmentServiceArgs = {
  input: UpdateTreatmentServiceInput;
};

export type MutationupdateTreatmentServiceInstituteConditionsArgs = {
  input: UpdateTreatmentServiceInstituteConditionsInput;
};

export type MutationupdateTreatmentServiceOrganizationsArgs = {
  input: UpdateTreatmentServiceOrganizationsInput;
};

export type MutationupdateTreatmentServiceProvidersArgs = {
  input: UpdateTreatmentServiceProvidersInput;
};

export type MyActiveTimeEntryLog = {
  readonly __typename: "MyActiveTimeEntryLog";
  readonly globalId: Scalars["ID"]["output"];
  readonly timeEntryLog: Maybe<TimeEntryLog>;
};

export type NewPipbhcTreatmentGoalAnswer = {
  readonly note?: InputMaybe<Scalars["String"]["input"]>;
  readonly pipbhcTreatmentGoalId: Scalars["PipbhcTreatmentGoalId"]["input"];
  readonly value?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Norm = {
  readonly __typename: "Norm";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["NormId"]["output"];
  readonly mean: Maybe<Scalars["Float"]["output"]>;
  readonly mode: Maybe<Scalars["Float"]["output"]>;
  readonly population: Population;
  readonly scale: Scale;
  readonly stddev: Maybe<Scalars["Float"]["output"]>;
};

/** A normalized score in the range of 1 = good to 7 = bad. Used to show color coding for items */
export enum NormalizedScore {
  /** 1 */
  VALUE_1 = "VALUE_1",
  /** 2 */
  VALUE_2 = "VALUE_2",
  /** 3 */
  VALUE_3 = "VALUE_3",
  /** 4 */
  VALUE_4 = "VALUE_4",
  /** 5 */
  VALUE_5 = "VALUE_5",
  /** 6 */
  VALUE_6 = "VALUE_6",
  /** 7 */
  VALUE_7 = "VALUE_7",
}

export type Note = {
  readonly __typename: "Note";
  readonly author: Provider;
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly encounter: Maybe<Encounter>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["NoteId"]["output"];
  readonly task: Task;
  readonly text: Scalars["String"]["output"];
  readonly timeEntry: Maybe<TimeEntryLog>;
};

/** The connection type for Note. */
export type NoteConnection = {
  readonly __typename: "NoteConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<NoteEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Note>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type NoteEdge = {
  readonly __typename: "NoteEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Note;
};

export enum NoteSortParameter {
  /** Id */
  ID = "ID",
}

/** THe manner in which a user wants to get notifications */
export enum NotificationPreference {
  /** Send both text message and email notifications */
  BOTH = "BOTH",
  /** Only send email notifications */
  EMAIL = "EMAIL",
  /** Do not send any notifications */
  NONE = "NONE",
  /** Only send text message notifications */
  TEXT = "TEXT",
}

export type NumericalScaleScorer = ScaleScorer & {
  readonly __typename: "NumericalScaleScorer";
  readonly domain: ScaleScorerDomain;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ScaleScorerId"]["output"];
  /** Which other values are used to generate this score */
  readonly inputDomains: Maybe<ReadonlyArray<Scalars["String"]["output"]>>;
  readonly maxInterpretation: Maybe<Scalars["String"]["output"]>;
  readonly maxInterpretationShort: Maybe<Scalars["String"]["output"]>;
  readonly maxValue: Scalars["Float"]["output"];
  readonly minInterpretation: Maybe<Scalars["String"]["output"]>;
  readonly minInterpretationShort: Maybe<Scalars["String"]["output"]>;
  readonly minValue: Scalars["Float"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly norm: Maybe<Norm>;
  readonly scale: Scale;
  readonly scoreType: ScaleScorerType;
  readonly supportedSeverityCategories: ReadonlyArray<SeverityCategory>;
  readonly supportedTrends: ReadonlyArray<Trend>;
  readonly thresholdData: Maybe<ReadonlyArray<ScaleThreshold>>;
};

/** A mathematical operator, e.g. equals */
export enum Operator {
  /** Field is equal to the value */
  EQUAL = "EQUAL",
  /** Field is greater than the value */
  GREATER_THAN = "GREATER_THAN",
  /** Field is less than the value */
  LESS_THAN = "LESS_THAN",
}

export type Organization = {
  readonly __typename: "Organization";
  readonly children: ReadonlyArray<Organization>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["OrganizationId"]["output"];
  readonly identifiers: Maybe<ReadonlyArray<Identifier>>;
  readonly inTrialPeriod: Scalars["Boolean"]["output"];
  readonly integrationAllowCreateSessions: Maybe<Scalars["Boolean"]["output"]>;
  readonly integrationAutoCreateSessions: Maybe<Scalars["Boolean"]["output"]>;
  readonly integrationsDataSourceRecords: ReadonlyArray<DataSourceRecord>;
  readonly measurementAllowed: Scalars["Boolean"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly orgType: Maybe<OrganizationType>;
  readonly parent: Maybe<Organization>;
  readonly parentId: Maybe<Scalars["OrganizationId"]["output"]>;
  readonly practicingAttachedProviders: ProviderConnection;
  readonly providerOrganizations: ProviderOrganizationConnection;
  readonly providers: ProviderConnection;
  readonly shortname: Scalars["String"]["output"];
  readonly subtree: Maybe<ReadonlyArray<Organization>>;
  readonly timezone: Maybe<Scalars["String"]["output"]>;
};

export type OrganizationpracticingAttachedProvidersArgs = {
  active: InputMaybe<Scalars["Boolean"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  defaultRelationship: InputMaybe<ReadonlyArray<CareEpisodeProviderRelationship>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  measurementAllowed: InputMaybe<Scalars["Boolean"]["input"]>;
  practicing: InputMaybe<Scalars["Boolean"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<ProviderSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

export type OrganizationproviderOrganizationsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<ProviderOrganizationSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

export type OrganizationprovidersArgs = {
  active: InputMaybe<Scalars["Boolean"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  defaultRelationship: InputMaybe<ReadonlyArray<CareEpisodeProviderRelationship>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  measurementAllowed: InputMaybe<Scalars["Boolean"]["input"]>;
  practicing: InputMaybe<Scalars["Boolean"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<ProviderSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The connection type for Organization. */
export type OrganizationConnection = {
  readonly __typename: "OrganizationConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<OrganizationEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Organization>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type OrganizationEdge = {
  readonly __typename: "OrganizationEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Organization;
};

/** Filter on organizations including an option for only your own */
export type OrganizationFilter =
  /** Show all organizations */
  | {
      readonly allOrganizations: Scalars["Boolean"]["input"];
      readonly myOrganizations?: never;
      readonly organizationIds?: never;
    } /** Show only my organizations */
  | {
      readonly allOrganizations?: never;
      readonly myOrganizations: Scalars["Boolean"]["input"];
      readonly organizationIds?: never;
    } /** Show the specified organizations */
  | {
      readonly allOrganizations?: never;
      readonly myOrganizations?: never;
      readonly organizationIds: ReadonlyArray<Scalars["OrganizationId"]["input"]>;
    };

export enum OrganizationSortParameter {
  /** Id */
  ID = "ID",
  /** Measurement Allowed */
  MEASUREMENT_ALLOWED = "MEASUREMENT_ALLOWED",
  /** Name */
  NAME = "NAME",
}

/** Type of an organization, see http://hl7.org/fhir/R4/valueset-organization-type.html */
export enum OrganizationType {
  /** An organization that is identified as a Pharmaceutical/Clinical Research Sponsor. */
  CLINICAL_RESEARCH_SPONSOR = "CLINICAL_RESEARCH_SPONSOR",
  /** An un-incorporated community group. */
  COMMUNITY_GROUP = "COMMUNITY_GROUP",
  /** An educational institution that provides education or research facilities. */
  EDUCATIONAL_INSTITUTE = "EDUCATIONAL_INSTITUTE",
  /**
   * A political body, often used when including organization records for
   * government bodies such as a Federal Government, State or Local Government.
   */
  GOVERNMENT = "GOVERNMENT",
  /** An organization that provides healthcare services. */
  HEALTHCARE_PROVIDER = "HEALTHCARE_PROVIDER",
  /** A department or ward within a hospital (Generally is not applicable to top level organizations) */
  HOSPITAL_DEPARTMENT = "HOSPITAL_DEPARTMENT",
  /** A company that provides insurance to its subscribers that may include healthcare related policies. */
  INSURANCE_COMPANY = "INSURANCE_COMPANY",
  /** or Corporation  An organization that is a registered business or corporation but not identified by other types. */
  NON_HEALTHCARE_BUSINESS = "NON_HEALTHCARE_BUSINESS",
  /**
   * An organizational team is usually a grouping of practitioners that perform a
   * specific function within an organization (which could be a top level
   * organization, or a department).
   */
  ORGANIZATIONAL_TEAM = "ORGANIZATIONAL_TEAM",
  /** Other type of organization not already specified. */
  OTHER = "OTHER",
  /**
   * A company, charity, or governmental organization, which processes claims
   * and/or issues payments to providers on behalf of patients or groups of patients.
   */
  PAYER = "PAYER",
  /** An organization that is identified as a part of a religious institution. */
  RELIGIOUS_INSTITUTION = "RELIGIOUS_INSTITUTION",
}

/** Autogenerated input type of OrganizationUpdateMeasurementAllowed */
export type OrganizationUpdateMeasurementAllowedInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly measurementAllowed: Scalars["Boolean"]["input"];
  readonly organizationId: Scalars["OrganizationId"]["input"];
};

/** Autogenerated return type of OrganizationUpdateMeasurementAllowed. */
export type OrganizationUpdateMeasurementAllowedPayload = {
  readonly __typename: "OrganizationUpdateMeasurementAllowedPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<OrganizationUpdateMeasurementAllowedResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type OrganizationUpdateMeasurementAllowedResult = {
  readonly __typename: "OrganizationUpdateMeasurementAllowedResult";
  readonly measurementAllowed: Scalars["Boolean"]["output"];
  readonly organizationId: Scalars["OrganizationId"]["output"];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  readonly __typename: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  readonly endCursor: Maybe<Scalars["String"]["output"]>;
  /** When paginating forwards, are there more items? */
  readonly hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  readonly hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  readonly startCursor: Maybe<Scalars["String"]["output"]>;
};

export type Panel = {
  readonly __typename: "Panel";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["PanelId"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly status: PanelStatus;
  readonly teamMembers: ReadonlyArray<PanelTeamMember>;
};

/** The connection type for Panel. */
export type PanelConnection = {
  readonly __typename: "PanelConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<PanelEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Panel>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type PanelEdge = {
  readonly __typename: "PanelEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Panel;
};

/** Filter on panels including an option for only yours */
export type PanelFilter =
  /** Show all panels */
  | {
      readonly all: Scalars["Boolean"]["input"];
      readonly mine?: never;
      readonly panelIds?: never;
    } /** Show only my active panels */
  | {
      readonly all?: never;
      readonly mine: Scalars["Boolean"]["input"];
      readonly panelIds?: never;
    } /** Show the specified panels */
  | {
      readonly all?: never;
      readonly mine?: never;
      readonly panelIds: ReadonlyArray<Scalars["PanelId"]["input"]>;
    };

export enum PanelSortParameter {
  /** Id */
  ID = "ID",
}

/** What state a panel is in */
export enum PanelStatus {
  /** This panel is treating patients */
  ACTIVE = "ACTIVE",
  /** This panel is no longer treating patients */
  RETIRED = "RETIRED",
}

export type PanelTeamMember = {
  readonly __typename: "PanelTeamMember";
  readonly endDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly function: PanelTeamMemberFunction;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["PanelTeamMemberId"]["output"];
  readonly panel: Panel;
  readonly provider: Provider;
  readonly startDate: Scalars["ISO8601DateTime"]["output"];
  readonly status: PanelTeamMemberStatus;
};

/** The connection type for PanelTeamMember. */
export type PanelTeamMemberConnection = {
  readonly __typename: "PanelTeamMemberConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<PanelTeamMemberEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<PanelTeamMember>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type PanelTeamMemberEdge = {
  readonly __typename: "PanelTeamMemberEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: PanelTeamMember;
};

/** What purpose a team member plays on a panel */
export enum PanelTeamMemberFunction {
  /** Behavioral Health Consultant */
  BEHAVIOR_HEALTH_CONSULTANT = "BEHAVIOR_HEALTH_CONSULTANT",
  /** Care Manager */
  CARE_MANAGER = "CARE_MANAGER",
  /** Consulting Psychologist */
  CONSULTING_PSYCH = "CONSULTING_PSYCH",
  /** Primary Care Provider */
  PRIMARY_CARE = "PRIMARY_CARE",
}

export enum PanelTeamMemberSortParameter {
  /** Id */
  ID = "ID",
}

/** Whether a team member is active on a panel or not */
export enum PanelTeamMemberStatus {
  /** Active */
  ACTIVE = "ACTIVE",
  /** Deactivated */
  DEACTIVATED = "DEACTIVATED",
  /** Future */
  FUTURE = "FUTURE",
}

export type Participant = {
  readonly email: Maybe<Scalars["String"]["output"]>;
  readonly firstName: Maybe<Scalars["String"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly lastName: Maybe<Scalars["String"]["output"]>;
  readonly name: Scalars["String"]["output"];
  readonly participantId: Scalars["ParticipantId"]["output"];
  readonly phoneNumber: Maybe<Scalars["String"]["output"]>;
  readonly relationship: ParticipantRelationship;
  readonly user: User;
};

export enum ParticipantRelationship {
  /** Child */
  CHILD = "CHILD",
  /** Father */
  FATHER = "FATHER",
  /** Guardian/caregiver */
  GUARDIAN = "GUARDIAN",
  /** Mother */
  MOTHER = "MOTHER",
  /** Other */
  OTHER = "OTHER",
  /** Patient */
  PATIENT = "PATIENT",
  /** Provider on a care team */
  PROVIDER = "PROVIDER",
  /** Teacher */
  TEACHER = "TEACHER",
}

export type Patient = Participant & {
  readonly __typename: "Patient";
  readonly activeCollaborativeCareCareEpisode: Maybe<CareEpisode>;
  readonly autoCreateSessions: Maybe<Scalars["Boolean"]["output"]>;
  readonly careEpisodes: CareEpisodeConnection;
  readonly careManager: Maybe<Provider>;
  readonly careUnitType: Maybe<CareUnitType>;
  readonly caseManager: Maybe<Scalars["String"]["output"]>;
  readonly clientParticipantUsers: ReadonlyArray<User>;
  readonly clientParticipants: ReadonlyArray<Participant>;
  readonly cocmPrimaryCareProvider: Maybe<Provider>;
  readonly collaborativeCareEnrollments: EnrollmentConnection;
  readonly collaborativeCareTasks: TaskConnection;
  readonly collaborativeCareTimeEntryLogs: TimeEntryLogConnection;
  readonly currentStats: Maybe<CurrentStats>;
  readonly defaultOrganization: Maybe<Organization>;
  readonly disabilityCategories: Maybe<Scalars["String"]["output"]>;
  readonly dob: Maybe<Scalars["ISO8601Date"]["output"]>;
  readonly email: Maybe<Scalars["String"]["output"]>;
  readonly enableNotifications: Scalars["Boolean"]["output"];
  readonly ethnicity: Maybe<Scalars["String"]["output"]>;
  readonly firstName: Maybe<Scalars["String"]["output"]>;
  readonly gender: Maybe<Gender>;
  readonly genderIdentity: Maybe<Scalars["String"]["output"]>;
  readonly genderIdentityFhir: Maybe<GenderIdentity>;
  readonly genderIdentityFhirSource: Maybe<FieldSourceEnum>;
  readonly genderIdentitySource: Maybe<FieldSourceEnum>;
  readonly genderSource: Maybe<FieldSourceEnum>;
  readonly globalId: Scalars["ID"]["output"];
  readonly grade: Maybe<Scalars["String"]["output"]>;
  readonly groupName: Maybe<Scalars["String"]["output"]>;
  readonly has504Plan: Maybe<Scalars["String"]["output"]>;
  readonly hasCompletedAssessment: Scalars["Boolean"]["output"];
  readonly hasIep: Maybe<Scalars["String"]["output"]>;
  readonly homeLanguage: Maybe<Scalars["String"]["output"]>;
  readonly homeroomTeacher: Maybe<Scalars["String"]["output"]>;
  readonly id: Scalars["PatientId"]["output"];
  readonly identifiers: Maybe<ReadonlyArray<Identifier>>;
  readonly integrationsDataSourceRecords: ReadonlyArray<DataSourceRecord>;
  readonly isProvider: Maybe<Scalars["Boolean"]["output"]>;
  readonly isTest: Scalars["Boolean"]["output"];
  readonly lastName: Maybe<Scalars["String"]["output"]>;
  readonly lastSsn: Maybe<Scalars["String"]["output"]>;
  readonly latestCareEpisode: Maybe<CareEpisode>;
  readonly learningCommunity: Maybe<Scalars["String"]["output"]>;
  readonly legalFirstName: Maybe<Scalars["String"]["output"]>;
  readonly legalLastName: Maybe<Scalars["String"]["output"]>;
  readonly legalName: Maybe<Scalars["String"]["output"]>;
  readonly lowIncome: Maybe<Scalars["String"]["output"]>;
  readonly maritalStatus: Maybe<Scalars["String"]["output"]>;
  readonly mllStatus: Maybe<Scalars["String"]["output"]>;
  readonly mrn: Maybe<Scalars["String"]["output"]>;
  readonly name: Scalars["String"]["output"];
  readonly nameLastFirst: Scalars["String"]["output"];
  readonly note: Maybe<Scalars["String"]["output"]>;
  readonly notificationPreference: Maybe<NotificationPreference>;
  readonly notificationPreferenceWithDefault: NotificationPreference;
  readonly participantId: Scalars["ParticipantId"]["output"];
  readonly patientFlags: ReadonlyArray<PatientFlag>;
  readonly patientQualityIssues: ReadonlyArray<PatientQualityIssue>;
  readonly patientSessions: PatientSessionConnection;
  readonly phoneNumber: Maybe<Scalars["String"]["output"]>;
  readonly pipbhcPatientConditions: ReadonlyArray<PipbhcPatientCondition>;
  readonly pipbhcTreatmentGoalReports: ReadonlyArray<PipbhcTreatmentGoalReport>;
  readonly pipbhcTreatmentGoals: ReadonlyArray<PipbhcTreatmentGoal>;
  readonly preferredFirstName: Maybe<Scalars["String"]["output"]>;
  readonly preferredLastName: Maybe<Scalars["String"]["output"]>;
  readonly preferredName: Maybe<Scalars["String"]["output"]>;
  readonly preferredPronouns: Maybe<Scalars["String"]["output"]>;
  readonly primaryLanguage: Maybe<Scalars["String"]["output"]>;
  readonly provider: Maybe<Provider>;
  readonly race: Maybe<Scalars["String"]["output"]>;
  readonly relatedPeople: ReadonlyArray<RelatedPerson>;
  readonly relationship: ParticipantRelationship;
  readonly researchOther: Maybe<Scalars["String"]["output"]>;
  readonly researchOtherDiagnoses: Maybe<Scalars["String"]["output"]>;
  readonly researchPrimaryDiagnosis: Maybe<Scalars["String"]["output"]>;
  readonly schedulingAppointments: AppointmentConnection;
  readonly source: Maybe<SourceEnum>;
  readonly state: Maybe<Scalars["String"]["output"]>;
  readonly supportsUser: Scalars["Boolean"]["output"];
  readonly user: User;
  readonly veteranStatus: Maybe<Scalars["String"]["output"]>;
  readonly zipCode: Maybe<Scalars["String"]["output"]>;
};

export type PatientcareEpisodesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  flagIds: InputMaybe<ReadonlyArray<Scalars["FlagId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  measurementAllowed: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationId: InputMaybe<ReadonlyArray<Scalars["OrganizationId"]["input"]>>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  periodEnd: InputMaybe<DateRangeInput>;
  periodStart: InputMaybe<DateRangeInput>;
  sortBy: InputMaybe<CareEpisodeSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<CareEpisodeStatus>>;
};

export type PatientcollaborativeCareEnrollmentsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  availableForNode: InputMaybe<EntityTreeNodeParams>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<EnrollmentSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<EnrollmentStatus>;
};

export type PatientcollaborativeCareTasksArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  assignedTo: InputMaybe<ProviderFilter>;
  before: InputMaybe<Scalars["String"]["input"]>;
  enrollmentId: InputMaybe<Scalars["EnrollmentId"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<TaskSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<TaskStatus>;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PatientcollaborativeCareTimeEntryLogsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  deleted: InputMaybe<Scalars["Boolean"]["input"]>;
  endTime: InputMaybe<DateRangeInput>;
  enrollmentMonthId: InputMaybe<ReadonlyArray<Scalars["EnrollmentMonthId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<TimeEntryLogSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  startTime: InputMaybe<DateRangeInput>;
  taskId: InputMaybe<Scalars["TaskId"]["input"]>;
};

export type PatientpatientSessionsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<PatientSessionSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<PatientSessionStatus>>;
  targetDate: InputMaybe<DateRangeInput>;
};

export type PatientschedulingAppointmentsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careEpisodeId: InputMaybe<Scalars["CareEpisodeId"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  flags: InputMaybe<ReadonlyArray<Scalars["FlagId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  measurementStatus: InputMaybe<ReadonlyArray<AppointmentMeasurementStatus>>;
  patientFlagId: InputMaybe<ReadonlyArray<Scalars["PatientFlagId"]["input"]>>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  provider: InputMaybe<ProviderFilter>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<AppointmentSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  startDateAfter: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  startDateBefore: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  status: InputMaybe<ReadonlyArray<AppointmentStatus>>;
  testClient: InputMaybe<Scalars["Boolean"]["input"]>;
  unit: InputMaybe<OrganizationFilter>;
};

export type PatientCondition = {
  readonly __typename: "PatientCondition";
  readonly abatementDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["PatientConditionId"]["output"];
  readonly instituteCondition: InstituteCondition;
  readonly onsetDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly patient: Patient;
  readonly status: PatientConditionStatus;
};

/** The connection type for PatientCondition. */
export type PatientConditionConnection = {
  readonly __typename: "PatientConditionConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<PatientConditionEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<PatientCondition>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type PatientConditionEdge = {
  readonly __typename: "PatientConditionEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: PatientCondition;
};

export enum PatientConditionSortParameter {
  /** Id */
  ID = "ID",
}

export enum PatientConditionStatus {
  /** Condition is active */
  ACTIVE = "ACTIVE",
  /** Condition is inactive */
  INACTIVE = "INACTIVE",
  /** Condition is active and has recurred */
  RECURRENCE = "RECURRENCE",
  /** Condition is in remission */
  REMISSION = "REMISSION",
  /** Condition is resolved */
  RESOLVED = "RESOLVED",
}

/** The connection type for Patient. */
export type PatientConnection = {
  readonly __typename: "PatientConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<PatientEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Patient>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type PatientEdge = {
  readonly __typename: "PatientEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Patient;
};

/** Filter on patients including an option for only your own */
export type PatientFilter =
  /** Show all patients */
  | {
      readonly allPatients: Scalars["Boolean"]["input"];
      readonly myPatients?: never;
      readonly patientIds?: never;
    } /** Show only my patients */
  | {
      readonly allPatients?: never;
      readonly myPatients: Scalars["Boolean"]["input"];
      readonly patientIds?: never;
    } /** Show the specified patients */
  | {
      readonly allPatients?: never;
      readonly myPatients?: never;
      readonly patientIds: ReadonlyArray<Scalars["PatientId"]["input"]>;
    };

export type PatientFlag = {
  readonly __typename: "PatientFlag";
  readonly active: Scalars["Boolean"]["output"];
  readonly eventDate: Scalars["ISO8601DateTime"]["output"];
  readonly flag: Flag;
  readonly flagId: Scalars["FlagId"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["PatientFlagId"]["output"];
  readonly notes: Maybe<Scalars["String"]["output"]>;
  readonly patient: Patient;
  readonly user: Maybe<User>;
};

/** The connection type for PatientFlag. */
export type PatientFlagConnection = {
  readonly __typename: "PatientFlagConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<PatientFlagEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<PatientFlag>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type PatientFlagEdge = {
  readonly __typename: "PatientFlagEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: PatientFlag;
};

export enum PatientFlagSortParameter {
  /** Id */
  ID = "ID",
}

export type PatientMinutesTarget = {
  readonly __typename: "PatientMinutesTarget";
  readonly ceiling: Scalars["Int"]["output"];
  readonly enrollmentMonthEnd: Maybe<Scalars["Int"]["output"]>;
  readonly enrollmentMonthStart: Scalars["Int"]["output"];
  readonly floor: Scalars["Int"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["PatientMinutesTargetId"]["output"];
};

/** The connection type for PatientMinutesTarget. */
export type PatientMinutesTargetConnection = {
  readonly __typename: "PatientMinutesTargetConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<PatientMinutesTargetEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<PatientMinutesTarget>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type PatientMinutesTargetEdge = {
  readonly __typename: "PatientMinutesTargetEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: PatientMinutesTarget;
};

export enum PatientMinutesTargetSortParameter {
  /** Id */
  ID = "ID",
}

export type PatientQualityIssue = {
  readonly __typename: "PatientQualityIssue";
  readonly createdAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["PatientQualityIssueId"]["output"];
  readonly issue: PatientQualityIssueType;
  readonly notes: Maybe<Scalars["String"]["output"]>;
  readonly patient: Patient;
  readonly provider: Maybe<Provider>;
  readonly relatedPerson: Maybe<RelatedPerson>;
  readonly resolvedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly status: PatientQualityIssueStatus;
  readonly statusActiveUntil: Maybe<Scalars["ISO8601DateTime"]["output"]>;
};

/** The connection type for PatientQualityIssue. */
export type PatientQualityIssueConnection = {
  readonly __typename: "PatientQualityIssueConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<PatientQualityIssueEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<PatientQualityIssue>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type PatientQualityIssueEdge = {
  readonly __typename: "PatientQualityIssueEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: PatientQualityIssue;
};

/** Set of issues and statuses for patient quality issues */
export type PatientQualityIssueFilter = {
  /** What kind of issues */
  readonly issues?: InputMaybe<ReadonlyArray<PatientQualityIssueType>>;
  /** What status of issues */
  readonly statuses?: InputMaybe<ReadonlyArray<PatientQualityIssueStatus>>;
};

export enum PatientQualityIssueSortParameter {
  /** Created at */
  CREATED_AT = "CREATED_AT",
  /** Id */
  ID = "ID",
  NAME = "NAME",
  /** Patient id */
  PATIENT_ID = "PATIENT_ID",
}

/** The status of the quality issue */
export enum PatientQualityIssueStatus {
  /** It's been marked as fixed in the EHR, but hasn't come through to us yet. */
  FIXED_IN_EHR = "FIXED_IN_EHR",
  /** This is not an issue */
  NOT_AN_ISSUE = "NOT_AN_ISSUE",
  /** This patient is not in treatment and therefore the flag was cleared */
  OUT_OF_TREATMENT = "OUT_OF_TREATMENT",
  /** All is fixed */
  RESOLVED = "RESOLVED",
  /** Not resolved but come back later */
  SNOOZE = "SNOOZE",
  /** It hasn't been looked at */
  UNRESOLVED = "UNRESOLVED",
  /** This IS an issue, but it's too difficult to fix */
  WONT_FIX = "WONT_FIX",
}

/** The type of issue the quality check has found */
export enum PatientQualityIssueType {
  /** Age may be incorrect */
  AGE_INCORRECT = "AGE_INCORRECT",
  /** Caregiver has forbidden contact */
  CAREGIVER_CONTACT_FORBIDDEN = "CAREGIVER_CONTACT_FORBIDDEN",
  /** Caregiver has never opened an assessment */
  CAREGIVER_NEVER_OPENED = "CAREGIVER_NEVER_OPENED",
  /** Caregiver has no contact info */
  CAREGIVER_NO_CONTACT_INFO = "CAREGIVER_NO_CONTACT_INFO",
  /** Patient and caregiver share contact info */
  CAREGIVER_SHARES_CONTACT_INFO = "CAREGIVER_SHARES_CONTACT_INFO",
  /** No assessments have been taken in a while */
  NOT_MEASURED_FOR_EXTENDED_PERIOD = "NOT_MEASURED_FOR_EXTENDED_PERIOD",
  /** Patient has never opened an assessment */
  PATIENT_NEVER_OPENED = "PATIENT_NEVER_OPENED",
  /** Patient has forbidden contact */
  PATIENT_OVER_11_CONTACT_FORBIDDEN = "PATIENT_OVER_11_CONTACT_FORBIDDEN",
  /** Patient has no contact info */
  PATIENT_OVER_11_NO_CONTACT_INFO = "PATIENT_OVER_11_NO_CONTACT_INFO",
  /** Child patient has no caregiver */
  PATIENT_UNDER_18_NO_CAREGIVER = "PATIENT_UNDER_18_NO_CAREGIVER",
}

export type PatientSession = {
  readonly __typename: "PatientSession";
  readonly assessmentReport: Maybe<Report>;
  /** Is this measurement subject to automatic measure selection */
  readonly autoplan: Scalars["Boolean"]["output"];
  /** The episode of care associated with this measurement */
  readonly careEpisode: CareEpisode;
  /** The measurement plans associated with this session */
  readonly entityMeasurementPlans: ReadonlyArray<EntityMeasurementPlan>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["PatientSessionId"]["output"];
  /** Is a report available for this measurement? */
  readonly isReportAvailable: Scalars["Boolean"]["output"];
  /** Is the report complete for this measurement? */
  readonly isReportComplete: Scalars["Boolean"]["output"];
  /** The last time the planning system was run on this session */
  readonly lastPlannedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly managedBy: ManagedBy;
  readonly maxPlanningPhase: Maybe<MeasurementPlanningPhase>;
  readonly patient: Patient;
  readonly patientId: Scalars["PatientId"]["output"];
  /** The provider associated with this measurement */
  readonly provider: Maybe<Provider>;
  /** Whether scales have been planned yet for this session */
  readonly scalesPlanned: Maybe<Scalars["Boolean"]["output"]>;
  /** The recurrence associated with this session */
  readonly schedulingRecurrence: Maybe<Recurrence>;
  readonly sessionNumber: Maybe<Scalars["Int"]["output"]>;
  readonly status: PatientSessionStatus;
  readonly targetDate: Scalars["ISO8601DateTime"]["output"];
};

/** The connection type for PatientSession. */
export type PatientSessionConnection = {
  readonly __typename: "PatientSessionConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<PatientSessionEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<PatientSession>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type PatientSessionEdge = {
  readonly __typename: "PatientSessionEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: PatientSession;
};

export type PatientSessionPlanningDetail = {
  readonly __typename: "PatientSessionPlanningDetail";
  readonly planningDecision: ReportScalePlanningDecision;
  readonly planningReason: Scalars["String"]["output"];
  readonly scaleId: Scalars["ScaleId"]["output"];
};

export enum PatientSessionSortParameter {
  /** Id */
  ID = "ID",
  /** Target date */
  TARGETDATE = "TARGETDATE",
}

export enum PatientSessionStatus {
  /** Measurement In Progress */
  ASSESSMENT_IN_PROGRESS = "ASSESSMENT_IN_PROGRESS",
  /** Notifications have been sent and we are waiting for input */
  AWAITING_ASSESSMENT = "AWAITING_ASSESSMENT",
  /** The measurement has been canceled and is not necessary */
  CANCELED = "CANCELED",
  /** The measurement was not taken by the participant */
  CLOSED = "CLOSED",
  /** The report is complete and has been viewed by the provider */
  COMPLETE = "COMPLETE",
  /** All invitations have been completed */
  FEEDBACKCOMPLETE = "FEEDBACKCOMPLETE",
  /** At least one participant has started and there is data available */
  FEEDBACK_AVAILABLE = "FEEDBACK_AVAILABLE",
  /** New */
  NEW = "NEW",
  /** The appointment attached to the session was a noshow */
  NOSHOW = "NOSHOW",
  /** The session did not send any measurements */
  NOT_MEASURED = "NOT_MEASURED",
  /** UNUSED: The report has been previewed but not fully viewed */
  REPORT_PREVIEWED = "REPORT_PREVIEWED",
  /** The report has been viewed but not all feedback has been gathered yet */
  REPORT_VIEWED = "REPORT_VIEWED",
  /** An appointment is present but no notifications have been sent */
  SCHEDULED = "SCHEDULED",
}

export enum PatientSortParameter {
  /** Date of Birth */
  DOB = "DOB",
  /** Enrolled At */
  ENROLLEDAT = "ENROLLEDAT",
  /** First name */
  FIRSTNAME = "FIRSTNAME",
  /** Gender */
  GENDER = "GENDER",
  /** Id */
  ID = "ID",
  /** Last name */
  LASTNAME = "LASTNAME",
  /** MRN */
  MRN = "MRN",
  NAME_LAST_FIRST = "NAME_LAST_FIRST",
}

export type PipbhcExternalAppointment = {
  readonly __typename: "PipbhcExternalAppointment";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["PipbhcExternalAppointmentId"]["output"];
  readonly identifiers: Maybe<ReadonlyArray<Identifier>>;
  readonly minutesDuration: Maybe<Scalars["Int"]["output"]>;
  readonly patient: Patient;
  readonly patientId: Scalars["PatientId"]["output"];
  readonly providerName: Maybe<Scalars["String"]["output"]>;
  readonly startDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly status: AppointmentStatus;
};

export type PipbhcPatientCondition = {
  readonly __typename: "PipbhcPatientCondition";
  readonly abatementDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly condition: Condition;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["PipbhcPatientConditionId"]["output"];
  readonly onsetDate: Scalars["ISO8601DateTime"]["output"];
  readonly patient: Patient;
  readonly status: PipbhcPatientConditionStatus;
};

/** The status of the patient condition */
export enum PipbhcPatientConditionStatus {
  /** Active */
  ACTIVE = "ACTIVE",
  /** Inactive */
  INACTIVE = "INACTIVE",
  /** Recurrence */
  RECURRENCE = "RECURRENCE",
  /** Remission */
  REMISSION = "REMISSION",
  /** Resolved */
  RESOLVED = "RESOLVED",
}

export type PipbhcTreatmentGoal = {
  readonly __typename: "PipbhcTreatmentGoal";
  readonly globalId: Scalars["ID"]["output"];
  readonly goalText: Scalars["String"]["output"];
  readonly id: Scalars["PipbhcTreatmentGoalId"]["output"];
  readonly note: Maybe<Scalars["String"]["output"]>;
  readonly patient: Patient;
  readonly pipbhcTreatmentGoalAnswers: ReadonlyArray<PipbhcTreatmentGoalAnswer>;
  readonly pipbhcTreatmentGoalReports: ReadonlyArray<PipbhcTreatmentGoalReport>;
  readonly status: PipbhcTreatmentGoalStatus;
};

export type PipbhcTreatmentGoalAnswer = {
  readonly __typename: "PipbhcTreatmentGoalAnswer";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["PipbhcTreatmentGoalAnswerId"]["output"];
  readonly note: Maybe<Scalars["String"]["output"]>;
  readonly pipbhcTreatmentGoal: PipbhcTreatmentGoal;
  readonly pipbhcTreatmentGoalReport: PipbhcTreatmentGoalReport;
  readonly value: Maybe<Scalars["Int"]["output"]>;
};

/** The connection type for PipbhcTreatmentGoal. */
export type PipbhcTreatmentGoalConnection = {
  readonly __typename: "PipbhcTreatmentGoalConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<PipbhcTreatmentGoalEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<PipbhcTreatmentGoal>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type PipbhcTreatmentGoalEdge = {
  readonly __typename: "PipbhcTreatmentGoalEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: PipbhcTreatmentGoal;
};

export type PipbhcTreatmentGoalReport = {
  readonly __typename: "PipbhcTreatmentGoalReport";
  readonly effectiveDate: Scalars["ISO8601DateTime"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["PipbhcTreatmentGoalReportId"]["output"];
  readonly note: Maybe<Scalars["String"]["output"]>;
  readonly pipbhcTreatmentGoalAnswers: ReadonlyArray<PipbhcTreatmentGoalAnswer>;
};

/** The connection type for PipbhcTreatmentGoalReport. */
export type PipbhcTreatmentGoalReportConnection = {
  readonly __typename: "PipbhcTreatmentGoalReportConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<PipbhcTreatmentGoalReportEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<PipbhcTreatmentGoalReport>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type PipbhcTreatmentGoalReportEdge = {
  readonly __typename: "PipbhcTreatmentGoalReportEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: PipbhcTreatmentGoalReport;
};

export enum PipbhcTreatmentGoalReportSortParameter {
  /** The effective date of the report */
  EFFECTIVEDATE = "EFFECTIVEDATE",
  /** Id */
  ID = "ID",
}

export enum PipbhcTreatmentGoalSortParameter {
  /** Id */
  ID = "ID",
}

/** The status of goal */
export enum PipbhcTreatmentGoalStatus {
  /** Active */
  ACTIVE = "ACTIVE",
  /** Canceled */
  CANCELED = "CANCELED",
  /** Completed */
  COMPLETED = "COMPLETED",
  /** Entered in error, no data entered */
  ENTERED_IN_ERROR = "ENTERED_IN_ERROR",
  /** Created but never answered */
  NEW = "NEW",
  /** Paused */
  PAUSED = "PAUSED",
}

export type Population = {
  readonly __typename: "Population";
  readonly count: Maybe<Scalars["Int"]["output"]>;
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["PopulationId"]["output"];
  readonly mnemonic: Scalars["String"]["output"];
  readonly name: Scalars["String"]["output"];
};

export type Provider = Participant & {
  readonly __typename: "Provider";
  readonly defaultRelationship: Maybe<CareEpisodeProviderRelationship>;
  readonly email: Maybe<Scalars["String"]["output"]>;
  readonly firstName: Maybe<Scalars["String"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ProviderId"]["output"];
  readonly integrationsDataSourceRecords: ReadonlyArray<DataSourceRecord>;
  readonly lastName: Maybe<Scalars["String"]["output"]>;
  readonly measurementAllowed: Scalars["Boolean"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly npiNumber: Maybe<Scalars["String"]["output"]>;
  readonly orderedName: Scalars["String"]["output"];
  readonly organizations: ReadonlyArray<Organization>;
  readonly participantId: Scalars["ParticipantId"]["output"];
  readonly phoneNumber: Maybe<Scalars["String"]["output"]>;
  readonly practicing: Maybe<Scalars["Boolean"]["output"]>;
  readonly providerOrganizations: ReadonlyArray<ProviderOrganization>;
  readonly relationship: ParticipantRelationship;
  readonly schedulingAppointments: AppointmentConnection;
  readonly status: Scalars["String"]["output"];
  readonly suffix: Maybe<Scalars["String"]["output"]>;
  readonly title: Maybe<Scalars["String"]["output"]>;
  readonly user: User;
};

export type ProviderschedulingAppointmentsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careEpisodeId: InputMaybe<Scalars["CareEpisodeId"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  flags: InputMaybe<ReadonlyArray<Scalars["FlagId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  measurementStatus: InputMaybe<ReadonlyArray<AppointmentMeasurementStatus>>;
  patientFlagId: InputMaybe<ReadonlyArray<Scalars["PatientFlagId"]["input"]>>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  provider: InputMaybe<ProviderFilter>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<AppointmentSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  startDateAfter: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  startDateBefore: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  status: InputMaybe<ReadonlyArray<AppointmentStatus>>;
  testClient: InputMaybe<Scalars["Boolean"]["input"]>;
  unit: InputMaybe<OrganizationFilter>;
};

/** The connection type for Provider. */
export type ProviderConnection = {
  readonly __typename: "ProviderConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ProviderEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Provider>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ProviderEdge = {
  readonly __typename: "ProviderEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Provider;
};

/** Filter on providers including an option for only yourself */
export type ProviderFilter =
  /** Show all providers */
  | {
      readonly allProviders: Scalars["Boolean"]["input"];
      readonly me?: never;
      readonly providerIds?: never;
    } /** Show only myself */
  | {
      readonly allProviders?: never;
      readonly me: Scalars["Boolean"]["input"];
      readonly providerIds?: never;
    } /** Show the specified providers */
  | {
      readonly allProviders?: never;
      readonly me?: never;
      readonly providerIds: ReadonlyArray<Scalars["ProviderId"]["input"]>;
    };

export type ProviderNotification = {
  readonly __typename: "ProviderNotification";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ProviderNotificationId"]["output"];
  readonly provider: Provider;
  readonly sentAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly triggeredWorkflow: TriggeredWorkflow;
};

/** The connection type for ProviderNotification. */
export type ProviderNotificationConnection = {
  readonly __typename: "ProviderNotificationConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ProviderNotificationEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<ProviderNotification>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ProviderNotificationEdge = {
  readonly __typename: "ProviderNotificationEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: ProviderNotification;
};

export enum ProviderNotificationSortParameter {
  /** Id */
  ID = "ID",
}

export type ProviderOrganization = {
  readonly __typename: "ProviderOrganization";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ProviderOrganizationId"]["output"];
  readonly organization: Organization;
  readonly provider: Provider;
};

/** The connection type for ProviderOrganization. */
export type ProviderOrganizationConnection = {
  readonly __typename: "ProviderOrganizationConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ProviderOrganizationEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<ProviderOrganization>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ProviderOrganizationEdge = {
  readonly __typename: "ProviderOrganizationEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: ProviderOrganization;
};

export enum ProviderOrganizationSortParameter {
  /** Id */
  ID = "ID",
}

export enum ProviderSortParameter {
  /** Id */
  ID = "ID",
  /** Measurement Allowed */
  MEASUREMENT_ALLOWED = "MEASUREMENT_ALLOWED",
  /** Full name in the format First Last */
  NAME = "NAME",
  /** Name in the format Last, First */
  ORDERED_NAME = "ORDERED_NAME",
}

/** Autogenerated input type of ProviderUpdateMeasurementAllowed */
export type ProviderUpdateMeasurementAllowedInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly measurementAllowed: Scalars["Boolean"]["input"];
  readonly providerId: Scalars["ProviderId"]["input"];
};

/** Autogenerated return type of ProviderUpdateMeasurementAllowed. */
export type ProviderUpdateMeasurementAllowedPayload = {
  readonly __typename: "ProviderUpdateMeasurementAllowedPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<ProviderUpdateMeasurementAllowedResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type ProviderUpdateMeasurementAllowedResult = {
  readonly __typename: "ProviderUpdateMeasurementAllowedResult";
  readonly measurementAllowed: Scalars["Boolean"]["output"];
  readonly providerId: Scalars["ProviderId"]["output"];
};

/** The query root of this schema */
export type Query = {
  readonly __typename: "Query";
  /** Find a assessment_administrable_scale by ID */
  readonly assessmentAdministrableScale: Maybe<AdministrableScale>;
  /** List assessment_administrable_scale objects */
  readonly assessmentAdministrableScales: Maybe<AdministrableScaleConnection>;
  readonly assessmentCareEpisodeClinicalAlertHistory: Maybe<CareEpisodeClinicalAlertHistory>;
  /** Find a assessment_care_episode_computed_value by ID */
  readonly assessmentCareEpisodeComputedValue: Maybe<CareEpisodeComputedValue>;
  /** List assessment_care_episode_computed_value objects */
  readonly assessmentCareEpisodeComputedValues: Maybe<CareEpisodeComputedValueConnection>;
  /** Find a assessment_care_episode_scale by ID */
  readonly assessmentCareEpisodeScale: Maybe<CareEpisodeScale>;
  readonly assessmentCareEpisodeScaleDetail: Maybe<CareEpisodeScaleDetail>;
  readonly assessmentCareEpisodeScaleGroupDetail: Maybe<CareEpisodeScaleGroupDetail>;
  readonly assessmentCareEpisodeScaleGroupReport: Maybe<CareEpisodeScaleGroupReport>;
  readonly assessmentCareEpisodeScaleReport: Maybe<CareEpisodeScaleReport>;
  /** List assessment_care_episode_scale_scorer_summary objects */
  readonly assessmentCareEpisodeScaleScorerSummaries: Maybe<CareEpisodeScaleScorerSummaryConnection>;
  /** Find a assessment_care_episode_scale_scorer_summary by ID */
  readonly assessmentCareEpisodeScaleScorerSummary: Maybe<CareEpisodeScaleScorerSummary>;
  readonly assessmentCareEpisodeScaleSummary: Maybe<CareEpisodeScaleSummary>;
  /** List assessment_care_episode_scale objects */
  readonly assessmentCareEpisodeScales: Maybe<CareEpisodeScaleConnection>;
  /** Find a assessment_computed_value by ID */
  readonly assessmentComputedValue: Maybe<ComputedValue>;
  /** List assessment_computed_value objects */
  readonly assessmentComputedValues: Maybe<ComputedValueConnection>;
  /** Find a assessment_construct by ID */
  readonly assessmentConstruct: Maybe<Construct>;
  /** List assessment_construct objects */
  readonly assessmentConstructs: Maybe<ConstructConnection>;
  /** Find a assessment_external_observation by ID */
  readonly assessmentExternalObservation: Maybe<ExternalObservation>;
  /** List assessment_external_observation objects */
  readonly assessmentExternalObservations: Maybe<ExternalObservationConnection>;
  /** Find a assessment_goal by ID */
  readonly assessmentGoal: Maybe<Goal>;
  /** List assessment_goal objects */
  readonly assessmentGoals: Maybe<GoalConnection>;
  /** Find a assessment_institute_scale by ID */
  readonly assessmentInstituteScale: Maybe<InstituteScale>;
  /** List assessment_institute_scale objects */
  readonly assessmentInstituteScales: Maybe<InstituteScaleConnection>;
  /** Find a assessment_invitation by ID */
  readonly assessmentInvitation: Maybe<Invitation>;
  /** List assessment_invitation objects */
  readonly assessmentInvitations: Maybe<InvitationConnection>;
  readonly assessmentLatestReportText: Maybe<ReportText>;
  /** Find a assessment_report by ID */
  readonly assessmentReport: Maybe<Report>;
  readonly assessmentReportProviderObligations: Maybe<ReportProviderObligations>;
  readonly assessmentReportText: Maybe<ReportText>;
  /** List assessment_report objects */
  readonly assessmentReports: Maybe<ReportConnection>;
  /** Find a assessment_scale by ID */
  readonly assessmentScale: Maybe<Scale>;
  /** Find a assessment_scale_group by ID */
  readonly assessmentScaleGroup: Maybe<ScaleGroup>;
  /** List assessment_scale_group objects */
  readonly assessmentScaleGroups: Maybe<ScaleGroupConnection>;
  /** Find a assessment_scale_scorer by ID */
  readonly assessmentScaleScorer: Maybe<ScaleScorer>;
  /** List assessment_scale_scorer objects */
  readonly assessmentScaleScorers: Maybe<ScaleScorerConnection>;
  readonly assessmentScaleScorersWithUsage: Maybe<ScaleScorerWithUsageConnection>;
  /** List assessment_scale objects */
  readonly assessmentScales: Maybe<ScaleConnection>;
  /** Find a billing_cocm_billing_algorithm by ID */
  readonly billingCocmBillingAlgorithm: Maybe<CocmBillingAlgorithm>;
  /** Find a billing_cocm_billing_algorithm_code by ID */
  readonly billingCocmBillingAlgorithmCode: Maybe<CocmBillingAlgorithmCode>;
  /** List billing_cocm_billing_algorithm_code objects */
  readonly billingCocmBillingAlgorithmCodes: Maybe<CocmBillingAlgorithmCodeConnection>;
  /** Find a billing_cocm_billing_algorithm_rule by ID */
  readonly billingCocmBillingAlgorithmRule: Maybe<CocmBillingAlgorithmRule>;
  /** List billing_cocm_billing_algorithm_rule objects */
  readonly billingCocmBillingAlgorithmRules: Maybe<CocmBillingAlgorithmRuleConnection>;
  /** List billing_cocm_billing_algorithm objects */
  readonly billingCocmBillingAlgorithms: Maybe<CocmBillingAlgorithmConnection>;
  readonly billingCocmEstimatedBillingStatsSummary: Maybe<CocmEstimatedBillingStatsSummary>;
  /** Find a billing_coverage by ID */
  readonly billingCoverage: Maybe<Coverage>;
  /** List billing_coverage objects */
  readonly billingCoverages: Maybe<CoverageConnection>;
  /** Find a billing_enrollment_month_billing_rule_result by ID */
  readonly billingEnrollmentMonthBillingRuleResult: Maybe<EnrollmentMonthBillingRuleResult>;
  /** Find a billing_enrollment_month_billing_rule_result_winner by ID */
  readonly billingEnrollmentMonthBillingRuleResultWinner: Maybe<EnrollmentMonthBillingRuleResultWinner>;
  /** List billing_enrollment_month_billing_rule_result_winner objects */
  readonly billingEnrollmentMonthBillingRuleResultWinners: Maybe<EnrollmentMonthBillingRuleResultWinnerConnection>;
  /** List billing_enrollment_month_billing_rule_result objects */
  readonly billingEnrollmentMonthBillingRuleResults: Maybe<EnrollmentMonthBillingRuleResultConnection>;
  readonly billingEstimatedBillingCumulativeEffort: Maybe<EstimatedBillingCumulativeEffort>;
  /** Find a business_event by ID */
  readonly businessEvent: Maybe<BusinessEvent>;
  /** List business_event objects */
  readonly businessEvents: Maybe<BusinessEventConnection>;
  /** Find a care_care_episode_treatment_track by ID */
  readonly careCareEpisodeTreatmentTrack: Maybe<CareEpisodeTreatmentTrack>;
  /** List care_care_episode_treatment_track objects */
  readonly careCareEpisodeTreatmentTracks: Maybe<CareEpisodeTreatmentTrackConnection>;
  /** Find a care_entity_measurement_plan by ID */
  readonly careEntityMeasurementPlan: Maybe<EntityMeasurementPlan>;
  /** List care_entity_measurement_plan objects */
  readonly careEntityMeasurementPlans: Maybe<EntityMeasurementPlanConnection>;
  /** Find a care_episode by ID */
  readonly careEpisode: Maybe<CareEpisode>;
  /** Find a care_episode_message by ID */
  readonly careEpisodeMessage: Maybe<CareEpisodeMessage>;
  /** List care_episode_message objects */
  readonly careEpisodeMessages: Maybe<CareEpisodeMessageConnection>;
  /** List care_episode objects */
  readonly careEpisodes: Maybe<CareEpisodeConnection>;
  readonly careFutureSessionMeasurementEstimate: Maybe<FutureSessionMeasurementEstimate>;
  /** Find a care_group by ID */
  readonly careGroup: Maybe<CareGroup>;
  /** List care_group objects */
  readonly careGroups: Maybe<CareGroupConnection>;
  /** Find a care_measurement_plan by ID */
  readonly careMeasurementPlan: Maybe<MeasurementPlan>;
  /** List care_measurement_plan objects */
  readonly careMeasurementPlans: Maybe<MeasurementPlanConnection>;
  /** Find a care_treatment_track by ID */
  readonly careTreatmentTrack: Maybe<TreatmentTrack>;
  /** List care_treatment_track objects */
  readonly careTreatmentTracks: Maybe<TreatmentTrackConnection>;
  /** Find a care_unit_type by ID */
  readonly careUnitType: Maybe<CareUnitType>;
  /** List care_unit_type objects */
  readonly careUnitTypes: Maybe<CareUnitTypeConnection>;
  readonly collaborativeCareAssessedPatientsStats: Maybe<ReadonlyArray<ChartData>>;
  /** Find a collaborative_care_care_episode_treatment_target by ID */
  readonly collaborativeCareCareEpisodeTreatmentTarget: Maybe<CareEpisodeTreatmentTarget>;
  /** List collaborative_care_care_episode_treatment_target objects */
  readonly collaborativeCareCareEpisodeTreatmentTargets: Maybe<CareEpisodeTreatmentTargetConnection>;
  readonly collaborativeCareCareManagers: Maybe<ReadonlyArray<Provider>>;
  /** Find a collaborative_care_case_consult by ID */
  readonly collaborativeCareCaseConsult: Maybe<CaseConsult>;
  /** List collaborative_care_case_consult objects */
  readonly collaborativeCareCaseConsults: Maybe<CaseConsultConnection>;
  readonly collaborativeCareCaseloadEfficiencyStats: Maybe<ReadonlyArray<ChartData>>;
  /** Find a collaborative_care_consult_meeting by ID */
  readonly collaborativeCareConsultMeeting: Maybe<ConsultMeeting>;
  /** Find a collaborative_care_consult_meeting_attendee by ID */
  readonly collaborativeCareConsultMeetingAttendee: Maybe<ConsultMeetingAttendee>;
  /** List collaborative_care_consult_meeting_attendee objects */
  readonly collaborativeCareConsultMeetingAttendees: Maybe<ConsultMeetingAttendeeConnection>;
  /** List collaborative_care_consult_meeting objects */
  readonly collaborativeCareConsultMeetings: Maybe<ConsultMeetingConnection>;
  /** Find a collaborative_care_consult_request by ID */
  readonly collaborativeCareConsultRequest: Maybe<ConsultRequest>;
  /** List collaborative_care_consult_request objects */
  readonly collaborativeCareConsultRequests: Maybe<ConsultRequestConnection>;
  readonly collaborativeCareDefaultPatientMinutesTargets: Maybe<ReadonlyArray<PatientMinutesTarget>>;
  /** Find a collaborative_care_enrollment by ID */
  readonly collaborativeCareEnrollment: Maybe<Enrollment>;
  readonly collaborativeCareEnrollmentAcuityStats: Maybe<ReadonlyArray<ChartData>>;
  readonly collaborativeCareEnrollmentBillingMonth: Maybe<ReadonlyArray<EnrollmentBillingMonth>>;
  /** Find a collaborative_care_enrollment_month by ID */
  readonly collaborativeCareEnrollmentMonth: Maybe<EnrollmentMonth>;
  /** List collaborative_care_enrollment_month objects */
  readonly collaborativeCareEnrollmentMonths: Maybe<EnrollmentMonthConnection>;
  /** List collaborative_care_enrollment objects */
  readonly collaborativeCareEnrollments: Maybe<EnrollmentConnection>;
  /** Find a collaborative_care_finding by ID */
  readonly collaborativeCareFinding: Maybe<Finding>;
  /** List collaborative_care_finding objects */
  readonly collaborativeCareFindings: Maybe<FindingConnection>;
  /** Find a collaborative_care_intake_session_scale by ID */
  readonly collaborativeCareIntakeSessionScale: Maybe<IntakeSessionScale>;
  /** List collaborative_care_intake_session_scale objects */
  readonly collaborativeCareIntakeSessionScales: Maybe<IntakeSessionScaleConnection>;
  readonly collaborativeCareMyActiveTimeEntryLog: Maybe<MyActiveTimeEntryLog>;
  readonly collaborativeCareMyDefaultTaskList: Maybe<TaskList>;
  readonly collaborativeCareNewEnrollmentStats: Maybe<ReadonlyArray<ChartData>>;
  /** Find a collaborative_care_note by ID */
  readonly collaborativeCareNote: Maybe<Note>;
  /** List collaborative_care_note objects */
  readonly collaborativeCareNotes: Maybe<NoteConnection>;
  /** Find a collaborative_care_panel by ID */
  readonly collaborativeCarePanel: Maybe<Panel>;
  /** Find a collaborative_care_panel_team_member by ID */
  readonly collaborativeCarePanelTeamMember: Maybe<PanelTeamMember>;
  /** List collaborative_care_panel_team_member objects */
  readonly collaborativeCarePanelTeamMembers: Maybe<PanelTeamMemberConnection>;
  /** List collaborative_care_panel objects */
  readonly collaborativeCarePanels: Maybe<PanelConnection>;
  /** Find a collaborative_care_patient_minutes_target by ID */
  readonly collaborativeCarePatientMinutesTarget: Maybe<PatientMinutesTarget>;
  /** List collaborative_care_patient_minutes_target objects */
  readonly collaborativeCarePatientMinutesTargets: Maybe<PatientMinutesTargetConnection>;
  readonly collaborativeCarePrimaryCareProviders: Maybe<ReadonlyArray<Provider>>;
  /** Find a collaborative_care_quick_text by ID */
  readonly collaborativeCareQuickText: Maybe<QuickText>;
  /** List collaborative_care_quick_text objects */
  readonly collaborativeCareQuickTexts: Maybe<QuickTextConnection>;
  readonly collaborativeCareSeverityBadge: Maybe<SeverityBadge>;
  /** Find a collaborative_care_task by ID */
  readonly collaborativeCareTask: Maybe<Task>;
  /** Find a collaborative_care_task_creation_log by ID */
  readonly collaborativeCareTaskCreationLog: Maybe<TaskCreationLog>;
  /** List collaborative_care_task_creation_log objects */
  readonly collaborativeCareTaskCreationLogs: Maybe<TaskCreationLogConnection>;
  /** Find a collaborative_care_task_list_item by ID */
  readonly collaborativeCareTaskListItem: Maybe<TaskListItem>;
  /** List collaborative_care_task_list_item objects */
  readonly collaborativeCareTaskListItems: Maybe<TaskListItemConnection>;
  /** Find a collaborative_care_task_template by ID */
  readonly collaborativeCareTaskTemplate: Maybe<TaskTemplate>;
  /** List collaborative_care_task_template objects */
  readonly collaborativeCareTaskTemplates: Maybe<TaskTemplateConnection>;
  /** List collaborative_care_task objects */
  readonly collaborativeCareTasks: Maybe<TaskConnection>;
  /** Find a collaborative_care_time_entry_log by ID */
  readonly collaborativeCareTimeEntryLog: Maybe<TimeEntryLog>;
  /** List collaborative_care_time_entry_log objects */
  readonly collaborativeCareTimeEntryLogs: Maybe<TimeEntryLogConnection>;
  /** Find a collaborative_care_treatment_target by ID */
  readonly collaborativeCareTreatmentTarget: Maybe<TreatmentTarget>;
  /** List collaborative_care_treatment_target objects */
  readonly collaborativeCareTreatmentTargets: Maybe<TreatmentTargetConnection>;
  /** Find a consent by ID */
  readonly consent: Maybe<Consent>;
  /** Find a consent_agreement by ID */
  readonly consentAgreement: Maybe<ConsentAgreement>;
  /** List consent_agreement objects */
  readonly consentAgreements: Maybe<ConsentAgreementConnection>;
  /** List consent objects */
  readonly consents: Maybe<ConsentConnection>;
  readonly currentInstitute: CurrentInstitute;
  /** Find a decision_support_decision_algorithm by ID */
  readonly decisionSupportDecisionAlgorithm: Maybe<DecisionAlgorithm>;
  /** List decision_support_decision_algorithm objects */
  readonly decisionSupportDecisionAlgorithms: Maybe<DecisionAlgorithmConnection>;
  /** Find a decision_support_entity_decision_algorithm by ID */
  readonly decisionSupportEntityDecisionAlgorithm: Maybe<EntityDecisionAlgorithm>;
  /** List decision_support_entity_decision_algorithm objects */
  readonly decisionSupportEntityDecisionAlgorithms: Maybe<EntityDecisionAlgorithmConnection>;
  readonly decisionSupportInstituteWorkflows: Maybe<InstituteWorkflowConnection>;
  /** Search by workflow title, instructions, email text, etc. */
  readonly decisionSupportMyProviderNotifications: ProviderNotificationConnection;
  /** Find a decision_support_provider_notification by ID */
  readonly decisionSupportProviderNotification: Maybe<ProviderNotification>;
  /** List decision_support_provider_notification objects */
  readonly decisionSupportProviderNotifications: Maybe<ProviderNotificationConnection>;
  /** Find a decision_support_triggered_decision_algorithm by ID */
  readonly decisionSupportTriggeredDecisionAlgorithm: Maybe<TriggeredDecisionAlgorithm>;
  /** List decision_support_triggered_decision_algorithm objects */
  readonly decisionSupportTriggeredDecisionAlgorithms: Maybe<TriggeredDecisionAlgorithmConnection>;
  /** Find a decision_support_triggered_workflow by ID */
  readonly decisionSupportTriggeredWorkflow: Maybe<TriggeredWorkflow>;
  /** Find a decision_support_triggered_workflow_action by ID */
  readonly decisionSupportTriggeredWorkflowAction: Maybe<TriggeredWorkflowAction>;
  /** Find a decision_support_triggered_workflow_action_log by ID */
  readonly decisionSupportTriggeredWorkflowActionLog: Maybe<TriggeredWorkflowActionLog>;
  /** List decision_support_triggered_workflow_action_log objects */
  readonly decisionSupportTriggeredWorkflowActionLogs: Maybe<TriggeredWorkflowActionLogConnection>;
  /** List decision_support_triggered_workflow_action objects */
  readonly decisionSupportTriggeredWorkflowActions: Maybe<TriggeredWorkflowActionConnection>;
  /** List decision_support_triggered_workflow objects */
  readonly decisionSupportTriggeredWorkflows: Maybe<TriggeredWorkflowConnection>;
  /** Find a decision_support_workflow by ID */
  readonly decisionSupportWorkflow: Maybe<Workflow>;
  /** Find a decision_support_workflow_action by ID */
  readonly decisionSupportWorkflowAction: Maybe<WorkflowAction>;
  /** List decision_support_workflow_action objects */
  readonly decisionSupportWorkflowActions: Maybe<WorkflowActionConnection>;
  /** List decision_support_workflow objects */
  readonly decisionSupportWorkflows: Maybe<WorkflowConnection>;
  /** Find a encounter by ID */
  readonly encounter: Maybe<Encounter>;
  /** List encounter objects */
  readonly encounters: Maybe<EncounterConnection>;
  /** List entity objects */
  readonly entities: Maybe<EntityConnection>;
  /** Find a entity by ID */
  readonly entity: Maybe<Entity>;
  /** Find an Entity Tree Node */
  readonly entityTreeNode: Maybe<EntityTreeNode>;
  /** Find a export_data_dump by ID */
  readonly exportDataDump: Maybe<DataDump>;
  /** List export_data_dump objects */
  readonly exportDataDumps: Maybe<DataDumpConnection>;
  /** Find a external_event by ID */
  readonly externalEvent: Maybe<ExternalEvent>;
  /** Find a external_event_type by ID */
  readonly externalEventType: Maybe<ExternalEventType>;
  /** List external_event_type objects */
  readonly externalEventTypes: Maybe<ExternalEventTypeConnection>;
  /** List external_event objects */
  readonly externalEvents: Maybe<ExternalEventConnection>;
  /** Find a flag by ID */
  readonly flag: Maybe<Flag>;
  /** List flag objects */
  readonly flags: Maybe<FlagConnection>;
  /** Find a implementation_target by ID */
  readonly implementationTarget: Maybe<ImplementationTarget>;
  readonly implementationTargetBestReport: Maybe<ImplementationTargetReport>;
  readonly implementationTargetClosestReport: Maybe<ImplementationTargetReport>;
  readonly implementationTargetReport: Maybe<ImplementationTargetReport>;
  /** List implementation_target objects */
  readonly implementationTargets: Maybe<ImplementationTargetConnection>;
  /** Find a inbox_messages_finding by ID */
  readonly inboxMessagesFinding: Maybe<InboxMessagesFinding>;
  /** List inbox_messages_finding objects */
  readonly inboxMessagesFindings: Maybe<InboxMessagesFindingConnection>;
  readonly inspectorNode: Maybe<InspectorNode>;
  /** Find a institute by ID */
  readonly institute: Maybe<Institute>;
  /** Find a institute_condition by ID */
  readonly instituteCondition: Maybe<InstituteCondition>;
  /** List institute_condition objects */
  readonly instituteConditions: Maybe<InstituteConditionConnection>;
  /** Find a institute_group by ID */
  readonly instituteGroup: Maybe<InstituteGroup>;
  /** List institute_group objects */
  readonly instituteGroups: Maybe<InstituteGroupConnection>;
  /** Find a institute_payor by ID */
  readonly institutePayor: Maybe<InstitutePayor>;
  /** List institute_payor objects */
  readonly institutePayors: Maybe<InstitutePayorConnection>;
  /** List institute objects */
  readonly institutes: Maybe<InstituteConnection>;
  readonly integrationStatuses: Maybe<ReadonlyArray<IntegrationStatus>>;
  /** Find a integrations_data_fetch_request by ID */
  readonly integrationsDataFetchRequest: Maybe<DataFetchRequest>;
  /** List integrations_data_fetch_request objects */
  readonly integrationsDataFetchRequests: Maybe<DataFetchRequestConnection>;
  /** Find a integrations_data_source by ID */
  readonly integrationsDataSource: Maybe<DataSource>;
  /** Find a integrations_data_source_record by ID */
  readonly integrationsDataSourceRecord: Maybe<DataSourceRecord>;
  /** Find a integrations_data_source_record_log by ID */
  readonly integrationsDataSourceRecordLog: Maybe<DataSourceRecordLog>;
  /** List integrations_data_source_record_log objects */
  readonly integrationsDataSourceRecordLogs: Maybe<DataSourceRecordLogConnection>;
  /** List integrations_data_source_record objects */
  readonly integrationsDataSourceRecords: Maybe<DataSourceRecordConnection>;
  /** Find a integrations_data_source_resource by ID */
  readonly integrationsDataSourceResource: Maybe<DataSourceResource>;
  /** Find a integrations_data_source_resource_config by ID */
  readonly integrationsDataSourceResourceConfig: Maybe<DataSourceResourceConfig>;
  /** List integrations_data_source_resource_config objects */
  readonly integrationsDataSourceResourceConfigs: Maybe<DataSourceResourceConfigConnection>;
  /** List integrations_data_source_resource objects */
  readonly integrationsDataSourceResources: Maybe<DataSourceResourceConnection>;
  /** List integrations_data_source objects */
  readonly integrationsDataSources: Maybe<DataSourceConnection>;
  /** Find a integrations_ehr_link by ID */
  readonly integrationsEhrLink: Maybe<EhrLink>;
  /** Find a integrations_ehr_link_log by ID */
  readonly integrationsEhrLinkLog: Maybe<EhrLinkLog>;
  /** List integrations_ehr_link_log objects */
  readonly integrationsEhrLinkLogs: Maybe<EhrLinkLogConnection>;
  /** List integrations_ehr_link objects */
  readonly integrationsEhrLinks: Maybe<EhrLinkConnection>;
  readonly integrationsImportHistory: Maybe<ImportHistory>;
  /** Find a key_pair by ID */
  readonly keyPair: Maybe<KeyPair>;
  /** List key_pair objects */
  readonly keyPairs: Maybe<KeyPairConnection>;
  /** Find a location by ID */
  readonly location: Maybe<Location>;
  /** List location objects */
  readonly locations: Maybe<LocationConnection>;
  /** Given a string, try to find an inspector path that it corresponds to */
  readonly magicInspectorPath: Maybe<Scalars["String"]["output"]>;
  /** Find a MonitoredJob by ID */
  readonly monitoredJob: Maybe<MonitoredJob>;
  /** Find a organization by ID */
  readonly organization: Maybe<Organization>;
  /** List organization objects */
  readonly organizations: Maybe<OrganizationConnection>;
  /** Find a outcomes_metric by ID */
  readonly outcomesMetric: Maybe<Metric>;
  readonly outcomesMetricComputedValues: Maybe<MetricComputedValueConnection>;
  /** Find a outcomes_metric_data_export by ID */
  readonly outcomesMetricDataExport: Maybe<MetricDataExport>;
  /** List outcomes_metric_data_export objects */
  readonly outcomesMetricDataExports: Maybe<MetricDataExportConnection>;
  readonly outcomesMetricSummaryData: MetricSummaryData;
  /** Find a outcomes_metric_template by ID */
  readonly outcomesMetricTemplate: Maybe<MetricTemplate>;
  /** List outcomes_metric_template objects */
  readonly outcomesMetricTemplates: Maybe<MetricTemplateConnection>;
  /** List outcomes_metric objects */
  readonly outcomesMetrics: Maybe<MetricConnection>;
  readonly outcomesTransientMetricComputedValues: Maybe<MetricComputedValueConnection>;
  readonly outcomesTransientMetricSummaryData: MetricSummaryData;
  /** Find a patient by ID */
  readonly patient: Maybe<Patient>;
  /** Find a patient_condition by ID */
  readonly patientCondition: Maybe<PatientCondition>;
  /** List patient_condition objects */
  readonly patientConditions: Maybe<PatientConditionConnection>;
  /** Find a patient_flag by ID */
  readonly patientFlag: Maybe<PatientFlag>;
  /** List patient_flag objects */
  readonly patientFlags: Maybe<PatientFlagConnection>;
  /** Find a patient_quality_issue by ID */
  readonly patientQualityIssue: Maybe<PatientQualityIssue>;
  /** List patient_quality_issue objects */
  readonly patientQualityIssues: Maybe<PatientQualityIssueConnection>;
  /** Find a patient_session by ID */
  readonly patientSession: Maybe<PatientSession>;
  /** List patient_session objects */
  readonly patientSessions: Maybe<PatientSessionConnection>;
  /** List patient objects */
  readonly patients: Maybe<PatientConnection>;
  /** Find a pipbhc_treatment_goal by ID */
  readonly pipbhcTreatmentGoal: Maybe<PipbhcTreatmentGoal>;
  /** Find a pipbhc_treatment_goal_report by ID */
  readonly pipbhcTreatmentGoalReport: Maybe<PipbhcTreatmentGoalReport>;
  /** List pipbhc_treatment_goal_report objects */
  readonly pipbhcTreatmentGoalReports: Maybe<PipbhcTreatmentGoalReportConnection>;
  /** List pipbhc_treatment_goal objects */
  readonly pipbhcTreatmentGoals: Maybe<PipbhcTreatmentGoalConnection>;
  /** Find a provider by ID */
  readonly provider: Maybe<Provider>;
  /** List provider objects */
  readonly providers: Maybe<ProviderConnection>;
  /** Find a scheduling_appointment by ID */
  readonly schedulingAppointment: Maybe<Appointment>;
  /** Find a scheduling_appointment_schedule by ID */
  readonly schedulingAppointmentSchedule: Maybe<AppointmentSchedule>;
  /** List scheduling_appointment_schedule objects */
  readonly schedulingAppointmentSchedules: Maybe<AppointmentScheduleConnection>;
  /** List scheduling_appointment objects */
  readonly schedulingAppointments: Maybe<AppointmentConnection>;
  /** Find a scheduling_recurrence by ID */
  readonly schedulingRecurrence: Maybe<Recurrence>;
  /** List scheduling_recurrence objects */
  readonly schedulingRecurrences: Maybe<RecurrenceConnection>;
  readonly supportTask: Maybe<SupportTask>;
  /** List support_task_history objects */
  readonly supportTaskHistories: Maybe<SupportTaskHistoryConnection>;
  /** Find a support_task_history by ID */
  readonly supportTaskHistory: Maybe<SupportTaskHistory>;
  readonly supportTasks: Maybe<ReadonlyArray<SupportTask>>;
  /** Find a survey_response by ID */
  readonly surveyResponse: Maybe<Response>;
  /** List survey_response objects */
  readonly surveyResponses: Maybe<ResponseConnection>;
  readonly timezones: Maybe<ReadonlyArray<Timezone>>;
  /** Find a treatment_service by ID */
  readonly treatmentService: Maybe<TreatmentService>;
  /** List treatment_service objects */
  readonly treatmentServices: Maybe<TreatmentServiceConnection>;
  /** Find a user by ID */
  readonly user: Maybe<User>;
  /** Find a user_consent by ID */
  readonly userConsent: Maybe<UserConsent>;
  /** List user_consent objects */
  readonly userConsents: Maybe<UserConsentConnection>;
  /** List user objects */
  readonly users: Maybe<UserConnection>;
};

/** The query root of this schema */
export type QueryassessmentAdministrableScaleArgs = {
  id: Scalars["ScaleId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentAdministrableScalesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  age: InputMaybe<Scalars["Int"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  gender: InputMaybe<ReadonlyArray<Gender>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  smartSearch: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<AdministrableScaleSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryassessmentCareEpisodeClinicalAlertHistoryArgs = {
  careEpisodeClinicalAlertHistoryId: Scalars["CareEpisodeScaleId"]["input"];
  date: InputMaybe<Scalars["ISO8601Date"]["input"]>;
};

/** The query root of this schema */
export type QueryassessmentCareEpisodeComputedValueArgs = {
  id: Scalars["CareEpisodeComputedValueId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentCareEpisodeComputedValuesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  reportId: InputMaybe<ReadonlyArray<Scalars["ReportId"]["input"]>>;
  sortBy: InputMaybe<CareEpisodeComputedValueSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryassessmentCareEpisodeScaleArgs = {
  id: Scalars["CareEpisodeScaleId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentCareEpisodeScaleDetailArgs = {
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
  date: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  scaleId: Scalars["ScaleId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentCareEpisodeScaleGroupDetailArgs = {
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
  date: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  scaleGroupId: Scalars["ScaleGroupId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentCareEpisodeScaleGroupReportArgs = {
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
  reportId: Scalars["ReportId"]["input"];
  scaleGroupId: Scalars["ScaleGroupId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentCareEpisodeScaleReportArgs = {
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
  reportId: Scalars["ReportId"]["input"];
  scaleId: Scalars["ScaleId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentCareEpisodeScaleScorerSummariesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  headline: InputMaybe<Scalars["Boolean"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<CareEpisodeScaleScorerSummarySortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryassessmentCareEpisodeScaleScorerSummaryArgs = {
  id: Scalars["CareEpisodeScaleScorerSummaryId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentCareEpisodeScaleSummaryArgs = {
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
  date: InputMaybe<Scalars["ISO8601Date"]["input"]>;
};

/** The query root of this schema */
export type QueryassessmentCareEpisodeScalesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<CareEpisodeScaleSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryassessmentComputedValueArgs = {
  id: Scalars["ComputedValueId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentComputedValuesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  reportId: InputMaybe<ReadonlyArray<Scalars["ReportId"]["input"]>>;
  sortBy: InputMaybe<ComputedValueSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryassessmentConstructArgs = {
  id: Scalars["ConstructId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentConstructsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
};

/** The query root of this schema */
export type QueryassessmentExternalObservationArgs = {
  id: Scalars["ExternalObservationId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentExternalObservationsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  sortBy: InputMaybe<ExternalObservationSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryassessmentGoalArgs = {
  id: Scalars["GoalId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentGoalsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<GoalSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<GoalStatus>>;
};

/** The query root of this schema */
export type QueryassessmentInstituteScaleArgs = {
  id: Scalars["InstituteScaleId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentInstituteScalesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<InstituteScaleSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryassessmentInvitationArgs = {
  id: Scalars["InvitationId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentInvitationsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careEpisodeId: InputMaybe<Scalars["CareEpisodeId"]["input"]>;
  closesAt: InputMaybe<DateRangeInput>;
  dateBegun: InputMaybe<DateRangeInput>;
  dateCompleted: InputMaybe<DateRangeInput>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  isWaitingOnUserStates: InputMaybe<Scalars["Boolean"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  opensAt: InputMaybe<DateRangeInput>;
  patientId: InputMaybe<Scalars["PatientId"]["input"]>;
  sortBy: InputMaybe<InvitationSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<InvitationStatus>>;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
  userId: InputMaybe<ReadonlyArray<Scalars["UserId"]["input"]>>;
};

/** The query root of this schema */
export type QueryassessmentLatestReportTextArgs = {
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentReportArgs = {
  id: Scalars["ReportId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentReportProviderObligationsArgs = {
  providerId: Scalars["String"]["input"];
  reportId: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryassessmentReportTextArgs = {
  reportId: Scalars["ReportId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentReportsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careEpisodeId: InputMaybe<ReadonlyArray<Scalars["CareEpisodeId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  sortBy: InputMaybe<ReportSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryassessmentScaleArgs = {
  id: Scalars["ScaleId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentScaleGroupArgs = {
  id: Scalars["ScaleGroupId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentScaleGroupsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
};

/** The query root of this schema */
export type QueryassessmentScaleScorerArgs = {
  id: Scalars["ScaleScorerId"]["input"];
};

/** The query root of this schema */
export type QueryassessmentScaleScorersArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  domain: InputMaybe<ReadonlyArray<ScaleScorerDomain>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  id: InputMaybe<ReadonlyArray<Scalars["ScaleScorerId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  parentId: InputMaybe<Scalars["ScaleScorerId"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<ScaleScorerSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryassessmentScaleScorersWithUsageArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  dateRange: InputMaybe<DateRangeInput>;
  domain: InputMaybe<ReadonlyArray<ScaleScorerDomain>>;
  entityTreeNode: InputMaybe<EntityTreeNodeParams>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
};

/** The query root of this schema */
export type QueryassessmentScalesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  onlyAdministrable: InputMaybe<Scalars["Boolean"]["input"]>;
  smartSearch: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<ScaleSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerybillingCocmBillingAlgorithmArgs = {
  id: Scalars["CocmBillingAlgorithmId"]["input"];
};

/** The query root of this schema */
export type QuerybillingCocmBillingAlgorithmCodeArgs = {
  id: Scalars["CocmBillingAlgorithmCodeId"]["input"];
};

/** The query root of this schema */
export type QuerybillingCocmBillingAlgorithmCodesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  cocmBillingAlgorithmId: InputMaybe<ReadonlyArray<Scalars["CocmBillingAlgorithmId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<CocmBillingAlgorithmCodeSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerybillingCocmBillingAlgorithmRuleArgs = {
  id: Scalars["CocmBillingAlgorithmRuleId"]["input"];
};

/** The query root of this schema */
export type QuerybillingCocmBillingAlgorithmRulesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  availableForNode: InputMaybe<EntityTreeNodeParams>;
  before: InputMaybe<Scalars["String"]["input"]>;
  cocmBillingAlgorithmId: InputMaybe<ReadonlyArray<Scalars["CocmBillingAlgorithmId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<CocmBillingAlgorithmRuleSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerybillingCocmBillingAlgorithmsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  availableForNode: InputMaybe<EntityTreeNodeParams>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  smartSearch: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<CocmBillingAlgorithmSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<CocmBillingAlgorithmStatus>>;
};

/** The query root of this schema */
export type QuerybillingCocmEstimatedBillingStatsSummaryArgs = {
  entityTreeNode: InputMaybe<EntityTreeNodeParams>;
  month: InputMaybe<MonthParams>;
  monthOfEnrollment: InputMaybe<ReadonlyArray<Scalars["Int"]["input"]>>;
  rules: InputMaybe<ReadonlyArray<Scalars["CocmBillingAlgorithmRuleId"]["input"]>>;
};

/** The query root of this schema */
export type QuerybillingCoverageArgs = {
  id: Scalars["CoverageId"]["input"];
};

/** The query root of this schema */
export type QuerybillingCoveragesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  sortBy: InputMaybe<CoverageSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerybillingEnrollmentMonthBillingRuleResultArgs = {
  id: Scalars["EnrollmentMonthBillingRuleResultId"]["input"];
};

/** The query root of this schema */
export type QuerybillingEnrollmentMonthBillingRuleResultWinnerArgs = {
  id: Scalars["EnrollmentMonthBillingRuleResultWinnerId"]["input"];
};

/** The query root of this schema */
export type QuerybillingEnrollmentMonthBillingRuleResultWinnersArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  availableForNode: InputMaybe<EntityTreeNodeParams>;
  before: InputMaybe<Scalars["String"]["input"]>;
  enrollmentId: InputMaybe<ReadonlyArray<Scalars["EnrollmentId"]["input"]>>;
  enrollmentMonthId: InputMaybe<ReadonlyArray<Scalars["EnrollmentMonthId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  month: InputMaybe<MonthParams>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<EnrollmentMonthBillingRuleResultWinnerSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  stillEnrolled: InputMaybe<Scalars["Boolean"]["input"]>;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
  withCurrentWinnerValue: InputMaybe<CocmBillingAlgorithmRuleCheckValue>;
  withCurrentWinners: InputMaybe<Scalars["Boolean"]["input"]>;
  withMonthOfTreatment: InputMaybe<ReadonlyArray<Scalars["Int"]["input"]>>;
  withNextRule: InputMaybe<ReadonlyArray<Scalars["CocmBillingAlgorithmRuleId"]["input"]>>;
  withNextUp: InputMaybe<Scalars["Boolean"]["input"]>;
  withNextUpFailReasons: InputMaybe<ReadonlyArray<CocmBillingAlgorithmRuleCheck>>;
  withWinningRule: InputMaybe<ReadonlyArray<Scalars["CocmBillingAlgorithmRuleId"]["input"]>>;
};

/** The query root of this schema */
export type QuerybillingEnrollmentMonthBillingRuleResultsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  availableForNode: InputMaybe<EntityTreeNodeParams>;
  before: InputMaybe<Scalars["String"]["input"]>;
  enrollmentMonthId: InputMaybe<ReadonlyArray<Scalars["EnrollmentMonthId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<EnrollmentMonthBillingRuleResultSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerybillingEstimatedBillingCumulativeEffortArgs = {
  allowMirahInternalTargets: InputMaybe<Scalars["Boolean"]["input"]>;
  entityTreeNode: InputMaybe<EntityTreeNodeParams>;
  month: InputMaybe<MonthParams>;
  monthOfEnrollment: InputMaybe<ReadonlyArray<Scalars["Int"]["input"]>>;
  ruleIds: InputMaybe<ReadonlyArray<Scalars["CocmBillingAlgorithmRuleId"]["input"]>>;
  valueType: CocmBillingAlgorithmRuleCheckValue;
};

/** The query root of this schema */
export type QuerybusinessEventArgs = {
  id: Scalars["BusinessEventId"]["input"];
};

/** The query root of this schema */
export type QuerybusinessEventsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  eventName: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  isTest: InputMaybe<Scalars["Boolean"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<BusinessEventSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerycareCareEpisodeTreatmentTrackArgs = {
  id: Scalars["CareEpisodeTreatmentTrackId"]["input"];
};

/** The query root of this schema */
export type QuerycareCareEpisodeTreatmentTracksArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careEpisodeId: InputMaybe<ReadonlyArray<Scalars["CareEpisodeId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<CareEpisodeTreatmentTrackSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<CareEpisodeTreatmentTrackStatus>>;
};

/** The query root of this schema */
export type QuerycareEntityMeasurementPlanArgs = {
  id: Scalars["EntityMeasurementPlanId"]["input"];
};

/** The query root of this schema */
export type QuerycareEntityMeasurementPlansArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<EntityMeasurementPlanSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerycareEpisodeArgs = {
  id: Scalars["CareEpisodeId"]["input"];
};

/** The query root of this schema */
export type QuerycareEpisodeMessageArgs = {
  id: Scalars["CareEpisodeMessageId"]["input"];
};

/** The query root of this schema */
export type QuerycareEpisodeMessagesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careEpisodeId: InputMaybe<ReadonlyArray<Scalars["CareEpisodeId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<CareEpisodeMessageSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<CareEpisodeMessageStatus>>;
};

/** The query root of this schema */
export type QuerycareEpisodesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  flagIds: InputMaybe<ReadonlyArray<Scalars["FlagId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  measurementAllowed: InputMaybe<Scalars["Boolean"]["input"]>;
  organizationId: InputMaybe<ReadonlyArray<Scalars["OrganizationId"]["input"]>>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  periodEnd: InputMaybe<DateRangeInput>;
  periodStart: InputMaybe<DateRangeInput>;
  sortBy: InputMaybe<CareEpisodeSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<CareEpisodeStatus>>;
};

/** The query root of this schema */
export type QuerycareFutureSessionMeasurementEstimateArgs = {
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
  includeAllUnplanned: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The query root of this schema */
export type QuerycareGroupArgs = {
  id: Scalars["CareGroupId"]["input"];
};

/** The query root of this schema */
export type QuerycareGroupsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careUnitTypeId: InputMaybe<ReadonlyArray<Scalars["CareUnitTypeId"]["input"]>>;
  careUnitTypeMnemonic: InputMaybe<Scalars["String"]["input"]>;
  externalId: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  flagIds: InputMaybe<ReadonlyArray<Scalars["FlagId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<CareGroupSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerycareMeasurementPlanArgs = {
  id: Scalars["MeasurementPlanId"]["input"];
};

/** The query root of this schema */
export type QuerycareMeasurementPlansArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  defaultPlanType: InputMaybe<ReadonlyArray<MeasurementPlanType>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<MeasurementPlanSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerycareTreatmentTrackArgs = {
  id: Scalars["TreatmentTrackId"]["input"];
};

/** The query root of this schema */
export type QuerycareTreatmentTracksArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  smartSearch: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<TreatmentTrackSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<TreatmentTrackStatus>;
};

/** The query root of this schema */
export type QuerycareUnitTypeArgs = {
  id: Scalars["CareUnitTypeId"]["input"];
};

/** The query root of this schema */
export type QuerycareUnitTypesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  mnemonic: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
  sortBy: InputMaybe<CareUnitTypeSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerycollaborativeCareAssessedPatientsStatsArgs = {
  careManagerIds: InputMaybe<ReadonlyArray<Scalars["ProviderId"]["input"]>>;
  endDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  organizationId: InputMaybe<Scalars["OrganizationId"]["input"]>;
  startDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
};

/** The query root of this schema */
export type QuerycollaborativeCareCareEpisodeTreatmentTargetArgs = {
  id: Scalars["CareEpisodeTreatmentTargetId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareCareEpisodeTreatmentTargetsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<CareEpisodeTreatmentTargetSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<CareEpisodeTreatmentTargetStatus>;
};

/** The query root of this schema */
export type QuerycollaborativeCareCaseConsultArgs = {
  id: Scalars["CaseConsultId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareCaseConsultsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careManagerId: InputMaybe<Scalars["ProviderId"]["input"]>;
  consultMeetingId: InputMaybe<Scalars["ConsultMeetingId"]["input"]>;
  enrollmentId: InputMaybe<Scalars["EnrollmentId"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patient: InputMaybe<PatientFilter>;
  primaryCareProviderId: InputMaybe<Scalars["ProviderId"]["input"]>;
  sortBy: InputMaybe<CaseConsultSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<CaseConsultStatus>>;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The query root of this schema */
export type QuerycollaborativeCareCaseloadEfficiencyStatsArgs = {
  careManagerIds: InputMaybe<ReadonlyArray<Scalars["ProviderId"]["input"]>>;
  endDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  organizationId: InputMaybe<Scalars["OrganizationId"]["input"]>;
  startDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
};

/** The query root of this schema */
export type QuerycollaborativeCareConsultMeetingArgs = {
  id: Scalars["ConsultMeetingId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareConsultMeetingAttendeeArgs = {
  id: Scalars["ConsultMeetingAttendeeId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareConsultMeetingAttendeesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<ConsultMeetingAttendeeSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerycollaborativeCareConsultMeetingsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<ConsultMeetingSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<ConsultMeetingStatus>>;
};

/** The query root of this schema */
export type QuerycollaborativeCareConsultRequestArgs = {
  id: Scalars["ConsultRequestId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareConsultRequestsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<ConsultRequestSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerycollaborativeCareEnrollmentArgs = {
  id: Scalars["EnrollmentId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareEnrollmentAcuityStatsArgs = {
  careManagerIds: InputMaybe<ReadonlyArray<Scalars["ProviderId"]["input"]>>;
  endDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  organizationId: InputMaybe<Scalars["OrganizationId"]["input"]>;
  startDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
};

/** The query root of this schema */
export type QuerycollaborativeCareEnrollmentBillingMonthArgs = {
  month: Scalars["Int"]["input"];
  patientId: InputMaybe<Scalars["PatientId"]["input"]>;
  providerId: InputMaybe<Scalars["ProviderId"]["input"]>;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
  updateEnrollmentSummaries: InputMaybe<Scalars["Boolean"]["input"]>;
  year: Scalars["Int"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareEnrollmentMonthArgs = {
  id: Scalars["EnrollmentMonthId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareEnrollmentMonthsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  availableForNode: InputMaybe<EntityTreeNodeParams>;
  before: InputMaybe<Scalars["String"]["input"]>;
  beginningOfMonth: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  enrollmentId: InputMaybe<Scalars["EnrollmentId"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  minimumBillableMinutes: InputMaybe<Scalars["Int"]["input"]>;
  minutesTargetStatus: InputMaybe<EnrollmentMonthMinutesTargetStatus>;
  month: InputMaybe<Scalars["Int"]["input"]>;
  monthAndYear: InputMaybe<MonthParams>;
  patientId: InputMaybe<Scalars["PatientId"]["input"]>;
  sortBy: InputMaybe<EnrollmentMonthSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The query root of this schema */
export type QuerycollaborativeCareEnrollmentsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  availableForNode: InputMaybe<EntityTreeNodeParams>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<EnrollmentSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<EnrollmentStatus>;
};

/** The query root of this schema */
export type QuerycollaborativeCareFindingArgs = {
  id: Scalars["FindingId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareFindingsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  flavor: InputMaybe<FindingFlavor>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<FindingSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  unresolvedOnly: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The query root of this schema */
export type QuerycollaborativeCareIntakeSessionScaleArgs = {
  id: Scalars["IntakeSessionScaleId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareIntakeSessionScalesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<IntakeSessionScaleSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerycollaborativeCareNewEnrollmentStatsArgs = {
  careManagerIds: InputMaybe<ReadonlyArray<Scalars["ProviderId"]["input"]>>;
  endDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  organizationId: InputMaybe<Scalars["OrganizationId"]["input"]>;
  startDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
};

/** The query root of this schema */
export type QuerycollaborativeCareNoteArgs = {
  id: Scalars["NoteId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareNotesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<NoteSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerycollaborativeCarePanelArgs = {
  id: Scalars["PanelId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCarePanelTeamMemberArgs = {
  id: Scalars["PanelTeamMemberId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCarePanelTeamMembersArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  providerId: InputMaybe<Scalars["ProviderId"]["input"]>;
  sortBy: InputMaybe<PanelTeamMemberSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerycollaborativeCarePanelsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<PanelSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<PanelStatus>;
};

/** The query root of this schema */
export type QuerycollaborativeCarePatientMinutesTargetArgs = {
  id: Scalars["PatientMinutesTargetId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCarePatientMinutesTargetsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<PatientMinutesTargetSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerycollaborativeCareQuickTextArgs = {
  id: Scalars["QuickTextId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareQuickTextsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<QuickTextSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerycollaborativeCareSeverityBadgeArgs = {
  enrollmentId: Scalars["EnrollmentId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareTaskArgs = {
  id: Scalars["TaskId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareTaskCreationLogArgs = {
  id: Scalars["TaskCreationLogId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareTaskCreationLogsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<TaskCreationLogSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerycollaborativeCareTaskListItemArgs = {
  id: Scalars["TaskListItemId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareTaskListItemsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<TaskListItemSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<TaskStatus>;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The query root of this schema */
export type QuerycollaborativeCareTaskTemplateArgs = {
  id: Scalars["TaskTemplateId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareTaskTemplatesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<TaskTemplateSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerycollaborativeCareTasksArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  assignedTo: InputMaybe<ProviderFilter>;
  before: InputMaybe<Scalars["String"]["input"]>;
  enrollmentId: InputMaybe<Scalars["EnrollmentId"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<TaskSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<TaskStatus>;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The query root of this schema */
export type QuerycollaborativeCareTimeEntryLogArgs = {
  id: Scalars["TimeEntryLogId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareTimeEntryLogsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  deleted: InputMaybe<Scalars["Boolean"]["input"]>;
  endTime: InputMaybe<DateRangeInput>;
  enrollmentMonthId: InputMaybe<ReadonlyArray<Scalars["EnrollmentMonthId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<TimeEntryLogSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  startTime: InputMaybe<DateRangeInput>;
  taskId: InputMaybe<Scalars["TaskId"]["input"]>;
};

/** The query root of this schema */
export type QuerycollaborativeCareTreatmentTargetArgs = {
  id: Scalars["TreatmentTargetId"]["input"];
};

/** The query root of this schema */
export type QuerycollaborativeCareTreatmentTargetsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<TreatmentTargetSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryconsentArgs = {
  id: Scalars["ConsentId"]["input"];
};

/** The query root of this schema */
export type QueryconsentAgreementArgs = {
  id: Scalars["ConsentAgreementId"]["input"];
};

/** The query root of this schema */
export type QueryconsentAgreementsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  sortBy: InputMaybe<ConsentAgreementSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryconsentsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  smartSearch: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<ConsentSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerydecisionSupportDecisionAlgorithmArgs = {
  id: Scalars["DecisionAlgorithmId"]["input"];
};

/** The query root of this schema */
export type QuerydecisionSupportDecisionAlgorithmsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<DecisionAlgorithmSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerydecisionSupportEntityDecisionAlgorithmArgs = {
  id: Scalars["EntityDecisionAlgorithmId"]["input"];
};

/** The query root of this schema */
export type QuerydecisionSupportEntityDecisionAlgorithmsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<EntityDecisionAlgorithmSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<EntityDecisionAlgorithmStatus>>;
};

/** The query root of this schema */
export type QuerydecisionSupportInstituteWorkflowsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  smartSearch: InputMaybe<Scalars["String"]["input"]>;
};

/** The query root of this schema */
export type QuerydecisionSupportMyProviderNotificationsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
};

/** The query root of this schema */
export type QuerydecisionSupportProviderNotificationArgs = {
  id: Scalars["ProviderNotificationId"]["input"];
};

/** The query root of this schema */
export type QuerydecisionSupportProviderNotificationsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  includeHidden: InputMaybe<Scalars["Boolean"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patient: InputMaybe<PatientFilter>;
  provider: InputMaybe<ProviderFilter>;
  providerId: InputMaybe<Scalars["ProviderId"]["input"]>;
  sortBy: InputMaybe<ProviderNotificationSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  workflowStatus: InputMaybe<TriggeredWorkflowStatus>;
  workflowType: InputMaybe<WorkflowFilter>;
};

/** The query root of this schema */
export type QuerydecisionSupportTriggeredDecisionAlgorithmArgs = {
  id: Scalars["TriggeredDecisionAlgorithmId"]["input"];
};

/** The query root of this schema */
export type QuerydecisionSupportTriggeredDecisionAlgorithmsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<TriggeredDecisionAlgorithmSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerydecisionSupportTriggeredWorkflowArgs = {
  id: Scalars["TriggeredWorkflowId"]["input"];
};

/** The query root of this schema */
export type QuerydecisionSupportTriggeredWorkflowActionArgs = {
  id: Scalars["TriggeredWorkflowActionId"]["input"];
};

/** The query root of this schema */
export type QuerydecisionSupportTriggeredWorkflowActionLogArgs = {
  id: Scalars["TriggeredWorkflowActionLogId"]["input"];
};

/** The query root of this schema */
export type QuerydecisionSupportTriggeredWorkflowActionLogsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<TriggeredWorkflowActionLogSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerydecisionSupportTriggeredWorkflowActionsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<TriggeredWorkflowActionSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerydecisionSupportTriggeredWorkflowsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patient: InputMaybe<PatientFilter>;
  provider: InputMaybe<ProviderFilter>;
  sortBy: InputMaybe<TriggeredWorkflowSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<TriggeredWorkflowStatus>;
  workflowType: InputMaybe<WorkflowFilter>;
};

/** The query root of this schema */
export type QuerydecisionSupportWorkflowArgs = {
  id: Scalars["WorkflowId"]["input"];
};

/** The query root of this schema */
export type QuerydecisionSupportWorkflowActionArgs = {
  id: Scalars["WorkflowActionId"]["input"];
};

/** The query root of this schema */
export type QuerydecisionSupportWorkflowActionsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<WorkflowActionSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerydecisionSupportWorkflowsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<WorkflowSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryencounterArgs = {
  id: Scalars["EncounterId"]["input"];
};

/** The query root of this schema */
export type QueryencountersArgs = {
  actualPeriodEnd: InputMaybe<DateRangeInput>;
  actualPeriodStart: InputMaybe<DateRangeInput>;
  after: InputMaybe<Scalars["String"]["input"]>;
  appointment: InputMaybe<ReadonlyArray<Scalars["AppointmentId"]["input"]>>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careEpisode: InputMaybe<ReadonlyArray<Scalars["CareEpisodeId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patient: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  sortBy: InputMaybe<EncounterSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryentitiesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  entityType: InputMaybe<ReadonlyArray<EntityType>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<EntitySortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryentityArgs = {
  id: Scalars["EntityId"]["input"];
};

/** The query root of this schema */
export type QueryentityTreeNodeArgs = {
  node: EntityTreeNodeParams;
};

/** The query root of this schema */
export type QueryexportDataDumpArgs = {
  id: Scalars["DataDumpId"]["input"];
};

/** The query root of this schema */
export type QueryexportDataDumpsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<DataDumpSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  userId: InputMaybe<ReadonlyArray<Scalars["UserId"]["input"]>>;
};

/** The query root of this schema */
export type QueryexternalEventArgs = {
  id: Scalars["ExternalEventId"]["input"];
};

/** The query root of this schema */
export type QueryexternalEventTypeArgs = {
  id: Scalars["ExternalEventTypeId"]["input"];
};

/** The query root of this schema */
export type QueryexternalEventTypesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<ExternalEventTypeSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryexternalEventsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  authorId: InputMaybe<ReadonlyArray<Scalars["UserId"]["input"]>>;
  before: InputMaybe<Scalars["String"]["input"]>;
  effectiveDate: InputMaybe<DateRangeInput>;
  externalEventTypeId: InputMaybe<ReadonlyArray<Scalars["ExternalEventTypeId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<ExternalEventSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryflagArgs = {
  id: Scalars["FlagId"]["input"];
};

/** The query root of this schema */
export type QueryflagsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  id: InputMaybe<ReadonlyArray<Scalars["FlagId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  smartSearch: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<FlagSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryimplementationTargetArgs = {
  id: Scalars["ImplementationTargetId"]["input"];
};

/** The query root of this schema */
export type QueryimplementationTargetBestReportArgs = {
  allowMirahInternal: InputMaybe<Scalars["Boolean"]["input"]>;
  date: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  entityTreeNode: InputMaybe<EntityTreeNodeParams>;
  implementationTargetType: ImplementationTargetType;
};

/** The query root of this schema */
export type QueryimplementationTargetClosestReportArgs = {
  date: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  entityTreeNode: InputMaybe<EntityTreeNodeParams>;
  implementationTargetId: Scalars["ImplementationTargetId"]["input"];
};

/** The query root of this schema */
export type QueryimplementationTargetReportArgs = {
  dateAndPeriod: ImplementationTargetReportInput;
  entityTreeNode: InputMaybe<EntityTreeNodeParams>;
  implementationTargetId: Scalars["ImplementationTargetId"]["input"];
};

/** The query root of this schema */
export type QueryimplementationTargetsArgs = {
  activeForDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  availableForNode: InputMaybe<EntityTreeNodeParams>;
  before: InputMaybe<Scalars["String"]["input"]>;
  entityQuery: InputMaybe<EntityTreeNodeQueryParams>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  hideProviderInformation: InputMaybe<Scalars["Boolean"]["input"]>;
  includeMirahInternal: InputMaybe<Scalars["Boolean"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  smartSearch: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<ImplementationTargetSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<ImplementationTargetStatus>>;
  targetType: InputMaybe<ReadonlyArray<ImplementationTargetType>>;
};

/** The query root of this schema */
export type QueryinboxMessagesFindingArgs = {
  id: Scalars["FindingId"]["input"];
};

/** The query root of this schema */
export type QueryinboxMessagesFindingsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  flavor: InputMaybe<FindingFlavor>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<InboxMessagesFindingSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  unresolvedOnly: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The query root of this schema */
export type QueryinspectorNodeArgs = {
  className: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QueryinstituteArgs = {
  id: Scalars["InstituteId"]["input"];
};

/** The query root of this schema */
export type QueryinstituteConditionArgs = {
  id: Scalars["InstituteConditionId"]["input"];
};

/** The query root of this schema */
export type QueryinstituteConditionsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  smartSearch: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<InstituteConditionSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryinstituteGroupArgs = {
  id: Scalars["InstituteGroupId"]["input"];
};

/** The query root of this schema */
export type QueryinstituteGroupsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  smartSearch: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<InstituteGroupSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryinstitutePayorArgs = {
  id: Scalars["InstitutePayorId"]["input"];
};

/** The query root of this schema */
export type QueryinstitutePayorsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<InstitutePayorSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryinstitutesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  enabled: InputMaybe<Scalars["Boolean"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  instituteType: InputMaybe<ReadonlyArray<InstituteType>>;
  isTest: InputMaybe<Scalars["Boolean"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  measurementAllowed: InputMaybe<Scalars["Boolean"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<InstituteSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryintegrationStatusesArgs = {
  endDate: Scalars["ISO8601Date"]["input"];
  startDate: Scalars["ISO8601Date"]["input"];
};

/** The query root of this schema */
export type QueryintegrationsDataFetchRequestArgs = {
  id: Scalars["DataFetchRequestId"]["input"];
};

/** The query root of this schema */
export type QueryintegrationsDataFetchRequestsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  dataSourceRecordId: InputMaybe<ReadonlyArray<Scalars["DataSourceRecordId"]["input"]>>;
  dataSourceResourceId: InputMaybe<ReadonlyArray<Scalars["DataSourceResourceId"]["input"]>>;
  fetchType: InputMaybe<ReadonlyArray<DataFetchRequestFetchType>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<DataFetchRequestSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<DataFetchRequestStatus>>;
};

/** The query root of this schema */
export type QueryintegrationsDataSourceArgs = {
  id: Scalars["DataSourceId"]["input"];
};

/** The query root of this schema */
export type QueryintegrationsDataSourceRecordArgs = {
  id: Scalars["DataSourceRecordId"]["input"];
};

/** The query root of this schema */
export type QueryintegrationsDataSourceRecordLogArgs = {
  id: Scalars["DataSourceRecordLogId"]["input"];
};

/** The query root of this schema */
export type QueryintegrationsDataSourceRecordLogsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  dataSourceRecordId: InputMaybe<ReadonlyArray<Scalars["DataSourceRecordId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  importHistoryId: InputMaybe<ReadonlyArray<Scalars["ImportHistoryId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<DataSourceRecordLogSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<DataSourceRecordLogStatus>>;
};

/** The query root of this schema */
export type QueryintegrationsDataSourceRecordsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  dataSourceResourceId: InputMaybe<ReadonlyArray<Scalars["DataSourceResourceId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  remoteId: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
  resourceType: InputMaybe<ReadonlyArray<DataSourceResourceType>>;
  sortBy: InputMaybe<DataSourceRecordSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  targetId: InputMaybe<ReadonlyArray<Scalars["ImportHistoryId"]["input"]>>;
};

/** The query root of this schema */
export type QueryintegrationsDataSourceResourceArgs = {
  id: Scalars["DataSourceResourceId"]["input"];
};

/** The query root of this schema */
export type QueryintegrationsDataSourceResourceConfigArgs = {
  id: Scalars["DataSourceResourceConfigId"]["input"];
};

/** The query root of this schema */
export type QueryintegrationsDataSourceResourceConfigsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<DataSourceResourceConfigSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryintegrationsDataSourceResourcesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  dataSourceId: InputMaybe<ReadonlyArray<Scalars["DataSourceId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  resourceType: InputMaybe<ReadonlyArray<DataSourceResourceType>>;
  sortBy: InputMaybe<DataSourceResourceSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryintegrationsDataSourcesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<DataSourceSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryintegrationsEhrLinkArgs = {
  id: Scalars["EhrLinkId"]["input"];
};

/** The query root of this schema */
export type QueryintegrationsEhrLinkLogArgs = {
  id: Scalars["EhrLinkLogId"]["input"];
};

/** The query root of this schema */
export type QueryintegrationsEhrLinkLogsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  ehrLinkId: InputMaybe<ReadonlyArray<Scalars["EhrLinkId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<EhrLinkLogSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<EhrLinkLogStatus>>;
};

/** The query root of this schema */
export type QueryintegrationsEhrLinksArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  connectionType: InputMaybe<ReadonlyArray<EhrLinkType>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<EhrLinkSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryintegrationsImportHistoryArgs = {
  id: Scalars["ImportHistoryId"]["input"];
  resourceType: DataSourceResourceType;
};

/** The query root of this schema */
export type QuerykeyPairArgs = {
  id: Scalars["KeyPairId"]["input"];
};

/** The query root of this schema */
export type QuerykeyPairsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<KeyPairSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerylocationArgs = {
  id: Scalars["LocationId"]["input"];
};

/** The query root of this schema */
export type QuerylocationsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  smartSearch: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<LocationSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerymagicInspectorPathArgs = {
  text: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QuerymonitoredJobArgs = {
  id: Scalars["MonitoredJobId"]["input"];
};

/** The query root of this schema */
export type QueryorganizationArgs = {
  id: Scalars["OrganizationId"]["input"];
};

/** The query root of this schema */
export type QueryorganizationsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  measurementAllowed: InputMaybe<Scalars["Boolean"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<OrganizationSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryoutcomesMetricArgs = {
  id: Scalars["MetricId"]["input"];
};

/** The query root of this schema */
export type QueryoutcomesMetricComputedValuesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  dateRange: DateRangeInput;
  entityTreeNode: EntityTreeNodeParams;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  metricId: Scalars["MetricId"]["input"];
  sortBy: InputMaybe<MetricComputedValueSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryoutcomesMetricDataExportArgs = {
  id: Scalars["MetricDataExportId"]["input"];
};

/** The query root of this schema */
export type QueryoutcomesMetricDataExportsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<MetricDataExportSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<MetricDataExportStatus>>;
  userId: InputMaybe<ReadonlyArray<Scalars["UserId"]["input"]>>;
};

/** The query root of this schema */
export type QueryoutcomesMetricSummaryDataArgs = {
  drilldownMetric: DrilldownMetricParams;
  metricId: Scalars["MetricId"]["input"];
  scaleScorerId: InputMaybe<Scalars["ScaleScorerId"]["input"]>;
};

/** The query root of this schema */
export type QueryoutcomesMetricTemplateArgs = {
  id: Scalars["MetricId"]["input"];
};

/** The query root of this schema */
export type QueryoutcomesMetricTemplatesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<MetricTemplateSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryoutcomesMetricsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  availableForNode: InputMaybe<EntityTreeNodeParams>;
  before: InputMaybe<Scalars["String"]["input"]>;
  entityQuery: InputMaybe<EntityTreeNodeQueryParams>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  smartSearch: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<MetricSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<MetricStatus>>;
};

/** The query root of this schema */
export type QueryoutcomesTransientMetricComputedValuesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  dateRange: DateRangeInput;
  entityTreeNode: EntityTreeNodeParams;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  metricParams: MetricParams;
  sortBy: InputMaybe<MetricComputedValueSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryoutcomesTransientMetricSummaryDataArgs = {
  drilldownMetric: DrilldownMetricParams;
  scaleScorerId: InputMaybe<Scalars["ScaleScorerId"]["input"]>;
  transientMetricData: MetricParams;
};

/** The query root of this schema */
export type QuerypatientArgs = {
  id: Scalars["PatientId"]["input"];
};

/** The query root of this schema */
export type QuerypatientConditionArgs = {
  id: Scalars["PatientConditionId"]["input"];
};

/** The query root of this schema */
export type QuerypatientConditionsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  instituteConditionId: InputMaybe<ReadonlyArray<Scalars["InstituteConditionId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patientId: InputMaybe<Scalars["PatientId"]["input"]>;
  sortBy: InputMaybe<PatientConditionSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<PatientConditionStatus>>;
};

/** The query root of this schema */
export type QuerypatientFlagArgs = {
  id: Scalars["PatientFlagId"]["input"];
};

/** The query root of this schema */
export type QuerypatientFlagsArgs = {
  active: InputMaybe<Scalars["Boolean"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  sortBy: InputMaybe<PatientFlagSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerypatientQualityIssueArgs = {
  id: Scalars["PatientQualityIssueId"]["input"];
};

/** The query root of this schema */
export type QuerypatientQualityIssuesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  createdAt: InputMaybe<DateRangeInput>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  issue: InputMaybe<ReadonlyArray<PatientQualityIssueType>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  organizationId: InputMaybe<ReadonlyArray<Scalars["OrganizationId"]["input"]>>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  providerId: InputMaybe<ReadonlyArray<Scalars["ProviderId"]["input"]>>;
  sortBy: InputMaybe<PatientQualityIssueSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<PatientQualityIssueStatus>>;
};

/** The query root of this schema */
export type QuerypatientSessionArgs = {
  id: Scalars["PatientSessionId"]["input"];
};

/** The query root of this schema */
export type QuerypatientSessionsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<PatientSessionSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<PatientSessionStatus>>;
  targetDate: InputMaybe<DateRangeInput>;
};

/** The query root of this schema */
export type QuerypatientsArgs = {
  activeOrganizations: InputMaybe<OrganizationFilter>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  cocmCareManager: InputMaybe<Scalars["ProviderId"]["input"]>;
  cocmPrimaryCareProvider: InputMaybe<Scalars["ProviderId"]["input"]>;
  enrollmentStatus: InputMaybe<EnrollmentStatus>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  flagIds: InputMaybe<ReadonlyArray<Scalars["FlagId"]["input"]>>;
  forCocmPanel: InputMaybe<PanelFilter>;
  forProvider: InputMaybe<ProviderFilter>;
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<PatientSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
  withAnyIdentifier: InputMaybe<Scalars["String"]["input"]>;
  withFilter: InputMaybe<PatientFilter>;
  withQualityIssue: InputMaybe<PatientQualityIssueFilter>;
};

/** The query root of this schema */
export type QuerypipbhcTreatmentGoalArgs = {
  id: Scalars["PipbhcTreatmentGoalId"]["input"];
};

/** The query root of this schema */
export type QuerypipbhcTreatmentGoalReportArgs = {
  id: Scalars["PipbhcTreatmentGoalReportId"]["input"];
};

/** The query root of this schema */
export type QuerypipbhcTreatmentGoalReportsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  smartSearch: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<PipbhcTreatmentGoalReportSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerypipbhcTreatmentGoalsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<PipbhcTreatmentGoalSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<PipbhcTreatmentGoalStatus>>;
};

/** The query root of this schema */
export type QueryproviderArgs = {
  id: Scalars["ProviderId"]["input"];
};

/** The query root of this schema */
export type QueryprovidersArgs = {
  active: InputMaybe<Scalars["Boolean"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  defaultRelationship: InputMaybe<ReadonlyArray<CareEpisodeProviderRelationship>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  measurementAllowed: InputMaybe<Scalars["Boolean"]["input"]>;
  practicing: InputMaybe<Scalars["Boolean"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<ProviderSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryschedulingAppointmentArgs = {
  id: Scalars["AppointmentId"]["input"];
};

/** The query root of this schema */
export type QueryschedulingAppointmentScheduleArgs = {
  id: Scalars["AppointmentId"]["input"];
};

/** The query root of this schema */
export type QueryschedulingAppointmentSchedulesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  flags: InputMaybe<ReadonlyArray<Scalars["FlagId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  measurementCategory: InputMaybe<ReadonlyArray<AppointmentMeasurementCategory>>;
  measurementStatus: InputMaybe<ReadonlyArray<AppointmentMeasurementStatus>>;
  patientFlagId: InputMaybe<ReadonlyArray<Scalars["PatientFlagId"]["input"]>>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  provider: InputMaybe<ProviderFilter>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<AppointmentScheduleSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  startDateAfter: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  startDateBefore: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  status: InputMaybe<ReadonlyArray<AppointmentStatus>>;
  testClient: InputMaybe<Scalars["Boolean"]["input"]>;
  unit: InputMaybe<OrganizationFilter>;
};

/** The query root of this schema */
export type QueryschedulingAppointmentsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careEpisodeId: InputMaybe<Scalars["CareEpisodeId"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  flags: InputMaybe<ReadonlyArray<Scalars["FlagId"]["input"]>>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  measurementStatus: InputMaybe<ReadonlyArray<AppointmentMeasurementStatus>>;
  patientFlagId: InputMaybe<ReadonlyArray<Scalars["PatientFlagId"]["input"]>>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  provider: InputMaybe<ProviderFilter>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<AppointmentSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  startDateAfter: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  startDateBefore: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  status: InputMaybe<ReadonlyArray<AppointmentStatus>>;
  testClient: InputMaybe<Scalars["Boolean"]["input"]>;
  unit: InputMaybe<OrganizationFilter>;
};

/** The query root of this schema */
export type QueryschedulingRecurrenceArgs = {
  id: Scalars["RecurrenceId"]["input"];
};

/** The query root of this schema */
export type QueryschedulingRecurrencesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]>>;
  sortBy: InputMaybe<RecurrenceSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QuerysupportTaskArgs = {
  shortcode: Scalars["String"]["input"];
};

/** The query root of this schema */
export type QuerysupportTaskHistoriesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  className: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<SupportTaskHistorySortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  userId: InputMaybe<Scalars["UserId"]["input"]>;
};

/** The query root of this schema */
export type QuerysupportTaskHistoryArgs = {
  id: Scalars["SupportTaskHistoryId"]["input"];
};

/** The query root of this schema */
export type QuerysurveyResponseArgs = {
  id: Scalars["ResponseId"]["input"];
};

/** The query root of this schema */
export type QuerysurveyResponsesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
};

/** The query root of this schema */
export type QuerytreatmentServiceArgs = {
  id: Scalars["TreatmentServiceId"]["input"];
};

/** The query root of this schema */
export type QuerytreatmentServicesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<TreatmentServiceSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryuserArgs = {
  id: Scalars["UserId"]["input"];
};

/** The query root of this schema */
export type QueryuserConsentArgs = {
  id: Scalars["UserConsentId"]["input"];
};

/** The query root of this schema */
export type QueryuserConsentsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  forPatient: InputMaybe<Scalars["PatientId"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<UserConsentSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

/** The query root of this schema */
export type QueryusersArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  isProvider: InputMaybe<Scalars["Boolean"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<UserSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

export type Question = {
  readonly __typename: "Question";
  readonly beneficial: Maybe<Scalars["Boolean"]["output"]>;
  readonly domain: Scalars["String"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["QuestionId"]["output"];
  readonly options: ReadonlyArray<QuestionOption>;
  readonly questionConstruct: QuestionConstruct;
  readonly questionType: QuestionType;
  readonly reportDisplayDetails: Maybe<QuestionDisplayDetails>;
  readonly reportText: Scalars["String"]["output"];
  readonly scales: ReadonlyArray<Scale>;
  /** Human readable identifier used for this question */
  readonly shortname: Scalars["String"]["output"];
  readonly title: Scalars["String"]["output"];
};

export type QuestionConstruct = {
  readonly __typename: "QuestionConstruct";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["QuestionConstructId"]["output"];
  readonly mnemonic: Scalars["String"]["output"];
  readonly questions: ReadonlyArray<Question>;
  readonly reportText: Scalars["String"]["output"];
};

export type QuestionDisplayDetails = {
  readonly __typename: "QuestionDisplayDetails";
  /** Whether to use the per-question-type preconfigured display */
  readonly automatic: Maybe<Scalars["Boolean"]["output"]>;
  /** Left label of a likert scale */
  readonly leftLabel: Maybe<Scalars["String"]["output"]>;
  /** Left value of a likert scale */
  readonly leftValue: Maybe<Scalars["Int"]["output"]>;
  /** Right label of a likert scale */
  readonly rightLabel: Maybe<Scalars["String"]["output"]>;
  /** Right value of a likert scale */
  readonly rightValue: Maybe<Scalars["Int"]["output"]>;
  /** The types of value display allowed */
  readonly valueModes: Maybe<ReadonlyArray<Scalars["String"]["output"]>>;
};

export type QuestionOption = {
  readonly __typename: "QuestionOption";
  readonly endorsed: Maybe<Scalars["Boolean"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["QuestionOptionId"]["output"];
  readonly index: Scalars["Int"]["output"];
  readonly itemCoding: Maybe<ItemCoding>;
  readonly normalizedScore: Maybe<NormalizedScore>;
  readonly question: Question;
  /** A simplified version of the option used for displaying in the clinical report */
  readonly reportText: Maybe<Scalars["String"]["output"]>;
  readonly title: Scalars["String"]["output"];
  readonly value: Scalars["String"]["output"];
};

/** The type of a question */
export enum QuestionType {
  /** List of checkbox options */
  CHECKBOX = "CHECKBOX",
  /** This question will be displayed by a fully custom component */
  CUSTOM = "CUSTOM",
  /** A checkbox that instead of setting one variable with multiple answers, sets a series of variables one per answer */
  DYNAMIC_VARIABLE_CHECKBOX = "DYNAMIC_VARIABLE_CHECKBOX",
  /** Longer essay input */
  ESSAY = "ESSAY",
  /** An HTML slider */
  FULL_SLIDER = "FULL_SLIDER",
  /** Hidden */
  HIDDEN = "HIDDEN",
  /** instructions */
  INSTRUCTOINS = "INSTRUCTOINS",
  /** Select a month and a year */
  MONTH_YEAR = "MONTH_YEAR",
  /** Multiple text boxes, used for some custom questionnaires */
  MULTI_TEXTBOX = "MULTI_TEXTBOX",
  /** Radio button */
  RADIO = "RADIO",
  /** HTML Select */
  SELECT = "SELECT",
  /** Slightly misnmaed - this is a TA widget for the NORSE */
  SLIDER = "SLIDER",
  /** Show a table that can be filled in */
  TABLE = "TABLE",
  /** Simple short textbox */
  TEXTBOX = "TEXTBOX",
  /** Select a range of years */
  YEAR_RANGE = "YEAR_RANGE",
}

export type QuickText = {
  readonly __typename: "QuickText";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["QuickTextId"]["output"];
  readonly order: Scalars["Int"]["output"];
  readonly text: Maybe<Scalars["String"]["output"]>;
  readonly title: Scalars["String"]["output"];
};

/** The connection type for QuickText. */
export type QuickTextConnection = {
  readonly __typename: "QuickTextConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<QuickTextEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<QuickText>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type QuickTextEdge = {
  readonly __typename: "QuickTextEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: QuickText;
};

export enum QuickTextSortParameter {
  /** Id */
  ID = "ID",
}

/** Autogenerated input type of QuickTrackUnknownTask */
export type QuickTrackUnknownTaskInput = {
  readonly addToDefaultList?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly patientId: Scalars["PatientId"]["input"];
};

/** Autogenerated return type of QuickTrackUnknownTask. */
export type QuickTrackUnknownTaskPayload = {
  readonly __typename: "QuickTrackUnknownTaskPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<QuickTrackUnknownTaskResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type QuickTrackUnknownTaskResult = {
  readonly __typename: "QuickTrackUnknownTaskResult";
  readonly myActiveTimeEntryLog: MyActiveTimeEntryLog;
  readonly task: Task;
};

/** Autogenerated input type of ReactivatePreviousEnrollment */
export type ReactivatePreviousEnrollmentInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly enrollmentId: Scalars["EnrollmentId"]["input"];
};

/** Autogenerated return type of ReactivatePreviousEnrollment. */
export type ReactivatePreviousEnrollmentPayload = {
  readonly __typename: "ReactivatePreviousEnrollmentPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<ReactivatePreviousEnrollmentResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type ReactivatePreviousEnrollmentResult = {
  readonly __typename: "ReactivatePreviousEnrollmentResult";
  readonly enrollment: Enrollment;
};

export type Recurrence = {
  readonly __typename: "Recurrence";
  readonly endDate: Scalars["ISO8601Date"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["RecurrenceId"]["output"];
  readonly patient: Patient;
  readonly patientSessions: ReadonlyArray<PatientSession>;
  readonly repeatFrequency: RecurrenceFrequency;
};

/** The connection type for Recurrence. */
export type RecurrenceConnection = {
  readonly __typename: "RecurrenceConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<RecurrenceEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Recurrence>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type RecurrenceEdge = {
  readonly __typename: "RecurrenceEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Recurrence;
};

/** How often an appointment is repeated */
export enum RecurrenceFrequency {
  /** The appointment repeats anually */
  ANNUALLY = "ANNUALLY",
  /** The appointment repeats twice per year */
  BIANNUALLY = "BIANNUALLY",
  /** The appointment repeats every two months */
  BIMONTHLY = "BIMONTHLY",
  /** The appointment repeats every two weeks */
  BIWEEKLY = "BIWEEKLY",
  /** The appointment repeats every day */
  DAILY = "DAILY",
  /** The appointment repeats monthly */
  MONTHLY = "MONTHLY",
  /** The appointment does not repeat */
  NONE = "NONE",
  /** The appointment repeats once per quarter */
  QUARTERLY = "QUARTERLY",
  /** The appointment repeats every weekday */
  WEEKDAYS = "WEEKDAYS",
  /** The appointment repeats once per week */
  WEEKLY = "WEEKLY",
}

export enum RecurrenceSortParameter {
  /** Id */
  ID = "ID",
}

export type RelatedPerson = Participant & {
  readonly __typename: "RelatedPerson";
  readonly active: Scalars["Boolean"]["output"];
  readonly email: Maybe<Scalars["String"]["output"]>;
  readonly firstName: Maybe<Scalars["String"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["RelatedPersonId"]["output"];
  readonly lastName: Maybe<Scalars["String"]["output"]>;
  readonly name: Scalars["String"]["output"];
  readonly notificationPreference: Maybe<NotificationPreference>;
  readonly notificationPreferenceWithDefault: NotificationPreference;
  readonly participantId: Scalars["ParticipantId"]["output"];
  readonly phoneNumber: Maybe<Scalars["String"]["output"]>;
  readonly relatedPersonRelationship: RelatedPersonRelationship;
  readonly relationship: ParticipantRelationship;
  readonly user: User;
};

export enum RelatedPersonRelationship {
  /** Child */
  CHILD = "CHILD",
  /** Father */
  FATHER = "FATHER",
  /** Guardian/caregiver */
  GUARDIAN = "GUARDIAN",
  /** Mother */
  MOTHER = "MOTHER",
  /** Other */
  OTHER = "OTHER",
  /** Teacher */
  TEACHER = "TEACHER",
}

/** Autogenerated input type of RemoveConsultMeetingAttendee */
export type RemoveConsultMeetingAttendeeInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly consultMeetingAttendeeId: Scalars["ConsultMeetingAttendeeId"]["input"];
};

/** Autogenerated return type of RemoveConsultMeetingAttendee. */
export type RemoveConsultMeetingAttendeePayload = {
  readonly __typename: "RemoveConsultMeetingAttendeePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<RemoveConsultMeetingAttendeeResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type RemoveConsultMeetingAttendeeResult = {
  readonly __typename: "RemoveConsultMeetingAttendeeResult";
  readonly consultMeeting: ConsultMeeting;
};

/** Autogenerated input type of RemoveInstituteFromInstituteGroup */
export type RemoveInstituteFromInstituteGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly instituteGroupId: Scalars["InstituteGroupId"]["input"];
  readonly memberId: Scalars["InstituteId"]["input"];
};

/** Autogenerated return type of RemoveInstituteFromInstituteGroup. */
export type RemoveInstituteFromInstituteGroupPayload = {
  readonly __typename: "RemoveInstituteFromInstituteGroupPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<RemoveInstituteFromInstituteGroupResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type RemoveInstituteFromInstituteGroupResult = {
  readonly __typename: "RemoveInstituteFromInstituteGroupResult";
  readonly instituteGroup: InstituteGroup;
};

/** Autogenerated input type of RemoveTaskListItemFromDefaultTaskList */
export type RemoveTaskListItemFromDefaultTaskListInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly taskListItemId: Scalars["TaskListItemId"]["input"];
};

/** Autogenerated return type of RemoveTaskListItemFromDefaultTaskList. */
export type RemoveTaskListItemFromDefaultTaskListPayload = {
  readonly __typename: "RemoveTaskListItemFromDefaultTaskListPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<RemoveTaskListItemFromDefaultTaskListResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type RemoveTaskListItemFromDefaultTaskListResult = {
  readonly __typename: "RemoveTaskListItemFromDefaultTaskListResult";
  readonly taskList: TaskList;
};

/** Autogenerated input type of RemoveTreatmentTarget */
export type RemoveTreatmentTargetInput = {
  readonly careEpisodeTreatmentTargetId: Scalars["CareEpisodeTreatmentTargetId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of RemoveTreatmentTarget. */
export type RemoveTreatmentTargetPayload = {
  readonly __typename: "RemoveTreatmentTargetPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<RemoveTreatmentTargetResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type RemoveTreatmentTargetResult = {
  readonly __typename: "RemoveTreatmentTargetResult";
  readonly careEpisodeTreatmentTarget: CareEpisodeTreatmentTarget;
};

/** Autogenerated input type of ReplanAllActiveEnrollment */
export type ReplanAllActiveEnrollmentInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly instituteId: Scalars["InstituteId"]["input"];
};

/** Autogenerated return type of ReplanAllActiveEnrollment. */
export type ReplanAllActiveEnrollmentPayload = {
  readonly __typename: "ReplanAllActiveEnrollmentPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<ReplanAllActiveEnrollmentResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type ReplanAllActiveEnrollmentResult = {
  readonly __typename: "ReplanAllActiveEnrollmentResult";
  readonly monitoredJob: MonitoredJob;
};

export type Report = {
  readonly __typename: "Report";
  readonly answers: ReadonlyArray<Answer>;
  readonly careEpisode: CareEpisode;
  readonly computedValues: ReadonlyArray<ComputedValue>;
  readonly downloadAvailable: Scalars["Boolean"]["output"];
  readonly downloadUrl: Maybe<Scalars["String"]["output"]>;
  readonly effectiveDate: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly goalAnswers: ReadonlyArray<GoalAnswer>;
  readonly id: Scalars["ReportId"]["output"];
  readonly invitations: ReadonlyArray<Invitation>;
  readonly patient: Patient;
  readonly patientLoginMethodUsed: Maybe<InvitationLoginMethod>;
  readonly patientSession: Maybe<PatientSession>;
  readonly pdfContentBase64: Maybe<Scalars["String"]["output"]>;
  readonly reportScales: ReadonlyArray<ReportScale>;
  readonly reportText: ReportText;
  readonly scaleParticipants: ReadonlyArray<ReportScaleParticipant>;
  readonly scales: ReadonlyArray<Scale>;
  readonly status: ReportStatus;
};

/** The connection type for Report. */
export type ReportConnection = {
  readonly __typename: "ReportConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ReportEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Report>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ReportEdge = {
  readonly __typename: "ReportEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Report;
};

export type ReportEntityTreeNode = {
  readonly __typename: "ReportEntityTreeNode";
  readonly entityTreeCollections: ReadonlyArray<EntityTreeCollection>;
  readonly entityTreeNode: EntityTreeNode;
  readonly graphInterval: Maybe<DrilldownMetricGraphInterval>;
  readonly permittedChildren: ReadonlyArray<EntityType>;
  readonly summaryData: ReadonlyArray<ReportSummaryDataField>;
  readonly summaryGraphData: Maybe<ReadonlyArray<ReportSummaryGraphData>>;
};

export type ReportProviderObligations = {
  readonly __typename: "ReportProviderObligations";
  readonly globalId: Scalars["ID"]["output"];
  readonly obligations: ReadonlyArray<ReportProviderObligationsEnum>;
  readonly providerId: Scalars["ProviderId"]["output"];
  readonly reportId: Scalars["ReportId"]["output"];
};

/** What obligations a provider has for a given report. */
export enum ReportProviderObligationsEnum {
  /** The provider has no obligations for this report */
  NONE = "NONE",
  /** The provider needs to view this report */
  VIEW = "VIEW",
}

export type ReportScale = {
  readonly __typename: "ReportScale";
  readonly blinding: ScaleBlinding;
  /** Which measurement plans were involved for this report scale. Expensive to calculate so beware */
  readonly careMeasurementPlanScales: ReadonlyArray<MeasurementPlanScale>;
  readonly defaultParticipants: Maybe<Scalars["Boolean"]["output"]>;
  /** This scale has been removed by a user and should not be administered */
  readonly disabled: Scalars["Boolean"]["output"];
  readonly displayOrder: Maybe<Scalars["Int"]["output"]>;
  readonly format: InvitationFormat;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ReportScaleId"]["output"];
  /** This scale has been set or removed by a user and should not be altered by autoplanning */
  readonly manual: Scalars["Boolean"]["output"];
  readonly phase: InvitationPhase;
  readonly planningDecision: Maybe<ReportScalePlanningDecision>;
  readonly planningReason: Maybe<Scalars["String"]["output"]>;
  readonly report: Report;
  readonly scale: Scale;
  readonly scaleParticipants: ReadonlyArray<ReportScaleParticipant>;
};

export type ReportScaleParticipant = {
  readonly __typename: "ReportScaleParticipant";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ReportScaleParticipantId"]["output"];
  readonly invitation: Invitation;
  readonly reportScale: ReportScale;
  readonly user: User;
};

/** The status of the planning engine to determine whether a scale should be administered */
export enum ReportScalePlanningDecision {
  /** The scale will be administered */
  ADMINISTER = "ADMINISTER",
  /** The scale is part of an alternation group and was not selected this time */
  ALTERNATION = "ALTERNATION",
  /** The scale has hit its maximum session and has been discontinued */
  DISCONTINUE_SESSION = "DISCONTINUE_SESSION",
  /** The scale has hit the maximum elapsed administration time and will not be administered again */
  DISCONTINUE_TIME = "DISCONTINUE_TIME",
  /** The scale has a maximum frequency of administration and this sesssion is too close */
  MAX_REPEAT_INTERVAL = "MAX_REPEAT_INTERVAL",
  /** The scale was previously scheduled but the plan that uses it is no longer active */
  NO_LONGER_IN_PLAN = "NO_LONGER_IN_PLAN",
  /** The scale has already been administered and will not repeat */
  NO_REPEAT = "NO_REPEAT",
  /** The scale is configured to never be administered without other scales present */
  NO_SOLO_ADMINISTRATION = "NO_SOLO_ADMINISTRATION",
  /** The scale will not be administered because the onset session has not happened yet */
  ONSET_SESSION = "ONSET_SESSION",
  /** The scale will not be administered because the onset time has not happened yet */
  ONSET_TIME = "ONSET_TIME",
  /** The scale is not scheduled to repeat this session */
  REPEAT = "REPEAT",
}

export enum ReportSortParameter {
  /** Effective date */
  EFFECTIVE_DATE = "EFFECTIVE_DATE",
  /** Id */
  ID = "ID",
}

/** The status of a report */
export enum ReportStatus {
  /** Report was completed */
  COMPLETE = "COMPLETE",
  /** Report was never finished */
  INCOMPLETE = "INCOMPLETE",
  /** No data present, not started */
  NEW = "NEW",
  /** Some data present but others missing */
  PARTIAL = "PARTIAL",
}

export type ReportSummaryDataField = {
  readonly __typename: "ReportSummaryDataField";
  readonly name: Scalars["String"]["output"];
  readonly value: Maybe<Scalars["Float"]["output"]>;
};

export type ReportSummaryGraphData = {
  readonly __typename: "ReportSummaryGraphData";
  readonly name: Scalars["String"]["output"];
  readonly points: ReadonlyArray<ChartData>;
};

export type ReportText = {
  readonly __typename: "ReportText";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ReportTextId"]["output"];
  readonly plainText: Scalars["String"]["output"];
  readonly report: Report;
};

/** Autogenerated input type of RequestCaseConsult */
export type RequestCaseConsultInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly enrollmentId: Scalars["EnrollmentId"]["input"];
  readonly onBehalfOf?: InputMaybe<Scalars["ProviderId"]["input"]>;
  readonly requestReason: ConsultRequestReason;
  readonly requestText?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of RequestCaseConsult. */
export type RequestCaseConsultPayload = {
  readonly __typename: "RequestCaseConsultPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<RequestCaseConsultResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type RequestCaseConsultResult = {
  readonly __typename: "RequestCaseConsultResult";
  readonly consultRequest: ConsultRequest;
};

export type RequiredPanelTeamMemberIds = {
  readonly cmTeamMemberId: Scalars["PanelTeamMemberId"]["input"];
  readonly pcpTeamMemberId: Scalars["PanelTeamMemberId"]["input"];
};

/** Autogenerated input type of ResendAllInvitationNotifications */
export type ResendAllInvitationNotificationsInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly patientId: Scalars["PatientId"]["input"];
};

/** Autogenerated return type of ResendAllInvitationNotifications. */
export type ResendAllInvitationNotificationsPayload = {
  readonly __typename: "ResendAllInvitationNotificationsPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<ResendAllInvitationNotificationsResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type ResendAllInvitationNotificationsResult = {
  readonly __typename: "ResendAllInvitationNotificationsResult";
  readonly patientSession: PatientSession;
};

export type ResendInvitationNotificationResult = {
  readonly __typename: "ResendInvitationNotificationResult";
  readonly invitation: Invitation;
  readonly newNotificationSent: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of ResendInvitationNotifications */
export type ResendInvitationNotificationsInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly invitationId: Scalars["InvitationId"]["input"];
};

/** Autogenerated return type of ResendInvitationNotifications. */
export type ResendInvitationNotificationsPayload = {
  readonly __typename: "ResendInvitationNotificationsPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<ResendInvitationNotificationResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type Response = {
  readonly __typename: "Response";
  readonly currentLanguage: Maybe<Scalars["String"]["output"]>;
  readonly currentModuleNum: Maybe<Scalars["Int"]["output"]>;
  readonly currentPageNum: Maybe<Scalars["Int"]["output"]>;
  readonly currentQuestionNum: Maybe<Scalars["Int"]["output"]>;
  readonly endTime: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ResponseId"]["output"];
  readonly invite: Invite;
  readonly responseItems: ReadonlyArray<ResponseItem>;
  readonly startTime: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly status: ResponseStatus;
};

/** The connection type for Response. */
export type ResponseConnection = {
  readonly __typename: "ResponseConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ResponseEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Response>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ResponseEdge = {
  readonly __typename: "ResponseEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Response;
};

export type ResponseItem = {
  readonly __typename: "ResponseItem";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ResponseItemId"]["output"];
  readonly optionId: Maybe<Scalars["String"]["output"]>;
  readonly overriddenItems: Maybe<ReadonlyArray<ResponseItem>>;
  readonly overridingItem: Maybe<ResponseItem>;
  readonly questionDomain: Maybe<Scalars["String"]["output"]>;
  readonly questionId: Maybe<Scalars["String"]["output"]>;
  readonly questionMnemonic: Maybe<Scalars["String"]["output"]>;
  readonly response: Response;
  readonly value: Maybe<Scalars["String"]["output"]>;
};

export enum ResponseStatus {
  /** Completed */
  COMPLETE = "COMPLETE",
  /** Currently in progress */
  IN_PROGRESS = "IN_PROGRESS",
  /** Not started yet */
  NEW = "NEW",
  /** Started but left incomplete */
  PARTIAL = "PARTIAL",
}

/** Whether this observation has individual item responses available */
export enum ResponsesAvailable {
  /** This computed value has item responses available */
  AVAILABLE = "AVAILABLE",
  /** This computed value only has the score available and no items */
  SCORE_ONLY = "SCORE_ONLY",
}

/** Autogenerated input type of RunSupportTask */
export type RunSupportTaskInput = {
  readonly arguments: ReadonlyArray<SupportTaskArgumentInput>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly dryRun: Scalars["Boolean"]["input"];
  readonly shortcode: Scalars["String"]["input"];
};

/** Autogenerated return type of RunSupportTask. */
export type RunSupportTaskPayload = {
  readonly __typename: "RunSupportTaskPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<SupportTaskResult>;
  readonly success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of RunWorkflowAction */
export type RunWorkflowActionInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly triggeredWorkflowActionId: Scalars["TriggeredWorkflowActionId"]["input"];
};

/** Autogenerated return type of RunWorkflowAction. */
export type RunWorkflowActionPayload = {
  readonly __typename: "RunWorkflowActionPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<RunWorkflowActionResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type RunWorkflowActionResult = {
  readonly __typename: "RunWorkflowActionResult";
  readonly triggeredWorkflow: TriggeredWorkflow;
};

/** Autogenerated input type of SaveConsultReviewNote */
export type SaveConsultReviewNoteInput = {
  readonly caseConsultId: Scalars["CaseConsultId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly text: Scalars["String"]["input"];
  readonly type: ConsultReviewNote;
};

/** Autogenerated return type of SaveConsultReviewNote. */
export type SaveConsultReviewNotePayload = {
  readonly __typename: "SaveConsultReviewNotePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<SaveConsultReviewNoteResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type SaveConsultReviewNoteResult = {
  readonly __typename: "SaveConsultReviewNoteResult";
  readonly caseConsult: CaseConsult;
};

export type Scale = {
  readonly construct: Maybe<Construct>;
  readonly description: Maybe<Scalars["String"]["output"]>;
  /** If this scale is allowed to be administered */
  readonly disabled: Scalars["Boolean"]["output"];
  readonly displayData: Maybe<ScaleDisplayData>;
  readonly friendlyName: Maybe<Scalars["String"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ScaleId"]["output"];
  /** Can this scale be directly administered? */
  readonly independent: Scalars["Boolean"]["output"];
  readonly measureType: ScaleMeasureType;
  readonly mnemonic: Scalars["String"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly nanoname: Maybe<Scalars["String"]["output"]>;
  readonly norms: ReadonlyArray<Norm>;
  readonly parent: Maybe<Scale>;
  readonly parentId: Maybe<Scalars["ScaleId"]["output"]>;
  readonly questions: ReadonlyArray<Question>;
  /** Short description for use inside clinical report */
  readonly reportShortDescription: Maybe<Scalars["String"]["output"]>;
  readonly role: Maybe<ScaleRole>;
  readonly scaleGroup: Maybe<ScaleGroup>;
  readonly scaleScorers: ReadonlyArray<ScaleScorer>;
  readonly scoringText: Maybe<Scalars["String"]["output"]>;
  readonly shortname: Maybe<Scalars["String"]["output"]>;
};

/** How a scale might be blinded from being shown */
export enum ScaleBlinding {
  /** Scale results are available only in exports */
  EXPORT_ONLY = "EXPORT_ONLY",
  /** Scale results are fully visible */
  UNBLINDED = "UNBLINDED",
}

/** The connection type for Scale. */
export type ScaleConnection = {
  readonly __typename: "ScaleConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ScaleEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Scale>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/**
 * How to display the scale - which group it is in, etc. How to display individual scores is governed by the
 * display rules for each scale scorer.
 */
export type ScaleDisplayData = {
  readonly __typename: "ScaleDisplayData";
  /** A priority for the display of the scale group */
  readonly order: Maybe<Scalars["Int"]["output"]>;
  /** If this scale requires different treatment for note generation, it can be specified here. */
  readonly synopsis: Maybe<ScaleSynopsis>;
};

/** An edge in a connection. */
export type ScaleEdge = {
  readonly __typename: "ScaleEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Scale;
};

export type ScaleGroup = {
  readonly __typename: "ScaleGroup";
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly displayMode: ScaleGroupDisplayDataType;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ScaleGroupId"]["output"];
  readonly mnemonic: Scalars["String"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly scales: ReadonlyArray<Scale>;
  readonly shortname: Scalars["String"]["output"];
};

/** The connection type for ScaleGroup. */
export type ScaleGroupConnection = {
  readonly __typename: "ScaleGroupConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ScaleGroupEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<ScaleGroup>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** How to display this group of scales */
export enum ScaleGroupDisplayDataType {
  /** Alert group not meant for general display */
  ALERT = "ALERT",
  /** All scales are rendered on a single graph */
  COMBINED = "COMBINED",
  /** An headline numerical scale with subscales */
  GRAPH_WITH_SUBSCALES = "GRAPH_WITH_SUBSCALES",
  /** Each scale should be shown individually */
  INDIVIDUAL = "INDIVIDUAL",
  /** A headline interpreted (non-numerical) scale with subscales */
  INTERPRETED_WITH_SUBSCALES = "INTERPRETED_WITH_SUBSCALES",
  /** Special display mode for the SFSS */
  SFSS = "SFSS",
}

/** An edge in a connection. */
export type ScaleGroupEdge = {
  readonly __typename: "ScaleGroupEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: ScaleGroup;
};

/** The language supported by a scale */
export enum ScaleLocale {
  /** English */
  EN = "EN",
  /** Spanish */
  ES = "ES",
}

/** The "type" of the scale. This is mostly a legacy field that will be replaced in future versions */
export enum ScaleMeasureType {
  /** Treat as administrative only, no scoring */
  ADMINISTRATIVE = "ADMINISTRATIVE",
  /** Is an aggregate scale which represents a value from multiple different scales */
  AGGREGATE = "AGGREGATE",
  /** Is not an administrable scale but is an alert */
  ALERT = "ALERT",
  /** Scale is not administered directly but instead is a battery of other scales */
  BATTERY = "BATTERY",
  /** Part of the DSM5 battery */
  DSM5 = "DSM5",
  /** Special scale type for Mclean EMAP study */
  EMAP_MCQ = "EMAP_MCQ",
  /** DEPRECATED: Used as a rollup for an entire patient, e.g. severity  */
  INDICATOR = "INDICATOR",
  /** A special scale that should not be generally directly displayed */
  MODULE = "MODULE",
  /** Member of the NORSE */
  NORSE = "NORSE",
  /** Member of the PTPB */
  PTPB = "PTPB",
  /** Scale is a recent changes set */
  RECENT_CHANGES = "RECENT_CHANGES",
  /** A social determinants of health scale */
  SDOH = "SDOH",
  /** Part of the SDQ */
  SDQ = "SDQ",
  /** A standard scale */
  STANDARD = "STANDARD",
}

/** What role this scale has - e.g. a total score, subscale, etc */
export enum ScaleRole {
  /** Administrative only, has no scoring */
  ADMINISTRATIVE = "ADMINISTRATIVE",
  /** A data aggregate which can be used to display data from different scales/norms */
  AGGREGATE = "AGGREGATE",
  /** Used as an alert rather than a scale */
  ALERT = "ALERT",
  /** represents a subscale result of multiple different participants */
  COMBINED_SUBSCALE = "COMBINED_SUBSCALE",
  /** represents the total result of multiple different participants,  E.g. SfSS Combined med concerns */
  COMBINED_TOTAL = "COMBINED_TOTAL",
  /** A custom scale that is used as a placeholder for other functionality */
  CUSTOM = "CUSTOM",
  /** An intermediate result which should not be directly displayed */
  INTERMEDIATE = "INTERMEDIATE",
  /** A subscale on a per-respondent scale */
  PER_RESPONDENT_SUBSCALE = "PER_RESPONDENT_SUBSCALE",
  /** per_respondent_total */
  PER_RESPONDENT_TOTAL = "PER_RESPONDENT_TOTAL",
}

export type ScaleScorer = {
  readonly domain: ScaleScorerDomain;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ScaleScorerId"]["output"];
  /** Which other values are used to generate this score */
  readonly inputDomains: Maybe<ReadonlyArray<Scalars["String"]["output"]>>;
  readonly name: Scalars["String"]["output"];
  readonly scale: Scale;
  readonly scoreType: ScaleScorerType;
  readonly supportedSeverityCategories: ReadonlyArray<SeverityCategory>;
  readonly supportedTrends: ReadonlyArray<Trend>;
};

/** The connection type for ScaleScorer. */
export type ScaleScorerConnection = {
  readonly __typename: "ScaleScorerConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ScaleScorerEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<ScaleScorer>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** The domain type of the scorer */
export enum ScaleScorerDomain {
  /** Currently only used as part of goals scoring, possibly obsolete */
  AVERAGE = "AVERAGE",
  /** Internal Mirah scorer type for showing subject complexity */
  COMPLEXITY = "COMPLEXITY",
  /** Combined of many other data, currently used by the CAMS */
  COMPOSITE = "COMPOSITE",
  /** A categorical score with no numerical meaning */
  INTERPRETED = "INTERPRETED",
  /** Based on normative data for a population */
  NORMED = "NORMED",
  /** Used to set the per-patient adjustment on the CAMS */
  PATIENT_ADJUSTMENT = "PATIENT_ADJUSTMENT",
  /** Raw score value */
  RAW = "RAW",
  /** Internal Mirah scorer type for showing subject severity */
  SEVERITY = "SEVERITY",
}

/** An edge in a connection. */
export type ScaleScorerEdge = {
  readonly __typename: "ScaleScorerEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: ScaleScorer;
};

export enum ScaleScorerSortParameter {
  /** Id */
  ID = "ID",
  /** Scale Name */
  SCALE_NAME = "SCALE_NAME",
}

/** The language supported by a scale */
export enum ScaleScorerType {
  /** Scorer is one of a set of categories */
  CATEGORICAL = "CATEGORICAL",
  /** Scorer is a number */
  NUMERICAL = "NUMERICAL",
  /** Scorer has no scored meaning and is administrative */
  UNSCORED = "UNSCORED",
}

export type ScaleScorerWithUsage = {
  readonly __typename: "ScaleScorerWithUsage";
  readonly careEpisodeCount: Scalars["Int"]["output"];
  readonly scale: Scale;
  readonly scaleScorer: ScaleScorer;
  readonly sessionCount: Scalars["Int"]["output"];
};

/** The connection type for ScaleScorerWithUsage. */
export type ScaleScorerWithUsageConnection = {
  readonly __typename: "ScaleScorerWithUsageConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<ScaleScorerWithUsageEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<ScaleScorerWithUsage>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type ScaleScorerWithUsageEdge = {
  readonly __typename: "ScaleScorerWithUsageEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: ScaleScorerWithUsage;
};

export enum ScaleSortParameter {
  /** Estimated completion time */
  ESTIMATED_TIME = "ESTIMATED_TIME",
  /** Id */
  ID = "ID",
  /** Name */
  NAME = "NAME",
}

/**
 * How to display the scale when in clinical note generation. This exists for special overrides over the
 * existing system.
 */
export type ScaleSynopsis = {
  readonly __typename: "ScaleSynopsis";
  /** A different description for the scale, in comparison to the one used in tool. */
  readonly description: Maybe<Scalars["String"]["output"]>;
  /** Completely hide this scale from note generation. */
  readonly hide: Maybe<Scalars["Boolean"]["output"]>;
  /** A different name for the scale to be used only in note generation. */
  readonly name: Maybe<Scalars["String"]["output"]>;
};

/** Whether a scale is targeted as a goal of treatment */
export enum ScaleTargeting {
  /** This scale measures general functioning */
  GENERAL_FUNCTIONING = "GENERAL_FUNCTIONING",
  /** This scale is single most important target. Only one per care episode */
  HEADLINE = "HEADLINE",
  /** This scale is not the target of treatment */
  NOT_TARGETED = "NOT_TARGETED",
  /** This scale is a subscale of a targeted scale */
  SUBSCALE_OF_TARGET = "SUBSCALE_OF_TARGET",
  /** This scale is the target of treatment */
  TARGETED = "TARGETED",
}

/**
 * A clinical cutoff for a scale. Note that the maxValue is exclusive for all except
 * the final item of a set, where it is considered to be inclusive. This allows us
 * to specify a maximum range effectively.
 */
export type ScaleThreshold = {
  readonly __typename: "ScaleThreshold";
  /** The maximum value of this threshold */
  readonly maxValue: Scalars["Float"]["output"];
  /** The minimum value of this threshold */
  readonly minValue: Scalars["Float"]["output"];
  /** A string to be used as a title */
  readonly mnemonic: Scalars["String"]["output"];
  /** The threshold class, defining severity */
  readonly thresholdClass: ScaleThresholdClass;
};

/** A color-based representation of severity */
export enum ScaleThresholdClass {
  /** Continuous */
  CONTINUOUS = "CONTINUOUS",
  /** low */
  LOW = "LOW",
  /** mild */
  MILD = "MILD",
  /** Moderate  */
  MODERATE = "MODERATE",
  /** Moderately Severe */
  MOD_SEVERE = "MOD_SEVERE",
  /** Not present if a symptom, strong if a strength */
  NONE = "NONE",
  /** Severe  */
  SEVERE = "SEVERE",
  /** Unanswered */
  UNANSWERED = "UNANSWERED",
  /** Unknown */
  UNKNOWN = "UNKNOWN",
}

/** Autogenerated input type of SendImmediateSurvey */
export type SendImmediateSurveyInput = {
  readonly administrationChoice?: InputMaybe<InvitationFormat>;
  readonly careEpisodeId: Scalars["CareEpisodeId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly scaleIds: ReadonlyArray<Scalars["ScaleId"]["input"]>;
};

/** Autogenerated return type of SendImmediateSurvey. */
export type SendImmediateSurveyPayload = {
  readonly __typename: "SendImmediateSurveyPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<SendImmediateSurveyResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type SendImmediateSurveyResult = {
  readonly __typename: "SendImmediateSurveyResult";
  readonly patientSession: PatientSession;
};

/** Autogenerated input type of SendInvitationNotifications */
export type SendInvitationNotificationsInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly invitationId: Scalars["InvitationId"]["input"];
};

/** Autogenerated return type of SendInvitationNotifications. */
export type SendInvitationNotificationsPayload = {
  readonly __typename: "SendInvitationNotificationsPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<SendInvitationNotificationsResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type SendInvitationNotificationsResult = {
  readonly __typename: "SendInvitationNotificationsResult";
  readonly patientSession: PatientSession;
};

/** Autogenerated input type of SetIntakeScaleAdministrationChoice */
export type SetIntakeScaleAdministrationChoiceInput = {
  readonly choices: ReadonlyArray<IntakeScaleAdministrationChoice>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly enrollmentId: Scalars["EnrollmentId"]["input"];
};

/** Autogenerated return type of SetIntakeScaleAdministrationChoice. */
export type SetIntakeScaleAdministrationChoicePayload = {
  readonly __typename: "SetIntakeScaleAdministrationChoicePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<SetIntakeScaleAdministrationChoiceResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type SetIntakeScaleAdministrationChoiceResult = {
  readonly __typename: "SetIntakeScaleAdministrationChoiceResult";
  readonly enrollment: Enrollment;
};

/** Autogenerated input type of SetPatientFlag */
export type SetPatientFlagInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly flagId: Scalars["FlagId"]["input"];
  readonly notes?: InputMaybe<Scalars["String"]["input"]>;
  readonly patientId: Scalars["PatientId"]["input"];
};

/** Autogenerated return type of SetPatientFlag. */
export type SetPatientFlagPayload = {
  readonly __typename: "SetPatientFlagPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<SetPatientFlagResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type SetPatientFlagResult = {
  readonly __typename: "SetPatientFlagResult";
  readonly patientFlag: Maybe<PatientFlag>;
};

/** Autogenerated input type of SetTaskStatus */
export type SetTaskStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly noteText?: InputMaybe<Scalars["String"]["input"]>;
  readonly status: TaskStatus;
  readonly taskId: Scalars["TaskId"]["input"];
};

/** Autogenerated return type of SetTaskStatus. */
export type SetTaskStatusPayload = {
  readonly __typename: "SetTaskStatusPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<SetTaskStatusResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type SetTaskStatusResult = {
  readonly __typename: "SetTaskStatusResult";
  readonly task: Task;
};

export type SeverityBadge = {
  readonly __typename: "SeverityBadge";
  readonly alert: Maybe<CareEpisodeClinicalAlertHistory>;
  readonly generalFunctioning: Maybe<CareEpisodeComputedValue>;
  readonly globalId: Scalars["ID"]["output"];
  readonly target: Maybe<CareEpisodeComputedValue>;
};

/** A quantized representation of clinical severity for a score */
export enum SeverityCategory {
  /** The result was not valid and could not be used */
  INVALID = "INVALID",
  /** Mild */
  MILD = "MILD",
  /** Moderate */
  MODERATE = "MODERATE",
  /** Non-clinical */
  NONE = "NONE",
  /** There is not enough data or the algorithm chose not to evaluate */
  NOT_SPECIFIED = "NOT_SPECIFIED",
  /** Acute change is not supported */
  NOT_SUPPORTED = "NOT_SUPPORTED",
  /** Severe */
  SEVERE = "SEVERE",
}

/** The algorithms available for scoring clinical severity */
export enum SeverityCategoryType {
  /** Automatically calculated based on specified clinical thresholds. There may be some ambiguity if some scales  */
  THRESHOLD = "THRESHOLD",
}

export type ShortTaskDescription = {
  readonly body: Scalars["String"]["input"];
  readonly dueAt: Scalars["ISO8601DateTime"]["input"];
  readonly title: Scalars["String"]["input"];
};

/** Autogenerated input type of SnoozeFinding */
export type SnoozeFindingInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly date: Scalars["ISO8601Date"]["input"];
  readonly findingId: Scalars["FindingId"]["input"];
};

/** Autogenerated return type of SnoozeFinding. */
export type SnoozeFindingPayload = {
  readonly __typename: "SnoozeFindingPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<SnoozeFindingResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type SnoozeFindingResult = {
  readonly __typename: "SnoozeFindingResult";
  readonly finding: Finding;
};

export enum SortDirection {
  /** Sort ascending */
  ASC = "ASC",
  /** Sort descending */
  DESC = "DESC",
  /** Sort descending, nulls last */
  DESC_NULLS_LAST = "DESC_NULLS_LAST",
}

export enum SourceEnum {
  /** This object was created by an algorithm in the Mirah system as a response to other data that was received */
  ALGORITHM = "ALGORITHM",
  /** This object was created from bulk import data from the EHR */
  BULK_IMPORT = "BULK_IMPORT",
  /** This object was created by a human user of the system */
  MANUAL = "MANUAL",
  /** What created this field is not known */
  UNKNOWN = "UNKNOWN",
}

/** Autogenerated input type of StartCaseConsultReview */
export type StartCaseConsultReviewInput = {
  readonly caseConsultId: Scalars["CaseConsultId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly consultMeetingId: Scalars["ConsultMeetingId"]["input"];
};

/** Autogenerated return type of StartCaseConsultReview. */
export type StartCaseConsultReviewPayload = {
  readonly __typename: "StartCaseConsultReviewPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<StartCaseConsultReviewResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type StartCaseConsultReviewResult = {
  readonly __typename: "StartCaseConsultReviewResult";
  readonly caseConsult: CaseConsult;
  readonly consultMeeting: ConsultMeeting;
  readonly myActiveTimeEntryLog: MyActiveTimeEntryLog;
};

/** Autogenerated input type of StartCaseSummaryTimeEntry */
export type StartCaseSummaryTimeEntryInput = {
  readonly caseConsultId: Scalars["CaseConsultId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of StartCaseSummaryTimeEntry. */
export type StartCaseSummaryTimeEntryPayload = {
  readonly __typename: "StartCaseSummaryTimeEntryPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<StartCaseSummaryTimeEntryResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type StartCaseSummaryTimeEntryResult = {
  readonly __typename: "StartCaseSummaryTimeEntryResult";
  readonly caseConsult: CaseConsult;
  readonly myActiveTimeEntryLog: MyActiveTimeEntryLog;
};

/** Autogenerated input type of StartConsultMeeting */
export type StartConsultMeetingInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of StartConsultMeeting. */
export type StartConsultMeetingPayload = {
  readonly __typename: "StartConsultMeetingPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<StartConsultMeetingResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type StartConsultMeetingResult = {
  readonly __typename: "StartConsultMeetingResult";
  readonly consultMeeting: Maybe<ConsultMeeting>;
};

/** Autogenerated input type of StartTimeEntryLogNow */
export type StartTimeEntryLogNowInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly clientStartTime: Scalars["ISO8601DateTime"]["input"];
  readonly forceStart?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly providerId: Scalars["ProviderId"]["input"];
  readonly taskId: Scalars["TaskId"]["input"];
};

/** Autogenerated return type of StartTimeEntryLogNow. */
export type StartTimeEntryLogNowPayload = {
  readonly __typename: "StartTimeEntryLogNowPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<StartTimeEntryLogNowResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type StartTimeEntryLogNowResult = {
  readonly __typename: "StartTimeEntryLogNowResult";
  readonly myActiveTimeEntryLog: MyActiveTimeEntryLog;
  readonly timeEntryLog: TimeEntryLog;
};

export type Subscale = Scale & {
  readonly __typename: "Subscale";
  readonly construct: Maybe<Construct>;
  readonly description: Maybe<Scalars["String"]["output"]>;
  /** If this scale is allowed to be administered */
  readonly disabled: Scalars["Boolean"]["output"];
  readonly displayData: Maybe<ScaleDisplayData>;
  readonly friendlyName: Maybe<Scalars["String"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ScaleId"]["output"];
  /** Can this scale be directly administered? */
  readonly independent: Scalars["Boolean"]["output"];
  readonly measureType: ScaleMeasureType;
  readonly mnemonic: Scalars["String"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly nanoname: Maybe<Scalars["String"]["output"]>;
  readonly norms: ReadonlyArray<Norm>;
  readonly parent: Maybe<Scale>;
  readonly parentId: Maybe<Scalars["ScaleId"]["output"]>;
  readonly questions: ReadonlyArray<Question>;
  /** Short description for use inside clinical report */
  readonly reportShortDescription: Maybe<Scalars["String"]["output"]>;
  readonly role: Maybe<ScaleRole>;
  readonly scaleGroup: Maybe<ScaleGroup>;
  readonly scaleScorers: ReadonlyArray<ScaleScorer>;
  readonly scoringText: Maybe<Scalars["String"]["output"]>;
  readonly shortname: Maybe<Scalars["String"]["output"]>;
};

export type SupportTask = {
  readonly __typename: "SupportTask";
  readonly arguments: ReadonlyArray<SupportTaskArgument>;
  readonly category: Maybe<Scalars["String"]["output"]>;
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly shortcode: Scalars["String"]["output"];
  readonly subCategory: Maybe<Scalars["String"]["output"]>;
};

export type SupportTaskArgument = {
  readonly __typename: "SupportTaskArgument";
  readonly code: Scalars["String"]["output"];
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly fieldType: SupportTaskArgumentType;
  readonly globalId: Scalars["ID"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly required: Scalars["Boolean"]["output"];
};

export type SupportTaskArgumentInput = {
  readonly booleanValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly code: Scalars["String"]["input"];
  readonly integerValue?: InputMaybe<Scalars["Int"]["input"]>;
  readonly stringValue?: InputMaybe<Scalars["String"]["input"]>;
};

/** The kind of thing that can be submitted for a support task. */
export enum SupportTaskArgumentType {
  /** A boolean */
  BOOLEAN = "BOOLEAN",
  /** A system identifier */
  ID = "ID",
  /** An institute id */
  INSTITUTE_ID = "INSTITUTE_ID",
  /** An integer value */
  INT = "INT",
  /** Basic short string */
  STRING = "STRING",
  /** A longer block of text */
  TEXT = "TEXT",
}

export type SupportTaskHistory = {
  readonly __typename: "SupportTaskHistory";
  readonly arguments: Maybe<ReadonlyArray<SupportTaskHistoryArgument>>;
  readonly className: Scalars["String"]["output"];
  readonly completedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["SupportTaskHistoryId"]["output"];
  readonly result: Maybe<Scalars["String"]["output"]>;
  readonly startedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly status: SupportTaskHistoryStatus;
  readonly user: Maybe<User>;
};

/** The values given to an argument for a run */
export type SupportTaskHistoryArgument = {
  readonly __typename: "SupportTaskHistoryArgument";
  readonly name: Scalars["String"]["output"];
  readonly value: Maybe<Scalars["String"]["output"]>;
};

/** The connection type for SupportTaskHistory. */
export type SupportTaskHistoryConnection = {
  readonly __typename: "SupportTaskHistoryConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<SupportTaskHistoryEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<SupportTaskHistory>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type SupportTaskHistoryEdge = {
  readonly __typename: "SupportTaskHistoryEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: SupportTaskHistory;
};

export enum SupportTaskHistorySortParameter {
  /** Id */
  ID = "ID",
  /** Date Started */
  STARTED_AT = "STARTED_AT",
}

/** The status of a support task run */
export enum SupportTaskHistoryStatus {
  /** The task did not succeed */
  FAILURE = "FAILURE",
  /** The task was run successfully */
  SUCCESS = "SUCCESS",
}

export type SupportTaskResult = {
  readonly __typename: "SupportTaskResult";
  readonly globalId: Scalars["ID"]["output"];
  readonly log: Scalars["String"]["output"];
  readonly monitoredJobId: Maybe<Scalars["MonitoredJobId"]["output"]>;
  readonly result: Maybe<Scalars["String"]["output"]>;
  readonly url: Maybe<Scalars["String"]["output"]>;
  readonly urlContainsPhi: Maybe<Scalars["Boolean"]["output"]>;
};

/** Autogenerated input type of TakeSurvey */
export type TakeSurveyInput = {
  readonly assessmentInvitationId?: InputMaybe<Scalars["ID"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly currentLanguage?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
  readonly referralUrl?: InputMaybe<Scalars["String"]["input"]>;
  readonly requestedUrlCodes?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
};

/** Autogenerated return type of TakeSurvey. */
export type TakeSurveyPayload = {
  readonly __typename: "TakeSurveyPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<TakeSurveyResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type TakeSurveyResult = {
  readonly __typename: "TakeSurveyResult";
  readonly assessmentInvitationId: Scalars["ID"]["output"];
  readonly invite: Maybe<Invite>;
  readonly response: Maybe<Response>;
};

export type Task = {
  readonly __typename: "Task";
  readonly assignedTo: Provider;
  readonly body: Scalars["String"]["output"];
  readonly completedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly completedBy: Maybe<Provider>;
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly creationLog: Maybe<TaskCreationLog>;
  readonly dueAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly enrollment: Maybe<Enrollment>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["TaskId"]["output"];
  readonly isPlaceholder: Scalars["Boolean"]["output"];
  readonly notes: NoteConnection;
  readonly patient: Maybe<Patient>;
  readonly status: TaskStatus;
  readonly timeEntries: TimeEntryLogConnection;
  readonly title: Scalars["String"]["output"];
  readonly totalMinutesForMonth: Scalars["Int"]["output"];
};

export type TasknotesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<NoteSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
};

export type TasktimeEntriesArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  deleted: InputMaybe<Scalars["Boolean"]["input"]>;
  endTime: InputMaybe<DateRangeInput>;
  enrollmentMonthId: InputMaybe<ReadonlyArray<Scalars["EnrollmentMonthId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<TimeEntryLogSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  startTime: InputMaybe<DateRangeInput>;
  taskId: InputMaybe<Scalars["TaskId"]["input"]>;
};

export type TasktotalMinutesForMonthArgs = {
  month: InputMaybe<Scalars["Int"]["input"]>;
  year: InputMaybe<Scalars["Int"]["input"]>;
};

/** The connection type for Task. */
export type TaskConnection = {
  readonly __typename: "TaskConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<TaskEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Task>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

export type TaskCreationLog = {
  readonly __typename: "TaskCreationLog";
  readonly assignedBy: Maybe<Provider>;
  readonly creationType: TaskCreationType;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["TaskCreationLogId"]["output"];
  readonly patientSession: Maybe<PatientSession>;
  readonly requestedBy: Maybe<Provider>;
  readonly task: Task;
};

/** The connection type for TaskCreationLog. */
export type TaskCreationLogConnection = {
  readonly __typename: "TaskCreationLogConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<TaskCreationLogEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<TaskCreationLog>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type TaskCreationLogEdge = {
  readonly __typename: "TaskCreationLogEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: TaskCreationLog;
};

export enum TaskCreationLogSortParameter {
  /** Id */
  ID = "ID",
}

/** The different ways a collaborative care task can be created */
export enum TaskCreationType {
  /** This task was created to manage a case consultation */
  CASE_CONSULT = "CASE_CONSULT",
  /** This task was created to track a patient's enrollment and intake */
  ENROLLMENT = "ENROLLMENT",
  /** This task was created manually by someone filling out a form in the app */
  MANUAL = "MANUAL",
  /** This task was created automatically in response to a patient session */
  SESSION_DRIVEN = "SESSION_DRIVEN",
  /** This task was created, but the reason is unknown */
  UNKNOWN = "UNKNOWN",
}

/** An edge in a connection. */
export type TaskEdge = {
  readonly __typename: "TaskEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Task;
};

export type TaskList = {
  readonly __typename: "TaskList";
  readonly id: Scalars["TaskListId"]["output"];
  readonly isDefault: Scalars["Boolean"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly taskListItems: TaskListItemConnection;
};

export type TaskListtaskListItemsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<TaskListItemSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<TaskStatus>;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TaskListItem = {
  readonly __typename: "TaskListItem";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["TaskListItemId"]["output"];
  readonly position: Scalars["Int"]["output"];
  readonly task: Task;
};

/** The connection type for TaskListItem. */
export type TaskListItemConnection = {
  readonly __typename: "TaskListItemConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<TaskListItemEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<TaskListItem>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type TaskListItemEdge = {
  readonly __typename: "TaskListItemEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: TaskListItem;
};

export enum TaskListItemSortParameter {
  /** Position */
  POSITION = "POSITION",
}

export enum TaskSortParameter {
  /** Assigned to provider name, last first */
  ASSIGNED_TO_NAME_LAST_FIRST = "ASSIGNED_TO_NAME_LAST_FIRST",
  /** Due At */
  DUE_AT = "DUE_AT",
  /** Id */
  ID = "ID",
  /** Patient name, last first */
  PATIENT_NAME_LAST_FIRST = "PATIENT_NAME_LAST_FIRST",
  /** Status */
  STATUS = "STATUS",
  /** Title */
  TITLE = "TITLE",
}

/** The states a collaborative care task might be in. */
export enum TaskStatus {
  /** This task has open work to do */
  ACTIVE = "ACTIVE",
  /** This task has been completed */
  COMPLETE = "COMPLETE",
  /** This task has been marked as wont do */
  WONTDO = "WONTDO",
}

export type TaskTemplate = {
  readonly __typename: "TaskTemplate";
  readonly body: Maybe<Scalars["String"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["TaskTemplateId"]["output"];
  readonly title: Scalars["String"]["output"];
};

/** The connection type for TaskTemplate. */
export type TaskTemplateConnection = {
  readonly __typename: "TaskTemplateConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<TaskTemplateEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<TaskTemplate>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type TaskTemplateEdge = {
  readonly __typename: "TaskTemplateEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: TaskTemplate;
};

export enum TaskTemplateSortParameter {
  /** Id */
  ID = "ID",
}

export type TherapyNotesEhrLinkDetails = {
  readonly __typename: "TherapyNotesEhrLinkDetails";
  readonly enableScheduling: Scalars["Boolean"]["output"];
  readonly password: Maybe<Scalars["String"]["output"]>;
  readonly practiceCode: Maybe<Scalars["String"]["output"]>;
  readonly username: Maybe<Scalars["String"]["output"]>;
};

/** Aggregations suitable for time-based metrics */
export enum TimeBasedAggregationType {
  /** The number of patients meeting success criteria */
  NUMBER_OF_PATIENTS = "NUMBER_OF_PATIENTS",
  /** The percentage of patients meeting success criteria */
  PERCENTAGE_OF_PATIENTS = "PERCENTAGE_OF_PATIENTS",
}

export type TimeBasedMetric = MetricConfiguration & {
  readonly __typename: "TimeBasedMetric";
  readonly globalId: Scalars["ID"]["output"];
  readonly metricInclusionCriteria: ReadonlyArray<MetricInclusionCriterion>;
  readonly metricSuccessCriteria: ReadonlyArray<MetricSuccessCriterion>;
  readonly population: Maybe<Population>;
  readonly rollup: MetricRollupType;
  readonly scorerConfig: MetricScorerConfiguration;
  readonly timeBasedAggregationType: TimeBasedAggregationType;
  readonly timePeriodType: TimePeriodType;
  readonly timePeriodValue: Maybe<Scalars["Int"]["output"]>;
};

export type TimeBasedMetricParams = {
  readonly successCriteria: ReadonlyArray<MetricSuccessCriterionParams>;
  readonly timeBasedAggregationType: TimeBasedAggregationType;
  readonly timePeriodType?: InputMaybe<TimePeriodType>;
  readonly timePeriodValue?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TimeEntryLog = {
  readonly __typename: "TimeEntryLog";
  readonly billableMinutes: Maybe<Scalars["Int"]["output"]>;
  readonly blockedMinutes: Maybe<Scalars["Int"]["output"]>;
  readonly clientEndTime: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly clientStartTime: Scalars["ISO8601DateTime"]["output"];
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly deletedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly durationSeconds: Maybe<Scalars["Int"]["output"]>;
  readonly encounter: Maybe<Encounter>;
  readonly endTime: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["TimeEntryLogId"]["output"];
  readonly provider: Provider;
  readonly startTime: Scalars["ISO8601DateTime"]["output"];
  readonly workFor: Task;
};

/** The connection type for TimeEntryLog. */
export type TimeEntryLogConnection = {
  readonly __typename: "TimeEntryLogConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<TimeEntryLogEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<TimeEntryLog>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type TimeEntryLogEdge = {
  readonly __typename: "TimeEntryLogEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: TimeEntryLog;
};

export enum TimeEntryLogSortParameter {
  /** End Time */
  END_TIME = "END_TIME",
  /** Id */
  ID = "ID",
  /** Start Time */
  START_TIME = "START_TIME",
}

/** Evaluation time periods available for time based metrics */
export enum TimePeriodType {
  /** The last measurement taken by this user for the scale scorer */
  LAST_MEASUREMENT = "LAST_MEASUREMENT",
  /** The last measurement in the given month of treatment */
  MONTH_OF_TREATMENT = "MONTH_OF_TREATMENT",
  /** The last measurement in the given week of treatment */
  WEEK_OF_TREATMENT = "WEEK_OF_TREATMENT",
}

export type Timezone = {
  readonly __typename: "Timezone";
  readonly globalId: Scalars["ID"]["output"];
  readonly timezone: Scalars["String"]["output"];
};

/** A set of translated text */
export type TranslatedText = {
  readonly __typename: "TranslatedText";
  /** The English translation */
  readonly en: Scalars["String"]["output"];
  /** The Spanish translation */
  readonly es: Maybe<Scalars["String"]["output"]>;
};

/** Events possible to be triggered on treatment goals */
export enum TreatmentGoalEvent {
  /** Cancel this goal */
  CANCEL = "CANCEL",
  /** Complete this goal */
  COMPLETE = "COMPLETE",
  /** Pause */
  PAUSE = "PAUSE",
  /** Resume */
  RESUME = "RESUME",
}

export type TreatmentService = {
  readonly __typename: "TreatmentService";
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["TreatmentServiceId"]["output"];
  readonly instituteConditions: ReadonlyArray<InstituteCondition>;
  readonly measurementAllowed: Scalars["Boolean"]["output"];
  readonly name: Scalars["String"]["output"];
  readonly organizations: ReadonlyArray<Organization>;
  readonly parentId: Maybe<Scalars["TreatmentServiceId"]["output"]>;
  readonly providers: ReadonlyArray<Provider>;
  readonly serviceTypes: ReadonlyArray<Scalars["String"]["output"]>;
  readonly updatedAt: Scalars["ISO8601DateTime"]["output"];
};

/** The connection type for TreatmentService. */
export type TreatmentServiceConnection = {
  readonly __typename: "TreatmentServiceConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<TreatmentServiceEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<TreatmentService>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type TreatmentServiceEdge = {
  readonly __typename: "TreatmentServiceEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: TreatmentService;
};

/** How and why a treatment service was inferred */
export enum TreatmentServiceInferenceReason {
  /** Not enough data was supplied to match a treatment service */
  INSUFFICIENT_DATA_TO_MATCH = "INSUFFICIENT_DATA_TO_MATCH",
  /** A treatment service with the same organization was found */
  MATCHED_ORGANIZATION = "MATCHED_ORGANIZATION",
  /** A treatment service with the same patient diagnosis was found */
  MATCHED_PATIENT_CONDITION = "MATCHED_PATIENT_CONDITION",
  /** A treatment service with the same provider was found */
  MATCHED_PROVIDER = "MATCHED_PROVIDER",
  /** A treatment service with the same service type was found */
  MATCHED_SERVICE_TYPE = "MATCHED_SERVICE_TYPE",
  /** No treatment services were found matching */
  NO_MATCHES_FOUND = "NO_MATCHES_FOUND",
  /** Treatment services are disabled for this institute */
  TREATMENT_SERVICES_DISABLED = "TREATMENT_SERVICES_DISABLED",
}

export enum TreatmentServiceSortParameter {
  /** Created At */
  CREATED_AT = "CREATED_AT",
  /** Id */
  ID = "ID",
  /** Name */
  NAME = "NAME",
  /** Updated At */
  UPDATED_AT = "UPDATED_AT",
}

export type TreatmentTarget = {
  readonly __typename: "TreatmentTarget";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["TreatmentTargetId"]["output"];
  readonly interval: Maybe<TreatmentTargetInterval>;
  readonly measurementPlan: MeasurementPlan;
  readonly name: Scalars["String"]["output"];
};

/** The connection type for TreatmentTarget. */
export type TreatmentTargetConnection = {
  readonly __typename: "TreatmentTargetConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<TreatmentTargetEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<TreatmentTarget>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type TreatmentTargetEdge = {
  readonly __typename: "TreatmentTargetEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: TreatmentTarget;
};

/** How often should the fastest-cadence scale in this this treatment target be administered */
export enum TreatmentTargetInterval {
  /** Every five weeks */
  FIVE_WEEKS = "FIVE_WEEKS",
  /** One a month */
  MONTHLY = "MONTHLY",
  /** Every nine weeks */
  NINE_WEEKS = "NINE_WEEKS",
  /** Once a quarter */
  QUARTERLY = "QUARTERLY",
  /** Every six weeks */
  SIX_WEEKS = "SIX_WEEKS",
  /** Every three weeks */
  THREE_WEEKS = "THREE_WEEKS",
  /** Twice a month */
  TWICE_MONTHLY = "TWICE_MONTHLY",
}

export enum TreatmentTargetSortParameter {
  /** Id */
  ID = "ID",
}

export type TreatmentTrack = {
  readonly __typename: "TreatmentTrack";
  readonly careEpisodeTreatmentTracks: CareEpisodeTreatmentTrackConnection;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["TreatmentTrackId"]["output"];
  readonly institute: Institute;
  readonly name: Scalars["String"]["output"];
  readonly status: TreatmentTrackStatus;
};

export type TreatmentTrackcareEpisodeTreatmentTracksArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careEpisodeId: InputMaybe<ReadonlyArray<Scalars["CareEpisodeId"]["input"]>>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<CareEpisodeTreatmentTrackSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<ReadonlyArray<CareEpisodeTreatmentTrackStatus>>;
};

/** The connection type for TreatmentTrack. */
export type TreatmentTrackConnection = {
  readonly __typename: "TreatmentTrackConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<TreatmentTrackEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<TreatmentTrack>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type TreatmentTrackEdge = {
  readonly __typename: "TreatmentTrackEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: TreatmentTrack;
};

export enum TreatmentTrackSortParameter {
  /** Id */
  ID = "ID",
  /** Name */
  NAME = "NAME",
}

/** The status of a treatment track */
export enum TreatmentTrackStatus {
  /** Treatment track is active and can be assigned */
  ACTIVE = "ACTIVE",
  /** Treatment track is inactive and cannot be used */
  INACTIVE = "INACTIVE",
}

/** Whether there has been a general trend in this score over the course of treatment */
export enum Trend {
  /** There was a clinically significant worsening */
  DECLINING = "DECLINING",
  /** There was a clinically significant improvement */
  IMPROVING = "IMPROVING",
  /** The result was not valid and could not be used */
  INVALID = "INVALID",
  /** There is not enough data or the algorithm chose not to evaluate */
  NOT_SPECIFIED = "NOT_SPECIFIED",
  /** Acute change is not supported */
  NOT_SUPPORTED = "NOT_SUPPORTED",
  /** There was no clinically significant change */
  STABLE = "STABLE",
}

/** The algorithms available for calculating trend */
export enum TrendType {
  /** Calculate on the basis of linear regression of previous results */
  AVG = "AVG",
  /** Any change in categorical result will yield a trend */
  CATEGORICAL = "CATEGORICAL",
}

/** Aggregations suitable for trigger-based metrics */
export enum TriggerBasedAggregationType {
  /** Average number of days since treatment start */
  AVERAGE_DAYS_SINCE_TREATMENT_START = "AVERAGE_DAYS_SINCE_TREATMENT_START",
}

export type TriggerBasedMetric = MetricConfiguration & {
  readonly __typename: "TriggerBasedMetric";
  readonly globalId: Scalars["ID"]["output"];
  readonly metricInclusionCriteria: ReadonlyArray<MetricInclusionCriterion>;
  readonly metricTriggers: ReadonlyArray<MetricTrigger>;
  readonly population: Maybe<Population>;
  readonly rollup: MetricRollupType;
  readonly scorerConfig: MetricScorerConfiguration;
  readonly triggerBasedAggregationType: TriggerBasedAggregationType;
};

export type TriggerBasedMetricParams = {
  readonly triggerBasedAggregationType: TriggerBasedAggregationType;
  readonly triggers: ReadonlyArray<MetricTriggerParams>;
};

export type TriggeredDecisionAlgorithm = {
  readonly __typename: "TriggeredDecisionAlgorithm";
  readonly careEpisode: CareEpisode;
  readonly entityDecisionAlgorithm: EntityDecisionAlgorithm;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["TriggeredDecisionAlgorithmId"]["output"];
  readonly institute: Institute;
  readonly status: TriggeredDecisionAlgorithmStatus;
  readonly triggeredAt: Scalars["ISO8601DateTime"]["output"];
};

/** The connection type for TriggeredDecisionAlgorithm. */
export type TriggeredDecisionAlgorithmConnection = {
  readonly __typename: "TriggeredDecisionAlgorithmConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<TriggeredDecisionAlgorithmEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<TriggeredDecisionAlgorithm>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type TriggeredDecisionAlgorithmEdge = {
  readonly __typename: "TriggeredDecisionAlgorithmEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: TriggeredDecisionAlgorithm;
};

export enum TriggeredDecisionAlgorithmSortParameter {
  /** Id */
  ID = "ID",
}

/** Tracks the status of a triggered decision algorithm */
export enum TriggeredDecisionAlgorithmStatus {
  /** A decision algorithm has been triggered and has a workflow active */
  ACTIVE = "ACTIVE",
  /** The workflow for the algorithm has been canceled */
  CANCELED = "CANCELED",
  /** The workflow for the decision algorithm has been completed */
  COMPLETE = "COMPLETE",
}

export type TriggeredWorkflow = {
  readonly __typename: "TriggeredWorkflow";
  readonly callToAction: Scalars["String"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["TriggeredWorkflowId"]["output"];
  readonly institute: Institute;
  readonly instituteWorkflow: InstituteWorkflow;
  readonly instructions: Scalars["String"]["output"];
  readonly isCurrentlyTriggered: Scalars["Boolean"]["output"];
  readonly reasons: ReadonlyArray<Scalars["String"]["output"]>;
  readonly snoozedUntil: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly status: TriggeredWorkflowStatus;
  readonly statusUpdatedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly statusUpdatedBy: Maybe<Provider>;
  readonly title: Scalars["String"]["output"];
  readonly triggeredAt: Scalars["ISO8601DateTime"]["output"];
  readonly triggeredDecisionAlgorithm: TriggeredDecisionAlgorithm;
  readonly triggeredWorkflowActions: ReadonlyArray<TriggeredWorkflowAction>;
  readonly workflow: Workflow;
};

export type TriggeredWorkflowAction = {
  readonly __typename: "TriggeredWorkflowAction";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["TriggeredWorkflowActionId"]["output"];
  readonly institute: Institute;
  readonly status: TriggeredWorkflowActionStatus;
  readonly triggeredWorkflow: TriggeredWorkflow;
  readonly workflowAction: WorkflowAction;
};

/** The connection type for TriggeredWorkflowAction. */
export type TriggeredWorkflowActionConnection = {
  readonly __typename: "TriggeredWorkflowActionConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<TriggeredWorkflowActionEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<TriggeredWorkflowAction>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type TriggeredWorkflowActionEdge = {
  readonly __typename: "TriggeredWorkflowActionEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: TriggeredWorkflowAction;
};

export type TriggeredWorkflowActionLog = {
  readonly __typename: "TriggeredWorkflowActionLog";
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["TriggeredWorkflowActionLogId"]["output"];
  readonly institute: Institute;
  readonly triggeredWorkflowAction: TriggeredWorkflowAction;
  readonly usedAt: Scalars["ISO8601DateTime"]["output"];
  readonly user: User;
};

/** The connection type for TriggeredWorkflowActionLog. */
export type TriggeredWorkflowActionLogConnection = {
  readonly __typename: "TriggeredWorkflowActionLogConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<TriggeredWorkflowActionLogEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<TriggeredWorkflowActionLog>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type TriggeredWorkflowActionLogEdge = {
  readonly __typename: "TriggeredWorkflowActionLogEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: TriggeredWorkflowActionLog;
};

export enum TriggeredWorkflowActionLogSortParameter {
  /** Id */
  ID = "ID",
}

export enum TriggeredWorkflowActionSortParameter {
  /** Id */
  ID = "ID",
}

/** Tracks the status for a triggered workflow action */
export enum TriggeredWorkflowActionStatus {
  /** This action can be used */
  AVAILABLE = "AVAILABLE",
  /** This action has been used and can't be used again */
  USED = "USED",
}

/** The connection type for TriggeredWorkflow. */
export type TriggeredWorkflowConnection = {
  readonly __typename: "TriggeredWorkflowConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<TriggeredWorkflowEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<TriggeredWorkflow>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type TriggeredWorkflowEdge = {
  readonly __typename: "TriggeredWorkflowEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: TriggeredWorkflow;
};

export enum TriggeredWorkflowSortParameter {
  /** Id */
  ID = "ID",
}

/** Tracks the status of a triggered workflow */
export enum TriggeredWorkflowStatus {
  /** Workflow is triggered, shows as active in the UI, can be interacted with */
  ACTIVE = "ACTIVE",
  /** Workflow is no longer active because the underlying conditions have changed */
  CANCELED = "CANCELED",
  /** Everything users need to do related to the workflow has happened */
  COMPLETE = "COMPLETE",
  /** Workflow is temporarily paused until the target time is reached */
  SNOOZED = "SNOOZED",
}

export type UnscoredScaleScorer = ScaleScorer & {
  readonly __typename: "UnscoredScaleScorer";
  readonly domain: ScaleScorerDomain;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["ScaleScorerId"]["output"];
  /** Which other values are used to generate this score */
  readonly inputDomains: Maybe<ReadonlyArray<Scalars["String"]["output"]>>;
  readonly name: Scalars["String"]["output"];
  readonly scale: Scale;
  readonly scoreType: ScaleScorerType;
  readonly supportedSeverityCategories: ReadonlyArray<SeverityCategory>;
  readonly supportedTrends: ReadonlyArray<Trend>;
};

/** Autogenerated input type of UpdateCareEpisodeTreatmentTrack */
export type UpdateCareEpisodeTreatmentTrackInput = {
  readonly careEpisodeTreatmentTrackId: Scalars["CareEpisodeTreatmentTrackId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly status: CareEpisodeTreatmentTrackStatus;
};

/** Autogenerated return type of UpdateCareEpisodeTreatmentTrack. */
export type UpdateCareEpisodeTreatmentTrackPayload = {
  readonly __typename: "UpdateCareEpisodeTreatmentTrackPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateCareEpisodeTreatmentTrackResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateCareEpisodeTreatmentTrackResult = {
  readonly __typename: "UpdateCareEpisodeTreatmentTrackResult";
  readonly careEpisodeTreatmentTrack: CareEpisodeTreatmentTrack;
};

/** Autogenerated input type of UpdateCareGroup */
export type UpdateCareGroupInput = {
  readonly careGroupId: Scalars["CareGroupId"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly patientIds: ReadonlyArray<Scalars["PatientId"]["input"]>;
};

/** Autogenerated return type of UpdateCareGroup. */
export type UpdateCareGroupPayload = {
  readonly __typename: "UpdateCareGroupPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateCareGroupResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateCareGroupResult = {
  readonly __typename: "UpdateCareGroupResult";
  readonly careGroup: CareGroup;
};

/** Autogenerated input type of UpdateCocmBillingAlgorithm */
export type UpdateCocmBillingAlgorithmInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly codes: ReadonlyArray<CocmBillingAlgorithmCodeParams>;
  readonly endDate?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  readonly id: Scalars["CocmBillingAlgorithmId"]["input"];
  readonly name: Scalars["String"]["input"];
  readonly rules: ReadonlyArray<CocmBillingAlgorithmRuleParams>;
  readonly startDate: Scalars["ISO8601DateTime"]["input"];
  readonly status: CocmBillingAlgorithmStatus;
};

/** Autogenerated return type of UpdateCocmBillingAlgorithm. */
export type UpdateCocmBillingAlgorithmPayload = {
  readonly __typename: "UpdateCocmBillingAlgorithmPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateCocmBillingAlgorithmResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateCocmBillingAlgorithmResult = {
  readonly __typename: "UpdateCocmBillingAlgorithmResult";
  readonly cocmBillingAlgorithm: CocmBillingAlgorithm;
};

/** Autogenerated input type of UpdateEhrLinkNotes */
export type UpdateEhrLinkNotesInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly ehrLinkId: Scalars["EhrLinkId"]["input"];
  readonly notes: Scalars["String"]["input"];
};

/** Autogenerated return type of UpdateEhrLinkNotes. */
export type UpdateEhrLinkNotesPayload = {
  readonly __typename: "UpdateEhrLinkNotesPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateEhrLinkNotesResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateEhrLinkNotesResult = {
  readonly __typename: "UpdateEhrLinkNotesResult";
  readonly ehrLinkId: Scalars["EhrLinkId"]["output"];
  readonly notes: Scalars["String"]["output"];
};

/** Autogenerated input type of UpdateEnrollment */
export type UpdateEnrollmentInput = {
  readonly careManagerId?: InputMaybe<Scalars["PanelTeamMemberId"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly consentDate?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  readonly enrolledAt?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  readonly enrollmentId: Scalars["EnrollmentId"]["input"];
  readonly panelId?: InputMaybe<Scalars["PanelId"]["input"]>;
  readonly primaryCareProviderId?: InputMaybe<Scalars["PanelTeamMemberId"]["input"]>;
};

/** Autogenerated return type of UpdateEnrollment. */
export type UpdateEnrollmentPayload = {
  readonly __typename: "UpdateEnrollmentPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateEnrollmentResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateEnrollmentResult = {
  readonly __typename: "UpdateEnrollmentResult";
  readonly enrollment: Enrollment;
};

/** Autogenerated input type of UpdateGoal */
export type UpdateGoalInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly event: GoalEvent;
  readonly goalId: Scalars["ID"]["input"];
};

/** Autogenerated return type of UpdateGoal. */
export type UpdateGoalPayload = {
  readonly __typename: "UpdateGoalPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateGoalResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateGoalResult = {
  readonly __typename: "UpdateGoalResult";
  readonly goal: Goal;
};

/** Autogenerated input type of UpdateGpraId */
export type UpdateGpraIdInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly gpraId: Scalars["String"]["input"];
  readonly patientId: Scalars["PatientId"]["input"];
};

/** Autogenerated return type of UpdateGpraId. */
export type UpdateGpraIdPayload = {
  readonly __typename: "UpdateGpraIdPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdatePipbhcGpraIdResult>;
  readonly success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of UpdateImplementationTarget */
export type UpdateImplementationTargetInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly description?: InputMaybe<Scalars["String"]["input"]>;
  readonly endDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  readonly entityTreeNode?: InputMaybe<EntityTreeNodeParams>;
  readonly id: Scalars["ImplementationTargetId"]["input"];
  readonly initialCredits?: InputMaybe<Scalars["Float"]["input"]>;
  readonly mirahInternal?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly name?: InputMaybe<Scalars["String"]["input"]>;
  readonly softLaunchDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  readonly startDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  readonly status?: InputMaybe<ImplementationTargetStatus>;
  readonly target?: InputMaybe<Scalars["Float"]["input"]>;
  readonly targetType?: InputMaybe<ImplementationTargetType>;
};

/** Autogenerated return type of UpdateImplementationTarget. */
export type UpdateImplementationTargetPayload = {
  readonly __typename: "UpdateImplementationTargetPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateImplementationTargetResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateImplementationTargetResult = {
  readonly __typename: "UpdateImplementationTargetResult";
  readonly implementationTarget: ImplementationTarget;
};

/** Autogenerated input type of UpdateImplementationTargetStatus */
export type UpdateImplementationTargetStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly implementationTargetId: Scalars["ImplementationTargetId"]["input"];
  readonly status: ImplementationTargetStatus;
};

/** Autogenerated return type of UpdateImplementationTargetStatus. */
export type UpdateImplementationTargetStatusPayload = {
  readonly __typename: "UpdateImplementationTargetStatusPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateImplementationTargetStatusResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateImplementationTargetStatusResult = {
  readonly __typename: "UpdateImplementationTargetStatusResult";
  readonly implementationTarget: ImplementationTarget;
};

/** Autogenerated input type of UpdateInvitation */
export type UpdateInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly invitationId: Scalars["InvitationId"]["input"];
  readonly referrer?: InputMaybe<InvitationLoginMethod>;
  readonly statusEvent?: InputMaybe<InvitationStatusEvent>;
  readonly takingUserId?: InputMaybe<Scalars["UserId"]["input"]>;
};

/** Autogenerated return type of UpdateInvitation. */
export type UpdateInvitationPayload = {
  readonly __typename: "UpdateInvitationPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateInvitationResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateInvitationResult = {
  readonly __typename: "UpdateInvitationResult";
  readonly invitationId: Scalars["InvitationId"]["output"];
  readonly status: Maybe<InvitationStatus>;
};

/** Autogenerated input type of UpdateMetric */
export type UpdateMetricInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly entityTreeNode?: InputMaybe<EntityTreeNodeParams>;
  readonly id: Scalars["MetricId"]["input"];
  readonly name: Scalars["String"]["input"];
  readonly payload: MetricParams;
  readonly status: MetricStatus;
};

/** Autogenerated return type of UpdateMetric. */
export type UpdateMetricPayload = {
  readonly __typename: "UpdateMetricPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateMetricResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateMetricResult = {
  readonly __typename: "UpdateMetricResult";
  readonly metric: Metric;
};

/** Autogenerated input type of UpdateMetricStatus */
export type UpdateMetricStatusInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly metricId: Scalars["MetricId"]["input"];
  readonly status: MetricStatus;
};

/** Autogenerated return type of UpdateMetricStatus. */
export type UpdateMetricStatusPayload = {
  readonly __typename: "UpdateMetricStatusPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateMetricStatusResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateMetricStatusResult = {
  readonly __typename: "UpdateMetricStatusResult";
  readonly metric: Metric;
};

/** Autogenerated input type of UpdateOrganizationSettings */
export type UpdateOrganizationSettingsInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly organizationId: Scalars["OrganizationId"]["input"];
  readonly timezone?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of UpdateOrganizationSettings. */
export type UpdateOrganizationSettingsPayload = {
  readonly __typename: "UpdateOrganizationSettingsPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateOrganizationSettingsResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateOrganizationSettingsResult = {
  readonly __typename: "UpdateOrganizationSettingsResult";
  readonly organization: Organization;
};

/** Autogenerated input type of UpdatePatientFlag */
export type UpdatePatientFlagInput = {
  readonly clear: Scalars["Boolean"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly notes?: InputMaybe<Scalars["String"]["input"]>;
  readonly patientFlagId: Scalars["PatientFlagId"]["input"];
};

/** Autogenerated return type of UpdatePatientFlag. */
export type UpdatePatientFlagPayload = {
  readonly __typename: "UpdatePatientFlagPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdatePatientFlagResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdatePatientFlagResult = {
  readonly __typename: "UpdatePatientFlagResult";
  readonly patientFlag: Maybe<PatientFlag>;
};

/** Autogenerated input type of UpdatePatientNote */
export type UpdatePatientNoteInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly note: Scalars["String"]["input"];
  readonly patientId: Scalars["PatientId"]["input"];
};

/** Autogenerated return type of UpdatePatientNote. */
export type UpdatePatientNotePayload = {
  readonly __typename: "UpdatePatientNotePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdatePatientNoteResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdatePatientNoteResult = {
  readonly __typename: "UpdatePatientNoteResult";
  readonly patient: Patient;
};

/** Autogenerated input type of UpdatePatientNotificationPreference */
export type UpdatePatientNotificationPreferenceInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly enableNotifications: Scalars["Boolean"]["input"];
  readonly patientId: Scalars["PatientId"]["input"];
};

/** Autogenerated return type of UpdatePatientNotificationPreference. */
export type UpdatePatientNotificationPreferencePayload = {
  readonly __typename: "UpdatePatientNotificationPreferencePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdatePatientNotificationPreferenceResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdatePatientNotificationPreferenceResult = {
  readonly __typename: "UpdatePatientNotificationPreferenceResult";
  readonly patient: Patient;
};

/** Autogenerated input type of UpdatePatientQualityIssue */
export type UpdatePatientQualityIssueInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly notes?: InputMaybe<Scalars["String"]["input"]>;
  readonly patientQualityIssueId: Scalars["PatientQualityIssueId"]["input"];
  readonly status: PatientQualityIssueStatus;
  readonly statusActiveUntil?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
};

/** Autogenerated return type of UpdatePatientQualityIssue. */
export type UpdatePatientQualityIssuePayload = {
  readonly __typename: "UpdatePatientQualityIssuePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdatePatientQualityIssueResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdatePatientQualityIssueResult = {
  readonly __typename: "UpdatePatientQualityIssueResult";
  readonly patientQualityIssue: PatientQualityIssue;
};

export type UpdatePipbhcGpraIdResult = {
  readonly __typename: "UpdatePipbhcGpraIdResult";
  readonly patient: Patient;
};

export type UpdatePipbhcTreatmentGoalResult = {
  readonly __typename: "UpdatePipbhcTreatmentGoalResult";
  readonly treatmentGoal: PipbhcTreatmentGoal;
};

/** Autogenerated input type of UpdateQuickText */
export type UpdateQuickTextInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly order: Scalars["Int"]["input"];
  readonly quickTextId: Scalars["QuickTextId"]["input"];
  readonly text?: InputMaybe<Scalars["String"]["input"]>;
  readonly title: Scalars["String"]["input"];
};

/** Autogenerated return type of UpdateQuickText. */
export type UpdateQuickTextPayload = {
  readonly __typename: "UpdateQuickTextPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateQuickTextResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateQuickTextResult = {
  readonly __typename: "UpdateQuickTextResult";
  readonly quickText: QuickText;
};

/** Autogenerated input type of UpdateRelatedPerson */
export type UpdateRelatedPersonInput = {
  readonly active?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly email?: InputMaybe<Scalars["String"]["input"]>;
  readonly firstName: Scalars["String"]["input"];
  readonly lastName: Scalars["String"]["input"];
  readonly notificationPreference?: InputMaybe<NotificationPreference>;
  readonly phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  readonly relatedPersonId: Scalars["RelatedPersonId"]["input"];
  readonly relatedPersonRelationship: RelatedPersonRelationship;
};

/** Autogenerated return type of UpdateRelatedPerson. */
export type UpdateRelatedPersonPayload = {
  readonly __typename: "UpdateRelatedPersonPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateRelatedPersonResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateRelatedPersonResult = {
  readonly __typename: "UpdateRelatedPersonResult";
  readonly relatedPerson: RelatedPerson;
};

/** Autogenerated input type of UpdateTask */
export type UpdateTaskInput = {
  readonly assignedToId?: InputMaybe<Scalars["ProviderId"]["input"]>;
  readonly body?: InputMaybe<Scalars["String"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly dueAt?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  readonly patientId?: InputMaybe<Scalars["PatientId"]["input"]>;
  readonly taskId: Scalars["TaskId"]["input"];
  readonly title?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of UpdateTask. */
export type UpdateTaskPayload = {
  readonly __typename: "UpdateTaskPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateTaskResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateTaskResult = {
  readonly __typename: "UpdateTaskResult";
  readonly task: Task;
};

/** Autogenerated input type of UpdateTaskTemplate */
export type UpdateTaskTemplateInput = {
  readonly body: Scalars["String"]["input"];
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly taskTemplateId: Scalars["TaskTemplateId"]["input"];
  readonly title: Scalars["String"]["input"];
};

/** Autogenerated return type of UpdateTaskTemplate. */
export type UpdateTaskTemplatePayload = {
  readonly __typename: "UpdateTaskTemplatePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateTaskTemplateResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateTaskTemplateResult = {
  readonly __typename: "UpdateTaskTemplateResult";
  readonly taskTemplate: TaskTemplate;
};

/** Autogenerated input type of UpdateTreatmentGoal */
export type UpdateTreatmentGoalInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly event?: InputMaybe<TreatmentGoalEvent>;
  readonly goalText: Scalars["String"]["input"];
  readonly note?: InputMaybe<Scalars["String"]["input"]>;
  readonly treatmentGoalId: Scalars["PipbhcTreatmentGoalId"]["input"];
};

/** Autogenerated return type of UpdateTreatmentGoal. */
export type UpdateTreatmentGoalPayload = {
  readonly __typename: "UpdateTreatmentGoalPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdatePipbhcTreatmentGoalResult>;
  readonly success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of UpdateTreatmentService */
export type UpdateTreatmentServiceInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly measurementAllowed?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly name?: InputMaybe<Scalars["String"]["input"]>;
  readonly parentId?: InputMaybe<Scalars["TreatmentServiceId"]["input"]>;
  readonly serviceTypes?: InputMaybe<ReadonlyArray<Scalars["String"]["input"]>>;
  readonly treatmentServiceId: Scalars["TreatmentServiceId"]["input"];
};

/** Autogenerated input type of UpdateTreatmentServiceInstituteConditions */
export type UpdateTreatmentServiceInstituteConditionsInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly instituteConditionIds: ReadonlyArray<Scalars["InstituteConditionId"]["input"]>;
  readonly treatmentServiceId: Scalars["TreatmentServiceId"]["input"];
};

/** Autogenerated return type of UpdateTreatmentServiceInstituteConditions. */
export type UpdateTreatmentServiceInstituteConditionsPayload = {
  readonly __typename: "UpdateTreatmentServiceInstituteConditionsPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateTreatmentServiceInstituteConditionsResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateTreatmentServiceInstituteConditionsResult = {
  readonly __typename: "UpdateTreatmentServiceInstituteConditionsResult";
  readonly treatmentService: Maybe<TreatmentService>;
};

/** Autogenerated input type of UpdateTreatmentServiceOrganizations */
export type UpdateTreatmentServiceOrganizationsInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly organizationIds: ReadonlyArray<Scalars["OrganizationId"]["input"]>;
  readonly treatmentServiceId: Scalars["TreatmentServiceId"]["input"];
};

/** Autogenerated return type of UpdateTreatmentServiceOrganizations. */
export type UpdateTreatmentServiceOrganizationsPayload = {
  readonly __typename: "UpdateTreatmentServiceOrganizationsPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateTreatmentServiceOrganizationsResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateTreatmentServiceOrganizationsResult = {
  readonly __typename: "UpdateTreatmentServiceOrganizationsResult";
  readonly treatmentService: Maybe<TreatmentService>;
};

/** Autogenerated return type of UpdateTreatmentService. */
export type UpdateTreatmentServicePayload = {
  readonly __typename: "UpdateTreatmentServicePayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateTreatmentServiceResult>;
  readonly success: Scalars["Boolean"]["output"];
};

/** Autogenerated input type of UpdateTreatmentServiceProviders */
export type UpdateTreatmentServiceProvidersInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly providerIds: ReadonlyArray<Scalars["ProviderId"]["input"]>;
  readonly treatmentServiceId: Scalars["TreatmentServiceId"]["input"];
};

/** Autogenerated return type of UpdateTreatmentServiceProviders. */
export type UpdateTreatmentServiceProvidersPayload = {
  readonly __typename: "UpdateTreatmentServiceProvidersPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateTreatmentServiceProvidersResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateTreatmentServiceProvidersResult = {
  readonly __typename: "UpdateTreatmentServiceProvidersResult";
  readonly treatmentService: TreatmentService;
};

export type UpdateTreatmentServiceResult = {
  readonly __typename: "UpdateTreatmentServiceResult";
  readonly treatmentService: Maybe<TreatmentService>;
};

/** Autogenerated input type of UpdateTreatmentTrack */
export type UpdateTreatmentTrackInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly name: Scalars["String"]["input"];
  readonly status: TreatmentTrackStatus;
  readonly treatmentTrackId: Scalars["TreatmentTrackId"]["input"];
};

/** Autogenerated return type of UpdateTreatmentTrack. */
export type UpdateTreatmentTrackPayload = {
  readonly __typename: "UpdateTreatmentTrackPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateTreatmentTrackResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateTreatmentTrackResult = {
  readonly __typename: "UpdateTreatmentTrackResult";
  readonly treatmentTrack: TreatmentTrack;
};

/** Autogenerated input type of UpdateTrialPeriod */
export type UpdateTrialPeriodInput = {
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  readonly enableAllCareEpisodes?: InputMaybe<Scalars["Boolean"]["input"]>;
  readonly inTrialPeriod: Scalars["Boolean"]["input"];
  readonly organizationId: Scalars["OrganizationId"]["input"];
};

/** Autogenerated return type of UpdateTrialPeriod. */
export type UpdateTrialPeriodPayload = {
  readonly __typename: "UpdateTrialPeriodPayload";
  /** A unique identifier for the client performing the mutation. */
  readonly clientMutationId: Maybe<Scalars["String"]["output"]>;
  readonly errors: ReadonlyArray<UserError>;
  readonly result: Maybe<UpdateTrialPeriodResult>;
  readonly success: Scalars["Boolean"]["output"];
};

export type UpdateTrialPeriodResult = {
  readonly __typename: "UpdateTrialPeriodResult";
  readonly inTrialPeriod: Scalars["Boolean"]["output"];
  readonly organizationId: Scalars["OrganizationId"]["output"];
};

export type User = {
  readonly __typename: "User";
  readonly allowsEmail: Scalars["Boolean"]["output"];
  readonly allowsText: Scalars["Boolean"]["output"];
  readonly confirmedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly currentSignInAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly email: Maybe<Scalars["String"]["output"]>;
  readonly emailSource: Maybe<FieldSourceEnum>;
  readonly failedAttempts: Scalars["Int"]["output"];
  readonly firstName: Maybe<Scalars["String"]["output"]>;
  readonly firstNameSource: Maybe<FieldSourceEnum>;
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["UserId"]["output"];
  readonly isInternal: Scalars["Boolean"]["output"];
  readonly language: Maybe<Scalars["String"]["output"]>;
  readonly lastActivityAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly lastName: Maybe<Scalars["String"]["output"]>;
  readonly lastNameSource: Maybe<FieldSourceEnum>;
  readonly lastSignInAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly legalFirstName: Maybe<Scalars["String"]["output"]>;
  readonly legalFirstNameSource: Maybe<FieldSourceEnum>;
  readonly legalLastName: Scalars["String"]["output"];
  readonly legalLastNameSource: Maybe<FieldSourceEnum>;
  readonly legalName: Maybe<Scalars["String"]["output"]>;
  readonly legalNameSource: Maybe<FieldSourceEnum>;
  readonly lockedAt: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  readonly name: Scalars["String"]["output"];
  readonly notificationPreference: Maybe<NotificationPreference>;
  readonly patient: Maybe<Patient>;
  readonly phoneNumber: Maybe<Scalars["String"]["output"]>;
  readonly phoneNumberSource: Maybe<FieldSourceEnum>;
  readonly preferredFirstName: Maybe<Scalars["String"]["output"]>;
  readonly preferredFirstNameSource: Maybe<FieldSourceEnum>;
  readonly preferredLastName: Maybe<Scalars["String"]["output"]>;
  readonly preferredLastNameSource: Maybe<FieldSourceEnum>;
  readonly preferredName: Maybe<Scalars["String"]["output"]>;
  readonly preferredNameSource: Maybe<FieldSourceEnum>;
  readonly preferredPronouns: Maybe<Scalars["String"]["output"]>;
  readonly preferredPronounsSource: Maybe<FieldSourceEnum>;
  readonly role: Maybe<Scalars["String"]["output"]>;
  readonly signInCount: Scalars["Int"]["output"];
  readonly ssoUsername: Maybe<Scalars["String"]["output"]>;
  readonly suffix: Maybe<Scalars["String"]["output"]>;
  readonly timezone: Maybe<Scalars["String"]["output"]>;
  readonly title: Maybe<Scalars["String"]["output"]>;
  readonly updatedAt: Scalars["ISO8601DateTime"]["output"];
  readonly userConsents: ReadonlyArray<UserConsent>;
  readonly userType: UserType;
  readonly username: Maybe<Scalars["String"]["output"]>;
};

/** The connection type for User. */
export type UserConnection = {
  readonly __typename: "UserConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<UserEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<User>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

export type UserConsent = {
  readonly __typename: "UserConsent";
  readonly accepted: Scalars["Boolean"]["output"];
  readonly consentAgreement: Maybe<ConsentAgreement>;
  readonly consentType: Scalars["String"]["output"];
  readonly createdAt: Scalars["ISO8601DateTime"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["UserConsentId"]["output"];
  readonly status: UserConsentStatus;
  readonly user: User;
};

/** The connection type for UserConsent. */
export type UserConsentConnection = {
  readonly __typename: "UserConsentConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<UserConsentEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<UserConsent>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type UserConsentEdge = {
  readonly __typename: "UserConsentEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: UserConsent;
};

export enum UserConsentSortParameter {
  /** Created At */
  CREATED_AT = "CREATED_AT",
  /** Id */
  ID = "ID",
}

/** Status of a consent form */
export enum UserConsentStatus {
  /** The user accepted consent */
  ACCEPTED = "ACCEPTED",
  /** The user declined consent */
  DECLINED = "DECLINED",
  /** The user did not make a decision about consent and deferred */
  DEFERRED = "DEFERRED",
}

/** An edge in a connection. */
export type UserEdge = {
  readonly __typename: "UserEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: User;
};

/** A user-readable error */
export type UserError = {
  readonly __typename: "UserError";
  /** A description of the error */
  readonly message: Scalars["String"]["output"];
  /** Which input value this error came from */
  readonly path: Maybe<ReadonlyArray<Scalars["String"]["output"]>>;
};

export type UserLoginCode = {
  readonly __typename: "UserLoginCode";
  readonly code: Scalars["String"]["output"];
  readonly relationship: ParticipantRelationship;
  readonly user: User;
};

export enum UserSortParameter {
  /** First name */
  FIRSTNAME = "FIRSTNAME",
  /** Id */
  ID = "ID",
  /** Last name */
  LASTNAME = "LASTNAME",
  NAME_LAST_FIRST = "NAME_LAST_FIRST",
}

export enum UserType {
  /** This user is only allowed to access the API */
  API = "API",
  /** This user is a Mirah employee */
  INTERNAL = "INTERNAL",
  /** This user is a patient */
  PATIENT = "PATIENT",
  /** This user is a clinical provider */
  PROVIDER = "PROVIDER",
  /** This user is a related person */
  RELATED_PERSON = "RELATED_PERSON",
}

export type ValentEhrLinkDetails = {
  readonly __typename: "ValentEhrLinkDetails";
  readonly enableBilling: Scalars["Boolean"]["output"];
  readonly enableScheduling: Scalars["Boolean"]["output"];
  readonly organizationNameColumn: Maybe<Scalars["String"]["output"]>;
  readonly password: Maybe<Scalars["String"]["output"]>;
  readonly username: Maybe<Scalars["String"]["output"]>;
};

export type Version = {
  readonly __typename: "Version";
  readonly createdAt: Scalars["String"]["output"];
  readonly event: Maybe<Scalars["String"]["output"]>;
  readonly fields: ReadonlyArray<VersionField>;
  readonly id: Scalars["String"]["output"];
  readonly whodunnit: Maybe<Scalars["String"]["output"]>;
};

export type VersionField = {
  readonly __typename: "VersionField";
  readonly name: Scalars["String"]["output"];
  readonly newValue: Maybe<Scalars["String"]["output"]>;
  readonly oldValue: Maybe<Scalars["String"]["output"]>;
};

export type Workflow = {
  readonly __typename: "Workflow";
  readonly callToAction: Scalars["String"]["output"];
  readonly description: Maybe<Scalars["String"]["output"]>;
  readonly emailBody: Scalars["String"]["output"];
  readonly emailSubject: Scalars["String"]["output"];
  readonly globalId: Scalars["ID"]["output"];
  readonly id: Scalars["WorkflowId"]["output"];
  readonly instructions: Maybe<Scalars["String"]["output"]>;
  readonly mnemonic: Scalars["String"]["output"];
  readonly title: Scalars["String"]["output"];
  readonly workflowActions: ReadonlyArray<WorkflowAction>;
};

export type WorkflowAction = {
  readonly __typename: "WorkflowAction";
  readonly globalId: Scalars["ID"]["output"];
  readonly hardcodedSideEffectClass: Scalars["String"]["output"];
  readonly id: Scalars["WorkflowActionId"]["output"];
  readonly reusable: Scalars["Boolean"]["output"];
  readonly sideEffectParams: Maybe<Scalars["SideEffectParams"]["output"]>;
  readonly text: Scalars["String"]["output"];
  readonly workflow: Workflow;
};

/** The connection type for WorkflowAction. */
export type WorkflowActionConnection = {
  readonly __typename: "WorkflowActionConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<WorkflowActionEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<WorkflowAction>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type WorkflowActionEdge = {
  readonly __typename: "WorkflowActionEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: WorkflowAction;
};

export enum WorkflowActionSortParameter {
  /** Id */
  ID = "ID",
}

/** The connection type for Workflow. */
export type WorkflowConnection = {
  readonly __typename: "WorkflowConnection";
  /** A list of edges. */
  readonly edges: ReadonlyArray<WorkflowEdge>;
  /** A list of nodes. */
  readonly nodes: ReadonlyArray<Workflow>;
  /** Information to aid in pagination. */
  readonly pageInfo: PageInfo;
  readonly totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type WorkflowEdge = {
  readonly __typename: "WorkflowEdge";
  /** A cursor for use in pagination. */
  readonly cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  readonly node: Workflow;
};

/** Filter on a workflow type, including option for all workflows */
export type WorkflowFilter =
  /** Show all workflows */
  | {
      readonly allWorkflows: Scalars["Boolean"]["input"];
      readonly workflowIds?: never;
    } /** Show the specified workflows */
  | { readonly allWorkflows?: never; readonly workflowIds: ReadonlyArray<Scalars["WorkflowId"]["input"]> };

export enum WorkflowSortParameter {
  /** Id */
  ID = "ID",
}

export type InstituteGroupsListQueryVariables = Exact<{ [key: string]: never }>;

export type InstituteGroupsListQuery = {
  readonly __typename: "Query";
  readonly instituteGroups: {
    readonly __typename: "InstituteGroupConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "InstituteGroup";
      readonly id: Ids.InstituteGroupId;
      readonly name: string;
      readonly members: ReadonlyArray<{
        readonly __typename: "CurrentInstitute";
        readonly id: Ids.InstituteId;
        readonly name: string;
      }>;
    }>;
  } | null;
};

export type CocmBillingAlgorithmDetailsQueryVariables = Exact<{
  id: Scalars["CocmBillingAlgorithmId"]["input"];
}>;

export type CocmBillingAlgorithmDetailsQuery = {
  readonly __typename: "Query";
  readonly billingCocmBillingAlgorithm: {
    readonly __typename: "CocmBillingAlgorithm";
    readonly id: Ids.CocmBillingAlgorithmId;
    readonly name: string;
    readonly startDate: Date;
    readonly endDate: Date | null;
    readonly status: CocmBillingAlgorithmStatus;
    readonly entityTreeNode: {
      readonly __typename: "EntityTreeNode";
      readonly path: string;
      readonly minAge: number | null;
      readonly maxAge: number | null;
      readonly entity: {
        readonly __typename: "Entity";
        readonly id: Ids.EntityId;
        readonly name: string;
        readonly shortname: string;
        readonly entityType: EntityType;
      };
      readonly segments: ReadonlyArray<{
        readonly __typename: "EntityTreeSegment";
        readonly id: Ids.EntityId;
        readonly path: string;
        readonly entityType: EntityType;
        readonly name: string;
        readonly shortname: string;
        readonly rootPath: string;
      }>;
    };
    readonly codes: ReadonlyArray<{
      readonly __typename: "CocmBillingAlgorithmCode";
      readonly id: Ids.CocmBillingAlgorithmCodeId;
      readonly code: string;
      readonly initialEncounter: boolean;
      readonly rvusPerUnit: number;
      readonly valueUnits: number;
      readonly avgRatePerUnit: {
        readonly __typename: "Money";
        readonly cents: number;
        readonly currency: string;
      };
    }>;
    readonly rules: ReadonlyArray<{
      readonly __typename: "CocmBillingAlgorithmRule";
      readonly id: Ids.CocmBillingAlgorithmRuleId;
      readonly anyAssessmentCompleted: boolean;
      readonly billingDate: CocmBillingAlgorithmBillingDate;
      readonly minimumMinutes: number | null;
      readonly billableMinutes: number | null;
      readonly mustBeInitialEncounter: boolean;
      readonly mustBeSubsequentEncounter: boolean;
      readonly priority: number;
      readonly ruleCodes: ReadonlyArray<{
        readonly __typename: "CocmBillingAlgorithmRuleCode";
        readonly id: Ids.CocmBillingAlgorithmRuleCodeId;
        readonly units: number;
        readonly code: {
          readonly __typename: "CocmBillingAlgorithmCode";
          readonly id: Ids.CocmBillingAlgorithmCodeId;
          readonly code: string;
        };
      }>;
    }>;
  } | null;
};

export type CocmBillingAlgorithmListQueryVariables = Exact<{
  sortBy: InputMaybe<CocmBillingAlgorithmSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  status: InputMaybe<ReadonlyArray<CocmBillingAlgorithmStatus> | CocmBillingAlgorithmStatus>;
  entityTreeNode: InputMaybe<EntityTreeNodeParams>;
}>;

export type CocmBillingAlgorithmListQuery = {
  readonly __typename: "Query";
  readonly billingCocmBillingAlgorithms: {
    readonly __typename: "CocmBillingAlgorithmConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "CocmBillingAlgorithm";
      readonly id: Ids.CocmBillingAlgorithmId;
      readonly name: string;
      readonly startDate: Date;
      readonly endDate: Date | null;
      readonly status: CocmBillingAlgorithmStatus;
      readonly entityTreeNode: {
        readonly __typename: "EntityTreeNode";
        readonly path: string;
        readonly minAge: number | null;
        readonly maxAge: number | null;
        readonly entity: {
          readonly __typename: "Entity";
          readonly id: Ids.EntityId;
          readonly name: string;
          readonly shortname: string;
          readonly entityType: EntityType;
        };
        readonly segments: ReadonlyArray<{
          readonly __typename: "EntityTreeSegment";
          readonly id: Ids.EntityId;
          readonly path: string;
          readonly entityType: EntityType;
          readonly name: string;
          readonly shortname: string;
          readonly rootPath: string;
        }>;
      };
      readonly codes: ReadonlyArray<{
        readonly __typename: "CocmBillingAlgorithmCode";
        readonly id: Ids.CocmBillingAlgorithmCodeId;
        readonly code: string;
      }>;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type BillingPredictionQueryVariables = Exact<{
  sortBy: InputMaybe<EnrollmentMonthBillingRuleResultWinnerSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  month: InputMaybe<MonthParams>;
  forPanel: InputMaybe<PanelFilter>;
  withWinningRule: InputMaybe<
    | ReadonlyArray<Scalars["CocmBillingAlgorithmRuleId"]["input"]>
    | Scalars["CocmBillingAlgorithmRuleId"]["input"]
  >;
  withMonthOfTreatment: InputMaybe<ReadonlyArray<Scalars["Int"]["input"]> | Scalars["Int"]["input"]>;
  availableForNode: InputMaybe<EntityTreeNodeParams>;
  testPatient?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type BillingPredictionQuery = {
  readonly __typename: "Query";
  readonly billingEnrollmentMonthBillingRuleResultWinners: {
    readonly __typename: "EnrollmentMonthBillingRuleResultWinnerConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "EnrollmentMonthBillingRuleResultWinner";
      readonly id: Ids.EnrollmentMonthBillingRuleResultWinnerId;
      readonly enrollmentMonth: {
        readonly __typename: "EnrollmentMonth";
        readonly id: Ids.EnrollmentMonthId;
        readonly billableMinutes: number;
        readonly beginningOfMonth: Date;
        readonly month: number;
        readonly validatedMeasureCompleted: boolean;
        readonly minutesTargetCeiling: number;
        readonly minutesTargetFloor: number;
        readonly minutesTargetStatus: EnrollmentMonthMinutesTargetStatus;
        readonly careManager: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly orderedName: string;
        } | null;
        readonly pcp: { readonly __typename: "Provider"; readonly orderedName: string } | null;
        readonly enrollment: {
          readonly __typename: "Enrollment";
          readonly id: Ids.EnrollmentId;
          readonly careEpisode: {
            readonly __typename: "CareEpisode";
            readonly id: Ids.CareEpisodeId;
            readonly patient: {
              readonly __typename: "Patient";
              readonly id: Ids.PatientId;
              readonly name: string;
              readonly firstName: string | null;
              readonly lastName: string | null;
              readonly legalFirstName: string | null;
              readonly legalLastName: string | null;
              readonly mrn: string | null;
              readonly dob: Date | null;
              readonly isTest: boolean;
              readonly nameLastFirst: string;
            };
          };
        };
      };
      readonly winningResult: {
        readonly __typename: "EnrollmentMonthBillingRuleResult";
        readonly id: Ids.EnrollmentMonthBillingRuleResultId;
        readonly status: EnrollmentMonthBillingRuleResultStatus;
        readonly dateOfService: Date | null;
        readonly minutesDiffVsCurrentWinner: number | null;
        readonly minutesDiffVsNextPriority: number | null;
        readonly minutesShort: number | null;
        readonly priority: number;
        readonly ratePowerVsCurrentWinner: number | null;
        readonly ratePowerVsNextPriority: number | null;
        readonly ratePowerStrength: CocmBillingAlgorithmPowerStrength | null;
        readonly rvuDiffVsCurrentWinner: number | null;
        readonly rvuDiffVsNextPriority: number | null;
        readonly rvuPowerVsCurrentWinner: number | null;
        readonly rvuPowerVsNextPriority: number | null;
        readonly rvus: number | null;
        readonly rvuPowerStrength: CocmBillingAlgorithmPowerStrength | null;
        readonly billableMinutesDiffVsCurrentWinner: number | null;
        readonly billableMinutesDiffVsNextPriority: number | null;
        readonly billableMinutesPowerVsCurrentWinner: number | null;
        readonly billableMinutesPowerVsNextPriority: number | null;
        readonly billableMinutes: number | null;
        readonly billableMinutesPowerStrength: CocmBillingAlgorithmPowerStrength | null;
        readonly triggerDate: Date | null;
        readonly valueUnitDiffVsCurrentWinner: number | null;
        readonly valueUnitDiffVsNextPriority: number | null;
        readonly valueUnitPowerVsCurrentWinner: number | null;
        readonly valueUnitPowerVsNextPriority: number | null;
        readonly valueUnitPowerStrength: CocmBillingAlgorithmPowerStrength | null;
        readonly valueUnits: number | null;
        readonly failReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
        readonly disqualifyReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
        readonly expectedRate: {
          readonly __typename: "Money";
          readonly cents: number;
          readonly currency: string;
        };
        readonly rateDiffVsCurrentWinner: {
          readonly __typename: "Money";
          readonly cents: number;
          readonly currency: string;
        } | null;
        readonly rateDiffVsNextPriority: {
          readonly __typename: "Money";
          readonly cents: number;
          readonly currency: string;
        } | null;
        readonly rule: {
          readonly __typename: "CocmBillingAlgorithmRule";
          readonly id: Ids.CocmBillingAlgorithmRuleId;
          readonly anyAssessmentCompleted: boolean;
          readonly minimumMinutes: number | null;
          readonly billableMinutes: number | null;
          readonly mustBeInitialEncounter: boolean;
          readonly mustBeSubsequentEncounter: boolean;
          readonly ruleCodes: ReadonlyArray<{
            readonly __typename: "CocmBillingAlgorithmRuleCode";
            readonly id: Ids.CocmBillingAlgorithmRuleCodeId;
            readonly units: number;
            readonly code: {
              readonly __typename: "CocmBillingAlgorithmCode";
              readonly id: Ids.CocmBillingAlgorithmCodeId;
              readonly code: string;
            };
          }>;
        };
      } | null;
      readonly nextResult: {
        readonly __typename: "EnrollmentMonthBillingRuleResult";
        readonly id: Ids.EnrollmentMonthBillingRuleResultId;
        readonly status: EnrollmentMonthBillingRuleResultStatus;
        readonly dateOfService: Date | null;
        readonly minutesDiffVsCurrentWinner: number | null;
        readonly minutesDiffVsNextPriority: number | null;
        readonly minutesShort: number | null;
        readonly priority: number;
        readonly ratePowerVsCurrentWinner: number | null;
        readonly ratePowerVsNextPriority: number | null;
        readonly ratePowerStrength: CocmBillingAlgorithmPowerStrength | null;
        readonly rvuDiffVsCurrentWinner: number | null;
        readonly rvuDiffVsNextPriority: number | null;
        readonly rvuPowerVsCurrentWinner: number | null;
        readonly rvuPowerVsNextPriority: number | null;
        readonly rvus: number | null;
        readonly rvuPowerStrength: CocmBillingAlgorithmPowerStrength | null;
        readonly billableMinutesDiffVsCurrentWinner: number | null;
        readonly billableMinutesDiffVsNextPriority: number | null;
        readonly billableMinutesPowerVsCurrentWinner: number | null;
        readonly billableMinutesPowerVsNextPriority: number | null;
        readonly billableMinutes: number | null;
        readonly billableMinutesPowerStrength: CocmBillingAlgorithmPowerStrength | null;
        readonly triggerDate: Date | null;
        readonly valueUnitDiffVsCurrentWinner: number | null;
        readonly valueUnitDiffVsNextPriority: number | null;
        readonly valueUnitPowerVsCurrentWinner: number | null;
        readonly valueUnitPowerVsNextPriority: number | null;
        readonly valueUnitPowerStrength: CocmBillingAlgorithmPowerStrength | null;
        readonly valueUnits: number | null;
        readonly failReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
        readonly disqualifyReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
        readonly expectedRate: {
          readonly __typename: "Money";
          readonly cents: number;
          readonly currency: string;
        };
        readonly rateDiffVsCurrentWinner: {
          readonly __typename: "Money";
          readonly cents: number;
          readonly currency: string;
        } | null;
        readonly rateDiffVsNextPriority: {
          readonly __typename: "Money";
          readonly cents: number;
          readonly currency: string;
        } | null;
        readonly rule: {
          readonly __typename: "CocmBillingAlgorithmRule";
          readonly id: Ids.CocmBillingAlgorithmRuleId;
          readonly anyAssessmentCompleted: boolean;
          readonly minimumMinutes: number | null;
          readonly billableMinutes: number | null;
          readonly mustBeInitialEncounter: boolean;
          readonly mustBeSubsequentEncounter: boolean;
          readonly ruleCodes: ReadonlyArray<{
            readonly __typename: "CocmBillingAlgorithmRuleCode";
            readonly id: Ids.CocmBillingAlgorithmRuleCodeId;
            readonly units: number;
            readonly code: {
              readonly __typename: "CocmBillingAlgorithmCode";
              readonly id: Ids.CocmBillingAlgorithmCodeId;
              readonly code: string;
            };
          }>;
        };
      } | null;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type RuleResultDetailsFragment = {
  readonly __typename: "EnrollmentMonthBillingRuleResult";
  readonly id: Ids.EnrollmentMonthBillingRuleResultId;
  readonly status: EnrollmentMonthBillingRuleResultStatus;
  readonly dateOfService: Date | null;
  readonly minutesDiffVsCurrentWinner: number | null;
  readonly minutesDiffVsNextPriority: number | null;
  readonly minutesShort: number | null;
  readonly priority: number;
  readonly ratePowerVsCurrentWinner: number | null;
  readonly ratePowerVsNextPriority: number | null;
  readonly ratePowerStrength: CocmBillingAlgorithmPowerStrength | null;
  readonly rvuDiffVsCurrentWinner: number | null;
  readonly rvuDiffVsNextPriority: number | null;
  readonly rvuPowerVsCurrentWinner: number | null;
  readonly rvuPowerVsNextPriority: number | null;
  readonly rvus: number | null;
  readonly rvuPowerStrength: CocmBillingAlgorithmPowerStrength | null;
  readonly billableMinutesDiffVsCurrentWinner: number | null;
  readonly billableMinutesDiffVsNextPriority: number | null;
  readonly billableMinutesPowerVsCurrentWinner: number | null;
  readonly billableMinutesPowerVsNextPriority: number | null;
  readonly billableMinutes: number | null;
  readonly billableMinutesPowerStrength: CocmBillingAlgorithmPowerStrength | null;
  readonly triggerDate: Date | null;
  readonly valueUnitDiffVsCurrentWinner: number | null;
  readonly valueUnitDiffVsNextPriority: number | null;
  readonly valueUnitPowerVsCurrentWinner: number | null;
  readonly valueUnitPowerVsNextPriority: number | null;
  readonly valueUnitPowerStrength: CocmBillingAlgorithmPowerStrength | null;
  readonly valueUnits: number | null;
  readonly failReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
  readonly disqualifyReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
  readonly expectedRate: { readonly __typename: "Money"; readonly cents: number; readonly currency: string };
  readonly rateDiffVsCurrentWinner: {
    readonly __typename: "Money";
    readonly cents: number;
    readonly currency: string;
  } | null;
  readonly rateDiffVsNextPriority: {
    readonly __typename: "Money";
    readonly cents: number;
    readonly currency: string;
  } | null;
  readonly rule: {
    readonly __typename: "CocmBillingAlgorithmRule";
    readonly id: Ids.CocmBillingAlgorithmRuleId;
    readonly anyAssessmentCompleted: boolean;
    readonly minimumMinutes: number | null;
    readonly billableMinutes: number | null;
    readonly mustBeInitialEncounter: boolean;
    readonly mustBeSubsequentEncounter: boolean;
    readonly ruleCodes: ReadonlyArray<{
      readonly __typename: "CocmBillingAlgorithmRuleCode";
      readonly id: Ids.CocmBillingAlgorithmRuleCodeId;
      readonly units: number;
      readonly code: {
        readonly __typename: "CocmBillingAlgorithmCode";
        readonly id: Ids.CocmBillingAlgorithmCodeId;
        readonly code: string;
      };
    }>;
  };
};

export type BillingPredictionSummaryStatsQueryVariables = Exact<{
  month: MonthParams;
  entityTreeNode: EntityTreeNodeParams;
  monthOfEnrollment: InputMaybe<ReadonlyArray<Scalars["Int"]["input"]> | Scalars["Int"]["input"]>;
  rules: InputMaybe<
    | ReadonlyArray<Scalars["CocmBillingAlgorithmRuleId"]["input"]>
    | Scalars["CocmBillingAlgorithmRuleId"]["input"]
  >;
}>;

export type BillingPredictionSummaryStatsQuery = {
  readonly __typename: "Query";
  readonly billingCocmEstimatedBillingStatsSummary: {
    readonly __typename: "CocmEstimatedBillingStatsSummary";
    readonly maxRateCents: number;
    readonly maxRvus: number;
    readonly maxBillableMinutes: number;
    readonly maxValueUnits: number;
    readonly currentMonthData: ReadonlyArray<{
      readonly __typename: "CocmEstimatedBillingStatsSummaryStat";
      readonly avgEnrollmentMonths: number;
      readonly avgRateCents: number;
      readonly avgRvus: number;
      readonly avgValueUnits: number;
      readonly avgBillableMinutes: number;
      readonly rule: {
        readonly __typename: "CocmBillingAlgorithmRule";
        readonly id: Ids.CocmBillingAlgorithmRuleId;
        readonly priority: number;
      };
    }>;
    readonly previousMonthData: ReadonlyArray<{
      readonly __typename: "CocmEstimatedBillingStatsSummaryStat";
      readonly avgEnrollmentMonths: number;
      readonly avgRateCents: number;
      readonly avgRvus: number;
      readonly avgBillableMinutes: number;
      readonly avgValueUnits: number;
      readonly rule: {
        readonly __typename: "CocmBillingAlgorithmRule";
        readonly id: Ids.CocmBillingAlgorithmRuleId;
        readonly priority: number;
      };
    }>;
    readonly historicalAverageData: ReadonlyArray<{
      readonly __typename: "CocmEstimatedBillingStatsSummaryStat";
      readonly avgEnrollmentMonths: number;
      readonly avgRateCents: number;
      readonly avgRvus: number;
      readonly avgBillableMinutes: number;
      readonly avgValueUnits: number;
      readonly rule: {
        readonly __typename: "CocmBillingAlgorithmRule";
        readonly id: Ids.CocmBillingAlgorithmRuleId;
        readonly priority: number;
      };
    }>;
    readonly entityTreeNode: { readonly __typename: "EntityTreeNode"; readonly path: string };
  } | null;
};

export type BillingPredictionWinnerDetailsQueryVariables = Exact<{
  id: Scalars["EnrollmentMonthBillingRuleResultWinnerId"]["input"];
}>;

export type BillingPredictionWinnerDetailsQuery = {
  readonly __typename: "Query";
  readonly billingEnrollmentMonthBillingRuleResultWinner: {
    readonly __typename: "EnrollmentMonthBillingRuleResultWinner";
    readonly id: Ids.EnrollmentMonthBillingRuleResultWinnerId;
    readonly enrollmentMonth: {
      readonly __typename: "EnrollmentMonth";
      readonly id: Ids.EnrollmentMonthId;
      readonly billableMinutes: number;
      readonly beginningOfMonth: Date;
      readonly month: number;
      readonly validatedMeasureCompleted: boolean;
      readonly firstValidatedMeasureCompletedAt: Date | null;
      readonly lastValidatedMeasureCompletedAt: Date | null;
      readonly careManager: {
        readonly __typename: "Provider";
        readonly id: Ids.ProviderId;
        readonly title: string | null;
        readonly user: {
          readonly __typename: "User";
          readonly id: Ids.UserId;
          readonly name: string;
          readonly firstName: string | null;
        };
      } | null;
      readonly pcp: {
        readonly __typename: "Provider";
        readonly id: Ids.ProviderId;
        readonly title: string | null;
        readonly user: {
          readonly __typename: "User";
          readonly id: Ids.UserId;
          readonly name: string;
          readonly firstName: string | null;
        };
      } | null;
      readonly enrollment: {
        readonly __typename: "Enrollment";
        readonly id: Ids.EnrollmentId;
        readonly firstValidatedMeasureCompletedAt: Date | null;
        readonly enrolledAt: Date;
        readonly careEpisode: {
          readonly __typename: "CareEpisode";
          readonly id: Ids.CareEpisodeId;
          readonly patient: {
            readonly __typename: "Patient";
            readonly id: Ids.PatientId;
            readonly name: string;
            readonly firstName: string | null;
            readonly lastName: string | null;
            readonly legalFirstName: string | null;
            readonly legalLastName: string | null;
            readonly mrn: string | null;
            readonly dob: Date | null;
            readonly isTest: boolean;
            readonly nameLastFirst: string;
          };
        };
      };
    };
    readonly winningResult: {
      readonly __typename: "EnrollmentMonthBillingRuleResult";
      readonly id: Ids.EnrollmentMonthBillingRuleResultId;
      readonly status: EnrollmentMonthBillingRuleResultStatus;
      readonly dateOfService: Date | null;
      readonly minutesDiffVsCurrentWinner: number | null;
      readonly minutesDiffVsNextPriority: number | null;
      readonly minutesShort: number | null;
      readonly priority: number;
      readonly ratePowerVsCurrentWinner: number | null;
      readonly ratePowerVsNextPriority: number | null;
      readonly ratePowerStrength: CocmBillingAlgorithmPowerStrength | null;
      readonly rvuDiffVsCurrentWinner: number | null;
      readonly rvuDiffVsNextPriority: number | null;
      readonly rvuPowerVsCurrentWinner: number | null;
      readonly rvuPowerVsNextPriority: number | null;
      readonly rvus: number | null;
      readonly rvuPowerStrength: CocmBillingAlgorithmPowerStrength | null;
      readonly billableMinutesDiffVsCurrentWinner: number | null;
      readonly billableMinutesDiffVsNextPriority: number | null;
      readonly billableMinutesPowerVsCurrentWinner: number | null;
      readonly billableMinutesPowerVsNextPriority: number | null;
      readonly billableMinutes: number | null;
      readonly billableMinutesPowerStrength: CocmBillingAlgorithmPowerStrength | null;
      readonly triggerDate: Date | null;
      readonly valueUnitDiffVsCurrentWinner: number | null;
      readonly valueUnitDiffVsNextPriority: number | null;
      readonly valueUnitPowerVsCurrentWinner: number | null;
      readonly valueUnitPowerVsNextPriority: number | null;
      readonly valueUnitPowerStrength: CocmBillingAlgorithmPowerStrength | null;
      readonly valueUnits: number | null;
      readonly failReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
      readonly disqualifyReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
      readonly expectedRate: {
        readonly __typename: "Money";
        readonly cents: number;
        readonly currency: string;
      };
      readonly rateDiffVsCurrentWinner: {
        readonly __typename: "Money";
        readonly cents: number;
        readonly currency: string;
      } | null;
      readonly rateDiffVsNextPriority: {
        readonly __typename: "Money";
        readonly cents: number;
        readonly currency: string;
      } | null;
      readonly rule: {
        readonly __typename: "CocmBillingAlgorithmRule";
        readonly id: Ids.CocmBillingAlgorithmRuleId;
        readonly anyAssessmentCompleted: boolean;
        readonly minimumMinutes: number | null;
        readonly billableMinutes: number | null;
        readonly mustBeInitialEncounter: boolean;
        readonly mustBeSubsequentEncounter: boolean;
        readonly ruleCodes: ReadonlyArray<{
          readonly __typename: "CocmBillingAlgorithmRuleCode";
          readonly id: Ids.CocmBillingAlgorithmRuleCodeId;
          readonly units: number;
          readonly code: {
            readonly __typename: "CocmBillingAlgorithmCode";
            readonly id: Ids.CocmBillingAlgorithmCodeId;
            readonly code: string;
          };
        }>;
      };
    } | null;
    readonly nextResult: {
      readonly __typename: "EnrollmentMonthBillingRuleResult";
      readonly id: Ids.EnrollmentMonthBillingRuleResultId;
      readonly status: EnrollmentMonthBillingRuleResultStatus;
      readonly dateOfService: Date | null;
      readonly minutesDiffVsCurrentWinner: number | null;
      readonly minutesDiffVsNextPriority: number | null;
      readonly minutesShort: number | null;
      readonly priority: number;
      readonly ratePowerVsCurrentWinner: number | null;
      readonly ratePowerVsNextPriority: number | null;
      readonly ratePowerStrength: CocmBillingAlgorithmPowerStrength | null;
      readonly rvuDiffVsCurrentWinner: number | null;
      readonly rvuDiffVsNextPriority: number | null;
      readonly rvuPowerVsCurrentWinner: number | null;
      readonly rvuPowerVsNextPriority: number | null;
      readonly rvus: number | null;
      readonly rvuPowerStrength: CocmBillingAlgorithmPowerStrength | null;
      readonly billableMinutesDiffVsCurrentWinner: number | null;
      readonly billableMinutesDiffVsNextPriority: number | null;
      readonly billableMinutesPowerVsCurrentWinner: number | null;
      readonly billableMinutesPowerVsNextPriority: number | null;
      readonly billableMinutes: number | null;
      readonly billableMinutesPowerStrength: CocmBillingAlgorithmPowerStrength | null;
      readonly triggerDate: Date | null;
      readonly valueUnitDiffVsCurrentWinner: number | null;
      readonly valueUnitDiffVsNextPriority: number | null;
      readonly valueUnitPowerVsCurrentWinner: number | null;
      readonly valueUnitPowerVsNextPriority: number | null;
      readonly valueUnitPowerStrength: CocmBillingAlgorithmPowerStrength | null;
      readonly valueUnits: number | null;
      readonly failReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
      readonly disqualifyReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
      readonly expectedRate: {
        readonly __typename: "Money";
        readonly cents: number;
        readonly currency: string;
      };
      readonly rateDiffVsCurrentWinner: {
        readonly __typename: "Money";
        readonly cents: number;
        readonly currency: string;
      } | null;
      readonly rateDiffVsNextPriority: {
        readonly __typename: "Money";
        readonly cents: number;
        readonly currency: string;
      } | null;
      readonly rule: {
        readonly __typename: "CocmBillingAlgorithmRule";
        readonly id: Ids.CocmBillingAlgorithmRuleId;
        readonly anyAssessmentCompleted: boolean;
        readonly minimumMinutes: number | null;
        readonly billableMinutes: number | null;
        readonly mustBeInitialEncounter: boolean;
        readonly mustBeSubsequentEncounter: boolean;
        readonly ruleCodes: ReadonlyArray<{
          readonly __typename: "CocmBillingAlgorithmRuleCode";
          readonly id: Ids.CocmBillingAlgorithmRuleCodeId;
          readonly units: number;
          readonly code: {
            readonly __typename: "CocmBillingAlgorithmCode";
            readonly id: Ids.CocmBillingAlgorithmCodeId;
            readonly code: string;
          };
        }>;
      };
    } | null;
    readonly results: ReadonlyArray<{
      readonly __typename: "EnrollmentMonthBillingRuleResult";
      readonly id: Ids.EnrollmentMonthBillingRuleResultId;
      readonly status: EnrollmentMonthBillingRuleResultStatus;
      readonly dateOfService: Date | null;
      readonly minutesDiffVsCurrentWinner: number | null;
      readonly minutesDiffVsNextPriority: number | null;
      readonly minutesShort: number | null;
      readonly priority: number;
      readonly ratePowerVsCurrentWinner: number | null;
      readonly ratePowerVsNextPriority: number | null;
      readonly ratePowerStrength: CocmBillingAlgorithmPowerStrength | null;
      readonly rvuDiffVsCurrentWinner: number | null;
      readonly rvuDiffVsNextPriority: number | null;
      readonly rvuPowerVsCurrentWinner: number | null;
      readonly rvuPowerVsNextPriority: number | null;
      readonly rvus: number | null;
      readonly rvuPowerStrength: CocmBillingAlgorithmPowerStrength | null;
      readonly billableMinutesDiffVsCurrentWinner: number | null;
      readonly billableMinutesDiffVsNextPriority: number | null;
      readonly billableMinutesPowerVsCurrentWinner: number | null;
      readonly billableMinutesPowerVsNextPriority: number | null;
      readonly billableMinutes: number | null;
      readonly billableMinutesPowerStrength: CocmBillingAlgorithmPowerStrength | null;
      readonly triggerDate: Date | null;
      readonly valueUnitDiffVsCurrentWinner: number | null;
      readonly valueUnitDiffVsNextPriority: number | null;
      readonly valueUnitPowerVsCurrentWinner: number | null;
      readonly valueUnitPowerVsNextPriority: number | null;
      readonly valueUnitPowerStrength: CocmBillingAlgorithmPowerStrength | null;
      readonly valueUnits: number | null;
      readonly failReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
      readonly disqualifyReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
      readonly expectedRate: {
        readonly __typename: "Money";
        readonly cents: number;
        readonly currency: string;
      };
      readonly rateDiffVsCurrentWinner: {
        readonly __typename: "Money";
        readonly cents: number;
        readonly currency: string;
      } | null;
      readonly rateDiffVsNextPriority: {
        readonly __typename: "Money";
        readonly cents: number;
        readonly currency: string;
      } | null;
      readonly rule: {
        readonly __typename: "CocmBillingAlgorithmRule";
        readonly id: Ids.CocmBillingAlgorithmRuleId;
        readonly anyAssessmentCompleted: boolean;
        readonly minimumMinutes: number | null;
        readonly billableMinutes: number | null;
        readonly mustBeInitialEncounter: boolean;
        readonly mustBeSubsequentEncounter: boolean;
        readonly ruleCodes: ReadonlyArray<{
          readonly __typename: "CocmBillingAlgorithmRuleCode";
          readonly id: Ids.CocmBillingAlgorithmRuleCodeId;
          readonly units: number;
          readonly code: {
            readonly __typename: "CocmBillingAlgorithmCode";
            readonly id: Ids.CocmBillingAlgorithmCodeId;
            readonly code: string;
          };
        }>;
      };
    }>;
  } | null;
};

export type CocmBillingDashboardRulesQueryVariables = Exact<{
  entityTreeNode: InputMaybe<EntityTreeNodeParams>;
}>;

export type CocmBillingDashboardRulesQuery = {
  readonly __typename: "Query";
  readonly billingCocmBillingAlgorithms: {
    readonly __typename: "CocmBillingAlgorithmConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "CocmBillingAlgorithm";
      readonly id: Ids.CocmBillingAlgorithmId;
      readonly name: string;
      readonly startDate: Date;
      readonly endDate: Date | null;
      readonly status: CocmBillingAlgorithmStatus;
      readonly entityTreeNode: {
        readonly __typename: "EntityTreeNode";
        readonly path: string;
        readonly minAge: number | null;
        readonly maxAge: number | null;
        readonly entity: {
          readonly __typename: "Entity";
          readonly id: Ids.EntityId;
          readonly name: string;
          readonly shortname: string;
          readonly entityType: EntityType;
        };
        readonly segments: ReadonlyArray<{
          readonly __typename: "EntityTreeSegment";
          readonly id: Ids.EntityId;
          readonly path: string;
          readonly entityType: EntityType;
          readonly name: string;
          readonly shortname: string;
          readonly rootPath: string;
        }>;
      };
      readonly codes: ReadonlyArray<{
        readonly __typename: "CocmBillingAlgorithmCode";
        readonly id: Ids.CocmBillingAlgorithmCodeId;
        readonly code: string;
      }>;
      readonly rules: ReadonlyArray<{
        readonly __typename: "CocmBillingAlgorithmRule";
        readonly id: Ids.CocmBillingAlgorithmRuleId;
        readonly anyAssessmentCompleted: boolean;
        readonly billingDate: CocmBillingAlgorithmBillingDate;
        readonly minimumMinutes: number | null;
        readonly mustBeInitialEncounter: boolean;
        readonly mustBeSubsequentEncounter: boolean;
        readonly priority: number;
        readonly ruleCodes: ReadonlyArray<{
          readonly __typename: "CocmBillingAlgorithmRuleCode";
          readonly id: Ids.CocmBillingAlgorithmRuleCodeId;
          readonly units: number;
          readonly code: {
            readonly __typename: "CocmBillingAlgorithmCode";
            readonly id: Ids.CocmBillingAlgorithmCodeId;
            readonly code: string;
          };
        }>;
      }>;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type EstimatedBillingCumulativeEffortQueryVariables = Exact<{
  month: MonthParams;
  entityTreeNode: EntityTreeNodeParams;
  valueType: CocmBillingAlgorithmRuleCheckValue;
  monthOfEnrollment: InputMaybe<ReadonlyArray<Scalars["Int"]["input"]> | Scalars["Int"]["input"]>;
  ruleIds: InputMaybe<
    | ReadonlyArray<Scalars["CocmBillingAlgorithmRuleId"]["input"]>
    | Scalars["CocmBillingAlgorithmRuleId"]["input"]
  >;
}>;

export type EstimatedBillingCumulativeEffortQuery = {
  readonly __typename: "Query";
  readonly billingEstimatedBillingCumulativeEffort: {
    readonly __typename: "EstimatedBillingCumulativeEffort";
    readonly dataPoints: ReadonlyArray<{
      readonly __typename: "EstimatedBillingCumulativeEffortDatum";
      readonly cumulativeMinutes: number;
      readonly cumulativeRvus: number;
      readonly cumulativeBillableMinutes: number;
      readonly cumulativeValueUnits: number;
      readonly cumulativeRateCents: number;
      readonly cumulativeValueWhenCompleted: number | null;
      readonly additionalPercentOfTarget: number | null;
      readonly totalPercentOfTarget: number | null;
      readonly winnerId: Ids.EnrollmentMonthBillingRuleResultWinnerId | null;
    }>;
  } | null;
};

export type MeasurementPlanScaleDetailsFragment = {
  readonly __typename: "MeasurementPlanScale";
  readonly id: Ids.MeasurementPlanScaleId;
  readonly format: InvitationFormat | null;
  readonly phase: InvitationPhase | null;
  readonly alternationGroup: string | null;
  readonly discontinueSession: number | null;
  readonly onsetSession: number | null;
  readonly onsetTime: GqlScalars.Interval | null;
  readonly repeatSessions: number | null;
  readonly maxRepeatInterval: GqlScalars.Interval | null;
  readonly discontinueTime: GqlScalars.Interval | null;
  readonly measureOnsetFrom: MeasurementPlanMeasureOnsetFrom | null;
  readonly scale:
    | {
        readonly __typename: "AdministrableScale";
        readonly id: Ids.ScaleId;
        readonly name: string;
        readonly shortname: string | null;
      }
    | {
        readonly __typename: "Subscale";
        readonly id: Ids.ScaleId;
        readonly name: string;
        readonly shortname: string | null;
      };
};

export type DefaultTaskListQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<TaskListItemSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  forPanel: InputMaybe<PanelFilter>;
  status: InputMaybe<TaskStatus>;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type DefaultTaskListQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareMyDefaultTaskList: {
    readonly __typename: "TaskList";
    readonly id: Ids.TaskListId;
    readonly name: string;
    readonly isDefault: boolean;
    readonly taskListItems: {
      readonly __typename: "TaskListItemConnection";
      readonly totalCount: number;
      readonly pageInfo: {
        readonly __typename: "PageInfo";
        readonly endCursor: string | null;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null;
      };
      readonly nodes: ReadonlyArray<{
        readonly __typename: "TaskListItem";
        readonly id: Ids.TaskListItemId;
        readonly position: number;
        readonly task: {
          readonly __typename: "Task";
          readonly id: Ids.TaskId;
          readonly title: string;
          readonly status: TaskStatus;
          readonly createdAt: Date;
          readonly dueAt: Date | null;
          readonly patient: {
            readonly __typename: "Patient";
            readonly id: Ids.PatientId;
            readonly name: string;
            readonly firstName: string | null;
          } | null;
        };
      }>;
    };
    readonly nonPanelItems: { readonly __typename: "TaskListItemConnection"; readonly totalCount: number };
  } | null;
};

export type MergePlaceholderTaskWithExistingTaskMutationVariables = Exact<{
  input: MergePlaceholderTaskWithExistingTaskInput;
}>;

export type MergePlaceholderTaskWithExistingTaskMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareMergePlaceholderTaskWithExistingTask: {
    readonly __typename: "MergePlaceholderTaskWithExistingTaskPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "MergePlaceholderTaskWithExistingTaskResult";
      readonly task: {
        readonly __typename: "Task";
        readonly id: Ids.TaskId;
        readonly title: string;
        readonly body: string;
        readonly createdAt: Date;
        readonly dueAt: Date | null;
        readonly status: TaskStatus;
        readonly isPlaceholder: boolean;
        readonly patient: {
          readonly __typename: "Patient";
          readonly id: Ids.PatientId;
          readonly name: string;
          readonly isTest: boolean;
        } | null;
        readonly assignedTo: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
        readonly notes: {
          readonly __typename: "NoteConnection";
          readonly nodes: ReadonlyArray<{
            readonly __typename: "Note";
            readonly id: Ids.NoteId;
            readonly text: string;
            readonly createdAt: Date;
            readonly author: {
              readonly __typename: "Provider";
              readonly id: Ids.ProviderId;
              readonly name: string;
            };
            readonly timeEntry: { readonly __typename: "TimeEntryLog"; readonly startTime: Date } | null;
          }>;
        };
        readonly timeEntries: {
          readonly __typename: "TimeEntryLogConnection";
          readonly nodes: ReadonlyArray<{
            readonly __typename: "TimeEntryLog";
            readonly id: Ids.TimeEntryLogId;
            readonly startTime: Date;
            readonly endTime: Date | null;
            readonly durationSeconds: number | null;
            readonly createdAt: Date;
            readonly provider: {
              readonly __typename: "Provider";
              readonly id: Ids.ProviderId;
              readonly name: string;
            };
          }>;
        };
        readonly creationLog: {
          readonly __typename: "TaskCreationLog";
          readonly id: Ids.TaskCreationLogId;
          readonly creationType: TaskCreationType;
          readonly patientSession: {
            readonly __typename: "PatientSession";
            readonly id: Ids.PatientSessionId;
            readonly targetDate: Date;
            readonly patient: {
              readonly __typename: "Patient";
              readonly id: Ids.PatientId;
              readonly name: string;
              readonly isTest: boolean;
            };
          } | null;
          readonly requestedBy: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          } | null;
          readonly assignedBy: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          } | null;
        } | null;
      };
    } | null;
  } | null;
};

export type MoveDefaultTaskListItemMutationVariables = Exact<{
  input: MoveTaskListItemToPositionInDefaultTaskListInput;
}>;

export type MoveDefaultTaskListItemMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareMoveTaskListItemToPositionInDefaultTaskList: {
    readonly __typename: "MoveTaskListItemToPositionInDefaultTaskListPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "MoveTaskListItemToPositionInDefaultTaskListResult";
      readonly taskList: {
        readonly __typename: "TaskList";
        readonly id: Ids.TaskListId;
        readonly taskListItems: {
          readonly __typename: "TaskListItemConnection";
          readonly nodes: ReadonlyArray<{
            readonly __typename: "TaskListItem";
            readonly id: Ids.TaskListItemId;
            readonly position: number;
          }>;
        };
      };
    } | null;
  } | null;
};

export type MoveDefaultTaskListTaskMutationVariables = Exact<{
  input: MoveTaskToPositionInDefaultTaskListInput;
}>;

export type MoveDefaultTaskListTaskMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareMoveTaskToPositionInDefaultTaskList: {
    readonly __typename: "MoveTaskToPositionInDefaultTaskListPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "MoveTaskToPositionInDefaultTaskListResult";
      readonly taskList: {
        readonly __typename: "TaskList";
        readonly id: Ids.TaskListId;
        readonly taskListItems: {
          readonly __typename: "TaskListItemConnection";
          readonly nodes: ReadonlyArray<{
            readonly __typename: "TaskListItem";
            readonly id: Ids.TaskListItemId;
            readonly position: number;
          }>;
        };
      } | null;
    } | null;
  } | null;
};

export type DailyAppointmentQueryVariables = Exact<{
  unit: InputMaybe<OrganizationFilter>;
  startDateAfter: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  startDateBefore: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
}>;

export type DailyAppointmentQuery = {
  readonly __typename: "Query";
  readonly schedulingAppointments: {
    readonly __typename: "AppointmentConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Appointment";
      readonly id: Ids.AppointmentId;
      readonly startDate: Date | null;
      readonly endDate: Date | null;
      readonly patient: { readonly __typename: "Patient"; readonly id: Ids.PatientId; readonly name: string };
      readonly careEpisode: {
        readonly __typename: "CareEpisode";
        readonly id: Ids.CareEpisodeId;
        readonly careEpisodeProviders: ReadonlyArray<{
          readonly __typename: "CareEpisodeProvider";
          readonly relationship: CareEpisodeProviderRelationship;
          readonly id: Ids.CareEpisodeProviderId;
          readonly provider: {
            readonly __typename: "Provider";
            readonly name: string;
            readonly id: Ids.ProviderId;
          };
        }>;
      } | null;
    }>;
  } | null;
};

export type FindingsQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  unresolvedOnly: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type FindingsQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareFindings: {
    readonly __typename: "FindingConnection";
    readonly totalCount: number;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly startCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
    };
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Finding";
      readonly id: Ids.FindingId;
      readonly flavor: FindingFlavor;
      readonly createdAt: Date;
      readonly patient: {
        readonly __typename: "Patient";
        readonly id: Ids.PatientId;
        readonly name: string;
      } | null;
      readonly invitation: {
        readonly __typename: "Invitation";
        readonly id: Ids.InvitationId;
        readonly reportScales: ReadonlyArray<{
          readonly __typename: "ReportScale";
          readonly id: Ids.ReportScaleId;
          readonly scale:
            | { readonly __typename: "AdministrableScale"; readonly shortname: string | null }
            | { readonly __typename: "Subscale"; readonly shortname: string | null };
        }>;
        readonly user: {
          readonly __typename: "User";
          readonly id: Ids.UserId;
          readonly name: string;
          readonly firstName: string | null;
        };
      } | null;
    }>;
  } | null;
  readonly nonPanelFindings: { readonly __typename: "FindingConnection"; readonly totalCount: number } | null;
};

export type AcceptFindingMutationVariables = Exact<{
  input: AcceptFindingInput;
}>;

export type AcceptFindingMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareAcceptFinding: {
    readonly __typename: "AcceptFindingPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "AcceptFindingResult";
      readonly finding: {
        readonly __typename: "Finding";
        readonly id: Ids.FindingId;
        readonly flavor: FindingFlavor;
        readonly dismissedAt: Date | null;
        readonly createdAt: Date;
        readonly patient: {
          readonly __typename: "Patient";
          readonly id: Ids.PatientId;
          readonly name: string;
        } | null;
      };
    } | null;
  } | null;
};

export type DismissFindingMutationVariables = Exact<{
  input: DismissFindingInput;
}>;

export type DismissFindingMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareDismissFinding: {
    readonly __typename: "DismissFindingPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "DismissFindingResult";
      readonly finding: {
        readonly __typename: "Finding";
        readonly id: Ids.FindingId;
        readonly flavor: FindingFlavor;
        readonly dismissedAt: Date | null;
        readonly createdAt: Date;
        readonly patient: {
          readonly __typename: "Patient";
          readonly id: Ids.PatientId;
          readonly name: string;
        } | null;
      };
    } | null;
  } | null;
};

export type InvalidateFindingsMutationVariables = Exact<{
  input: InvalidateFindingsInput;
}>;

export type InvalidateFindingsMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareInvalidateFindings: {
    readonly __typename: "InvalidateFindingsPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "InvalidateFindingsResult";
      readonly findings: ReadonlyArray<{
        readonly __typename: "Finding";
        readonly id: Ids.FindingId;
        readonly flavor: FindingFlavor;
        readonly invalidatedAt: Date | null;
      }>;
    } | null;
  } | null;
};

export type SnoozeFindingMutationVariables = Exact<{
  input: SnoozeFindingInput;
}>;

export type SnoozeFindingMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareSnoozeFinding: {
    readonly __typename: "SnoozeFindingPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "SnoozeFindingResult";
      readonly finding: {
        readonly __typename: "Finding";
        readonly id: Ids.FindingId;
        readonly flavor: FindingFlavor;
        readonly dismissedAt: Date | null;
        readonly createdAt: Date;
        readonly patient: {
          readonly __typename: "Patient";
          readonly id: Ids.PatientId;
          readonly name: string;
        } | null;
      };
    } | null;
  } | null;
};

export type CancelCaseConsultMutationVariables = Exact<{
  input: CancelCaseConsultInput;
}>;

export type CancelCaseConsultMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareCancelCaseConsult: {
    readonly __typename: "CancelCaseConsultPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CancelCaseConsultResult";
      readonly caseConsult: {
        readonly __typename: "CaseConsult";
        readonly id: Ids.CaseConsultId;
        readonly status: CaseConsultStatus;
      };
    } | null;
  } | null;
};

export type CaseConsultDetailsQueryVariables = Exact<{
  id: Scalars["CaseConsultId"]["input"];
}>;

export type CaseConsultDetailsQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareCaseConsult: {
    readonly __typename: "CaseConsult";
    readonly id: Ids.CaseConsultId;
    readonly caseSummary: string | null;
    readonly consultationDocumentation: string | null;
    readonly consultationNotes: string | null;
    readonly status: CaseConsultStatus;
    readonly summaryUpdatedAt: Date | null;
    readonly enrollment: {
      readonly __typename: "Enrollment";
      readonly id: Ids.EnrollmentId;
      readonly careEpisode: {
        readonly __typename: "CareEpisode";
        readonly id: Ids.CareEpisodeId;
        readonly collaborativeCareCareEpisodeTreatmentTargets: ReadonlyArray<{
          readonly __typename: "CareEpisodeTreatmentTarget";
          readonly id: Ids.CareEpisodeTreatmentTargetId;
          readonly startedDate: Date;
          readonly treatmentTarget: {
            readonly __typename: "TreatmentTarget";
            readonly id: Ids.TreatmentTargetId;
            readonly name: string;
          };
        }>;
      };
    };
    readonly patient: {
      readonly __typename: "Patient";
      readonly id: Ids.PatientId;
      readonly note: string | null;
      readonly name: string;
      readonly mrn: string | null;
    };
    readonly consultRequests: {
      readonly __typename: "ConsultRequestConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "ConsultRequest";
        readonly id: Ids.ConsultRequestId;
        readonly requestDate: Date;
        readonly requestReason: ConsultRequestReason;
        readonly requestText: string | null;
        readonly provider: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
      }>;
    };
    readonly consultMeeting: {
      readonly __typename: "ConsultMeeting";
      readonly id: Ids.ConsultMeetingId;
      readonly status: ConsultMeetingStatus;
    } | null;
    readonly caseSummaryTask: { readonly __typename: "Task"; readonly id: Ids.TaskId } | null;
    readonly consultMeetingTask: { readonly __typename: "Task"; readonly id: Ids.TaskId } | null;
    readonly documentationTask: { readonly __typename: "Task"; readonly id: Ids.TaskId } | null;
  } | null;
};

export type CaseConsultListQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  forPanel?: InputMaybe<PanelFilter>;
  statuses?: InputMaybe<ReadonlyArray<CaseConsultStatus> | CaseConsultStatus>;
  consultMeetingId?: InputMaybe<Scalars["ConsultMeetingId"]["input"]>;
  patient?: InputMaybe<PatientFilter>;
  primaryCareProviderId?: InputMaybe<Scalars["ProviderId"]["input"]>;
  careManagerId?: InputMaybe<Scalars["ProviderId"]["input"]>;
  enrollmentId?: InputMaybe<Scalars["EnrollmentId"]["input"]>;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type CaseConsultListQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareCaseConsults: {
    readonly __typename: "CaseConsultConnection";
    readonly totalCount: number;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
    readonly nodes: ReadonlyArray<{
      readonly __typename: "CaseConsult";
      readonly id: Ids.CaseConsultId;
      readonly status: CaseConsultStatus;
      readonly caseSummary: string | null;
      readonly consultationNotes: string | null;
      readonly consultationDocumentation: string | null;
      readonly presentationOrder: number;
      readonly enrollment: { readonly __typename: "Enrollment"; readonly id: Ids.EnrollmentId };
      readonly patient: {
        readonly __typename: "Patient";
        readonly id: Ids.PatientId;
        readonly name: string;
        readonly cocmPrimaryCareProvider: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        } | null;
      };
      readonly consultRequests: {
        readonly __typename: "ConsultRequestConnection";
        readonly nodes: ReadonlyArray<{
          readonly __typename: "ConsultRequest";
          readonly id: Ids.ConsultRequestId;
          readonly requestReason: ConsultRequestReason;
          readonly requestText: string | null;
          readonly requestDate: Date;
          readonly provider: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          };
        }>;
      };
      readonly consultMeeting: {
        readonly __typename: "ConsultMeeting";
        readonly id: Ids.ConsultMeetingId;
        readonly status: ConsultMeetingStatus;
        readonly startTime: Date;
      } | null;
    }>;
  } | null;
  readonly nonPanelConsults: {
    readonly __typename: "CaseConsultConnection";
    readonly totalCount: number;
  } | null;
};

export type ConsultMeetingListQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  forPanel?: InputMaybe<PanelFilter>;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ConsultMeetingListQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareConsultMeetings: {
    readonly __typename: "ConsultMeetingConnection";
    readonly totalCount: number;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
    readonly nodes: ReadonlyArray<{
      readonly __typename: "ConsultMeeting";
      readonly id: Ids.ConsultMeetingId;
      readonly startTime: Date;
      readonly status: ConsultMeetingStatus;
      readonly attendees: {
        readonly __typename: "ConsultMeetingAttendeeConnection";
        readonly nodes: ReadonlyArray<{
          readonly __typename: "ConsultMeetingAttendee";
          readonly id: Ids.ConsultMeetingAttendeeId;
          readonly role: ConsultMeetingAttendeeRole;
          readonly provider: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          };
        }>;
      } | null;
      readonly cases: {
        readonly __typename: "CaseConsultConnection";
        readonly nodes: ReadonlyArray<{
          readonly __typename: "CaseConsult";
          readonly id: Ids.CaseConsultId;
          readonly patient: {
            readonly __typename: "Patient";
            readonly id: Ids.PatientId;
            readonly name: string;
          };
        }>;
      } | null;
    }>;
  } | null;
  readonly nonPanelConsultMeetings: {
    readonly __typename: "ConsultMeetingConnection";
    readonly totalCount: number;
  } | null;
};

export type ConsultMeetingSummaryQueryVariables = Exact<{
  id: Scalars["ConsultMeetingId"]["input"];
}>;

export type ConsultMeetingSummaryQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareConsultMeeting: {
    readonly __typename: "ConsultMeeting";
    readonly id: Ids.ConsultMeetingId;
    readonly startTime: Date;
    readonly endTime: Date | null;
    readonly status: ConsultMeetingStatus;
    readonly attendees: {
      readonly __typename: "ConsultMeetingAttendeeConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "ConsultMeetingAttendee";
        readonly id: Ids.ConsultMeetingAttendeeId;
        readonly role: ConsultMeetingAttendeeRole;
        readonly provider: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
      }>;
    } | null;
  } | null;
};

export type CreateConsultMeetingAttendeeMutationVariables = Exact<{
  input: CreateConsultMeetingAttendeeInput;
}>;

export type CreateConsultMeetingAttendeeMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareCreateConsultMeetingAttendee: {
    readonly __typename: "CreateConsultMeetingAttendeePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateConsultMeetingAttendeeResult";
      readonly consultMeetingAttendee: {
        readonly __typename: "ConsultMeetingAttendee";
        readonly id: Ids.ConsultMeetingAttendeeId;
        readonly provider: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
        readonly consultMeeting: {
          readonly __typename: "ConsultMeeting";
          readonly id: Ids.ConsultMeetingId;
          readonly attendees: {
            readonly __typename: "ConsultMeetingAttendeeConnection";
            readonly nodes: ReadonlyArray<{
              readonly __typename: "ConsultMeetingAttendee";
              readonly id: Ids.ConsultMeetingAttendeeId;
            }>;
          } | null;
        };
      };
    } | null;
  } | null;
};

export type EndConsultReviewMutationVariables = Exact<{
  input: EndCaseConsultReviewInput;
}>;

export type EndConsultReviewMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareEndCaseConsultReview: {
    readonly __typename: "EndCaseConsultReviewPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EndCaseConsultReviewResult";
      readonly caseConsult: {
        readonly __typename: "CaseConsult";
        readonly id: Ids.CaseConsultId;
        readonly status: CaseConsultStatus;
      };
      readonly myActiveTimeEntryLog: {
        readonly __typename: "MyActiveTimeEntryLog";
        readonly timeEntryLog: {
          readonly __typename: "TimeEntryLog";
          readonly id: Ids.TimeEntryLogId;
          readonly clientStartTime: Date;
          readonly startTime: Date;
          readonly durationSeconds: number | null;
        } | null;
      };
    } | null;
  } | null;
};

export type MarkCaseConsultCompleteMutationVariables = Exact<{
  input: MarkCaseConsultCompleteInput;
}>;

export type MarkCaseConsultCompleteMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareMarkCaseConsultComplete: {
    readonly __typename: "MarkCaseConsultCompletePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "MarkCaseConsultCompleteResult";
      readonly caseConsult: {
        readonly __typename: "CaseConsult";
        readonly id: Ids.CaseConsultId;
        readonly status: CaseConsultStatus;
      };
    } | null;
  } | null;
};

export type MarkConsultMeetingCompleteMutationVariables = Exact<{
  input: MarkConsultMeetingCompleteInput;
}>;

export type MarkConsultMeetingCompleteMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareMarkConsultMeetingComplete: {
    readonly __typename: "MarkConsultMeetingCompletePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "MarkConsultMeetingCompleteResult";
      readonly consultMeeting: {
        readonly __typename: "ConsultMeeting";
        readonly id: Ids.ConsultMeetingId;
        readonly status: ConsultMeetingStatus;
      };
    } | null;
  } | null;
};

export type QuickTextListQueryVariables = Exact<{ [key: string]: never }>;

export type QuickTextListQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareQuickTexts: {
    readonly __typename: "QuickTextConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "QuickText";
      readonly id: Ids.QuickTextId;
      readonly title: string;
      readonly text: string | null;
      readonly order: number;
    }>;
  } | null;
};

export type CreateQuickTextMutationVariables = Exact<{
  input: CreateQuickTextInput;
}>;

export type CreateQuickTextMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareCreateQuickText: {
    readonly __typename: "CreateQuickTextPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateQuickTextResult";
      readonly quickText: {
        readonly __typename: "QuickText";
        readonly id: Ids.QuickTextId;
        readonly title: string;
        readonly text: string | null;
        readonly order: number;
      };
    } | null;
  } | null;
};

export type DeleteQuickTextMutationVariables = Exact<{
  input: DeleteQuickTextInput;
}>;

export type DeleteQuickTextMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareDeleteQuickText: {
    readonly __typename: "DeleteQuickTextPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "DeleteQuickTextResult";
      readonly quickText: { readonly __typename: "QuickText"; readonly id: Ids.QuickTextId };
    } | null;
  } | null;
};

export type UpdateQuickTextMutationVariables = Exact<{
  input: UpdateQuickTextInput;
}>;

export type UpdateQuickTextMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareUpdateQuickText: {
    readonly __typename: "UpdateQuickTextPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateQuickTextResult";
      readonly quickText: {
        readonly __typename: "QuickText";
        readonly id: Ids.QuickTextId;
        readonly title: string;
        readonly text: string | null;
        readonly order: number;
      };
    } | null;
  } | null;
};

export type CollaborativeCareReactivatePreviousEnrollmentMutationVariables = Exact<{
  input: ReactivatePreviousEnrollmentInput;
}>;

export type CollaborativeCareReactivatePreviousEnrollmentMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareReactivatePreviousEnrollment: {
    readonly __typename: "ReactivatePreviousEnrollmentPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "ReactivatePreviousEnrollmentResult";
      readonly enrollment: { readonly __typename: "Enrollment"; readonly id: Ids.EnrollmentId };
    } | null;
  } | null;
};

export type RemoveConsultMeetingAttendeeMutationVariables = Exact<{
  input: RemoveConsultMeetingAttendeeInput;
}>;

export type RemoveConsultMeetingAttendeeMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareRemoveConsultMeetingAttendee: {
    readonly __typename: "RemoveConsultMeetingAttendeePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "RemoveConsultMeetingAttendeeResult";
      readonly consultMeeting: {
        readonly __typename: "ConsultMeeting";
        readonly attendees: {
          readonly __typename: "ConsultMeetingAttendeeConnection";
          readonly nodes: ReadonlyArray<{
            readonly __typename: "ConsultMeetingAttendee";
            readonly id: Ids.ConsultMeetingAttendeeId;
            readonly provider: { readonly __typename: "Provider"; readonly name: string };
          }>;
        } | null;
      };
    } | null;
  } | null;
};

export type RequestCaseConsultMutationVariables = Exact<{
  input: RequestCaseConsultInput;
}>;

export type RequestCaseConsultMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareRequestCaseConsult: {
    readonly __typename: "RequestCaseConsultPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "RequestCaseConsultResult";
      readonly consultRequest: { readonly __typename: "ConsultRequest"; readonly id: Ids.ConsultRequestId };
    } | null;
  } | null;
};

export type SaveConsultReviewNotesMutationVariables = Exact<{
  input: SaveConsultReviewNoteInput;
}>;

export type SaveConsultReviewNotesMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareSaveConsultReviewNotes: {
    readonly __typename: "SaveConsultReviewNotePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "SaveConsultReviewNoteResult";
      readonly caseConsult: {
        readonly __typename: "CaseConsult";
        readonly id: Ids.CaseConsultId;
        readonly caseSummary: string | null;
        readonly consultationDocumentation: string | null;
        readonly consultationNotes: string | null;
      };
    } | null;
  } | null;
};

export type StartCaseSummaryTimeEntryMutationVariables = Exact<{
  input: StartCaseSummaryTimeEntryInput;
}>;

export type StartCaseSummaryTimeEntryMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareStartCaseSummaryTimeEntry: {
    readonly __typename: "StartCaseSummaryTimeEntryPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "StartCaseSummaryTimeEntryResult";
      readonly myActiveTimeEntryLog: {
        readonly __typename: "MyActiveTimeEntryLog";
        readonly timeEntryLog: {
          readonly __typename: "TimeEntryLog";
          readonly id: Ids.TimeEntryLogId;
        } | null;
      };
    } | null;
  } | null;
};

export type StartConsultMeetingMutationVariables = Exact<{
  input: StartConsultMeetingInput;
}>;

export type StartConsultMeetingMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareStartConsultMeeting: {
    readonly __typename: "StartConsultMeetingPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "StartConsultMeetingResult";
      readonly consultMeeting: {
        readonly __typename: "ConsultMeeting";
        readonly id: Ids.ConsultMeetingId;
        readonly startTime: Date;
        readonly status: ConsultMeetingStatus;
        readonly attendees: {
          readonly __typename: "ConsultMeetingAttendeeConnection";
          readonly nodes: ReadonlyArray<{
            readonly __typename: "ConsultMeetingAttendee";
            readonly id: Ids.ConsultMeetingAttendeeId;
            readonly role: ConsultMeetingAttendeeRole;
            readonly provider: {
              readonly __typename: "Provider";
              readonly id: Ids.ProviderId;
              readonly name: string;
            };
          }>;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type StartCaseConsultReviewMutationVariables = Exact<{
  input: StartCaseConsultReviewInput;
}>;

export type StartCaseConsultReviewMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareStartCaseConsultReview: {
    readonly __typename: "StartCaseConsultReviewPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly path: ReadonlyArray<string> | null;
      readonly message: string;
    }>;
    readonly result: {
      readonly __typename: "StartCaseConsultReviewResult";
      readonly caseConsult: {
        readonly __typename: "CaseConsult";
        readonly id: Ids.CaseConsultId;
        readonly caseSummaryTask: { readonly __typename: "Task"; readonly id: Ids.TaskId } | null;
        readonly consultMeetingTask: { readonly __typename: "Task"; readonly id: Ids.TaskId } | null;
        readonly documentationTask: { readonly __typename: "Task"; readonly id: Ids.TaskId } | null;
      };
      readonly myActiveTimeEntryLog: {
        readonly __typename: "MyActiveTimeEntryLog";
        readonly timeEntryLog: {
          readonly __typename: "TimeEntryLog";
          readonly id: Ids.TimeEntryLogId;
          readonly clientStartTime: Date;
          readonly startTime: Date;
          readonly durationSeconds: number | null;
        } | null;
      };
    } | null;
  } | null;
};

export type ActiveEnrollmentsQueryVariables = Exact<{
  sortBy: InputMaybe<EnrollmentSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ActiveEnrollmentsQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareEnrollments: {
    readonly __typename: "EnrollmentConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Enrollment";
      readonly id: Ids.EnrollmentId;
      readonly status: EnrollmentStatus;
      readonly careEpisode: {
        readonly __typename: "CareEpisode";
        readonly id: Ids.CareEpisodeId;
        readonly patient: {
          readonly __typename: "Patient";
          readonly id: Ids.PatientId;
          readonly name: string;
        };
      };
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type AddNoteToTaskMutationVariables = Exact<{
  input: AddNoteToTaskInput;
}>;

export type AddNoteToTaskMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareAddNoteToTask: {
    readonly __typename: "AddNoteToTaskPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "AddNoteToTaskResult";
      readonly note: {
        readonly __typename: "Note";
        readonly id: Ids.NoteId;
        readonly text: string;
        readonly createdAt: Date;
        readonly author: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
        readonly task: {
          readonly __typename: "Task";
          readonly id: Ids.TaskId;
          readonly notes: {
            readonly __typename: "NoteConnection";
            readonly nodes: ReadonlyArray<{ readonly __typename: "Note"; readonly id: Ids.NoteId }>;
          };
        };
      };
    } | null;
  } | null;
};

export type AllTreatmentTargetsQueryVariables = Exact<{ [key: string]: never }>;

export type AllTreatmentTargetsQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareTreatmentTargets: {
    readonly __typename: "TreatmentTargetConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "TreatmentTarget";
      readonly id: Ids.TreatmentTargetId;
      readonly name: string;
      readonly measurementPlan: {
        readonly __typename: "MeasurementPlan";
        readonly id: Ids.MeasurementPlanId;
        readonly measurementPlanScales: ReadonlyArray<{
          readonly __typename: "MeasurementPlanScale";
          readonly id: Ids.MeasurementPlanScaleId;
          readonly scale:
            | {
                readonly __typename: "AdministrableScale";
                readonly id: Ids.ScaleId;
                readonly name: string;
                readonly shortname: string | null;
              }
            | {
                readonly __typename: "Subscale";
                readonly id: Ids.ScaleId;
                readonly name: string;
                readonly shortname: string | null;
              };
        }>;
      };
    }>;
  } | null;
};

export type CaseloadEfficiencyStatsQueryVariables = Exact<{
  careManagerIds: ReadonlyArray<Scalars["ProviderId"]["input"]> | Scalars["ProviderId"]["input"];
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  startDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  endDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
}>;

export type CaseloadEfficiencyStatsQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareCaseloadEfficiencyStats: ReadonlyArray<{
    readonly __typename: "ChartData";
    readonly date: Date;
    readonly value: number;
  }> | null;
};

export type CreateTaskMutationVariables = Exact<{
  input: CreateTaskInput;
}>;

export type CreateTaskMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareCreateTask: {
    readonly __typename: "CreateTaskPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateTaskResult";
      readonly task: {
        readonly __typename: "Task";
        readonly id: Ids.TaskId;
        readonly title: string;
        readonly body: string;
        readonly dueAt: Date | null;
        readonly patient: {
          readonly __typename: "Patient";
          readonly id: Ids.PatientId;
          readonly name: string;
        } | null;
        readonly assignedTo: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
      };
    } | null;
  } | null;
};

export type CreateTaskTemplateMutationVariables = Exact<{
  input: CreateTaskTemplateInput;
}>;

export type CreateTaskTemplateMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareCreateTaskTemplate: {
    readonly __typename: "CreateTaskTemplatePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateTaskTemplateResult";
      readonly taskTemplate: {
        readonly __typename: "TaskTemplate";
        readonly id: Ids.TaskTemplateId;
        readonly title: string;
        readonly body: string | null;
      };
    } | null;
  } | null;
};

export type DeleteTaskNoteMutationVariables = Exact<{
  input: DeleteNoteInput;
}>;

export type DeleteTaskNoteMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareDeleteNote: {
    readonly __typename: "DeleteNotePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "DeleteNoteResult";
      readonly institute: { readonly __typename: "Institute"; readonly id: Ids.InstituteId };
    } | null;
  } | null;
};

export type DeleteTaskTemplateMutationVariables = Exact<{
  input: DeleteTaskTemplateInput;
}>;

export type DeleteTaskTemplateMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareDeleteTaskTemplate: {
    readonly __typename: "DeleteTaskTemplatePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: { readonly __typename: "DeleteTaskTemplateResult"; readonly success: boolean } | null;
  } | null;
};

export type DownloadEnrollmentMonthSummaryPdfQueryVariables = Exact<{
  enrollmentMonthId: Scalars["EnrollmentMonthId"]["input"];
}>;

export type DownloadEnrollmentMonthSummaryPdfQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareEnrollmentMonth: {
    readonly __typename: "EnrollmentMonth";
    readonly id: Ids.EnrollmentMonthId;
    readonly monthlySummaryPdfContentBase64: string | null;
  } | null;
};

export type EditTaskNoteMutationVariables = Exact<{
  input: EditNoteInput;
}>;

export type EditTaskNoteMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareEditNote: {
    readonly __typename: "EditNotePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EditNoteResult";
      readonly note: {
        readonly __typename: "Note";
        readonly id: Ids.NoteId;
        readonly text: string;
        readonly author: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
      };
    } | null;
  } | null;
};

export type MinutesTargetsQueryVariables = Exact<{ [key: string]: never }>;

export type MinutesTargetsQuery = {
  readonly __typename: "Query";
  readonly collaborativeCarePatientMinutesTargets: {
    readonly __typename: "PatientMinutesTargetConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "PatientMinutesTarget";
      readonly id: Ids.PatientMinutesTargetId;
      readonly floor: number;
      readonly ceiling: number;
      readonly enrollmentMonthStart: number;
      readonly enrollmentMonthEnd: number | null;
    }>;
  } | null;
  readonly collaborativeCareDefaultPatientMinutesTargets: ReadonlyArray<{
    readonly __typename: "PatientMinutesTarget";
    readonly id: Ids.PatientMinutesTargetId;
    readonly floor: number;
    readonly ceiling: number;
    readonly enrollmentMonthStart: number;
    readonly enrollmentMonthEnd: number | null;
  }> | null;
};

export type PatientBillableMinutesQueryVariables = Exact<{
  monthAndYear: MonthParams;
  patientId: Scalars["PatientId"]["input"];
}>;

export type PatientBillableMinutesQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareEnrollmentMonths: {
    readonly __typename: "EnrollmentMonthConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "EnrollmentMonth";
      readonly id: Ids.EnrollmentMonthId;
      readonly billableMinutes: number;
      readonly minutesTargetFloor: number;
      readonly minutesTargetCeiling: number;
      readonly minutesTargetStatus: EnrollmentMonthMinutesTargetStatus;
    }>;
  } | null;
};

export type PatientMonthlyBillableMinutesQueryVariables = Exact<{
  year: Scalars["Int"]["input"];
  month: Scalars["Int"]["input"];
  patientId: InputMaybe<Scalars["PatientId"]["input"]>;
}>;

export type PatientMonthlyBillableMinutesQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareEnrollmentBillingMonth: ReadonlyArray<{
    readonly __typename: "EnrollmentBillingMonth";
    readonly billableMinutes: number;
  }> | null;
};

export type PatientMonthlyBillingReportQueryVariables = Exact<{
  monthAndYear: MonthParams;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
  minimumBillableMinutes: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type PatientMonthlyBillingReportQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareEnrollmentMonths: {
    readonly __typename: "EnrollmentMonthConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "EnrollmentMonth";
      readonly id: Ids.EnrollmentMonthId;
      readonly billableMinutes: number;
      readonly beginningOfMonth: Date;
      readonly validatedMeasureCompleted: boolean;
      readonly enrollment: {
        readonly __typename: "Enrollment";
        readonly id: Ids.EnrollmentId;
        readonly enrolledAt: Date;
        readonly careEpisode: {
          readonly __typename: "CareEpisode";
          readonly id: Ids.CareEpisodeId;
          readonly patient: {
            readonly __typename: "Patient";
            readonly id: Ids.PatientId;
            readonly name: string;
            readonly firstName: string | null;
            readonly lastName: string | null;
            readonly legalFirstName: string | null;
            readonly legalLastName: string | null;
            readonly mrn: string | null;
            readonly dob: Date | null;
            readonly isTest: boolean;
          };
          readonly careEpisodeProviders: ReadonlyArray<{
            readonly __typename: "CareEpisodeProvider";
            readonly id: Ids.CareEpisodeProviderId;
            readonly relationship: CareEpisodeProviderRelationship;
            readonly provider: {
              readonly __typename: "Provider";
              readonly id: Ids.ProviderId;
              readonly name: string;
              readonly firstName: string | null;
              readonly lastName: string | null;
              readonly npiNumber: string | null;
            };
          }>;
        };
      };
    }>;
  } | null;
};

export type QuickTrackMutationVariables = Exact<{
  input: QuickTrackUnknownTaskInput;
}>;

export type QuickTrackMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareQuickTrackUnknownTask: {
    readonly __typename: "QuickTrackUnknownTaskPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "QuickTrackUnknownTaskResult";
      readonly myActiveTimeEntryLog: {
        readonly __typename: "MyActiveTimeEntryLog";
        readonly timeEntryLog: {
          readonly __typename: "TimeEntryLog";
          readonly id: Ids.TimeEntryLogId;
          readonly clientStartTime: Date;
          readonly startTime: Date;
          readonly durationSeconds: number | null;
        } | null;
      };
    } | null;
  } | null;
};

export type SetTaskStatusMutationVariables = Exact<{
  input: SetTaskStatusInput;
}>;

export type SetTaskStatusMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareSetTaskStatus: {
    readonly __typename: "SetTaskStatusPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "SetTaskStatusResult";
      readonly task: {
        readonly __typename: "Task";
        readonly id: Ids.TaskId;
        readonly status: TaskStatus;
        readonly notes: {
          readonly __typename: "NoteConnection";
          readonly nodes: ReadonlyArray<{
            readonly __typename: "Note";
            readonly id: Ids.NoteId;
            readonly text: string;
          }>;
        };
      };
    } | null;
  } | null;
};

export type CollaborativeCareTaskDetailsQueryVariables = Exact<{
  id: Scalars["TaskId"]["input"];
}>;

export type CollaborativeCareTaskDetailsQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareTask: {
    readonly __typename: "Task";
    readonly id: Ids.TaskId;
    readonly title: string;
    readonly body: string;
    readonly createdAt: Date;
    readonly dueAt: Date | null;
    readonly status: TaskStatus;
    readonly isPlaceholder: boolean;
    readonly patient: {
      readonly __typename: "Patient";
      readonly id: Ids.PatientId;
      readonly name: string;
      readonly isTest: boolean;
    } | null;
    readonly assignedTo: {
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
    };
    readonly notes: {
      readonly __typename: "NoteConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "Note";
        readonly id: Ids.NoteId;
        readonly text: string;
        readonly createdAt: Date;
        readonly author: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
        readonly timeEntry: { readonly __typename: "TimeEntryLog"; readonly startTime: Date } | null;
      }>;
    };
    readonly timeEntries: {
      readonly __typename: "TimeEntryLogConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "TimeEntryLog";
        readonly id: Ids.TimeEntryLogId;
        readonly startTime: Date;
        readonly endTime: Date | null;
        readonly durationSeconds: number | null;
        readonly createdAt: Date;
        readonly provider: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
      }>;
    };
    readonly creationLog: {
      readonly __typename: "TaskCreationLog";
      readonly id: Ids.TaskCreationLogId;
      readonly creationType: TaskCreationType;
      readonly patientSession: {
        readonly __typename: "PatientSession";
        readonly id: Ids.PatientSessionId;
        readonly targetDate: Date;
        readonly patient: {
          readonly __typename: "Patient";
          readonly id: Ids.PatientId;
          readonly name: string;
          readonly isTest: boolean;
        };
      } | null;
      readonly requestedBy: {
        readonly __typename: "Provider";
        readonly id: Ids.ProviderId;
        readonly name: string;
      } | null;
      readonly assignedBy: {
        readonly __typename: "Provider";
        readonly id: Ids.ProviderId;
        readonly name: string;
      } | null;
    } | null;
  } | null;
};

export type TaskTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type TaskTemplatesQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareTaskTemplates: {
    readonly __typename: "TaskTemplateConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "TaskTemplate";
      readonly id: Ids.TaskTemplateId;
      readonly title: string;
      readonly body: string | null;
    }>;
  } | null;
};

export type UpdateTaskMutationVariables = Exact<{
  input: UpdateTaskInput;
}>;

export type UpdateTaskMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareUpdateTask: {
    readonly __typename: "UpdateTaskPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateTaskResult";
      readonly task: {
        readonly __typename: "Task";
        readonly id: Ids.TaskId;
        readonly title: string;
        readonly body: string;
        readonly dueAt: Date | null;
        readonly patient: {
          readonly __typename: "Patient";
          readonly id: Ids.PatientId;
          readonly name: string;
        } | null;
        readonly assignedTo: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
      };
    } | null;
  } | null;
};

export type UpdateTaskTemplateMutationVariables = Exact<{
  input: UpdateTaskTemplateInput;
}>;

export type UpdateTaskTemplateMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareUpdateTaskTemplate: {
    readonly __typename: "UpdateTaskTemplatePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateTaskTemplateResult";
      readonly taskTemplate: {
        readonly __typename: "TaskTemplate";
        readonly id: Ids.TaskTemplateId;
        readonly title: string;
        readonly body: string | null;
      };
    } | null;
  } | null;
};

export type DischargeEnrollmentMutationVariables = Exact<{
  input: DischargeEnrollmentInput;
}>;

export type DischargeEnrollmentMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareDischargeEnrollment: {
    readonly __typename: "DischargeEnrollmentPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly path: ReadonlyArray<string> | null;
      readonly message: string;
    }>;
    readonly result: {
      readonly __typename: "DischargeEnrollmentResult";
      readonly enrollment: {
        readonly __typename: "Enrollment";
        readonly id: Ids.EnrollmentId;
        readonly status: EnrollmentStatus;
        readonly careEpisode: {
          readonly __typename: "CareEpisode";
          readonly id: Ids.CareEpisodeId;
          readonly patient: {
            readonly __typename: "Patient";
            readonly id: Ids.PatientId;
            readonly activeCollaborativeCareCareEpisode: {
              readonly __typename: "CareEpisode";
              readonly id: Ids.CareEpisodeId;
            } | null;
          };
        };
      };
    } | null;
  } | null;
};

export type EnrollPatientMutationVariables = Exact<{
  input: EnrollPatientInput;
}>;

export type EnrollPatientMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareEnrollPatient: {
    readonly __typename: "EnrollPatientPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EnrollPatientResult";
      readonly enrollment: {
        readonly __typename: "Enrollment";
        readonly id: Ids.EnrollmentId;
        readonly status: EnrollmentStatus;
        readonly intakeStatus: IntakeStatus;
        readonly careEpisode: {
          readonly __typename: "CareEpisode";
          readonly id: Ids.CareEpisodeId;
          readonly patient: {
            readonly __typename: "Patient";
            readonly id: Ids.PatientId;
            readonly activeCollaborativeCareCareEpisode: {
              readonly __typename: "CareEpisode";
              readonly id: Ids.CareEpisodeId;
            } | null;
          };
        };
        readonly intakeSessionScales: ReadonlyArray<{
          readonly __typename: "IntakeSessionScale";
          readonly id: Ids.IntakeSessionScaleId;
          readonly administrationChoice: IntakeSessionAdministrationChoice;
          readonly status: IntakeSessionScaleStatus;
          readonly scale: {
            readonly __typename: "AdministrableScale";
            readonly id: Ids.ScaleId;
            readonly name: string;
            readonly shortname: string | null;
          };
        }>;
      } | null;
      readonly tasks: ReadonlyArray<{ readonly __typename: "Task"; readonly id: Ids.TaskId }> | null;
      readonly myActiveTimeEntryLog: {
        readonly __typename: "MyActiveTimeEntryLog";
        readonly timeEntryLog: {
          readonly __typename: "TimeEntryLog";
          readonly id: Ids.TimeEntryLogId;
        } | null;
      };
    } | null;
  } | null;
};

export type EnrollmentQueryVariables = Exact<{
  enrollmentId: Scalars["EnrollmentId"]["input"];
  patientId: Scalars["PatientId"]["input"];
}>;

export type EnrollmentQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareEnrollment: {
    readonly __typename: "Enrollment";
    readonly id: Ids.EnrollmentId;
    readonly enrolledAt: Date;
    readonly dischargedAt: Date | null;
    readonly consentDate: Date;
    readonly dischargeReason: DischargeReason | null;
    readonly canReactivate: boolean;
    readonly careEpisode: {
      readonly __typename: "CareEpisode";
      readonly id: Ids.CareEpisodeId;
      readonly collaborativeCareCareEpisodeTreatmentTargets: ReadonlyArray<{
        readonly __typename: "CareEpisodeTreatmentTarget";
        readonly treatmentTarget: { readonly __typename: "TreatmentTarget"; readonly name: string };
      }>;
      readonly careEpisodeProviders: ReadonlyArray<{
        readonly __typename: "CareEpisodeProvider";
        readonly relationship: CareEpisodeProviderRelationship;
        readonly provider: {
          readonly __typename: "Provider";
          readonly user: { readonly __typename: "User"; readonly name: string };
        };
      }>;
    };
    readonly panel: { readonly __typename: "Panel"; readonly name: string } | null;
    readonly primaryCareProviderTeamMember: {
      readonly __typename: "PanelTeamMember";
      readonly provider: {
        readonly __typename: "Provider";
        readonly user: { readonly __typename: "User"; readonly name: string };
      };
    } | null;
    readonly careManagerTeamMember: {
      readonly __typename: "PanelTeamMember";
      readonly provider: {
        readonly __typename: "Provider";
        readonly user: { readonly __typename: "User"; readonly name: string };
      };
    } | null;
  } | null;
  readonly patient: {
    readonly __typename: "Patient";
    readonly id: Ids.PatientId;
    readonly name: string;
    readonly nameLastFirst: string;
    readonly email: string | null;
    readonly phoneNumber: string | null;
    readonly mrn: string | null;
    readonly note: string | null;
    readonly dob: Date | null;
    readonly careManager: {
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
    } | null;
    readonly cocmPrimaryCareProvider: {
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
    } | null;
    readonly activeCollaborativeCareCareEpisode: {
      readonly __typename: "CareEpisode";
      readonly id: Ids.CareEpisodeId;
      readonly collaborativeCareEnrollment: {
        readonly __typename: "Enrollment";
        readonly id: Ids.EnrollmentId;
        readonly enrolledAt: Date;
        readonly consentDate: Date;
        readonly panel: {
          readonly __typename: "Panel";
          readonly id: Ids.PanelId;
          readonly name: string;
        } | null;
        readonly primaryCareProviderTeamMember: {
          readonly __typename: "PanelTeamMember";
          readonly id: Ids.PanelTeamMemberId;
          readonly provider: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          };
        } | null;
        readonly careManagerTeamMember: {
          readonly __typename: "PanelTeamMember";
          readonly id: Ids.PanelTeamMemberId;
          readonly provider: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          };
        } | null;
      } | null;
    } | null;
    readonly collaborativeCareEnrollments: {
      readonly __typename: "EnrollmentConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "Enrollment";
        readonly id: Ids.EnrollmentId;
        readonly enrolledAt: Date;
        readonly dischargedAt: Date | null;
        readonly consentDate: Date;
        readonly dischargeReason: DischargeReason | null;
        readonly careEpisode: {
          readonly __typename: "CareEpisode";
          readonly collaborativeCareCareEpisodeTreatmentTargets: ReadonlyArray<{
            readonly __typename: "CareEpisodeTreatmentTarget";
            readonly treatmentTarget: { readonly __typename: "TreatmentTarget"; readonly name: string };
          }>;
        };
        readonly panel: {
          readonly __typename: "Panel";
          readonly id: Ids.PanelId;
          readonly name: string;
        } | null;
        readonly primaryCareProviderTeamMember: {
          readonly __typename: "PanelTeamMember";
          readonly id: Ids.PanelTeamMemberId;
          readonly provider: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string };
          };
        } | null;
        readonly careManagerTeamMember: {
          readonly __typename: "PanelTeamMember";
          readonly id: Ids.PanelTeamMemberId;
          readonly provider: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string };
          };
        } | null;
      }>;
    };
  } | null;
};

export type EnrollmentCheckIntakeScalesCompleteMutationVariables = Exact<{
  input: EnrollmentCheckIntakeScalesCompleteInput;
}>;

export type EnrollmentCheckIntakeScalesCompleteMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareEnrollmentCheckIntakeScalesComplete: {
    readonly __typename: "EnrollmentCheckIntakeScalesCompletePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EnrollmentCheckIntakeScalesCompleteResult";
      readonly enrollment: {
        readonly __typename: "Enrollment";
        readonly id: Ids.EnrollmentId;
        readonly status: EnrollmentStatus;
        readonly intakeStatus: IntakeStatus;
        readonly intakeSessionScales: ReadonlyArray<{
          readonly __typename: "IntakeSessionScale";
          readonly id: Ids.IntakeSessionScaleId;
          readonly status: IntakeSessionScaleStatus;
        }>;
        readonly intakeSession: {
          readonly __typename: "PatientSession";
          readonly id: Ids.PatientSessionId;
          readonly assessmentReport: {
            readonly __typename: "Report";
            readonly id: Ids.ReportId;
            readonly invitations: ReadonlyArray<{
              readonly __typename: "Invitation";
              readonly id: Ids.InvitationId;
              readonly status: InvitationStatus;
            }>;
          } | null;
        } | null;
      };
    } | null;
  } | null;
};

export type EnrollmentIntakeScalesQueryVariables = Exact<{
  enrollmentId: Scalars["EnrollmentId"]["input"];
}>;

export type EnrollmentIntakeScalesQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareEnrollment: {
    readonly __typename: "Enrollment";
    readonly id: Ids.EnrollmentId;
    readonly status: EnrollmentStatus;
    readonly intakeStatus: IntakeStatus;
    readonly intakeSessionScales: ReadonlyArray<{
      readonly __typename: "IntakeSessionScale";
      readonly id: Ids.IntakeSessionScaleId;
      readonly administrationChoice: IntakeSessionAdministrationChoice;
      readonly status: IntakeSessionScaleStatus;
      readonly scale: {
        readonly __typename: "AdministrableScale";
        readonly id: Ids.ScaleId;
        readonly name: string;
        readonly shortname: string | null;
        readonly allowedParticipants: ReadonlyArray<ParticipantRelationship> | null;
      };
    }>;
  } | null;
};

export type EnrollmentIntakeSessionInvitationsQueryVariables = Exact<{
  enrollmentId: Scalars["EnrollmentId"]["input"];
}>;

export type EnrollmentIntakeSessionInvitationsQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareEnrollment: {
    readonly __typename: "Enrollment";
    readonly id: Ids.EnrollmentId;
    readonly intakeStatus: IntakeStatus;
    readonly intakeSession: {
      readonly __typename: "PatientSession";
      readonly id: Ids.PatientSessionId;
      readonly patient: { readonly __typename: "Patient"; readonly id: Ids.PatientId; readonly name: string };
      readonly assessmentReport: {
        readonly __typename: "Report";
        readonly id: Ids.ReportId;
        readonly invitations: ReadonlyArray<{
          readonly __typename: "Invitation";
          readonly id: Ids.InvitationId;
          readonly format: InvitationFormat;
          readonly status: InvitationStatus;
          readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string };
          readonly reportScales: ReadonlyArray<{
            readonly __typename: "ReportScale";
            readonly id: Ids.ReportScaleId;
            readonly scale:
              | {
                  readonly __typename: "AdministrableScale";
                  readonly id: Ids.ScaleId;
                  readonly shortname: string | null;
                }
              | {
                  readonly __typename: "Subscale";
                  readonly id: Ids.ScaleId;
                  readonly shortname: string | null;
                };
          }>;
        }>;
      } | null;
    } | null;
  } | null;
};

export type EnrollmentTaskQueryVariables = Exact<{
  enrollmentId: Scalars["EnrollmentId"]["input"];
}>;

export type EnrollmentTaskQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareEnrollment: {
    readonly __typename: "Enrollment";
    readonly enrollmentTask: { readonly __typename: "Task"; readonly id: Ids.TaskId } | null;
  } | null;
};

export type ScaleSummaryQueryVariables = Exact<{
  id: Scalars["ScaleId"]["input"];
}>;

export type ScaleSummaryQuery = {
  readonly __typename: "Query";
  readonly assessmentScale:
    | {
        readonly __typename: "AdministrableScale";
        readonly id: Ids.ScaleId;
        readonly name: string;
        readonly shortname: string | null;
      }
    | {
        readonly __typename: "Subscale";
        readonly id: Ids.ScaleId;
        readonly name: string;
        readonly shortname: string | null;
      }
    | null;
};

export type SetIntakeScaleAdministrationChoiceMutationVariables = Exact<{
  input: SetIntakeScaleAdministrationChoiceInput;
}>;

export type SetIntakeScaleAdministrationChoiceMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareSetIntakeScaleAdministrationChoice: {
    readonly __typename: "SetIntakeScaleAdministrationChoicePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "SetIntakeScaleAdministrationChoiceResult";
      readonly enrollment: {
        readonly __typename: "Enrollment";
        readonly id: Ids.EnrollmentId;
        readonly intakeStatus: IntakeStatus;
        readonly intakeSessionScales: ReadonlyArray<{
          readonly __typename: "IntakeSessionScale";
          readonly id: Ids.IntakeSessionScaleId;
          readonly administrationChoice: IntakeSessionAdministrationChoice;
          readonly status: IntakeSessionScaleStatus;
          readonly scale: {
            readonly __typename: "AdministrableScale";
            readonly id: Ids.ScaleId;
            readonly name: string;
            readonly shortname: string | null;
          };
        }>;
      };
    } | null;
  } | null;
};

export type CreatePanelMutationVariables = Exact<{
  input: CreatePanelInput;
}>;

export type CreatePanelMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareCreatePanel: {
    readonly __typename: "CreatePanelPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreatePanelResult";
      readonly panel: {
        readonly __typename: "Panel";
        readonly id: Ids.PanelId;
        readonly name: string;
        readonly status: PanelStatus;
      };
    } | null;
  } | null;
};

export type CreatePanelTeamMemberMutationVariables = Exact<{
  input: CreatePanelTeamMemberInput;
}>;

export type CreatePanelTeamMemberMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareCreatePanelTeamMember: {
    readonly __typename: "CreatePanelTeamMemberPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreatePanelTeamMemberResult";
      readonly panelTeamMember: {
        readonly __typename: "PanelTeamMember";
        readonly id: Ids.PanelTeamMemberId;
        readonly function: PanelTeamMemberFunction;
        readonly startDate: Date;
        readonly endDate: Date | null;
        readonly provider: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
        readonly panel: {
          readonly __typename: "Panel";
          readonly id: Ids.PanelId;
          readonly teamMembers: ReadonlyArray<{
            readonly __typename: "PanelTeamMember";
            readonly id: Ids.PanelTeamMemberId;
          }>;
        };
      };
    } | null;
  } | null;
};

export type EditPanelMutationVariables = Exact<{
  input: EditPanelInput;
}>;

export type EditPanelMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareEditPanel: {
    readonly __typename: "EditPanelPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EditPanelResult";
      readonly panel: {
        readonly __typename: "Panel";
        readonly id: Ids.PanelId;
        readonly name: string;
        readonly status: PanelStatus;
      };
    } | null;
  } | null;
};

export type EditPanelTeamMemberMutationVariables = Exact<{
  input: EditPanelTeamMemberInput;
}>;

export type EditPanelTeamMemberMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareEditPanelTeamMember: {
    readonly __typename: "EditPanelTeamMemberPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EditPanelTeamMemberResult";
      readonly panelTeamMember: {
        readonly __typename: "PanelTeamMember";
        readonly id: Ids.PanelTeamMemberId;
        readonly endDate: Date | null;
        readonly startDate: Date;
      };
    } | null;
  } | null;
};

export type PanelsListQueryVariables = Exact<{
  status: InputMaybe<PanelStatus>;
}>;

export type PanelsListQuery = {
  readonly __typename: "Query";
  readonly collaborativeCarePanels: {
    readonly __typename: "PanelConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Panel";
      readonly id: Ids.PanelId;
      readonly name: string;
      readonly status: PanelStatus;
      readonly teamMembers: ReadonlyArray<{
        readonly __typename: "PanelTeamMember";
        readonly id: Ids.PanelTeamMemberId;
        readonly function: PanelTeamMemberFunction;
        readonly startDate: Date;
        readonly endDate: Date | null;
        readonly status: PanelTeamMemberStatus;
        readonly provider: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
      }>;
    }>;
  } | null;
  readonly providers: {
    readonly __typename: "ProviderConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
    }>;
  } | null;
};

export type ActiveEncounterDetailsQueryVariables = Exact<{
  encounterId: Scalars["EncounterId"]["input"];
}>;

export type ActiveEncounterDetailsQuery = {
  readonly __typename: "Query";
  readonly encounter: {
    readonly __typename: "Encounter";
    readonly id: Ids.EncounterId;
    readonly status: EncounterStatus;
    readonly actualPeriodStart: Date | null;
    readonly actualPeriodEnd: Date | null;
    readonly serviceType: string | null;
    readonly encounterProviders: ReadonlyArray<{
      readonly __typename: "EncounterProvider";
      readonly provider: {
        readonly __typename: "Provider";
        readonly id: Ids.ProviderId;
        readonly name: string;
      };
    }>;
    readonly encounterLocations: ReadonlyArray<{
      readonly __typename: "EncounterLocation";
      readonly location: {
        readonly __typename: "Location";
        readonly id: Ids.LocationId;
        readonly name: string;
      };
    }>;
  } | null;
};

export type ActiveEncounterFetchQueryVariables = Exact<{
  dataFetchId: Scalars["DataFetchRequestId"]["input"];
}>;

export type ActiveEncounterFetchQuery = {
  readonly __typename: "Query";
  readonly integrationsDataFetchRequest: {
    readonly __typename: "DataFetchRequest";
    readonly id: Ids.DataFetchRequestId;
    readonly status: DataFetchRequestStatus;
    readonly dataSourceRecord: {
      readonly __typename: "DataSourceRecord";
      readonly id: Ids.DataSourceRecordId;
      readonly target: {
        readonly __typename: "Entity";
        readonly id: Ids.EntityId;
        readonly entityType: EntityType;
      } | null;
    } | null;
  } | null;
};

export type ActiveEnrollmentMonthEncounterQueryVariables = Exact<{
  monthAndYear: MonthParams;
  enrollmentId: InputMaybe<Scalars["EnrollmentId"]["input"]>;
}>;

export type ActiveEnrollmentMonthEncounterQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareEnrollmentMonths: {
    readonly __typename: "EnrollmentMonthConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "EnrollmentMonth";
      readonly id: Ids.EnrollmentMonthId;
      readonly beginningOfMonth: Date;
      readonly encounter: { readonly __typename: "Encounter"; readonly id: Ids.EncounterId } | null;
    }>;
  } | null;
};

export type ActiveTreatmentTargetsQueryVariables = Exact<{
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
}>;

export type ActiveTreatmentTargetsQuery = {
  readonly __typename: "Query";
  readonly careEpisode: {
    readonly __typename: "CareEpisode";
    readonly id: Ids.CareEpisodeId;
    readonly collaborativeCareCareEpisodeTreatmentTargets: ReadonlyArray<{
      readonly __typename: "CareEpisodeTreatmentTarget";
      readonly id: Ids.CareEpisodeTreatmentTargetId;
      readonly startedDate: Date;
      readonly treatmentTarget: {
        readonly __typename: "TreatmentTarget";
        readonly id: Ids.TreatmentTargetId;
        readonly name: string;
        readonly measurementPlan: {
          readonly __typename: "MeasurementPlan";
          readonly id: Ids.MeasurementPlanId;
          readonly measurementPlanScales: ReadonlyArray<{
            readonly __typename: "MeasurementPlanScale";
            readonly id: Ids.MeasurementPlanScaleId;
            readonly scale:
              | {
                  readonly __typename: "AdministrableScale";
                  readonly id: Ids.ScaleId;
                  readonly name: string;
                  readonly shortname: string | null;
                }
              | {
                  readonly __typename: "Subscale";
                  readonly id: Ids.ScaleId;
                  readonly name: string;
                  readonly shortname: string | null;
                };
          }>;
        };
      };
    }>;
  } | null;
};

export type AddTreatmentTargetMutationVariables = Exact<{
  input: AddTreatmentTargetInput;
}>;

export type AddTreatmentTargetMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareAddTreatmentTarget: {
    readonly __typename: "AddTreatmentTargetPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "AddTreatmentTargetResult";
      readonly careEpisodeTreatmentTarget: {
        readonly __typename: "CareEpisodeTreatmentTarget";
        readonly id: Ids.CareEpisodeTreatmentTargetId;
        readonly careEpisode: {
          readonly __typename: "CareEpisode";
          readonly id: Ids.CareEpisodeId;
          readonly collaborativeCareCareEpisodeTreatmentTargets: ReadonlyArray<{
            readonly __typename: "CareEpisodeTreatmentTarget";
            readonly id: Ids.CareEpisodeTreatmentTargetId;
            readonly startedDate: Date;
            readonly treatmentTarget: {
              readonly __typename: "TreatmentTarget";
              readonly id: Ids.TreatmentTargetId;
              readonly name: string;
              readonly measurementPlan: {
                readonly __typename: "MeasurementPlan";
                readonly id: Ids.MeasurementPlanId;
                readonly measurementPlanScales: ReadonlyArray<{
                  readonly __typename: "MeasurementPlanScale";
                  readonly id: Ids.MeasurementPlanScaleId;
                  readonly scale:
                    | {
                        readonly __typename: "AdministrableScale";
                        readonly id: Ids.ScaleId;
                        readonly name: string;
                        readonly shortname: string | null;
                      }
                    | {
                        readonly __typename: "Subscale";
                        readonly id: Ids.ScaleId;
                        readonly name: string;
                        readonly shortname: string | null;
                      };
                }>;
              };
            };
          }>;
        };
      };
    } | null;
  } | null;
};

export type AssessedPatientsStatsQueryVariables = Exact<{
  careManagerIds: ReadonlyArray<Scalars["ProviderId"]["input"]> | Scalars["ProviderId"]["input"];
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  startDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  endDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
}>;

export type AssessedPatientsStatsQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareAssessedPatientsStats: ReadonlyArray<{
    readonly __typename: "ChartData";
    readonly date: Date;
    readonly value: number;
  }> | null;
};

export type AssignDocumentationEncounterMutationVariables = Exact<{
  input: AssignDocumentationEncounterInput;
}>;

export type AssignDocumentationEncounterMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareAssignDocumentationEncounter: {
    readonly __typename: "AssignDocumentationEncounterPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "AssignDocumentationEncounterResult";
      readonly enrollmentMonth: {
        readonly __typename: "EnrollmentMonth";
        readonly id: Ids.EnrollmentMonthId;
        readonly encounter: { readonly __typename: "Encounter"; readonly id: Ids.EncounterId } | null;
      };
    } | null;
  } | null;
};

export type EditPatientDemographicsMutationVariables = Exact<{
  input: EditPatientDemographicInput;
}>;

export type EditPatientDemographicsMutation = {
  readonly __typename: "Mutation";
  readonly editPatientDemographics: {
    readonly __typename: "EditPatientDemographicPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EditPatientDemographicResult";
      readonly patient: {
        readonly __typename: "Patient";
        readonly id: Ids.PatientId;
        readonly name: string;
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly legalFirstName: string | null;
        readonly legalLastName: string | null;
        readonly preferredFirstName: string | null;
        readonly preferredLastName: string | null;
        readonly preferredPronouns: string | null;
        readonly email: string | null;
        readonly phoneNumber: string | null;
        readonly mrn: string | null;
        readonly gender: Gender | null;
        readonly genderIdentity: string | null;
        readonly genderIdentityFhir: GenderIdentity | null;
        readonly dob: Date | null;
        readonly primaryLanguage: string | null;
        readonly race: string | null;
        readonly ethnicity: string | null;
        readonly isTest: boolean;
        readonly user: {
          readonly __typename: "User";
          readonly id: Ids.UserId;
          readonly notificationPreference: NotificationPreference | null;
        };
      };
    } | null;
  } | null;
};

export type EnrollmentAcuityStatsQueryVariables = Exact<{
  careManagerIds: ReadonlyArray<Scalars["ProviderId"]["input"]> | Scalars["ProviderId"]["input"];
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  startDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  endDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
}>;

export type EnrollmentAcuityStatsQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareEnrollmentAcuityStats: ReadonlyArray<{
    readonly __typename: "ChartData";
    readonly baselineAcuity: string | null;
    readonly date: Date;
    readonly value: number;
    readonly label: number | null;
  }> | null;
};

export type EnrollmentMonthsTableQueryVariables = Exact<{
  sortBy: InputMaybe<EnrollmentMonthSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  enrollmentId: InputMaybe<Scalars["EnrollmentId"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
}>;

export type EnrollmentMonthsTableQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareEnrollmentMonths: {
    readonly __typename: "EnrollmentMonthConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "EnrollmentMonth";
      readonly id: Ids.EnrollmentMonthId;
      readonly beginningOfMonth: Date;
      readonly month: number;
      readonly billableMinutes: number;
      readonly minutesTargetCeiling: number;
      readonly minutesTargetFloor: number;
      readonly minutesTargetStatus: EnrollmentMonthMinutesTargetStatus;
      readonly validatedMeasureCompleted: boolean;
      readonly billingEnrollmentMonthBillingRuleResultWinner: {
        readonly __typename: "EnrollmentMonthBillingRuleResultWinner";
        readonly id: Ids.EnrollmentMonthBillingRuleResultWinnerId;
        readonly winningResult: {
          readonly __typename: "EnrollmentMonthBillingRuleResult";
          readonly id: Ids.EnrollmentMonthBillingRuleResultId;
          readonly status: EnrollmentMonthBillingRuleResultStatus;
          readonly dateOfService: Date | null;
          readonly minutesDiffVsCurrentWinner: number | null;
          readonly minutesDiffVsNextPriority: number | null;
          readonly minutesShort: number | null;
          readonly priority: number;
          readonly ratePowerVsCurrentWinner: number | null;
          readonly ratePowerVsNextPriority: number | null;
          readonly ratePowerStrength: CocmBillingAlgorithmPowerStrength | null;
          readonly rvuDiffVsCurrentWinner: number | null;
          readonly rvuDiffVsNextPriority: number | null;
          readonly rvuPowerVsCurrentWinner: number | null;
          readonly rvuPowerVsNextPriority: number | null;
          readonly rvus: number | null;
          readonly rvuPowerStrength: CocmBillingAlgorithmPowerStrength | null;
          readonly billableMinutesDiffVsCurrentWinner: number | null;
          readonly billableMinutesDiffVsNextPriority: number | null;
          readonly billableMinutesPowerVsCurrentWinner: number | null;
          readonly billableMinutesPowerVsNextPriority: number | null;
          readonly billableMinutes: number | null;
          readonly billableMinutesPowerStrength: CocmBillingAlgorithmPowerStrength | null;
          readonly triggerDate: Date | null;
          readonly valueUnitDiffVsCurrentWinner: number | null;
          readonly valueUnitDiffVsNextPriority: number | null;
          readonly valueUnitPowerVsCurrentWinner: number | null;
          readonly valueUnitPowerVsNextPriority: number | null;
          readonly valueUnitPowerStrength: CocmBillingAlgorithmPowerStrength | null;
          readonly valueUnits: number | null;
          readonly failReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
          readonly disqualifyReasonCodes: ReadonlyArray<CocmBillingAlgorithmRuleCheck> | null;
          readonly expectedRate: {
            readonly __typename: "Money";
            readonly cents: number;
            readonly currency: string;
          };
          readonly rateDiffVsCurrentWinner: {
            readonly __typename: "Money";
            readonly cents: number;
            readonly currency: string;
          } | null;
          readonly rateDiffVsNextPriority: {
            readonly __typename: "Money";
            readonly cents: number;
            readonly currency: string;
          } | null;
          readonly rule: {
            readonly __typename: "CocmBillingAlgorithmRule";
            readonly id: Ids.CocmBillingAlgorithmRuleId;
            readonly anyAssessmentCompleted: boolean;
            readonly minimumMinutes: number | null;
            readonly billableMinutes: number | null;
            readonly mustBeInitialEncounter: boolean;
            readonly mustBeSubsequentEncounter: boolean;
            readonly ruleCodes: ReadonlyArray<{
              readonly __typename: "CocmBillingAlgorithmRuleCode";
              readonly id: Ids.CocmBillingAlgorithmRuleCodeId;
              readonly units: number;
              readonly code: {
                readonly __typename: "CocmBillingAlgorithmCode";
                readonly id: Ids.CocmBillingAlgorithmCodeId;
                readonly code: string;
              };
            }>;
          };
        } | null;
      } | null;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly startCursor: string | null;
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
    };
  } | null;
};

export type NewEnrollmentStatsQueryVariables = Exact<{
  careManagerIds: ReadonlyArray<Scalars["ProviderId"]["input"]> | Scalars["ProviderId"]["input"];
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  startDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  endDate: InputMaybe<Scalars["ISO8601Date"]["input"]>;
}>;

export type NewEnrollmentStatsQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareNewEnrollmentStats: ReadonlyArray<{
    readonly __typename: "ChartData";
    readonly date: Date;
    readonly value: number;
  }> | null;
};

export type PatientDemographicsQueryVariables = Exact<{
  id: Scalars["PatientId"]["input"];
}>;

export type PatientDemographicsQuery = {
  readonly __typename: "Query";
  readonly patient: {
    readonly __typename: "Patient";
    readonly id: Ids.PatientId;
    readonly legalFirstName: string | null;
    readonly legalLastName: string | null;
    readonly preferredFirstName: string | null;
    readonly preferredLastName: string | null;
    readonly preferredPronouns: string | null;
    readonly email: string | null;
    readonly phoneNumber: string | null;
    readonly mrn: string | null;
    readonly gender: Gender | null;
    readonly genderIdentity: string | null;
    readonly genderIdentityFhir: GenderIdentity | null;
    readonly dob: Date | null;
    readonly primaryLanguage: string | null;
    readonly race: string | null;
    readonly ethnicity: string | null;
    readonly isTest: boolean;
    readonly user: {
      readonly __typename: "User";
      readonly id: Ids.UserId;
      readonly notificationPreference: NotificationPreference | null;
    };
  } | null;
};

export type PatientDetailsFragmentFragment = {
  readonly __typename: "Patient";
  readonly id: Ids.PatientId;
  readonly name: string;
  readonly nameLastFirst: string;
  readonly email: string | null;
  readonly phoneNumber: string | null;
  readonly mrn: string | null;
  readonly note: string | null;
  readonly dob: Date | null;
  readonly careManager: {
    readonly __typename: "Provider";
    readonly id: Ids.ProviderId;
    readonly name: string;
  } | null;
  readonly cocmPrimaryCareProvider: {
    readonly __typename: "Provider";
    readonly id: Ids.ProviderId;
    readonly name: string;
  } | null;
  readonly activeCollaborativeCareCareEpisode: {
    readonly __typename: "CareEpisode";
    readonly id: Ids.CareEpisodeId;
    readonly collaborativeCareEnrollment: {
      readonly __typename: "Enrollment";
      readonly id: Ids.EnrollmentId;
      readonly enrolledAt: Date;
      readonly consentDate: Date;
      readonly panel: {
        readonly __typename: "Panel";
        readonly id: Ids.PanelId;
        readonly name: string;
      } | null;
      readonly primaryCareProviderTeamMember: {
        readonly __typename: "PanelTeamMember";
        readonly id: Ids.PanelTeamMemberId;
        readonly provider: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
      } | null;
      readonly careManagerTeamMember: {
        readonly __typename: "PanelTeamMember";
        readonly id: Ids.PanelTeamMemberId;
        readonly provider: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
      } | null;
    } | null;
  } | null;
  readonly collaborativeCareEnrollments: {
    readonly __typename: "EnrollmentConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Enrollment";
      readonly id: Ids.EnrollmentId;
      readonly enrolledAt: Date;
      readonly dischargedAt: Date | null;
      readonly consentDate: Date;
      readonly dischargeReason: DischargeReason | null;
      readonly careEpisode: {
        readonly __typename: "CareEpisode";
        readonly collaborativeCareCareEpisodeTreatmentTargets: ReadonlyArray<{
          readonly __typename: "CareEpisodeTreatmentTarget";
          readonly treatmentTarget: { readonly __typename: "TreatmentTarget"; readonly name: string };
        }>;
      };
      readonly panel: {
        readonly __typename: "Panel";
        readonly id: Ids.PanelId;
        readonly name: string;
      } | null;
      readonly primaryCareProviderTeamMember: {
        readonly __typename: "PanelTeamMember";
        readonly id: Ids.PanelTeamMemberId;
        readonly provider: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string };
        };
      } | null;
      readonly careManagerTeamMember: {
        readonly __typename: "PanelTeamMember";
        readonly id: Ids.PanelTeamMemberId;
        readonly provider: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string };
        };
      } | null;
    }>;
  };
};

export type PatientDetailsQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
}>;

export type PatientDetailsQuery = {
  readonly __typename: "Query";
  readonly patient: {
    readonly __typename: "Patient";
    readonly id: Ids.PatientId;
    readonly name: string;
    readonly nameLastFirst: string;
    readonly email: string | null;
    readonly phoneNumber: string | null;
    readonly mrn: string | null;
    readonly note: string | null;
    readonly dob: Date | null;
    readonly careManager: {
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
    } | null;
    readonly cocmPrimaryCareProvider: {
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
    } | null;
    readonly activeCollaborativeCareCareEpisode: {
      readonly __typename: "CareEpisode";
      readonly id: Ids.CareEpisodeId;
      readonly collaborativeCareEnrollment: {
        readonly __typename: "Enrollment";
        readonly id: Ids.EnrollmentId;
        readonly enrolledAt: Date;
        readonly consentDate: Date;
        readonly panel: {
          readonly __typename: "Panel";
          readonly id: Ids.PanelId;
          readonly name: string;
        } | null;
        readonly primaryCareProviderTeamMember: {
          readonly __typename: "PanelTeamMember";
          readonly id: Ids.PanelTeamMemberId;
          readonly provider: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          };
        } | null;
        readonly careManagerTeamMember: {
          readonly __typename: "PanelTeamMember";
          readonly id: Ids.PanelTeamMemberId;
          readonly provider: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          };
        } | null;
      } | null;
    } | null;
    readonly collaborativeCareEnrollments: {
      readonly __typename: "EnrollmentConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "Enrollment";
        readonly id: Ids.EnrollmentId;
        readonly enrolledAt: Date;
        readonly dischargedAt: Date | null;
        readonly consentDate: Date;
        readonly dischargeReason: DischargeReason | null;
        readonly careEpisode: {
          readonly __typename: "CareEpisode";
          readonly collaborativeCareCareEpisodeTreatmentTargets: ReadonlyArray<{
            readonly __typename: "CareEpisodeTreatmentTarget";
            readonly treatmentTarget: { readonly __typename: "TreatmentTarget"; readonly name: string };
          }>;
        };
        readonly panel: {
          readonly __typename: "Panel";
          readonly id: Ids.PanelId;
          readonly name: string;
        } | null;
        readonly primaryCareProviderTeamMember: {
          readonly __typename: "PanelTeamMember";
          readonly id: Ids.PanelTeamMemberId;
          readonly provider: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string };
          };
        } | null;
        readonly careManagerTeamMember: {
          readonly __typename: "PanelTeamMember";
          readonly id: Ids.PanelTeamMemberId;
          readonly provider: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string };
          };
        } | null;
      }>;
    };
  } | null;
};

export type CollaborativeCareUpdatePatientNoteMutationVariables = Exact<{
  input: UpdatePatientNoteInput;
}>;

export type CollaborativeCareUpdatePatientNoteMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareUpdatePatientNote: {
    readonly __typename: "UpdatePatientNotePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdatePatientNoteResult";
      readonly patient: {
        readonly __typename: "Patient";
        readonly id: Ids.PatientId;
        readonly note: string | null;
      };
    } | null;
  } | null;
};

export type RemoveTreatmentTargetMutationVariables = Exact<{
  input: RemoveTreatmentTargetInput;
}>;

export type RemoveTreatmentTargetMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareRemoveTreatmentTarget: {
    readonly __typename: "RemoveTreatmentTargetPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "RemoveTreatmentTargetResult";
      readonly careEpisodeTreatmentTarget: {
        readonly __typename: "CareEpisodeTreatmentTarget";
        readonly id: Ids.CareEpisodeTreatmentTargetId;
        readonly careEpisode: {
          readonly __typename: "CareEpisode";
          readonly id: Ids.CareEpisodeId;
          readonly collaborativeCareCareEpisodeTreatmentTargets: ReadonlyArray<{
            readonly __typename: "CareEpisodeTreatmentTarget";
            readonly id: Ids.CareEpisodeTreatmentTargetId;
          }>;
        };
        readonly treatmentTarget: {
          readonly __typename: "TreatmentTarget";
          readonly id: Ids.TreatmentTargetId;
        };
      };
    } | null;
  } | null;
};

export type SendImmediateSurveyMutationVariables = Exact<{
  input: SendImmediateSurveyInput;
}>;

export type SendImmediateSurveyMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareSendImmediateSurvey: {
    readonly __typename: "SendImmediateSurveyPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "SendImmediateSurveyResult";
      readonly patientSession: {
        readonly __typename: "PatientSession";
        readonly id: Ids.PatientSessionId;
        readonly assessmentReport: {
          readonly __typename: "Report";
          readonly invitations: ReadonlyArray<{
            readonly __typename: "Invitation";
            readonly id: Ids.InvitationId;
            readonly reportScaleParticipants: ReadonlyArray<{
              readonly __typename: "ReportScaleParticipant";
              readonly user: { readonly __typename: "User"; readonly name: string };
            }>;
            readonly reportScales: ReadonlyArray<{
              readonly __typename: "ReportScale";
              readonly scale:
                | {
                    readonly __typename: "AdministrableScale";
                    readonly id: Ids.ScaleId;
                    readonly shortname: string | null;
                  }
                | {
                    readonly __typename: "Subscale";
                    readonly id: Ids.ScaleId;
                    readonly shortname: string | null;
                  };
            }>;
          }>;
        } | null;
      };
    } | null;
  } | null;
};

export type SurveyManagementIndexQueryVariables = Exact<{
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
}>;

export type SurveyManagementIndexQuery = {
  readonly __typename: "Query";
  readonly assessmentCareEpisodeScaleSummary: {
    readonly __typename: "CareEpisodeScaleSummary";
    readonly id: Ids.CareEpisodeId;
    readonly invitations: ReadonlyArray<{
      readonly __typename: "Invitation";
      readonly id: Ids.InvitationId;
      readonly status: InvitationStatus;
      readonly phase: InvitationPhase;
      readonly closesAt: Date | null;
      readonly opensAt: Date | null;
      readonly format: InvitationFormat;
      readonly dateBegun: Date | null;
      readonly dateCompleted: Date | null;
      readonly createdAt: Date;
      readonly statusUpdatedAt: Date | null;
      readonly downloadAvailable: boolean;
      readonly manuallyCancelable: boolean;
      readonly manuallyAdministerable: boolean;
      readonly canceledAt: Date | null;
      readonly managedBy: ManagedBy;
      readonly canResendNotifications: boolean;
      readonly canceledByUser: {
        readonly __typename: "User";
        readonly id: Ids.UserId;
        readonly name: string;
        readonly firstName: string | null;
      } | null;
      readonly invitationNotifications: ReadonlyArray<{
        readonly __typename: "InvitationNotification";
        readonly id: Ids.InvitationNotificationId;
        readonly status: InvitationNotificationStatus;
        readonly sendAt: Date | null;
        readonly dateEmailed: Date | null;
        readonly dateTexted: Date | null;
      }>;
      readonly reportScales: ReadonlyArray<{
        readonly __typename: "ReportScale";
        readonly scale:
          | {
              readonly __typename: "AdministrableScale";
              readonly id: Ids.ScaleId;
              readonly shortname: string | null;
              readonly scaleGroup: {
                readonly __typename: "ScaleGroup";
                readonly id: Ids.ScaleGroupId;
              } | null;
            }
          | {
              readonly __typename: "Subscale";
              readonly id: Ids.ScaleId;
              readonly shortname: string | null;
              readonly scaleGroup: {
                readonly __typename: "ScaleGroup";
                readonly id: Ids.ScaleGroupId;
              } | null;
            };
        readonly report: {
          readonly __typename: "Report";
          readonly id: Ids.ReportId;
          readonly computedValues: ReadonlyArray<{
            readonly __typename: "ComputedValue";
            readonly id: Ids.ComputedValueId;
          }>;
          readonly patientSession: {
            readonly __typename: "PatientSession";
            readonly id: Ids.PatientSessionId;
          } | null;
        };
      }>;
      readonly patient: { readonly __typename: "Patient"; readonly id: Ids.PatientId };
      readonly user: {
        readonly __typename: "User";
        readonly id: Ids.UserId;
        readonly name: string;
        readonly firstName: string | null;
      };
    }>;
  } | null;
};

export type TasksCardQueryVariables = Exact<{
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]> | Scalars["PatientId"]["input"]>;
  sortBy: InputMaybe<TaskSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  status: InputMaybe<TaskStatus>;
  year: InputMaybe<Scalars["Int"]["input"]>;
  month: InputMaybe<Scalars["Int"]["input"]>;
  enrollmentId: InputMaybe<Scalars["EnrollmentId"]["input"]>;
}>;

export type TasksCardQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareTasks: {
    readonly __typename: "TaskConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Task";
      readonly id: Ids.TaskId;
      readonly createdAt: Date;
      readonly title: string;
      readonly body: string;
      readonly dueAt: Date | null;
      readonly status: TaskStatus;
      readonly isPlaceholder: boolean;
      readonly totalMinutesForMonth: number;
      readonly patient: {
        readonly __typename: "Patient";
        readonly id: Ids.PatientId;
        readonly name: string;
        readonly isTest: boolean;
      } | null;
      readonly assignedTo: {
        readonly __typename: "Provider";
        readonly id: Ids.ProviderId;
        readonly name: string;
      };
      readonly notes: {
        readonly __typename: "NoteConnection";
        readonly nodes: ReadonlyArray<{
          readonly __typename: "Note";
          readonly id: Ids.NoteId;
          readonly text: string;
          readonly createdAt: Date;
          readonly author: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          };
          readonly timeEntry: { readonly __typename: "TimeEntryLog"; readonly startTime: Date } | null;
        }>;
      };
      readonly timeEntries: {
        readonly __typename: "TimeEntryLogConnection";
        readonly nodes: ReadonlyArray<{
          readonly __typename: "TimeEntryLog";
          readonly id: Ids.TimeEntryLogId;
          readonly startTime: Date;
          readonly endTime: Date | null;
          readonly durationSeconds: number | null;
          readonly createdAt: Date;
          readonly provider: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          };
        }>;
      };
      readonly creationLog: {
        readonly __typename: "TaskCreationLog";
        readonly id: Ids.TaskCreationLogId;
        readonly creationType: TaskCreationType;
        readonly patientSession: {
          readonly __typename: "PatientSession";
          readonly id: Ids.PatientSessionId;
          readonly targetDate: Date;
          readonly patient: {
            readonly __typename: "Patient";
            readonly id: Ids.PatientId;
            readonly name: string;
            readonly isTest: boolean;
          };
        } | null;
        readonly requestedBy: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        } | null;
        readonly assignedBy: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        } | null;
      } | null;
    }>;
  } | null;
};

export type TimelineCardQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
  startTime: Scalars["ISO8601DateTime"]["input"];
}>;

export type TimelineCardQuery = {
  readonly __typename: "Query";
  readonly patient: {
    readonly __typename: "Patient";
    readonly id: Ids.PatientId;
    readonly collaborativeCareTimeEntryLogs: {
      readonly __typename: "TimeEntryLogConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "TimeEntryLog";
        readonly id: Ids.TimeEntryLogId;
        readonly startTime: Date;
        readonly clientStartTime: Date;
        readonly endTime: Date | null;
        readonly durationSeconds: number | null;
        readonly blockedMinutes: number | null;
        readonly workFor: {
          readonly __typename: "Task";
          readonly id: Ids.TaskId;
          readonly title: string;
          readonly body: string;
          readonly dueAt: Date | null;
          readonly status: TaskStatus;
          readonly isPlaceholder: boolean;
          readonly patient: {
            readonly __typename: "Patient";
            readonly id: Ids.PatientId;
            readonly name: string;
            readonly isTest: boolean;
          } | null;
          readonly assignedTo: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          };
          readonly notes: {
            readonly __typename: "NoteConnection";
            readonly nodes: ReadonlyArray<{
              readonly __typename: "Note";
              readonly id: Ids.NoteId;
              readonly text: string;
              readonly createdAt: Date;
              readonly author: {
                readonly __typename: "Provider";
                readonly id: Ids.ProviderId;
                readonly name: string;
              };
              readonly timeEntry: { readonly __typename: "TimeEntryLog"; readonly startTime: Date } | null;
            }>;
          };
          readonly timeEntries: {
            readonly __typename: "TimeEntryLogConnection";
            readonly nodes: ReadonlyArray<{
              readonly __typename: "TimeEntryLog";
              readonly id: Ids.TimeEntryLogId;
              readonly startTime: Date;
              readonly endTime: Date | null;
              readonly durationSeconds: number | null;
              readonly createdAt: Date;
              readonly provider: {
                readonly __typename: "Provider";
                readonly id: Ids.ProviderId;
                readonly name: string;
              };
            }>;
          };
          readonly creationLog: {
            readonly __typename: "TaskCreationLog";
            readonly id: Ids.TaskCreationLogId;
            readonly creationType: TaskCreationType;
            readonly patientSession: {
              readonly __typename: "PatientSession";
              readonly id: Ids.PatientSessionId;
              readonly targetDate: Date;
              readonly patient: {
                readonly __typename: "Patient";
                readonly id: Ids.PatientId;
                readonly name: string;
                readonly isTest: boolean;
              };
            } | null;
            readonly requestedBy: {
              readonly __typename: "Provider";
              readonly id: Ids.ProviderId;
              readonly name: string;
            } | null;
            readonly assignedBy: {
              readonly __typename: "Provider";
              readonly id: Ids.ProviderId;
              readonly name: string;
            } | null;
          } | null;
        };
      }>;
    };
  } | null;
};

export type UpdateEnrollmentMutationVariables = Exact<{
  input: UpdateEnrollmentInput;
}>;

export type UpdateEnrollmentMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareUpdateEnrollment: {
    readonly __typename: "UpdateEnrollmentPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateEnrollmentResult";
      readonly enrollment: {
        readonly __typename: "Enrollment";
        readonly id: Ids.EnrollmentId;
        readonly enrolledAt: Date;
        readonly consentDate: Date;
        readonly panel: { readonly __typename: "Panel"; readonly id: Ids.PanelId } | null;
        readonly primaryCareProviderTeamMember: {
          readonly __typename: "PanelTeamMember";
          readonly id: Ids.PanelTeamMemberId;
        } | null;
        readonly careManagerTeamMember: {
          readonly __typename: "PanelTeamMember";
          readonly id: Ids.PanelTeamMemberId;
        } | null;
      };
    } | null;
  } | null;
};

export type CareEpisodeUpcomingSessionsQueryVariables = Exact<{
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
  start: Scalars["ISO8601DateTime"]["input"];
}>;

export type CareEpisodeUpcomingSessionsQuery = {
  readonly __typename: "Query";
  readonly careEpisode: {
    readonly __typename: "CareEpisode";
    readonly patientSessions: {
      readonly __typename: "PatientSessionConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "PatientSession";
        readonly id: Ids.PatientSessionId;
        readonly status: PatientSessionStatus;
        readonly targetDate: Date;
      }>;
    };
  } | null;
};

export type DownloadInvitationPdfQueryVariables = Exact<{
  invitationId: Scalars["InvitationId"]["input"];
}>;

export type DownloadInvitationPdfQuery = {
  readonly __typename: "Query";
  readonly assessmentInvitation: {
    readonly __typename: "Invitation";
    readonly id: Ids.InvitationId;
    readonly pdfContentBase64: string | null;
  } | null;
};

export type ManuallyCancelInvitationMutationVariables = Exact<{
  input: ManuallyCancelInvitationInput;
}>;

export type ManuallyCancelInvitationMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareManuallyCancelInvitation: {
    readonly __typename: "ManuallyCancelInvitationPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "ManuallyCancelInvitationResult";
      readonly invitation: {
        readonly __typename: "Invitation";
        readonly id: Ids.InvitationId;
        readonly status: InvitationStatus;
      };
    } | null;
  } | null;
};

export type PatientActiveCollaborativeCareEpisodeQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
}>;

export type PatientActiveCollaborativeCareEpisodeQuery = {
  readonly __typename: "Query";
  readonly patient: {
    readonly __typename: "Patient";
    readonly activeCollaborativeCareCareEpisode: {
      readonly __typename: "CareEpisode";
      readonly id: Ids.CareEpisodeId;
      readonly collaborativeCareEnrollment: {
        readonly __typename: "Enrollment";
        readonly id: Ids.EnrollmentId;
        readonly status: EnrollmentStatus;
        readonly intakeStatus: IntakeStatus;
        readonly enrolledAt: Date;
        readonly consentDate: Date;
      } | null;
    } | null;
  } | null;
};

export type PatientRelatedPeopleQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
}>;

export type PatientRelatedPeopleQuery = {
  readonly __typename: "Query";
  readonly patient: {
    readonly __typename: "Patient";
    readonly id: Ids.PatientId;
    readonly relatedPeople: ReadonlyArray<{
      readonly __typename: "RelatedPerson";
      readonly id: Ids.RelatedPersonId;
      readonly email: string | null;
      readonly active: boolean;
      readonly firstName: string | null;
      readonly lastName: string | null;
      readonly name: string;
      readonly phoneNumber: string | null;
      readonly notificationPreference: NotificationPreference | null;
      readonly notificationPreferenceWithDefault: NotificationPreference;
      readonly relatedPersonRelationship: RelatedPersonRelationship;
    }>;
  } | null;
};

export type CreateRelatedPersonMutationVariables = Exact<{
  input: CreateRelatedPersonInput;
}>;

export type CreateRelatedPersonMutation = {
  readonly __typename: "Mutation";
  readonly createRelatedPerson: {
    readonly __typename: "CreateRelatedPersonPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateRelatedPersonResult";
      readonly relatedPerson: { readonly __typename: "RelatedPerson"; readonly id: Ids.RelatedPersonId };
    } | null;
  } | null;
};

export type UpdateRelatedPersonMutationVariables = Exact<{
  input: UpdateRelatedPersonInput;
}>;

export type UpdateRelatedPersonMutation = {
  readonly __typename: "Mutation";
  readonly updateRelatedPerson: {
    readonly __typename: "UpdateRelatedPersonPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateRelatedPersonResult";
      readonly relatedPerson: { readonly __typename: "RelatedPerson"; readonly id: Ids.RelatedPersonId };
    } | null;
  } | null;
};

export type ResendInvitationNotificationsMutationVariables = Exact<{
  input: ResendAllInvitationNotificationsInput;
}>;

export type ResendInvitationNotificationsMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareResendAllInvitationNotifications: {
    readonly __typename: "ResendAllInvitationNotificationsPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "ResendAllInvitationNotificationsResult";
      readonly patientSession: { readonly __typename: "PatientSession"; readonly id: Ids.PatientSessionId };
    } | null;
  } | null;
};

export type PatientSchedulingAppointmentsQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
}>;

export type PatientSchedulingAppointmentsQuery = {
  readonly __typename: "Query";
  readonly patient: {
    readonly __typename: "Patient";
    readonly id: Ids.PatientId;
    readonly schedulingAppointments: {
      readonly __typename: "AppointmentConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "Appointment";
        readonly id: Ids.AppointmentId;
        readonly startDate: Date | null;
        readonly endDate: Date | null;
        readonly provider: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        } | null;
      }>;
    };
  } | null;
};

export type SendInvitationNotificationsMutationVariables = Exact<{
  input: SendInvitationNotificationsInput;
}>;

export type SendInvitationNotificationsMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareSendInvitationNotifications: {
    readonly __typename: "SendInvitationNotificationsPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "SendInvitationNotificationsResult";
      readonly patientSession: { readonly __typename: "PatientSession"; readonly id: Ids.PatientSessionId };
    } | null;
  } | null;
};

export type CollaborativeCareCareManagersQueryVariables = Exact<{ [key: string]: never }>;

export type CollaborativeCareCareManagersQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareCareManagers: ReadonlyArray<{
    readonly __typename: "Provider";
    readonly id: Ids.ProviderId;
    readonly name: string;
  }> | null;
};

export type CollaborativeCarePatientCountQueryVariables = Exact<{
  sortBy: InputMaybe<PatientSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  cocmPrimaryCareProvider: InputMaybe<Scalars["ProviderId"]["input"]>;
  cocmCareManager: InputMaybe<Scalars["ProviderId"]["input"]>;
  enrollmentStatus: InputMaybe<EnrollmentStatus>;
  testPatient: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type CollaborativeCarePatientCountQuery = {
  readonly __typename: "Query";
  readonly patients: { readonly __typename: "PatientConnection"; readonly totalCount: number } | null;
};

export type CollaborativeCarePatientSearchQueryVariables = Exact<{
  sortBy: InputMaybe<PatientSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  forPanel: InputMaybe<PanelFilter>;
  cocmPrimaryCareProvider: InputMaybe<Scalars["ProviderId"]["input"]>;
  cocmCareManager: InputMaybe<Scalars["ProviderId"]["input"]>;
  enrollmentStatus: InputMaybe<EnrollmentStatus>;
  testPatient?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type CollaborativeCarePatientSearchQuery = {
  readonly __typename: "Query";
  readonly patients: {
    readonly __typename: "PatientConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Patient";
      readonly id: Ids.PatientId;
      readonly name: string;
      readonly nameLastFirst: string;
      readonly dob: Date | null;
      readonly gender: Gender | null;
      readonly genderIdentity: string | null;
      readonly mrn: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
      readonly isTest: boolean;
      readonly activeCollaborativeCareCareEpisode: {
        readonly __typename: "CareEpisode";
        readonly id: Ids.CareEpisodeId;
        readonly collaborativeCareEnrollment: {
          readonly __typename: "Enrollment";
          readonly id: Ids.EnrollmentId;
          readonly status: EnrollmentStatus;
          readonly enrolledAt: Date;
        } | null;
        readonly careEpisodeProviders: ReadonlyArray<{
          readonly __typename: "CareEpisodeProvider";
          readonly id: Ids.CareEpisodeProviderId;
          readonly relationship: CareEpisodeProviderRelationship;
          readonly provider: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly firstName: string | null;
            readonly lastName: string | null;
            readonly orderedName: string;
          };
        }>;
      } | null;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
  readonly nonPanelPatients: { readonly __typename: "PatientConnection"; readonly totalCount: number } | null;
};

export type CollaborativeCarePrimaryCareProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type CollaborativeCarePrimaryCareProvidersQuery = {
  readonly __typename: "Query";
  readonly collaborativeCarePrimaryCareProviders: ReadonlyArray<{
    readonly __typename: "Provider";
    readonly id: Ids.ProviderId;
    readonly name: string;
  }> | null;
};

export type CreatePatientMutationVariables = Exact<{
  input: CreatePatientInput;
}>;

export type CreatePatientMutation = {
  readonly __typename: "Mutation";
  readonly createPatient: {
    readonly __typename: "CreatePatientPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreatePatientResult";
      readonly patient: {
        readonly __typename: "Patient";
        readonly id: Ids.PatientId;
        readonly gender: Gender | null;
        readonly genderIdentity: string | null;
        readonly genderIdentityFhir: GenderIdentity | null;
        readonly preferredPronouns: string | null;
        readonly dob: Date | null;
        readonly name: string;
        readonly activeCollaborativeCareCareEpisode: {
          readonly __typename: "CareEpisode";
          readonly id: Ids.CareEpisodeId;
        } | null;
      };
    } | null;
  } | null;
};

export type PatientReferenceBaseQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
}>;

export type PatientReferenceBaseQuery = {
  readonly __typename: "Query";
  readonly patient: {
    readonly __typename: "Patient";
    readonly id: Ids.PatientId;
    readonly gender: Gender | null;
    readonly genderIdentity: string | null;
    readonly genderIdentityFhir: GenderIdentity | null;
    readonly preferredPronouns: string | null;
    readonly dob: Date | null;
    readonly name: string;
    readonly isTest: boolean;
    readonly activeCollaborativeCareCareEpisode: {
      readonly __typename: "CareEpisode";
      readonly id: Ids.CareEpisodeId;
      readonly collaborativeCareEnrollment: {
        readonly __typename: "Enrollment";
        readonly id: Ids.EnrollmentId;
        readonly enrolledAt: Date;
      } | null;
    } | null;
    readonly collaborativeCareTasks: { readonly __typename: "TaskConnection"; readonly totalCount: number };
  } | null;
};

export type EnrolledStatusIndicatorQueryVariables = Exact<{
  enrollmentId: Scalars["EnrollmentId"]["input"];
}>;

export type EnrolledStatusIndicatorQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareSeverityBadge: {
    readonly __typename: "SeverityBadge";
    readonly alert: {
      readonly __typename: "CareEpisodeClinicalAlertHistory";
      readonly id: Ids.CareEpisodeScaleId;
      readonly alertType: ClinicalAlertType;
      readonly scale: {
        readonly __typename: "AdministrableScale";
        readonly id: Ids.ScaleId;
        readonly shortname: string | null;
      } | null;
      readonly latest: {
        readonly __typename: "CareEpisodeClinicalAlert";
        readonly id: string;
        readonly status: ClinicalAlertValue;
      } | null;
    } | null;
    readonly target: {
      readonly __typename: "CareEpisodeComputedValue";
      readonly id: Ids.CareEpisodeComputedValueId;
      readonly thresholdClass: ScaleThresholdClass | null;
      readonly thresholdMnemonic: string | null;
      readonly scale:
        | {
            readonly __typename: "AdministrableScale";
            readonly id: Ids.ScaleId;
            readonly shortname: string | null;
          }
        | { readonly __typename: "Subscale"; readonly id: Ids.ScaleId; readonly shortname: string | null };
      readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string } | null;
      readonly scorer:
        | {
            readonly __typename: "CategoricalScaleScorer";
            readonly id: Ids.ScaleScorerId;
            readonly scoreType: ScaleScorerType;
          }
        | {
            readonly __typename: "NumericalScaleScorer";
            readonly id: Ids.ScaleScorerId;
            readonly scoreType: ScaleScorerType;
            readonly thresholdData: ReadonlyArray<{
              readonly __typename: "ScaleThreshold";
              readonly thresholdClass: ScaleThresholdClass;
            }> | null;
          }
        | {
            readonly __typename: "UnscoredScaleScorer";
            readonly id: Ids.ScaleScorerId;
            readonly scoreType: ScaleScorerType;
          };
    } | null;
    readonly generalFunctioning: {
      readonly __typename: "CareEpisodeComputedValue";
      readonly id: Ids.CareEpisodeComputedValueId;
      readonly thresholdClass: ScaleThresholdClass | null;
      readonly thresholdMnemonic: string | null;
      readonly scale:
        | {
            readonly __typename: "AdministrableScale";
            readonly id: Ids.ScaleId;
            readonly shortname: string | null;
          }
        | { readonly __typename: "Subscale"; readonly id: Ids.ScaleId; readonly shortname: string | null };
      readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string } | null;
      readonly scorer:
        | {
            readonly __typename: "CategoricalScaleScorer";
            readonly id: Ids.ScaleScorerId;
            readonly scoreType: ScaleScorerType;
          }
        | {
            readonly __typename: "NumericalScaleScorer";
            readonly id: Ids.ScaleScorerId;
            readonly scoreType: ScaleScorerType;
            readonly thresholdData: ReadonlyArray<{
              readonly __typename: "ScaleThreshold";
              readonly thresholdClass: ScaleThresholdClass;
            }> | null;
          }
        | {
            readonly __typename: "UnscoredScaleScorer";
            readonly id: Ids.ScaleScorerId;
            readonly scoreType: ScaleScorerType;
          };
    } | null;
  } | null;
};

export type TaskSearchQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<TaskSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  forPanel?: InputMaybe<PanelFilter>;
  search: InputMaybe<Scalars["String"]["input"]>;
  status: InputMaybe<TaskStatus>;
  assignedTo: InputMaybe<ProviderFilter>;
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]> | Scalars["PatientId"]["input"]>;
  testPatient?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type TaskSearchQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareTasks: {
    readonly __typename: "TaskConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Task";
      readonly id: Ids.TaskId;
      readonly title: string;
      readonly body: string;
      readonly dueAt: Date | null;
      readonly status: TaskStatus;
      readonly isPlaceholder: boolean;
      readonly patient: {
        readonly __typename: "Patient";
        readonly id: Ids.PatientId;
        readonly name: string;
        readonly isTest: boolean;
        readonly nameLastFirst: string;
      } | null;
      readonly assignedTo: {
        readonly __typename: "Provider";
        readonly id: Ids.ProviderId;
        readonly name: string;
        readonly orderedName: string;
      };
      readonly notes: {
        readonly __typename: "NoteConnection";
        readonly nodes: ReadonlyArray<{
          readonly __typename: "Note";
          readonly id: Ids.NoteId;
          readonly text: string;
          readonly createdAt: Date;
          readonly author: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          };
          readonly timeEntry: { readonly __typename: "TimeEntryLog"; readonly startTime: Date } | null;
        }>;
      };
      readonly timeEntries: {
        readonly __typename: "TimeEntryLogConnection";
        readonly nodes: ReadonlyArray<{
          readonly __typename: "TimeEntryLog";
          readonly id: Ids.TimeEntryLogId;
          readonly startTime: Date;
          readonly endTime: Date | null;
          readonly durationSeconds: number | null;
          readonly createdAt: Date;
          readonly provider: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          };
        }>;
      };
      readonly creationLog: {
        readonly __typename: "TaskCreationLog";
        readonly id: Ids.TaskCreationLogId;
        readonly creationType: TaskCreationType;
        readonly patientSession: {
          readonly __typename: "PatientSession";
          readonly id: Ids.PatientSessionId;
          readonly targetDate: Date;
          readonly patient: {
            readonly __typename: "Patient";
            readonly id: Ids.PatientId;
            readonly name: string;
            readonly isTest: boolean;
          };
        } | null;
        readonly requestedBy: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
          readonly orderedName: string;
        } | null;
        readonly assignedBy: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
          readonly orderedName: string;
        } | null;
      } | null;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly startCursor: string | null;
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
    };
  } | null;
  readonly nonPanelTasks: { readonly __typename: "TaskConnection"; readonly totalCount: number } | null;
};

export type TaskCardBackTimelineQueryVariables = Exact<{
  taskId: Scalars["TaskId"]["input"];
}>;

export type TaskCardBackTimelineQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareTimeEntryLogs: {
    readonly __typename: "TimeEntryLogConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "TimeEntryLog";
      readonly id: Ids.TimeEntryLogId;
      readonly startTime: Date;
      readonly clientStartTime: Date;
      readonly endTime: Date | null;
      readonly durationSeconds: number | null;
      readonly blockedMinutes: number | null;
      readonly workFor: {
        readonly __typename: "Task";
        readonly id: Ids.TaskId;
        readonly title: string;
        readonly body: string;
        readonly dueAt: Date | null;
        readonly status: TaskStatus;
        readonly isPlaceholder: boolean;
        readonly patient: {
          readonly __typename: "Patient";
          readonly id: Ids.PatientId;
          readonly name: string;
          readonly isTest: boolean;
        } | null;
        readonly assignedTo: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
        readonly notes: {
          readonly __typename: "NoteConnection";
          readonly nodes: ReadonlyArray<{
            readonly __typename: "Note";
            readonly id: Ids.NoteId;
            readonly text: string;
            readonly createdAt: Date;
            readonly author: {
              readonly __typename: "Provider";
              readonly id: Ids.ProviderId;
              readonly name: string;
            };
            readonly timeEntry: { readonly __typename: "TimeEntryLog"; readonly startTime: Date } | null;
          }>;
        };
        readonly timeEntries: {
          readonly __typename: "TimeEntryLogConnection";
          readonly nodes: ReadonlyArray<{
            readonly __typename: "TimeEntryLog";
            readonly id: Ids.TimeEntryLogId;
            readonly startTime: Date;
            readonly endTime: Date | null;
            readonly durationSeconds: number | null;
            readonly createdAt: Date;
            readonly provider: {
              readonly __typename: "Provider";
              readonly id: Ids.ProviderId;
              readonly name: string;
            };
          }>;
        };
        readonly creationLog: {
          readonly __typename: "TaskCreationLog";
          readonly id: Ids.TaskCreationLogId;
          readonly creationType: TaskCreationType;
          readonly patientSession: {
            readonly __typename: "PatientSession";
            readonly id: Ids.PatientSessionId;
            readonly targetDate: Date;
            readonly patient: {
              readonly __typename: "Patient";
              readonly id: Ids.PatientId;
              readonly name: string;
              readonly isTest: boolean;
            };
          } | null;
          readonly requestedBy: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          } | null;
          readonly assignedBy: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          } | null;
        } | null;
      };
    }>;
  } | null;
};

export type BeginTimeEntryLogMutationVariables = Exact<{
  input: StartTimeEntryLogNowInput;
}>;

export type BeginTimeEntryLogMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareStartTimeEntryLogNow: {
    readonly __typename: "StartTimeEntryLogNowPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "StartTimeEntryLogNowResult";
      readonly myActiveTimeEntryLog: {
        readonly __typename: "MyActiveTimeEntryLog";
        readonly timeEntryLog: {
          readonly __typename: "TimeEntryLog";
          readonly id: Ids.TimeEntryLogId;
          readonly startTime: Date;
          readonly clientStartTime: Date;
        } | null;
      };
    } | null;
  } | null;
};

export type CreateTimeEntryLogMutationVariables = Exact<{
  input: CreateTimeEntryLogInput;
}>;

export type CreateTimeEntryLogMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareCreateTimeEntryLog: {
    readonly __typename: "CreateTimeEntryLogPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateTimeEntryLogResult";
      readonly timeEntryLog: {
        readonly __typename: "TimeEntryLog";
        readonly id: Ids.TimeEntryLogId;
        readonly startTime: Date;
        readonly endTime: Date | null;
        readonly durationSeconds: number | null;
        readonly provider: { readonly __typename: "Provider"; readonly id: Ids.ProviderId };
        readonly workFor: {
          readonly __typename: "Task";
          readonly id: Ids.TaskId;
          readonly status: TaskStatus;
          readonly timeEntries: {
            readonly __typename: "TimeEntryLogConnection";
            readonly nodes: ReadonlyArray<{
              readonly __typename: "TimeEntryLog";
              readonly id: Ids.TimeEntryLogId;
            }>;
          };
          readonly notes: {
            readonly __typename: "NoteConnection";
            readonly nodes: ReadonlyArray<{ readonly __typename: "Note"; readonly id: Ids.NoteId }>;
          };
        };
      };
      readonly myActiveTimeEntryLog: {
        readonly __typename: "MyActiveTimeEntryLog";
        readonly timeEntryLog: {
          readonly __typename: "TimeEntryLog";
          readonly id: Ids.TimeEntryLogId;
        } | null;
      };
    } | null;
  } | null;
};

export type DiscardTimerMutationVariables = Exact<{
  input: DeleteTimeEntryLogInput;
}>;

export type DiscardTimerMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareDeleteTimeEntryLog: {
    readonly __typename: "DeleteTimeEntryLogPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "DeleteTimeEntryLogResult";
      readonly myActiveTimeEntryLog: {
        readonly __typename: "MyActiveTimeEntryLog";
        readonly timeEntryLog: {
          readonly __typename: "TimeEntryLog";
          readonly id: Ids.TimeEntryLogId;
        } | null;
      };
    } | null;
  } | null;
};

export type EditTimeEntryLogMutationVariables = Exact<{
  input: EditTimeEntryLogInput;
}>;

export type EditTimeEntryLogMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareEditTimeEntryLog: {
    readonly __typename: "EditTimeEntryLogPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EditTimeEntryLogResult";
      readonly timeEntryLog: {
        readonly __typename: "TimeEntryLog";
        readonly id: Ids.TimeEntryLogId;
        readonly startTime: Date;
        readonly endTime: Date | null;
        readonly durationSeconds: number | null;
        readonly provider: { readonly __typename: "Provider"; readonly id: Ids.ProviderId };
        readonly workFor: {
          readonly __typename: "Task";
          readonly id: Ids.TaskId;
          readonly status: TaskStatus;
          readonly notes: {
            readonly __typename: "NoteConnection";
            readonly nodes: ReadonlyArray<{ readonly __typename: "Note"; readonly id: Ids.NoteId }>;
          };
          readonly timeEntries: {
            readonly __typename: "TimeEntryLogConnection";
            readonly nodes: ReadonlyArray<{
              readonly __typename: "TimeEntryLog";
              readonly id: Ids.TimeEntryLogId;
            }>;
          };
        };
      };
      readonly myActiveTimeEntryLog: {
        readonly __typename: "MyActiveTimeEntryLog";
        readonly timeEntryLog: {
          readonly __typename: "TimeEntryLog";
          readonly id: Ids.TimeEntryLogId;
          readonly clientStartTime: Date;
          readonly startTime: Date;
          readonly durationSeconds: number | null;
        } | null;
      };
    } | null;
  } | null;
};

export type EndTimeEntryLogMutationVariables = Exact<{
  input: EndTimeEntryLogInput;
}>;

export type EndTimeEntryLogMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareEndTimeEntryLog: {
    readonly __typename: "EndTimeEntryLogPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EndTimeEntryLogResult";
      readonly myActiveTimeEntryLog: {
        readonly __typename: "MyActiveTimeEntryLog";
        readonly timeEntryLog: {
          readonly __typename: "TimeEntryLog";
          readonly id: Ids.TimeEntryLogId;
        } | null;
      };
      readonly timeEntryLog: {
        readonly __typename: "TimeEntryLog";
        readonly id: Ids.TimeEntryLogId;
        readonly startTime: Date;
        readonly clientStartTime: Date;
        readonly durationSeconds: number | null;
        readonly provider: { readonly __typename: "Provider"; readonly id: Ids.ProviderId };
        readonly workFor: {
          readonly __typename: "Task";
          readonly id: Ids.TaskId;
          readonly title: string;
          readonly body: string;
          readonly createdAt: Date;
          readonly dueAt: Date | null;
          readonly status: TaskStatus;
          readonly isPlaceholder: boolean;
          readonly patient: {
            readonly __typename: "Patient";
            readonly id: Ids.PatientId;
            readonly name: string;
            readonly isTest: boolean;
          } | null;
          readonly assignedTo: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          };
          readonly notes: {
            readonly __typename: "NoteConnection";
            readonly nodes: ReadonlyArray<{
              readonly __typename: "Note";
              readonly id: Ids.NoteId;
              readonly text: string;
              readonly createdAt: Date;
              readonly author: {
                readonly __typename: "Provider";
                readonly id: Ids.ProviderId;
                readonly name: string;
              };
              readonly timeEntry: { readonly __typename: "TimeEntryLog"; readonly startTime: Date } | null;
            }>;
          };
          readonly timeEntries: {
            readonly __typename: "TimeEntryLogConnection";
            readonly nodes: ReadonlyArray<{
              readonly __typename: "TimeEntryLog";
              readonly id: Ids.TimeEntryLogId;
              readonly startTime: Date;
              readonly endTime: Date | null;
              readonly durationSeconds: number | null;
              readonly createdAt: Date;
              readonly provider: {
                readonly __typename: "Provider";
                readonly id: Ids.ProviderId;
                readonly name: string;
              };
            }>;
          };
          readonly creationLog: {
            readonly __typename: "TaskCreationLog";
            readonly id: Ids.TaskCreationLogId;
            readonly creationType: TaskCreationType;
            readonly patientSession: {
              readonly __typename: "PatientSession";
              readonly id: Ids.PatientSessionId;
              readonly targetDate: Date;
              readonly patient: {
                readonly __typename: "Patient";
                readonly id: Ids.PatientId;
                readonly name: string;
                readonly isTest: boolean;
              };
            } | null;
            readonly requestedBy: {
              readonly __typename: "Provider";
              readonly id: Ids.ProviderId;
              readonly name: string;
            } | null;
            readonly assignedBy: {
              readonly __typename: "Provider";
              readonly id: Ids.ProviderId;
              readonly name: string;
            } | null;
          } | null;
        };
      };
    } | null;
  } | null;
};

export type CurrentInstituteDetailsFragment = {
  readonly __typename: "CurrentInstitute";
  readonly id: Ids.InstituteId;
  readonly shortcode: string;
  readonly name: string;
  readonly supportsInstituteGroups: boolean;
  readonly enabled: boolean;
  readonly activeDataSourceResources: ReadonlyArray<DataSourceResourceType>;
  readonly configuration: {
    readonly __typename: "InstituteConfiguration";
    readonly allowBulkExport: boolean;
    readonly integrationEnabled: boolean;
    readonly enableSeveritySummary: boolean;
    readonly integrationAppointmentDeduplicationWindowMinutes: number;
    readonly integrationOnlyMeasurePrimaryProvider: boolean;
  };
  readonly featureSet: {
    readonly __typename: "InstituteFeatureSet";
    readonly enableBilling: boolean;
    readonly enableCareUnits: boolean;
    readonly enableConditions: boolean;
    readonly enableDemoQuickAdd: boolean;
    readonly enableExperiments: boolean;
    readonly enableMeasurementDimensions: boolean;
    readonly enableMeasurementPlans: boolean;
    readonly enablePayors: boolean;
    readonly enableQualityIssues: boolean;
    readonly enableTreatmentServices: boolean;
    readonly enableDashboardSchedule: boolean;
    readonly enableAthenaWriteback: boolean;
    readonly enableDecisionSupport: boolean;
    readonly enableCollaborativeCare: boolean;
    readonly enableImplementationTargets: boolean;
    readonly assessmentIncompleteFindings: boolean;
    readonly monthlyAssessmentMissingFindings: boolean;
    readonly enableHealthieChildPatientSwap: boolean;
    readonly enableLocations: boolean;
    readonly enableOptumMetrics: boolean;
    readonly enableCocmEncounters: boolean;
    readonly enableMbcRedesign: boolean;
  };
};

export type CurrentInstituteQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentInstituteQuery = {
  readonly __typename: "Query";
  readonly currentInstitute: {
    readonly __typename: "CurrentInstitute";
    readonly id: Ids.InstituteId;
    readonly shortcode: string;
    readonly name: string;
    readonly supportsInstituteGroups: boolean;
    readonly enabled: boolean;
    readonly activeDataSourceResources: ReadonlyArray<DataSourceResourceType>;
    readonly configuration: {
      readonly __typename: "InstituteConfiguration";
      readonly allowBulkExport: boolean;
      readonly integrationEnabled: boolean;
      readonly enableSeveritySummary: boolean;
      readonly integrationAppointmentDeduplicationWindowMinutes: number;
      readonly integrationOnlyMeasurePrimaryProvider: boolean;
    };
    readonly featureSet: {
      readonly __typename: "InstituteFeatureSet";
      readonly enableBilling: boolean;
      readonly enableCareUnits: boolean;
      readonly enableConditions: boolean;
      readonly enableDemoQuickAdd: boolean;
      readonly enableExperiments: boolean;
      readonly enableMeasurementDimensions: boolean;
      readonly enableMeasurementPlans: boolean;
      readonly enablePayors: boolean;
      readonly enableQualityIssues: boolean;
      readonly enableTreatmentServices: boolean;
      readonly enableDashboardSchedule: boolean;
      readonly enableAthenaWriteback: boolean;
      readonly enableDecisionSupport: boolean;
      readonly enableCollaborativeCare: boolean;
      readonly enableImplementationTargets: boolean;
      readonly assessmentIncompleteFindings: boolean;
      readonly monthlyAssessmentMissingFindings: boolean;
      readonly enableHealthieChildPatientSwap: boolean;
      readonly enableLocations: boolean;
      readonly enableOptumMetrics: boolean;
      readonly enableCocmEncounters: boolean;
      readonly enableMbcRedesign: boolean;
    };
  };
};

export type CurrentTimeTrackingQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentTimeTrackingQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareMyActiveTimeEntryLog: {
    readonly __typename: "MyActiveTimeEntryLog";
    readonly timeEntryLog: {
      readonly __typename: "TimeEntryLog";
      readonly id: Ids.TimeEntryLogId;
      readonly workFor: {
        readonly __typename: "Task";
        readonly id: Ids.TaskId;
        readonly title: string;
        readonly patient: {
          readonly __typename: "Patient";
          readonly id: Ids.PatientId;
          readonly name: string;
        } | null;
      };
    } | null;
  } | null;
};

export type EntitySummaryQueryVariables = Exact<{
  id: Scalars["EntityId"]["input"];
}>;

export type EntitySummaryQuery = {
  readonly __typename: "Query";
  readonly entity: {
    readonly __typename: "Entity";
    readonly id: Ids.EntityId;
    readonly name: string;
    readonly entityType: EntityType;
  } | null;
};

export type EntityTreeNodeDetailsFragment = {
  readonly __typename: "EntityTreeNode";
  readonly path: string;
  readonly minAge: number | null;
  readonly maxAge: number | null;
  readonly entity: {
    readonly __typename: "Entity";
    readonly id: Ids.EntityId;
    readonly name: string;
    readonly shortname: string;
    readonly entityType: EntityType;
  };
  readonly segments: ReadonlyArray<{
    readonly __typename: "EntityTreeSegment";
    readonly id: Ids.EntityId;
    readonly path: string;
    readonly entityType: EntityType;
    readonly name: string;
    readonly shortname: string;
    readonly rootPath: string;
  }>;
};

export type CheckFirstTimePatientCreateQueryVariables = Exact<{
  dataFetchId: Scalars["DataFetchRequestId"]["input"];
}>;

export type CheckFirstTimePatientCreateQuery = {
  readonly __typename: "Query";
  readonly integrationsDataFetchRequest: {
    readonly __typename: "DataFetchRequest";
    readonly id: Ids.DataFetchRequestId;
    readonly status: DataFetchRequestStatus;
    readonly dataSourceRecord: {
      readonly __typename: "DataSourceRecord";
      readonly id: Ids.DataSourceRecordId;
      readonly target: {
        readonly __typename: "Entity";
        readonly id: Ids.EntityId;
        readonly entityType: EntityType;
      } | null;
    } | null;
  } | null;
};

export type ExternalPatientSearchQueryVariables = Exact<{
  externalId: Scalars["String"]["input"];
}>;

export type ExternalPatientSearchQuery = {
  readonly __typename: "Query";
  readonly patients: {
    readonly __typename: "PatientConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Patient";
      readonly id: Ids.PatientId;
      readonly hasCompletedAssessment: boolean;
    }>;
  } | null;
};

export type RecentFailedLoginsQueryVariables = Exact<{
  sortBy: InputMaybe<BusinessEventSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
}>;

export type RecentFailedLoginsQuery = {
  readonly __typename: "Query";
  readonly businessEvents: {
    readonly __typename: "BusinessEventConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "BusinessEvent";
      readonly id: Ids.BusinessEventId;
      readonly eventDate: Date;
      readonly data: GqlScalars.BusinessEventData | null;
      readonly patient: {
        readonly __typename: "Patient";
        readonly id: Ids.PatientId;
        readonly dob: Date | null;
        readonly lastSsn: string | null;
        readonly lastName: string | null;
      } | null;
      readonly patientSession: {
        readonly __typename: "PatientSession";
        readonly id: Ids.PatientSessionId;
        readonly targetDate: Date;
      } | null;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type FeedbackReportCareEpisodeQueryQueryVariables = Exact<{
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
}>;

export type FeedbackReportCareEpisodeQueryQuery = {
  readonly __typename: "Query";
  readonly assessmentCareEpisodeScaleSummary: {
    readonly __typename: "CareEpisodeScaleSummary";
    readonly id: Ids.CareEpisodeId;
    readonly administrationDates: ReadonlyArray<Date>;
    readonly careEpisode: {
      readonly __typename: "CareEpisode";
      readonly id: Ids.CareEpisodeId;
      readonly status: CareEpisodeStatus;
      readonly periodStart: Date | null;
      readonly periodEnd: Date | null;
      readonly careEpisodeProviders: ReadonlyArray<{
        readonly __typename: "CareEpisodeProvider";
        readonly provider: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly title: string | null;
          readonly user: {
            readonly __typename: "User";
            readonly id: Ids.UserId;
            readonly name: string;
            readonly firstName: string | null;
          };
        };
      }>;
      readonly patientConditions: ReadonlyArray<{
        readonly __typename: "PatientCondition";
        readonly id: Ids.PatientConditionId;
        readonly status: PatientConditionStatus;
        readonly onsetDate: Date | null;
        readonly abatementDate: Date | null;
        readonly instituteCondition: {
          readonly __typename: "InstituteCondition";
          readonly id: Ids.InstituteConditionId;
          readonly name: string;
          readonly code: string;
        };
        readonly patient: { readonly __typename: "Patient"; readonly id: Ids.PatientId };
      }>;
      readonly patient: {
        readonly __typename: "Patient";
        readonly id: Ids.PatientId;
        readonly name: string;
        readonly phoneNumber: string | null;
        readonly email: string | null;
        readonly primaryLanguage: string | null;
        readonly gender: Gender | null;
        readonly genderIdentity: string | null;
        readonly genderIdentityFhir: GenderIdentity | null;
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly legalFirstName: string | null;
        readonly legalLastName: string | null;
        readonly preferredFirstName: string | null;
        readonly preferredLastName: string | null;
        readonly preferredPronouns: string | null;
        readonly dob: Date | null;
      };
      readonly patientSessions: {
        readonly __typename: "PatientSessionConnection";
        readonly nodes: ReadonlyArray<{
          readonly __typename: "PatientSession";
          readonly id: Ids.PatientSessionId;
          readonly sessionNumber: number | null;
          readonly targetDate: Date;
          readonly assessmentReport: { readonly __typename: "Report"; readonly id: Ids.ReportId } | null;
        }>;
      };
      readonly organization: {
        readonly __typename: "Organization";
        readonly id: Ids.OrganizationId;
        readonly name: string;
      };
      readonly participants: ReadonlyArray<
        | {
            readonly __typename: "Patient";
            readonly participantId: Ids.ParticipantId;
            readonly relationship: ParticipantRelationship;
            readonly user: {
              readonly __typename: "User";
              readonly id: Ids.UserId;
              readonly name: string;
              readonly firstName: string | null;
            };
          }
        | {
            readonly __typename: "Provider";
            readonly participantId: Ids.ParticipantId;
            readonly relationship: ParticipantRelationship;
            readonly user: {
              readonly __typename: "User";
              readonly id: Ids.UserId;
              readonly name: string;
              readonly firstName: string | null;
            };
          }
        | {
            readonly __typename: "RelatedPerson";
            readonly participantId: Ids.ParticipantId;
            readonly relationship: ParticipantRelationship;
            readonly user: {
              readonly __typename: "User";
              readonly id: Ids.UserId;
              readonly name: string;
              readonly firstName: string | null;
            };
          }
      >;
    };
    readonly activeInvitations: ReadonlyArray<{
      readonly __typename: "Invitation";
      readonly id: Ids.InvitationId;
      readonly status: InvitationStatus;
      readonly phase: InvitationPhase;
      readonly user: {
        readonly __typename: "User";
        readonly id: Ids.UserId;
        readonly name: string;
        readonly firstName: string | null;
      };
    }>;
  } | null;
};

export type CareEpisodeScaleResultSummaryFragment = {
  readonly __typename: "CareEpisodeScaleResult";
  readonly scale: {
    readonly __typename: "AdministrableScale";
    readonly id: Ids.ScaleId;
    readonly mnemonic: string;
    readonly parentId: Ids.ScaleId | null;
    readonly name: string;
    readonly shortname: string | null;
    readonly nanoname: string | null;
    readonly friendlyName: string | null;
    readonly measureType: ScaleMeasureType;
    readonly role: ScaleRole | null;
    readonly reportShortDescription: string | null;
    readonly questions: ReadonlyArray<{
      readonly __typename: "Question";
      readonly id: Ids.QuestionId;
      readonly title: string;
      readonly reportText: string;
      readonly questionType: QuestionType;
    }>;
  };
  readonly byUser: ReadonlyArray<{
    readonly __typename: "CareEpisodeScaleByUser";
    readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string } | null;
    readonly byScorer: ReadonlyArray<{
      readonly __typename: "CareEpisodeScaleByUserByScorer";
      readonly scorer:
        | { readonly __typename: "CategoricalScaleScorer"; readonly domain: ScaleScorerDomain }
        | {
            readonly __typename: "NumericalScaleScorer";
            readonly domain: ScaleScorerDomain;
            readonly minValue: number;
            readonly maxValue: number;
            readonly minInterpretation: string | null;
            readonly maxInterpretation: string | null;
            readonly minInterpretationShort: string | null;
            readonly maxInterpretationShort: string | null;
            readonly thresholdData: ReadonlyArray<{
              readonly __typename: "ScaleThreshold";
              readonly mnemonic: string;
              readonly thresholdClass: ScaleThresholdClass;
              readonly minValue: number;
              readonly maxValue: number;
            }> | null;
          }
        | { readonly __typename: "UnscoredScaleScorer"; readonly domain: ScaleScorerDomain };
      readonly latest: {
        readonly __typename: "CareEpisodeComputedValue";
        readonly id: Ids.CareEpisodeComputedValueId;
        readonly value: number | null;
        readonly thresholdClass: ScaleThresholdClass | null;
        readonly thresholdMnemonic: string | null;
        readonly effectiveDate: Date | null;
        readonly targetDate: Date;
        readonly date: Date;
        readonly validity: ComputedValueValidity;
        readonly acuteChangeCategory: AcuteChangeCategory;
        readonly clinicalCategory: ClinicalCategory;
        readonly trend: Trend;
        readonly severityCategory: SeverityCategory;
        readonly scoringMechanism: CalculationMechanism;
        readonly responsesAvailable: ResponsesAvailable;
        readonly answers: ReadonlyArray<{
          readonly __typename: "Answer";
          readonly id: Ids.AnswerId;
          readonly value: string | null;
          readonly questionId: Ids.QuestionId;
          readonly normalizedScore: NormalizedScore | null;
          readonly endorsed: boolean | null;
          readonly itemCoding: ItemCoding | null;
          readonly option: {
            readonly __typename: "QuestionOption";
            readonly title: string;
            readonly value: string;
            readonly reportText: string | null;
          } | null;
        }>;
        readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
        readonly scorer:
          | { readonly __typename: "CategoricalScaleScorer" }
          | { readonly __typename: "NumericalScaleScorer" }
          | { readonly __typename: "UnscoredScaleScorer" };
        readonly mostRelevantInvitation: {
          readonly __typename: "Invitation";
          readonly id: Ids.InvitationId;
          readonly dateCompleted: Date | null;
          readonly dateBegun: Date | null;
        } | null;
      };
      readonly history: ReadonlyArray<{
        readonly __typename: "CareEpisodeComputedValue";
        readonly id: Ids.CareEpisodeComputedValueId;
        readonly value: number | null;
        readonly thresholdClass: ScaleThresholdClass | null;
        readonly thresholdMnemonic: string | null;
        readonly effectiveDate: Date | null;
        readonly targetDate: Date;
        readonly date: Date;
        readonly validity: ComputedValueValidity;
        readonly acuteChangeCategory: AcuteChangeCategory;
        readonly clinicalCategory: ClinicalCategory;
        readonly trend: Trend;
        readonly severityCategory: SeverityCategory;
        readonly scoringMechanism: CalculationMechanism;
        readonly responsesAvailable: ResponsesAvailable;
        readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
        readonly scorer:
          | { readonly __typename: "CategoricalScaleScorer" }
          | { readonly __typename: "NumericalScaleScorer" }
          | { readonly __typename: "UnscoredScaleScorer" };
        readonly mostRelevantInvitation: {
          readonly __typename: "Invitation";
          readonly id: Ids.InvitationId;
          readonly dateCompleted: Date | null;
          readonly dateBegun: Date | null;
        } | null;
      }>;
    }>;
  }>;
};

export type ClinicalAlertHistoryQueryQueryVariables = Exact<{
  careEpisodeClinicalAlertHistoryId: Scalars["CareEpisodeScaleId"]["input"];
}>;

export type ClinicalAlertHistoryQueryQuery = {
  readonly __typename: "Query";
  readonly assessmentCareEpisodeClinicalAlertHistory: {
    readonly __typename: "CareEpisodeClinicalAlertHistory";
    readonly id: Ids.CareEpisodeScaleId;
    readonly alertType: ClinicalAlertType;
    readonly scale: {
      readonly __typename: "AdministrableScale";
      readonly mnemonic: string;
      readonly scaleGroup: { readonly __typename: "ScaleGroup"; readonly id: Ids.ScaleGroupId } | null;
    } | null;
    readonly latest: {
      readonly __typename: "CareEpisodeClinicalAlert";
      readonly id: string;
      readonly effectiveDate: Date;
      readonly targetDate: Date;
      readonly status: ClinicalAlertValue;
      readonly answers: ReadonlyArray<{
        readonly __typename: "Answer";
        readonly id: Ids.AnswerId;
        readonly value: string | null;
        readonly questionId: Ids.QuestionId;
        readonly normalizedScore: NormalizedScore | null;
        readonly itemCoding: ItemCoding | null;
        readonly endorsed: boolean | null;
        readonly option: {
          readonly __typename: "QuestionOption";
          readonly title: string;
          readonly value: string;
          readonly reportText: string | null;
        } | null;
        readonly question: {
          readonly __typename: "Question";
          readonly id: Ids.QuestionId;
          readonly title: string;
          readonly reportText: string;
          readonly questionType: QuestionType;
        };
        readonly user: {
          readonly __typename: "User";
          readonly id: Ids.UserId;
          readonly name: string;
          readonly firstName: string | null;
        };
      }>;
      readonly invitations: ReadonlyArray<{
        readonly __typename: "Invitation";
        readonly id: Ids.InvitationId;
        readonly dateCompleted: Date | null;
        readonly dateBegun: Date | null;
        readonly user: { readonly __typename: "User"; readonly id: Ids.UserId };
      }>;
    } | null;
    readonly history: ReadonlyArray<{
      readonly __typename: "CareEpisodeClinicalAlert";
      readonly id: string;
      readonly effectiveDate: Date;
      readonly targetDate: Date;
      readonly status: ClinicalAlertValue;
      readonly answers: ReadonlyArray<{
        readonly __typename: "Answer";
        readonly id: Ids.AnswerId;
        readonly value: string | null;
        readonly questionId: Ids.QuestionId;
        readonly normalizedScore: NormalizedScore | null;
        readonly itemCoding: ItemCoding | null;
        readonly endorsed: boolean | null;
        readonly option: {
          readonly __typename: "QuestionOption";
          readonly title: string;
          readonly value: string;
          readonly reportText: string | null;
        } | null;
        readonly question: {
          readonly __typename: "Question";
          readonly id: Ids.QuestionId;
          readonly title: string;
          readonly reportText: string;
          readonly questionType: QuestionType;
        };
        readonly user: {
          readonly __typename: "User";
          readonly id: Ids.UserId;
          readonly name: string;
          readonly firstName: string | null;
        };
      }>;
      readonly invitations: ReadonlyArray<{
        readonly __typename: "Invitation";
        readonly id: Ids.InvitationId;
        readonly dateCompleted: Date | null;
        readonly dateBegun: Date | null;
        readonly user: { readonly __typename: "User"; readonly id: Ids.UserId };
      }>;
    }>;
  } | null;
};

export type GoalDetailQueryQueryVariables = Exact<{
  goalId: Scalars["GoalId"]["input"];
}>;

export type GoalDetailQueryQuery = {
  readonly __typename: "Query";
  readonly assessmentGoal: {
    readonly __typename: "Goal";
    readonly id: Ids.GoalId;
    readonly goalType: GoalType;
    readonly status: GoalStatus;
    readonly startDate: Date;
    readonly endDate: Date | null;
    readonly isBeneficial: boolean;
    readonly patientText: string;
    readonly user: {
      readonly __typename: "User";
      readonly id: Ids.UserId;
      readonly name: string;
      readonly firstName: string | null;
    };
    readonly thresholdData: ReadonlyArray<{
      readonly __typename: "ScaleThreshold";
      readonly mnemonic: string;
      readonly thresholdClass: ScaleThresholdClass;
      readonly maxValue: number;
      readonly minValue: number;
    }>;
    readonly goalAnswers: ReadonlyArray<{
      readonly __typename: "GoalAnswer";
      readonly id: Ids.GoalAnswerId;
      readonly answerDate: Date;
      readonly targetDate: Date;
      readonly effectiveDate: Date;
      readonly value: number | null;
    }>;
  } | null;
};

export type OverviewAlertsQueryQueryVariables = Exact<{
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
}>;

export type OverviewAlertsQueryQuery = {
  readonly __typename: "Query";
  readonly assessmentCareEpisodeScaleSummary: {
    readonly __typename: "CareEpisodeScaleSummary";
    readonly id: Ids.CareEpisodeId;
    readonly alerts: ReadonlyArray<{
      readonly __typename: "CareEpisodeClinicalAlertHistory";
      readonly id: Ids.CareEpisodeScaleId;
      readonly alertType: ClinicalAlertType;
      readonly scale: {
        readonly __typename: "AdministrableScale";
        readonly mnemonic: string;
        readonly scaleGroup: { readonly __typename: "ScaleGroup"; readonly id: Ids.ScaleGroupId } | null;
      } | null;
      readonly latest: {
        readonly __typename: "CareEpisodeClinicalAlert";
        readonly id: string;
        readonly effectiveDate: Date;
        readonly targetDate: Date;
        readonly status: ClinicalAlertValue;
        readonly answers: ReadonlyArray<{
          readonly __typename: "Answer";
          readonly id: Ids.AnswerId;
          readonly value: string | null;
          readonly questionId: Ids.QuestionId;
          readonly normalizedScore: NormalizedScore | null;
          readonly itemCoding: ItemCoding | null;
          readonly endorsed: boolean | null;
          readonly option: {
            readonly __typename: "QuestionOption";
            readonly title: string;
            readonly value: string;
            readonly reportText: string | null;
          } | null;
          readonly question: {
            readonly __typename: "Question";
            readonly id: Ids.QuestionId;
            readonly title: string;
            readonly reportText: string;
            readonly questionType: QuestionType;
          };
          readonly user: {
            readonly __typename: "User";
            readonly id: Ids.UserId;
            readonly name: string;
            readonly firstName: string | null;
          };
        }>;
        readonly invitations: ReadonlyArray<{
          readonly __typename: "Invitation";
          readonly id: Ids.InvitationId;
          readonly dateCompleted: Date | null;
          readonly dateBegun: Date | null;
          readonly user: { readonly __typename: "User"; readonly id: Ids.UserId };
        }>;
      } | null;
      readonly history: ReadonlyArray<{
        readonly __typename: "CareEpisodeClinicalAlert";
        readonly id: string;
        readonly effectiveDate: Date;
        readonly targetDate: Date;
        readonly status: ClinicalAlertValue;
        readonly answers: ReadonlyArray<{
          readonly __typename: "Answer";
          readonly id: Ids.AnswerId;
          readonly value: string | null;
          readonly questionId: Ids.QuestionId;
          readonly normalizedScore: NormalizedScore | null;
          readonly itemCoding: ItemCoding | null;
          readonly endorsed: boolean | null;
          readonly option: {
            readonly __typename: "QuestionOption";
            readonly title: string;
            readonly value: string;
            readonly reportText: string | null;
          } | null;
          readonly question: {
            readonly __typename: "Question";
            readonly id: Ids.QuestionId;
            readonly title: string;
            readonly reportText: string;
            readonly questionType: QuestionType;
          };
          readonly user: {
            readonly __typename: "User";
            readonly id: Ids.UserId;
            readonly name: string;
            readonly firstName: string | null;
          };
        }>;
        readonly invitations: ReadonlyArray<{
          readonly __typename: "Invitation";
          readonly id: Ids.InvitationId;
          readonly dateCompleted: Date | null;
          readonly dateBegun: Date | null;
          readonly user: { readonly __typename: "User"; readonly id: Ids.UserId };
        }>;
      }>;
    }>;
  } | null;
};

export type ConstructScaleDetailsFragment = {
  readonly __typename: "CareEpisodeScaleResult";
  readonly scale: {
    readonly __typename: "AdministrableScale";
    readonly id: Ids.ScaleId;
    readonly mnemonic: string;
    readonly parentId: Ids.ScaleId | null;
    readonly name: string;
    readonly shortname: string | null;
    readonly nanoname: string | null;
    readonly friendlyName: string | null;
    readonly measureType: ScaleMeasureType;
    readonly reportShortDescription: string | null;
    readonly role: ScaleRole | null;
    readonly questions: ReadonlyArray<{
      readonly __typename: "Question";
      readonly id: Ids.QuestionId;
      readonly title: string;
      readonly reportText: string;
      readonly questionType: QuestionType;
    }>;
  };
  readonly byUser: ReadonlyArray<{
    readonly __typename: "CareEpisodeScaleByUser";
    readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string } | null;
    readonly byScorer: ReadonlyArray<{
      readonly __typename: "CareEpisodeScaleByUserByScorer";
      readonly scorer:
        | { readonly __typename: "CategoricalScaleScorer"; readonly domain: ScaleScorerDomain }
        | {
            readonly __typename: "NumericalScaleScorer";
            readonly minValue: number;
            readonly maxValue: number;
            readonly minInterpretation: string | null;
            readonly maxInterpretation: string | null;
            readonly minInterpretationShort: string | null;
            readonly maxInterpretationShort: string | null;
            readonly domain: ScaleScorerDomain;
            readonly thresholdData: ReadonlyArray<{
              readonly __typename: "ScaleThreshold";
              readonly mnemonic: string;
              readonly thresholdClass: ScaleThresholdClass;
              readonly minValue: number;
              readonly maxValue: number;
            }> | null;
          }
        | { readonly __typename: "UnscoredScaleScorer"; readonly domain: ScaleScorerDomain };
      readonly latest: {
        readonly __typename: "CareEpisodeComputedValue";
        readonly id: Ids.CareEpisodeComputedValueId;
        readonly value: number | null;
        readonly thresholdClass: ScaleThresholdClass | null;
        readonly thresholdMnemonic: string | null;
        readonly effectiveDate: Date | null;
        readonly targetDate: Date;
        readonly date: Date;
        readonly validity: ComputedValueValidity;
        readonly acuteChangeCategory: AcuteChangeCategory;
        readonly clinicalCategory: ClinicalCategory;
        readonly trend: Trend;
        readonly severityCategory: SeverityCategory;
        readonly scoringMechanism: CalculationMechanism;
        readonly responsesAvailable: ResponsesAvailable;
        readonly answers: ReadonlyArray<{
          readonly __typename: "Answer";
          readonly id: Ids.AnswerId;
          readonly value: string | null;
          readonly questionId: Ids.QuestionId;
          readonly normalizedScore: NormalizedScore | null;
          readonly endorsed: boolean | null;
          readonly itemCoding: ItemCoding | null;
          readonly option: {
            readonly __typename: "QuestionOption";
            readonly title: string;
            readonly value: string;
            readonly reportText: string | null;
          } | null;
        }>;
        readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
        readonly scorer:
          | { readonly __typename: "CategoricalScaleScorer" }
          | { readonly __typename: "NumericalScaleScorer" }
          | { readonly __typename: "UnscoredScaleScorer" };
        readonly mostRelevantInvitation: {
          readonly __typename: "Invitation";
          readonly id: Ids.InvitationId;
          readonly dateCompleted: Date | null;
          readonly dateBegun: Date | null;
        } | null;
      };
      readonly history: ReadonlyArray<{
        readonly __typename: "CareEpisodeComputedValue";
        readonly id: Ids.CareEpisodeComputedValueId;
        readonly value: number | null;
        readonly thresholdClass: ScaleThresholdClass | null;
        readonly thresholdMnemonic: string | null;
        readonly effectiveDate: Date | null;
        readonly targetDate: Date;
        readonly date: Date;
        readonly validity: ComputedValueValidity;
        readonly acuteChangeCategory: AcuteChangeCategory;
        readonly clinicalCategory: ClinicalCategory;
        readonly trend: Trend;
        readonly severityCategory: SeverityCategory;
        readonly scoringMechanism: CalculationMechanism;
        readonly responsesAvailable: ResponsesAvailable;
        readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
        readonly scorer:
          | { readonly __typename: "CategoricalScaleScorer" }
          | { readonly __typename: "NumericalScaleScorer" }
          | { readonly __typename: "UnscoredScaleScorer" };
        readonly mostRelevantInvitation: {
          readonly __typename: "Invitation";
          readonly id: Ids.InvitationId;
          readonly dateCompleted: Date | null;
          readonly dateBegun: Date | null;
        } | null;
      }>;
    }>;
  }>;
};

export type OverviewConstructsQueryQueryVariables = Exact<{
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
}>;

export type OverviewConstructsQueryQuery = {
  readonly __typename: "Query";
  readonly assessmentCareEpisodeScaleSummary: {
    readonly __typename: "CareEpisodeScaleSummary";
    readonly id: Ids.CareEpisodeId;
    readonly specialSections: ReadonlyArray<{
      readonly __typename: "CareEpisodeSpecialSection";
      readonly sectionType: CareEpisodeSpecialSectionType;
      readonly scaleSections: ReadonlyArray<
        | {
            readonly __typename: "CareEpisodeScaleGroupResult";
            readonly scaleGroup: {
              readonly __typename: "ScaleGroup";
              readonly id: Ids.ScaleGroupId;
              readonly name: string;
              readonly description: string | null;
              readonly shortname: string;
              readonly displayMode: ScaleGroupDisplayDataType;
            };
            readonly byScale: ReadonlyArray<{
              readonly __typename: "CareEpisodeScaleResult";
              readonly scale: {
                readonly __typename: "AdministrableScale";
                readonly id: Ids.ScaleId;
                readonly mnemonic: string;
                readonly parentId: Ids.ScaleId | null;
                readonly name: string;
                readonly shortname: string | null;
                readonly nanoname: string | null;
                readonly friendlyName: string | null;
                readonly measureType: ScaleMeasureType;
                readonly reportShortDescription: string | null;
                readonly role: ScaleRole | null;
                readonly questions: ReadonlyArray<{
                  readonly __typename: "Question";
                  readonly id: Ids.QuestionId;
                  readonly title: string;
                  readonly reportText: string;
                  readonly questionType: QuestionType;
                }>;
              };
              readonly byUser: ReadonlyArray<{
                readonly __typename: "CareEpisodeScaleByUser";
                readonly user: {
                  readonly __typename: "User";
                  readonly id: Ids.UserId;
                  readonly name: string;
                } | null;
                readonly byScorer: ReadonlyArray<{
                  readonly __typename: "CareEpisodeScaleByUserByScorer";
                  readonly scorer:
                    | { readonly __typename: "CategoricalScaleScorer"; readonly domain: ScaleScorerDomain }
                    | {
                        readonly __typename: "NumericalScaleScorer";
                        readonly minValue: number;
                        readonly maxValue: number;
                        readonly minInterpretation: string | null;
                        readonly maxInterpretation: string | null;
                        readonly minInterpretationShort: string | null;
                        readonly maxInterpretationShort: string | null;
                        readonly domain: ScaleScorerDomain;
                        readonly thresholdData: ReadonlyArray<{
                          readonly __typename: "ScaleThreshold";
                          readonly mnemonic: string;
                          readonly thresholdClass: ScaleThresholdClass;
                          readonly minValue: number;
                          readonly maxValue: number;
                        }> | null;
                      }
                    | { readonly __typename: "UnscoredScaleScorer"; readonly domain: ScaleScorerDomain };
                  readonly latest: {
                    readonly __typename: "CareEpisodeComputedValue";
                    readonly id: Ids.CareEpisodeComputedValueId;
                    readonly value: number | null;
                    readonly thresholdClass: ScaleThresholdClass | null;
                    readonly thresholdMnemonic: string | null;
                    readonly effectiveDate: Date | null;
                    readonly targetDate: Date;
                    readonly date: Date;
                    readonly validity: ComputedValueValidity;
                    readonly acuteChangeCategory: AcuteChangeCategory;
                    readonly clinicalCategory: ClinicalCategory;
                    readonly trend: Trend;
                    readonly severityCategory: SeverityCategory;
                    readonly scoringMechanism: CalculationMechanism;
                    readonly responsesAvailable: ResponsesAvailable;
                    readonly answers: ReadonlyArray<{
                      readonly __typename: "Answer";
                      readonly id: Ids.AnswerId;
                      readonly value: string | null;
                      readonly questionId: Ids.QuestionId;
                      readonly normalizedScore: NormalizedScore | null;
                      readonly endorsed: boolean | null;
                      readonly itemCoding: ItemCoding | null;
                      readonly option: {
                        readonly __typename: "QuestionOption";
                        readonly title: string;
                        readonly value: string;
                        readonly reportText: string | null;
                      } | null;
                    }>;
                    readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
                    readonly scorer:
                      | { readonly __typename: "CategoricalScaleScorer" }
                      | { readonly __typename: "NumericalScaleScorer" }
                      | { readonly __typename: "UnscoredScaleScorer" };
                    readonly mostRelevantInvitation: {
                      readonly __typename: "Invitation";
                      readonly id: Ids.InvitationId;
                      readonly dateCompleted: Date | null;
                      readonly dateBegun: Date | null;
                    } | null;
                  };
                  readonly history: ReadonlyArray<{
                    readonly __typename: "CareEpisodeComputedValue";
                    readonly id: Ids.CareEpisodeComputedValueId;
                    readonly value: number | null;
                    readonly thresholdClass: ScaleThresholdClass | null;
                    readonly thresholdMnemonic: string | null;
                    readonly effectiveDate: Date | null;
                    readonly targetDate: Date;
                    readonly date: Date;
                    readonly validity: ComputedValueValidity;
                    readonly acuteChangeCategory: AcuteChangeCategory;
                    readonly clinicalCategory: ClinicalCategory;
                    readonly trend: Trend;
                    readonly severityCategory: SeverityCategory;
                    readonly scoringMechanism: CalculationMechanism;
                    readonly responsesAvailable: ResponsesAvailable;
                    readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
                    readonly scorer:
                      | { readonly __typename: "CategoricalScaleScorer" }
                      | { readonly __typename: "NumericalScaleScorer" }
                      | { readonly __typename: "UnscoredScaleScorer" };
                    readonly mostRelevantInvitation: {
                      readonly __typename: "Invitation";
                      readonly id: Ids.InvitationId;
                      readonly dateCompleted: Date | null;
                      readonly dateBegun: Date | null;
                    } | null;
                  }>;
                }>;
              }>;
            }>;
          }
        | {
            readonly __typename: "CareEpisodeScaleResult";
            readonly scale: {
              readonly __typename: "AdministrableScale";
              readonly id: Ids.ScaleId;
              readonly mnemonic: string;
              readonly parentId: Ids.ScaleId | null;
              readonly name: string;
              readonly shortname: string | null;
              readonly nanoname: string | null;
              readonly friendlyName: string | null;
              readonly measureType: ScaleMeasureType;
              readonly reportShortDescription: string | null;
              readonly role: ScaleRole | null;
              readonly questions: ReadonlyArray<{
                readonly __typename: "Question";
                readonly id: Ids.QuestionId;
                readonly title: string;
                readonly reportText: string;
                readonly questionType: QuestionType;
              }>;
            };
            readonly byUser: ReadonlyArray<{
              readonly __typename: "CareEpisodeScaleByUser";
              readonly user: {
                readonly __typename: "User";
                readonly id: Ids.UserId;
                readonly name: string;
              } | null;
              readonly byScorer: ReadonlyArray<{
                readonly __typename: "CareEpisodeScaleByUserByScorer";
                readonly scorer:
                  | { readonly __typename: "CategoricalScaleScorer"; readonly domain: ScaleScorerDomain }
                  | {
                      readonly __typename: "NumericalScaleScorer";
                      readonly minValue: number;
                      readonly maxValue: number;
                      readonly minInterpretation: string | null;
                      readonly maxInterpretation: string | null;
                      readonly minInterpretationShort: string | null;
                      readonly maxInterpretationShort: string | null;
                      readonly domain: ScaleScorerDomain;
                      readonly thresholdData: ReadonlyArray<{
                        readonly __typename: "ScaleThreshold";
                        readonly mnemonic: string;
                        readonly thresholdClass: ScaleThresholdClass;
                        readonly minValue: number;
                        readonly maxValue: number;
                      }> | null;
                    }
                  | { readonly __typename: "UnscoredScaleScorer"; readonly domain: ScaleScorerDomain };
                readonly latest: {
                  readonly __typename: "CareEpisodeComputedValue";
                  readonly id: Ids.CareEpisodeComputedValueId;
                  readonly value: number | null;
                  readonly thresholdClass: ScaleThresholdClass | null;
                  readonly thresholdMnemonic: string | null;
                  readonly effectiveDate: Date | null;
                  readonly targetDate: Date;
                  readonly date: Date;
                  readonly validity: ComputedValueValidity;
                  readonly acuteChangeCategory: AcuteChangeCategory;
                  readonly clinicalCategory: ClinicalCategory;
                  readonly trend: Trend;
                  readonly severityCategory: SeverityCategory;
                  readonly scoringMechanism: CalculationMechanism;
                  readonly responsesAvailable: ResponsesAvailable;
                  readonly answers: ReadonlyArray<{
                    readonly __typename: "Answer";
                    readonly id: Ids.AnswerId;
                    readonly value: string | null;
                    readonly questionId: Ids.QuestionId;
                    readonly normalizedScore: NormalizedScore | null;
                    readonly endorsed: boolean | null;
                    readonly itemCoding: ItemCoding | null;
                    readonly option: {
                      readonly __typename: "QuestionOption";
                      readonly title: string;
                      readonly value: string;
                      readonly reportText: string | null;
                    } | null;
                  }>;
                  readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
                  readonly scorer:
                    | { readonly __typename: "CategoricalScaleScorer" }
                    | { readonly __typename: "NumericalScaleScorer" }
                    | { readonly __typename: "UnscoredScaleScorer" };
                  readonly mostRelevantInvitation: {
                    readonly __typename: "Invitation";
                    readonly id: Ids.InvitationId;
                    readonly dateCompleted: Date | null;
                    readonly dateBegun: Date | null;
                  } | null;
                };
                readonly history: ReadonlyArray<{
                  readonly __typename: "CareEpisodeComputedValue";
                  readonly id: Ids.CareEpisodeComputedValueId;
                  readonly value: number | null;
                  readonly thresholdClass: ScaleThresholdClass | null;
                  readonly thresholdMnemonic: string | null;
                  readonly effectiveDate: Date | null;
                  readonly targetDate: Date;
                  readonly date: Date;
                  readonly validity: ComputedValueValidity;
                  readonly acuteChangeCategory: AcuteChangeCategory;
                  readonly clinicalCategory: ClinicalCategory;
                  readonly trend: Trend;
                  readonly severityCategory: SeverityCategory;
                  readonly scoringMechanism: CalculationMechanism;
                  readonly responsesAvailable: ResponsesAvailable;
                  readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
                  readonly scorer:
                    | { readonly __typename: "CategoricalScaleScorer" }
                    | { readonly __typename: "NumericalScaleScorer" }
                    | { readonly __typename: "UnscoredScaleScorer" };
                  readonly mostRelevantInvitation: {
                    readonly __typename: "Invitation";
                    readonly id: Ids.InvitationId;
                    readonly dateCompleted: Date | null;
                    readonly dateBegun: Date | null;
                  } | null;
                }>;
              }>;
            }>;
          }
      >;
    }>;
    readonly constructs: ReadonlyArray<{
      readonly __typename: "CareEpisodeConstruct";
      readonly construct: {
        readonly __typename: "Construct";
        readonly id: Ids.ConstructId;
        readonly name: string;
        readonly shortname: string;
        readonly description: string | null;
        readonly displayOrder: number;
        readonly domain: { readonly __typename: "Domain"; readonly id: Ids.DomainId; readonly name: string };
      } | null;
      readonly scaleSections: ReadonlyArray<
        | {
            readonly __typename: "CareEpisodeScaleGroupResult";
            readonly scaleGroup: {
              readonly __typename: "ScaleGroup";
              readonly id: Ids.ScaleGroupId;
              readonly name: string;
              readonly description: string | null;
              readonly shortname: string;
              readonly displayMode: ScaleGroupDisplayDataType;
            };
            readonly byScale: ReadonlyArray<{
              readonly __typename: "CareEpisodeScaleResult";
              readonly scale: {
                readonly __typename: "AdministrableScale";
                readonly id: Ids.ScaleId;
                readonly mnemonic: string;
                readonly parentId: Ids.ScaleId | null;
                readonly name: string;
                readonly shortname: string | null;
                readonly nanoname: string | null;
                readonly friendlyName: string | null;
                readonly measureType: ScaleMeasureType;
                readonly reportShortDescription: string | null;
                readonly role: ScaleRole | null;
                readonly questions: ReadonlyArray<{
                  readonly __typename: "Question";
                  readonly id: Ids.QuestionId;
                  readonly title: string;
                  readonly reportText: string;
                  readonly questionType: QuestionType;
                }>;
              };
              readonly byUser: ReadonlyArray<{
                readonly __typename: "CareEpisodeScaleByUser";
                readonly user: {
                  readonly __typename: "User";
                  readonly id: Ids.UserId;
                  readonly name: string;
                } | null;
                readonly byScorer: ReadonlyArray<{
                  readonly __typename: "CareEpisodeScaleByUserByScorer";
                  readonly scorer:
                    | { readonly __typename: "CategoricalScaleScorer"; readonly domain: ScaleScorerDomain }
                    | {
                        readonly __typename: "NumericalScaleScorer";
                        readonly minValue: number;
                        readonly maxValue: number;
                        readonly minInterpretation: string | null;
                        readonly maxInterpretation: string | null;
                        readonly minInterpretationShort: string | null;
                        readonly maxInterpretationShort: string | null;
                        readonly domain: ScaleScorerDomain;
                        readonly thresholdData: ReadonlyArray<{
                          readonly __typename: "ScaleThreshold";
                          readonly mnemonic: string;
                          readonly thresholdClass: ScaleThresholdClass;
                          readonly minValue: number;
                          readonly maxValue: number;
                        }> | null;
                      }
                    | { readonly __typename: "UnscoredScaleScorer"; readonly domain: ScaleScorerDomain };
                  readonly latest: {
                    readonly __typename: "CareEpisodeComputedValue";
                    readonly id: Ids.CareEpisodeComputedValueId;
                    readonly value: number | null;
                    readonly thresholdClass: ScaleThresholdClass | null;
                    readonly thresholdMnemonic: string | null;
                    readonly effectiveDate: Date | null;
                    readonly targetDate: Date;
                    readonly date: Date;
                    readonly validity: ComputedValueValidity;
                    readonly acuteChangeCategory: AcuteChangeCategory;
                    readonly clinicalCategory: ClinicalCategory;
                    readonly trend: Trend;
                    readonly severityCategory: SeverityCategory;
                    readonly scoringMechanism: CalculationMechanism;
                    readonly responsesAvailable: ResponsesAvailable;
                    readonly answers: ReadonlyArray<{
                      readonly __typename: "Answer";
                      readonly id: Ids.AnswerId;
                      readonly value: string | null;
                      readonly questionId: Ids.QuestionId;
                      readonly normalizedScore: NormalizedScore | null;
                      readonly endorsed: boolean | null;
                      readonly itemCoding: ItemCoding | null;
                      readonly option: {
                        readonly __typename: "QuestionOption";
                        readonly title: string;
                        readonly value: string;
                        readonly reportText: string | null;
                      } | null;
                    }>;
                    readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
                    readonly scorer:
                      | { readonly __typename: "CategoricalScaleScorer" }
                      | { readonly __typename: "NumericalScaleScorer" }
                      | { readonly __typename: "UnscoredScaleScorer" };
                    readonly mostRelevantInvitation: {
                      readonly __typename: "Invitation";
                      readonly id: Ids.InvitationId;
                      readonly dateCompleted: Date | null;
                      readonly dateBegun: Date | null;
                    } | null;
                  };
                  readonly history: ReadonlyArray<{
                    readonly __typename: "CareEpisodeComputedValue";
                    readonly id: Ids.CareEpisodeComputedValueId;
                    readonly value: number | null;
                    readonly thresholdClass: ScaleThresholdClass | null;
                    readonly thresholdMnemonic: string | null;
                    readonly effectiveDate: Date | null;
                    readonly targetDate: Date;
                    readonly date: Date;
                    readonly validity: ComputedValueValidity;
                    readonly acuteChangeCategory: AcuteChangeCategory;
                    readonly clinicalCategory: ClinicalCategory;
                    readonly trend: Trend;
                    readonly severityCategory: SeverityCategory;
                    readonly scoringMechanism: CalculationMechanism;
                    readonly responsesAvailable: ResponsesAvailable;
                    readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
                    readonly scorer:
                      | { readonly __typename: "CategoricalScaleScorer" }
                      | { readonly __typename: "NumericalScaleScorer" }
                      | { readonly __typename: "UnscoredScaleScorer" };
                    readonly mostRelevantInvitation: {
                      readonly __typename: "Invitation";
                      readonly id: Ids.InvitationId;
                      readonly dateCompleted: Date | null;
                      readonly dateBegun: Date | null;
                    } | null;
                  }>;
                }>;
              }>;
            }>;
          }
        | {
            readonly __typename: "CareEpisodeScaleResult";
            readonly scale: {
              readonly __typename: "AdministrableScale";
              readonly id: Ids.ScaleId;
              readonly mnemonic: string;
              readonly parentId: Ids.ScaleId | null;
              readonly name: string;
              readonly shortname: string | null;
              readonly nanoname: string | null;
              readonly friendlyName: string | null;
              readonly measureType: ScaleMeasureType;
              readonly reportShortDescription: string | null;
              readonly role: ScaleRole | null;
              readonly questions: ReadonlyArray<{
                readonly __typename: "Question";
                readonly id: Ids.QuestionId;
                readonly title: string;
                readonly reportText: string;
                readonly questionType: QuestionType;
              }>;
            };
            readonly byUser: ReadonlyArray<{
              readonly __typename: "CareEpisodeScaleByUser";
              readonly user: {
                readonly __typename: "User";
                readonly id: Ids.UserId;
                readonly name: string;
              } | null;
              readonly byScorer: ReadonlyArray<{
                readonly __typename: "CareEpisodeScaleByUserByScorer";
                readonly scorer:
                  | { readonly __typename: "CategoricalScaleScorer"; readonly domain: ScaleScorerDomain }
                  | {
                      readonly __typename: "NumericalScaleScorer";
                      readonly minValue: number;
                      readonly maxValue: number;
                      readonly minInterpretation: string | null;
                      readonly maxInterpretation: string | null;
                      readonly minInterpretationShort: string | null;
                      readonly maxInterpretationShort: string | null;
                      readonly domain: ScaleScorerDomain;
                      readonly thresholdData: ReadonlyArray<{
                        readonly __typename: "ScaleThreshold";
                        readonly mnemonic: string;
                        readonly thresholdClass: ScaleThresholdClass;
                        readonly minValue: number;
                        readonly maxValue: number;
                      }> | null;
                    }
                  | { readonly __typename: "UnscoredScaleScorer"; readonly domain: ScaleScorerDomain };
                readonly latest: {
                  readonly __typename: "CareEpisodeComputedValue";
                  readonly id: Ids.CareEpisodeComputedValueId;
                  readonly value: number | null;
                  readonly thresholdClass: ScaleThresholdClass | null;
                  readonly thresholdMnemonic: string | null;
                  readonly effectiveDate: Date | null;
                  readonly targetDate: Date;
                  readonly date: Date;
                  readonly validity: ComputedValueValidity;
                  readonly acuteChangeCategory: AcuteChangeCategory;
                  readonly clinicalCategory: ClinicalCategory;
                  readonly trend: Trend;
                  readonly severityCategory: SeverityCategory;
                  readonly scoringMechanism: CalculationMechanism;
                  readonly responsesAvailable: ResponsesAvailable;
                  readonly answers: ReadonlyArray<{
                    readonly __typename: "Answer";
                    readonly id: Ids.AnswerId;
                    readonly value: string | null;
                    readonly questionId: Ids.QuestionId;
                    readonly normalizedScore: NormalizedScore | null;
                    readonly endorsed: boolean | null;
                    readonly itemCoding: ItemCoding | null;
                    readonly option: {
                      readonly __typename: "QuestionOption";
                      readonly title: string;
                      readonly value: string;
                      readonly reportText: string | null;
                    } | null;
                  }>;
                  readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
                  readonly scorer:
                    | { readonly __typename: "CategoricalScaleScorer" }
                    | { readonly __typename: "NumericalScaleScorer" }
                    | { readonly __typename: "UnscoredScaleScorer" };
                  readonly mostRelevantInvitation: {
                    readonly __typename: "Invitation";
                    readonly id: Ids.InvitationId;
                    readonly dateCompleted: Date | null;
                    readonly dateBegun: Date | null;
                  } | null;
                };
                readonly history: ReadonlyArray<{
                  readonly __typename: "CareEpisodeComputedValue";
                  readonly id: Ids.CareEpisodeComputedValueId;
                  readonly value: number | null;
                  readonly thresholdClass: ScaleThresholdClass | null;
                  readonly thresholdMnemonic: string | null;
                  readonly effectiveDate: Date | null;
                  readonly targetDate: Date;
                  readonly date: Date;
                  readonly validity: ComputedValueValidity;
                  readonly acuteChangeCategory: AcuteChangeCategory;
                  readonly clinicalCategory: ClinicalCategory;
                  readonly trend: Trend;
                  readonly severityCategory: SeverityCategory;
                  readonly scoringMechanism: CalculationMechanism;
                  readonly responsesAvailable: ResponsesAvailable;
                  readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
                  readonly scorer:
                    | { readonly __typename: "CategoricalScaleScorer" }
                    | { readonly __typename: "NumericalScaleScorer" }
                    | { readonly __typename: "UnscoredScaleScorer" };
                  readonly mostRelevantInvitation: {
                    readonly __typename: "Invitation";
                    readonly id: Ids.InvitationId;
                    readonly dateCompleted: Date | null;
                    readonly dateBegun: Date | null;
                  } | null;
                }>;
              }>;
            }>;
          }
      >;
    }>;
  } | null;
};

export type OverviewGoalsQueryQueryVariables = Exact<{
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
}>;

export type OverviewGoalsQueryQuery = {
  readonly __typename: "Query";
  readonly assessmentCareEpisodeScaleSummary: {
    readonly __typename: "CareEpisodeScaleSummary";
    readonly id: Ids.CareEpisodeId;
    readonly goals: ReadonlyArray<{
      readonly __typename: "Goal";
      readonly id: Ids.GoalId;
      readonly goalType: GoalType;
      readonly status: GoalStatus;
      readonly startDate: Date;
      readonly endDate: Date | null;
      readonly isBeneficial: boolean;
      readonly patientText: string;
      readonly user: {
        readonly __typename: "User";
        readonly id: Ids.UserId;
        readonly name: string;
        readonly firstName: string | null;
      };
      readonly thresholdData: ReadonlyArray<{
        readonly __typename: "ScaleThreshold";
        readonly mnemonic: string;
        readonly thresholdClass: ScaleThresholdClass;
        readonly maxValue: number;
        readonly minValue: number;
      }>;
      readonly goalAnswers: ReadonlyArray<{
        readonly __typename: "GoalAnswer";
        readonly id: Ids.GoalAnswerId;
        readonly answerDate: Date;
        readonly targetDate: Date;
        readonly effectiveDate: Date;
        readonly value: number | null;
      }>;
    }>;
  } | null;
};

export type TargetScaleDetailsFragment = {
  readonly __typename: "CareEpisodeScaleResult";
  readonly scale: {
    readonly __typename: "AdministrableScale";
    readonly id: Ids.ScaleId;
    readonly mnemonic: string;
    readonly parentId: Ids.ScaleId | null;
    readonly name: string;
    readonly shortname: string | null;
    readonly nanoname: string | null;
    readonly friendlyName: string | null;
    readonly measureType: ScaleMeasureType;
    readonly reportShortDescription: string | null;
    readonly role: ScaleRole | null;
    readonly questions: ReadonlyArray<{
      readonly __typename: "Question";
      readonly id: Ids.QuestionId;
      readonly title: string;
      readonly reportText: string;
      readonly questionType: QuestionType;
    }>;
  };
  readonly byUser: ReadonlyArray<{
    readonly __typename: "CareEpisodeScaleByUser";
    readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string } | null;
    readonly byScorer: ReadonlyArray<{
      readonly __typename: "CareEpisodeScaleByUserByScorer";
      readonly scorer:
        | { readonly __typename: "CategoricalScaleScorer"; readonly domain: ScaleScorerDomain }
        | {
            readonly __typename: "NumericalScaleScorer";
            readonly minValue: number;
            readonly maxValue: number;
            readonly minInterpretation: string | null;
            readonly maxInterpretation: string | null;
            readonly minInterpretationShort: string | null;
            readonly maxInterpretationShort: string | null;
            readonly domain: ScaleScorerDomain;
            readonly thresholdData: ReadonlyArray<{
              readonly __typename: "ScaleThreshold";
              readonly mnemonic: string;
              readonly thresholdClass: ScaleThresholdClass;
              readonly minValue: number;
              readonly maxValue: number;
            }> | null;
          }
        | { readonly __typename: "UnscoredScaleScorer"; readonly domain: ScaleScorerDomain };
      readonly latest: {
        readonly __typename: "CareEpisodeComputedValue";
        readonly id: Ids.CareEpisodeComputedValueId;
        readonly value: number | null;
        readonly thresholdClass: ScaleThresholdClass | null;
        readonly thresholdMnemonic: string | null;
        readonly effectiveDate: Date | null;
        readonly targetDate: Date;
        readonly date: Date;
        readonly validity: ComputedValueValidity;
        readonly acuteChangeCategory: AcuteChangeCategory;
        readonly clinicalCategory: ClinicalCategory;
        readonly trend: Trend;
        readonly severityCategory: SeverityCategory;
        readonly scoringMechanism: CalculationMechanism;
        readonly responsesAvailable: ResponsesAvailable;
        readonly answers: ReadonlyArray<{
          readonly __typename: "Answer";
          readonly id: Ids.AnswerId;
          readonly value: string | null;
          readonly questionId: Ids.QuestionId;
          readonly normalizedScore: NormalizedScore | null;
          readonly endorsed: boolean | null;
          readonly itemCoding: ItemCoding | null;
          readonly option: {
            readonly __typename: "QuestionOption";
            readonly title: string;
            readonly value: string;
            readonly reportText: string | null;
          } | null;
        }>;
        readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
        readonly scorer:
          | { readonly __typename: "CategoricalScaleScorer" }
          | { readonly __typename: "NumericalScaleScorer" }
          | { readonly __typename: "UnscoredScaleScorer" };
        readonly mostRelevantInvitation: {
          readonly __typename: "Invitation";
          readonly id: Ids.InvitationId;
          readonly dateCompleted: Date | null;
          readonly dateBegun: Date | null;
        } | null;
      };
      readonly history: ReadonlyArray<{
        readonly __typename: "CareEpisodeComputedValue";
        readonly id: Ids.CareEpisodeComputedValueId;
        readonly value: number | null;
        readonly thresholdClass: ScaleThresholdClass | null;
        readonly thresholdMnemonic: string | null;
        readonly effectiveDate: Date | null;
        readonly targetDate: Date;
        readonly date: Date;
        readonly validity: ComputedValueValidity;
        readonly acuteChangeCategory: AcuteChangeCategory;
        readonly clinicalCategory: ClinicalCategory;
        readonly trend: Trend;
        readonly severityCategory: SeverityCategory;
        readonly scoringMechanism: CalculationMechanism;
        readonly responsesAvailable: ResponsesAvailable;
        readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
        readonly scorer:
          | { readonly __typename: "CategoricalScaleScorer" }
          | { readonly __typename: "NumericalScaleScorer" }
          | { readonly __typename: "UnscoredScaleScorer" };
        readonly mostRelevantInvitation: {
          readonly __typename: "Invitation";
          readonly id: Ids.InvitationId;
          readonly dateCompleted: Date | null;
          readonly dateBegun: Date | null;
        } | null;
      }>;
    }>;
  }>;
};

export type OverviewTreatmentResponseQueryQueryVariables = Exact<{
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
}>;

export type OverviewTreatmentResponseQueryQuery = {
  readonly __typename: "Query";
  readonly assessmentCareEpisodeScaleSummary: {
    readonly __typename: "CareEpisodeScaleSummary";
    readonly id: Ids.CareEpisodeId;
    readonly targetSections: ReadonlyArray<
      | {
          readonly __typename: "CareEpisodeScaleGroupResult";
          readonly scaleGroup: {
            readonly __typename: "ScaleGroup";
            readonly id: Ids.ScaleGroupId;
            readonly name: string;
            readonly description: string | null;
            readonly shortname: string;
            readonly displayMode: ScaleGroupDisplayDataType;
          };
          readonly byScale: ReadonlyArray<{
            readonly __typename: "CareEpisodeScaleResult";
            readonly scale: {
              readonly __typename: "AdministrableScale";
              readonly id: Ids.ScaleId;
              readonly mnemonic: string;
              readonly parentId: Ids.ScaleId | null;
              readonly name: string;
              readonly shortname: string | null;
              readonly nanoname: string | null;
              readonly friendlyName: string | null;
              readonly measureType: ScaleMeasureType;
              readonly reportShortDescription: string | null;
              readonly role: ScaleRole | null;
              readonly questions: ReadonlyArray<{
                readonly __typename: "Question";
                readonly id: Ids.QuestionId;
                readonly title: string;
                readonly reportText: string;
                readonly questionType: QuestionType;
              }>;
            };
            readonly byUser: ReadonlyArray<{
              readonly __typename: "CareEpisodeScaleByUser";
              readonly user: {
                readonly __typename: "User";
                readonly id: Ids.UserId;
                readonly name: string;
              } | null;
              readonly byScorer: ReadonlyArray<{
                readonly __typename: "CareEpisodeScaleByUserByScorer";
                readonly scorer:
                  | { readonly __typename: "CategoricalScaleScorer"; readonly domain: ScaleScorerDomain }
                  | {
                      readonly __typename: "NumericalScaleScorer";
                      readonly minValue: number;
                      readonly maxValue: number;
                      readonly minInterpretation: string | null;
                      readonly maxInterpretation: string | null;
                      readonly minInterpretationShort: string | null;
                      readonly maxInterpretationShort: string | null;
                      readonly domain: ScaleScorerDomain;
                      readonly thresholdData: ReadonlyArray<{
                        readonly __typename: "ScaleThreshold";
                        readonly mnemonic: string;
                        readonly thresholdClass: ScaleThresholdClass;
                        readonly minValue: number;
                        readonly maxValue: number;
                      }> | null;
                    }
                  | { readonly __typename: "UnscoredScaleScorer"; readonly domain: ScaleScorerDomain };
                readonly latest: {
                  readonly __typename: "CareEpisodeComputedValue";
                  readonly id: Ids.CareEpisodeComputedValueId;
                  readonly value: number | null;
                  readonly thresholdClass: ScaleThresholdClass | null;
                  readonly thresholdMnemonic: string | null;
                  readonly effectiveDate: Date | null;
                  readonly targetDate: Date;
                  readonly date: Date;
                  readonly validity: ComputedValueValidity;
                  readonly acuteChangeCategory: AcuteChangeCategory;
                  readonly clinicalCategory: ClinicalCategory;
                  readonly trend: Trend;
                  readonly severityCategory: SeverityCategory;
                  readonly scoringMechanism: CalculationMechanism;
                  readonly responsesAvailable: ResponsesAvailable;
                  readonly answers: ReadonlyArray<{
                    readonly __typename: "Answer";
                    readonly id: Ids.AnswerId;
                    readonly value: string | null;
                    readonly questionId: Ids.QuestionId;
                    readonly normalizedScore: NormalizedScore | null;
                    readonly endorsed: boolean | null;
                    readonly itemCoding: ItemCoding | null;
                    readonly option: {
                      readonly __typename: "QuestionOption";
                      readonly title: string;
                      readonly value: string;
                      readonly reportText: string | null;
                    } | null;
                  }>;
                  readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
                  readonly scorer:
                    | { readonly __typename: "CategoricalScaleScorer" }
                    | { readonly __typename: "NumericalScaleScorer" }
                    | { readonly __typename: "UnscoredScaleScorer" };
                  readonly mostRelevantInvitation: {
                    readonly __typename: "Invitation";
                    readonly id: Ids.InvitationId;
                    readonly dateCompleted: Date | null;
                    readonly dateBegun: Date | null;
                  } | null;
                };
                readonly history: ReadonlyArray<{
                  readonly __typename: "CareEpisodeComputedValue";
                  readonly id: Ids.CareEpisodeComputedValueId;
                  readonly value: number | null;
                  readonly thresholdClass: ScaleThresholdClass | null;
                  readonly thresholdMnemonic: string | null;
                  readonly effectiveDate: Date | null;
                  readonly targetDate: Date;
                  readonly date: Date;
                  readonly validity: ComputedValueValidity;
                  readonly acuteChangeCategory: AcuteChangeCategory;
                  readonly clinicalCategory: ClinicalCategory;
                  readonly trend: Trend;
                  readonly severityCategory: SeverityCategory;
                  readonly scoringMechanism: CalculationMechanism;
                  readonly responsesAvailable: ResponsesAvailable;
                  readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
                  readonly scorer:
                    | { readonly __typename: "CategoricalScaleScorer" }
                    | { readonly __typename: "NumericalScaleScorer" }
                    | { readonly __typename: "UnscoredScaleScorer" };
                  readonly mostRelevantInvitation: {
                    readonly __typename: "Invitation";
                    readonly id: Ids.InvitationId;
                    readonly dateCompleted: Date | null;
                    readonly dateBegun: Date | null;
                  } | null;
                }>;
              }>;
            }>;
          }>;
        }
      | {
          readonly __typename: "CareEpisodeScaleResult";
          readonly scale: {
            readonly __typename: "AdministrableScale";
            readonly id: Ids.ScaleId;
            readonly mnemonic: string;
            readonly parentId: Ids.ScaleId | null;
            readonly name: string;
            readonly shortname: string | null;
            readonly nanoname: string | null;
            readonly friendlyName: string | null;
            readonly measureType: ScaleMeasureType;
            readonly reportShortDescription: string | null;
            readonly role: ScaleRole | null;
            readonly questions: ReadonlyArray<{
              readonly __typename: "Question";
              readonly id: Ids.QuestionId;
              readonly title: string;
              readonly reportText: string;
              readonly questionType: QuestionType;
            }>;
          };
          readonly byUser: ReadonlyArray<{
            readonly __typename: "CareEpisodeScaleByUser";
            readonly user: {
              readonly __typename: "User";
              readonly id: Ids.UserId;
              readonly name: string;
            } | null;
            readonly byScorer: ReadonlyArray<{
              readonly __typename: "CareEpisodeScaleByUserByScorer";
              readonly scorer:
                | { readonly __typename: "CategoricalScaleScorer"; readonly domain: ScaleScorerDomain }
                | {
                    readonly __typename: "NumericalScaleScorer";
                    readonly minValue: number;
                    readonly maxValue: number;
                    readonly minInterpretation: string | null;
                    readonly maxInterpretation: string | null;
                    readonly minInterpretationShort: string | null;
                    readonly maxInterpretationShort: string | null;
                    readonly domain: ScaleScorerDomain;
                    readonly thresholdData: ReadonlyArray<{
                      readonly __typename: "ScaleThreshold";
                      readonly mnemonic: string;
                      readonly thresholdClass: ScaleThresholdClass;
                      readonly minValue: number;
                      readonly maxValue: number;
                    }> | null;
                  }
                | { readonly __typename: "UnscoredScaleScorer"; readonly domain: ScaleScorerDomain };
              readonly latest: {
                readonly __typename: "CareEpisodeComputedValue";
                readonly id: Ids.CareEpisodeComputedValueId;
                readonly value: number | null;
                readonly thresholdClass: ScaleThresholdClass | null;
                readonly thresholdMnemonic: string | null;
                readonly effectiveDate: Date | null;
                readonly targetDate: Date;
                readonly date: Date;
                readonly validity: ComputedValueValidity;
                readonly acuteChangeCategory: AcuteChangeCategory;
                readonly clinicalCategory: ClinicalCategory;
                readonly trend: Trend;
                readonly severityCategory: SeverityCategory;
                readonly scoringMechanism: CalculationMechanism;
                readonly responsesAvailable: ResponsesAvailable;
                readonly answers: ReadonlyArray<{
                  readonly __typename: "Answer";
                  readonly id: Ids.AnswerId;
                  readonly value: string | null;
                  readonly questionId: Ids.QuestionId;
                  readonly normalizedScore: NormalizedScore | null;
                  readonly endorsed: boolean | null;
                  readonly itemCoding: ItemCoding | null;
                  readonly option: {
                    readonly __typename: "QuestionOption";
                    readonly title: string;
                    readonly value: string;
                    readonly reportText: string | null;
                  } | null;
                }>;
                readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
                readonly scorer:
                  | { readonly __typename: "CategoricalScaleScorer" }
                  | { readonly __typename: "NumericalScaleScorer" }
                  | { readonly __typename: "UnscoredScaleScorer" };
                readonly mostRelevantInvitation: {
                  readonly __typename: "Invitation";
                  readonly id: Ids.InvitationId;
                  readonly dateCompleted: Date | null;
                  readonly dateBegun: Date | null;
                } | null;
              };
              readonly history: ReadonlyArray<{
                readonly __typename: "CareEpisodeComputedValue";
                readonly id: Ids.CareEpisodeComputedValueId;
                readonly value: number | null;
                readonly thresholdClass: ScaleThresholdClass | null;
                readonly thresholdMnemonic: string | null;
                readonly effectiveDate: Date | null;
                readonly targetDate: Date;
                readonly date: Date;
                readonly validity: ComputedValueValidity;
                readonly acuteChangeCategory: AcuteChangeCategory;
                readonly clinicalCategory: ClinicalCategory;
                readonly trend: Trend;
                readonly severityCategory: SeverityCategory;
                readonly scoringMechanism: CalculationMechanism;
                readonly responsesAvailable: ResponsesAvailable;
                readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
                readonly scorer:
                  | { readonly __typename: "CategoricalScaleScorer" }
                  | { readonly __typename: "NumericalScaleScorer" }
                  | { readonly __typename: "UnscoredScaleScorer" };
                readonly mostRelevantInvitation: {
                  readonly __typename: "Invitation";
                  readonly id: Ids.InvitationId;
                  readonly dateCompleted: Date | null;
                  readonly dateBegun: Date | null;
                } | null;
              }>;
            }>;
          }>;
        }
    >;
  } | null;
};

export type ScaleCompletionQueryQueryVariables = Exact<{
  scaleCompletionId: Scalars["CareEpisodeComputedValueId"]["input"];
}>;

export type ScaleCompletionQueryQuery = {
  readonly __typename: "Query";
  readonly assessmentCareEpisodeComputedValue: {
    readonly __typename: "CareEpisodeComputedValue";
    readonly id: Ids.CareEpisodeComputedValueId;
    readonly value: number | null;
    readonly thresholdClass: ScaleThresholdClass | null;
    readonly thresholdMnemonic: string | null;
    readonly effectiveDate: Date | null;
    readonly targetDate: Date;
    readonly date: Date;
    readonly validity: ComputedValueValidity;
    readonly acuteChangeCategory: AcuteChangeCategory;
    readonly clinicalCategory: ClinicalCategory;
    readonly trend: Trend;
    readonly severityCategory: SeverityCategory;
    readonly scoringMechanism: CalculationMechanism;
    readonly responsesAvailable: ResponsesAvailable;
    readonly scale:
      | {
          readonly __typename: "AdministrableScale";
          readonly parentId: Ids.ScaleId | null;
          readonly mnemonic: string;
          readonly id: Ids.ScaleId;
          readonly name: string;
          readonly shortname: string | null;
          readonly nanoname: string | null;
          readonly friendlyName: string | null;
          readonly measureType: ScaleMeasureType;
          readonly reportShortDescription: string | null;
          readonly role: ScaleRole | null;
          readonly questions: ReadonlyArray<{
            readonly __typename: "Question";
            readonly id: Ids.QuestionId;
            readonly title: string;
            readonly questionType: QuestionType;
            readonly reportText: string;
          }>;
        }
      | {
          readonly __typename: "Subscale";
          readonly parentId: Ids.ScaleId | null;
          readonly mnemonic: string;
          readonly id: Ids.ScaleId;
          readonly name: string;
          readonly shortname: string | null;
          readonly nanoname: string | null;
          readonly friendlyName: string | null;
          readonly measureType: ScaleMeasureType;
          readonly reportShortDescription: string | null;
          readonly role: ScaleRole | null;
          readonly questions: ReadonlyArray<{
            readonly __typename: "Question";
            readonly id: Ids.QuestionId;
            readonly title: string;
            readonly questionType: QuestionType;
            readonly reportText: string;
          }>;
        };
    readonly answers: ReadonlyArray<{
      readonly __typename: "Answer";
      readonly id: Ids.AnswerId;
      readonly value: string | null;
      readonly questionId: Ids.QuestionId;
      readonly normalizedScore: NormalizedScore | null;
      readonly endorsed: boolean | null;
      readonly itemCoding: ItemCoding | null;
      readonly option: {
        readonly __typename: "QuestionOption";
        readonly title: string;
        readonly value: string;
        readonly reportText: string | null;
      } | null;
    }>;
    readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
    readonly scorer:
      | { readonly __typename: "CategoricalScaleScorer" }
      | { readonly __typename: "NumericalScaleScorer" }
      | { readonly __typename: "UnscoredScaleScorer" };
    readonly mostRelevantInvitation: {
      readonly __typename: "Invitation";
      readonly id: Ids.InvitationId;
      readonly dateCompleted: Date | null;
      readonly dateBegun: Date | null;
    } | null;
  } | null;
};

export type ScaleGroupHistoryQueryQueryVariables = Exact<{
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
  scaleGroupId: Scalars["ScaleGroupId"]["input"];
}>;

export type ScaleGroupHistoryQueryQuery = {
  readonly __typename: "Query";
  readonly assessmentCareEpisodeScaleGroupDetail: {
    readonly __typename: "CareEpisodeScaleGroupDetail";
    readonly careEpisodeScaleGroupResult: {
      readonly __typename: "CareEpisodeScaleGroupResult";
      readonly scaleGroup: {
        readonly __typename: "ScaleGroup";
        readonly id: Ids.ScaleGroupId;
        readonly name: string;
        readonly description: string | null;
        readonly shortname: string;
        readonly displayMode: ScaleGroupDisplayDataType;
      };
      readonly byScale: ReadonlyArray<{
        readonly __typename: "CareEpisodeScaleResult";
        readonly scale: {
          readonly __typename: "AdministrableScale";
          readonly id: Ids.ScaleId;
          readonly mnemonic: string;
          readonly parentId: Ids.ScaleId | null;
          readonly name: string;
          readonly shortname: string | null;
          readonly nanoname: string | null;
          readonly friendlyName: string | null;
          readonly measureType: ScaleMeasureType;
          readonly role: ScaleRole | null;
          readonly reportShortDescription: string | null;
          readonly questions: ReadonlyArray<{
            readonly __typename: "Question";
            readonly id: Ids.QuestionId;
            readonly title: string;
            readonly reportText: string;
            readonly questionType: QuestionType;
          }>;
        };
        readonly byUser: ReadonlyArray<{
          readonly __typename: "CareEpisodeScaleByUser";
          readonly user: {
            readonly __typename: "User";
            readonly id: Ids.UserId;
            readonly name: string;
          } | null;
          readonly byScorer: ReadonlyArray<{
            readonly __typename: "CareEpisodeScaleByUserByScorer";
            readonly scorer:
              | { readonly __typename: "CategoricalScaleScorer"; readonly domain: ScaleScorerDomain }
              | {
                  readonly __typename: "NumericalScaleScorer";
                  readonly minValue: number;
                  readonly maxValue: number;
                  readonly minInterpretation: string | null;
                  readonly maxInterpretation: string | null;
                  readonly minInterpretationShort: string | null;
                  readonly maxInterpretationShort: string | null;
                  readonly domain: ScaleScorerDomain;
                  readonly thresholdData: ReadonlyArray<{
                    readonly __typename: "ScaleThreshold";
                    readonly mnemonic: string;
                    readonly thresholdClass: ScaleThresholdClass;
                    readonly minValue: number;
                    readonly maxValue: number;
                  }> | null;
                }
              | { readonly __typename: "UnscoredScaleScorer"; readonly domain: ScaleScorerDomain };
            readonly latest: {
              readonly __typename: "CareEpisodeComputedValue";
              readonly id: Ids.CareEpisodeComputedValueId;
              readonly value: number | null;
              readonly thresholdClass: ScaleThresholdClass | null;
              readonly thresholdMnemonic: string | null;
              readonly effectiveDate: Date | null;
              readonly targetDate: Date;
              readonly date: Date;
              readonly validity: ComputedValueValidity;
              readonly acuteChangeCategory: AcuteChangeCategory;
              readonly clinicalCategory: ClinicalCategory;
              readonly trend: Trend;
              readonly severityCategory: SeverityCategory;
              readonly scoringMechanism: CalculationMechanism;
              readonly responsesAvailable: ResponsesAvailable;
              readonly answers: ReadonlyArray<{
                readonly __typename: "Answer";
                readonly id: Ids.AnswerId;
                readonly value: string | null;
                readonly questionId: Ids.QuestionId;
                readonly normalizedScore: NormalizedScore | null;
                readonly endorsed: boolean | null;
                readonly itemCoding: ItemCoding | null;
                readonly option: {
                  readonly __typename: "QuestionOption";
                  readonly title: string;
                  readonly value: string;
                  readonly reportText: string | null;
                } | null;
              }>;
              readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
              readonly scorer:
                | { readonly __typename: "CategoricalScaleScorer" }
                | { readonly __typename: "NumericalScaleScorer" }
                | { readonly __typename: "UnscoredScaleScorer" };
              readonly mostRelevantInvitation: {
                readonly __typename: "Invitation";
                readonly id: Ids.InvitationId;
                readonly dateCompleted: Date | null;
                readonly dateBegun: Date | null;
              } | null;
            };
            readonly history: ReadonlyArray<{
              readonly __typename: "CareEpisodeComputedValue";
              readonly id: Ids.CareEpisodeComputedValueId;
              readonly value: number | null;
              readonly thresholdClass: ScaleThresholdClass | null;
              readonly thresholdMnemonic: string | null;
              readonly effectiveDate: Date | null;
              readonly targetDate: Date;
              readonly date: Date;
              readonly validity: ComputedValueValidity;
              readonly acuteChangeCategory: AcuteChangeCategory;
              readonly clinicalCategory: ClinicalCategory;
              readonly trend: Trend;
              readonly severityCategory: SeverityCategory;
              readonly scoringMechanism: CalculationMechanism;
              readonly responsesAvailable: ResponsesAvailable;
              readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
              readonly scorer:
                | { readonly __typename: "CategoricalScaleScorer" }
                | { readonly __typename: "NumericalScaleScorer" }
                | { readonly __typename: "UnscoredScaleScorer" };
              readonly mostRelevantInvitation: {
                readonly __typename: "Invitation";
                readonly id: Ids.InvitationId;
                readonly dateCompleted: Date | null;
                readonly dateBegun: Date | null;
              } | null;
            }>;
          }>;
        }>;
      }>;
    };
  } | null;
};

export type ReportInvitationsFragmentFragment = {
  readonly __typename: "CareEpisodeComputedValue";
  readonly report: {
    readonly __typename: "Report";
    readonly invitations: ReadonlyArray<{
      readonly __typename: "Invitation";
      readonly id: Ids.InvitationId;
      readonly user: { readonly __typename: "User"; readonly id: Ids.UserId };
      readonly reportScales: ReadonlyArray<{
        readonly __typename: "ReportScale";
        readonly scale:
          | { readonly __typename: "AdministrableScale"; readonly id: Ids.ScaleId }
          | { readonly __typename: "Subscale"; readonly id: Ids.ScaleId };
      }>;
    }>;
  } | null;
};

export type CareEpisodeComputedValueWithScaleAndAnswersFragment = {
  readonly __typename: "CareEpisodeComputedValue";
  readonly id: Ids.CareEpisodeComputedValueId;
  readonly value: number | null;
  readonly thresholdClass: ScaleThresholdClass | null;
  readonly thresholdMnemonic: string | null;
  readonly effectiveDate: Date | null;
  readonly targetDate: Date;
  readonly date: Date;
  readonly validity: ComputedValueValidity;
  readonly acuteChangeCategory: AcuteChangeCategory;
  readonly clinicalCategory: ClinicalCategory;
  readonly trend: Trend;
  readonly severityCategory: SeverityCategory;
  readonly scoringMechanism: CalculationMechanism;
  readonly responsesAvailable: ResponsesAvailable;
  readonly user: { readonly __typename: "User"; readonly name: string; readonly id: Ids.UserId } | null;
  readonly scale:
    | {
        readonly __typename: "AdministrableScale";
        readonly id: Ids.ScaleId;
        readonly mnemonic: string;
        readonly parentId: Ids.ScaleId | null;
        readonly name: string;
        readonly shortname: string | null;
        readonly nanoname: string | null;
        readonly friendlyName: string | null;
        readonly measureType: ScaleMeasureType;
        readonly role: ScaleRole | null;
        readonly reportShortDescription: string | null;
        readonly questions: ReadonlyArray<{
          readonly __typename: "Question";
          readonly id: Ids.QuestionId;
          readonly title: string;
          readonly reportText: string;
          readonly questionType: QuestionType;
        }>;
      }
    | {
        readonly __typename: "Subscale";
        readonly id: Ids.ScaleId;
        readonly mnemonic: string;
        readonly parentId: Ids.ScaleId | null;
        readonly name: string;
        readonly shortname: string | null;
        readonly nanoname: string | null;
        readonly friendlyName: string | null;
        readonly measureType: ScaleMeasureType;
        readonly role: ScaleRole | null;
        readonly reportShortDescription: string | null;
        readonly questions: ReadonlyArray<{
          readonly __typename: "Question";
          readonly id: Ids.QuestionId;
          readonly title: string;
          readonly reportText: string;
          readonly questionType: QuestionType;
        }>;
      };
  readonly answers: ReadonlyArray<{
    readonly __typename: "Answer";
    readonly id: Ids.AnswerId;
    readonly value: string | null;
    readonly questionId: Ids.QuestionId;
    readonly normalizedScore: NormalizedScore | null;
    readonly itemCoding: ItemCoding | null;
    readonly endorsed: boolean | null;
    readonly option: {
      readonly __typename: "QuestionOption";
      readonly title: string;
      readonly value: string;
      readonly reportText: string | null;
    } | null;
    readonly question: {
      readonly __typename: "Question";
      readonly id: Ids.QuestionId;
      readonly title: string;
      readonly reportText: string;
      readonly questionType: QuestionType;
    };
    readonly user: {
      readonly __typename: "User";
      readonly id: Ids.UserId;
      readonly name: string;
      readonly firstName: string | null;
    };
  }>;
  readonly mostRelevantInvitation: {
    readonly __typename: "Invitation";
    readonly id: Ids.InvitationId;
    readonly dateCompleted: Date | null;
    readonly dateBegun: Date | null;
  } | null;
  readonly scorer:
    | { readonly __typename: "CategoricalScaleScorer" }
    | { readonly __typename: "NumericalScaleScorer" }
    | { readonly __typename: "UnscoredScaleScorer" };
  readonly report: {
    readonly __typename: "Report";
    readonly invitations: ReadonlyArray<{
      readonly __typename: "Invitation";
      readonly id: Ids.InvitationId;
      readonly user: { readonly __typename: "User"; readonly id: Ids.UserId };
      readonly reportScales: ReadonlyArray<{
        readonly __typename: "ReportScale";
        readonly scale:
          | { readonly __typename: "AdministrableScale"; readonly id: Ids.ScaleId }
          | { readonly __typename: "Subscale"; readonly id: Ids.ScaleId };
      }>;
    }>;
  } | null;
};

export type ScaleScoreQueryQueryVariables = Exact<{
  careEpisodeComputedValueId: Scalars["CareEpisodeComputedValueId"]["input"];
}>;

export type ScaleScoreQueryQuery = {
  readonly __typename: "Query";
  readonly assessmentCareEpisodeComputedValue: {
    readonly __typename: "CareEpisodeComputedValue";
    readonly id: Ids.CareEpisodeComputedValueId;
    readonly value: number | null;
    readonly thresholdClass: ScaleThresholdClass | null;
    readonly thresholdMnemonic: string | null;
    readonly effectiveDate: Date | null;
    readonly targetDate: Date;
    readonly date: Date;
    readonly validity: ComputedValueValidity;
    readonly acuteChangeCategory: AcuteChangeCategory;
    readonly clinicalCategory: ClinicalCategory;
    readonly trend: Trend;
    readonly severityCategory: SeverityCategory;
    readonly scoringMechanism: CalculationMechanism;
    readonly responsesAvailable: ResponsesAvailable;
    readonly user: { readonly __typename: "User"; readonly name: string; readonly id: Ids.UserId } | null;
    readonly scale:
      | {
          readonly __typename: "AdministrableScale";
          readonly id: Ids.ScaleId;
          readonly mnemonic: string;
          readonly parentId: Ids.ScaleId | null;
          readonly name: string;
          readonly shortname: string | null;
          readonly nanoname: string | null;
          readonly friendlyName: string | null;
          readonly measureType: ScaleMeasureType;
          readonly role: ScaleRole | null;
          readonly reportShortDescription: string | null;
          readonly questions: ReadonlyArray<{
            readonly __typename: "Question";
            readonly id: Ids.QuestionId;
            readonly title: string;
            readonly reportText: string;
            readonly questionType: QuestionType;
          }>;
        }
      | {
          readonly __typename: "Subscale";
          readonly id: Ids.ScaleId;
          readonly mnemonic: string;
          readonly parentId: Ids.ScaleId | null;
          readonly name: string;
          readonly shortname: string | null;
          readonly nanoname: string | null;
          readonly friendlyName: string | null;
          readonly measureType: ScaleMeasureType;
          readonly role: ScaleRole | null;
          readonly reportShortDescription: string | null;
          readonly questions: ReadonlyArray<{
            readonly __typename: "Question";
            readonly id: Ids.QuestionId;
            readonly title: string;
            readonly reportText: string;
            readonly questionType: QuestionType;
          }>;
        };
    readonly answers: ReadonlyArray<{
      readonly __typename: "Answer";
      readonly id: Ids.AnswerId;
      readonly value: string | null;
      readonly questionId: Ids.QuestionId;
      readonly normalizedScore: NormalizedScore | null;
      readonly itemCoding: ItemCoding | null;
      readonly endorsed: boolean | null;
      readonly option: {
        readonly __typename: "QuestionOption";
        readonly title: string;
        readonly value: string;
        readonly reportText: string | null;
      } | null;
      readonly question: {
        readonly __typename: "Question";
        readonly id: Ids.QuestionId;
        readonly title: string;
        readonly reportText: string;
        readonly questionType: QuestionType;
      };
      readonly user: {
        readonly __typename: "User";
        readonly id: Ids.UserId;
        readonly name: string;
        readonly firstName: string | null;
      };
    }>;
    readonly mostRelevantInvitation: {
      readonly __typename: "Invitation";
      readonly id: Ids.InvitationId;
      readonly dateCompleted: Date | null;
      readonly dateBegun: Date | null;
    } | null;
    readonly scorer:
      | { readonly __typename: "CategoricalScaleScorer" }
      | { readonly __typename: "NumericalScaleScorer" }
      | { readonly __typename: "UnscoredScaleScorer" };
    readonly report: {
      readonly __typename: "Report";
      readonly invitations: ReadonlyArray<{
        readonly __typename: "Invitation";
        readonly id: Ids.InvitationId;
        readonly user: { readonly __typename: "User"; readonly id: Ids.UserId };
        readonly reportScales: ReadonlyArray<{
          readonly __typename: "ReportScale";
          readonly scale:
            | { readonly __typename: "AdministrableScale"; readonly id: Ids.ScaleId }
            | { readonly __typename: "Subscale"; readonly id: Ids.ScaleId };
        }>;
      }>;
    } | null;
  } | null;
};

export type ScaleScorerHistoryQueryVariables = Exact<{
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
  scaleId: Scalars["ScaleId"]["input"];
}>;

export type ScaleScorerHistoryQuery = {
  readonly __typename: "Query";
  readonly assessmentCareEpisodeScaleDetail: {
    readonly __typename: "CareEpisodeScaleDetail";
    readonly careEpisodeScaleResult: {
      readonly __typename: "CareEpisodeScaleResult";
      readonly scale: {
        readonly __typename: "AdministrableScale";
        readonly id: Ids.ScaleId;
        readonly mnemonic: string;
        readonly parentId: Ids.ScaleId | null;
        readonly name: string;
        readonly shortname: string | null;
        readonly nanoname: string | null;
        readonly friendlyName: string | null;
        readonly measureType: ScaleMeasureType;
        readonly reportShortDescription: string | null;
        readonly role: ScaleRole | null;
        readonly questions: ReadonlyArray<{
          readonly __typename: "Question";
          readonly id: Ids.QuestionId;
          readonly title: string;
          readonly reportText: string;
          readonly questionType: QuestionType;
        }>;
      };
      readonly byUser: ReadonlyArray<{
        readonly __typename: "CareEpisodeScaleByUser";
        readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string } | null;
        readonly byScorer: ReadonlyArray<{
          readonly __typename: "CareEpisodeScaleByUserByScorer";
          readonly scorer:
            | { readonly __typename: "CategoricalScaleScorer"; readonly domain: ScaleScorerDomain }
            | {
                readonly __typename: "NumericalScaleScorer";
                readonly minValue: number;
                readonly maxValue: number;
                readonly minInterpretation: string | null;
                readonly maxInterpretation: string | null;
                readonly minInterpretationShort: string | null;
                readonly maxInterpretationShort: string | null;
                readonly domain: ScaleScorerDomain;
                readonly thresholdData: ReadonlyArray<{
                  readonly __typename: "ScaleThreshold";
                  readonly mnemonic: string;
                  readonly thresholdClass: ScaleThresholdClass;
                  readonly minValue: number;
                  readonly maxValue: number;
                }> | null;
              }
            | { readonly __typename: "UnscoredScaleScorer"; readonly domain: ScaleScorerDomain };
          readonly latest: {
            readonly __typename: "CareEpisodeComputedValue";
            readonly id: Ids.CareEpisodeComputedValueId;
            readonly value: number | null;
            readonly thresholdClass: ScaleThresholdClass | null;
            readonly thresholdMnemonic: string | null;
            readonly effectiveDate: Date | null;
            readonly targetDate: Date;
            readonly date: Date;
            readonly validity: ComputedValueValidity;
            readonly acuteChangeCategory: AcuteChangeCategory;
            readonly clinicalCategory: ClinicalCategory;
            readonly trend: Trend;
            readonly severityCategory: SeverityCategory;
            readonly scoringMechanism: CalculationMechanism;
            readonly responsesAvailable: ResponsesAvailable;
            readonly answers: ReadonlyArray<{
              readonly __typename: "Answer";
              readonly id: Ids.AnswerId;
              readonly value: string | null;
              readonly questionId: Ids.QuestionId;
              readonly normalizedScore: NormalizedScore | null;
              readonly endorsed: boolean | null;
              readonly itemCoding: ItemCoding | null;
              readonly option: {
                readonly __typename: "QuestionOption";
                readonly title: string;
                readonly value: string;
                readonly reportText: string | null;
              } | null;
            }>;
            readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
            readonly scorer:
              | { readonly __typename: "CategoricalScaleScorer" }
              | { readonly __typename: "NumericalScaleScorer" }
              | { readonly __typename: "UnscoredScaleScorer" };
            readonly mostRelevantInvitation: {
              readonly __typename: "Invitation";
              readonly id: Ids.InvitationId;
              readonly dateCompleted: Date | null;
              readonly dateBegun: Date | null;
            } | null;
          };
          readonly history: ReadonlyArray<{
            readonly __typename: "CareEpisodeComputedValue";
            readonly id: Ids.CareEpisodeComputedValueId;
            readonly value: number | null;
            readonly thresholdClass: ScaleThresholdClass | null;
            readonly thresholdMnemonic: string | null;
            readonly effectiveDate: Date | null;
            readonly targetDate: Date;
            readonly date: Date;
            readonly validity: ComputedValueValidity;
            readonly acuteChangeCategory: AcuteChangeCategory;
            readonly clinicalCategory: ClinicalCategory;
            readonly trend: Trend;
            readonly severityCategory: SeverityCategory;
            readonly scoringMechanism: CalculationMechanism;
            readonly responsesAvailable: ResponsesAvailable;
            readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
            readonly scorer:
              | { readonly __typename: "CategoricalScaleScorer" }
              | { readonly __typename: "NumericalScaleScorer" }
              | { readonly __typename: "UnscoredScaleScorer" };
            readonly mostRelevantInvitation: {
              readonly __typename: "Invitation";
              readonly id: Ids.InvitationId;
              readonly dateCompleted: Date | null;
              readonly dateBegun: Date | null;
            } | null;
          }>;
        }>;
      }>;
    };
  } | null;
};

export type UpdateGoalMutationMutationVariables = Exact<{
  goal: UpdateGoalInput;
}>;

export type UpdateGoalMutationMutation = {
  readonly __typename: "Mutation";
  readonly assessmentUpdateGoal: {
    readonly __typename: "UpdateGoalPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateGoalResult";
      readonly goal: {
        readonly __typename: "Goal";
        readonly id: Ids.GoalId;
        readonly goalType: GoalType;
        readonly status: GoalStatus;
        readonly startDate: Date;
        readonly endDate: Date | null;
        readonly isBeneficial: boolean;
        readonly patientText: string;
        readonly user: {
          readonly __typename: "User";
          readonly id: Ids.UserId;
          readonly name: string;
          readonly firstName: string | null;
        };
        readonly thresholdData: ReadonlyArray<{
          readonly __typename: "ScaleThreshold";
          readonly mnemonic: string;
          readonly thresholdClass: ScaleThresholdClass;
          readonly maxValue: number;
          readonly minValue: number;
        }>;
        readonly goalAnswers: ReadonlyArray<{
          readonly __typename: "GoalAnswer";
          readonly id: Ids.GoalAnswerId;
          readonly answerDate: Date;
          readonly targetDate: Date;
          readonly effectiveDate: Date;
          readonly value: number | null;
        }>;
      };
    } | null;
  } | null;
};

export type UpdateGoalStatusMutationVariables = Exact<{
  input: UpdateGoalInput;
}>;

export type UpdateGoalStatusMutation = {
  readonly __typename: "Mutation";
  readonly assessmentUpdateGoal: {
    readonly __typename: "UpdateGoalPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateGoalResult";
      readonly goal: {
        readonly __typename: "Goal";
        readonly id: Ids.GoalId;
        readonly goalType: GoalType;
        readonly status: GoalStatus;
        readonly startDate: Date;
        readonly endDate: Date | null;
        readonly isBeneficial: boolean;
        readonly patientText: string;
        readonly user: {
          readonly __typename: "User";
          readonly id: Ids.UserId;
          readonly name: string;
          readonly firstName: string | null;
        };
        readonly thresholdData: ReadonlyArray<{
          readonly __typename: "ScaleThreshold";
          readonly mnemonic: string;
          readonly thresholdClass: ScaleThresholdClass;
          readonly maxValue: number;
          readonly minValue: number;
        }>;
        readonly goalAnswers: ReadonlyArray<{
          readonly __typename: "GoalAnswer";
          readonly id: Ids.GoalAnswerId;
          readonly answerDate: Date;
          readonly targetDate: Date;
          readonly effectiveDate: Date;
          readonly value: number | null;
        }>;
      };
    } | null;
  } | null;
};

export type FeedbackReportProgressNoteTextQueryVariables = Exact<{
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
}>;

export type FeedbackReportProgressNoteTextQuery = {
  readonly __typename: "Query";
  readonly assessmentLatestReportText: {
    readonly __typename: "ReportText";
    readonly plainText: string;
  } | null;
};

export type CreateGoalMutationMutationVariables = Exact<{
  goal: CreateGoalInput;
}>;

export type CreateGoalMutationMutation = {
  readonly __typename: "Mutation";
  readonly assessmentCreateGoal: {
    readonly __typename: "CreateGoalPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateGoalResult";
      readonly goal: {
        readonly __typename: "Goal";
        readonly id: Ids.GoalId;
        readonly goalType: GoalType;
        readonly status: GoalStatus;
        readonly startDate: Date;
        readonly endDate: Date | null;
        readonly isBeneficial: boolean;
        readonly patientText: string;
        readonly user: {
          readonly __typename: "User";
          readonly id: Ids.UserId;
          readonly name: string;
          readonly firstName: string | null;
        };
        readonly thresholdData: ReadonlyArray<{
          readonly __typename: "ScaleThreshold";
          readonly mnemonic: string;
          readonly thresholdClass: ScaleThresholdClass;
          readonly maxValue: number;
          readonly minValue: number;
        }>;
        readonly goalAnswers: ReadonlyArray<{
          readonly __typename: "GoalAnswer";
          readonly id: Ids.GoalAnswerId;
          readonly answerDate: Date;
          readonly targetDate: Date;
          readonly effectiveDate: Date;
          readonly value: number | null;
        }>;
      };
    } | null;
  } | null;
};

export type ReportRedirectQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
}>;

export type ReportRedirectQuery = {
  readonly __typename: "Query";
  readonly patient: {
    readonly __typename: "Patient";
    readonly latestCareEpisode: { readonly __typename: "CareEpisode"; readonly id: Ids.CareEpisodeId } | null;
  } | null;
};

export type CreateImplementationTargetMutationVariables = Exact<{
  input: CreateImplementationTargetInput;
}>;

export type CreateImplementationTargetMutation = {
  readonly __typename: "Mutation";
  readonly implementationCreateImplementationTarget: {
    readonly __typename: "CreateImplementationTargetPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateImplementationTargetResult";
      readonly implementationTarget: {
        readonly __typename: "ImplementationTarget";
        readonly id: Ids.ImplementationTargetId;
        readonly status: ImplementationTargetStatus;
        readonly name: string;
        readonly mirahInternal: boolean;
        readonly description: string | null;
        readonly targetType: ImplementationTargetType;
        readonly entityTreeNode: {
          readonly __typename: "EntityTreeNode";
          readonly path: string;
          readonly minAge: number | null;
          readonly maxAge: number | null;
          readonly entity: {
            readonly __typename: "Entity";
            readonly id: Ids.EntityId;
            readonly name: string;
            readonly shortname: string;
            readonly entityType: EntityType;
          };
          readonly segments: ReadonlyArray<{
            readonly __typename: "EntityTreeSegment";
            readonly id: Ids.EntityId;
            readonly path: string;
            readonly entityType: EntityType;
            readonly name: string;
            readonly shortname: string;
            readonly rootPath: string;
          }>;
        };
      };
    } | null;
  } | null;
};

export type EmailImplementationTargetReportMutationVariables = Exact<{
  input: EmailImplementationTargetReportInput;
}>;

export type EmailImplementationTargetReportMutation = {
  readonly __typename: "Mutation";
  readonly implementationEmailImplementationTargetReport: {
    readonly __typename: "EmailImplementationTargetReportPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EmailImplementationTargetReportResult";
      readonly sentToEmails: ReadonlyArray<string>;
    } | null;
  } | null;
};

export type ImplementationTargetDashboardQueryVariables = Exact<{
  entityQuery: InputMaybe<EntityTreeNodeQueryParams>;
  availableForNode: InputMaybe<EntityTreeNodeParams>;
  targetType: InputMaybe<ReadonlyArray<ImplementationTargetType> | ImplementationTargetType>;
  date: Scalars["ISO8601Date"]["input"];
  status: ReadonlyArray<ImplementationTargetStatus> | ImplementationTargetStatus;
  hideProviderInformation: InputMaybe<Scalars["Boolean"]["input"]>;
  includeMirahInternal: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ImplementationTargetDashboardQuery = {
  readonly __typename: "Query";
  readonly implementationTargets: {
    readonly __typename: "ImplementationTargetConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "ImplementationTarget";
      readonly id: Ids.ImplementationTargetId;
      readonly name: string;
      readonly description: string | null;
      readonly displayOrder: number | null;
      readonly targetType: ImplementationTargetType;
      readonly startDate: Date;
      readonly initialCredits: number | null;
      readonly softLaunchDate: Date | null;
      readonly target: number | null;
      readonly mirahInternal: boolean;
      readonly entityTreeNode: {
        readonly __typename: "EntityTreeNode";
        readonly path: string;
        readonly minAge: number | null;
        readonly maxAge: number | null;
        readonly entity: {
          readonly __typename: "Entity";
          readonly id: Ids.EntityId;
          readonly name: string;
          readonly shortname: string;
          readonly entityType: EntityType;
        };
        readonly segments: ReadonlyArray<{
          readonly __typename: "EntityTreeSegment";
          readonly id: Ids.EntityId;
          readonly path: string;
          readonly entityType: EntityType;
          readonly name: string;
          readonly shortname: string;
          readonly rootPath: string;
        }>;
      };
      readonly closestReportDateTo: {
        readonly __typename: "ImplementationTargetReportDate";
        readonly endDate: Date;
        readonly period: ImplementationTargetReportPeriod;
      } | null;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type ImplementationTargetDetailsQueryVariables = Exact<{
  implementationTargetId: Scalars["ImplementationTargetId"]["input"];
}>;

export type ImplementationTargetDetailsQuery = {
  readonly __typename: "Query";
  readonly implementationTarget: {
    readonly __typename: "ImplementationTarget";
    readonly id: Ids.ImplementationTargetId;
    readonly name: string;
    readonly description: string | null;
    readonly status: ImplementationTargetStatus;
    readonly target: number | null;
    readonly startDate: Date;
    readonly targetType: ImplementationTargetType;
    readonly softLaunchDate: Date | null;
    readonly initialCredits: number | null;
    readonly mirahInternal: boolean;
    readonly entityTreeNode: {
      readonly __typename: "EntityTreeNode";
      readonly path: string;
      readonly minAge: number | null;
      readonly maxAge: number | null;
      readonly entity: {
        readonly __typename: "Entity";
        readonly id: Ids.EntityId;
        readonly name: string;
        readonly shortname: string;
        readonly entityType: EntityType;
      };
      readonly segments: ReadonlyArray<{
        readonly __typename: "EntityTreeSegment";
        readonly id: Ids.EntityId;
        readonly path: string;
        readonly entityType: EntityType;
        readonly name: string;
        readonly shortname: string;
        readonly rootPath: string;
      }>;
    };
  } | null;
};

export type ImplementationTargetReportDataDetailsQueryVariables = Exact<{
  implementationTargetId: Scalars["ImplementationTargetId"]["input"];
  entityTreeNode: EntityTreeNodeParams;
  dateAndPeriod: ImplementationTargetReportInput;
}>;

export type ImplementationTargetReportDataDetailsQuery = {
  readonly __typename: "Query";
  readonly implementationTargetReport: {
    readonly __typename: "ImplementationTargetReport";
    readonly id: string;
    readonly onTrack: ImplementationTargetOnTrack;
    readonly graphMaxValue: number;
    readonly breakdownMaxValue: number;
    readonly unitClass: ImplementationTargetReportGraphDataUnit;
    readonly implementationTarget: {
      readonly __typename: "ImplementationTarget";
      readonly id: Ids.ImplementationTargetId;
    };
    readonly reportDate: {
      readonly __typename: "ImplementationTargetReportDate";
      readonly startDate: Date;
      readonly period: ImplementationTargetReportPeriod;
    };
    readonly graphData: ReadonlyArray<{
      readonly __typename: "ImplementationTargetGraphData";
      readonly name: string;
      readonly seriesType: ImplementationTargetReportGraphDataSeries;
      readonly points: ReadonlyArray<{
        readonly __typename: "ChartData";
        readonly date: Date;
        readonly value: number;
      }>;
    }>;
    readonly headlineStats: ReadonlyArray<{
      readonly __typename: "ImplementationTargetHeadlineStat";
      readonly value: number | null;
      readonly period: ImplementationTargetStatPeriod;
      readonly denominator: number | null;
      readonly units: string | null;
      readonly unitClass: ImplementationTargetReportGraphDataUnit;
    }>;
    readonly insights: ReadonlyArray<{
      readonly __typename: "ImplementationTargetReportInsight";
      readonly summary: string;
      readonly insightType: ImplementationTargetReportInsightType;
    }>;
    readonly entityTreeNode: {
      readonly __typename: "EntityTreeNode";
      readonly path: string;
      readonly minAge: number | null;
      readonly maxAge: number | null;
      readonly entity: {
        readonly __typename: "Entity";
        readonly id: Ids.EntityId;
        readonly name: string;
        readonly shortname: string;
        readonly entityType: EntityType;
      };
      readonly segments: ReadonlyArray<{
        readonly __typename: "EntityTreeSegment";
        readonly id: Ids.EntityId;
        readonly path: string;
        readonly entityType: EntityType;
        readonly name: string;
        readonly shortname: string;
        readonly rootPath: string;
      }>;
    };
    readonly breakdowns: ReadonlyArray<{
      readonly __typename: "ImplementationTargetReportBreakdown";
      readonly collection: { readonly __typename: "EntityTreeCollection"; readonly collection: EntityType };
      readonly nodes: ReadonlyArray<{
        readonly __typename: "ImplementationTargetReportBreakdownNode";
        readonly node: {
          readonly __typename: "EntityTreeNode";
          readonly path: string;
          readonly minAge: number | null;
          readonly maxAge: number | null;
          readonly entity: {
            readonly __typename: "Entity";
            readonly id: Ids.EntityId;
            readonly name: string;
            readonly shortname: string;
            readonly entityType: EntityType;
          };
          readonly segments: ReadonlyArray<{
            readonly __typename: "EntityTreeSegment";
            readonly id: Ids.EntityId;
            readonly path: string;
            readonly entityType: EntityType;
            readonly name: string;
            readonly shortname: string;
            readonly rootPath: string;
          }>;
        };
        readonly values: ReadonlyArray<{
          readonly __typename: "ImplementationTargetBreakdownStat";
          readonly value: number;
          readonly displayValue: string;
          readonly series: ImplementationTargetStatPeriod;
        }>;
      }>;
    }>;
  } | null;
};

export type ImplementationTargetReportDataSummaryQueryVariables = Exact<{
  implementationTargetId: Scalars["ImplementationTargetId"]["input"];
  entityTreeNode: InputMaybe<EntityTreeNodeParams>;
  dateAndPeriod: ImplementationTargetReportInput;
}>;

export type ImplementationTargetReportDataSummaryQuery = {
  readonly __typename: "Query";
  readonly implementationTargetReport: {
    readonly __typename: "ImplementationTargetReport";
    readonly id: string;
    readonly onTrack: ImplementationTargetOnTrack;
    readonly unitClass: ImplementationTargetReportGraphDataUnit;
    readonly graphMaxValue: number;
    readonly implementationTarget: {
      readonly __typename: "ImplementationTarget";
      readonly id: Ids.ImplementationTargetId;
    };
    readonly reportDate: {
      readonly __typename: "ImplementationTargetReportDate";
      readonly startDate: Date;
      readonly period: ImplementationTargetReportPeriod;
    };
    readonly graphData: ReadonlyArray<{
      readonly __typename: "ImplementationTargetGraphData";
      readonly name: string;
      readonly seriesType: ImplementationTargetReportGraphDataSeries;
      readonly points: ReadonlyArray<{
        readonly __typename: "ChartData";
        readonly date: Date;
        readonly value: number;
      }>;
    }>;
    readonly headlineStats: ReadonlyArray<{
      readonly __typename: "ImplementationTargetHeadlineStat";
      readonly value: number | null;
      readonly period: ImplementationTargetStatPeriod;
      readonly denominator: number | null;
      readonly units: string | null;
      readonly unitClass: ImplementationTargetReportGraphDataUnit;
    }>;
    readonly entityTreeNode: { readonly __typename: "EntityTreeNode"; readonly path: string };
  } | null;
};

export type ImplementationTargetWidgetQueryVariables = Exact<{
  implementationTargetType: ImplementationTargetType;
  entityTreeNode: EntityTreeNodeParams;
  date: Scalars["ISO8601Date"]["input"];
  allowMirahInternal: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ImplementationTargetWidgetQuery = {
  readonly __typename: "Query";
  readonly implementationTargetBestReport: {
    readonly __typename: "ImplementationTargetReport";
    readonly id: string;
    readonly onTrack: ImplementationTargetOnTrack;
    readonly unitClass: ImplementationTargetReportGraphDataUnit;
    readonly graphMaxValue: number;
    readonly implementationTarget: {
      readonly __typename: "ImplementationTarget";
      readonly id: Ids.ImplementationTargetId;
      readonly name: string;
      readonly targetType: ImplementationTargetType;
      readonly entityTreeNode: {
        readonly __typename: "EntityTreeNode";
        readonly path: string;
        readonly minAge: number | null;
        readonly maxAge: number | null;
        readonly entity: {
          readonly __typename: "Entity";
          readonly id: Ids.EntityId;
          readonly name: string;
          readonly shortname: string;
          readonly entityType: EntityType;
        };
        readonly segments: ReadonlyArray<{
          readonly __typename: "EntityTreeSegment";
          readonly id: Ids.EntityId;
          readonly path: string;
          readonly entityType: EntityType;
          readonly name: string;
          readonly shortname: string;
          readonly rootPath: string;
        }>;
      };
    };
    readonly reportDate: {
      readonly __typename: "ImplementationTargetReportDate";
      readonly startDate: Date;
      readonly endDate: Date;
      readonly period: ImplementationTargetReportPeriod;
    };
    readonly graphData: ReadonlyArray<{
      readonly __typename: "ImplementationTargetGraphData";
      readonly name: string;
      readonly seriesType: ImplementationTargetReportGraphDataSeries;
      readonly points: ReadonlyArray<{
        readonly __typename: "ChartData";
        readonly date: Date;
        readonly value: number;
      }>;
    }>;
    readonly headlineStats: ReadonlyArray<{
      readonly __typename: "ImplementationTargetHeadlineStat";
      readonly value: number | null;
      readonly period: ImplementationTargetStatPeriod;
      readonly denominator: number | null;
      readonly units: string | null;
      readonly unitClass: ImplementationTargetReportGraphDataUnit;
    }>;
    readonly entityTreeNode: {
      readonly __typename: "EntityTreeNode";
      readonly path: string;
      readonly minAge: number | null;
      readonly maxAge: number | null;
      readonly entity: {
        readonly __typename: "Entity";
        readonly id: Ids.EntityId;
        readonly name: string;
        readonly shortname: string;
        readonly entityType: EntityType;
      };
      readonly segments: ReadonlyArray<{
        readonly __typename: "EntityTreeSegment";
        readonly id: Ids.EntityId;
        readonly path: string;
        readonly entityType: EntityType;
        readonly name: string;
        readonly shortname: string;
        readonly rootPath: string;
      }>;
    };
  } | null;
};

export type ImplementationTargetWithReportDateDetailsQueryVariables = Exact<{
  implementationTargetId: Scalars["ImplementationTargetId"]["input"];
  date: Scalars["ISO8601Date"]["input"];
}>;

export type ImplementationTargetWithReportDateDetailsQuery = {
  readonly __typename: "Query";
  readonly implementationTarget: {
    readonly __typename: "ImplementationTarget";
    readonly id: Ids.ImplementationTargetId;
    readonly name: string;
    readonly description: string | null;
    readonly status: ImplementationTargetStatus;
    readonly target: number | null;
    readonly startDate: Date;
    readonly targetType: ImplementationTargetType;
    readonly softLaunchDate: Date | null;
    readonly initialCredits: number | null;
    readonly entityTreeNode: {
      readonly __typename: "EntityTreeNode";
      readonly path: string;
      readonly minAge: number | null;
      readonly maxAge: number | null;
      readonly entity: {
        readonly __typename: "Entity";
        readonly id: Ids.EntityId;
        readonly name: string;
        readonly shortname: string;
        readonly entityType: EntityType;
      };
      readonly segments: ReadonlyArray<{
        readonly __typename: "EntityTreeSegment";
        readonly id: Ids.EntityId;
        readonly path: string;
        readonly entityType: EntityType;
        readonly name: string;
        readonly shortname: string;
        readonly rootPath: string;
      }>;
    };
    readonly closestReportDateTo: {
      readonly __typename: "ImplementationTargetReportDate";
      readonly endDate: Date;
      readonly period: ImplementationTargetReportPeriod;
    } | null;
  } | null;
};

export type UpdateImplementationTargetMutationVariables = Exact<{
  input: UpdateImplementationTargetInput;
}>;

export type UpdateImplementationTargetMutation = {
  readonly __typename: "Mutation";
  readonly implementationUpdateImplementationTarget: {
    readonly __typename: "UpdateImplementationTargetPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateImplementationTargetResult";
      readonly implementationTarget: {
        readonly __typename: "ImplementationTarget";
        readonly id: Ids.ImplementationTargetId;
        readonly status: ImplementationTargetStatus;
        readonly name: string;
        readonly description: string | null;
        readonly mirahInternal: boolean;
        readonly targetType: ImplementationTargetType;
        readonly entityTreeNode: {
          readonly __typename: "EntityTreeNode";
          readonly path: string;
          readonly minAge: number | null;
          readonly maxAge: number | null;
          readonly entity: {
            readonly __typename: "Entity";
            readonly id: Ids.EntityId;
            readonly name: string;
            readonly shortname: string;
            readonly entityType: EntityType;
          };
          readonly segments: ReadonlyArray<{
            readonly __typename: "EntityTreeSegment";
            readonly id: Ids.EntityId;
            readonly path: string;
            readonly entityType: EntityType;
            readonly name: string;
            readonly shortname: string;
            readonly rootPath: string;
          }>;
        };
      };
    } | null;
  } | null;
};

export type UpdateImplementationTargetStatusMutationVariables = Exact<{
  input: UpdateImplementationTargetStatusInput;
}>;

export type UpdateImplementationTargetStatusMutation = {
  readonly __typename: "Mutation";
  readonly implementationUpdateImplementationTargetStatus: {
    readonly __typename: "UpdateImplementationTargetStatusPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateImplementationTargetStatusResult";
      readonly implementationTarget: {
        readonly __typename: "ImplementationTarget";
        readonly id: Ids.ImplementationTargetId;
        readonly status: ImplementationTargetStatus;
      };
    } | null;
  } | null;
};

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInput;
}>;

export type CreateOrganizationMutation = {
  readonly __typename: "Mutation";
  readonly createOrganization: {
    readonly __typename: "CreateOrganizationPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateOrganizationResult";
      readonly organization: {
        readonly __typename: "Organization";
        readonly id: Ids.OrganizationId;
        readonly name: string;
        readonly shortname: string;
        readonly parentId: Ids.OrganizationId | null;
        readonly inTrialPeriod: boolean;
        readonly measurementAllowed: boolean;
        readonly timezone: string | null;
      };
    } | null;
  } | null;
};

export type EditOrganizationMutationVariables = Exact<{
  input: EditOrganizationInput;
}>;

export type EditOrganizationMutation = {
  readonly __typename: "Mutation";
  readonly editOrganization: {
    readonly __typename: "EditOrganizationPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EditOrganizationResult";
      readonly organization: {
        readonly __typename: "Organization";
        readonly id: Ids.OrganizationId;
        readonly name: string;
        readonly shortname: string;
        readonly parentId: Ids.OrganizationId | null;
        readonly inTrialPeriod: boolean;
        readonly measurementAllowed: boolean;
        readonly timezone: string | null;
      };
    } | null;
  } | null;
};

export type AssociateTreatmentServiceQueryVariables = Exact<{
  treatmentServiceId: Scalars["TreatmentServiceId"]["input"];
}>;

export type AssociateTreatmentServiceQuery = {
  readonly __typename: "Query";
  readonly treatmentService: {
    readonly __typename: "TreatmentService";
    readonly id: Ids.TreatmentServiceId;
    readonly name: string;
    readonly parentId: Ids.TreatmentServiceId | null;
    readonly measurementAllowed: boolean;
    readonly providers: ReadonlyArray<{ readonly __typename: "Provider"; readonly id: Ids.ProviderId }>;
    readonly organizations: ReadonlyArray<{
      readonly __typename: "Organization";
      readonly id: Ids.OrganizationId;
    }>;
    readonly instituteConditions: ReadonlyArray<{
      readonly __typename: "InstituteCondition";
      readonly id: Ids.InstituteConditionId;
    }>;
  } | null;
  readonly organizations: {
    readonly __typename: "OrganizationConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Organization";
      readonly id: Ids.OrganizationId;
      readonly name: string;
    }>;
  } | null;
  readonly providers: {
    readonly __typename: "ProviderConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
    }>;
  } | null;
  readonly instituteConditions: {
    readonly __typename: "InstituteConditionConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "InstituteCondition";
      readonly id: Ids.InstituteConditionId;
      readonly name: string;
    }>;
  } | null;
};

export type UpdateTreatmentServiceAssociationsMutationVariables = Exact<{
  inputOrganizations: UpdateTreatmentServiceOrganizationsInput;
  inputProviders: UpdateTreatmentServiceProvidersInput;
  inputInstituteConditions: UpdateTreatmentServiceInstituteConditionsInput;
}>;

export type UpdateTreatmentServiceAssociationsMutation = {
  readonly __typename: "Mutation";
  readonly updateTreatmentServiceOrganizations: {
    readonly __typename: "UpdateTreatmentServiceOrganizationsPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateTreatmentServiceOrganizationsResult";
      readonly treatmentService: {
        readonly __typename: "TreatmentService";
        readonly id: Ids.TreatmentServiceId;
        readonly organizations: ReadonlyArray<{
          readonly __typename: "Organization";
          readonly id: Ids.OrganizationId;
          readonly name: string;
        }>;
      } | null;
    } | null;
  } | null;
  readonly updateTreatmentServiceProviders: {
    readonly __typename: "UpdateTreatmentServiceProvidersPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateTreatmentServiceProvidersResult";
      readonly treatmentService: {
        readonly __typename: "TreatmentService";
        readonly id: Ids.TreatmentServiceId;
        readonly providers: ReadonlyArray<{
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        }>;
      };
    } | null;
  } | null;
  readonly updateTreatmentServiceInstituteConditions: {
    readonly __typename: "UpdateTreatmentServiceInstituteConditionsPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateTreatmentServiceInstituteConditionsResult";
      readonly treatmentService: {
        readonly __typename: "TreatmentService";
        readonly id: Ids.TreatmentServiceId;
        readonly instituteConditions: ReadonlyArray<{
          readonly __typename: "InstituteCondition";
          readonly id: Ids.InstituteConditionId;
          readonly name: string;
        }>;
      } | null;
    } | null;
  } | null;
};

export type CreateTreatmentServiceMutationVariables = Exact<{
  input: CreateTreatmentServiceInput;
}>;

export type CreateTreatmentServiceMutation = {
  readonly __typename: "Mutation";
  readonly createTreatmentService: {
    readonly __typename: "CreateTreatmentServicePayload";
    readonly clientMutationId: string | null;
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateTreatmentServiceResult";
      readonly treatmentService: { readonly __typename: "TreatmentService"; readonly name: string } | null;
    } | null;
  } | null;
};

export type TreatmentServiceQueryVariables = Exact<{
  treatmentServiceId: Scalars["TreatmentServiceId"]["input"];
}>;

export type TreatmentServiceQuery = {
  readonly __typename: "Query";
  readonly treatmentService: {
    readonly __typename: "TreatmentService";
    readonly id: Ids.TreatmentServiceId;
    readonly name: string;
    readonly parentId: Ids.TreatmentServiceId | null;
    readonly measurementAllowed: boolean;
  } | null;
};

export type TreatmentServicesQueryVariables = Exact<{ [key: string]: never }>;

export type TreatmentServicesQuery = {
  readonly __typename: "Query";
  readonly treatmentServices: {
    readonly __typename: "TreatmentServiceConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "TreatmentService";
      readonly name: string;
      readonly id: Ids.TreatmentServiceId;
      readonly measurementAllowed: boolean;
      readonly parentId: Ids.TreatmentServiceId | null;
      readonly serviceTypes: ReadonlyArray<string>;
      readonly instituteConditions: ReadonlyArray<{
        readonly __typename: "InstituteCondition";
        readonly id: Ids.InstituteConditionId;
        readonly name: string;
      }>;
    }>;
  } | null;
};

export type UpdateTreatmentServiceRequiredDataQueryVariables = Exact<{
  treatmentServiceId: Scalars["TreatmentServiceId"]["input"];
}>;

export type UpdateTreatmentServiceRequiredDataQuery = {
  readonly __typename: "Query";
  readonly treatmentService: {
    readonly __typename: "TreatmentService";
    readonly id: Ids.TreatmentServiceId;
    readonly name: string;
    readonly parentId: Ids.TreatmentServiceId | null;
    readonly measurementAllowed: boolean;
    readonly serviceTypes: ReadonlyArray<string>;
  } | null;
  readonly treatmentServices: {
    readonly __typename: "TreatmentServiceConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "TreatmentService";
      readonly name: string;
      readonly id: Ids.TreatmentServiceId;
    }>;
  } | null;
};

export type UpdateTreatmentServiceMutationVariables = Exact<{
  input: UpdateTreatmentServiceInput;
}>;

export type UpdateTreatmentServiceMutation = {
  readonly __typename: "Mutation";
  readonly updateTreatmentService: {
    readonly __typename: "UpdateTreatmentServicePayload";
    readonly success: boolean;
    readonly clientMutationId: string | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateTreatmentServiceResult";
      readonly treatmentService: {
        readonly __typename: "TreatmentService";
        readonly id: Ids.TreatmentServiceId;
        readonly name: string;
        readonly parentId: Ids.TreatmentServiceId | null;
        readonly measurementAllowed: boolean;
        readonly serviceTypes: ReadonlyArray<string>;
      } | null;
    } | null;
  } | null;
};

export type IntegrationDataSourceRecordLogDetailQueryVariables = Exact<{
  dataSourceRecordLogId: Scalars["DataSourceRecordLogId"]["input"];
}>;

export type IntegrationDataSourceRecordLogDetailQuery = {
  readonly __typename: "Query";
  readonly integrationsDataSourceRecordLog: {
    readonly __typename: "DataSourceRecordLog";
    readonly id: Ids.DataSourceRecordLogId;
    readonly createdAt: Date;
    readonly status: DataSourceRecordLogStatus;
    readonly matchStatus: DataSourceRecordLogMatchStatus | null;
    readonly raw: GqlScalars.DataSourceRecordLogRawData;
    readonly transformErrors: ReadonlyArray<{
      readonly __typename: "DataSourceRecordLogTransformError";
      readonly field: string;
      readonly errors: ReadonlyArray<DataSourceRecordLogError>;
    }> | null;
    readonly dataSourceRecordAlgorithmLogs: ReadonlyArray<{
      readonly __typename: "DataSourceRecordAlgorithmLog";
      readonly id: Ids.DataSourceRecordAlgorithmLogId;
      readonly algorithm: DataSourceRecordAlgorithm;
      readonly algorithmCode: string;
      readonly statusName: string;
      readonly statusDescription: string;
    }>;
  } | null;
};

export type IntegrationDataSourceRecordLogHistoryQueryVariables = Exact<{
  importHistoryId: Scalars["ImportHistoryId"]["input"];
  status: InputMaybe<ReadonlyArray<DataSourceRecordLogStatus> | DataSourceRecordLogStatus>;
  sortBy: InputMaybe<DataSourceRecordLogSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
}>;

export type IntegrationDataSourceRecordLogHistoryQuery = {
  readonly __typename: "Query";
  readonly integrationsDataSourceRecordLogs: {
    readonly __typename: "DataSourceRecordLogConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "DataSourceRecordLog";
      readonly id: Ids.DataSourceRecordLogId;
      readonly createdAt: Date;
      readonly status: DataSourceRecordLogStatus;
      readonly matchStatus: DataSourceRecordLogMatchStatus | null;
      readonly raw: GqlScalars.DataSourceRecordLogRawData;
      readonly transformErrors: ReadonlyArray<{
        readonly __typename: "DataSourceRecordLogTransformError";
        readonly field: string;
        readonly errors: ReadonlyArray<DataSourceRecordLogError>;
      }> | null;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type IntegrationStatusBadgeQueryVariables = Exact<{
  id: Scalars["ImportHistoryId"]["input"];
  resourceType: DataSourceResourceType;
}>;

export type IntegrationStatusBadgeQuery = {
  readonly __typename: "Query";
  readonly integrationsImportHistory: {
    readonly __typename: "ImportHistory";
    readonly id: Ids.ImportHistoryId;
    readonly name: string;
    readonly source: SourceEnum | null;
    readonly lastImportDate: Date | null;
    readonly updatedAt: Date | null;
    readonly createdBy: {
      readonly __typename: "User";
      readonly id: Ids.UserId;
      readonly name: string;
    } | null;
    readonly lastUpdatedBy: {
      readonly __typename: "User";
      readonly id: Ids.UserId;
      readonly name: string;
    } | null;
  } | null;
};

export type IntegrationStatusBadgeInlineQueryVariables = Exact<{
  id: Scalars["ImportHistoryId"]["input"];
  resourceType: DataSourceResourceType;
}>;

export type IntegrationStatusBadgeInlineQuery = {
  readonly __typename: "Query";
  readonly integrationsImportHistory: {
    readonly __typename: "ImportHistory";
    readonly id: Ids.ImportHistoryId;
    readonly name: string;
    readonly source: SourceEnum | null;
    readonly lastImportDate: Date | null;
    readonly updatedAt: Date | null;
  } | null;
};

export type IntegrationStatusLogQueryVariables = Exact<{
  id: Scalars["ImportHistoryId"]["input"];
  resourceType: DataSourceResourceType;
}>;

export type IntegrationStatusLogQuery = {
  readonly __typename: "Query";
  readonly integrationsImportHistory: {
    readonly __typename: "ImportHistory";
    readonly id: Ids.ImportHistoryId;
    readonly name: string;
    readonly resourceType: DataSourceResourceType;
    readonly source: SourceEnum | null;
    readonly lastImportDate: Date | null;
    readonly updatedAt: Date | null;
    readonly integrationsDataSourceRecords: ReadonlyArray<{
      readonly __typename: "DataSourceRecord";
      readonly id: Ids.DataSourceRecordId;
      readonly remoteId: string;
      readonly effectiveSince: Date | null;
      readonly dataSourceResource: {
        readonly __typename: "DataSourceResource";
        readonly dataSource: {
          readonly __typename: "DataSource";
          readonly id: Ids.DataSourceId;
          readonly name: string;
        };
      };
    }>;
    readonly integrationsDataSourceRecordLogs: {
      readonly __typename: "DataSourceRecordLogConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "DataSourceRecordLog";
        readonly id: Ids.DataSourceRecordLogId;
        readonly createdAt: Date;
        readonly status: DataSourceRecordLogStatus;
        readonly matchStatus: DataSourceRecordLogMatchStatus | null;
        readonly raw: GqlScalars.DataSourceRecordLogRawData;
        readonly transformErrors: ReadonlyArray<{
          readonly __typename: "DataSourceRecordLogTransformError";
          readonly field: string;
          readonly errors: ReadonlyArray<DataSourceRecordLogError>;
        }> | null;
        readonly dataSourceRecordAlgorithmLogs: ReadonlyArray<{
          readonly __typename: "DataSourceRecordAlgorithmLog";
          readonly id: Ids.DataSourceRecordAlgorithmLogId;
          readonly algorithm: DataSourceRecordAlgorithm;
          readonly algorithmCode: string;
          readonly statusName: string;
          readonly statusDescription: string;
        }>;
      }>;
    };
  } | null;
};

export type MagicInspectorPathQueryVariables = Exact<{
  text: Scalars["String"]["input"];
}>;

export type MagicInspectorPathQuery = {
  readonly __typename: "Query";
  readonly magicInspectorPath: string | null;
};

export type InspectorNodeQueryVariables = Exact<{
  id: Scalars["String"]["input"];
  className: Scalars["String"]["input"];
}>;

export type InspectorNodeQuery = {
  readonly __typename: "Query";
  readonly inspectorNode: {
    readonly __typename: "InspectorNode";
    readonly model: {
      readonly __typename: "Model";
      readonly id: string;
      readonly className: string;
      readonly fields: ReadonlyArray<{
        readonly __typename: "Field";
        readonly name: string;
        readonly value: string | null;
      }>;
    };
    readonly associations: ReadonlyArray<{
      readonly __typename: "Edges";
      readonly className: string;
      readonly edges: ReadonlyArray<{
        readonly __typename: "Edge";
        readonly id: string;
        readonly summary: ReadonlyArray<{
          readonly __typename: "Field";
          readonly name: string;
          readonly value: string | null;
        }>;
      }>;
    }>;
    readonly versions: ReadonlyArray<{
      readonly __typename: "Version";
      readonly id: string;
      readonly event: string | null;
      readonly createdAt: string;
      readonly whodunnit: string | null;
      readonly fields: ReadonlyArray<{
        readonly __typename: "VersionField";
        readonly name: string;
        readonly oldValue: string | null;
        readonly newValue: string | null;
      }>;
    }>;
  } | null;
};

export type AddInstituteToInstituteGroupMutationVariables = Exact<{
  input: AddInstituteToInstituteGroupInput;
}>;

export type AddInstituteToInstituteGroupMutation = {
  readonly __typename: "Mutation";
  readonly addInstituteToInstituteGroup: {
    readonly __typename: "AddInstituteToInstituteGroupPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "AddInstituteToInstituteGroupResult";
      readonly instituteGroup: {
        readonly __typename: "InstituteGroup";
        readonly id: Ids.InstituteGroupId;
        readonly name: string;
        readonly leader: {
          readonly __typename: "CurrentInstitute";
          readonly id: Ids.InstituteId;
          readonly name: string;
        };
        readonly members: ReadonlyArray<{
          readonly __typename: "CurrentInstitute";
          readonly id: Ids.InstituteId;
          readonly name: string;
        }>;
      };
    } | null;
  } | null;
};

export type CreateInstituteGroupMutationVariables = Exact<{
  input: CreateInstituteGroupInput;
}>;

export type CreateInstituteGroupMutation = {
  readonly __typename: "Mutation";
  readonly createInstituteGroup: {
    readonly __typename: "CreateInstituteGroupPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateInstituteGroupResult";
      readonly instituteGroup: {
        readonly __typename: "InstituteGroup";
        readonly id: Ids.InstituteGroupId;
        readonly name: string;
        readonly leader: {
          readonly __typename: "CurrentInstitute";
          readonly id: Ids.InstituteId;
          readonly name: string;
        };
        readonly members: ReadonlyArray<{
          readonly __typename: "CurrentInstitute";
          readonly id: Ids.InstituteId;
          readonly name: string;
        }>;
      };
    } | null;
  } | null;
};

export type InstituteGroupDetailsQueryVariables = Exact<{
  id: Scalars["InstituteGroupId"]["input"];
}>;

export type InstituteGroupDetailsQuery = {
  readonly __typename: "Query";
  readonly instituteGroup: {
    readonly __typename: "InstituteGroup";
    readonly id: Ids.InstituteGroupId;
    readonly name: string;
    readonly leader: {
      readonly __typename: "CurrentInstitute";
      readonly id: Ids.InstituteId;
      readonly name: string;
    };
    readonly members: ReadonlyArray<{
      readonly __typename: "CurrentInstitute";
      readonly id: Ids.InstituteId;
      readonly name: string;
    }>;
  } | null;
};

export type InstituteGroupListQueryVariables = Exact<{
  sortBy: InputMaybe<InstituteGroupSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
}>;

export type InstituteGroupListQuery = {
  readonly __typename: "Query";
  readonly instituteGroups: {
    readonly __typename: "InstituteGroupConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "InstituteGroup";
      readonly id: Ids.InstituteGroupId;
      readonly name: string;
      readonly leader: {
        readonly __typename: "CurrentInstitute";
        readonly id: Ids.InstituteId;
        readonly name: string;
      };
      readonly members: ReadonlyArray<{
        readonly __typename: "CurrentInstitute";
        readonly id: Ids.InstituteId;
        readonly name: string;
      }>;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type RemoveInstituteFromInstituteGroupMutationVariables = Exact<{
  input: RemoveInstituteFromInstituteGroupInput;
}>;

export type RemoveInstituteFromInstituteGroupMutation = {
  readonly __typename: "Mutation";
  readonly removeInstituteFromInstituteGroup: {
    readonly __typename: "RemoveInstituteFromInstituteGroupPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "RemoveInstituteFromInstituteGroupResult";
      readonly instituteGroup: {
        readonly __typename: "InstituteGroup";
        readonly id: Ids.InstituteGroupId;
        readonly name: string;
        readonly leader: {
          readonly __typename: "CurrentInstitute";
          readonly id: Ids.InstituteId;
          readonly name: string;
        };
        readonly members: ReadonlyArray<{
          readonly __typename: "CurrentInstitute";
          readonly id: Ids.InstituteId;
          readonly name: string;
        }>;
      };
    } | null;
  } | null;
};

export type InstituteAppointmentAthenaPushbackQueryVariables = Exact<{
  appointmentId: Scalars["AppointmentId"]["input"];
}>;

export type InstituteAppointmentAthenaPushbackQuery = {
  readonly __typename: "Query";
  readonly schedulingAppointment: {
    readonly __typename: "Appointment";
    readonly id: Ids.AppointmentId;
    readonly athenaAppointmentEncounter: {
      readonly __typename: "AthenaAppointmentEncounter";
      readonly id: Ids.AthenaAppointmentEncounterId;
      readonly athenaEncounterId: string;
      readonly athenaPatientId: string;
      readonly departmentId: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
    } | null;
    readonly athenaScreeningQuestionnaires: ReadonlyArray<{
      readonly __typename: "AthenaScreeningQuestionnaire";
      readonly status: AthenaScreeningQuestionnaireStatus;
      readonly appointmentEncounterId: Ids.AthenaAppointmentEncounterId | null;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly pdfS3Location: string | null;
      readonly athenaDocumentId: string | null;
      readonly athenaQuestionaireId: string | null;
      readonly computedValueId: Ids.ComputedValueId;
      readonly id: Ids.AthenaScreeningQuestionnaireId;
      readonly scale:
        | { readonly __typename: "AdministrableScale"; readonly mnemonic: string }
        | { readonly __typename: "Subscale"; readonly mnemonic: string };
    }>;
  } | null;
};

export type InstituteAppointmentQueryVariables = Exact<{
  appointmentId: Scalars["AppointmentId"]["input"];
}>;

export type InstituteAppointmentQuery = {
  readonly __typename: "Query";
  readonly schedulingAppointment: {
    readonly __typename: "Appointment";
    readonly id: Ids.AppointmentId;
    readonly source: SourceEnum | null;
    readonly startDate: Date | null;
    readonly endDate: Date | null;
    readonly minutesDuration: number | null;
    readonly comment: string | null;
    readonly status: AppointmentStatus;
    readonly patientSession: {
      readonly __typename: "PatientSession";
      readonly id: Ids.PatientSessionId;
      readonly status: PatientSessionStatus;
      readonly sessionNumber: number | null;
      readonly careEpisode: {
        readonly __typename: "CareEpisode";
        readonly id: Ids.CareEpisodeId;
        readonly name: string;
      };
    } | null;
    readonly provider: {
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
    } | null;
    readonly patient: { readonly __typename: "Patient"; readonly id: Ids.PatientId; readonly name: string };
    readonly organization: {
      readonly __typename: "Organization";
      readonly id: Ids.OrganizationId;
      readonly name: string;
      readonly shortname: string;
    } | null;
    readonly careEpisode: {
      readonly __typename: "CareEpisode";
      readonly id: Ids.CareEpisodeId;
      readonly name: string;
    } | null;
    readonly integrationsDataSourceRecords: ReadonlyArray<{
      readonly __typename: "DataSourceRecord";
      readonly id: Ids.DataSourceRecordId;
      readonly remoteId: string;
    }>;
  } | null;
};

export type InstituteCareEpisodeQueryVariables = Exact<{
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
}>;

export type InstituteCareEpisodeQuery = {
  readonly __typename: "Query";
  readonly careEpisode: {
    readonly __typename: "CareEpisode";
    readonly id: Ids.CareEpisodeId;
    readonly status: CareEpisodeStatus;
    readonly periodStart: Date | null;
    readonly periodEnd: Date | null;
    readonly name: string;
    readonly shortname: string;
    readonly measurementAllowed: boolean;
    readonly institute: {
      readonly __typename: "Institute";
      readonly measurementAllowed: boolean;
      readonly id: Ids.InstituteId;
      readonly name: string;
    };
    readonly patient: {
      readonly __typename: "Patient";
      readonly id: Ids.PatientId;
      readonly name: string;
      readonly autoCreateSessions: boolean | null;
    };
    readonly providers: ReadonlyArray<{
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
      readonly measurementAllowed: boolean;
    }>;
    readonly organization: {
      readonly __typename: "Organization";
      readonly id: Ids.OrganizationId;
      readonly name: string;
      readonly shortname: string;
      readonly measurementAllowed: boolean;
    };
    readonly integrationsDataSourceRecords: ReadonlyArray<{
      readonly __typename: "DataSourceRecord";
      readonly id: Ids.DataSourceRecordId;
      readonly remoteId: string;
    }>;
  } | null;
};

export type CareEpisodeMeasurementAllowedDetailsFragment = {
  readonly __typename: "CareEpisode";
  readonly id: Ids.CareEpisodeId;
  readonly measurementAllowed: boolean;
  readonly institute: {
    readonly __typename: "Institute";
    readonly id: Ids.InstituteId;
    readonly name: string;
    readonly measurementAllowed: boolean;
  };
  readonly providers: ReadonlyArray<{
    readonly __typename: "Provider";
    readonly id: Ids.ProviderId;
    readonly name: string;
    readonly measurementAllowed: boolean;
  }>;
  readonly organization: {
    readonly __typename: "Organization";
    readonly id: Ids.OrganizationId;
    readonly name: string;
    readonly measurementAllowed: boolean;
  };
  readonly patient: {
    readonly __typename: "Patient";
    readonly id: Ids.PatientId;
    readonly name: string;
    readonly autoCreateSessions: boolean | null;
  };
};

export type EhrLinkLogListQueryVariables = Exact<{
  ehrLinkId: InputMaybe<ReadonlyArray<Scalars["EhrLinkId"]["input"]> | Scalars["EhrLinkId"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  status: InputMaybe<ReadonlyArray<EhrLinkLogStatus> | EhrLinkLogStatus>;
}>;

export type EhrLinkLogListQuery = {
  readonly __typename: "Query";
  readonly integrationsEhrLinkLogs: {
    readonly __typename: "EhrLinkLogConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "EhrLinkLog";
      readonly id: Ids.EhrLinkLogId;
      readonly status: EhrLinkLogStatus;
      readonly startDate: Date;
      readonly endDate: Date | null;
      readonly log: string | null;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type InstituteDetailsQueryQueryVariables = Exact<{
  instituteId: Scalars["InstituteId"]["input"];
}>;

export type InstituteDetailsQueryQuery = {
  readonly __typename: "Query";
  readonly institute: {
    readonly __typename: "Institute";
    readonly id: Ids.InstituteId;
    readonly name: string;
    readonly measurementAllowed: boolean;
    readonly ehrLinks: {
      readonly __typename: "EhrLinkConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "EhrLink";
        readonly id: Ids.EhrLinkId;
        readonly connectionType: EhrLinkType | null;
        readonly lastLog: {
          readonly __typename: "EhrLinkLog";
          readonly status: EhrLinkLogStatus;
          readonly startDate: Date;
          readonly endDate: Date | null;
        } | null;
      }>;
    };
  } | null;
};

export type InstituteSsoSignonUrlQueryVariables = Exact<{
  instituteId: Scalars["InstituteId"]["input"];
}>;

export type InstituteSsoSignonUrlQuery = {
  readonly __typename: "Query";
  readonly institute: { readonly __typename: "Institute"; readonly ssoSignonUrl: string } | null;
};

export type InstituteSummaryQueryVariables = Exact<{
  instituteId: Scalars["InstituteId"]["input"];
}>;

export type InstituteSummaryQuery = {
  readonly __typename: "Query";
  readonly institute: {
    readonly __typename: "Institute";
    readonly id: Ids.InstituteId;
    readonly name: string;
  } | null;
};

export type UserInternalOverviewFragment = {
  readonly __typename: "User";
  readonly id: Ids.UserId;
  readonly name: string;
  readonly confirmedAt: Date | null;
  readonly currentSignInAt: Date | null;
  readonly email: string | null;
  readonly emailSource: FieldSourceEnum | null;
  readonly failedAttempts: number;
  readonly firstName: string | null;
  readonly firstNameSource: FieldSourceEnum | null;
  readonly isInternal: boolean;
  readonly language: string | null;
  readonly lastActivityAt: Date | null;
  readonly lastName: string | null;
  readonly lastNameSource: FieldSourceEnum | null;
  readonly lastSignInAt: Date | null;
  readonly lockedAt: Date | null;
  readonly notificationPreference: NotificationPreference | null;
  readonly phoneNumber: string | null;
  readonly phoneNumberSource: FieldSourceEnum | null;
  readonly role: string | null;
  readonly signInCount: number;
  readonly ssoUsername: string | null;
  readonly suffix: string | null;
  readonly timezone: string | null;
  readonly title: string | null;
  readonly username: string | null;
  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type DataSourceRecordLogsQueryVariables = Exact<{
  dataSourceRecordId: InputMaybe<
    ReadonlyArray<Scalars["DataSourceRecordId"]["input"]> | Scalars["DataSourceRecordId"]["input"]
  >;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
}>;

export type DataSourceRecordLogsQuery = {
  readonly __typename: "Query";
  readonly integrationsDataSourceRecordLogs: {
    readonly __typename: "DataSourceRecordLogConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "DataSourceRecordLog";
      readonly id: Ids.DataSourceRecordLogId;
      readonly status: DataSourceRecordLogStatus;
      readonly matchStatus: DataSourceRecordLogMatchStatus | null;
      readonly raw: GqlScalars.DataSourceRecordLogRawData;
      readonly idError: DataSourceRecordLogError | null;
      readonly createdAt: Date;
      readonly transformErrors: ReadonlyArray<{
        readonly __typename: "DataSourceRecordLogTransformError";
        readonly field: string;
        readonly errors: ReadonlyArray<DataSourceRecordLogError>;
      }> | null;
      readonly dataSourceRecordAlgorithmLogs: ReadonlyArray<{
        readonly __typename: "DataSourceRecordAlgorithmLog";
        readonly algorithmCode: string;
        readonly statusName: string;
        readonly statusDescription: string;
      }>;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type InternalIntegrationsDataSourceRecordsFragment = {
  readonly __typename: "DataSourceRecord";
  readonly id: Ids.DataSourceRecordId;
  readonly remoteId: string;
};

export type CreateOrEditTreatmentTargetScaleMutationVariables = Exact<{
  input: CreateOrEditTreatmentTargetScaleInput;
}>;

export type CreateOrEditTreatmentTargetScaleMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareCreateOrEditTreatmentTargetScale: {
    readonly __typename: "CreateOrEditTreatmentTargetScalePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EditTreatmentTargetScaleResult";
      readonly treatmentTarget: {
        readonly __typename: "TreatmentTarget";
        readonly id: Ids.TreatmentTargetId;
        readonly measurementPlan: {
          readonly __typename: "MeasurementPlan";
          readonly id: Ids.MeasurementPlanId;
          readonly measurementPlanScales: ReadonlyArray<{
            readonly __typename: "MeasurementPlanScale";
            readonly id: Ids.MeasurementPlanScaleId;
            readonly discontinueSession: number | null;
            readonly maxRepeatInterval: GqlScalars.Interval | null;
            readonly targeting: ScaleTargeting | null;
            readonly onsetTime: GqlScalars.Interval | null;
            readonly scale:
              | {
                  readonly __typename: "AdministrableScale";
                  readonly defaultParticipants: ReadonlyArray<ParticipantRelationship> | null;
                  readonly id: Ids.ScaleId;
                  readonly shortname: string | null;
                  readonly nanoname: string | null;
                  readonly mnemonic: string;
                }
              | {
                  readonly __typename: "Subscale";
                  readonly id: Ids.ScaleId;
                  readonly shortname: string | null;
                  readonly nanoname: string | null;
                  readonly mnemonic: string;
                };
          }>;
        };
      };
    } | null;
  } | null;
};

export type CreateTreatmentTargetMutationVariables = Exact<{
  input: CreateTreatmentTargetInput;
}>;

export type CreateTreatmentTargetMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareCreateTreatmentTarget: {
    readonly __typename: "CreateTreatmentTargetPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateTreatmentTargetResult";
      readonly treatmentTarget: {
        readonly __typename: "TreatmentTarget";
        readonly id: Ids.TreatmentTargetId;
        readonly name: string;
        readonly measurementPlan: {
          readonly __typename: "MeasurementPlan";
          readonly id: Ids.MeasurementPlanId;
          readonly measurementPlanScales: ReadonlyArray<{
            readonly __typename: "MeasurementPlanScale";
            readonly id: Ids.MeasurementPlanScaleId;
            readonly discontinueSession: number | null;
            readonly maxRepeatInterval: GqlScalars.Interval | null;
            readonly targeting: ScaleTargeting | null;
            readonly scale:
              | {
                  readonly __typename: "AdministrableScale";
                  readonly defaultParticipants: ReadonlyArray<ParticipantRelationship> | null;
                  readonly id: Ids.ScaleId;
                  readonly shortname: string | null;
                  readonly nanoname: string | null;
                  readonly mnemonic: string;
                }
              | {
                  readonly __typename: "Subscale";
                  readonly id: Ids.ScaleId;
                  readonly shortname: string | null;
                  readonly nanoname: string | null;
                  readonly mnemonic: string;
                };
          }>;
        };
      };
    } | null;
  } | null;
};

export type DeleteTreatmentTargetMutationVariables = Exact<{
  input: DeleteTreatmentTargetInput;
}>;

export type DeleteTreatmentTargetMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareDeleteTreatmentTarget: {
    readonly __typename: "DeleteTreatmentTargetPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "DeleteTreatmentTargetResult";
      readonly treatmentTarget: {
        readonly __typename: "TreatmentTarget";
        readonly id: Ids.TreatmentTargetId;
      };
    } | null;
  } | null;
};

export type DeleteTreatmentTargetScaleMutationVariables = Exact<{
  input: DeleteTreatmentTargetScaleInput;
}>;

export type DeleteTreatmentTargetScaleMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareDeleteTreatmentTargetScale: {
    readonly __typename: "DeleteTreatmentTargetScalePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "DeleteTreatmentTargetScaleResult";
      readonly treatmentTarget: {
        readonly __typename: "TreatmentTarget";
        readonly id: Ids.TreatmentTargetId;
        readonly measurementPlan: {
          readonly __typename: "MeasurementPlan";
          readonly id: Ids.MeasurementPlanId;
          readonly measurementPlanScales: ReadonlyArray<{
            readonly __typename: "MeasurementPlanScale";
            readonly id: Ids.MeasurementPlanScaleId;
          }>;
        };
      };
    } | null;
  } | null;
};

export type EditTreatmentTargetMutationVariables = Exact<{
  input: EditTreatmentTargetInput;
}>;

export type EditTreatmentTargetMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareEditTreatmentTarget: {
    readonly __typename: "EditTreatmentTargetPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EditTreatmentTargetResult";
      readonly treatmentTarget: {
        readonly __typename: "TreatmentTarget";
        readonly id: Ids.TreatmentTargetId;
        readonly name: string;
      };
    } | null;
  } | null;
};

export type InstituteMeasurementBundlesQueryVariables = Exact<{
  instituteId: Scalars["InstituteId"]["input"];
}>;

export type InstituteMeasurementBundlesQuery = {
  readonly __typename: "Query";
  readonly collaborativeCareTreatmentTargets: {
    readonly __typename: "TreatmentTargetConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "TreatmentTarget";
      readonly id: Ids.TreatmentTargetId;
      readonly name: string;
      readonly measurementPlan: {
        readonly __typename: "MeasurementPlan";
        readonly id: Ids.MeasurementPlanId;
        readonly measurementPlanScales: ReadonlyArray<{
          readonly __typename: "MeasurementPlanScale";
          readonly id: Ids.MeasurementPlanScaleId;
          readonly discontinueSession: number | null;
          readonly maxRepeatInterval: GqlScalars.Interval | null;
          readonly targeting: ScaleTargeting | null;
          readonly onsetTime: GqlScalars.Interval | null;
          readonly scale:
            | {
                readonly __typename: "AdministrableScale";
                readonly defaultParticipants: ReadonlyArray<ParticipantRelationship> | null;
                readonly id: Ids.ScaleId;
                readonly shortname: string | null;
                readonly nanoname: string | null;
                readonly mnemonic: string;
              }
            | {
                readonly __typename: "Subscale";
                readonly id: Ids.ScaleId;
                readonly shortname: string | null;
                readonly nanoname: string | null;
                readonly mnemonic: string;
              };
        }>;
      };
    }>;
  } | null;
  readonly assessmentInstituteScales: {
    readonly __typename: "InstituteScaleConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "InstituteScale";
      readonly id: Ids.InstituteScaleId;
      readonly format: InvitationFormat | null;
      readonly disableAdministration: boolean;
      readonly scale:
        | { readonly __typename: "AdministrableScale"; readonly id: Ids.ScaleId }
        | { readonly __typename: "Subscale"; readonly id: Ids.ScaleId };
    }>;
  } | null;
  readonly collaborativeCareCareEpisodeTreatmentTargets: {
    readonly __typename: "CareEpisodeTreatmentTargetConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "CareEpisodeTreatmentTarget";
      readonly id: Ids.CareEpisodeTreatmentTargetId;
      readonly treatmentTarget: {
        readonly __typename: "TreatmentTarget";
        readonly id: Ids.TreatmentTargetId;
      };
    }>;
  } | null;
};

export type ReplanAllActiveEnrollmentsMutationVariables = Exact<{
  input: ReplanAllActiveEnrollmentInput;
}>;

export type ReplanAllActiveEnrollmentsMutation = {
  readonly __typename: "Mutation";
  readonly collaborativeCareReplanAllActiveEnrollments: {
    readonly __typename: "ReplanAllActiveEnrollmentPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "ReplanAllActiveEnrollmentResult";
      readonly monitoredJob: { readonly __typename: "MonitoredJob"; readonly id: Ids.MonitoredJobId };
    } | null;
  } | null;
};

export type InstituteOrganizationQueryVariables = Exact<{
  organizationId: Scalars["OrganizationId"]["input"];
}>;

export type InstituteOrganizationQuery = {
  readonly __typename: "Query";
  readonly organization: {
    readonly __typename: "Organization";
    readonly id: Ids.OrganizationId;
    readonly name: string;
    readonly shortname: string;
    readonly measurementAllowed: boolean;
    readonly inTrialPeriod: boolean;
    readonly parent: {
      readonly __typename: "Organization";
      readonly id: Ids.OrganizationId;
      readonly name: string;
      readonly shortname: string;
    } | null;
    readonly integrationsDataSourceRecords: ReadonlyArray<{
      readonly __typename: "DataSourceRecord";
      readonly id: Ids.DataSourceRecordId;
      readonly remoteId: string;
    }>;
  } | null;
};

export type InstituteOrganizationListQueryVariables = Exact<{
  instituteId: Scalars["InstituteId"]["input"];
}>;

export type InstituteOrganizationListQuery = {
  readonly __typename: "Query";
  readonly institute: {
    readonly __typename: "Institute";
    readonly id: Ids.InstituteId;
    readonly name: string;
    readonly allOrganizations: ReadonlyArray<{
      readonly __typename: "Organization";
      readonly id: Ids.OrganizationId;
      readonly parentId: Ids.OrganizationId | null;
      readonly name: string;
      readonly shortname: string;
      readonly measurementAllowed: boolean;
      readonly inTrialPeriod: boolean;
    }>;
  } | null;
};

export type InstitutePatientQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
}>;

export type InstitutePatientQuery = {
  readonly __typename: "Query";
  readonly patient: {
    readonly __typename: "Patient";
    readonly id: Ids.PatientId;
    readonly name: string;
    readonly phoneNumber: string | null;
    readonly email: string | null;
    readonly primaryLanguage: string | null;
    readonly gender: Gender | null;
    readonly mrn: string | null;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly dob: Date | null;
    readonly isTest: boolean;
    readonly source: SourceEnum | null;
    readonly autoCreateSessions: boolean | null;
    readonly isProvider: boolean | null;
    readonly genderSource: FieldSourceEnum | null;
    readonly enableNotifications: boolean;
    readonly groupName: string | null;
    readonly lastSsn: string | null;
    readonly maritalStatus: string | null;
    readonly race: string | null;
    readonly researchOther: string | null;
    readonly researchOtherDiagnoses: string | null;
    readonly researchPrimaryDiagnosis: string | null;
    readonly state: string | null;
    readonly veteranStatus: string | null;
    readonly zipCode: string | null;
    readonly ethnicity: string | null;
    readonly identifiers: ReadonlyArray<{
      readonly __typename: "Identifier";
      readonly assigner: string;
      readonly value: string;
    }> | null;
    readonly integrationsDataSourceRecords: ReadonlyArray<{
      readonly __typename: "DataSourceRecord";
      readonly id: Ids.DataSourceRecordId;
      readonly remoteId: string;
    }>;
    readonly user: {
      readonly __typename: "User";
      readonly id: Ids.UserId;
      readonly name: string;
      readonly confirmedAt: Date | null;
      readonly currentSignInAt: Date | null;
      readonly email: string | null;
      readonly emailSource: FieldSourceEnum | null;
      readonly failedAttempts: number;
      readonly firstName: string | null;
      readonly firstNameSource: FieldSourceEnum | null;
      readonly isInternal: boolean;
      readonly language: string | null;
      readonly lastActivityAt: Date | null;
      readonly lastName: string | null;
      readonly lastNameSource: FieldSourceEnum | null;
      readonly lastSignInAt: Date | null;
      readonly lockedAt: Date | null;
      readonly notificationPreference: NotificationPreference | null;
      readonly phoneNumber: string | null;
      readonly phoneNumberSource: FieldSourceEnum | null;
      readonly role: string | null;
      readonly signInCount: number;
      readonly ssoUsername: string | null;
      readonly suffix: string | null;
      readonly timezone: string | null;
      readonly title: string | null;
      readonly username: string | null;
      readonly createdAt: Date;
      readonly updatedAt: Date;
    };
  } | null;
};

export type InstitutePatientAppointmentListQueryVariables = Exact<{
  patientId: InputMaybe<ReadonlyArray<Scalars["PatientId"]["input"]> | Scalars["PatientId"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
}>;

export type InstitutePatientAppointmentListQuery = {
  readonly __typename: "Query";
  readonly schedulingAppointments: {
    readonly __typename: "AppointmentConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Appointment";
      readonly id: Ids.AppointmentId;
      readonly startDate: Date | null;
      readonly status: AppointmentStatus;
      readonly patientSession: {
        readonly __typename: "PatientSession";
        readonly id: Ids.PatientSessionId;
        readonly status: PatientSessionStatus;
        readonly sessionNumber: number | null;
      } | null;
      readonly provider: { readonly __typename: "Provider"; readonly name: string } | null;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type InstitutePatientListQueryVariables = Exact<{
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  sortBy: InputMaybe<PatientSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
}>;

export type InstitutePatientListQuery = {
  readonly __typename: "Query";
  readonly patients: {
    readonly __typename: "PatientConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Patient";
      readonly id: Ids.PatientId;
      readonly gender: Gender | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
      readonly dob: Date | null;
      readonly mrn: string | null;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type InstituteProviderQueryVariables = Exact<{
  providerId: Scalars["ProviderId"]["input"];
}>;

export type InstituteProviderQuery = {
  readonly __typename: "Query";
  readonly provider: {
    readonly __typename: "Provider";
    readonly id: Ids.ProviderId;
    readonly name: string;
    readonly status: string;
    readonly measurementAllowed: boolean;
    readonly title: string | null;
    readonly suffix: string | null;
    readonly defaultRelationship: CareEpisodeProviderRelationship | null;
    readonly practicing: boolean | null;
    readonly user: {
      readonly __typename: "User";
      readonly id: Ids.UserId;
      readonly name: string;
      readonly confirmedAt: Date | null;
      readonly currentSignInAt: Date | null;
      readonly email: string | null;
      readonly emailSource: FieldSourceEnum | null;
      readonly failedAttempts: number;
      readonly firstName: string | null;
      readonly firstNameSource: FieldSourceEnum | null;
      readonly isInternal: boolean;
      readonly language: string | null;
      readonly lastActivityAt: Date | null;
      readonly lastName: string | null;
      readonly lastNameSource: FieldSourceEnum | null;
      readonly lastSignInAt: Date | null;
      readonly lockedAt: Date | null;
      readonly notificationPreference: NotificationPreference | null;
      readonly phoneNumber: string | null;
      readonly phoneNumberSource: FieldSourceEnum | null;
      readonly role: string | null;
      readonly signInCount: number;
      readonly ssoUsername: string | null;
      readonly suffix: string | null;
      readonly timezone: string | null;
      readonly title: string | null;
      readonly username: string | null;
      readonly createdAt: Date;
      readonly updatedAt: Date;
    };
    readonly integrationsDataSourceRecords: ReadonlyArray<{
      readonly __typename: "DataSourceRecord";
      readonly id: Ids.DataSourceRecordId;
      readonly remoteId: string;
    }>;
  } | null;
};

export type InstituteProviderListQueryVariables = Exact<{
  instituteId: InputMaybe<Scalars["InstituteId"]["input"]>;
  sortBy: InputMaybe<ProviderSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
}>;

export type InstituteProviderListQuery = {
  readonly __typename: "Query";
  readonly providers: {
    readonly __typename: "ProviderConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
      readonly status: string;
      readonly measurementAllowed: boolean;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type InstituteListQueryQueryVariables = Exact<{
  sortBy: InputMaybe<InstituteSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
}>;

export type InstituteListQueryQuery = {
  readonly __typename: "Query";
  readonly institutes: {
    readonly __typename: "InstituteConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Institute";
      readonly id: Ids.InstituteId;
      readonly name: string;
      readonly shortcode: string;
      readonly createdAt: Date;
      readonly updatedAt: Date;
      readonly isTest: boolean | null;
      readonly enabled: boolean | null;
      readonly measurementAllowed: boolean;
      readonly featureSet: {
        readonly __typename: "InstituteFeatureSet";
        readonly enableBilling: boolean;
        readonly enableCareUnits: boolean;
        readonly enableConditions: boolean;
        readonly enableDemoQuickAdd: boolean;
        readonly enableExperiments: boolean;
        readonly enableMeasurementDimensions: boolean;
        readonly enableMeasurementPlans: boolean;
        readonly enablePayors: boolean;
        readonly enableQualityIssues: boolean;
        readonly enableTreatmentServices: boolean;
        readonly enableDashboardSchedule: boolean;
        readonly enableAthenaWriteback: boolean;
        readonly enableDecisionSupport: boolean;
        readonly enableCollaborativeCare: boolean;
        readonly enableImplementationTargets: boolean;
        readonly assessmentIncompleteFindings: boolean;
        readonly monthlyAssessmentMissingFindings: boolean;
        readonly enableHealthieChildPatientSwap: boolean;
        readonly enableLocations: boolean;
        readonly enableOptumMetrics: boolean;
        readonly enableCocmEncounters: boolean;
        readonly enableMbcRedesign: boolean;
      };
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type InstituteIntegrationReportQueryVariables = Exact<{
  startDate: Scalars["ISO8601Date"]["input"];
  endDate: Scalars["ISO8601Date"]["input"];
}>;

export type InstituteIntegrationReportQuery = {
  readonly __typename: "Query";
  readonly integrationStatuses: ReadonlyArray<{
    readonly __typename: "IntegrationStatus";
    readonly institute: {
      readonly __typename: "Institute";
      readonly id: Ids.InstituteId;
      readonly name: string;
      readonly customerSupportManager: string | null;
    };
    readonly ehrConnection: {
      readonly __typename: "EhrLink";
      readonly id: Ids.EhrLinkId;
      readonly connectionType: EhrLinkType | null;
      readonly connectionTypeClass: EhrLinkTypeClass | null;
      readonly notes: string | null;
    };
    readonly dates: ReadonlyArray<{
      readonly __typename: "IntegrationStatusDate";
      readonly date: Date;
      readonly success: number;
      readonly total: number;
    }>;
  }> | null;
};

export type UpdateEhrLinkNotesMutationVariables = Exact<{
  input: UpdateEhrLinkNotesInput;
}>;

export type UpdateEhrLinkNotesMutation = {
  readonly __typename: "Mutation";
  readonly updateEhrLinkNotes: {
    readonly __typename: "UpdateEhrLinkNotesPayload";
    readonly success: boolean;
    readonly result: {
      readonly __typename: "UpdateEhrLinkNotesResult";
      readonly ehrLinkId: Ids.EhrLinkId;
      readonly notes: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
  } | null;
};

export type MonitoredJobQueryVariables = Exact<{
  id: Scalars["MonitoredJobId"]["input"];
}>;

export type MonitoredJobQuery = {
  readonly __typename: "Query";
  readonly monitoredJob: {
    readonly __typename: "MonitoredJob";
    readonly id: Ids.MonitoredJobId;
    readonly status: MonitoredJobStatus;
    readonly logOutput: string;
    readonly completion: number | null;
    readonly startedAt: Date | null;
    readonly endedAt: Date | null;
  } | null;
};

export type RunSupportTaskMutationVariables = Exact<{
  input: RunSupportTaskInput;
}>;

export type RunSupportTaskMutation = {
  readonly __typename: "Mutation";
  readonly supportRunTask: {
    readonly __typename: "RunSupportTaskPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "SupportTaskResult";
      readonly result: string | null;
      readonly log: string;
      readonly url: string | null;
      readonly urlContainsPhi: boolean | null;
      readonly monitoredJobId: Ids.MonitoredJobId | null;
    } | null;
  } | null;
};

export type TaskDetailsQueryVariables = Exact<{
  shortcode: Scalars["String"]["input"];
}>;

export type TaskDetailsQuery = {
  readonly __typename: "Query";
  readonly supportTask: {
    readonly __typename: "SupportTask";
    readonly shortcode: string;
    readonly name: string;
    readonly description: string | null;
    readonly arguments: ReadonlyArray<{
      readonly __typename: "SupportTaskArgument";
      readonly code: string;
      readonly name: string;
      readonly fieldType: SupportTaskArgumentType;
      readonly description: string | null;
      readonly required: boolean;
    }>;
  } | null;
};

export type TaskHistoriesQueryVariables = Exact<{
  sortBy: InputMaybe<SupportTaskHistorySortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  userId: InputMaybe<Scalars["UserId"]["input"]>;
  className: InputMaybe<Scalars["String"]["input"]>;
}>;

export type TaskHistoriesQuery = {
  readonly __typename: "Query";
  readonly supportTaskHistories: {
    readonly __typename: "SupportTaskHistoryConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "SupportTaskHistory";
      readonly id: Ids.SupportTaskHistoryId;
      readonly className: string;
      readonly completedAt: Date | null;
      readonly startedAt: Date | null;
      readonly status: SupportTaskHistoryStatus;
      readonly arguments: ReadonlyArray<{
        readonly __typename: "SupportTaskHistoryArgument";
        readonly name: string;
        readonly value: string | null;
      }> | null;
      readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string } | null;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type TaskListQueryVariables = Exact<{ [key: string]: never }>;

export type TaskListQuery = {
  readonly __typename: "Query";
  readonly supportTasks: ReadonlyArray<{
    readonly __typename: "SupportTask";
    readonly shortcode: string;
    readonly name: string;
    readonly description: string | null;
    readonly category: string | null;
    readonly subCategory: string | null;
  }> | null;
};

export type CreateMetricAndExportMutationVariables = Exact<{
  input: CreateMetricAndExportInput;
}>;

export type CreateMetricAndExportMutation = {
  readonly __typename: "Mutation";
  readonly outcomesCreateMetricAndExport: {
    readonly __typename: "CreateMetricAndExportPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateMetricAndExportResult";
      readonly metricDataExport: {
        readonly __typename: "MetricDataExport";
        readonly id: Ids.MetricDataExportId;
        readonly status: MetricDataExportStatus;
      };
      readonly metric: { readonly __typename: "Metric"; readonly id: Ids.MetricId };
    } | null;
  } | null;
};

export type CreateOutcomesMetricMutationVariables = Exact<{
  input: CreateMetricInput;
}>;

export type CreateOutcomesMetricMutation = {
  readonly __typename: "Mutation";
  readonly outcomesCreateMetric: {
    readonly __typename: "CreateMetricPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateMetricResult";
      readonly metric: {
        readonly __typename: "Metric";
        readonly id: Ids.MetricId;
        readonly name: string;
        readonly entityTreeNode: {
          readonly __typename: "EntityTreeNode";
          readonly path: string;
          readonly minAge: number | null;
          readonly maxAge: number | null;
          readonly entity: {
            readonly __typename: "Entity";
            readonly id: Ids.EntityId;
            readonly name: string;
            readonly shortname: string;
            readonly entityType: EntityType;
          };
          readonly segments: ReadonlyArray<{
            readonly __typename: "EntityTreeSegment";
            readonly id: Ids.EntityId;
            readonly path: string;
            readonly entityType: EntityType;
            readonly name: string;
            readonly shortname: string;
            readonly rootPath: string;
          }>;
        };
        readonly configuration:
          | {
              readonly __typename: "TimeBasedMetric";
              readonly timeBasedAggregationType: TimeBasedAggregationType;
              readonly timePeriodType: TimePeriodType;
              readonly timePeriodValue: number | null;
              readonly scorerConfig:
                | {
                    readonly __typename: "MetricMultiScaleScorerConfiguration";
                    readonly scaleScorers: ReadonlyArray<
                      | {
                          readonly __typename: "CategoricalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        }
                      | {
                          readonly __typename: "NumericalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        }
                      | {
                          readonly __typename: "UnscoredScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        }
                    >;
                  }
                | {
                    readonly __typename: "MetricSingleScaleScorerConfiguration";
                    readonly scaleScorer:
                      | {
                          readonly __typename: "CategoricalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        }
                      | {
                          readonly __typename: "NumericalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        }
                      | {
                          readonly __typename: "UnscoredScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        };
                  };
              readonly metricInclusionCriteria: ReadonlyArray<{
                readonly __typename: "MetricInclusionCriterion";
                readonly id: Ids.MetricInclusionCriterionId;
                readonly criterionType: MetricInclusionCriterionType;
                readonly operator: Operator | null;
                readonly stringValue: string | null;
                readonly numberValue: number | null;
                readonly integerValue: number | null;
                readonly severityValues: ReadonlyArray<SeverityCategory> | null;
                readonly trendValue: Trend | null;
                readonly stringArrayValue: ReadonlyArray<string | null> | null;
                readonly integerArrayValue: ReadonlyArray<number | null> | null;
                readonly excludeResults: boolean | null;
              }>;
              readonly metricSuccessCriteria: ReadonlyArray<{
                readonly __typename: "MetricSuccessCriterion";
                readonly id: Ids.MetricSuccessCriterionId;
                readonly criterionType: MetricSuccessCriterionType;
                readonly operator: Operator | null;
                readonly numberValue: number | null;
                readonly stringValue: string | null;
                readonly severityValues: ReadonlyArray<SeverityCategory> | null;
              }>;
            }
          | {
              readonly __typename: "TriggerBasedMetric";
              readonly triggerBasedAggregationType: TriggerBasedAggregationType;
              readonly scorerConfig:
                | {
                    readonly __typename: "MetricMultiScaleScorerConfiguration";
                    readonly scaleScorers: ReadonlyArray<
                      | {
                          readonly __typename: "CategoricalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        }
                      | {
                          readonly __typename: "NumericalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        }
                      | {
                          readonly __typename: "UnscoredScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        }
                    >;
                  }
                | {
                    readonly __typename: "MetricSingleScaleScorerConfiguration";
                    readonly scaleScorer:
                      | {
                          readonly __typename: "CategoricalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        }
                      | {
                          readonly __typename: "NumericalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        }
                      | {
                          readonly __typename: "UnscoredScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        };
                  };
              readonly metricInclusionCriteria: ReadonlyArray<{
                readonly __typename: "MetricInclusionCriterion";
                readonly id: Ids.MetricInclusionCriterionId;
                readonly criterionType: MetricInclusionCriterionType;
                readonly operator: Operator | null;
                readonly stringValue: string | null;
                readonly numberValue: number | null;
                readonly integerValue: number | null;
                readonly severityValues: ReadonlyArray<SeverityCategory> | null;
                readonly trendValue: Trend | null;
                readonly stringArrayValue: ReadonlyArray<string | null> | null;
                readonly integerArrayValue: ReadonlyArray<number | null> | null;
                readonly excludeResults: boolean | null;
              }>;
              readonly metricTriggers: ReadonlyArray<{
                readonly __typename: "MetricTrigger";
                readonly id: Ids.MetricTriggerId;
                readonly triggerType: MetricTriggerType;
                readonly severityValue: SeverityCategory | null;
                readonly trendValue: Trend | null;
                readonly integerValue: number | null;
                readonly stringArrayValue: ReadonlyArray<string> | null;
                readonly stringValue: string | null;
              }>;
            };
      };
    } | null;
  } | null;
};

export type CreateOutcomesMetricDataExportMutationVariables = Exact<{
  input: CreateMetricDataExportInput;
}>;

export type CreateOutcomesMetricDataExportMutation = {
  readonly __typename: "Mutation";
  readonly outcomesCreateMetricDataExport: {
    readonly __typename: "CreateMetricDataExportPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateMetricDataExportResult";
      readonly metricDataExport: {
        readonly __typename: "MetricDataExport";
        readonly id: Ids.MetricDataExportId;
        readonly status: MetricDataExportStatus;
      };
    } | null;
  } | null;
};

export type OutcomesMetricComputedValueQueryVariables = Exact<{
  metricId: Scalars["MetricId"]["input"];
  entityTreeNode: EntityTreeNodeParams;
  dateRange: DateRangeInput;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<MetricComputedValueSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
}>;

export type OutcomesMetricComputedValueQuery = {
  readonly __typename: "Query";
  readonly outcomesMetricComputedValues: {
    readonly __typename: "MetricComputedValueConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "MetricComputedValue";
      readonly id: string;
      readonly treatmentStartDate: Date;
      readonly ageAtMeasurement: number | null;
      readonly ageAtTreatmentStart: number | null;
      readonly daysBetweenTreatmentStartAndBaseline: number | null;
      readonly daysSinceTreatmentStart: number;
      readonly weeksSinceTreatmentStart: number;
      readonly monthsSinceTreatmentStart: number;
      readonly firstDate: Date;
      readonly firstThresholdClass: ScaleThresholdClass | null;
      readonly firstThresholdMnemonic: string | null;
      readonly firstSeverityCategory: SeverityCategory;
      readonly firstClinicalCategory: ClinicalCategory;
      readonly firstValue: number | null;
      readonly sessionTargetDate: Date;
      readonly validity: ComputedValueValidity;
      readonly thresholdClass: ScaleThresholdClass | null;
      readonly thresholdMnemonic: string | null;
      readonly trend: Trend;
      readonly severityCategory: SeverityCategory;
      readonly clinicalCategory: ClinicalCategory;
      readonly value: number | null;
      readonly delta: number | null;
      readonly metInclusionCriteria: boolean;
      readonly metSuccessCriteria: boolean;
      readonly metTrigger: boolean;
      readonly patient: { readonly __typename: "Patient"; readonly id: Ids.PatientId; readonly name: string };
      readonly scale:
        | {
            readonly __typename: "AdministrableScale";
            readonly id: Ids.ScaleId;
            readonly name: string;
            readonly shortname: string | null;
            readonly nanoname: string | null;
          }
        | {
            readonly __typename: "Subscale";
            readonly id: Ids.ScaleId;
            readonly name: string;
            readonly shortname: string | null;
            readonly nanoname: string | null;
          };
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly startCursor: string | null;
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
    };
  } | null;
};

export type OutcomesMetricDataExportDownloadUrlMutationVariables = Exact<{
  input: CreateMetricDataExportDownloadUrlInput;
}>;

export type OutcomesMetricDataExportDownloadUrlMutation = {
  readonly __typename: "Mutation";
  readonly outcomesCreateMetricDataExportDownloadUrl: {
    readonly __typename: "CreateMetricDataExportDownloadUrlPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateMetricDataExportDownloadUrlResult";
      readonly downloadUrl: string;
    } | null;
  } | null;
};

export type OutcomesMetricDataExportQueryVariables = Exact<{
  metricDataExportId: Scalars["MetricDataExportId"]["input"];
}>;

export type OutcomesMetricDataExportQuery = {
  readonly __typename: "Query";
  readonly outcomesMetricDataExport: {
    readonly __typename: "MetricDataExport";
    readonly id: Ids.MetricDataExportId;
    readonly status: MetricDataExportStatus;
    readonly filename: string;
  } | null;
};

export type OutcomesMetricDetailsQueryVariables = Exact<{
  id: Scalars["MetricId"]["input"];
}>;

export type OutcomesMetricDetailsQuery = {
  readonly __typename: "Query";
  readonly outcomesMetric: {
    readonly __typename: "Metric";
    readonly id: Ids.MetricId;
    readonly name: string;
    readonly entityTreeNode: {
      readonly __typename: "EntityTreeNode";
      readonly path: string;
      readonly minAge: number | null;
      readonly maxAge: number | null;
      readonly entity: {
        readonly __typename: "Entity";
        readonly id: Ids.EntityId;
        readonly name: string;
        readonly shortname: string;
        readonly entityType: EntityType;
      };
      readonly segments: ReadonlyArray<{
        readonly __typename: "EntityTreeSegment";
        readonly id: Ids.EntityId;
        readonly path: string;
        readonly entityType: EntityType;
        readonly name: string;
        readonly shortname: string;
        readonly rootPath: string;
      }>;
    };
    readonly configuration:
      | {
          readonly __typename: "TimeBasedMetric";
          readonly timeBasedAggregationType: TimeBasedAggregationType;
          readonly timePeriodType: TimePeriodType;
          readonly timePeriodValue: number | null;
          readonly rollup: MetricRollupType;
          readonly metricSuccessCriteria: ReadonlyArray<{
            readonly __typename: "MetricSuccessCriterion";
            readonly id: Ids.MetricSuccessCriterionId;
            readonly criterionType: MetricSuccessCriterionType;
            readonly operator: Operator | null;
            readonly numberValue: number | null;
            readonly stringValue: string | null;
            readonly integerValue: number | null;
            readonly severityValues: ReadonlyArray<SeverityCategory> | null;
            readonly trendValue: Trend | null;
          }>;
          readonly scorerConfig:
            | {
                readonly __typename: "MetricMultiScaleScorerConfiguration";
                readonly scaleScorers: ReadonlyArray<
                  | {
                      readonly __typename: "CategoricalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                  | {
                      readonly __typename: "NumericalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                  | {
                      readonly __typename: "UnscoredScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                >;
              }
            | {
                readonly __typename: "MetricSingleScaleScorerConfiguration";
                readonly scaleScorer:
                  | {
                      readonly __typename: "CategoricalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly nanoname: string | null;
                            readonly name: string;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly nanoname: string | null;
                            readonly name: string;
                          };
                    }
                  | {
                      readonly __typename: "NumericalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly nanoname: string | null;
                            readonly name: string;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly nanoname: string | null;
                            readonly name: string;
                          };
                    }
                  | {
                      readonly __typename: "UnscoredScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly nanoname: string | null;
                            readonly name: string;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly nanoname: string | null;
                            readonly name: string;
                          };
                    };
              };
          readonly metricInclusionCriteria: ReadonlyArray<{
            readonly __typename: "MetricInclusionCriterion";
            readonly id: Ids.MetricInclusionCriterionId;
            readonly criterionType: MetricInclusionCriterionType;
            readonly operator: Operator | null;
            readonly stringValue: string | null;
            readonly numberValue: number | null;
            readonly integerValue: number | null;
            readonly severityValues: ReadonlyArray<SeverityCategory> | null;
            readonly trendValue: Trend | null;
            readonly stringArrayValue: ReadonlyArray<string | null> | null;
            readonly integerArrayValue: ReadonlyArray<number | null> | null;
            readonly excludeResults: boolean | null;
          }>;
        }
      | {
          readonly __typename: "TriggerBasedMetric";
          readonly triggerBasedAggregationType: TriggerBasedAggregationType;
          readonly rollup: MetricRollupType;
          readonly metricTriggers: ReadonlyArray<{
            readonly __typename: "MetricTrigger";
            readonly id: Ids.MetricTriggerId;
            readonly triggerType: MetricTriggerType;
            readonly severityValue: SeverityCategory | null;
            readonly trendValue: Trend | null;
            readonly integerValue: number | null;
            readonly stringArrayValue: ReadonlyArray<string> | null;
            readonly stringValue: string | null;
          }>;
          readonly scorerConfig:
            | {
                readonly __typename: "MetricMultiScaleScorerConfiguration";
                readonly scaleScorers: ReadonlyArray<
                  | {
                      readonly __typename: "CategoricalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                  | {
                      readonly __typename: "NumericalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                  | {
                      readonly __typename: "UnscoredScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                >;
              }
            | {
                readonly __typename: "MetricSingleScaleScorerConfiguration";
                readonly scaleScorer:
                  | {
                      readonly __typename: "CategoricalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly nanoname: string | null;
                            readonly name: string;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly nanoname: string | null;
                            readonly name: string;
                          };
                    }
                  | {
                      readonly __typename: "NumericalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly nanoname: string | null;
                            readonly name: string;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly nanoname: string | null;
                            readonly name: string;
                          };
                    }
                  | {
                      readonly __typename: "UnscoredScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly nanoname: string | null;
                            readonly name: string;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly nanoname: string | null;
                            readonly name: string;
                          };
                    };
              };
          readonly metricInclusionCriteria: ReadonlyArray<{
            readonly __typename: "MetricInclusionCriterion";
            readonly id: Ids.MetricInclusionCriterionId;
            readonly criterionType: MetricInclusionCriterionType;
            readonly operator: Operator | null;
            readonly stringValue: string | null;
            readonly numberValue: number | null;
            readonly integerValue: number | null;
            readonly severityValues: ReadonlyArray<SeverityCategory> | null;
            readonly trendValue: Trend | null;
            readonly stringArrayValue: ReadonlyArray<string | null> | null;
            readonly integerArrayValue: ReadonlyArray<number | null> | null;
            readonly excludeResults: boolean | null;
          }>;
        };
  } | null;
};

export type OutcomesMetricSummaryDataQueryVariables = Exact<{
  metricId: Scalars["MetricId"]["input"];
  drilldownMetric: DrilldownMetricParams;
  scaleScorerId: InputMaybe<Scalars["ScaleScorerId"]["input"]>;
}>;

export type OutcomesMetricSummaryDataQuery = {
  readonly __typename: "Query";
  readonly outcomesMetricSummaryData: {
    readonly __typename: "MetricSummaryData";
    readonly value: number | null;
    readonly totalRows: number;
    readonly eligibleRows: number;
    readonly successfulRows: number;
    readonly triggerRows: number;
    readonly rowsInTimePeriod: number;
    readonly reportEntityTreeNodes: ReadonlyArray<{
      readonly __typename: "ReportEntityTreeNode";
      readonly graphInterval: DrilldownMetricGraphInterval | null;
      readonly entityTreeNode: {
        readonly __typename: "EntityTreeNode";
        readonly path: string;
        readonly entity: {
          readonly __typename: "Entity";
          readonly id: Ids.EntityId;
          readonly name: string;
          readonly shortname: string;
          readonly entityType: EntityType;
        };
      };
      readonly summaryData: ReadonlyArray<{
        readonly __typename: "ReportSummaryDataField";
        readonly name: string;
        readonly value: number | null;
      }>;
      readonly summaryGraphData: ReadonlyArray<{
        readonly __typename: "ReportSummaryGraphData";
        readonly name: string;
        readonly points: ReadonlyArray<{
          readonly __typename: "ChartData";
          readonly date: Date;
          readonly value: number;
          readonly label: number | null;
        }>;
      }> | null;
    }>;
  };
};

export type OutcomesMetricTemplateDetailsQueryVariables = Exact<{
  id: Scalars["MetricId"]["input"];
}>;

export type OutcomesMetricTemplateDetailsQuery = {
  readonly __typename: "Query";
  readonly outcomesMetricTemplate: {
    readonly __typename: "MetricTemplate";
    readonly id: Ids.MetricId;
    readonly name: string;
    readonly configuration:
      | {
          readonly __typename: "TimeBasedMetric";
          readonly timeBasedAggregationType: TimeBasedAggregationType;
          readonly timePeriodType: TimePeriodType;
          readonly timePeriodValue: number | null;
          readonly rollup: MetricRollupType;
          readonly metricSuccessCriteria: ReadonlyArray<{
            readonly __typename: "MetricSuccessCriterion";
            readonly id: Ids.MetricSuccessCriterionId;
            readonly criterionType: MetricSuccessCriterionType;
            readonly operator: Operator | null;
            readonly numberValue: number | null;
            readonly stringValue: string | null;
            readonly integerValue: number | null;
            readonly severityValues: ReadonlyArray<SeverityCategory> | null;
            readonly trendValue: Trend | null;
          }>;
          readonly scorerConfig:
            | {
                readonly __typename: "MetricMultiScaleScorerConfiguration";
                readonly scaleScorers: ReadonlyArray<
                  | {
                      readonly __typename: "CategoricalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                  | {
                      readonly __typename: "NumericalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                  | {
                      readonly __typename: "UnscoredScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                >;
              }
            | {
                readonly __typename: "MetricSingleScaleScorerConfiguration";
                readonly scaleScorer:
                  | {
                      readonly __typename: "CategoricalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                  | {
                      readonly __typename: "NumericalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                  | {
                      readonly __typename: "UnscoredScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    };
              };
          readonly metricInclusionCriteria: ReadonlyArray<{
            readonly __typename: "MetricInclusionCriterion";
            readonly id: Ids.MetricInclusionCriterionId;
            readonly criterionType: MetricInclusionCriterionType;
            readonly operator: Operator | null;
            readonly stringValue: string | null;
            readonly numberValue: number | null;
            readonly integerValue: number | null;
            readonly severityValues: ReadonlyArray<SeverityCategory> | null;
            readonly trendValue: Trend | null;
            readonly stringArrayValue: ReadonlyArray<string | null> | null;
            readonly integerArrayValue: ReadonlyArray<number | null> | null;
            readonly excludeResults: boolean | null;
          }>;
        }
      | {
          readonly __typename: "TriggerBasedMetric";
          readonly triggerBasedAggregationType: TriggerBasedAggregationType;
          readonly rollup: MetricRollupType;
          readonly metricTriggers: ReadonlyArray<{
            readonly __typename: "MetricTrigger";
            readonly id: Ids.MetricTriggerId;
            readonly triggerType: MetricTriggerType;
            readonly severityValue: SeverityCategory | null;
            readonly trendValue: Trend | null;
            readonly integerValue: number | null;
            readonly stringArrayValue: ReadonlyArray<string> | null;
            readonly stringValue: string | null;
          }>;
          readonly scorerConfig:
            | {
                readonly __typename: "MetricMultiScaleScorerConfiguration";
                readonly scaleScorers: ReadonlyArray<
                  | {
                      readonly __typename: "CategoricalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                  | {
                      readonly __typename: "NumericalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                  | {
                      readonly __typename: "UnscoredScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                >;
              }
            | {
                readonly __typename: "MetricSingleScaleScorerConfiguration";
                readonly scaleScorer:
                  | {
                      readonly __typename: "CategoricalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                  | {
                      readonly __typename: "NumericalScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    }
                  | {
                      readonly __typename: "UnscoredScaleScorer";
                      readonly id: Ids.ScaleScorerId;
                      readonly scale:
                        | {
                            readonly __typename: "AdministrableScale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          }
                        | {
                            readonly __typename: "Subscale";
                            readonly shortname: string | null;
                            readonly name: string;
                            readonly nanoname: string | null;
                          };
                    };
              };
          readonly metricInclusionCriteria: ReadonlyArray<{
            readonly __typename: "MetricInclusionCriterion";
            readonly id: Ids.MetricInclusionCriterionId;
            readonly criterionType: MetricInclusionCriterionType;
            readonly operator: Operator | null;
            readonly stringValue: string | null;
            readonly numberValue: number | null;
            readonly integerValue: number | null;
            readonly severityValues: ReadonlyArray<SeverityCategory> | null;
            readonly trendValue: Trend | null;
            readonly stringArrayValue: ReadonlyArray<string | null> | null;
            readonly integerArrayValue: ReadonlyArray<number | null> | null;
            readonly excludeResults: boolean | null;
          }>;
        };
  } | null;
};

export type OutcomesMetricTemplateNameQueryVariables = Exact<{
  id: Scalars["MetricId"]["input"];
}>;

export type OutcomesMetricTemplateNameQuery = {
  readonly __typename: "Query";
  readonly outcomesMetricTemplate: {
    readonly __typename: "MetricTemplate";
    readonly id: Ids.MetricId;
    readonly name: string;
  } | null;
};

export type OutcomesMetricTemplatesQueryVariables = Exact<{
  search: InputMaybe<Scalars["String"]["input"]>;
}>;

export type OutcomesMetricTemplatesQuery = {
  readonly __typename: "Query";
  readonly outcomesMetricTemplates: {
    readonly __typename: "MetricTemplateConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "MetricTemplate";
      readonly id: Ids.MetricId;
      readonly name: string;
    }>;
  } | null;
};

export type MetricDetailsFragment = {
  readonly __typename: "Metric";
  readonly id: Ids.MetricId;
  readonly name: string;
  readonly entityTreeNode: {
    readonly __typename: "EntityTreeNode";
    readonly path: string;
    readonly minAge: number | null;
    readonly maxAge: number | null;
    readonly entity: {
      readonly __typename: "Entity";
      readonly id: Ids.EntityId;
      readonly name: string;
      readonly shortname: string;
      readonly entityType: EntityType;
    };
    readonly segments: ReadonlyArray<{
      readonly __typename: "EntityTreeSegment";
      readonly id: Ids.EntityId;
      readonly path: string;
      readonly entityType: EntityType;
      readonly name: string;
      readonly shortname: string;
      readonly rootPath: string;
    }>;
  };
  readonly configuration:
    | {
        readonly __typename: "TimeBasedMetric";
        readonly timeBasedAggregationType: TimeBasedAggregationType;
        readonly timePeriodType: TimePeriodType;
        readonly timePeriodValue: number | null;
        readonly scorerConfig:
          | {
              readonly __typename: "MetricMultiScaleScorerConfiguration";
              readonly scaleScorers: ReadonlyArray<
                | {
                    readonly __typename: "CategoricalScaleScorer";
                    readonly id: Ids.ScaleScorerId;
                    readonly scale:
                      | {
                          readonly __typename: "AdministrableScale";
                          readonly shortname: string | null;
                          readonly name: string;
                          readonly nanoname: string | null;
                        }
                      | {
                          readonly __typename: "Subscale";
                          readonly shortname: string | null;
                          readonly name: string;
                          readonly nanoname: string | null;
                        };
                  }
                | {
                    readonly __typename: "NumericalScaleScorer";
                    readonly id: Ids.ScaleScorerId;
                    readonly scale:
                      | {
                          readonly __typename: "AdministrableScale";
                          readonly shortname: string | null;
                          readonly name: string;
                          readonly nanoname: string | null;
                        }
                      | {
                          readonly __typename: "Subscale";
                          readonly shortname: string | null;
                          readonly name: string;
                          readonly nanoname: string | null;
                        };
                  }
                | {
                    readonly __typename: "UnscoredScaleScorer";
                    readonly id: Ids.ScaleScorerId;
                    readonly scale:
                      | {
                          readonly __typename: "AdministrableScale";
                          readonly shortname: string | null;
                          readonly name: string;
                          readonly nanoname: string | null;
                        }
                      | {
                          readonly __typename: "Subscale";
                          readonly shortname: string | null;
                          readonly name: string;
                          readonly nanoname: string | null;
                        };
                  }
              >;
            }
          | {
              readonly __typename: "MetricSingleScaleScorerConfiguration";
              readonly scaleScorer:
                | {
                    readonly __typename: "CategoricalScaleScorer";
                    readonly id: Ids.ScaleScorerId;
                    readonly scale:
                      | {
                          readonly __typename: "AdministrableScale";
                          readonly shortname: string | null;
                          readonly name: string;
                          readonly nanoname: string | null;
                        }
                      | {
                          readonly __typename: "Subscale";
                          readonly shortname: string | null;
                          readonly name: string;
                          readonly nanoname: string | null;
                        };
                  }
                | {
                    readonly __typename: "NumericalScaleScorer";
                    readonly id: Ids.ScaleScorerId;
                    readonly scale:
                      | {
                          readonly __typename: "AdministrableScale";
                          readonly shortname: string | null;
                          readonly name: string;
                          readonly nanoname: string | null;
                        }
                      | {
                          readonly __typename: "Subscale";
                          readonly shortname: string | null;
                          readonly name: string;
                          readonly nanoname: string | null;
                        };
                  }
                | {
                    readonly __typename: "UnscoredScaleScorer";
                    readonly id: Ids.ScaleScorerId;
                    readonly scale:
                      | {
                          readonly __typename: "AdministrableScale";
                          readonly shortname: string | null;
                          readonly name: string;
                          readonly nanoname: string | null;
                        }
                      | {
                          readonly __typename: "Subscale";
                          readonly shortname: string | null;
                          readonly name: string;
                          readonly nanoname: string | null;
                        };
                  };
            };
        readonly metricInclusionCriteria: ReadonlyArray<{
          readonly __typename: "MetricInclusionCriterion";
          readonly id: Ids.MetricInclusionCriterionId;
          readonly criterionType: MetricInclusionCriterionType;
          readonly operator: Operator | null;
          readonly stringValue: string | null;
          readonly numberValue: number | null;
          readonly integerValue: number | null;
          readonly severityValues: ReadonlyArray<SeverityCategory> | null;
          readonly trendValue: Trend | null;
          readonly stringArrayValue: ReadonlyArray<string | null> | null;
          readonly integerArrayValue: ReadonlyArray<number | null> | null;
          readonly excludeResults: boolean | null;
        }>;
        readonly metricSuccessCriteria: ReadonlyArray<{
          readonly __typename: "MetricSuccessCriterion";
          readonly id: Ids.MetricSuccessCriterionId;
          readonly criterionType: MetricSuccessCriterionType;
          readonly operator: Operator | null;
          readonly numberValue: number | null;
          readonly stringValue: string | null;
          readonly severityValues: ReadonlyArray<SeverityCategory> | null;
        }>;
      }
    | {
        readonly __typename: "TriggerBasedMetric";
        readonly triggerBasedAggregationType: TriggerBasedAggregationType;
        readonly scorerConfig:
          | {
              readonly __typename: "MetricMultiScaleScorerConfiguration";
              readonly scaleScorers: ReadonlyArray<
                | {
                    readonly __typename: "CategoricalScaleScorer";
                    readonly id: Ids.ScaleScorerId;
                    readonly scale:
                      | {
                          readonly __typename: "AdministrableScale";
                          readonly shortname: string | null;
                          readonly name: string;
                        }
                      | {
                          readonly __typename: "Subscale";
                          readonly shortname: string | null;
                          readonly name: string;
                        };
                  }
                | {
                    readonly __typename: "NumericalScaleScorer";
                    readonly id: Ids.ScaleScorerId;
                    readonly scale:
                      | {
                          readonly __typename: "AdministrableScale";
                          readonly shortname: string | null;
                          readonly name: string;
                        }
                      | {
                          readonly __typename: "Subscale";
                          readonly shortname: string | null;
                          readonly name: string;
                        };
                  }
                | {
                    readonly __typename: "UnscoredScaleScorer";
                    readonly id: Ids.ScaleScorerId;
                    readonly scale:
                      | {
                          readonly __typename: "AdministrableScale";
                          readonly shortname: string | null;
                          readonly name: string;
                        }
                      | {
                          readonly __typename: "Subscale";
                          readonly shortname: string | null;
                          readonly name: string;
                        };
                  }
              >;
            }
          | {
              readonly __typename: "MetricSingleScaleScorerConfiguration";
              readonly scaleScorer:
                | {
                    readonly __typename: "CategoricalScaleScorer";
                    readonly id: Ids.ScaleScorerId;
                    readonly scale:
                      | {
                          readonly __typename: "AdministrableScale";
                          readonly shortname: string | null;
                          readonly name: string;
                        }
                      | {
                          readonly __typename: "Subscale";
                          readonly shortname: string | null;
                          readonly name: string;
                        };
                  }
                | {
                    readonly __typename: "NumericalScaleScorer";
                    readonly id: Ids.ScaleScorerId;
                    readonly scale:
                      | {
                          readonly __typename: "AdministrableScale";
                          readonly shortname: string | null;
                          readonly name: string;
                        }
                      | {
                          readonly __typename: "Subscale";
                          readonly shortname: string | null;
                          readonly name: string;
                        };
                  }
                | {
                    readonly __typename: "UnscoredScaleScorer";
                    readonly id: Ids.ScaleScorerId;
                    readonly scale:
                      | {
                          readonly __typename: "AdministrableScale";
                          readonly shortname: string | null;
                          readonly name: string;
                        }
                      | {
                          readonly __typename: "Subscale";
                          readonly shortname: string | null;
                          readonly name: string;
                        };
                  };
            };
        readonly metricInclusionCriteria: ReadonlyArray<{
          readonly __typename: "MetricInclusionCriterion";
          readonly id: Ids.MetricInclusionCriterionId;
          readonly criterionType: MetricInclusionCriterionType;
          readonly operator: Operator | null;
          readonly stringValue: string | null;
          readonly numberValue: number | null;
          readonly integerValue: number | null;
          readonly severityValues: ReadonlyArray<SeverityCategory> | null;
          readonly trendValue: Trend | null;
          readonly stringArrayValue: ReadonlyArray<string | null> | null;
          readonly integerArrayValue: ReadonlyArray<number | null> | null;
          readonly excludeResults: boolean | null;
        }>;
        readonly metricTriggers: ReadonlyArray<{
          readonly __typename: "MetricTrigger";
          readonly id: Ids.MetricTriggerId;
          readonly triggerType: MetricTriggerType;
          readonly severityValue: SeverityCategory | null;
          readonly trendValue: Trend | null;
          readonly integerValue: number | null;
          readonly stringArrayValue: ReadonlyArray<string> | null;
          readonly stringValue: string | null;
        }>;
      };
};

export type TimeBasedMetricDetailsFragment = {
  readonly __typename: "TimeBasedMetric";
  readonly timeBasedAggregationType: TimeBasedAggregationType;
  readonly timePeriodType: TimePeriodType;
  readonly timePeriodValue: number | null;
  readonly scorerConfig:
    | {
        readonly __typename: "MetricMultiScaleScorerConfiguration";
        readonly scaleScorers: ReadonlyArray<
          | {
              readonly __typename: "CategoricalScaleScorer";
              readonly id: Ids.ScaleScorerId;
              readonly scale:
                | {
                    readonly __typename: "AdministrableScale";
                    readonly shortname: string | null;
                    readonly name: string;
                    readonly nanoname: string | null;
                  }
                | {
                    readonly __typename: "Subscale";
                    readonly shortname: string | null;
                    readonly name: string;
                    readonly nanoname: string | null;
                  };
            }
          | {
              readonly __typename: "NumericalScaleScorer";
              readonly id: Ids.ScaleScorerId;
              readonly scale:
                | {
                    readonly __typename: "AdministrableScale";
                    readonly shortname: string | null;
                    readonly name: string;
                    readonly nanoname: string | null;
                  }
                | {
                    readonly __typename: "Subscale";
                    readonly shortname: string | null;
                    readonly name: string;
                    readonly nanoname: string | null;
                  };
            }
          | {
              readonly __typename: "UnscoredScaleScorer";
              readonly id: Ids.ScaleScorerId;
              readonly scale:
                | {
                    readonly __typename: "AdministrableScale";
                    readonly shortname: string | null;
                    readonly name: string;
                    readonly nanoname: string | null;
                  }
                | {
                    readonly __typename: "Subscale";
                    readonly shortname: string | null;
                    readonly name: string;
                    readonly nanoname: string | null;
                  };
            }
        >;
      }
    | {
        readonly __typename: "MetricSingleScaleScorerConfiguration";
        readonly scaleScorer:
          | {
              readonly __typename: "CategoricalScaleScorer";
              readonly id: Ids.ScaleScorerId;
              readonly scale:
                | {
                    readonly __typename: "AdministrableScale";
                    readonly shortname: string | null;
                    readonly name: string;
                    readonly nanoname: string | null;
                  }
                | {
                    readonly __typename: "Subscale";
                    readonly shortname: string | null;
                    readonly name: string;
                    readonly nanoname: string | null;
                  };
            }
          | {
              readonly __typename: "NumericalScaleScorer";
              readonly id: Ids.ScaleScorerId;
              readonly scale:
                | {
                    readonly __typename: "AdministrableScale";
                    readonly shortname: string | null;
                    readonly name: string;
                    readonly nanoname: string | null;
                  }
                | {
                    readonly __typename: "Subscale";
                    readonly shortname: string | null;
                    readonly name: string;
                    readonly nanoname: string | null;
                  };
            }
          | {
              readonly __typename: "UnscoredScaleScorer";
              readonly id: Ids.ScaleScorerId;
              readonly scale:
                | {
                    readonly __typename: "AdministrableScale";
                    readonly shortname: string | null;
                    readonly name: string;
                    readonly nanoname: string | null;
                  }
                | {
                    readonly __typename: "Subscale";
                    readonly shortname: string | null;
                    readonly name: string;
                    readonly nanoname: string | null;
                  };
            };
      };
  readonly metricInclusionCriteria: ReadonlyArray<{
    readonly __typename: "MetricInclusionCriterion";
    readonly id: Ids.MetricInclusionCriterionId;
    readonly criterionType: MetricInclusionCriterionType;
    readonly operator: Operator | null;
    readonly stringValue: string | null;
    readonly numberValue: number | null;
    readonly integerValue: number | null;
    readonly severityValues: ReadonlyArray<SeverityCategory> | null;
    readonly trendValue: Trend | null;
    readonly stringArrayValue: ReadonlyArray<string | null> | null;
    readonly integerArrayValue: ReadonlyArray<number | null> | null;
    readonly excludeResults: boolean | null;
  }>;
  readonly metricSuccessCriteria: ReadonlyArray<{
    readonly __typename: "MetricSuccessCriterion";
    readonly id: Ids.MetricSuccessCriterionId;
    readonly criterionType: MetricSuccessCriterionType;
    readonly operator: Operator | null;
    readonly numberValue: number | null;
    readonly stringValue: string | null;
    readonly severityValues: ReadonlyArray<SeverityCategory> | null;
  }>;
};

export type TriggerBasedMetricDetailsFragment = {
  readonly __typename: "TriggerBasedMetric";
  readonly triggerBasedAggregationType: TriggerBasedAggregationType;
  readonly scorerConfig:
    | {
        readonly __typename: "MetricMultiScaleScorerConfiguration";
        readonly scaleScorers: ReadonlyArray<
          | {
              readonly __typename: "CategoricalScaleScorer";
              readonly id: Ids.ScaleScorerId;
              readonly scale:
                | {
                    readonly __typename: "AdministrableScale";
                    readonly shortname: string | null;
                    readonly name: string;
                  }
                | {
                    readonly __typename: "Subscale";
                    readonly shortname: string | null;
                    readonly name: string;
                  };
            }
          | {
              readonly __typename: "NumericalScaleScorer";
              readonly id: Ids.ScaleScorerId;
              readonly scale:
                | {
                    readonly __typename: "AdministrableScale";
                    readonly shortname: string | null;
                    readonly name: string;
                  }
                | {
                    readonly __typename: "Subscale";
                    readonly shortname: string | null;
                    readonly name: string;
                  };
            }
          | {
              readonly __typename: "UnscoredScaleScorer";
              readonly id: Ids.ScaleScorerId;
              readonly scale:
                | {
                    readonly __typename: "AdministrableScale";
                    readonly shortname: string | null;
                    readonly name: string;
                  }
                | {
                    readonly __typename: "Subscale";
                    readonly shortname: string | null;
                    readonly name: string;
                  };
            }
        >;
      }
    | {
        readonly __typename: "MetricSingleScaleScorerConfiguration";
        readonly scaleScorer:
          | {
              readonly __typename: "CategoricalScaleScorer";
              readonly id: Ids.ScaleScorerId;
              readonly scale:
                | {
                    readonly __typename: "AdministrableScale";
                    readonly shortname: string | null;
                    readonly name: string;
                  }
                | {
                    readonly __typename: "Subscale";
                    readonly shortname: string | null;
                    readonly name: string;
                  };
            }
          | {
              readonly __typename: "NumericalScaleScorer";
              readonly id: Ids.ScaleScorerId;
              readonly scale:
                | {
                    readonly __typename: "AdministrableScale";
                    readonly shortname: string | null;
                    readonly name: string;
                  }
                | {
                    readonly __typename: "Subscale";
                    readonly shortname: string | null;
                    readonly name: string;
                  };
            }
          | {
              readonly __typename: "UnscoredScaleScorer";
              readonly id: Ids.ScaleScorerId;
              readonly scale:
                | {
                    readonly __typename: "AdministrableScale";
                    readonly shortname: string | null;
                    readonly name: string;
                  }
                | {
                    readonly __typename: "Subscale";
                    readonly shortname: string | null;
                    readonly name: string;
                  };
            };
      };
  readonly metricInclusionCriteria: ReadonlyArray<{
    readonly __typename: "MetricInclusionCriterion";
    readonly id: Ids.MetricInclusionCriterionId;
    readonly criterionType: MetricInclusionCriterionType;
    readonly operator: Operator | null;
    readonly stringValue: string | null;
    readonly numberValue: number | null;
    readonly integerValue: number | null;
    readonly severityValues: ReadonlyArray<SeverityCategory> | null;
    readonly trendValue: Trend | null;
    readonly stringArrayValue: ReadonlyArray<string | null> | null;
    readonly integerArrayValue: ReadonlyArray<number | null> | null;
    readonly excludeResults: boolean | null;
  }>;
  readonly metricTriggers: ReadonlyArray<{
    readonly __typename: "MetricTrigger";
    readonly id: Ids.MetricTriggerId;
    readonly triggerType: MetricTriggerType;
    readonly severityValue: SeverityCategory | null;
    readonly trendValue: Trend | null;
    readonly integerValue: number | null;
    readonly stringArrayValue: ReadonlyArray<string> | null;
    readonly stringValue: string | null;
  }>;
};

export type OutcomesMetricsQueryVariables = Exact<{
  availableForNode: InputMaybe<EntityTreeNodeParams>;
  entityQuery: InputMaybe<EntityTreeNodeQueryParams>;
  smartSearch: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type OutcomesMetricsQuery = {
  readonly __typename: "Query";
  readonly outcomesMetrics: {
    readonly __typename: "MetricConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Metric";
      readonly id: Ids.MetricId;
      readonly name: string;
      readonly entityTreeNode: {
        readonly __typename: "EntityTreeNode";
        readonly path: string;
        readonly minAge: number | null;
        readonly maxAge: number | null;
        readonly entity: {
          readonly __typename: "Entity";
          readonly id: Ids.EntityId;
          readonly name: string;
          readonly shortname: string;
          readonly entityType: EntityType;
        };
        readonly segments: ReadonlyArray<{
          readonly __typename: "EntityTreeSegment";
          readonly id: Ids.EntityId;
          readonly path: string;
          readonly entityType: EntityType;
          readonly name: string;
          readonly shortname: string;
          readonly rootPath: string;
        }>;
      };
      readonly configuration:
        | {
            readonly __typename: "TimeBasedMetric";
            readonly timeBasedAggregationType: TimeBasedAggregationType;
            readonly timePeriodType: TimePeriodType;
            readonly timePeriodValue: number | null;
            readonly rollup: MetricRollupType;
            readonly metricSuccessCriteria: ReadonlyArray<{
              readonly __typename: "MetricSuccessCriterion";
              readonly id: Ids.MetricSuccessCriterionId;
              readonly criterionType: MetricSuccessCriterionType;
              readonly operator: Operator | null;
              readonly numberValue: number | null;
              readonly stringValue: string | null;
              readonly integerValue: number | null;
              readonly severityValues: ReadonlyArray<SeverityCategory> | null;
              readonly trendValue: Trend | null;
            }>;
            readonly scorerConfig:
              | {
                  readonly __typename: "MetricMultiScaleScorerConfiguration";
                  readonly scaleScorers: ReadonlyArray<
                    | {
                        readonly __typename: "CategoricalScaleScorer";
                        readonly id: Ids.ScaleScorerId;
                        readonly scale:
                          | {
                              readonly __typename: "AdministrableScale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            }
                          | {
                              readonly __typename: "Subscale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            };
                      }
                    | {
                        readonly __typename: "NumericalScaleScorer";
                        readonly id: Ids.ScaleScorerId;
                        readonly scale:
                          | {
                              readonly __typename: "AdministrableScale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            }
                          | {
                              readonly __typename: "Subscale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            };
                      }
                    | {
                        readonly __typename: "UnscoredScaleScorer";
                        readonly id: Ids.ScaleScorerId;
                        readonly scale:
                          | {
                              readonly __typename: "AdministrableScale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            }
                          | {
                              readonly __typename: "Subscale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            };
                      }
                  >;
                }
              | {
                  readonly __typename: "MetricSingleScaleScorerConfiguration";
                  readonly scaleScorer:
                    | {
                        readonly __typename: "CategoricalScaleScorer";
                        readonly id: Ids.ScaleScorerId;
                        readonly scale:
                          | {
                              readonly __typename: "AdministrableScale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            }
                          | {
                              readonly __typename: "Subscale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            };
                      }
                    | {
                        readonly __typename: "NumericalScaleScorer";
                        readonly id: Ids.ScaleScorerId;
                        readonly scale:
                          | {
                              readonly __typename: "AdministrableScale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            }
                          | {
                              readonly __typename: "Subscale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            };
                      }
                    | {
                        readonly __typename: "UnscoredScaleScorer";
                        readonly id: Ids.ScaleScorerId;
                        readonly scale:
                          | {
                              readonly __typename: "AdministrableScale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            }
                          | {
                              readonly __typename: "Subscale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            };
                      };
                };
            readonly metricInclusionCriteria: ReadonlyArray<{
              readonly __typename: "MetricInclusionCriterion";
              readonly id: Ids.MetricInclusionCriterionId;
              readonly criterionType: MetricInclusionCriterionType;
              readonly operator: Operator | null;
              readonly stringValue: string | null;
              readonly numberValue: number | null;
              readonly integerValue: number | null;
              readonly severityValues: ReadonlyArray<SeverityCategory> | null;
              readonly trendValue: Trend | null;
              readonly stringArrayValue: ReadonlyArray<string | null> | null;
              readonly integerArrayValue: ReadonlyArray<number | null> | null;
              readonly excludeResults: boolean | null;
            }>;
          }
        | {
            readonly __typename: "TriggerBasedMetric";
            readonly triggerBasedAggregationType: TriggerBasedAggregationType;
            readonly rollup: MetricRollupType;
            readonly metricTriggers: ReadonlyArray<{
              readonly __typename: "MetricTrigger";
              readonly id: Ids.MetricTriggerId;
              readonly triggerType: MetricTriggerType;
              readonly severityValue: SeverityCategory | null;
              readonly trendValue: Trend | null;
              readonly integerValue: number | null;
              readonly stringArrayValue: ReadonlyArray<string> | null;
              readonly stringValue: string | null;
            }>;
            readonly scorerConfig:
              | {
                  readonly __typename: "MetricMultiScaleScorerConfiguration";
                  readonly scaleScorers: ReadonlyArray<
                    | {
                        readonly __typename: "CategoricalScaleScorer";
                        readonly id: Ids.ScaleScorerId;
                        readonly scale:
                          | {
                              readonly __typename: "AdministrableScale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            }
                          | {
                              readonly __typename: "Subscale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            };
                      }
                    | {
                        readonly __typename: "NumericalScaleScorer";
                        readonly id: Ids.ScaleScorerId;
                        readonly scale:
                          | {
                              readonly __typename: "AdministrableScale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            }
                          | {
                              readonly __typename: "Subscale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            };
                      }
                    | {
                        readonly __typename: "UnscoredScaleScorer";
                        readonly id: Ids.ScaleScorerId;
                        readonly scale:
                          | {
                              readonly __typename: "AdministrableScale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            }
                          | {
                              readonly __typename: "Subscale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            };
                      }
                  >;
                }
              | {
                  readonly __typename: "MetricSingleScaleScorerConfiguration";
                  readonly scaleScorer:
                    | {
                        readonly __typename: "CategoricalScaleScorer";
                        readonly id: Ids.ScaleScorerId;
                        readonly scale:
                          | {
                              readonly __typename: "AdministrableScale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            }
                          | {
                              readonly __typename: "Subscale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            };
                      }
                    | {
                        readonly __typename: "NumericalScaleScorer";
                        readonly id: Ids.ScaleScorerId;
                        readonly scale:
                          | {
                              readonly __typename: "AdministrableScale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            }
                          | {
                              readonly __typename: "Subscale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            };
                      }
                    | {
                        readonly __typename: "UnscoredScaleScorer";
                        readonly id: Ids.ScaleScorerId;
                        readonly scale:
                          | {
                              readonly __typename: "AdministrableScale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            }
                          | {
                              readonly __typename: "Subscale";
                              readonly shortname: string | null;
                              readonly name: string;
                              readonly nanoname: string | null;
                            };
                      };
                };
            readonly metricInclusionCriteria: ReadonlyArray<{
              readonly __typename: "MetricInclusionCriterion";
              readonly id: Ids.MetricInclusionCriterionId;
              readonly criterionType: MetricInclusionCriterionType;
              readonly operator: Operator | null;
              readonly stringValue: string | null;
              readonly numberValue: number | null;
              readonly integerValue: number | null;
              readonly severityValues: ReadonlyArray<SeverityCategory> | null;
              readonly trendValue: Trend | null;
              readonly stringArrayValue: ReadonlyArray<string | null> | null;
              readonly integerArrayValue: ReadonlyArray<number | null> | null;
              readonly excludeResults: boolean | null;
            }>;
          };
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type OutcomesTransientMetricComputedValueQueryVariables = Exact<{
  metricParams: MetricParams;
  entityTreeNode: EntityTreeNodeParams;
  dateRange: DateRangeInput;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<MetricComputedValueSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
}>;

export type OutcomesTransientMetricComputedValueQuery = {
  readonly __typename: "Query";
  readonly outcomesTransientMetricComputedValues: {
    readonly __typename: "MetricComputedValueConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "MetricComputedValue";
      readonly id: string;
      readonly treatmentStartDate: Date;
      readonly ageAtMeasurement: number | null;
      readonly ageAtTreatmentStart: number | null;
      readonly daysBetweenTreatmentStartAndBaseline: number | null;
      readonly daysSinceTreatmentStart: number;
      readonly weeksSinceTreatmentStart: number;
      readonly monthsSinceTreatmentStart: number;
      readonly firstDate: Date;
      readonly firstThresholdClass: ScaleThresholdClass | null;
      readonly firstThresholdMnemonic: string | null;
      readonly firstSeverityCategory: SeverityCategory;
      readonly firstClinicalCategory: ClinicalCategory;
      readonly firstValue: number | null;
      readonly sessionTargetDate: Date;
      readonly validity: ComputedValueValidity;
      readonly thresholdClass: ScaleThresholdClass | null;
      readonly thresholdMnemonic: string | null;
      readonly trend: Trend;
      readonly severityCategory: SeverityCategory;
      readonly clinicalCategory: ClinicalCategory;
      readonly value: number | null;
      readonly delta: number | null;
      readonly metInclusionCriteria: boolean;
      readonly metSuccessCriteria: boolean;
      readonly metTrigger: boolean;
      readonly patient: { readonly __typename: "Patient"; readonly id: Ids.PatientId; readonly name: string };
      readonly scale:
        | {
            readonly __typename: "AdministrableScale";
            readonly id: Ids.ScaleId;
            readonly name: string;
            readonly shortname: string | null;
            readonly nanoname: string | null;
          }
        | {
            readonly __typename: "Subscale";
            readonly id: Ids.ScaleId;
            readonly name: string;
            readonly shortname: string | null;
            readonly nanoname: string | null;
          };
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly startCursor: string | null;
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
    };
  } | null;
};

export type OutcomesTransientMetricSummaryDataQueryVariables = Exact<{
  transientMetricData: MetricParams;
  drilldownMetric: DrilldownMetricParams;
  scaleScorerId: InputMaybe<Scalars["ScaleScorerId"]["input"]>;
}>;

export type OutcomesTransientMetricSummaryDataQuery = {
  readonly __typename: "Query";
  readonly outcomesTransientMetricSummaryData: {
    readonly __typename: "MetricSummaryData";
    readonly value: number | null;
    readonly totalRows: number;
    readonly eligibleRows: number;
    readonly successfulRows: number;
    readonly triggerRows: number;
    readonly rowsInTimePeriod: number;
    readonly metric: {
      readonly __typename: "Metric";
      readonly configuration:
        | {
            readonly __typename: "TimeBasedMetric";
            readonly timeBasedAggregationType: TimeBasedAggregationType;
          }
        | {
            readonly __typename: "TriggerBasedMetric";
            readonly triggerBasedAggregationType: TriggerBasedAggregationType;
          };
    };
    readonly reportEntityTreeNodes: ReadonlyArray<{
      readonly __typename: "ReportEntityTreeNode";
      readonly graphInterval: DrilldownMetricGraphInterval | null;
      readonly entityTreeNode: {
        readonly __typename: "EntityTreeNode";
        readonly path: string;
        readonly entity: {
          readonly __typename: "Entity";
          readonly id: Ids.EntityId;
          readonly name: string;
          readonly shortname: string;
          readonly entityType: EntityType;
        };
      };
      readonly summaryData: ReadonlyArray<{
        readonly __typename: "ReportSummaryDataField";
        readonly name: string;
        readonly value: number | null;
      }>;
      readonly summaryGraphData: ReadonlyArray<{
        readonly __typename: "ReportSummaryGraphData";
        readonly name: string;
        readonly points: ReadonlyArray<{
          readonly __typename: "ChartData";
          readonly date: Date;
          readonly value: number;
          readonly label: number | null;
        }>;
      }> | null;
    }>;
  };
};

export type ScaleScorerConfigurationQueryVariables = Exact<{
  id: Scalars["ScaleScorerId"]["input"];
}>;

export type ScaleScorerConfigurationQuery = {
  readonly __typename: "Query";
  readonly assessmentScaleScorer:
    | {
        readonly __typename: "CategoricalScaleScorer";
        readonly id: Ids.ScaleScorerId;
        readonly supportedTrends: ReadonlyArray<Trend>;
        readonly supportedSeverityCategories: ReadonlyArray<SeverityCategory>;
      }
    | {
        readonly __typename: "NumericalScaleScorer";
        readonly domain: ScaleScorerDomain;
        readonly minValue: number;
        readonly maxValue: number;
        readonly minInterpretation: string | null;
        readonly maxInterpretation: string | null;
        readonly minInterpretationShort: string | null;
        readonly maxInterpretationShort: string | null;
        readonly id: Ids.ScaleScorerId;
        readonly supportedTrends: ReadonlyArray<Trend>;
        readonly supportedSeverityCategories: ReadonlyArray<SeverityCategory>;
        readonly thresholdData: ReadonlyArray<{
          readonly __typename: "ScaleThreshold";
          readonly mnemonic: string;
          readonly thresholdClass: ScaleThresholdClass;
          readonly minValue: number;
          readonly maxValue: number;
        }> | null;
      }
    | {
        readonly __typename: "UnscoredScaleScorer";
        readonly id: Ids.ScaleScorerId;
        readonly supportedTrends: ReadonlyArray<Trend>;
        readonly supportedSeverityCategories: ReadonlyArray<SeverityCategory>;
      }
    | null;
};

export type ScaleScorerSubtreeQueryVariables = Exact<{
  domain: InputMaybe<ReadonlyArray<ScaleScorerDomain> | ScaleScorerDomain>;
  parentId: Scalars["ScaleScorerId"]["input"];
}>;

export type ScaleScorerSubtreeQuery = {
  readonly __typename: "Query";
  readonly assessmentScaleScorers: {
    readonly __typename: "ScaleScorerConnection";
    readonly nodes: ReadonlyArray<
      | { readonly __typename: "CategoricalScaleScorer"; readonly id: Ids.ScaleScorerId }
      | { readonly __typename: "NumericalScaleScorer"; readonly id: Ids.ScaleScorerId }
      | { readonly __typename: "UnscoredScaleScorer"; readonly id: Ids.ScaleScorerId }
    >;
  } | null;
};

export type ScaleScorersConfigurationQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars["ScaleScorerId"]["input"]> | Scalars["ScaleScorerId"]["input"];
}>;

export type ScaleScorersConfigurationQuery = {
  readonly __typename: "Query";
  readonly assessmentScaleScorers: {
    readonly __typename: "ScaleScorerConnection";
    readonly nodes: ReadonlyArray<
      | {
          readonly __typename: "CategoricalScaleScorer";
          readonly id: Ids.ScaleScorerId;
          readonly scoreType: ScaleScorerType;
          readonly supportedTrends: ReadonlyArray<Trend>;
          readonly supportedSeverityCategories: ReadonlyArray<SeverityCategory>;
          readonly scale:
            | {
                readonly __typename: "AdministrableScale";
                readonly name: string;
                readonly shortname: string | null;
                readonly nanoname: string | null;
              }
            | {
                readonly __typename: "Subscale";
                readonly name: string;
                readonly shortname: string | null;
                readonly nanoname: string | null;
              };
        }
      | {
          readonly __typename: "NumericalScaleScorer";
          readonly domain: ScaleScorerDomain;
          readonly minValue: number;
          readonly maxValue: number;
          readonly minInterpretation: string | null;
          readonly maxInterpretation: string | null;
          readonly minInterpretationShort: string | null;
          readonly maxInterpretationShort: string | null;
          readonly id: Ids.ScaleScorerId;
          readonly scoreType: ScaleScorerType;
          readonly supportedTrends: ReadonlyArray<Trend>;
          readonly supportedSeverityCategories: ReadonlyArray<SeverityCategory>;
          readonly thresholdData: ReadonlyArray<{
            readonly __typename: "ScaleThreshold";
            readonly mnemonic: string;
            readonly thresholdClass: ScaleThresholdClass;
            readonly minValue: number;
            readonly maxValue: number;
          }> | null;
          readonly scale:
            | {
                readonly __typename: "AdministrableScale";
                readonly name: string;
                readonly shortname: string | null;
                readonly nanoname: string | null;
              }
            | {
                readonly __typename: "Subscale";
                readonly name: string;
                readonly shortname: string | null;
                readonly nanoname: string | null;
              };
        }
      | {
          readonly __typename: "UnscoredScaleScorer";
          readonly id: Ids.ScaleScorerId;
          readonly scoreType: ScaleScorerType;
          readonly supportedTrends: ReadonlyArray<Trend>;
          readonly supportedSeverityCategories: ReadonlyArray<SeverityCategory>;
          readonly scale:
            | {
                readonly __typename: "AdministrableScale";
                readonly name: string;
                readonly shortname: string | null;
                readonly nanoname: string | null;
              }
            | {
                readonly __typename: "Subscale";
                readonly name: string;
                readonly shortname: string | null;
                readonly nanoname: string | null;
              };
        }
    >;
  } | null;
};

export type UpdateOutcomesMetricMutationVariables = Exact<{
  input: UpdateMetricInput;
}>;

export type UpdateOutcomesMetricMutation = {
  readonly __typename: "Mutation";
  readonly outcomesUpdateMetric: {
    readonly __typename: "UpdateMetricPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateMetricResult";
      readonly metric: {
        readonly __typename: "Metric";
        readonly id: Ids.MetricId;
        readonly name: string;
        readonly entityTreeNode: {
          readonly __typename: "EntityTreeNode";
          readonly path: string;
          readonly minAge: number | null;
          readonly maxAge: number | null;
          readonly entity: {
            readonly __typename: "Entity";
            readonly id: Ids.EntityId;
            readonly name: string;
            readonly shortname: string;
            readonly entityType: EntityType;
          };
          readonly segments: ReadonlyArray<{
            readonly __typename: "EntityTreeSegment";
            readonly id: Ids.EntityId;
            readonly path: string;
            readonly entityType: EntityType;
            readonly name: string;
            readonly shortname: string;
            readonly rootPath: string;
          }>;
        };
        readonly configuration:
          | {
              readonly __typename: "TimeBasedMetric";
              readonly timeBasedAggregationType: TimeBasedAggregationType;
              readonly timePeriodType: TimePeriodType;
              readonly timePeriodValue: number | null;
              readonly scorerConfig:
                | {
                    readonly __typename: "MetricMultiScaleScorerConfiguration";
                    readonly scaleScorers: ReadonlyArray<
                      | {
                          readonly __typename: "CategoricalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        }
                      | {
                          readonly __typename: "NumericalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        }
                      | {
                          readonly __typename: "UnscoredScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        }
                    >;
                  }
                | {
                    readonly __typename: "MetricSingleScaleScorerConfiguration";
                    readonly scaleScorer:
                      | {
                          readonly __typename: "CategoricalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        }
                      | {
                          readonly __typename: "NumericalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        }
                      | {
                          readonly __typename: "UnscoredScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        };
                  };
              readonly metricInclusionCriteria: ReadonlyArray<{
                readonly __typename: "MetricInclusionCriterion";
                readonly id: Ids.MetricInclusionCriterionId;
                readonly criterionType: MetricInclusionCriterionType;
                readonly operator: Operator | null;
                readonly stringValue: string | null;
                readonly numberValue: number | null;
                readonly integerValue: number | null;
                readonly severityValues: ReadonlyArray<SeverityCategory> | null;
                readonly trendValue: Trend | null;
                readonly stringArrayValue: ReadonlyArray<string | null> | null;
                readonly integerArrayValue: ReadonlyArray<number | null> | null;
                readonly excludeResults: boolean | null;
              }>;
              readonly metricSuccessCriteria: ReadonlyArray<{
                readonly __typename: "MetricSuccessCriterion";
                readonly id: Ids.MetricSuccessCriterionId;
                readonly criterionType: MetricSuccessCriterionType;
                readonly operator: Operator | null;
                readonly numberValue: number | null;
                readonly stringValue: string | null;
                readonly severityValues: ReadonlyArray<SeverityCategory> | null;
              }>;
            }
          | {
              readonly __typename: "TriggerBasedMetric";
              readonly triggerBasedAggregationType: TriggerBasedAggregationType;
              readonly scorerConfig:
                | {
                    readonly __typename: "MetricMultiScaleScorerConfiguration";
                    readonly scaleScorers: ReadonlyArray<
                      | {
                          readonly __typename: "CategoricalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        }
                      | {
                          readonly __typename: "NumericalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        }
                      | {
                          readonly __typename: "UnscoredScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        }
                    >;
                  }
                | {
                    readonly __typename: "MetricSingleScaleScorerConfiguration";
                    readonly scaleScorer:
                      | {
                          readonly __typename: "CategoricalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        }
                      | {
                          readonly __typename: "NumericalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        }
                      | {
                          readonly __typename: "UnscoredScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        };
                  };
              readonly metricInclusionCriteria: ReadonlyArray<{
                readonly __typename: "MetricInclusionCriterion";
                readonly id: Ids.MetricInclusionCriterionId;
                readonly criterionType: MetricInclusionCriterionType;
                readonly operator: Operator | null;
                readonly stringValue: string | null;
                readonly numberValue: number | null;
                readonly integerValue: number | null;
                readonly severityValues: ReadonlyArray<SeverityCategory> | null;
                readonly trendValue: Trend | null;
                readonly stringArrayValue: ReadonlyArray<string | null> | null;
                readonly integerArrayValue: ReadonlyArray<number | null> | null;
                readonly excludeResults: boolean | null;
              }>;
              readonly metricTriggers: ReadonlyArray<{
                readonly __typename: "MetricTrigger";
                readonly id: Ids.MetricTriggerId;
                readonly triggerType: MetricTriggerType;
                readonly severityValue: SeverityCategory | null;
                readonly trendValue: Trend | null;
                readonly integerValue: number | null;
                readonly stringArrayValue: ReadonlyArray<string> | null;
                readonly stringValue: string | null;
              }>;
            };
      };
    } | null;
  } | null;
};

export type UpdateOutcomesMetricStatusMutationVariables = Exact<{
  input: UpdateMetricStatusInput;
}>;

export type UpdateOutcomesMetricStatusMutation = {
  readonly __typename: "Mutation";
  readonly outcomesUpdateMetricStatus: {
    readonly __typename: "UpdateMetricStatusPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateMetricStatusResult";
      readonly metric: {
        readonly __typename: "Metric";
        readonly id: Ids.MetricId;
        readonly name: string;
        readonly entityTreeNode: {
          readonly __typename: "EntityTreeNode";
          readonly path: string;
          readonly minAge: number | null;
          readonly maxAge: number | null;
          readonly entity: {
            readonly __typename: "Entity";
            readonly id: Ids.EntityId;
            readonly name: string;
            readonly shortname: string;
            readonly entityType: EntityType;
          };
          readonly segments: ReadonlyArray<{
            readonly __typename: "EntityTreeSegment";
            readonly id: Ids.EntityId;
            readonly path: string;
            readonly entityType: EntityType;
            readonly name: string;
            readonly shortname: string;
            readonly rootPath: string;
          }>;
        };
        readonly configuration:
          | {
              readonly __typename: "TimeBasedMetric";
              readonly timeBasedAggregationType: TimeBasedAggregationType;
              readonly timePeriodType: TimePeriodType;
              readonly timePeriodValue: number | null;
              readonly scorerConfig:
                | {
                    readonly __typename: "MetricMultiScaleScorerConfiguration";
                    readonly scaleScorers: ReadonlyArray<
                      | {
                          readonly __typename: "CategoricalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        }
                      | {
                          readonly __typename: "NumericalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        }
                      | {
                          readonly __typename: "UnscoredScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        }
                    >;
                  }
                | {
                    readonly __typename: "MetricSingleScaleScorerConfiguration";
                    readonly scaleScorer:
                      | {
                          readonly __typename: "CategoricalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        }
                      | {
                          readonly __typename: "NumericalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        }
                      | {
                          readonly __typename: "UnscoredScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                                readonly nanoname: string | null;
                              };
                        };
                  };
              readonly metricInclusionCriteria: ReadonlyArray<{
                readonly __typename: "MetricInclusionCriterion";
                readonly id: Ids.MetricInclusionCriterionId;
                readonly criterionType: MetricInclusionCriterionType;
                readonly operator: Operator | null;
                readonly stringValue: string | null;
                readonly numberValue: number | null;
                readonly integerValue: number | null;
                readonly severityValues: ReadonlyArray<SeverityCategory> | null;
                readonly trendValue: Trend | null;
                readonly stringArrayValue: ReadonlyArray<string | null> | null;
                readonly integerArrayValue: ReadonlyArray<number | null> | null;
                readonly excludeResults: boolean | null;
              }>;
              readonly metricSuccessCriteria: ReadonlyArray<{
                readonly __typename: "MetricSuccessCriterion";
                readonly id: Ids.MetricSuccessCriterionId;
                readonly criterionType: MetricSuccessCriterionType;
                readonly operator: Operator | null;
                readonly numberValue: number | null;
                readonly stringValue: string | null;
                readonly severityValues: ReadonlyArray<SeverityCategory> | null;
              }>;
            }
          | {
              readonly __typename: "TriggerBasedMetric";
              readonly triggerBasedAggregationType: TriggerBasedAggregationType;
              readonly scorerConfig:
                | {
                    readonly __typename: "MetricMultiScaleScorerConfiguration";
                    readonly scaleScorers: ReadonlyArray<
                      | {
                          readonly __typename: "CategoricalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        }
                      | {
                          readonly __typename: "NumericalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        }
                      | {
                          readonly __typename: "UnscoredScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        }
                    >;
                  }
                | {
                    readonly __typename: "MetricSingleScaleScorerConfiguration";
                    readonly scaleScorer:
                      | {
                          readonly __typename: "CategoricalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        }
                      | {
                          readonly __typename: "NumericalScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        }
                      | {
                          readonly __typename: "UnscoredScaleScorer";
                          readonly id: Ids.ScaleScorerId;
                          readonly scale:
                            | {
                                readonly __typename: "AdministrableScale";
                                readonly shortname: string | null;
                                readonly name: string;
                              }
                            | {
                                readonly __typename: "Subscale";
                                readonly shortname: string | null;
                                readonly name: string;
                              };
                        };
                  };
              readonly metricInclusionCriteria: ReadonlyArray<{
                readonly __typename: "MetricInclusionCriterion";
                readonly id: Ids.MetricInclusionCriterionId;
                readonly criterionType: MetricInclusionCriterionType;
                readonly operator: Operator | null;
                readonly stringValue: string | null;
                readonly numberValue: number | null;
                readonly integerValue: number | null;
                readonly severityValues: ReadonlyArray<SeverityCategory> | null;
                readonly trendValue: Trend | null;
                readonly stringArrayValue: ReadonlyArray<string | null> | null;
                readonly integerArrayValue: ReadonlyArray<number | null> | null;
                readonly excludeResults: boolean | null;
              }>;
              readonly metricTriggers: ReadonlyArray<{
                readonly __typename: "MetricTrigger";
                readonly id: Ids.MetricTriggerId;
                readonly triggerType: MetricTriggerType;
                readonly severityValue: SeverityCategory | null;
                readonly trendValue: Trend | null;
                readonly integerValue: number | null;
                readonly stringArrayValue: ReadonlyArray<string> | null;
                readonly stringValue: string | null;
              }>;
            };
      };
    } | null;
  } | null;
};

export type AppointmentAlgorithmDetailsQueryVariables = Exact<{
  appointmentId: Scalars["AppointmentId"]["input"];
}>;

export type AppointmentAlgorithmDetailsQuery = {
  readonly __typename: "Query";
  readonly schedulingAppointment: {
    readonly __typename: "Appointment";
    readonly id: Ids.AppointmentId;
    readonly source: SourceEnum | null;
    readonly inferredCareEpisodeReason: CareEpisodeInferenceReason | null;
    readonly inferredTreatmentServiceReason: TreatmentServiceInferenceReason | null;
    readonly organizationSource: FieldSourceEnum;
    readonly careEpisodeSource: FieldSourceEnum;
    readonly treatmentServiceSource: FieldSourceEnum;
    readonly careEpisode: { readonly __typename: "CareEpisode"; readonly id: Ids.CareEpisodeId } | null;
    readonly treatmentService: {
      readonly __typename: "TreatmentService";
      readonly id: Ids.TreatmentServiceId;
      readonly name: string;
    } | null;
    readonly lastCareEpisodeCreationAlgorithm: {
      readonly __typename: "DataSourceRecordAlgorithmLog";
      readonly algorithm: DataSourceRecordAlgorithm;
      readonly statusName: string;
      readonly statusDescription: string;
    } | null;
    readonly lastPatientSessionCreationAlgorithm: {
      readonly __typename: "DataSourceRecordAlgorithmLog";
      readonly algorithm: DataSourceRecordAlgorithm;
      readonly statusName: string;
      readonly statusDescription: string;
    } | null;
    readonly lastActiveCareEpisodeCreationAlgorithm: {
      readonly __typename: "DataSourceRecordAlgorithmLog";
      readonly algorithm: DataSourceRecordAlgorithm;
      readonly statusName: string;
      readonly statusDescription: string;
    } | null;
    readonly lastActivePatientSessionCreationAlgorithm: {
      readonly __typename: "DataSourceRecordAlgorithmLog";
      readonly algorithm: DataSourceRecordAlgorithm;
      readonly statusName: string;
      readonly statusDescription: string;
    } | null;
    readonly patientSession: {
      readonly __typename: "PatientSession";
      readonly id: Ids.PatientSessionId;
      readonly scalesPlanned: boolean | null;
    } | null;
  } | null;
};

export type AppointmentDetailsQueryVariables = Exact<{
  appointmentId: Scalars["AppointmentId"]["input"];
}>;

export type AppointmentDetailsQuery = {
  readonly __typename: "Query";
  readonly schedulingAppointment: {
    readonly __typename: "Appointment";
    readonly id: Ids.AppointmentId;
    readonly source: SourceEnum | null;
    readonly createdAt: Date;
    readonly lastImportDate: Date | null;
    readonly startDate: Date | null;
    readonly status: AppointmentStatus;
    readonly serviceType: string | null;
    readonly minutesDuration: number | null;
    readonly measurementStatus: AppointmentMeasurementStatus;
    readonly measurementCategory: AppointmentMeasurementCategory;
    readonly organizationSource: FieldSourceEnum;
    readonly careEpisodeSource: FieldSourceEnum;
    readonly organization: {
      readonly __typename: "Organization";
      readonly id: Ids.OrganizationId;
      readonly name: string;
    } | null;
    readonly provider: {
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
    } | null;
    readonly patient: { readonly __typename: "Patient"; readonly id: Ids.PatientId; readonly name: string };
    readonly treatmentService: {
      readonly __typename: "TreatmentService";
      readonly id: Ids.TreatmentServiceId;
      readonly name: string;
    } | null;
    readonly careEpisode: {
      readonly __typename: "CareEpisode";
      readonly id: Ids.CareEpisodeId;
      readonly status: CareEpisodeStatus;
      readonly name: string;
      readonly organization: {
        readonly __typename: "Organization";
        readonly id: Ids.OrganizationId;
        readonly name: string;
      };
      readonly treatmentService: {
        readonly __typename: "TreatmentService";
        readonly id: Ids.TreatmentServiceId;
        readonly name: string;
      } | null;
    } | null;
    readonly patientSession: {
      readonly __typename: "PatientSession";
      readonly id: Ids.PatientSessionId;
      readonly sessionNumber: number | null;
      readonly autoplan: boolean;
      readonly isReportAvailable: boolean;
      readonly assessmentReport: {
        readonly __typename: "Report";
        readonly id: Ids.ReportId;
        readonly invitations: ReadonlyArray<{
          readonly __typename: "Invitation";
          readonly id: Ids.InvitationId;
          readonly status: InvitationStatus;
          readonly format: InvitationFormat;
          readonly user: { readonly __typename: "User"; readonly name: string };
        }>;
      } | null;
      readonly schedulingRecurrence: {
        readonly __typename: "Recurrence";
        readonly id: Ids.RecurrenceId;
        readonly endDate: Date;
        readonly repeatFrequency: RecurrenceFrequency;
      } | null;
    } | null;
  } | null;
};

export type AppointmentInvitationDetailsQueryVariables = Exact<{
  appointmentId: Scalars["AppointmentId"]["input"];
}>;

export type AppointmentInvitationDetailsQuery = {
  readonly __typename: "Query";
  readonly schedulingAppointment: {
    readonly __typename: "Appointment";
    readonly id: Ids.AppointmentId;
    readonly patientSession: {
      readonly __typename: "PatientSession";
      readonly id: Ids.PatientSessionId;
      readonly scalesPlanned: boolean | null;
      readonly assessmentReport: {
        readonly __typename: "Report";
        readonly invitations: ReadonlyArray<{
          readonly __typename: "Invitation";
          readonly id: Ids.InvitationId;
          readonly status: InvitationStatus;
          readonly referrer: InvitationLoginMethod | null;
          readonly phase: InvitationPhase;
          readonly format: InvitationFormat;
          readonly opensAt: Date | null;
          readonly closesAt: Date | null;
          readonly dateBegun: Date | null;
          readonly dateCompleted: Date | null;
          readonly user: {
            readonly __typename: "User";
            readonly id: Ids.UserId;
            readonly name: string;
            readonly userType: UserType;
            readonly notificationPreference: NotificationPreference | null;
            readonly allowsText: boolean;
            readonly allowsEmail: boolean;
          };
          readonly invitationNotifications: ReadonlyArray<{
            readonly __typename: "InvitationNotification";
            readonly id: Ids.InvitationNotificationId;
            readonly status: InvitationNotificationStatus;
            readonly sendAt: Date | null;
            readonly dateTexted: Date | null;
            readonly dateEmailed: Date | null;
            readonly textAllowed: boolean;
            readonly emailAllowed: boolean;
          }>;
          readonly reportScales: ReadonlyArray<{
            readonly __typename: "ReportScale";
            readonly id: Ids.ReportScaleId;
            readonly disabled: boolean;
            readonly scale:
              | {
                  readonly __typename: "AdministrableScale";
                  readonly id: Ids.ScaleId;
                  readonly name: string;
                  readonly shortname: string | null;
                }
              | {
                  readonly __typename: "Subscale";
                  readonly id: Ids.ScaleId;
                  readonly name: string;
                  readonly shortname: string | null;
                };
          }>;
        }>;
      } | null;
    } | null;
  } | null;
};

export type AppointmentMeasurementPlansQueryVariables = Exact<{
  appointmentId: Scalars["AppointmentId"]["input"];
}>;

export type AppointmentMeasurementPlansQuery = {
  readonly __typename: "Query";
  readonly schedulingAppointment: {
    readonly __typename: "Appointment";
    readonly id: Ids.AppointmentId;
    readonly patientSession: {
      readonly __typename: "PatientSession";
      readonly id: Ids.PatientSessionId;
      readonly autoplan: boolean;
      readonly entityMeasurementPlans: ReadonlyArray<{
        readonly __typename: "EntityMeasurementPlan";
        readonly id: Ids.EntityMeasurementPlanId;
        readonly planType: MeasurementPlanType;
        readonly entityTreeNode: {
          readonly __typename: "EntityTreeNode";
          readonly path: string;
          readonly minAge: number | null;
          readonly maxAge: number | null;
          readonly entity: {
            readonly __typename: "Entity";
            readonly name: string;
            readonly shortname: string;
            readonly id: Ids.EntityId;
            readonly entityType: EntityType;
          };
          readonly segments: ReadonlyArray<{
            readonly __typename: "EntityTreeSegment";
            readonly id: Ids.EntityId;
            readonly path: string;
            readonly entityType: EntityType;
            readonly name: string;
            readonly shortname: string;
            readonly rootPath: string;
          }>;
        };
        readonly measurementPlan: {
          readonly __typename: "MeasurementPlan";
          readonly id: Ids.MeasurementPlanId;
          readonly name: string;
          readonly description: string | null;
          readonly measurementPlanScales: ReadonlyArray<{
            readonly __typename: "MeasurementPlanScale";
            readonly id: Ids.MeasurementPlanScaleId;
            readonly format: InvitationFormat | null;
            readonly phase: InvitationPhase | null;
            readonly alternationGroup: string | null;
            readonly discontinueSession: number | null;
            readonly onsetSession: number | null;
            readonly onsetTime: GqlScalars.Interval | null;
            readonly repeatSessions: number | null;
            readonly maxRepeatInterval: GqlScalars.Interval | null;
            readonly discontinueTime: GqlScalars.Interval | null;
            readonly measureOnsetFrom: MeasurementPlanMeasureOnsetFrom | null;
            readonly scale:
              | {
                  readonly __typename: "AdministrableScale";
                  readonly id: Ids.ScaleId;
                  readonly name: string;
                  readonly shortname: string | null;
                }
              | {
                  readonly __typename: "Subscale";
                  readonly id: Ids.ScaleId;
                  readonly name: string;
                  readonly shortname: string | null;
                };
          }>;
        };
      }>;
    } | null;
  } | null;
};

export type AppointmentScaleAdministrationQueryVariables = Exact<{
  appointmentId: Scalars["AppointmentId"]["input"];
}>;

export type AppointmentScaleAdministrationQuery = {
  readonly __typename: "Query";
  readonly schedulingAppointment: {
    readonly __typename: "Appointment";
    readonly id: Ids.AppointmentId;
    readonly patientSession: {
      readonly __typename: "PatientSession";
      readonly id: Ids.PatientSessionId;
      readonly scalesPlanned: boolean | null;
      readonly lastPlannedAt: Date | null;
      readonly assessmentReport: {
        readonly __typename: "Report";
        readonly reportScales: ReadonlyArray<{
          readonly __typename: "ReportScale";
          readonly id: Ids.ReportScaleId;
          readonly disabled: boolean;
          readonly manual: boolean;
          readonly planningDecision: ReportScalePlanningDecision | null;
          readonly planningReason: string | null;
          readonly scale:
            | {
                readonly __typename: "AdministrableScale";
                readonly id: Ids.ScaleId;
                readonly name: string;
                readonly shortname: string | null;
              }
            | {
                readonly __typename: "Subscale";
                readonly id: Ids.ScaleId;
                readonly name: string;
                readonly shortname: string | null;
              };
          readonly careMeasurementPlanScales: ReadonlyArray<{
            readonly __typename: "MeasurementPlanScale";
            readonly id: Ids.MeasurementPlanScaleId;
            readonly format: InvitationFormat | null;
            readonly phase: InvitationPhase | null;
            readonly alternationGroup: string | null;
            readonly discontinueSession: number | null;
            readonly onsetSession: number | null;
            readonly onsetTime: GqlScalars.Interval | null;
            readonly repeatSessions: number | null;
            readonly maxRepeatInterval: GqlScalars.Interval | null;
            readonly discontinueTime: GqlScalars.Interval | null;
            readonly measureOnsetFrom: MeasurementPlanMeasureOnsetFrom | null;
            readonly measurementPlan: {
              readonly __typename: "MeasurementPlan";
              readonly id: Ids.MeasurementPlanId;
              readonly name: string;
            };
            readonly scale:
              | {
                  readonly __typename: "AdministrableScale";
                  readonly id: Ids.ScaleId;
                  readonly name: string;
                  readonly shortname: string | null;
                }
              | {
                  readonly __typename: "Subscale";
                  readonly id: Ids.ScaleId;
                  readonly name: string;
                  readonly shortname: string | null;
                };
          }>;
        }>;
      } | null;
    } | null;
  } | null;
};

export type AppointmentSessionCreationAlgorithmDetailsQueryVariables = Exact<{
  appointmentId: Scalars["AppointmentId"]["input"];
}>;

export type AppointmentSessionCreationAlgorithmDetailsQuery = {
  readonly __typename: "Query";
  readonly schedulingAppointment: {
    readonly __typename: "Appointment";
    readonly id: Ids.AppointmentId;
    readonly source: SourceEnum | null;
    readonly patientSession: {
      readonly __typename: "PatientSession";
      readonly id: Ids.PatientSessionId;
    } | null;
    readonly lastPatientSessionCreationAlgorithm: {
      readonly __typename: "DataSourceRecordAlgorithmLog";
      readonly algorithm: DataSourceRecordAlgorithm;
      readonly statusName: string;
      readonly statusDescription: string;
    } | null;
    readonly lastActivePatientSessionCreationAlgorithm: {
      readonly __typename: "DataSourceRecordAlgorithmLog";
      readonly algorithm: DataSourceRecordAlgorithm;
      readonly statusName: string;
      readonly statusDescription: string;
    } | null;
  } | null;
};

export type CancelAppointmentMutationVariables = Exact<{
  input: CancelAppointmentInput;
}>;

export type CancelAppointmentMutation = {
  readonly __typename: "Mutation";
  readonly schedulingCancelAppointment: {
    readonly __typename: "CancelAppointmentPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CancelAppointmentResult";
      readonly appointment: {
        readonly __typename: "Appointment";
        readonly id: Ids.AppointmentId;
        readonly status: AppointmentStatus;
      };
    } | null;
  } | null;
};

export type CreateAppointmentMutationVariables = Exact<{
  input: CreateAppointmentInput;
}>;

export type CreateAppointmentMutation = {
  readonly __typename: "Mutation";
  readonly schedulingCreateAppointment: {
    readonly __typename: "CreateAppointmentPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateAppointmentResult";
      readonly appointment: { readonly __typename: "Appointment"; readonly id: Ids.AppointmentId };
    } | null;
  } | null;
};

export type DeleteAppointmentMutationVariables = Exact<{
  input: DeleteAppointmentInput;
}>;

export type DeleteAppointmentMutation = {
  readonly __typename: "Mutation";
  readonly schedulingDeleteAppointment: {
    readonly __typename: "DeleteAppointmentPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "DeleteAppointmentResult";
      readonly deletedAppointmentId: Ids.AppointmentId;
    } | null;
  } | null;
};

export type EditAppointmentMutationVariables = Exact<{
  input: EditAppointmentInput;
}>;

export type EditAppointmentMutation = {
  readonly __typename: "Mutation";
  readonly schedulingEditAppointment: {
    readonly __typename: "EditAppointmentPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EditAppointmentResult";
      readonly appointment: { readonly __typename: "Appointment"; readonly id: Ids.AppointmentId };
    } | null;
  } | null;
};

export type MbcResendInvitationNotificationsMutationVariables = Exact<{
  input: ResendInvitationNotificationsInput;
}>;

export type MbcResendInvitationNotificationsMutation = {
  readonly __typename: "Mutation";
  readonly assessmentResendInvitationNotifications: {
    readonly __typename: "ResendInvitationNotificationsPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "ResendInvitationNotificationResult";
      readonly newNotificationSent: boolean;
      readonly invitation: {
        readonly __typename: "Invitation";
        readonly id: Ids.InvitationId;
        readonly invitationNotifications: ReadonlyArray<{
          readonly __typename: "InvitationNotification";
          readonly id: Ids.InvitationNotificationId;
          readonly status: InvitationNotificationStatus;
          readonly sendAt: Date | null;
          readonly dateTexted: Date | null;
          readonly dateEmailed: Date | null;
          readonly textAllowed: boolean;
          readonly emailAllowed: boolean;
        }>;
      };
    } | null;
  } | null;
};

export type CareEpisodeDetailsQueryVariables = Exact<{
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
}>;

export type CareEpisodeDetailsQuery = {
  readonly __typename: "Query";
  readonly careEpisode: {
    readonly __typename: "CareEpisode";
    readonly id: Ids.CareEpisodeId;
    readonly status: CareEpisodeStatus;
    readonly periodStart: Date | null;
    readonly periodEnd: Date | null;
    readonly dischargeDueToInactivityDate: Date | null;
    readonly measurementAllowed: boolean;
    readonly patient: {
      readonly __typename: "Patient";
      readonly id: Ids.PatientId;
      readonly hasCompletedAssessment: boolean;
      readonly name: string;
      readonly autoCreateSessions: boolean | null;
    };
    readonly organization: {
      readonly __typename: "Organization";
      readonly id: Ids.OrganizationId;
      readonly name: string;
      readonly measurementAllowed: boolean;
    };
    readonly treatmentService: {
      readonly __typename: "TreatmentService";
      readonly id: Ids.TreatmentServiceId;
      readonly name: string;
    } | null;
    readonly careEpisodeProviders: ReadonlyArray<{
      readonly __typename: "CareEpisodeProvider";
      readonly id: Ids.CareEpisodeProviderId;
      readonly relationship: CareEpisodeProviderRelationship;
      readonly provider: {
        readonly __typename: "Provider";
        readonly id: Ids.ProviderId;
        readonly name: string;
      };
    }>;
    readonly careEpisodeTreatmentTracks: ReadonlyArray<{
      readonly __typename: "CareEpisodeTreatmentTrack";
      readonly id: Ids.CareEpisodeTreatmentTrackId;
      readonly status: CareEpisodeTreatmentTrackStatus;
      readonly enrollmentDate: Date;
      readonly completionDate: Date | null;
      readonly treatmentTrack: {
        readonly __typename: "TreatmentTrack";
        readonly id: Ids.TreatmentTrackId;
        readonly name: string;
      };
    }>;
    readonly institute: {
      readonly __typename: "Institute";
      readonly id: Ids.InstituteId;
      readonly name: string;
      readonly measurementAllowed: boolean;
    };
    readonly providers: ReadonlyArray<{
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
      readonly measurementAllowed: boolean;
    }>;
  } | null;
};

export type CareEpisodeDetailsEditQueryVariables = Exact<{
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
}>;

export type CareEpisodeDetailsEditQuery = {
  readonly __typename: "Query";
  readonly careEpisode: {
    readonly __typename: "CareEpisode";
    readonly id: Ids.CareEpisodeId;
    readonly status: CareEpisodeStatus;
    readonly periodStart: Date | null;
    readonly periodEnd: Date | null;
    readonly measurementAllowed: boolean;
    readonly institute: {
      readonly __typename: "Institute";
      readonly id: Ids.InstituteId;
      readonly name: string;
      readonly measurementAllowed: boolean;
      readonly organizations: {
        readonly __typename: "OrganizationConnection";
        readonly nodes: ReadonlyArray<{
          readonly __typename: "Organization";
          readonly id: Ids.OrganizationId;
          readonly name: string;
          readonly parentId: Ids.OrganizationId | null;
          readonly providers: {
            readonly __typename: "ProviderConnection";
            readonly nodes: ReadonlyArray<{
              readonly __typename: "Provider";
              readonly id: Ids.ProviderId;
              readonly name: string;
            }>;
          };
        }>;
      };
      readonly providers: ReadonlyArray<{
        readonly __typename: "Provider";
        readonly id: Ids.ProviderId;
        readonly name: string;
      }>;
    };
    readonly organization: {
      readonly __typename: "Organization";
      readonly id: Ids.OrganizationId;
      readonly name: string;
      readonly measurementAllowed: boolean;
    };
    readonly treatmentService: {
      readonly __typename: "TreatmentService";
      readonly id: Ids.TreatmentServiceId;
      readonly name: string;
    } | null;
    readonly careEpisodeProviders: ReadonlyArray<{
      readonly __typename: "CareEpisodeProvider";
      readonly id: Ids.CareEpisodeProviderId;
      readonly relationship: CareEpisodeProviderRelationship;
      readonly provider: {
        readonly __typename: "Provider";
        readonly id: Ids.ProviderId;
        readonly name: string;
      };
    }>;
    readonly providers: ReadonlyArray<{
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
      readonly measurementAllowed: boolean;
    }>;
    readonly patient: {
      readonly __typename: "Patient";
      readonly id: Ids.PatientId;
      readonly name: string;
      readonly autoCreateSessions: boolean | null;
    };
  } | null;
};

export type CareEpisodeSessionsQueryVariables = Exact<{
  sortBy: InputMaybe<PatientSessionSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
}>;

export type CareEpisodeSessionsQuery = {
  readonly __typename: "Query";
  readonly careEpisode: {
    readonly __typename: "CareEpisode";
    readonly id: Ids.CareEpisodeId;
    readonly patientSessions: {
      readonly __typename: "PatientSessionConnection";
      readonly totalCount: number;
      readonly pageInfo: {
        readonly __typename: "PageInfo";
        readonly endCursor: string | null;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null;
      };
      readonly nodes: ReadonlyArray<{
        readonly __typename: "PatientSession";
        readonly id: Ids.PatientSessionId;
        readonly targetDate: Date;
        readonly status: PatientSessionStatus;
        readonly assessmentReport: { readonly __typename: "Report"; readonly id: Ids.ReportId } | null;
      }>;
    };
  } | null;
};

export type CareUnitSummaryQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
}>;

export type CareUnitSummaryQuery = {
  readonly __typename: "Query";
  readonly patient: {
    readonly __typename: "Patient";
    readonly id: Ids.PatientId;
    readonly name: string;
  } | null;
};

export type CreateCareEpisodeMutationVariables = Exact<{
  careEpisode: CreateCareEpisodeInput;
}>;

export type CreateCareEpisodeMutation = {
  readonly __typename: "Mutation";
  readonly createCareEpisode: {
    readonly __typename: "CreateCareEpisodePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateCareEpisodeResult";
      readonly careEpisode: { readonly __typename: "CareEpisode"; readonly id: Ids.CareEpisodeId };
    } | null;
  } | null;
};

export type CreateCareEpisodeTreatmentTrackMutationVariables = Exact<{
  input: CreateCareEpisodeTreatmentTrackInput;
}>;

export type CreateCareEpisodeTreatmentTrackMutation = {
  readonly __typename: "Mutation";
  readonly careCreateCareEpisodeTreatmentTrack: {
    readonly __typename: "CreateCareEpisodeTreatmentTrackPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateCareEpisodeTreatmentTrackResult";
      readonly careEpisodeTreatmentTrack: {
        readonly __typename: "CareEpisodeTreatmentTrack";
        readonly id: Ids.CareEpisodeTreatmentTrackId;
        readonly status: CareEpisodeTreatmentTrackStatus;
        readonly treatmentTrack: {
          readonly __typename: "TreatmentTrack";
          readonly id: Ids.TreatmentTrackId;
          readonly name: string;
        };
        readonly careEpisode: {
          readonly __typename: "CareEpisode";
          readonly careEpisodeTreatmentTracks: ReadonlyArray<{
            readonly __typename: "CareEpisodeTreatmentTrack";
            readonly id: Ids.CareEpisodeTreatmentTrackId;
            readonly treatmentTrack: {
              readonly __typename: "TreatmentTrack";
              readonly id: Ids.TreatmentTrackId;
              readonly name: string;
            };
          }>;
        };
      };
    } | null;
  } | null;
};

export type EditCareEpisodeMutationVariables = Exact<{
  careEpisode: EditCareEpisodeInput;
}>;

export type EditCareEpisodeMutation = {
  readonly __typename: "Mutation";
  readonly editCareEpisode: {
    readonly __typename: "EditCareEpisodePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EditCareEpisodeResult";
      readonly careEpisode: {
        readonly __typename: "CareEpisode";
        readonly id: Ids.CareEpisodeId;
        readonly organization: { readonly __typename: "Organization"; readonly id: Ids.OrganizationId };
        readonly treatmentService: {
          readonly __typename: "TreatmentService";
          readonly id: Ids.TreatmentServiceId;
        } | null;
      };
    } | null;
  } | null;
};

export type ProvidersForOrganizationQueryVariables = Exact<{
  organizationId: Scalars["OrganizationId"]["input"];
  search: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  defaultRelationship: InputMaybe<
    ReadonlyArray<CareEpisodeProviderRelationship> | CareEpisodeProviderRelationship
  >;
}>;

export type ProvidersForOrganizationQuery = {
  readonly __typename: "Query";
  readonly organization: {
    readonly __typename: "Organization";
    readonly practicingAttachedProviders: {
      readonly __typename: "ProviderConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "Provider";
        readonly id: Ids.ProviderId;
        readonly name: string;
      }>;
    };
  } | null;
};

export type TreatmentTracksByCareEpisodeIdQueryVariables = Exact<{
  careEpisodeId: InputMaybe<
    ReadonlyArray<Scalars["CareEpisodeId"]["input"]> | Scalars["CareEpisodeId"]["input"]
  >;
  status: InputMaybe<ReadonlyArray<CareEpisodeTreatmentTrackStatus> | CareEpisodeTreatmentTrackStatus>;
}>;

export type TreatmentTracksByCareEpisodeIdQuery = {
  readonly __typename: "Query";
  readonly careCareEpisodeTreatmentTracks: {
    readonly __typename: "CareEpisodeTreatmentTrackConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "CareEpisodeTreatmentTrack";
      readonly id: Ids.CareEpisodeTreatmentTrackId;
      readonly status: CareEpisodeTreatmentTrackStatus;
      readonly startSessionNumber: number;
      readonly enrollmentDate: Date;
      readonly treatmentTrack: {
        readonly __typename: "TreatmentTrack";
        readonly id: Ids.TreatmentTrackId;
        readonly name: string;
      };
    }>;
  } | null;
};

export type TreatmentTracksByStatusQueryVariables = Exact<{
  status: InputMaybe<TreatmentTrackStatus>;
}>;

export type TreatmentTracksByStatusQuery = {
  readonly __typename: "Query";
  readonly careTreatmentTracks: {
    readonly __typename: "TreatmentTrackConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "TreatmentTrack";
      readonly id: Ids.TreatmentTrackId;
      readonly name: string;
    }>;
  } | null;
};

export type UpdateCareEpisodeTreatmentTrackMutationVariables = Exact<{
  input: UpdateCareEpisodeTreatmentTrackInput;
}>;

export type UpdateCareEpisodeTreatmentTrackMutation = {
  readonly __typename: "Mutation";
  readonly careUpdateCareEpisodeTreatmentTrack: {
    readonly __typename: "UpdateCareEpisodeTreatmentTrackPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateCareEpisodeTreatmentTrackResult";
      readonly careEpisodeTreatmentTrack: {
        readonly __typename: "CareEpisodeTreatmentTrack";
        readonly id: Ids.CareEpisodeTreatmentTrackId;
        readonly status: CareEpisodeTreatmentTrackStatus;
        readonly treatmentTrack: {
          readonly __typename: "TreatmentTrack";
          readonly id: Ids.TreatmentTrackId;
          readonly name: string;
        };
        readonly careEpisode: {
          readonly __typename: "CareEpisode";
          readonly careEpisodeTreatmentTracks: ReadonlyArray<{
            readonly __typename: "CareEpisodeTreatmentTrack";
            readonly id: Ids.CareEpisodeTreatmentTrackId;
            readonly treatmentTrack: {
              readonly __typename: "TreatmentTrack";
              readonly id: Ids.TreatmentTrackId;
              readonly name: string;
            };
          }>;
        };
      };
    } | null;
  } | null;
};

export type CareEpisodeDischargeMutationVariables = Exact<{
  input: DischargeCareEpisodeInput;
}>;

export type CareEpisodeDischargeMutation = {
  readonly __typename: "Mutation";
  readonly careDischargeCareEpisode: {
    readonly __typename: "DischargeCareEpisodePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "DischargeCareEpisodeResult";
      readonly success: boolean | null;
    } | null;
  } | null;
};

export type CareEpisodeSettingsQueryVariables = Exact<{
  id: Scalars["CareEpisodeId"]["input"];
}>;

export type CareEpisodeSettingsQuery = {
  readonly __typename: "Query";
  readonly careEpisode: {
    readonly __typename: "CareEpisode";
    readonly id: Ids.CareEpisodeId;
    readonly measurementAllowed: boolean;
    readonly institute: {
      readonly __typename: "Institute";
      readonly id: Ids.InstituteId;
      readonly name: string;
      readonly measurementAllowed: boolean;
    };
    readonly providers: ReadonlyArray<{
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
      readonly measurementAllowed: boolean;
    }>;
    readonly organization: {
      readonly __typename: "Organization";
      readonly id: Ids.OrganizationId;
      readonly name: string;
      readonly measurementAllowed: boolean;
    };
    readonly patient: {
      readonly __typename: "Patient";
      readonly id: Ids.PatientId;
      readonly name: string;
      readonly autoCreateSessions: boolean | null;
    };
  } | null;
};

export type CareEpisodeUpdateMeasurementAllowedMutationVariables = Exact<{
  input: CareEpisodeUpdateMeasurementAllowedInput;
}>;

export type CareEpisodeUpdateMeasurementAllowedMutation = {
  readonly __typename: "Mutation";
  readonly settingsCareEpisodeUpdateMeasurementAllowed: {
    readonly __typename: "CareEpisodeUpdateMeasurementAllowedPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CareEpisodeUpdateMeasurementAllowedResult";
      readonly measurementAllowed: boolean;
    } | null;
  } | null;
};

export type AddCareEpisodeProviderMutationVariables = Exact<{
  input: AddCareEpisodeProviderInput;
}>;

export type AddCareEpisodeProviderMutation = {
  readonly __typename: "Mutation";
  readonly addCareEpisodeProvider: {
    readonly __typename: "AddCareEpisodeProviderPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "AddCareEpisodeProviderResult";
      readonly careEpisode: {
        readonly __typename: "CareEpisode";
        readonly id: Ids.CareEpisodeId;
        readonly careEpisodeProviders: ReadonlyArray<{
          readonly __typename: "CareEpisodeProvider";
          readonly id: Ids.CareEpisodeProviderId;
          readonly relationship: CareEpisodeProviderRelationship;
          readonly provider: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          };
        }>;
      };
    } | null;
  } | null;
};

export type CareEpisodeCareTeamDetailsQueryVariables = Exact<{
  careEpisodeId: Scalars["CareEpisodeId"]["input"];
}>;

export type CareEpisodeCareTeamDetailsQuery = {
  readonly __typename: "Query";
  readonly careEpisode: {
    readonly __typename: "CareEpisode";
    readonly id: Ids.CareEpisodeId;
    readonly organization: { readonly __typename: "Organization"; readonly id: Ids.OrganizationId };
    readonly careEpisodeProviders: ReadonlyArray<{
      readonly __typename: "CareEpisodeProvider";
      readonly id: Ids.CareEpisodeProviderId;
      readonly relationship: CareEpisodeProviderRelationship;
      readonly provider: {
        readonly __typename: "Provider";
        readonly id: Ids.ProviderId;
        readonly name: string;
      };
    }>;
  } | null;
};

export type DeleteCareEpisodeProviderMutationVariables = Exact<{
  input: DeleteCareEpisodeProviderInput;
}>;

export type DeleteCareEpisodeProviderMutation = {
  readonly __typename: "Mutation";
  readonly deleteCareEpisodeProvider: {
    readonly __typename: "DeleteCareEpisodeProviderPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "DeleteCareEpisodeProviderResult";
      readonly careEpisode: {
        readonly __typename: "CareEpisode";
        readonly id: Ids.CareEpisodeId;
        readonly careEpisodeProviders: ReadonlyArray<{
          readonly __typename: "CareEpisodeProvider";
          readonly id: Ids.CareEpisodeProviderId;
          readonly relationship: CareEpisodeProviderRelationship;
          readonly provider: {
            readonly __typename: "Provider";
            readonly id: Ids.ProviderId;
            readonly name: string;
          };
        }>;
      };
    } | null;
  } | null;
};

export type CreateMbcPatientMutationVariables = Exact<{
  input: CreatePatientInput;
}>;

export type CreateMbcPatientMutation = {
  readonly __typename: "Mutation";
  readonly createPatient: {
    readonly __typename: "CreatePatientPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreatePatientResult";
      readonly patient: {
        readonly __typename: "Patient";
        readonly id: Ids.PatientId;
        readonly gender: Gender | null;
        readonly genderIdentity: string | null;
        readonly genderIdentityFhir: GenderIdentity | null;
        readonly preferredPronouns: string | null;
        readonly dob: Date | null;
        readonly name: string;
      };
    } | null;
  } | null;
};

export type MbcPatientDetailsHeaderQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
}>;

export type MbcPatientDetailsHeaderQuery = {
  readonly __typename: "Query";
  readonly patient: {
    readonly __typename: "Patient";
    readonly id: Ids.PatientId;
    readonly name: string;
    readonly email: string | null;
    readonly phoneNumber: string | null;
    readonly mrn: string | null;
    readonly dob: Date | null;
    readonly gender: Gender | null;
    readonly genderIdentity: string | null;
    readonly genderIdentityFhir: GenderIdentity | null;
    readonly preferredPronouns: string | null;
    readonly isTest: boolean;
    readonly ethnicity: string | null;
    readonly race: string | null;
    readonly hasCompletedAssessment: boolean;
    readonly notificationPreference: NotificationPreference | null;
    readonly notificationPreferenceWithDefault: NotificationPreference;
    readonly primaryLanguage: string | null;
    readonly enableNotifications: boolean;
    readonly patientFlags: ReadonlyArray<{
      readonly __typename: "PatientFlag";
      readonly active: boolean;
      readonly flag: {
        readonly __typename: "Flag";
        readonly id: Ids.FlagId;
        readonly icon: string;
        readonly name: string;
      };
    }>;
  } | null;
};

export type PatientListQueryVariables = Exact<{
  sortBy: InputMaybe<PatientSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
  forProvider: InputMaybe<ProviderFilter>;
  activeOrganizations: InputMaybe<OrganizationFilter>;
  flagIds: InputMaybe<ReadonlyArray<Scalars["FlagId"]["input"]> | Scalars["FlagId"]["input"]>;
  testPatient?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type PatientListQuery = {
  readonly __typename: "Query";
  readonly patients: {
    readonly __typename: "PatientConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Patient";
      readonly id: Ids.PatientId;
      readonly name: string;
      readonly dob: Date | null;
      readonly gender: Gender | null;
      readonly genderIdentity: string | null;
      readonly mrn: string | null;
      readonly firstName: string | null;
      readonly lastName: string | null;
      readonly isTest: boolean;
      readonly nameLastFirst: string;
      readonly hasCompletedAssessment: boolean;
      readonly patientFlags: ReadonlyArray<{
        readonly __typename: "PatientFlag";
        readonly id: Ids.PatientFlagId;
        readonly active: boolean;
        readonly flag: {
          readonly __typename: "Flag";
          readonly id: Ids.FlagId;
          readonly name: string;
          readonly icon: string;
        };
      }>;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type ActiveInvitationDetailsQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
  careEpisodeId: InputMaybe<Scalars["CareEpisodeId"]["input"]>;
}>;

export type ActiveInvitationDetailsQuery = {
  readonly __typename: "Query";
  readonly assessmentInvitations: {
    readonly __typename: "InvitationConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Invitation";
      readonly id: Ids.InvitationId;
      readonly status: InvitationStatus;
      readonly referrer: InvitationLoginMethod | null;
      readonly phase: InvitationPhase;
      readonly format: InvitationFormat;
      readonly opensAt: Date | null;
      readonly closesAt: Date | null;
      readonly dateBegun: Date | null;
      readonly dateCompleted: Date | null;
      readonly user: {
        readonly __typename: "User";
        readonly id: Ids.UserId;
        readonly name: string;
        readonly userType: UserType;
        readonly notificationPreference: NotificationPreference | null;
        readonly allowsText: boolean;
        readonly allowsEmail: boolean;
      };
      readonly invitationNotifications: ReadonlyArray<{
        readonly __typename: "InvitationNotification";
        readonly id: Ids.InvitationNotificationId;
        readonly status: InvitationNotificationStatus;
        readonly sendAt: Date | null;
        readonly dateTexted: Date | null;
        readonly dateEmailed: Date | null;
        readonly textAllowed: boolean;
        readonly emailAllowed: boolean;
      }>;
      readonly reportScales: ReadonlyArray<{
        readonly __typename: "ReportScale";
        readonly id: Ids.ReportScaleId;
        readonly disabled: boolean;
        readonly scale:
          | {
              readonly __typename: "AdministrableScale";
              readonly id: Ids.ScaleId;
              readonly name: string;
              readonly shortname: string | null;
            }
          | {
              readonly __typename: "Subscale";
              readonly id: Ids.ScaleId;
              readonly name: string;
              readonly shortname: string | null;
            };
      }>;
    }>;
  } | null;
};

export type AppointmentsAndSessionsQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
  careEpisodeId: InputMaybe<Scalars["CareEpisodeId"]["input"]>;
  sortBy: InputMaybe<AppointmentSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  startDateBefore: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  startDateAfter: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
}>;

export type AppointmentsAndSessionsQuery = {
  readonly __typename: "Query";
  readonly schedulingAppointments: {
    readonly __typename: "AppointmentConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Appointment";
      readonly startDate: Date | null;
      readonly status: AppointmentStatus;
      readonly id: Ids.AppointmentId;
      readonly measurementStatus: AppointmentMeasurementStatus;
      readonly measurementCategory: AppointmentMeasurementCategory;
      readonly source: SourceEnum | null;
      readonly careEpisode: {
        readonly __typename: "CareEpisode";
        readonly id: Ids.CareEpisodeId;
        readonly status: CareEpisodeStatus;
        readonly organization: {
          readonly __typename: "Organization";
          readonly id: Ids.OrganizationId;
          readonly name: string;
        };
        readonly treatmentService: {
          readonly __typename: "TreatmentService";
          readonly id: Ids.TreatmentServiceId;
          readonly name: string;
        } | null;
      } | null;
      readonly patientSession: {
        readonly __typename: "PatientSession";
        readonly id: Ids.PatientSessionId;
        readonly autoplan: boolean;
        readonly maxPlanningPhase: MeasurementPlanningPhase | null;
        readonly isReportAvailable: boolean;
        readonly assessmentReport: {
          readonly __typename: "Report";
          readonly id: Ids.ReportId;
          readonly invitations: ReadonlyArray<{
            readonly __typename: "Invitation";
            readonly id: Ids.InvitationId;
            readonly status: InvitationStatus;
            readonly format: InvitationFormat;
            readonly user: { readonly __typename: "User"; readonly name: string };
          }>;
        } | null;
        readonly schedulingRecurrence: {
          readonly __typename: "Recurrence";
          readonly id: Ids.RecurrenceId;
          readonly endDate: Date;
          readonly repeatFrequency: RecurrenceFrequency;
        } | null;
      } | null;
      readonly provider: {
        readonly __typename: "Provider";
        readonly id: Ids.ProviderId;
        readonly name: string;
      } | null;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type CareEpisodeListQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
  sortBy: InputMaybe<CareEpisodeSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  status: InputMaybe<ReadonlyArray<CareEpisodeStatus> | CareEpisodeStatus>;
}>;

export type CareEpisodeListQuery = {
  readonly __typename: "Query";
  readonly careEpisodes: {
    readonly __typename: "CareEpisodeConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "CareEpisode";
      readonly id: Ids.CareEpisodeId;
      readonly shortname: string;
      readonly periodStart: Date | null;
      readonly periodEnd: Date | null;
      readonly status: CareEpisodeStatus;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type CoverageListQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
  sortBy: InputMaybe<CoverageSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CoverageListQuery = {
  readonly __typename: "Query";
  readonly billingCoverages: {
    readonly __typename: "CoverageConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Coverage";
      readonly id: Ids.CoverageId;
      readonly startDate: Date;
      readonly endDate: Date | null;
      readonly subscriberNumber: string | null;
      readonly relationship: CoverageRelationship | null;
      readonly institutePayor: {
        readonly __typename: "InstitutePayor";
        readonly id: Ids.InstitutePayorId;
        readonly name: string;
        readonly primaryId: string | null;
      };
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type PatientConditionListQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
  sortBy: InputMaybe<PatientConditionSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
}>;

export type PatientConditionListQuery = {
  readonly __typename: "Query";
  readonly patientConditions: {
    readonly __typename: "PatientConditionConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "PatientCondition";
      readonly id: Ids.PatientConditionId;
      readonly status: PatientConditionStatus;
      readonly onsetDate: Date | null;
      readonly abatementDate: Date | null;
      readonly instituteCondition: {
        readonly __typename: "InstituteCondition";
        readonly id: Ids.InstituteConditionId;
        readonly name: string;
        readonly code: string;
      };
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type UpdatePatientNotificationPreferenceMutationVariables = Exact<{
  input: UpdatePatientNotificationPreferenceInput;
}>;

export type UpdatePatientNotificationPreferenceMutation = {
  readonly __typename: "Mutation";
  readonly updatePatientNotificationPreference: {
    readonly __typename: "UpdatePatientNotificationPreferencePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdatePatientNotificationPreferenceResult";
      readonly patient: {
        readonly __typename: "Patient";
        readonly id: Ids.PatientId;
        readonly enableNotifications: boolean;
      };
    } | null;
  } | null;
};

export type UserConsentListQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
  sortBy: InputMaybe<UserConsentSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UserConsentListQuery = {
  readonly __typename: "Query";
  readonly userConsents: {
    readonly __typename: "UserConsentConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "UserConsent";
      readonly id: Ids.UserConsentId;
      readonly status: UserConsentStatus;
      readonly createdAt: Date;
      readonly consentType: string;
      readonly accepted: boolean;
      readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string };
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type PatientQualityIssuesQueryVariables = Exact<{
  sortBy: InputMaybe<PatientSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  organizationFilter: InputMaybe<OrganizationFilter>;
  patientFilter: InputMaybe<PatientFilter>;
  qualityIssues: InputMaybe<PatientQualityIssueFilter>;
}>;

export type PatientQualityIssuesQuery = {
  readonly __typename: "Query";
  readonly patients: {
    readonly __typename: "PatientConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Patient";
      readonly id: Ids.PatientId;
      readonly name: string;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type QualityIssueDetailQueryVariables = Exact<{
  id: Scalars["PatientId"]["input"];
}>;

export type QualityIssueDetailQuery = {
  readonly __typename: "Query";
  readonly patient: {
    readonly __typename: "Patient";
    readonly id: Ids.PatientId;
    readonly name: string;
    readonly dob: Date | null;
    readonly user: {
      readonly __typename: "User";
      readonly phoneNumber: string | null;
      readonly email: string | null;
      readonly notificationPreference: NotificationPreference | null;
    };
    readonly patientQualityIssues: ReadonlyArray<{
      readonly __typename: "PatientQualityIssue";
      readonly id: Ids.PatientQualityIssueId;
      readonly issue: PatientQualityIssueType;
      readonly status: PatientQualityIssueStatus;
      readonly statusActiveUntil: Date | null;
      readonly resolvedAt: Date | null;
      readonly notes: string | null;
      readonly provider: { readonly __typename: "Provider"; readonly name: string } | null;
      readonly relatedPerson: {
        readonly __typename: "RelatedPerson";
        readonly id: Ids.RelatedPersonId;
        readonly name: string;
        readonly user: {
          readonly __typename: "User";
          readonly notificationPreference: NotificationPreference | null;
          readonly phoneNumber: string | null;
          readonly email: string | null;
        };
      } | null;
    }>;
  } | null;
};

export type UpdatePatientQualityIssueMutationVariables = Exact<{
  input: UpdatePatientQualityIssueInput;
}>;

export type UpdatePatientQualityIssueMutation = {
  readonly __typename: "Mutation";
  readonly updatePatientQualityIssue: {
    readonly __typename: "UpdatePatientQualityIssuePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdatePatientQualityIssueResult";
      readonly patientQualityIssue: {
        readonly __typename: "PatientQualityIssue";
        readonly id: Ids.PatientQualityIssueId;
        readonly status: PatientQualityIssueStatus;
        readonly issue: PatientQualityIssueType;
        readonly notes: string | null;
        readonly resolvedAt: Date | null;
        readonly statusActiveUntil: Date | null;
        readonly provider: { readonly __typename: "Provider"; readonly name: string } | null;
      };
    } | null;
  } | null;
};

export type PatientQualityIssueDetailsQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
}>;

export type PatientQualityIssueDetailsQuery = {
  readonly __typename: "Query";
  readonly patientQualityIssues: {
    readonly __typename: "PatientQualityIssueConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "PatientQualityIssue";
      readonly id: Ids.PatientQualityIssueId;
      readonly status: PatientQualityIssueStatus;
      readonly issue: PatientQualityIssueType;
      readonly resolvedAt: Date | null;
    }>;
  } | null;
};

export type CreateCareGroupMutationVariables = Exact<{
  careGroup: CreateCareGroupInput;
}>;

export type CreateCareGroupMutation = {
  readonly __typename: "Mutation";
  readonly createCareGroup: {
    readonly __typename: "CreateCareGroupPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateCareGroupResult";
      readonly careGroup: {
        readonly __typename: "CareGroup";
        readonly id: Ids.CareGroupId;
        readonly name: string;
      };
    } | null;
  } | null;
};

export type FamilyCareUnitTypeQueryVariables = Exact<{ [key: string]: never }>;

export type FamilyCareUnitTypeQuery = {
  readonly __typename: "Query";
  readonly careUnitTypes: {
    readonly __typename: "CareUnitTypeConnection";
    readonly nodes: ReadonlyArray<{ readonly __typename: "CareUnitType"; readonly id: Ids.CareUnitTypeId }>;
  } | null;
};

export type UpdateCareGroupMutationVariables = Exact<{
  careGroup: UpdateCareGroupInput;
}>;

export type UpdateCareGroupMutation = {
  readonly __typename: "Mutation";
  readonly updateCareGroup: {
    readonly __typename: "UpdateCareGroupPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateCareGroupResult";
      readonly careGroup: {
        readonly __typename: "CareGroup";
        readonly id: Ids.CareGroupId;
        readonly name: string;
      };
    } | null;
  } | null;
};

export type CareGroupAppointmentDetailsQueryVariables = Exact<{
  careGroupId: Scalars["CareGroupId"]["input"];
}>;

export type CareGroupAppointmentDetailsQuery = {
  readonly __typename: "Query";
  readonly careGroup: {
    readonly __typename: "CareGroup";
    readonly id: Ids.CareGroupId;
    readonly pipbhcAllGroupSchedulingAppointments: ReadonlyArray<{
      readonly __typename: "Appointment";
      readonly id: Ids.AppointmentId;
      readonly comment: string | null;
      readonly endDate: Date | null;
      readonly startDate: Date | null;
      readonly status: AppointmentStatus;
      readonly minutesDuration: number | null;
      readonly patientSession: {
        readonly __typename: "PatientSession";
        readonly status: PatientSessionStatus;
        readonly targetDate: Date;
      } | null;
      readonly provider: {
        readonly __typename: "Provider";
        readonly name: string;
        readonly id: Ids.ProviderId;
      } | null;
      readonly patient: { readonly __typename: "Patient"; readonly name: string; readonly id: Ids.PatientId };
    }>;
    readonly pipbhcAllGroupExternalAppointments: ReadonlyArray<{
      readonly __typename: "PipbhcExternalAppointment";
      readonly id: Ids.PipbhcExternalAppointmentId;
      readonly startDate: Date | null;
      readonly status: AppointmentStatus;
      readonly minutesDuration: number | null;
      readonly providerName: string | null;
      readonly patient: { readonly __typename: "Patient"; readonly name: string; readonly id: Ids.PatientId };
    }>;
  } | null;
};

export type PipbhcAllGroupAppointmentsFragment = {
  readonly __typename: "CareGroup";
  readonly pipbhcAllGroupSchedulingAppointments: ReadonlyArray<{
    readonly __typename: "Appointment";
    readonly id: Ids.AppointmentId;
    readonly comment: string | null;
    readonly endDate: Date | null;
    readonly startDate: Date | null;
    readonly status: AppointmentStatus;
    readonly minutesDuration: number | null;
    readonly patientSession: {
      readonly __typename: "PatientSession";
      readonly status: PatientSessionStatus;
      readonly targetDate: Date;
    } | null;
    readonly provider: {
      readonly __typename: "Provider";
      readonly name: string;
      readonly id: Ids.ProviderId;
    } | null;
    readonly patient: { readonly __typename: "Patient"; readonly name: string; readonly id: Ids.PatientId };
  }>;
  readonly pipbhcAllGroupExternalAppointments: ReadonlyArray<{
    readonly __typename: "PipbhcExternalAppointment";
    readonly id: Ids.PipbhcExternalAppointmentId;
    readonly startDate: Date | null;
    readonly status: AppointmentStatus;
    readonly minutesDuration: number | null;
    readonly providerName: string | null;
    readonly patient: { readonly __typename: "Patient"; readonly name: string; readonly id: Ids.PatientId };
  }>;
};

export type ConditionFragment = {
  readonly __typename: "Condition";
  readonly description: string | null;
  readonly id: Ids.ConditionId;
  readonly mnemonic: string;
  readonly name: string;
  readonly shortname: string;
  readonly identifiers: ReadonlyArray<{
    readonly __typename: "Identifier";
    readonly assigner: string;
    readonly value: string;
  }> | null;
};

export type CareGroupDetailsQueryQueryVariables = Exact<{
  careGroupId: Scalars["CareGroupId"]["input"];
}>;

export type CareGroupDetailsQueryQuery = {
  readonly __typename: "Query";
  readonly careGroup: {
    readonly __typename: "CareGroup";
    readonly id: Ids.CareGroupId;
    readonly name: string;
    readonly identifiers: ReadonlyArray<{
      readonly __typename: "Identifier";
      readonly assigner: string;
      readonly value: string;
    }> | null;
    readonly careUnitType: {
      readonly __typename: "CareUnitType";
      readonly id: Ids.CareUnitTypeId;
      readonly mnemonic: string;
    } | null;
    readonly currentCareUnitParentMemberships: ReadonlyArray<{
      readonly __typename: "CareUnitMembership";
      readonly id: Ids.CareUnitMembershipId;
      readonly child: {
        readonly __typename: "Patient";
        readonly id: Ids.PatientId;
        readonly name: string;
        readonly gender: Gender | null;
        readonly genderIdentity: string | null;
        readonly genderIdentityFhir: GenderIdentity | null;
        readonly dob: Date | null;
        readonly identifiers: ReadonlyArray<{
          readonly __typename: "Identifier";
          readonly assigner: string;
          readonly value: string;
        }> | null;
        readonly pipbhcPatientConditions: ReadonlyArray<{
          readonly __typename: "PipbhcPatientCondition";
          readonly id: Ids.PipbhcPatientConditionId;
          readonly status: PipbhcPatientConditionStatus;
          readonly onsetDate: Date;
          readonly abatementDate: Date | null;
          readonly condition: {
            readonly __typename: "Condition";
            readonly description: string | null;
            readonly id: Ids.ConditionId;
            readonly mnemonic: string;
            readonly name: string;
            readonly shortname: string;
            readonly identifiers: ReadonlyArray<{
              readonly __typename: "Identifier";
              readonly assigner: string;
              readonly value: string;
            }> | null;
          };
        }>;
      };
    }>;
    readonly groupSchedulingAppointments: {
      readonly __typename: "AppointmentConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "Appointment";
        readonly id: Ids.AppointmentId;
        readonly comment: string | null;
        readonly endDate: Date | null;
        readonly startDate: Date | null;
        readonly status: AppointmentStatus;
        readonly minutesDuration: number | null;
        readonly patientSession: {
          readonly __typename: "PatientSession";
          readonly status: PatientSessionStatus;
          readonly targetDate: Date;
        } | null;
        readonly provider: {
          readonly __typename: "Provider";
          readonly name: string;
          readonly id: Ids.ProviderId;
        } | null;
        readonly patient: {
          readonly __typename: "Patient";
          readonly name: string;
          readonly id: Ids.PatientId;
        };
      }>;
    };
    readonly groupExternalEvents: {
      readonly __typename: "ExternalEventConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "ExternalEvent";
        readonly id: Ids.ExternalEventId;
        readonly data: GqlScalars.PipbhcExternalEventData | null;
        readonly name: string;
        readonly details: string | null;
        readonly effectiveDate: Date;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly externalEventType: {
          readonly __typename: "ExternalEventType";
          readonly name: string;
          readonly mnemonic: string;
          readonly description: string;
          readonly category: ExternalEventTypeCategory;
        };
        readonly author: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string };
        readonly patient: {
          readonly __typename: "Patient";
          readonly name: string;
          readonly id: Ids.PatientId;
        };
      }>;
    };
    readonly groupCareEpisodeProviders: {
      readonly __typename: "CareEpisodeProviderConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "CareEpisodeProvider";
        readonly id: Ids.CareEpisodeProviderId;
        readonly relationship: CareEpisodeProviderRelationship;
        readonly provider: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        };
        readonly patient: { readonly __typename: "Patient"; readonly name: string };
        readonly careEpisode: {
          readonly __typename: "CareEpisode";
          readonly organization: { readonly __typename: "Organization"; readonly name: string };
        };
      }>;
    };
  } | null;
};

export type PipbhcPatientDetailsFragment = {
  readonly __typename: "Patient";
  readonly id: Ids.PatientId;
  readonly name: string;
  readonly gender: Gender | null;
  readonly genderIdentity: string | null;
  readonly genderIdentityFhir: GenderIdentity | null;
  readonly dob: Date | null;
  readonly identifiers: ReadonlyArray<{
    readonly __typename: "Identifier";
    readonly assigner: string;
    readonly value: string;
  }> | null;
  readonly pipbhcPatientConditions: ReadonlyArray<{
    readonly __typename: "PipbhcPatientCondition";
    readonly id: Ids.PipbhcPatientConditionId;
    readonly status: PipbhcPatientConditionStatus;
    readonly onsetDate: Date;
    readonly abatementDate: Date | null;
    readonly condition: {
      readonly __typename: "Condition";
      readonly description: string | null;
      readonly id: Ids.ConditionId;
      readonly mnemonic: string;
      readonly name: string;
      readonly shortname: string;
      readonly identifiers: ReadonlyArray<{
        readonly __typename: "Identifier";
        readonly assigner: string;
        readonly value: string;
      }> | null;
    };
  }>;
};

export type PipbhcPatientConditionFragment = {
  readonly __typename: "PipbhcPatientCondition";
  readonly id: Ids.PipbhcPatientConditionId;
  readonly status: PipbhcPatientConditionStatus;
  readonly onsetDate: Date;
  readonly abatementDate: Date | null;
  readonly condition: {
    readonly __typename: "Condition";
    readonly description: string | null;
    readonly id: Ids.ConditionId;
    readonly mnemonic: string;
    readonly name: string;
    readonly shortname: string;
    readonly identifiers: ReadonlyArray<{
      readonly __typename: "Identifier";
      readonly assigner: string;
      readonly value: string;
    }> | null;
  };
};

export type UpdatePipbhcGpraIdMutationVariables = Exact<{
  updateInput: UpdateGpraIdInput;
}>;

export type UpdatePipbhcGpraIdMutation = {
  readonly __typename: "Mutation";
  readonly updatePipbhcGpraId: {
    readonly __typename: "UpdateGpraIdPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdatePipbhcGpraIdResult";
      readonly patient: {
        readonly __typename: "Patient";
        readonly id: Ids.PatientId;
        readonly name: string;
        readonly gender: Gender | null;
        readonly genderIdentity: string | null;
        readonly genderIdentityFhir: GenderIdentity | null;
        readonly dob: Date | null;
        readonly identifiers: ReadonlyArray<{
          readonly __typename: "Identifier";
          readonly assigner: string;
          readonly value: string;
        }> | null;
        readonly pipbhcPatientConditions: ReadonlyArray<{
          readonly __typename: "PipbhcPatientCondition";
          readonly id: Ids.PipbhcPatientConditionId;
          readonly status: PipbhcPatientConditionStatus;
          readonly onsetDate: Date;
          readonly abatementDate: Date | null;
          readonly condition: {
            readonly __typename: "Condition";
            readonly description: string | null;
            readonly id: Ids.ConditionId;
            readonly mnemonic: string;
            readonly name: string;
            readonly shortname: string;
            readonly identifiers: ReadonlyArray<{
              readonly __typename: "Identifier";
              readonly assigner: string;
              readonly value: string;
            }> | null;
          };
        }>;
      };
    } | null;
  } | null;
};

export type CreateExternalEventMutationMutationVariables = Exact<{
  externalEvent: CreateExternalEventInput;
}>;

export type CreateExternalEventMutationMutation = {
  readonly __typename: "Mutation";
  readonly createExternalEvent: {
    readonly __typename: "CreateExternalEventPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateExternalEventResult";
      readonly externalEvent: {
        readonly __typename: "ExternalEvent";
        readonly id: Ids.ExternalEventId;
        readonly data: GqlScalars.PipbhcExternalEventData | null;
        readonly name: string;
        readonly details: string | null;
        readonly effectiveDate: Date;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly externalEventType: {
          readonly __typename: "ExternalEventType";
          readonly name: string;
          readonly mnemonic: string;
          readonly description: string;
          readonly category: ExternalEventTypeCategory;
        };
        readonly author: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string };
        readonly patient: {
          readonly __typename: "Patient";
          readonly name: string;
          readonly id: Ids.PatientId;
        };
      };
    } | null;
  } | null;
};

export type EditExternalEventMutationMutationVariables = Exact<{
  externalEvent: EditExternalEventInput;
}>;

export type EditExternalEventMutationMutation = {
  readonly __typename: "Mutation";
  readonly editExternalEvent: {
    readonly __typename: "EditExternalEventPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "EditExternalEventResult";
      readonly externalEvent: {
        readonly __typename: "ExternalEvent";
        readonly id: Ids.ExternalEventId;
        readonly data: GqlScalars.PipbhcExternalEventData | null;
        readonly name: string;
        readonly details: string | null;
        readonly effectiveDate: Date;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly externalEventType: {
          readonly __typename: "ExternalEventType";
          readonly name: string;
          readonly mnemonic: string;
          readonly description: string;
          readonly category: ExternalEventTypeCategory;
        };
        readonly author: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string };
        readonly patient: {
          readonly __typename: "Patient";
          readonly name: string;
          readonly id: Ids.PatientId;
        };
      };
    } | null;
  } | null;
};

export type ExternalEventFragmentFragment = {
  readonly __typename: "ExternalEvent";
  readonly id: Ids.ExternalEventId;
  readonly data: GqlScalars.PipbhcExternalEventData | null;
  readonly name: string;
  readonly details: string | null;
  readonly effectiveDate: Date;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly externalEventType: {
    readonly __typename: "ExternalEventType";
    readonly name: string;
    readonly mnemonic: string;
    readonly description: string;
    readonly category: ExternalEventTypeCategory;
  };
  readonly author: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string };
  readonly patient: { readonly __typename: "Patient"; readonly name: string; readonly id: Ids.PatientId };
};

export type CreatePipbhcTreatmentGoalMutationVariables = Exact<{
  treatmentGoal: CreatePipbhcTreatmentGoalInput;
}>;

export type CreatePipbhcTreatmentGoalMutation = {
  readonly __typename: "Mutation";
  readonly createPipbhcTreatmentGoal: {
    readonly __typename: "CreatePipbhcTreatmentGoalPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreatePipbhcTreatmentGoalResult";
      readonly treatmentGoal: {
        readonly __typename: "PipbhcTreatmentGoal";
        readonly id: Ids.PipbhcTreatmentGoalId;
        readonly goalText: string;
        readonly note: string | null;
        readonly status: PipbhcTreatmentGoalStatus;
      };
    } | null;
  } | null;
};

export type CreatePipbhcTreatmentGoalReportMutationVariables = Exact<{
  pipbhcTreatmentGoalReport: CreatePipbhcTreatmentGoalReportInput;
}>;

export type CreatePipbhcTreatmentGoalReportMutation = {
  readonly __typename: "Mutation";
  readonly createPipbhcTreatmentGoalReport: {
    readonly __typename: "CreatePipbhcTreatmentGoalReportPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreatePipbhcTreatmentGoalReportResult";
      readonly pipbhcTreatmentGoalReport: {
        readonly __typename: "PipbhcTreatmentGoalReport";
        readonly id: Ids.PipbhcTreatmentGoalReportId;
        readonly effectiveDate: Date;
        readonly note: string | null;
      };
    } | null;
  } | null;
};

export type PipbhcTreatmentGoalDetailsQueryVariables = Exact<{
  pipbhcTreatmentGoalId: Scalars["PipbhcTreatmentGoalId"]["input"];
}>;

export type PipbhcTreatmentGoalDetailsQuery = {
  readonly __typename: "Query";
  readonly pipbhcTreatmentGoal: {
    readonly __typename: "PipbhcTreatmentGoal";
    readonly id: Ids.PipbhcTreatmentGoalId;
    readonly goalText: string;
    readonly note: string | null;
    readonly status: PipbhcTreatmentGoalStatus;
    readonly patient: {
      readonly __typename: "Patient";
      readonly id: Ids.PatientId;
      readonly supportsUser: boolean;
      readonly name: string;
    };
    readonly pipbhcTreatmentGoalAnswers: ReadonlyArray<{
      readonly __typename: "PipbhcTreatmentGoalAnswer";
      readonly id: Ids.PipbhcTreatmentGoalAnswerId;
      readonly value: number | null;
      readonly note: string | null;
      readonly pipbhcTreatmentGoalReport: {
        readonly __typename: "PipbhcTreatmentGoalReport";
        readonly id: Ids.PipbhcTreatmentGoalReportId;
        readonly effectiveDate: Date;
        readonly note: string | null;
      };
    }>;
  } | null;
};

export type PipbhcTreatmentGoalReportDetailsQueryVariables = Exact<{
  pipbhcTreatmentGoalReportId: Scalars["PipbhcTreatmentGoalReportId"]["input"];
}>;

export type PipbhcTreatmentGoalReportDetailsQuery = {
  readonly __typename: "Query";
  readonly pipbhcTreatmentGoalReport: {
    readonly __typename: "PipbhcTreatmentGoalReport";
    readonly id: Ids.PipbhcTreatmentGoalReportId;
    readonly effectiveDate: Date;
    readonly note: string | null;
    readonly pipbhcTreatmentGoalAnswers: ReadonlyArray<{
      readonly __typename: "PipbhcTreatmentGoalAnswer";
      readonly id: Ids.PipbhcTreatmentGoalAnswerId;
      readonly value: number | null;
      readonly note: string | null;
      readonly pipbhcTreatmentGoal: {
        readonly __typename: "PipbhcTreatmentGoal";
        readonly id: Ids.PipbhcTreatmentGoalId;
        readonly goalText: string;
        readonly note: string | null;
        readonly status: PipbhcTreatmentGoalStatus;
        readonly patient: {
          readonly __typename: "Patient";
          readonly id: Ids.PatientId;
          readonly supportsUser: boolean;
          readonly name: string;
        };
      };
      readonly pipbhcTreatmentGoalReport: {
        readonly __typename: "PipbhcTreatmentGoalReport";
        readonly id: Ids.PipbhcTreatmentGoalReportId;
        readonly effectiveDate: Date;
        readonly note: string | null;
      };
    }>;
  } | null;
};

export type UpdatePipbhcTreatmentGoalMutationVariables = Exact<{
  treatmentGoalChanges: UpdateTreatmentGoalInput;
}>;

export type UpdatePipbhcTreatmentGoalMutation = {
  readonly __typename: "Mutation";
  readonly updatePipbhcTreatmentGoal: {
    readonly __typename: "UpdateTreatmentGoalPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdatePipbhcTreatmentGoalResult";
      readonly treatmentGoal: {
        readonly __typename: "PipbhcTreatmentGoal";
        readonly id: Ids.PipbhcTreatmentGoalId;
        readonly goalText: string;
        readonly note: string | null;
        readonly status: PipbhcTreatmentGoalStatus;
      };
    } | null;
  } | null;
};

export type CareGroupTreatmentGoalsDetailsQueryVariables = Exact<{
  careGroupId: Scalars["CareGroupId"]["input"];
}>;

export type CareGroupTreatmentGoalsDetailsQuery = {
  readonly __typename: "Query";
  readonly careGroup: {
    readonly __typename: "CareGroup";
    readonly id: Ids.CareGroupId;
    readonly name: string;
    readonly pipbhcTreatmentGoals: ReadonlyArray<{
      readonly __typename: "PipbhcTreatmentGoal";
      readonly id: Ids.PipbhcTreatmentGoalId;
      readonly goalText: string;
      readonly note: string | null;
      readonly status: PipbhcTreatmentGoalStatus;
      readonly pipbhcTreatmentGoalAnswers: ReadonlyArray<{
        readonly __typename: "PipbhcTreatmentGoalAnswer";
        readonly id: Ids.PipbhcTreatmentGoalAnswerId;
        readonly value: number | null;
        readonly note: string | null;
        readonly pipbhcTreatmentGoalReport: {
          readonly __typename: "PipbhcTreatmentGoalReport";
          readonly id: Ids.PipbhcTreatmentGoalReportId;
          readonly effectiveDate: Date;
          readonly note: string | null;
        };
      }>;
    }>;
    readonly currentCareUnitParentMemberships: ReadonlyArray<{
      readonly __typename: "CareUnitMembership";
      readonly id: Ids.CareUnitMembershipId;
      readonly child: {
        readonly __typename: "Patient";
        readonly dob: Date | null;
        readonly id: Ids.PatientId;
        readonly name: string;
        readonly pipbhcTreatmentGoals: ReadonlyArray<{
          readonly __typename: "PipbhcTreatmentGoal";
          readonly id: Ids.PipbhcTreatmentGoalId;
          readonly goalText: string;
          readonly note: string | null;
          readonly status: PipbhcTreatmentGoalStatus;
          readonly pipbhcTreatmentGoalAnswers: ReadonlyArray<{
            readonly __typename: "PipbhcTreatmentGoalAnswer";
            readonly id: Ids.PipbhcTreatmentGoalAnswerId;
            readonly value: number | null;
            readonly note: string | null;
            readonly pipbhcTreatmentGoalReport: {
              readonly __typename: "PipbhcTreatmentGoalReport";
              readonly id: Ids.PipbhcTreatmentGoalReportId;
              readonly effectiveDate: Date;
              readonly note: string | null;
            };
          }>;
        }>;
      };
    }>;
  } | null;
};

export type PipbhcTreatmentGoalReportFragment = {
  readonly __typename: "PipbhcTreatmentGoalReport";
  readonly id: Ids.PipbhcTreatmentGoalReportId;
  readonly effectiveDate: Date;
  readonly note: string | null;
};

export type PipbhcTreatmentGoalFragment = {
  readonly __typename: "PipbhcTreatmentGoal";
  readonly id: Ids.PipbhcTreatmentGoalId;
  readonly goalText: string;
  readonly note: string | null;
  readonly status: PipbhcTreatmentGoalStatus;
};

export type PipbhcTreatmentGoalAnswerFragment = {
  readonly __typename: "PipbhcTreatmentGoalAnswer";
  readonly id: Ids.PipbhcTreatmentGoalAnswerId;
  readonly value: number | null;
  readonly note: string | null;
  readonly pipbhcTreatmentGoalReport: {
    readonly __typename: "PipbhcTreatmentGoalReport";
    readonly id: Ids.PipbhcTreatmentGoalReportId;
    readonly effectiveDate: Date;
    readonly note: string | null;
  };
};

export type CareGroupListQueryQueryVariables = Exact<{
  careUnitTypeMnemonic: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  search: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CareGroupListQueryQuery = {
  readonly __typename: "Query";
  readonly careGroups: {
    readonly __typename: "CareGroupConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "CareGroup";
      readonly id: Ids.CareGroupId;
      readonly name: string;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type PatientParticipantsQueryQueryVariables = Exact<{
  patientId: Scalars["PatientId"]["input"];
}>;

export type PatientParticipantsQueryQuery = {
  readonly __typename: "Query";
  readonly patient: {
    readonly __typename: "Patient";
    readonly id: Ids.PatientId;
    readonly clientParticipantUsers: ReadonlyArray<{
      readonly __typename: "User";
      readonly id: Ids.UserId;
      readonly name: string;
      readonly firstName: string | null;
    }>;
  } | null;
};

export type SearchPatientsQueryVariables = Exact<{
  search: Scalars["String"]["input"];
}>;

export type SearchPatientsQuery = {
  readonly __typename: "Query";
  readonly patients: {
    readonly __typename: "PatientConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Patient";
      readonly id: Ids.PatientId;
      readonly name: string;
      readonly gender: Gender | null;
      readonly genderIdentity: string | null;
      readonly genderIdentityFhir: GenderIdentity | null;
      readonly dob: Date | null;
      readonly identifiers: ReadonlyArray<{
        readonly __typename: "Identifier";
        readonly assigner: string;
        readonly value: string;
      }> | null;
      readonly pipbhcPatientConditions: ReadonlyArray<{
        readonly __typename: "PipbhcPatientCondition";
        readonly id: Ids.PipbhcPatientConditionId;
        readonly status: PipbhcPatientConditionStatus;
        readonly onsetDate: Date;
        readonly abatementDate: Date | null;
        readonly condition: {
          readonly __typename: "Condition";
          readonly description: string | null;
          readonly id: Ids.ConditionId;
          readonly mnemonic: string;
          readonly name: string;
          readonly shortname: string;
          readonly identifiers: ReadonlyArray<{
            readonly __typename: "Identifier";
            readonly assigner: string;
            readonly value: string;
          }> | null;
        };
      }>;
    }>;
  } | null;
};

export type AssessmentReportProviderObligationsQueryVariables = Exact<{
  reportId: Scalars["String"]["input"];
  providerId: Scalars["String"]["input"];
}>;

export type AssessmentReportProviderObligationsQuery = {
  readonly __typename: "Query";
  readonly assessmentReportProviderObligations: {
    readonly __typename: "ReportProviderObligations";
    readonly obligations: ReadonlyArray<ReportProviderObligationsEnum>;
  } | null;
};

export type GenerateUserLoginCodeMutationVariables = Exact<{
  input: GenerateUserLoginCodeInput;
}>;

export type GenerateUserLoginCodeMutation = {
  readonly __typename: "Mutation";
  readonly generateUserLoginCode: {
    readonly __typename: "GenerateUserLoginCodePayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "GenerateUserLoginCodeResult";
      readonly loginCodes: ReadonlyArray<{
        readonly __typename: "UserLoginCode";
        readonly code: string;
        readonly relationship: ParticipantRelationship;
        readonly user: { readonly __typename: "User"; readonly name: string };
      }>;
    } | null;
  } | null;
};

export type ProviderAssessmentInvitationsQueryVariables = Exact<{
  status: InputMaybe<ReadonlyArray<InvitationStatus> | InvitationStatus>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  sortBy: InputMaybe<InvitationSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  userId: InputMaybe<ReadonlyArray<Scalars["UserId"]["input"]> | Scalars["UserId"]["input"]>;
  isWaitingOnUserStates: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type ProviderAssessmentInvitationsQuery = {
  readonly __typename: "Query";
  readonly assessmentInvitations: {
    readonly __typename: "InvitationConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Invitation";
      readonly id: Ids.InvitationId;
      readonly referrer: InvitationLoginMethod | null;
      readonly status: InvitationStatus;
      readonly user: {
        readonly __typename: "User";
        readonly id: Ids.UserId;
        readonly patient: {
          readonly __typename: "Patient";
          readonly name: string;
          readonly id: Ids.PatientId;
        } | null;
      };
      readonly takingUser: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
      readonly report: {
        readonly __typename: "Report";
        readonly effectiveDate: Date | null;
        readonly patientSession: {
          readonly __typename: "PatientSession";
          readonly id: Ids.PatientSessionId;
          readonly isReportAvailable: boolean;
          readonly isReportComplete: boolean;
          readonly targetDate: Date;
          readonly patient: {
            readonly __typename: "Patient";
            readonly name: string;
            readonly id: Ids.PatientId;
          };
        } | null;
      };
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type ProviderSchedulingAppointmentsQueryVariables = Exact<{
  sortBy: InputMaybe<AppointmentScheduleSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  provider: InputMaybe<ProviderFilter>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  flags: InputMaybe<ReadonlyArray<Scalars["FlagId"]["input"]> | Scalars["FlagId"]["input"]>;
  testClient: InputMaybe<Scalars["Boolean"]["input"]>;
  unit: InputMaybe<OrganizationFilter>;
  startDateBefore: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  startDateAfter: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  measurementCategory: InputMaybe<
    ReadonlyArray<AppointmentMeasurementCategory> | AppointmentMeasurementCategory
  >;
  search: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ProviderSchedulingAppointmentsQuery = {
  readonly __typename: "Query";
  readonly schedulingAppointmentSchedules: {
    readonly __typename: "AppointmentScheduleConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "AppointmentSchedule";
      readonly startDate: Date | null;
      readonly status: AppointmentStatus;
      readonly id: Ids.AppointmentId;
      readonly measurementStatus: AppointmentMeasurementStatus;
      readonly measurementCategory: AppointmentMeasurementCategory;
      readonly patientName: string | null;
      readonly patientId: Ids.PatientId;
      readonly providerName: string | null;
      readonly patientSession: {
        readonly __typename: "PatientSession";
        readonly id: Ids.PatientSessionId;
        readonly isReportAvailable: boolean;
        readonly assessmentReport: { readonly __typename: "Report"; readonly id: Ids.ReportId } | null;
      } | null;
      readonly patientFlags: ReadonlyArray<{
        readonly __typename: "PatientFlag";
        readonly id: Ids.PatientFlagId;
        readonly active: boolean;
        readonly flag: {
          readonly __typename: "Flag";
          readonly id: Ids.FlagId;
          readonly name: string;
          readonly icon: string;
        };
      }>;
      readonly currentStats: {
        readonly __typename: "CurrentStats";
        readonly complexity: number | null;
        readonly severity: number | null;
        readonly severityTrend: number | null;
      } | null;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type UpdateAssessmentInvitationMutationMutationVariables = Exact<{
  invitationInput: UpdateInvitationInput;
}>;

export type UpdateAssessmentInvitationMutationMutation = {
  readonly __typename: "Mutation";
  readonly assessmentUpdateInvitation: {
    readonly __typename: "UpdateInvitationPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateInvitationResult";
      readonly invitationId: Ids.InvitationId;
      readonly status: InvitationStatus | null;
    } | null;
  } | null;
};

export type AlgorithmDetailsQueryVariables = Exact<{
  entityDecisionAlgorithmId: Scalars["EntityDecisionAlgorithmId"]["input"];
}>;

export type AlgorithmDetailsQuery = {
  readonly __typename: "Query";
  readonly decisionSupportEntityDecisionAlgorithm: {
    readonly __typename: "EntityDecisionAlgorithm";
    readonly id: Ids.EntityDecisionAlgorithmId;
    readonly name: string;
    readonly notes: string | null;
    readonly status: EntityDecisionAlgorithmStatus;
    readonly startDate: Date | null;
    readonly sendNotifications: boolean;
    readonly entityTreeNode: {
      readonly __typename: "EntityTreeNode";
      readonly path: string;
      readonly minAge: number | null;
      readonly maxAge: number | null;
      readonly entity: {
        readonly __typename: "Entity";
        readonly id: Ids.EntityId;
        readonly name: string;
        readonly shortname: string;
        readonly entityType: EntityType;
      };
      readonly segments: ReadonlyArray<{
        readonly __typename: "EntityTreeSegment";
        readonly id: Ids.EntityId;
        readonly path: string;
        readonly entityType: EntityType;
        readonly name: string;
        readonly shortname: string;
        readonly rootPath: string;
      }>;
    };
    readonly decisionAlgorithm: {
      readonly __typename: "DecisionAlgorithm";
      readonly id: Ids.DecisionAlgorithmId;
      readonly name: string;
      readonly shortDescription: string;
      readonly fullDescription: string;
    };
  } | null;
};

export type EntityDecisionAlgorithmListQueryVariables = Exact<{
  sortBy: InputMaybe<EntityDecisionAlgorithmSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  status: InputMaybe<ReadonlyArray<EntityDecisionAlgorithmStatus> | EntityDecisionAlgorithmStatus>;
}>;

export type EntityDecisionAlgorithmListQuery = {
  readonly __typename: "Query";
  readonly decisionSupportEntityDecisionAlgorithms: {
    readonly __typename: "EntityDecisionAlgorithmConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "EntityDecisionAlgorithm";
      readonly id: Ids.EntityDecisionAlgorithmId;
      readonly name: string;
      readonly decisionAlgorithm: {
        readonly __typename: "DecisionAlgorithm";
        readonly id: Ids.DecisionAlgorithmId;
      };
      readonly entityTreeNode: {
        readonly __typename: "EntityTreeNode";
        readonly path: string;
        readonly minAge: number | null;
        readonly maxAge: number | null;
        readonly entity: {
          readonly __typename: "Entity";
          readonly id: Ids.EntityId;
          readonly name: string;
          readonly shortname: string;
          readonly entityType: EntityType;
        };
        readonly segments: ReadonlyArray<{
          readonly __typename: "EntityTreeSegment";
          readonly id: Ids.EntityId;
          readonly path: string;
          readonly entityType: EntityType;
          readonly name: string;
          readonly shortname: string;
          readonly rootPath: string;
        }>;
      };
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type ProviderInboxNotificationsQueryVariables = Exact<{
  sortBy: InputMaybe<ProviderNotificationSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  provider: ProviderFilter;
  patient: PatientFilter;
  workflowStatus: InputMaybe<TriggeredWorkflowStatus>;
  workflow: WorkflowFilter;
}>;

export type ProviderInboxNotificationsQuery = {
  readonly __typename: "Query";
  readonly decisionSupportProviderNotifications: {
    readonly __typename: "ProviderNotificationConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "ProviderNotification";
      readonly id: Ids.ProviderNotificationId;
      readonly sentAt: Date | null;
      readonly triggeredWorkflow: {
        readonly __typename: "TriggeredWorkflow";
        readonly id: Ids.TriggeredWorkflowId;
        readonly status: TriggeredWorkflowStatus;
        readonly workflow: { readonly __typename: "Workflow"; readonly title: string };
        readonly triggeredDecisionAlgorithm: {
          readonly __typename: "TriggeredDecisionAlgorithm";
          readonly id: Ids.TriggeredDecisionAlgorithmId;
          readonly careEpisode: {
            readonly __typename: "CareEpisode";
            readonly id: Ids.CareEpisodeId;
            readonly patient: {
              readonly __typename: "Patient";
              readonly id: Ids.PatientId;
              readonly name: string;
            };
          };
        };
      };
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type RunWorkflowActionMutationVariables = Exact<{
  input: RunWorkflowActionInput;
}>;

export type RunWorkflowActionMutation = {
  readonly __typename: "Mutation";
  readonly decisionSupportRunWorkflowAction: {
    readonly __typename: "RunWorkflowActionPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "RunWorkflowActionResult";
      readonly triggeredWorkflow: {
        readonly __typename: "TriggeredWorkflow";
        readonly id: Ids.TriggeredWorkflowId;
        readonly status: TriggeredWorkflowStatus;
        readonly snoozedUntil: Date | null;
        readonly triggeredAt: Date;
        readonly reasons: ReadonlyArray<string>;
        readonly statusUpdatedAt: Date | null;
        readonly statusUpdatedBy: {
          readonly __typename: "Provider";
          readonly id: Ids.ProviderId;
          readonly name: string;
        } | null;
        readonly triggeredWorkflowActions: ReadonlyArray<{
          readonly __typename: "TriggeredWorkflowAction";
          readonly id: Ids.TriggeredWorkflowActionId;
          readonly status: TriggeredWorkflowActionStatus;
          readonly workflowAction: {
            readonly __typename: "WorkflowAction";
            readonly id: Ids.WorkflowActionId;
            readonly text: string;
          };
        }>;
      };
    } | null;
  } | null;
};

export type TriggeredWorkflowDetailsQueryVariables = Exact<{
  triggeredWorkflowId: Scalars["TriggeredWorkflowId"]["input"];
}>;

export type TriggeredWorkflowDetailsQuery = {
  readonly __typename: "Query";
  readonly decisionSupportTriggeredWorkflow: {
    readonly __typename: "TriggeredWorkflow";
    readonly id: Ids.TriggeredWorkflowId;
    readonly status: TriggeredWorkflowStatus;
    readonly snoozedUntil: Date | null;
    readonly triggeredAt: Date;
    readonly reasons: ReadonlyArray<string>;
    readonly statusUpdatedAt: Date | null;
    readonly triggeredDecisionAlgorithm: {
      readonly __typename: "TriggeredDecisionAlgorithm";
      readonly id: Ids.TriggeredDecisionAlgorithmId;
      readonly entityDecisionAlgorithm: {
        readonly __typename: "EntityDecisionAlgorithm";
        readonly id: Ids.EntityDecisionAlgorithmId;
        readonly workflowNote: string | null;
      };
      readonly careEpisode: {
        readonly __typename: "CareEpisode";
        readonly id: Ids.CareEpisodeId;
        readonly patient: {
          readonly __typename: "Patient";
          readonly id: Ids.PatientId;
          readonly name: string;
        };
      };
    };
    readonly workflow: {
      readonly __typename: "Workflow";
      readonly id: Ids.WorkflowId;
      readonly title: string;
      readonly callToAction: string;
      readonly instructions: string | null;
    };
    readonly statusUpdatedBy: {
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
    } | null;
    readonly triggeredWorkflowActions: ReadonlyArray<{
      readonly __typename: "TriggeredWorkflowAction";
      readonly id: Ids.TriggeredWorkflowActionId;
      readonly status: TriggeredWorkflowActionStatus;
      readonly workflowAction: {
        readonly __typename: "WorkflowAction";
        readonly id: Ids.WorkflowActionId;
        readonly text: string;
      };
    }>;
  } | null;
};

export type TriggeredWorkflowSummaryFragment = {
  readonly __typename: "TriggeredWorkflow";
  readonly id: Ids.TriggeredWorkflowId;
  readonly status: TriggeredWorkflowStatus;
  readonly snoozedUntil: Date | null;
  readonly triggeredAt: Date;
  readonly reasons: ReadonlyArray<string>;
  readonly statusUpdatedAt: Date | null;
  readonly statusUpdatedBy: {
    readonly __typename: "Provider";
    readonly id: Ids.ProviderId;
    readonly name: string;
  } | null;
  readonly triggeredWorkflowActions: ReadonlyArray<{
    readonly __typename: "TriggeredWorkflowAction";
    readonly id: Ids.TriggeredWorkflowActionId;
    readonly status: TriggeredWorkflowActionStatus;
    readonly workflowAction: {
      readonly __typename: "WorkflowAction";
      readonly id: Ids.WorkflowActionId;
      readonly text: string;
    };
  }>;
};

export type TriggeredWorkflowListQueryVariables = Exact<{
  sortBy: InputMaybe<TriggeredWorkflowSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  provider: ProviderFilter;
  patient: PatientFilter;
  status: InputMaybe<TriggeredWorkflowStatus>;
  workflow: WorkflowFilter;
}>;

export type TriggeredWorkflowListQuery = {
  readonly __typename: "Query";
  readonly decisionSupportTriggeredWorkflows: {
    readonly __typename: "TriggeredWorkflowConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "TriggeredWorkflow";
      readonly id: Ids.TriggeredWorkflowId;
      readonly status: TriggeredWorkflowStatus;
      readonly workflow: { readonly __typename: "Workflow"; readonly title: string };
      readonly triggeredDecisionAlgorithm: {
        readonly __typename: "TriggeredDecisionAlgorithm";
        readonly id: Ids.TriggeredDecisionAlgorithmId;
        readonly careEpisode: {
          readonly __typename: "CareEpisode";
          readonly id: Ids.CareEpisodeId;
          readonly patient: {
            readonly __typename: "Patient";
            readonly id: Ids.PatientId;
            readonly name: string;
          };
        };
      };
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
      readonly startCursor: string | null;
    };
  } | null;
};

export type WorkflowSelectQueryVariables = Exact<{
  search: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type WorkflowSelectQuery = {
  readonly __typename: "Query";
  readonly decisionSupportInstituteWorkflows: {
    readonly __typename: "InstituteWorkflowConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "InstituteWorkflow";
      readonly workflow: {
        readonly __typename: "Workflow";
        readonly id: Ids.WorkflowId;
        readonly title: string;
      };
    }>;
  } | null;
};

export type ProviderActiveDecisionsQueryVariables = Exact<{ [key: string]: never }>;

export type ProviderActiveDecisionsQuery = {
  readonly __typename: "Query";
  readonly decisionSupportMyProviderNotifications: {
    readonly __typename: "ProviderNotificationConnection";
    readonly totalCount: number;
  };
};

export type ProviderUserQueryVariables = Exact<{
  id: Scalars["UserId"]["input"];
}>;

export type ProviderUserQuery = {
  readonly __typename: "Query";
  readonly user: { readonly __typename: "User"; readonly id: Ids.UserId; readonly name: string } | null;
};

export type InstituteUpdateMeasurementAllowedMutationVariables = Exact<{
  input: InstituteUpdateMeasurementAllowedInput;
}>;

export type InstituteUpdateMeasurementAllowedMutation = {
  readonly __typename: "Mutation";
  readonly settingsInstituteUpdateMeasurementAllowed: {
    readonly __typename: "InstituteUpdateMeasurementAllowedPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "InstituteUpdateMeasurementAllowedResult";
      readonly measurementAllowed: boolean;
    } | null;
  } | null;
};

export type InstituteSettingsQueryVariables = Exact<{
  instituteId: Scalars["InstituteId"]["input"];
}>;

export type InstituteSettingsQuery = {
  readonly __typename: "Query";
  readonly institute: {
    readonly __typename: "Institute";
    readonly id: Ids.InstituteId;
    readonly measurementAllowed: boolean;
    readonly organizations: {
      readonly __typename: "OrganizationConnection";
      readonly nodes: ReadonlyArray<{
        readonly __typename: "Organization";
        readonly id: Ids.OrganizationId;
        readonly name: string;
      }>;
    };
  } | null;
};

export type OrganizationSettingsQueryVariables = Exact<{
  id: Scalars["OrganizationId"]["input"];
}>;

export type OrganizationSettingsQuery = {
  readonly __typename: "Query";
  readonly organization: {
    readonly __typename: "Organization";
    readonly id: Ids.OrganizationId;
    readonly measurementAllowed: boolean;
    readonly inTrialPeriod: boolean;
    readonly name: string;
    readonly timezone: string | null;
  } | null;
  readonly timezones: ReadonlyArray<{ readonly __typename: "Timezone"; readonly timezone: string }> | null;
};

export type OrganizationUpdateMeasurementAllowedMutationVariables = Exact<{
  input: OrganizationUpdateMeasurementAllowedInput;
}>;

export type OrganizationUpdateMeasurementAllowedMutation = {
  readonly __typename: "Mutation";
  readonly settingsOrganizationUpdateMeasurementAllowed: {
    readonly __typename: "OrganizationUpdateMeasurementAllowedPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "OrganizationUpdateMeasurementAllowedResult";
      readonly measurementAllowed: boolean;
    } | null;
  } | null;
};

export type UpdateOrganizationSettingsMutationVariables = Exact<{
  input: UpdateOrganizationSettingsInput;
}>;

export type UpdateOrganizationSettingsMutation = {
  readonly __typename: "Mutation";
  readonly settingsOrganizationUpdateOrganizationSettings: {
    readonly __typename: "UpdateOrganizationSettingsPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateOrganizationSettingsResult";
      readonly organization: {
        readonly __typename: "Organization";
        readonly id: Ids.OrganizationId;
        readonly timezone: string | null;
      };
    } | null;
  } | null;
};

export type UpdateTrialPeriodMutationVariables = Exact<{
  input: UpdateTrialPeriodInput;
}>;

export type UpdateTrialPeriodMutation = {
  readonly __typename: "Mutation";
  readonly settingsOrganizationUpdateTrialPeriod: {
    readonly __typename: "UpdateTrialPeriodPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateTrialPeriodResult";
      readonly inTrialPeriod: boolean;
      readonly organizationId: Ids.OrganizationId;
    } | null;
  } | null;
};

export type CreateTreatmentTrackMutationVariables = Exact<{
  input: CreateTreatmentTrackInput;
}>;

export type CreateTreatmentTrackMutation = {
  readonly __typename: "Mutation";
  readonly careCreateTreatmentTrack: {
    readonly __typename: "CreateTreatmentTrackPayload";
    readonly success: boolean;
    readonly result: {
      readonly __typename: "CreateTreatmentTrackResult";
      readonly treatmentTrack: { readonly __typename: "TreatmentTrack"; readonly id: Ids.TreatmentTrackId };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
  } | null;
};

export type ShowTreatmentTrackQueryVariables = Exact<{
  id: Scalars["TreatmentTrackId"]["input"];
}>;

export type ShowTreatmentTrackQuery = {
  readonly __typename: "Query";
  readonly careTreatmentTrack: {
    readonly __typename: "TreatmentTrack";
    readonly id: Ids.TreatmentTrackId;
    readonly name: string;
    readonly status: TreatmentTrackStatus;
  } | null;
};

export type TreatmentTracksQueryVariables = Exact<{ [key: string]: never }>;

export type TreatmentTracksQuery = {
  readonly __typename: "Query";
  readonly careTreatmentTracks: {
    readonly __typename: "TreatmentTrackConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "TreatmentTrack";
      readonly id: Ids.TreatmentTrackId;
      readonly name: string;
      readonly status: TreatmentTrackStatus;
    }>;
  } | null;
};

export type UpdateTreatmentTrackMutationVariables = Exact<{
  input: UpdateTreatmentTrackInput;
}>;

export type UpdateTreatmentTrackMutation = {
  readonly __typename: "Mutation";
  readonly careUpdateTreatmentTrack: {
    readonly __typename: "UpdateTreatmentTrackPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "UpdateTreatmentTrackResult";
      readonly treatmentTrack: {
        readonly __typename: "TreatmentTrack";
        readonly id: Ids.TreatmentTrackId;
        readonly name: string;
        readonly status: TreatmentTrackStatus;
      };
    } | null;
  } | null;
};

export type CreateExportMutationVariables = Exact<{
  input: CreateDataDumpInput;
}>;

export type CreateExportMutation = {
  readonly __typename: "Mutation";
  readonly exportCreateDataDump: {
    readonly __typename: "CreateDataDumpPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateDataDumpResult";
      readonly dataDump: { readonly __typename: "DataDump"; readonly id: Ids.DataDumpId };
    } | null;
  } | null;
};

export type DownloadExportMutationVariables = Exact<{
  input: CreateDataDumpDownloadUrlInput;
}>;

export type DownloadExportMutation = {
  readonly __typename: "Mutation";
  readonly exportCreateDataDumpDownloadUrl: {
    readonly __typename: "CreateDataDumpDownloadUrlPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "CreateDataDumpDownloadUrlResult";
      readonly downloadUrl: string;
    } | null;
  } | null;
};

export type ExportListQueryVariables = Exact<{
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  sortBy: InputMaybe<DataDumpSortParameter>;
  sortDirection: InputMaybe<SortDirection>;
}>;

export type ExportListQuery = {
  readonly __typename: "Query";
  readonly exportDataDumps: {
    readonly __typename: "DataDumpConnection";
    readonly totalCount: number;
    readonly nodes: ReadonlyArray<{
      readonly __typename: "DataDump";
      readonly id: Ids.DataDumpId;
      readonly status: DataDumpStatus;
      readonly name: string;
      readonly password: string | null;
      readonly resources: ReadonlyArray<DataDumpResource>;
      readonly createdAt: Date;
    }>;
    readonly pageInfo: {
      readonly __typename: "PageInfo";
      readonly startCursor: string | null;
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
    };
  } | null;
};

export type ProviderSettingsQueryVariables = Exact<{
  id: Scalars["ProviderId"]["input"];
}>;

export type ProviderSettingsQuery = {
  readonly __typename: "Query";
  readonly provider: {
    readonly __typename: "Provider";
    readonly id: Ids.ProviderId;
    readonly measurementAllowed: boolean;
  } | null;
};

export type ProviderUpdateMeasurementAllowedMutationVariables = Exact<{
  input: ProviderUpdateMeasurementAllowedInput;
}>;

export type ProviderUpdateMeasurementAllowedMutation = {
  readonly __typename: "Mutation";
  readonly settingsProviderUpdateMeasurementAllowed: {
    readonly __typename: "ProviderUpdateMeasurementAllowedPayload";
    readonly success: boolean;
    readonly errors: ReadonlyArray<{
      readonly __typename: "UserError";
      readonly message: string;
      readonly path: ReadonlyArray<string> | null;
    }>;
    readonly result: {
      readonly __typename: "ProviderUpdateMeasurementAllowedResult";
      readonly measurementAllowed: boolean;
    } | null;
  } | null;
};

export type CreateAnalyticsEventMutationMutationVariables = Exact<{
  event: CreateAnalyticsEventInput;
}>;

export type CreateAnalyticsEventMutationMutation = {
  readonly __typename: "Mutation";
  readonly createAnalyticsEvent: {
    readonly __typename: "CreateAnalyticsEventPayload";
    readonly ok: boolean;
    readonly errors: ReadonlyArray<string> | null;
    readonly success: boolean;
    readonly result: string | null;
  } | null;
};

export type CareEpisodeDetailFragmentFragment = {
  readonly __typename: "CareEpisode";
  readonly id: Ids.CareEpisodeId;
  readonly status: CareEpisodeStatus;
  readonly periodStart: Date | null;
  readonly periodEnd: Date | null;
  readonly patientSessions: {
    readonly __typename: "PatientSessionConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "PatientSession";
      readonly id: Ids.PatientSessionId;
      readonly sessionNumber: number | null;
      readonly targetDate: Date;
      readonly assessmentReport: { readonly __typename: "Report"; readonly id: Ids.ReportId } | null;
    }>;
  };
  readonly organization: {
    readonly __typename: "Organization";
    readonly id: Ids.OrganizationId;
    readonly name: string;
  };
  readonly participants: ReadonlyArray<
    | {
        readonly __typename: "Patient";
        readonly participantId: Ids.ParticipantId;
        readonly relationship: ParticipantRelationship;
        readonly user: {
          readonly __typename: "User";
          readonly id: Ids.UserId;
          readonly name: string;
          readonly firstName: string | null;
        };
      }
    | {
        readonly __typename: "Provider";
        readonly participantId: Ids.ParticipantId;
        readonly relationship: ParticipantRelationship;
        readonly user: {
          readonly __typename: "User";
          readonly id: Ids.UserId;
          readonly name: string;
          readonly firstName: string | null;
        };
      }
    | {
        readonly __typename: "RelatedPerson";
        readonly participantId: Ids.ParticipantId;
        readonly relationship: ParticipantRelationship;
        readonly user: {
          readonly __typename: "User";
          readonly id: Ids.UserId;
          readonly name: string;
          readonly firstName: string | null;
        };
      }
  >;
};

export type CareEpisodeSummaryFragment = {
  readonly __typename: "CareEpisode";
  readonly id: Ids.CareEpisodeId;
  readonly status: CareEpisodeStatus;
  readonly periodStart: Date | null;
  readonly periodEnd: Date | null;
  readonly organization: {
    readonly __typename: "Organization";
    readonly id: Ids.OrganizationId;
    readonly name: string;
  };
};

export type ConstructSummaryFragment = {
  readonly __typename: "Construct";
  readonly id: Ids.ConstructId;
  readonly name: string;
  readonly shortname: string;
  readonly description: string | null;
  readonly displayOrder: number;
  readonly domain: { readonly __typename: "Domain"; readonly id: Ids.DomainId; readonly name: string };
};

export type SearchAdministrableScalesQueryVariables = Exact<{
  search: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type SearchAdministrableScalesQuery = {
  readonly __typename: "Query";
  readonly assessmentAdministrableScales: {
    readonly __typename: "AdministrableScaleConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "AdministrableScale";
      readonly id: Ids.ScaleId;
      readonly name: string;
      readonly shortname: string | null;
      readonly gender: Gender | null;
      readonly minAge: number | null;
      readonly maxAge: number | null;
      readonly locale: ReadonlyArray<ScaleLocale> | null;
      readonly estimatedTime: number | null;
    }>;
  } | null;
};

export type SearchAllScalesQueryVariables = Exact<{
  search: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type SearchAllScalesQuery = {
  readonly __typename: "Query";
  readonly assessmentScales: {
    readonly __typename: "ScaleConnection";
    readonly nodes: ReadonlyArray<
      | {
          readonly __typename: "AdministrableScale";
          readonly gender: Gender | null;
          readonly minAge: number | null;
          readonly maxAge: number | null;
          readonly locale: ReadonlyArray<ScaleLocale> | null;
          readonly estimatedTime: number | null;
          readonly id: Ids.ScaleId;
          readonly name: string;
          readonly shortname: string | null;
        }
      | {
          readonly __typename: "Subscale";
          readonly id: Ids.ScaleId;
          readonly name: string;
          readonly shortname: string | null;
        }
    >;
  } | null;
};

export type TreatmentTrackSelectQueryVariables = Exact<{
  search: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  status: InputMaybe<TreatmentTrackStatus>;
}>;

export type TreatmentTrackSelectQuery = {
  readonly __typename: "Query";
  readonly careTreatmentTracks: {
    readonly __typename: "TreatmentTrackConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "TreatmentTrack";
      readonly id: Ids.TreatmentTrackId;
      readonly name: string;
    }>;
  } | null;
};

export type TreatmentTrackSelectSingleQueryVariables = Exact<{
  id: Scalars["TreatmentTrackId"]["input"];
}>;

export type TreatmentTrackSelectSingleQuery = {
  readonly __typename: "Query";
  readonly careTreatmentTrack: {
    readonly __typename: "TreatmentTrack";
    readonly id: Ids.TreatmentTrackId;
    readonly name: string;
  } | null;
};

export type UserSelectQueryVariables = Exact<{
  search: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  isProvider: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type UserSelectQuery = {
  readonly __typename: "Query";
  readonly users: {
    readonly __typename: "UserConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "User";
      readonly id: Ids.UserId;
      readonly name: string;
    }>;
  } | null;
};

export type EntitySummaryFragment = {
  readonly __typename: "Entity";
  readonly id: Ids.EntityId;
  readonly entityType: EntityType;
  readonly name: string;
  readonly shortname: string;
};

export type EntitySelectQueryVariables = Exact<{
  search: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  entityType: InputMaybe<ReadonlyArray<EntityType> | EntityType>;
}>;

export type EntitySelectQuery = {
  readonly __typename: "Query";
  readonly entities: {
    readonly __typename: "EntityConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Entity";
      readonly id: Ids.EntityId;
      readonly entityType: EntityType;
      readonly name: string;
      readonly shortname: string;
    }>;
  } | null;
};

export type EntitySelectSingleQueryVariables = Exact<{
  id: Scalars["EntityId"]["input"];
}>;

export type EntitySelectSingleQuery = {
  readonly __typename: "Query";
  readonly entity: {
    readonly __typename: "Entity";
    readonly id: Ids.EntityId;
    readonly entityType: EntityType;
    readonly name: string;
    readonly shortname: string;
  } | null;
};

export type EntitySelectWithExtrasQueryVariables = Exact<{
  search: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  entityType: InputMaybe<ReadonlyArray<EntityType> | EntityType>;
}>;

export type EntitySelectWithExtrasQuery = {
  readonly __typename: "Query";
  readonly all: {
    readonly __typename: "EntityConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Entity";
      readonly id: Ids.EntityId;
      readonly entityType: EntityType;
      readonly name: string;
      readonly shortname: string;
    }>;
  } | null;
  readonly groups: {
    readonly __typename: "EntityConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Entity";
      readonly id: Ids.EntityId;
      readonly entityType: EntityType;
      readonly name: string;
      readonly shortname: string;
    }>;
  } | null;
  readonly institutes: {
    readonly __typename: "EntityConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Entity";
      readonly id: Ids.EntityId;
      readonly entityType: EntityType;
      readonly name: string;
      readonly shortname: string;
    }>;
  } | null;
};

export type EntityTreeNodeSelectSingleQueryVariables = Exact<{
  node: EntityTreeNodeParams;
}>;

export type EntityTreeNodeSelectSingleQuery = {
  readonly __typename: "Query";
  readonly entityTreeNode: {
    readonly __typename: "EntityTreeNode";
    readonly path: string;
    readonly minAge: number | null;
    readonly maxAge: number | null;
    readonly entity: {
      readonly __typename: "Entity";
      readonly id: Ids.EntityId;
      readonly name: string;
      readonly shortname: string;
      readonly entityType: EntityType;
    };
    readonly segments: ReadonlyArray<{
      readonly __typename: "EntityTreeSegment";
      readonly id: Ids.EntityId;
      readonly path: string;
      readonly entityType: EntityType;
      readonly name: string;
      readonly shortname: string;
      readonly rootPath: string;
    }>;
  } | null;
};

export type FlagFetchQueryVariables = Exact<{
  ids: InputMaybe<ReadonlyArray<Scalars["FlagId"]["input"]> | Scalars["FlagId"]["input"]>;
}>;

export type FlagFetchQuery = {
  readonly __typename: "Query";
  readonly flags: {
    readonly __typename: "FlagConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Flag";
      readonly id: Ids.FlagId;
      readonly name: string;
      readonly icon: string;
    }>;
  } | null;
};

export type FlagSelectQueryVariables = Exact<{
  search: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type FlagSelectQuery = {
  readonly __typename: "Query";
  readonly flags: {
    readonly __typename: "FlagConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Flag";
      readonly id: Ids.FlagId;
      readonly name: string;
      readonly icon: string;
    }>;
  } | null;
};

export type InstituteSelectQueryVariables = Exact<{
  search: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  instituteType: InputMaybe<ReadonlyArray<InstituteType> | InstituteType>;
}>;

export type InstituteSelectQuery = {
  readonly __typename: "Query";
  readonly institutes: {
    readonly __typename: "InstituteConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Institute";
      readonly id: Ids.InstituteId;
      readonly name: string;
    }>;
  } | null;
};

export type InstituteSelectSingleQueryVariables = Exact<{
  id: Scalars["InstituteId"]["input"];
}>;

export type InstituteSelectSingleQuery = {
  readonly __typename: "Query";
  readonly institute: {
    readonly __typename: "Institute";
    readonly id: Ids.InstituteId;
    readonly name: string;
  } | null;
};

export type OrganizationSelectQueryVariables = Exact<{
  search: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type OrganizationSelectQuery = {
  readonly __typename: "Query";
  readonly organizations: {
    readonly __typename: "OrganizationConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Organization";
      readonly id: Ids.OrganizationId;
      readonly name: string;
    }>;
  } | null;
};

export type OrganizationTreeQueryVariables = Exact<{ [key: string]: never }>;

export type OrganizationTreeQuery = {
  readonly __typename: "Query";
  readonly organizations: {
    readonly __typename: "OrganizationConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Organization";
      readonly id: Ids.OrganizationId;
      readonly name: string;
      readonly shortname: string;
      readonly parentId: Ids.OrganizationId | null;
      readonly measurementAllowed: boolean;
      readonly inTrialPeriod: boolean;
      readonly timezone: string | null;
    }>;
  } | null;
};

export type PatientSelectQueryVariables = Exact<{
  search: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type PatientSelectQuery = {
  readonly __typename: "Query";
  readonly patients: {
    readonly __typename: "PatientConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Patient";
      readonly id: Ids.PatientId;
      readonly name: string;
    }>;
  } | null;
};

export type ProviderSelectQueryVariables = Exact<{
  search: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  defaultRelationship: InputMaybe<
    ReadonlyArray<CareEpisodeProviderRelationship> | CareEpisodeProviderRelationship
  >;
}>;

export type ProviderSelectQuery = {
  readonly __typename: "Query";
  readonly providers: {
    readonly __typename: "ProviderConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "Provider";
      readonly id: Ids.ProviderId;
      readonly name: string;
    }>;
  } | null;
};

export type ProviderSelectSingleQueryVariables = Exact<{
  id: Scalars["ProviderId"]["input"];
}>;

export type ProviderSelectSingleQuery = {
  readonly __typename: "Query";
  readonly provider: {
    readonly __typename: "Provider";
    readonly id: Ids.ProviderId;
    readonly name: string;
  } | null;
};

export type ScaleScorerSelectQueryVariables = Exact<{
  search: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  domain: InputMaybe<ReadonlyArray<ScaleScorerDomain> | ScaleScorerDomain>;
}>;

export type ScaleScorerSelectQuery = {
  readonly __typename: "Query";
  readonly assessmentScaleScorers: {
    readonly __typename: "ScaleScorerConnection";
    readonly nodes: ReadonlyArray<
      | {
          readonly __typename: "CategoricalScaleScorer";
          readonly id: Ids.ScaleScorerId;
          readonly name: string;
          readonly domain: ScaleScorerDomain;
          readonly scale:
            | {
                readonly __typename: "AdministrableScale";
                readonly name: string;
                readonly shortname: string | null;
              }
            | { readonly __typename: "Subscale"; readonly name: string; readonly shortname: string | null };
        }
      | {
          readonly __typename: "NumericalScaleScorer";
          readonly id: Ids.ScaleScorerId;
          readonly name: string;
          readonly domain: ScaleScorerDomain;
          readonly scale:
            | {
                readonly __typename: "AdministrableScale";
                readonly name: string;
                readonly shortname: string | null;
              }
            | { readonly __typename: "Subscale"; readonly name: string; readonly shortname: string | null };
        }
      | {
          readonly __typename: "UnscoredScaleScorer";
          readonly id: Ids.ScaleScorerId;
          readonly name: string;
          readonly domain: ScaleScorerDomain;
          readonly scale:
            | {
                readonly __typename: "AdministrableScale";
                readonly name: string;
                readonly shortname: string | null;
              }
            | { readonly __typename: "Subscale"; readonly name: string; readonly shortname: string | null };
        }
    >;
  } | null;
};

export type ScaleScorerSelectSingleQueryVariables = Exact<{
  id: Scalars["ScaleScorerId"]["input"];
}>;

export type ScaleScorerSelectSingleQuery = {
  readonly __typename: "Query";
  readonly assessmentScaleScorer:
    | {
        readonly __typename: "CategoricalScaleScorer";
        readonly id: Ids.ScaleScorerId;
        readonly name: string;
        readonly domain: ScaleScorerDomain;
        readonly scale:
          | {
              readonly __typename: "AdministrableScale";
              readonly name: string;
              readonly shortname: string | null;
            }
          | { readonly __typename: "Subscale"; readonly name: string; readonly shortname: string | null };
      }
    | {
        readonly __typename: "NumericalScaleScorer";
        readonly id: Ids.ScaleScorerId;
        readonly name: string;
        readonly domain: ScaleScorerDomain;
        readonly scale:
          | {
              readonly __typename: "AdministrableScale";
              readonly name: string;
              readonly shortname: string | null;
            }
          | { readonly __typename: "Subscale"; readonly name: string; readonly shortname: string | null };
      }
    | {
        readonly __typename: "UnscoredScaleScorer";
        readonly id: Ids.ScaleScorerId;
        readonly name: string;
        readonly domain: ScaleScorerDomain;
        readonly scale:
          | {
              readonly __typename: "AdministrableScale";
              readonly name: string;
              readonly shortname: string | null;
            }
          | { readonly __typename: "Subscale"; readonly name: string; readonly shortname: string | null };
      }
    | null;
};

export type ScaleScorerWithUsageSelectQueryVariables = Exact<{
  search: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  domain: InputMaybe<ReadonlyArray<ScaleScorerDomain> | ScaleScorerDomain>;
  entityTreeNode: InputMaybe<EntityTreeNodeParams>;
  dateRange: InputMaybe<DateRangeInput>;
}>;

export type ScaleScorerWithUsageSelectQuery = {
  readonly __typename: "Query";
  readonly assessmentScaleScorersWithUsage: {
    readonly __typename: "ScaleScorerWithUsageConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "ScaleScorerWithUsage";
      readonly sessionCount: number;
      readonly scale:
        | {
            readonly __typename: "AdministrableScale";
            readonly name: string;
            readonly shortname: string | null;
          }
        | { readonly __typename: "Subscale"; readonly name: string; readonly shortname: string | null };
      readonly scaleScorer:
        | { readonly __typename: "CategoricalScaleScorer"; readonly id: Ids.ScaleScorerId }
        | { readonly __typename: "NumericalScaleScorer"; readonly id: Ids.ScaleScorerId }
        | { readonly __typename: "UnscoredScaleScorer"; readonly id: Ids.ScaleScorerId };
    }>;
  } | null;
};

export type TreatmentServiceTreeQueryVariables = Exact<{ [key: string]: never }>;

export type TreatmentServiceTreeQuery = {
  readonly __typename: "Query";
  readonly treatmentServices: {
    readonly __typename: "TreatmentServiceConnection";
    readonly nodes: ReadonlyArray<{
      readonly __typename: "TreatmentService";
      readonly id: Ids.TreatmentServiceId;
      readonly name: string;
      readonly parentId: Ids.TreatmentServiceId | null;
    }>;
  } | null;
};

export type IdentifierDetailsFragment = {
  readonly __typename: "Identifier";
  readonly assigner: string;
  readonly value: string;
};

export type ProviderNameQueryVariables = Exact<{
  id: Scalars["ProviderId"]["input"];
}>;

export type ProviderNameQuery = {
  readonly __typename: "Query";
  readonly provider: { readonly __typename: "Provider"; readonly name: string } | null;
};

export type OrganizationSummaryFragment = {
  readonly __typename: "Organization";
  readonly id: Ids.OrganizationId;
  readonly name: string;
};

type ParticipantSummaryFragment_Patient_Fragment = {
  readonly __typename: "Patient";
  readonly participantId: Ids.ParticipantId;
  readonly relationship: ParticipantRelationship;
  readonly user: {
    readonly __typename: "User";
    readonly id: Ids.UserId;
    readonly name: string;
    readonly firstName: string | null;
  };
};

type ParticipantSummaryFragment_Provider_Fragment = {
  readonly __typename: "Provider";
  readonly participantId: Ids.ParticipantId;
  readonly relationship: ParticipantRelationship;
  readonly user: {
    readonly __typename: "User";
    readonly id: Ids.UserId;
    readonly name: string;
    readonly firstName: string | null;
  };
};

type ParticipantSummaryFragment_RelatedPerson_Fragment = {
  readonly __typename: "RelatedPerson";
  readonly participantId: Ids.ParticipantId;
  readonly relationship: ParticipantRelationship;
  readonly user: {
    readonly __typename: "User";
    readonly id: Ids.UserId;
    readonly name: string;
    readonly firstName: string | null;
  };
};

export type ParticipantSummaryFragmentFragment =
  | ParticipantSummaryFragment_Patient_Fragment
  | ParticipantSummaryFragment_Provider_Fragment
  | ParticipantSummaryFragment_RelatedPerson_Fragment;

export type PatientSessionSummaryFragmentFragment = {
  readonly __typename: "PatientSession";
  readonly id: Ids.PatientSessionId;
  readonly sessionNumber: number | null;
  readonly targetDate: Date;
  readonly assessmentReport: { readonly __typename: "Report"; readonly id: Ids.ReportId } | null;
};

export type ProviderSummaryFragmentFragment = {
  readonly __typename: "Provider";
  readonly id: Ids.ProviderId;
  readonly title: string | null;
  readonly user: {
    readonly __typename: "User";
    readonly id: Ids.UserId;
    readonly name: string;
    readonly firstName: string | null;
  };
};

export type AnswerDetailWithUserFragmentFragment = {
  readonly __typename: "Answer";
  readonly id: Ids.AnswerId;
  readonly value: string | null;
  readonly questionId: Ids.QuestionId;
  readonly normalizedScore: NormalizedScore | null;
  readonly itemCoding: ItemCoding | null;
  readonly endorsed: boolean | null;
  readonly option: {
    readonly __typename: "QuestionOption";
    readonly title: string;
    readonly value: string;
    readonly reportText: string | null;
  } | null;
  readonly question: {
    readonly __typename: "Question";
    readonly id: Ids.QuestionId;
    readonly title: string;
    readonly reportText: string;
    readonly questionType: QuestionType;
  };
  readonly user: {
    readonly __typename: "User";
    readonly id: Ids.UserId;
    readonly name: string;
    readonly firstName: string | null;
  };
};

export type AnswerSummaryWithoutUserFragmentFragment = {
  readonly __typename: "Answer";
  readonly id: Ids.AnswerId;
  readonly value: string | null;
  readonly questionId: Ids.QuestionId;
  readonly normalizedScore: NormalizedScore | null;
  readonly endorsed: boolean | null;
  readonly itemCoding: ItemCoding | null;
  readonly option: {
    readonly __typename: "QuestionOption";
    readonly title: string;
    readonly value: string;
    readonly reportText: string | null;
  } | null;
};

export type ClinicalAlertDetailsFragmentFragment = {
  readonly __typename: "CareEpisodeClinicalAlert";
  readonly id: string;
  readonly effectiveDate: Date;
  readonly targetDate: Date;
  readonly status: ClinicalAlertValue;
  readonly answers: ReadonlyArray<{
    readonly __typename: "Answer";
    readonly id: Ids.AnswerId;
    readonly value: string | null;
    readonly questionId: Ids.QuestionId;
    readonly normalizedScore: NormalizedScore | null;
    readonly itemCoding: ItemCoding | null;
    readonly endorsed: boolean | null;
    readonly option: {
      readonly __typename: "QuestionOption";
      readonly title: string;
      readonly value: string;
      readonly reportText: string | null;
    } | null;
    readonly question: {
      readonly __typename: "Question";
      readonly id: Ids.QuestionId;
      readonly title: string;
      readonly reportText: string;
      readonly questionType: QuestionType;
    };
    readonly user: {
      readonly __typename: "User";
      readonly id: Ids.UserId;
      readonly name: string;
      readonly firstName: string | null;
    };
  }>;
  readonly invitations: ReadonlyArray<{
    readonly __typename: "Invitation";
    readonly id: Ids.InvitationId;
    readonly dateCompleted: Date | null;
    readonly dateBegun: Date | null;
    readonly user: { readonly __typename: "User"; readonly id: Ids.UserId };
  }>;
};

export type ClinicalAlertHistoryFragmentFragment = {
  readonly __typename: "CareEpisodeClinicalAlertHistory";
  readonly id: Ids.CareEpisodeScaleId;
  readonly alertType: ClinicalAlertType;
  readonly scale: {
    readonly __typename: "AdministrableScale";
    readonly mnemonic: string;
    readonly scaleGroup: { readonly __typename: "ScaleGroup"; readonly id: Ids.ScaleGroupId } | null;
  } | null;
  readonly latest: {
    readonly __typename: "CareEpisodeClinicalAlert";
    readonly id: string;
    readonly effectiveDate: Date;
    readonly targetDate: Date;
    readonly status: ClinicalAlertValue;
    readonly answers: ReadonlyArray<{
      readonly __typename: "Answer";
      readonly id: Ids.AnswerId;
      readonly value: string | null;
      readonly questionId: Ids.QuestionId;
      readonly normalizedScore: NormalizedScore | null;
      readonly itemCoding: ItemCoding | null;
      readonly endorsed: boolean | null;
      readonly option: {
        readonly __typename: "QuestionOption";
        readonly title: string;
        readonly value: string;
        readonly reportText: string | null;
      } | null;
      readonly question: {
        readonly __typename: "Question";
        readonly id: Ids.QuestionId;
        readonly title: string;
        readonly reportText: string;
        readonly questionType: QuestionType;
      };
      readonly user: {
        readonly __typename: "User";
        readonly id: Ids.UserId;
        readonly name: string;
        readonly firstName: string | null;
      };
    }>;
    readonly invitations: ReadonlyArray<{
      readonly __typename: "Invitation";
      readonly id: Ids.InvitationId;
      readonly dateCompleted: Date | null;
      readonly dateBegun: Date | null;
      readonly user: { readonly __typename: "User"; readonly id: Ids.UserId };
    }>;
  } | null;
  readonly history: ReadonlyArray<{
    readonly __typename: "CareEpisodeClinicalAlert";
    readonly id: string;
    readonly effectiveDate: Date;
    readonly targetDate: Date;
    readonly status: ClinicalAlertValue;
    readonly answers: ReadonlyArray<{
      readonly __typename: "Answer";
      readonly id: Ids.AnswerId;
      readonly value: string | null;
      readonly questionId: Ids.QuestionId;
      readonly normalizedScore: NormalizedScore | null;
      readonly itemCoding: ItemCoding | null;
      readonly endorsed: boolean | null;
      readonly option: {
        readonly __typename: "QuestionOption";
        readonly title: string;
        readonly value: string;
        readonly reportText: string | null;
      } | null;
      readonly question: {
        readonly __typename: "Question";
        readonly id: Ids.QuestionId;
        readonly title: string;
        readonly reportText: string;
        readonly questionType: QuestionType;
      };
      readonly user: {
        readonly __typename: "User";
        readonly id: Ids.UserId;
        readonly name: string;
        readonly firstName: string | null;
      };
    }>;
    readonly invitations: ReadonlyArray<{
      readonly __typename: "Invitation";
      readonly id: Ids.InvitationId;
      readonly dateCompleted: Date | null;
      readonly dateBegun: Date | null;
      readonly user: { readonly __typename: "User"; readonly id: Ids.UserId };
    }>;
  }>;
};

export type DetailedScaleCompletionFragmentFragment = {
  readonly __typename: "CareEpisodeComputedValue";
  readonly id: Ids.CareEpisodeComputedValueId;
  readonly value: number | null;
  readonly thresholdClass: ScaleThresholdClass | null;
  readonly thresholdMnemonic: string | null;
  readonly effectiveDate: Date | null;
  readonly targetDate: Date;
  readonly date: Date;
  readonly validity: ComputedValueValidity;
  readonly acuteChangeCategory: AcuteChangeCategory;
  readonly clinicalCategory: ClinicalCategory;
  readonly trend: Trend;
  readonly severityCategory: SeverityCategory;
  readonly scoringMechanism: CalculationMechanism;
  readonly responsesAvailable: ResponsesAvailable;
  readonly answers: ReadonlyArray<{
    readonly __typename: "Answer";
    readonly id: Ids.AnswerId;
    readonly value: string | null;
    readonly questionId: Ids.QuestionId;
    readonly normalizedScore: NormalizedScore | null;
    readonly endorsed: boolean | null;
    readonly itemCoding: ItemCoding | null;
    readonly option: {
      readonly __typename: "QuestionOption";
      readonly title: string;
      readonly value: string;
      readonly reportText: string | null;
    } | null;
  }>;
  readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
  readonly scorer:
    | { readonly __typename: "CategoricalScaleScorer" }
    | { readonly __typename: "NumericalScaleScorer" }
    | { readonly __typename: "UnscoredScaleScorer" };
  readonly mostRelevantInvitation: {
    readonly __typename: "Invitation";
    readonly id: Ids.InvitationId;
    readonly dateCompleted: Date | null;
    readonly dateBegun: Date | null;
  } | null;
};

export type DetailedScaleScoreFragment = {
  readonly __typename: "CareEpisodeComputedValue";
  readonly id: Ids.CareEpisodeComputedValueId;
  readonly value: number | null;
  readonly thresholdClass: ScaleThresholdClass | null;
  readonly thresholdMnemonic: string | null;
  readonly effectiveDate: Date | null;
  readonly targetDate: Date;
  readonly date: Date;
  readonly validity: ComputedValueValidity;
  readonly acuteChangeCategory: AcuteChangeCategory;
  readonly clinicalCategory: ClinicalCategory;
  readonly trend: Trend;
  readonly severityCategory: SeverityCategory;
  readonly scoringMechanism: CalculationMechanism;
  readonly responsesAvailable: ResponsesAvailable;
  readonly answers: ReadonlyArray<{
    readonly __typename: "Answer";
    readonly id: Ids.AnswerId;
    readonly value: string | null;
    readonly questionId: Ids.QuestionId;
    readonly normalizedScore: NormalizedScore | null;
    readonly endorsed: boolean | null;
    readonly itemCoding: ItemCoding | null;
    readonly option: {
      readonly __typename: "QuestionOption";
      readonly title: string;
      readonly value: string;
      readonly reportText: string | null;
    } | null;
  }>;
  readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
  readonly scorer:
    | { readonly __typename: "CategoricalScaleScorer" }
    | { readonly __typename: "NumericalScaleScorer" }
    | { readonly __typename: "UnscoredScaleScorer" };
  readonly mostRelevantInvitation: {
    readonly __typename: "Invitation";
    readonly id: Ids.InvitationId;
    readonly dateCompleted: Date | null;
    readonly dateBegun: Date | null;
  } | null;
};

export type GoalFragmentFragment = {
  readonly __typename: "Goal";
  readonly id: Ids.GoalId;
  readonly goalType: GoalType;
  readonly status: GoalStatus;
  readonly startDate: Date;
  readonly endDate: Date | null;
  readonly isBeneficial: boolean;
  readonly patientText: string;
  readonly user: {
    readonly __typename: "User";
    readonly id: Ids.UserId;
    readonly name: string;
    readonly firstName: string | null;
  };
  readonly thresholdData: ReadonlyArray<{
    readonly __typename: "ScaleThreshold";
    readonly mnemonic: string;
    readonly thresholdClass: ScaleThresholdClass;
    readonly maxValue: number;
    readonly minValue: number;
  }>;
  readonly goalAnswers: ReadonlyArray<{
    readonly __typename: "GoalAnswer";
    readonly id: Ids.GoalAnswerId;
    readonly answerDate: Date;
    readonly targetDate: Date;
    readonly effectiveDate: Date;
    readonly value: number | null;
  }>;
};

export type QuestionSummaryFragment = {
  readonly __typename: "Question";
  readonly id: Ids.QuestionId;
  readonly title: string;
  readonly reportText: string;
  readonly questionType: QuestionType;
};

export type ScaleCompletionFragmentFragment = {
  readonly __typename: "CareEpisodeComputedValue";
  readonly id: Ids.CareEpisodeComputedValueId;
  readonly value: number | null;
  readonly effectiveDate: Date | null;
  readonly targetDate: Date;
  readonly validity: ComputedValueValidity;
  readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
};

type ScaleSummary_AdministrableScale_Fragment = {
  readonly __typename: "AdministrableScale";
  readonly id: Ids.ScaleId;
  readonly mnemonic: string;
  readonly parentId: Ids.ScaleId | null;
  readonly name: string;
  readonly shortname: string | null;
  readonly nanoname: string | null;
  readonly friendlyName: string | null;
  readonly measureType: ScaleMeasureType;
  readonly role: ScaleRole | null;
  readonly reportShortDescription: string | null;
};

type ScaleSummary_Subscale_Fragment = {
  readonly __typename: "Subscale";
  readonly id: Ids.ScaleId;
  readonly mnemonic: string;
  readonly parentId: Ids.ScaleId | null;
  readonly name: string;
  readonly shortname: string | null;
  readonly nanoname: string | null;
  readonly friendlyName: string | null;
  readonly measureType: ScaleMeasureType;
  readonly role: ScaleRole | null;
  readonly reportShortDescription: string | null;
};

export type ScaleSummaryFragment = ScaleSummary_AdministrableScale_Fragment | ScaleSummary_Subscale_Fragment;

type ScaleWithQuestions_AdministrableScale_Fragment = {
  readonly __typename: "AdministrableScale";
  readonly id: Ids.ScaleId;
  readonly mnemonic: string;
  readonly parentId: Ids.ScaleId | null;
  readonly name: string;
  readonly shortname: string | null;
  readonly nanoname: string | null;
  readonly friendlyName: string | null;
  readonly measureType: ScaleMeasureType;
  readonly role: ScaleRole | null;
  readonly reportShortDescription: string | null;
  readonly questions: ReadonlyArray<{
    readonly __typename: "Question";
    readonly id: Ids.QuestionId;
    readonly title: string;
    readonly reportText: string;
    readonly questionType: QuestionType;
  }>;
};

type ScaleWithQuestions_Subscale_Fragment = {
  readonly __typename: "Subscale";
  readonly id: Ids.ScaleId;
  readonly mnemonic: string;
  readonly parentId: Ids.ScaleId | null;
  readonly name: string;
  readonly shortname: string | null;
  readonly nanoname: string | null;
  readonly friendlyName: string | null;
  readonly measureType: ScaleMeasureType;
  readonly role: ScaleRole | null;
  readonly reportShortDescription: string | null;
  readonly questions: ReadonlyArray<{
    readonly __typename: "Question";
    readonly id: Ids.QuestionId;
    readonly title: string;
    readonly reportText: string;
    readonly questionType: QuestionType;
  }>;
};

export type ScaleWithQuestionsFragment =
  | ScaleWithQuestions_AdministrableScale_Fragment
  | ScaleWithQuestions_Subscale_Fragment;

export type NumericalScaleScorerSummaryFragment = {
  readonly __typename: "NumericalScaleScorer";
  readonly domain: ScaleScorerDomain;
  readonly minValue: number;
  readonly maxValue: number;
  readonly minInterpretation: string | null;
  readonly maxInterpretation: string | null;
  readonly minInterpretationShort: string | null;
  readonly maxInterpretationShort: string | null;
  readonly thresholdData: ReadonlyArray<{
    readonly __typename: "ScaleThreshold";
    readonly mnemonic: string;
    readonly thresholdClass: ScaleThresholdClass;
    readonly minValue: number;
    readonly maxValue: number;
  }> | null;
};

export type CategoricalScaleScorerSummaryFragment = {
  readonly __typename: "CategoricalScaleScorer";
  readonly domain: ScaleScorerDomain;
};

export type UnscoredScaleScorerSummaryFragment = {
  readonly __typename: "UnscoredScaleScorer";
  readonly domain: ScaleScorerDomain;
};

type ScaleScorerSummary_CategoricalScaleScorer_Fragment = {
  readonly __typename: "CategoricalScaleScorer";
  readonly domain: ScaleScorerDomain;
};

type ScaleScorerSummary_NumericalScaleScorer_Fragment = {
  readonly __typename: "NumericalScaleScorer";
  readonly domain: ScaleScorerDomain;
  readonly minValue: number;
  readonly maxValue: number;
  readonly minInterpretation: string | null;
  readonly maxInterpretation: string | null;
  readonly minInterpretationShort: string | null;
  readonly maxInterpretationShort: string | null;
  readonly thresholdData: ReadonlyArray<{
    readonly __typename: "ScaleThreshold";
    readonly mnemonic: string;
    readonly thresholdClass: ScaleThresholdClass;
    readonly minValue: number;
    readonly maxValue: number;
  }> | null;
};

type ScaleScorerSummary_UnscoredScaleScorer_Fragment = {
  readonly __typename: "UnscoredScaleScorer";
  readonly domain: ScaleScorerDomain;
};

export type ScaleScorerSummaryFragment =
  | ScaleScorerSummary_CategoricalScaleScorer_Fragment
  | ScaleScorerSummary_NumericalScaleScorer_Fragment
  | ScaleScorerSummary_UnscoredScaleScorer_Fragment;

export type ScaleGroupSummaryFragmentFragment = {
  readonly __typename: "ScaleGroup";
  readonly id: Ids.ScaleGroupId;
  readonly name: string;
  readonly description: string | null;
  readonly shortname: string;
  readonly displayMode: ScaleGroupDisplayDataType;
};

export type CareEpisodeComputedValueDetailsFragment = {
  readonly __typename: "CareEpisodeComputedValue";
  readonly id: Ids.CareEpisodeComputedValueId;
  readonly value: number | null;
  readonly thresholdClass: ScaleThresholdClass | null;
  readonly thresholdMnemonic: string | null;
  readonly effectiveDate: Date | null;
  readonly targetDate: Date;
  readonly date: Date;
  readonly validity: ComputedValueValidity;
  readonly acuteChangeCategory: AcuteChangeCategory;
  readonly clinicalCategory: ClinicalCategory;
  readonly trend: Trend;
  readonly severityCategory: SeverityCategory;
  readonly scoringMechanism: CalculationMechanism;
  readonly responsesAvailable: ResponsesAvailable;
  readonly user: { readonly __typename: "User"; readonly id: Ids.UserId } | null;
  readonly scorer:
    | { readonly __typename: "CategoricalScaleScorer" }
    | { readonly __typename: "NumericalScaleScorer" }
    | { readonly __typename: "UnscoredScaleScorer" };
  readonly mostRelevantInvitation: {
    readonly __typename: "Invitation";
    readonly id: Ids.InvitationId;
    readonly dateCompleted: Date | null;
    readonly dateBegun: Date | null;
  } | null;
};

export type ThresholdFragment = {
  readonly __typename: "ScaleThreshold";
  readonly mnemonic: string;
  readonly minValue: number;
  readonly maxValue: number;
  readonly thresholdClass: ScaleThresholdClass;
};

export type UserSummaryFragmentFragment = {
  readonly __typename: "User";
  readonly id: Ids.UserId;
  readonly name: string;
  readonly firstName: string | null;
};

export const RuleResultDetailsFragmentDoc = gql`
  fragment RuleResultDetails on EnrollmentMonthBillingRuleResult {
    id
    status
    dateOfService
    expectedRate {
      cents
      currency
    }
    minutesDiffVsCurrentWinner
    minutesDiffVsNextPriority
    minutesShort
    priority
    rateDiffVsCurrentWinner {
      cents
      currency
    }
    rateDiffVsNextPriority {
      cents
      currency
    }
    ratePowerVsCurrentWinner
    ratePowerVsNextPriority
    ratePowerStrength
    rvuDiffVsCurrentWinner
    rvuDiffVsNextPriority
    rvuPowerVsCurrentWinner
    rvuPowerVsNextPriority
    rvus
    rvuPowerStrength
    billableMinutesDiffVsCurrentWinner
    billableMinutesDiffVsNextPriority
    billableMinutesPowerVsCurrentWinner
    billableMinutesPowerVsNextPriority
    billableMinutes
    billableMinutesPowerStrength
    triggerDate
    valueUnitDiffVsCurrentWinner
    valueUnitDiffVsNextPriority
    valueUnitPowerVsCurrentWinner
    valueUnitPowerVsNextPriority
    valueUnitPowerStrength
    valueUnits
    failReasonCodes
    disqualifyReasonCodes
    rule {
      id
      ruleCodes {
        id
        code {
          id
          code
        }
        units
      }
      anyAssessmentCompleted
      minimumMinutes
      billableMinutes
      mustBeInitialEncounter
      mustBeSubsequentEncounter
    }
  }
`;
export const MeasurementPlanScaleDetailsFragmentDoc = gql`
  fragment MeasurementPlanScaleDetails on MeasurementPlanScale {
    id
    scale {
      id
      name
      shortname
    }
    format
    phase
    alternationGroup
    discontinueSession
    onsetSession
    onsetTime
    repeatSessions
    maxRepeatInterval
    discontinueSession
    discontinueTime
    measureOnsetFrom
  }
`;
export const PatientDetailsFragmentFragmentDoc = gql`
  fragment PatientDetailsFragment on Patient {
    id
    name
    nameLastFirst
    email
    phoneNumber
    mrn
    careManager {
      id
      name
    }
    cocmPrimaryCareProvider {
      id
      name
    }
    activeCollaborativeCareCareEpisode {
      id
      collaborativeCareEnrollment {
        id
        enrolledAt
        consentDate
        panel {
          id
          name
        }
        primaryCareProviderTeamMember {
          id
          provider {
            id
            name
          }
        }
        careManagerTeamMember {
          id
          provider {
            id
            name
          }
        }
      }
    }
    collaborativeCareEnrollments {
      nodes {
        id
        enrolledAt
        dischargedAt
        consentDate
        dischargeReason
        careEpisode {
          collaborativeCareCareEpisodeTreatmentTargets {
            treatmentTarget {
              name
            }
          }
        }
        panel {
          id
          name
        }
        primaryCareProviderTeamMember {
          id
          provider {
            id
            user {
              id
              name
            }
          }
        }
        careManagerTeamMember {
          id
          provider {
            id
            user {
              id
              name
            }
          }
        }
      }
    }
    note
    dob
  }
`;
export const CurrentInstituteDetailsFragmentDoc = gql`
  fragment CurrentInstituteDetails on CurrentInstitute {
    id
    shortcode
    name
    supportsInstituteGroups
    configuration {
      allowBulkExport
      integrationEnabled
      enableSeveritySummary
      integrationAppointmentDeduplicationWindowMinutes
      integrationOnlyMeasurePrimaryProvider
    }
    featureSet {
      enableBilling
      enableCareUnits
      enableConditions
      enableDemoQuickAdd
      enableExperiments
      enableMeasurementDimensions
      enableMeasurementPlans
      enablePayors
      enableQualityIssues
      enableTreatmentServices
      enableDashboardSchedule
      enableAthenaWriteback
      enableDecisionSupport
      enableCollaborativeCare
      enableImplementationTargets
      assessmentIncompleteFindings
      monthlyAssessmentMissingFindings
      enableHealthieChildPatientSwap
      enableLocations
      enableOptumMetrics
      enableCocmEncounters
      enableMbcRedesign
    }
    enabled
    activeDataSourceResources
  }
`;
export const ScaleSummaryFragmentDoc = gql`
  fragment ScaleSummary on Scale {
    id
    mnemonic
    parentId
    name
    shortname
    nanoname
    friendlyName
    measureType
    role
    reportShortDescription
  }
`;
export const ScaleWithQuestionsFragmentDoc = gql`
  fragment ScaleWithQuestions on Scale {
    ...ScaleSummary
    questions {
      id
      title
      reportText
      questionType
    }
  }
  ${ScaleSummaryFragmentDoc}
`;
export const NumericalScaleScorerSummaryFragmentDoc = gql`
  fragment NumericalScaleScorerSummary on NumericalScaleScorer {
    domain
    minValue
    maxValue
    minInterpretation
    maxInterpretation
    minInterpretationShort
    maxInterpretationShort
    thresholdData {
      mnemonic
      thresholdClass
      minValue
      maxValue
    }
  }
`;
export const CategoricalScaleScorerSummaryFragmentDoc = gql`
  fragment CategoricalScaleScorerSummary on CategoricalScaleScorer {
    domain
  }
`;
export const UnscoredScaleScorerSummaryFragmentDoc = gql`
  fragment UnscoredScaleScorerSummary on UnscoredScaleScorer {
    domain
  }
`;
export const ScaleScorerSummaryFragmentDoc = gql`
  fragment ScaleScorerSummary on ScaleScorer {
    domain
    ... on NumericalScaleScorer {
      ...NumericalScaleScorerSummary
    }
    ... on CategoricalScaleScorer {
      ...CategoricalScaleScorerSummary
    }
    ... on UnscoredScaleScorer {
      ...UnscoredScaleScorerSummary
    }
  }
  ${NumericalScaleScorerSummaryFragmentDoc}
  ${CategoricalScaleScorerSummaryFragmentDoc}
  ${UnscoredScaleScorerSummaryFragmentDoc}
`;
export const CareEpisodeComputedValueDetailsFragmentDoc = gql`
  fragment CareEpisodeComputedValueDetails on CareEpisodeComputedValue {
    id
    value
    thresholdClass
    thresholdMnemonic
    effectiveDate
    targetDate
    date
    validity
    acuteChangeCategory
    clinicalCategory
    trend
    severityCategory
    user {
      id
    }
    scorer {
      __typename
    }
    mostRelevantInvitation {
      id
      dateCompleted
      dateBegun
    }
    scoringMechanism
    responsesAvailable
  }
`;
export const AnswerSummaryWithoutUserFragmentFragmentDoc = gql`
  fragment AnswerSummaryWithoutUserFragment on Answer {
    id
    value
    option {
      title
      value
      reportText
    }
    questionId
    normalizedScore
    endorsed
    itemCoding
  }
`;
export const DetailedScaleCompletionFragmentFragmentDoc = gql`
  fragment DetailedScaleCompletionFragment on CareEpisodeComputedValue {
    ...CareEpisodeComputedValueDetails
    answers {
      ...AnswerSummaryWithoutUserFragment
    }
  }
  ${CareEpisodeComputedValueDetailsFragmentDoc}
  ${AnswerSummaryWithoutUserFragmentFragmentDoc}
`;
export const DetailedScaleScoreFragmentDoc = gql`
  fragment DetailedScaleScore on CareEpisodeComputedValue {
    ...DetailedScaleCompletionFragment
  }
  ${DetailedScaleCompletionFragmentFragmentDoc}
`;
export const CareEpisodeScaleResultSummaryFragmentDoc = gql`
  fragment CareEpisodeScaleResultSummary on CareEpisodeScaleResult {
    scale {
      ...ScaleWithQuestions
    }
    byUser {
      user {
        id
        name
      }
      byScorer {
        scorer {
          ...ScaleScorerSummary
        }
        latest {
          ...DetailedScaleScore
        }
        history {
          ...CareEpisodeComputedValueDetails
        }
      }
    }
  }
  ${ScaleWithQuestionsFragmentDoc}
  ${ScaleScorerSummaryFragmentDoc}
  ${DetailedScaleScoreFragmentDoc}
  ${CareEpisodeComputedValueDetailsFragmentDoc}
`;
export const ConstructScaleDetailsFragmentDoc = gql`
  fragment ConstructScaleDetails on CareEpisodeScaleResult {
    scale {
      id
      mnemonic
      parentId
      name
      shortname
      nanoname
      friendlyName
      measureType
      reportShortDescription
      role
      questions {
        id
        title
        reportText
        questionType
      }
    }
    byUser {
      user {
        id
        name
      }
      byScorer {
        scorer {
          domain
          ... on NumericalScaleScorer {
            minValue
            maxValue
            minInterpretation
            maxInterpretation
            minInterpretationShort
            maxInterpretationShort
            thresholdData {
              mnemonic
              thresholdClass
              minValue
              maxValue
            }
          }
        }
        latest {
          ...DetailedScaleScore
        }
        history {
          ...CareEpisodeComputedValueDetails
        }
      }
    }
  }
  ${DetailedScaleScoreFragmentDoc}
  ${CareEpisodeComputedValueDetailsFragmentDoc}
`;
export const TargetScaleDetailsFragmentDoc = gql`
  fragment TargetScaleDetails on CareEpisodeScaleResult {
    scale {
      id
      mnemonic
      parentId
      name
      shortname
      nanoname
      friendlyName
      measureType
      reportShortDescription
      role
      questions {
        id
        title
        reportText
        questionType
      }
    }
    byUser {
      user {
        id
        name
      }
      byScorer {
        scorer {
          domain
          ... on NumericalScaleScorer {
            minValue
            maxValue
            minInterpretation
            maxInterpretation
            minInterpretationShort
            maxInterpretationShort
            thresholdData {
              mnemonic
              thresholdClass
              minValue
              maxValue
            }
          }
        }
        latest {
          ...DetailedScaleScore
        }
        history {
          ...CareEpisodeComputedValueDetails
        }
      }
    }
  }
  ${DetailedScaleScoreFragmentDoc}
  ${CareEpisodeComputedValueDetailsFragmentDoc}
`;
export const ReportInvitationsFragmentFragmentDoc = gql`
  fragment ReportInvitationsFragment on CareEpisodeComputedValue {
    report {
      invitations {
        id
        user {
          id
        }
        reportScales {
          scale {
            id
          }
        }
      }
    }
  }
`;
export const QuestionSummaryFragmentDoc = gql`
  fragment QuestionSummary on Question {
    id
    title
    reportText
    questionType
  }
`;
export const UserSummaryFragmentFragmentDoc = gql`
  fragment UserSummaryFragment on User {
    id
    name
    firstName
  }
`;
export const AnswerDetailWithUserFragmentFragmentDoc = gql`
  fragment AnswerDetailWithUserFragment on Answer {
    id
    value
    option {
      title
      value
      reportText
    }
    questionId
    question {
      ...QuestionSummary
    }
    user {
      ...UserSummaryFragment
    }
    normalizedScore
    itemCoding
    endorsed
  }
  ${QuestionSummaryFragmentDoc}
  ${UserSummaryFragmentFragmentDoc}
`;
export const CareEpisodeComputedValueWithScaleAndAnswersFragmentDoc = gql`
  fragment CareEpisodeComputedValueWithScaleAndAnswers on CareEpisodeComputedValue {
    ...CareEpisodeComputedValueDetails
    ...ReportInvitationsFragment
    user {
      name
    }
    scale {
      ...ScaleWithQuestions
    }
    answers {
      ...AnswerDetailWithUserFragment
    }
    mostRelevantInvitation {
      id
      dateCompleted
      dateBegun
    }
  }
  ${CareEpisodeComputedValueDetailsFragmentDoc}
  ${ReportInvitationsFragmentFragmentDoc}
  ${ScaleWithQuestionsFragmentDoc}
  ${AnswerDetailWithUserFragmentFragmentDoc}
`;
export const CareEpisodeMeasurementAllowedDetailsFragmentDoc = gql`
  fragment CareEpisodeMeasurementAllowedDetails on CareEpisode {
    id
    measurementAllowed
    institute {
      id
      name
      measurementAllowed
    }
    providers {
      id
      name
      measurementAllowed
    }
    organization {
      id
      name
      measurementAllowed
    }
    patient {
      id
      name
      autoCreateSessions
    }
  }
`;
export const UserInternalOverviewFragmentDoc = gql`
  fragment UserInternalOverview on User {
    id
    name
    confirmedAt
    currentSignInAt
    email
    emailSource
    failedAttempts
    firstName
    firstNameSource
    isInternal
    language
    lastActivityAt
    lastName
    lastNameSource
    lastSignInAt
    lockedAt
    notificationPreference
    phoneNumber
    phoneNumberSource
    role
    signInCount
    ssoUsername
    suffix
    timezone
    title
    username
    createdAt
    updatedAt
  }
`;
export const InternalIntegrationsDataSourceRecordsFragmentDoc = gql`
  fragment InternalIntegrationsDataSourceRecords on DataSourceRecord {
    id
    remoteId
  }
`;
export const EntityTreeNodeDetailsFragmentDoc = gql`
  fragment EntityTreeNodeDetails on EntityTreeNode {
    path
    entity {
      id
      name
      shortname
      entityType
    }
    segments {
      id
      path
      entityType
      name
      shortname
      rootPath
    }
    minAge
    maxAge
  }
`;
export const TimeBasedMetricDetailsFragmentDoc = gql`
  fragment TimeBasedMetricDetails on TimeBasedMetric {
    scorerConfig {
      ... on MetricSingleScaleScorerConfiguration {
        scaleScorer {
          id
          scale {
            shortname
            name
            nanoname
          }
        }
      }
      ... on MetricMultiScaleScorerConfiguration {
        scaleScorers {
          id
          scale {
            shortname
            name
            nanoname
          }
        }
      }
    }
    metricInclusionCriteria {
      id
      criterionType
      operator
      stringValue
      numberValue
      integerValue
      severityValues
      trendValue
      stringArrayValue
      integerArrayValue
      excludeResults
    }
    timeBasedAggregationType
    timePeriodType
    timePeriodValue
    metricSuccessCriteria {
      id
      criterionType
      operator
      numberValue
      stringValue
      severityValues
    }
  }
`;
export const TriggerBasedMetricDetailsFragmentDoc = gql`
  fragment TriggerBasedMetricDetails on TriggerBasedMetric {
    scorerConfig {
      ... on MetricSingleScaleScorerConfiguration {
        scaleScorer {
          id
          scale {
            shortname
            name
          }
        }
      }
      ... on MetricMultiScaleScorerConfiguration {
        scaleScorers {
          id
          scale {
            shortname
            name
          }
        }
      }
    }
    metricInclusionCriteria {
      id
      criterionType
      operator
      stringValue
      numberValue
      integerValue
      severityValues
      trendValue
      stringArrayValue
      integerArrayValue
      excludeResults
    }
    triggerBasedAggregationType
    metricTriggers {
      id
      triggerType
      severityValue
      trendValue
      integerValue
      stringArrayValue
      stringValue
    }
  }
`;
export const MetricDetailsFragmentDoc = gql`
  fragment MetricDetails on Metric {
    id
    name
    entityTreeNode {
      ...EntityTreeNodeDetails
    }
    configuration {
      ...TimeBasedMetricDetails
      ...TriggerBasedMetricDetails
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
  ${TimeBasedMetricDetailsFragmentDoc}
  ${TriggerBasedMetricDetailsFragmentDoc}
`;
export const PipbhcAllGroupAppointmentsFragmentDoc = gql`
  fragment PipbhcAllGroupAppointments on CareGroup {
    pipbhcAllGroupSchedulingAppointments {
      id
      comment
      endDate
      startDate
      status
      minutesDuration
      patientSession {
        status
        targetDate
      }
      provider {
        name
        id
      }
      patient {
        name
        id
      }
    }
    pipbhcAllGroupExternalAppointments {
      id
      startDate
      status
      minutesDuration
      providerName
      patient {
        name
        id
      }
    }
  }
`;
export const IdentifierDetailsFragmentDoc = gql`
  fragment IdentifierDetails on Identifier {
    assigner
    value
  }
`;
export const ConditionFragmentDoc = gql`
  fragment Condition on Condition {
    description
    id
    mnemonic
    name
    shortname
    identifiers {
      ...IdentifierDetails
    }
  }
  ${IdentifierDetailsFragmentDoc}
`;
export const PipbhcPatientConditionFragmentDoc = gql`
  fragment PipbhcPatientCondition on PipbhcPatientCondition {
    condition {
      ...Condition
    }
    id
    status
    onsetDate
    abatementDate
  }
  ${ConditionFragmentDoc}
`;
export const PipbhcPatientDetailsFragmentDoc = gql`
  fragment PipbhcPatientDetails on Patient {
    identifiers {
      ...IdentifierDetails
    }
    id
    name
    gender
    genderIdentity
    genderIdentityFhir
    dob
    pipbhcPatientConditions {
      ...PipbhcPatientCondition
    }
  }
  ${IdentifierDetailsFragmentDoc}
  ${PipbhcPatientConditionFragmentDoc}
`;
export const ExternalEventFragmentFragmentDoc = gql`
  fragment ExternalEventFragment on ExternalEvent {
    id
    data
    name
    details
    effectiveDate
    createdAt
    updatedAt
    externalEventType {
      name
      mnemonic
      description
      category
    }
    author {
      id
      name
    }
    patient {
      name
      id
    }
  }
`;
export const PipbhcTreatmentGoalReportFragmentDoc = gql`
  fragment PipbhcTreatmentGoalReport on PipbhcTreatmentGoalReport {
    id
    effectiveDate
    note
  }
`;
export const PipbhcTreatmentGoalFragmentDoc = gql`
  fragment PipbhcTreatmentGoal on PipbhcTreatmentGoal {
    id
    goalText
    note
    status
  }
`;
export const PipbhcTreatmentGoalAnswerFragmentDoc = gql`
  fragment PipbhcTreatmentGoalAnswer on PipbhcTreatmentGoalAnswer {
    id
    value
    note
    pipbhcTreatmentGoalReport {
      id
      effectiveDate
      note
    }
  }
`;
export const TriggeredWorkflowSummaryFragmentDoc = gql`
  fragment TriggeredWorkflowSummary on TriggeredWorkflow {
    id
    status
    snoozedUntil
    triggeredAt
    reasons
    statusUpdatedAt
    statusUpdatedBy {
      id
      name
    }
    triggeredWorkflowActions {
      id
      status
      workflowAction {
        id
        text
      }
    }
  }
`;
export const PatientSessionSummaryFragmentFragmentDoc = gql`
  fragment PatientSessionSummaryFragment on PatientSession {
    id
    sessionNumber
    targetDate
    assessmentReport {
      id
    }
  }
`;
export const OrganizationSummaryFragmentDoc = gql`
  fragment OrganizationSummary on Organization {
    id
    name
  }
`;
export const ParticipantSummaryFragmentFragmentDoc = gql`
  fragment ParticipantSummaryFragment on Participant {
    participantId
    relationship
    user {
      ...UserSummaryFragment
    }
  }
  ${UserSummaryFragmentFragmentDoc}
`;
export const CareEpisodeDetailFragmentFragmentDoc = gql`
  fragment CareEpisodeDetailFragment on CareEpisode {
    id
    status
    periodStart
    periodEnd
    patientSessions(
      sortBy: TARGETDATE
      sortDirection: DESC
      first: 1
      status: [FEEDBACK_AVAILABLE, FEEDBACKCOMPLETE, REPORT_PREVIEWED, REPORT_VIEWED, COMPLETE]
    ) {
      nodes {
        ...PatientSessionSummaryFragment
      }
    }
    organization {
      ...OrganizationSummary
    }
    participants {
      ...ParticipantSummaryFragment
      participantId
      relationship
      user {
        ...UserSummaryFragment
      }
    }
  }
  ${PatientSessionSummaryFragmentFragmentDoc}
  ${OrganizationSummaryFragmentDoc}
  ${ParticipantSummaryFragmentFragmentDoc}
  ${UserSummaryFragmentFragmentDoc}
`;
export const CareEpisodeSummaryFragmentDoc = gql`
  fragment CareEpisodeSummary on CareEpisode {
    id
    status
    periodStart
    periodEnd
    organization {
      ...OrganizationSummary
    }
  }
  ${OrganizationSummaryFragmentDoc}
`;
export const ConstructSummaryFragmentDoc = gql`
  fragment ConstructSummary on Construct {
    id
    name
    shortname
    description
    displayOrder
    domain {
      id
      name
    }
  }
`;
export const EntitySummaryFragmentDoc = gql`
  fragment EntitySummary on Entity {
    id
    entityType
    name
    shortname
  }
`;
export const ProviderSummaryFragmentFragmentDoc = gql`
  fragment ProviderSummaryFragment on Provider {
    id
    title
    user {
      ...UserSummaryFragment
    }
  }
  ${UserSummaryFragmentFragmentDoc}
`;
export const ClinicalAlertDetailsFragmentFragmentDoc = gql`
  fragment ClinicalAlertDetailsFragment on CareEpisodeClinicalAlert {
    id
    effectiveDate
    targetDate
    status
    answers {
      ...AnswerDetailWithUserFragment
    }
    invitations {
      id
      dateCompleted
      dateBegun
      user {
        id
      }
    }
  }
  ${AnswerDetailWithUserFragmentFragmentDoc}
`;
export const ClinicalAlertHistoryFragmentFragmentDoc = gql`
  fragment ClinicalAlertHistoryFragment on CareEpisodeClinicalAlertHistory {
    id
    alertType
    scale {
      mnemonic
      scaleGroup {
        id
      }
    }
    latest {
      ...ClinicalAlertDetailsFragment
    }
    history {
      ...ClinicalAlertDetailsFragment
    }
  }
  ${ClinicalAlertDetailsFragmentFragmentDoc}
`;
export const GoalFragmentFragmentDoc = gql`
  fragment GoalFragment on Goal {
    id
    goalType
    status
    startDate
    endDate
    isBeneficial
    user {
      ...UserSummaryFragment
    }
    patientText
    thresholdData {
      mnemonic
      thresholdClass
      maxValue
      minValue
    }
    goalAnswers {
      id
      answerDate
      targetDate
      effectiveDate
      value
    }
  }
  ${UserSummaryFragmentFragmentDoc}
`;
export const ScaleCompletionFragmentFragmentDoc = gql`
  fragment ScaleCompletionFragment on CareEpisodeComputedValue {
    id
    value
    effectiveDate
    targetDate
    validity
    user {
      id
    }
  }
`;
export const ScaleGroupSummaryFragmentFragmentDoc = gql`
  fragment ScaleGroupSummaryFragment on ScaleGroup {
    id
    name
    description
    shortname
    displayMode
  }
`;
export const ThresholdFragmentDoc = gql`
  fragment Threshold on ScaleThreshold {
    mnemonic
    minValue
    maxValue
    thresholdClass
  }
`;
export const InstituteGroupsListDocument = gql`
  query InstituteGroupsList {
    instituteGroups {
      nodes {
        id
        name
        members {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useInstituteGroupsListQuery__
 *
 * To run a query within a React component, call `useInstituteGroupsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteGroupsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteGroupsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useInstituteGroupsListQuery(
  baseOptions?: Apollo.QueryHookOptions<InstituteGroupsListQuery, InstituteGroupsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteGroupsListQuery, InstituteGroupsListQueryVariables>(
    InstituteGroupsListDocument,
    options
  );
}
export function useInstituteGroupsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstituteGroupsListQuery, InstituteGroupsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteGroupsListQuery, InstituteGroupsListQueryVariables>(
    InstituteGroupsListDocument,
    options
  );
}
export type InstituteGroupsListQueryHookResult = ReturnType<typeof useInstituteGroupsListQuery>;
export type InstituteGroupsListLazyQueryHookResult = ReturnType<typeof useInstituteGroupsListLazyQuery>;
export type InstituteGroupsListQueryResult = Apollo.QueryResult<
  InstituteGroupsListQuery,
  InstituteGroupsListQueryVariables
>;
export const CocmBillingAlgorithmDetailsDocument = gql`
  query CocmBillingAlgorithmDetails($id: CocmBillingAlgorithmId!) {
    billingCocmBillingAlgorithm(id: $id) {
      id
      name
      startDate
      endDate
      status
      entityTreeNode {
        ...EntityTreeNodeDetails
      }
      codes {
        id
        code
        initialEncounter
        rvusPerUnit
        avgRatePerUnit {
          cents
          currency
        }
        valueUnits
      }
      rules {
        id
        anyAssessmentCompleted
        billingDate
        minimumMinutes
        billableMinutes
        mustBeInitialEncounter
        mustBeSubsequentEncounter
        priority
        ruleCodes {
          id
          code {
            id
            code
          }
          units
        }
      }
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
`;

/**
 * __useCocmBillingAlgorithmDetailsQuery__
 *
 * To run a query within a React component, call `useCocmBillingAlgorithmDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCocmBillingAlgorithmDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCocmBillingAlgorithmDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCocmBillingAlgorithmDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CocmBillingAlgorithmDetailsQuery,
    CocmBillingAlgorithmDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CocmBillingAlgorithmDetailsQuery, CocmBillingAlgorithmDetailsQueryVariables>(
    CocmBillingAlgorithmDetailsDocument,
    options
  );
}
export function useCocmBillingAlgorithmDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CocmBillingAlgorithmDetailsQuery,
    CocmBillingAlgorithmDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CocmBillingAlgorithmDetailsQuery, CocmBillingAlgorithmDetailsQueryVariables>(
    CocmBillingAlgorithmDetailsDocument,
    options
  );
}
export type CocmBillingAlgorithmDetailsQueryHookResult = ReturnType<
  typeof useCocmBillingAlgorithmDetailsQuery
>;
export type CocmBillingAlgorithmDetailsLazyQueryHookResult = ReturnType<
  typeof useCocmBillingAlgorithmDetailsLazyQuery
>;
export type CocmBillingAlgorithmDetailsQueryResult = Apollo.QueryResult<
  CocmBillingAlgorithmDetailsQuery,
  CocmBillingAlgorithmDetailsQueryVariables
>;
export const CocmBillingAlgorithmListDocument = gql`
  query CocmBillingAlgorithmList(
    $sortBy: CocmBillingAlgorithmSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $status: [CocmBillingAlgorithmStatus!]
    $entityTreeNode: EntityTreeNodeParams
  ) {
    billingCocmBillingAlgorithms(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      status: $status
      availableForNode: $entityTreeNode
    ) {
      nodes {
        id
        name
        startDate
        endDate
        status
        entityTreeNode {
          ...EntityTreeNodeDetails
        }
        codes {
          id
          code
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
`;

/**
 * __useCocmBillingAlgorithmListQuery__
 *
 * To run a query within a React component, call `useCocmBillingAlgorithmListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCocmBillingAlgorithmListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCocmBillingAlgorithmListQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      status: // value for 'status'
 *      entityTreeNode: // value for 'entityTreeNode'
 *   },
 * });
 */
export function useCocmBillingAlgorithmListQuery(
  baseOptions?: Apollo.QueryHookOptions<CocmBillingAlgorithmListQuery, CocmBillingAlgorithmListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CocmBillingAlgorithmListQuery, CocmBillingAlgorithmListQueryVariables>(
    CocmBillingAlgorithmListDocument,
    options
  );
}
export function useCocmBillingAlgorithmListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CocmBillingAlgorithmListQuery,
    CocmBillingAlgorithmListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CocmBillingAlgorithmListQuery, CocmBillingAlgorithmListQueryVariables>(
    CocmBillingAlgorithmListDocument,
    options
  );
}
export type CocmBillingAlgorithmListQueryHookResult = ReturnType<typeof useCocmBillingAlgorithmListQuery>;
export type CocmBillingAlgorithmListLazyQueryHookResult = ReturnType<
  typeof useCocmBillingAlgorithmListLazyQuery
>;
export type CocmBillingAlgorithmListQueryResult = Apollo.QueryResult<
  CocmBillingAlgorithmListQuery,
  CocmBillingAlgorithmListQueryVariables
>;
export const BillingPredictionDocument = gql`
  query BillingPrediction(
    $sortBy: EnrollmentMonthBillingRuleResultWinnerSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $month: MonthParams
    $forPanel: PanelFilter
    $withWinningRule: [CocmBillingAlgorithmRuleId!]
    $withMonthOfTreatment: [Int!]
    $availableForNode: EntityTreeNodeParams
    $testPatient: Boolean = false
  ) {
    billingEnrollmentMonthBillingRuleResultWinners(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      search: $search
      month: $month
      forPanel: $forPanel
      withWinningRule: $withWinningRule
      withMonthOfTreatment: $withMonthOfTreatment
      availableForNode: $availableForNode
      testPatient: $testPatient
    ) {
      nodes {
        id
        enrollmentMonth {
          id
          billableMinutes
          beginningOfMonth
          month
          validatedMeasureCompleted
          minutesTargetCeiling
          minutesTargetFloor
          minutesTargetStatus
          careManager {
            id
            orderedName
          }
          pcp {
            orderedName
          }
          enrollment {
            id
            careEpisode {
              id
              patient {
                id
                name
                firstName
                lastName
                legalFirstName
                legalLastName
                mrn
                dob
                isTest
                nameLastFirst
              }
            }
          }
        }
        winningResult {
          ...RuleResultDetails
        }
        nextResult {
          ...RuleResultDetails
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
  ${RuleResultDetailsFragmentDoc}
`;

/**
 * __useBillingPredictionQuery__
 *
 * To run a query within a React component, call `useBillingPredictionQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingPredictionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingPredictionQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      month: // value for 'month'
 *      forPanel: // value for 'forPanel'
 *      withWinningRule: // value for 'withWinningRule'
 *      withMonthOfTreatment: // value for 'withMonthOfTreatment'
 *      availableForNode: // value for 'availableForNode'
 *      testPatient: // value for 'testPatient'
 *   },
 * });
 */
export function useBillingPredictionQuery(
  baseOptions?: Apollo.QueryHookOptions<BillingPredictionQuery, BillingPredictionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingPredictionQuery, BillingPredictionQueryVariables>(
    BillingPredictionDocument,
    options
  );
}
export function useBillingPredictionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BillingPredictionQuery, BillingPredictionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingPredictionQuery, BillingPredictionQueryVariables>(
    BillingPredictionDocument,
    options
  );
}
export type BillingPredictionQueryHookResult = ReturnType<typeof useBillingPredictionQuery>;
export type BillingPredictionLazyQueryHookResult = ReturnType<typeof useBillingPredictionLazyQuery>;
export type BillingPredictionQueryResult = Apollo.QueryResult<
  BillingPredictionQuery,
  BillingPredictionQueryVariables
>;
export const BillingPredictionSummaryStatsDocument = gql`
  query BillingPredictionSummaryStats(
    $month: MonthParams!
    $entityTreeNode: EntityTreeNodeParams!
    $monthOfEnrollment: [Int!]
    $rules: [CocmBillingAlgorithmRuleId!]
  ) {
    billingCocmEstimatedBillingStatsSummary(
      month: $month
      entityTreeNode: $entityTreeNode
      rules: $rules
      monthOfEnrollment: $monthOfEnrollment
    ) {
      currentMonthData {
        rule {
          id
          priority
        }
        avgEnrollmentMonths
        avgRateCents
        avgRvus
        avgValueUnits
        avgBillableMinutes
      }
      maxRateCents
      maxRvus
      maxBillableMinutes
      maxValueUnits
      previousMonthData {
        rule {
          id
          priority
        }
        avgEnrollmentMonths
        avgRateCents
        avgRvus
        avgBillableMinutes
        avgValueUnits
      }
      historicalAverageData {
        rule {
          id
          priority
        }
        avgEnrollmentMonths
        avgRateCents
        avgRvus
        avgBillableMinutes
        avgValueUnits
      }
      entityTreeNode {
        path
      }
    }
  }
`;

/**
 * __useBillingPredictionSummaryStatsQuery__
 *
 * To run a query within a React component, call `useBillingPredictionSummaryStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingPredictionSummaryStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingPredictionSummaryStatsQuery({
 *   variables: {
 *      month: // value for 'month'
 *      entityTreeNode: // value for 'entityTreeNode'
 *      monthOfEnrollment: // value for 'monthOfEnrollment'
 *      rules: // value for 'rules'
 *   },
 * });
 */
export function useBillingPredictionSummaryStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BillingPredictionSummaryStatsQuery,
    BillingPredictionSummaryStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingPredictionSummaryStatsQuery, BillingPredictionSummaryStatsQueryVariables>(
    BillingPredictionSummaryStatsDocument,
    options
  );
}
export function useBillingPredictionSummaryStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BillingPredictionSummaryStatsQuery,
    BillingPredictionSummaryStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BillingPredictionSummaryStatsQuery, BillingPredictionSummaryStatsQueryVariables>(
    BillingPredictionSummaryStatsDocument,
    options
  );
}
export type BillingPredictionSummaryStatsQueryHookResult = ReturnType<
  typeof useBillingPredictionSummaryStatsQuery
>;
export type BillingPredictionSummaryStatsLazyQueryHookResult = ReturnType<
  typeof useBillingPredictionSummaryStatsLazyQuery
>;
export type BillingPredictionSummaryStatsQueryResult = Apollo.QueryResult<
  BillingPredictionSummaryStatsQuery,
  BillingPredictionSummaryStatsQueryVariables
>;
export const BillingPredictionWinnerDetailsDocument = gql`
  query BillingPredictionWinnerDetails($id: EnrollmentMonthBillingRuleResultWinnerId!) {
    billingEnrollmentMonthBillingRuleResultWinner(id: $id) {
      id
      enrollmentMonth {
        id
        billableMinutes
        beginningOfMonth
        month
        validatedMeasureCompleted
        firstValidatedMeasureCompletedAt
        lastValidatedMeasureCompletedAt
        careManager {
          ...ProviderSummaryFragment
        }
        pcp {
          ...ProviderSummaryFragment
        }
        enrollment {
          id
          firstValidatedMeasureCompletedAt
          enrolledAt
          careEpisode {
            id
            patient {
              id
              name
              firstName
              lastName
              legalFirstName
              legalLastName
              mrn
              dob
              isTest
              nameLastFirst
            }
          }
        }
      }
      winningResult {
        ...RuleResultDetails
      }
      nextResult {
        ...RuleResultDetails
      }
      results {
        ...RuleResultDetails
      }
    }
  }
  ${ProviderSummaryFragmentFragmentDoc}
  ${RuleResultDetailsFragmentDoc}
`;

/**
 * __useBillingPredictionWinnerDetailsQuery__
 *
 * To run a query within a React component, call `useBillingPredictionWinnerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingPredictionWinnerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingPredictionWinnerDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBillingPredictionWinnerDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BillingPredictionWinnerDetailsQuery,
    BillingPredictionWinnerDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BillingPredictionWinnerDetailsQuery, BillingPredictionWinnerDetailsQueryVariables>(
    BillingPredictionWinnerDetailsDocument,
    options
  );
}
export function useBillingPredictionWinnerDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BillingPredictionWinnerDetailsQuery,
    BillingPredictionWinnerDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BillingPredictionWinnerDetailsQuery,
    BillingPredictionWinnerDetailsQueryVariables
  >(BillingPredictionWinnerDetailsDocument, options);
}
export type BillingPredictionWinnerDetailsQueryHookResult = ReturnType<
  typeof useBillingPredictionWinnerDetailsQuery
>;
export type BillingPredictionWinnerDetailsLazyQueryHookResult = ReturnType<
  typeof useBillingPredictionWinnerDetailsLazyQuery
>;
export type BillingPredictionWinnerDetailsQueryResult = Apollo.QueryResult<
  BillingPredictionWinnerDetailsQuery,
  BillingPredictionWinnerDetailsQueryVariables
>;
export const CocmBillingDashboardRulesDocument = gql`
  query CocmBillingDashboardRules($entityTreeNode: EntityTreeNodeParams) {
    billingCocmBillingAlgorithms(availableForNode: $entityTreeNode, first: 10) {
      nodes {
        id
        name
        startDate
        endDate
        status
        entityTreeNode {
          ...EntityTreeNodeDetails
        }
        codes {
          id
          code
        }
        rules {
          id
          anyAssessmentCompleted
          billingDate
          minimumMinutes
          mustBeInitialEncounter
          mustBeSubsequentEncounter
          priority
          ruleCodes {
            id
            code {
              id
              code
            }
            units
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
`;

/**
 * __useCocmBillingDashboardRulesQuery__
 *
 * To run a query within a React component, call `useCocmBillingDashboardRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCocmBillingDashboardRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCocmBillingDashboardRulesQuery({
 *   variables: {
 *      entityTreeNode: // value for 'entityTreeNode'
 *   },
 * });
 */
export function useCocmBillingDashboardRulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CocmBillingDashboardRulesQuery,
    CocmBillingDashboardRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CocmBillingDashboardRulesQuery, CocmBillingDashboardRulesQueryVariables>(
    CocmBillingDashboardRulesDocument,
    options
  );
}
export function useCocmBillingDashboardRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CocmBillingDashboardRulesQuery,
    CocmBillingDashboardRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CocmBillingDashboardRulesQuery, CocmBillingDashboardRulesQueryVariables>(
    CocmBillingDashboardRulesDocument,
    options
  );
}
export type CocmBillingDashboardRulesQueryHookResult = ReturnType<typeof useCocmBillingDashboardRulesQuery>;
export type CocmBillingDashboardRulesLazyQueryHookResult = ReturnType<
  typeof useCocmBillingDashboardRulesLazyQuery
>;
export type CocmBillingDashboardRulesQueryResult = Apollo.QueryResult<
  CocmBillingDashboardRulesQuery,
  CocmBillingDashboardRulesQueryVariables
>;
export const EstimatedBillingCumulativeEffortDocument = gql`
  query EstimatedBillingCumulativeEffort(
    $month: MonthParams!
    $entityTreeNode: EntityTreeNodeParams!
    $valueType: CocmBillingAlgorithmRuleCheckValue!
    $monthOfEnrollment: [Int!]
    $ruleIds: [CocmBillingAlgorithmRuleId!]
  ) {
    billingEstimatedBillingCumulativeEffort(
      month: $month
      entityTreeNode: $entityTreeNode
      valueType: $valueType
      monthOfEnrollment: $monthOfEnrollment
      ruleIds: $ruleIds
    ) {
      dataPoints {
        cumulativeMinutes
        cumulativeRvus
        cumulativeBillableMinutes
        cumulativeValueUnits
        cumulativeRateCents
        cumulativeValueWhenCompleted
        additionalPercentOfTarget
        totalPercentOfTarget
        winnerId
      }
    }
  }
`;

/**
 * __useEstimatedBillingCumulativeEffortQuery__
 *
 * To run a query within a React component, call `useEstimatedBillingCumulativeEffortQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimatedBillingCumulativeEffortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimatedBillingCumulativeEffortQuery({
 *   variables: {
 *      month: // value for 'month'
 *      entityTreeNode: // value for 'entityTreeNode'
 *      valueType: // value for 'valueType'
 *      monthOfEnrollment: // value for 'monthOfEnrollment'
 *      ruleIds: // value for 'ruleIds'
 *   },
 * });
 */
export function useEstimatedBillingCumulativeEffortQuery(
  baseOptions: Apollo.QueryHookOptions<
    EstimatedBillingCumulativeEffortQuery,
    EstimatedBillingCumulativeEffortQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EstimatedBillingCumulativeEffortQuery,
    EstimatedBillingCumulativeEffortQueryVariables
  >(EstimatedBillingCumulativeEffortDocument, options);
}
export function useEstimatedBillingCumulativeEffortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EstimatedBillingCumulativeEffortQuery,
    EstimatedBillingCumulativeEffortQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EstimatedBillingCumulativeEffortQuery,
    EstimatedBillingCumulativeEffortQueryVariables
  >(EstimatedBillingCumulativeEffortDocument, options);
}
export type EstimatedBillingCumulativeEffortQueryHookResult = ReturnType<
  typeof useEstimatedBillingCumulativeEffortQuery
>;
export type EstimatedBillingCumulativeEffortLazyQueryHookResult = ReturnType<
  typeof useEstimatedBillingCumulativeEffortLazyQuery
>;
export type EstimatedBillingCumulativeEffortQueryResult = Apollo.QueryResult<
  EstimatedBillingCumulativeEffortQuery,
  EstimatedBillingCumulativeEffortQueryVariables
>;
export const DefaultTaskListDocument = gql`
  query DefaultTaskList(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $sortBy: TaskListItemSortParameter
    $sortDirection: SortDirection
    $forPanel: PanelFilter
    $status: TaskStatus
    $testPatient: Boolean
  ) {
    collaborativeCareMyDefaultTaskList {
      id
      name
      isDefault
      taskListItems(
        first: $first
        last: $last
        before: $before
        after: $after
        sortBy: $sortBy
        sortDirection: $sortDirection
        status: $status
        testPatient: $testPatient
        forPanel: $forPanel
      ) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
        nodes {
          id
          position
          task {
            id
            title
            status
            createdAt
            dueAt
            patient {
              id
              name
              firstName
            }
          }
        }
      }
      nonPanelItems: taskListItems(status: $status, testPatient: $testPatient) {
        totalCount
      }
    }
  }
`;

/**
 * __useDefaultTaskListQuery__
 *
 * To run a query within a React component, call `useDefaultTaskListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultTaskListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultTaskListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      forPanel: // value for 'forPanel'
 *      status: // value for 'status'
 *      testPatient: // value for 'testPatient'
 *   },
 * });
 */
export function useDefaultTaskListQuery(
  baseOptions?: Apollo.QueryHookOptions<DefaultTaskListQuery, DefaultTaskListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DefaultTaskListQuery, DefaultTaskListQueryVariables>(
    DefaultTaskListDocument,
    options
  );
}
export function useDefaultTaskListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DefaultTaskListQuery, DefaultTaskListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DefaultTaskListQuery, DefaultTaskListQueryVariables>(
    DefaultTaskListDocument,
    options
  );
}
export type DefaultTaskListQueryHookResult = ReturnType<typeof useDefaultTaskListQuery>;
export type DefaultTaskListLazyQueryHookResult = ReturnType<typeof useDefaultTaskListLazyQuery>;
export type DefaultTaskListQueryResult = Apollo.QueryResult<
  DefaultTaskListQuery,
  DefaultTaskListQueryVariables
>;
export const MergePlaceholderTaskWithExistingTaskDocument = gql`
  mutation MergePlaceholderTaskWithExistingTask($input: MergePlaceholderTaskWithExistingTaskInput!) {
    collaborativeCareMergePlaceholderTaskWithExistingTask(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        task {
          id
          title
          body
          createdAt
          dueAt
          status
          isPlaceholder
          patient {
            id
            name
            isTest
          }
          assignedTo {
            id
            name
          }
          notes(first: 1000) {
            nodes {
              id
              text
              author {
                id
                name
              }
              timeEntry {
                startTime
              }
              createdAt
            }
          }
          timeEntries(first: 1000) {
            nodes {
              id
              startTime
              endTime
              durationSeconds
              createdAt
              provider {
                id
                name
              }
            }
          }
          creationLog {
            id
            creationType
            patientSession {
              id
              targetDate
              patient {
                id
                name
                isTest
              }
            }
            requestedBy {
              id
              name
            }
            assignedBy {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export type MergePlaceholderTaskWithExistingTaskMutationFn = Apollo.MutationFunction<
  MergePlaceholderTaskWithExistingTaskMutation,
  MergePlaceholderTaskWithExistingTaskMutationVariables
>;

/**
 * __useMergePlaceholderTaskWithExistingTaskMutation__
 *
 * To run a mutation, you first call `useMergePlaceholderTaskWithExistingTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergePlaceholderTaskWithExistingTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergePlaceholderTaskWithExistingTaskMutation, { data, loading, error }] = useMergePlaceholderTaskWithExistingTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMergePlaceholderTaskWithExistingTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MergePlaceholderTaskWithExistingTaskMutation,
    MergePlaceholderTaskWithExistingTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MergePlaceholderTaskWithExistingTaskMutation,
    MergePlaceholderTaskWithExistingTaskMutationVariables
  >(MergePlaceholderTaskWithExistingTaskDocument, options);
}
export type MergePlaceholderTaskWithExistingTaskMutationHookResult = ReturnType<
  typeof useMergePlaceholderTaskWithExistingTaskMutation
>;
export type MergePlaceholderTaskWithExistingTaskMutationResult =
  Apollo.MutationResult<MergePlaceholderTaskWithExistingTaskMutation>;
export type MergePlaceholderTaskWithExistingTaskMutationOptions = Apollo.BaseMutationOptions<
  MergePlaceholderTaskWithExistingTaskMutation,
  MergePlaceholderTaskWithExistingTaskMutationVariables
>;
export const MoveDefaultTaskListItemDocument = gql`
  mutation MoveDefaultTaskListItem($input: MoveTaskListItemToPositionInDefaultTaskListInput!) {
    collaborativeCareMoveTaskListItemToPositionInDefaultTaskList(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        taskList {
          id
          taskListItems {
            nodes {
              id
              position
            }
          }
        }
      }
    }
  }
`;
export type MoveDefaultTaskListItemMutationFn = Apollo.MutationFunction<
  MoveDefaultTaskListItemMutation,
  MoveDefaultTaskListItemMutationVariables
>;

/**
 * __useMoveDefaultTaskListItemMutation__
 *
 * To run a mutation, you first call `useMoveDefaultTaskListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveDefaultTaskListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveDefaultTaskListItemMutation, { data, loading, error }] = useMoveDefaultTaskListItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveDefaultTaskListItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveDefaultTaskListItemMutation,
    MoveDefaultTaskListItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoveDefaultTaskListItemMutation, MoveDefaultTaskListItemMutationVariables>(
    MoveDefaultTaskListItemDocument,
    options
  );
}
export type MoveDefaultTaskListItemMutationHookResult = ReturnType<typeof useMoveDefaultTaskListItemMutation>;
export type MoveDefaultTaskListItemMutationResult = Apollo.MutationResult<MoveDefaultTaskListItemMutation>;
export type MoveDefaultTaskListItemMutationOptions = Apollo.BaseMutationOptions<
  MoveDefaultTaskListItemMutation,
  MoveDefaultTaskListItemMutationVariables
>;
export const MoveDefaultTaskListTaskDocument = gql`
  mutation MoveDefaultTaskListTask($input: MoveTaskToPositionInDefaultTaskListInput!) {
    collaborativeCareMoveTaskToPositionInDefaultTaskList(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        taskList {
          id
          taskListItems {
            nodes {
              id
              position
            }
          }
        }
      }
    }
  }
`;
export type MoveDefaultTaskListTaskMutationFn = Apollo.MutationFunction<
  MoveDefaultTaskListTaskMutation,
  MoveDefaultTaskListTaskMutationVariables
>;

/**
 * __useMoveDefaultTaskListTaskMutation__
 *
 * To run a mutation, you first call `useMoveDefaultTaskListTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveDefaultTaskListTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveDefaultTaskListTaskMutation, { data, loading, error }] = useMoveDefaultTaskListTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveDefaultTaskListTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveDefaultTaskListTaskMutation,
    MoveDefaultTaskListTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoveDefaultTaskListTaskMutation, MoveDefaultTaskListTaskMutationVariables>(
    MoveDefaultTaskListTaskDocument,
    options
  );
}
export type MoveDefaultTaskListTaskMutationHookResult = ReturnType<typeof useMoveDefaultTaskListTaskMutation>;
export type MoveDefaultTaskListTaskMutationResult = Apollo.MutationResult<MoveDefaultTaskListTaskMutation>;
export type MoveDefaultTaskListTaskMutationOptions = Apollo.BaseMutationOptions<
  MoveDefaultTaskListTaskMutation,
  MoveDefaultTaskListTaskMutationVariables
>;
export const DailyAppointmentDocument = gql`
  query DailyAppointment(
    $unit: OrganizationFilter
    $startDateAfter: ISO8601DateTime
    $startDateBefore: ISO8601DateTime
  ) {
    schedulingAppointments(unit: $unit, startDateAfter: $startDateAfter, startDateBefore: $startDateBefore) {
      nodes {
        id
        startDate
        endDate
        patient {
          id
          name
        }
        careEpisode {
          id
          careEpisodeProviders {
            relationship
            id
            provider {
              name
              id
            }
          }
        }
      }
    }
  }
`;

/**
 * __useDailyAppointmentQuery__
 *
 * To run a query within a React component, call `useDailyAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyAppointmentQuery({
 *   variables: {
 *      unit: // value for 'unit'
 *      startDateAfter: // value for 'startDateAfter'
 *      startDateBefore: // value for 'startDateBefore'
 *   },
 * });
 */
export function useDailyAppointmentQuery(
  baseOptions?: Apollo.QueryHookOptions<DailyAppointmentQuery, DailyAppointmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyAppointmentQuery, DailyAppointmentQueryVariables>(
    DailyAppointmentDocument,
    options
  );
}
export function useDailyAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyAppointmentQuery, DailyAppointmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyAppointmentQuery, DailyAppointmentQueryVariables>(
    DailyAppointmentDocument,
    options
  );
}
export type DailyAppointmentQueryHookResult = ReturnType<typeof useDailyAppointmentQuery>;
export type DailyAppointmentLazyQueryHookResult = ReturnType<typeof useDailyAppointmentLazyQuery>;
export type DailyAppointmentQueryResult = Apollo.QueryResult<
  DailyAppointmentQuery,
  DailyAppointmentQueryVariables
>;
export const FindingsDocument = gql`
  query Findings(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $forPanel: PanelFilter
    $unresolvedOnly: Boolean
  ) {
    collaborativeCareFindings(
      first: $first
      last: $last
      before: $before
      after: $after
      unresolvedOnly: $unresolvedOnly
      forPanel: $forPanel
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      nodes {
        id
        flavor
        patient {
          id
          name
        }
        invitation {
          id
          reportScales {
            id
            scale {
              shortname
            }
          }
          user {
            ...UserSummaryFragment
          }
        }
        createdAt
      }
    }
    nonPanelFindings: collaborativeCareFindings(unresolvedOnly: $unresolvedOnly) {
      totalCount
    }
  }
  ${UserSummaryFragmentFragmentDoc}
`;

/**
 * __useFindingsQuery__
 *
 * To run a query within a React component, call `useFindingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindingsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      forPanel: // value for 'forPanel'
 *      unresolvedOnly: // value for 'unresolvedOnly'
 *   },
 * });
 */
export function useFindingsQuery(
  baseOptions?: Apollo.QueryHookOptions<FindingsQuery, FindingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindingsQuery, FindingsQueryVariables>(FindingsDocument, options);
}
export function useFindingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindingsQuery, FindingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindingsQuery, FindingsQueryVariables>(FindingsDocument, options);
}
export type FindingsQueryHookResult = ReturnType<typeof useFindingsQuery>;
export type FindingsLazyQueryHookResult = ReturnType<typeof useFindingsLazyQuery>;
export type FindingsQueryResult = Apollo.QueryResult<FindingsQuery, FindingsQueryVariables>;
export const AcceptFindingDocument = gql`
  mutation AcceptFinding($input: AcceptFindingInput!) {
    collaborativeCareAcceptFinding(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        finding {
          id
          flavor
          dismissedAt
          createdAt
          patient {
            id
            name
          }
        }
      }
    }
  }
`;
export type AcceptFindingMutationFn = Apollo.MutationFunction<
  AcceptFindingMutation,
  AcceptFindingMutationVariables
>;

/**
 * __useAcceptFindingMutation__
 *
 * To run a mutation, you first call `useAcceptFindingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptFindingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptFindingMutation, { data, loading, error }] = useAcceptFindingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptFindingMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptFindingMutation, AcceptFindingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AcceptFindingMutation, AcceptFindingMutationVariables>(
    AcceptFindingDocument,
    options
  );
}
export type AcceptFindingMutationHookResult = ReturnType<typeof useAcceptFindingMutation>;
export type AcceptFindingMutationResult = Apollo.MutationResult<AcceptFindingMutation>;
export type AcceptFindingMutationOptions = Apollo.BaseMutationOptions<
  AcceptFindingMutation,
  AcceptFindingMutationVariables
>;
export const DismissFindingDocument = gql`
  mutation DismissFinding($input: DismissFindingInput!) {
    collaborativeCareDismissFinding(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        finding {
          id
          flavor
          dismissedAt
          createdAt
          patient {
            id
            name
          }
        }
      }
    }
  }
`;
export type DismissFindingMutationFn = Apollo.MutationFunction<
  DismissFindingMutation,
  DismissFindingMutationVariables
>;

/**
 * __useDismissFindingMutation__
 *
 * To run a mutation, you first call `useDismissFindingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissFindingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissFindingMutation, { data, loading, error }] = useDismissFindingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDismissFindingMutation(
  baseOptions?: Apollo.MutationHookOptions<DismissFindingMutation, DismissFindingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DismissFindingMutation, DismissFindingMutationVariables>(
    DismissFindingDocument,
    options
  );
}
export type DismissFindingMutationHookResult = ReturnType<typeof useDismissFindingMutation>;
export type DismissFindingMutationResult = Apollo.MutationResult<DismissFindingMutation>;
export type DismissFindingMutationOptions = Apollo.BaseMutationOptions<
  DismissFindingMutation,
  DismissFindingMutationVariables
>;
export const InvalidateFindingsDocument = gql`
  mutation InvalidateFindings($input: InvalidateFindingsInput!) {
    collaborativeCareInvalidateFindings(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        findings {
          id
          flavor
          invalidatedAt
        }
      }
    }
  }
`;
export type InvalidateFindingsMutationFn = Apollo.MutationFunction<
  InvalidateFindingsMutation,
  InvalidateFindingsMutationVariables
>;

/**
 * __useInvalidateFindingsMutation__
 *
 * To run a mutation, you first call `useInvalidateFindingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvalidateFindingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invalidateFindingsMutation, { data, loading, error }] = useInvalidateFindingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInvalidateFindingsMutation(
  baseOptions?: Apollo.MutationHookOptions<InvalidateFindingsMutation, InvalidateFindingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InvalidateFindingsMutation, InvalidateFindingsMutationVariables>(
    InvalidateFindingsDocument,
    options
  );
}
export type InvalidateFindingsMutationHookResult = ReturnType<typeof useInvalidateFindingsMutation>;
export type InvalidateFindingsMutationResult = Apollo.MutationResult<InvalidateFindingsMutation>;
export type InvalidateFindingsMutationOptions = Apollo.BaseMutationOptions<
  InvalidateFindingsMutation,
  InvalidateFindingsMutationVariables
>;
export const SnoozeFindingDocument = gql`
  mutation SnoozeFinding($input: SnoozeFindingInput!) {
    collaborativeCareSnoozeFinding(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        finding {
          id
          flavor
          dismissedAt
          createdAt
          patient {
            id
            name
          }
        }
      }
    }
  }
`;
export type SnoozeFindingMutationFn = Apollo.MutationFunction<
  SnoozeFindingMutation,
  SnoozeFindingMutationVariables
>;

/**
 * __useSnoozeFindingMutation__
 *
 * To run a mutation, you first call `useSnoozeFindingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSnoozeFindingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [snoozeFindingMutation, { data, loading, error }] = useSnoozeFindingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSnoozeFindingMutation(
  baseOptions?: Apollo.MutationHookOptions<SnoozeFindingMutation, SnoozeFindingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SnoozeFindingMutation, SnoozeFindingMutationVariables>(
    SnoozeFindingDocument,
    options
  );
}
export type SnoozeFindingMutationHookResult = ReturnType<typeof useSnoozeFindingMutation>;
export type SnoozeFindingMutationResult = Apollo.MutationResult<SnoozeFindingMutation>;
export type SnoozeFindingMutationOptions = Apollo.BaseMutationOptions<
  SnoozeFindingMutation,
  SnoozeFindingMutationVariables
>;
export const CancelCaseConsultDocument = gql`
  mutation CancelCaseConsult($input: CancelCaseConsultInput!) {
    collaborativeCareCancelCaseConsult(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        caseConsult {
          id
          status
        }
      }
    }
  }
`;
export type CancelCaseConsultMutationFn = Apollo.MutationFunction<
  CancelCaseConsultMutation,
  CancelCaseConsultMutationVariables
>;

/**
 * __useCancelCaseConsultMutation__
 *
 * To run a mutation, you first call `useCancelCaseConsultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCaseConsultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCaseConsultMutation, { data, loading, error }] = useCancelCaseConsultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCaseConsultMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelCaseConsultMutation, CancelCaseConsultMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelCaseConsultMutation, CancelCaseConsultMutationVariables>(
    CancelCaseConsultDocument,
    options
  );
}
export type CancelCaseConsultMutationHookResult = ReturnType<typeof useCancelCaseConsultMutation>;
export type CancelCaseConsultMutationResult = Apollo.MutationResult<CancelCaseConsultMutation>;
export type CancelCaseConsultMutationOptions = Apollo.BaseMutationOptions<
  CancelCaseConsultMutation,
  CancelCaseConsultMutationVariables
>;
export const CaseConsultDetailsDocument = gql`
  query CaseConsultDetails($id: CaseConsultId!) {
    collaborativeCareCaseConsult(id: $id) {
      id
      caseSummary
      consultationDocumentation
      consultationNotes
      status
      summaryUpdatedAt
      enrollment {
        id
        careEpisode {
          id
          collaborativeCareCareEpisodeTreatmentTargets(status: ACTIVE) {
            id
            startedDate
            treatmentTarget {
              id
              name
            }
          }
        }
      }
      patient {
        id
        note
        name
        mrn
      }
      consultRequests {
        nodes {
          id
          requestDate
          requestReason
          requestText
          provider {
            id
            name
          }
        }
      }
      consultMeeting {
        id
        status
      }
      caseSummaryTask {
        id
      }
      consultMeetingTask {
        id
      }
      documentationTask {
        id
      }
    }
  }
`;

/**
 * __useCaseConsultDetailsQuery__
 *
 * To run a query within a React component, call `useCaseConsultDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseConsultDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseConsultDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCaseConsultDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<CaseConsultDetailsQuery, CaseConsultDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CaseConsultDetailsQuery, CaseConsultDetailsQueryVariables>(
    CaseConsultDetailsDocument,
    options
  );
}
export function useCaseConsultDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CaseConsultDetailsQuery, CaseConsultDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CaseConsultDetailsQuery, CaseConsultDetailsQueryVariables>(
    CaseConsultDetailsDocument,
    options
  );
}
export type CaseConsultDetailsQueryHookResult = ReturnType<typeof useCaseConsultDetailsQuery>;
export type CaseConsultDetailsLazyQueryHookResult = ReturnType<typeof useCaseConsultDetailsLazyQuery>;
export type CaseConsultDetailsQueryResult = Apollo.QueryResult<
  CaseConsultDetailsQuery,
  CaseConsultDetailsQueryVariables
>;
export const CaseConsultListDocument = gql`
  query CaseConsultList(
    $first: Int = null
    $last: Int = null
    $before: String = null
    $after: String = null
    $forPanel: PanelFilter = null
    $statuses: [CaseConsultStatus!] = [
      NEEDS_SUMMARY
      AWAITING_CONSULT
      STALE
      AWAITING_DOCUMENTATION
      COMPLETE
    ]
    $consultMeetingId: ConsultMeetingId = null
    $patient: PatientFilter = null
    $primaryCareProviderId: ProviderId = null
    $careManagerId: ProviderId = null
    $enrollmentId: EnrollmentId = null
    $testPatient: Boolean
  ) {
    collaborativeCareCaseConsults(
      status: $statuses
      consultMeetingId: $consultMeetingId
      patient: $patient
      primaryCareProviderId: $primaryCareProviderId
      careManagerId: $careManagerId
      enrollmentId: $enrollmentId
      forPanel: $forPanel
      testPatient: $testPatient
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      nodes {
        id
        status
        caseSummary
        consultationNotes
        consultationDocumentation
        presentationOrder
        enrollment {
          id
        }
        patient {
          id
          name
          cocmPrimaryCareProvider {
            id
            name
          }
        }
        consultRequests {
          nodes {
            id
            provider {
              id
              name
            }
            requestReason
            requestText
            requestDate
          }
        }
        consultMeeting {
          id
          status
          startTime
        }
      }
    }
    nonPanelConsults: collaborativeCareCaseConsults(
      status: $statuses
      consultMeetingId: $consultMeetingId
      patient: $patient
      primaryCareProviderId: $primaryCareProviderId
      careManagerId: $careManagerId
      enrollmentId: $enrollmentId
      testPatient: $testPatient
    ) {
      totalCount
    }
  }
`;

/**
 * __useCaseConsultListQuery__
 *
 * To run a query within a React component, call `useCaseConsultListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseConsultListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseConsultListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      forPanel: // value for 'forPanel'
 *      statuses: // value for 'statuses'
 *      consultMeetingId: // value for 'consultMeetingId'
 *      patient: // value for 'patient'
 *      primaryCareProviderId: // value for 'primaryCareProviderId'
 *      careManagerId: // value for 'careManagerId'
 *      enrollmentId: // value for 'enrollmentId'
 *      testPatient: // value for 'testPatient'
 *   },
 * });
 */
export function useCaseConsultListQuery(
  baseOptions?: Apollo.QueryHookOptions<CaseConsultListQuery, CaseConsultListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CaseConsultListQuery, CaseConsultListQueryVariables>(
    CaseConsultListDocument,
    options
  );
}
export function useCaseConsultListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CaseConsultListQuery, CaseConsultListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CaseConsultListQuery, CaseConsultListQueryVariables>(
    CaseConsultListDocument,
    options
  );
}
export type CaseConsultListQueryHookResult = ReturnType<typeof useCaseConsultListQuery>;
export type CaseConsultListLazyQueryHookResult = ReturnType<typeof useCaseConsultListLazyQuery>;
export type CaseConsultListQueryResult = Apollo.QueryResult<
  CaseConsultListQuery,
  CaseConsultListQueryVariables
>;
export const ConsultMeetingListDocument = gql`
  query ConsultMeetingList(
    $first: Int = null
    $last: Int = null
    $before: String = null
    $after: String = null
    $forPanel: PanelFilter = null
    $testPatient: Boolean
  ) {
    collaborativeCareConsultMeetings(
      sortBy: START_TIME
      sortDirection: DESC
      first: $first
      last: $last
      before: $before
      after: $after
      forPanel: $forPanel
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      nodes {
        id
        startTime
        status
        attendees {
          nodes {
            id
            role
            provider {
              id
              name
            }
          }
        }
        cases(testPatient: $testPatient) {
          nodes {
            id
            patient {
              id
              name
            }
          }
        }
      }
    }
    nonPanelConsultMeetings: collaborativeCareConsultMeetings {
      totalCount
    }
  }
`;

/**
 * __useConsultMeetingListQuery__
 *
 * To run a query within a React component, call `useConsultMeetingListQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultMeetingListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultMeetingListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      forPanel: // value for 'forPanel'
 *      testPatient: // value for 'testPatient'
 *   },
 * });
 */
export function useConsultMeetingListQuery(
  baseOptions?: Apollo.QueryHookOptions<ConsultMeetingListQuery, ConsultMeetingListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConsultMeetingListQuery, ConsultMeetingListQueryVariables>(
    ConsultMeetingListDocument,
    options
  );
}
export function useConsultMeetingListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConsultMeetingListQuery, ConsultMeetingListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConsultMeetingListQuery, ConsultMeetingListQueryVariables>(
    ConsultMeetingListDocument,
    options
  );
}
export type ConsultMeetingListQueryHookResult = ReturnType<typeof useConsultMeetingListQuery>;
export type ConsultMeetingListLazyQueryHookResult = ReturnType<typeof useConsultMeetingListLazyQuery>;
export type ConsultMeetingListQueryResult = Apollo.QueryResult<
  ConsultMeetingListQuery,
  ConsultMeetingListQueryVariables
>;
export const ConsultMeetingSummaryDocument = gql`
  query ConsultMeetingSummary($id: ConsultMeetingId!) {
    collaborativeCareConsultMeeting(id: $id) {
      id
      startTime
      endTime
      status
      attendees {
        nodes {
          id
          role
          provider {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useConsultMeetingSummaryQuery__
 *
 * To run a query within a React component, call `useConsultMeetingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsultMeetingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsultMeetingSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConsultMeetingSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<ConsultMeetingSummaryQuery, ConsultMeetingSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConsultMeetingSummaryQuery, ConsultMeetingSummaryQueryVariables>(
    ConsultMeetingSummaryDocument,
    options
  );
}
export function useConsultMeetingSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConsultMeetingSummaryQuery, ConsultMeetingSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConsultMeetingSummaryQuery, ConsultMeetingSummaryQueryVariables>(
    ConsultMeetingSummaryDocument,
    options
  );
}
export type ConsultMeetingSummaryQueryHookResult = ReturnType<typeof useConsultMeetingSummaryQuery>;
export type ConsultMeetingSummaryLazyQueryHookResult = ReturnType<typeof useConsultMeetingSummaryLazyQuery>;
export type ConsultMeetingSummaryQueryResult = Apollo.QueryResult<
  ConsultMeetingSummaryQuery,
  ConsultMeetingSummaryQueryVariables
>;
export const CreateConsultMeetingAttendeeDocument = gql`
  mutation CreateConsultMeetingAttendee($input: CreateConsultMeetingAttendeeInput!) {
    collaborativeCareCreateConsultMeetingAttendee(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        consultMeetingAttendee {
          id
          provider {
            id
            name
          }
          consultMeeting {
            id
            attendees {
              nodes {
                id
              }
            }
          }
        }
      }
    }
  }
`;
export type CreateConsultMeetingAttendeeMutationFn = Apollo.MutationFunction<
  CreateConsultMeetingAttendeeMutation,
  CreateConsultMeetingAttendeeMutationVariables
>;

/**
 * __useCreateConsultMeetingAttendeeMutation__
 *
 * To run a mutation, you first call `useCreateConsultMeetingAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConsultMeetingAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConsultMeetingAttendeeMutation, { data, loading, error }] = useCreateConsultMeetingAttendeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateConsultMeetingAttendeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConsultMeetingAttendeeMutation,
    CreateConsultMeetingAttendeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateConsultMeetingAttendeeMutation,
    CreateConsultMeetingAttendeeMutationVariables
  >(CreateConsultMeetingAttendeeDocument, options);
}
export type CreateConsultMeetingAttendeeMutationHookResult = ReturnType<
  typeof useCreateConsultMeetingAttendeeMutation
>;
export type CreateConsultMeetingAttendeeMutationResult =
  Apollo.MutationResult<CreateConsultMeetingAttendeeMutation>;
export type CreateConsultMeetingAttendeeMutationOptions = Apollo.BaseMutationOptions<
  CreateConsultMeetingAttendeeMutation,
  CreateConsultMeetingAttendeeMutationVariables
>;
export const EndConsultReviewDocument = gql`
  mutation EndConsultReview($input: EndCaseConsultReviewInput!) {
    collaborativeCareEndCaseConsultReview(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        caseConsult {
          id
          status
        }
        myActiveTimeEntryLog {
          timeEntryLog {
            id
            clientStartTime
            startTime
            durationSeconds
          }
        }
      }
    }
  }
`;
export type EndConsultReviewMutationFn = Apollo.MutationFunction<
  EndConsultReviewMutation,
  EndConsultReviewMutationVariables
>;

/**
 * __useEndConsultReviewMutation__
 *
 * To run a mutation, you first call `useEndConsultReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndConsultReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endConsultReviewMutation, { data, loading, error }] = useEndConsultReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndConsultReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<EndConsultReviewMutation, EndConsultReviewMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EndConsultReviewMutation, EndConsultReviewMutationVariables>(
    EndConsultReviewDocument,
    options
  );
}
export type EndConsultReviewMutationHookResult = ReturnType<typeof useEndConsultReviewMutation>;
export type EndConsultReviewMutationResult = Apollo.MutationResult<EndConsultReviewMutation>;
export type EndConsultReviewMutationOptions = Apollo.BaseMutationOptions<
  EndConsultReviewMutation,
  EndConsultReviewMutationVariables
>;
export const MarkCaseConsultCompleteDocument = gql`
  mutation MarkCaseConsultComplete($input: MarkCaseConsultCompleteInput!) {
    collaborativeCareMarkCaseConsultComplete(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        caseConsult {
          id
          status
        }
      }
    }
  }
`;
export type MarkCaseConsultCompleteMutationFn = Apollo.MutationFunction<
  MarkCaseConsultCompleteMutation,
  MarkCaseConsultCompleteMutationVariables
>;

/**
 * __useMarkCaseConsultCompleteMutation__
 *
 * To run a mutation, you first call `useMarkCaseConsultCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkCaseConsultCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markCaseConsultCompleteMutation, { data, loading, error }] = useMarkCaseConsultCompleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkCaseConsultCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkCaseConsultCompleteMutation,
    MarkCaseConsultCompleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkCaseConsultCompleteMutation, MarkCaseConsultCompleteMutationVariables>(
    MarkCaseConsultCompleteDocument,
    options
  );
}
export type MarkCaseConsultCompleteMutationHookResult = ReturnType<typeof useMarkCaseConsultCompleteMutation>;
export type MarkCaseConsultCompleteMutationResult = Apollo.MutationResult<MarkCaseConsultCompleteMutation>;
export type MarkCaseConsultCompleteMutationOptions = Apollo.BaseMutationOptions<
  MarkCaseConsultCompleteMutation,
  MarkCaseConsultCompleteMutationVariables
>;
export const MarkConsultMeetingCompleteDocument = gql`
  mutation MarkConsultMeetingComplete($input: MarkConsultMeetingCompleteInput!) {
    collaborativeCareMarkConsultMeetingComplete(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        consultMeeting {
          id
          status
        }
      }
    }
  }
`;
export type MarkConsultMeetingCompleteMutationFn = Apollo.MutationFunction<
  MarkConsultMeetingCompleteMutation,
  MarkConsultMeetingCompleteMutationVariables
>;

/**
 * __useMarkConsultMeetingCompleteMutation__
 *
 * To run a mutation, you first call `useMarkConsultMeetingCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkConsultMeetingCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markConsultMeetingCompleteMutation, { data, loading, error }] = useMarkConsultMeetingCompleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkConsultMeetingCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkConsultMeetingCompleteMutation,
    MarkConsultMeetingCompleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkConsultMeetingCompleteMutation, MarkConsultMeetingCompleteMutationVariables>(
    MarkConsultMeetingCompleteDocument,
    options
  );
}
export type MarkConsultMeetingCompleteMutationHookResult = ReturnType<
  typeof useMarkConsultMeetingCompleteMutation
>;
export type MarkConsultMeetingCompleteMutationResult =
  Apollo.MutationResult<MarkConsultMeetingCompleteMutation>;
export type MarkConsultMeetingCompleteMutationOptions = Apollo.BaseMutationOptions<
  MarkConsultMeetingCompleteMutation,
  MarkConsultMeetingCompleteMutationVariables
>;
export const QuickTextListDocument = gql`
  query QuickTextList {
    collaborativeCareQuickTexts {
      nodes {
        id
        title
        text
        order
      }
    }
  }
`;

/**
 * __useQuickTextListQuery__
 *
 * To run a query within a React component, call `useQuickTextListQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickTextListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickTextListQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuickTextListQuery(
  baseOptions?: Apollo.QueryHookOptions<QuickTextListQuery, QuickTextListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuickTextListQuery, QuickTextListQueryVariables>(QuickTextListDocument, options);
}
export function useQuickTextListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QuickTextListQuery, QuickTextListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuickTextListQuery, QuickTextListQueryVariables>(QuickTextListDocument, options);
}
export type QuickTextListQueryHookResult = ReturnType<typeof useQuickTextListQuery>;
export type QuickTextListLazyQueryHookResult = ReturnType<typeof useQuickTextListLazyQuery>;
export type QuickTextListQueryResult = Apollo.QueryResult<QuickTextListQuery, QuickTextListQueryVariables>;
export const CreateQuickTextDocument = gql`
  mutation CreateQuickText($input: CreateQuickTextInput!) {
    collaborativeCareCreateQuickText(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        quickText {
          id
          title
          text
          order
        }
      }
    }
  }
`;
export type CreateQuickTextMutationFn = Apollo.MutationFunction<
  CreateQuickTextMutation,
  CreateQuickTextMutationVariables
>;

/**
 * __useCreateQuickTextMutation__
 *
 * To run a mutation, you first call `useCreateQuickTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuickTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuickTextMutation, { data, loading, error }] = useCreateQuickTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateQuickTextMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateQuickTextMutation, CreateQuickTextMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateQuickTextMutation, CreateQuickTextMutationVariables>(
    CreateQuickTextDocument,
    options
  );
}
export type CreateQuickTextMutationHookResult = ReturnType<typeof useCreateQuickTextMutation>;
export type CreateQuickTextMutationResult = Apollo.MutationResult<CreateQuickTextMutation>;
export type CreateQuickTextMutationOptions = Apollo.BaseMutationOptions<
  CreateQuickTextMutation,
  CreateQuickTextMutationVariables
>;
export const DeleteQuickTextDocument = gql`
  mutation DeleteQuickText($input: DeleteQuickTextInput!) {
    collaborativeCareDeleteQuickText(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        quickText {
          id
        }
      }
    }
  }
`;
export type DeleteQuickTextMutationFn = Apollo.MutationFunction<
  DeleteQuickTextMutation,
  DeleteQuickTextMutationVariables
>;

/**
 * __useDeleteQuickTextMutation__
 *
 * To run a mutation, you first call `useDeleteQuickTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuickTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuickTextMutation, { data, loading, error }] = useDeleteQuickTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteQuickTextMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteQuickTextMutation, DeleteQuickTextMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteQuickTextMutation, DeleteQuickTextMutationVariables>(
    DeleteQuickTextDocument,
    options
  );
}
export type DeleteQuickTextMutationHookResult = ReturnType<typeof useDeleteQuickTextMutation>;
export type DeleteQuickTextMutationResult = Apollo.MutationResult<DeleteQuickTextMutation>;
export type DeleteQuickTextMutationOptions = Apollo.BaseMutationOptions<
  DeleteQuickTextMutation,
  DeleteQuickTextMutationVariables
>;
export const UpdateQuickTextDocument = gql`
  mutation UpdateQuickText($input: UpdateQuickTextInput!) {
    collaborativeCareUpdateQuickText(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        quickText {
          id
          title
          text
          order
        }
      }
    }
  }
`;
export type UpdateQuickTextMutationFn = Apollo.MutationFunction<
  UpdateQuickTextMutation,
  UpdateQuickTextMutationVariables
>;

/**
 * __useUpdateQuickTextMutation__
 *
 * To run a mutation, you first call `useUpdateQuickTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuickTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuickTextMutation, { data, loading, error }] = useUpdateQuickTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuickTextMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateQuickTextMutation, UpdateQuickTextMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateQuickTextMutation, UpdateQuickTextMutationVariables>(
    UpdateQuickTextDocument,
    options
  );
}
export type UpdateQuickTextMutationHookResult = ReturnType<typeof useUpdateQuickTextMutation>;
export type UpdateQuickTextMutationResult = Apollo.MutationResult<UpdateQuickTextMutation>;
export type UpdateQuickTextMutationOptions = Apollo.BaseMutationOptions<
  UpdateQuickTextMutation,
  UpdateQuickTextMutationVariables
>;
export const CollaborativeCareReactivatePreviousEnrollmentDocument = gql`
  mutation CollaborativeCareReactivatePreviousEnrollment($input: ReactivatePreviousEnrollmentInput!) {
    collaborativeCareReactivatePreviousEnrollment(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        enrollment {
          id
        }
      }
    }
  }
`;
export type CollaborativeCareReactivatePreviousEnrollmentMutationFn = Apollo.MutationFunction<
  CollaborativeCareReactivatePreviousEnrollmentMutation,
  CollaborativeCareReactivatePreviousEnrollmentMutationVariables
>;

/**
 * __useCollaborativeCareReactivatePreviousEnrollmentMutation__
 *
 * To run a mutation, you first call `useCollaborativeCareReactivatePreviousEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollaborativeCareReactivatePreviousEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collaborativeCareReactivatePreviousEnrollmentMutation, { data, loading, error }] = useCollaborativeCareReactivatePreviousEnrollmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCollaborativeCareReactivatePreviousEnrollmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CollaborativeCareReactivatePreviousEnrollmentMutation,
    CollaborativeCareReactivatePreviousEnrollmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CollaborativeCareReactivatePreviousEnrollmentMutation,
    CollaborativeCareReactivatePreviousEnrollmentMutationVariables
  >(CollaborativeCareReactivatePreviousEnrollmentDocument, options);
}
export type CollaborativeCareReactivatePreviousEnrollmentMutationHookResult = ReturnType<
  typeof useCollaborativeCareReactivatePreviousEnrollmentMutation
>;
export type CollaborativeCareReactivatePreviousEnrollmentMutationResult =
  Apollo.MutationResult<CollaborativeCareReactivatePreviousEnrollmentMutation>;
export type CollaborativeCareReactivatePreviousEnrollmentMutationOptions = Apollo.BaseMutationOptions<
  CollaborativeCareReactivatePreviousEnrollmentMutation,
  CollaborativeCareReactivatePreviousEnrollmentMutationVariables
>;
export const RemoveConsultMeetingAttendeeDocument = gql`
  mutation RemoveConsultMeetingAttendee($input: RemoveConsultMeetingAttendeeInput!) {
    collaborativeCareRemoveConsultMeetingAttendee(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        consultMeeting {
          attendees {
            nodes {
              id
              provider {
                name
              }
            }
          }
        }
      }
    }
  }
`;
export type RemoveConsultMeetingAttendeeMutationFn = Apollo.MutationFunction<
  RemoveConsultMeetingAttendeeMutation,
  RemoveConsultMeetingAttendeeMutationVariables
>;

/**
 * __useRemoveConsultMeetingAttendeeMutation__
 *
 * To run a mutation, you first call `useRemoveConsultMeetingAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveConsultMeetingAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeConsultMeetingAttendeeMutation, { data, loading, error }] = useRemoveConsultMeetingAttendeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveConsultMeetingAttendeeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveConsultMeetingAttendeeMutation,
    RemoveConsultMeetingAttendeeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveConsultMeetingAttendeeMutation,
    RemoveConsultMeetingAttendeeMutationVariables
  >(RemoveConsultMeetingAttendeeDocument, options);
}
export type RemoveConsultMeetingAttendeeMutationHookResult = ReturnType<
  typeof useRemoveConsultMeetingAttendeeMutation
>;
export type RemoveConsultMeetingAttendeeMutationResult =
  Apollo.MutationResult<RemoveConsultMeetingAttendeeMutation>;
export type RemoveConsultMeetingAttendeeMutationOptions = Apollo.BaseMutationOptions<
  RemoveConsultMeetingAttendeeMutation,
  RemoveConsultMeetingAttendeeMutationVariables
>;
export const RequestCaseConsultDocument = gql`
  mutation RequestCaseConsult($input: RequestCaseConsultInput!) {
    collaborativeCareRequestCaseConsult(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        consultRequest {
          id
        }
      }
    }
  }
`;
export type RequestCaseConsultMutationFn = Apollo.MutationFunction<
  RequestCaseConsultMutation,
  RequestCaseConsultMutationVariables
>;

/**
 * __useRequestCaseConsultMutation__
 *
 * To run a mutation, you first call `useRequestCaseConsultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestCaseConsultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestCaseConsultMutation, { data, loading, error }] = useRequestCaseConsultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestCaseConsultMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestCaseConsultMutation, RequestCaseConsultMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestCaseConsultMutation, RequestCaseConsultMutationVariables>(
    RequestCaseConsultDocument,
    options
  );
}
export type RequestCaseConsultMutationHookResult = ReturnType<typeof useRequestCaseConsultMutation>;
export type RequestCaseConsultMutationResult = Apollo.MutationResult<RequestCaseConsultMutation>;
export type RequestCaseConsultMutationOptions = Apollo.BaseMutationOptions<
  RequestCaseConsultMutation,
  RequestCaseConsultMutationVariables
>;
export const SaveConsultReviewNotesDocument = gql`
  mutation SaveConsultReviewNotes($input: SaveConsultReviewNoteInput!) {
    collaborativeCareSaveConsultReviewNotes(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        caseConsult {
          id
          caseSummary
          consultationDocumentation
          consultationNotes
        }
      }
    }
  }
`;
export type SaveConsultReviewNotesMutationFn = Apollo.MutationFunction<
  SaveConsultReviewNotesMutation,
  SaveConsultReviewNotesMutationVariables
>;

/**
 * __useSaveConsultReviewNotesMutation__
 *
 * To run a mutation, you first call `useSaveConsultReviewNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveConsultReviewNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveConsultReviewNotesMutation, { data, loading, error }] = useSaveConsultReviewNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveConsultReviewNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveConsultReviewNotesMutation,
    SaveConsultReviewNotesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveConsultReviewNotesMutation, SaveConsultReviewNotesMutationVariables>(
    SaveConsultReviewNotesDocument,
    options
  );
}
export type SaveConsultReviewNotesMutationHookResult = ReturnType<typeof useSaveConsultReviewNotesMutation>;
export type SaveConsultReviewNotesMutationResult = Apollo.MutationResult<SaveConsultReviewNotesMutation>;
export type SaveConsultReviewNotesMutationOptions = Apollo.BaseMutationOptions<
  SaveConsultReviewNotesMutation,
  SaveConsultReviewNotesMutationVariables
>;
export const StartCaseSummaryTimeEntryDocument = gql`
  mutation StartCaseSummaryTimeEntry($input: StartCaseSummaryTimeEntryInput!) {
    collaborativeCareStartCaseSummaryTimeEntry(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        myActiveTimeEntryLog {
          timeEntryLog {
            id
          }
        }
      }
    }
  }
`;
export type StartCaseSummaryTimeEntryMutationFn = Apollo.MutationFunction<
  StartCaseSummaryTimeEntryMutation,
  StartCaseSummaryTimeEntryMutationVariables
>;

/**
 * __useStartCaseSummaryTimeEntryMutation__
 *
 * To run a mutation, you first call `useStartCaseSummaryTimeEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCaseSummaryTimeEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCaseSummaryTimeEntryMutation, { data, loading, error }] = useStartCaseSummaryTimeEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartCaseSummaryTimeEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartCaseSummaryTimeEntryMutation,
    StartCaseSummaryTimeEntryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartCaseSummaryTimeEntryMutation, StartCaseSummaryTimeEntryMutationVariables>(
    StartCaseSummaryTimeEntryDocument,
    options
  );
}
export type StartCaseSummaryTimeEntryMutationHookResult = ReturnType<
  typeof useStartCaseSummaryTimeEntryMutation
>;
export type StartCaseSummaryTimeEntryMutationResult =
  Apollo.MutationResult<StartCaseSummaryTimeEntryMutation>;
export type StartCaseSummaryTimeEntryMutationOptions = Apollo.BaseMutationOptions<
  StartCaseSummaryTimeEntryMutation,
  StartCaseSummaryTimeEntryMutationVariables
>;
export const StartConsultMeetingDocument = gql`
  mutation StartConsultMeeting($input: StartConsultMeetingInput!) {
    collaborativeCareStartConsultMeeting(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        consultMeeting {
          id
          startTime
          status
          attendees {
            nodes {
              id
              role
              provider {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
export type StartConsultMeetingMutationFn = Apollo.MutationFunction<
  StartConsultMeetingMutation,
  StartConsultMeetingMutationVariables
>;

/**
 * __useStartConsultMeetingMutation__
 *
 * To run a mutation, you first call `useStartConsultMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartConsultMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startConsultMeetingMutation, { data, loading, error }] = useStartConsultMeetingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartConsultMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<StartConsultMeetingMutation, StartConsultMeetingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartConsultMeetingMutation, StartConsultMeetingMutationVariables>(
    StartConsultMeetingDocument,
    options
  );
}
export type StartConsultMeetingMutationHookResult = ReturnType<typeof useStartConsultMeetingMutation>;
export type StartConsultMeetingMutationResult = Apollo.MutationResult<StartConsultMeetingMutation>;
export type StartConsultMeetingMutationOptions = Apollo.BaseMutationOptions<
  StartConsultMeetingMutation,
  StartConsultMeetingMutationVariables
>;
export const StartCaseConsultReviewDocument = gql`
  mutation StartCaseConsultReview($input: StartCaseConsultReviewInput!) {
    collaborativeCareStartCaseConsultReview(input: $input) {
      success
      errors {
        path
        message
      }
      result {
        caseConsult {
          id
          caseSummaryTask {
            id
          }
          consultMeetingTask {
            id
          }
          documentationTask {
            id
          }
        }
        myActiveTimeEntryLog {
          timeEntryLog {
            id
            clientStartTime
            startTime
            durationSeconds
          }
        }
      }
    }
  }
`;
export type StartCaseConsultReviewMutationFn = Apollo.MutationFunction<
  StartCaseConsultReviewMutation,
  StartCaseConsultReviewMutationVariables
>;

/**
 * __useStartCaseConsultReviewMutation__
 *
 * To run a mutation, you first call `useStartCaseConsultReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCaseConsultReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCaseConsultReviewMutation, { data, loading, error }] = useStartCaseConsultReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartCaseConsultReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartCaseConsultReviewMutation,
    StartCaseConsultReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartCaseConsultReviewMutation, StartCaseConsultReviewMutationVariables>(
    StartCaseConsultReviewDocument,
    options
  );
}
export type StartCaseConsultReviewMutationHookResult = ReturnType<typeof useStartCaseConsultReviewMutation>;
export type StartCaseConsultReviewMutationResult = Apollo.MutationResult<StartCaseConsultReviewMutation>;
export type StartCaseConsultReviewMutationOptions = Apollo.BaseMutationOptions<
  StartCaseConsultReviewMutation,
  StartCaseConsultReviewMutationVariables
>;
export const ActiveEnrollmentsDocument = gql`
  query ActiveEnrollments(
    $sortBy: EnrollmentSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    collaborativeCareEnrollments(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      status: ENROLLED
    ) {
      nodes {
        id
        status
        careEpisode {
          id
          patient {
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useActiveEnrollmentsQuery__
 *
 * To run a query within a React component, call `useActiveEnrollmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveEnrollmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveEnrollmentsQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useActiveEnrollmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<ActiveEnrollmentsQuery, ActiveEnrollmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveEnrollmentsQuery, ActiveEnrollmentsQueryVariables>(
    ActiveEnrollmentsDocument,
    options
  );
}
export function useActiveEnrollmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveEnrollmentsQuery, ActiveEnrollmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveEnrollmentsQuery, ActiveEnrollmentsQueryVariables>(
    ActiveEnrollmentsDocument,
    options
  );
}
export type ActiveEnrollmentsQueryHookResult = ReturnType<typeof useActiveEnrollmentsQuery>;
export type ActiveEnrollmentsLazyQueryHookResult = ReturnType<typeof useActiveEnrollmentsLazyQuery>;
export type ActiveEnrollmentsQueryResult = Apollo.QueryResult<
  ActiveEnrollmentsQuery,
  ActiveEnrollmentsQueryVariables
>;
export const AddNoteToTaskDocument = gql`
  mutation AddNoteToTask($input: AddNoteToTaskInput!) {
    collaborativeCareAddNoteToTask(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        note {
          id
          text
          author {
            id
            name
          }
          task {
            id
            notes(first: 1000) {
              nodes {
                id
              }
            }
          }
          createdAt
        }
      }
    }
  }
`;
export type AddNoteToTaskMutationFn = Apollo.MutationFunction<
  AddNoteToTaskMutation,
  AddNoteToTaskMutationVariables
>;

/**
 * __useAddNoteToTaskMutation__
 *
 * To run a mutation, you first call `useAddNoteToTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteToTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteToTaskMutation, { data, loading, error }] = useAddNoteToTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNoteToTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<AddNoteToTaskMutation, AddNoteToTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddNoteToTaskMutation, AddNoteToTaskMutationVariables>(
    AddNoteToTaskDocument,
    options
  );
}
export type AddNoteToTaskMutationHookResult = ReturnType<typeof useAddNoteToTaskMutation>;
export type AddNoteToTaskMutationResult = Apollo.MutationResult<AddNoteToTaskMutation>;
export type AddNoteToTaskMutationOptions = Apollo.BaseMutationOptions<
  AddNoteToTaskMutation,
  AddNoteToTaskMutationVariables
>;
export const AllTreatmentTargetsDocument = gql`
  query AllTreatmentTargets {
    collaborativeCareTreatmentTargets {
      nodes {
        id
        name
        measurementPlan {
          id
          measurementPlanScales {
            id
            scale {
              id
              name
              shortname
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAllTreatmentTargetsQuery__
 *
 * To run a query within a React component, call `useAllTreatmentTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTreatmentTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTreatmentTargetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTreatmentTargetsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllTreatmentTargetsQuery, AllTreatmentTargetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllTreatmentTargetsQuery, AllTreatmentTargetsQueryVariables>(
    AllTreatmentTargetsDocument,
    options
  );
}
export function useAllTreatmentTargetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllTreatmentTargetsQuery, AllTreatmentTargetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllTreatmentTargetsQuery, AllTreatmentTargetsQueryVariables>(
    AllTreatmentTargetsDocument,
    options
  );
}
export type AllTreatmentTargetsQueryHookResult = ReturnType<typeof useAllTreatmentTargetsQuery>;
export type AllTreatmentTargetsLazyQueryHookResult = ReturnType<typeof useAllTreatmentTargetsLazyQuery>;
export type AllTreatmentTargetsQueryResult = Apollo.QueryResult<
  AllTreatmentTargetsQuery,
  AllTreatmentTargetsQueryVariables
>;
export const CaseloadEfficiencyStatsDocument = gql`
  query CaseloadEfficiencyStats(
    $careManagerIds: [ProviderId!]!
    $instituteId: InstituteId
    $startDate: ISO8601Date
    $endDate: ISO8601Date
  ) {
    collaborativeCareCaseloadEfficiencyStats(
      careManagerIds: $careManagerIds
      instituteId: $instituteId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      value
    }
  }
`;

/**
 * __useCaseloadEfficiencyStatsQuery__
 *
 * To run a query within a React component, call `useCaseloadEfficiencyStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaseloadEfficiencyStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCaseloadEfficiencyStatsQuery({
 *   variables: {
 *      careManagerIds: // value for 'careManagerIds'
 *      instituteId: // value for 'instituteId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useCaseloadEfficiencyStatsQuery(
  baseOptions: Apollo.QueryHookOptions<CaseloadEfficiencyStatsQuery, CaseloadEfficiencyStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CaseloadEfficiencyStatsQuery, CaseloadEfficiencyStatsQueryVariables>(
    CaseloadEfficiencyStatsDocument,
    options
  );
}
export function useCaseloadEfficiencyStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CaseloadEfficiencyStatsQuery,
    CaseloadEfficiencyStatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CaseloadEfficiencyStatsQuery, CaseloadEfficiencyStatsQueryVariables>(
    CaseloadEfficiencyStatsDocument,
    options
  );
}
export type CaseloadEfficiencyStatsQueryHookResult = ReturnType<typeof useCaseloadEfficiencyStatsQuery>;
export type CaseloadEfficiencyStatsLazyQueryHookResult = ReturnType<
  typeof useCaseloadEfficiencyStatsLazyQuery
>;
export type CaseloadEfficiencyStatsQueryResult = Apollo.QueryResult<
  CaseloadEfficiencyStatsQuery,
  CaseloadEfficiencyStatsQueryVariables
>;
export const CreateTaskDocument = gql`
  mutation CreateTask($input: CreateTaskInput!) {
    collaborativeCareCreateTask(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        task {
          id
          title
          body
          dueAt
          patient {
            id
            name
          }
          assignedTo {
            id
            name
          }
        }
      }
    }
  }
`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
}
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>;
export const CreateTaskTemplateDocument = gql`
  mutation CreateTaskTemplate($input: CreateTaskTemplateInput!) {
    collaborativeCareCreateTaskTemplate(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        taskTemplate {
          id
          title
          body
        }
      }
    }
  }
`;
export type CreateTaskTemplateMutationFn = Apollo.MutationFunction<
  CreateTaskTemplateMutation,
  CreateTaskTemplateMutationVariables
>;

/**
 * __useCreateTaskTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTaskTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskTemplateMutation, { data, loading, error }] = useCreateTaskTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTaskTemplateMutation, CreateTaskTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTaskTemplateMutation, CreateTaskTemplateMutationVariables>(
    CreateTaskTemplateDocument,
    options
  );
}
export type CreateTaskTemplateMutationHookResult = ReturnType<typeof useCreateTaskTemplateMutation>;
export type CreateTaskTemplateMutationResult = Apollo.MutationResult<CreateTaskTemplateMutation>;
export type CreateTaskTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskTemplateMutation,
  CreateTaskTemplateMutationVariables
>;
export const DeleteTaskNoteDocument = gql`
  mutation DeleteTaskNote($input: DeleteNoteInput!) {
    collaborativeCareDeleteNote(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        institute {
          id
        }
      }
    }
  }
`;
export type DeleteTaskNoteMutationFn = Apollo.MutationFunction<
  DeleteTaskNoteMutation,
  DeleteTaskNoteMutationVariables
>;

/**
 * __useDeleteTaskNoteMutation__
 *
 * To run a mutation, you first call `useDeleteTaskNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskNoteMutation, { data, loading, error }] = useDeleteTaskNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTaskNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTaskNoteMutation, DeleteTaskNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskNoteMutation, DeleteTaskNoteMutationVariables>(
    DeleteTaskNoteDocument,
    options
  );
}
export type DeleteTaskNoteMutationHookResult = ReturnType<typeof useDeleteTaskNoteMutation>;
export type DeleteTaskNoteMutationResult = Apollo.MutationResult<DeleteTaskNoteMutation>;
export type DeleteTaskNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskNoteMutation,
  DeleteTaskNoteMutationVariables
>;
export const DeleteTaskTemplateDocument = gql`
  mutation DeleteTaskTemplate($input: DeleteTaskTemplateInput!) {
    collaborativeCareDeleteTaskTemplate(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        success
      }
    }
  }
`;
export type DeleteTaskTemplateMutationFn = Apollo.MutationFunction<
  DeleteTaskTemplateMutation,
  DeleteTaskTemplateMutationVariables
>;

/**
 * __useDeleteTaskTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTaskTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskTemplateMutation, { data, loading, error }] = useDeleteTaskTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTaskTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTaskTemplateMutation, DeleteTaskTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskTemplateMutation, DeleteTaskTemplateMutationVariables>(
    DeleteTaskTemplateDocument,
    options
  );
}
export type DeleteTaskTemplateMutationHookResult = ReturnType<typeof useDeleteTaskTemplateMutation>;
export type DeleteTaskTemplateMutationResult = Apollo.MutationResult<DeleteTaskTemplateMutation>;
export type DeleteTaskTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskTemplateMutation,
  DeleteTaskTemplateMutationVariables
>;
export const DownloadEnrollmentMonthSummaryPdfDocument = gql`
  query DownloadEnrollmentMonthSummaryPdf($enrollmentMonthId: EnrollmentMonthId!) {
    collaborativeCareEnrollmentMonth(id: $enrollmentMonthId) {
      id
      monthlySummaryPdfContentBase64
    }
  }
`;

/**
 * __useDownloadEnrollmentMonthSummaryPdfQuery__
 *
 * To run a query within a React component, call `useDownloadEnrollmentMonthSummaryPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadEnrollmentMonthSummaryPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadEnrollmentMonthSummaryPdfQuery({
 *   variables: {
 *      enrollmentMonthId: // value for 'enrollmentMonthId'
 *   },
 * });
 */
export function useDownloadEnrollmentMonthSummaryPdfQuery(
  baseOptions: Apollo.QueryHookOptions<
    DownloadEnrollmentMonthSummaryPdfQuery,
    DownloadEnrollmentMonthSummaryPdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DownloadEnrollmentMonthSummaryPdfQuery,
    DownloadEnrollmentMonthSummaryPdfQueryVariables
  >(DownloadEnrollmentMonthSummaryPdfDocument, options);
}
export function useDownloadEnrollmentMonthSummaryPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadEnrollmentMonthSummaryPdfQuery,
    DownloadEnrollmentMonthSummaryPdfQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DownloadEnrollmentMonthSummaryPdfQuery,
    DownloadEnrollmentMonthSummaryPdfQueryVariables
  >(DownloadEnrollmentMonthSummaryPdfDocument, options);
}
export type DownloadEnrollmentMonthSummaryPdfQueryHookResult = ReturnType<
  typeof useDownloadEnrollmentMonthSummaryPdfQuery
>;
export type DownloadEnrollmentMonthSummaryPdfLazyQueryHookResult = ReturnType<
  typeof useDownloadEnrollmentMonthSummaryPdfLazyQuery
>;
export type DownloadEnrollmentMonthSummaryPdfQueryResult = Apollo.QueryResult<
  DownloadEnrollmentMonthSummaryPdfQuery,
  DownloadEnrollmentMonthSummaryPdfQueryVariables
>;
export const EditTaskNoteDocument = gql`
  mutation EditTaskNote($input: EditNoteInput!) {
    collaborativeCareEditNote(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        note {
          id
          text
          author {
            id
            name
          }
        }
      }
    }
  }
`;
export type EditTaskNoteMutationFn = Apollo.MutationFunction<
  EditTaskNoteMutation,
  EditTaskNoteMutationVariables
>;

/**
 * __useEditTaskNoteMutation__
 *
 * To run a mutation, you first call `useEditTaskNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskNoteMutation, { data, loading, error }] = useEditTaskNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTaskNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<EditTaskNoteMutation, EditTaskNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditTaskNoteMutation, EditTaskNoteMutationVariables>(
    EditTaskNoteDocument,
    options
  );
}
export type EditTaskNoteMutationHookResult = ReturnType<typeof useEditTaskNoteMutation>;
export type EditTaskNoteMutationResult = Apollo.MutationResult<EditTaskNoteMutation>;
export type EditTaskNoteMutationOptions = Apollo.BaseMutationOptions<
  EditTaskNoteMutation,
  EditTaskNoteMutationVariables
>;
export const MinutesTargetsDocument = gql`
  query MinutesTargets {
    collaborativeCarePatientMinutesTargets {
      nodes {
        id
        floor
        ceiling
        enrollmentMonthStart
        enrollmentMonthEnd
      }
    }
    collaborativeCareDefaultPatientMinutesTargets {
      id
      floor
      ceiling
      enrollmentMonthStart
      enrollmentMonthEnd
    }
  }
`;

/**
 * __useMinutesTargetsQuery__
 *
 * To run a query within a React component, call `useMinutesTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMinutesTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMinutesTargetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMinutesTargetsQuery(
  baseOptions?: Apollo.QueryHookOptions<MinutesTargetsQuery, MinutesTargetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MinutesTargetsQuery, MinutesTargetsQueryVariables>(MinutesTargetsDocument, options);
}
export function useMinutesTargetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MinutesTargetsQuery, MinutesTargetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MinutesTargetsQuery, MinutesTargetsQueryVariables>(
    MinutesTargetsDocument,
    options
  );
}
export type MinutesTargetsQueryHookResult = ReturnType<typeof useMinutesTargetsQuery>;
export type MinutesTargetsLazyQueryHookResult = ReturnType<typeof useMinutesTargetsLazyQuery>;
export type MinutesTargetsQueryResult = Apollo.QueryResult<MinutesTargetsQuery, MinutesTargetsQueryVariables>;
export const PatientBillableMinutesDocument = gql`
  query PatientBillableMinutes($monthAndYear: MonthParams!, $patientId: PatientId!) {
    collaborativeCareEnrollmentMonths(monthAndYear: $monthAndYear, patientId: $patientId) {
      nodes {
        id
        billableMinutes
        minutesTargetFloor
        minutesTargetCeiling
        minutesTargetStatus
      }
    }
  }
`;

/**
 * __usePatientBillableMinutesQuery__
 *
 * To run a query within a React component, call `usePatientBillableMinutesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientBillableMinutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientBillableMinutesQuery({
 *   variables: {
 *      monthAndYear: // value for 'monthAndYear'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientBillableMinutesQuery(
  baseOptions: Apollo.QueryHookOptions<PatientBillableMinutesQuery, PatientBillableMinutesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientBillableMinutesQuery, PatientBillableMinutesQueryVariables>(
    PatientBillableMinutesDocument,
    options
  );
}
export function usePatientBillableMinutesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientBillableMinutesQuery, PatientBillableMinutesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientBillableMinutesQuery, PatientBillableMinutesQueryVariables>(
    PatientBillableMinutesDocument,
    options
  );
}
export type PatientBillableMinutesQueryHookResult = ReturnType<typeof usePatientBillableMinutesQuery>;
export type PatientBillableMinutesLazyQueryHookResult = ReturnType<typeof usePatientBillableMinutesLazyQuery>;
export type PatientBillableMinutesQueryResult = Apollo.QueryResult<
  PatientBillableMinutesQuery,
  PatientBillableMinutesQueryVariables
>;
export const PatientMonthlyBillableMinutesDocument = gql`
  query PatientMonthlyBillableMinutes($year: Int!, $month: Int!, $patientId: PatientId) {
    collaborativeCareEnrollmentBillingMonth(year: $year, month: $month, patientId: $patientId) {
      billableMinutes
    }
  }
`;

/**
 * __usePatientMonthlyBillableMinutesQuery__
 *
 * To run a query within a React component, call `usePatientMonthlyBillableMinutesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientMonthlyBillableMinutesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientMonthlyBillableMinutesQuery({
 *   variables: {
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientMonthlyBillableMinutesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PatientMonthlyBillableMinutesQuery,
    PatientMonthlyBillableMinutesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientMonthlyBillableMinutesQuery, PatientMonthlyBillableMinutesQueryVariables>(
    PatientMonthlyBillableMinutesDocument,
    options
  );
}
export function usePatientMonthlyBillableMinutesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientMonthlyBillableMinutesQuery,
    PatientMonthlyBillableMinutesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientMonthlyBillableMinutesQuery, PatientMonthlyBillableMinutesQueryVariables>(
    PatientMonthlyBillableMinutesDocument,
    options
  );
}
export type PatientMonthlyBillableMinutesQueryHookResult = ReturnType<
  typeof usePatientMonthlyBillableMinutesQuery
>;
export type PatientMonthlyBillableMinutesLazyQueryHookResult = ReturnType<
  typeof usePatientMonthlyBillableMinutesLazyQuery
>;
export type PatientMonthlyBillableMinutesQueryResult = Apollo.QueryResult<
  PatientMonthlyBillableMinutesQuery,
  PatientMonthlyBillableMinutesQueryVariables
>;
export const PatientMonthlyBillingReportDocument = gql`
  query PatientMonthlyBillingReport(
    $monthAndYear: MonthParams!
    $testPatient: Boolean
    $minimumBillableMinutes: Int
  ) {
    collaborativeCareEnrollmentMonths(
      monthAndYear: $monthAndYear
      testPatient: $testPatient
      minimumBillableMinutes: $minimumBillableMinutes
    ) {
      nodes {
        id
        billableMinutes
        beginningOfMonth
        validatedMeasureCompleted
        enrollment {
          id
          enrolledAt
          careEpisode {
            id
            patient {
              id
              name
              firstName
              lastName
              legalFirstName
              legalLastName
              mrn
              dob
              isTest
            }
            careEpisodeProviders {
              id
              relationship
              provider {
                id
                name
                firstName
                lastName
                npiNumber
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __usePatientMonthlyBillingReportQuery__
 *
 * To run a query within a React component, call `usePatientMonthlyBillingReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientMonthlyBillingReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientMonthlyBillingReportQuery({
 *   variables: {
 *      monthAndYear: // value for 'monthAndYear'
 *      testPatient: // value for 'testPatient'
 *      minimumBillableMinutes: // value for 'minimumBillableMinutes'
 *   },
 * });
 */
export function usePatientMonthlyBillingReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    PatientMonthlyBillingReportQuery,
    PatientMonthlyBillingReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientMonthlyBillingReportQuery, PatientMonthlyBillingReportQueryVariables>(
    PatientMonthlyBillingReportDocument,
    options
  );
}
export function usePatientMonthlyBillingReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientMonthlyBillingReportQuery,
    PatientMonthlyBillingReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientMonthlyBillingReportQuery, PatientMonthlyBillingReportQueryVariables>(
    PatientMonthlyBillingReportDocument,
    options
  );
}
export type PatientMonthlyBillingReportQueryHookResult = ReturnType<
  typeof usePatientMonthlyBillingReportQuery
>;
export type PatientMonthlyBillingReportLazyQueryHookResult = ReturnType<
  typeof usePatientMonthlyBillingReportLazyQuery
>;
export type PatientMonthlyBillingReportQueryResult = Apollo.QueryResult<
  PatientMonthlyBillingReportQuery,
  PatientMonthlyBillingReportQueryVariables
>;
export const QuickTrackDocument = gql`
  mutation QuickTrack($input: QuickTrackUnknownTaskInput!) {
    collaborativeCareQuickTrackUnknownTask(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        myActiveTimeEntryLog {
          timeEntryLog {
            id
            clientStartTime
            startTime
            durationSeconds
          }
        }
      }
    }
  }
`;
export type QuickTrackMutationFn = Apollo.MutationFunction<QuickTrackMutation, QuickTrackMutationVariables>;

/**
 * __useQuickTrackMutation__
 *
 * To run a mutation, you first call `useQuickTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuickTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quickTrackMutation, { data, loading, error }] = useQuickTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQuickTrackMutation(
  baseOptions?: Apollo.MutationHookOptions<QuickTrackMutation, QuickTrackMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<QuickTrackMutation, QuickTrackMutationVariables>(QuickTrackDocument, options);
}
export type QuickTrackMutationHookResult = ReturnType<typeof useQuickTrackMutation>;
export type QuickTrackMutationResult = Apollo.MutationResult<QuickTrackMutation>;
export type QuickTrackMutationOptions = Apollo.BaseMutationOptions<
  QuickTrackMutation,
  QuickTrackMutationVariables
>;
export const SetTaskStatusDocument = gql`
  mutation SetTaskStatus($input: SetTaskStatusInput!) {
    collaborativeCareSetTaskStatus(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        task {
          id
          status
          notes {
            nodes {
              id
              text
            }
          }
        }
      }
    }
  }
`;
export type SetTaskStatusMutationFn = Apollo.MutationFunction<
  SetTaskStatusMutation,
  SetTaskStatusMutationVariables
>;

/**
 * __useSetTaskStatusMutation__
 *
 * To run a mutation, you first call `useSetTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTaskStatusMutation, { data, loading, error }] = useSetTaskStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<SetTaskStatusMutation, SetTaskStatusMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetTaskStatusMutation, SetTaskStatusMutationVariables>(
    SetTaskStatusDocument,
    options
  );
}
export type SetTaskStatusMutationHookResult = ReturnType<typeof useSetTaskStatusMutation>;
export type SetTaskStatusMutationResult = Apollo.MutationResult<SetTaskStatusMutation>;
export type SetTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  SetTaskStatusMutation,
  SetTaskStatusMutationVariables
>;
export const CollaborativeCareTaskDetailsDocument = gql`
  query CollaborativeCareTaskDetails($id: TaskId!) {
    collaborativeCareTask(id: $id) {
      id
      title
      body
      createdAt
      dueAt
      status
      isPlaceholder
      patient {
        id
        name
        isTest
      }
      assignedTo {
        id
        name
      }
      notes(first: 1000) {
        nodes {
          id
          text
          author {
            id
            name
          }
          timeEntry {
            startTime
          }
          createdAt
        }
      }
      timeEntries(first: 1000) {
        nodes {
          id
          startTime
          endTime
          durationSeconds
          createdAt
          provider {
            id
            name
          }
        }
      }
      creationLog {
        id
        creationType
        patientSession {
          id
          targetDate
          patient {
            id
            name
            isTest
          }
        }
        requestedBy {
          id
          name
        }
        assignedBy {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useCollaborativeCareTaskDetailsQuery__
 *
 * To run a query within a React component, call `useCollaborativeCareTaskDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollaborativeCareTaskDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollaborativeCareTaskDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCollaborativeCareTaskDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CollaborativeCareTaskDetailsQuery,
    CollaborativeCareTaskDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CollaborativeCareTaskDetailsQuery, CollaborativeCareTaskDetailsQueryVariables>(
    CollaborativeCareTaskDetailsDocument,
    options
  );
}
export function useCollaborativeCareTaskDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollaborativeCareTaskDetailsQuery,
    CollaborativeCareTaskDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CollaborativeCareTaskDetailsQuery, CollaborativeCareTaskDetailsQueryVariables>(
    CollaborativeCareTaskDetailsDocument,
    options
  );
}
export type CollaborativeCareTaskDetailsQueryHookResult = ReturnType<
  typeof useCollaborativeCareTaskDetailsQuery
>;
export type CollaborativeCareTaskDetailsLazyQueryHookResult = ReturnType<
  typeof useCollaborativeCareTaskDetailsLazyQuery
>;
export type CollaborativeCareTaskDetailsQueryResult = Apollo.QueryResult<
  CollaborativeCareTaskDetailsQuery,
  CollaborativeCareTaskDetailsQueryVariables
>;
export const TaskTemplatesDocument = gql`
  query TaskTemplates {
    collaborativeCareTaskTemplates {
      nodes {
        id
        title
        body
      }
    }
  }
`;

/**
 * __useTaskTemplatesQuery__
 *
 * To run a query within a React component, call `useTaskTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaskTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<TaskTemplatesQuery, TaskTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskTemplatesQuery, TaskTemplatesQueryVariables>(TaskTemplatesDocument, options);
}
export function useTaskTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskTemplatesQuery, TaskTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskTemplatesQuery, TaskTemplatesQueryVariables>(TaskTemplatesDocument, options);
}
export type TaskTemplatesQueryHookResult = ReturnType<typeof useTaskTemplatesQuery>;
export type TaskTemplatesLazyQueryHookResult = ReturnType<typeof useTaskTemplatesLazyQuery>;
export type TaskTemplatesQueryResult = Apollo.QueryResult<TaskTemplatesQuery, TaskTemplatesQueryVariables>;
export const UpdateTaskDocument = gql`
  mutation UpdateTask($input: UpdateTaskInput!) {
    collaborativeCareUpdateTask(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        task {
          id
          title
          body
          dueAt
          patient {
            id
            name
          }
          assignedTo {
            id
            name
          }
        }
      }
    }
  }
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
}
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>;
export const UpdateTaskTemplateDocument = gql`
  mutation UpdateTaskTemplate($input: UpdateTaskTemplateInput!) {
    collaborativeCareUpdateTaskTemplate(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        taskTemplate {
          id
          title
          body
        }
      }
    }
  }
`;
export type UpdateTaskTemplateMutationFn = Apollo.MutationFunction<
  UpdateTaskTemplateMutation,
  UpdateTaskTemplateMutationVariables
>;

/**
 * __useUpdateTaskTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTaskTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskTemplateMutation, { data, loading, error }] = useUpdateTaskTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTaskTemplateMutation, UpdateTaskTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskTemplateMutation, UpdateTaskTemplateMutationVariables>(
    UpdateTaskTemplateDocument,
    options
  );
}
export type UpdateTaskTemplateMutationHookResult = ReturnType<typeof useUpdateTaskTemplateMutation>;
export type UpdateTaskTemplateMutationResult = Apollo.MutationResult<UpdateTaskTemplateMutation>;
export type UpdateTaskTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskTemplateMutation,
  UpdateTaskTemplateMutationVariables
>;
export const DischargeEnrollmentDocument = gql`
  mutation DischargeEnrollment($input: DischargeEnrollmentInput!) {
    collaborativeCareDischargeEnrollment(input: $input) {
      success
      errors {
        path
        message
      }
      result {
        enrollment {
          id
          status
          careEpisode {
            id
            patient {
              id
              activeCollaborativeCareCareEpisode {
                id
              }
            }
          }
        }
      }
    }
  }
`;
export type DischargeEnrollmentMutationFn = Apollo.MutationFunction<
  DischargeEnrollmentMutation,
  DischargeEnrollmentMutationVariables
>;

/**
 * __useDischargeEnrollmentMutation__
 *
 * To run a mutation, you first call `useDischargeEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDischargeEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dischargeEnrollmentMutation, { data, loading, error }] = useDischargeEnrollmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDischargeEnrollmentMutation(
  baseOptions?: Apollo.MutationHookOptions<DischargeEnrollmentMutation, DischargeEnrollmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DischargeEnrollmentMutation, DischargeEnrollmentMutationVariables>(
    DischargeEnrollmentDocument,
    options
  );
}
export type DischargeEnrollmentMutationHookResult = ReturnType<typeof useDischargeEnrollmentMutation>;
export type DischargeEnrollmentMutationResult = Apollo.MutationResult<DischargeEnrollmentMutation>;
export type DischargeEnrollmentMutationOptions = Apollo.BaseMutationOptions<
  DischargeEnrollmentMutation,
  DischargeEnrollmentMutationVariables
>;
export const EnrollPatientDocument = gql`
  mutation EnrollPatient($input: EnrollPatientInput!) {
    collaborativeCareEnrollPatient(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        enrollment {
          id
          status
          intakeStatus
          careEpisode {
            id
            patient {
              id
              activeCollaborativeCareCareEpisode {
                id
              }
            }
          }
          intakeSessionScales {
            id
            administrationChoice
            status
            scale {
              id
              name
              shortname
            }
          }
        }
        tasks {
          id
        }
        myActiveTimeEntryLog {
          timeEntryLog {
            id
          }
        }
      }
    }
  }
`;
export type EnrollPatientMutationFn = Apollo.MutationFunction<
  EnrollPatientMutation,
  EnrollPatientMutationVariables
>;

/**
 * __useEnrollPatientMutation__
 *
 * To run a mutation, you first call `useEnrollPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollPatientMutation, { data, loading, error }] = useEnrollPatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<EnrollPatientMutation, EnrollPatientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnrollPatientMutation, EnrollPatientMutationVariables>(
    EnrollPatientDocument,
    options
  );
}
export type EnrollPatientMutationHookResult = ReturnType<typeof useEnrollPatientMutation>;
export type EnrollPatientMutationResult = Apollo.MutationResult<EnrollPatientMutation>;
export type EnrollPatientMutationOptions = Apollo.BaseMutationOptions<
  EnrollPatientMutation,
  EnrollPatientMutationVariables
>;
export const EnrollmentDocument = gql`
  query Enrollment($enrollmentId: EnrollmentId!, $patientId: PatientId!) {
    collaborativeCareEnrollment(id: $enrollmentId) {
      id
      enrolledAt
      dischargedAt
      consentDate
      dischargedAt
      dischargeReason
      canReactivate
      careEpisode {
        id
        collaborativeCareCareEpisodeTreatmentTargets {
          treatmentTarget {
            name
          }
        }
        careEpisodeProviders {
          relationship
          provider {
            user {
              name
            }
          }
        }
      }
      panel {
        name
      }
      primaryCareProviderTeamMember {
        provider {
          user {
            name
          }
        }
      }
      careManagerTeamMember {
        provider {
          user {
            name
          }
        }
      }
    }
    patient(id: $patientId) {
      ...PatientDetailsFragment
    }
  }
  ${PatientDetailsFragmentFragmentDoc}
`;

/**
 * __useEnrollmentQuery__
 *
 * To run a query within a React component, call `useEnrollmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentQuery({
 *   variables: {
 *      enrollmentId: // value for 'enrollmentId'
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useEnrollmentQuery(
  baseOptions: Apollo.QueryHookOptions<EnrollmentQuery, EnrollmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnrollmentQuery, EnrollmentQueryVariables>(EnrollmentDocument, options);
}
export function useEnrollmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentQuery, EnrollmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EnrollmentQuery, EnrollmentQueryVariables>(EnrollmentDocument, options);
}
export type EnrollmentQueryHookResult = ReturnType<typeof useEnrollmentQuery>;
export type EnrollmentLazyQueryHookResult = ReturnType<typeof useEnrollmentLazyQuery>;
export type EnrollmentQueryResult = Apollo.QueryResult<EnrollmentQuery, EnrollmentQueryVariables>;
export const EnrollmentCheckIntakeScalesCompleteDocument = gql`
  mutation EnrollmentCheckIntakeScalesComplete($input: EnrollmentCheckIntakeScalesCompleteInput!) {
    collaborativeCareEnrollmentCheckIntakeScalesComplete(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        enrollment {
          id
          status
          intakeStatus
          intakeSessionScales {
            id
            status
          }
          intakeSession {
            id
            assessmentReport {
              id
              invitations {
                id
                status
              }
            }
          }
        }
      }
    }
  }
`;
export type EnrollmentCheckIntakeScalesCompleteMutationFn = Apollo.MutationFunction<
  EnrollmentCheckIntakeScalesCompleteMutation,
  EnrollmentCheckIntakeScalesCompleteMutationVariables
>;

/**
 * __useEnrollmentCheckIntakeScalesCompleteMutation__
 *
 * To run a mutation, you first call `useEnrollmentCheckIntakeScalesCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentCheckIntakeScalesCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollmentCheckIntakeScalesCompleteMutation, { data, loading, error }] = useEnrollmentCheckIntakeScalesCompleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnrollmentCheckIntakeScalesCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnrollmentCheckIntakeScalesCompleteMutation,
    EnrollmentCheckIntakeScalesCompleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EnrollmentCheckIntakeScalesCompleteMutation,
    EnrollmentCheckIntakeScalesCompleteMutationVariables
  >(EnrollmentCheckIntakeScalesCompleteDocument, options);
}
export type EnrollmentCheckIntakeScalesCompleteMutationHookResult = ReturnType<
  typeof useEnrollmentCheckIntakeScalesCompleteMutation
>;
export type EnrollmentCheckIntakeScalesCompleteMutationResult =
  Apollo.MutationResult<EnrollmentCheckIntakeScalesCompleteMutation>;
export type EnrollmentCheckIntakeScalesCompleteMutationOptions = Apollo.BaseMutationOptions<
  EnrollmentCheckIntakeScalesCompleteMutation,
  EnrollmentCheckIntakeScalesCompleteMutationVariables
>;
export const EnrollmentIntakeScalesDocument = gql`
  query EnrollmentIntakeScales($enrollmentId: EnrollmentId!) {
    collaborativeCareEnrollment(id: $enrollmentId) {
      id
      status
      intakeStatus
      intakeSessionScales {
        id
        administrationChoice
        status
        scale {
          id
          name
          shortname
          allowedParticipants
        }
      }
    }
  }
`;

/**
 * __useEnrollmentIntakeScalesQuery__
 *
 * To run a query within a React component, call `useEnrollmentIntakeScalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentIntakeScalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentIntakeScalesQuery({
 *   variables: {
 *      enrollmentId: // value for 'enrollmentId'
 *   },
 * });
 */
export function useEnrollmentIntakeScalesQuery(
  baseOptions: Apollo.QueryHookOptions<EnrollmentIntakeScalesQuery, EnrollmentIntakeScalesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnrollmentIntakeScalesQuery, EnrollmentIntakeScalesQueryVariables>(
    EnrollmentIntakeScalesDocument,
    options
  );
}
export function useEnrollmentIntakeScalesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentIntakeScalesQuery, EnrollmentIntakeScalesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EnrollmentIntakeScalesQuery, EnrollmentIntakeScalesQueryVariables>(
    EnrollmentIntakeScalesDocument,
    options
  );
}
export type EnrollmentIntakeScalesQueryHookResult = ReturnType<typeof useEnrollmentIntakeScalesQuery>;
export type EnrollmentIntakeScalesLazyQueryHookResult = ReturnType<typeof useEnrollmentIntakeScalesLazyQuery>;
export type EnrollmentIntakeScalesQueryResult = Apollo.QueryResult<
  EnrollmentIntakeScalesQuery,
  EnrollmentIntakeScalesQueryVariables
>;
export const EnrollmentIntakeSessionInvitationsDocument = gql`
  query EnrollmentIntakeSessionInvitations($enrollmentId: EnrollmentId!) {
    collaborativeCareEnrollment(id: $enrollmentId) {
      id
      intakeStatus
      intakeSession {
        id
        patient {
          id
          name
        }
        assessmentReport {
          id
          invitations {
            id
            format
            status
            user {
              id
              name
            }
            reportScales {
              id
              scale {
                id
                shortname
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useEnrollmentIntakeSessionInvitationsQuery__
 *
 * To run a query within a React component, call `useEnrollmentIntakeSessionInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentIntakeSessionInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentIntakeSessionInvitationsQuery({
 *   variables: {
 *      enrollmentId: // value for 'enrollmentId'
 *   },
 * });
 */
export function useEnrollmentIntakeSessionInvitationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    EnrollmentIntakeSessionInvitationsQuery,
    EnrollmentIntakeSessionInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EnrollmentIntakeSessionInvitationsQuery,
    EnrollmentIntakeSessionInvitationsQueryVariables
  >(EnrollmentIntakeSessionInvitationsDocument, options);
}
export function useEnrollmentIntakeSessionInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EnrollmentIntakeSessionInvitationsQuery,
    EnrollmentIntakeSessionInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EnrollmentIntakeSessionInvitationsQuery,
    EnrollmentIntakeSessionInvitationsQueryVariables
  >(EnrollmentIntakeSessionInvitationsDocument, options);
}
export type EnrollmentIntakeSessionInvitationsQueryHookResult = ReturnType<
  typeof useEnrollmentIntakeSessionInvitationsQuery
>;
export type EnrollmentIntakeSessionInvitationsLazyQueryHookResult = ReturnType<
  typeof useEnrollmentIntakeSessionInvitationsLazyQuery
>;
export type EnrollmentIntakeSessionInvitationsQueryResult = Apollo.QueryResult<
  EnrollmentIntakeSessionInvitationsQuery,
  EnrollmentIntakeSessionInvitationsQueryVariables
>;
export const EnrollmentTaskDocument = gql`
  query EnrollmentTask($enrollmentId: EnrollmentId!) {
    collaborativeCareEnrollment(id: $enrollmentId) {
      enrollmentTask {
        id
      }
    }
  }
`;

/**
 * __useEnrollmentTaskQuery__
 *
 * To run a query within a React component, call `useEnrollmentTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentTaskQuery({
 *   variables: {
 *      enrollmentId: // value for 'enrollmentId'
 *   },
 * });
 */
export function useEnrollmentTaskQuery(
  baseOptions: Apollo.QueryHookOptions<EnrollmentTaskQuery, EnrollmentTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnrollmentTaskQuery, EnrollmentTaskQueryVariables>(EnrollmentTaskDocument, options);
}
export function useEnrollmentTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentTaskQuery, EnrollmentTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EnrollmentTaskQuery, EnrollmentTaskQueryVariables>(
    EnrollmentTaskDocument,
    options
  );
}
export type EnrollmentTaskQueryHookResult = ReturnType<typeof useEnrollmentTaskQuery>;
export type EnrollmentTaskLazyQueryHookResult = ReturnType<typeof useEnrollmentTaskLazyQuery>;
export type EnrollmentTaskQueryResult = Apollo.QueryResult<EnrollmentTaskQuery, EnrollmentTaskQueryVariables>;
export const ScaleSummaryDocument = gql`
  query ScaleSummary($id: ScaleId!) {
    assessmentScale(id: $id) {
      id
      name
      shortname
    }
  }
`;

/**
 * __useScaleSummaryQuery__
 *
 * To run a query within a React component, call `useScaleSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useScaleSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScaleSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScaleSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<ScaleSummaryQuery, ScaleSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScaleSummaryQuery, ScaleSummaryQueryVariables>(ScaleSummaryDocument, options);
}
export function useScaleSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScaleSummaryQuery, ScaleSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScaleSummaryQuery, ScaleSummaryQueryVariables>(ScaleSummaryDocument, options);
}
export type ScaleSummaryQueryHookResult = ReturnType<typeof useScaleSummaryQuery>;
export type ScaleSummaryLazyQueryHookResult = ReturnType<typeof useScaleSummaryLazyQuery>;
export type ScaleSummaryQueryResult = Apollo.QueryResult<ScaleSummaryQuery, ScaleSummaryQueryVariables>;
export const SetIntakeScaleAdministrationChoiceDocument = gql`
  mutation SetIntakeScaleAdministrationChoice($input: SetIntakeScaleAdministrationChoiceInput!) {
    collaborativeCareSetIntakeScaleAdministrationChoice(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        enrollment {
          id
          intakeStatus
          intakeSessionScales {
            id
            administrationChoice
            status
            scale {
              id
              name
              shortname
            }
          }
        }
      }
    }
  }
`;
export type SetIntakeScaleAdministrationChoiceMutationFn = Apollo.MutationFunction<
  SetIntakeScaleAdministrationChoiceMutation,
  SetIntakeScaleAdministrationChoiceMutationVariables
>;

/**
 * __useSetIntakeScaleAdministrationChoiceMutation__
 *
 * To run a mutation, you first call `useSetIntakeScaleAdministrationChoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIntakeScaleAdministrationChoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIntakeScaleAdministrationChoiceMutation, { data, loading, error }] = useSetIntakeScaleAdministrationChoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetIntakeScaleAdministrationChoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetIntakeScaleAdministrationChoiceMutation,
    SetIntakeScaleAdministrationChoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetIntakeScaleAdministrationChoiceMutation,
    SetIntakeScaleAdministrationChoiceMutationVariables
  >(SetIntakeScaleAdministrationChoiceDocument, options);
}
export type SetIntakeScaleAdministrationChoiceMutationHookResult = ReturnType<
  typeof useSetIntakeScaleAdministrationChoiceMutation
>;
export type SetIntakeScaleAdministrationChoiceMutationResult =
  Apollo.MutationResult<SetIntakeScaleAdministrationChoiceMutation>;
export type SetIntakeScaleAdministrationChoiceMutationOptions = Apollo.BaseMutationOptions<
  SetIntakeScaleAdministrationChoiceMutation,
  SetIntakeScaleAdministrationChoiceMutationVariables
>;
export const CreatePanelDocument = gql`
  mutation CreatePanel($input: CreatePanelInput!) {
    collaborativeCareCreatePanel(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        panel {
          id
          name
          status
        }
      }
    }
  }
`;
export type CreatePanelMutationFn = Apollo.MutationFunction<
  CreatePanelMutation,
  CreatePanelMutationVariables
>;

/**
 * __useCreatePanelMutation__
 *
 * To run a mutation, you first call `useCreatePanelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePanelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPanelMutation, { data, loading, error }] = useCreatePanelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePanelMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePanelMutation, CreatePanelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePanelMutation, CreatePanelMutationVariables>(CreatePanelDocument, options);
}
export type CreatePanelMutationHookResult = ReturnType<typeof useCreatePanelMutation>;
export type CreatePanelMutationResult = Apollo.MutationResult<CreatePanelMutation>;
export type CreatePanelMutationOptions = Apollo.BaseMutationOptions<
  CreatePanelMutation,
  CreatePanelMutationVariables
>;
export const CreatePanelTeamMemberDocument = gql`
  mutation CreatePanelTeamMember($input: CreatePanelTeamMemberInput!) {
    collaborativeCareCreatePanelTeamMember(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        panelTeamMember {
          id
          function
          startDate
          endDate
          provider {
            id
            name
          }
          panel {
            id
            teamMembers {
              id
            }
          }
        }
      }
    }
  }
`;
export type CreatePanelTeamMemberMutationFn = Apollo.MutationFunction<
  CreatePanelTeamMemberMutation,
  CreatePanelTeamMemberMutationVariables
>;

/**
 * __useCreatePanelTeamMemberMutation__
 *
 * To run a mutation, you first call `useCreatePanelTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePanelTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPanelTeamMemberMutation, { data, loading, error }] = useCreatePanelTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePanelTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePanelTeamMemberMutation,
    CreatePanelTeamMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePanelTeamMemberMutation, CreatePanelTeamMemberMutationVariables>(
    CreatePanelTeamMemberDocument,
    options
  );
}
export type CreatePanelTeamMemberMutationHookResult = ReturnType<typeof useCreatePanelTeamMemberMutation>;
export type CreatePanelTeamMemberMutationResult = Apollo.MutationResult<CreatePanelTeamMemberMutation>;
export type CreatePanelTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  CreatePanelTeamMemberMutation,
  CreatePanelTeamMemberMutationVariables
>;
export const EditPanelDocument = gql`
  mutation EditPanel($input: EditPanelInput!) {
    collaborativeCareEditPanel(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        panel {
          id
          name
          status
        }
      }
    }
  }
`;
export type EditPanelMutationFn = Apollo.MutationFunction<EditPanelMutation, EditPanelMutationVariables>;

/**
 * __useEditPanelMutation__
 *
 * To run a mutation, you first call `useEditPanelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPanelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPanelMutation, { data, loading, error }] = useEditPanelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPanelMutation(
  baseOptions?: Apollo.MutationHookOptions<EditPanelMutation, EditPanelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditPanelMutation, EditPanelMutationVariables>(EditPanelDocument, options);
}
export type EditPanelMutationHookResult = ReturnType<typeof useEditPanelMutation>;
export type EditPanelMutationResult = Apollo.MutationResult<EditPanelMutation>;
export type EditPanelMutationOptions = Apollo.BaseMutationOptions<
  EditPanelMutation,
  EditPanelMutationVariables
>;
export const EditPanelTeamMemberDocument = gql`
  mutation EditPanelTeamMember($input: EditPanelTeamMemberInput!) {
    collaborativeCareEditPanelTeamMember(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        panelTeamMember {
          id
          endDate
          startDate
        }
      }
    }
  }
`;
export type EditPanelTeamMemberMutationFn = Apollo.MutationFunction<
  EditPanelTeamMemberMutation,
  EditPanelTeamMemberMutationVariables
>;

/**
 * __useEditPanelTeamMemberMutation__
 *
 * To run a mutation, you first call `useEditPanelTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPanelTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPanelTeamMemberMutation, { data, loading, error }] = useEditPanelTeamMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPanelTeamMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<EditPanelTeamMemberMutation, EditPanelTeamMemberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditPanelTeamMemberMutation, EditPanelTeamMemberMutationVariables>(
    EditPanelTeamMemberDocument,
    options
  );
}
export type EditPanelTeamMemberMutationHookResult = ReturnType<typeof useEditPanelTeamMemberMutation>;
export type EditPanelTeamMemberMutationResult = Apollo.MutationResult<EditPanelTeamMemberMutation>;
export type EditPanelTeamMemberMutationOptions = Apollo.BaseMutationOptions<
  EditPanelTeamMemberMutation,
  EditPanelTeamMemberMutationVariables
>;
export const PanelsListDocument = gql`
  query PanelsList($status: PanelStatus) {
    collaborativeCarePanels(status: $status) {
      nodes {
        id
        name
        status
        teamMembers {
          id
          function
          startDate
          endDate
          status
          provider {
            id
            name
          }
        }
      }
    }
    providers(active: true, practicing: true) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __usePanelsListQuery__
 *
 * To run a query within a React component, call `usePanelsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePanelsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePanelsListQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function usePanelsListQuery(
  baseOptions?: Apollo.QueryHookOptions<PanelsListQuery, PanelsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PanelsListQuery, PanelsListQueryVariables>(PanelsListDocument, options);
}
export function usePanelsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PanelsListQuery, PanelsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PanelsListQuery, PanelsListQueryVariables>(PanelsListDocument, options);
}
export type PanelsListQueryHookResult = ReturnType<typeof usePanelsListQuery>;
export type PanelsListLazyQueryHookResult = ReturnType<typeof usePanelsListLazyQuery>;
export type PanelsListQueryResult = Apollo.QueryResult<PanelsListQuery, PanelsListQueryVariables>;
export const ActiveEncounterDetailsDocument = gql`
  query ActiveEncounterDetails($encounterId: EncounterId!) {
    encounter(id: $encounterId) {
      id
      status
      actualPeriodStart
      actualPeriodEnd
      serviceType
      encounterProviders {
        provider {
          id
          name
        }
      }
      encounterLocations {
        location {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useActiveEncounterDetailsQuery__
 *
 * To run a query within a React component, call `useActiveEncounterDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveEncounterDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveEncounterDetailsQuery({
 *   variables: {
 *      encounterId: // value for 'encounterId'
 *   },
 * });
 */
export function useActiveEncounterDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<ActiveEncounterDetailsQuery, ActiveEncounterDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveEncounterDetailsQuery, ActiveEncounterDetailsQueryVariables>(
    ActiveEncounterDetailsDocument,
    options
  );
}
export function useActiveEncounterDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveEncounterDetailsQuery, ActiveEncounterDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveEncounterDetailsQuery, ActiveEncounterDetailsQueryVariables>(
    ActiveEncounterDetailsDocument,
    options
  );
}
export type ActiveEncounterDetailsQueryHookResult = ReturnType<typeof useActiveEncounterDetailsQuery>;
export type ActiveEncounterDetailsLazyQueryHookResult = ReturnType<typeof useActiveEncounterDetailsLazyQuery>;
export type ActiveEncounterDetailsQueryResult = Apollo.QueryResult<
  ActiveEncounterDetailsQuery,
  ActiveEncounterDetailsQueryVariables
>;
export const ActiveEncounterFetchDocument = gql`
  query ActiveEncounterFetch($dataFetchId: DataFetchRequestId!) {
    integrationsDataFetchRequest(id: $dataFetchId) {
      id
      status
      dataSourceRecord {
        id
        target {
          id
          entityType
        }
      }
    }
  }
`;

/**
 * __useActiveEncounterFetchQuery__
 *
 * To run a query within a React component, call `useActiveEncounterFetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveEncounterFetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveEncounterFetchQuery({
 *   variables: {
 *      dataFetchId: // value for 'dataFetchId'
 *   },
 * });
 */
export function useActiveEncounterFetchQuery(
  baseOptions: Apollo.QueryHookOptions<ActiveEncounterFetchQuery, ActiveEncounterFetchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveEncounterFetchQuery, ActiveEncounterFetchQueryVariables>(
    ActiveEncounterFetchDocument,
    options
  );
}
export function useActiveEncounterFetchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveEncounterFetchQuery, ActiveEncounterFetchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveEncounterFetchQuery, ActiveEncounterFetchQueryVariables>(
    ActiveEncounterFetchDocument,
    options
  );
}
export type ActiveEncounterFetchQueryHookResult = ReturnType<typeof useActiveEncounterFetchQuery>;
export type ActiveEncounterFetchLazyQueryHookResult = ReturnType<typeof useActiveEncounterFetchLazyQuery>;
export type ActiveEncounterFetchQueryResult = Apollo.QueryResult<
  ActiveEncounterFetchQuery,
  ActiveEncounterFetchQueryVariables
>;
export const ActiveEnrollmentMonthEncounterDocument = gql`
  query ActiveEnrollmentMonthEncounter($monthAndYear: MonthParams!, $enrollmentId: EnrollmentId) {
    collaborativeCareEnrollmentMonths(monthAndYear: $monthAndYear, enrollmentId: $enrollmentId) {
      nodes {
        id
        beginningOfMonth
        encounter {
          id
        }
      }
    }
  }
`;

/**
 * __useActiveEnrollmentMonthEncounterQuery__
 *
 * To run a query within a React component, call `useActiveEnrollmentMonthEncounterQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveEnrollmentMonthEncounterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveEnrollmentMonthEncounterQuery({
 *   variables: {
 *      monthAndYear: // value for 'monthAndYear'
 *      enrollmentId: // value for 'enrollmentId'
 *   },
 * });
 */
export function useActiveEnrollmentMonthEncounterQuery(
  baseOptions: Apollo.QueryHookOptions<
    ActiveEnrollmentMonthEncounterQuery,
    ActiveEnrollmentMonthEncounterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveEnrollmentMonthEncounterQuery, ActiveEnrollmentMonthEncounterQueryVariables>(
    ActiveEnrollmentMonthEncounterDocument,
    options
  );
}
export function useActiveEnrollmentMonthEncounterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveEnrollmentMonthEncounterQuery,
    ActiveEnrollmentMonthEncounterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ActiveEnrollmentMonthEncounterQuery,
    ActiveEnrollmentMonthEncounterQueryVariables
  >(ActiveEnrollmentMonthEncounterDocument, options);
}
export type ActiveEnrollmentMonthEncounterQueryHookResult = ReturnType<
  typeof useActiveEnrollmentMonthEncounterQuery
>;
export type ActiveEnrollmentMonthEncounterLazyQueryHookResult = ReturnType<
  typeof useActiveEnrollmentMonthEncounterLazyQuery
>;
export type ActiveEnrollmentMonthEncounterQueryResult = Apollo.QueryResult<
  ActiveEnrollmentMonthEncounterQuery,
  ActiveEnrollmentMonthEncounterQueryVariables
>;
export const ActiveTreatmentTargetsDocument = gql`
  query ActiveTreatmentTargets($careEpisodeId: CareEpisodeId!) {
    careEpisode(id: $careEpisodeId) {
      id
      collaborativeCareCareEpisodeTreatmentTargets(status: ACTIVE) {
        id
        startedDate
        treatmentTarget {
          id
          name
          measurementPlan {
            id
            measurementPlanScales {
              id
              scale {
                id
                name
                shortname
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useActiveTreatmentTargetsQuery__
 *
 * To run a query within a React component, call `useActiveTreatmentTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveTreatmentTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveTreatmentTargetsQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *   },
 * });
 */
export function useActiveTreatmentTargetsQuery(
  baseOptions: Apollo.QueryHookOptions<ActiveTreatmentTargetsQuery, ActiveTreatmentTargetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveTreatmentTargetsQuery, ActiveTreatmentTargetsQueryVariables>(
    ActiveTreatmentTargetsDocument,
    options
  );
}
export function useActiveTreatmentTargetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActiveTreatmentTargetsQuery, ActiveTreatmentTargetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveTreatmentTargetsQuery, ActiveTreatmentTargetsQueryVariables>(
    ActiveTreatmentTargetsDocument,
    options
  );
}
export type ActiveTreatmentTargetsQueryHookResult = ReturnType<typeof useActiveTreatmentTargetsQuery>;
export type ActiveTreatmentTargetsLazyQueryHookResult = ReturnType<typeof useActiveTreatmentTargetsLazyQuery>;
export type ActiveTreatmentTargetsQueryResult = Apollo.QueryResult<
  ActiveTreatmentTargetsQuery,
  ActiveTreatmentTargetsQueryVariables
>;
export const AddTreatmentTargetDocument = gql`
  mutation AddTreatmentTarget($input: AddTreatmentTargetInput!) {
    collaborativeCareAddTreatmentTarget(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        careEpisodeTreatmentTarget {
          id
          careEpisode {
            id
            collaborativeCareCareEpisodeTreatmentTargets(status: ACTIVE) {
              id
              startedDate
              treatmentTarget {
                id
                name
                measurementPlan {
                  id
                  measurementPlanScales {
                    id
                    scale {
                      id
                      name
                      shortname
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type AddTreatmentTargetMutationFn = Apollo.MutationFunction<
  AddTreatmentTargetMutation,
  AddTreatmentTargetMutationVariables
>;

/**
 * __useAddTreatmentTargetMutation__
 *
 * To run a mutation, you first call `useAddTreatmentTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTreatmentTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTreatmentTargetMutation, { data, loading, error }] = useAddTreatmentTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTreatmentTargetMutation(
  baseOptions?: Apollo.MutationHookOptions<AddTreatmentTargetMutation, AddTreatmentTargetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTreatmentTargetMutation, AddTreatmentTargetMutationVariables>(
    AddTreatmentTargetDocument,
    options
  );
}
export type AddTreatmentTargetMutationHookResult = ReturnType<typeof useAddTreatmentTargetMutation>;
export type AddTreatmentTargetMutationResult = Apollo.MutationResult<AddTreatmentTargetMutation>;
export type AddTreatmentTargetMutationOptions = Apollo.BaseMutationOptions<
  AddTreatmentTargetMutation,
  AddTreatmentTargetMutationVariables
>;
export const AssessedPatientsStatsDocument = gql`
  query AssessedPatientsStats(
    $careManagerIds: [ProviderId!]!
    $instituteId: InstituteId
    $startDate: ISO8601Date
    $endDate: ISO8601Date
  ) {
    collaborativeCareAssessedPatientsStats(
      careManagerIds: $careManagerIds
      instituteId: $instituteId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      value
    }
  }
`;

/**
 * __useAssessedPatientsStatsQuery__
 *
 * To run a query within a React component, call `useAssessedPatientsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessedPatientsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessedPatientsStatsQuery({
 *   variables: {
 *      careManagerIds: // value for 'careManagerIds'
 *      instituteId: // value for 'instituteId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useAssessedPatientsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<AssessedPatientsStatsQuery, AssessedPatientsStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssessedPatientsStatsQuery, AssessedPatientsStatsQueryVariables>(
    AssessedPatientsStatsDocument,
    options
  );
}
export function useAssessedPatientsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssessedPatientsStatsQuery, AssessedPatientsStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssessedPatientsStatsQuery, AssessedPatientsStatsQueryVariables>(
    AssessedPatientsStatsDocument,
    options
  );
}
export type AssessedPatientsStatsQueryHookResult = ReturnType<typeof useAssessedPatientsStatsQuery>;
export type AssessedPatientsStatsLazyQueryHookResult = ReturnType<typeof useAssessedPatientsStatsLazyQuery>;
export type AssessedPatientsStatsQueryResult = Apollo.QueryResult<
  AssessedPatientsStatsQuery,
  AssessedPatientsStatsQueryVariables
>;
export const AssignDocumentationEncounterDocument = gql`
  mutation AssignDocumentationEncounter($input: AssignDocumentationEncounterInput!) {
    collaborativeCareAssignDocumentationEncounter(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        enrollmentMonth {
          id
          encounter {
            id
          }
        }
      }
    }
  }
`;
export type AssignDocumentationEncounterMutationFn = Apollo.MutationFunction<
  AssignDocumentationEncounterMutation,
  AssignDocumentationEncounterMutationVariables
>;

/**
 * __useAssignDocumentationEncounterMutation__
 *
 * To run a mutation, you first call `useAssignDocumentationEncounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignDocumentationEncounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignDocumentationEncounterMutation, { data, loading, error }] = useAssignDocumentationEncounterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignDocumentationEncounterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignDocumentationEncounterMutation,
    AssignDocumentationEncounterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignDocumentationEncounterMutation,
    AssignDocumentationEncounterMutationVariables
  >(AssignDocumentationEncounterDocument, options);
}
export type AssignDocumentationEncounterMutationHookResult = ReturnType<
  typeof useAssignDocumentationEncounterMutation
>;
export type AssignDocumentationEncounterMutationResult =
  Apollo.MutationResult<AssignDocumentationEncounterMutation>;
export type AssignDocumentationEncounterMutationOptions = Apollo.BaseMutationOptions<
  AssignDocumentationEncounterMutation,
  AssignDocumentationEncounterMutationVariables
>;
export const EditPatientDemographicsDocument = gql`
  mutation EditPatientDemographics($input: EditPatientDemographicInput!) {
    editPatientDemographics(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        patient {
          id
          name
          firstName
          lastName
          legalFirstName
          legalLastName
          preferredFirstName
          preferredLastName
          preferredPronouns
          email
          phoneNumber
          mrn
          gender
          genderIdentity
          genderIdentityFhir
          dob
          primaryLanguage
          race
          ethnicity
          isTest
          user {
            id
            notificationPreference
          }
        }
      }
    }
  }
`;
export type EditPatientDemographicsMutationFn = Apollo.MutationFunction<
  EditPatientDemographicsMutation,
  EditPatientDemographicsMutationVariables
>;

/**
 * __useEditPatientDemographicsMutation__
 *
 * To run a mutation, you first call `useEditPatientDemographicsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPatientDemographicsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPatientDemographicsMutation, { data, loading, error }] = useEditPatientDemographicsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditPatientDemographicsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditPatientDemographicsMutation,
    EditPatientDemographicsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditPatientDemographicsMutation, EditPatientDemographicsMutationVariables>(
    EditPatientDemographicsDocument,
    options
  );
}
export type EditPatientDemographicsMutationHookResult = ReturnType<typeof useEditPatientDemographicsMutation>;
export type EditPatientDemographicsMutationResult = Apollo.MutationResult<EditPatientDemographicsMutation>;
export type EditPatientDemographicsMutationOptions = Apollo.BaseMutationOptions<
  EditPatientDemographicsMutation,
  EditPatientDemographicsMutationVariables
>;
export const EnrollmentAcuityStatsDocument = gql`
  query EnrollmentAcuityStats(
    $careManagerIds: [ProviderId!]!
    $instituteId: InstituteId
    $startDate: ISO8601Date
    $endDate: ISO8601Date
  ) {
    collaborativeCareEnrollmentAcuityStats(
      careManagerIds: $careManagerIds
      instituteId: $instituteId
      startDate: $startDate
      endDate: $endDate
    ) {
      baselineAcuity
      date
      value
      label
    }
  }
`;

/**
 * __useEnrollmentAcuityStatsQuery__
 *
 * To run a query within a React component, call `useEnrollmentAcuityStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentAcuityStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentAcuityStatsQuery({
 *   variables: {
 *      careManagerIds: // value for 'careManagerIds'
 *      instituteId: // value for 'instituteId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useEnrollmentAcuityStatsQuery(
  baseOptions: Apollo.QueryHookOptions<EnrollmentAcuityStatsQuery, EnrollmentAcuityStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnrollmentAcuityStatsQuery, EnrollmentAcuityStatsQueryVariables>(
    EnrollmentAcuityStatsDocument,
    options
  );
}
export function useEnrollmentAcuityStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentAcuityStatsQuery, EnrollmentAcuityStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EnrollmentAcuityStatsQuery, EnrollmentAcuityStatsQueryVariables>(
    EnrollmentAcuityStatsDocument,
    options
  );
}
export type EnrollmentAcuityStatsQueryHookResult = ReturnType<typeof useEnrollmentAcuityStatsQuery>;
export type EnrollmentAcuityStatsLazyQueryHookResult = ReturnType<typeof useEnrollmentAcuityStatsLazyQuery>;
export type EnrollmentAcuityStatsQueryResult = Apollo.QueryResult<
  EnrollmentAcuityStatsQuery,
  EnrollmentAcuityStatsQueryVariables
>;
export const EnrollmentMonthsTableDocument = gql`
  query EnrollmentMonthsTable(
    $sortBy: EnrollmentMonthSortParameter
    $sortDirection: SortDirection
    $enrollmentId: EnrollmentId
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    collaborativeCareEnrollmentMonths(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      enrollmentId: $enrollmentId
    ) {
      nodes {
        id
        beginningOfMonth
        month
        billableMinutes
        minutesTargetCeiling
        minutesTargetFloor
        minutesTargetStatus
        validatedMeasureCompleted
        billingEnrollmentMonthBillingRuleResultWinner {
          id
          winningResult {
            ...RuleResultDetails
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
  ${RuleResultDetailsFragmentDoc}
`;

/**
 * __useEnrollmentMonthsTableQuery__
 *
 * To run a query within a React component, call `useEnrollmentMonthsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrollmentMonthsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrollmentMonthsTableQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      enrollmentId: // value for 'enrollmentId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useEnrollmentMonthsTableQuery(
  baseOptions?: Apollo.QueryHookOptions<EnrollmentMonthsTableQuery, EnrollmentMonthsTableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnrollmentMonthsTableQuery, EnrollmentMonthsTableQueryVariables>(
    EnrollmentMonthsTableDocument,
    options
  );
}
export function useEnrollmentMonthsTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EnrollmentMonthsTableQuery, EnrollmentMonthsTableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EnrollmentMonthsTableQuery, EnrollmentMonthsTableQueryVariables>(
    EnrollmentMonthsTableDocument,
    options
  );
}
export type EnrollmentMonthsTableQueryHookResult = ReturnType<typeof useEnrollmentMonthsTableQuery>;
export type EnrollmentMonthsTableLazyQueryHookResult = ReturnType<typeof useEnrollmentMonthsTableLazyQuery>;
export type EnrollmentMonthsTableQueryResult = Apollo.QueryResult<
  EnrollmentMonthsTableQuery,
  EnrollmentMonthsTableQueryVariables
>;
export const NewEnrollmentStatsDocument = gql`
  query NewEnrollmentStats(
    $careManagerIds: [ProviderId!]!
    $instituteId: InstituteId
    $startDate: ISO8601Date
    $endDate: ISO8601Date
  ) {
    collaborativeCareNewEnrollmentStats(
      careManagerIds: $careManagerIds
      instituteId: $instituteId
      startDate: $startDate
      endDate: $endDate
    ) {
      date
      value
    }
  }
`;

/**
 * __useNewEnrollmentStatsQuery__
 *
 * To run a query within a React component, call `useNewEnrollmentStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewEnrollmentStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewEnrollmentStatsQuery({
 *   variables: {
 *      careManagerIds: // value for 'careManagerIds'
 *      instituteId: // value for 'instituteId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useNewEnrollmentStatsQuery(
  baseOptions: Apollo.QueryHookOptions<NewEnrollmentStatsQuery, NewEnrollmentStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewEnrollmentStatsQuery, NewEnrollmentStatsQueryVariables>(
    NewEnrollmentStatsDocument,
    options
  );
}
export function useNewEnrollmentStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NewEnrollmentStatsQuery, NewEnrollmentStatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewEnrollmentStatsQuery, NewEnrollmentStatsQueryVariables>(
    NewEnrollmentStatsDocument,
    options
  );
}
export type NewEnrollmentStatsQueryHookResult = ReturnType<typeof useNewEnrollmentStatsQuery>;
export type NewEnrollmentStatsLazyQueryHookResult = ReturnType<typeof useNewEnrollmentStatsLazyQuery>;
export type NewEnrollmentStatsQueryResult = Apollo.QueryResult<
  NewEnrollmentStatsQuery,
  NewEnrollmentStatsQueryVariables
>;
export const PatientDemographicsDocument = gql`
  query PatientDemographics($id: PatientId!) {
    patient(id: $id) {
      id
      legalFirstName
      legalLastName
      preferredFirstName
      preferredLastName
      preferredPronouns
      email
      phoneNumber
      mrn
      gender
      genderIdentity
      genderIdentityFhir
      dob
      primaryLanguage
      race
      ethnicity
      isTest
      user {
        id
        notificationPreference
      }
    }
  }
`;

/**
 * __usePatientDemographicsQuery__
 *
 * To run a query within a React component, call `usePatientDemographicsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientDemographicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientDemographicsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientDemographicsQuery(
  baseOptions: Apollo.QueryHookOptions<PatientDemographicsQuery, PatientDemographicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientDemographicsQuery, PatientDemographicsQueryVariables>(
    PatientDemographicsDocument,
    options
  );
}
export function usePatientDemographicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientDemographicsQuery, PatientDemographicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientDemographicsQuery, PatientDemographicsQueryVariables>(
    PatientDemographicsDocument,
    options
  );
}
export type PatientDemographicsQueryHookResult = ReturnType<typeof usePatientDemographicsQuery>;
export type PatientDemographicsLazyQueryHookResult = ReturnType<typeof usePatientDemographicsLazyQuery>;
export type PatientDemographicsQueryResult = Apollo.QueryResult<
  PatientDemographicsQuery,
  PatientDemographicsQueryVariables
>;
export const PatientDetailsDocument = gql`
  query PatientDetails($patientId: PatientId!) {
    patient(id: $patientId) {
      ...PatientDetailsFragment
    }
  }
  ${PatientDetailsFragmentFragmentDoc}
`;

/**
 * __usePatientDetailsQuery__
 *
 * To run a query within a React component, call `usePatientDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientDetailsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<PatientDetailsQuery, PatientDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientDetailsQuery, PatientDetailsQueryVariables>(PatientDetailsDocument, options);
}
export function usePatientDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientDetailsQuery, PatientDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientDetailsQuery, PatientDetailsQueryVariables>(
    PatientDetailsDocument,
    options
  );
}
export type PatientDetailsQueryHookResult = ReturnType<typeof usePatientDetailsQuery>;
export type PatientDetailsLazyQueryHookResult = ReturnType<typeof usePatientDetailsLazyQuery>;
export type PatientDetailsQueryResult = Apollo.QueryResult<PatientDetailsQuery, PatientDetailsQueryVariables>;
export const CollaborativeCareUpdatePatientNoteDocument = gql`
  mutation CollaborativeCareUpdatePatientNote($input: UpdatePatientNoteInput!) {
    collaborativeCareUpdatePatientNote(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        patient {
          id
          note
        }
      }
    }
  }
`;
export type CollaborativeCareUpdatePatientNoteMutationFn = Apollo.MutationFunction<
  CollaborativeCareUpdatePatientNoteMutation,
  CollaborativeCareUpdatePatientNoteMutationVariables
>;

/**
 * __useCollaborativeCareUpdatePatientNoteMutation__
 *
 * To run a mutation, you first call `useCollaborativeCareUpdatePatientNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCollaborativeCareUpdatePatientNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [collaborativeCareUpdatePatientNoteMutation, { data, loading, error }] = useCollaborativeCareUpdatePatientNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCollaborativeCareUpdatePatientNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CollaborativeCareUpdatePatientNoteMutation,
    CollaborativeCareUpdatePatientNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CollaborativeCareUpdatePatientNoteMutation,
    CollaborativeCareUpdatePatientNoteMutationVariables
  >(CollaborativeCareUpdatePatientNoteDocument, options);
}
export type CollaborativeCareUpdatePatientNoteMutationHookResult = ReturnType<
  typeof useCollaborativeCareUpdatePatientNoteMutation
>;
export type CollaborativeCareUpdatePatientNoteMutationResult =
  Apollo.MutationResult<CollaborativeCareUpdatePatientNoteMutation>;
export type CollaborativeCareUpdatePatientNoteMutationOptions = Apollo.BaseMutationOptions<
  CollaborativeCareUpdatePatientNoteMutation,
  CollaborativeCareUpdatePatientNoteMutationVariables
>;
export const RemoveTreatmentTargetDocument = gql`
  mutation RemoveTreatmentTarget($input: RemoveTreatmentTargetInput!) {
    collaborativeCareRemoveTreatmentTarget(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        careEpisodeTreatmentTarget {
          id
          careEpisode {
            id
            collaborativeCareCareEpisodeTreatmentTargets(status: ACTIVE) {
              id
            }
          }
          treatmentTarget {
            id
          }
        }
      }
    }
  }
`;
export type RemoveTreatmentTargetMutationFn = Apollo.MutationFunction<
  RemoveTreatmentTargetMutation,
  RemoveTreatmentTargetMutationVariables
>;

/**
 * __useRemoveTreatmentTargetMutation__
 *
 * To run a mutation, you first call `useRemoveTreatmentTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTreatmentTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTreatmentTargetMutation, { data, loading, error }] = useRemoveTreatmentTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTreatmentTargetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTreatmentTargetMutation,
    RemoveTreatmentTargetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveTreatmentTargetMutation, RemoveTreatmentTargetMutationVariables>(
    RemoveTreatmentTargetDocument,
    options
  );
}
export type RemoveTreatmentTargetMutationHookResult = ReturnType<typeof useRemoveTreatmentTargetMutation>;
export type RemoveTreatmentTargetMutationResult = Apollo.MutationResult<RemoveTreatmentTargetMutation>;
export type RemoveTreatmentTargetMutationOptions = Apollo.BaseMutationOptions<
  RemoveTreatmentTargetMutation,
  RemoveTreatmentTargetMutationVariables
>;
export const SendImmediateSurveyDocument = gql`
  mutation SendImmediateSurvey($input: SendImmediateSurveyInput!) {
    collaborativeCareSendImmediateSurvey(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        patientSession {
          id
          assessmentReport {
            invitations {
              id
              reportScaleParticipants {
                user {
                  name
                }
              }
              reportScales {
                scale {
                  id
                  shortname
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type SendImmediateSurveyMutationFn = Apollo.MutationFunction<
  SendImmediateSurveyMutation,
  SendImmediateSurveyMutationVariables
>;

/**
 * __useSendImmediateSurveyMutation__
 *
 * To run a mutation, you first call `useSendImmediateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendImmediateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendImmediateSurveyMutation, { data, loading, error }] = useSendImmediateSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendImmediateSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<SendImmediateSurveyMutation, SendImmediateSurveyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendImmediateSurveyMutation, SendImmediateSurveyMutationVariables>(
    SendImmediateSurveyDocument,
    options
  );
}
export type SendImmediateSurveyMutationHookResult = ReturnType<typeof useSendImmediateSurveyMutation>;
export type SendImmediateSurveyMutationResult = Apollo.MutationResult<SendImmediateSurveyMutation>;
export type SendImmediateSurveyMutationOptions = Apollo.BaseMutationOptions<
  SendImmediateSurveyMutation,
  SendImmediateSurveyMutationVariables
>;
export const SurveyManagementIndexDocument = gql`
  query SurveyManagementIndex($careEpisodeId: CareEpisodeId!) {
    assessmentCareEpisodeScaleSummary(careEpisodeId: $careEpisodeId) {
      id
      invitations {
        id
        status
        phase
        closesAt
        opensAt
        format
        dateBegun
        dateCompleted
        createdAt
        statusUpdatedAt
        downloadAvailable
        manuallyCancelable
        manuallyAdministerable
        canceledAt
        managedBy
        canceledByUser {
          ...UserSummaryFragment
        }
        canResendNotifications
        invitationNotifications {
          id
          status
          sendAt
          dateEmailed
          dateTexted
        }
        reportScales {
          scale {
            id
            shortname
            scaleGroup {
              id
            }
          }
          report {
            id
            computedValues {
              id
            }
            patientSession {
              id
            }
          }
        }
        patient {
          id
        }
        user {
          ...UserSummaryFragment
        }
      }
    }
  }
  ${UserSummaryFragmentFragmentDoc}
`;

/**
 * __useSurveyManagementIndexQuery__
 *
 * To run a query within a React component, call `useSurveyManagementIndexQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyManagementIndexQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyManagementIndexQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *   },
 * });
 */
export function useSurveyManagementIndexQuery(
  baseOptions: Apollo.QueryHookOptions<SurveyManagementIndexQuery, SurveyManagementIndexQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SurveyManagementIndexQuery, SurveyManagementIndexQueryVariables>(
    SurveyManagementIndexDocument,
    options
  );
}
export function useSurveyManagementIndexLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SurveyManagementIndexQuery, SurveyManagementIndexQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SurveyManagementIndexQuery, SurveyManagementIndexQueryVariables>(
    SurveyManagementIndexDocument,
    options
  );
}
export type SurveyManagementIndexQueryHookResult = ReturnType<typeof useSurveyManagementIndexQuery>;
export type SurveyManagementIndexLazyQueryHookResult = ReturnType<typeof useSurveyManagementIndexLazyQuery>;
export type SurveyManagementIndexQueryResult = Apollo.QueryResult<
  SurveyManagementIndexQuery,
  SurveyManagementIndexQueryVariables
>;
export const TasksCardDocument = gql`
  query TasksCard(
    $patientId: [PatientId!]
    $sortBy: TaskSortParameter
    $sortDirection: SortDirection
    $status: TaskStatus
    $year: Int
    $month: Int
    $enrollmentId: EnrollmentId
  ) {
    collaborativeCareTasks(
      patientId: $patientId
      sortBy: $sortBy
      sortDirection: $sortDirection
      status: $status
      enrollmentId: $enrollmentId
    ) {
      nodes {
        id
        createdAt
        title
        body
        dueAt
        status
        isPlaceholder
        totalMinutesForMonth(year: $year, month: $month)
        patient {
          id
          name
          isTest
        }
        assignedTo {
          id
          name
        }
        notes(first: 1000) {
          nodes {
            id
            text
            author {
              id
              name
            }
            createdAt
            timeEntry {
              startTime
            }
          }
        }
        timeEntries(first: 1000) {
          nodes {
            id
            startTime
            endTime
            durationSeconds
            createdAt
            provider {
              id
              name
            }
          }
        }
        creationLog {
          id
          creationType
          patientSession {
            id
            targetDate
            patient {
              id
              name
              isTest
            }
          }
          requestedBy {
            id
            name
          }
          assignedBy {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useTasksCardQuery__
 *
 * To run a query within a React component, call `useTasksCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksCardQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      status: // value for 'status'
 *      year: // value for 'year'
 *      month: // value for 'month'
 *      enrollmentId: // value for 'enrollmentId'
 *   },
 * });
 */
export function useTasksCardQuery(
  baseOptions?: Apollo.QueryHookOptions<TasksCardQuery, TasksCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TasksCardQuery, TasksCardQueryVariables>(TasksCardDocument, options);
}
export function useTasksCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TasksCardQuery, TasksCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TasksCardQuery, TasksCardQueryVariables>(TasksCardDocument, options);
}
export type TasksCardQueryHookResult = ReturnType<typeof useTasksCardQuery>;
export type TasksCardLazyQueryHookResult = ReturnType<typeof useTasksCardLazyQuery>;
export type TasksCardQueryResult = Apollo.QueryResult<TasksCardQuery, TasksCardQueryVariables>;
export const TimelineCardDocument = gql`
  query TimelineCard($patientId: PatientId!, $startTime: ISO8601DateTime!) {
    patient(id: $patientId) {
      id
      collaborativeCareTimeEntryLogs(startTime: { min: $startTime }, deleted: false) {
        nodes {
          id
          startTime
          clientStartTime
          endTime
          durationSeconds
          blockedMinutes
          workFor {
            id
            title
            body
            dueAt
            status
            isPlaceholder
            patient {
              id
              name
              isTest
            }
            assignedTo {
              id
              name
            }
            notes(first: 1000) {
              nodes {
                id
                text
                author {
                  id
                  name
                }
                createdAt
                timeEntry {
                  startTime
                }
              }
            }
            timeEntries(first: 1000) {
              nodes {
                id
                startTime
                endTime
                durationSeconds
                createdAt
                provider {
                  id
                  name
                }
              }
            }
            creationLog {
              id
              creationType
              patientSession {
                id
                targetDate
                patient {
                  id
                  name
                  isTest
                }
              }
              requestedBy {
                id
                name
              }
              assignedBy {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTimelineCardQuery__
 *
 * To run a query within a React component, call `useTimelineCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelineCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelineCardQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      startTime: // value for 'startTime'
 *   },
 * });
 */
export function useTimelineCardQuery(
  baseOptions: Apollo.QueryHookOptions<TimelineCardQuery, TimelineCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimelineCardQuery, TimelineCardQueryVariables>(TimelineCardDocument, options);
}
export function useTimelineCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TimelineCardQuery, TimelineCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TimelineCardQuery, TimelineCardQueryVariables>(TimelineCardDocument, options);
}
export type TimelineCardQueryHookResult = ReturnType<typeof useTimelineCardQuery>;
export type TimelineCardLazyQueryHookResult = ReturnType<typeof useTimelineCardLazyQuery>;
export type TimelineCardQueryResult = Apollo.QueryResult<TimelineCardQuery, TimelineCardQueryVariables>;
export const UpdateEnrollmentDocument = gql`
  mutation UpdateEnrollment($input: UpdateEnrollmentInput!) {
    collaborativeCareUpdateEnrollment(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        enrollment {
          id
          enrolledAt
          consentDate
          panel {
            id
          }
          primaryCareProviderTeamMember {
            id
          }
          careManagerTeamMember {
            id
          }
        }
      }
    }
  }
`;
export type UpdateEnrollmentMutationFn = Apollo.MutationFunction<
  UpdateEnrollmentMutation,
  UpdateEnrollmentMutationVariables
>;

/**
 * __useUpdateEnrollmentMutation__
 *
 * To run a mutation, you first call `useUpdateEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEnrollmentMutation, { data, loading, error }] = useUpdateEnrollmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEnrollmentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEnrollmentMutation, UpdateEnrollmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEnrollmentMutation, UpdateEnrollmentMutationVariables>(
    UpdateEnrollmentDocument,
    options
  );
}
export type UpdateEnrollmentMutationHookResult = ReturnType<typeof useUpdateEnrollmentMutation>;
export type UpdateEnrollmentMutationResult = Apollo.MutationResult<UpdateEnrollmentMutation>;
export type UpdateEnrollmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateEnrollmentMutation,
  UpdateEnrollmentMutationVariables
>;
export const CareEpisodeUpcomingSessionsDocument = gql`
  query CareEpisodeUpcomingSessions($careEpisodeId: CareEpisodeId!, $start: ISO8601DateTime!) {
    careEpisode(id: $careEpisodeId) {
      patientSessions(targetDate: { min: $start }, sortBy: TARGETDATE, sortDirection: ASC) {
        nodes {
          id
          status
          targetDate
        }
      }
    }
  }
`;

/**
 * __useCareEpisodeUpcomingSessionsQuery__
 *
 * To run a query within a React component, call `useCareEpisodeUpcomingSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareEpisodeUpcomingSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareEpisodeUpcomingSessionsQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useCareEpisodeUpcomingSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CareEpisodeUpcomingSessionsQuery,
    CareEpisodeUpcomingSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareEpisodeUpcomingSessionsQuery, CareEpisodeUpcomingSessionsQueryVariables>(
    CareEpisodeUpcomingSessionsDocument,
    options
  );
}
export function useCareEpisodeUpcomingSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareEpisodeUpcomingSessionsQuery,
    CareEpisodeUpcomingSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareEpisodeUpcomingSessionsQuery, CareEpisodeUpcomingSessionsQueryVariables>(
    CareEpisodeUpcomingSessionsDocument,
    options
  );
}
export type CareEpisodeUpcomingSessionsQueryHookResult = ReturnType<
  typeof useCareEpisodeUpcomingSessionsQuery
>;
export type CareEpisodeUpcomingSessionsLazyQueryHookResult = ReturnType<
  typeof useCareEpisodeUpcomingSessionsLazyQuery
>;
export type CareEpisodeUpcomingSessionsQueryResult = Apollo.QueryResult<
  CareEpisodeUpcomingSessionsQuery,
  CareEpisodeUpcomingSessionsQueryVariables
>;
export const DownloadInvitationPdfDocument = gql`
  query DownloadInvitationPdf($invitationId: InvitationId!) {
    assessmentInvitation(id: $invitationId) {
      id
      pdfContentBase64
    }
  }
`;

/**
 * __useDownloadInvitationPdfQuery__
 *
 * To run a query within a React component, call `useDownloadInvitationPdfQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadInvitationPdfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadInvitationPdfQuery({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useDownloadInvitationPdfQuery(
  baseOptions: Apollo.QueryHookOptions<DownloadInvitationPdfQuery, DownloadInvitationPdfQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DownloadInvitationPdfQuery, DownloadInvitationPdfQueryVariables>(
    DownloadInvitationPdfDocument,
    options
  );
}
export function useDownloadInvitationPdfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DownloadInvitationPdfQuery, DownloadInvitationPdfQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DownloadInvitationPdfQuery, DownloadInvitationPdfQueryVariables>(
    DownloadInvitationPdfDocument,
    options
  );
}
export type DownloadInvitationPdfQueryHookResult = ReturnType<typeof useDownloadInvitationPdfQuery>;
export type DownloadInvitationPdfLazyQueryHookResult = ReturnType<typeof useDownloadInvitationPdfLazyQuery>;
export type DownloadInvitationPdfQueryResult = Apollo.QueryResult<
  DownloadInvitationPdfQuery,
  DownloadInvitationPdfQueryVariables
>;
export const ManuallyCancelInvitationDocument = gql`
  mutation ManuallyCancelInvitation($input: ManuallyCancelInvitationInput!) {
    collaborativeCareManuallyCancelInvitation(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        invitation {
          id
          status
        }
      }
    }
  }
`;
export type ManuallyCancelInvitationMutationFn = Apollo.MutationFunction<
  ManuallyCancelInvitationMutation,
  ManuallyCancelInvitationMutationVariables
>;

/**
 * __useManuallyCancelInvitationMutation__
 *
 * To run a mutation, you first call `useManuallyCancelInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManuallyCancelInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manuallyCancelInvitationMutation, { data, loading, error }] = useManuallyCancelInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManuallyCancelInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ManuallyCancelInvitationMutation,
    ManuallyCancelInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ManuallyCancelInvitationMutation, ManuallyCancelInvitationMutationVariables>(
    ManuallyCancelInvitationDocument,
    options
  );
}
export type ManuallyCancelInvitationMutationHookResult = ReturnType<
  typeof useManuallyCancelInvitationMutation
>;
export type ManuallyCancelInvitationMutationResult = Apollo.MutationResult<ManuallyCancelInvitationMutation>;
export type ManuallyCancelInvitationMutationOptions = Apollo.BaseMutationOptions<
  ManuallyCancelInvitationMutation,
  ManuallyCancelInvitationMutationVariables
>;
export const PatientActiveCollaborativeCareEpisodeDocument = gql`
  query PatientActiveCollaborativeCareEpisode($patientId: PatientId!) {
    patient(id: $patientId) {
      activeCollaborativeCareCareEpisode {
        id
        collaborativeCareEnrollment {
          id
          status
          intakeStatus
          enrolledAt
          consentDate
        }
      }
    }
  }
`;

/**
 * __usePatientActiveCollaborativeCareEpisodeQuery__
 *
 * To run a query within a React component, call `usePatientActiveCollaborativeCareEpisodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientActiveCollaborativeCareEpisodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientActiveCollaborativeCareEpisodeQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientActiveCollaborativeCareEpisodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    PatientActiveCollaborativeCareEpisodeQuery,
    PatientActiveCollaborativeCareEpisodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PatientActiveCollaborativeCareEpisodeQuery,
    PatientActiveCollaborativeCareEpisodeQueryVariables
  >(PatientActiveCollaborativeCareEpisodeDocument, options);
}
export function usePatientActiveCollaborativeCareEpisodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientActiveCollaborativeCareEpisodeQuery,
    PatientActiveCollaborativeCareEpisodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PatientActiveCollaborativeCareEpisodeQuery,
    PatientActiveCollaborativeCareEpisodeQueryVariables
  >(PatientActiveCollaborativeCareEpisodeDocument, options);
}
export type PatientActiveCollaborativeCareEpisodeQueryHookResult = ReturnType<
  typeof usePatientActiveCollaborativeCareEpisodeQuery
>;
export type PatientActiveCollaborativeCareEpisodeLazyQueryHookResult = ReturnType<
  typeof usePatientActiveCollaborativeCareEpisodeLazyQuery
>;
export type PatientActiveCollaborativeCareEpisodeQueryResult = Apollo.QueryResult<
  PatientActiveCollaborativeCareEpisodeQuery,
  PatientActiveCollaborativeCareEpisodeQueryVariables
>;
export const PatientRelatedPeopleDocument = gql`
  query PatientRelatedPeople($patientId: PatientId!) {
    patient(id: $patientId) {
      id
      relatedPeople {
        id
        email
        active
        firstName
        lastName
        name
        phoneNumber
        notificationPreference
        notificationPreferenceWithDefault
        relatedPersonRelationship
      }
    }
  }
`;

/**
 * __usePatientRelatedPeopleQuery__
 *
 * To run a query within a React component, call `usePatientRelatedPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientRelatedPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientRelatedPeopleQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientRelatedPeopleQuery(
  baseOptions: Apollo.QueryHookOptions<PatientRelatedPeopleQuery, PatientRelatedPeopleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientRelatedPeopleQuery, PatientRelatedPeopleQueryVariables>(
    PatientRelatedPeopleDocument,
    options
  );
}
export function usePatientRelatedPeopleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientRelatedPeopleQuery, PatientRelatedPeopleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientRelatedPeopleQuery, PatientRelatedPeopleQueryVariables>(
    PatientRelatedPeopleDocument,
    options
  );
}
export type PatientRelatedPeopleQueryHookResult = ReturnType<typeof usePatientRelatedPeopleQuery>;
export type PatientRelatedPeopleLazyQueryHookResult = ReturnType<typeof usePatientRelatedPeopleLazyQuery>;
export type PatientRelatedPeopleQueryResult = Apollo.QueryResult<
  PatientRelatedPeopleQuery,
  PatientRelatedPeopleQueryVariables
>;
export const CreateRelatedPersonDocument = gql`
  mutation CreateRelatedPerson($input: CreateRelatedPersonInput!) {
    createRelatedPerson(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        relatedPerson {
          id
        }
      }
    }
  }
`;
export type CreateRelatedPersonMutationFn = Apollo.MutationFunction<
  CreateRelatedPersonMutation,
  CreateRelatedPersonMutationVariables
>;

/**
 * __useCreateRelatedPersonMutation__
 *
 * To run a mutation, you first call `useCreateRelatedPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRelatedPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRelatedPersonMutation, { data, loading, error }] = useCreateRelatedPersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRelatedPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRelatedPersonMutation, CreateRelatedPersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRelatedPersonMutation, CreateRelatedPersonMutationVariables>(
    CreateRelatedPersonDocument,
    options
  );
}
export type CreateRelatedPersonMutationHookResult = ReturnType<typeof useCreateRelatedPersonMutation>;
export type CreateRelatedPersonMutationResult = Apollo.MutationResult<CreateRelatedPersonMutation>;
export type CreateRelatedPersonMutationOptions = Apollo.BaseMutationOptions<
  CreateRelatedPersonMutation,
  CreateRelatedPersonMutationVariables
>;
export const UpdateRelatedPersonDocument = gql`
  mutation UpdateRelatedPerson($input: UpdateRelatedPersonInput!) {
    updateRelatedPerson(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        relatedPerson {
          id
        }
      }
    }
  }
`;
export type UpdateRelatedPersonMutationFn = Apollo.MutationFunction<
  UpdateRelatedPersonMutation,
  UpdateRelatedPersonMutationVariables
>;

/**
 * __useUpdateRelatedPersonMutation__
 *
 * To run a mutation, you first call `useUpdateRelatedPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRelatedPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRelatedPersonMutation, { data, loading, error }] = useUpdateRelatedPersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRelatedPersonMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRelatedPersonMutation, UpdateRelatedPersonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRelatedPersonMutation, UpdateRelatedPersonMutationVariables>(
    UpdateRelatedPersonDocument,
    options
  );
}
export type UpdateRelatedPersonMutationHookResult = ReturnType<typeof useUpdateRelatedPersonMutation>;
export type UpdateRelatedPersonMutationResult = Apollo.MutationResult<UpdateRelatedPersonMutation>;
export type UpdateRelatedPersonMutationOptions = Apollo.BaseMutationOptions<
  UpdateRelatedPersonMutation,
  UpdateRelatedPersonMutationVariables
>;
export const ResendInvitationNotificationsDocument = gql`
  mutation ResendInvitationNotifications($input: ResendAllInvitationNotificationsInput!) {
    collaborativeCareResendAllInvitationNotifications(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        patientSession {
          id
        }
      }
    }
  }
`;
export type ResendInvitationNotificationsMutationFn = Apollo.MutationFunction<
  ResendInvitationNotificationsMutation,
  ResendInvitationNotificationsMutationVariables
>;

/**
 * __useResendInvitationNotificationsMutation__
 *
 * To run a mutation, you first call `useResendInvitationNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationNotificationsMutation, { data, loading, error }] = useResendInvitationNotificationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResendInvitationNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendInvitationNotificationsMutation,
    ResendInvitationNotificationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendInvitationNotificationsMutation,
    ResendInvitationNotificationsMutationVariables
  >(ResendInvitationNotificationsDocument, options);
}
export type ResendInvitationNotificationsMutationHookResult = ReturnType<
  typeof useResendInvitationNotificationsMutation
>;
export type ResendInvitationNotificationsMutationResult =
  Apollo.MutationResult<ResendInvitationNotificationsMutation>;
export type ResendInvitationNotificationsMutationOptions = Apollo.BaseMutationOptions<
  ResendInvitationNotificationsMutation,
  ResendInvitationNotificationsMutationVariables
>;
export const PatientSchedulingAppointmentsDocument = gql`
  query PatientSchedulingAppointments($patientId: PatientId!) {
    patient(id: $patientId) {
      id
      schedulingAppointments(first: 1000) {
        nodes {
          id
          startDate
          endDate
          provider {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __usePatientSchedulingAppointmentsQuery__
 *
 * To run a query within a React component, call `usePatientSchedulingAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientSchedulingAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientSchedulingAppointmentsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientSchedulingAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PatientSchedulingAppointmentsQuery,
    PatientSchedulingAppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientSchedulingAppointmentsQuery, PatientSchedulingAppointmentsQueryVariables>(
    PatientSchedulingAppointmentsDocument,
    options
  );
}
export function usePatientSchedulingAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientSchedulingAppointmentsQuery,
    PatientSchedulingAppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientSchedulingAppointmentsQuery, PatientSchedulingAppointmentsQueryVariables>(
    PatientSchedulingAppointmentsDocument,
    options
  );
}
export type PatientSchedulingAppointmentsQueryHookResult = ReturnType<
  typeof usePatientSchedulingAppointmentsQuery
>;
export type PatientSchedulingAppointmentsLazyQueryHookResult = ReturnType<
  typeof usePatientSchedulingAppointmentsLazyQuery
>;
export type PatientSchedulingAppointmentsQueryResult = Apollo.QueryResult<
  PatientSchedulingAppointmentsQuery,
  PatientSchedulingAppointmentsQueryVariables
>;
export const SendInvitationNotificationsDocument = gql`
  mutation SendInvitationNotifications($input: SendInvitationNotificationsInput!) {
    collaborativeCareSendInvitationNotifications(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        patientSession {
          id
        }
      }
    }
  }
`;
export type SendInvitationNotificationsMutationFn = Apollo.MutationFunction<
  SendInvitationNotificationsMutation,
  SendInvitationNotificationsMutationVariables
>;

/**
 * __useSendInvitationNotificationsMutation__
 *
 * To run a mutation, you first call `useSendInvitationNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationNotificationsMutation, { data, loading, error }] = useSendInvitationNotificationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInvitationNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendInvitationNotificationsMutation,
    SendInvitationNotificationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendInvitationNotificationsMutation,
    SendInvitationNotificationsMutationVariables
  >(SendInvitationNotificationsDocument, options);
}
export type SendInvitationNotificationsMutationHookResult = ReturnType<
  typeof useSendInvitationNotificationsMutation
>;
export type SendInvitationNotificationsMutationResult =
  Apollo.MutationResult<SendInvitationNotificationsMutation>;
export type SendInvitationNotificationsMutationOptions = Apollo.BaseMutationOptions<
  SendInvitationNotificationsMutation,
  SendInvitationNotificationsMutationVariables
>;
export const CollaborativeCareCareManagersDocument = gql`
  query CollaborativeCareCareManagers {
    collaborativeCareCareManagers {
      id
      name
    }
  }
`;

/**
 * __useCollaborativeCareCareManagersQuery__
 *
 * To run a query within a React component, call `useCollaborativeCareCareManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollaborativeCareCareManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollaborativeCareCareManagersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCollaborativeCareCareManagersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CollaborativeCareCareManagersQuery,
    CollaborativeCareCareManagersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CollaborativeCareCareManagersQuery, CollaborativeCareCareManagersQueryVariables>(
    CollaborativeCareCareManagersDocument,
    options
  );
}
export function useCollaborativeCareCareManagersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollaborativeCareCareManagersQuery,
    CollaborativeCareCareManagersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CollaborativeCareCareManagersQuery, CollaborativeCareCareManagersQueryVariables>(
    CollaborativeCareCareManagersDocument,
    options
  );
}
export type CollaborativeCareCareManagersQueryHookResult = ReturnType<
  typeof useCollaborativeCareCareManagersQuery
>;
export type CollaborativeCareCareManagersLazyQueryHookResult = ReturnType<
  typeof useCollaborativeCareCareManagersLazyQuery
>;
export type CollaborativeCareCareManagersQueryResult = Apollo.QueryResult<
  CollaborativeCareCareManagersQuery,
  CollaborativeCareCareManagersQueryVariables
>;
export const CollaborativeCarePatientCountDocument = gql`
  query CollaborativeCarePatientCount(
    $sortBy: PatientSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $cocmPrimaryCareProvider: ProviderId
    $cocmCareManager: ProviderId
    $enrollmentStatus: EnrollmentStatus
    $testPatient: Boolean
  ) {
    patients(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      search: $search
      cocmPrimaryCareProvider: $cocmPrimaryCareProvider
      cocmCareManager: $cocmCareManager
      enrollmentStatus: $enrollmentStatus
      testPatient: $testPatient
    ) {
      totalCount
    }
  }
`;

/**
 * __useCollaborativeCarePatientCountQuery__
 *
 * To run a query within a React component, call `useCollaborativeCarePatientCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollaborativeCarePatientCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollaborativeCarePatientCountQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      cocmPrimaryCareProvider: // value for 'cocmPrimaryCareProvider'
 *      cocmCareManager: // value for 'cocmCareManager'
 *      enrollmentStatus: // value for 'enrollmentStatus'
 *      testPatient: // value for 'testPatient'
 *   },
 * });
 */
export function useCollaborativeCarePatientCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CollaborativeCarePatientCountQuery,
    CollaborativeCarePatientCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CollaborativeCarePatientCountQuery, CollaborativeCarePatientCountQueryVariables>(
    CollaborativeCarePatientCountDocument,
    options
  );
}
export function useCollaborativeCarePatientCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollaborativeCarePatientCountQuery,
    CollaborativeCarePatientCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CollaborativeCarePatientCountQuery, CollaborativeCarePatientCountQueryVariables>(
    CollaborativeCarePatientCountDocument,
    options
  );
}
export type CollaborativeCarePatientCountQueryHookResult = ReturnType<
  typeof useCollaborativeCarePatientCountQuery
>;
export type CollaborativeCarePatientCountLazyQueryHookResult = ReturnType<
  typeof useCollaborativeCarePatientCountLazyQuery
>;
export type CollaborativeCarePatientCountQueryResult = Apollo.QueryResult<
  CollaborativeCarePatientCountQuery,
  CollaborativeCarePatientCountQueryVariables
>;
export const CollaborativeCarePatientSearchDocument = gql`
  query CollaborativeCarePatientSearch(
    $sortBy: PatientSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $forPanel: PanelFilter
    $cocmPrimaryCareProvider: ProviderId
    $cocmCareManager: ProviderId
    $enrollmentStatus: EnrollmentStatus
    $testPatient: Boolean = false
  ) {
    patients(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      search: $search
      cocmPrimaryCareProvider: $cocmPrimaryCareProvider
      cocmCareManager: $cocmCareManager
      enrollmentStatus: $enrollmentStatus
      testPatient: $testPatient
      forCocmPanel: $forPanel
    ) {
      nodes {
        id
        name
        nameLastFirst
        dob
        gender
        genderIdentity
        mrn
        firstName
        lastName
        isTest
        activeCollaborativeCareCareEpisode {
          id
          collaborativeCareEnrollment {
            id
            status
            enrolledAt
          }
          careEpisodeProviders {
            id
            relationship
            provider {
              id
              firstName
              lastName
              orderedName
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
    nonPanelPatients: patients(
      search: $search
      cocmPrimaryCareProvider: $cocmPrimaryCareProvider
      cocmCareManager: $cocmCareManager
      enrollmentStatus: $enrollmentStatus
      testPatient: $testPatient
    ) {
      totalCount
    }
  }
`;

/**
 * __useCollaborativeCarePatientSearchQuery__
 *
 * To run a query within a React component, call `useCollaborativeCarePatientSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollaborativeCarePatientSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollaborativeCarePatientSearchQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      forPanel: // value for 'forPanel'
 *      cocmPrimaryCareProvider: // value for 'cocmPrimaryCareProvider'
 *      cocmCareManager: // value for 'cocmCareManager'
 *      enrollmentStatus: // value for 'enrollmentStatus'
 *      testPatient: // value for 'testPatient'
 *   },
 * });
 */
export function useCollaborativeCarePatientSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CollaborativeCarePatientSearchQuery,
    CollaborativeCarePatientSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CollaborativeCarePatientSearchQuery, CollaborativeCarePatientSearchQueryVariables>(
    CollaborativeCarePatientSearchDocument,
    options
  );
}
export function useCollaborativeCarePatientSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollaborativeCarePatientSearchQuery,
    CollaborativeCarePatientSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CollaborativeCarePatientSearchQuery,
    CollaborativeCarePatientSearchQueryVariables
  >(CollaborativeCarePatientSearchDocument, options);
}
export type CollaborativeCarePatientSearchQueryHookResult = ReturnType<
  typeof useCollaborativeCarePatientSearchQuery
>;
export type CollaborativeCarePatientSearchLazyQueryHookResult = ReturnType<
  typeof useCollaborativeCarePatientSearchLazyQuery
>;
export type CollaborativeCarePatientSearchQueryResult = Apollo.QueryResult<
  CollaborativeCarePatientSearchQuery,
  CollaborativeCarePatientSearchQueryVariables
>;
export const CollaborativeCarePrimaryCareProvidersDocument = gql`
  query CollaborativeCarePrimaryCareProviders {
    collaborativeCarePrimaryCareProviders {
      id
      name
    }
  }
`;

/**
 * __useCollaborativeCarePrimaryCareProvidersQuery__
 *
 * To run a query within a React component, call `useCollaborativeCarePrimaryCareProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCollaborativeCarePrimaryCareProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCollaborativeCarePrimaryCareProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCollaborativeCarePrimaryCareProvidersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CollaborativeCarePrimaryCareProvidersQuery,
    CollaborativeCarePrimaryCareProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CollaborativeCarePrimaryCareProvidersQuery,
    CollaborativeCarePrimaryCareProvidersQueryVariables
  >(CollaborativeCarePrimaryCareProvidersDocument, options);
}
export function useCollaborativeCarePrimaryCareProvidersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CollaborativeCarePrimaryCareProvidersQuery,
    CollaborativeCarePrimaryCareProvidersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CollaborativeCarePrimaryCareProvidersQuery,
    CollaborativeCarePrimaryCareProvidersQueryVariables
  >(CollaborativeCarePrimaryCareProvidersDocument, options);
}
export type CollaborativeCarePrimaryCareProvidersQueryHookResult = ReturnType<
  typeof useCollaborativeCarePrimaryCareProvidersQuery
>;
export type CollaborativeCarePrimaryCareProvidersLazyQueryHookResult = ReturnType<
  typeof useCollaborativeCarePrimaryCareProvidersLazyQuery
>;
export type CollaborativeCarePrimaryCareProvidersQueryResult = Apollo.QueryResult<
  CollaborativeCarePrimaryCareProvidersQuery,
  CollaborativeCarePrimaryCareProvidersQueryVariables
>;
export const CreatePatientDocument = gql`
  mutation CreatePatient($input: CreatePatientInput!) {
    createPatient(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        patient {
          id
          gender
          genderIdentity
          genderIdentityFhir
          preferredPronouns
          dob
          name
          activeCollaborativeCareCareEpisode {
            id
          }
        }
      }
    }
  }
`;
export type CreatePatientMutationFn = Apollo.MutationFunction<
  CreatePatientMutation,
  CreatePatientMutationVariables
>;

/**
 * __useCreatePatientMutation__
 *
 * To run a mutation, you first call `useCreatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMutation, { data, loading, error }] = useCreatePatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePatientMutation, CreatePatientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePatientMutation, CreatePatientMutationVariables>(
    CreatePatientDocument,
    options
  );
}
export type CreatePatientMutationHookResult = ReturnType<typeof useCreatePatientMutation>;
export type CreatePatientMutationResult = Apollo.MutationResult<CreatePatientMutation>;
export type CreatePatientMutationOptions = Apollo.BaseMutationOptions<
  CreatePatientMutation,
  CreatePatientMutationVariables
>;
export const PatientReferenceBaseDocument = gql`
  query PatientReferenceBase($patientId: PatientId!) {
    patient(id: $patientId) {
      id
      gender
      genderIdentity
      genderIdentityFhir
      preferredPronouns
      dob
      name
      activeCollaborativeCareCareEpisode {
        id
        collaborativeCareEnrollment {
          id
          enrolledAt
        }
      }
      collaborativeCareTasks(status: ACTIVE) {
        totalCount
      }
      isTest
    }
  }
`;

/**
 * __usePatientReferenceBaseQuery__
 *
 * To run a query within a React component, call `usePatientReferenceBaseQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientReferenceBaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientReferenceBaseQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientReferenceBaseQuery(
  baseOptions: Apollo.QueryHookOptions<PatientReferenceBaseQuery, PatientReferenceBaseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientReferenceBaseQuery, PatientReferenceBaseQueryVariables>(
    PatientReferenceBaseDocument,
    options
  );
}
export function usePatientReferenceBaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientReferenceBaseQuery, PatientReferenceBaseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientReferenceBaseQuery, PatientReferenceBaseQueryVariables>(
    PatientReferenceBaseDocument,
    options
  );
}
export type PatientReferenceBaseQueryHookResult = ReturnType<typeof usePatientReferenceBaseQuery>;
export type PatientReferenceBaseLazyQueryHookResult = ReturnType<typeof usePatientReferenceBaseLazyQuery>;
export type PatientReferenceBaseQueryResult = Apollo.QueryResult<
  PatientReferenceBaseQuery,
  PatientReferenceBaseQueryVariables
>;
export const EnrolledStatusIndicatorDocument = gql`
  query EnrolledStatusIndicator($enrollmentId: EnrollmentId!) {
    collaborativeCareSeverityBadge(enrollmentId: $enrollmentId) {
      alert {
        id
        alertType
        scale {
          id
          shortname
        }
        latest {
          id
          status
        }
      }
      target {
        id
        thresholdClass
        thresholdMnemonic
        scale {
          id
          shortname
        }
        user {
          id
          name
        }
        scorer {
          id
          scoreType
          ... on NumericalScaleScorer {
            thresholdData {
              thresholdClass
            }
          }
        }
      }
      generalFunctioning {
        id
        thresholdClass
        thresholdMnemonic
        scale {
          id
          shortname
        }
        user {
          id
          name
        }
        scorer {
          id
          scoreType
          ... on NumericalScaleScorer {
            thresholdData {
              thresholdClass
            }
          }
        }
      }
    }
  }
`;

/**
 * __useEnrolledStatusIndicatorQuery__
 *
 * To run a query within a React component, call `useEnrolledStatusIndicatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrolledStatusIndicatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrolledStatusIndicatorQuery({
 *   variables: {
 *      enrollmentId: // value for 'enrollmentId'
 *   },
 * });
 */
export function useEnrolledStatusIndicatorQuery(
  baseOptions: Apollo.QueryHookOptions<EnrolledStatusIndicatorQuery, EnrolledStatusIndicatorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnrolledStatusIndicatorQuery, EnrolledStatusIndicatorQueryVariables>(
    EnrolledStatusIndicatorDocument,
    options
  );
}
export function useEnrolledStatusIndicatorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EnrolledStatusIndicatorQuery,
    EnrolledStatusIndicatorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EnrolledStatusIndicatorQuery, EnrolledStatusIndicatorQueryVariables>(
    EnrolledStatusIndicatorDocument,
    options
  );
}
export type EnrolledStatusIndicatorQueryHookResult = ReturnType<typeof useEnrolledStatusIndicatorQuery>;
export type EnrolledStatusIndicatorLazyQueryHookResult = ReturnType<
  typeof useEnrolledStatusIndicatorLazyQuery
>;
export type EnrolledStatusIndicatorQueryResult = Apollo.QueryResult<
  EnrolledStatusIndicatorQuery,
  EnrolledStatusIndicatorQueryVariables
>;
export const TaskSearchDocument = gql`
  query TaskSearch(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $sortBy: TaskSortParameter
    $sortDirection: SortDirection
    $forPanel: PanelFilter = null
    $search: String
    $status: TaskStatus
    $assignedTo: ProviderFilter
    $patientId: [PatientId!]
    $testPatient: Boolean = false
  ) {
    collaborativeCareTasks(
      first: $first
      last: $last
      after: $after
      before: $before
      search: $search
      status: $status
      sortBy: $sortBy
      sortDirection: $sortDirection
      assignedTo: $assignedTo
      patientId: $patientId
      testPatient: $testPatient
      forPanel: $forPanel
    ) {
      nodes {
        id
        title
        body
        dueAt
        status
        isPlaceholder
        patient {
          id
          name
          isTest
          nameLastFirst
        }
        assignedTo {
          id
          name
          orderedName
        }
        notes(first: 1000) {
          nodes {
            id
            text
            author {
              id
              name
            }
            timeEntry {
              startTime
            }
            createdAt
          }
        }
        timeEntries(first: 1000) {
          nodes {
            id
            startTime
            endTime
            durationSeconds
            createdAt
            provider {
              id
              name
            }
          }
        }
        creationLog {
          id
          creationType
          patientSession {
            id
            targetDate
            patient {
              id
              name
              isTest
            }
          }
          requestedBy {
            id
            name
            orderedName
          }
          assignedBy {
            id
            name
            orderedName
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
    nonPanelTasks: collaborativeCareTasks(
      search: $search
      status: $status
      assignedTo: $assignedTo
      patientId: $patientId
      testPatient: $testPatient
    ) {
      totalCount
    }
  }
`;

/**
 * __useTaskSearchQuery__
 *
 * To run a query within a React component, call `useTaskSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskSearchQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      forPanel: // value for 'forPanel'
 *      search: // value for 'search'
 *      status: // value for 'status'
 *      assignedTo: // value for 'assignedTo'
 *      patientId: // value for 'patientId'
 *      testPatient: // value for 'testPatient'
 *   },
 * });
 */
export function useTaskSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<TaskSearchQuery, TaskSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskSearchQuery, TaskSearchQueryVariables>(TaskSearchDocument, options);
}
export function useTaskSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskSearchQuery, TaskSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskSearchQuery, TaskSearchQueryVariables>(TaskSearchDocument, options);
}
export type TaskSearchQueryHookResult = ReturnType<typeof useTaskSearchQuery>;
export type TaskSearchLazyQueryHookResult = ReturnType<typeof useTaskSearchLazyQuery>;
export type TaskSearchQueryResult = Apollo.QueryResult<TaskSearchQuery, TaskSearchQueryVariables>;
export const TaskCardBackTimelineDocument = gql`
  query TaskCardBackTimeline($taskId: TaskId!) {
    collaborativeCareTimeEntryLogs(taskId: $taskId, deleted: false) {
      nodes {
        id
        startTime
        clientStartTime
        endTime
        durationSeconds
        blockedMinutes
        workFor {
          id
          title
          body
          dueAt
          status
          isPlaceholder
          patient {
            id
            name
            isTest
          }
          assignedTo {
            id
            name
          }
          notes(first: 1000) {
            nodes {
              id
              text
              author {
                id
                name
              }
              createdAt
              timeEntry {
                startTime
              }
            }
          }
          timeEntries(first: 1000) {
            nodes {
              id
              startTime
              endTime
              durationSeconds
              createdAt
              provider {
                id
                name
              }
            }
          }
          creationLog {
            id
            creationType
            patientSession {
              id
              targetDate
              patient {
                id
                name
                isTest
              }
            }
            requestedBy {
              id
              name
            }
            assignedBy {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useTaskCardBackTimelineQuery__
 *
 * To run a query within a React component, call `useTaskCardBackTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskCardBackTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskCardBackTimelineQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskCardBackTimelineQuery(
  baseOptions: Apollo.QueryHookOptions<TaskCardBackTimelineQuery, TaskCardBackTimelineQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskCardBackTimelineQuery, TaskCardBackTimelineQueryVariables>(
    TaskCardBackTimelineDocument,
    options
  );
}
export function useTaskCardBackTimelineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskCardBackTimelineQuery, TaskCardBackTimelineQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskCardBackTimelineQuery, TaskCardBackTimelineQueryVariables>(
    TaskCardBackTimelineDocument,
    options
  );
}
export type TaskCardBackTimelineQueryHookResult = ReturnType<typeof useTaskCardBackTimelineQuery>;
export type TaskCardBackTimelineLazyQueryHookResult = ReturnType<typeof useTaskCardBackTimelineLazyQuery>;
export type TaskCardBackTimelineQueryResult = Apollo.QueryResult<
  TaskCardBackTimelineQuery,
  TaskCardBackTimelineQueryVariables
>;
export const BeginTimeEntryLogDocument = gql`
  mutation BeginTimeEntryLog($input: StartTimeEntryLogNowInput!) {
    collaborativeCareStartTimeEntryLogNow(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        myActiveTimeEntryLog {
          timeEntryLog {
            id
            startTime
            clientStartTime
          }
        }
      }
    }
  }
`;
export type BeginTimeEntryLogMutationFn = Apollo.MutationFunction<
  BeginTimeEntryLogMutation,
  BeginTimeEntryLogMutationVariables
>;

/**
 * __useBeginTimeEntryLogMutation__
 *
 * To run a mutation, you first call `useBeginTimeEntryLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBeginTimeEntryLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [beginTimeEntryLogMutation, { data, loading, error }] = useBeginTimeEntryLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBeginTimeEntryLogMutation(
  baseOptions?: Apollo.MutationHookOptions<BeginTimeEntryLogMutation, BeginTimeEntryLogMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BeginTimeEntryLogMutation, BeginTimeEntryLogMutationVariables>(
    BeginTimeEntryLogDocument,
    options
  );
}
export type BeginTimeEntryLogMutationHookResult = ReturnType<typeof useBeginTimeEntryLogMutation>;
export type BeginTimeEntryLogMutationResult = Apollo.MutationResult<BeginTimeEntryLogMutation>;
export type BeginTimeEntryLogMutationOptions = Apollo.BaseMutationOptions<
  BeginTimeEntryLogMutation,
  BeginTimeEntryLogMutationVariables
>;
export const CreateTimeEntryLogDocument = gql`
  mutation CreateTimeEntryLog($input: CreateTimeEntryLogInput!) {
    collaborativeCareCreateTimeEntryLog(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        timeEntryLog {
          id
          startTime
          endTime
          durationSeconds
          provider {
            id
          }
          workFor {
            id
            status
            timeEntries(first: 1000) {
              nodes {
                id
              }
            }
            notes(first: 1000) {
              nodes {
                id
              }
            }
          }
        }
        myActiveTimeEntryLog {
          timeEntryLog {
            id
          }
        }
      }
    }
  }
`;
export type CreateTimeEntryLogMutationFn = Apollo.MutationFunction<
  CreateTimeEntryLogMutation,
  CreateTimeEntryLogMutationVariables
>;

/**
 * __useCreateTimeEntryLogMutation__
 *
 * To run a mutation, you first call `useCreateTimeEntryLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeEntryLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeEntryLogMutation, { data, loading, error }] = useCreateTimeEntryLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTimeEntryLogMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTimeEntryLogMutation, CreateTimeEntryLogMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTimeEntryLogMutation, CreateTimeEntryLogMutationVariables>(
    CreateTimeEntryLogDocument,
    options
  );
}
export type CreateTimeEntryLogMutationHookResult = ReturnType<typeof useCreateTimeEntryLogMutation>;
export type CreateTimeEntryLogMutationResult = Apollo.MutationResult<CreateTimeEntryLogMutation>;
export type CreateTimeEntryLogMutationOptions = Apollo.BaseMutationOptions<
  CreateTimeEntryLogMutation,
  CreateTimeEntryLogMutationVariables
>;
export const DiscardTimerDocument = gql`
  mutation DiscardTimer($input: DeleteTimeEntryLogInput!) {
    collaborativeCareDeleteTimeEntryLog(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        myActiveTimeEntryLog {
          timeEntryLog {
            id
          }
        }
      }
    }
  }
`;
export type DiscardTimerMutationFn = Apollo.MutationFunction<
  DiscardTimerMutation,
  DiscardTimerMutationVariables
>;

/**
 * __useDiscardTimerMutation__
 *
 * To run a mutation, you first call `useDiscardTimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscardTimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discardTimerMutation, { data, loading, error }] = useDiscardTimerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiscardTimerMutation(
  baseOptions?: Apollo.MutationHookOptions<DiscardTimerMutation, DiscardTimerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DiscardTimerMutation, DiscardTimerMutationVariables>(
    DiscardTimerDocument,
    options
  );
}
export type DiscardTimerMutationHookResult = ReturnType<typeof useDiscardTimerMutation>;
export type DiscardTimerMutationResult = Apollo.MutationResult<DiscardTimerMutation>;
export type DiscardTimerMutationOptions = Apollo.BaseMutationOptions<
  DiscardTimerMutation,
  DiscardTimerMutationVariables
>;
export const EditTimeEntryLogDocument = gql`
  mutation EditTimeEntryLog($input: EditTimeEntryLogInput!) {
    collaborativeCareEditTimeEntryLog(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        timeEntryLog {
          id
          startTime
          endTime
          durationSeconds
          provider {
            id
          }
          workFor {
            id
            status
            notes(first: 1000) {
              nodes {
                id
              }
            }
            timeEntries(first: 1000) {
              nodes {
                id
              }
            }
          }
        }
        myActiveTimeEntryLog {
          timeEntryLog {
            id
            clientStartTime
            startTime
            durationSeconds
          }
        }
      }
    }
  }
`;
export type EditTimeEntryLogMutationFn = Apollo.MutationFunction<
  EditTimeEntryLogMutation,
  EditTimeEntryLogMutationVariables
>;

/**
 * __useEditTimeEntryLogMutation__
 *
 * To run a mutation, you first call `useEditTimeEntryLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTimeEntryLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTimeEntryLogMutation, { data, loading, error }] = useEditTimeEntryLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTimeEntryLogMutation(
  baseOptions?: Apollo.MutationHookOptions<EditTimeEntryLogMutation, EditTimeEntryLogMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditTimeEntryLogMutation, EditTimeEntryLogMutationVariables>(
    EditTimeEntryLogDocument,
    options
  );
}
export type EditTimeEntryLogMutationHookResult = ReturnType<typeof useEditTimeEntryLogMutation>;
export type EditTimeEntryLogMutationResult = Apollo.MutationResult<EditTimeEntryLogMutation>;
export type EditTimeEntryLogMutationOptions = Apollo.BaseMutationOptions<
  EditTimeEntryLogMutation,
  EditTimeEntryLogMutationVariables
>;
export const EndTimeEntryLogDocument = gql`
  mutation EndTimeEntryLog($input: EndTimeEntryLogInput!) {
    collaborativeCareEndTimeEntryLog(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        myActiveTimeEntryLog {
          timeEntryLog {
            id
          }
        }
        timeEntryLog {
          id
          startTime
          clientStartTime
          durationSeconds
          provider {
            id
          }
          workFor {
            id
            title
            body
            createdAt
            dueAt
            status
            isPlaceholder
            patient {
              id
              name
              isTest
            }
            assignedTo {
              id
              name
            }
            notes(first: 1000) {
              nodes {
                id
                text
                author {
                  id
                  name
                }
                timeEntry {
                  startTime
                }
                createdAt
              }
            }
            timeEntries(first: 1000) {
              nodes {
                id
                startTime
                endTime
                durationSeconds
                createdAt
                provider {
                  id
                  name
                }
              }
            }
            creationLog {
              id
              creationType
              patientSession {
                id
                targetDate
                patient {
                  id
                  name
                  isTest
                }
              }
              requestedBy {
                id
                name
              }
              assignedBy {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
export type EndTimeEntryLogMutationFn = Apollo.MutationFunction<
  EndTimeEntryLogMutation,
  EndTimeEntryLogMutationVariables
>;

/**
 * __useEndTimeEntryLogMutation__
 *
 * To run a mutation, you first call `useEndTimeEntryLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndTimeEntryLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endTimeEntryLogMutation, { data, loading, error }] = useEndTimeEntryLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndTimeEntryLogMutation(
  baseOptions?: Apollo.MutationHookOptions<EndTimeEntryLogMutation, EndTimeEntryLogMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EndTimeEntryLogMutation, EndTimeEntryLogMutationVariables>(
    EndTimeEntryLogDocument,
    options
  );
}
export type EndTimeEntryLogMutationHookResult = ReturnType<typeof useEndTimeEntryLogMutation>;
export type EndTimeEntryLogMutationResult = Apollo.MutationResult<EndTimeEntryLogMutation>;
export type EndTimeEntryLogMutationOptions = Apollo.BaseMutationOptions<
  EndTimeEntryLogMutation,
  EndTimeEntryLogMutationVariables
>;
export const CurrentInstituteDocument = gql`
  query CurrentInstitute {
    currentInstitute {
      ...CurrentInstituteDetails
    }
  }
  ${CurrentInstituteDetailsFragmentDoc}
`;

/**
 * __useCurrentInstituteQuery__
 *
 * To run a query within a React component, call `useCurrentInstituteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentInstituteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentInstituteQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentInstituteQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentInstituteQuery, CurrentInstituteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentInstituteQuery, CurrentInstituteQueryVariables>(
    CurrentInstituteDocument,
    options
  );
}
export function useCurrentInstituteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentInstituteQuery, CurrentInstituteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentInstituteQuery, CurrentInstituteQueryVariables>(
    CurrentInstituteDocument,
    options
  );
}
export type CurrentInstituteQueryHookResult = ReturnType<typeof useCurrentInstituteQuery>;
export type CurrentInstituteLazyQueryHookResult = ReturnType<typeof useCurrentInstituteLazyQuery>;
export type CurrentInstituteQueryResult = Apollo.QueryResult<
  CurrentInstituteQuery,
  CurrentInstituteQueryVariables
>;
export const CurrentTimeTrackingDocument = gql`
  query CurrentTimeTracking {
    collaborativeCareMyActiveTimeEntryLog {
      timeEntryLog {
        id
        workFor {
          id
          title
          patient {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useCurrentTimeTrackingQuery__
 *
 * To run a query within a React component, call `useCurrentTimeTrackingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTimeTrackingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTimeTrackingQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentTimeTrackingQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentTimeTrackingQuery, CurrentTimeTrackingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentTimeTrackingQuery, CurrentTimeTrackingQueryVariables>(
    CurrentTimeTrackingDocument,
    options
  );
}
export function useCurrentTimeTrackingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentTimeTrackingQuery, CurrentTimeTrackingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentTimeTrackingQuery, CurrentTimeTrackingQueryVariables>(
    CurrentTimeTrackingDocument,
    options
  );
}
export type CurrentTimeTrackingQueryHookResult = ReturnType<typeof useCurrentTimeTrackingQuery>;
export type CurrentTimeTrackingLazyQueryHookResult = ReturnType<typeof useCurrentTimeTrackingLazyQuery>;
export type CurrentTimeTrackingQueryResult = Apollo.QueryResult<
  CurrentTimeTrackingQuery,
  CurrentTimeTrackingQueryVariables
>;
export const EntitySummaryDocument = gql`
  query EntitySummary($id: EntityId!) {
    entity(id: $id) {
      id
      name
      entityType
    }
  }
`;

/**
 * __useEntitySummaryQuery__
 *
 * To run a query within a React component, call `useEntitySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntitySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntitySummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEntitySummaryQuery(
  baseOptions: Apollo.QueryHookOptions<EntitySummaryQuery, EntitySummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EntitySummaryQuery, EntitySummaryQueryVariables>(EntitySummaryDocument, options);
}
export function useEntitySummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EntitySummaryQuery, EntitySummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EntitySummaryQuery, EntitySummaryQueryVariables>(EntitySummaryDocument, options);
}
export type EntitySummaryQueryHookResult = ReturnType<typeof useEntitySummaryQuery>;
export type EntitySummaryLazyQueryHookResult = ReturnType<typeof useEntitySummaryLazyQuery>;
export type EntitySummaryQueryResult = Apollo.QueryResult<EntitySummaryQuery, EntitySummaryQueryVariables>;
export const CheckFirstTimePatientCreateDocument = gql`
  query CheckFirstTimePatientCreate($dataFetchId: DataFetchRequestId!) {
    integrationsDataFetchRequest(id: $dataFetchId) {
      id
      status
      dataSourceRecord {
        id
        target {
          id
          entityType
        }
      }
    }
  }
`;

/**
 * __useCheckFirstTimePatientCreateQuery__
 *
 * To run a query within a React component, call `useCheckFirstTimePatientCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckFirstTimePatientCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckFirstTimePatientCreateQuery({
 *   variables: {
 *      dataFetchId: // value for 'dataFetchId'
 *   },
 * });
 */
export function useCheckFirstTimePatientCreateQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckFirstTimePatientCreateQuery,
    CheckFirstTimePatientCreateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckFirstTimePatientCreateQuery, CheckFirstTimePatientCreateQueryVariables>(
    CheckFirstTimePatientCreateDocument,
    options
  );
}
export function useCheckFirstTimePatientCreateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckFirstTimePatientCreateQuery,
    CheckFirstTimePatientCreateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckFirstTimePatientCreateQuery, CheckFirstTimePatientCreateQueryVariables>(
    CheckFirstTimePatientCreateDocument,
    options
  );
}
export type CheckFirstTimePatientCreateQueryHookResult = ReturnType<
  typeof useCheckFirstTimePatientCreateQuery
>;
export type CheckFirstTimePatientCreateLazyQueryHookResult = ReturnType<
  typeof useCheckFirstTimePatientCreateLazyQuery
>;
export type CheckFirstTimePatientCreateQueryResult = Apollo.QueryResult<
  CheckFirstTimePatientCreateQuery,
  CheckFirstTimePatientCreateQueryVariables
>;
export const ExternalPatientSearchDocument = gql`
  query ExternalPatientSearch($externalId: String!) {
    patients(withAnyIdentifier: $externalId) {
      nodes {
        id
        hasCompletedAssessment
      }
    }
  }
`;

/**
 * __useExternalPatientSearchQuery__
 *
 * To run a query within a React component, call `useExternalPatientSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalPatientSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalPatientSearchQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useExternalPatientSearchQuery(
  baseOptions: Apollo.QueryHookOptions<ExternalPatientSearchQuery, ExternalPatientSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExternalPatientSearchQuery, ExternalPatientSearchQueryVariables>(
    ExternalPatientSearchDocument,
    options
  );
}
export function useExternalPatientSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExternalPatientSearchQuery, ExternalPatientSearchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExternalPatientSearchQuery, ExternalPatientSearchQueryVariables>(
    ExternalPatientSearchDocument,
    options
  );
}
export type ExternalPatientSearchQueryHookResult = ReturnType<typeof useExternalPatientSearchQuery>;
export type ExternalPatientSearchLazyQueryHookResult = ReturnType<typeof useExternalPatientSearchLazyQuery>;
export type ExternalPatientSearchQueryResult = Apollo.QueryResult<
  ExternalPatientSearchQuery,
  ExternalPatientSearchQueryVariables
>;
export const RecentFailedLoginsDocument = gql`
  query RecentFailedLogins(
    $sortBy: BusinessEventSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    businessEvents(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      last: $last
      before: $before
      after: $after
      eventName: "patient.failedLogin"
    ) {
      nodes {
        id
        eventDate
        patient {
          id
          dob
          lastSsn
          lastName
        }
        patientSession {
          id
          targetDate
        }
        data
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useRecentFailedLoginsQuery__
 *
 * To run a query within a React component, call `useRecentFailedLoginsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentFailedLoginsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentFailedLoginsQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useRecentFailedLoginsQuery(
  baseOptions?: Apollo.QueryHookOptions<RecentFailedLoginsQuery, RecentFailedLoginsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecentFailedLoginsQuery, RecentFailedLoginsQueryVariables>(
    RecentFailedLoginsDocument,
    options
  );
}
export function useRecentFailedLoginsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecentFailedLoginsQuery, RecentFailedLoginsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecentFailedLoginsQuery, RecentFailedLoginsQueryVariables>(
    RecentFailedLoginsDocument,
    options
  );
}
export type RecentFailedLoginsQueryHookResult = ReturnType<typeof useRecentFailedLoginsQuery>;
export type RecentFailedLoginsLazyQueryHookResult = ReturnType<typeof useRecentFailedLoginsLazyQuery>;
export type RecentFailedLoginsQueryResult = Apollo.QueryResult<
  RecentFailedLoginsQuery,
  RecentFailedLoginsQueryVariables
>;
export const FeedbackReportCareEpisodeQueryDocument = gql`
  query FeedbackReportCareEpisodeQuery($careEpisodeId: CareEpisodeId!) {
    assessmentCareEpisodeScaleSummary(careEpisodeId: $careEpisodeId) {
      id
      careEpisode {
        ...CareEpisodeDetailFragment
        careEpisodeProviders {
          provider {
            ...ProviderSummaryFragment
          }
        }
        patientConditions {
          id
          instituteCondition {
            id
            name
            code
          }
          patient {
            id
          }
          status
          onsetDate
          abatementDate
        }
        patient {
          id
          name
          phoneNumber
          email
          primaryLanguage
          gender
          genderIdentity
          genderIdentityFhir
          firstName
          lastName
          legalFirstName
          legalLastName
          preferredFirstName
          preferredLastName
          preferredPronouns
          dob
        }
      }
      administrationDates
      activeInvitations {
        id
        status
        phase
        user {
          ...UserSummaryFragment
        }
      }
    }
  }
  ${CareEpisodeDetailFragmentFragmentDoc}
  ${ProviderSummaryFragmentFragmentDoc}
  ${UserSummaryFragmentFragmentDoc}
`;

/**
 * __useFeedbackReportCareEpisodeQueryQuery__
 *
 * To run a query within a React component, call `useFeedbackReportCareEpisodeQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackReportCareEpisodeQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackReportCareEpisodeQueryQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *   },
 * });
 */
export function useFeedbackReportCareEpisodeQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    FeedbackReportCareEpisodeQueryQuery,
    FeedbackReportCareEpisodeQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FeedbackReportCareEpisodeQueryQuery, FeedbackReportCareEpisodeQueryQueryVariables>(
    FeedbackReportCareEpisodeQueryDocument,
    options
  );
}
export function useFeedbackReportCareEpisodeQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeedbackReportCareEpisodeQueryQuery,
    FeedbackReportCareEpisodeQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeedbackReportCareEpisodeQueryQuery,
    FeedbackReportCareEpisodeQueryQueryVariables
  >(FeedbackReportCareEpisodeQueryDocument, options);
}
export type FeedbackReportCareEpisodeQueryQueryHookResult = ReturnType<
  typeof useFeedbackReportCareEpisodeQueryQuery
>;
export type FeedbackReportCareEpisodeQueryLazyQueryHookResult = ReturnType<
  typeof useFeedbackReportCareEpisodeQueryLazyQuery
>;
export type FeedbackReportCareEpisodeQueryQueryResult = Apollo.QueryResult<
  FeedbackReportCareEpisodeQueryQuery,
  FeedbackReportCareEpisodeQueryQueryVariables
>;
export const ClinicalAlertHistoryQueryDocument = gql`
  query ClinicalAlertHistoryQuery($careEpisodeClinicalAlertHistoryId: CareEpisodeScaleId!) {
    assessmentCareEpisodeClinicalAlertHistory(
      careEpisodeClinicalAlertHistoryId: $careEpisodeClinicalAlertHistoryId
    ) {
      ...ClinicalAlertHistoryFragment
    }
  }
  ${ClinicalAlertHistoryFragmentFragmentDoc}
`;

/**
 * __useClinicalAlertHistoryQueryQuery__
 *
 * To run a query within a React component, call `useClinicalAlertHistoryQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicalAlertHistoryQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicalAlertHistoryQueryQuery({
 *   variables: {
 *      careEpisodeClinicalAlertHistoryId: // value for 'careEpisodeClinicalAlertHistoryId'
 *   },
 * });
 */
export function useClinicalAlertHistoryQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClinicalAlertHistoryQueryQuery,
    ClinicalAlertHistoryQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ClinicalAlertHistoryQueryQuery, ClinicalAlertHistoryQueryQueryVariables>(
    ClinicalAlertHistoryQueryDocument,
    options
  );
}
export function useClinicalAlertHistoryQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClinicalAlertHistoryQueryQuery,
    ClinicalAlertHistoryQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ClinicalAlertHistoryQueryQuery, ClinicalAlertHistoryQueryQueryVariables>(
    ClinicalAlertHistoryQueryDocument,
    options
  );
}
export type ClinicalAlertHistoryQueryQueryHookResult = ReturnType<typeof useClinicalAlertHistoryQueryQuery>;
export type ClinicalAlertHistoryQueryLazyQueryHookResult = ReturnType<
  typeof useClinicalAlertHistoryQueryLazyQuery
>;
export type ClinicalAlertHistoryQueryQueryResult = Apollo.QueryResult<
  ClinicalAlertHistoryQueryQuery,
  ClinicalAlertHistoryQueryQueryVariables
>;
export const GoalDetailQueryDocument = gql`
  query GoalDetailQuery($goalId: GoalId!) {
    assessmentGoal(id: $goalId) {
      ...GoalFragment
    }
  }
  ${GoalFragmentFragmentDoc}
`;

/**
 * __useGoalDetailQueryQuery__
 *
 * To run a query within a React component, call `useGoalDetailQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalDetailQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalDetailQueryQuery({
 *   variables: {
 *      goalId: // value for 'goalId'
 *   },
 * });
 */
export function useGoalDetailQueryQuery(
  baseOptions: Apollo.QueryHookOptions<GoalDetailQueryQuery, GoalDetailQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GoalDetailQueryQuery, GoalDetailQueryQueryVariables>(
    GoalDetailQueryDocument,
    options
  );
}
export function useGoalDetailQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GoalDetailQueryQuery, GoalDetailQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GoalDetailQueryQuery, GoalDetailQueryQueryVariables>(
    GoalDetailQueryDocument,
    options
  );
}
export type GoalDetailQueryQueryHookResult = ReturnType<typeof useGoalDetailQueryQuery>;
export type GoalDetailQueryLazyQueryHookResult = ReturnType<typeof useGoalDetailQueryLazyQuery>;
export type GoalDetailQueryQueryResult = Apollo.QueryResult<
  GoalDetailQueryQuery,
  GoalDetailQueryQueryVariables
>;
export const OverviewAlertsQueryDocument = gql`
  query OverviewAlertsQuery($careEpisodeId: CareEpisodeId!) {
    assessmentCareEpisodeScaleSummary(careEpisodeId: $careEpisodeId) {
      id
      alerts {
        ...ClinicalAlertHistoryFragment
      }
    }
  }
  ${ClinicalAlertHistoryFragmentFragmentDoc}
`;

/**
 * __useOverviewAlertsQueryQuery__
 *
 * To run a query within a React component, call `useOverviewAlertsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewAlertsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewAlertsQueryQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *   },
 * });
 */
export function useOverviewAlertsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<OverviewAlertsQueryQuery, OverviewAlertsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OverviewAlertsQueryQuery, OverviewAlertsQueryQueryVariables>(
    OverviewAlertsQueryDocument,
    options
  );
}
export function useOverviewAlertsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OverviewAlertsQueryQuery, OverviewAlertsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OverviewAlertsQueryQuery, OverviewAlertsQueryQueryVariables>(
    OverviewAlertsQueryDocument,
    options
  );
}
export type OverviewAlertsQueryQueryHookResult = ReturnType<typeof useOverviewAlertsQueryQuery>;
export type OverviewAlertsQueryLazyQueryHookResult = ReturnType<typeof useOverviewAlertsQueryLazyQuery>;
export type OverviewAlertsQueryQueryResult = Apollo.QueryResult<
  OverviewAlertsQueryQuery,
  OverviewAlertsQueryQueryVariables
>;
export const OverviewConstructsQueryDocument = gql`
  query OverviewConstructsQuery($careEpisodeId: CareEpisodeId!) {
    assessmentCareEpisodeScaleSummary(careEpisodeId: $careEpisodeId) {
      id
      specialSections {
        sectionType
        scaleSections {
          ... on CareEpisodeScaleResult {
            ...ConstructScaleDetails
          }
          ... on CareEpisodeScaleGroupResult {
            scaleGroup {
              id
              name
              description
              shortname
              displayMode
            }
            byScale {
              ...ConstructScaleDetails
            }
          }
        }
      }
      constructs {
        construct {
          ...ConstructSummary
        }
        scaleSections {
          ... on CareEpisodeScaleResult {
            ...ConstructScaleDetails
          }
          ... on CareEpisodeScaleGroupResult {
            scaleGroup {
              id
              name
              description
              shortname
              displayMode
            }
            byScale {
              ...ConstructScaleDetails
            }
          }
        }
      }
    }
  }
  ${ConstructScaleDetailsFragmentDoc}
  ${ConstructSummaryFragmentDoc}
`;

/**
 * __useOverviewConstructsQueryQuery__
 *
 * To run a query within a React component, call `useOverviewConstructsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewConstructsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewConstructsQueryQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *   },
 * });
 */
export function useOverviewConstructsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<OverviewConstructsQueryQuery, OverviewConstructsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OverviewConstructsQueryQuery, OverviewConstructsQueryQueryVariables>(
    OverviewConstructsQueryDocument,
    options
  );
}
export function useOverviewConstructsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OverviewConstructsQueryQuery,
    OverviewConstructsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OverviewConstructsQueryQuery, OverviewConstructsQueryQueryVariables>(
    OverviewConstructsQueryDocument,
    options
  );
}
export type OverviewConstructsQueryQueryHookResult = ReturnType<typeof useOverviewConstructsQueryQuery>;
export type OverviewConstructsQueryLazyQueryHookResult = ReturnType<
  typeof useOverviewConstructsQueryLazyQuery
>;
export type OverviewConstructsQueryQueryResult = Apollo.QueryResult<
  OverviewConstructsQueryQuery,
  OverviewConstructsQueryQueryVariables
>;
export const OverviewGoalsQueryDocument = gql`
  query OverviewGoalsQuery($careEpisodeId: CareEpisodeId!) {
    assessmentCareEpisodeScaleSummary(careEpisodeId: $careEpisodeId) {
      id
      goals {
        ...GoalFragment
      }
    }
  }
  ${GoalFragmentFragmentDoc}
`;

/**
 * __useOverviewGoalsQueryQuery__
 *
 * To run a query within a React component, call `useOverviewGoalsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewGoalsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewGoalsQueryQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *   },
 * });
 */
export function useOverviewGoalsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<OverviewGoalsQueryQuery, OverviewGoalsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OverviewGoalsQueryQuery, OverviewGoalsQueryQueryVariables>(
    OverviewGoalsQueryDocument,
    options
  );
}
export function useOverviewGoalsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OverviewGoalsQueryQuery, OverviewGoalsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OverviewGoalsQueryQuery, OverviewGoalsQueryQueryVariables>(
    OverviewGoalsQueryDocument,
    options
  );
}
export type OverviewGoalsQueryQueryHookResult = ReturnType<typeof useOverviewGoalsQueryQuery>;
export type OverviewGoalsQueryLazyQueryHookResult = ReturnType<typeof useOverviewGoalsQueryLazyQuery>;
export type OverviewGoalsQueryQueryResult = Apollo.QueryResult<
  OverviewGoalsQueryQuery,
  OverviewGoalsQueryQueryVariables
>;
export const OverviewTreatmentResponseQueryDocument = gql`
  query OverviewTreatmentResponseQuery($careEpisodeId: CareEpisodeId!) {
    assessmentCareEpisodeScaleSummary(careEpisodeId: $careEpisodeId) {
      id
      targetSections {
        ... on CareEpisodeScaleResult {
          ...TargetScaleDetails
        }
        ... on CareEpisodeScaleGroupResult {
          scaleGroup {
            id
            name
            description
            shortname
            displayMode
          }
          byScale {
            ...TargetScaleDetails
          }
        }
      }
    }
  }
  ${TargetScaleDetailsFragmentDoc}
`;

/**
 * __useOverviewTreatmentResponseQueryQuery__
 *
 * To run a query within a React component, call `useOverviewTreatmentResponseQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewTreatmentResponseQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewTreatmentResponseQueryQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *   },
 * });
 */
export function useOverviewTreatmentResponseQueryQuery(
  baseOptions: Apollo.QueryHookOptions<
    OverviewTreatmentResponseQueryQuery,
    OverviewTreatmentResponseQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OverviewTreatmentResponseQueryQuery, OverviewTreatmentResponseQueryQueryVariables>(
    OverviewTreatmentResponseQueryDocument,
    options
  );
}
export function useOverviewTreatmentResponseQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OverviewTreatmentResponseQueryQuery,
    OverviewTreatmentResponseQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OverviewTreatmentResponseQueryQuery,
    OverviewTreatmentResponseQueryQueryVariables
  >(OverviewTreatmentResponseQueryDocument, options);
}
export type OverviewTreatmentResponseQueryQueryHookResult = ReturnType<
  typeof useOverviewTreatmentResponseQueryQuery
>;
export type OverviewTreatmentResponseQueryLazyQueryHookResult = ReturnType<
  typeof useOverviewTreatmentResponseQueryLazyQuery
>;
export type OverviewTreatmentResponseQueryQueryResult = Apollo.QueryResult<
  OverviewTreatmentResponseQueryQuery,
  OverviewTreatmentResponseQueryQueryVariables
>;
export const ScaleCompletionQueryDocument = gql`
  query ScaleCompletionQuery($scaleCompletionId: CareEpisodeComputedValueId!) {
    assessmentCareEpisodeComputedValue(id: $scaleCompletionId) {
      ...DetailedScaleCompletionFragment
      scale {
        parentId
        mnemonic
        id
        name
        shortname
        nanoname
        friendlyName
        measureType
        reportShortDescription
        role
        questions {
          id
          title
          questionType
          reportText
        }
      }
    }
  }
  ${DetailedScaleCompletionFragmentFragmentDoc}
`;

/**
 * __useScaleCompletionQueryQuery__
 *
 * To run a query within a React component, call `useScaleCompletionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useScaleCompletionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScaleCompletionQueryQuery({
 *   variables: {
 *      scaleCompletionId: // value for 'scaleCompletionId'
 *   },
 * });
 */
export function useScaleCompletionQueryQuery(
  baseOptions: Apollo.QueryHookOptions<ScaleCompletionQueryQuery, ScaleCompletionQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScaleCompletionQueryQuery, ScaleCompletionQueryQueryVariables>(
    ScaleCompletionQueryDocument,
    options
  );
}
export function useScaleCompletionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScaleCompletionQueryQuery, ScaleCompletionQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScaleCompletionQueryQuery, ScaleCompletionQueryQueryVariables>(
    ScaleCompletionQueryDocument,
    options
  );
}
export type ScaleCompletionQueryQueryHookResult = ReturnType<typeof useScaleCompletionQueryQuery>;
export type ScaleCompletionQueryLazyQueryHookResult = ReturnType<typeof useScaleCompletionQueryLazyQuery>;
export type ScaleCompletionQueryQueryResult = Apollo.QueryResult<
  ScaleCompletionQueryQuery,
  ScaleCompletionQueryQueryVariables
>;
export const ScaleGroupHistoryQueryDocument = gql`
  query ScaleGroupHistoryQuery($careEpisodeId: CareEpisodeId!, $scaleGroupId: ScaleGroupId!) {
    assessmentCareEpisodeScaleGroupDetail(careEpisodeId: $careEpisodeId, scaleGroupId: $scaleGroupId) {
      careEpisodeScaleGroupResult {
        scaleGroup {
          ...ScaleGroupSummaryFragment
        }
        byScale {
          scale {
            id
            mnemonic
            parentId
            name
            shortname
            nanoname
            friendlyName
            measureType
            role
            reportShortDescription
            questions {
              id
              title
              reportText
              questionType
            }
          }
          byUser {
            user {
              id
              name
            }
            byScorer {
              scorer {
                domain
                ... on NumericalScaleScorer {
                  minValue
                  maxValue
                  minInterpretation
                  maxInterpretation
                  minInterpretationShort
                  maxInterpretationShort
                  thresholdData {
                    mnemonic
                    thresholdClass
                    minValue
                    maxValue
                  }
                }
              }
              latest {
                ...DetailedScaleScore
              }
              history {
                ...CareEpisodeComputedValueDetails
              }
            }
          }
        }
      }
    }
  }
  ${ScaleGroupSummaryFragmentFragmentDoc}
  ${DetailedScaleScoreFragmentDoc}
  ${CareEpisodeComputedValueDetailsFragmentDoc}
`;

/**
 * __useScaleGroupHistoryQueryQuery__
 *
 * To run a query within a React component, call `useScaleGroupHistoryQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useScaleGroupHistoryQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScaleGroupHistoryQueryQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *      scaleGroupId: // value for 'scaleGroupId'
 *   },
 * });
 */
export function useScaleGroupHistoryQueryQuery(
  baseOptions: Apollo.QueryHookOptions<ScaleGroupHistoryQueryQuery, ScaleGroupHistoryQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScaleGroupHistoryQueryQuery, ScaleGroupHistoryQueryQueryVariables>(
    ScaleGroupHistoryQueryDocument,
    options
  );
}
export function useScaleGroupHistoryQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScaleGroupHistoryQueryQuery, ScaleGroupHistoryQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScaleGroupHistoryQueryQuery, ScaleGroupHistoryQueryQueryVariables>(
    ScaleGroupHistoryQueryDocument,
    options
  );
}
export type ScaleGroupHistoryQueryQueryHookResult = ReturnType<typeof useScaleGroupHistoryQueryQuery>;
export type ScaleGroupHistoryQueryLazyQueryHookResult = ReturnType<typeof useScaleGroupHistoryQueryLazyQuery>;
export type ScaleGroupHistoryQueryQueryResult = Apollo.QueryResult<
  ScaleGroupHistoryQueryQuery,
  ScaleGroupHistoryQueryQueryVariables
>;
export const ScaleScoreQueryDocument = gql`
  query ScaleScoreQuery($careEpisodeComputedValueId: CareEpisodeComputedValueId!) {
    assessmentCareEpisodeComputedValue(id: $careEpisodeComputedValueId) {
      ...CareEpisodeComputedValueWithScaleAndAnswers
    }
  }
  ${CareEpisodeComputedValueWithScaleAndAnswersFragmentDoc}
`;

/**
 * __useScaleScoreQueryQuery__
 *
 * To run a query within a React component, call `useScaleScoreQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useScaleScoreQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScaleScoreQueryQuery({
 *   variables: {
 *      careEpisodeComputedValueId: // value for 'careEpisodeComputedValueId'
 *   },
 * });
 */
export function useScaleScoreQueryQuery(
  baseOptions: Apollo.QueryHookOptions<ScaleScoreQueryQuery, ScaleScoreQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScaleScoreQueryQuery, ScaleScoreQueryQueryVariables>(
    ScaleScoreQueryDocument,
    options
  );
}
export function useScaleScoreQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScaleScoreQueryQuery, ScaleScoreQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScaleScoreQueryQuery, ScaleScoreQueryQueryVariables>(
    ScaleScoreQueryDocument,
    options
  );
}
export type ScaleScoreQueryQueryHookResult = ReturnType<typeof useScaleScoreQueryQuery>;
export type ScaleScoreQueryLazyQueryHookResult = ReturnType<typeof useScaleScoreQueryLazyQuery>;
export type ScaleScoreQueryQueryResult = Apollo.QueryResult<
  ScaleScoreQueryQuery,
  ScaleScoreQueryQueryVariables
>;
export const ScaleScorerHistoryDocument = gql`
  query ScaleScorerHistory($careEpisodeId: CareEpisodeId!, $scaleId: ScaleId!) {
    assessmentCareEpisodeScaleDetail(careEpisodeId: $careEpisodeId, scaleId: $scaleId) {
      careEpisodeScaleResult {
        scale {
          id
          mnemonic
          parentId
          name
          shortname
          nanoname
          friendlyName
          measureType
          reportShortDescription
          role
          questions {
            id
            title
            reportText
            questionType
          }
        }
        byUser {
          user {
            id
            name
          }
          byScorer {
            scorer {
              domain
              ... on NumericalScaleScorer {
                minValue
                maxValue
                minInterpretation
                maxInterpretation
                minInterpretationShort
                maxInterpretationShort
                thresholdData {
                  mnemonic
                  thresholdClass
                  minValue
                  maxValue
                }
              }
            }
            latest {
              ...DetailedScaleScore
            }
            history {
              ...CareEpisodeComputedValueDetails
            }
          }
        }
      }
    }
  }
  ${DetailedScaleScoreFragmentDoc}
  ${CareEpisodeComputedValueDetailsFragmentDoc}
`;

/**
 * __useScaleScorerHistoryQuery__
 *
 * To run a query within a React component, call `useScaleScorerHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useScaleScorerHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScaleScorerHistoryQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *      scaleId: // value for 'scaleId'
 *   },
 * });
 */
export function useScaleScorerHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<ScaleScorerHistoryQuery, ScaleScorerHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScaleScorerHistoryQuery, ScaleScorerHistoryQueryVariables>(
    ScaleScorerHistoryDocument,
    options
  );
}
export function useScaleScorerHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScaleScorerHistoryQuery, ScaleScorerHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScaleScorerHistoryQuery, ScaleScorerHistoryQueryVariables>(
    ScaleScorerHistoryDocument,
    options
  );
}
export type ScaleScorerHistoryQueryHookResult = ReturnType<typeof useScaleScorerHistoryQuery>;
export type ScaleScorerHistoryLazyQueryHookResult = ReturnType<typeof useScaleScorerHistoryLazyQuery>;
export type ScaleScorerHistoryQueryResult = Apollo.QueryResult<
  ScaleScorerHistoryQuery,
  ScaleScorerHistoryQueryVariables
>;
export const UpdateGoalMutationDocument = gql`
  mutation UpdateGoalMutation($goal: UpdateGoalInput!) {
    assessmentUpdateGoal(input: $goal) {
      success
      errors {
        message
        path
      }
      result {
        goal {
          ...GoalFragment
        }
      }
    }
  }
  ${GoalFragmentFragmentDoc}
`;
export type UpdateGoalMutationMutationFn = Apollo.MutationFunction<
  UpdateGoalMutationMutation,
  UpdateGoalMutationMutationVariables
>;

/**
 * __useUpdateGoalMutationMutation__
 *
 * To run a mutation, you first call `useUpdateGoalMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoalMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoalMutationMutation, { data, loading, error }] = useUpdateGoalMutationMutation({
 *   variables: {
 *      goal: // value for 'goal'
 *   },
 * });
 */
export function useUpdateGoalMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateGoalMutationMutation, UpdateGoalMutationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGoalMutationMutation, UpdateGoalMutationMutationVariables>(
    UpdateGoalMutationDocument,
    options
  );
}
export type UpdateGoalMutationMutationHookResult = ReturnType<typeof useUpdateGoalMutationMutation>;
export type UpdateGoalMutationMutationResult = Apollo.MutationResult<UpdateGoalMutationMutation>;
export type UpdateGoalMutationMutationOptions = Apollo.BaseMutationOptions<
  UpdateGoalMutationMutation,
  UpdateGoalMutationMutationVariables
>;
export const UpdateGoalStatusDocument = gql`
  mutation UpdateGoalStatus($input: UpdateGoalInput!) {
    assessmentUpdateGoal(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        goal {
          ...GoalFragment
        }
      }
    }
  }
  ${GoalFragmentFragmentDoc}
`;
export type UpdateGoalStatusMutationFn = Apollo.MutationFunction<
  UpdateGoalStatusMutation,
  UpdateGoalStatusMutationVariables
>;

/**
 * __useUpdateGoalStatusMutation__
 *
 * To run a mutation, you first call `useUpdateGoalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGoalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGoalStatusMutation, { data, loading, error }] = useUpdateGoalStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGoalStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateGoalStatusMutation, UpdateGoalStatusMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGoalStatusMutation, UpdateGoalStatusMutationVariables>(
    UpdateGoalStatusDocument,
    options
  );
}
export type UpdateGoalStatusMutationHookResult = ReturnType<typeof useUpdateGoalStatusMutation>;
export type UpdateGoalStatusMutationResult = Apollo.MutationResult<UpdateGoalStatusMutation>;
export type UpdateGoalStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateGoalStatusMutation,
  UpdateGoalStatusMutationVariables
>;
export const FeedbackReportProgressNoteTextDocument = gql`
  query FeedbackReportProgressNoteText($careEpisodeId: CareEpisodeId!) {
    assessmentLatestReportText(careEpisodeId: $careEpisodeId) {
      plainText
    }
  }
`;

/**
 * __useFeedbackReportProgressNoteTextQuery__
 *
 * To run a query within a React component, call `useFeedbackReportProgressNoteTextQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeedbackReportProgressNoteTextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeedbackReportProgressNoteTextQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *   },
 * });
 */
export function useFeedbackReportProgressNoteTextQuery(
  baseOptions: Apollo.QueryHookOptions<
    FeedbackReportProgressNoteTextQuery,
    FeedbackReportProgressNoteTextQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FeedbackReportProgressNoteTextQuery, FeedbackReportProgressNoteTextQueryVariables>(
    FeedbackReportProgressNoteTextDocument,
    options
  );
}
export function useFeedbackReportProgressNoteTextLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FeedbackReportProgressNoteTextQuery,
    FeedbackReportProgressNoteTextQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FeedbackReportProgressNoteTextQuery,
    FeedbackReportProgressNoteTextQueryVariables
  >(FeedbackReportProgressNoteTextDocument, options);
}
export type FeedbackReportProgressNoteTextQueryHookResult = ReturnType<
  typeof useFeedbackReportProgressNoteTextQuery
>;
export type FeedbackReportProgressNoteTextLazyQueryHookResult = ReturnType<
  typeof useFeedbackReportProgressNoteTextLazyQuery
>;
export type FeedbackReportProgressNoteTextQueryResult = Apollo.QueryResult<
  FeedbackReportProgressNoteTextQuery,
  FeedbackReportProgressNoteTextQueryVariables
>;
export const CreateGoalMutationDocument = gql`
  mutation CreateGoalMutation($goal: CreateGoalInput!) {
    assessmentCreateGoal(input: $goal) {
      success
      errors {
        message
        path
      }
      result {
        goal {
          ...GoalFragment
        }
      }
    }
  }
  ${GoalFragmentFragmentDoc}
`;
export type CreateGoalMutationMutationFn = Apollo.MutationFunction<
  CreateGoalMutationMutation,
  CreateGoalMutationMutationVariables
>;

/**
 * __useCreateGoalMutationMutation__
 *
 * To run a mutation, you first call `useCreateGoalMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoalMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoalMutationMutation, { data, loading, error }] = useCreateGoalMutationMutation({
 *   variables: {
 *      goal: // value for 'goal'
 *   },
 * });
 */
export function useCreateGoalMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateGoalMutationMutation, CreateGoalMutationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGoalMutationMutation, CreateGoalMutationMutationVariables>(
    CreateGoalMutationDocument,
    options
  );
}
export type CreateGoalMutationMutationHookResult = ReturnType<typeof useCreateGoalMutationMutation>;
export type CreateGoalMutationMutationResult = Apollo.MutationResult<CreateGoalMutationMutation>;
export type CreateGoalMutationMutationOptions = Apollo.BaseMutationOptions<
  CreateGoalMutationMutation,
  CreateGoalMutationMutationVariables
>;
export const ReportRedirectDocument = gql`
  query ReportRedirect($patientId: PatientId!) {
    patient(id: $patientId) {
      latestCareEpisode {
        id
      }
    }
  }
`;

/**
 * __useReportRedirectQuery__
 *
 * To run a query within a React component, call `useReportRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportRedirectQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useReportRedirectQuery(
  baseOptions: Apollo.QueryHookOptions<ReportRedirectQuery, ReportRedirectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReportRedirectQuery, ReportRedirectQueryVariables>(ReportRedirectDocument, options);
}
export function useReportRedirectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReportRedirectQuery, ReportRedirectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReportRedirectQuery, ReportRedirectQueryVariables>(
    ReportRedirectDocument,
    options
  );
}
export type ReportRedirectQueryHookResult = ReturnType<typeof useReportRedirectQuery>;
export type ReportRedirectLazyQueryHookResult = ReturnType<typeof useReportRedirectLazyQuery>;
export type ReportRedirectQueryResult = Apollo.QueryResult<ReportRedirectQuery, ReportRedirectQueryVariables>;
export const CreateImplementationTargetDocument = gql`
  mutation CreateImplementationTarget($input: CreateImplementationTargetInput!) {
    implementationCreateImplementationTarget(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        implementationTarget {
          id
          status
          name
          mirahInternal
          description
          entityTreeNode {
            ...EntityTreeNodeDetails
          }
          targetType
        }
      }
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
`;
export type CreateImplementationTargetMutationFn = Apollo.MutationFunction<
  CreateImplementationTargetMutation,
  CreateImplementationTargetMutationVariables
>;

/**
 * __useCreateImplementationTargetMutation__
 *
 * To run a mutation, you first call `useCreateImplementationTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateImplementationTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createImplementationTargetMutation, { data, loading, error }] = useCreateImplementationTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateImplementationTargetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateImplementationTargetMutation,
    CreateImplementationTargetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateImplementationTargetMutation, CreateImplementationTargetMutationVariables>(
    CreateImplementationTargetDocument,
    options
  );
}
export type CreateImplementationTargetMutationHookResult = ReturnType<
  typeof useCreateImplementationTargetMutation
>;
export type CreateImplementationTargetMutationResult =
  Apollo.MutationResult<CreateImplementationTargetMutation>;
export type CreateImplementationTargetMutationOptions = Apollo.BaseMutationOptions<
  CreateImplementationTargetMutation,
  CreateImplementationTargetMutationVariables
>;
export const EmailImplementationTargetReportDocument = gql`
  mutation EmailImplementationTargetReport($input: EmailImplementationTargetReportInput!) {
    implementationEmailImplementationTargetReport(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        sentToEmails
      }
    }
  }
`;
export type EmailImplementationTargetReportMutationFn = Apollo.MutationFunction<
  EmailImplementationTargetReportMutation,
  EmailImplementationTargetReportMutationVariables
>;

/**
 * __useEmailImplementationTargetReportMutation__
 *
 * To run a mutation, you first call `useEmailImplementationTargetReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailImplementationTargetReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailImplementationTargetReportMutation, { data, loading, error }] = useEmailImplementationTargetReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailImplementationTargetReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EmailImplementationTargetReportMutation,
    EmailImplementationTargetReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EmailImplementationTargetReportMutation,
    EmailImplementationTargetReportMutationVariables
  >(EmailImplementationTargetReportDocument, options);
}
export type EmailImplementationTargetReportMutationHookResult = ReturnType<
  typeof useEmailImplementationTargetReportMutation
>;
export type EmailImplementationTargetReportMutationResult =
  Apollo.MutationResult<EmailImplementationTargetReportMutation>;
export type EmailImplementationTargetReportMutationOptions = Apollo.BaseMutationOptions<
  EmailImplementationTargetReportMutation,
  EmailImplementationTargetReportMutationVariables
>;
export const ImplementationTargetDashboardDocument = gql`
  query ImplementationTargetDashboard(
    $entityQuery: EntityTreeNodeQueryParams
    $availableForNode: EntityTreeNodeParams
    $targetType: [ImplementationTargetType!]
    $date: ISO8601Date!
    $status: [ImplementationTargetStatus!]!
    $hideProviderInformation: Boolean
    $includeMirahInternal: Boolean
    $first: Int = null
    $last: Int = null
    $before: String = null
    $after: String = null
  ) {
    implementationTargets(
      activeForDate: $date
      entityQuery: $entityQuery
      availableForNode: $availableForNode
      targetType: $targetType
      hideProviderInformation: $hideProviderInformation
      includeMirahInternal: $includeMirahInternal
      sortBy: DISPLAY_ORDER
      status: $status
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      nodes {
        id
        name
        description
        displayOrder
        targetType
        startDate
        initialCredits
        softLaunchDate
        target
        mirahInternal
        entityTreeNode {
          ...EntityTreeNodeDetails
        }
        closestReportDateTo(date: $date) {
          endDate
          period
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
`;

/**
 * __useImplementationTargetDashboardQuery__
 *
 * To run a query within a React component, call `useImplementationTargetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useImplementationTargetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImplementationTargetDashboardQuery({
 *   variables: {
 *      entityQuery: // value for 'entityQuery'
 *      availableForNode: // value for 'availableForNode'
 *      targetType: // value for 'targetType'
 *      date: // value for 'date'
 *      status: // value for 'status'
 *      hideProviderInformation: // value for 'hideProviderInformation'
 *      includeMirahInternal: // value for 'includeMirahInternal'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useImplementationTargetDashboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImplementationTargetDashboardQuery,
    ImplementationTargetDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ImplementationTargetDashboardQuery, ImplementationTargetDashboardQueryVariables>(
    ImplementationTargetDashboardDocument,
    options
  );
}
export function useImplementationTargetDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImplementationTargetDashboardQuery,
    ImplementationTargetDashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ImplementationTargetDashboardQuery, ImplementationTargetDashboardQueryVariables>(
    ImplementationTargetDashboardDocument,
    options
  );
}
export type ImplementationTargetDashboardQueryHookResult = ReturnType<
  typeof useImplementationTargetDashboardQuery
>;
export type ImplementationTargetDashboardLazyQueryHookResult = ReturnType<
  typeof useImplementationTargetDashboardLazyQuery
>;
export type ImplementationTargetDashboardQueryResult = Apollo.QueryResult<
  ImplementationTargetDashboardQuery,
  ImplementationTargetDashboardQueryVariables
>;
export const ImplementationTargetDetailsDocument = gql`
  query ImplementationTargetDetails($implementationTargetId: ImplementationTargetId!) {
    implementationTarget(id: $implementationTargetId) {
      id
      name
      description
      status
      target
      startDate
      targetType
      softLaunchDate
      initialCredits
      mirahInternal
      entityTreeNode {
        ...EntityTreeNodeDetails
      }
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
`;

/**
 * __useImplementationTargetDetailsQuery__
 *
 * To run a query within a React component, call `useImplementationTargetDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImplementationTargetDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImplementationTargetDetailsQuery({
 *   variables: {
 *      implementationTargetId: // value for 'implementationTargetId'
 *   },
 * });
 */
export function useImplementationTargetDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImplementationTargetDetailsQuery,
    ImplementationTargetDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ImplementationTargetDetailsQuery, ImplementationTargetDetailsQueryVariables>(
    ImplementationTargetDetailsDocument,
    options
  );
}
export function useImplementationTargetDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImplementationTargetDetailsQuery,
    ImplementationTargetDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ImplementationTargetDetailsQuery, ImplementationTargetDetailsQueryVariables>(
    ImplementationTargetDetailsDocument,
    options
  );
}
export type ImplementationTargetDetailsQueryHookResult = ReturnType<
  typeof useImplementationTargetDetailsQuery
>;
export type ImplementationTargetDetailsLazyQueryHookResult = ReturnType<
  typeof useImplementationTargetDetailsLazyQuery
>;
export type ImplementationTargetDetailsQueryResult = Apollo.QueryResult<
  ImplementationTargetDetailsQuery,
  ImplementationTargetDetailsQueryVariables
>;
export const ImplementationTargetReportDataDetailsDocument = gql`
  query ImplementationTargetReportDataDetails(
    $implementationTargetId: ImplementationTargetId!
    $entityTreeNode: EntityTreeNodeParams!
    $dateAndPeriod: ImplementationTargetReportInput!
  ) {
    implementationTargetReport(
      implementationTargetId: $implementationTargetId
      entityTreeNode: $entityTreeNode
      dateAndPeriod: $dateAndPeriod
    ) {
      id
      implementationTarget {
        id
      }
      onTrack
      reportDate {
        startDate
        period
      }
      graphData {
        name
        seriesType
        points {
          date
          value
        }
      }
      graphMaxValue
      headlineStats {
        value
        period
        denominator
        units
        unitClass
      }
      insights {
        summary
        insightType
      }
      entityTreeNode {
        ...EntityTreeNodeDetails
      }
      breakdowns {
        collection {
          collection
        }
        nodes {
          node {
            ...EntityTreeNodeDetails
          }
          values {
            value
            displayValue
            series
          }
        }
      }
      breakdownMaxValue
      unitClass
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
`;

/**
 * __useImplementationTargetReportDataDetailsQuery__
 *
 * To run a query within a React component, call `useImplementationTargetReportDataDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImplementationTargetReportDataDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImplementationTargetReportDataDetailsQuery({
 *   variables: {
 *      implementationTargetId: // value for 'implementationTargetId'
 *      entityTreeNode: // value for 'entityTreeNode'
 *      dateAndPeriod: // value for 'dateAndPeriod'
 *   },
 * });
 */
export function useImplementationTargetReportDataDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImplementationTargetReportDataDetailsQuery,
    ImplementationTargetReportDataDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ImplementationTargetReportDataDetailsQuery,
    ImplementationTargetReportDataDetailsQueryVariables
  >(ImplementationTargetReportDataDetailsDocument, options);
}
export function useImplementationTargetReportDataDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImplementationTargetReportDataDetailsQuery,
    ImplementationTargetReportDataDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImplementationTargetReportDataDetailsQuery,
    ImplementationTargetReportDataDetailsQueryVariables
  >(ImplementationTargetReportDataDetailsDocument, options);
}
export type ImplementationTargetReportDataDetailsQueryHookResult = ReturnType<
  typeof useImplementationTargetReportDataDetailsQuery
>;
export type ImplementationTargetReportDataDetailsLazyQueryHookResult = ReturnType<
  typeof useImplementationTargetReportDataDetailsLazyQuery
>;
export type ImplementationTargetReportDataDetailsQueryResult = Apollo.QueryResult<
  ImplementationTargetReportDataDetailsQuery,
  ImplementationTargetReportDataDetailsQueryVariables
>;
export const ImplementationTargetReportDataSummaryDocument = gql`
  query ImplementationTargetReportDataSummary(
    $implementationTargetId: ImplementationTargetId!
    $entityTreeNode: EntityTreeNodeParams
    $dateAndPeriod: ImplementationTargetReportInput!
  ) {
    implementationTargetReport(
      implementationTargetId: $implementationTargetId
      entityTreeNode: $entityTreeNode
      dateAndPeriod: $dateAndPeriod
    ) {
      id
      implementationTarget {
        id
      }
      onTrack
      unitClass
      reportDate {
        startDate
        period
      }
      graphData {
        name
        seriesType
        points {
          date
          value
        }
      }
      graphMaxValue
      headlineStats {
        value
        period
        denominator
        units
        unitClass
      }
      entityTreeNode {
        path
      }
    }
  }
`;

/**
 * __useImplementationTargetReportDataSummaryQuery__
 *
 * To run a query within a React component, call `useImplementationTargetReportDataSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useImplementationTargetReportDataSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImplementationTargetReportDataSummaryQuery({
 *   variables: {
 *      implementationTargetId: // value for 'implementationTargetId'
 *      entityTreeNode: // value for 'entityTreeNode'
 *      dateAndPeriod: // value for 'dateAndPeriod'
 *   },
 * });
 */
export function useImplementationTargetReportDataSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImplementationTargetReportDataSummaryQuery,
    ImplementationTargetReportDataSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ImplementationTargetReportDataSummaryQuery,
    ImplementationTargetReportDataSummaryQueryVariables
  >(ImplementationTargetReportDataSummaryDocument, options);
}
export function useImplementationTargetReportDataSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImplementationTargetReportDataSummaryQuery,
    ImplementationTargetReportDataSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImplementationTargetReportDataSummaryQuery,
    ImplementationTargetReportDataSummaryQueryVariables
  >(ImplementationTargetReportDataSummaryDocument, options);
}
export type ImplementationTargetReportDataSummaryQueryHookResult = ReturnType<
  typeof useImplementationTargetReportDataSummaryQuery
>;
export type ImplementationTargetReportDataSummaryLazyQueryHookResult = ReturnType<
  typeof useImplementationTargetReportDataSummaryLazyQuery
>;
export type ImplementationTargetReportDataSummaryQueryResult = Apollo.QueryResult<
  ImplementationTargetReportDataSummaryQuery,
  ImplementationTargetReportDataSummaryQueryVariables
>;
export const ImplementationTargetWidgetDocument = gql`
  query ImplementationTargetWidget(
    $implementationTargetType: ImplementationTargetType!
    $entityTreeNode: EntityTreeNodeParams!
    $date: ISO8601Date!
    $allowMirahInternal: Boolean
  ) {
    implementationTargetBestReport(
      implementationTargetType: $implementationTargetType
      entityTreeNode: $entityTreeNode
      date: $date
      allowMirahInternal: $allowMirahInternal
    ) {
      id
      implementationTarget {
        id
        name
        targetType
        entityTreeNode {
          ...EntityTreeNodeDetails
        }
      }
      onTrack
      unitClass
      reportDate {
        startDate
        endDate
        period
      }
      graphData {
        name
        seriesType
        points {
          date
          value
        }
      }
      graphMaxValue
      headlineStats {
        value
        period
        denominator
        units
        unitClass
      }
      entityTreeNode {
        ...EntityTreeNodeDetails
      }
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
`;

/**
 * __useImplementationTargetWidgetQuery__
 *
 * To run a query within a React component, call `useImplementationTargetWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useImplementationTargetWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImplementationTargetWidgetQuery({
 *   variables: {
 *      implementationTargetType: // value for 'implementationTargetType'
 *      entityTreeNode: // value for 'entityTreeNode'
 *      date: // value for 'date'
 *      allowMirahInternal: // value for 'allowMirahInternal'
 *   },
 * });
 */
export function useImplementationTargetWidgetQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImplementationTargetWidgetQuery,
    ImplementationTargetWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ImplementationTargetWidgetQuery, ImplementationTargetWidgetQueryVariables>(
    ImplementationTargetWidgetDocument,
    options
  );
}
export function useImplementationTargetWidgetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImplementationTargetWidgetQuery,
    ImplementationTargetWidgetQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ImplementationTargetWidgetQuery, ImplementationTargetWidgetQueryVariables>(
    ImplementationTargetWidgetDocument,
    options
  );
}
export type ImplementationTargetWidgetQueryHookResult = ReturnType<typeof useImplementationTargetWidgetQuery>;
export type ImplementationTargetWidgetLazyQueryHookResult = ReturnType<
  typeof useImplementationTargetWidgetLazyQuery
>;
export type ImplementationTargetWidgetQueryResult = Apollo.QueryResult<
  ImplementationTargetWidgetQuery,
  ImplementationTargetWidgetQueryVariables
>;
export const ImplementationTargetWithReportDateDetailsDocument = gql`
  query ImplementationTargetWithReportDateDetails(
    $implementationTargetId: ImplementationTargetId!
    $date: ISO8601Date!
  ) {
    implementationTarget(id: $implementationTargetId) {
      id
      name
      description
      status
      target
      startDate
      targetType
      softLaunchDate
      initialCredits
      entityTreeNode {
        ...EntityTreeNodeDetails
      }
      closestReportDateTo(date: $date) {
        endDate
        period
      }
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
`;

/**
 * __useImplementationTargetWithReportDateDetailsQuery__
 *
 * To run a query within a React component, call `useImplementationTargetWithReportDateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImplementationTargetWithReportDateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImplementationTargetWithReportDateDetailsQuery({
 *   variables: {
 *      implementationTargetId: // value for 'implementationTargetId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useImplementationTargetWithReportDateDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ImplementationTargetWithReportDateDetailsQuery,
    ImplementationTargetWithReportDateDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ImplementationTargetWithReportDateDetailsQuery,
    ImplementationTargetWithReportDateDetailsQueryVariables
  >(ImplementationTargetWithReportDateDetailsDocument, options);
}
export function useImplementationTargetWithReportDateDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ImplementationTargetWithReportDateDetailsQuery,
    ImplementationTargetWithReportDateDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ImplementationTargetWithReportDateDetailsQuery,
    ImplementationTargetWithReportDateDetailsQueryVariables
  >(ImplementationTargetWithReportDateDetailsDocument, options);
}
export type ImplementationTargetWithReportDateDetailsQueryHookResult = ReturnType<
  typeof useImplementationTargetWithReportDateDetailsQuery
>;
export type ImplementationTargetWithReportDateDetailsLazyQueryHookResult = ReturnType<
  typeof useImplementationTargetWithReportDateDetailsLazyQuery
>;
export type ImplementationTargetWithReportDateDetailsQueryResult = Apollo.QueryResult<
  ImplementationTargetWithReportDateDetailsQuery,
  ImplementationTargetWithReportDateDetailsQueryVariables
>;
export const UpdateImplementationTargetDocument = gql`
  mutation UpdateImplementationTarget($input: UpdateImplementationTargetInput!) {
    implementationUpdateImplementationTarget(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        implementationTarget {
          id
          status
          name
          description
          entityTreeNode {
            ...EntityTreeNodeDetails
          }
          mirahInternal
          targetType
        }
      }
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
`;
export type UpdateImplementationTargetMutationFn = Apollo.MutationFunction<
  UpdateImplementationTargetMutation,
  UpdateImplementationTargetMutationVariables
>;

/**
 * __useUpdateImplementationTargetMutation__
 *
 * To run a mutation, you first call `useUpdateImplementationTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImplementationTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImplementationTargetMutation, { data, loading, error }] = useUpdateImplementationTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateImplementationTargetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateImplementationTargetMutation,
    UpdateImplementationTargetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateImplementationTargetMutation, UpdateImplementationTargetMutationVariables>(
    UpdateImplementationTargetDocument,
    options
  );
}
export type UpdateImplementationTargetMutationHookResult = ReturnType<
  typeof useUpdateImplementationTargetMutation
>;
export type UpdateImplementationTargetMutationResult =
  Apollo.MutationResult<UpdateImplementationTargetMutation>;
export type UpdateImplementationTargetMutationOptions = Apollo.BaseMutationOptions<
  UpdateImplementationTargetMutation,
  UpdateImplementationTargetMutationVariables
>;
export const UpdateImplementationTargetStatusDocument = gql`
  mutation UpdateImplementationTargetStatus($input: UpdateImplementationTargetStatusInput!) {
    implementationUpdateImplementationTargetStatus(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        implementationTarget {
          id
          status
        }
      }
    }
  }
`;
export type UpdateImplementationTargetStatusMutationFn = Apollo.MutationFunction<
  UpdateImplementationTargetStatusMutation,
  UpdateImplementationTargetStatusMutationVariables
>;

/**
 * __useUpdateImplementationTargetStatusMutation__
 *
 * To run a mutation, you first call `useUpdateImplementationTargetStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImplementationTargetStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImplementationTargetStatusMutation, { data, loading, error }] = useUpdateImplementationTargetStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateImplementationTargetStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateImplementationTargetStatusMutation,
    UpdateImplementationTargetStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateImplementationTargetStatusMutation,
    UpdateImplementationTargetStatusMutationVariables
  >(UpdateImplementationTargetStatusDocument, options);
}
export type UpdateImplementationTargetStatusMutationHookResult = ReturnType<
  typeof useUpdateImplementationTargetStatusMutation
>;
export type UpdateImplementationTargetStatusMutationResult =
  Apollo.MutationResult<UpdateImplementationTargetStatusMutation>;
export type UpdateImplementationTargetStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateImplementationTargetStatusMutation,
  UpdateImplementationTargetStatusMutationVariables
>;
export const CreateOrganizationDocument = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        organization {
          id
          name
          shortname
          parentId
          inTrialPeriod
          measurementAllowed
          timezone
        }
      }
    }
  }
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(
    CreateOrganizationDocument,
    options
  );
}
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const EditOrganizationDocument = gql`
  mutation EditOrganization($input: EditOrganizationInput!) {
    editOrganization(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        organization {
          id
          name
          shortname
          parentId
          inTrialPeriod
          measurementAllowed
          timezone
        }
      }
    }
  }
`;
export type EditOrganizationMutationFn = Apollo.MutationFunction<
  EditOrganizationMutation,
  EditOrganizationMutationVariables
>;

/**
 * __useEditOrganizationMutation__
 *
 * To run a mutation, you first call `useEditOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrganizationMutation, { data, loading, error }] = useEditOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<EditOrganizationMutation, EditOrganizationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditOrganizationMutation, EditOrganizationMutationVariables>(
    EditOrganizationDocument,
    options
  );
}
export type EditOrganizationMutationHookResult = ReturnType<typeof useEditOrganizationMutation>;
export type EditOrganizationMutationResult = Apollo.MutationResult<EditOrganizationMutation>;
export type EditOrganizationMutationOptions = Apollo.BaseMutationOptions<
  EditOrganizationMutation,
  EditOrganizationMutationVariables
>;
export const AssociateTreatmentServiceDocument = gql`
  query AssociateTreatmentService($treatmentServiceId: TreatmentServiceId!) {
    treatmentService(id: $treatmentServiceId) {
      id
      name
      parentId
      measurementAllowed
      providers {
        id
      }
      organizations {
        id
      }
      instituteConditions {
        id
      }
    }
    organizations {
      nodes {
        id
        name
      }
    }
    providers {
      nodes {
        id
        name
      }
    }
    instituteConditions {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useAssociateTreatmentServiceQuery__
 *
 * To run a query within a React component, call `useAssociateTreatmentServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssociateTreatmentServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssociateTreatmentServiceQuery({
 *   variables: {
 *      treatmentServiceId: // value for 'treatmentServiceId'
 *   },
 * });
 */
export function useAssociateTreatmentServiceQuery(
  baseOptions: Apollo.QueryHookOptions<
    AssociateTreatmentServiceQuery,
    AssociateTreatmentServiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssociateTreatmentServiceQuery, AssociateTreatmentServiceQueryVariables>(
    AssociateTreatmentServiceDocument,
    options
  );
}
export function useAssociateTreatmentServiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssociateTreatmentServiceQuery,
    AssociateTreatmentServiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssociateTreatmentServiceQuery, AssociateTreatmentServiceQueryVariables>(
    AssociateTreatmentServiceDocument,
    options
  );
}
export type AssociateTreatmentServiceQueryHookResult = ReturnType<typeof useAssociateTreatmentServiceQuery>;
export type AssociateTreatmentServiceLazyQueryHookResult = ReturnType<
  typeof useAssociateTreatmentServiceLazyQuery
>;
export type AssociateTreatmentServiceQueryResult = Apollo.QueryResult<
  AssociateTreatmentServiceQuery,
  AssociateTreatmentServiceQueryVariables
>;
export const UpdateTreatmentServiceAssociationsDocument = gql`
  mutation UpdateTreatmentServiceAssociations(
    $inputOrganizations: UpdateTreatmentServiceOrganizationsInput!
    $inputProviders: UpdateTreatmentServiceProvidersInput!
    $inputInstituteConditions: UpdateTreatmentServiceInstituteConditionsInput!
  ) {
    updateTreatmentServiceOrganizations(input: $inputOrganizations) {
      success
      errors {
        message
        path
      }
      result {
        treatmentService {
          id
          organizations {
            id
            name
          }
        }
      }
    }
    updateTreatmentServiceProviders(input: $inputProviders) {
      errors {
        message
        path
      }
      result {
        treatmentService {
          id
          providers {
            id
            name
          }
        }
      }
      success
    }
    updateTreatmentServiceInstituteConditions(input: $inputInstituteConditions) {
      errors {
        message
        path
      }
      result {
        treatmentService {
          id
          instituteConditions {
            id
            name
          }
        }
      }
      success
    }
  }
`;
export type UpdateTreatmentServiceAssociationsMutationFn = Apollo.MutationFunction<
  UpdateTreatmentServiceAssociationsMutation,
  UpdateTreatmentServiceAssociationsMutationVariables
>;

/**
 * __useUpdateTreatmentServiceAssociationsMutation__
 *
 * To run a mutation, you first call `useUpdateTreatmentServiceAssociationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTreatmentServiceAssociationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTreatmentServiceAssociationsMutation, { data, loading, error }] = useUpdateTreatmentServiceAssociationsMutation({
 *   variables: {
 *      inputOrganizations: // value for 'inputOrganizations'
 *      inputProviders: // value for 'inputProviders'
 *      inputInstituteConditions: // value for 'inputInstituteConditions'
 *   },
 * });
 */
export function useUpdateTreatmentServiceAssociationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTreatmentServiceAssociationsMutation,
    UpdateTreatmentServiceAssociationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTreatmentServiceAssociationsMutation,
    UpdateTreatmentServiceAssociationsMutationVariables
  >(UpdateTreatmentServiceAssociationsDocument, options);
}
export type UpdateTreatmentServiceAssociationsMutationHookResult = ReturnType<
  typeof useUpdateTreatmentServiceAssociationsMutation
>;
export type UpdateTreatmentServiceAssociationsMutationResult =
  Apollo.MutationResult<UpdateTreatmentServiceAssociationsMutation>;
export type UpdateTreatmentServiceAssociationsMutationOptions = Apollo.BaseMutationOptions<
  UpdateTreatmentServiceAssociationsMutation,
  UpdateTreatmentServiceAssociationsMutationVariables
>;
export const CreateTreatmentServiceDocument = gql`
  mutation CreateTreatmentService($input: CreateTreatmentServiceInput!) {
    createTreatmentService(input: $input) {
      clientMutationId
      errors {
        message
        path
      }
      result {
        treatmentService {
          name
        }
      }
      success
    }
  }
`;
export type CreateTreatmentServiceMutationFn = Apollo.MutationFunction<
  CreateTreatmentServiceMutation,
  CreateTreatmentServiceMutationVariables
>;

/**
 * __useCreateTreatmentServiceMutation__
 *
 * To run a mutation, you first call `useCreateTreatmentServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTreatmentServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTreatmentServiceMutation, { data, loading, error }] = useCreateTreatmentServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTreatmentServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTreatmentServiceMutation,
    CreateTreatmentServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTreatmentServiceMutation, CreateTreatmentServiceMutationVariables>(
    CreateTreatmentServiceDocument,
    options
  );
}
export type CreateTreatmentServiceMutationHookResult = ReturnType<typeof useCreateTreatmentServiceMutation>;
export type CreateTreatmentServiceMutationResult = Apollo.MutationResult<CreateTreatmentServiceMutation>;
export type CreateTreatmentServiceMutationOptions = Apollo.BaseMutationOptions<
  CreateTreatmentServiceMutation,
  CreateTreatmentServiceMutationVariables
>;
export const TreatmentServiceDocument = gql`
  query TreatmentService($treatmentServiceId: TreatmentServiceId!) {
    treatmentService(id: $treatmentServiceId) {
      id
      name
      parentId
      measurementAllowed
    }
  }
`;

/**
 * __useTreatmentServiceQuery__
 *
 * To run a query within a React component, call `useTreatmentServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentServiceQuery({
 *   variables: {
 *      treatmentServiceId: // value for 'treatmentServiceId'
 *   },
 * });
 */
export function useTreatmentServiceQuery(
  baseOptions: Apollo.QueryHookOptions<TreatmentServiceQuery, TreatmentServiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TreatmentServiceQuery, TreatmentServiceQueryVariables>(
    TreatmentServiceDocument,
    options
  );
}
export function useTreatmentServiceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TreatmentServiceQuery, TreatmentServiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TreatmentServiceQuery, TreatmentServiceQueryVariables>(
    TreatmentServiceDocument,
    options
  );
}
export type TreatmentServiceQueryHookResult = ReturnType<typeof useTreatmentServiceQuery>;
export type TreatmentServiceLazyQueryHookResult = ReturnType<typeof useTreatmentServiceLazyQuery>;
export type TreatmentServiceQueryResult = Apollo.QueryResult<
  TreatmentServiceQuery,
  TreatmentServiceQueryVariables
>;
export const TreatmentServicesDocument = gql`
  query TreatmentServices {
    treatmentServices {
      nodes {
        name
        id
        measurementAllowed
        parentId
        serviceTypes
        instituteConditions {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useTreatmentServicesQuery__
 *
 * To run a query within a React component, call `useTreatmentServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTreatmentServicesQuery(
  baseOptions?: Apollo.QueryHookOptions<TreatmentServicesQuery, TreatmentServicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TreatmentServicesQuery, TreatmentServicesQueryVariables>(
    TreatmentServicesDocument,
    options
  );
}
export function useTreatmentServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TreatmentServicesQuery, TreatmentServicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TreatmentServicesQuery, TreatmentServicesQueryVariables>(
    TreatmentServicesDocument,
    options
  );
}
export type TreatmentServicesQueryHookResult = ReturnType<typeof useTreatmentServicesQuery>;
export type TreatmentServicesLazyQueryHookResult = ReturnType<typeof useTreatmentServicesLazyQuery>;
export type TreatmentServicesQueryResult = Apollo.QueryResult<
  TreatmentServicesQuery,
  TreatmentServicesQueryVariables
>;
export const UpdateTreatmentServiceRequiredDataDocument = gql`
  query UpdateTreatmentServiceRequiredData($treatmentServiceId: TreatmentServiceId!) {
    treatmentService(id: $treatmentServiceId) {
      id
      name
      parentId
      measurementAllowed
      serviceTypes
    }
    treatmentServices {
      nodes {
        name
        id
      }
    }
  }
`;

/**
 * __useUpdateTreatmentServiceRequiredDataQuery__
 *
 * To run a query within a React component, call `useUpdateTreatmentServiceRequiredDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpdateTreatmentServiceRequiredDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpdateTreatmentServiceRequiredDataQuery({
 *   variables: {
 *      treatmentServiceId: // value for 'treatmentServiceId'
 *   },
 * });
 */
export function useUpdateTreatmentServiceRequiredDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    UpdateTreatmentServiceRequiredDataQuery,
    UpdateTreatmentServiceRequiredDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UpdateTreatmentServiceRequiredDataQuery,
    UpdateTreatmentServiceRequiredDataQueryVariables
  >(UpdateTreatmentServiceRequiredDataDocument, options);
}
export function useUpdateTreatmentServiceRequiredDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpdateTreatmentServiceRequiredDataQuery,
    UpdateTreatmentServiceRequiredDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UpdateTreatmentServiceRequiredDataQuery,
    UpdateTreatmentServiceRequiredDataQueryVariables
  >(UpdateTreatmentServiceRequiredDataDocument, options);
}
export type UpdateTreatmentServiceRequiredDataQueryHookResult = ReturnType<
  typeof useUpdateTreatmentServiceRequiredDataQuery
>;
export type UpdateTreatmentServiceRequiredDataLazyQueryHookResult = ReturnType<
  typeof useUpdateTreatmentServiceRequiredDataLazyQuery
>;
export type UpdateTreatmentServiceRequiredDataQueryResult = Apollo.QueryResult<
  UpdateTreatmentServiceRequiredDataQuery,
  UpdateTreatmentServiceRequiredDataQueryVariables
>;
export const UpdateTreatmentServiceDocument = gql`
  mutation UpdateTreatmentService($input: UpdateTreatmentServiceInput!) {
    updateTreatmentService(input: $input) {
      errors {
        message
        path
      }
      result {
        treatmentService {
          id
          name
          parentId
          measurementAllowed
          serviceTypes
        }
      }
      success
      clientMutationId
    }
  }
`;
export type UpdateTreatmentServiceMutationFn = Apollo.MutationFunction<
  UpdateTreatmentServiceMutation,
  UpdateTreatmentServiceMutationVariables
>;

/**
 * __useUpdateTreatmentServiceMutation__
 *
 * To run a mutation, you first call `useUpdateTreatmentServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTreatmentServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTreatmentServiceMutation, { data, loading, error }] = useUpdateTreatmentServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTreatmentServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTreatmentServiceMutation,
    UpdateTreatmentServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTreatmentServiceMutation, UpdateTreatmentServiceMutationVariables>(
    UpdateTreatmentServiceDocument,
    options
  );
}
export type UpdateTreatmentServiceMutationHookResult = ReturnType<typeof useUpdateTreatmentServiceMutation>;
export type UpdateTreatmentServiceMutationResult = Apollo.MutationResult<UpdateTreatmentServiceMutation>;
export type UpdateTreatmentServiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateTreatmentServiceMutation,
  UpdateTreatmentServiceMutationVariables
>;
export const IntegrationDataSourceRecordLogDetailDocument = gql`
  query IntegrationDataSourceRecordLogDetail($dataSourceRecordLogId: DataSourceRecordLogId!) {
    integrationsDataSourceRecordLog(id: $dataSourceRecordLogId) {
      id
      createdAt
      status
      matchStatus
      raw
      transformErrors {
        field
        errors
      }
      dataSourceRecordAlgorithmLogs {
        id
        algorithm
        algorithmCode
        statusName
        statusDescription
      }
    }
  }
`;

/**
 * __useIntegrationDataSourceRecordLogDetailQuery__
 *
 * To run a query within a React component, call `useIntegrationDataSourceRecordLogDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationDataSourceRecordLogDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationDataSourceRecordLogDetailQuery({
 *   variables: {
 *      dataSourceRecordLogId: // value for 'dataSourceRecordLogId'
 *   },
 * });
 */
export function useIntegrationDataSourceRecordLogDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    IntegrationDataSourceRecordLogDetailQuery,
    IntegrationDataSourceRecordLogDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IntegrationDataSourceRecordLogDetailQuery,
    IntegrationDataSourceRecordLogDetailQueryVariables
  >(IntegrationDataSourceRecordLogDetailDocument, options);
}
export function useIntegrationDataSourceRecordLogDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntegrationDataSourceRecordLogDetailQuery,
    IntegrationDataSourceRecordLogDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IntegrationDataSourceRecordLogDetailQuery,
    IntegrationDataSourceRecordLogDetailQueryVariables
  >(IntegrationDataSourceRecordLogDetailDocument, options);
}
export type IntegrationDataSourceRecordLogDetailQueryHookResult = ReturnType<
  typeof useIntegrationDataSourceRecordLogDetailQuery
>;
export type IntegrationDataSourceRecordLogDetailLazyQueryHookResult = ReturnType<
  typeof useIntegrationDataSourceRecordLogDetailLazyQuery
>;
export type IntegrationDataSourceRecordLogDetailQueryResult = Apollo.QueryResult<
  IntegrationDataSourceRecordLogDetailQuery,
  IntegrationDataSourceRecordLogDetailQueryVariables
>;
export const IntegrationDataSourceRecordLogHistoryDocument = gql`
  query IntegrationDataSourceRecordLogHistory(
    $importHistoryId: ImportHistoryId!
    $status: [DataSourceRecordLogStatus!]
    $sortBy: DataSourceRecordLogSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    integrationsDataSourceRecordLogs(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      importHistoryId: [$importHistoryId]
      status: $status
    ) {
      nodes {
        id
        createdAt
        status
        matchStatus
        raw
        transformErrors {
          field
          errors
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useIntegrationDataSourceRecordLogHistoryQuery__
 *
 * To run a query within a React component, call `useIntegrationDataSourceRecordLogHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationDataSourceRecordLogHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationDataSourceRecordLogHistoryQuery({
 *   variables: {
 *      importHistoryId: // value for 'importHistoryId'
 *      status: // value for 'status'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useIntegrationDataSourceRecordLogHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    IntegrationDataSourceRecordLogHistoryQuery,
    IntegrationDataSourceRecordLogHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IntegrationDataSourceRecordLogHistoryQuery,
    IntegrationDataSourceRecordLogHistoryQueryVariables
  >(IntegrationDataSourceRecordLogHistoryDocument, options);
}
export function useIntegrationDataSourceRecordLogHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntegrationDataSourceRecordLogHistoryQuery,
    IntegrationDataSourceRecordLogHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IntegrationDataSourceRecordLogHistoryQuery,
    IntegrationDataSourceRecordLogHistoryQueryVariables
  >(IntegrationDataSourceRecordLogHistoryDocument, options);
}
export type IntegrationDataSourceRecordLogHistoryQueryHookResult = ReturnType<
  typeof useIntegrationDataSourceRecordLogHistoryQuery
>;
export type IntegrationDataSourceRecordLogHistoryLazyQueryHookResult = ReturnType<
  typeof useIntegrationDataSourceRecordLogHistoryLazyQuery
>;
export type IntegrationDataSourceRecordLogHistoryQueryResult = Apollo.QueryResult<
  IntegrationDataSourceRecordLogHistoryQuery,
  IntegrationDataSourceRecordLogHistoryQueryVariables
>;
export const IntegrationStatusBadgeDocument = gql`
  query IntegrationStatusBadge($id: ImportHistoryId!, $resourceType: DataSourceResourceType!) {
    integrationsImportHistory(id: $id, resourceType: $resourceType) {
      id
      name
      source
      lastImportDate
      updatedAt
      createdBy {
        id
        name
      }
      lastUpdatedBy {
        id
        name
      }
    }
  }
`;

/**
 * __useIntegrationStatusBadgeQuery__
 *
 * To run a query within a React component, call `useIntegrationStatusBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationStatusBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationStatusBadgeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      resourceType: // value for 'resourceType'
 *   },
 * });
 */
export function useIntegrationStatusBadgeQuery(
  baseOptions: Apollo.QueryHookOptions<IntegrationStatusBadgeQuery, IntegrationStatusBadgeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IntegrationStatusBadgeQuery, IntegrationStatusBadgeQueryVariables>(
    IntegrationStatusBadgeDocument,
    options
  );
}
export function useIntegrationStatusBadgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IntegrationStatusBadgeQuery, IntegrationStatusBadgeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IntegrationStatusBadgeQuery, IntegrationStatusBadgeQueryVariables>(
    IntegrationStatusBadgeDocument,
    options
  );
}
export type IntegrationStatusBadgeQueryHookResult = ReturnType<typeof useIntegrationStatusBadgeQuery>;
export type IntegrationStatusBadgeLazyQueryHookResult = ReturnType<typeof useIntegrationStatusBadgeLazyQuery>;
export type IntegrationStatusBadgeQueryResult = Apollo.QueryResult<
  IntegrationStatusBadgeQuery,
  IntegrationStatusBadgeQueryVariables
>;
export const IntegrationStatusBadgeInlineDocument = gql`
  query IntegrationStatusBadgeInline($id: ImportHistoryId!, $resourceType: DataSourceResourceType!) {
    integrationsImportHistory(id: $id, resourceType: $resourceType) {
      id
      name
      source
      lastImportDate
      updatedAt
    }
  }
`;

/**
 * __useIntegrationStatusBadgeInlineQuery__
 *
 * To run a query within a React component, call `useIntegrationStatusBadgeInlineQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationStatusBadgeInlineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationStatusBadgeInlineQuery({
 *   variables: {
 *      id: // value for 'id'
 *      resourceType: // value for 'resourceType'
 *   },
 * });
 */
export function useIntegrationStatusBadgeInlineQuery(
  baseOptions: Apollo.QueryHookOptions<
    IntegrationStatusBadgeInlineQuery,
    IntegrationStatusBadgeInlineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IntegrationStatusBadgeInlineQuery, IntegrationStatusBadgeInlineQueryVariables>(
    IntegrationStatusBadgeInlineDocument,
    options
  );
}
export function useIntegrationStatusBadgeInlineLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IntegrationStatusBadgeInlineQuery,
    IntegrationStatusBadgeInlineQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IntegrationStatusBadgeInlineQuery, IntegrationStatusBadgeInlineQueryVariables>(
    IntegrationStatusBadgeInlineDocument,
    options
  );
}
export type IntegrationStatusBadgeInlineQueryHookResult = ReturnType<
  typeof useIntegrationStatusBadgeInlineQuery
>;
export type IntegrationStatusBadgeInlineLazyQueryHookResult = ReturnType<
  typeof useIntegrationStatusBadgeInlineLazyQuery
>;
export type IntegrationStatusBadgeInlineQueryResult = Apollo.QueryResult<
  IntegrationStatusBadgeInlineQuery,
  IntegrationStatusBadgeInlineQueryVariables
>;
export const IntegrationStatusLogDocument = gql`
  query IntegrationStatusLog($id: ImportHistoryId!, $resourceType: DataSourceResourceType!) {
    integrationsImportHistory(id: $id, resourceType: $resourceType) {
      id
      name
      resourceType
      source
      lastImportDate
      updatedAt
      integrationsDataSourceRecords {
        id
        remoteId
        effectiveSince
        dataSourceResource {
          dataSource {
            id
            name
          }
        }
      }
      integrationsDataSourceRecordLogs(sortBy: CREATED_AT, sortDirection: DESC, first: 1) {
        nodes {
          id
          createdAt
          status
          matchStatus
          raw
          transformErrors {
            field
            errors
          }
          dataSourceRecordAlgorithmLogs {
            id
            algorithm
            algorithmCode
            statusName
            statusDescription
          }
        }
      }
    }
  }
`;

/**
 * __useIntegrationStatusLogQuery__
 *
 * To run a query within a React component, call `useIntegrationStatusLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationStatusLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationStatusLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *      resourceType: // value for 'resourceType'
 *   },
 * });
 */
export function useIntegrationStatusLogQuery(
  baseOptions: Apollo.QueryHookOptions<IntegrationStatusLogQuery, IntegrationStatusLogQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IntegrationStatusLogQuery, IntegrationStatusLogQueryVariables>(
    IntegrationStatusLogDocument,
    options
  );
}
export function useIntegrationStatusLogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IntegrationStatusLogQuery, IntegrationStatusLogQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IntegrationStatusLogQuery, IntegrationStatusLogQueryVariables>(
    IntegrationStatusLogDocument,
    options
  );
}
export type IntegrationStatusLogQueryHookResult = ReturnType<typeof useIntegrationStatusLogQuery>;
export type IntegrationStatusLogLazyQueryHookResult = ReturnType<typeof useIntegrationStatusLogLazyQuery>;
export type IntegrationStatusLogQueryResult = Apollo.QueryResult<
  IntegrationStatusLogQuery,
  IntegrationStatusLogQueryVariables
>;
export const MagicInspectorPathDocument = gql`
  query MagicInspectorPath($text: String!) {
    magicInspectorPath(text: $text)
  }
`;

/**
 * __useMagicInspectorPathQuery__
 *
 * To run a query within a React component, call `useMagicInspectorPathQuery` and pass it any options that fit your needs.
 * When your component renders, `useMagicInspectorPathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMagicInspectorPathQuery({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useMagicInspectorPathQuery(
  baseOptions: Apollo.QueryHookOptions<MagicInspectorPathQuery, MagicInspectorPathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MagicInspectorPathQuery, MagicInspectorPathQueryVariables>(
    MagicInspectorPathDocument,
    options
  );
}
export function useMagicInspectorPathLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MagicInspectorPathQuery, MagicInspectorPathQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MagicInspectorPathQuery, MagicInspectorPathQueryVariables>(
    MagicInspectorPathDocument,
    options
  );
}
export type MagicInspectorPathQueryHookResult = ReturnType<typeof useMagicInspectorPathQuery>;
export type MagicInspectorPathLazyQueryHookResult = ReturnType<typeof useMagicInspectorPathLazyQuery>;
export type MagicInspectorPathQueryResult = Apollo.QueryResult<
  MagicInspectorPathQuery,
  MagicInspectorPathQueryVariables
>;
export const InspectorNodeDocument = gql`
  query InspectorNode($id: String!, $className: String!) {
    inspectorNode(id: $id, className: $className) {
      model {
        id
        className
        fields {
          name
          value
        }
      }
      associations {
        className
        edges {
          id
          summary {
            name
            value
          }
        }
      }
      versions {
        id
        event
        createdAt
        whodunnit
        fields {
          name
          oldValue
          newValue
        }
      }
    }
  }
`;

/**
 * __useInspectorNodeQuery__
 *
 * To run a query within a React component, call `useInspectorNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspectorNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspectorNodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      className: // value for 'className'
 *   },
 * });
 */
export function useInspectorNodeQuery(
  baseOptions: Apollo.QueryHookOptions<InspectorNodeQuery, InspectorNodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InspectorNodeQuery, InspectorNodeQueryVariables>(InspectorNodeDocument, options);
}
export function useInspectorNodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InspectorNodeQuery, InspectorNodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InspectorNodeQuery, InspectorNodeQueryVariables>(InspectorNodeDocument, options);
}
export type InspectorNodeQueryHookResult = ReturnType<typeof useInspectorNodeQuery>;
export type InspectorNodeLazyQueryHookResult = ReturnType<typeof useInspectorNodeLazyQuery>;
export type InspectorNodeQueryResult = Apollo.QueryResult<InspectorNodeQuery, InspectorNodeQueryVariables>;
export const AddInstituteToInstituteGroupDocument = gql`
  mutation AddInstituteToInstituteGroup($input: AddInstituteToInstituteGroupInput!) {
    addInstituteToInstituteGroup(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        instituteGroup {
          id
          name
          leader {
            id
            name
          }
          members {
            id
            name
          }
        }
      }
    }
  }
`;
export type AddInstituteToInstituteGroupMutationFn = Apollo.MutationFunction<
  AddInstituteToInstituteGroupMutation,
  AddInstituteToInstituteGroupMutationVariables
>;

/**
 * __useAddInstituteToInstituteGroupMutation__
 *
 * To run a mutation, you first call `useAddInstituteToInstituteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInstituteToInstituteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInstituteToInstituteGroupMutation, { data, loading, error }] = useAddInstituteToInstituteGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddInstituteToInstituteGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddInstituteToInstituteGroupMutation,
    AddInstituteToInstituteGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddInstituteToInstituteGroupMutation,
    AddInstituteToInstituteGroupMutationVariables
  >(AddInstituteToInstituteGroupDocument, options);
}
export type AddInstituteToInstituteGroupMutationHookResult = ReturnType<
  typeof useAddInstituteToInstituteGroupMutation
>;
export type AddInstituteToInstituteGroupMutationResult =
  Apollo.MutationResult<AddInstituteToInstituteGroupMutation>;
export type AddInstituteToInstituteGroupMutationOptions = Apollo.BaseMutationOptions<
  AddInstituteToInstituteGroupMutation,
  AddInstituteToInstituteGroupMutationVariables
>;
export const CreateInstituteGroupDocument = gql`
  mutation CreateInstituteGroup($input: CreateInstituteGroupInput!) {
    createInstituteGroup(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        instituteGroup {
          id
          name
          leader {
            id
            name
          }
          members {
            id
            name
          }
        }
      }
    }
  }
`;
export type CreateInstituteGroupMutationFn = Apollo.MutationFunction<
  CreateInstituteGroupMutation,
  CreateInstituteGroupMutationVariables
>;

/**
 * __useCreateInstituteGroupMutation__
 *
 * To run a mutation, you first call `useCreateInstituteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstituteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstituteGroupMutation, { data, loading, error }] = useCreateInstituteGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInstituteGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateInstituteGroupMutation,
    CreateInstituteGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInstituteGroupMutation, CreateInstituteGroupMutationVariables>(
    CreateInstituteGroupDocument,
    options
  );
}
export type CreateInstituteGroupMutationHookResult = ReturnType<typeof useCreateInstituteGroupMutation>;
export type CreateInstituteGroupMutationResult = Apollo.MutationResult<CreateInstituteGroupMutation>;
export type CreateInstituteGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateInstituteGroupMutation,
  CreateInstituteGroupMutationVariables
>;
export const InstituteGroupDetailsDocument = gql`
  query InstituteGroupDetails($id: InstituteGroupId!) {
    instituteGroup(id: $id) {
      id
      name
      leader {
        id
        name
      }
      members {
        id
        name
      }
    }
  }
`;

/**
 * __useInstituteGroupDetailsQuery__
 *
 * To run a query within a React component, call `useInstituteGroupDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteGroupDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteGroupDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInstituteGroupDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<InstituteGroupDetailsQuery, InstituteGroupDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteGroupDetailsQuery, InstituteGroupDetailsQueryVariables>(
    InstituteGroupDetailsDocument,
    options
  );
}
export function useInstituteGroupDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstituteGroupDetailsQuery, InstituteGroupDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteGroupDetailsQuery, InstituteGroupDetailsQueryVariables>(
    InstituteGroupDetailsDocument,
    options
  );
}
export type InstituteGroupDetailsQueryHookResult = ReturnType<typeof useInstituteGroupDetailsQuery>;
export type InstituteGroupDetailsLazyQueryHookResult = ReturnType<typeof useInstituteGroupDetailsLazyQuery>;
export type InstituteGroupDetailsQueryResult = Apollo.QueryResult<
  InstituteGroupDetailsQuery,
  InstituteGroupDetailsQueryVariables
>;
export const InstituteGroupListDocument = gql`
  query InstituteGroupList(
    $sortBy: InstituteGroupSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
  ) {
    instituteGroups(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      smartSearch: $search
    ) {
      nodes {
        id
        name
        leader {
          id
          name
        }
        members {
          id
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useInstituteGroupListQuery__
 *
 * To run a query within a React component, call `useInstituteGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteGroupListQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useInstituteGroupListQuery(
  baseOptions?: Apollo.QueryHookOptions<InstituteGroupListQuery, InstituteGroupListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteGroupListQuery, InstituteGroupListQueryVariables>(
    InstituteGroupListDocument,
    options
  );
}
export function useInstituteGroupListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstituteGroupListQuery, InstituteGroupListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteGroupListQuery, InstituteGroupListQueryVariables>(
    InstituteGroupListDocument,
    options
  );
}
export type InstituteGroupListQueryHookResult = ReturnType<typeof useInstituteGroupListQuery>;
export type InstituteGroupListLazyQueryHookResult = ReturnType<typeof useInstituteGroupListLazyQuery>;
export type InstituteGroupListQueryResult = Apollo.QueryResult<
  InstituteGroupListQuery,
  InstituteGroupListQueryVariables
>;
export const RemoveInstituteFromInstituteGroupDocument = gql`
  mutation RemoveInstituteFromInstituteGroup($input: RemoveInstituteFromInstituteGroupInput!) {
    removeInstituteFromInstituteGroup(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        instituteGroup {
          id
          name
          leader {
            id
            name
          }
          members {
            id
            name
          }
        }
      }
    }
  }
`;
export type RemoveInstituteFromInstituteGroupMutationFn = Apollo.MutationFunction<
  RemoveInstituteFromInstituteGroupMutation,
  RemoveInstituteFromInstituteGroupMutationVariables
>;

/**
 * __useRemoveInstituteFromInstituteGroupMutation__
 *
 * To run a mutation, you first call `useRemoveInstituteFromInstituteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInstituteFromInstituteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInstituteFromInstituteGroupMutation, { data, loading, error }] = useRemoveInstituteFromInstituteGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveInstituteFromInstituteGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveInstituteFromInstituteGroupMutation,
    RemoveInstituteFromInstituteGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveInstituteFromInstituteGroupMutation,
    RemoveInstituteFromInstituteGroupMutationVariables
  >(RemoveInstituteFromInstituteGroupDocument, options);
}
export type RemoveInstituteFromInstituteGroupMutationHookResult = ReturnType<
  typeof useRemoveInstituteFromInstituteGroupMutation
>;
export type RemoveInstituteFromInstituteGroupMutationResult =
  Apollo.MutationResult<RemoveInstituteFromInstituteGroupMutation>;
export type RemoveInstituteFromInstituteGroupMutationOptions = Apollo.BaseMutationOptions<
  RemoveInstituteFromInstituteGroupMutation,
  RemoveInstituteFromInstituteGroupMutationVariables
>;
export const InstituteAppointmentAthenaPushbackDocument = gql`
  query InstituteAppointmentAthenaPushback($appointmentId: AppointmentId!) {
    schedulingAppointment(id: $appointmentId) {
      id
      athenaAppointmentEncounter {
        id
        athenaEncounterId
        athenaPatientId
        departmentId
        createdAt
        updatedAt
      }
      athenaScreeningQuestionnaires {
        scale {
          mnemonic
        }
        status
        appointmentEncounterId
        createdAt
        updatedAt
        pdfS3Location
        athenaDocumentId
        athenaQuestionaireId
        computedValueId
        id
      }
    }
  }
`;

/**
 * __useInstituteAppointmentAthenaPushbackQuery__
 *
 * To run a query within a React component, call `useInstituteAppointmentAthenaPushbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteAppointmentAthenaPushbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteAppointmentAthenaPushbackQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useInstituteAppointmentAthenaPushbackQuery(
  baseOptions: Apollo.QueryHookOptions<
    InstituteAppointmentAthenaPushbackQuery,
    InstituteAppointmentAthenaPushbackQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    InstituteAppointmentAthenaPushbackQuery,
    InstituteAppointmentAthenaPushbackQueryVariables
  >(InstituteAppointmentAthenaPushbackDocument, options);
}
export function useInstituteAppointmentAthenaPushbackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstituteAppointmentAthenaPushbackQuery,
    InstituteAppointmentAthenaPushbackQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InstituteAppointmentAthenaPushbackQuery,
    InstituteAppointmentAthenaPushbackQueryVariables
  >(InstituteAppointmentAthenaPushbackDocument, options);
}
export type InstituteAppointmentAthenaPushbackQueryHookResult = ReturnType<
  typeof useInstituteAppointmentAthenaPushbackQuery
>;
export type InstituteAppointmentAthenaPushbackLazyQueryHookResult = ReturnType<
  typeof useInstituteAppointmentAthenaPushbackLazyQuery
>;
export type InstituteAppointmentAthenaPushbackQueryResult = Apollo.QueryResult<
  InstituteAppointmentAthenaPushbackQuery,
  InstituteAppointmentAthenaPushbackQueryVariables
>;
export const InstituteAppointmentDocument = gql`
  query InstituteAppointment($appointmentId: AppointmentId!) {
    schedulingAppointment(id: $appointmentId) {
      id
      source
      startDate
      endDate
      minutesDuration
      comment
      status
      patientSession {
        id
        status
        sessionNumber
        careEpisode {
          id
          name
        }
      }
      provider {
        id
        name
      }
      patient {
        id
        name
      }
      organization {
        id
        name
        shortname
      }
      careEpisode {
        id
        name
      }
      integrationsDataSourceRecords {
        ...InternalIntegrationsDataSourceRecords
      }
    }
  }
  ${InternalIntegrationsDataSourceRecordsFragmentDoc}
`;

/**
 * __useInstituteAppointmentQuery__
 *
 * To run a query within a React component, call `useInstituteAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteAppointmentQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useInstituteAppointmentQuery(
  baseOptions: Apollo.QueryHookOptions<InstituteAppointmentQuery, InstituteAppointmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteAppointmentQuery, InstituteAppointmentQueryVariables>(
    InstituteAppointmentDocument,
    options
  );
}
export function useInstituteAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstituteAppointmentQuery, InstituteAppointmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteAppointmentQuery, InstituteAppointmentQueryVariables>(
    InstituteAppointmentDocument,
    options
  );
}
export type InstituteAppointmentQueryHookResult = ReturnType<typeof useInstituteAppointmentQuery>;
export type InstituteAppointmentLazyQueryHookResult = ReturnType<typeof useInstituteAppointmentLazyQuery>;
export type InstituteAppointmentQueryResult = Apollo.QueryResult<
  InstituteAppointmentQuery,
  InstituteAppointmentQueryVariables
>;
export const InstituteCareEpisodeDocument = gql`
  query InstituteCareEpisode($careEpisodeId: CareEpisodeId!) {
    careEpisode(id: $careEpisodeId) {
      id
      status
      periodStart
      periodEnd
      name
      shortname
      measurementAllowed
      institute {
        measurementAllowed
      }
      patient {
        id
        name
      }
      providers {
        id
        name
        measurementAllowed
      }
      organization {
        id
        name
        shortname
        measurementAllowed
      }
      integrationsDataSourceRecords {
        ...InternalIntegrationsDataSourceRecords
      }
      ...CareEpisodeMeasurementAllowedDetails
    }
  }
  ${InternalIntegrationsDataSourceRecordsFragmentDoc}
  ${CareEpisodeMeasurementAllowedDetailsFragmentDoc}
`;

/**
 * __useInstituteCareEpisodeQuery__
 *
 * To run a query within a React component, call `useInstituteCareEpisodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteCareEpisodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteCareEpisodeQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *   },
 * });
 */
export function useInstituteCareEpisodeQuery(
  baseOptions: Apollo.QueryHookOptions<InstituteCareEpisodeQuery, InstituteCareEpisodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteCareEpisodeQuery, InstituteCareEpisodeQueryVariables>(
    InstituteCareEpisodeDocument,
    options
  );
}
export function useInstituteCareEpisodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstituteCareEpisodeQuery, InstituteCareEpisodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteCareEpisodeQuery, InstituteCareEpisodeQueryVariables>(
    InstituteCareEpisodeDocument,
    options
  );
}
export type InstituteCareEpisodeQueryHookResult = ReturnType<typeof useInstituteCareEpisodeQuery>;
export type InstituteCareEpisodeLazyQueryHookResult = ReturnType<typeof useInstituteCareEpisodeLazyQuery>;
export type InstituteCareEpisodeQueryResult = Apollo.QueryResult<
  InstituteCareEpisodeQuery,
  InstituteCareEpisodeQueryVariables
>;
export const EhrLinkLogListDocument = gql`
  query EhrLinkLogList(
    $ehrLinkId: [EhrLinkId!]
    $first: Int
    $last: Int
    $after: String
    $before: String
    $status: [EhrLinkLogStatus!]
  ) {
    integrationsEhrLinkLogs(
      ehrLinkId: $ehrLinkId
      sortBy: STARTDATE
      sortDirection: DESC
      first: $first
      after: $after
      before: $before
      last: $last
      status: $status
    ) {
      nodes {
        id
        status
        startDate
        endDate
        log
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useEhrLinkLogListQuery__
 *
 * To run a query within a React component, call `useEhrLinkLogListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEhrLinkLogListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEhrLinkLogListQuery({
 *   variables: {
 *      ehrLinkId: // value for 'ehrLinkId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEhrLinkLogListQuery(
  baseOptions?: Apollo.QueryHookOptions<EhrLinkLogListQuery, EhrLinkLogListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EhrLinkLogListQuery, EhrLinkLogListQueryVariables>(EhrLinkLogListDocument, options);
}
export function useEhrLinkLogListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EhrLinkLogListQuery, EhrLinkLogListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EhrLinkLogListQuery, EhrLinkLogListQueryVariables>(
    EhrLinkLogListDocument,
    options
  );
}
export type EhrLinkLogListQueryHookResult = ReturnType<typeof useEhrLinkLogListQuery>;
export type EhrLinkLogListLazyQueryHookResult = ReturnType<typeof useEhrLinkLogListLazyQuery>;
export type EhrLinkLogListQueryResult = Apollo.QueryResult<EhrLinkLogListQuery, EhrLinkLogListQueryVariables>;
export const InstituteDetailsQueryDocument = gql`
  query InstituteDetailsQuery($instituteId: InstituteId!) {
    institute(id: $instituteId) {
      id
      name
      measurementAllowed
      ehrLinks {
        nodes {
          id
          connectionType
          lastLog {
            status
            startDate
            endDate
          }
        }
      }
    }
  }
`;

/**
 * __useInstituteDetailsQueryQuery__
 *
 * To run a query within a React component, call `useInstituteDetailsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteDetailsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteDetailsQueryQuery({
 *   variables: {
 *      instituteId: // value for 'instituteId'
 *   },
 * });
 */
export function useInstituteDetailsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<InstituteDetailsQueryQuery, InstituteDetailsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteDetailsQueryQuery, InstituteDetailsQueryQueryVariables>(
    InstituteDetailsQueryDocument,
    options
  );
}
export function useInstituteDetailsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstituteDetailsQueryQuery, InstituteDetailsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteDetailsQueryQuery, InstituteDetailsQueryQueryVariables>(
    InstituteDetailsQueryDocument,
    options
  );
}
export type InstituteDetailsQueryQueryHookResult = ReturnType<typeof useInstituteDetailsQueryQuery>;
export type InstituteDetailsQueryLazyQueryHookResult = ReturnType<typeof useInstituteDetailsQueryLazyQuery>;
export type InstituteDetailsQueryQueryResult = Apollo.QueryResult<
  InstituteDetailsQueryQuery,
  InstituteDetailsQueryQueryVariables
>;
export const InstituteSsoSignonUrlDocument = gql`
  query InstituteSsoSignonUrl($instituteId: InstituteId!) {
    institute(id: $instituteId) {
      ssoSignonUrl
    }
  }
`;

/**
 * __useInstituteSsoSignonUrlQuery__
 *
 * To run a query within a React component, call `useInstituteSsoSignonUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteSsoSignonUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteSsoSignonUrlQuery({
 *   variables: {
 *      instituteId: // value for 'instituteId'
 *   },
 * });
 */
export function useInstituteSsoSignonUrlQuery(
  baseOptions: Apollo.QueryHookOptions<InstituteSsoSignonUrlQuery, InstituteSsoSignonUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteSsoSignonUrlQuery, InstituteSsoSignonUrlQueryVariables>(
    InstituteSsoSignonUrlDocument,
    options
  );
}
export function useInstituteSsoSignonUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstituteSsoSignonUrlQuery, InstituteSsoSignonUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteSsoSignonUrlQuery, InstituteSsoSignonUrlQueryVariables>(
    InstituteSsoSignonUrlDocument,
    options
  );
}
export type InstituteSsoSignonUrlQueryHookResult = ReturnType<typeof useInstituteSsoSignonUrlQuery>;
export type InstituteSsoSignonUrlLazyQueryHookResult = ReturnType<typeof useInstituteSsoSignonUrlLazyQuery>;
export type InstituteSsoSignonUrlQueryResult = Apollo.QueryResult<
  InstituteSsoSignonUrlQuery,
  InstituteSsoSignonUrlQueryVariables
>;
export const InstituteSummaryDocument = gql`
  query InstituteSummary($instituteId: InstituteId!) {
    institute(id: $instituteId) {
      id
      name
    }
  }
`;

/**
 * __useInstituteSummaryQuery__
 *
 * To run a query within a React component, call `useInstituteSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteSummaryQuery({
 *   variables: {
 *      instituteId: // value for 'instituteId'
 *   },
 * });
 */
export function useInstituteSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<InstituteSummaryQuery, InstituteSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteSummaryQuery, InstituteSummaryQueryVariables>(
    InstituteSummaryDocument,
    options
  );
}
export function useInstituteSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstituteSummaryQuery, InstituteSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteSummaryQuery, InstituteSummaryQueryVariables>(
    InstituteSummaryDocument,
    options
  );
}
export type InstituteSummaryQueryHookResult = ReturnType<typeof useInstituteSummaryQuery>;
export type InstituteSummaryLazyQueryHookResult = ReturnType<typeof useInstituteSummaryLazyQuery>;
export type InstituteSummaryQueryResult = Apollo.QueryResult<
  InstituteSummaryQuery,
  InstituteSummaryQueryVariables
>;
export const DataSourceRecordLogsDocument = gql`
  query DataSourceRecordLogs(
    $dataSourceRecordId: [DataSourceRecordId!]
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    integrationsDataSourceRecordLogs(
      dataSourceRecordId: $dataSourceRecordId
      sortBy: CREATED_AT
      sortDirection: DESC
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      nodes {
        id
        status
        matchStatus
        raw
        idError
        createdAt
        transformErrors {
          field
          errors
        }
        dataSourceRecordAlgorithmLogs {
          algorithmCode
          statusName
          statusDescription
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useDataSourceRecordLogsQuery__
 *
 * To run a query within a React component, call `useDataSourceRecordLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataSourceRecordLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataSourceRecordLogsQuery({
 *   variables: {
 *      dataSourceRecordId: // value for 'dataSourceRecordId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useDataSourceRecordLogsQuery(
  baseOptions?: Apollo.QueryHookOptions<DataSourceRecordLogsQuery, DataSourceRecordLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DataSourceRecordLogsQuery, DataSourceRecordLogsQueryVariables>(
    DataSourceRecordLogsDocument,
    options
  );
}
export function useDataSourceRecordLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DataSourceRecordLogsQuery, DataSourceRecordLogsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DataSourceRecordLogsQuery, DataSourceRecordLogsQueryVariables>(
    DataSourceRecordLogsDocument,
    options
  );
}
export type DataSourceRecordLogsQueryHookResult = ReturnType<typeof useDataSourceRecordLogsQuery>;
export type DataSourceRecordLogsLazyQueryHookResult = ReturnType<typeof useDataSourceRecordLogsLazyQuery>;
export type DataSourceRecordLogsQueryResult = Apollo.QueryResult<
  DataSourceRecordLogsQuery,
  DataSourceRecordLogsQueryVariables
>;
export const CreateOrEditTreatmentTargetScaleDocument = gql`
  mutation CreateOrEditTreatmentTargetScale($input: CreateOrEditTreatmentTargetScaleInput!) {
    collaborativeCareCreateOrEditTreatmentTargetScale(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        treatmentTarget {
          id
          measurementPlan {
            id
            measurementPlanScales {
              id
              discontinueSession
              maxRepeatInterval
              targeting
              onsetTime
              scale {
                id
                shortname
                nanoname
                mnemonic
                ... on AdministrableScale {
                  defaultParticipants
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type CreateOrEditTreatmentTargetScaleMutationFn = Apollo.MutationFunction<
  CreateOrEditTreatmentTargetScaleMutation,
  CreateOrEditTreatmentTargetScaleMutationVariables
>;

/**
 * __useCreateOrEditTreatmentTargetScaleMutation__
 *
 * To run a mutation, you first call `useCreateOrEditTreatmentTargetScaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrEditTreatmentTargetScaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrEditTreatmentTargetScaleMutation, { data, loading, error }] = useCreateOrEditTreatmentTargetScaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrEditTreatmentTargetScaleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrEditTreatmentTargetScaleMutation,
    CreateOrEditTreatmentTargetScaleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrEditTreatmentTargetScaleMutation,
    CreateOrEditTreatmentTargetScaleMutationVariables
  >(CreateOrEditTreatmentTargetScaleDocument, options);
}
export type CreateOrEditTreatmentTargetScaleMutationHookResult = ReturnType<
  typeof useCreateOrEditTreatmentTargetScaleMutation
>;
export type CreateOrEditTreatmentTargetScaleMutationResult =
  Apollo.MutationResult<CreateOrEditTreatmentTargetScaleMutation>;
export type CreateOrEditTreatmentTargetScaleMutationOptions = Apollo.BaseMutationOptions<
  CreateOrEditTreatmentTargetScaleMutation,
  CreateOrEditTreatmentTargetScaleMutationVariables
>;
export const CreateTreatmentTargetDocument = gql`
  mutation CreateTreatmentTarget($input: CreateTreatmentTargetInput!) {
    collaborativeCareCreateTreatmentTarget(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        treatmentTarget {
          id
          name
          measurementPlan {
            id
            measurementPlanScales {
              id
              discontinueSession
              maxRepeatInterval
              targeting
              scale {
                id
                shortname
                nanoname
                mnemonic
                ... on AdministrableScale {
                  defaultParticipants
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type CreateTreatmentTargetMutationFn = Apollo.MutationFunction<
  CreateTreatmentTargetMutation,
  CreateTreatmentTargetMutationVariables
>;

/**
 * __useCreateTreatmentTargetMutation__
 *
 * To run a mutation, you first call `useCreateTreatmentTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTreatmentTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTreatmentTargetMutation, { data, loading, error }] = useCreateTreatmentTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTreatmentTargetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTreatmentTargetMutation,
    CreateTreatmentTargetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTreatmentTargetMutation, CreateTreatmentTargetMutationVariables>(
    CreateTreatmentTargetDocument,
    options
  );
}
export type CreateTreatmentTargetMutationHookResult = ReturnType<typeof useCreateTreatmentTargetMutation>;
export type CreateTreatmentTargetMutationResult = Apollo.MutationResult<CreateTreatmentTargetMutation>;
export type CreateTreatmentTargetMutationOptions = Apollo.BaseMutationOptions<
  CreateTreatmentTargetMutation,
  CreateTreatmentTargetMutationVariables
>;
export const DeleteTreatmentTargetDocument = gql`
  mutation DeleteTreatmentTarget($input: DeleteTreatmentTargetInput!) {
    collaborativeCareDeleteTreatmentTarget(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        treatmentTarget {
          id
        }
      }
    }
  }
`;
export type DeleteTreatmentTargetMutationFn = Apollo.MutationFunction<
  DeleteTreatmentTargetMutation,
  DeleteTreatmentTargetMutationVariables
>;

/**
 * __useDeleteTreatmentTargetMutation__
 *
 * To run a mutation, you first call `useDeleteTreatmentTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTreatmentTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTreatmentTargetMutation, { data, loading, error }] = useDeleteTreatmentTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTreatmentTargetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTreatmentTargetMutation,
    DeleteTreatmentTargetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTreatmentTargetMutation, DeleteTreatmentTargetMutationVariables>(
    DeleteTreatmentTargetDocument,
    options
  );
}
export type DeleteTreatmentTargetMutationHookResult = ReturnType<typeof useDeleteTreatmentTargetMutation>;
export type DeleteTreatmentTargetMutationResult = Apollo.MutationResult<DeleteTreatmentTargetMutation>;
export type DeleteTreatmentTargetMutationOptions = Apollo.BaseMutationOptions<
  DeleteTreatmentTargetMutation,
  DeleteTreatmentTargetMutationVariables
>;
export const DeleteTreatmentTargetScaleDocument = gql`
  mutation DeleteTreatmentTargetScale($input: DeleteTreatmentTargetScaleInput!) {
    collaborativeCareDeleteTreatmentTargetScale(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        treatmentTarget {
          id
          measurementPlan {
            id
            measurementPlanScales {
              id
            }
          }
        }
      }
    }
  }
`;
export type DeleteTreatmentTargetScaleMutationFn = Apollo.MutationFunction<
  DeleteTreatmentTargetScaleMutation,
  DeleteTreatmentTargetScaleMutationVariables
>;

/**
 * __useDeleteTreatmentTargetScaleMutation__
 *
 * To run a mutation, you first call `useDeleteTreatmentTargetScaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTreatmentTargetScaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTreatmentTargetScaleMutation, { data, loading, error }] = useDeleteTreatmentTargetScaleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteTreatmentTargetScaleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTreatmentTargetScaleMutation,
    DeleteTreatmentTargetScaleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTreatmentTargetScaleMutation, DeleteTreatmentTargetScaleMutationVariables>(
    DeleteTreatmentTargetScaleDocument,
    options
  );
}
export type DeleteTreatmentTargetScaleMutationHookResult = ReturnType<
  typeof useDeleteTreatmentTargetScaleMutation
>;
export type DeleteTreatmentTargetScaleMutationResult =
  Apollo.MutationResult<DeleteTreatmentTargetScaleMutation>;
export type DeleteTreatmentTargetScaleMutationOptions = Apollo.BaseMutationOptions<
  DeleteTreatmentTargetScaleMutation,
  DeleteTreatmentTargetScaleMutationVariables
>;
export const EditTreatmentTargetDocument = gql`
  mutation EditTreatmentTarget($input: EditTreatmentTargetInput!) {
    collaborativeCareEditTreatmentTarget(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        treatmentTarget {
          id
          name
        }
      }
    }
  }
`;
export type EditTreatmentTargetMutationFn = Apollo.MutationFunction<
  EditTreatmentTargetMutation,
  EditTreatmentTargetMutationVariables
>;

/**
 * __useEditTreatmentTargetMutation__
 *
 * To run a mutation, you first call `useEditTreatmentTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTreatmentTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTreatmentTargetMutation, { data, loading, error }] = useEditTreatmentTargetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTreatmentTargetMutation(
  baseOptions?: Apollo.MutationHookOptions<EditTreatmentTargetMutation, EditTreatmentTargetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditTreatmentTargetMutation, EditTreatmentTargetMutationVariables>(
    EditTreatmentTargetDocument,
    options
  );
}
export type EditTreatmentTargetMutationHookResult = ReturnType<typeof useEditTreatmentTargetMutation>;
export type EditTreatmentTargetMutationResult = Apollo.MutationResult<EditTreatmentTargetMutation>;
export type EditTreatmentTargetMutationOptions = Apollo.BaseMutationOptions<
  EditTreatmentTargetMutation,
  EditTreatmentTargetMutationVariables
>;
export const InstituteMeasurementBundlesDocument = gql`
  query InstituteMeasurementBundles($instituteId: InstituteId!) {
    collaborativeCareTreatmentTargets(instituteId: $instituteId) {
      nodes {
        id
        name
        measurementPlan {
          id
          measurementPlanScales {
            id
            discontinueSession
            maxRepeatInterval
            targeting
            onsetTime
            scale {
              id
              shortname
              nanoname
              mnemonic
              ... on AdministrableScale {
                defaultParticipants
              }
            }
          }
        }
      }
    }
    assessmentInstituteScales(instituteId: $instituteId) {
      nodes {
        id
        format
        disableAdministration
        scale {
          id
        }
      }
    }
    collaborativeCareCareEpisodeTreatmentTargets {
      nodes {
        id
        treatmentTarget {
          id
        }
      }
    }
  }
`;

/**
 * __useInstituteMeasurementBundlesQuery__
 *
 * To run a query within a React component, call `useInstituteMeasurementBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteMeasurementBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteMeasurementBundlesQuery({
 *   variables: {
 *      instituteId: // value for 'instituteId'
 *   },
 * });
 */
export function useInstituteMeasurementBundlesQuery(
  baseOptions: Apollo.QueryHookOptions<
    InstituteMeasurementBundlesQuery,
    InstituteMeasurementBundlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteMeasurementBundlesQuery, InstituteMeasurementBundlesQueryVariables>(
    InstituteMeasurementBundlesDocument,
    options
  );
}
export function useInstituteMeasurementBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstituteMeasurementBundlesQuery,
    InstituteMeasurementBundlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteMeasurementBundlesQuery, InstituteMeasurementBundlesQueryVariables>(
    InstituteMeasurementBundlesDocument,
    options
  );
}
export type InstituteMeasurementBundlesQueryHookResult = ReturnType<
  typeof useInstituteMeasurementBundlesQuery
>;
export type InstituteMeasurementBundlesLazyQueryHookResult = ReturnType<
  typeof useInstituteMeasurementBundlesLazyQuery
>;
export type InstituteMeasurementBundlesQueryResult = Apollo.QueryResult<
  InstituteMeasurementBundlesQuery,
  InstituteMeasurementBundlesQueryVariables
>;
export const ReplanAllActiveEnrollmentsDocument = gql`
  mutation ReplanAllActiveEnrollments($input: ReplanAllActiveEnrollmentInput!) {
    collaborativeCareReplanAllActiveEnrollments(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        monitoredJob {
          id
        }
      }
    }
  }
`;
export type ReplanAllActiveEnrollmentsMutationFn = Apollo.MutationFunction<
  ReplanAllActiveEnrollmentsMutation,
  ReplanAllActiveEnrollmentsMutationVariables
>;

/**
 * __useReplanAllActiveEnrollmentsMutation__
 *
 * To run a mutation, you first call `useReplanAllActiveEnrollmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplanAllActiveEnrollmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replanAllActiveEnrollmentsMutation, { data, loading, error }] = useReplanAllActiveEnrollmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReplanAllActiveEnrollmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReplanAllActiveEnrollmentsMutation,
    ReplanAllActiveEnrollmentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReplanAllActiveEnrollmentsMutation, ReplanAllActiveEnrollmentsMutationVariables>(
    ReplanAllActiveEnrollmentsDocument,
    options
  );
}
export type ReplanAllActiveEnrollmentsMutationHookResult = ReturnType<
  typeof useReplanAllActiveEnrollmentsMutation
>;
export type ReplanAllActiveEnrollmentsMutationResult =
  Apollo.MutationResult<ReplanAllActiveEnrollmentsMutation>;
export type ReplanAllActiveEnrollmentsMutationOptions = Apollo.BaseMutationOptions<
  ReplanAllActiveEnrollmentsMutation,
  ReplanAllActiveEnrollmentsMutationVariables
>;
export const InstituteOrganizationDocument = gql`
  query InstituteOrganization($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      shortname
      measurementAllowed
      inTrialPeriod
      parent {
        id
        name
        shortname
      }
      integrationsDataSourceRecords {
        ...InternalIntegrationsDataSourceRecords
      }
    }
  }
  ${InternalIntegrationsDataSourceRecordsFragmentDoc}
`;

/**
 * __useInstituteOrganizationQuery__
 *
 * To run a query within a React component, call `useInstituteOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useInstituteOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<InstituteOrganizationQuery, InstituteOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteOrganizationQuery, InstituteOrganizationQueryVariables>(
    InstituteOrganizationDocument,
    options
  );
}
export function useInstituteOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstituteOrganizationQuery, InstituteOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteOrganizationQuery, InstituteOrganizationQueryVariables>(
    InstituteOrganizationDocument,
    options
  );
}
export type InstituteOrganizationQueryHookResult = ReturnType<typeof useInstituteOrganizationQuery>;
export type InstituteOrganizationLazyQueryHookResult = ReturnType<typeof useInstituteOrganizationLazyQuery>;
export type InstituteOrganizationQueryResult = Apollo.QueryResult<
  InstituteOrganizationQuery,
  InstituteOrganizationQueryVariables
>;
export const InstituteOrganizationListDocument = gql`
  query InstituteOrganizationList($instituteId: InstituteId!) {
    institute(id: $instituteId) {
      id
      name
      allOrganizations {
        id
        parentId
        name
        shortname
        measurementAllowed
        inTrialPeriod
      }
    }
  }
`;

/**
 * __useInstituteOrganizationListQuery__
 *
 * To run a query within a React component, call `useInstituteOrganizationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteOrganizationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteOrganizationListQuery({
 *   variables: {
 *      instituteId: // value for 'instituteId'
 *   },
 * });
 */
export function useInstituteOrganizationListQuery(
  baseOptions: Apollo.QueryHookOptions<
    InstituteOrganizationListQuery,
    InstituteOrganizationListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteOrganizationListQuery, InstituteOrganizationListQueryVariables>(
    InstituteOrganizationListDocument,
    options
  );
}
export function useInstituteOrganizationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstituteOrganizationListQuery,
    InstituteOrganizationListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteOrganizationListQuery, InstituteOrganizationListQueryVariables>(
    InstituteOrganizationListDocument,
    options
  );
}
export type InstituteOrganizationListQueryHookResult = ReturnType<typeof useInstituteOrganizationListQuery>;
export type InstituteOrganizationListLazyQueryHookResult = ReturnType<
  typeof useInstituteOrganizationListLazyQuery
>;
export type InstituteOrganizationListQueryResult = Apollo.QueryResult<
  InstituteOrganizationListQuery,
  InstituteOrganizationListQueryVariables
>;
export const InstitutePatientDocument = gql`
  query InstitutePatient($patientId: PatientId!) {
    patient(id: $patientId) {
      id
      name
      phoneNumber
      email
      primaryLanguage
      gender
      mrn
      firstName
      lastName
      dob
      isTest
      source
      autoCreateSessions
      isProvider
      genderSource
      enableNotifications
      groupName
      lastSsn
      maritalStatus
      race
      researchOther
      researchOtherDiagnoses
      researchPrimaryDiagnosis
      state
      veteranStatus
      zipCode
      ethnicity
      identifiers {
        assigner
        value
      }
      integrationsDataSourceRecords {
        ...InternalIntegrationsDataSourceRecords
      }
      user {
        ...UserInternalOverview
      }
    }
  }
  ${InternalIntegrationsDataSourceRecordsFragmentDoc}
  ${UserInternalOverviewFragmentDoc}
`;

/**
 * __useInstitutePatientQuery__
 *
 * To run a query within a React component, call `useInstitutePatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstitutePatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstitutePatientQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useInstitutePatientQuery(
  baseOptions: Apollo.QueryHookOptions<InstitutePatientQuery, InstitutePatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstitutePatientQuery, InstitutePatientQueryVariables>(
    InstitutePatientDocument,
    options
  );
}
export function useInstitutePatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstitutePatientQuery, InstitutePatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstitutePatientQuery, InstitutePatientQueryVariables>(
    InstitutePatientDocument,
    options
  );
}
export type InstitutePatientQueryHookResult = ReturnType<typeof useInstitutePatientQuery>;
export type InstitutePatientLazyQueryHookResult = ReturnType<typeof useInstitutePatientLazyQuery>;
export type InstitutePatientQueryResult = Apollo.QueryResult<
  InstitutePatientQuery,
  InstitutePatientQueryVariables
>;
export const InstitutePatientAppointmentListDocument = gql`
  query InstitutePatientAppointmentList(
    $patientId: [PatientId!]
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    schedulingAppointments(
      patientId: $patientId
      sortBy: STARTDATE
      sortDirection: DESC
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      nodes {
        id
        startDate
        status
        patientSession {
          id
          status
          sessionNumber
        }
        provider {
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useInstitutePatientAppointmentListQuery__
 *
 * To run a query within a React component, call `useInstitutePatientAppointmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstitutePatientAppointmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstitutePatientAppointmentListQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useInstitutePatientAppointmentListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    InstitutePatientAppointmentListQuery,
    InstitutePatientAppointmentListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstitutePatientAppointmentListQuery, InstitutePatientAppointmentListQueryVariables>(
    InstitutePatientAppointmentListDocument,
    options
  );
}
export function useInstitutePatientAppointmentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstitutePatientAppointmentListQuery,
    InstitutePatientAppointmentListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    InstitutePatientAppointmentListQuery,
    InstitutePatientAppointmentListQueryVariables
  >(InstitutePatientAppointmentListDocument, options);
}
export type InstitutePatientAppointmentListQueryHookResult = ReturnType<
  typeof useInstitutePatientAppointmentListQuery
>;
export type InstitutePatientAppointmentListLazyQueryHookResult = ReturnType<
  typeof useInstitutePatientAppointmentListLazyQuery
>;
export type InstitutePatientAppointmentListQueryResult = Apollo.QueryResult<
  InstitutePatientAppointmentListQuery,
  InstitutePatientAppointmentListQueryVariables
>;
export const InstitutePatientListDocument = gql`
  query InstitutePatientList(
    $instituteId: InstituteId
    $sortBy: PatientSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
  ) {
    patients(
      instituteId: $instituteId
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      search: $search
    ) {
      nodes {
        id
        gender
        firstName
        lastName
        dob
        mrn
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useInstitutePatientListQuery__
 *
 * To run a query within a React component, call `useInstitutePatientListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstitutePatientListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstitutePatientListQuery({
 *   variables: {
 *      instituteId: // value for 'instituteId'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useInstitutePatientListQuery(
  baseOptions?: Apollo.QueryHookOptions<InstitutePatientListQuery, InstitutePatientListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstitutePatientListQuery, InstitutePatientListQueryVariables>(
    InstitutePatientListDocument,
    options
  );
}
export function useInstitutePatientListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstitutePatientListQuery, InstitutePatientListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstitutePatientListQuery, InstitutePatientListQueryVariables>(
    InstitutePatientListDocument,
    options
  );
}
export type InstitutePatientListQueryHookResult = ReturnType<typeof useInstitutePatientListQuery>;
export type InstitutePatientListLazyQueryHookResult = ReturnType<typeof useInstitutePatientListLazyQuery>;
export type InstitutePatientListQueryResult = Apollo.QueryResult<
  InstitutePatientListQuery,
  InstitutePatientListQueryVariables
>;
export const InstituteProviderDocument = gql`
  query InstituteProvider($providerId: ProviderId!) {
    provider(id: $providerId) {
      id
      name
      status
      measurementAllowed
      title
      suffix
      defaultRelationship
      practicing
      user {
        ...UserInternalOverview
      }
      integrationsDataSourceRecords {
        ...InternalIntegrationsDataSourceRecords
      }
    }
  }
  ${UserInternalOverviewFragmentDoc}
  ${InternalIntegrationsDataSourceRecordsFragmentDoc}
`;

/**
 * __useInstituteProviderQuery__
 *
 * To run a query within a React component, call `useInstituteProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteProviderQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useInstituteProviderQuery(
  baseOptions: Apollo.QueryHookOptions<InstituteProviderQuery, InstituteProviderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteProviderQuery, InstituteProviderQueryVariables>(
    InstituteProviderDocument,
    options
  );
}
export function useInstituteProviderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstituteProviderQuery, InstituteProviderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteProviderQuery, InstituteProviderQueryVariables>(
    InstituteProviderDocument,
    options
  );
}
export type InstituteProviderQueryHookResult = ReturnType<typeof useInstituteProviderQuery>;
export type InstituteProviderLazyQueryHookResult = ReturnType<typeof useInstituteProviderLazyQuery>;
export type InstituteProviderQueryResult = Apollo.QueryResult<
  InstituteProviderQuery,
  InstituteProviderQueryVariables
>;
export const InstituteProviderListDocument = gql`
  query InstituteProviderList(
    $instituteId: InstituteId
    $sortBy: ProviderSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
  ) {
    providers(
      instituteId: $instituteId
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      search: $search
    ) {
      nodes {
        id
        name
        status
        measurementAllowed
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useInstituteProviderListQuery__
 *
 * To run a query within a React component, call `useInstituteProviderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteProviderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteProviderListQuery({
 *   variables: {
 *      instituteId: // value for 'instituteId'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useInstituteProviderListQuery(
  baseOptions?: Apollo.QueryHookOptions<InstituteProviderListQuery, InstituteProviderListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteProviderListQuery, InstituteProviderListQueryVariables>(
    InstituteProviderListDocument,
    options
  );
}
export function useInstituteProviderListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstituteProviderListQuery, InstituteProviderListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteProviderListQuery, InstituteProviderListQueryVariables>(
    InstituteProviderListDocument,
    options
  );
}
export type InstituteProviderListQueryHookResult = ReturnType<typeof useInstituteProviderListQuery>;
export type InstituteProviderListLazyQueryHookResult = ReturnType<typeof useInstituteProviderListLazyQuery>;
export type InstituteProviderListQueryResult = Apollo.QueryResult<
  InstituteProviderListQuery,
  InstituteProviderListQueryVariables
>;
export const InstituteListQueryDocument = gql`
  query InstituteListQuery(
    $sortBy: InstituteSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
  ) {
    institutes(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      search: $search
    ) {
      nodes {
        id
        name
        shortcode
        createdAt
        updatedAt
        isTest
        enabled
        measurementAllowed
        featureSet {
          enableBilling
          enableCareUnits
          enableConditions
          enableDemoQuickAdd
          enableExperiments
          enableMeasurementDimensions
          enableMeasurementPlans
          enablePayors
          enableQualityIssues
          enableTreatmentServices
          enableDashboardSchedule
          enableAthenaWriteback
          enableDecisionSupport
          enableCollaborativeCare
          enableImplementationTargets
          assessmentIncompleteFindings
          monthlyAssessmentMissingFindings
          enableHealthieChildPatientSwap
          enableLocations
          enableOptumMetrics
          enableCocmEncounters
          enableMbcRedesign
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useInstituteListQueryQuery__
 *
 * To run a query within a React component, call `useInstituteListQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteListQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteListQueryQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useInstituteListQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<InstituteListQueryQuery, InstituteListQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteListQueryQuery, InstituteListQueryQueryVariables>(
    InstituteListQueryDocument,
    options
  );
}
export function useInstituteListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstituteListQueryQuery, InstituteListQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteListQueryQuery, InstituteListQueryQueryVariables>(
    InstituteListQueryDocument,
    options
  );
}
export type InstituteListQueryQueryHookResult = ReturnType<typeof useInstituteListQueryQuery>;
export type InstituteListQueryLazyQueryHookResult = ReturnType<typeof useInstituteListQueryLazyQuery>;
export type InstituteListQueryQueryResult = Apollo.QueryResult<
  InstituteListQueryQuery,
  InstituteListQueryQueryVariables
>;
export const InstituteIntegrationReportDocument = gql`
  query InstituteIntegrationReport($startDate: ISO8601Date!, $endDate: ISO8601Date!) {
    integrationStatuses(startDate: $startDate, endDate: $endDate) {
      institute {
        id
        name
        customerSupportManager
      }
      ehrConnection {
        id
        connectionType
        connectionTypeClass
        notes
      }
      dates {
        date
        success
        total
      }
    }
  }
`;

/**
 * __useInstituteIntegrationReportQuery__
 *
 * To run a query within a React component, call `useInstituteIntegrationReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteIntegrationReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteIntegrationReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useInstituteIntegrationReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    InstituteIntegrationReportQuery,
    InstituteIntegrationReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteIntegrationReportQuery, InstituteIntegrationReportQueryVariables>(
    InstituteIntegrationReportDocument,
    options
  );
}
export function useInstituteIntegrationReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InstituteIntegrationReportQuery,
    InstituteIntegrationReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteIntegrationReportQuery, InstituteIntegrationReportQueryVariables>(
    InstituteIntegrationReportDocument,
    options
  );
}
export type InstituteIntegrationReportQueryHookResult = ReturnType<typeof useInstituteIntegrationReportQuery>;
export type InstituteIntegrationReportLazyQueryHookResult = ReturnType<
  typeof useInstituteIntegrationReportLazyQuery
>;
export type InstituteIntegrationReportQueryResult = Apollo.QueryResult<
  InstituteIntegrationReportQuery,
  InstituteIntegrationReportQueryVariables
>;
export const UpdateEhrLinkNotesDocument = gql`
  mutation UpdateEhrLinkNotes($input: UpdateEhrLinkNotesInput!) {
    updateEhrLinkNotes(input: $input) {
      result {
        ehrLinkId
        notes
      }
      errors {
        message
        path
      }
      success
    }
  }
`;
export type UpdateEhrLinkNotesMutationFn = Apollo.MutationFunction<
  UpdateEhrLinkNotesMutation,
  UpdateEhrLinkNotesMutationVariables
>;

/**
 * __useUpdateEhrLinkNotesMutation__
 *
 * To run a mutation, you first call `useUpdateEhrLinkNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEhrLinkNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEhrLinkNotesMutation, { data, loading, error }] = useUpdateEhrLinkNotesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEhrLinkNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEhrLinkNotesMutation, UpdateEhrLinkNotesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEhrLinkNotesMutation, UpdateEhrLinkNotesMutationVariables>(
    UpdateEhrLinkNotesDocument,
    options
  );
}
export type UpdateEhrLinkNotesMutationHookResult = ReturnType<typeof useUpdateEhrLinkNotesMutation>;
export type UpdateEhrLinkNotesMutationResult = Apollo.MutationResult<UpdateEhrLinkNotesMutation>;
export type UpdateEhrLinkNotesMutationOptions = Apollo.BaseMutationOptions<
  UpdateEhrLinkNotesMutation,
  UpdateEhrLinkNotesMutationVariables
>;
export const MonitoredJobDocument = gql`
  query MonitoredJob($id: MonitoredJobId!) {
    monitoredJob(id: $id) {
      id
      status
      logOutput
      completion
      startedAt
      endedAt
    }
  }
`;

/**
 * __useMonitoredJobQuery__
 *
 * To run a query within a React component, call `useMonitoredJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitoredJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitoredJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMonitoredJobQuery(
  baseOptions: Apollo.QueryHookOptions<MonitoredJobQuery, MonitoredJobQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MonitoredJobQuery, MonitoredJobQueryVariables>(MonitoredJobDocument, options);
}
export function useMonitoredJobLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MonitoredJobQuery, MonitoredJobQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MonitoredJobQuery, MonitoredJobQueryVariables>(MonitoredJobDocument, options);
}
export type MonitoredJobQueryHookResult = ReturnType<typeof useMonitoredJobQuery>;
export type MonitoredJobLazyQueryHookResult = ReturnType<typeof useMonitoredJobLazyQuery>;
export type MonitoredJobQueryResult = Apollo.QueryResult<MonitoredJobQuery, MonitoredJobQueryVariables>;
export const RunSupportTaskDocument = gql`
  mutation RunSupportTask($input: RunSupportTaskInput!) {
    supportRunTask(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        result
        log
        url
        urlContainsPhi
        monitoredJobId
      }
    }
  }
`;
export type RunSupportTaskMutationFn = Apollo.MutationFunction<
  RunSupportTaskMutation,
  RunSupportTaskMutationVariables
>;

/**
 * __useRunSupportTaskMutation__
 *
 * To run a mutation, you first call `useRunSupportTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunSupportTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runSupportTaskMutation, { data, loading, error }] = useRunSupportTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunSupportTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<RunSupportTaskMutation, RunSupportTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RunSupportTaskMutation, RunSupportTaskMutationVariables>(
    RunSupportTaskDocument,
    options
  );
}
export type RunSupportTaskMutationHookResult = ReturnType<typeof useRunSupportTaskMutation>;
export type RunSupportTaskMutationResult = Apollo.MutationResult<RunSupportTaskMutation>;
export type RunSupportTaskMutationOptions = Apollo.BaseMutationOptions<
  RunSupportTaskMutation,
  RunSupportTaskMutationVariables
>;
export const TaskDetailsDocument = gql`
  query TaskDetails($shortcode: String!) {
    supportTask(shortcode: $shortcode) {
      shortcode
      name
      description
      arguments {
        code
        name
        fieldType
        description
        required
      }
    }
  }
`;

/**
 * __useTaskDetailsQuery__
 *
 * To run a query within a React component, call `useTaskDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskDetailsQuery({
 *   variables: {
 *      shortcode: // value for 'shortcode'
 *   },
 * });
 */
export function useTaskDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<TaskDetailsQuery, TaskDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskDetailsQuery, TaskDetailsQueryVariables>(TaskDetailsDocument, options);
}
export function useTaskDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskDetailsQuery, TaskDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskDetailsQuery, TaskDetailsQueryVariables>(TaskDetailsDocument, options);
}
export type TaskDetailsQueryHookResult = ReturnType<typeof useTaskDetailsQuery>;
export type TaskDetailsLazyQueryHookResult = ReturnType<typeof useTaskDetailsLazyQuery>;
export type TaskDetailsQueryResult = Apollo.QueryResult<TaskDetailsQuery, TaskDetailsQueryVariables>;
export const TaskHistoriesDocument = gql`
  query TaskHistories(
    $sortBy: SupportTaskHistorySortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $userId: UserId
    $className: String
  ) {
    supportTaskHistories(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      userId: $userId
      className: $className
    ) {
      nodes {
        id
        className
        arguments {
          name
          value
        }
        completedAt
        startedAt
        status
        user {
          id
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useTaskHistoriesQuery__
 *
 * To run a query within a React component, call `useTaskHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskHistoriesQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      userId: // value for 'userId'
 *      className: // value for 'className'
 *   },
 * });
 */
export function useTaskHistoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<TaskHistoriesQuery, TaskHistoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskHistoriesQuery, TaskHistoriesQueryVariables>(TaskHistoriesDocument, options);
}
export function useTaskHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskHistoriesQuery, TaskHistoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskHistoriesQuery, TaskHistoriesQueryVariables>(TaskHistoriesDocument, options);
}
export type TaskHistoriesQueryHookResult = ReturnType<typeof useTaskHistoriesQuery>;
export type TaskHistoriesLazyQueryHookResult = ReturnType<typeof useTaskHistoriesLazyQuery>;
export type TaskHistoriesQueryResult = Apollo.QueryResult<TaskHistoriesQuery, TaskHistoriesQueryVariables>;
export const TaskListDocument = gql`
  query TaskList {
    supportTasks {
      shortcode
      name
      description
      category
      subCategory
    }
  }
`;

/**
 * __useTaskListQuery__
 *
 * To run a query within a React component, call `useTaskListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTaskListQuery(
  baseOptions?: Apollo.QueryHookOptions<TaskListQuery, TaskListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskListQuery, TaskListQueryVariables>(TaskListDocument, options);
}
export function useTaskListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskListQuery, TaskListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskListQuery, TaskListQueryVariables>(TaskListDocument, options);
}
export type TaskListQueryHookResult = ReturnType<typeof useTaskListQuery>;
export type TaskListLazyQueryHookResult = ReturnType<typeof useTaskListLazyQuery>;
export type TaskListQueryResult = Apollo.QueryResult<TaskListQuery, TaskListQueryVariables>;
export const CreateMetricAndExportDocument = gql`
  mutation CreateMetricAndExport($input: CreateMetricAndExportInput!) {
    outcomesCreateMetricAndExport(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        metricDataExport {
          id
          status
        }
        metric {
          id
        }
      }
    }
  }
`;
export type CreateMetricAndExportMutationFn = Apollo.MutationFunction<
  CreateMetricAndExportMutation,
  CreateMetricAndExportMutationVariables
>;

/**
 * __useCreateMetricAndExportMutation__
 *
 * To run a mutation, you first call `useCreateMetricAndExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetricAndExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetricAndExportMutation, { data, loading, error }] = useCreateMetricAndExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMetricAndExportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMetricAndExportMutation,
    CreateMetricAndExportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMetricAndExportMutation, CreateMetricAndExportMutationVariables>(
    CreateMetricAndExportDocument,
    options
  );
}
export type CreateMetricAndExportMutationHookResult = ReturnType<typeof useCreateMetricAndExportMutation>;
export type CreateMetricAndExportMutationResult = Apollo.MutationResult<CreateMetricAndExportMutation>;
export type CreateMetricAndExportMutationOptions = Apollo.BaseMutationOptions<
  CreateMetricAndExportMutation,
  CreateMetricAndExportMutationVariables
>;
export const CreateOutcomesMetricDocument = gql`
  mutation CreateOutcomesMetric($input: CreateMetricInput!) {
    outcomesCreateMetric(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        metric {
          ...MetricDetails
        }
      }
    }
  }
  ${MetricDetailsFragmentDoc}
`;
export type CreateOutcomesMetricMutationFn = Apollo.MutationFunction<
  CreateOutcomesMetricMutation,
  CreateOutcomesMetricMutationVariables
>;

/**
 * __useCreateOutcomesMetricMutation__
 *
 * To run a mutation, you first call `useCreateOutcomesMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOutcomesMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOutcomesMetricMutation, { data, loading, error }] = useCreateOutcomesMetricMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOutcomesMetricMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOutcomesMetricMutation,
    CreateOutcomesMetricMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOutcomesMetricMutation, CreateOutcomesMetricMutationVariables>(
    CreateOutcomesMetricDocument,
    options
  );
}
export type CreateOutcomesMetricMutationHookResult = ReturnType<typeof useCreateOutcomesMetricMutation>;
export type CreateOutcomesMetricMutationResult = Apollo.MutationResult<CreateOutcomesMetricMutation>;
export type CreateOutcomesMetricMutationOptions = Apollo.BaseMutationOptions<
  CreateOutcomesMetricMutation,
  CreateOutcomesMetricMutationVariables
>;
export const CreateOutcomesMetricDataExportDocument = gql`
  mutation CreateOutcomesMetricDataExport($input: CreateMetricDataExportInput!) {
    outcomesCreateMetricDataExport(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        metricDataExport {
          id
          status
        }
      }
    }
  }
`;
export type CreateOutcomesMetricDataExportMutationFn = Apollo.MutationFunction<
  CreateOutcomesMetricDataExportMutation,
  CreateOutcomesMetricDataExportMutationVariables
>;

/**
 * __useCreateOutcomesMetricDataExportMutation__
 *
 * To run a mutation, you first call `useCreateOutcomesMetricDataExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOutcomesMetricDataExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOutcomesMetricDataExportMutation, { data, loading, error }] = useCreateOutcomesMetricDataExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOutcomesMetricDataExportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOutcomesMetricDataExportMutation,
    CreateOutcomesMetricDataExportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOutcomesMetricDataExportMutation,
    CreateOutcomesMetricDataExportMutationVariables
  >(CreateOutcomesMetricDataExportDocument, options);
}
export type CreateOutcomesMetricDataExportMutationHookResult = ReturnType<
  typeof useCreateOutcomesMetricDataExportMutation
>;
export type CreateOutcomesMetricDataExportMutationResult =
  Apollo.MutationResult<CreateOutcomesMetricDataExportMutation>;
export type CreateOutcomesMetricDataExportMutationOptions = Apollo.BaseMutationOptions<
  CreateOutcomesMetricDataExportMutation,
  CreateOutcomesMetricDataExportMutationVariables
>;
export const OutcomesMetricComputedValueDocument = gql`
  query OutcomesMetricComputedValue(
    $metricId: MetricId!
    $entityTreeNode: EntityTreeNodeParams!
    $dateRange: DateRangeInput!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $sortBy: MetricComputedValueSortParameter
    $sortDirection: SortDirection
  ) {
    outcomesMetricComputedValues(
      metricId: $metricId
      entityTreeNode: $entityTreeNode
      dateRange: $dateRange
      first: $first
      last: $last
      after: $after
      before: $before
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      nodes {
        id
        treatmentStartDate
        patient {
          id
          name
        }
        scale {
          id
          name
          shortname
          nanoname
        }
        ageAtMeasurement
        ageAtTreatmentStart
        daysBetweenTreatmentStartAndBaseline
        daysSinceTreatmentStart
        weeksSinceTreatmentStart
        monthsSinceTreatmentStart
        firstDate
        firstThresholdClass
        firstThresholdMnemonic
        firstSeverityCategory
        firstClinicalCategory
        firstValue
        sessionTargetDate
        validity
        thresholdClass
        thresholdMnemonic
        trend
        severityCategory
        clinicalCategory
        value
        delta
        metInclusionCriteria
        metSuccessCriteria
        metTrigger
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useOutcomesMetricComputedValueQuery__
 *
 * To run a query within a React component, call `useOutcomesMetricComputedValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutcomesMetricComputedValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutcomesMetricComputedValueQuery({
 *   variables: {
 *      metricId: // value for 'metricId'
 *      entityTreeNode: // value for 'entityTreeNode'
 *      dateRange: // value for 'dateRange'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useOutcomesMetricComputedValueQuery(
  baseOptions: Apollo.QueryHookOptions<
    OutcomesMetricComputedValueQuery,
    OutcomesMetricComputedValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OutcomesMetricComputedValueQuery, OutcomesMetricComputedValueQueryVariables>(
    OutcomesMetricComputedValueDocument,
    options
  );
}
export function useOutcomesMetricComputedValueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OutcomesMetricComputedValueQuery,
    OutcomesMetricComputedValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OutcomesMetricComputedValueQuery, OutcomesMetricComputedValueQueryVariables>(
    OutcomesMetricComputedValueDocument,
    options
  );
}
export type OutcomesMetricComputedValueQueryHookResult = ReturnType<
  typeof useOutcomesMetricComputedValueQuery
>;
export type OutcomesMetricComputedValueLazyQueryHookResult = ReturnType<
  typeof useOutcomesMetricComputedValueLazyQuery
>;
export type OutcomesMetricComputedValueQueryResult = Apollo.QueryResult<
  OutcomesMetricComputedValueQuery,
  OutcomesMetricComputedValueQueryVariables
>;
export const OutcomesMetricDataExportDownloadUrlDocument = gql`
  mutation OutcomesMetricDataExportDownloadUrl($input: CreateMetricDataExportDownloadUrlInput!) {
    outcomesCreateMetricDataExportDownloadUrl(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        downloadUrl
      }
    }
  }
`;
export type OutcomesMetricDataExportDownloadUrlMutationFn = Apollo.MutationFunction<
  OutcomesMetricDataExportDownloadUrlMutation,
  OutcomesMetricDataExportDownloadUrlMutationVariables
>;

/**
 * __useOutcomesMetricDataExportDownloadUrlMutation__
 *
 * To run a mutation, you first call `useOutcomesMetricDataExportDownloadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOutcomesMetricDataExportDownloadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [outcomesMetricDataExportDownloadUrlMutation, { data, loading, error }] = useOutcomesMetricDataExportDownloadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOutcomesMetricDataExportDownloadUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OutcomesMetricDataExportDownloadUrlMutation,
    OutcomesMetricDataExportDownloadUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OutcomesMetricDataExportDownloadUrlMutation,
    OutcomesMetricDataExportDownloadUrlMutationVariables
  >(OutcomesMetricDataExportDownloadUrlDocument, options);
}
export type OutcomesMetricDataExportDownloadUrlMutationHookResult = ReturnType<
  typeof useOutcomesMetricDataExportDownloadUrlMutation
>;
export type OutcomesMetricDataExportDownloadUrlMutationResult =
  Apollo.MutationResult<OutcomesMetricDataExportDownloadUrlMutation>;
export type OutcomesMetricDataExportDownloadUrlMutationOptions = Apollo.BaseMutationOptions<
  OutcomesMetricDataExportDownloadUrlMutation,
  OutcomesMetricDataExportDownloadUrlMutationVariables
>;
export const OutcomesMetricDataExportDocument = gql`
  query OutcomesMetricDataExport($metricDataExportId: MetricDataExportId!) {
    outcomesMetricDataExport(id: $metricDataExportId) {
      id
      status
      filename
    }
  }
`;

/**
 * __useOutcomesMetricDataExportQuery__
 *
 * To run a query within a React component, call `useOutcomesMetricDataExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutcomesMetricDataExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutcomesMetricDataExportQuery({
 *   variables: {
 *      metricDataExportId: // value for 'metricDataExportId'
 *   },
 * });
 */
export function useOutcomesMetricDataExportQuery(
  baseOptions: Apollo.QueryHookOptions<OutcomesMetricDataExportQuery, OutcomesMetricDataExportQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OutcomesMetricDataExportQuery, OutcomesMetricDataExportQueryVariables>(
    OutcomesMetricDataExportDocument,
    options
  );
}
export function useOutcomesMetricDataExportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OutcomesMetricDataExportQuery,
    OutcomesMetricDataExportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OutcomesMetricDataExportQuery, OutcomesMetricDataExportQueryVariables>(
    OutcomesMetricDataExportDocument,
    options
  );
}
export type OutcomesMetricDataExportQueryHookResult = ReturnType<typeof useOutcomesMetricDataExportQuery>;
export type OutcomesMetricDataExportLazyQueryHookResult = ReturnType<
  typeof useOutcomesMetricDataExportLazyQuery
>;
export type OutcomesMetricDataExportQueryResult = Apollo.QueryResult<
  OutcomesMetricDataExportQuery,
  OutcomesMetricDataExportQueryVariables
>;
export const OutcomesMetricDetailsDocument = gql`
  query OutcomesMetricDetails($id: MetricId!) {
    outcomesMetric(id: $id) {
      id
      name
      entityTreeNode {
        ...EntityTreeNodeDetails
      }
      configuration {
        rollup
        scorerConfig {
          ... on MetricSingleScaleScorerConfiguration {
            scaleScorer {
              id
              scale {
                shortname
                nanoname
                name
              }
            }
          }
          ... on MetricMultiScaleScorerConfiguration {
            scaleScorers {
              id
              scale {
                shortname
                name
                nanoname
              }
            }
          }
        }
        metricInclusionCriteria {
          id
          criterionType
          operator
          stringValue
          numberValue
          integerValue
          severityValues
          trendValue
          stringArrayValue
          integerArrayValue
          excludeResults
        }
        ... on TriggerBasedMetric {
          triggerBasedAggregationType
          metricTriggers {
            id
            triggerType
            severityValue
            trendValue
            integerValue
            stringArrayValue
            stringValue
          }
        }
        ... on TimeBasedMetric {
          timeBasedAggregationType
          timePeriodType
          timePeriodValue
          metricSuccessCriteria {
            id
            criterionType
            operator
            numberValue
            stringValue
            integerValue
            severityValues
            trendValue
          }
        }
      }
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
`;

/**
 * __useOutcomesMetricDetailsQuery__
 *
 * To run a query within a React component, call `useOutcomesMetricDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutcomesMetricDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutcomesMetricDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOutcomesMetricDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<OutcomesMetricDetailsQuery, OutcomesMetricDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OutcomesMetricDetailsQuery, OutcomesMetricDetailsQueryVariables>(
    OutcomesMetricDetailsDocument,
    options
  );
}
export function useOutcomesMetricDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OutcomesMetricDetailsQuery, OutcomesMetricDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OutcomesMetricDetailsQuery, OutcomesMetricDetailsQueryVariables>(
    OutcomesMetricDetailsDocument,
    options
  );
}
export type OutcomesMetricDetailsQueryHookResult = ReturnType<typeof useOutcomesMetricDetailsQuery>;
export type OutcomesMetricDetailsLazyQueryHookResult = ReturnType<typeof useOutcomesMetricDetailsLazyQuery>;
export type OutcomesMetricDetailsQueryResult = Apollo.QueryResult<
  OutcomesMetricDetailsQuery,
  OutcomesMetricDetailsQueryVariables
>;
export const OutcomesMetricSummaryDataDocument = gql`
  query OutcomesMetricSummaryData(
    $metricId: MetricId!
    $drilldownMetric: DrilldownMetricParams!
    $scaleScorerId: ScaleScorerId
  ) {
    outcomesMetricSummaryData(
      metricId: $metricId
      drilldownMetric: $drilldownMetric
      scaleScorerId: $scaleScorerId
    ) {
      value
      totalRows
      eligibleRows
      successfulRows
      triggerRows
      rowsInTimePeriod
      reportEntityTreeNodes {
        entityTreeNode {
          path
          entity {
            id
            name
            shortname
            entityType
          }
        }
        graphInterval
        summaryData {
          name
          value
        }
        summaryGraphData {
          name
          points {
            date
            value
            label
          }
        }
      }
    }
  }
`;

/**
 * __useOutcomesMetricSummaryDataQuery__
 *
 * To run a query within a React component, call `useOutcomesMetricSummaryDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutcomesMetricSummaryDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutcomesMetricSummaryDataQuery({
 *   variables: {
 *      metricId: // value for 'metricId'
 *      drilldownMetric: // value for 'drilldownMetric'
 *      scaleScorerId: // value for 'scaleScorerId'
 *   },
 * });
 */
export function useOutcomesMetricSummaryDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    OutcomesMetricSummaryDataQuery,
    OutcomesMetricSummaryDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OutcomesMetricSummaryDataQuery, OutcomesMetricSummaryDataQueryVariables>(
    OutcomesMetricSummaryDataDocument,
    options
  );
}
export function useOutcomesMetricSummaryDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OutcomesMetricSummaryDataQuery,
    OutcomesMetricSummaryDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OutcomesMetricSummaryDataQuery, OutcomesMetricSummaryDataQueryVariables>(
    OutcomesMetricSummaryDataDocument,
    options
  );
}
export type OutcomesMetricSummaryDataQueryHookResult = ReturnType<typeof useOutcomesMetricSummaryDataQuery>;
export type OutcomesMetricSummaryDataLazyQueryHookResult = ReturnType<
  typeof useOutcomesMetricSummaryDataLazyQuery
>;
export type OutcomesMetricSummaryDataQueryResult = Apollo.QueryResult<
  OutcomesMetricSummaryDataQuery,
  OutcomesMetricSummaryDataQueryVariables
>;
export const OutcomesMetricTemplateDetailsDocument = gql`
  query OutcomesMetricTemplateDetails($id: MetricId!) {
    outcomesMetricTemplate(id: $id) {
      id
      name
      configuration {
        rollup
        scorerConfig {
          ... on MetricSingleScaleScorerConfiguration {
            scaleScorer {
              id
              scale {
                shortname
                name
                nanoname
              }
            }
          }
          ... on MetricMultiScaleScorerConfiguration {
            scaleScorers {
              id
              scale {
                shortname
                name
                nanoname
              }
            }
          }
        }
        metricInclusionCriteria {
          id
          criterionType
          operator
          stringValue
          numberValue
          integerValue
          severityValues
          trendValue
          stringArrayValue
          integerArrayValue
          excludeResults
        }
        ... on TriggerBasedMetric {
          triggerBasedAggregationType
          metricTriggers {
            id
            triggerType
            severityValue
            trendValue
            integerValue
            stringArrayValue
            stringValue
          }
        }
        ... on TimeBasedMetric {
          timeBasedAggregationType
          timePeriodType
          timePeriodValue
          metricSuccessCriteria {
            id
            criterionType
            operator
            numberValue
            stringValue
            integerValue
            severityValues
            trendValue
          }
        }
      }
    }
  }
`;

/**
 * __useOutcomesMetricTemplateDetailsQuery__
 *
 * To run a query within a React component, call `useOutcomesMetricTemplateDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutcomesMetricTemplateDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutcomesMetricTemplateDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOutcomesMetricTemplateDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OutcomesMetricTemplateDetailsQuery,
    OutcomesMetricTemplateDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OutcomesMetricTemplateDetailsQuery, OutcomesMetricTemplateDetailsQueryVariables>(
    OutcomesMetricTemplateDetailsDocument,
    options
  );
}
export function useOutcomesMetricTemplateDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OutcomesMetricTemplateDetailsQuery,
    OutcomesMetricTemplateDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OutcomesMetricTemplateDetailsQuery, OutcomesMetricTemplateDetailsQueryVariables>(
    OutcomesMetricTemplateDetailsDocument,
    options
  );
}
export type OutcomesMetricTemplateDetailsQueryHookResult = ReturnType<
  typeof useOutcomesMetricTemplateDetailsQuery
>;
export type OutcomesMetricTemplateDetailsLazyQueryHookResult = ReturnType<
  typeof useOutcomesMetricTemplateDetailsLazyQuery
>;
export type OutcomesMetricTemplateDetailsQueryResult = Apollo.QueryResult<
  OutcomesMetricTemplateDetailsQuery,
  OutcomesMetricTemplateDetailsQueryVariables
>;
export const OutcomesMetricTemplateNameDocument = gql`
  query OutcomesMetricTemplateName($id: MetricId!) {
    outcomesMetricTemplate(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useOutcomesMetricTemplateNameQuery__
 *
 * To run a query within a React component, call `useOutcomesMetricTemplateNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutcomesMetricTemplateNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutcomesMetricTemplateNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOutcomesMetricTemplateNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    OutcomesMetricTemplateNameQuery,
    OutcomesMetricTemplateNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OutcomesMetricTemplateNameQuery, OutcomesMetricTemplateNameQueryVariables>(
    OutcomesMetricTemplateNameDocument,
    options
  );
}
export function useOutcomesMetricTemplateNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OutcomesMetricTemplateNameQuery,
    OutcomesMetricTemplateNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OutcomesMetricTemplateNameQuery, OutcomesMetricTemplateNameQueryVariables>(
    OutcomesMetricTemplateNameDocument,
    options
  );
}
export type OutcomesMetricTemplateNameQueryHookResult = ReturnType<typeof useOutcomesMetricTemplateNameQuery>;
export type OutcomesMetricTemplateNameLazyQueryHookResult = ReturnType<
  typeof useOutcomesMetricTemplateNameLazyQuery
>;
export type OutcomesMetricTemplateNameQueryResult = Apollo.QueryResult<
  OutcomesMetricTemplateNameQuery,
  OutcomesMetricTemplateNameQueryVariables
>;
export const OutcomesMetricTemplatesDocument = gql`
  query OutcomesMetricTemplates($search: String) {
    outcomesMetricTemplates(first: 1000, search: $search) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useOutcomesMetricTemplatesQuery__
 *
 * To run a query within a React component, call `useOutcomesMetricTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutcomesMetricTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutcomesMetricTemplatesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useOutcomesMetricTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<OutcomesMetricTemplatesQuery, OutcomesMetricTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OutcomesMetricTemplatesQuery, OutcomesMetricTemplatesQueryVariables>(
    OutcomesMetricTemplatesDocument,
    options
  );
}
export function useOutcomesMetricTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OutcomesMetricTemplatesQuery,
    OutcomesMetricTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OutcomesMetricTemplatesQuery, OutcomesMetricTemplatesQueryVariables>(
    OutcomesMetricTemplatesDocument,
    options
  );
}
export type OutcomesMetricTemplatesQueryHookResult = ReturnType<typeof useOutcomesMetricTemplatesQuery>;
export type OutcomesMetricTemplatesLazyQueryHookResult = ReturnType<
  typeof useOutcomesMetricTemplatesLazyQuery
>;
export type OutcomesMetricTemplatesQueryResult = Apollo.QueryResult<
  OutcomesMetricTemplatesQuery,
  OutcomesMetricTemplatesQueryVariables
>;
export const OutcomesMetricsDocument = gql`
  query OutcomesMetrics(
    $availableForNode: EntityTreeNodeParams
    $entityQuery: EntityTreeNodeQueryParams
    $smartSearch: String
    $first: Int = null
    $last: Int = null
    $before: String = null
    $after: String = null
  ) {
    outcomesMetrics(
      status: ACTIVE
      smartSearch: $smartSearch
      availableForNode: $availableForNode
      entityQuery: $entityQuery
      sortBy: DISPLAY_ORDER
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      nodes {
        id
        name
        entityTreeNode {
          ...EntityTreeNodeDetails
        }
        configuration {
          rollup
          scorerConfig {
            ... on MetricSingleScaleScorerConfiguration {
              scaleScorer {
                id
                scale {
                  shortname
                  name
                  nanoname
                }
              }
            }
            ... on MetricMultiScaleScorerConfiguration {
              scaleScorers {
                id
                scale {
                  shortname
                  name
                  nanoname
                }
              }
            }
          }
          metricInclusionCriteria {
            id
            criterionType
            operator
            stringValue
            numberValue
            integerValue
            severityValues
            trendValue
            stringArrayValue
            integerArrayValue
            excludeResults
          }
          ... on TriggerBasedMetric {
            triggerBasedAggregationType
            metricTriggers {
              id
              triggerType
              severityValue
              trendValue
              integerValue
              stringArrayValue
              stringValue
            }
          }
          ... on TimeBasedMetric {
            timeBasedAggregationType
            timePeriodType
            timePeriodValue
            metricSuccessCriteria {
              id
              criterionType
              operator
              numberValue
              stringValue
              integerValue
              severityValues
              trendValue
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
`;

/**
 * __useOutcomesMetricsQuery__
 *
 * To run a query within a React component, call `useOutcomesMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutcomesMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutcomesMetricsQuery({
 *   variables: {
 *      availableForNode: // value for 'availableForNode'
 *      entityQuery: // value for 'entityQuery'
 *      smartSearch: // value for 'smartSearch'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useOutcomesMetricsQuery(
  baseOptions?: Apollo.QueryHookOptions<OutcomesMetricsQuery, OutcomesMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OutcomesMetricsQuery, OutcomesMetricsQueryVariables>(
    OutcomesMetricsDocument,
    options
  );
}
export function useOutcomesMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OutcomesMetricsQuery, OutcomesMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OutcomesMetricsQuery, OutcomesMetricsQueryVariables>(
    OutcomesMetricsDocument,
    options
  );
}
export type OutcomesMetricsQueryHookResult = ReturnType<typeof useOutcomesMetricsQuery>;
export type OutcomesMetricsLazyQueryHookResult = ReturnType<typeof useOutcomesMetricsLazyQuery>;
export type OutcomesMetricsQueryResult = Apollo.QueryResult<
  OutcomesMetricsQuery,
  OutcomesMetricsQueryVariables
>;
export const OutcomesTransientMetricComputedValueDocument = gql`
  query OutcomesTransientMetricComputedValue(
    $metricParams: MetricParams!
    $entityTreeNode: EntityTreeNodeParams!
    $dateRange: DateRangeInput!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $sortBy: MetricComputedValueSortParameter
    $sortDirection: SortDirection
  ) {
    outcomesTransientMetricComputedValues(
      metricParams: $metricParams
      entityTreeNode: $entityTreeNode
      dateRange: $dateRange
      first: $first
      last: $last
      after: $after
      before: $before
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      nodes {
        id
        treatmentStartDate
        patient {
          id
          name
        }
        scale {
          id
          name
          shortname
          nanoname
        }
        ageAtMeasurement
        ageAtTreatmentStart
        daysBetweenTreatmentStartAndBaseline
        daysSinceTreatmentStart
        weeksSinceTreatmentStart
        monthsSinceTreatmentStart
        firstDate
        firstThresholdClass
        firstThresholdMnemonic
        firstSeverityCategory
        firstClinicalCategory
        firstValue
        sessionTargetDate
        validity
        thresholdClass
        thresholdMnemonic
        trend
        severityCategory
        clinicalCategory
        value
        delta
        metInclusionCriteria
        metSuccessCriteria
        metTrigger
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useOutcomesTransientMetricComputedValueQuery__
 *
 * To run a query within a React component, call `useOutcomesTransientMetricComputedValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutcomesTransientMetricComputedValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutcomesTransientMetricComputedValueQuery({
 *   variables: {
 *      metricParams: // value for 'metricParams'
 *      entityTreeNode: // value for 'entityTreeNode'
 *      dateRange: // value for 'dateRange'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useOutcomesTransientMetricComputedValueQuery(
  baseOptions: Apollo.QueryHookOptions<
    OutcomesTransientMetricComputedValueQuery,
    OutcomesTransientMetricComputedValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OutcomesTransientMetricComputedValueQuery,
    OutcomesTransientMetricComputedValueQueryVariables
  >(OutcomesTransientMetricComputedValueDocument, options);
}
export function useOutcomesTransientMetricComputedValueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OutcomesTransientMetricComputedValueQuery,
    OutcomesTransientMetricComputedValueQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OutcomesTransientMetricComputedValueQuery,
    OutcomesTransientMetricComputedValueQueryVariables
  >(OutcomesTransientMetricComputedValueDocument, options);
}
export type OutcomesTransientMetricComputedValueQueryHookResult = ReturnType<
  typeof useOutcomesTransientMetricComputedValueQuery
>;
export type OutcomesTransientMetricComputedValueLazyQueryHookResult = ReturnType<
  typeof useOutcomesTransientMetricComputedValueLazyQuery
>;
export type OutcomesTransientMetricComputedValueQueryResult = Apollo.QueryResult<
  OutcomesTransientMetricComputedValueQuery,
  OutcomesTransientMetricComputedValueQueryVariables
>;
export const OutcomesTransientMetricSummaryDataDocument = gql`
  query OutcomesTransientMetricSummaryData(
    $transientMetricData: MetricParams!
    $drilldownMetric: DrilldownMetricParams!
    $scaleScorerId: ScaleScorerId
  ) {
    outcomesTransientMetricSummaryData(
      transientMetricData: $transientMetricData
      drilldownMetric: $drilldownMetric
      scaleScorerId: $scaleScorerId
    ) {
      value
      totalRows
      eligibleRows
      successfulRows
      triggerRows
      rowsInTimePeriod
      metric {
        configuration {
          ... on TimeBasedMetric {
            timeBasedAggregationType
          }
          ... on TriggerBasedMetric {
            triggerBasedAggregationType
          }
        }
      }
      reportEntityTreeNodes {
        entityTreeNode {
          path
          entity {
            id
            name
            shortname
            entityType
          }
        }
        graphInterval
        summaryData {
          name
          value
        }
        summaryGraphData {
          name
          points {
            date
            value
            label
          }
        }
      }
    }
  }
`;

/**
 * __useOutcomesTransientMetricSummaryDataQuery__
 *
 * To run a query within a React component, call `useOutcomesTransientMetricSummaryDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutcomesTransientMetricSummaryDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutcomesTransientMetricSummaryDataQuery({
 *   variables: {
 *      transientMetricData: // value for 'transientMetricData'
 *      drilldownMetric: // value for 'drilldownMetric'
 *      scaleScorerId: // value for 'scaleScorerId'
 *   },
 * });
 */
export function useOutcomesTransientMetricSummaryDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    OutcomesTransientMetricSummaryDataQuery,
    OutcomesTransientMetricSummaryDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OutcomesTransientMetricSummaryDataQuery,
    OutcomesTransientMetricSummaryDataQueryVariables
  >(OutcomesTransientMetricSummaryDataDocument, options);
}
export function useOutcomesTransientMetricSummaryDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OutcomesTransientMetricSummaryDataQuery,
    OutcomesTransientMetricSummaryDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OutcomesTransientMetricSummaryDataQuery,
    OutcomesTransientMetricSummaryDataQueryVariables
  >(OutcomesTransientMetricSummaryDataDocument, options);
}
export type OutcomesTransientMetricSummaryDataQueryHookResult = ReturnType<
  typeof useOutcomesTransientMetricSummaryDataQuery
>;
export type OutcomesTransientMetricSummaryDataLazyQueryHookResult = ReturnType<
  typeof useOutcomesTransientMetricSummaryDataLazyQuery
>;
export type OutcomesTransientMetricSummaryDataQueryResult = Apollo.QueryResult<
  OutcomesTransientMetricSummaryDataQuery,
  OutcomesTransientMetricSummaryDataQueryVariables
>;
export const ScaleScorerConfigurationDocument = gql`
  query ScaleScorerConfiguration($id: ScaleScorerId!) {
    assessmentScaleScorer(id: $id) {
      id
      supportedTrends
      supportedSeverityCategories
      ... on NumericalScaleScorer {
        domain
        minValue
        maxValue
        minInterpretation
        maxInterpretation
        minInterpretationShort
        maxInterpretationShort
        thresholdData {
          mnemonic
          thresholdClass
          minValue
          maxValue
        }
      }
    }
  }
`;

/**
 * __useScaleScorerConfigurationQuery__
 *
 * To run a query within a React component, call `useScaleScorerConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useScaleScorerConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScaleScorerConfigurationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScaleScorerConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<ScaleScorerConfigurationQuery, ScaleScorerConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScaleScorerConfigurationQuery, ScaleScorerConfigurationQueryVariables>(
    ScaleScorerConfigurationDocument,
    options
  );
}
export function useScaleScorerConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScaleScorerConfigurationQuery,
    ScaleScorerConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScaleScorerConfigurationQuery, ScaleScorerConfigurationQueryVariables>(
    ScaleScorerConfigurationDocument,
    options
  );
}
export type ScaleScorerConfigurationQueryHookResult = ReturnType<typeof useScaleScorerConfigurationQuery>;
export type ScaleScorerConfigurationLazyQueryHookResult = ReturnType<
  typeof useScaleScorerConfigurationLazyQuery
>;
export type ScaleScorerConfigurationQueryResult = Apollo.QueryResult<
  ScaleScorerConfigurationQuery,
  ScaleScorerConfigurationQueryVariables
>;
export const ScaleScorerSubtreeDocument = gql`
  query ScaleScorerSubtree($domain: [ScaleScorerDomain!], $parentId: ScaleScorerId!) {
    assessmentScaleScorers(first: 100, domain: $domain, parentId: $parentId) {
      nodes {
        id
      }
    }
  }
`;

/**
 * __useScaleScorerSubtreeQuery__
 *
 * To run a query within a React component, call `useScaleScorerSubtreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useScaleScorerSubtreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScaleScorerSubtreeQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useScaleScorerSubtreeQuery(
  baseOptions: Apollo.QueryHookOptions<ScaleScorerSubtreeQuery, ScaleScorerSubtreeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScaleScorerSubtreeQuery, ScaleScorerSubtreeQueryVariables>(
    ScaleScorerSubtreeDocument,
    options
  );
}
export function useScaleScorerSubtreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScaleScorerSubtreeQuery, ScaleScorerSubtreeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScaleScorerSubtreeQuery, ScaleScorerSubtreeQueryVariables>(
    ScaleScorerSubtreeDocument,
    options
  );
}
export type ScaleScorerSubtreeQueryHookResult = ReturnType<typeof useScaleScorerSubtreeQuery>;
export type ScaleScorerSubtreeLazyQueryHookResult = ReturnType<typeof useScaleScorerSubtreeLazyQuery>;
export type ScaleScorerSubtreeQueryResult = Apollo.QueryResult<
  ScaleScorerSubtreeQuery,
  ScaleScorerSubtreeQueryVariables
>;
export const ScaleScorersConfigurationDocument = gql`
  query ScaleScorersConfiguration($ids: [ScaleScorerId!]!) {
    assessmentScaleScorers(id: $ids, first: 100) {
      nodes {
        id
        scoreType
        supportedTrends
        supportedSeverityCategories
        scale {
          name
          shortname
          nanoname
        }
        ... on NumericalScaleScorer {
          domain
          minValue
          maxValue
          minInterpretation
          maxInterpretation
          minInterpretationShort
          maxInterpretationShort
          thresholdData {
            mnemonic
            thresholdClass
            minValue
            maxValue
          }
        }
      }
    }
  }
`;

/**
 * __useScaleScorersConfigurationQuery__
 *
 * To run a query within a React component, call `useScaleScorersConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useScaleScorersConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScaleScorersConfigurationQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useScaleScorersConfigurationQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScaleScorersConfigurationQuery,
    ScaleScorersConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScaleScorersConfigurationQuery, ScaleScorersConfigurationQueryVariables>(
    ScaleScorersConfigurationDocument,
    options
  );
}
export function useScaleScorersConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScaleScorersConfigurationQuery,
    ScaleScorersConfigurationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScaleScorersConfigurationQuery, ScaleScorersConfigurationQueryVariables>(
    ScaleScorersConfigurationDocument,
    options
  );
}
export type ScaleScorersConfigurationQueryHookResult = ReturnType<typeof useScaleScorersConfigurationQuery>;
export type ScaleScorersConfigurationLazyQueryHookResult = ReturnType<
  typeof useScaleScorersConfigurationLazyQuery
>;
export type ScaleScorersConfigurationQueryResult = Apollo.QueryResult<
  ScaleScorersConfigurationQuery,
  ScaleScorersConfigurationQueryVariables
>;
export const UpdateOutcomesMetricDocument = gql`
  mutation UpdateOutcomesMetric($input: UpdateMetricInput!) {
    outcomesUpdateMetric(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        metric {
          ...MetricDetails
        }
      }
    }
  }
  ${MetricDetailsFragmentDoc}
`;
export type UpdateOutcomesMetricMutationFn = Apollo.MutationFunction<
  UpdateOutcomesMetricMutation,
  UpdateOutcomesMetricMutationVariables
>;

/**
 * __useUpdateOutcomesMetricMutation__
 *
 * To run a mutation, you first call `useUpdateOutcomesMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutcomesMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutcomesMetricMutation, { data, loading, error }] = useUpdateOutcomesMetricMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOutcomesMetricMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOutcomesMetricMutation,
    UpdateOutcomesMetricMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOutcomesMetricMutation, UpdateOutcomesMetricMutationVariables>(
    UpdateOutcomesMetricDocument,
    options
  );
}
export type UpdateOutcomesMetricMutationHookResult = ReturnType<typeof useUpdateOutcomesMetricMutation>;
export type UpdateOutcomesMetricMutationResult = Apollo.MutationResult<UpdateOutcomesMetricMutation>;
export type UpdateOutcomesMetricMutationOptions = Apollo.BaseMutationOptions<
  UpdateOutcomesMetricMutation,
  UpdateOutcomesMetricMutationVariables
>;
export const UpdateOutcomesMetricStatusDocument = gql`
  mutation UpdateOutcomesMetricStatus($input: UpdateMetricStatusInput!) {
    outcomesUpdateMetricStatus(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        metric {
          ...MetricDetails
        }
      }
    }
  }
  ${MetricDetailsFragmentDoc}
`;
export type UpdateOutcomesMetricStatusMutationFn = Apollo.MutationFunction<
  UpdateOutcomesMetricStatusMutation,
  UpdateOutcomesMetricStatusMutationVariables
>;

/**
 * __useUpdateOutcomesMetricStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOutcomesMetricStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOutcomesMetricStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOutcomesMetricStatusMutation, { data, loading, error }] = useUpdateOutcomesMetricStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOutcomesMetricStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOutcomesMetricStatusMutation,
    UpdateOutcomesMetricStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOutcomesMetricStatusMutation, UpdateOutcomesMetricStatusMutationVariables>(
    UpdateOutcomesMetricStatusDocument,
    options
  );
}
export type UpdateOutcomesMetricStatusMutationHookResult = ReturnType<
  typeof useUpdateOutcomesMetricStatusMutation
>;
export type UpdateOutcomesMetricStatusMutationResult =
  Apollo.MutationResult<UpdateOutcomesMetricStatusMutation>;
export type UpdateOutcomesMetricStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateOutcomesMetricStatusMutation,
  UpdateOutcomesMetricStatusMutationVariables
>;
export const AppointmentAlgorithmDetailsDocument = gql`
  query AppointmentAlgorithmDetails($appointmentId: AppointmentId!) {
    schedulingAppointment(id: $appointmentId) {
      id
      careEpisode {
        id
      }
      treatmentService {
        id
        name
      }
      lastCareEpisodeCreationAlgorithm {
        algorithm
        statusName
        statusDescription
      }
      lastPatientSessionCreationAlgorithm {
        algorithm
        statusName
        statusDescription
      }
      lastActiveCareEpisodeCreationAlgorithm {
        algorithm
        statusName
        statusDescription
      }
      lastActivePatientSessionCreationAlgorithm {
        algorithm
        statusName
        statusDescription
      }
      source
      inferredCareEpisodeReason
      inferredTreatmentServiceReason
      organizationSource
      careEpisodeSource
      treatmentServiceSource
      patientSession {
        id
        scalesPlanned
      }
    }
  }
`;

/**
 * __useAppointmentAlgorithmDetailsQuery__
 *
 * To run a query within a React component, call `useAppointmentAlgorithmDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentAlgorithmDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentAlgorithmDetailsQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useAppointmentAlgorithmDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentAlgorithmDetailsQuery,
    AppointmentAlgorithmDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentAlgorithmDetailsQuery, AppointmentAlgorithmDetailsQueryVariables>(
    AppointmentAlgorithmDetailsDocument,
    options
  );
}
export function useAppointmentAlgorithmDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentAlgorithmDetailsQuery,
    AppointmentAlgorithmDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentAlgorithmDetailsQuery, AppointmentAlgorithmDetailsQueryVariables>(
    AppointmentAlgorithmDetailsDocument,
    options
  );
}
export type AppointmentAlgorithmDetailsQueryHookResult = ReturnType<
  typeof useAppointmentAlgorithmDetailsQuery
>;
export type AppointmentAlgorithmDetailsLazyQueryHookResult = ReturnType<
  typeof useAppointmentAlgorithmDetailsLazyQuery
>;
export type AppointmentAlgorithmDetailsQueryResult = Apollo.QueryResult<
  AppointmentAlgorithmDetailsQuery,
  AppointmentAlgorithmDetailsQueryVariables
>;
export const AppointmentDetailsDocument = gql`
  query AppointmentDetails($appointmentId: AppointmentId!) {
    schedulingAppointment(id: $appointmentId) {
      id
      source
      createdAt
      lastImportDate
      startDate
      status
      serviceType
      minutesDuration
      measurementStatus
      measurementCategory
      organization {
        id
        name
      }
      organizationSource
      provider {
        id
        name
      }
      patient {
        id
        name
      }
      treatmentService {
        id
        name
      }
      careEpisode {
        id
        status
        name
        organization {
          id
          name
        }
        treatmentService {
          id
          name
        }
      }
      careEpisodeSource
      patientSession {
        id
        sessionNumber
        autoplan
        assessmentReport {
          id
          invitations {
            id
            status
            format
            user {
              name
            }
          }
        }
        isReportAvailable
        schedulingRecurrence {
          id
          endDate
          repeatFrequency
        }
      }
    }
  }
`;

/**
 * __useAppointmentDetailsQuery__
 *
 * To run a query within a React component, call `useAppointmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentDetailsQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useAppointmentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<AppointmentDetailsQuery, AppointmentDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentDetailsQuery, AppointmentDetailsQueryVariables>(
    AppointmentDetailsDocument,
    options
  );
}
export function useAppointmentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppointmentDetailsQuery, AppointmentDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentDetailsQuery, AppointmentDetailsQueryVariables>(
    AppointmentDetailsDocument,
    options
  );
}
export type AppointmentDetailsQueryHookResult = ReturnType<typeof useAppointmentDetailsQuery>;
export type AppointmentDetailsLazyQueryHookResult = ReturnType<typeof useAppointmentDetailsLazyQuery>;
export type AppointmentDetailsQueryResult = Apollo.QueryResult<
  AppointmentDetailsQuery,
  AppointmentDetailsQueryVariables
>;
export const AppointmentInvitationDetailsDocument = gql`
  query AppointmentInvitationDetails($appointmentId: AppointmentId!) {
    schedulingAppointment(id: $appointmentId) {
      id
      patientSession {
        id
        scalesPlanned
        assessmentReport {
          invitations {
            id
            status
            referrer
            phase
            format
            opensAt
            closesAt
            dateBegun
            dateCompleted
            user {
              id
              name
              userType
              notificationPreference
              allowsText
              allowsEmail
            }
            invitationNotifications {
              id
              status
              sendAt
              dateTexted
              dateEmailed
              textAllowed
              emailAllowed
            }
            reportScales {
              id
              disabled
              scale {
                id
                name
                shortname
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAppointmentInvitationDetailsQuery__
 *
 * To run a query within a React component, call `useAppointmentInvitationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentInvitationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentInvitationDetailsQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useAppointmentInvitationDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentInvitationDetailsQuery,
    AppointmentInvitationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentInvitationDetailsQuery, AppointmentInvitationDetailsQueryVariables>(
    AppointmentInvitationDetailsDocument,
    options
  );
}
export function useAppointmentInvitationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentInvitationDetailsQuery,
    AppointmentInvitationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentInvitationDetailsQuery, AppointmentInvitationDetailsQueryVariables>(
    AppointmentInvitationDetailsDocument,
    options
  );
}
export type AppointmentInvitationDetailsQueryHookResult = ReturnType<
  typeof useAppointmentInvitationDetailsQuery
>;
export type AppointmentInvitationDetailsLazyQueryHookResult = ReturnType<
  typeof useAppointmentInvitationDetailsLazyQuery
>;
export type AppointmentInvitationDetailsQueryResult = Apollo.QueryResult<
  AppointmentInvitationDetailsQuery,
  AppointmentInvitationDetailsQueryVariables
>;
export const AppointmentMeasurementPlansDocument = gql`
  query AppointmentMeasurementPlans($appointmentId: AppointmentId!) {
    schedulingAppointment(id: $appointmentId) {
      id
      patientSession {
        id
        autoplan
        entityMeasurementPlans {
          id
          planType
          entityTreeNode {
            path
            minAge
            maxAge
            entity {
              name
              shortname
              id
              entityType
            }
            segments {
              id
              path
              entityType
              name
              shortname
              rootPath
            }
          }
          measurementPlan {
            id
            name
            description
            measurementPlanScales {
              ...MeasurementPlanScaleDetails
            }
          }
        }
      }
    }
  }
  ${MeasurementPlanScaleDetailsFragmentDoc}
`;

/**
 * __useAppointmentMeasurementPlansQuery__
 *
 * To run a query within a React component, call `useAppointmentMeasurementPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentMeasurementPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentMeasurementPlansQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useAppointmentMeasurementPlansQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentMeasurementPlansQuery,
    AppointmentMeasurementPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentMeasurementPlansQuery, AppointmentMeasurementPlansQueryVariables>(
    AppointmentMeasurementPlansDocument,
    options
  );
}
export function useAppointmentMeasurementPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentMeasurementPlansQuery,
    AppointmentMeasurementPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentMeasurementPlansQuery, AppointmentMeasurementPlansQueryVariables>(
    AppointmentMeasurementPlansDocument,
    options
  );
}
export type AppointmentMeasurementPlansQueryHookResult = ReturnType<
  typeof useAppointmentMeasurementPlansQuery
>;
export type AppointmentMeasurementPlansLazyQueryHookResult = ReturnType<
  typeof useAppointmentMeasurementPlansLazyQuery
>;
export type AppointmentMeasurementPlansQueryResult = Apollo.QueryResult<
  AppointmentMeasurementPlansQuery,
  AppointmentMeasurementPlansQueryVariables
>;
export const AppointmentScaleAdministrationDocument = gql`
  query AppointmentScaleAdministration($appointmentId: AppointmentId!) {
    schedulingAppointment(id: $appointmentId) {
      id
      patientSession {
        id
        scalesPlanned
        lastPlannedAt
        assessmentReport {
          reportScales {
            id
            disabled
            manual
            planningDecision
            planningReason
            scale {
              id
              name
              shortname
            }
            careMeasurementPlanScales {
              measurementPlan {
                id
                name
              }
              ...MeasurementPlanScaleDetails
            }
          }
        }
      }
    }
  }
  ${MeasurementPlanScaleDetailsFragmentDoc}
`;

/**
 * __useAppointmentScaleAdministrationQuery__
 *
 * To run a query within a React component, call `useAppointmentScaleAdministrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentScaleAdministrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentScaleAdministrationQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useAppointmentScaleAdministrationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentScaleAdministrationQuery,
    AppointmentScaleAdministrationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentScaleAdministrationQuery, AppointmentScaleAdministrationQueryVariables>(
    AppointmentScaleAdministrationDocument,
    options
  );
}
export function useAppointmentScaleAdministrationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentScaleAdministrationQuery,
    AppointmentScaleAdministrationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppointmentScaleAdministrationQuery,
    AppointmentScaleAdministrationQueryVariables
  >(AppointmentScaleAdministrationDocument, options);
}
export type AppointmentScaleAdministrationQueryHookResult = ReturnType<
  typeof useAppointmentScaleAdministrationQuery
>;
export type AppointmentScaleAdministrationLazyQueryHookResult = ReturnType<
  typeof useAppointmentScaleAdministrationLazyQuery
>;
export type AppointmentScaleAdministrationQueryResult = Apollo.QueryResult<
  AppointmentScaleAdministrationQuery,
  AppointmentScaleAdministrationQueryVariables
>;
export const AppointmentSessionCreationAlgorithmDetailsDocument = gql`
  query AppointmentSessionCreationAlgorithmDetails($appointmentId: AppointmentId!) {
    schedulingAppointment(id: $appointmentId) {
      id
      patientSession {
        id
      }
      lastPatientSessionCreationAlgorithm {
        algorithm
        statusName
        statusDescription
      }
      lastActivePatientSessionCreationAlgorithm {
        algorithm
        statusName
        statusDescription
      }
      source
    }
  }
`;

/**
 * __useAppointmentSessionCreationAlgorithmDetailsQuery__
 *
 * To run a query within a React component, call `useAppointmentSessionCreationAlgorithmDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentSessionCreationAlgorithmDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentSessionCreationAlgorithmDetailsQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useAppointmentSessionCreationAlgorithmDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentSessionCreationAlgorithmDetailsQuery,
    AppointmentSessionCreationAlgorithmDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AppointmentSessionCreationAlgorithmDetailsQuery,
    AppointmentSessionCreationAlgorithmDetailsQueryVariables
  >(AppointmentSessionCreationAlgorithmDetailsDocument, options);
}
export function useAppointmentSessionCreationAlgorithmDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentSessionCreationAlgorithmDetailsQuery,
    AppointmentSessionCreationAlgorithmDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AppointmentSessionCreationAlgorithmDetailsQuery,
    AppointmentSessionCreationAlgorithmDetailsQueryVariables
  >(AppointmentSessionCreationAlgorithmDetailsDocument, options);
}
export type AppointmentSessionCreationAlgorithmDetailsQueryHookResult = ReturnType<
  typeof useAppointmentSessionCreationAlgorithmDetailsQuery
>;
export type AppointmentSessionCreationAlgorithmDetailsLazyQueryHookResult = ReturnType<
  typeof useAppointmentSessionCreationAlgorithmDetailsLazyQuery
>;
export type AppointmentSessionCreationAlgorithmDetailsQueryResult = Apollo.QueryResult<
  AppointmentSessionCreationAlgorithmDetailsQuery,
  AppointmentSessionCreationAlgorithmDetailsQueryVariables
>;
export const CancelAppointmentDocument = gql`
  mutation CancelAppointment($input: CancelAppointmentInput!) {
    schedulingCancelAppointment(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        appointment {
          id
          status
        }
      }
    }
  }
`;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<CancelAppointmentMutation, CancelAppointmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelAppointmentMutation, CancelAppointmentMutationVariables>(
    CancelAppointmentDocument,
    options
  );
}
export type CancelAppointmentMutationHookResult = ReturnType<typeof useCancelAppointmentMutation>;
export type CancelAppointmentMutationResult = Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;
export const CreateAppointmentDocument = gql`
  mutation CreateAppointment($input: CreateAppointmentInput!) {
    schedulingCreateAppointment(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        appointment {
          id
        }
      }
    }
  }
`;
export type CreateAppointmentMutationFn = Apollo.MutationFunction<
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables
>;

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAppointmentMutation, CreateAppointmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAppointmentMutation, CreateAppointmentMutationVariables>(
    CreateAppointmentDocument,
    options
  );
}
export type CreateAppointmentMutationHookResult = ReturnType<typeof useCreateAppointmentMutation>;
export type CreateAppointmentMutationResult = Apollo.MutationResult<CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables
>;
export const DeleteAppointmentDocument = gql`
  mutation DeleteAppointment($input: DeleteAppointmentInput!) {
    schedulingDeleteAppointment(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        deletedAppointmentId
      }
    }
  }
`;
export type DeleteAppointmentMutationFn = Apollo.MutationFunction<
  DeleteAppointmentMutation,
  DeleteAppointmentMutationVariables
>;

/**
 * __useDeleteAppointmentMutation__
 *
 * To run a mutation, you first call `useDeleteAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppointmentMutation, { data, loading, error }] = useDeleteAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAppointmentMutation, DeleteAppointmentMutationVariables>(
    DeleteAppointmentDocument,
    options
  );
}
export type DeleteAppointmentMutationHookResult = ReturnType<typeof useDeleteAppointmentMutation>;
export type DeleteAppointmentMutationResult = Apollo.MutationResult<DeleteAppointmentMutation>;
export type DeleteAppointmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteAppointmentMutation,
  DeleteAppointmentMutationVariables
>;
export const EditAppointmentDocument = gql`
  mutation EditAppointment($input: EditAppointmentInput!) {
    schedulingEditAppointment(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        appointment {
          id
        }
      }
    }
  }
`;
export type EditAppointmentMutationFn = Apollo.MutationFunction<
  EditAppointmentMutation,
  EditAppointmentMutationVariables
>;

/**
 * __useEditAppointmentMutation__
 *
 * To run a mutation, you first call `useEditAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAppointmentMutation, { data, loading, error }] = useEditAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<EditAppointmentMutation, EditAppointmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditAppointmentMutation, EditAppointmentMutationVariables>(
    EditAppointmentDocument,
    options
  );
}
export type EditAppointmentMutationHookResult = ReturnType<typeof useEditAppointmentMutation>;
export type EditAppointmentMutationResult = Apollo.MutationResult<EditAppointmentMutation>;
export type EditAppointmentMutationOptions = Apollo.BaseMutationOptions<
  EditAppointmentMutation,
  EditAppointmentMutationVariables
>;
export const MbcResendInvitationNotificationsDocument = gql`
  mutation MbcResendInvitationNotifications($input: ResendInvitationNotificationsInput!) {
    assessmentResendInvitationNotifications(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        newNotificationSent
        invitation {
          id
          invitationNotifications {
            id
            status
            sendAt
            dateTexted
            dateEmailed
            textAllowed
            emailAllowed
          }
        }
      }
    }
  }
`;
export type MbcResendInvitationNotificationsMutationFn = Apollo.MutationFunction<
  MbcResendInvitationNotificationsMutation,
  MbcResendInvitationNotificationsMutationVariables
>;

/**
 * __useMbcResendInvitationNotificationsMutation__
 *
 * To run a mutation, you first call `useMbcResendInvitationNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMbcResendInvitationNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mbcResendInvitationNotificationsMutation, { data, loading, error }] = useMbcResendInvitationNotificationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMbcResendInvitationNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MbcResendInvitationNotificationsMutation,
    MbcResendInvitationNotificationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MbcResendInvitationNotificationsMutation,
    MbcResendInvitationNotificationsMutationVariables
  >(MbcResendInvitationNotificationsDocument, options);
}
export type MbcResendInvitationNotificationsMutationHookResult = ReturnType<
  typeof useMbcResendInvitationNotificationsMutation
>;
export type MbcResendInvitationNotificationsMutationResult =
  Apollo.MutationResult<MbcResendInvitationNotificationsMutation>;
export type MbcResendInvitationNotificationsMutationOptions = Apollo.BaseMutationOptions<
  MbcResendInvitationNotificationsMutation,
  MbcResendInvitationNotificationsMutationVariables
>;
export const CareEpisodeDetailsDocument = gql`
  query CareEpisodeDetails($careEpisodeId: CareEpisodeId!) {
    careEpisode(id: $careEpisodeId) {
      id
      status
      periodStart
      periodEnd
      dischargeDueToInactivityDate
      patient {
        id
        hasCompletedAssessment
      }
      organization {
        id
        name
      }
      treatmentService {
        id
        name
      }
      careEpisodeProviders {
        id
        relationship
        provider {
          id
          name
        }
      }
      careEpisodeTreatmentTracks {
        id
        status
        enrollmentDate
        completionDate
        treatmentTrack {
          id
          name
        }
      }
      ...CareEpisodeMeasurementAllowedDetails
    }
  }
  ${CareEpisodeMeasurementAllowedDetailsFragmentDoc}
`;

/**
 * __useCareEpisodeDetailsQuery__
 *
 * To run a query within a React component, call `useCareEpisodeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareEpisodeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareEpisodeDetailsQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *   },
 * });
 */
export function useCareEpisodeDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<CareEpisodeDetailsQuery, CareEpisodeDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareEpisodeDetailsQuery, CareEpisodeDetailsQueryVariables>(
    CareEpisodeDetailsDocument,
    options
  );
}
export function useCareEpisodeDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareEpisodeDetailsQuery, CareEpisodeDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareEpisodeDetailsQuery, CareEpisodeDetailsQueryVariables>(
    CareEpisodeDetailsDocument,
    options
  );
}
export type CareEpisodeDetailsQueryHookResult = ReturnType<typeof useCareEpisodeDetailsQuery>;
export type CareEpisodeDetailsLazyQueryHookResult = ReturnType<typeof useCareEpisodeDetailsLazyQuery>;
export type CareEpisodeDetailsQueryResult = Apollo.QueryResult<
  CareEpisodeDetailsQuery,
  CareEpisodeDetailsQueryVariables
>;
export const CareEpisodeDetailsEditDocument = gql`
  query CareEpisodeDetailsEdit($careEpisodeId: CareEpisodeId!) {
    careEpisode(id: $careEpisodeId) {
      id
      status
      periodStart
      periodEnd
      institute {
        organizations {
          nodes {
            id
            name
            parentId
            providers {
              nodes {
                id
                name
              }
            }
          }
        }
        providers {
          id
          name
        }
      }
      organization {
        id
        name
      }
      treatmentService {
        id
        name
      }
      careEpisodeProviders {
        id
        relationship
        provider {
          id
          name
        }
      }
      ...CareEpisodeMeasurementAllowedDetails
    }
  }
  ${CareEpisodeMeasurementAllowedDetailsFragmentDoc}
`;

/**
 * __useCareEpisodeDetailsEditQuery__
 *
 * To run a query within a React component, call `useCareEpisodeDetailsEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareEpisodeDetailsEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareEpisodeDetailsEditQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *   },
 * });
 */
export function useCareEpisodeDetailsEditQuery(
  baseOptions: Apollo.QueryHookOptions<CareEpisodeDetailsEditQuery, CareEpisodeDetailsEditQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareEpisodeDetailsEditQuery, CareEpisodeDetailsEditQueryVariables>(
    CareEpisodeDetailsEditDocument,
    options
  );
}
export function useCareEpisodeDetailsEditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareEpisodeDetailsEditQuery, CareEpisodeDetailsEditQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareEpisodeDetailsEditQuery, CareEpisodeDetailsEditQueryVariables>(
    CareEpisodeDetailsEditDocument,
    options
  );
}
export type CareEpisodeDetailsEditQueryHookResult = ReturnType<typeof useCareEpisodeDetailsEditQuery>;
export type CareEpisodeDetailsEditLazyQueryHookResult = ReturnType<typeof useCareEpisodeDetailsEditLazyQuery>;
export type CareEpisodeDetailsEditQueryResult = Apollo.QueryResult<
  CareEpisodeDetailsEditQuery,
  CareEpisodeDetailsEditQueryVariables
>;
export const CareEpisodeSessionsDocument = gql`
  query CareEpisodeSessions(
    $sortBy: PatientSessionSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $careEpisodeId: CareEpisodeId!
  ) {
    careEpisode(id: $careEpisodeId) {
      id
      patientSessions(
        sortBy: $sortBy
        sortDirection: $sortDirection
        first: $first
        last: $last
        after: $after
        before: $before
      ) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        nodes {
          id
          targetDate
          status
          assessmentReport {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useCareEpisodeSessionsQuery__
 *
 * To run a query within a React component, call `useCareEpisodeSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareEpisodeSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareEpisodeSessionsQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      careEpisodeId: // value for 'careEpisodeId'
 *   },
 * });
 */
export function useCareEpisodeSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<CareEpisodeSessionsQuery, CareEpisodeSessionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareEpisodeSessionsQuery, CareEpisodeSessionsQueryVariables>(
    CareEpisodeSessionsDocument,
    options
  );
}
export function useCareEpisodeSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareEpisodeSessionsQuery, CareEpisodeSessionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareEpisodeSessionsQuery, CareEpisodeSessionsQueryVariables>(
    CareEpisodeSessionsDocument,
    options
  );
}
export type CareEpisodeSessionsQueryHookResult = ReturnType<typeof useCareEpisodeSessionsQuery>;
export type CareEpisodeSessionsLazyQueryHookResult = ReturnType<typeof useCareEpisodeSessionsLazyQuery>;
export type CareEpisodeSessionsQueryResult = Apollo.QueryResult<
  CareEpisodeSessionsQuery,
  CareEpisodeSessionsQueryVariables
>;
export const CareUnitSummaryDocument = gql`
  query CareUnitSummary($patientId: PatientId!) {
    patient(id: $patientId) {
      id
      name
    }
  }
`;

/**
 * __useCareUnitSummaryQuery__
 *
 * To run a query within a React component, call `useCareUnitSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareUnitSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareUnitSummaryQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useCareUnitSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<CareUnitSummaryQuery, CareUnitSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareUnitSummaryQuery, CareUnitSummaryQueryVariables>(
    CareUnitSummaryDocument,
    options
  );
}
export function useCareUnitSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareUnitSummaryQuery, CareUnitSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareUnitSummaryQuery, CareUnitSummaryQueryVariables>(
    CareUnitSummaryDocument,
    options
  );
}
export type CareUnitSummaryQueryHookResult = ReturnType<typeof useCareUnitSummaryQuery>;
export type CareUnitSummaryLazyQueryHookResult = ReturnType<typeof useCareUnitSummaryLazyQuery>;
export type CareUnitSummaryQueryResult = Apollo.QueryResult<
  CareUnitSummaryQuery,
  CareUnitSummaryQueryVariables
>;
export const CreateCareEpisodeDocument = gql`
  mutation CreateCareEpisode($careEpisode: CreateCareEpisodeInput!) {
    createCareEpisode(input: $careEpisode) {
      success
      errors {
        message
        path
      }
      result {
        careEpisode {
          id
        }
      }
    }
  }
`;
export type CreateCareEpisodeMutationFn = Apollo.MutationFunction<
  CreateCareEpisodeMutation,
  CreateCareEpisodeMutationVariables
>;

/**
 * __useCreateCareEpisodeMutation__
 *
 * To run a mutation, you first call `useCreateCareEpisodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCareEpisodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCareEpisodeMutation, { data, loading, error }] = useCreateCareEpisodeMutation({
 *   variables: {
 *      careEpisode: // value for 'careEpisode'
 *   },
 * });
 */
export function useCreateCareEpisodeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCareEpisodeMutation, CreateCareEpisodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCareEpisodeMutation, CreateCareEpisodeMutationVariables>(
    CreateCareEpisodeDocument,
    options
  );
}
export type CreateCareEpisodeMutationHookResult = ReturnType<typeof useCreateCareEpisodeMutation>;
export type CreateCareEpisodeMutationResult = Apollo.MutationResult<CreateCareEpisodeMutation>;
export type CreateCareEpisodeMutationOptions = Apollo.BaseMutationOptions<
  CreateCareEpisodeMutation,
  CreateCareEpisodeMutationVariables
>;
export const CreateCareEpisodeTreatmentTrackDocument = gql`
  mutation CreateCareEpisodeTreatmentTrack($input: CreateCareEpisodeTreatmentTrackInput!) {
    careCreateCareEpisodeTreatmentTrack(input: $input) {
      errors {
        message
        path
      }
      success
      result {
        careEpisodeTreatmentTrack {
          id
          treatmentTrack {
            id
            name
          }
          status
          careEpisode {
            careEpisodeTreatmentTracks {
              id
              treatmentTrack {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;
export type CreateCareEpisodeTreatmentTrackMutationFn = Apollo.MutationFunction<
  CreateCareEpisodeTreatmentTrackMutation,
  CreateCareEpisodeTreatmentTrackMutationVariables
>;

/**
 * __useCreateCareEpisodeTreatmentTrackMutation__
 *
 * To run a mutation, you first call `useCreateCareEpisodeTreatmentTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCareEpisodeTreatmentTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCareEpisodeTreatmentTrackMutation, { data, loading, error }] = useCreateCareEpisodeTreatmentTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCareEpisodeTreatmentTrackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCareEpisodeTreatmentTrackMutation,
    CreateCareEpisodeTreatmentTrackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCareEpisodeTreatmentTrackMutation,
    CreateCareEpisodeTreatmentTrackMutationVariables
  >(CreateCareEpisodeTreatmentTrackDocument, options);
}
export type CreateCareEpisodeTreatmentTrackMutationHookResult = ReturnType<
  typeof useCreateCareEpisodeTreatmentTrackMutation
>;
export type CreateCareEpisodeTreatmentTrackMutationResult =
  Apollo.MutationResult<CreateCareEpisodeTreatmentTrackMutation>;
export type CreateCareEpisodeTreatmentTrackMutationOptions = Apollo.BaseMutationOptions<
  CreateCareEpisodeTreatmentTrackMutation,
  CreateCareEpisodeTreatmentTrackMutationVariables
>;
export const EditCareEpisodeDocument = gql`
  mutation EditCareEpisode($careEpisode: EditCareEpisodeInput!) {
    editCareEpisode(input: $careEpisode) {
      success
      errors {
        message
        path
      }
      result {
        careEpisode {
          id
          organization {
            id
          }
          treatmentService {
            id
          }
        }
      }
    }
  }
`;
export type EditCareEpisodeMutationFn = Apollo.MutationFunction<
  EditCareEpisodeMutation,
  EditCareEpisodeMutationVariables
>;

/**
 * __useEditCareEpisodeMutation__
 *
 * To run a mutation, you first call `useEditCareEpisodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCareEpisodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCareEpisodeMutation, { data, loading, error }] = useEditCareEpisodeMutation({
 *   variables: {
 *      careEpisode: // value for 'careEpisode'
 *   },
 * });
 */
export function useEditCareEpisodeMutation(
  baseOptions?: Apollo.MutationHookOptions<EditCareEpisodeMutation, EditCareEpisodeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditCareEpisodeMutation, EditCareEpisodeMutationVariables>(
    EditCareEpisodeDocument,
    options
  );
}
export type EditCareEpisodeMutationHookResult = ReturnType<typeof useEditCareEpisodeMutation>;
export type EditCareEpisodeMutationResult = Apollo.MutationResult<EditCareEpisodeMutation>;
export type EditCareEpisodeMutationOptions = Apollo.BaseMutationOptions<
  EditCareEpisodeMutation,
  EditCareEpisodeMutationVariables
>;
export const ProvidersForOrganizationDocument = gql`
  query ProvidersForOrganization(
    $organizationId: OrganizationId!
    $search: String
    $first: Int
    $defaultRelationship: [CareEpisodeProviderRelationship!]
  ) {
    organization(id: $organizationId) {
      practicingAttachedProviders(
        search: $search
        first: $first
        sortBy: NAME
        sortDirection: ASC
        defaultRelationship: $defaultRelationship
      ) {
        nodes {
          __typename
          id
          name
        }
      }
    }
  }
`;

/**
 * __useProvidersForOrganizationQuery__
 *
 * To run a query within a React component, call `useProvidersForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvidersForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvidersForOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      defaultRelationship: // value for 'defaultRelationship'
 *   },
 * });
 */
export function useProvidersForOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<ProvidersForOrganizationQuery, ProvidersForOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProvidersForOrganizationQuery, ProvidersForOrganizationQueryVariables>(
    ProvidersForOrganizationDocument,
    options
  );
}
export function useProvidersForOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProvidersForOrganizationQuery,
    ProvidersForOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProvidersForOrganizationQuery, ProvidersForOrganizationQueryVariables>(
    ProvidersForOrganizationDocument,
    options
  );
}
export type ProvidersForOrganizationQueryHookResult = ReturnType<typeof useProvidersForOrganizationQuery>;
export type ProvidersForOrganizationLazyQueryHookResult = ReturnType<
  typeof useProvidersForOrganizationLazyQuery
>;
export type ProvidersForOrganizationQueryResult = Apollo.QueryResult<
  ProvidersForOrganizationQuery,
  ProvidersForOrganizationQueryVariables
>;
export const TreatmentTracksByCareEpisodeIdDocument = gql`
  query TreatmentTracksByCareEpisodeId(
    $careEpisodeId: [CareEpisodeId!]
    $status: [CareEpisodeTreatmentTrackStatus!]
  ) {
    careCareEpisodeTreatmentTracks(careEpisodeId: $careEpisodeId, status: $status) {
      nodes {
        id
        status
        startSessionNumber
        enrollmentDate
        treatmentTrack {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useTreatmentTracksByCareEpisodeIdQuery__
 *
 * To run a query within a React component, call `useTreatmentTracksByCareEpisodeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentTracksByCareEpisodeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentTracksByCareEpisodeIdQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useTreatmentTracksByCareEpisodeIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TreatmentTracksByCareEpisodeIdQuery,
    TreatmentTracksByCareEpisodeIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TreatmentTracksByCareEpisodeIdQuery, TreatmentTracksByCareEpisodeIdQueryVariables>(
    TreatmentTracksByCareEpisodeIdDocument,
    options
  );
}
export function useTreatmentTracksByCareEpisodeIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TreatmentTracksByCareEpisodeIdQuery,
    TreatmentTracksByCareEpisodeIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TreatmentTracksByCareEpisodeIdQuery,
    TreatmentTracksByCareEpisodeIdQueryVariables
  >(TreatmentTracksByCareEpisodeIdDocument, options);
}
export type TreatmentTracksByCareEpisodeIdQueryHookResult = ReturnType<
  typeof useTreatmentTracksByCareEpisodeIdQuery
>;
export type TreatmentTracksByCareEpisodeIdLazyQueryHookResult = ReturnType<
  typeof useTreatmentTracksByCareEpisodeIdLazyQuery
>;
export type TreatmentTracksByCareEpisodeIdQueryResult = Apollo.QueryResult<
  TreatmentTracksByCareEpisodeIdQuery,
  TreatmentTracksByCareEpisodeIdQueryVariables
>;
export const TreatmentTracksByStatusDocument = gql`
  query TreatmentTracksByStatus($status: TreatmentTrackStatus) {
    careTreatmentTracks(status: $status) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useTreatmentTracksByStatusQuery__
 *
 * To run a query within a React component, call `useTreatmentTracksByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentTracksByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentTracksByStatusQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useTreatmentTracksByStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<TreatmentTracksByStatusQuery, TreatmentTracksByStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TreatmentTracksByStatusQuery, TreatmentTracksByStatusQueryVariables>(
    TreatmentTracksByStatusDocument,
    options
  );
}
export function useTreatmentTracksByStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TreatmentTracksByStatusQuery,
    TreatmentTracksByStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TreatmentTracksByStatusQuery, TreatmentTracksByStatusQueryVariables>(
    TreatmentTracksByStatusDocument,
    options
  );
}
export type TreatmentTracksByStatusQueryHookResult = ReturnType<typeof useTreatmentTracksByStatusQuery>;
export type TreatmentTracksByStatusLazyQueryHookResult = ReturnType<
  typeof useTreatmentTracksByStatusLazyQuery
>;
export type TreatmentTracksByStatusQueryResult = Apollo.QueryResult<
  TreatmentTracksByStatusQuery,
  TreatmentTracksByStatusQueryVariables
>;
export const UpdateCareEpisodeTreatmentTrackDocument = gql`
  mutation UpdateCareEpisodeTreatmentTrack($input: UpdateCareEpisodeTreatmentTrackInput!) {
    careUpdateCareEpisodeTreatmentTrack(input: $input) {
      errors {
        message
        path
      }
      result {
        careEpisodeTreatmentTrack {
          id
          status
          treatmentTrack {
            id
            name
          }
          careEpisode {
            careEpisodeTreatmentTracks {
              id
              treatmentTrack {
                id
                name
              }
            }
          }
        }
      }
      success
    }
  }
`;
export type UpdateCareEpisodeTreatmentTrackMutationFn = Apollo.MutationFunction<
  UpdateCareEpisodeTreatmentTrackMutation,
  UpdateCareEpisodeTreatmentTrackMutationVariables
>;

/**
 * __useUpdateCareEpisodeTreatmentTrackMutation__
 *
 * To run a mutation, you first call `useUpdateCareEpisodeTreatmentTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCareEpisodeTreatmentTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCareEpisodeTreatmentTrackMutation, { data, loading, error }] = useUpdateCareEpisodeTreatmentTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCareEpisodeTreatmentTrackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCareEpisodeTreatmentTrackMutation,
    UpdateCareEpisodeTreatmentTrackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCareEpisodeTreatmentTrackMutation,
    UpdateCareEpisodeTreatmentTrackMutationVariables
  >(UpdateCareEpisodeTreatmentTrackDocument, options);
}
export type UpdateCareEpisodeTreatmentTrackMutationHookResult = ReturnType<
  typeof useUpdateCareEpisodeTreatmentTrackMutation
>;
export type UpdateCareEpisodeTreatmentTrackMutationResult =
  Apollo.MutationResult<UpdateCareEpisodeTreatmentTrackMutation>;
export type UpdateCareEpisodeTreatmentTrackMutationOptions = Apollo.BaseMutationOptions<
  UpdateCareEpisodeTreatmentTrackMutation,
  UpdateCareEpisodeTreatmentTrackMutationVariables
>;
export const CareEpisodeDischargeDocument = gql`
  mutation CareEpisodeDischarge($input: DischargeCareEpisodeInput!) {
    careDischargeCareEpisode(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        success
      }
    }
  }
`;
export type CareEpisodeDischargeMutationFn = Apollo.MutationFunction<
  CareEpisodeDischargeMutation,
  CareEpisodeDischargeMutationVariables
>;

/**
 * __useCareEpisodeDischargeMutation__
 *
 * To run a mutation, you first call `useCareEpisodeDischargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCareEpisodeDischargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [careEpisodeDischargeMutation, { data, loading, error }] = useCareEpisodeDischargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCareEpisodeDischargeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CareEpisodeDischargeMutation,
    CareEpisodeDischargeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CareEpisodeDischargeMutation, CareEpisodeDischargeMutationVariables>(
    CareEpisodeDischargeDocument,
    options
  );
}
export type CareEpisodeDischargeMutationHookResult = ReturnType<typeof useCareEpisodeDischargeMutation>;
export type CareEpisodeDischargeMutationResult = Apollo.MutationResult<CareEpisodeDischargeMutation>;
export type CareEpisodeDischargeMutationOptions = Apollo.BaseMutationOptions<
  CareEpisodeDischargeMutation,
  CareEpisodeDischargeMutationVariables
>;
export const CareEpisodeSettingsDocument = gql`
  query CareEpisodeSettings($id: CareEpisodeId!) {
    careEpisode(id: $id) {
      ...CareEpisodeMeasurementAllowedDetails
    }
  }
  ${CareEpisodeMeasurementAllowedDetailsFragmentDoc}
`;

/**
 * __useCareEpisodeSettingsQuery__
 *
 * To run a query within a React component, call `useCareEpisodeSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareEpisodeSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareEpisodeSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCareEpisodeSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<CareEpisodeSettingsQuery, CareEpisodeSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareEpisodeSettingsQuery, CareEpisodeSettingsQueryVariables>(
    CareEpisodeSettingsDocument,
    options
  );
}
export function useCareEpisodeSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareEpisodeSettingsQuery, CareEpisodeSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareEpisodeSettingsQuery, CareEpisodeSettingsQueryVariables>(
    CareEpisodeSettingsDocument,
    options
  );
}
export type CareEpisodeSettingsQueryHookResult = ReturnType<typeof useCareEpisodeSettingsQuery>;
export type CareEpisodeSettingsLazyQueryHookResult = ReturnType<typeof useCareEpisodeSettingsLazyQuery>;
export type CareEpisodeSettingsQueryResult = Apollo.QueryResult<
  CareEpisodeSettingsQuery,
  CareEpisodeSettingsQueryVariables
>;
export const CareEpisodeUpdateMeasurementAllowedDocument = gql`
  mutation CareEpisodeUpdateMeasurementAllowed($input: CareEpisodeUpdateMeasurementAllowedInput!) {
    settingsCareEpisodeUpdateMeasurementAllowed(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        measurementAllowed
      }
    }
  }
`;
export type CareEpisodeUpdateMeasurementAllowedMutationFn = Apollo.MutationFunction<
  CareEpisodeUpdateMeasurementAllowedMutation,
  CareEpisodeUpdateMeasurementAllowedMutationVariables
>;

/**
 * __useCareEpisodeUpdateMeasurementAllowedMutation__
 *
 * To run a mutation, you first call `useCareEpisodeUpdateMeasurementAllowedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCareEpisodeUpdateMeasurementAllowedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [careEpisodeUpdateMeasurementAllowedMutation, { data, loading, error }] = useCareEpisodeUpdateMeasurementAllowedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCareEpisodeUpdateMeasurementAllowedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CareEpisodeUpdateMeasurementAllowedMutation,
    CareEpisodeUpdateMeasurementAllowedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CareEpisodeUpdateMeasurementAllowedMutation,
    CareEpisodeUpdateMeasurementAllowedMutationVariables
  >(CareEpisodeUpdateMeasurementAllowedDocument, options);
}
export type CareEpisodeUpdateMeasurementAllowedMutationHookResult = ReturnType<
  typeof useCareEpisodeUpdateMeasurementAllowedMutation
>;
export type CareEpisodeUpdateMeasurementAllowedMutationResult =
  Apollo.MutationResult<CareEpisodeUpdateMeasurementAllowedMutation>;
export type CareEpisodeUpdateMeasurementAllowedMutationOptions = Apollo.BaseMutationOptions<
  CareEpisodeUpdateMeasurementAllowedMutation,
  CareEpisodeUpdateMeasurementAllowedMutationVariables
>;
export const AddCareEpisodeProviderDocument = gql`
  mutation AddCareEpisodeProvider($input: AddCareEpisodeProviderInput!) {
    addCareEpisodeProvider(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        careEpisode {
          id
          careEpisodeProviders {
            id
            provider {
              id
              name
            }
            relationship
          }
        }
      }
    }
  }
`;
export type AddCareEpisodeProviderMutationFn = Apollo.MutationFunction<
  AddCareEpisodeProviderMutation,
  AddCareEpisodeProviderMutationVariables
>;

/**
 * __useAddCareEpisodeProviderMutation__
 *
 * To run a mutation, you first call `useAddCareEpisodeProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCareEpisodeProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCareEpisodeProviderMutation, { data, loading, error }] = useAddCareEpisodeProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCareEpisodeProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCareEpisodeProviderMutation,
    AddCareEpisodeProviderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCareEpisodeProviderMutation, AddCareEpisodeProviderMutationVariables>(
    AddCareEpisodeProviderDocument,
    options
  );
}
export type AddCareEpisodeProviderMutationHookResult = ReturnType<typeof useAddCareEpisodeProviderMutation>;
export type AddCareEpisodeProviderMutationResult = Apollo.MutationResult<AddCareEpisodeProviderMutation>;
export type AddCareEpisodeProviderMutationOptions = Apollo.BaseMutationOptions<
  AddCareEpisodeProviderMutation,
  AddCareEpisodeProviderMutationVariables
>;
export const CareEpisodeCareTeamDetailsDocument = gql`
  query CareEpisodeCareTeamDetails($careEpisodeId: CareEpisodeId!) {
    careEpisode(id: $careEpisodeId) {
      id
      organization {
        id
      }
      careEpisodeProviders {
        id
        relationship
        provider {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useCareEpisodeCareTeamDetailsQuery__
 *
 * To run a query within a React component, call `useCareEpisodeCareTeamDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareEpisodeCareTeamDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareEpisodeCareTeamDetailsQuery({
 *   variables: {
 *      careEpisodeId: // value for 'careEpisodeId'
 *   },
 * });
 */
export function useCareEpisodeCareTeamDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CareEpisodeCareTeamDetailsQuery,
    CareEpisodeCareTeamDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareEpisodeCareTeamDetailsQuery, CareEpisodeCareTeamDetailsQueryVariables>(
    CareEpisodeCareTeamDetailsDocument,
    options
  );
}
export function useCareEpisodeCareTeamDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareEpisodeCareTeamDetailsQuery,
    CareEpisodeCareTeamDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareEpisodeCareTeamDetailsQuery, CareEpisodeCareTeamDetailsQueryVariables>(
    CareEpisodeCareTeamDetailsDocument,
    options
  );
}
export type CareEpisodeCareTeamDetailsQueryHookResult = ReturnType<typeof useCareEpisodeCareTeamDetailsQuery>;
export type CareEpisodeCareTeamDetailsLazyQueryHookResult = ReturnType<
  typeof useCareEpisodeCareTeamDetailsLazyQuery
>;
export type CareEpisodeCareTeamDetailsQueryResult = Apollo.QueryResult<
  CareEpisodeCareTeamDetailsQuery,
  CareEpisodeCareTeamDetailsQueryVariables
>;
export const DeleteCareEpisodeProviderDocument = gql`
  mutation DeleteCareEpisodeProvider($input: DeleteCareEpisodeProviderInput!) {
    deleteCareEpisodeProvider(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        careEpisode {
          id
          careEpisodeProviders {
            id
            provider {
              id
              name
            }
            relationship
          }
        }
      }
    }
  }
`;
export type DeleteCareEpisodeProviderMutationFn = Apollo.MutationFunction<
  DeleteCareEpisodeProviderMutation,
  DeleteCareEpisodeProviderMutationVariables
>;

/**
 * __useDeleteCareEpisodeProviderMutation__
 *
 * To run a mutation, you first call `useDeleteCareEpisodeProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCareEpisodeProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCareEpisodeProviderMutation, { data, loading, error }] = useDeleteCareEpisodeProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCareEpisodeProviderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCareEpisodeProviderMutation,
    DeleteCareEpisodeProviderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCareEpisodeProviderMutation, DeleteCareEpisodeProviderMutationVariables>(
    DeleteCareEpisodeProviderDocument,
    options
  );
}
export type DeleteCareEpisodeProviderMutationHookResult = ReturnType<
  typeof useDeleteCareEpisodeProviderMutation
>;
export type DeleteCareEpisodeProviderMutationResult =
  Apollo.MutationResult<DeleteCareEpisodeProviderMutation>;
export type DeleteCareEpisodeProviderMutationOptions = Apollo.BaseMutationOptions<
  DeleteCareEpisodeProviderMutation,
  DeleteCareEpisodeProviderMutationVariables
>;
export const CreateMbcPatientDocument = gql`
  mutation CreateMbcPatient($input: CreatePatientInput!) {
    createPatient(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        patient {
          id
          gender
          genderIdentity
          genderIdentityFhir
          preferredPronouns
          dob
          name
        }
      }
    }
  }
`;
export type CreateMbcPatientMutationFn = Apollo.MutationFunction<
  CreateMbcPatientMutation,
  CreateMbcPatientMutationVariables
>;

/**
 * __useCreateMbcPatientMutation__
 *
 * To run a mutation, you first call `useCreateMbcPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMbcPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMbcPatientMutation, { data, loading, error }] = useCreateMbcPatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMbcPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMbcPatientMutation, CreateMbcPatientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMbcPatientMutation, CreateMbcPatientMutationVariables>(
    CreateMbcPatientDocument,
    options
  );
}
export type CreateMbcPatientMutationHookResult = ReturnType<typeof useCreateMbcPatientMutation>;
export type CreateMbcPatientMutationResult = Apollo.MutationResult<CreateMbcPatientMutation>;
export type CreateMbcPatientMutationOptions = Apollo.BaseMutationOptions<
  CreateMbcPatientMutation,
  CreateMbcPatientMutationVariables
>;
export const MbcPatientDetailsHeaderDocument = gql`
  query MbcPatientDetailsHeader($patientId: PatientId!) {
    patient(id: $patientId) {
      id
      name
      email
      phoneNumber
      mrn
      dob
      gender
      genderIdentity
      genderIdentityFhir
      preferredPronouns
      isTest
      ethnicity
      race
      hasCompletedAssessment
      notificationPreference
      notificationPreferenceWithDefault
      primaryLanguage
      enableNotifications
      patientFlags {
        active
        flag {
          id
          icon
          name
        }
      }
    }
  }
`;

/**
 * __useMbcPatientDetailsHeaderQuery__
 *
 * To run a query within a React component, call `useMbcPatientDetailsHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useMbcPatientDetailsHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMbcPatientDetailsHeaderQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useMbcPatientDetailsHeaderQuery(
  baseOptions: Apollo.QueryHookOptions<MbcPatientDetailsHeaderQuery, MbcPatientDetailsHeaderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MbcPatientDetailsHeaderQuery, MbcPatientDetailsHeaderQueryVariables>(
    MbcPatientDetailsHeaderDocument,
    options
  );
}
export function useMbcPatientDetailsHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MbcPatientDetailsHeaderQuery,
    MbcPatientDetailsHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MbcPatientDetailsHeaderQuery, MbcPatientDetailsHeaderQueryVariables>(
    MbcPatientDetailsHeaderDocument,
    options
  );
}
export type MbcPatientDetailsHeaderQueryHookResult = ReturnType<typeof useMbcPatientDetailsHeaderQuery>;
export type MbcPatientDetailsHeaderLazyQueryHookResult = ReturnType<
  typeof useMbcPatientDetailsHeaderLazyQuery
>;
export type MbcPatientDetailsHeaderQueryResult = Apollo.QueryResult<
  MbcPatientDetailsHeaderQuery,
  MbcPatientDetailsHeaderQueryVariables
>;
export const PatientListDocument = gql`
  query PatientList(
    $sortBy: PatientSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
    $forProvider: ProviderFilter
    $activeOrganizations: OrganizationFilter
    $flagIds: [FlagId!]
    $testPatient: Boolean = false
  ) {
    patients(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      search: $search
      forProvider: $forProvider
      activeOrganizations: $activeOrganizations
      testPatient: $testPatient
      flagIds: $flagIds
    ) {
      nodes {
        id
        name
        dob
        gender
        genderIdentity
        mrn
        firstName
        lastName
        isTest
        patientFlags {
          id
          active
          flag {
            id
            name
            icon
          }
        }
        nameLastFirst
        hasCompletedAssessment
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __usePatientListQuery__
 *
 * To run a query within a React component, call `usePatientListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientListQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *      forProvider: // value for 'forProvider'
 *      activeOrganizations: // value for 'activeOrganizations'
 *      flagIds: // value for 'flagIds'
 *      testPatient: // value for 'testPatient'
 *   },
 * });
 */
export function usePatientListQuery(
  baseOptions?: Apollo.QueryHookOptions<PatientListQuery, PatientListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientListQuery, PatientListQueryVariables>(PatientListDocument, options);
}
export function usePatientListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientListQuery, PatientListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientListQuery, PatientListQueryVariables>(PatientListDocument, options);
}
export type PatientListQueryHookResult = ReturnType<typeof usePatientListQuery>;
export type PatientListLazyQueryHookResult = ReturnType<typeof usePatientListLazyQuery>;
export type PatientListQueryResult = Apollo.QueryResult<PatientListQuery, PatientListQueryVariables>;
export const ActiveInvitationDetailsDocument = gql`
  query ActiveInvitationDetails($patientId: PatientId!, $careEpisodeId: CareEpisodeId) {
    assessmentInvitations(patientId: $patientId, careEpisodeId: $careEpisodeId, status: [SENT, BEGUN, NEW]) {
      nodes {
        id
        status
        referrer
        phase
        format
        opensAt
        closesAt
        dateBegun
        dateCompleted
        user {
          id
          name
          userType
          notificationPreference
          allowsText
          allowsEmail
        }
        invitationNotifications {
          id
          status
          sendAt
          dateTexted
          dateEmailed
          textAllowed
          emailAllowed
        }
        reportScales {
          id
          disabled
          scale {
            id
            name
            shortname
          }
        }
      }
    }
  }
`;

/**
 * __useActiveInvitationDetailsQuery__
 *
 * To run a query within a React component, call `useActiveInvitationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveInvitationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveInvitationDetailsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      careEpisodeId: // value for 'careEpisodeId'
 *   },
 * });
 */
export function useActiveInvitationDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<ActiveInvitationDetailsQuery, ActiveInvitationDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveInvitationDetailsQuery, ActiveInvitationDetailsQueryVariables>(
    ActiveInvitationDetailsDocument,
    options
  );
}
export function useActiveInvitationDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveInvitationDetailsQuery,
    ActiveInvitationDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveInvitationDetailsQuery, ActiveInvitationDetailsQueryVariables>(
    ActiveInvitationDetailsDocument,
    options
  );
}
export type ActiveInvitationDetailsQueryHookResult = ReturnType<typeof useActiveInvitationDetailsQuery>;
export type ActiveInvitationDetailsLazyQueryHookResult = ReturnType<
  typeof useActiveInvitationDetailsLazyQuery
>;
export type ActiveInvitationDetailsQueryResult = Apollo.QueryResult<
  ActiveInvitationDetailsQuery,
  ActiveInvitationDetailsQueryVariables
>;
export const AppointmentsAndSessionsDocument = gql`
  query AppointmentsAndSessions(
    $patientId: PatientId!
    $careEpisodeId: CareEpisodeId
    $sortBy: AppointmentSortParameter
    $sortDirection: SortDirection
    $first: Int
    $startDateBefore: ISO8601DateTime
    $startDateAfter: ISO8601DateTime
  ) {
    schedulingAppointments(
      patientId: [$patientId]
      careEpisodeId: $careEpisodeId
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      startDateBefore: $startDateBefore
      startDateAfter: $startDateAfter
    ) {
      nodes {
        startDate
        status
        id
        measurementStatus
        measurementCategory
        source
        careEpisode {
          id
          status
          organization {
            id
            name
          }
          treatmentService {
            id
            name
          }
        }
        patientSession {
          id
          autoplan
          maxPlanningPhase
          assessmentReport {
            id
            invitations {
              id
              status
              format
              user {
                name
              }
            }
          }
          isReportAvailable
          schedulingRecurrence {
            id
            endDate
            repeatFrequency
          }
        }
        provider {
          id
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useAppointmentsAndSessionsQuery__
 *
 * To run a query within a React component, call `useAppointmentsAndSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentsAndSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentsAndSessionsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      careEpisodeId: // value for 'careEpisodeId'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      startDateBefore: // value for 'startDateBefore'
 *      startDateAfter: // value for 'startDateAfter'
 *   },
 * });
 */
export function useAppointmentsAndSessionsQuery(
  baseOptions: Apollo.QueryHookOptions<AppointmentsAndSessionsQuery, AppointmentsAndSessionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentsAndSessionsQuery, AppointmentsAndSessionsQueryVariables>(
    AppointmentsAndSessionsDocument,
    options
  );
}
export function useAppointmentsAndSessionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentsAndSessionsQuery,
    AppointmentsAndSessionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentsAndSessionsQuery, AppointmentsAndSessionsQueryVariables>(
    AppointmentsAndSessionsDocument,
    options
  );
}
export type AppointmentsAndSessionsQueryHookResult = ReturnType<typeof useAppointmentsAndSessionsQuery>;
export type AppointmentsAndSessionsLazyQueryHookResult = ReturnType<
  typeof useAppointmentsAndSessionsLazyQuery
>;
export type AppointmentsAndSessionsQueryResult = Apollo.QueryResult<
  AppointmentsAndSessionsQuery,
  AppointmentsAndSessionsQueryVariables
>;
export const CareEpisodeListDocument = gql`
  query CareEpisodeList(
    $patientId: PatientId!
    $sortBy: CareEpisodeSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $status: [CareEpisodeStatus!]
  ) {
    careEpisodes(
      patientId: [$patientId]
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      status: $status
    ) {
      nodes {
        id
        shortname
        periodStart
        periodEnd
        status
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useCareEpisodeListQuery__
 *
 * To run a query within a React component, call `useCareEpisodeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareEpisodeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareEpisodeListQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCareEpisodeListQuery(
  baseOptions: Apollo.QueryHookOptions<CareEpisodeListQuery, CareEpisodeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareEpisodeListQuery, CareEpisodeListQueryVariables>(
    CareEpisodeListDocument,
    options
  );
}
export function useCareEpisodeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareEpisodeListQuery, CareEpisodeListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareEpisodeListQuery, CareEpisodeListQueryVariables>(
    CareEpisodeListDocument,
    options
  );
}
export type CareEpisodeListQueryHookResult = ReturnType<typeof useCareEpisodeListQuery>;
export type CareEpisodeListLazyQueryHookResult = ReturnType<typeof useCareEpisodeListLazyQuery>;
export type CareEpisodeListQueryResult = Apollo.QueryResult<
  CareEpisodeListQuery,
  CareEpisodeListQueryVariables
>;
export const CoverageListDocument = gql`
  query CoverageList(
    $patientId: PatientId!
    $sortBy: CoverageSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    billingCoverages(
      patientId: [$patientId]
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      nodes {
        id
        startDate
        endDate
        subscriberNumber
        relationship
        institutePayor {
          id
          name
          primaryId
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useCoverageListQuery__
 *
 * To run a query within a React component, call `useCoverageListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoverageListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoverageListQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useCoverageListQuery(
  baseOptions: Apollo.QueryHookOptions<CoverageListQuery, CoverageListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoverageListQuery, CoverageListQueryVariables>(CoverageListDocument, options);
}
export function useCoverageListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CoverageListQuery, CoverageListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoverageListQuery, CoverageListQueryVariables>(CoverageListDocument, options);
}
export type CoverageListQueryHookResult = ReturnType<typeof useCoverageListQuery>;
export type CoverageListLazyQueryHookResult = ReturnType<typeof useCoverageListLazyQuery>;
export type CoverageListQueryResult = Apollo.QueryResult<CoverageListQuery, CoverageListQueryVariables>;
export const PatientConditionListDocument = gql`
  query PatientConditionList(
    $patientId: PatientId!
    $sortBy: PatientConditionSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    patientConditions(
      patientId: $patientId
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      nodes {
        id
        status
        onsetDate
        abatementDate
        instituteCondition {
          id
          name
          code
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __usePatientConditionListQuery__
 *
 * To run a query within a React component, call `usePatientConditionListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientConditionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientConditionListQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function usePatientConditionListQuery(
  baseOptions: Apollo.QueryHookOptions<PatientConditionListQuery, PatientConditionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientConditionListQuery, PatientConditionListQueryVariables>(
    PatientConditionListDocument,
    options
  );
}
export function usePatientConditionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientConditionListQuery, PatientConditionListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientConditionListQuery, PatientConditionListQueryVariables>(
    PatientConditionListDocument,
    options
  );
}
export type PatientConditionListQueryHookResult = ReturnType<typeof usePatientConditionListQuery>;
export type PatientConditionListLazyQueryHookResult = ReturnType<typeof usePatientConditionListLazyQuery>;
export type PatientConditionListQueryResult = Apollo.QueryResult<
  PatientConditionListQuery,
  PatientConditionListQueryVariables
>;
export const UpdatePatientNotificationPreferenceDocument = gql`
  mutation UpdatePatientNotificationPreference($input: UpdatePatientNotificationPreferenceInput!) {
    updatePatientNotificationPreference(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        patient {
          id
          enableNotifications
        }
      }
    }
  }
`;
export type UpdatePatientNotificationPreferenceMutationFn = Apollo.MutationFunction<
  UpdatePatientNotificationPreferenceMutation,
  UpdatePatientNotificationPreferenceMutationVariables
>;

/**
 * __useUpdatePatientNotificationPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdatePatientNotificationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientNotificationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientNotificationPreferenceMutation, { data, loading, error }] = useUpdatePatientNotificationPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientNotificationPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatientNotificationPreferenceMutation,
    UpdatePatientNotificationPreferenceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePatientNotificationPreferenceMutation,
    UpdatePatientNotificationPreferenceMutationVariables
  >(UpdatePatientNotificationPreferenceDocument, options);
}
export type UpdatePatientNotificationPreferenceMutationHookResult = ReturnType<
  typeof useUpdatePatientNotificationPreferenceMutation
>;
export type UpdatePatientNotificationPreferenceMutationResult =
  Apollo.MutationResult<UpdatePatientNotificationPreferenceMutation>;
export type UpdatePatientNotificationPreferenceMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientNotificationPreferenceMutation,
  UpdatePatientNotificationPreferenceMutationVariables
>;
export const UserConsentListDocument = gql`
  query UserConsentList(
    $patientId: PatientId!
    $sortBy: UserConsentSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    userConsents(
      forPatient: $patientId
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      nodes {
        id
        status
        createdAt
        consentType
        accepted
        user {
          id
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useUserConsentListQuery__
 *
 * To run a query within a React component, call `useUserConsentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserConsentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserConsentListQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useUserConsentListQuery(
  baseOptions: Apollo.QueryHookOptions<UserConsentListQuery, UserConsentListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserConsentListQuery, UserConsentListQueryVariables>(
    UserConsentListDocument,
    options
  );
}
export function useUserConsentListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserConsentListQuery, UserConsentListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserConsentListQuery, UserConsentListQueryVariables>(
    UserConsentListDocument,
    options
  );
}
export type UserConsentListQueryHookResult = ReturnType<typeof useUserConsentListQuery>;
export type UserConsentListLazyQueryHookResult = ReturnType<typeof useUserConsentListLazyQuery>;
export type UserConsentListQueryResult = Apollo.QueryResult<
  UserConsentListQuery,
  UserConsentListQueryVariables
>;
export const PatientQualityIssuesDocument = gql`
  query PatientQualityIssues(
    $sortBy: PatientSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $organizationFilter: OrganizationFilter
    $patientFilter: PatientFilter
    $qualityIssues: PatientQualityIssueFilter
  ) {
    patients(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      withQualityIssue: $qualityIssues
      activeOrganizations: $organizationFilter
      withFilter: $patientFilter
    ) {
      nodes {
        id
        name
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __usePatientQualityIssuesQuery__
 *
 * To run a query within a React component, call `usePatientQualityIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientQualityIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientQualityIssuesQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      organizationFilter: // value for 'organizationFilter'
 *      patientFilter: // value for 'patientFilter'
 *      qualityIssues: // value for 'qualityIssues'
 *   },
 * });
 */
export function usePatientQualityIssuesQuery(
  baseOptions?: Apollo.QueryHookOptions<PatientQualityIssuesQuery, PatientQualityIssuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientQualityIssuesQuery, PatientQualityIssuesQueryVariables>(
    PatientQualityIssuesDocument,
    options
  );
}
export function usePatientQualityIssuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientQualityIssuesQuery, PatientQualityIssuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientQualityIssuesQuery, PatientQualityIssuesQueryVariables>(
    PatientQualityIssuesDocument,
    options
  );
}
export type PatientQualityIssuesQueryHookResult = ReturnType<typeof usePatientQualityIssuesQuery>;
export type PatientQualityIssuesLazyQueryHookResult = ReturnType<typeof usePatientQualityIssuesLazyQuery>;
export type PatientQualityIssuesQueryResult = Apollo.QueryResult<
  PatientQualityIssuesQuery,
  PatientQualityIssuesQueryVariables
>;
export const QualityIssueDetailDocument = gql`
  query QualityIssueDetail($id: PatientId!) {
    patient(id: $id) {
      id
      name
      dob
      user {
        phoneNumber
        email
        notificationPreference
      }
      patientQualityIssues {
        id
        issue
        status
        statusActiveUntil
        resolvedAt
        notes
        provider {
          name
        }
        relatedPerson {
          id
          name
          user {
            notificationPreference
            phoneNumber
            email
          }
        }
      }
    }
  }
`;

/**
 * __useQualityIssueDetailQuery__
 *
 * To run a query within a React component, call `useQualityIssueDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useQualityIssueDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQualityIssueDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQualityIssueDetailQuery(
  baseOptions: Apollo.QueryHookOptions<QualityIssueDetailQuery, QualityIssueDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QualityIssueDetailQuery, QualityIssueDetailQueryVariables>(
    QualityIssueDetailDocument,
    options
  );
}
export function useQualityIssueDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<QualityIssueDetailQuery, QualityIssueDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QualityIssueDetailQuery, QualityIssueDetailQueryVariables>(
    QualityIssueDetailDocument,
    options
  );
}
export type QualityIssueDetailQueryHookResult = ReturnType<typeof useQualityIssueDetailQuery>;
export type QualityIssueDetailLazyQueryHookResult = ReturnType<typeof useQualityIssueDetailLazyQuery>;
export type QualityIssueDetailQueryResult = Apollo.QueryResult<
  QualityIssueDetailQuery,
  QualityIssueDetailQueryVariables
>;
export const UpdatePatientQualityIssueDocument = gql`
  mutation UpdatePatientQualityIssue($input: UpdatePatientQualityIssueInput!) {
    updatePatientQualityIssue(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        patientQualityIssue {
          id
          status
          issue
          notes
          provider {
            name
          }
          resolvedAt
          statusActiveUntil
        }
      }
    }
  }
`;
export type UpdatePatientQualityIssueMutationFn = Apollo.MutationFunction<
  UpdatePatientQualityIssueMutation,
  UpdatePatientQualityIssueMutationVariables
>;

/**
 * __useUpdatePatientQualityIssueMutation__
 *
 * To run a mutation, you first call `useUpdatePatientQualityIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientQualityIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientQualityIssueMutation, { data, loading, error }] = useUpdatePatientQualityIssueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientQualityIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatientQualityIssueMutation,
    UpdatePatientQualityIssueMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePatientQualityIssueMutation, UpdatePatientQualityIssueMutationVariables>(
    UpdatePatientQualityIssueDocument,
    options
  );
}
export type UpdatePatientQualityIssueMutationHookResult = ReturnType<
  typeof useUpdatePatientQualityIssueMutation
>;
export type UpdatePatientQualityIssueMutationResult =
  Apollo.MutationResult<UpdatePatientQualityIssueMutation>;
export type UpdatePatientQualityIssueMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientQualityIssueMutation,
  UpdatePatientQualityIssueMutationVariables
>;
export const PatientQualityIssueDetailsDocument = gql`
  query PatientQualityIssueDetails($patientId: PatientId!) {
    patientQualityIssues(first: 50, patientId: [$patientId]) {
      nodes {
        id
        status
        issue
        resolvedAt
      }
    }
  }
`;

/**
 * __usePatientQualityIssueDetailsQuery__
 *
 * To run a query within a React component, call `usePatientQualityIssueDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientQualityIssueDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientQualityIssueDetailsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientQualityIssueDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PatientQualityIssueDetailsQuery,
    PatientQualityIssueDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientQualityIssueDetailsQuery, PatientQualityIssueDetailsQueryVariables>(
    PatientQualityIssueDetailsDocument,
    options
  );
}
export function usePatientQualityIssueDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientQualityIssueDetailsQuery,
    PatientQualityIssueDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientQualityIssueDetailsQuery, PatientQualityIssueDetailsQueryVariables>(
    PatientQualityIssueDetailsDocument,
    options
  );
}
export type PatientQualityIssueDetailsQueryHookResult = ReturnType<typeof usePatientQualityIssueDetailsQuery>;
export type PatientQualityIssueDetailsLazyQueryHookResult = ReturnType<
  typeof usePatientQualityIssueDetailsLazyQuery
>;
export type PatientQualityIssueDetailsQueryResult = Apollo.QueryResult<
  PatientQualityIssueDetailsQuery,
  PatientQualityIssueDetailsQueryVariables
>;
export const CreateCareGroupDocument = gql`
  mutation CreateCareGroup($careGroup: CreateCareGroupInput!) {
    createCareGroup(input: $careGroup) {
      success
      errors {
        message
        path
      }
      result {
        careGroup {
          id
          name
        }
      }
    }
  }
`;
export type CreateCareGroupMutationFn = Apollo.MutationFunction<
  CreateCareGroupMutation,
  CreateCareGroupMutationVariables
>;

/**
 * __useCreateCareGroupMutation__
 *
 * To run a mutation, you first call `useCreateCareGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCareGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCareGroupMutation, { data, loading, error }] = useCreateCareGroupMutation({
 *   variables: {
 *      careGroup: // value for 'careGroup'
 *   },
 * });
 */
export function useCreateCareGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCareGroupMutation, CreateCareGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCareGroupMutation, CreateCareGroupMutationVariables>(
    CreateCareGroupDocument,
    options
  );
}
export type CreateCareGroupMutationHookResult = ReturnType<typeof useCreateCareGroupMutation>;
export type CreateCareGroupMutationResult = Apollo.MutationResult<CreateCareGroupMutation>;
export type CreateCareGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateCareGroupMutation,
  CreateCareGroupMutationVariables
>;
export const FamilyCareUnitTypeDocument = gql`
  query FamilyCareUnitType {
    careUnitTypes(mnemonic: "family") {
      nodes {
        id
      }
    }
  }
`;

/**
 * __useFamilyCareUnitTypeQuery__
 *
 * To run a query within a React component, call `useFamilyCareUnitTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFamilyCareUnitTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFamilyCareUnitTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useFamilyCareUnitTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<FamilyCareUnitTypeQuery, FamilyCareUnitTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FamilyCareUnitTypeQuery, FamilyCareUnitTypeQueryVariables>(
    FamilyCareUnitTypeDocument,
    options
  );
}
export function useFamilyCareUnitTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FamilyCareUnitTypeQuery, FamilyCareUnitTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FamilyCareUnitTypeQuery, FamilyCareUnitTypeQueryVariables>(
    FamilyCareUnitTypeDocument,
    options
  );
}
export type FamilyCareUnitTypeQueryHookResult = ReturnType<typeof useFamilyCareUnitTypeQuery>;
export type FamilyCareUnitTypeLazyQueryHookResult = ReturnType<typeof useFamilyCareUnitTypeLazyQuery>;
export type FamilyCareUnitTypeQueryResult = Apollo.QueryResult<
  FamilyCareUnitTypeQuery,
  FamilyCareUnitTypeQueryVariables
>;
export const UpdateCareGroupDocument = gql`
  mutation UpdateCareGroup($careGroup: UpdateCareGroupInput!) {
    updateCareGroup(input: $careGroup) {
      success
      errors {
        message
        path
      }
      result {
        careGroup {
          id
          name
        }
      }
    }
  }
`;
export type UpdateCareGroupMutationFn = Apollo.MutationFunction<
  UpdateCareGroupMutation,
  UpdateCareGroupMutationVariables
>;

/**
 * __useUpdateCareGroupMutation__
 *
 * To run a mutation, you first call `useUpdateCareGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCareGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCareGroupMutation, { data, loading, error }] = useUpdateCareGroupMutation({
 *   variables: {
 *      careGroup: // value for 'careGroup'
 *   },
 * });
 */
export function useUpdateCareGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCareGroupMutation, UpdateCareGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCareGroupMutation, UpdateCareGroupMutationVariables>(
    UpdateCareGroupDocument,
    options
  );
}
export type UpdateCareGroupMutationHookResult = ReturnType<typeof useUpdateCareGroupMutation>;
export type UpdateCareGroupMutationResult = Apollo.MutationResult<UpdateCareGroupMutation>;
export type UpdateCareGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateCareGroupMutation,
  UpdateCareGroupMutationVariables
>;
export const CareGroupAppointmentDetailsDocument = gql`
  query CareGroupAppointmentDetails($careGroupId: CareGroupId!) {
    careGroup(id: $careGroupId) {
      id
      ...PipbhcAllGroupAppointments
    }
  }
  ${PipbhcAllGroupAppointmentsFragmentDoc}
`;

/**
 * __useCareGroupAppointmentDetailsQuery__
 *
 * To run a query within a React component, call `useCareGroupAppointmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareGroupAppointmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareGroupAppointmentDetailsQuery({
 *   variables: {
 *      careGroupId: // value for 'careGroupId'
 *   },
 * });
 */
export function useCareGroupAppointmentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CareGroupAppointmentDetailsQuery,
    CareGroupAppointmentDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareGroupAppointmentDetailsQuery, CareGroupAppointmentDetailsQueryVariables>(
    CareGroupAppointmentDetailsDocument,
    options
  );
}
export function useCareGroupAppointmentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareGroupAppointmentDetailsQuery,
    CareGroupAppointmentDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareGroupAppointmentDetailsQuery, CareGroupAppointmentDetailsQueryVariables>(
    CareGroupAppointmentDetailsDocument,
    options
  );
}
export type CareGroupAppointmentDetailsQueryHookResult = ReturnType<
  typeof useCareGroupAppointmentDetailsQuery
>;
export type CareGroupAppointmentDetailsLazyQueryHookResult = ReturnType<
  typeof useCareGroupAppointmentDetailsLazyQuery
>;
export type CareGroupAppointmentDetailsQueryResult = Apollo.QueryResult<
  CareGroupAppointmentDetailsQuery,
  CareGroupAppointmentDetailsQueryVariables
>;
export const CareGroupDetailsQueryDocument = gql`
  query CareGroupDetailsQuery($careGroupId: CareGroupId!) {
    careGroup(id: $careGroupId) {
      id
      name
      identifiers {
        ...IdentifierDetails
      }
      careUnitType {
        id
        mnemonic
      }
      currentCareUnitParentMemberships {
        id
        child {
          ...PipbhcPatientDetails
        }
      }
      groupSchedulingAppointments(sortBy: STARTDATE, sortDirection: ASC) {
        nodes {
          id
          comment
          endDate
          startDate
          status
          minutesDuration
          patientSession {
            status
            targetDate
          }
          provider {
            name
            id
          }
          patient {
            name
            id
          }
        }
      }
      groupExternalEvents {
        nodes {
          ...ExternalEventFragment
        }
      }
      groupCareEpisodeProviders {
        nodes {
          id
          provider {
            id
            name
          }
          patient {
            name
          }
          careEpisode {
            organization {
              name
            }
          }
          relationship
        }
      }
    }
  }
  ${IdentifierDetailsFragmentDoc}
  ${PipbhcPatientDetailsFragmentDoc}
  ${ExternalEventFragmentFragmentDoc}
`;

/**
 * __useCareGroupDetailsQueryQuery__
 *
 * To run a query within a React component, call `useCareGroupDetailsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareGroupDetailsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareGroupDetailsQueryQuery({
 *   variables: {
 *      careGroupId: // value for 'careGroupId'
 *   },
 * });
 */
export function useCareGroupDetailsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<CareGroupDetailsQueryQuery, CareGroupDetailsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareGroupDetailsQueryQuery, CareGroupDetailsQueryQueryVariables>(
    CareGroupDetailsQueryDocument,
    options
  );
}
export function useCareGroupDetailsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareGroupDetailsQueryQuery, CareGroupDetailsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareGroupDetailsQueryQuery, CareGroupDetailsQueryQueryVariables>(
    CareGroupDetailsQueryDocument,
    options
  );
}
export type CareGroupDetailsQueryQueryHookResult = ReturnType<typeof useCareGroupDetailsQueryQuery>;
export type CareGroupDetailsQueryLazyQueryHookResult = ReturnType<typeof useCareGroupDetailsQueryLazyQuery>;
export type CareGroupDetailsQueryQueryResult = Apollo.QueryResult<
  CareGroupDetailsQueryQuery,
  CareGroupDetailsQueryQueryVariables
>;
export const UpdatePipbhcGpraIdDocument = gql`
  mutation UpdatePipbhcGpraId($updateInput: UpdateGpraIdInput!) {
    updatePipbhcGpraId(input: $updateInput) {
      success
      errors {
        message
        path
      }
      result {
        patient {
          ...PipbhcPatientDetails
        }
      }
    }
  }
  ${PipbhcPatientDetailsFragmentDoc}
`;
export type UpdatePipbhcGpraIdMutationFn = Apollo.MutationFunction<
  UpdatePipbhcGpraIdMutation,
  UpdatePipbhcGpraIdMutationVariables
>;

/**
 * __useUpdatePipbhcGpraIdMutation__
 *
 * To run a mutation, you first call `useUpdatePipbhcGpraIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePipbhcGpraIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePipbhcGpraIdMutation, { data, loading, error }] = useUpdatePipbhcGpraIdMutation({
 *   variables: {
 *      updateInput: // value for 'updateInput'
 *   },
 * });
 */
export function useUpdatePipbhcGpraIdMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePipbhcGpraIdMutation, UpdatePipbhcGpraIdMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePipbhcGpraIdMutation, UpdatePipbhcGpraIdMutationVariables>(
    UpdatePipbhcGpraIdDocument,
    options
  );
}
export type UpdatePipbhcGpraIdMutationHookResult = ReturnType<typeof useUpdatePipbhcGpraIdMutation>;
export type UpdatePipbhcGpraIdMutationResult = Apollo.MutationResult<UpdatePipbhcGpraIdMutation>;
export type UpdatePipbhcGpraIdMutationOptions = Apollo.BaseMutationOptions<
  UpdatePipbhcGpraIdMutation,
  UpdatePipbhcGpraIdMutationVariables
>;
export const CreateExternalEventMutationDocument = gql`
  mutation CreateExternalEventMutation($externalEvent: CreateExternalEventInput!) {
    createExternalEvent(input: $externalEvent) {
      success
      errors {
        message
        path
      }
      result {
        externalEvent {
          ...ExternalEventFragment
        }
      }
    }
  }
  ${ExternalEventFragmentFragmentDoc}
`;
export type CreateExternalEventMutationMutationFn = Apollo.MutationFunction<
  CreateExternalEventMutationMutation,
  CreateExternalEventMutationMutationVariables
>;

/**
 * __useCreateExternalEventMutationMutation__
 *
 * To run a mutation, you first call `useCreateExternalEventMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalEventMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalEventMutationMutation, { data, loading, error }] = useCreateExternalEventMutationMutation({
 *   variables: {
 *      externalEvent: // value for 'externalEvent'
 *   },
 * });
 */
export function useCreateExternalEventMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateExternalEventMutationMutation,
    CreateExternalEventMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateExternalEventMutationMutation,
    CreateExternalEventMutationMutationVariables
  >(CreateExternalEventMutationDocument, options);
}
export type CreateExternalEventMutationMutationHookResult = ReturnType<
  typeof useCreateExternalEventMutationMutation
>;
export type CreateExternalEventMutationMutationResult =
  Apollo.MutationResult<CreateExternalEventMutationMutation>;
export type CreateExternalEventMutationMutationOptions = Apollo.BaseMutationOptions<
  CreateExternalEventMutationMutation,
  CreateExternalEventMutationMutationVariables
>;
export const EditExternalEventMutationDocument = gql`
  mutation EditExternalEventMutation($externalEvent: EditExternalEventInput!) {
    editExternalEvent(input: $externalEvent) {
      success
      errors {
        message
        path
      }
      result {
        externalEvent {
          ...ExternalEventFragment
        }
      }
    }
  }
  ${ExternalEventFragmentFragmentDoc}
`;
export type EditExternalEventMutationMutationFn = Apollo.MutationFunction<
  EditExternalEventMutationMutation,
  EditExternalEventMutationMutationVariables
>;

/**
 * __useEditExternalEventMutationMutation__
 *
 * To run a mutation, you first call `useEditExternalEventMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditExternalEventMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editExternalEventMutationMutation, { data, loading, error }] = useEditExternalEventMutationMutation({
 *   variables: {
 *      externalEvent: // value for 'externalEvent'
 *   },
 * });
 */
export function useEditExternalEventMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditExternalEventMutationMutation,
    EditExternalEventMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditExternalEventMutationMutation, EditExternalEventMutationMutationVariables>(
    EditExternalEventMutationDocument,
    options
  );
}
export type EditExternalEventMutationMutationHookResult = ReturnType<
  typeof useEditExternalEventMutationMutation
>;
export type EditExternalEventMutationMutationResult =
  Apollo.MutationResult<EditExternalEventMutationMutation>;
export type EditExternalEventMutationMutationOptions = Apollo.BaseMutationOptions<
  EditExternalEventMutationMutation,
  EditExternalEventMutationMutationVariables
>;
export const CreatePipbhcTreatmentGoalDocument = gql`
  mutation CreatePipbhcTreatmentGoal($treatmentGoal: CreatePipbhcTreatmentGoalInput!) {
    createPipbhcTreatmentGoal(input: $treatmentGoal) {
      success
      errors {
        message
        path
      }
      result {
        treatmentGoal {
          ...PipbhcTreatmentGoal
        }
      }
    }
  }
  ${PipbhcTreatmentGoalFragmentDoc}
`;
export type CreatePipbhcTreatmentGoalMutationFn = Apollo.MutationFunction<
  CreatePipbhcTreatmentGoalMutation,
  CreatePipbhcTreatmentGoalMutationVariables
>;

/**
 * __useCreatePipbhcTreatmentGoalMutation__
 *
 * To run a mutation, you first call `useCreatePipbhcTreatmentGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePipbhcTreatmentGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPipbhcTreatmentGoalMutation, { data, loading, error }] = useCreatePipbhcTreatmentGoalMutation({
 *   variables: {
 *      treatmentGoal: // value for 'treatmentGoal'
 *   },
 * });
 */
export function useCreatePipbhcTreatmentGoalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePipbhcTreatmentGoalMutation,
    CreatePipbhcTreatmentGoalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePipbhcTreatmentGoalMutation, CreatePipbhcTreatmentGoalMutationVariables>(
    CreatePipbhcTreatmentGoalDocument,
    options
  );
}
export type CreatePipbhcTreatmentGoalMutationHookResult = ReturnType<
  typeof useCreatePipbhcTreatmentGoalMutation
>;
export type CreatePipbhcTreatmentGoalMutationResult =
  Apollo.MutationResult<CreatePipbhcTreatmentGoalMutation>;
export type CreatePipbhcTreatmentGoalMutationOptions = Apollo.BaseMutationOptions<
  CreatePipbhcTreatmentGoalMutation,
  CreatePipbhcTreatmentGoalMutationVariables
>;
export const CreatePipbhcTreatmentGoalReportDocument = gql`
  mutation CreatePipbhcTreatmentGoalReport(
    $pipbhcTreatmentGoalReport: CreatePipbhcTreatmentGoalReportInput!
  ) {
    createPipbhcTreatmentGoalReport(input: $pipbhcTreatmentGoalReport) {
      success
      errors {
        message
        path
      }
      result {
        pipbhcTreatmentGoalReport {
          ...PipbhcTreatmentGoalReport
        }
      }
    }
  }
  ${PipbhcTreatmentGoalReportFragmentDoc}
`;
export type CreatePipbhcTreatmentGoalReportMutationFn = Apollo.MutationFunction<
  CreatePipbhcTreatmentGoalReportMutation,
  CreatePipbhcTreatmentGoalReportMutationVariables
>;

/**
 * __useCreatePipbhcTreatmentGoalReportMutation__
 *
 * To run a mutation, you first call `useCreatePipbhcTreatmentGoalReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePipbhcTreatmentGoalReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPipbhcTreatmentGoalReportMutation, { data, loading, error }] = useCreatePipbhcTreatmentGoalReportMutation({
 *   variables: {
 *      pipbhcTreatmentGoalReport: // value for 'pipbhcTreatmentGoalReport'
 *   },
 * });
 */
export function useCreatePipbhcTreatmentGoalReportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePipbhcTreatmentGoalReportMutation,
    CreatePipbhcTreatmentGoalReportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePipbhcTreatmentGoalReportMutation,
    CreatePipbhcTreatmentGoalReportMutationVariables
  >(CreatePipbhcTreatmentGoalReportDocument, options);
}
export type CreatePipbhcTreatmentGoalReportMutationHookResult = ReturnType<
  typeof useCreatePipbhcTreatmentGoalReportMutation
>;
export type CreatePipbhcTreatmentGoalReportMutationResult =
  Apollo.MutationResult<CreatePipbhcTreatmentGoalReportMutation>;
export type CreatePipbhcTreatmentGoalReportMutationOptions = Apollo.BaseMutationOptions<
  CreatePipbhcTreatmentGoalReportMutation,
  CreatePipbhcTreatmentGoalReportMutationVariables
>;
export const PipbhcTreatmentGoalDetailsDocument = gql`
  query PipbhcTreatmentGoalDetails($pipbhcTreatmentGoalId: PipbhcTreatmentGoalId!) {
    pipbhcTreatmentGoal(id: $pipbhcTreatmentGoalId) {
      ...PipbhcTreatmentGoal
      patient {
        id
        supportsUser
        name
      }
      pipbhcTreatmentGoalAnswers {
        ...PipbhcTreatmentGoalAnswer
      }
    }
  }
  ${PipbhcTreatmentGoalFragmentDoc}
  ${PipbhcTreatmentGoalAnswerFragmentDoc}
`;

/**
 * __usePipbhcTreatmentGoalDetailsQuery__
 *
 * To run a query within a React component, call `usePipbhcTreatmentGoalDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePipbhcTreatmentGoalDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePipbhcTreatmentGoalDetailsQuery({
 *   variables: {
 *      pipbhcTreatmentGoalId: // value for 'pipbhcTreatmentGoalId'
 *   },
 * });
 */
export function usePipbhcTreatmentGoalDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PipbhcTreatmentGoalDetailsQuery,
    PipbhcTreatmentGoalDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PipbhcTreatmentGoalDetailsQuery, PipbhcTreatmentGoalDetailsQueryVariables>(
    PipbhcTreatmentGoalDetailsDocument,
    options
  );
}
export function usePipbhcTreatmentGoalDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PipbhcTreatmentGoalDetailsQuery,
    PipbhcTreatmentGoalDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PipbhcTreatmentGoalDetailsQuery, PipbhcTreatmentGoalDetailsQueryVariables>(
    PipbhcTreatmentGoalDetailsDocument,
    options
  );
}
export type PipbhcTreatmentGoalDetailsQueryHookResult = ReturnType<typeof usePipbhcTreatmentGoalDetailsQuery>;
export type PipbhcTreatmentGoalDetailsLazyQueryHookResult = ReturnType<
  typeof usePipbhcTreatmentGoalDetailsLazyQuery
>;
export type PipbhcTreatmentGoalDetailsQueryResult = Apollo.QueryResult<
  PipbhcTreatmentGoalDetailsQuery,
  PipbhcTreatmentGoalDetailsQueryVariables
>;
export const PipbhcTreatmentGoalReportDetailsDocument = gql`
  query PipbhcTreatmentGoalReportDetails($pipbhcTreatmentGoalReportId: PipbhcTreatmentGoalReportId!) {
    pipbhcTreatmentGoalReport(id: $pipbhcTreatmentGoalReportId) {
      ...PipbhcTreatmentGoalReport
      pipbhcTreatmentGoalAnswers {
        ...PipbhcTreatmentGoalAnswer
        pipbhcTreatmentGoal {
          ...PipbhcTreatmentGoal
          patient {
            id
            supportsUser
            name
          }
        }
      }
    }
  }
  ${PipbhcTreatmentGoalReportFragmentDoc}
  ${PipbhcTreatmentGoalAnswerFragmentDoc}
  ${PipbhcTreatmentGoalFragmentDoc}
`;

/**
 * __usePipbhcTreatmentGoalReportDetailsQuery__
 *
 * To run a query within a React component, call `usePipbhcTreatmentGoalReportDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePipbhcTreatmentGoalReportDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePipbhcTreatmentGoalReportDetailsQuery({
 *   variables: {
 *      pipbhcTreatmentGoalReportId: // value for 'pipbhcTreatmentGoalReportId'
 *   },
 * });
 */
export function usePipbhcTreatmentGoalReportDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PipbhcTreatmentGoalReportDetailsQuery,
    PipbhcTreatmentGoalReportDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PipbhcTreatmentGoalReportDetailsQuery,
    PipbhcTreatmentGoalReportDetailsQueryVariables
  >(PipbhcTreatmentGoalReportDetailsDocument, options);
}
export function usePipbhcTreatmentGoalReportDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PipbhcTreatmentGoalReportDetailsQuery,
    PipbhcTreatmentGoalReportDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PipbhcTreatmentGoalReportDetailsQuery,
    PipbhcTreatmentGoalReportDetailsQueryVariables
  >(PipbhcTreatmentGoalReportDetailsDocument, options);
}
export type PipbhcTreatmentGoalReportDetailsQueryHookResult = ReturnType<
  typeof usePipbhcTreatmentGoalReportDetailsQuery
>;
export type PipbhcTreatmentGoalReportDetailsLazyQueryHookResult = ReturnType<
  typeof usePipbhcTreatmentGoalReportDetailsLazyQuery
>;
export type PipbhcTreatmentGoalReportDetailsQueryResult = Apollo.QueryResult<
  PipbhcTreatmentGoalReportDetailsQuery,
  PipbhcTreatmentGoalReportDetailsQueryVariables
>;
export const UpdatePipbhcTreatmentGoalDocument = gql`
  mutation UpdatePipbhcTreatmentGoal($treatmentGoalChanges: UpdateTreatmentGoalInput!) {
    updatePipbhcTreatmentGoal(input: $treatmentGoalChanges) {
      success
      errors {
        message
        path
      }
      result {
        treatmentGoal {
          ...PipbhcTreatmentGoal
        }
      }
    }
  }
  ${PipbhcTreatmentGoalFragmentDoc}
`;
export type UpdatePipbhcTreatmentGoalMutationFn = Apollo.MutationFunction<
  UpdatePipbhcTreatmentGoalMutation,
  UpdatePipbhcTreatmentGoalMutationVariables
>;

/**
 * __useUpdatePipbhcTreatmentGoalMutation__
 *
 * To run a mutation, you first call `useUpdatePipbhcTreatmentGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePipbhcTreatmentGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePipbhcTreatmentGoalMutation, { data, loading, error }] = useUpdatePipbhcTreatmentGoalMutation({
 *   variables: {
 *      treatmentGoalChanges: // value for 'treatmentGoalChanges'
 *   },
 * });
 */
export function useUpdatePipbhcTreatmentGoalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePipbhcTreatmentGoalMutation,
    UpdatePipbhcTreatmentGoalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePipbhcTreatmentGoalMutation, UpdatePipbhcTreatmentGoalMutationVariables>(
    UpdatePipbhcTreatmentGoalDocument,
    options
  );
}
export type UpdatePipbhcTreatmentGoalMutationHookResult = ReturnType<
  typeof useUpdatePipbhcTreatmentGoalMutation
>;
export type UpdatePipbhcTreatmentGoalMutationResult =
  Apollo.MutationResult<UpdatePipbhcTreatmentGoalMutation>;
export type UpdatePipbhcTreatmentGoalMutationOptions = Apollo.BaseMutationOptions<
  UpdatePipbhcTreatmentGoalMutation,
  UpdatePipbhcTreatmentGoalMutationVariables
>;
export const CareGroupTreatmentGoalsDetailsDocument = gql`
  query CareGroupTreatmentGoalsDetails($careGroupId: CareGroupId!) {
    careGroup(id: $careGroupId) {
      id
      name
      pipbhcTreatmentGoals {
        ...PipbhcTreatmentGoal
        pipbhcTreatmentGoalAnswers {
          ...PipbhcTreatmentGoalAnswer
        }
      }
      currentCareUnitParentMemberships {
        id
        child {
          id
          name
          ... on Patient {
            dob
          }
          pipbhcTreatmentGoals {
            ...PipbhcTreatmentGoal
            pipbhcTreatmentGoalAnswers {
              ...PipbhcTreatmentGoalAnswer
            }
          }
        }
      }
    }
  }
  ${PipbhcTreatmentGoalFragmentDoc}
  ${PipbhcTreatmentGoalAnswerFragmentDoc}
`;

/**
 * __useCareGroupTreatmentGoalsDetailsQuery__
 *
 * To run a query within a React component, call `useCareGroupTreatmentGoalsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareGroupTreatmentGoalsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareGroupTreatmentGoalsDetailsQuery({
 *   variables: {
 *      careGroupId: // value for 'careGroupId'
 *   },
 * });
 */
export function useCareGroupTreatmentGoalsDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CareGroupTreatmentGoalsDetailsQuery,
    CareGroupTreatmentGoalsDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareGroupTreatmentGoalsDetailsQuery, CareGroupTreatmentGoalsDetailsQueryVariables>(
    CareGroupTreatmentGoalsDetailsDocument,
    options
  );
}
export function useCareGroupTreatmentGoalsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CareGroupTreatmentGoalsDetailsQuery,
    CareGroupTreatmentGoalsDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CareGroupTreatmentGoalsDetailsQuery,
    CareGroupTreatmentGoalsDetailsQueryVariables
  >(CareGroupTreatmentGoalsDetailsDocument, options);
}
export type CareGroupTreatmentGoalsDetailsQueryHookResult = ReturnType<
  typeof useCareGroupTreatmentGoalsDetailsQuery
>;
export type CareGroupTreatmentGoalsDetailsLazyQueryHookResult = ReturnType<
  typeof useCareGroupTreatmentGoalsDetailsLazyQuery
>;
export type CareGroupTreatmentGoalsDetailsQueryResult = Apollo.QueryResult<
  CareGroupTreatmentGoalsDetailsQuery,
  CareGroupTreatmentGoalsDetailsQueryVariables
>;
export const CareGroupListQueryDocument = gql`
  query CareGroupListQuery(
    $careUnitTypeMnemonic: String
    $first: Int
    $last: Int
    $after: String
    $before: String
    $search: String
  ) {
    careGroups(
      careUnitTypeMnemonic: $careUnitTypeMnemonic
      first: $first
      after: $after
      before: $before
      last: $last
      search: $search
    ) {
      nodes {
        id
        name
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useCareGroupListQueryQuery__
 *
 * To run a query within a React component, call `useCareGroupListQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareGroupListQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareGroupListQueryQuery({
 *   variables: {
 *      careUnitTypeMnemonic: // value for 'careUnitTypeMnemonic'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCareGroupListQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<CareGroupListQueryQuery, CareGroupListQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareGroupListQueryQuery, CareGroupListQueryQueryVariables>(
    CareGroupListQueryDocument,
    options
  );
}
export function useCareGroupListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareGroupListQueryQuery, CareGroupListQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareGroupListQueryQuery, CareGroupListQueryQueryVariables>(
    CareGroupListQueryDocument,
    options
  );
}
export type CareGroupListQueryQueryHookResult = ReturnType<typeof useCareGroupListQueryQuery>;
export type CareGroupListQueryLazyQueryHookResult = ReturnType<typeof useCareGroupListQueryLazyQuery>;
export type CareGroupListQueryQueryResult = Apollo.QueryResult<
  CareGroupListQueryQuery,
  CareGroupListQueryQueryVariables
>;
export const PatientParticipantsQueryDocument = gql`
  query PatientParticipantsQuery($patientId: PatientId!) {
    patient(id: $patientId) {
      id
      clientParticipantUsers {
        ...UserSummaryFragment
      }
    }
  }
  ${UserSummaryFragmentFragmentDoc}
`;

/**
 * __usePatientParticipantsQueryQuery__
 *
 * To run a query within a React component, call `usePatientParticipantsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientParticipantsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientParticipantsQueryQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function usePatientParticipantsQueryQuery(
  baseOptions: Apollo.QueryHookOptions<PatientParticipantsQueryQuery, PatientParticipantsQueryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientParticipantsQueryQuery, PatientParticipantsQueryQueryVariables>(
    PatientParticipantsQueryDocument,
    options
  );
}
export function usePatientParticipantsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientParticipantsQueryQuery,
    PatientParticipantsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientParticipantsQueryQuery, PatientParticipantsQueryQueryVariables>(
    PatientParticipantsQueryDocument,
    options
  );
}
export type PatientParticipantsQueryQueryHookResult = ReturnType<typeof usePatientParticipantsQueryQuery>;
export type PatientParticipantsQueryLazyQueryHookResult = ReturnType<
  typeof usePatientParticipantsQueryLazyQuery
>;
export type PatientParticipantsQueryQueryResult = Apollo.QueryResult<
  PatientParticipantsQueryQuery,
  PatientParticipantsQueryQueryVariables
>;
export const SearchPatientsDocument = gql`
  query SearchPatients($search: String!) {
    patients(search: $search) {
      nodes {
        ...PipbhcPatientDetails
      }
    }
  }
  ${PipbhcPatientDetailsFragmentDoc}
`;

/**
 * __useSearchPatientsQuery__
 *
 * To run a query within a React component, call `useSearchPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPatientsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchPatientsQuery(
  baseOptions: Apollo.QueryHookOptions<SearchPatientsQuery, SearchPatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPatientsQuery, SearchPatientsQueryVariables>(SearchPatientsDocument, options);
}
export function useSearchPatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchPatientsQuery, SearchPatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchPatientsQuery, SearchPatientsQueryVariables>(
    SearchPatientsDocument,
    options
  );
}
export type SearchPatientsQueryHookResult = ReturnType<typeof useSearchPatientsQuery>;
export type SearchPatientsLazyQueryHookResult = ReturnType<typeof useSearchPatientsLazyQuery>;
export type SearchPatientsQueryResult = Apollo.QueryResult<SearchPatientsQuery, SearchPatientsQueryVariables>;
export const AssessmentReportProviderObligationsDocument = gql`
  query AssessmentReportProviderObligations($reportId: String!, $providerId: String!) {
    assessmentReportProviderObligations(reportId: $reportId, providerId: $providerId) {
      obligations
    }
  }
`;

/**
 * __useAssessmentReportProviderObligationsQuery__
 *
 * To run a query within a React component, call `useAssessmentReportProviderObligationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssessmentReportProviderObligationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssessmentReportProviderObligationsQuery({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useAssessmentReportProviderObligationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AssessmentReportProviderObligationsQuery,
    AssessmentReportProviderObligationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AssessmentReportProviderObligationsQuery,
    AssessmentReportProviderObligationsQueryVariables
  >(AssessmentReportProviderObligationsDocument, options);
}
export function useAssessmentReportProviderObligationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssessmentReportProviderObligationsQuery,
    AssessmentReportProviderObligationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AssessmentReportProviderObligationsQuery,
    AssessmentReportProviderObligationsQueryVariables
  >(AssessmentReportProviderObligationsDocument, options);
}
export type AssessmentReportProviderObligationsQueryHookResult = ReturnType<
  typeof useAssessmentReportProviderObligationsQuery
>;
export type AssessmentReportProviderObligationsLazyQueryHookResult = ReturnType<
  typeof useAssessmentReportProviderObligationsLazyQuery
>;
export type AssessmentReportProviderObligationsQueryResult = Apollo.QueryResult<
  AssessmentReportProviderObligationsQuery,
  AssessmentReportProviderObligationsQueryVariables
>;
export const GenerateUserLoginCodeDocument = gql`
  mutation GenerateUserLoginCode($input: GenerateUserLoginCodeInput!) {
    generateUserLoginCode(input: $input) {
      errors {
        message
        path
      }
      success
      result {
        loginCodes {
          user {
            name
          }
          code
          relationship
        }
      }
    }
  }
`;
export type GenerateUserLoginCodeMutationFn = Apollo.MutationFunction<
  GenerateUserLoginCodeMutation,
  GenerateUserLoginCodeMutationVariables
>;

/**
 * __useGenerateUserLoginCodeMutation__
 *
 * To run a mutation, you first call `useGenerateUserLoginCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUserLoginCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUserLoginCodeMutation, { data, loading, error }] = useGenerateUserLoginCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateUserLoginCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateUserLoginCodeMutation,
    GenerateUserLoginCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateUserLoginCodeMutation, GenerateUserLoginCodeMutationVariables>(
    GenerateUserLoginCodeDocument,
    options
  );
}
export type GenerateUserLoginCodeMutationHookResult = ReturnType<typeof useGenerateUserLoginCodeMutation>;
export type GenerateUserLoginCodeMutationResult = Apollo.MutationResult<GenerateUserLoginCodeMutation>;
export type GenerateUserLoginCodeMutationOptions = Apollo.BaseMutationOptions<
  GenerateUserLoginCodeMutation,
  GenerateUserLoginCodeMutationVariables
>;
export const ProviderAssessmentInvitationsDocument = gql`
  query ProviderAssessmentInvitations(
    $status: [InvitationStatus!]
    $first: Int
    $last: Int
    $sortBy: InvitationSortParameter
    $sortDirection: SortDirection
    $after: String
    $before: String
    $userId: [UserId!]
    $isWaitingOnUserStates: Boolean
  ) {
    assessmentInvitations(
      status: $status
      first: $first
      last: $last
      sortBy: $sortBy
      sortDirection: $sortDirection
      after: $after
      before: $before
      userId: $userId
      isWaitingOnUserStates: $isWaitingOnUserStates
    ) {
      nodes {
        id
        referrer
        status
        user {
          id
          patient {
            name
            id
          }
        }
        takingUser {
          id
        }
        report {
          patientSession {
            id
            isReportAvailable
            isReportComplete
            patient {
              name
              id
            }
            targetDate
          }
          effectiveDate
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useProviderAssessmentInvitationsQuery__
 *
 * To run a query within a React component, call `useProviderAssessmentInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderAssessmentInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderAssessmentInvitationsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      userId: // value for 'userId'
 *      isWaitingOnUserStates: // value for 'isWaitingOnUserStates'
 *   },
 * });
 */
export function useProviderAssessmentInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProviderAssessmentInvitationsQuery,
    ProviderAssessmentInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProviderAssessmentInvitationsQuery, ProviderAssessmentInvitationsQueryVariables>(
    ProviderAssessmentInvitationsDocument,
    options
  );
}
export function useProviderAssessmentInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProviderAssessmentInvitationsQuery,
    ProviderAssessmentInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProviderAssessmentInvitationsQuery, ProviderAssessmentInvitationsQueryVariables>(
    ProviderAssessmentInvitationsDocument,
    options
  );
}
export type ProviderAssessmentInvitationsQueryHookResult = ReturnType<
  typeof useProviderAssessmentInvitationsQuery
>;
export type ProviderAssessmentInvitationsLazyQueryHookResult = ReturnType<
  typeof useProviderAssessmentInvitationsLazyQuery
>;
export type ProviderAssessmentInvitationsQueryResult = Apollo.QueryResult<
  ProviderAssessmentInvitationsQuery,
  ProviderAssessmentInvitationsQueryVariables
>;
export const ProviderSchedulingAppointmentsDocument = gql`
  query ProviderSchedulingAppointments(
    $sortBy: AppointmentScheduleSortParameter
    $sortDirection: SortDirection
    $provider: ProviderFilter
    $after: String
    $before: String
    $first: Int
    $last: Int
    $flags: [FlagId!]
    $testClient: Boolean
    $unit: OrganizationFilter
    $startDateBefore: ISO8601DateTime
    $startDateAfter: ISO8601DateTime
    $measurementCategory: [AppointmentMeasurementCategory!]
    $search: String
  ) {
    schedulingAppointmentSchedules(
      sortBy: $sortBy
      sortDirection: $sortDirection
      provider: $provider
      measurementCategory: $measurementCategory
      after: $after
      before: $before
      first: $first
      last: $last
      flags: $flags
      testClient: $testClient
      unit: $unit
      startDateBefore: $startDateBefore
      startDateAfter: $startDateAfter
      search: $search
    ) {
      nodes {
        startDate
        status
        id
        measurementStatus
        measurementCategory
        patientSession {
          id
          assessmentReport {
            id
          }
          isReportAvailable
        }
        patientName
        patientId
        patientFlags {
          id
          active
          flag {
            id
            name
            icon
          }
        }
        currentStats {
          complexity
          severity
          severityTrend
        }
        providerName
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useProviderSchedulingAppointmentsQuery__
 *
 * To run a query within a React component, call `useProviderSchedulingAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderSchedulingAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderSchedulingAppointmentsQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      provider: // value for 'provider'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      flags: // value for 'flags'
 *      testClient: // value for 'testClient'
 *      unit: // value for 'unit'
 *      startDateBefore: // value for 'startDateBefore'
 *      startDateAfter: // value for 'startDateAfter'
 *      measurementCategory: // value for 'measurementCategory'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useProviderSchedulingAppointmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProviderSchedulingAppointmentsQuery,
    ProviderSchedulingAppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProviderSchedulingAppointmentsQuery, ProviderSchedulingAppointmentsQueryVariables>(
    ProviderSchedulingAppointmentsDocument,
    options
  );
}
export function useProviderSchedulingAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProviderSchedulingAppointmentsQuery,
    ProviderSchedulingAppointmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProviderSchedulingAppointmentsQuery,
    ProviderSchedulingAppointmentsQueryVariables
  >(ProviderSchedulingAppointmentsDocument, options);
}
export type ProviderSchedulingAppointmentsQueryHookResult = ReturnType<
  typeof useProviderSchedulingAppointmentsQuery
>;
export type ProviderSchedulingAppointmentsLazyQueryHookResult = ReturnType<
  typeof useProviderSchedulingAppointmentsLazyQuery
>;
export type ProviderSchedulingAppointmentsQueryResult = Apollo.QueryResult<
  ProviderSchedulingAppointmentsQuery,
  ProviderSchedulingAppointmentsQueryVariables
>;
export const UpdateAssessmentInvitationMutationDocument = gql`
  mutation UpdateAssessmentInvitationMutation($invitationInput: UpdateInvitationInput!) {
    assessmentUpdateInvitation(input: $invitationInput) {
      errors {
        message
        path
      }
      success
      result {
        invitationId
        status
      }
    }
  }
`;
export type UpdateAssessmentInvitationMutationMutationFn = Apollo.MutationFunction<
  UpdateAssessmentInvitationMutationMutation,
  UpdateAssessmentInvitationMutationMutationVariables
>;

/**
 * __useUpdateAssessmentInvitationMutationMutation__
 *
 * To run a mutation, you first call `useUpdateAssessmentInvitationMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssessmentInvitationMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssessmentInvitationMutationMutation, { data, loading, error }] = useUpdateAssessmentInvitationMutationMutation({
 *   variables: {
 *      invitationInput: // value for 'invitationInput'
 *   },
 * });
 */
export function useUpdateAssessmentInvitationMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssessmentInvitationMutationMutation,
    UpdateAssessmentInvitationMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAssessmentInvitationMutationMutation,
    UpdateAssessmentInvitationMutationMutationVariables
  >(UpdateAssessmentInvitationMutationDocument, options);
}
export type UpdateAssessmentInvitationMutationMutationHookResult = ReturnType<
  typeof useUpdateAssessmentInvitationMutationMutation
>;
export type UpdateAssessmentInvitationMutationMutationResult =
  Apollo.MutationResult<UpdateAssessmentInvitationMutationMutation>;
export type UpdateAssessmentInvitationMutationMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssessmentInvitationMutationMutation,
  UpdateAssessmentInvitationMutationMutationVariables
>;
export const AlgorithmDetailsDocument = gql`
  query AlgorithmDetails($entityDecisionAlgorithmId: EntityDecisionAlgorithmId!) {
    decisionSupportEntityDecisionAlgorithm(id: $entityDecisionAlgorithmId) {
      id
      name
      notes
      status
      startDate
      sendNotifications
      entityTreeNode {
        ...EntityTreeNodeDetails
      }
      decisionAlgorithm {
        id
        name
        shortDescription
        fullDescription
      }
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
`;

/**
 * __useAlgorithmDetailsQuery__
 *
 * To run a query within a React component, call `useAlgorithmDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlgorithmDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlgorithmDetailsQuery({
 *   variables: {
 *      entityDecisionAlgorithmId: // value for 'entityDecisionAlgorithmId'
 *   },
 * });
 */
export function useAlgorithmDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<AlgorithmDetailsQuery, AlgorithmDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AlgorithmDetailsQuery, AlgorithmDetailsQueryVariables>(
    AlgorithmDetailsDocument,
    options
  );
}
export function useAlgorithmDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AlgorithmDetailsQuery, AlgorithmDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AlgorithmDetailsQuery, AlgorithmDetailsQueryVariables>(
    AlgorithmDetailsDocument,
    options
  );
}
export type AlgorithmDetailsQueryHookResult = ReturnType<typeof useAlgorithmDetailsQuery>;
export type AlgorithmDetailsLazyQueryHookResult = ReturnType<typeof useAlgorithmDetailsLazyQuery>;
export type AlgorithmDetailsQueryResult = Apollo.QueryResult<
  AlgorithmDetailsQuery,
  AlgorithmDetailsQueryVariables
>;
export const EntityDecisionAlgorithmListDocument = gql`
  query EntityDecisionAlgorithmList(
    $sortBy: EntityDecisionAlgorithmSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $status: [EntityDecisionAlgorithmStatus!]
  ) {
    decisionSupportEntityDecisionAlgorithms(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      status: $status
    ) {
      nodes {
        id
        name
        decisionAlgorithm {
          id
        }
        entityTreeNode {
          ...EntityTreeNodeDetails
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
`;

/**
 * __useEntityDecisionAlgorithmListQuery__
 *
 * To run a query within a React component, call `useEntityDecisionAlgorithmListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityDecisionAlgorithmListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityDecisionAlgorithmListQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEntityDecisionAlgorithmListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EntityDecisionAlgorithmListQuery,
    EntityDecisionAlgorithmListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EntityDecisionAlgorithmListQuery, EntityDecisionAlgorithmListQueryVariables>(
    EntityDecisionAlgorithmListDocument,
    options
  );
}
export function useEntityDecisionAlgorithmListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EntityDecisionAlgorithmListQuery,
    EntityDecisionAlgorithmListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EntityDecisionAlgorithmListQuery, EntityDecisionAlgorithmListQueryVariables>(
    EntityDecisionAlgorithmListDocument,
    options
  );
}
export type EntityDecisionAlgorithmListQueryHookResult = ReturnType<
  typeof useEntityDecisionAlgorithmListQuery
>;
export type EntityDecisionAlgorithmListLazyQueryHookResult = ReturnType<
  typeof useEntityDecisionAlgorithmListLazyQuery
>;
export type EntityDecisionAlgorithmListQueryResult = Apollo.QueryResult<
  EntityDecisionAlgorithmListQuery,
  EntityDecisionAlgorithmListQueryVariables
>;
export const ProviderInboxNotificationsDocument = gql`
  query ProviderInboxNotifications(
    $sortBy: ProviderNotificationSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $provider: ProviderFilter!
    $patient: PatientFilter!
    $workflowStatus: TriggeredWorkflowStatus
    $workflow: WorkflowFilter!
  ) {
    decisionSupportProviderNotifications(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      provider: $provider
      patient: $patient
      workflowStatus: $workflowStatus
      workflowType: $workflow
    ) {
      nodes {
        id
        sentAt
        triggeredWorkflow {
          id
          status
          workflow {
            title
          }
          triggeredDecisionAlgorithm {
            id
            careEpisode {
              id
              patient {
                id
                name
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useProviderInboxNotificationsQuery__
 *
 * To run a query within a React component, call `useProviderInboxNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderInboxNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderInboxNotificationsQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      provider: // value for 'provider'
 *      patient: // value for 'patient'
 *      workflowStatus: // value for 'workflowStatus'
 *      workflow: // value for 'workflow'
 *   },
 * });
 */
export function useProviderInboxNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProviderInboxNotificationsQuery,
    ProviderInboxNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProviderInboxNotificationsQuery, ProviderInboxNotificationsQueryVariables>(
    ProviderInboxNotificationsDocument,
    options
  );
}
export function useProviderInboxNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProviderInboxNotificationsQuery,
    ProviderInboxNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProviderInboxNotificationsQuery, ProviderInboxNotificationsQueryVariables>(
    ProviderInboxNotificationsDocument,
    options
  );
}
export type ProviderInboxNotificationsQueryHookResult = ReturnType<typeof useProviderInboxNotificationsQuery>;
export type ProviderInboxNotificationsLazyQueryHookResult = ReturnType<
  typeof useProviderInboxNotificationsLazyQuery
>;
export type ProviderInboxNotificationsQueryResult = Apollo.QueryResult<
  ProviderInboxNotificationsQuery,
  ProviderInboxNotificationsQueryVariables
>;
export const RunWorkflowActionDocument = gql`
  mutation RunWorkflowAction($input: RunWorkflowActionInput!) {
    decisionSupportRunWorkflowAction(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        triggeredWorkflow {
          ...TriggeredWorkflowSummary
        }
      }
    }
  }
  ${TriggeredWorkflowSummaryFragmentDoc}
`;
export type RunWorkflowActionMutationFn = Apollo.MutationFunction<
  RunWorkflowActionMutation,
  RunWorkflowActionMutationVariables
>;

/**
 * __useRunWorkflowActionMutation__
 *
 * To run a mutation, you first call `useRunWorkflowActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunWorkflowActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runWorkflowActionMutation, { data, loading, error }] = useRunWorkflowActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunWorkflowActionMutation(
  baseOptions?: Apollo.MutationHookOptions<RunWorkflowActionMutation, RunWorkflowActionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RunWorkflowActionMutation, RunWorkflowActionMutationVariables>(
    RunWorkflowActionDocument,
    options
  );
}
export type RunWorkflowActionMutationHookResult = ReturnType<typeof useRunWorkflowActionMutation>;
export type RunWorkflowActionMutationResult = Apollo.MutationResult<RunWorkflowActionMutation>;
export type RunWorkflowActionMutationOptions = Apollo.BaseMutationOptions<
  RunWorkflowActionMutation,
  RunWorkflowActionMutationVariables
>;
export const TriggeredWorkflowDetailsDocument = gql`
  query TriggeredWorkflowDetails($triggeredWorkflowId: TriggeredWorkflowId!) {
    decisionSupportTriggeredWorkflow(id: $triggeredWorkflowId) {
      ...TriggeredWorkflowSummary
      triggeredDecisionAlgorithm {
        id
        entityDecisionAlgorithm {
          id
          workflowNote
        }
        careEpisode {
          id
          patient {
            id
            name
          }
        }
      }
      workflow {
        id
        title
        callToAction
        instructions
      }
    }
  }
  ${TriggeredWorkflowSummaryFragmentDoc}
`;

/**
 * __useTriggeredWorkflowDetailsQuery__
 *
 * To run a query within a React component, call `useTriggeredWorkflowDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTriggeredWorkflowDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTriggeredWorkflowDetailsQuery({
 *   variables: {
 *      triggeredWorkflowId: // value for 'triggeredWorkflowId'
 *   },
 * });
 */
export function useTriggeredWorkflowDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<TriggeredWorkflowDetailsQuery, TriggeredWorkflowDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TriggeredWorkflowDetailsQuery, TriggeredWorkflowDetailsQueryVariables>(
    TriggeredWorkflowDetailsDocument,
    options
  );
}
export function useTriggeredWorkflowDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TriggeredWorkflowDetailsQuery,
    TriggeredWorkflowDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TriggeredWorkflowDetailsQuery, TriggeredWorkflowDetailsQueryVariables>(
    TriggeredWorkflowDetailsDocument,
    options
  );
}
export type TriggeredWorkflowDetailsQueryHookResult = ReturnType<typeof useTriggeredWorkflowDetailsQuery>;
export type TriggeredWorkflowDetailsLazyQueryHookResult = ReturnType<
  typeof useTriggeredWorkflowDetailsLazyQuery
>;
export type TriggeredWorkflowDetailsQueryResult = Apollo.QueryResult<
  TriggeredWorkflowDetailsQuery,
  TriggeredWorkflowDetailsQueryVariables
>;
export const TriggeredWorkflowListDocument = gql`
  query TriggeredWorkflowList(
    $sortBy: TriggeredWorkflowSortParameter
    $sortDirection: SortDirection
    $first: Int
    $last: Int
    $after: String
    $before: String
    $provider: ProviderFilter!
    $patient: PatientFilter!
    $status: TriggeredWorkflowStatus
    $workflow: WorkflowFilter!
  ) {
    decisionSupportTriggeredWorkflows(
      sortBy: $sortBy
      sortDirection: $sortDirection
      first: $first
      after: $after
      before: $before
      last: $last
      provider: $provider
      patient: $patient
      status: $status
      workflowType: $workflow
    ) {
      nodes {
        id
        status
        workflow {
          title
        }
        triggeredDecisionAlgorithm {
          id
          careEpisode {
            id
            patient {
              id
              name
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

/**
 * __useTriggeredWorkflowListQuery__
 *
 * To run a query within a React component, call `useTriggeredWorkflowListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTriggeredWorkflowListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTriggeredWorkflowListQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      provider: // value for 'provider'
 *      patient: // value for 'patient'
 *      status: // value for 'status'
 *      workflow: // value for 'workflow'
 *   },
 * });
 */
export function useTriggeredWorkflowListQuery(
  baseOptions: Apollo.QueryHookOptions<TriggeredWorkflowListQuery, TriggeredWorkflowListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TriggeredWorkflowListQuery, TriggeredWorkflowListQueryVariables>(
    TriggeredWorkflowListDocument,
    options
  );
}
export function useTriggeredWorkflowListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TriggeredWorkflowListQuery, TriggeredWorkflowListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TriggeredWorkflowListQuery, TriggeredWorkflowListQueryVariables>(
    TriggeredWorkflowListDocument,
    options
  );
}
export type TriggeredWorkflowListQueryHookResult = ReturnType<typeof useTriggeredWorkflowListQuery>;
export type TriggeredWorkflowListLazyQueryHookResult = ReturnType<typeof useTriggeredWorkflowListLazyQuery>;
export type TriggeredWorkflowListQueryResult = Apollo.QueryResult<
  TriggeredWorkflowListQuery,
  TriggeredWorkflowListQueryVariables
>;
export const WorkflowSelectDocument = gql`
  query WorkflowSelect($search: String, $first: Int) {
    decisionSupportInstituteWorkflows(smartSearch: $search, first: $first) {
      nodes {
        workflow {
          id
          title
        }
      }
    }
  }
`;

/**
 * __useWorkflowSelectQuery__
 *
 * To run a query within a React component, call `useWorkflowSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowSelectQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useWorkflowSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<WorkflowSelectQuery, WorkflowSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkflowSelectQuery, WorkflowSelectQueryVariables>(WorkflowSelectDocument, options);
}
export function useWorkflowSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WorkflowSelectQuery, WorkflowSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WorkflowSelectQuery, WorkflowSelectQueryVariables>(
    WorkflowSelectDocument,
    options
  );
}
export type WorkflowSelectQueryHookResult = ReturnType<typeof useWorkflowSelectQuery>;
export type WorkflowSelectLazyQueryHookResult = ReturnType<typeof useWorkflowSelectLazyQuery>;
export type WorkflowSelectQueryResult = Apollo.QueryResult<WorkflowSelectQuery, WorkflowSelectQueryVariables>;
export const ProviderActiveDecisionsDocument = gql`
  query ProviderActiveDecisions {
    decisionSupportMyProviderNotifications {
      totalCount
    }
  }
`;

/**
 * __useProviderActiveDecisionsQuery__
 *
 * To run a query within a React component, call `useProviderActiveDecisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderActiveDecisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderActiveDecisionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProviderActiveDecisionsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProviderActiveDecisionsQuery, ProviderActiveDecisionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProviderActiveDecisionsQuery, ProviderActiveDecisionsQueryVariables>(
    ProviderActiveDecisionsDocument,
    options
  );
}
export function useProviderActiveDecisionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProviderActiveDecisionsQuery,
    ProviderActiveDecisionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProviderActiveDecisionsQuery, ProviderActiveDecisionsQueryVariables>(
    ProviderActiveDecisionsDocument,
    options
  );
}
export type ProviderActiveDecisionsQueryHookResult = ReturnType<typeof useProviderActiveDecisionsQuery>;
export type ProviderActiveDecisionsLazyQueryHookResult = ReturnType<
  typeof useProviderActiveDecisionsLazyQuery
>;
export type ProviderActiveDecisionsQueryResult = Apollo.QueryResult<
  ProviderActiveDecisionsQuery,
  ProviderActiveDecisionsQueryVariables
>;
export const ProviderUserDocument = gql`
  query ProviderUser($id: UserId!) {
    user(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useProviderUserQuery__
 *
 * To run a query within a React component, call `useProviderUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderUserQuery(
  baseOptions: Apollo.QueryHookOptions<ProviderUserQuery, ProviderUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProviderUserQuery, ProviderUserQueryVariables>(ProviderUserDocument, options);
}
export function useProviderUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProviderUserQuery, ProviderUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProviderUserQuery, ProviderUserQueryVariables>(ProviderUserDocument, options);
}
export type ProviderUserQueryHookResult = ReturnType<typeof useProviderUserQuery>;
export type ProviderUserLazyQueryHookResult = ReturnType<typeof useProviderUserLazyQuery>;
export type ProviderUserQueryResult = Apollo.QueryResult<ProviderUserQuery, ProviderUserQueryVariables>;
export const InstituteUpdateMeasurementAllowedDocument = gql`
  mutation InstituteUpdateMeasurementAllowed($input: InstituteUpdateMeasurementAllowedInput!) {
    settingsInstituteUpdateMeasurementAllowed(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        measurementAllowed
      }
    }
  }
`;
export type InstituteUpdateMeasurementAllowedMutationFn = Apollo.MutationFunction<
  InstituteUpdateMeasurementAllowedMutation,
  InstituteUpdateMeasurementAllowedMutationVariables
>;

/**
 * __useInstituteUpdateMeasurementAllowedMutation__
 *
 * To run a mutation, you first call `useInstituteUpdateMeasurementAllowedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInstituteUpdateMeasurementAllowedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [instituteUpdateMeasurementAllowedMutation, { data, loading, error }] = useInstituteUpdateMeasurementAllowedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInstituteUpdateMeasurementAllowedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InstituteUpdateMeasurementAllowedMutation,
    InstituteUpdateMeasurementAllowedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InstituteUpdateMeasurementAllowedMutation,
    InstituteUpdateMeasurementAllowedMutationVariables
  >(InstituteUpdateMeasurementAllowedDocument, options);
}
export type InstituteUpdateMeasurementAllowedMutationHookResult = ReturnType<
  typeof useInstituteUpdateMeasurementAllowedMutation
>;
export type InstituteUpdateMeasurementAllowedMutationResult =
  Apollo.MutationResult<InstituteUpdateMeasurementAllowedMutation>;
export type InstituteUpdateMeasurementAllowedMutationOptions = Apollo.BaseMutationOptions<
  InstituteUpdateMeasurementAllowedMutation,
  InstituteUpdateMeasurementAllowedMutationVariables
>;
export const InstituteSettingsDocument = gql`
  query InstituteSettings($instituteId: InstituteId!) {
    institute(id: $instituteId) {
      id
      measurementAllowed
      organizations {
        nodes {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useInstituteSettingsQuery__
 *
 * To run a query within a React component, call `useInstituteSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteSettingsQuery({
 *   variables: {
 *      instituteId: // value for 'instituteId'
 *   },
 * });
 */
export function useInstituteSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<InstituteSettingsQuery, InstituteSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteSettingsQuery, InstituteSettingsQueryVariables>(
    InstituteSettingsDocument,
    options
  );
}
export function useInstituteSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstituteSettingsQuery, InstituteSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteSettingsQuery, InstituteSettingsQueryVariables>(
    InstituteSettingsDocument,
    options
  );
}
export type InstituteSettingsQueryHookResult = ReturnType<typeof useInstituteSettingsQuery>;
export type InstituteSettingsLazyQueryHookResult = ReturnType<typeof useInstituteSettingsLazyQuery>;
export type InstituteSettingsQueryResult = Apollo.QueryResult<
  InstituteSettingsQuery,
  InstituteSettingsQueryVariables
>;
export const OrganizationSettingsDocument = gql`
  query OrganizationSettings($id: OrganizationId!) {
    organization(id: $id) {
      id
      measurementAllowed
      inTrialPeriod
      name
      timezone
    }
    timezones {
      timezone
    }
  }
`;

/**
 * __useOrganizationSettingsQuery__
 *
 * To run a query within a React component, call `useOrganizationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganizationSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<OrganizationSettingsQuery, OrganizationSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationSettingsQuery, OrganizationSettingsQueryVariables>(
    OrganizationSettingsDocument,
    options
  );
}
export function useOrganizationSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationSettingsQuery, OrganizationSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationSettingsQuery, OrganizationSettingsQueryVariables>(
    OrganizationSettingsDocument,
    options
  );
}
export type OrganizationSettingsQueryHookResult = ReturnType<typeof useOrganizationSettingsQuery>;
export type OrganizationSettingsLazyQueryHookResult = ReturnType<typeof useOrganizationSettingsLazyQuery>;
export type OrganizationSettingsQueryResult = Apollo.QueryResult<
  OrganizationSettingsQuery,
  OrganizationSettingsQueryVariables
>;
export const OrganizationUpdateMeasurementAllowedDocument = gql`
  mutation OrganizationUpdateMeasurementAllowed($input: OrganizationUpdateMeasurementAllowedInput!) {
    settingsOrganizationUpdateMeasurementAllowed(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        measurementAllowed
      }
    }
  }
`;
export type OrganizationUpdateMeasurementAllowedMutationFn = Apollo.MutationFunction<
  OrganizationUpdateMeasurementAllowedMutation,
  OrganizationUpdateMeasurementAllowedMutationVariables
>;

/**
 * __useOrganizationUpdateMeasurementAllowedMutation__
 *
 * To run a mutation, you first call `useOrganizationUpdateMeasurementAllowedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUpdateMeasurementAllowedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationUpdateMeasurementAllowedMutation, { data, loading, error }] = useOrganizationUpdateMeasurementAllowedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrganizationUpdateMeasurementAllowedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrganizationUpdateMeasurementAllowedMutation,
    OrganizationUpdateMeasurementAllowedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OrganizationUpdateMeasurementAllowedMutation,
    OrganizationUpdateMeasurementAllowedMutationVariables
  >(OrganizationUpdateMeasurementAllowedDocument, options);
}
export type OrganizationUpdateMeasurementAllowedMutationHookResult = ReturnType<
  typeof useOrganizationUpdateMeasurementAllowedMutation
>;
export type OrganizationUpdateMeasurementAllowedMutationResult =
  Apollo.MutationResult<OrganizationUpdateMeasurementAllowedMutation>;
export type OrganizationUpdateMeasurementAllowedMutationOptions = Apollo.BaseMutationOptions<
  OrganizationUpdateMeasurementAllowedMutation,
  OrganizationUpdateMeasurementAllowedMutationVariables
>;
export const UpdateOrganizationSettingsDocument = gql`
  mutation UpdateOrganizationSettings($input: UpdateOrganizationSettingsInput!) {
    settingsOrganizationUpdateOrganizationSettings(input: $input) {
      errors {
        message
        path
      }
      result {
        organization {
          id
          timezone
        }
      }
      success
    }
  }
`;
export type UpdateOrganizationSettingsMutationFn = Apollo.MutationFunction<
  UpdateOrganizationSettingsMutation,
  UpdateOrganizationSettingsMutationVariables
>;

/**
 * __useUpdateOrganizationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSettingsMutation, { data, loading, error }] = useUpdateOrganizationSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationSettingsMutation,
    UpdateOrganizationSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganizationSettingsMutation, UpdateOrganizationSettingsMutationVariables>(
    UpdateOrganizationSettingsDocument,
    options
  );
}
export type UpdateOrganizationSettingsMutationHookResult = ReturnType<
  typeof useUpdateOrganizationSettingsMutation
>;
export type UpdateOrganizationSettingsMutationResult =
  Apollo.MutationResult<UpdateOrganizationSettingsMutation>;
export type UpdateOrganizationSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationSettingsMutation,
  UpdateOrganizationSettingsMutationVariables
>;
export const UpdateTrialPeriodDocument = gql`
  mutation UpdateTrialPeriod($input: UpdateTrialPeriodInput!) {
    settingsOrganizationUpdateTrialPeriod(input: $input) {
      errors {
        message
        path
      }
      result {
        inTrialPeriod
        organizationId
      }
      success
    }
  }
`;
export type UpdateTrialPeriodMutationFn = Apollo.MutationFunction<
  UpdateTrialPeriodMutation,
  UpdateTrialPeriodMutationVariables
>;

/**
 * __useUpdateTrialPeriodMutation__
 *
 * To run a mutation, you first call `useUpdateTrialPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrialPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrialPeriodMutation, { data, loading, error }] = useUpdateTrialPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTrialPeriodMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTrialPeriodMutation, UpdateTrialPeriodMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTrialPeriodMutation, UpdateTrialPeriodMutationVariables>(
    UpdateTrialPeriodDocument,
    options
  );
}
export type UpdateTrialPeriodMutationHookResult = ReturnType<typeof useUpdateTrialPeriodMutation>;
export type UpdateTrialPeriodMutationResult = Apollo.MutationResult<UpdateTrialPeriodMutation>;
export type UpdateTrialPeriodMutationOptions = Apollo.BaseMutationOptions<
  UpdateTrialPeriodMutation,
  UpdateTrialPeriodMutationVariables
>;
export const CreateTreatmentTrackDocument = gql`
  mutation CreateTreatmentTrack($input: CreateTreatmentTrackInput!) {
    careCreateTreatmentTrack(input: $input) {
      result {
        treatmentTrack {
          id
        }
      }
      errors {
        message
        path
      }
      success
    }
  }
`;
export type CreateTreatmentTrackMutationFn = Apollo.MutationFunction<
  CreateTreatmentTrackMutation,
  CreateTreatmentTrackMutationVariables
>;

/**
 * __useCreateTreatmentTrackMutation__
 *
 * To run a mutation, you first call `useCreateTreatmentTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTreatmentTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTreatmentTrackMutation, { data, loading, error }] = useCreateTreatmentTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTreatmentTrackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTreatmentTrackMutation,
    CreateTreatmentTrackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTreatmentTrackMutation, CreateTreatmentTrackMutationVariables>(
    CreateTreatmentTrackDocument,
    options
  );
}
export type CreateTreatmentTrackMutationHookResult = ReturnType<typeof useCreateTreatmentTrackMutation>;
export type CreateTreatmentTrackMutationResult = Apollo.MutationResult<CreateTreatmentTrackMutation>;
export type CreateTreatmentTrackMutationOptions = Apollo.BaseMutationOptions<
  CreateTreatmentTrackMutation,
  CreateTreatmentTrackMutationVariables
>;
export const ShowTreatmentTrackDocument = gql`
  query ShowTreatmentTrack($id: TreatmentTrackId!) {
    careTreatmentTrack(id: $id) {
      id
      name
      status
    }
  }
`;

/**
 * __useShowTreatmentTrackQuery__
 *
 * To run a query within a React component, call `useShowTreatmentTrackQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowTreatmentTrackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowTreatmentTrackQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShowTreatmentTrackQuery(
  baseOptions: Apollo.QueryHookOptions<ShowTreatmentTrackQuery, ShowTreatmentTrackQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShowTreatmentTrackQuery, ShowTreatmentTrackQueryVariables>(
    ShowTreatmentTrackDocument,
    options
  );
}
export function useShowTreatmentTrackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShowTreatmentTrackQuery, ShowTreatmentTrackQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShowTreatmentTrackQuery, ShowTreatmentTrackQueryVariables>(
    ShowTreatmentTrackDocument,
    options
  );
}
export type ShowTreatmentTrackQueryHookResult = ReturnType<typeof useShowTreatmentTrackQuery>;
export type ShowTreatmentTrackLazyQueryHookResult = ReturnType<typeof useShowTreatmentTrackLazyQuery>;
export type ShowTreatmentTrackQueryResult = Apollo.QueryResult<
  ShowTreatmentTrackQuery,
  ShowTreatmentTrackQueryVariables
>;
export const TreatmentTracksDocument = gql`
  query TreatmentTracks {
    careTreatmentTracks {
      nodes {
        id
        name
        status
      }
    }
  }
`;

/**
 * __useTreatmentTracksQuery__
 *
 * To run a query within a React component, call `useTreatmentTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentTracksQuery({
 *   variables: {
 *   },
 * });
 */
export function useTreatmentTracksQuery(
  baseOptions?: Apollo.QueryHookOptions<TreatmentTracksQuery, TreatmentTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TreatmentTracksQuery, TreatmentTracksQueryVariables>(
    TreatmentTracksDocument,
    options
  );
}
export function useTreatmentTracksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TreatmentTracksQuery, TreatmentTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TreatmentTracksQuery, TreatmentTracksQueryVariables>(
    TreatmentTracksDocument,
    options
  );
}
export type TreatmentTracksQueryHookResult = ReturnType<typeof useTreatmentTracksQuery>;
export type TreatmentTracksLazyQueryHookResult = ReturnType<typeof useTreatmentTracksLazyQuery>;
export type TreatmentTracksQueryResult = Apollo.QueryResult<
  TreatmentTracksQuery,
  TreatmentTracksQueryVariables
>;
export const UpdateTreatmentTrackDocument = gql`
  mutation UpdateTreatmentTrack($input: UpdateTreatmentTrackInput!) {
    careUpdateTreatmentTrack(input: $input) {
      errors {
        message
        path
      }
      result {
        treatmentTrack {
          id
          name
          status
        }
      }
      success
    }
  }
`;
export type UpdateTreatmentTrackMutationFn = Apollo.MutationFunction<
  UpdateTreatmentTrackMutation,
  UpdateTreatmentTrackMutationVariables
>;

/**
 * __useUpdateTreatmentTrackMutation__
 *
 * To run a mutation, you first call `useUpdateTreatmentTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTreatmentTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTreatmentTrackMutation, { data, loading, error }] = useUpdateTreatmentTrackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTreatmentTrackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTreatmentTrackMutation,
    UpdateTreatmentTrackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTreatmentTrackMutation, UpdateTreatmentTrackMutationVariables>(
    UpdateTreatmentTrackDocument,
    options
  );
}
export type UpdateTreatmentTrackMutationHookResult = ReturnType<typeof useUpdateTreatmentTrackMutation>;
export type UpdateTreatmentTrackMutationResult = Apollo.MutationResult<UpdateTreatmentTrackMutation>;
export type UpdateTreatmentTrackMutationOptions = Apollo.BaseMutationOptions<
  UpdateTreatmentTrackMutation,
  UpdateTreatmentTrackMutationVariables
>;
export const CreateExportDocument = gql`
  mutation CreateExport($input: CreateDataDumpInput!) {
    exportCreateDataDump(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        dataDump {
          id
        }
      }
    }
  }
`;
export type CreateExportMutationFn = Apollo.MutationFunction<
  CreateExportMutation,
  CreateExportMutationVariables
>;

/**
 * __useCreateExportMutation__
 *
 * To run a mutation, you first call `useCreateExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExportMutation, { data, loading, error }] = useCreateExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExportMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateExportMutation, CreateExportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateExportMutation, CreateExportMutationVariables>(
    CreateExportDocument,
    options
  );
}
export type CreateExportMutationHookResult = ReturnType<typeof useCreateExportMutation>;
export type CreateExportMutationResult = Apollo.MutationResult<CreateExportMutation>;
export type CreateExportMutationOptions = Apollo.BaseMutationOptions<
  CreateExportMutation,
  CreateExportMutationVariables
>;
export const DownloadExportDocument = gql`
  mutation DownloadExport($input: CreateDataDumpDownloadUrlInput!) {
    exportCreateDataDumpDownloadUrl(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        downloadUrl
      }
    }
  }
`;
export type DownloadExportMutationFn = Apollo.MutationFunction<
  DownloadExportMutation,
  DownloadExportMutationVariables
>;

/**
 * __useDownloadExportMutation__
 *
 * To run a mutation, you first call `useDownloadExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadExportMutation, { data, loading, error }] = useDownloadExportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadExportMutation(
  baseOptions?: Apollo.MutationHookOptions<DownloadExportMutation, DownloadExportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DownloadExportMutation, DownloadExportMutationVariables>(
    DownloadExportDocument,
    options
  );
}
export type DownloadExportMutationHookResult = ReturnType<typeof useDownloadExportMutation>;
export type DownloadExportMutationResult = Apollo.MutationResult<DownloadExportMutation>;
export type DownloadExportMutationOptions = Apollo.BaseMutationOptions<
  DownloadExportMutation,
  DownloadExportMutationVariables
>;
export const ExportListDocument = gql`
  query ExportList(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $sortBy: DataDumpSortParameter
    $sortDirection: SortDirection
  ) {
    exportDataDumps(
      first: $first
      last: $last
      after: $after
      before: $before
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      nodes {
        id
        status
        name
        password
        resources
        createdAt
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

/**
 * __useExportListQuery__
 *
 * To run a query within a React component, call `useExportListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useExportListQuery(
  baseOptions?: Apollo.QueryHookOptions<ExportListQuery, ExportListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportListQuery, ExportListQueryVariables>(ExportListDocument, options);
}
export function useExportListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportListQuery, ExportListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportListQuery, ExportListQueryVariables>(ExportListDocument, options);
}
export type ExportListQueryHookResult = ReturnType<typeof useExportListQuery>;
export type ExportListLazyQueryHookResult = ReturnType<typeof useExportListLazyQuery>;
export type ExportListQueryResult = Apollo.QueryResult<ExportListQuery, ExportListQueryVariables>;
export const ProviderSettingsDocument = gql`
  query ProviderSettings($id: ProviderId!) {
    provider(id: $id) {
      id
      measurementAllowed
    }
  }
`;

/**
 * __useProviderSettingsQuery__
 *
 * To run a query within a React component, call `useProviderSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<ProviderSettingsQuery, ProviderSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProviderSettingsQuery, ProviderSettingsQueryVariables>(
    ProviderSettingsDocument,
    options
  );
}
export function useProviderSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProviderSettingsQuery, ProviderSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProviderSettingsQuery, ProviderSettingsQueryVariables>(
    ProviderSettingsDocument,
    options
  );
}
export type ProviderSettingsQueryHookResult = ReturnType<typeof useProviderSettingsQuery>;
export type ProviderSettingsLazyQueryHookResult = ReturnType<typeof useProviderSettingsLazyQuery>;
export type ProviderSettingsQueryResult = Apollo.QueryResult<
  ProviderSettingsQuery,
  ProviderSettingsQueryVariables
>;
export const ProviderUpdateMeasurementAllowedDocument = gql`
  mutation ProviderUpdateMeasurementAllowed($input: ProviderUpdateMeasurementAllowedInput!) {
    settingsProviderUpdateMeasurementAllowed(input: $input) {
      success
      errors {
        message
        path
      }
      result {
        measurementAllowed
      }
    }
  }
`;
export type ProviderUpdateMeasurementAllowedMutationFn = Apollo.MutationFunction<
  ProviderUpdateMeasurementAllowedMutation,
  ProviderUpdateMeasurementAllowedMutationVariables
>;

/**
 * __useProviderUpdateMeasurementAllowedMutation__
 *
 * To run a mutation, you first call `useProviderUpdateMeasurementAllowedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProviderUpdateMeasurementAllowedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [providerUpdateMeasurementAllowedMutation, { data, loading, error }] = useProviderUpdateMeasurementAllowedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProviderUpdateMeasurementAllowedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProviderUpdateMeasurementAllowedMutation,
    ProviderUpdateMeasurementAllowedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProviderUpdateMeasurementAllowedMutation,
    ProviderUpdateMeasurementAllowedMutationVariables
  >(ProviderUpdateMeasurementAllowedDocument, options);
}
export type ProviderUpdateMeasurementAllowedMutationHookResult = ReturnType<
  typeof useProviderUpdateMeasurementAllowedMutation
>;
export type ProviderUpdateMeasurementAllowedMutationResult =
  Apollo.MutationResult<ProviderUpdateMeasurementAllowedMutation>;
export type ProviderUpdateMeasurementAllowedMutationOptions = Apollo.BaseMutationOptions<
  ProviderUpdateMeasurementAllowedMutation,
  ProviderUpdateMeasurementAllowedMutationVariables
>;
export const CreateAnalyticsEventMutationDocument = gql`
  mutation CreateAnalyticsEventMutation($event: CreateAnalyticsEventInput!) {
    createAnalyticsEvent(input: $event) {
      ok
      errors
      success
      result
    }
  }
`;
export type CreateAnalyticsEventMutationMutationFn = Apollo.MutationFunction<
  CreateAnalyticsEventMutationMutation,
  CreateAnalyticsEventMutationMutationVariables
>;

/**
 * __useCreateAnalyticsEventMutationMutation__
 *
 * To run a mutation, you first call `useCreateAnalyticsEventMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalyticsEventMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalyticsEventMutationMutation, { data, loading, error }] = useCreateAnalyticsEventMutationMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useCreateAnalyticsEventMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnalyticsEventMutationMutation,
    CreateAnalyticsEventMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAnalyticsEventMutationMutation,
    CreateAnalyticsEventMutationMutationVariables
  >(CreateAnalyticsEventMutationDocument, options);
}
export type CreateAnalyticsEventMutationMutationHookResult = ReturnType<
  typeof useCreateAnalyticsEventMutationMutation
>;
export type CreateAnalyticsEventMutationMutationResult =
  Apollo.MutationResult<CreateAnalyticsEventMutationMutation>;
export type CreateAnalyticsEventMutationMutationOptions = Apollo.BaseMutationOptions<
  CreateAnalyticsEventMutationMutation,
  CreateAnalyticsEventMutationMutationVariables
>;
export const SearchAdministrableScalesDocument = gql`
  query SearchAdministrableScales($search: String, $first: Int) {
    assessmentAdministrableScales(smartSearch: $search, first: $first) {
      nodes {
        id
        name
        shortname
        gender
        minAge
        maxAge
        locale
        estimatedTime
      }
    }
  }
`;

/**
 * __useSearchAdministrableScalesQuery__
 *
 * To run a query within a React component, call `useSearchAdministrableScalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAdministrableScalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAdministrableScalesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSearchAdministrableScalesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchAdministrableScalesQuery,
    SearchAdministrableScalesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchAdministrableScalesQuery, SearchAdministrableScalesQueryVariables>(
    SearchAdministrableScalesDocument,
    options
  );
}
export function useSearchAdministrableScalesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchAdministrableScalesQuery,
    SearchAdministrableScalesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchAdministrableScalesQuery, SearchAdministrableScalesQueryVariables>(
    SearchAdministrableScalesDocument,
    options
  );
}
export type SearchAdministrableScalesQueryHookResult = ReturnType<typeof useSearchAdministrableScalesQuery>;
export type SearchAdministrableScalesLazyQueryHookResult = ReturnType<
  typeof useSearchAdministrableScalesLazyQuery
>;
export type SearchAdministrableScalesQueryResult = Apollo.QueryResult<
  SearchAdministrableScalesQuery,
  SearchAdministrableScalesQueryVariables
>;
export const SearchAllScalesDocument = gql`
  query SearchAllScales($search: String, $first: Int) {
    assessmentScales(smartSearch: $search, first: $first) {
      nodes {
        id
        name
        shortname
        ... on AdministrableScale {
          gender
          minAge
          maxAge
          locale
          estimatedTime
        }
      }
    }
  }
`;

/**
 * __useSearchAllScalesQuery__
 *
 * To run a query within a React component, call `useSearchAllScalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAllScalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAllScalesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSearchAllScalesQuery(
  baseOptions?: Apollo.QueryHookOptions<SearchAllScalesQuery, SearchAllScalesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchAllScalesQuery, SearchAllScalesQueryVariables>(
    SearchAllScalesDocument,
    options
  );
}
export function useSearchAllScalesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchAllScalesQuery, SearchAllScalesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchAllScalesQuery, SearchAllScalesQueryVariables>(
    SearchAllScalesDocument,
    options
  );
}
export type SearchAllScalesQueryHookResult = ReturnType<typeof useSearchAllScalesQuery>;
export type SearchAllScalesLazyQueryHookResult = ReturnType<typeof useSearchAllScalesLazyQuery>;
export type SearchAllScalesQueryResult = Apollo.QueryResult<
  SearchAllScalesQuery,
  SearchAllScalesQueryVariables
>;
export const TreatmentTrackSelectDocument = gql`
  query TreatmentTrackSelect($search: String, $first: Int, $status: TreatmentTrackStatus) {
    careTreatmentTracks(
      smartSearch: $search
      first: $first
      sortBy: NAME
      sortDirection: ASC
      status: $status
    ) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useTreatmentTrackSelectQuery__
 *
 * To run a query within a React component, call `useTreatmentTrackSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentTrackSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentTrackSelectQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useTreatmentTrackSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<TreatmentTrackSelectQuery, TreatmentTrackSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TreatmentTrackSelectQuery, TreatmentTrackSelectQueryVariables>(
    TreatmentTrackSelectDocument,
    options
  );
}
export function useTreatmentTrackSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TreatmentTrackSelectQuery, TreatmentTrackSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TreatmentTrackSelectQuery, TreatmentTrackSelectQueryVariables>(
    TreatmentTrackSelectDocument,
    options
  );
}
export type TreatmentTrackSelectQueryHookResult = ReturnType<typeof useTreatmentTrackSelectQuery>;
export type TreatmentTrackSelectLazyQueryHookResult = ReturnType<typeof useTreatmentTrackSelectLazyQuery>;
export type TreatmentTrackSelectQueryResult = Apollo.QueryResult<
  TreatmentTrackSelectQuery,
  TreatmentTrackSelectQueryVariables
>;
export const TreatmentTrackSelectSingleDocument = gql`
  query TreatmentTrackSelectSingle($id: TreatmentTrackId!) {
    careTreatmentTrack(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useTreatmentTrackSelectSingleQuery__
 *
 * To run a query within a React component, call `useTreatmentTrackSelectSingleQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentTrackSelectSingleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentTrackSelectSingleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTreatmentTrackSelectSingleQuery(
  baseOptions: Apollo.QueryHookOptions<
    TreatmentTrackSelectSingleQuery,
    TreatmentTrackSelectSingleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TreatmentTrackSelectSingleQuery, TreatmentTrackSelectSingleQueryVariables>(
    TreatmentTrackSelectSingleDocument,
    options
  );
}
export function useTreatmentTrackSelectSingleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TreatmentTrackSelectSingleQuery,
    TreatmentTrackSelectSingleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TreatmentTrackSelectSingleQuery, TreatmentTrackSelectSingleQueryVariables>(
    TreatmentTrackSelectSingleDocument,
    options
  );
}
export type TreatmentTrackSelectSingleQueryHookResult = ReturnType<typeof useTreatmentTrackSelectSingleQuery>;
export type TreatmentTrackSelectSingleLazyQueryHookResult = ReturnType<
  typeof useTreatmentTrackSelectSingleLazyQuery
>;
export type TreatmentTrackSelectSingleQueryResult = Apollo.QueryResult<
  TreatmentTrackSelectSingleQuery,
  TreatmentTrackSelectSingleQueryVariables
>;
export const UserSelectDocument = gql`
  query UserSelect($search: String, $first: Int, $isProvider: Boolean) {
    users(
      search: $search
      first: $first
      sortBy: NAME_LAST_FIRST
      sortDirection: ASC
      isProvider: $isProvider
    ) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useUserSelectQuery__
 *
 * To run a query within a React component, call `useUserSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      isProvider: // value for 'isProvider'
 *   },
 * });
 */
export function useUserSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<UserSelectQuery, UserSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSelectQuery, UserSelectQueryVariables>(UserSelectDocument, options);
}
export function useUserSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserSelectQuery, UserSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSelectQuery, UserSelectQueryVariables>(UserSelectDocument, options);
}
export type UserSelectQueryHookResult = ReturnType<typeof useUserSelectQuery>;
export type UserSelectLazyQueryHookResult = ReturnType<typeof useUserSelectLazyQuery>;
export type UserSelectQueryResult = Apollo.QueryResult<UserSelectQuery, UserSelectQueryVariables>;
export const EntitySelectDocument = gql`
  query EntitySelect($search: String, $first: Int, $entityType: [EntityType!]) {
    entities(search: $search, entityType: $entityType, first: $first, sortBy: NAME, sortDirection: ASC) {
      nodes {
        ...EntitySummary
      }
    }
  }
  ${EntitySummaryFragmentDoc}
`;

/**
 * __useEntitySelectQuery__
 *
 * To run a query within a React component, call `useEntitySelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntitySelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntitySelectQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useEntitySelectQuery(
  baseOptions?: Apollo.QueryHookOptions<EntitySelectQuery, EntitySelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EntitySelectQuery, EntitySelectQueryVariables>(EntitySelectDocument, options);
}
export function useEntitySelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EntitySelectQuery, EntitySelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EntitySelectQuery, EntitySelectQueryVariables>(EntitySelectDocument, options);
}
export type EntitySelectQueryHookResult = ReturnType<typeof useEntitySelectQuery>;
export type EntitySelectLazyQueryHookResult = ReturnType<typeof useEntitySelectLazyQuery>;
export type EntitySelectQueryResult = Apollo.QueryResult<EntitySelectQuery, EntitySelectQueryVariables>;
export const EntitySelectSingleDocument = gql`
  query EntitySelectSingle($id: EntityId!) {
    entity(id: $id) {
      id
      entityType
      name
      shortname
    }
  }
`;

/**
 * __useEntitySelectSingleQuery__
 *
 * To run a query within a React component, call `useEntitySelectSingleQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntitySelectSingleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntitySelectSingleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEntitySelectSingleQuery(
  baseOptions: Apollo.QueryHookOptions<EntitySelectSingleQuery, EntitySelectSingleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EntitySelectSingleQuery, EntitySelectSingleQueryVariables>(
    EntitySelectSingleDocument,
    options
  );
}
export function useEntitySelectSingleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EntitySelectSingleQuery, EntitySelectSingleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EntitySelectSingleQuery, EntitySelectSingleQueryVariables>(
    EntitySelectSingleDocument,
    options
  );
}
export type EntitySelectSingleQueryHookResult = ReturnType<typeof useEntitySelectSingleQuery>;
export type EntitySelectSingleLazyQueryHookResult = ReturnType<typeof useEntitySelectSingleLazyQuery>;
export type EntitySelectSingleQueryResult = Apollo.QueryResult<
  EntitySelectSingleQuery,
  EntitySelectSingleQueryVariables
>;
export const EntitySelectWithExtrasDocument = gql`
  query EntitySelectWithExtras($search: String, $first: Int, $entityType: [EntityType!]) {
    all: entities(search: $search, entityType: $entityType, first: $first, sortBy: NAME, sortDirection: ASC) {
      nodes {
        ...EntitySummary
      }
    }
    groups: entities(
      search: $search
      entityType: [INSTITUTE_GROUP]
      first: 10
      sortBy: NAME
      sortDirection: ASC
    ) {
      nodes {
        ...EntitySummary
      }
    }
    institutes: entities(
      search: $search
      entityType: [INSTITUTE]
      first: 10
      sortBy: NAME
      sortDirection: ASC
    ) {
      nodes {
        ...EntitySummary
      }
    }
  }
  ${EntitySummaryFragmentDoc}
`;

/**
 * __useEntitySelectWithExtrasQuery__
 *
 * To run a query within a React component, call `useEntitySelectWithExtrasQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntitySelectWithExtrasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntitySelectWithExtrasQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useEntitySelectWithExtrasQuery(
  baseOptions?: Apollo.QueryHookOptions<EntitySelectWithExtrasQuery, EntitySelectWithExtrasQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EntitySelectWithExtrasQuery, EntitySelectWithExtrasQueryVariables>(
    EntitySelectWithExtrasDocument,
    options
  );
}
export function useEntitySelectWithExtrasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EntitySelectWithExtrasQuery, EntitySelectWithExtrasQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EntitySelectWithExtrasQuery, EntitySelectWithExtrasQueryVariables>(
    EntitySelectWithExtrasDocument,
    options
  );
}
export type EntitySelectWithExtrasQueryHookResult = ReturnType<typeof useEntitySelectWithExtrasQuery>;
export type EntitySelectWithExtrasLazyQueryHookResult = ReturnType<typeof useEntitySelectWithExtrasLazyQuery>;
export type EntitySelectWithExtrasQueryResult = Apollo.QueryResult<
  EntitySelectWithExtrasQuery,
  EntitySelectWithExtrasQueryVariables
>;
export const EntityTreeNodeSelectSingleDocument = gql`
  query EntityTreeNodeSelectSingle($node: EntityTreeNodeParams!) {
    entityTreeNode(node: $node) {
      ...EntityTreeNodeDetails
    }
  }
  ${EntityTreeNodeDetailsFragmentDoc}
`;

/**
 * __useEntityTreeNodeSelectSingleQuery__
 *
 * To run a query within a React component, call `useEntityTreeNodeSelectSingleQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityTreeNodeSelectSingleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityTreeNodeSelectSingleQuery({
 *   variables: {
 *      node: // value for 'node'
 *   },
 * });
 */
export function useEntityTreeNodeSelectSingleQuery(
  baseOptions: Apollo.QueryHookOptions<
    EntityTreeNodeSelectSingleQuery,
    EntityTreeNodeSelectSingleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EntityTreeNodeSelectSingleQuery, EntityTreeNodeSelectSingleQueryVariables>(
    EntityTreeNodeSelectSingleDocument,
    options
  );
}
export function useEntityTreeNodeSelectSingleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EntityTreeNodeSelectSingleQuery,
    EntityTreeNodeSelectSingleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EntityTreeNodeSelectSingleQuery, EntityTreeNodeSelectSingleQueryVariables>(
    EntityTreeNodeSelectSingleDocument,
    options
  );
}
export type EntityTreeNodeSelectSingleQueryHookResult = ReturnType<typeof useEntityTreeNodeSelectSingleQuery>;
export type EntityTreeNodeSelectSingleLazyQueryHookResult = ReturnType<
  typeof useEntityTreeNodeSelectSingleLazyQuery
>;
export type EntityTreeNodeSelectSingleQueryResult = Apollo.QueryResult<
  EntityTreeNodeSelectSingleQuery,
  EntityTreeNodeSelectSingleQueryVariables
>;
export const FlagFetchDocument = gql`
  query FlagFetch($ids: [FlagId!]) {
    flags(id: $ids, first: 100, sortBy: NAME, sortDirection: ASC) {
      nodes {
        id
        name
        icon
      }
    }
  }
`;

/**
 * __useFlagFetchQuery__
 *
 * To run a query within a React component, call `useFlagFetchQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlagFetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlagFetchQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFlagFetchQuery(
  baseOptions?: Apollo.QueryHookOptions<FlagFetchQuery, FlagFetchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FlagFetchQuery, FlagFetchQueryVariables>(FlagFetchDocument, options);
}
export function useFlagFetchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FlagFetchQuery, FlagFetchQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FlagFetchQuery, FlagFetchQueryVariables>(FlagFetchDocument, options);
}
export type FlagFetchQueryHookResult = ReturnType<typeof useFlagFetchQuery>;
export type FlagFetchLazyQueryHookResult = ReturnType<typeof useFlagFetchLazyQuery>;
export type FlagFetchQueryResult = Apollo.QueryResult<FlagFetchQuery, FlagFetchQueryVariables>;
export const FlagSelectDocument = gql`
  query FlagSelect($search: String, $first: Int) {
    flags(smartSearch: $search, first: $first, sortBy: NAME, sortDirection: ASC) {
      nodes {
        id
        name
        icon
      }
    }
  }
`;

/**
 * __useFlagSelectQuery__
 *
 * To run a query within a React component, call `useFlagSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlagSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlagSelectQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useFlagSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<FlagSelectQuery, FlagSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FlagSelectQuery, FlagSelectQueryVariables>(FlagSelectDocument, options);
}
export function useFlagSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FlagSelectQuery, FlagSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FlagSelectQuery, FlagSelectQueryVariables>(FlagSelectDocument, options);
}
export type FlagSelectQueryHookResult = ReturnType<typeof useFlagSelectQuery>;
export type FlagSelectLazyQueryHookResult = ReturnType<typeof useFlagSelectLazyQuery>;
export type FlagSelectQueryResult = Apollo.QueryResult<FlagSelectQuery, FlagSelectQueryVariables>;
export const InstituteSelectDocument = gql`
  query InstituteSelect($search: String, $first: Int, $instituteType: [InstituteType!]) {
    institutes(
      search: $search
      first: $first
      sortBy: NAME
      sortDirection: ASC
      instituteType: $instituteType
    ) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useInstituteSelectQuery__
 *
 * To run a query within a React component, call `useInstituteSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteSelectQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      instituteType: // value for 'instituteType'
 *   },
 * });
 */
export function useInstituteSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<InstituteSelectQuery, InstituteSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteSelectQuery, InstituteSelectQueryVariables>(
    InstituteSelectDocument,
    options
  );
}
export function useInstituteSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstituteSelectQuery, InstituteSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteSelectQuery, InstituteSelectQueryVariables>(
    InstituteSelectDocument,
    options
  );
}
export type InstituteSelectQueryHookResult = ReturnType<typeof useInstituteSelectQuery>;
export type InstituteSelectLazyQueryHookResult = ReturnType<typeof useInstituteSelectLazyQuery>;
export type InstituteSelectQueryResult = Apollo.QueryResult<
  InstituteSelectQuery,
  InstituteSelectQueryVariables
>;
export const InstituteSelectSingleDocument = gql`
  query InstituteSelectSingle($id: InstituteId!) {
    institute(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useInstituteSelectSingleQuery__
 *
 * To run a query within a React component, call `useInstituteSelectSingleQuery` and pass it any options that fit your needs.
 * When your component renders, `useInstituteSelectSingleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInstituteSelectSingleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInstituteSelectSingleQuery(
  baseOptions: Apollo.QueryHookOptions<InstituteSelectSingleQuery, InstituteSelectSingleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InstituteSelectSingleQuery, InstituteSelectSingleQueryVariables>(
    InstituteSelectSingleDocument,
    options
  );
}
export function useInstituteSelectSingleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InstituteSelectSingleQuery, InstituteSelectSingleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InstituteSelectSingleQuery, InstituteSelectSingleQueryVariables>(
    InstituteSelectSingleDocument,
    options
  );
}
export type InstituteSelectSingleQueryHookResult = ReturnType<typeof useInstituteSelectSingleQuery>;
export type InstituteSelectSingleLazyQueryHookResult = ReturnType<typeof useInstituteSelectSingleLazyQuery>;
export type InstituteSelectSingleQueryResult = Apollo.QueryResult<
  InstituteSelectSingleQuery,
  InstituteSelectSingleQueryVariables
>;
export const OrganizationSelectDocument = gql`
  query OrganizationSelect($search: String, $first: Int) {
    organizations(search: $search, first: $first, sortBy: NAME, sortDirection: ASC) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useOrganizationSelectQuery__
 *
 * To run a query within a React component, call `useOrganizationSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSelectQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useOrganizationSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<OrganizationSelectQuery, OrganizationSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationSelectQuery, OrganizationSelectQueryVariables>(
    OrganizationSelectDocument,
    options
  );
}
export function useOrganizationSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationSelectQuery, OrganizationSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationSelectQuery, OrganizationSelectQueryVariables>(
    OrganizationSelectDocument,
    options
  );
}
export type OrganizationSelectQueryHookResult = ReturnType<typeof useOrganizationSelectQuery>;
export type OrganizationSelectLazyQueryHookResult = ReturnType<typeof useOrganizationSelectLazyQuery>;
export type OrganizationSelectQueryResult = Apollo.QueryResult<
  OrganizationSelectQuery,
  OrganizationSelectQueryVariables
>;
export const OrganizationTreeDocument = gql`
  query OrganizationTree {
    organizations(sortBy: NAME, sortDirection: ASC) {
      nodes {
        id
        name
        shortname
        parentId
        measurementAllowed
        inTrialPeriod
        timezone
      }
    }
  }
`;

/**
 * __useOrganizationTreeQuery__
 *
 * To run a query within a React component, call `useOrganizationTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTreeQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationTreeQuery(
  baseOptions?: Apollo.QueryHookOptions<OrganizationTreeQuery, OrganizationTreeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationTreeQuery, OrganizationTreeQueryVariables>(
    OrganizationTreeDocument,
    options
  );
}
export function useOrganizationTreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationTreeQuery, OrganizationTreeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationTreeQuery, OrganizationTreeQueryVariables>(
    OrganizationTreeDocument,
    options
  );
}
export type OrganizationTreeQueryHookResult = ReturnType<typeof useOrganizationTreeQuery>;
export type OrganizationTreeLazyQueryHookResult = ReturnType<typeof useOrganizationTreeLazyQuery>;
export type OrganizationTreeQueryResult = Apollo.QueryResult<
  OrganizationTreeQuery,
  OrganizationTreeQueryVariables
>;
export const PatientSelectDocument = gql`
  query PatientSelect($search: String, $first: Int) {
    patients(search: $search, first: $first, sortBy: FIRSTNAME, sortDirection: ASC) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __usePatientSelectQuery__
 *
 * To run a query within a React component, call `usePatientSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientSelectQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePatientSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<PatientSelectQuery, PatientSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientSelectQuery, PatientSelectQueryVariables>(PatientSelectDocument, options);
}
export function usePatientSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PatientSelectQuery, PatientSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientSelectQuery, PatientSelectQueryVariables>(PatientSelectDocument, options);
}
export type PatientSelectQueryHookResult = ReturnType<typeof usePatientSelectQuery>;
export type PatientSelectLazyQueryHookResult = ReturnType<typeof usePatientSelectLazyQuery>;
export type PatientSelectQueryResult = Apollo.QueryResult<PatientSelectQuery, PatientSelectQueryVariables>;
export const ProviderSelectDocument = gql`
  query ProviderSelect(
    $search: String
    $first: Int
    $defaultRelationship: [CareEpisodeProviderRelationship!]
  ) {
    providers(
      search: $search
      first: $first
      sortBy: NAME
      sortDirection: ASC
      active: true
      practicing: true
      defaultRelationship: $defaultRelationship
    ) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useProviderSelectQuery__
 *
 * To run a query within a React component, call `useProviderSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderSelectQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      defaultRelationship: // value for 'defaultRelationship'
 *   },
 * });
 */
export function useProviderSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<ProviderSelectQuery, ProviderSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProviderSelectQuery, ProviderSelectQueryVariables>(ProviderSelectDocument, options);
}
export function useProviderSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProviderSelectQuery, ProviderSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProviderSelectQuery, ProviderSelectQueryVariables>(
    ProviderSelectDocument,
    options
  );
}
export type ProviderSelectQueryHookResult = ReturnType<typeof useProviderSelectQuery>;
export type ProviderSelectLazyQueryHookResult = ReturnType<typeof useProviderSelectLazyQuery>;
export type ProviderSelectQueryResult = Apollo.QueryResult<ProviderSelectQuery, ProviderSelectQueryVariables>;
export const ProviderSelectSingleDocument = gql`
  query ProviderSelectSingle($id: ProviderId!) {
    provider(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useProviderSelectSingleQuery__
 *
 * To run a query within a React component, call `useProviderSelectSingleQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderSelectSingleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderSelectSingleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderSelectSingleQuery(
  baseOptions: Apollo.QueryHookOptions<ProviderSelectSingleQuery, ProviderSelectSingleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProviderSelectSingleQuery, ProviderSelectSingleQueryVariables>(
    ProviderSelectSingleDocument,
    options
  );
}
export function useProviderSelectSingleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProviderSelectSingleQuery, ProviderSelectSingleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProviderSelectSingleQuery, ProviderSelectSingleQueryVariables>(
    ProviderSelectSingleDocument,
    options
  );
}
export type ProviderSelectSingleQueryHookResult = ReturnType<typeof useProviderSelectSingleQuery>;
export type ProviderSelectSingleLazyQueryHookResult = ReturnType<typeof useProviderSelectSingleLazyQuery>;
export type ProviderSelectSingleQueryResult = Apollo.QueryResult<
  ProviderSelectSingleQuery,
  ProviderSelectSingleQueryVariables
>;
export const ScaleScorerSelectDocument = gql`
  query ScaleScorerSelect($search: String, $first: Int, $domain: [ScaleScorerDomain!]) {
    assessmentScaleScorers(
      search: $search
      first: $first
      sortBy: SCALE_NAME
      sortDirection: ASC
      domain: $domain
    ) {
      nodes {
        id
        name
        domain
        scale {
          name
          shortname
        }
      }
    }
  }
`;

/**
 * __useScaleScorerSelectQuery__
 *
 * To run a query within a React component, call `useScaleScorerSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useScaleScorerSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScaleScorerSelectQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useScaleScorerSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<ScaleScorerSelectQuery, ScaleScorerSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScaleScorerSelectQuery, ScaleScorerSelectQueryVariables>(
    ScaleScorerSelectDocument,
    options
  );
}
export function useScaleScorerSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ScaleScorerSelectQuery, ScaleScorerSelectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScaleScorerSelectQuery, ScaleScorerSelectQueryVariables>(
    ScaleScorerSelectDocument,
    options
  );
}
export type ScaleScorerSelectQueryHookResult = ReturnType<typeof useScaleScorerSelectQuery>;
export type ScaleScorerSelectLazyQueryHookResult = ReturnType<typeof useScaleScorerSelectLazyQuery>;
export type ScaleScorerSelectQueryResult = Apollo.QueryResult<
  ScaleScorerSelectQuery,
  ScaleScorerSelectQueryVariables
>;
export const ScaleScorerSelectSingleDocument = gql`
  query ScaleScorerSelectSingle($id: ScaleScorerId!) {
    assessmentScaleScorer(id: $id) {
      id
      name
      domain
      scale {
        name
        shortname
      }
    }
  }
`;

/**
 * __useScaleScorerSelectSingleQuery__
 *
 * To run a query within a React component, call `useScaleScorerSelectSingleQuery` and pass it any options that fit your needs.
 * When your component renders, `useScaleScorerSelectSingleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScaleScorerSelectSingleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScaleScorerSelectSingleQuery(
  baseOptions: Apollo.QueryHookOptions<ScaleScorerSelectSingleQuery, ScaleScorerSelectSingleQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScaleScorerSelectSingleQuery, ScaleScorerSelectSingleQueryVariables>(
    ScaleScorerSelectSingleDocument,
    options
  );
}
export function useScaleScorerSelectSingleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScaleScorerSelectSingleQuery,
    ScaleScorerSelectSingleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScaleScorerSelectSingleQuery, ScaleScorerSelectSingleQueryVariables>(
    ScaleScorerSelectSingleDocument,
    options
  );
}
export type ScaleScorerSelectSingleQueryHookResult = ReturnType<typeof useScaleScorerSelectSingleQuery>;
export type ScaleScorerSelectSingleLazyQueryHookResult = ReturnType<
  typeof useScaleScorerSelectSingleLazyQuery
>;
export type ScaleScorerSelectSingleQueryResult = Apollo.QueryResult<
  ScaleScorerSelectSingleQuery,
  ScaleScorerSelectSingleQueryVariables
>;
export const ScaleScorerWithUsageSelectDocument = gql`
  query ScaleScorerWithUsageSelect(
    $search: String
    $first: Int
    $domain: [ScaleScorerDomain!]
    $entityTreeNode: EntityTreeNodeParams
    $dateRange: DateRangeInput
  ) {
    assessmentScaleScorersWithUsage(
      search: $search
      first: $first
      domain: $domain
      entityTreeNode: $entityTreeNode
      dateRange: $dateRange
    ) {
      nodes {
        sessionCount
        scale {
          name
          shortname
        }
        scaleScorer {
          id
        }
      }
    }
  }
`;

/**
 * __useScaleScorerWithUsageSelectQuery__
 *
 * To run a query within a React component, call `useScaleScorerWithUsageSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useScaleScorerWithUsageSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScaleScorerWithUsageSelectQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      domain: // value for 'domain'
 *      entityTreeNode: // value for 'entityTreeNode'
 *      dateRange: // value for 'dateRange'
 *   },
 * });
 */
export function useScaleScorerWithUsageSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ScaleScorerWithUsageSelectQuery,
    ScaleScorerWithUsageSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScaleScorerWithUsageSelectQuery, ScaleScorerWithUsageSelectQueryVariables>(
    ScaleScorerWithUsageSelectDocument,
    options
  );
}
export function useScaleScorerWithUsageSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScaleScorerWithUsageSelectQuery,
    ScaleScorerWithUsageSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScaleScorerWithUsageSelectQuery, ScaleScorerWithUsageSelectQueryVariables>(
    ScaleScorerWithUsageSelectDocument,
    options
  );
}
export type ScaleScorerWithUsageSelectQueryHookResult = ReturnType<typeof useScaleScorerWithUsageSelectQuery>;
export type ScaleScorerWithUsageSelectLazyQueryHookResult = ReturnType<
  typeof useScaleScorerWithUsageSelectLazyQuery
>;
export type ScaleScorerWithUsageSelectQueryResult = Apollo.QueryResult<
  ScaleScorerWithUsageSelectQuery,
  ScaleScorerWithUsageSelectQueryVariables
>;
export const TreatmentServiceTreeDocument = gql`
  query TreatmentServiceTree {
    treatmentServices {
      nodes {
        id
        name
        parentId
      }
    }
  }
`;

/**
 * __useTreatmentServiceTreeQuery__
 *
 * To run a query within a React component, call `useTreatmentServiceTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreatmentServiceTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreatmentServiceTreeQuery({
 *   variables: {
 *   },
 * });
 */
export function useTreatmentServiceTreeQuery(
  baseOptions?: Apollo.QueryHookOptions<TreatmentServiceTreeQuery, TreatmentServiceTreeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TreatmentServiceTreeQuery, TreatmentServiceTreeQueryVariables>(
    TreatmentServiceTreeDocument,
    options
  );
}
export function useTreatmentServiceTreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TreatmentServiceTreeQuery, TreatmentServiceTreeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TreatmentServiceTreeQuery, TreatmentServiceTreeQueryVariables>(
    TreatmentServiceTreeDocument,
    options
  );
}
export type TreatmentServiceTreeQueryHookResult = ReturnType<typeof useTreatmentServiceTreeQuery>;
export type TreatmentServiceTreeLazyQueryHookResult = ReturnType<typeof useTreatmentServiceTreeLazyQuery>;
export type TreatmentServiceTreeQueryResult = Apollo.QueryResult<
  TreatmentServiceTreeQuery,
  TreatmentServiceTreeQueryVariables
>;
export const ProviderNameDocument = gql`
  query ProviderName($id: ProviderId!) {
    provider(id: $id) {
      name
    }
  }
`;

/**
 * __useProviderNameQuery__
 *
 * To run a query within a React component, call `useProviderNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useProviderNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProviderNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProviderNameQuery(
  baseOptions: Apollo.QueryHookOptions<ProviderNameQuery, ProviderNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProviderNameQuery, ProviderNameQueryVariables>(ProviderNameDocument, options);
}
export function useProviderNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProviderNameQuery, ProviderNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProviderNameQuery, ProviderNameQueryVariables>(ProviderNameDocument, options);
}
export type ProviderNameQueryHookResult = ReturnType<typeof useProviderNameQuery>;
export type ProviderNameLazyQueryHookResult = ReturnType<typeof useProviderNameLazyQuery>;
export type ProviderNameQueryResult = Apollo.QueryResult<ProviderNameQuery, ProviderNameQueryVariables>;
